import React from 'react';

export function MainProductI() {
    return (
        <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12.5" r="11.5" fill="#F9F7F2" stroke="#A3792D" />
            <path
                d="M12.9644 8.12432V16.5003H11.2844V10.6563L9.1364 16.5003H7.6724L5.5244 10.6803V16.5003H3.8324V8.12432H5.8844L8.4284 14.4243L10.9244 8.12432H12.9644Z"
                fill="#A2792C"
            />
            <path
                d="M16.1885 13.3563V16.5003H14.4965V8.02832H17.5805C18.5805 8.02832 19.3325 8.26832 19.8365 8.74832C20.3485 9.22832 20.6045 9.88032 20.6045 10.7043C20.6045 11.5123 20.3405 12.1563 19.8125 12.6363C19.2925 13.1163 18.5485 13.3563 17.5805 13.3563H16.1885ZM17.4005 12.0003C18.3925 12.0003 18.8885 11.5683 18.8885 10.7043C18.8885 10.2803 18.7725 9.95632 18.5405 9.73232C18.3085 9.50832 17.9285 9.39632 17.4005 9.39632H16.1885V12.0003H17.4005Z"
                fill="#A2792C"
            />
        </svg>
    );
}

export function BackI() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#A2792C" />
            <rect
                x="18.4853"
                y="13.1387"
                width="12"
                height="2"
                transform="rotate(-180 18.4853 13.1387)"
                fill="white"
            />
            <rect
                x="12.0709"
                y="19.1387"
                width="9.99977"
                height="2"
                transform="rotate(-135 12.0709 19.1387)"
                fill="white"
            />
            <rect
                x="13.4824"
                y="6.41406"
                width="9.99587"
                height="2"
                transform="rotate(135 13.4824 6.41406)"
                fill="white"
            />
        </svg>
    );
}

export function NextI() {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="10" fill="#A2792C" />
            <rect x="5" y="11" width="12" height="2" fill="white" />
            <rect
                x="11.4144"
                y="5"
                width="9.99977"
                height="2"
                transform="rotate(45 11.4144 5)"
                fill="white"
            />
            <rect
                x="10.003"
                y="17.7246"
                width="9.99587"
                height="2"
                transform="rotate(-45 10.003 17.7246)"
                fill="white"
            />
        </svg>
    );
}

export function LoopI() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.33333 7.33301H22.6667V9.99967H3.99999V21.9997H1.33333V7.33301Z"
                fill="#A3792D"
            />
            <rect
                x="22.8759"
                y="6.99023"
                width="2.66667"
                height="10.6667"
                transform="rotate(45 22.8759 6.99023)"
                fill="#A3792D"
            />
            <rect
                x="24.7614"
                y="8.875"
                width="2.66667"
                height="10.6667"
                transform="rotate(135 24.7614 8.875)"
                fill="#A3792D"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M30.6667 24.667H9.33333V22.0003H28V10.0003H30.6667V24.667Z"
                fill="#A3792D"
            />
            <rect
                x="8.55231"
                y="25.0098"
                width="2.66667"
                height="10.6667"
                transform="rotate(-135 8.55231 25.0098)"
                fill="#A3792D"
            />
            <rect
                x="6.66673"
                y="23.125"
                width="2.66667"
                height="10.6667"
                transform="rotate(-45 6.66673 23.125)"
                fill="#A3792D"
            />
        </svg>
    );
}

export function TrendingI() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="28" y="1.33301" width="2.66667" height="10.6667" fill="#A3792D" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.1946 10.5735L28.0202 2.0957L29.9797 3.90445L21.8053 12.76L15.1166 15.9451L12.4636 25.2307L2.98082 30.499L1.68578 28.1679L10.203 23.4361L12.8833 14.055L20.1946 10.5735Z"
                fill="#A3792D"
            />
            <rect
                x="30.6667"
                y="1.33301"
                width="2.66667"
                height="10.6667"
                transform="rotate(90 30.6667 1.33301)"
                fill="#A3792D"
            />
            <rect x="5.33333" y="1.33301" width="2.66667" height="10.6667" fill="#A3792D" />
            <rect
                x="12"
                y="5.33301"
                width="2.66667"
                height="10.6667"
                transform="rotate(90 12 5.33301)"
                fill="#A3792D"
            />
        </svg>
    );
}

export function DollarGraphI() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="1.33333" y="28" width="13.3333" height="2.66667" fill="#A3792D" />
            <rect x="17.3333" y="17.333" width="2.66667" height="13.3333" fill="#A3792D" />
            <rect x="22.6667" y="13.333" width="2.66667" height="17.3333" fill="#A3792D" />
            <rect x="28" y="1.33301" width="2.66667" height="29.3333" fill="#A3792D" />
            <path
                d="M14.6667 16.0497C14.6667 16.9674 14.4319 17.8184 13.9624 18.6026C13.509 19.3702 12.829 20.0042 11.9223 20.5048C11.0318 20.9887 9.97126 21.2556 8.74074 21.3057V23.333H7.18639V21.2807C5.47014 21.1305 4.08581 20.6049 3.03339 19.7039C1.98097 18.7862 1.42238 17.5431 1.35761 15.9746H5.77777C5.87492 16.9758 6.34446 17.6182 7.18639 17.9018V13.9473C5.92349 13.6136 4.91155 13.2883 4.15057 12.9712C3.40578 12.6542 2.75005 12.1453 2.18336 11.4445C1.61667 10.7437 1.33333 9.78428 1.33333 8.56623C1.33333 7.04784 1.87573 5.82978 2.96053 4.91207C4.06152 3.99437 5.47014 3.47711 7.18639 3.36031V1.33301H8.74074V3.36031C10.4408 3.4938 11.7847 4.00271 12.7723 4.88705C13.76 5.77139 14.3105 6.99778 14.4238 8.56623H9.97935C9.88221 7.6652 9.46933 7.08121 8.74074 6.81424V10.6936C10.0846 11.0774 11.1208 11.4195 11.8494 11.7198C12.578 12.0202 13.2257 12.5207 13.7923 13.2215C14.3752 13.9056 14.6667 14.8484 14.6667 16.0497ZM5.7292 8.366C5.7292 8.78314 5.85063 9.13354 6.0935 9.41719C6.35256 9.70085 6.71685 9.95113 7.18639 10.168V6.6891C6.73305 6.77252 6.37684 6.95606 6.11778 7.23972C5.85873 7.50669 5.7292 7.88212 5.7292 8.366ZM8.74074 17.9769C9.22647 17.8935 9.60696 17.6933 9.88221 17.3762C10.1736 17.0592 10.3194 16.6671 10.3194 16.1999C10.3194 15.7661 10.1817 15.4157 9.90649 15.1487C9.64744 14.8651 9.25885 14.6231 8.74074 14.4229V17.9769Z"
                fill="#A3792D"
            />
        </svg>
    );
}

export function AutomationUpI() {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <rect x="1.33333" y="28" width="10.6667" height="2.66667" fill="#A3792D" />
            <rect x="15.3333" y="19.333" width="2.66667" height="11.3333" fill="#A3792D" />
            <rect
                x="20.9905"
                y="3.21973"
                width="2.66667"
                height="10.6667"
                transform="rotate(-45 20.9905 3.21973)"
                fill="#A3792D"
            />
            <rect x="21.3333" y="4" width="2.66667" height="26.6667" fill="#A3792D" />
            <rect
                x="22.8759"
                y="1.33398"
                width="2.66667"
                height="10.6667"
                transform="rotate(45 22.8759 1.33398)"
                fill="#A3792D"
            />
            <path
                d="M1.35327 8.84454C1.65671 7.90396 2.15657 7.04058 2.81613 6.30888C2.92766 6.18514 3.10264 6.14105 3.2593 6.19719L4.8355 6.76205C5.26283 6.9151 5.73305 6.69198 5.88576 6.26368C5.90077 6.22157 5.91234 6.17829 5.92033 6.13437L6.22062 4.48236C6.25048 4.31811 6.37634 4.18818 6.53927 4.15342C7.016 4.05172 7.50479 4 7.99999 4C8.49489 4 8.98339 4.05166 9.45985 4.15324C9.62273 4.18796 9.74859 4.31781 9.77851 4.482L10.0796 6.1343C10.1611 6.58174 10.589 6.87832 11.0354 6.79672C11.0793 6.78869 11.1225 6.7771 11.1644 6.76207L12.7407 6.19719C12.8974 6.14105 13.0723 6.18514 13.1839 6.30888C13.8434 7.04058 14.3433 7.90396 14.6467 8.84454C14.6979 9.00322 14.6485 9.17718 14.5217 9.28512L13.2441 10.3722C12.8982 10.6666 12.8559 11.1863 13.1496 11.533C13.1785 11.5671 13.2101 11.5988 13.2441 11.6277L14.5217 12.7149C14.6485 12.8228 14.6979 12.9968 14.6467 13.1555C14.3433 14.096 13.8434 14.9594 13.1839 15.6911C13.0723 15.8149 12.8974 15.859 12.7407 15.8028L11.1645 15.238C10.7372 15.0849 10.2669 15.308 10.1142 15.7363C10.0992 15.7784 10.0876 15.8217 10.0796 15.8658L9.77851 17.518C9.74859 17.6822 9.62273 17.812 9.45985 17.8468C8.98339 17.9483 8.49489 18 7.99999 18C7.50479 18 7.016 17.9483 6.53927 17.8466C6.37634 17.8118 6.25048 17.6819 6.22062 17.5176L5.92035 15.8657C5.83893 15.4183 5.41103 15.1217 4.96459 15.2033C4.92069 15.2113 4.87752 15.2229 4.83557 15.2379L3.2593 15.8028C3.10264 15.859 2.92766 15.8149 2.81613 15.6911C2.15657 14.9594 1.65671 14.096 1.35327 13.1555C1.30208 12.9968 1.35144 12.8228 1.47828 12.7149L2.75585 11.6278C3.10177 11.3334 3.14408 10.8137 2.85035 10.467C2.82147 10.4329 2.78987 10.4012 2.75587 10.3723L1.47828 9.28512C1.35144 9.17718 1.30208 9.00322 1.35327 8.84454ZM6.35665 11C6.35665 11.9096 7.0924 12.6471 7.99999 12.6471C8.90759 12.6471 9.64334 11.9096 9.64334 11C9.64334 10.0904 8.90759 9.35294 7.99999 9.35294C7.0924 9.35294 6.35665 10.0904 6.35665 11Z"
                fill="#A3792D"
            />
        </svg>
    );
}

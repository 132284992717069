import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

export default function VendorCalendarProductPart(props) {
    const { data } = props;
    return (
        <>
            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Customer details</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Name</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {(data?.paystackData?.metadata?.customer_first_name || '') +
                                    ' ' +
                                    (data?.paystackData?.metadata?.customer_last_name || '')}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Email</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {data?.paystackData?.customer?.email}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Mobile number</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {data?.paystackData?.customer?.mobile_number}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Calendar details</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Title</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {data?.paystackData?.metadata?.calendar_title}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Product details</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        {Array.isArray(data?.paystackData?.metadata?.products) ? (
                            data?.paystackData?.metadata?.products.map((val, key) => (
                                <Fragment key={key}>
                                    <div className="payment-stats-data-header">
                                        <span className="text-muted fs-14">Title</span>
                                    </div>
                                    <div className="payment-stats-data-info">
                                        <span className="text-gray fs-14">
                                            {val?.product_title}
                                        </span>
                                    </div>
                                </Fragment>
                            ))
                        ) : (
                            <>
                                <div className="payment-stats-data-header">
                                    <span className="text-muted fs-14">Title</span>
                                </div>
                                <div className="payment-stats-data-info">
                                    <span className="text-gray fs-14">
                                        {data?.paystackData?.metadata?.product_title}
                                    </span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

VendorCalendarProductPart.propTypes = {
    data: PropTypes.object
};

import React from 'react';
import { Tag, Tooltip, Button, Collapse } from 'antd';
import PropTypes from 'prop-types';
import { stringSplitAndUppercase } from 'utils';
import { EditOutlined } from '@ant-design/icons';
const { Panel } = Collapse;

export default function BankDetailSection(props) {
    const { stripe_account_detail, redirectToStripeDashboard } = props;
    return (
        <div className="mb-4">
            <div className="pb-2 border-bottom">
                <div className="d-flex align-items-center">
                    <div className="pr-2">
                        <span className="fs-20 fw-700">Bank accounts or debit cards</span>
                    </div>
                    <div className="ml-auto">
                        <Button onClick={redirectToStripeDashboard} type="link" className="fw-400">
                            <span>Edit</span>
                            <EditOutlined />
                        </Button>
                        {/* <Link to="#">
                 <span>Edit</span>
                 <EditOutlined />
             </Link> */}
                    </div>
                </div>
            </div>

            {Array.isArray(stripe_account_detail?.external_accounts?.data) &&
                stripe_account_detail?.external_accounts?.data.map((value, ind) => (
                    <Collapse
                        key={ind}
                        className="bg-transparent"
                        bordered={false}
                        expandIcon={({ isActive }) => (
                            <span
                                className="d-inline-flex align-items-center text-primary text-nowrap"
                                style={{ paddingTop: 20 }}>
                                <span>
                                    <svg
                                        width="8"
                                        height="12"
                                        viewBox="0 0 8 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        style={{
                                            transform: `rotate(${isActive ? '90deg' : 0})`
                                        }}>
                                        <path
                                            d="M0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0L0 1.41Z"
                                            fill="currentColor"
                                        />
                                    </svg>
                                </span>
                            </span>
                        )}
                        defaultActiveKey={[1]}>
                        <Panel
                            className="border-0"
                            header={
                                <div className="d-flex align-items-center">
                                    <div className="pr-2">
                                        <span>
                                            <svg
                                                height="32"
                                                width="32"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g fill="none" fillRule="evenodd">
                                                    <path d="M0 0h32v32H0z" fill="#e3e8ee"></path>
                                                    <path
                                                        d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z"
                                                        fill="#697386"></path>
                                                </g>
                                            </svg>
                                        </span>
                                    </div>
                                    <div>
                                        <div>
                                            <span className="fs-14 fw-600 pr-2 lh-1-3">
                                                {value?.bank_name || ''}
                                            </span>
                                            <span>
                                                <Tag color="gray">
                                                    {value?.currency
                                                        ? value.currency.toUpperCase()
                                                        : ''}
                                                </Tag>
                                            </span>
                                            {value?.default_for_currency && (
                                                <span>
                                                    <Tooltip
                                                        title={
                                                            <div>
                                                                This is default account for payments
                                                            </div>
                                                        }>
                                                        <Tag color="blue">Default</Tag>
                                                    </Tooltip>
                                                </span>
                                            )}
                                        </div>
                                        <div>
                                            <span style={{ fontFamily: 'monospace' }}>
                                                <span>{value?.routing_number}</span> &middot;
                                                &middot; &middot; <span>{value?.last4}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            }
                            key="bank_account">
                            <div style={{ paddingLeft: 30 }}>
                                <div>
                                    <div className="payment-stats-data-row">
                                        <div className="payment-stats-data-header">
                                            <span className="text-muted fs-14">type</span>
                                        </div>
                                        <div className="payment-stats-data-info">
                                            <span className="text-gray fs-14">
                                                {value?.object
                                                    ? stringSplitAndUppercase(value?.object)
                                                    : ''}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="payment-stats-data-row">
                                        <div className="payment-stats-data-header">
                                            <span className="text-muted fs-14">Created</span>
                                        </div>
                                        <div className="payment-stats-data-info">
                                            <span className="text-gray fs-14">
                                                {value?.created || 'N/A'}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="payment-stats-data-row">
                                        <div className="payment-stats-data-header">
                                            <span className="text-muted fs-14">Origin</span>
                                        </div>
                                        <div className="payment-stats-data-info">
                                            <span className="text-gray fs-14">
                                                {value?.country}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="payment-stats-data-row">
                                        <div className="payment-stats-data-header">
                                            <span className="text-muted fs-14">Fingerprint</span>
                                        </div>
                                        <div className="payment-stats-data-info">
                                            <span className="text-gray fs-14">
                                                {value?.fingerprint}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="payment-stats-data-row">
                                        <div className="payment-stats-data-header">
                                            <span className="text-muted fs-14">ID</span>
                                        </div>
                                        <div className="payment-stats-data-info">
                                            <span className="text-gray fs-14">{value?.id}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Panel>
                    </Collapse>
                ))}
        </div>
    );
}

BankDetailSection.propTypes = {
    stripe_account_detail: PropTypes.object,
    my_profile: PropTypes.object,
    redirectToStripeDashboard: PropTypes.func,
    data: PropTypes.object
};

import React from 'react';
import {
    ExclusionIcon,
    GpsIcon,
    MultiDirectionIcon,
    ShieldIcon,
    TagIcon,
    ThumbsUpIcon,
    TicketIcon,
    TopSightIcon
} from './panel/icons';
import { Col, Row } from 'react-bootstrap';
import DetailedPanel from './panel';
import CollapseMoreLess from '../../collapse-more-less';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import AmountPanel from './amount-panel';
import DurationPanel from './duration-panel';
import LanguagePanel from './language-panel';
import GuidePanel from './guide-panel';
import ExtraInfoPanel from './extra-info-panel';
import MealsDrinksPanel from './meals-drinks-panel';
import PhotosVideosPanel from './photos-videos-panel';
import InclusionPanel from './inclusion-panel';

const ProductSummaryChild = (props) => {
    const { setViewGalleryItemModal } = props;
    return (
        <div className="ff-special">
            {/* amount you will get (pp) Panel */}
            <AmountPanel />
            {/* Main Destination Panel */}
            <DetailedPanel panelIcon={<GpsIcon />} panelTitle="Main Destination" isEditable={true}>
                <div className="fs-18 lh-1-7">
                    Burj Park by Emaar, 1 Sheikh Mohammed bin Rashid Blvd - Downtown Dubai - Dubai -
                    United Arab Emirates
                </div>
            </DetailedPanel>
            {/* Duration Panel */}
            <DurationPanel />

            {/* Photos & Videos Panel */}
            <PhotosVideosPanel setViewGalleryItemModal={setViewGalleryItemModal} />

            {/* Admission Price included Panel */}
            <DetailedPanel
                panelIcon={<TagIcon />}
                panelTitle="Admission Price included"
                isEditable={true}>
                Yes
            </DetailedPanel>

            {/* Meals and Drinks Panel */}
            <MealsDrinksPanel />

            {/* Language Offered Panel */}
            <LanguagePanel />

            {/* Inclusions Panel */}
            <InclusionPanel />

            {/* Exclusions Panel */}
            <DetailedPanel panelIcon={<ExclusionIcon />} panelTitle="Exclusions" isEditable={true}>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6} md={4}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Use of Equipment</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6} md={8}>
                        <span className="fs-18 lh-1-7">
                            Use of Tickkle, Use of Snorkelling equipment.
                        </span>
                    </Col>
                </Row>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6} md={4}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Fee</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6} md={8}>
                        <span className="fs-18 lh-1-7">Gratitude</span>
                    </Col>
                </Row>
            </DetailedPanel>

            {/* Guide will meet you at Panel */}
            <GuidePanel />

            {/* Adventures Panel */}
            <DetailedPanel
                panelIcon={<MultiDirectionIcon />}
                panelTitle="Adventures"
                isEditable={true}>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Adventures</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <span className="fs-18 lh-1-7">
                            Horseback Safari, Family Safari, Luxury Safari, Photography Safari,
                            Gorillas & Primates
                        </span>
                    </Col>
                </Row>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">River Cruises</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <span className="fs-18 lh-1-7">Dinner, Party</span>
                    </Col>
                </Row>
                <CollapseMoreLess>
                    <Row className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    Other Adventures
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <span className="fs-18 lh-1-7">Other Adventures</span>
                        </Col>
                    </Row>
                </CollapseMoreLess>
            </DetailedPanel>

            {/* Top Sights Panel */}
            <DetailedPanel panelIcon={<TopSightIcon />} panelTitle="Top Sights" isEditable={true}>
                {`Art and culture, Art Galleries, Architecture, Beaches, Casinos, Markets,
                        Wildlife`}
            </DetailedPanel>

            {/* Extra info Panel */}
            <ExtraInfoPanel />

            {/* Booking & Tickets Panel */}
            <DetailedPanel
                panelIcon={<TicketIcon />}
                panelTitle="Booking & Tickets"
                isEditable={true}>
                <h6 className="fa-18 fw-500 text-uppercase mb-2">Cut-off time</h6>
                <p className="fs-18 lh-1-7">10 Days before activity Starts</p>
                <Link to="#" className="fs-16 fw-500 text-primary">
                    <i>Cancellation Policy</i>
                </Link>
                <hr />
                <h6 className="fa-18 fw-500 text-uppercase mb-2">Standard </h6>
                <p className="fs-18 lh-1-7">
                    To receive a full refund, travelers may cancel up to 24 hours before the
                    experience start date in the local timezone. No refunds will be given after that
                    time period.
                </p>
            </DetailedPanel>

            {/* Covid-19 safety updates Panel */}
            <DetailedPanel
                panelIcon={<ShieldIcon />}
                panelTitle="Covid-19 safety updates"
                isEditable={true}>
                <h6 className="fs-18 fw-500 text-uppercase">Safety Measures</h6>
                <ul>
                    <li className="fs-18 mb-3">Face masks required for travelers in public area</li>
                    <li className="fs-18 mb-3">Face masks required for guides in public areas</li>
                    <CollapseMoreLess>
                        <li className="fs-18 mb-3">Face masks provided for travelers</li>
                        <li className="fs-18 mb-3">Hand sanitizer available to travelers</li>
                        <li className="fs-18 mb-3">
                            Hand sanitizer available to travelers and staff
                        </li>
                    </CollapseMoreLess>
                </ul>
            </DetailedPanel>

            {/* Rating Panel */}
            <DetailedPanel panelIcon={<ThumbsUpIcon />} panelTitle="Rating" isEditable={true}>
                <div className="border-bottom border-primary-shaded pb-2 mb-2">
                    <div className="d-flex flex-nowrap align-items-center">
                        <span className="mr-2">
                            <img
                                width={170}
                                src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                                alt=""
                            />
                        </span>
                        <span className="fs-28 text-primary">3.9</span>
                    </div>
                    <div className="fs-18 text-primary fw-500">
                        <i>38 Google Reviews</i>
                    </div>
                </div>
                <div className="border-bottom border-primary-shaded pb-2 mb-2">
                    <div className="d-flex flex-nowrap align-items-center">
                        <span className="mr-2">
                            <img
                                width={170}
                                src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                                alt=""
                            />
                        </span>
                        <span className="fs-28 text-primary">3.9</span>
                    </div>
                    <div className="fs-18 text-primary fw-500">
                        <i>23 Facebook Reviews</i>
                    </div>
                </div>
                <div className="border-bottom border-primary-shaded pb-2 mb-2">
                    <div className="d-flex flex-nowrap align-items-center">
                        <span className="mr-2">
                            <img
                                width={170}
                                src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                                alt=""
                            />
                        </span>
                        <span className="fs-28 text-primary">3.9</span>
                    </div>
                    <div className="fs-18 text-primary fw-500">
                        <i>13 Trip Advisor Reviews</i>
                    </div>
                </div>
            </DetailedPanel>
        </div>
    );
};
ProductSummaryChild.propTypes = {
    setViewGalleryItemModal: PropTypes.func
};
export default ProductSummaryChild;

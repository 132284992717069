import burjKhalifa from 'assets/images/burj-al-khalifa.jpg';
import dubaiCreeks from 'assets/images/dubai-creeks.jpg';
import dubaiGoldSouk from 'assets/images/dubai-gold-souk.jpg';
import heritageMarket from 'assets/images/heritage-market.jpg';
let eventGuid = 0;

let todayStr = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today
var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1))
    .toISOString()
    .replace(/T.*$/, '');
var dayAfterTomorrow = new Date(new Date().setDate(new Date().getDate() + 2))
    .toISOString()
    .replace(/T.*$/, '');

export const INITIAL_EVENTS = [
    {
        id: createEventId(),

        title: 'All-day event',

        imageurl:
            'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: todayStr
    },

    {
        id: createEventId(),

        title: 'Resting time',

        event_type: 'resting_time',
        event_duration_type: 'start',

        // imageurl:'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: todayStr + 'T00:00:00',
        end: todayStr + 'T07:00:00'
    },

    {
        id: createEventId(),

        title: 'Dubai Creeks',

        event_type: 'main_event',
        bookingStatus: 'Booked',

        is_main_product: true,
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],

        imageurl: dubaiCreeks,

        start: todayStr + 'T08:00:00',

        end: todayStr + 'T10:00:00'
    },

    {
        id: createEventId(),

        title: 'Burj al khalifa',

        event_type: 'main_event',
        bookingStatus: 'Booked',
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],

        imageurl: burjKhalifa,

        start: todayStr + 'T10:30:00',

        end: todayStr + 'T12:30:00'
    },
    {
        id: createEventId(),

        title: 'Dubai Gold Souk',

        event_type: 'main_event',
        bookingStatus: 'Booked',

        imageGallery: [dubaiGoldSouk, dubaiGoldSouk, dubaiGoldSouk],

        start: todayStr + 'T13:30:00',

        end: todayStr + 'T15:00:00'
    },
    {
        id: createEventId(),

        title: 'Emirate Towers',

        event_type: 'main_event',
        bookingStatus: 'Booked',

        imageGallery: [burjKhalifa, burjKhalifa, burjKhalifa],

        start: todayStr + 'T15:30:00',

        end: todayStr + 'T17:00:00'
    },
    {
        id: createEventId(),

        title: 'Heritage Market',

        event_type: 'main_event',
        bookingStatus: 'Booked',
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],
        hasMoreProducts: true,

        imageGallery: [heritageMarket, heritageMarket, heritageMarket],

        start: todayStr + 'T17:30:00',

        end: todayStr + 'T20:00:00'
    },

    {
        id: createEventId(),

        title: 'Resting time',

        event_type: 'resting_time',
        event_duration_type: 'end',

        // imageurl:'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: todayStr + 'T22:00:00',

        end: todayStr + 'T24:00:00'
    },
    {
        id: createEventId(),

        title: 'All-day event',

        imageurl:
            'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: tomorrow
    },
    {
        id: createEventId(),

        title: 'Resting time',

        event_type: 'resting_time',
        event_duration_type: 'start',

        // imageurl:'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: tomorrow + 'T00:00:00',
        end: tomorrow + 'T07:00:00'
    },
    {
        id: createEventId(),

        title: 'Emirates Tower',

        event_type: 'main_event',
        bookingStatus: 'Booked',
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],

        imageurl: burjKhalifa,

        start: tomorrow + 'T13:30:00',

        end: tomorrow + 'T14:00:00'
    },
    {
        id: createEventId(),

        title: 'Safari Tour',

        event_type: 'main_event',
        bookingStatus: 'Booked',
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],
        hasMoreProducts: true,

        imageGallery: [burjKhalifa, burjKhalifa, burjKhalifa],

        start: tomorrow + 'T15:12:00',

        end: tomorrow + 'T17:00:00'
    },

    {
        id: createEventId(),

        title: 'Resting time',

        event_type: 'resting_time',
        event_duration_type: 'end',

        // imageurl:'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: tomorrow + 'T22:00:00',

        end: tomorrow + 'T24:00:00'
    },
    {
        id: createEventId(),

        title: 'All-day event',

        imageurl:
            'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: dayAfterTomorrow
    },

    {
        id: createEventId(),

        title: 'Resting time',

        event_type: 'resting_time',
        event_duration_type: 'start',

        // imageurl:'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: dayAfterTomorrow + 'T00:00:00',
        end: dayAfterTomorrow + 'T07:00:00'
    },

    {
        id: createEventId(),

        title: 'Burj AL Khalifa',

        event_type: 'main_event',
        bookingStatus: 'Booked',

        is_main_product: true,
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],

        imageGallery: [burjKhalifa, burjKhalifa, burjKhalifa],

        start: dayAfterTomorrow + 'T08:00:00',

        end: dayAfterTomorrow + 'T10:00:00'
    },

    {
        id: createEventId(),

        title: 'Emirate Towers',

        event_type: 'main_event',
        bookingStatus: 'Booked',
        is_main_product: true,
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],

        imageGallery: [burjKhalifa, burjKhalifa, burjKhalifa],

        start: dayAfterTomorrow + 'T10:30:00',

        end: dayAfterTomorrow + 'T13:00:00'
    },
    {
        id: createEventId(),

        title: 'Heritage Market',

        event_type: 'main_event',
        bookingStatus: 'Booked',
        travellers: [
            { id: 1, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 2, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 3, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` },
            { id: 4, name: 'Buki', img: `https://randomuser.me/api/portraits/women/${30}.jpg` }
        ],

        imageGallery: [burjKhalifa, burjKhalifa, burjKhalifa],

        start: dayAfterTomorrow + 'T13:20:00',

        end: dayAfterTomorrow + 'T15:00:00'
    },

    {
        id: createEventId(),

        title: 'Resting time',

        event_type: 'resting_time',
        event_duration_type: 'end',

        // imageurl:'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',

        start: dayAfterTomorrow + 'T22:00:00',

        end: dayAfterTomorrow + 'T24:00:00'
    }
];

export function createEventId() {
    return String(eventGuid++);
}

import React, { useEffect } from 'react';
import VendorDashboard from '../vendor-dashboard';
import ImportProduct from '../importproduct';
import InputAccommodation from '../Inputaccommodation';
import TextAreaLayout from '../../../atoms/textarea-layout';

export default function Covid19() {
    //Scroll to the top
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //Mock data
    const data = [
        'Face masks required for travelers in public areas',
        'Face masks required for guides in public areas',
        'Face masks provided for travelers',
        'Hand sanitizer available to travelers and staff',
        'Social distancing enforced throughout experience',
        'Regularly sanitized high-traffic areas',
        'Gear/equipment sanitized between use',
        'Transportation vehicles regularly sanitized',
        'Guides required to regularly wash hands',
        'Regular temperature checks for staff',
        'Temperature checks for travelers upon arrival',
        'Paid stay-at-home policy for staff with symptoms',
        'Contactless payments for gratuities and add-ons'
    ];
    return (
        <VendorDashboard>
            <div className="background-sz">
                <ImportProduct
                    create="COVID-19 safety updates"
                    import="I will import COVID-19 information from my other activity "
                />
                <div style={{ paddingTop: 30 }}>
                    <p>Tell travelers about your COVID-19 safety measures</p>
                    <p style={{ fontWeight: 600 }}>Select all that apply</p>
                    {data.map((items, i) => (
                        <InputAccommodation other={items} key={i} />
                    ))}
                    <div style={{ paddingTop: 30 }}>
                        <p style={{ fontWeight: 600 }}>Additional safety measures (optional)</p>
                        <p>
                            Use the text box below to include any additional safety measure you
                            think travelers should know about.
                        </p>
                        <TextAreaLayout isSelected={false} height="125px" />
                    </div>
                    <hr style={{ marginTop: '40px' }} />
                    <div className="d-flex" style={{ paddingTop: '20px' }}>
                        <button className="button-save">Done</button>
                        <button className="button-outline">Cancel</button>
                    </div>
                </div>
            </div>
        </VendorDashboard>
    );
}

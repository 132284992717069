import { callApi } from 'utils/apiCaller';
import { Notification } from 'components/atoms/notification';
import {
    ALL_DAY_PRODUCT_CATEGORIES,
    WITHIN_DAY_PRODUCT_CATEGORIES,
    GET_EXISTING_MAIN_PRODUCTS,
    GET_PRODUCT,
    UPDATE_PRODUCTS_PERSONAL_INFO,
    UPDATE_PRODUCTS_PERSONAL_INFO_ERROR,
    PRODUCT_DETAIL,
    ADD_MORE_PRODUCTS,
    PACKAGE_DETAIL,
    RESET_PRODUCT_REDUCER,
    SET_UPDATE_PRODUCT_FIELDS,
    GET_ALL_ARCHIVED_PRODUCT,
    SET_CURRENT_PRODUCT,
    UPDATE_PRODUCT_MEALS_DRINKS,
    UPDATE_PRODUCT_LANGUAGE_OFFERS,
    UPDATE_PRODUCT_INCLUSION_EXCLUSION,
    UPDATE_PRODUCT_COVID,
    UPDATE_PRODUCT_CALENDAR_SEASON,
    UPDATE_PRODUCT_ADVENTURES,
    UPDATE_PRODUCT_SIGHTS,
    UPDATE_PRODUCT_TRAVELLER_INFORMATION,
    SELECTED_EXISTING_PRODUCT,
    UPDATE_PRODUCT_BOOKINGS_TICKETS,
    UPDATE_PRODUCT_PRICING,
    UPDATE_PRODUCT_HOUSE_RULES,
    UPDATE_PRODUCT_ROOMS,
    UPDATE_PRODUCT_FACILITIES,
    UPDATE_PRODUCT_BREAKFAST,
    ATTACHED_DUPLICATED_PRODUCT,
    CURRENT_PRODUCT_FLOW,
    UPDATE_PRODUCT_OCCASSIONS
} from 'store/types';
import { queryBuilder } from 'utils';
import { loading_, getCalendarsById_, getAllCalendarProducts } from 'store/actions';

export const setCurrentProduct_ = (payload) => ({
    type: SET_CURRENT_PRODUCT,
    payload
});
export const setCurrentProduct = (id) => (dispatch) => {
    // dispatch(setCurrentProduct_(null));
    return callApi(`/products/${id}`, 'GET')
        .then((response) => {
            const status = response?.status;
            if (status === 200) {
                return dispatch(setCurrentProduct_(response?.data?.data));
            } else elseNotifyError(response, status);
        })
        .catch((e) => console.log(e));
};

export const setUpdateProductFields_ = (payload) => {
    return {
        type: SET_UPDATE_PRODUCT_FIELDS,
        payload
    };
};

export function elseNotifyError(response, status) {
    if (response?.data && Array.isArray(response?.data?.errors) && response?.data?.errors.length) {
        return Notification('error', response.data.errors[0].message, status);
    } else {
        return Notification('error', response?.data?.message, status);
    }
}

export const getAllProducts_ = (payload) => ({ type: GET_PRODUCT, payload });
export const getAllProducts = () => {
    return (dispatch) => {
        callApi('/all-products', 'GET').then((response) => {
            const status = response?.status;
            if (status === 200) {
                return dispatch(getAllProducts_(response?.data));
            } else elseNotifyError(response, status);
        });
    };
};

export const fetchAllDayProductCategories_ = (payload) => ({
    type: ALL_DAY_PRODUCT_CATEGORIES,
    payload
});
export const fetchAllDayProductCategories = () => {
    return (dispatch) => {
        callApi('/allday-product-categories ', 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                dispatch(fetchAllDayProductCategories_(response));
            } else elseNotifyError(response, status);
        });
    };
};

export const fetchWithInDayProductCategories_ = (payload) => ({
    type: WITHIN_DAY_PRODUCT_CATEGORIES,
    payload
});
export const fetchWithInDayProductCategories = () => {
    return (dispatch) => {
        callApi('/withinday-product-categories', 'GET').then((response) => {
            const status = response?.status;

            if (status === 200) {
                dispatch(fetchWithInDayProductCategories_(response));
            } else elseNotifyError(response, status);
        });
    };
};

export const getExistingMainProducts_ = (payload) => ({
    type: GET_EXISTING_MAIN_PRODUCTS,
    payload
});
export const getExistingMainProducts = ({
    searchQuery = '',
    productCategoryId = '',
    // productSubCategoryId = '',
    page = '/?page=1',
    paginating = false
}) => {
    return (dispatch, getState) => {
        dispatch(loading_(true));
        callApi(
            `/products/calendar-products${page}&perPage=10&productCategory=${productCategoryId}&searchQuery=${searchQuery}`,
            'GET'
        )
            .then((response) => {
                const status = response?.status;

                if (status === 200) {
                    const prevState = getState().product.existing_main_products;
                    if (
                        prevState &&
                        Array.isArray(prevState.data) &&
                        response.data?.data &&
                        paginating
                    ) {
                        // if (response.data.data.meta?.current_page > prevState.meta?.current_page)
                        dispatch(
                            getExistingMainProducts_({
                                data: prevState.data.concat(response.data.data),
                                meta: { current_page: page }
                            })
                        );
                    } else dispatch(getExistingMainProducts_({ data: response.data.data }));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const updateProductStepTwoInfo_ = (payload) => ({
    type: UPDATE_PRODUCTS_PERSONAL_INFO,
    payload
});
export const updateProductStepTwoInfoError_ = (payload) => ({
    type: UPDATE_PRODUCTS_PERSONAL_INFO_ERROR,
    payload
});
export const updateProductStepTwoInfo = (data, id) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/update-product-information/${id}`, 'PUT', data)
            .then((response) => {
                const status = response?.status;
                if (status === 200) {
                    Notification('success', response?.data?.message || response?.message, status);
                    return dispatch(
                        updateProductStepTwoInfo_({ data: response.data, success: true })
                    );
                } else {
                    dispatch(updateProductStepTwoInfo_({ data: data, error: true }));
                    return elseNotifyError(response, status);
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const createMainProduct = (data) => {
    let url = data.product_type === 'package' ? '/create-package-product' : '/create-main-product';
    return (dispatch) => {
        return callApi(url, 'POST', data).then((response) => {
            const { status } = response;
            const successMsg =
                response?.data?.message || response?.message || 'Product created successfully.';
            if (status === 200) {
                Notification('success', successMsg, status);
                return dispatch(
                    selectedExistingProduct({
                        id: response.data?.product?.id,
                        productType: 'main_product',
                        notExisted: true
                    })
                );
            } else elseNotifyError(response, status);
        });
    };
};

export const updatePackageProduct = ({ data, id }) => {
    return () => {
        return callApi(`/update-package-product/${id}`, 'PUT', data).then((response) => {
            const status = response?.status;
            if (status === 200) {
                response.data.product['product-flag'] = 'package-leading-product';
                return true;
            } else elseNotifyError(response, status);
        });
    };
};

export const fetchProductDetail_ = (payload) => ({ type: PRODUCT_DETAIL, payload });
export const fetchProductDetail = (id) => {
    return async (dispatch) => {
        return callApi(`/products/${id}`, 'GET').then((response) => {
            const { status } = response;
            if (status === 200) return dispatch(fetchProductDetail_(response.data.data));
            else elseNotifyError(response, status);
        });
    };
};

export const addMoreProducts_ = (payload) => ({ type: ADD_MORE_PRODUCTS, payload });

export const deleteAddOnProduct = (product) => {
    return (dispatch) => {
        return callApi(`/products/${product.id}`, 'DELETE').then((response) => {
            const { status } = response;
            if (status === 200) {
                if (product.productType && product.productType === 'package') {
                    return true;
                } else {
                    dispatch(setCurrentProduct_(null));
                    return dispatch(updateProductsPersonalInfo_(null));
                }
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductMaxAvailabilityAccomodation = (data, id) => {
    return () => {
        return callApi(`/update-product-max-availability-accomodation/${id}`, 'PUT', data).then(
            (response) => {
                const status = response?.status;
                if (status === 200) {
                    Notification('success', response?.data?.message || response?.message, status);
                    return true;
                } else {
                    return elseNotifyError(response, status);
                }
            }
        );
    };
};

export const packageDetail_ = (payload) => ({ type: PACKAGE_DETAIL, payload });

export const resetProductReducer = (payload = {}) => ({
    type: RESET_PRODUCT_REDUCER,
    payload
});

export const createPackage = (data) => {
    return (dispatch) => {
        return callApi('/create-package', 'POST', data).then((response) => {
            const status = response?.status;
            const successMsg =
                response?.data?.message || response?.message || 'Package created successfully.';

            if (status === 200) {
                Notification('success', successMsg, status);
                return dispatch(packageDetail_(response.data.package));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const updatePackageCovid = (params) => {
    const { data, id } = params;
    return () => {
        return callApi(`/update-package-covid19/${id}`, 'PUT', data).then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const updatePackageMaxAvailabilityAccomodation = (data, id) => {
    return () => {
        return callApi(
            `/update-package-book-activity-without-accomodation/${id}`,
            'PUT',
            data
        ).then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const updatePackageBookingsTickets = (params) => {
    const { data, id } = params;
    return () => {
        return callApi(`/update-package-booking-ticket-details/${id}`, 'PUT', data).then(
            (response) => {
                const status = response?.status;
                if (status === 200) {
                    Notification('success', response?.data?.message || response?.message, status);
                    return true;
                } else {
                    return elseNotifyError(response, status);
                }
            }
        );
    };
};

export const updatePackagePricing = (params) => async () => {
    try {
        const { newPricing, product, packageId } = params;
        let prices = [];

        if (product.pricings) {
            if (newPricing.id) {
                prices = product.pricings.map((item) =>
                    item.id === newPricing.id ? newPricing : item
                );
            } else {
                prices = [...product.pricings, newPricing];
            }
        } else {
            prices = [newPricing];
        }

        let response = await callApi(`/update-package-pricing/${packageId}`, 'PUT', { prices });
        const status = response?.status;
        if (status === 200) {
            return true;
        } else {
            return elseNotifyError(response, status);
        }
    } catch (error) {
        console.log(error);
    }
};

export const getAllArchivedProduct_ = (payload) => ({ type: GET_ALL_ARCHIVED_PRODUCT, payload });
export const getAllArchivedProduct = (query) => {
    return (dispatch) => {
        let url = `/availability-archived`;
        if (query) {
            query = queryBuilder(query);
            url = `/availability-archived${query}`;
        }

        dispatch(loading_(true));
        callApi(url, 'GET')
            .then((response) => {
                const { status } = response;

                if (status === 200) {
                    dispatch(getAllArchivedProduct_(response.data));
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response.data.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};
export const updateCalendarSelectedAllDayProduct = (data) => {
    return (dispatch) => {
        return callApi(`/calendar-day/selected-product-all-day`, 'PUT', data).then((response) => {
            const status = response?.status;
            if (status === 200) {
                // Notification('success', response?.data?.message || response?.message, status);
                dispatch(getCalendarsById_(response.data));
                return true;
            }
            // else {
            //      elseNotifyError(response, status);
            // }
        });
    };
};
export const deleteProductHouseRules = (id) => {
    return () => {
        return callApi(`/delete-product-house-rules/${id}`, 'DELETE').then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductBreakfastDetail = (id) => {
    return () => {
        return callApi(`/delete-product-breakfast-details/${id}`, 'DELETE').then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductFacilities = (id) => {
    return () => {
        return callApi(`/delete-product-facilities/${id}`, 'DELETE').then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductRooms = (id) => {
    return () => {
        return callApi(`/delete-product-room/${id}`, 'DELETE').then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductBookingAndTicket = (id) => {
    return () => {
        return callApi(`/delete-product-booking-ticket-details/${id}`, 'DELETE').then(
            (response) => {
                const { status } = response;
                if (status === 200) {
                    Notification('success', response?.data?.message || response?.message, status);
                    return true;
                } else return elseNotifyError(response, status);
            }
        );
    };
};

export const deleteCovidUpdates = (id) => {
    return () => {
        return callApi(`/delete-product-covid19-updates/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deletePackageCovid19 = (id) => {
    return () => {
        return callApi(`/package-covid19/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deletePackageBookingAndTickets = (id) => {
    return () => {
        return callApi(`/package-booking-ticket-details/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductLanguageOffers = (id) => {
    return () => {
        return callApi(`/delete-product-language-offers/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductMealsDrinks = ({ id, data }) => {
    return () => {
        return callApi(`/delete-product-meals-drinks/${id}`, 'DELETE', data).then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductAdventures = (id) => {
    return () => {
        return callApi(`/delete-product-adventures/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductOccassions = (id) => {
    return () => {
        return callApi(`/delete-product-occassion/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductCalendarSeason = (id) => {
    return () => {
        return callApi(`/delete-product-calendar-season/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductSight = (id) => {
    return () => {
        return callApi(`/delete-product-sight/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductTravellerInformation = (id) => {
    return () => {
        return callApi(`/delete-product-traveller-information/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};

export const deleteProductInclusionExclusion = ({ id, type }) => {
    let url = type === 'exclusion' ? 'delete-product-exclusion' : 'delete-product-inclusion';
    return () => {
        return callApi(`/${url}/${id}`, 'DELETE').then((response) => {
            const status = response?.status;
            if (status === 200) {
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else return elseNotifyError(response, status);
        });
    };
};
export function unlinkProductOrPackage(data) {
    return () => {
        return callApi(`${data?.url ? data.url : '/unlink-product'}`, 'POST', data).then(
            (response) => {
                const { status } = response;
                if (status === 200) {
                    return true;
                } else elseNotifyError(response, status);
            }
        );
    };
}

export function unlinkPackageProduct(data) {
    return () => {
        return callApi(`/packages/products`, 'DELETE', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                return true;
            } else elseNotifyError(response, status);
        });
    };
}

export function switchProductType(data) {
    return () => {
        return callApi(`/switch-product-type`, 'POST', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                return true;
            } else elseNotifyError(response, status);
        });
    };
}

export function assignExistingProduct(data) {
    return () => {
        return callApi(`/assign-existing-product`, 'POST', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                return true;
            } else elseNotifyError(response, status);
        });
    };
}

export function assignExistingPackage(data) {
    return () => {
        return callApi(`/assign-existing-package`, 'POST', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                return true;
            } else elseNotifyError(response, status);
        });
    };
}

export function assignExistingProductToPackage(data) {
    return () => {
        return callApi(`/packages/products/existing`, 'POST', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                return true;
            } else elseNotifyError(response, status);
        });
    };
}

export const createAddonOrPackageProduct = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`${data?.url ? data.url : '/create-addon-product'}`, 'POST', data)
            .then((response) => {
                const { status } = response;
                const successMsg =
                    response?.data?.message || response?.message || 'Product created successfully.';

                if (status === 200) {
                    Notification('success', successMsg, status);
                    return dispatch(
                        setCurrentProduct(
                            response?.data?.product?.id || response?.data?.package?.id
                        )
                    );
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const updateAddonProduct = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/update-addon-product/${id}`, 'PUT', data)
            .then((response) => {
                const { status } = response;
                const successMsg =
                    response?.data?.message || response?.message || 'Product updated successfully.';

                if (status === 200) {
                    Notification('success', successMsg, status);
                    dispatch(setCurrentProduct(id));
                    return dispatch(loading_(false));
                } else elseNotifyError(response, status);
            })
            .finally(() => loading_(false));
    };
};
export const updateProductMealsDrinks_ = (payload) => ({
    type: UPDATE_PRODUCT_MEALS_DRINKS,
    payload
});

export const updateProductMealsDrinks = (data, id) => {
    return (dispatch) => {
        return callApi(`/update-product-meals-drinks/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductMealsDrinks_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductLanguageOffers_ = (payload) => ({
    type: UPDATE_PRODUCT_LANGUAGE_OFFERS,
    payload
});

export const updateProductLanguageOffers = (data, id) => {
    return (dispatch) => {
        return callApi(`/update-product-language-offers/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductLanguageOffers_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductInclusionExclusion_ = (payload) => ({
    type: UPDATE_PRODUCT_INCLUSION_EXCLUSION,
    payload
});

export const updateProductInclusionExclusion = (params) => {
    const { data, id, type } = params;
    return (dispatch) => {
        return callApi(`/update-product-${type}/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductInclusionExclusion_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductCovid_ = (payload) => ({
    type: UPDATE_PRODUCT_COVID,
    payload
});

export const updateProductCovid = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-covid19-updates/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductCovid_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductCalendarSeason_ = (payload) => ({
    type: UPDATE_PRODUCT_CALENDAR_SEASON,
    payload
});

export const updateProductCalendarSeason = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-product-calendar-season/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductCalendarSeason_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductAdventures_ = (payload) => ({
    type: UPDATE_PRODUCT_ADVENTURES,
    payload
});

export const updateProductAdventures = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-adventures/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductAdventures_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductOccassions_ = (payload) => ({
    type: UPDATE_PRODUCT_OCCASSIONS,
    payload
});

export const updateProductOccassions = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-occassions/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductOccassions_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductSights_ = (payload) => ({
    type: UPDATE_PRODUCT_SIGHTS,
    payload
});

export const updateProductSights = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-sights/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductSights_(response.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductTravellerInformation_ = (payload) => ({
    type: UPDATE_PRODUCT_TRAVELLER_INFORMATION,
    payload
});

export const updateProductTravellerInformation = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-traveller-information/${id}`, 'PUT', data).then(
            (response) => {
                const { status } = response;
                if (status === 200) {
                    Notification('success', response?.data?.message, status);
                    return dispatch(updateProductTravellerInformation_(response.data?.product));
                } else elseNotifyError(response, status);
            }
        );
    };
};

export const selectedExistingProduct_ = (payload) => ({ type: SELECTED_EXISTING_PRODUCT, payload });
export const selectedExistingProduct = (data) => {
    return async (dispatch) => {
        return callApi(`/products/${data.id}`, 'GET').then((response) => {
            const { status } = response;
            if (status === 200) {
                if (data.productType === 'main_product')
                    dispatch(
                        setCurrentProduct_({ ...response.data?.data, productType: 'main_product' })
                    );
                else dispatch(setCurrentProduct_(response.data?.data));
                //When required to set the existing not to true
                if (data?.notExisted)
                    setTimeout(() => dispatch(selectedExistingProduct_(false)), 1000);
                return dispatch(selectedExistingProduct_(true));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductsCalendarDay = (data, id, silently = false) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/update-products-calendar-day/${id}`, 'PUT', data)
            .then((response) => {
                const { status } = response;
                const successMsg =
                    response?.data?.message ||
                    response?.message ||
                    'Calendar updated successfully.';

                if (status === 200) {
                    if (!silently) Notification('success', successMsg, status);
                    return dispatch(setCurrentProduct_(response?.data?.product));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const updateProductsCustomerBook = (data, id) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/update-products-customer-book-period/${id}`, 'PUT', data)
            .then((response) => {
                const { status } = response;
                const successMsg = response?.data?.message || response?.message;

                if (status === 200) {
                    Notification('success', successMsg, status);
                    return dispatch(setCurrentProduct_(response?.data?.product));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const updateProductBookingsTickets_ = (payload) => ({
    type: UPDATE_PRODUCT_BOOKINGS_TICKETS,
    payload
});

export const updateProductBookingsTickets = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-booking-ticket-details/${id}`, 'PUT', data).then(
            (response) => {
                const { status } = response;
                if (status === 200) {
                    Notification('success', response.data.message, status);
                    return dispatch(updateProductBookingsTickets_(response.data?.product));
                } else elseNotifyError(response, status);
            }
        );
    };
};

export const updateProductPricing_ = (payload) => ({
    type: UPDATE_PRODUCT_PRICING,
    payload
});

export const updateProductPricing = (params) => async (dispatch) => {
    try {
        const { newPricing, product, showNotificaiton = true } = params;
        let prices = [];
        if (product.pricings) {
            if (newPricing.id) {
                prices = product.pricings.map((item) =>
                    item.id === newPricing.id ? newPricing : item
                );
            } else {
                prices = [...product.pricings, newPricing];
            }
        } else {
            prices = [newPricing];
        }

        let response = await callApi(`/update-products-pricings/${product.id}`, 'PUT', { prices });
        const status = response?.status;
        if (status === 200) {
            if (showNotificaiton) Notification('success', response.data.message, status);
            return dispatch(updateProductPricing_(response.data?.product));
        } else elseNotifyError(response, status);
    } catch (error) {
        console.log(error);
    }
};

export const deleteProductPricing = (params) => async (dispatch) => {
    try {
        const { id, product } = params;
        let prices = [];

        if (product.pricings) {
            prices = product.pricings.filter((item) => item.id !== id);
        } else {
            return;
        }

        let response = await callApi(`/update-products-pricings/${product.id}`, 'PUT', { prices });
        const status = response?.status;
        if (status === 200) {
            return dispatch(updateProductPricing_(response.data?.product));
        } else elseNotifyError(response, status);
    } catch (error) {
        console.log(error);
    }
};

export const deletePackagePricing = (params) => async () => {
    try {
        const { id, product } = params;
        let prices = [];

        if (product.pricings) {
            prices = product.pricings.filter((item) => item.id !== id);
        } else {
            return;
        }

        let response = await callApi(`/update-package-pricing/${product.id}`, 'PUT', { prices });
        const status = response?.status;
        if (status === 200) {
            return true;
        } else elseNotifyError(response, status);
    } catch (error) {
        console.log(error);
    }
};

export const updateProductsPersonalInfo_ = (payload) => ({
    type: UPDATE_PRODUCTS_PERSONAL_INFO,
    payload
});
export const updateProductsPersonalInfo = (data, id) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/update-products-personal-info/${id}`, 'PUT', data)
            .then((response) => {
                const { status } = response;
                const successMsg =
                    response?.data?.message ||
                    response?.message ||
                    'Calendar created successfully.';

                if (status === 200) {
                    Notification('success', successMsg, status);
                    dispatch(setCurrentProduct(id));
                    return dispatch(updateProductsPersonalInfo_(response.data?.product));
                } else {
                    elseNotifyError(response, status);
                    throw new Error();
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const updateProductHouseRules_ = (payload) => ({
    type: UPDATE_PRODUCT_HOUSE_RULES,
    payload
});

export const updateProductHouseRules = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-house-rules/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductHouseRules_(response?.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductRooms_ = (payload) => ({
    type: UPDATE_PRODUCT_ROOMS,
    payload
});

export const updateProductRooms = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-rooms/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductRooms_(response?.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductFacilities_ = (payload) => ({
    type: UPDATE_PRODUCT_FACILITIES,
    payload
});
export const updateProductFacilities = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-facilities/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductFacilities_(response?.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const updateProductBreakfast_ = (payload) => ({
    type: UPDATE_PRODUCT_BREAKFAST,
    payload
});

export const updateProductBreakfast = (params) => {
    const { data, id } = params;
    return (dispatch) => {
        return callApi(`/update-products-breakfast-details/${id}`, 'PUT', data).then((response) => {
            const { status } = response;
            if (status === 200) {
                Notification('success', response?.data?.message, status);
                return dispatch(updateProductBreakfast_(response?.data?.product));
            } else elseNotifyError(response, status);
        });
    };
};

export const attatchExistingProductAsAddon = (params) => {
    return (dispatch) => {
        return callApi(`/attatch-existing-product-as-addon`, 'POST', params).then((response) => {
            const { status } = response;
            if (status === 200) {
                dispatch(getAllCalendarProducts(params.calendar_id));
                dispatch(attachedDuplicatedProduct_(response?.data?.product?.[0]));
                return response?.data?.product?.[0];
            } else elseNotifyError(response, status);
        });
    };
};

export const duplicateExistingProductAsAddon = (params) => {
    return (dispatch) => {
        return callApi(`/duplicate-existing-product-as-addon`, 'POST', params).then((response) => {
            const { status } = response;
            if (status === 200) {
                dispatch(getAllCalendarProducts(params.calendar_id));
                dispatch(attachedDuplicatedProduct_(response?.data?.product));
                return response?.data?.product;
            } else elseNotifyError(response, status);
        });
    };
};

export const attachedDuplicatedProduct_ = (payload) => ({
    type: ATTACHED_DUPLICATED_PRODUCT,
    payload
});

export const currentProductFlow_ = (payload) => ({
    type: CURRENT_PRODUCT_FLOW,
    payload
});

export const getPackageById = (id, calendarId) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(
            calendarId ? `/packages/${id}?calendarId=${calendarId}` : `/packages/${id}`,
            'GET'
        )
            .then((response) => {
                const status = response?.status;
                if (status === 200) {
                    return dispatch(packageDetail_(response?.data?.package));
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

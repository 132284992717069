import React, { useState, useEffect } from 'react';
import MediaCropper from '../crop-media';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import './index.scss';
import { VideoModal } from 'components/molecules';

function MediaField(props) {
    const { type, height, width } = props;
    const [isCroppingPhoto, setIsCroppingPhoto] = useState(false);
    const [isError, setIsError] = useState(false);
    const [loadedImg, setLoadedImg] = useState('');
    const [nameFile, setNameFile] = useState('');
    const [photoFile, setPhotoFile] = useState({});
    const [videoFile, setVideoFile] = useState({});
    const [loadedVid, setLoadedVid] = useState('');
    const [showVideoModal, setShowVideoModal] = useState(false);
    const acceptedFiles = type === 'picture' ? 'image/*' : 'video/mp4,video/x-m4v,video/*';

    useEffect(() => {
        setIsError(false);
    }, [type]);

    const setError = (width, height, result, file) => {
        if (width >= 2000 && height >= 1200) {
            setLoadedImg(result);
            if (type === 'picture') {
                setNameFile(file.name);
                setIsCroppingPhoto(true);
                setPhotoFile({
                    file
                });
            } else {
                props.onSelectMedia && props.onSelectMedia(file, {});
            }
            setIsError(false);
        } else {
            setIsError(true);
        }
    };
    const closeCropper = (crop) => {
        setPhotoFile({
            file: photoFile
        });
        setIsCroppingPhoto(false);
        setIsError(false);
        props.onSelectMedia && props.onSelectMedia(crop);
    };
    const handleMediaChange = (e) => {
        const file = e[0];
        if (file) {
            // set photo in store/form or start upload
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                // file reader result base64
                if (type === 'picture') {
                    let image = new Image();
                    image.onload = function () {
                        setError(image.width, image.height, fileReader.result, file);
                    };
                    image.src = fileReader.result;
                } else if (type === 'video') {
                    if (file && file.size < 10485759) {
                        setLoadedVid(fileReader.result);
                        setVideoFile(file);
                        setShowVideoModal(true);
                        setIsError(false);
                    } else setIsError(true);
                }
            };
            fileReader.readAsDataURL(file);
        }
    };

    function uploadSelectedVideo() {
        props.onSelectMedia && props.onSelectMedia(videoFile);
    }
    return (
        <div style={{ [width && 'width']: width, [height && 'height']: height }}>
            <div
                style={{ [width && 'width']: width, [height && 'height']: height }}
                className={props.className}>
                {isCroppingPhoto && (
                    <MediaCropper
                        onCrop={closeCropper}
                        img={loadedImg}
                        nameFile={nameFile}
                        isVisible={isCroppingPhoto}
                        handleCancel={() => setIsCroppingPhoto(false)}
                    />
                )}
                <Dropzone
                    maxFiles={1}
                    accept={acceptedFiles}
                    onDropAccepted={(e) => handleMediaChange(e)}>
                    {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div
                                style={{
                                    fontWeight: 600,
                                    fontSize: '16px'
                                }}>
                                Upload A New File
                            </div>
                        </div>
                    )}
                </Dropzone>
            </div>
            <div
                style={{
                    padding: '5px 0',
                    width: '300px',
                    visibility: isError ? 'unset' : 'hidden'
                }}
                className="invalid-fields">
                {`The ${
                    type === 'picture'
                        ? 'Image should be min W:2000 x H:1200'
                        : 'Video size should be less than 10 MB'
                } `}
            </div>

            {showVideoModal && (
                <VideoModal
                    showModal={showVideoModal}
                    setShowModal={setShowVideoModal}
                    src={loadedVid}
                    uploadSelectedVideo={uploadSelectedVideo}
                />
            )}
        </div>
    );
}

MediaField.propTypes = {
    type: PropTypes.oneOf(['picture', 'video']),
    onSelectMedia: PropTypes.func,
    text: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
};

export default MediaField;

import React from 'react';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const ProductInfo = (props) => {
    return (
        <div>
            <div className="productDetailsDiv mt-1" style={{ backgroundColor: props.bgcolor }}>
                <Row className="productInfoRow">
                    <Col
                        xs="12"
                        md="9"
                        style={{ color: props.fontcolor }}
                        className="pl-4 my-auto productDetailsCol">
                        <Row>
                            <Col md="11">
                                <div className="py-1">
                                    <span
                                        className="d-block text-truncate"
                                        style={{ lineHeight: 1.2, fontWeight: props.fontweight }}>
                                        {props.title}
                                    </span>

                                    <span
                                        className="d-block"
                                        style={{ lineHeight: 1.2, fontSize: '10px' }}>
                                        {props.subtitle}
                                    </span>
                                </div>
                            </Col>
                            <Col md="1">
                                <div className="py-1">
                                    {props.mainproduct == true ? (
                                        <Tooltip title="Main Product">
                                            <span className="main-product-circle text-right">
                                                MP
                                            </span>
                                        </Tooltip>
                                    ) : (
                                        <span className="d-none"></span>
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs="12"
                        md="3"
                        className="product-price-div text-right py-2 pr-4"
                        style={{
                            color: props.pricefontcolor,
                            backgroundColor: props.pricebgcolor
                        }}>
                        <span
                            style={{
                                fontSize: props.pricefontsize,
                                fontWeight: props.pricefontweight
                            }}>
                            {props.price}
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

ProductInfo.propTypes = {
    bgcolor: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string.isRequired,
    pricebgcolor: PropTypes.string,
    fontcolor: PropTypes.string,
    pricefontcolor: PropTypes.string,
    fontweight: PropTypes.string,
    titlefontsize: PropTypes.string,
    price: PropTypes.string.isRequired,
    mainproduct: PropTypes.any,
    pricefontweight: PropTypes.string,
    pricefontsize: PropTypes.string
};

export default ProductInfo;

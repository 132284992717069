import React from 'react';
import './index.css';
import Info from '../../../assets/images/info.svg';
import PropTypes from 'prop-types';

export default function TextFormLayout(props) {
    return (
        <div style={{ paddingTop: '30.0px' }}>
            <p style={{ fontWeight: '500' }}>
                {props.layout}
                <span>
                    <img alt="" style={{ paddingBottom: '20px' }} src={Info} />
                </span>
            </p>
            <input
                placeholder={props.placeholder}
                style={{ height: props.height }}
                className="input-bar"
            />
        </div>
    );
}
TextFormLayout.propTypes = {
    layout: PropTypes.string,
    placeholder: PropTypes.string,
    height: PropTypes.string
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Plus from '../../../../assets/images/plus.svg';
import Min from '../../../../assets/images/min.svg';
import TextDropDown from '../../../atoms/select-dropdown';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';

export default function ImportMainProduct(props) {
    const [openModal, setOpenModal] = useState(false);

    //Importing product
    // const calendarData = [
    //     {
    //         name: 'Calendar title 1',
    //         child: [
    //             {
    //                 name: 'Activity',
    //                 category: 'itenary',
    //                 subcategory: ['Main Product Title 1', 'Main Product Title 2']
    //             }
    //         ]
    //     },
    //     {
    //         name: 'Calendar title 2',
    //         child: [
    //             {
    //                 name: 'Accommodation',
    //                 category: 'itenary',
    //                 subcategory: ['Main Product Title 1', 'Main Product Title 2']
    //             }
    //         ]
    //     }
    // ];

    const importProduct = () => {
        setOpenModal(!openModal);
    };
    const onSaveMainProduct = (item) => {
        props.onMainProduct(item);
        importProduct();
    };

    return (
        <div>
            <h3 style={{ fontSize: '20px', fontWeight: 900, marginTop: '13px' }}>{props.create}</h3>
            <div className="d-flex" onClick={importProduct}>
                <img className="cursor-pointer img-plus" src={!openModal ? Plus : Min} alt="" />
                <p
                    className="cursor-pointer"
                    style={{
                        marginBottom: '0',
                        paddingLeft: '6px',
                        fontSize: '14px',
                        color: 'rgb(162, 121, 44)'
                    }}>
                    {props.import}
                </p>
            </div>
            {openModal && (
                <div style={{ paddingTop: '11px', paddingLeft: '1px' }}>
                    <p style={{ fontWeight: 500 }}>
                        Select product to resuse from another calendar
                    </p>
                    <TextDropDown
                        name="Select"
                        className="input-bar-dropdown"
                        caret={Caret}
                        down={Down}
                        class={'image-icon-down'}
                        icon={'image-icon'}>
                        {props.calendars
                            ? props.calendars.data.map((item) => {
                                  return (
                                      <TextDropDown
                                          caret={Caret}
                                          down={Down}
                                          isCheckBox={false}
                                          name={item.title}
                                          key={item.id}
                                          className="input-no-curve"
                                          class={'image-icon-down'}
                                          icon={'image-icon'}>
                                          {item?.mainProduct ? (
                                              <TextDropDown
                                                  widthy="100%"
                                                  name={item.mainProduct.title}
                                                  key={item.mainProduct.id}
                                                  color={'#FFFAE8'}
                                                  isSidebar={false}
                                                  className="input-no-curve"
                                                  isCheckBox={false}
                                                  click={() => onSaveMainProduct(item.mainProduct)}
                                              />
                                          ) : null}
                                      </TextDropDown>
                                  );
                              })
                            : null}
                    </TextDropDown>
                </div>
            )}
        </div>
    );
}
ImportMainProduct.propTypes = {
    onMainProduct: PropTypes.func,
    create: PropTypes.string,
    import: PropTypes.string,
    calendars: PropTypes.object
};

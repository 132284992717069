import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreText from '../pricing/MoreText.jsx';
import DaysSelector from '../pricing/DaysSelector.jsx';
import AddPhotos from '../../../../assets/images/add_photos.svg';
import AddVideos from '../../../../assets/images/add_videos.svg';
import InfoIcon from '../../../../assets/images/info.svg';
import DamiBtn from '../pricing/DamiBtn.jsx';
import searchIcon from '../../../../assets/images/searchIcon.svg';
import Duration from './Duration.jsx';
import DurationTime from './DurationTime.jsx';
import SelectTime from './SelectTime.jsx';
import EndDuration from './EndDuration.jsx';
import VendorDashboard from '../vendor-dashboard/index';
import Pointer from '../../../../assets/images/hotline_pointer.svg';
import Headset from '../../../../assets/images/headset.svg';
import ThumbsUp from '../../../../assets/images/thumbsup.svg';
import ThumbsDown from '../../../../assets/images/thumbsdown.svg';
// import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '41px',
        paddingLeft: '45px',
        paddingRight: '10px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    line1: {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '10px',
        color: '#333333'
    },
    line2: {
        marginBottom: '57px'
    },
    line3: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        marginBottom: '9px',
        color: '#333333'
    },
    line4: {
        width: '90%'
    },
    line5: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        marginTop: '36px',
        marginBottom: '17px',
        color: '#333333'
    },
    line6: {
        marginBottom: '36px',
        width: '90%'
    },
    line7: {
        marginTop: '36px',
        marginBottom: '9px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */

        color: '#4F4F4F'
    },
    line8: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '19px'
    },
    line9: {
        marginTop: '36px',
        marginBottom: '3px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333'
    },
    line10: {
        marginBottom: '26px',
        width: '100%'
    },
    addSupplement: {
        marginTop: '33px',
        marginBottom: '16px',
        marginLeft: '28px' //marginBottom: "55px",
    },
    moreDetails: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '14px 29px'
    },
    accomodationTitle: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#333333'
    },
    recommendedPricing: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '22px',
        /* identical to box height */

        color: '#333333'
    },
    recommended: {
        background: '#A2792C',
        borderRadius: '8px',
        color: 'white',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        padding: '5px 14px' // color: "#FFFFFF",
    },
    line11: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '22px',
        marginTop: '28px'
    },
    line12: {
        marginTop: '17px',
        marginBottom: '32px'
    },
    line13: {
        marginBottom: '9px'
    },
    line14: {
        marginBottom: '17px'
    },
    line15: {
        marginTop: '17px',
        marginBottom: '38px'
    },
    line16: {},
    lastBtnGroup: {
        marginTop: '40px',
        marginBottom: '93px'
    },
    line6b: {
        marginTop: '17px',
        marginBottom: '9px',
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333'
    },
    line6d: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '19px'
    },
    hotlineParent: {
        position: 'relative'
    },
    hotline: {
        position: 'absolute',
        top: '-150px',
        left: '-156px'
    },
    hotlineRectangle: {
        background: '#FFFFFF',
        borderRadius: '6px',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        paddingTop: '25px',
        paddingLeft: '12px',
        zIndex: 2,
        paddingBottom: '14px',
        paddingRight: '3px'
    }
}));

export default function AddTransportOption() {
    const classes = useStyles();
    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);
    const [showHotline, setShowHotline] = useState(false);

    // let { day, activity } = useParams();

    const handleHotline = (response) => {
        setShowHotline(response);
    };

    const saveImage = (e) => {
        let images = e.target.files;
        let tempImages = [...photos];
        for (let i = 0; i < images.length; i++) {
            let name = images[i].name;
            let source = URL.createObjectURL(images[i]);
            tempImages.push({ name: name, source: source });
        }

        setPhotos([...new Set(tempImages)]);
    };

    const saveVideo = (e) => {
        let vids = e.target.files;
        let tempVids = [...videos];
        for (let i = 0; i < vids.length; i++) {
            let name = vids[i].name;
            let source = URL.createObjectURL(vids[i]);
            tempVids.push({ name: name, source: source });
        }

        setVideos([...new Set(tempVids)]);
    };

    return (
        <VendorDashboard>
            <Grid container className={classes.root} direction="column">
                <Grid item className={classes.line1}>
                    Edit Itinerary
                </Grid>
                <Grid item className={classes.line2}>
                    <MoreText
                        customStyle={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */

                            color: '#A2792C'
                        }}
                        text="I will import Itinerary information from my other activity"
                    />
                </Grid>
                <Grid item className={classes.line3}>
                    Add Itinerary title
                </Grid>
                <Grid item className={classes.line4}>
                    <input
                        style={{
                            width: '100%',
                            border: '0.5px solid #A2792C',
                            boxSizing: 'border-box',
                            borderRadius: '8px',
                            height: '50px',
                            padding: '13px'
                        }}
                        type="text"
                        placeholder="kruger national park safari, South Africa"
                    />
                </Grid>
                <Grid item className={classes.line5}>
                    Describe what travelers will see and do here if they book your experience
                </Grid>
                <Grid item className={classes.line6}>
                    <textarea
                        style={{
                            border: '0.5px solid #979797',
                            borderRadius: '8px',
                            width: '100%',
                            height: '139px',
                            padding: '20px'
                        }}
                        placeholder={`Enter a description about this Itinerary only.  For example, "Our accredited guide will meet you at the ticket booth and will accompany you up the tower, explaining what you'll see at each level."
Description is required.`}></textarea>
                </Grid>
                <Grid item className={classes.line6b}>
                    Select main destination
                </Grid>
                <Grid item className={classes.line6c}>
                    <input
                        style={{
                            background: `url(${searchIcon}) no-repeat right`,
                            backgroundOrigin: 'content-box',
                            border: '0.5px solid #A2792C',
                            boxSizing: 'border-box',
                            borderRadius: '8px',
                            width: '100%',
                            height: '50px',
                            padding: '13px'
                        }}
                        placeholder="kruger national park safari, South Africa"
                    />
                </Grid>
                <Grid
                    item
                    style={{
                        marginTop: '36px',
                        marginBottom: '36px'
                    }}>
                    <hr />
                </Grid>
                <Grid item className={classes.line6d}>
                    What time of the day can customers book your activity?
                </Grid>
                <Grid item>
                    <SelectTime />
                </Grid>
                <Grid
                    item
                    container
                    direction="column"
                    style={{
                        paddingTop: '36px',
                        paddingLeft: '21px',
                        paddingBottom: '54px',
                        boxSizing: 'border-box'
                    }}>
                    <Grid
                        item
                        style={{
                            marginTop: '20px',
                            marginBottom: '19px',
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            color: '#333333'
                        }}>
                        Suggest a specific time for customer to book
                    </Grid>
                    <Grid
                        item
                        style={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            textTransform: 'capitalize',
                            color: '#333333'
                        }}>
                        What's the duration of your Activity from start to finish?
                    </Grid>
                    <Grid item>
                        <Duration />
                    </Grid>
                    <Grid
                        item
                        style={{
                            fontWeight: '600',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            color: '#333333',
                            marginBottom: '20px'
                        }}>
                        Allow customers to choose within a time period in the day
                    </Grid>
                    <Grid
                        item
                        style={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            textTransform: 'capitalize',
                            color: '#333333'
                        }}>
                        What time period in the day can this product be booked?
                    </Grid>
                    <Grid item>
                        <DurationTime />
                    </Grid>
                    <Grid
                        item
                        style={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            textTransform: 'capitalize',
                            color: '#333333',
                            marginBottom: '36px',
                            marginTop: '25px'
                        }}>
                        Add another time period in the day.
                    </Grid>
                    <Grid
                        item
                        style={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            textTransform: 'capitalize',
                            color: '#333333',
                            marginBottom: '17px'
                        }}>
                        What's the duration of your Activity?
                    </Grid>
                    <Grid item container>
                        <Grid item>
                            <input
                                type=""
                                style={{
                                    border: '0.5px solid #A2792C',
                                    boxSizing: 'border-box',
                                    borderRadius: '8px 0px 0px 8px',
                                    width: '58px',
                                    height: '50px'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <select
                                name="min"
                                id="minutes"
                                style={{
                                    border: '0.5px solid #A2792C',
                                    boxSizing: 'border-box',
                                    borderRadius: '0px 8px 8px 0px',
                                    width: '132px',
                                    height: '50px',
                                    background: '#FFFFFF'
                                }}>
                                <option value="volvo">Minutes</option>
                                <option value="saab">Hours</option>
                            </select>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.line7}>
                    Select the customers calendar days this booking apply
                </Grid>
                <Grid item className={classes.line8}>
                    This product will be availlable for customers to book on the days selected
                    below.
                </Grid>
                <Grid item>
                    <DaysSelector />
                </Grid>
                <Grid item className={classes.line9}>
                    Add Photos &amp; Videos
                </Grid>
                <Grid item className={classes.line10}>
                    Use photos that highlight what’s unique about your experience and show travelers
                    having fun. Adding 6-9 photos and at least 1 video can increase your bookings.
                </Grid>
                <Grid item>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Grid
                                container
                                style={{
                                    backgroundImage: `url(${AddPhotos})`,
                                    backgroundSize: 'contain',
                                    width: '236px',
                                    height: '174px',
                                    marginRight: '10px'
                                }}>
                                <input
                                    type="file"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0
                                    }}
                                    onChange={saveImage}
                                    multiple
                                />
                            </Grid>
                            <Grid item>
                                <div>
                                    {photos.map((each, index) => (
                                        <img
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '5px',
                                                width: '50px',
                                                height: '50px'
                                            }}
                                            key={index}
                                            src={each.source}
                                            alt={each.name}
                                        />
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                style={{
                                    backgroundImage: `url(${AddVideos})`,
                                    backgroundSize: 'contain',
                                    width: '236px',
                                    height: '174px',
                                    marginRight: '10px'
                                }}>
                                <input
                                    type="file"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0
                                    }}
                                    onChange={saveVideo}
                                    multiple
                                />
                            </Grid>
                            <Grid item>
                                <div>
                                    {videos.map((each, index) => (
                                        <img
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '5px',
                                                width: '50px',
                                                height: '50px'
                                            }}
                                            key={index}
                                            src={each.source}
                                            alt={each.name}
                                        />
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.line11}>
                    Is admission to this place included in the price of your activity?
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="huey"
                                name="pickup"
                                value="huey"
                            />
                            <label htmlFor="huey">Yes</label>
                        </Grid>
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="dewey"
                                name="pickup"
                                value="dewey"
                                defaultChecked
                            />
                            <label htmlFor="dewey">No</label>
                        </Grid>
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="dewey"
                                name="pickup"
                                value="dewey"
                                defaultChecked
                            />
                            <label htmlFor="dewey">N/A</label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.line16}>
                    {[
                        ['Meals & Drinks', 'Add a meal', '#'],
                        ['Language Offered', 'Add language offered', '#'],
                        ['Inclusions & Exclusions', 'Add inclusions & exclusions', '#']
                    ].map((each, index) => (
                        <Grid key={index} item className={classes.addSupplement}>
                            <div className={classes.moreDetails}>
                                <Grid container justify="space-between">
                                    <Grid item className={classes.recommendedPricing}>
                                        {each[0]}
                                        <img src={InfoIcon} alt="" />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                                    <hr />
                                </div>
                                <Grid container>
                                    <MoreText
                                        customStyle={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            /* identical to box height */

                                            color: '#333333'
                                        }}
                                        text={each[1]}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid item>
                    <hr />
                </Grid>
                <Grid
                    item
                    style={{
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: '20px',
                        /* identical to box height */
                        color: '#333333',
                        marginBottom: '24px',
                        marginTop: '57px'
                    }}>
                    Now tell us where you meet travelers for this Itinerary?
                    <span
                        className={classes.hotlineParent}
                        style={{ marginLeft: '16px' }}
                        onMouseEnter={() => handleHotline(true)}
                        onMouseLeave={() => handleHotline(false)}>
                        <img src={InfoIcon} alt="" />
                        {showHotline ? (
                            <Grid
                                container
                                direction="row"
                                style={{
                                    width: '350px'
                                }}
                                className={classes.hotline}>
                                <Grid container item className={classes.hotlineRectangle}>
                                    <Grid item xs={2}>
                                        <img src={Headset} alt="headset icon" />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={10}
                                        style={{
                                            fontWeight: 'normal',
                                            fontSize: '12px',
                                            lineHeight: '15px',
                                            color: '#4F4F4F'
                                        }}>
                                        Travelers want to book products with accurate pickup
                                        locations. Adding specific meeting and pickup points will
                                        help their calendar plan their day. <br />
                                        <br />
                                        <Grid
                                            container
                                            justify="space-between"
                                            alignItems="flex-end">
                                            <Grid
                                                item
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '12.27px',
                                                    lineHeight: '15px',
                                                    /* identical to box height */
                                                    color: '#4F4F4F'
                                                }}>
                                                Lungo, <br />
                                                Your Customer’s Travel Planner.
                                            </Grid>
                                            <Grid item>
                                                <img
                                                    src={ThumbsUp}
                                                    alt="thumbs-up"
                                                    style={{ marginRight: '20px' }}
                                                />
                                                <img
                                                    src={ThumbsDown}
                                                    alt="thumbs-down"
                                                    style={{ marginRight: '20px' }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid
                                    xs={12}
                                    item
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '-10px',
                                        opacity: 1
                                    }}>
                                    <img src={Pointer} alt="hotline-pointer" />
                                </Grid>
                            </Grid>
                        ) : null}
                    </span>
                </Grid>
                <Grid item>
                    <Grid container direction="column">
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="huey"
                                name="meetup"
                                value="huey"
                            />
                            <label htmlFor="huey">At the accomodation provided by you</label>
                        </Grid>
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="dewey"
                                name="meetup"
                                value="dewey"
                                defaultChecked
                            />
                            <label htmlFor="dewey">
                                Allow travellers to enter their their own pick up location
                            </label>
                        </Grid>
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="dewey"
                                name="meetup"
                                value="dewey"
                                defaultChecked
                            />
                            <label htmlFor="dewey">N/A</label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.line12}>
                    <MoreText
                        customStyle={{
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */
                            color: '#333333'
                        }}
                        text="Add another starting point"
                    />
                </Grid>
                <Grid item>What is the departure time?</Grid>
                <Grid item>
                    <EndDuration />
                </Grid>
                <Grid
                    item
                    style={{
                        marginBottom: '17px',
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: '20px',
                        /* identical to box height */
                        textTransform: 'capitalize',
                        color: '#333333'
                    }}>
                    How long before departure does your pickup start?
                </Grid>
                <Grid
                    item
                    container
                    style={{
                        marginBottom: '38px'
                    }}>
                    <Grid item>
                        <input
                            type=""
                            style={{
                                border: '0.5px solid #A2792C',
                                boxSizing: 'border-box',
                                borderRadius: '8px 0px 0px 8px',
                                width: '58px',
                                height: '50px'
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <select
                            name="min"
                            id="minutes"
                            style={{
                                border: '0.5px solid #A2792C',
                                boxSizing: 'border-box',
                                borderRadius: '0px 8px 8px 0px',
                                width: '132px',
                                height: '50px',
                                background: '#FFFFFF',
                                textAlign: 'center'
                            }}>
                            <option value="volvo">Minutes</option>
                            <option value="saab">Hours</option>
                        </select>
                    </Grid>
                </Grid>
                <Grid item>
                    <hr />
                </Grid>
                <Grid item className={classes.line16}>
                    {[
                        ['Itinerary Calender Season', 'Add Itinerary calendar season', '#'],
                        ['Type of Adventure on this Itinerary', 'Add type of adventure', '#'],
                        ['Top Sights to see on this Itinerary', 'Add top sights', '#'],
                        [
                            'Information travellers need from you',
                            'Add any physical restrictions for this Itinerary',
                            '#'
                        ]
                    ].map((each, index) => (
                        <Grid key={index} item className={classes.addSupplement}>
                            <div className={classes.moreDetails}>
                                <Grid container justify="space-between">
                                    <Grid item className={classes.recommendedPricing}>
                                        {each[0]}
                                        <img src={InfoIcon} />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                                    <hr />
                                </div>
                                <Grid container>
                                    <MoreText
                                        customStyle={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            /* identical to box height */

                                            color: '#333333'
                                        }}
                                        text={each[1]}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>

                <Grid item className={classes.line13}>
                    Enter the maximum number of spaces available to book this activity per day
                </Grid>
                <Grid item className={classes.line14}>
                    This will indicate the spaces available to travelers and once this is reached,
                    this product activtity will be marked as sold out for that day
                </Grid>
                <Grid item className={classes.line15}>
                    <input
                        type="number"
                        style={{
                            border: '0.5px solid #C64545',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            width: '68px',
                            height: '48px'
                        }}
                    />
                </Grid>
                <Grid item>
                    <hr />
                </Grid>
                <Grid item className={classes.line16}>
                    {[
                        ['Pricing', 'Add Pricing details for your ‘Transportaion Title’', '#'],
                        [
                            'Booking and Tickets',
                            'Add Booking and Ticketing details  for your  ‘Transportaion Title’',
                            '#'
                        ],
                        [
                            'Information travellers need from you',
                            'Add any physical restrictions for this  ‘Transportaion Title’',
                            '#'
                        ],
                        ['Covid-19 Updates', 'Add Covid-19 safety updates', '#']
                    ].map((each, index) => (
                        <Grid key={index} item className={classes.addSupplement}>
                            <div className={classes.moreDetails}>
                                <Grid container justify="space-between">
                                    <Grid item className={classes.recommendedPricing}>
                                        {each[0]}
                                        <img src={InfoIcon} alt="" />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                                    <hr />
                                </div>
                                <Grid container>
                                    <MoreText
                                        customStyle={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            /* identical to box height */

                                            color: '#333333'
                                        }}
                                        text={each[1]}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid item container justify="space-between" className={classes.lastBtnGroup}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <DamiBtn text="Done" />
                            </Grid>
                            <Grid item>
                                <DamiBtn
                                    text="Cancel"
                                    customStyle={{ background: '#FFFFFF', color: '#A2792C' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </VendorDashboard>
    );
}

/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DownArrow from '../../../../assets/images/downArrow.svg';
import MidiCheckCircle from '../../../../assets/images/mdi_check_circle.svg';
import MdiInfo from '../../../../assets/images/mdi_info.svg';
import './index.scss';

function MembershipBenefits(props) {
    const benefits = [
        'Product deals up to 75% off.',
        'Flexi-pay, with no interest fees (no credit check).',
        'Refund guarantee (up to 24 hours before travel).',
        'Personalised travel experience.',
        'Trip planner & organiser.',
        'Travel help & support.',
        'Member wallet & cashback.',
        'No refund cancellation fee.'
    ];

    const [isViewingMembershipBenefits, setViewingStatus] = useState(true);
    const benefitsTitle = 'MEMBERSHIP BENEFITS & DETAILS';
    const handleOnClick = () => {
        setViewingStatus(!isViewingMembershipBenefits);
    };
    const { offerBenefits = benefits } = props;
    return (
        <div className="membership-benefits">
            <div className="membership-benefits__title">
                <h6>{benefitsTitle}</h6>
                <img
                    onClick={handleOnClick}
                    className={`membership-benefits__title-icon
                        ${
                            isViewingMembershipBenefits
                                ? 'membership-benefits__title-icon--viewing'
                                : 'membership-benefits__title-icon--default'
                        }
                    `}
                    src={DownArrow}
                />
            </div>
            <div
                className={`membership-benefits__body 
                    ${
                        isViewingMembershipBenefits
                            ? 'membership-benefits__body--show'
                            : 'membership-benefits__body--hide'
                    }`}
                style={{
                    [isViewingMembershipBenefits && 'height']: offerBenefits.length * 45
                }}>
                <div className="membership-benefits__body-wrapper">
                    {offerBenefits.map((benefit) => (
                        <div className="membership-benefits__body-row">
                            <img src={MidiCheckCircle} alt=""></img>
                            <span>{benefit}</span>
                            <img src={MdiInfo}></img>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

MembershipBenefits.propTypes = {
    benefits: PropTypes.array,
    offerBenefits: PropTypes.array
};

export default MembershipBenefits;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
// import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableRow from '@material-ui/core/TableRow';
import EndDurationRow from './EndDurationRow.jsx';

// const StyledTableCell = withStyles((theme) => ({
//     head: {
//         backgroundColor: theme.palette.common.white,
//         color: theme.palette.common.black,
//         border: 'none'
//     },
//     body: {
//         fontSize: 14,
//         border: 'none'
//     }
// }))(TableCell);

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover
//         }
//     }
// }))(TableRow);

const useStyles = makeStyles({
    table: {
        width: 'max-content'
    }
});

export default function EndDuration() {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableBody>
                    <EndDurationRow />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

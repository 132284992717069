import React from 'react';
import { useLocation } from 'react-router-dom';
import { withRouter, Link } from 'react-router-dom';
import './index.css';
import Container from 'reactstrap/lib/Container';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

const Footer = () => {
    const { pathname } = useLocation();
    let basePath = pathname.split('/')[1];
    const hideNav = ['signin', 'calendar'];
    const fullNavHidden = !hideNav.includes(basePath);
    return (
        <>
            {fullNavHidden && (
                // Site footer
                <footer className="site-footer">
                    <Container>
                        <Row>
                            <Col xs={{ order: 2, size: 12 }} lg={{ order: 1, size: 6 }}>
                                <p className="copyright-info mb-lg-0 d-flex flex-column flex-sm-row align-items-center justify-content-sm-center justify-content-lg-start">
                                    <span className="text-nowrap">
                                        &copy; 2021 Customer Calendar Manager.&nbsp;
                                    </span>
                                    <span className="text-nowrap">All rights reserved.</span>
                                </p>
                            </Col>
                            <Col xs={{ order: 1, size: 12 }} lg={{ order: 2, size: 6 }}>
                                <div className="d-sm-flex text-center text-lg-left justify-content-center justify-content-lg-end mx-n1 mb-3 mb-lg-0">
                                    <div className="px-1 list-item mb-lg-0">
                                        <Link
                                            to="/terms-conditions"
                                            target={'_blank'}
                                            className="text-reset footer-link">
                                            Terms and Conditions
                                        </Link>
                                    </div>
                                    <div className="px-1 list-item mb-lg-0">
                                        <Link
                                            to="/privacy-policy"
                                            target={'_blank'}
                                            className="text-reset footer-link">
                                            Privacy Statement
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            )}
        </>
    );
};

export default withRouter(Footer);

/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { Button, Col, Modal, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { Feedback, ConfirmationModal } from 'components/molecules';
import { connect } from 'react-redux';
import {
    getAllDayProductCategories,
    getWithInDayProductCategories,
    createAddonOrPackageProduct,
    resetProductReducer,
    getExistingProducts,
    updateAddonProduct,
    selectedExistingProduct,
    selectedExistingProduct_,
    deleteAddOnProduct,
    getCalendarsById,
    createPackage,
    getPackageById,
    assignExistingProductToPackage,
    currentProductFlow_
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import { Notification } from 'components/atoms';
import CreateNewProduct from './CreateNewProduct';
import ExistingProduct from './ExistingProduct';

const productTypes = [
    {
        name: 'Existing Product',
        value: 'existing_product'
    },
    {
        name: 'New Product',
        value: 'new_product'
    }
];
const heirachy = [
    {
        name: 'Create New Product',
        value: 'create_new_product'
    },
    {
        name: 'Onboard Partner',
        value: 'onboard_partner'
    }
];

const AddProductsProductTypeModal = (props) => {
    const loader = useRef(null);
    const pageRef = useRef('/?page=1');
    const categoryRef = useRef('');
    const subCategoryRef = useRef('');
    const TextSearchRef = useRef('');

    const { match, selectedDay } = props;
    const {
        loading,
        isModalOpen,
        setModalOpenState,
        handleCreate,
        setPreviousModalOpenState,
        setAddProductInitModalData,
        setAddOnProductItineraryTimeModal,
        existing_products,
        selectedExistingProduct,
        calendar_products_list,
        createAddonOrPackageProduct,
        updateAddonProduct,
        current_product,
        package_detail,
        createPackage,
        getPackageById,
        get_calendar_data,
        currentProductFlow_
    } = props;

    const [isConfirmationModalOpen, setConfirmationModalOpenState] = useState(false);
    const [productsProductType, setProductsProductType] = useState();
    const [productCreateMethod, setProductCreateMethod] = useState();
    const [currentProductCategory, setCurrentProductCategory] = useState();
    // const [allProductCategories, setAllProductCategories] = useState();
    const [currentProductSubCategory, setCurrentProductSubCategory] = useState();
    const [currentProductSubChildCategory, setCurrentProductSubChildCategory] = useState();

    const [allProductSubCategories, setAllProductSubCategories] = useState();
    const [choosedExistingProduct, setChoosedExistingProduct] = useState();
    const [productNameSearch, setProductNameSearch] = useState();

    const [selectedCategory, setSelectedCategory] = useState();
    const [selectedSubCategory, setSelectedSubCategory] = useState();

    const [isLoadingOnCreate, setIsLoadingOnCreate] = useState(false);
    const [isDuplicateProduct, setIsDuplicateProduct] = useState(false);
    const [productExistedInPackage, setProductExistedInPackage] = useState(false);

    const [form] = Form.useForm();

    const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: '20px',
        threshold: 1.0
    });
    // useEffect(() => {
    //     if (loader.current && intersectingCount === 0) {
    //         observer.observe(loader.current);
    //         intersectingCount = 1;
    //     }
    // }, [existing_products?.meta?.current_page === 1]);

    useEffect(() => {
        if (loader.current) {
            observer.observe(loader.current);
        }
        return () => {
            if (loader.current) observer.unobserve(loader.current);
            else observer.disconnect();
        };
    }, [loader.current]);

    useEffect(() => {
        pageRef.current = existing_products?.meta?.next_page_url;
    }, [existing_products?.meta?.next_page_url]);

    // useEffect(() => {
    //     if (props.create_addon_product) {
    //         if (selectedDay && selectedDay.value === 'within_day') {
    //             generalReset({ toggleCurrentModal: false });
    //             setAddOnProductItineraryTimeModal(true);
    //         } else {
    //             handleCreate();
    //         }
    //     }
    // }, [props.create_addon_product]);

    useEffect(() => {
        if (productsProductType === 'existing_product') {
            props.getExistingProducts({
                searchQuery: productNameSearch,
                productCategoryId: currentProductSubCategory,
                productSubCategoryId: currentProductSubChildCategory,
                dayType: selectedDay?.value
            });
            categoryRef.current = currentProductSubCategory; //creating ref's for observer
            subCategoryRef.current = currentProductSubChildCategory;
            TextSearchRef.current = productNameSearch;

            setSelectedCategory(); //resetting for new products
            setSelectedSubCategory(); //resetting for new products
        } else if (productsProductType === 'new_product') {
            props.selectedExistingProduct_(false);
        }
    }, [
        productsProductType,
        productNameSearch,
        currentProductSubCategory,
        currentProductSubChildCategory
    ]);

    useEffect(() => {
        if (current_product?.day_type === 'all_day' || selectedDay?.value === 'all_day') {
            getProductcategories();
        } else {
            getWithInProductcategories();
        }
    }, [selectedDay]);

    function handleObserver(entities) {
        const target = entities[0];
        if (target.isIntersecting) fetchPaginatingExistingProducts();
    }
    function fetchPaginatingExistingProducts() {
        if (pageRef.current)
            props.getExistingProducts({
                searchQuery: TextSearchRef.current,
                productCategoryId: categoryRef.current,
                productSubCategoryId: subCategoryRef.current,
                page: pageRef.current,
                paginating: true,
                dayType: selectedDay?.value
            });
    }
    const onChangeProductCreateMethod = (value) => {
        setProductCreateMethod(value);
        setCurrentProductCategory(value);
        // let data = heirachy.filter((data) => data.value == value)[0];
        // setAllProductCategories(data);
    };
    const onChangeCurrentProductCategory = (value) => {
        setCurrentProductSubCategory(value);
        let data = props.product_categories.filter((data) => data.id == value)[0];
        setAllProductSubCategories(data);
        setSelectedCategory(value);
        setCurrentProductSubChildCategory(); // empting sub category
        form.setFieldsValue({ product_sub_category_id: '' });
    };
    const onChangeCurrentProductSubCategory = (value) => {
        setCurrentProductSubChildCategory(value);
        // setCurrentProductSubCategory(value);
        setSelectedSubCategory(value);
    };
    const getProductcategories = () => {
        props.getAllDayProductCategories();
    };
    const getWithInProductcategories = () => {
        props.getWithInDayProductCategories();
    };

    const checkAttachOrDuplicate = () => {
        let filteredArray = [];
        let type = '';
        if (calendar_products_list && calendar_products_list.length > 0) {
            filteredArray = calendar_products_list.filter(
                (it) => it.id == choosedExistingProduct?.id
            );
        }
        if (filteredArray.length === 0) {
            type = 'attach';
        } else {
            type = 'duplicate';
        }
        return type;
    };

    const afterCheckAttachOrDuplicate = (flow) => {
        selectedExistingProduct(choosedExistingProduct).then((res) => {
            if (res?.payload) {
                proceedToNextStep();
                currentProductFlow_({ title: flow });
            }
        });
    };

    const proceedToNextStep = () => {
        if (choosedExistingProduct.day_type === 'within_day')
            props.setAddOnProductItineraryTimeModal(true);
        else props.handleCreate(true);

        generalReset({ toggleCurrentModal: false });
    };

    function callAssignExistingProductToPacakge(packageId) {
        props
            .assignExistingProductToPackage({
                package_id: packageId,
                product_id: choosedExistingProduct.id
            })
            .then((res) => {
                if (res)
                    selectedExistingProduct(choosedExistingProduct).then((res) => {
                        if (res?.payload) {
                            proceedToNextStep();
                            getPackageById(packageId, get_calendar_data?.data?.id);
                        }
                    });
            });
    }

    function beforeAssignExistingProductToPacakge() {
        if (package_detail?.id) callAssignExistingProductToPacakge(package_detail.id);
        else if (package_detail?.title)
            createPackage({
                calendar_id: package_detail.calendar_id,
                title: package_detail.title,
                description: package_detail.description,
                is_main_product: true
            }).then((res) => {
                if (res?.payload) {
                    callAssignExistingProductToPacakge(res.payload.id);
                }
            });
    }

    function isProductAlreadyExistedInPackage() {
        if (package_detail && Array.isArray(package_detail?.products)) {
            for (let value of package_detail.products) {
                if (value?.id === choosedExistingProduct?.id) return true;
            }
        }
        return false;
    }

    const onFinish = () => {
        if (productsProductType === 'existing_product') {
            if (!choosedExistingProduct?.id)
                return Notification('error', 'Choose One Product', '500');

            if (package_detail && Object.keys(package_detail).length > 0) {
                if (isProductAlreadyExistedInPackage()) return setProductExistedInPackage(true);
                beforeAssignExistingProductToPacakge();
            } else {
                let apiType = checkAttachOrDuplicate();
                if (apiType === 'attach') {
                    afterCheckAttachOrDuplicate('attach-product');
                } else if (apiType === 'duplicate') {
                    setIsDuplicateProduct(true);
                }
            }
        } else if (
            productCreateMethod === 'create_new_product' &&
            productsProductType === 'new_product'
        ) {
            let data = {
                calendar_id: match.params.id,
                day_type: selectedDay ? selectedDay.value : current_product?.day_type,
                product_type: 'addon_product',
                product_category_id: form.getFieldsValue().product_category_id,
                product_sub_category_id: form.getFieldsValue().product_sub_category_id
            };

            setIsLoadingOnCreate(true);
            if (package_detail?.title && !package_detail?.id) {
                createPackage({
                    calendar_id: package_detail.calendar_id,
                    title: package_detail.title,
                    description: package_detail.description,
                    is_main_product: true
                }).then((res) => {
                    if (res?.payload) {
                        delete data.calendar_id;
                        delete data.product_type;
                        data.package_id = res.payload.id;
                        data.url = '/packages/products';
                        callCreateAddonOrPackageProduct(data);
                        getPackageById(res.payload.id, get_calendar_data?.data?.id);
                    }
                });
            } else if (package_detail?.title && package_detail?.id) {
                delete data.calendar_id;
                delete data.product_type;
                data.package_id = package_detail?.id;
                data.url = '/packages/products';
                callCreateAddonOrPackageProduct(data);
            } else if (current_product) {
                // setIsLoadingOnCreate(true);
                updateAddonProduct({ data, id: current_product?.id }).then(() => {
                    //if (res?.payload) {
                    // setIsLoadingOnCreate(false);
                    generalReset({ toggleCurrentModal: false });
                    nextStep();
                    //}
                });
                //.finally(() => setIsLoadingOnCreate(false));
            } else callCreateAddonOrPackageProduct(data);
        }
    };

    function callCreateAddonOrPackageProduct(data) {
        createAddonOrPackageProduct(data).then(() => {
            //if (res?.payload?.data) {
            setIsLoadingOnCreate(false);
            generalReset({ toggleCurrentModal: false });
            nextStep();
            if (data.hasOwnProperty('package_id'))
                getPackageById(data?.package_id, get_calendar_data?.data?.id);
            //}
        });
        //.finally(() => setIsLoadingOnCreate(false));
    }

    const nextStep = () => {
        if (selectedDay && selectedDay?.value === 'within_day') {
            generalReset({ toggleCurrentModal: false });
            setAddOnProductItineraryTimeModal(true);
        } else {
            handleCreate();
        }
    };

    const checkButtonName = () => {
        const { current_product } = props;
        let name = 'Next';
        if (current_product) {
            name = 'Update';
        } else {
            if (
                productCreateMethod === 'create_new_product' &&
                productsProductType === 'new_product' &&
                selectedCategory &&
                selectedSubCategory
            ) {
                name = 'Create';
            }
        }
        return name;
    };
    const generalReset = ({
        resetForm,
        toggleCurrentModal,
        resetPreviousModal,
        openPreviousModal
    }) => {
        if (resetForm) {
            form.resetFields();
        }
        if (toggleCurrentModal !== isModalOpen) {
            setModalOpenState(!isModalOpen);
        }
        if (resetPreviousModal) {
            setAddProductInitModalData(null);
            props.resetProductReducer({
                currentProduct: true
            });
        }
        if (openPreviousModal) {
            props.resetProductReducer({
                currentProduct: true
            });
            setPreviousModalOpenState(true);
        }
    };
    function onChangeProductSearchInput(e) {
        const { value } = e.target;
        setProductNameSearch(value.trim());
    }

    return (
        <>
            <Modal
                size="lg"
                centered
                scrollable
                isOpen={isModalOpen}
                toggle={() => {
                    generalReset({
                        resetForm: true,
                        toggleCurrentModal: false,
                        resetPreviousModal: true
                    });
                }}>
                <Form className="d-flex flex-column h-100" form={form} onFinish={onFinish}>
                    <div className="modal-header flex-wrap flex-lg-nowrap">
                        <h5 className="modal-title order-2 order-lg-1">
                            {`Add Products (${
                                current_product?.day_type === 'all_day' ||
                                selectedDay?.value === 'all_day'
                                    ? 'All Day'
                                    : 'With in day'
                            })`}
                        </h5>
                        <div className="flex-shrink-0 order-1 order-lg-2 ml-lg-2">
                            <Button
                                color="link"
                                className="px-0 text-decoration-underline"
                                type="button"
                                onClick={() => {
                                    generalReset({
                                        resetForm: true,
                                        toggleCurrentModal: false,
                                        openPreviousModal: true
                                    });
                                }}>
                                Go Back
                            </Button>
                        </div>
                    </div>
                    <ModalBody className="d-flex flex-column align-items-stretch">
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    className="mb-14px"
                                    value={productsProductType}
                                    name="select_products_product_type"
                                    rules={[{ required: true, message: 'Please select one' }]}>
                                    <Select
                                        className="form-control-alt d-block"
                                        dropdownClassName="type-rich"
                                        size="large"
                                        placeholder="Select One"
                                        onChange={(value) => {
                                            setProductsProductType(value);
                                        }}>
                                        {productTypes.map((e, key) => {
                                            return (
                                                <Select.Option key={key} value={e.value}>
                                                    {e.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        {productsProductType === 'existing_product' && (
                            <ExistingProduct
                                onChangeProductSearchInput={onChangeProductSearchInput}
                                onChangeProductCategory={(value) => {
                                    onChangeCurrentProductCategory(value);
                                }}
                                productCategories={props.product_categories}
                                allProductSubCategories={allProductSubCategories}
                                onChangeProductSubCategory={(value) =>
                                    onChangeCurrentProductSubCategory(value)
                                }
                                loader={loader}
                                loading={loading}
                                existingProducts={existing_products}
                                setChoosedExistingProduct={setChoosedExistingProduct}
                            />
                        )}
                        {productsProductType === 'new_product' && (
                            <CreateNewProduct
                                currentProductCategory={currentProductCategory}
                                onChangeCategory={(value) => {
                                    onChangeCurrentProductCategory(value);
                                }}
                                productCategories={props.product_categories}
                                allProductSubCategories={allProductSubCategories}
                                onChangeSubCategory={(value) =>
                                    onChangeCurrentProductSubCategory(value)
                                }
                                currentProductSubCategory={currentProductSubCategory}
                                productCreateMethod={productCreateMethod}
                                onChangeProductCreate={(value) => {
                                    onChangeProductCreateMethod(value);
                                }}
                                heirachy={heirachy}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={isLoadingOnCreate}
                            color="primary"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="submit">
                            {checkButtonName()}
                            {isLoadingOnCreate && (
                                <Spinner className="ml-2" size="sm" color="light" />
                            )}
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="button"
                            onClick={() => setConfirmationModalOpenState(true)}>
                            Cancel
                        </Button>
                        <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                    </ModalFooter>
                </Form>
            </Modal>
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={setConfirmationModalOpenState}
                description="you want to cancel"
                handleConfirmation={() => {
                    const {
                        current_product,
                        deleteAddOnProduct,
                        getCalendarsById,
                        get_calendar_data
                    } = props;
                    if (current_product && current_product?.id && !current_product?.title) {
                        deleteAddOnProduct(current_product);
                    }
                    generalReset({
                        resetForm: true,
                        toggleCurrentModal: false,
                        resetPreviousModal: true
                    });
                    setConfirmationModalOpenState(false);
                    getCalendarsById(get_calendar_data?.data?.id);
                }}
            />
            <ConfirmationModal
                isModalOpen={isDuplicateProduct}
                setModalOpenState={setIsDuplicateProduct}
                description="This product is already in this calendar. Do you want to duplicate this product"
                handleConfirmation={() => {
                    afterCheckAttachOrDuplicate('duplicate-product');
                    setIsDuplicateProduct(false);
                }}
            />
            <ConfirmationModal
                title="Warning"
                noAllias="Close"
                isModalOpen={productExistedInPackage}
                setModalOpenState={setProductExistedInPackage}
                description="Product already existed in package for this calendar."
                showYesbutton={false}
            />
        </>
    );
};
const mapStateToProps = (state) => ({
    product_categories: state.calendar.product_categories,
    existing_products: state.calendar.existing_products,
    loading: state.loading,
    package_detail: state.product.package_detail,
    get_calendar_data: state.calendar.get_calendar_data,
    current_product: state.product.currentProduct,
    calendar_products_list: state.calendar.get_all_calendar_products?.data
});
const mapDispatch = (dispatch) => ({
    getAllDayProductCategories: () => dispatch(getAllDayProductCategories()),
    getWithInDayProductCategories: () => dispatch(getWithInDayProductCategories()),
    createAddonOrPackageProduct: (data) => dispatch(createAddonOrPackageProduct(data)),
    resetProductReducer: (data) => dispatch(resetProductReducer(data)),
    getExistingProducts: (query) => dispatch(getExistingProducts(query)),
    updateAddonProduct: (data) => dispatch(updateAddonProduct(data)),
    selectedExistingProduct: (data) => dispatch(selectedExistingProduct(data)),
    selectedExistingProduct_: (data) => dispatch(selectedExistingProduct_(data)),
    deleteAddOnProduct: (data) => dispatch(deleteAddOnProduct(data)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    createPackage: (data) => dispatch(createPackage(data)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId)),
    assignExistingProductToPackage: (data) => dispatch(assignExistingProductToPackage(data)),
    currentProductFlow_: (data) => dispatch(currentProductFlow_(data))
});
AddProductsProductTypeModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func,
    handleCreate: PropTypes.func,
    setPreviousModalOpenState: PropTypes.func,
    setAddProductInitModalData: PropTypes.func,
    setAddOnProductItineraryTimeModal: PropTypes.func,
    product_categories: PropTypes.array,
    current_product: PropTypes.any,
    getAllDayProductCategories: PropTypes.func,
    getWithInDayProductCategories: PropTypes.func,
    match: PropTypes.object,
    createAddonOrPackageProduct: PropTypes.func,
    selectedDay: PropTypes.object,
    resetProductReducer: PropTypes.func,
    getExistingProducts: PropTypes.func,
    existing_products: PropTypes.object,
    updateAddonProduct: PropTypes.func,
    selectedExistingProduct: PropTypes.func,
    setAddProductItineraryModal: PropTypes.func,
    selectedExistingProduct_: PropTypes.func,
    loading: PropTypes.bool,
    deleteAddOnProduct: PropTypes.func,
    package_detail: PropTypes.object,
    getCalendarsById: PropTypes.func,
    get_calendar_data: PropTypes.object,
    calendar_products_list: PropTypes.any,
    createPackage: PropTypes.func,
    getPackageById: PropTypes.func,
    assignExistingProductToPackage: PropTypes.func,
    currentProductFlow_: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(AddProductsProductTypeModal));

import React from 'react';
import {
    Feedback,
    CalendarMenuMobile,
    MasterEventHeader,
    MasterEventContent
} from 'components/molecules';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

const CalendarViewTab = (props) => {
    const {
        sideDayArray,
        selectedDowsArray,
        currentWindowWidth,
        isDayActive,
        fixedWidthMainCalendarWrap,
        selectedEventData,
        onClickGhost,
        responsive,
        calendarMenuVisibility,
        calendarTitleAndLengthWrap,
        onClickCalendarMenu,
        calendarLogoWrap,
        calendarNameWrap,
        calendarStatusWrap,
        customerStatusWrap,
        customerCalendarWrapMulti,
        onClickMenu,
        onClickClose,
        calendarBookingsData,
        defaultSelectedCalendar,
        viewTab
    } = props;
    return (
        <>
            {/* {currentWindowWidth >= 768 && filtersVisibility ? allFiltersWrap : ''} */}
            {/* Master Event Wrapper */}
            <div
                hidden={
                    viewTab !== 'calendar' ||
                    !defaultSelectedCalendar ||
                    !calendarBookingsData?.calendar
                }>
                <div className="master-event-wrapper master-event-wrapper--bordered">
                    {/* Calendar Menu for mobile */}
                    {currentWindowWidth < 768 && calendarMenuVisibility ? (
                        <CalendarMenuMobile
                            onClickMenu={onClickMenu}
                            onClickClose={onClickClose}
                            calendarLogoWrap={calendarLogoWrap}
                            calendarNameWrap={calendarNameWrap}
                            calendarTitleAndLengthWrap={calendarTitleAndLengthWrap}
                            calendarStatusWrap={calendarStatusWrap}
                            customerStatusWrap={customerStatusWrap}
                            customerCalendarWrapMulti={customerCalendarWrapMulti}
                            calendarBookingsData={calendarBookingsData}
                        />
                    ) : null}
                    <MasterEventHeader
                        currentWindowWidth={currentWindowWidth}
                        onClickCalendarMenu={onClickCalendarMenu}
                        calendarMenuVisibility={calendarMenuVisibility}
                        calendarLogoWrap={calendarLogoWrap}
                        calendarNameWrap={calendarNameWrap}
                        calendarTitleAndLengthWrap={calendarTitleAndLengthWrap}
                        calendarStatusWrap={calendarStatusWrap}
                        customerStatusWrap={customerStatusWrap}
                        customerCalendarWrapMulti={customerCalendarWrapMulti}
                        calendarBookingsData={calendarBookingsData}
                    />
                    <MasterEventContent
                        sideDayArray={sideDayArray}
                        selectedDowsArray={selectedDowsArray}
                        currentWindowWidth={currentWindowWidth}
                        isDayActive={isDayActive}
                        fixedWidthMainCalendarWrap={fixedWidthMainCalendarWrap}
                        selectedEventData={selectedEventData}
                        onClickGhost={onClickGhost}
                        responsive={responsive}
                    />
                </div>
                <div className="mt-2 mt-md-4">
                    <div className="d-flex mx-n2">
                        <div className="px-2 flex-grow-1 flex-md-grow-0">
                            <Button color="primary" block>
                                Go Back
                            </Button>
                        </div>
                        {/* <div className="px-2 flex-grow-1 flex-md-grow-0">
                            <Button color="primary" block>
                                Edit Calendar
                            </Button>
                        </div> */}
                        <div className="px-2 ml-auto">
                            <Feedback hideName="md" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
CalendarViewTab.propTypes = {
    sideDayArray: PropTypes.array,
    selectedDowsArray: PropTypes.array,
    currentWindowWidth: PropTypes.number,
    isDayActive: PropTypes.bool,
    fixedWidthMainCalendarWrap: PropTypes.node,
    selectedEventData: PropTypes.any,
    onClickGhost: PropTypes.func,
    responsive: PropTypes.object,
    calendarMenuVisibility: PropTypes.bool,
    calendarTitleAndLengthWrap: PropTypes.node,
    onClickCalendarMenu: PropTypes.func,
    calendarLogoWrap: PropTypes.node,
    calendarNameWrap: PropTypes.node,
    calendarStatusWrap: PropTypes.node,
    customerStatusWrap: PropTypes.node,
    customerCalendarWrapMulti: PropTypes.node,
    onClickMenu: PropTypes.func,
    onClickClose: PropTypes.func,
    calendarBookingsData: PropTypes.object,
    defaultSelectedCalendar: PropTypes.object,
    viewTab: PropTypes.string
};
export default CalendarViewTab;

import React from 'react';
import {
    ActivityIcon,
    SleepIcon,
    PersonalCareIcon,
    TransportationIcon,
    MealsAndDrinksIcon,
    ProductGuideIcon
} from 'components/atoms';
import PropTypes from 'prop-types';

const ProductCategoryIcon = ({ id, color }) => {
    switch (id) {
        // Accomodation
        case 1:
            return <SleepIcon color={color} />;
        // Transportation
        case 2:
            return <TransportationIcon color={color} />;
        // Meals & Drinks
        case 3:
        case 6:
            return <MealsAndDrinksIcon color={color} />;
        case 4:
        case 8:
            return <PersonalCareIcon color={color} />;
        case 5:
            return <ActivityIcon color={color} />;
        // Guide
        case 7:
            return <ProductGuideIcon color={color} />;

        default:
            return <></>;
    }
};

ProductCategoryIcon.propTypes = {
    id: PropTypes.any,
    color: PropTypes.string
};

export default ProductCategoryIcon;

import React, { useEffect } from 'react';
import ReviewCard from '../reviewcard';
import Google from '../../../../assets/images/google.svg';
import Facebook from '../../../../assets/images/facebook.svg';
import Booking from '../../../../assets/images/bookings.svg';
import Tripadvisor from '../../../../assets/images/tripadvisor.svg';
import VendorDashboard from '../vendor-dashboard';

export default function Reviews() {
    //Scroll to the top function
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <VendorDashboard>
            <h5 className="background-sz" style={{ fontWeight: 600 }}>
                Available Connections
            </h5>
            <div className="row background-review">
                <div className="col-md-6">
                    <ReviewCard name="GOOGLE" google={Google} width="100%" />
                </div>
                <div className="col-md-6">
                    <ReviewCard name="FACEBOOK" google={Facebook} width="100%" />
                </div>
            </div>
            <div className="row background-review">
                <div className="col-md-6">
                    <ReviewCard name="TRIP ADVISOR" google={Tripadvisor} width="100%" />
                </div>
                <div className="col-md-6">
                    <ReviewCard name="BOOKING.COM" google={Booking} width="100%" />
                </div>
            </div>
        </VendorDashboard>
    );
}

import React from 'react';
import { Collapse } from 'antd';
import Proptypes from 'prop-types';
const { Panel } = Collapse;

const SingleCollapse = (props) => {
    const comboTitle = (
        <div className="d-flex">
            {props.panelIcon ? <div className="panel-icon">{props.panelIcon}</div> : ''}
            <div className="align-self-center">{props.panelTitle}</div>
        </div>
    );
    return (
        <>
            {props.children ? (
                <Collapse
                    className="single-collapse"
                    expandIcon={() => (
                        <span
                            role="img"
                            aria-label="right"
                            className="anticon anticon-right ant-collapse-arrow">
                            <svg
                                width="12"
                                height="8"
                                viewBox="0 0 12 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.59 0L6 4.54638L1.41 0L0 1.39965L6 7.3556L12 1.39965L10.59 0Z"
                                    fill="#A2792C"
                                />
                            </svg>
                        </span>
                    )}
                    expandIconPosition="right">
                    <Panel
                        className={props.panelIcon ? 'panel-has-icon' : ''}
                        header={comboTitle}
                        key="1">
                        {props.children}
                    </Panel>
                </Collapse>
            ) : (
                <div className="ant-collapse single-collapse">
                    <div className="ant-collapse-item">
                        <div className="ant-collapse-header __no-content">
                            <div className="d-flex">
                                {props.panelIcon ? (
                                    <div className="panel-icon">{props.panelIcon}</div>
                                ) : (
                                    ''
                                )}
                                <div className="align-self-center">{props.panelTitle}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

SingleCollapse.propTypes = {
    panelIcon: Proptypes.object,
    panelTitle: Proptypes.object,
    children: Proptypes.any
};

export default SingleCollapse;

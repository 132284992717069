/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import alert_icon from '../../../../assets/images/alert.svg';
import './index.css';
import Info from '../../../../assets/images/info.svg';
// import HelpButton from '../../../atoms/helpbutton';
import RadioButton from '../../../atoms/radiobutton';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';
import TextDropDown from '../../../atoms/text-dropdown';
import Modal from 'react-modal';
import Feedback from '../../../molecules/user-feedback';

export default function Security() {
    //Custom style for modal
    const customStyles = {
        content: {
            height: 'auto',
            overflowY: 'scroll',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '5px'
        }
    };

    const numbers = ['+234', '+011', '+023'];

    const phone = ['Mobile', 'Landline'];

    const [setText] = useState('NO');
    const changeText = (e) => {
        setText(e.target.value);
    };

    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [verifyOpen, setverifyOpen] = useState(false);
    const [edit, setEdit] = useState(false);

    const openModal = () => {
        setmodalIsOpen(!modalIsOpen);
    };

    const closeModal = () => {
        setmodalIsOpen(!modalIsOpen);
    };

    const openVerify = () => {
        setverifyOpen(!verifyOpen);
    };

    const closeVerify = () => {
        setverifyOpen(!verifyOpen);
    };

    const openEdit = () => {
        setEdit(!edit);
    };

    const closeEdit = () => {
        setEdit(!edit);
    };

    return (
        <div style={{ backgroundColor: 'rgba(162, 121, 44, 0.06' }}>
            <div className="white-color">
                <div className="new-white-color">
                    <h5 style={{ paddingBottom: 30 }}>Account Security</h5>
                    <hr style={{ paddingBottom: 30, marginRight: '30%' }} />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            border: '1px solid #CAAD2E',
                            marginRight: '30%'
                        }}>
                        <div
                            style={{
                                backgroundColor: '#CAAD2E',
                                textAlign: 'center',
                                padding: 40
                            }}>
                            <h5 style={{ color: '#fff' }}>TIP:</h5>
                            <img src={alert_icon} alt="" />
                        </div>
                        <div style={{ padding: 30 }}>
                            <p style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                Your account is vulnerable
                            </p>
                            <p style={{ fontSize: 16 }}>
                                Enable two-factor authentication to protect your payout information
                            </p>
                        </div>
                    </div>
                    <div className="row" style={{ paddingTop: '40px' }}>
                        <div className="col-md-6">
                            <p style={{ fontWeight: '600' }}>Two factor authentication</p>
                            <p>
                                Disabled for payout settings{' '}
                                <span>
                                    {' '}
                                    <img alt="" style={{ paddingBottom: '20px' }} src={Info} />
                                </span>
                            </p>
                        </div>
                        <div className="col-md-6">
                            <button
                                type="button"
                                onClick={openModal}
                                data-target="#exampleModalCenter"
                                className="button-outline cursor-pointer">
                                Enable
                            </button>
                        </div>
                    </div>
                    <hr style={{ paddingBottom: 30, marginRight: '30%' }} />
                    <div className="row">
                        <div className="col-md-6">
                            <p style={{ fontWeight: '600' }}>Phone</p>
                            <p>
                                {' '}
                                <span style={{ fontWeight: '600', paddingRight: '10px' }}>
                                    Number
                                </span>
                                +44 7000 000000
                            </p>

                            <p
                                className="cursor-pointer"
                                onClick={openEdit}
                                style={{ color: '#A2792C' }}>
                                Edit Phone Number
                            </p>
                            <p>
                                {' '}
                                <span style={{ fontWeight: '600', paddingRight: '10px' }}>
                                    Status
                                </span>
                                Unverified
                            </p>
                        </div>
                        <div className="col-md-6">
                            <button
                                onClick={openVerify}
                                type="button"
                                data-toggle="modal"
                                data-target="#exampleModalCenter2"
                                className="button-outline cursor-pointer">
                                Verify
                            </button>
                        </div>
                    </div>
                    {/* <HelpButton /> */}
                    <div className="flex-shrink-0 mt-3 mt-lg-0">
                        <div className="d-flex justify-content-end">
                            <Feedback />
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                <h5
                    style={{ paddingBottom: '20px' }}
                    className="modal-title"
                    id="exampleModalCenterTitle">
                    Security check
                </h5>

                <p>To protect your account we need to confirm your identity</p>
                <p>
                    First, we must verify your phone number. We'll send you a verification code via
                    SMS or voice
                </p>
                <RadioButton
                    gender={changeText}
                    text="How do you want to receive your code"
                    yes="Text Message (SMS)"
                    no="Voice Call"
                />
                <p style={{ paddingTop: '20px' }}>
                    The code will be sent to phone number +44 7746 240583
                </p>
                <p style={{ color: '#ccc' }}>Message & data rates may apply</p>
                <hr />
                <div className="main-layout-popup">
                    <button style={{ marginRight: 20 }} className="security-button ">
                        Cancel
                    </button>
                    <button className="add-button ">Send my code</button>
                </div>
            </Modal>

            <Modal
                isOpen={verifyOpen}
                onRequestClose={closeVerify}
                style={customStyles}
                contentLabel="Example Modal">
                <h5
                    style={{ paddingBottom: '20px' }}
                    className="modal-title"
                    id="exampleModalCenterTitle">
                    Enter the code we sent you to verify your mobile number
                </h5>
                <p>
                    It may take a few minutes for the text message to arrive.The code will expire in
                    an hour"
                </p>
                <div style={{ paddingTop: '5.0px' }}>
                    <p style={{ fontWeight: '500' }}>Enter your code</p>
                    <div className="d-inline">
                        <div>
                            <input style={{ width: '100%' }} className="availability-dropdown" />
                        </div>
                        <div>
                            <button
                                style={{ marginTop: 20, width: '100%' }}
                                className="modal-submit">
                                {' '}
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={edit}
                onRequestClose={closeEdit}
                style={customStyles}
                contentLabel="Example Modal">
                <h5
                    style={{ paddingBottom: '20px' }}
                    className="modal-title"
                    id="exampleModalCenterTitle">
                    Edit phone number
                </h5>
                <div className="row">
                    <div className="col-md-6">
                        <p>Your Number</p>

                        <TextDropDown
                            caret={Caret}
                            down={Down}
                            isCheckBox={false}
                            name="+44"
                            className="input-no-curve"
                            class={'image-icon-down'}
                            icon={'image-icon'}>
                            {numbers.map((item, i) => (
                                <TextDropDown
                                    key={i}
                                    isCheckBox={false}
                                    name={item}
                                    className="input-no-curve"
                                />
                            ))}
                        </TextDropDown>
                    </div>
                    <div className="col-md-6">
                        <p>Type</p>

                        <TextDropDown
                            caret={Caret}
                            down={Down}
                            isCheckBox={false}
                            name="Choose"
                            className="input-no-curve"
                            class={'image-icon-down'}
                            icon={'image-icon'}>
                            {phone.map((item, i) => (
                                <TextDropDown
                                    key={i}
                                    isCheckBox={false}
                                    name={item}
                                    className="input-no-curve"
                                />
                            ))}
                        </TextDropDown>
                    </div>
                    <div className="main-layout-popup">
                        <button style={{ marginRight: 20 }} className="security-button ">
                            Cancel
                        </button>
                        <button className="add-button ">Save</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

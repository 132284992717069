/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Plus from '../../../../assets/images/plus.svg';
import TextDropDown from '../../../atoms/text-dropdown';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';

export default function ImportProduct(props) {
    const [openModal, setOpenModal] = useState(false);

    //Importing product
    const calendarData = [
        {
            name: 'Calendar title 1',
            child: [
                {
                    name: 'Activity',
                    category: 'itenary',
                    subcategory: ['Main Product Title 1', 'Main Product Title 2']
                }
            ]
        },
        {
            name: 'Calendar title 2',
            child: [
                {
                    name: 'Accommodation',
                    category: 'itenary',
                    subcategory: ['Main Product Title 1', 'Main Product Title 2']
                }
            ]
        }
    ];

    const importProduct = () => {
        setOpenModal(!openModal);
    };

    return (
        <div>
            <h3 style={{ fontSize: '20px', fontWeight: 900 }}>{props.create}</h3>
            <div className="d-flex" onClick={importProduct}>
                <img className="cursor-pointer" src={Plus} alt="" />
                <p
                    className="cursor-pointer"
                    style={{
                        marginBottom: '0',
                        paddingLeft: '10px',
                        fontSize: '16px',
                        color: 'rgb(162, 121, 44)'
                    }}>
                    {props.import}
                </p>
            </div>
            {openModal && (
                <div style={{ paddingTop: 20 }}>
                    <p style={{ fontWeight: 500 }}>
                        Select product to resuse from another calendar
                    </p>
                    <TextDropDown
                        name="Select"
                        className="input-bar-dropdown"
                        caret={Caret}
                        down={Down}
                        class={'image-icon-down'}
                        icon={'image-icon'}>
                        {calendarData.map((item) => {
                            return (
                                <TextDropDown
                                    caret={Caret}
                                    down={Down}
                                    isCheckBox={false}
                                    isSidebar={true}
                                    name={item.name}
                                    key={item.name}
                                    className="input-no-curve"
                                    class={'image-icon-down'}
                                    icon={'image-icon'}>
                                    {item.child &&
                                        item.child.map((child) => {
                                            return (
                                                <TextDropDown
                                                    caret={Caret}
                                                    down={Down}
                                                    isCheckBox={false}
                                                    isSidebar={true}
                                                    name={child.name}
                                                    key={child.name}
                                                    className="input-no-curve"
                                                    class={'image-icon-down'}
                                                    icon={'image-icon'}>
                                                    {item.child &&
                                                        item.child.map((cat) => {
                                                            return (
                                                                <TextDropDown
                                                                    widthy="100%"
                                                                    caret={Caret}
                                                                    down={Down}
                                                                    isCheckBox={false}
                                                                    isSidebar={false}
                                                                    name={cat.category}
                                                                    key={cat.category}
                                                                    className="input-no-curve"
                                                                    class={'image-icon-down'}
                                                                    icon={'image-icon'}>
                                                                    {item.child &&
                                                                        item.child.map((cat) => {
                                                                            return (
                                                                                cat.subcategory &&
                                                                                cat.subcategory.map(
                                                                                    (catData) => {
                                                                                        return (
                                                                                            //    <input placeholder={catData} style={{ height: "37px" }} className="input-no-curve d-inline" />

                                                                                            <TextDropDown
                                                                                                widthy="100%"
                                                                                                name={
                                                                                                    catData
                                                                                                }
                                                                                                key={
                                                                                                    catData
                                                                                                }
                                                                                                isSidebar={
                                                                                                    false
                                                                                                }
                                                                                                className="input-no-curve"
                                                                                                isCheckBox={
                                                                                                    false
                                                                                                }
                                                                                            />
                                                                                        );
                                                                                    }
                                                                                )
                                                                            );
                                                                        })}
                                                                </TextDropDown>
                                                            );
                                                        })}
                                                </TextDropDown>
                                            );
                                        })}
                                </TextDropDown>
                            );
                        })}
                    </TextDropDown>
                </div>
            )}
        </div>
    );
}
ImportProduct.propTypes = {
    create: PropTypes.string,
    import: PropTypes.string
};

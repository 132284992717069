import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAllCategories } from 'store/actions';
import PropTypes from 'prop-types';
import SearchBox from 'components/atoms/searchbox/SearchBox';
import { Select } from 'antd';
const { Option } = Select;

const angleIcon = (
    <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-4.62904e-07 1.41L4.58 6L-6.16331e-08 10.59L1.41 12L7.41 6L1.41 -6.1633e-08L-4.62904e-07 1.41Z"
                fill="#A2792C"
            />
        </svg>
    </span>
);

const ArchiveFilter = (props) => {
    const { onChangeFilter, filterState, categories, getAllCategories } = props;

    useEffect(() => {
        getAllCategories();
    }, [getAllCategories]);

    return (
        <>
            <div className="d-flex flex-wrap m-n2">
                <div className="p-2 flex-grow-1">
                    <SearchBox
                        inputValue={filterState.searchQuery}
                        search={(e) =>
                            onChangeFilter({ type: 'searchQuery', value: e.target.value })
                        }
                    />
                </div>

                <div className="p-2 flex-grow-1">
                    <Select
                        className="select-type-filter w-100"
                        dropdownClassName="select-basic-dropdown"
                        size="large"
                        placeholder="Sort By Category"
                        suffixIcon={angleIcon}
                        value={filterState.productCategoryId}
                        onChange={(value) =>
                            onChangeFilter({
                                type: 'productCategoryId',
                                value
                            })
                        }
                        allowClear>
                        {categories &&
                            categories.data.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.name}
                                </Option>
                            ))}
                    </Select>
                </div>
            </div>
        </>
    );
};

ArchiveFilter.propTypes = {
    filter: PropTypes.bool,
    setFilter: PropTypes.func,
    filterState: PropTypes.shape({
        searchQuery: PropTypes.string,
        productCategoryId: PropTypes.number,
        calendarId: PropTypes.number,
        productStatus: PropTypes.string,
        availabilityStatus: PropTypes.string
    }),
    onChangeFilter: PropTypes.func,
    onClearFilter: PropTypes.func,
    categories: PropTypes.object,
    getAllCategories: PropTypes.func
};

const mapStateToProps = (state) => ({
    categories: state.category.categories
});

const mapDispatch = (dispatch) => ({
    getAllCategories: () => dispatch(getAllCategories())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ArchiveFilter));

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ExclusionIcon } from 'components/atoms';

const EventExclusions = (props) => {
    const { product, onEdit } = props;
    let categories = {};
    if (product?.exclusions) {
        for (let item of product?.exclusions) {
            categories[item.category] = categories[item.category]
                ? [...categories[item.category], item.title]
                : [item.title];
        }
    }
    categories = Object.keys(categories).map((key) => ({ category: key, titles: categories[key] }));

    return (
        <DetailedPanel
            panelIcon={<ExclusionIcon />}
            panelTitle="Exclusions"
            isEditable={true}
            onEdit={onEdit}>
            {categories.length > 0 ? (
                categories.map((item, index) => (
                    <Row key={index} className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6} md={4}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    {item.category}
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6} md={8}>
                            <span className="fs-18 lh-1-7">
                                {item.titles.map(
                                    (title, index) =>
                                        `${title}${index === item.titles.length - 1 ? '' : ','}`
                                )}
                            </span>
                        </Col>
                    </Row>
                ))
            ) : (
                <></>
            )}{' '}
        </DetailedPanel>
    );
};

EventExclusions.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventExclusions;

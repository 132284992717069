/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

const CalendarMenuMobile = (props) => {
    const {
        onClickMenu,
        onClickClose,
        calendarLogoWrap,
        calendarNameWrap,
        calendarTitleAndLengthWrap,
        calendarStatusWrap,
        customerStatusWrap,
        customerCalendarWrapMulti,
        calendarBookingsData
    } = props;
    const getAgeGroupString = () => {
        let params = [];
        let ageGroup = calendarBookingsData?.ageGroups;
        if (ageGroup) {
            if (ageGroup?.adult) {
                params.push(`${ageGroup?.adult} Adult${ageGroup?.adult == 1 ? '' : 's'}`);
            } else if (ageGroup?.child) {
                params.push(`${ageGroup?.child} ${ageGroup?.child == 1 ? 'Child' : 'Children'}`);
            }
        }
        return params.length > 0 ? params.join(', ') : 0;
    };
    return (
        <div className="calendar-menu-wrapper">
            <div className="calendar-menu-overlay" role="dialog" onClick={onClickMenu}></div>
            <div className="calendar-menu-content">
                <div className="clearfix">
                    <button
                        className="float-left op-full close mt-2"
                        type="button"
                        aria-label="close"
                        onClick={onClickClose}>
                        <span className="close-bg-icon"></span>
                    </button>
                    <div className="float-right">
                        <div className="d-flex align-items-center flex-nowrap mx-n2">
                            <div className="px-2">{calendarLogoWrap}</div>
                            <div className="px-2">{calendarNameWrap}</div>
                        </div>
                    </div>
                </div>
                <div className="calendar-menu-seprator"></div>
                <div className="py-2">{calendarTitleAndLengthWrap}</div>
                <div className="calendar-menu-seprator"></div>
                <div className="py-2">
                    <div className="d-flex flex-nowrap align-items-center mx-n2">
                        <div className="px-2">{calendarStatusWrap}</div>
                        <div className="px-2">{customerStatusWrap}</div>
                    </div>
                </div>
                <div className="calendar-menu-seprator"></div>
                <div className="py-2">
                    <div className="text-left text-nowrap">
                        <div className="fs-14 my-1">
                            <span className="fw-600">No. of Products Booked: </span>
                            {calendarBookingsData?.calendar?.totalProductsBooked}
                        </div>
                        <div className="fs-14 my-1">
                            <span className="fw-600">No. of Travellers: </span>
                            {getAgeGroupString()}
                        </div>
                    </div>
                </div>
                <div className="calendar-menu-seprator"></div>
                <div className="py-2">
                    <>
                        {/* When more than 1 customer */}
                        {customerCalendarWrapMulti}
                    </>
                </div>
            </div>
        </div>
    );
};

CalendarMenuMobile.propTypes = {
    onClickMenu: PropTypes.func,
    onClickClose: PropTypes.func,
    calendarLogoWrap: PropTypes.node,
    calendarNameWrap: PropTypes.node,
    calendarTitleAndLengthWrap: PropTypes.node,
    calendarStatusWrap: PropTypes.node,
    customerStatusWrap: PropTypes.node,
    customerCalendarWrapMulti: PropTypes.node,
    ifCustomers: PropTypes.array,
    calendarBookingsData: PropTypes.object
};

export default CalendarMenuMobile;

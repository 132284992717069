import React, { useState, useEffect } from 'react';
import NewGroupRow from '../new-group';
import { Col, Row } from 'reactstrap';
import Prompter from '../../prompter';
import Spacer from '../../spacer';
import { Checkbox, Form, InputNumber } from 'antd';
import Proptypes from 'prop-types';
import styles from './styles';

export default function GroupRow(props) {
    const { form, ageGroups, setAgeGroups, groupName } = props;
    const [showGroupPrices, setShowGroupPrices] = useState(false);

    const { minAge, maxAge, amount, groupPrices } = ageGroups[groupName];

    const checked =
        (minAge !== null && minAge !== undefined) ||
        (maxAge !== null && maxAge !== undefined) ||
        (amount !== null && amount !== undefined);

    const onChange = (name, value) => {
        setAgeGroups({
            ...ageGroups,
            [props.groupName]: {
                ...ageGroups[groupName],
                [name]: value,
                type: groupName,
                checked: value === null || value === undefined ? checked : true
            }
        });
    };

    useEffect(() => {
        if (ageGroups[groupName]) {
            form.setFieldsValue({
                [`${props.groupName}minAge`]: minAge
            });
            form.setFieldsValue({
                [`${props.groupName}maxAge`]: maxAge
            });
            form.setFieldsValue({
                [`${props.groupName}currency`]: amount
            });

            if (
                minAge !== null &&
                minAge !== undefined &&
                maxAge !== null &&
                maxAge !== undefined &&
                amount !== null &&
                amount !== undefined
            ) {
                form.setFields([
                    {
                        name: `${props.groupName}minAge`,
                        errors: []
                    },
                    {
                        name: `${props.groupName}maxAge`,
                        errors: []
                    },
                    {
                        name: `${props.groupName}currency`,
                        errors: []
                    }
                ]);
            }

            if (minAge === null && maxAge !== null) {
                onChange('minAge', maxAge);
            }
        }
    }, [ageGroups]);

    return (
        <>
            <Row className="" style={{ margin: 0 }}>
                <Col sm={2} style={{ ...styles.title, maxWidth: '13.66667%' }}>
                    <div style={{ display: 'flex' }}>
                        <Checkbox
                            checked={checked}
                            onChange={(e) => {
                                const valueToSet = e.target.checked
                                    ? getMinValueForInput(ageGroups, groupName)
                                    : null;
                                onChange('minAge', valueToSet);
                                if (!e.target.checked) {
                                    onChange('maxAge', null);
                                    onChange('amount', null);
                                }
                            }}>
                            {props.groupName}
                        </Checkbox>
                    </div>
                </Col>
                <Col sm={2} style={{ ...styles.cell, maxWidth: '10.66667%' }}>
                    <Form.Item
                        className="mb-0"
                        name={`${props.groupName}minAge`}
                        rules={[
                            {
                                required: checked,
                                message: `Please add min age for ${props.groupName}`
                            }
                        ]}>
                        <InputNumber
                            size="large"
                            min={getMinValueForInput(ageGroups, groupName)}
                            value={minAge}
                            max={maxAge || getMaxValue(ageGroups, groupName)}
                            onChange={(value) => {
                                let max = getMaxValue(ageGroups, groupName);
                                let min = getMinValueForInput(ageGroups, groupName);

                                if (min !== null && value != null && value < min) {
                                    return form.setFieldsValue({
                                        [`${props.groupName}minAge`]: min
                                    });
                                }

                                if (max !== null && value != null && value > max) {
                                    return form.setFieldsValue({
                                        [`${props.groupName}minAge`]: max
                                    });
                                }

                                onChange('minAge', value);
                            }}
                        />
                    </Form.Item>
                </Col>
                <div style={{ ...styles.cell, margin: '12px 3px 0px', color: '#333333' }}>-</div>
                <Col sm={2} style={{ ...styles.cell, maxWidth: '10.66667%' }}>
                    <Form.Item
                        className="mb-0"
                        name={`${props.groupName}maxAge`}
                        rules={[
                            {
                                required: checked,
                                message: `Please add max age for ${props.groupName}`
                            }
                        ]}>
                        <InputNumber
                            size="large"
                            min={minAge || getMinValueForInput(ageGroups, groupName)}
                            value={maxAge}
                            max={getMaxValue(ageGroups, groupName)}
                            onChange={(value) => {
                                let max = getMaxValue(ageGroups, groupName);
                                let min = getMinValueForInput(ageGroups, groupName);
                                if (min !== null && value != null && value < min) {
                                    return form.setFieldsValue({
                                        [`${props.groupName}minAge`]: min
                                    });
                                }

                                if (max !== null && value != null && value > max) {
                                    return form.setFieldsValue({
                                        [`${props.groupName}minAge`]: max
                                    });
                                }
                                onChange('maxAge', value);
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col sm={3} style={{ ...styles.cell, marginLeft: 10, maxWidth: '26%' }}>
                    <div style={{ display: 'flex', alignItems: 'start' }}>
                        <div style={styles.currency}>USD</div>
                        <Form.Item
                            className="mb-0"
                            name={`${props.groupName}currency`}
                            rules={[
                                {
                                    required: checked,
                                    message: `Please add amount for ${props.groupName}`
                                }
                            ]}>
                            <InputNumber
                                className="border-left-radius-0"
                                value={amount}
                                min={0}
                                onChange={(value) => onChange('amount', value)}
                            />
                        </Form.Item>
                        <div style={{ fontWeight: 500, marginLeft: 8, marginTop: 8 }}>PP</div>
                    </div>
                </Col>
                <Col sm={4} style={{ ...styles.cell, maxWidth: '30%', marginLeft: 10 }}>
                    <Prompter
                        fontSize={14}
                        textColor="#A2792C"
                        text={`${groupPrices?.length > 0 ? 'Edit' : 'Add'} ${
                            props.groupName
                        } Group price (Optional)`}
                        onClick={() => {
                            if (amount !== null && amount !== undefined) {
                                setShowGroupPrices(true);
                            } else {
                                form.setFields([
                                    {
                                        name: `${props.groupName}currency`,
                                        errors: ['Please add amount first']
                                    }
                                ]);
                            }
                        }}
                    />
                </Col>
            </Row>
            <Spacer top={20} />
            <NewGroupRow
                minAge={minAge}
                maxAge={maxAge}
                amount={amount}
                title={groupName}
                data={groupPrices}
                showModal={showGroupPrices}
                onSave={(groups) => {
                    onChange('groupPrices', groups);
                    setShowGroupPrices(false);
                }}
                onClose={() => {
                    setShowGroupPrices(false);
                    onChange('groupPrices', null);
                }}
            />
        </>
    );
}
GroupRow.propTypes = {
    groupName: Proptypes.string,
    prevAgeGroups: Proptypes.array,
    checkBoxData: Proptypes.array,
    onSave: Proptypes.func,
    onEdit: Proptypes.string,
    onSaveCheckBoxData: Proptypes.func,
    index: Proptypes.number,
    form: Proptypes.any,
    ageGroups: Proptypes.any,
    setAgeGroups: Proptypes.func
};

const getMaxValue = (ageGroups, groupName) => {
    switch (groupName) {
        case AGE_GROUPS.INFANT:
            return ageGroups[AGE_GROUPS.CHILD]?.minAge
                ? ageGroups[AGE_GROUPS.CHILD]?.minAge - 1
                : getMaxValue(ageGroups, AGE_GROUPS.CHILD);
        case AGE_GROUPS.CHILD:
            return ageGroups[AGE_GROUPS.YOUTH]?.minAge
                ? ageGroups[AGE_GROUPS.YOUTH]?.minAge - 1
                : getMaxValue(ageGroups, AGE_GROUPS.YOUTH);
        case AGE_GROUPS.YOUTH:
            return ageGroups[AGE_GROUPS.ADULT]?.minAge
                ? ageGroups[AGE_GROUPS.ADULT]?.minAge - 1
                : getMaxValue(ageGroups, AGE_GROUPS.ADULT);
        case AGE_GROUPS.ADULT:
            return ageGroups[AGE_GROUPS.SENIOR]?.minAge
                ? ageGroups[AGE_GROUPS.SENIOR]?.minAge - 1
                : getMaxValue(ageGroups, AGE_GROUPS.SENIOR);
        default:
            return null;
    }
};

const getMinValue = (ageGroups, groupName) => {
    switch (groupName) {
        case AGE_GROUPS.CHILD:
            return ageGroups[AGE_GROUPS.INFANT]?.maxAge ||
                ageGroups[AGE_GROUPS.INFANT]?.maxAge === 0
                ? ageGroups[AGE_GROUPS.INFANT]?.maxAge + 1
                : getMinValue(ageGroups, AGE_GROUPS.INFANT);

        case AGE_GROUPS.YOUTH:
            return ageGroups[AGE_GROUPS.CHILD]?.maxAge
                ? ageGroups[AGE_GROUPS.CHILD]?.maxAge + 1
                : getMinValue(ageGroups, AGE_GROUPS.CHILD);

        case AGE_GROUPS.ADULT:
            return ageGroups[AGE_GROUPS.YOUTH]?.maxAge
                ? ageGroups[AGE_GROUPS.YOUTH]?.maxAge + 1
                : getMinValue(ageGroups, AGE_GROUPS.YOUTH);
        case AGE_GROUPS.SENIOR:
            return ageGroups[AGE_GROUPS.ADULT]?.maxAge
                ? ageGroups[AGE_GROUPS.ADULT]?.maxAge + 1
                : getMinValue(ageGroups, AGE_GROUPS.ADULT);

        default:
            return groupName === AGE_GROUPS.INFANT ? 0 : 1;
    }
};

const getMinValueForInput = (ageGroups, groupName) => {
    let value = getMinValue(ageGroups, groupName);
    if (value === 0) {
        if (groupName === AGE_GROUPS.INFANT) {
            return value;
        }
        return 1;
    }
    return value;
};

const AGE_GROUPS = {
    ADULT: 'Adult',
    INFANT: 'Infant',
    CHILD: 'Child',
    YOUTH: 'Youth',
    SENIOR: 'Senior'
};

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';

import {
    AddRoomButton,
    AddFacilityButton,
    AddBreakfastButton,
    AddHouseRuleButton
} from 'components/atoms';
import {
    updateProductHouseRules,
    updateProductRooms,
    updateProductFacilities,
    updateProductBreakfast,
    deleteProductHouseRules,
    deleteProductBreakfastDetail,
    deleteProductFacilities,
    deleteProductRooms
} from 'store/actions';
import {
    WhereCanPeopleSleep,
    AddFacilities,
    BreakfastDetails,
    HouseRules
} from 'components/molecules';
import { validateRequiredFormField } from 'utils/validation';

function AccomodationFormFields({ state, setState, currentProduct, ...props }) {
    const {
        updateProductHouseRules,
        updateProductBreakfast,
        updateProductFacilities,
        updateProductRooms
    } = props;

    const addHouseRules = (value) => {
        let data = {
            house_rules: value
        };
        updateProductHouseRules({
            data,
            id: currentProduct?.id
        }).then((res) => {
            if (res?.payload)
                setState({
                    houseRules: value,
                    isOpenHouseRules: false
                });
        });
    };

    const addBreakfastDetails = (value) => {
        let data = {
            breakfast_details: {
                is_breakfast_serve: value.included
            }
        };
        if (value.included) {
            data.breakfast_details.is_price_include = value.isIncludedPrice;
            if (value.price) {
                data.breakfast_details.price = Number(value.price);
            }

            if (value.types && value.types.length > 0) data.breakfast_details.types = value.types;
        }

        updateProductBreakfast({
            data,
            id: currentProduct?.id
        }).then((res) => {
            if (res?.payload)
                setState({
                    breakfastDetails: data.breakfast_details,
                    isOpenBreakfastDetails: false
                });
        });
    };

    const addFacilities = (value) => {
        let data = {
            facilities: value
        };
        updateProductFacilities({
            data,
            id: currentProduct?.id
        }).then((res) => {
            if (res?.payload)
                setState({
                    facilities: value,
                    isOpenAddFacilities: false
                });
        });
    };

    const addWhereCanPeopleSleep = (values) => {
        let data = {
            rooms: values
        };
        updateProductRooms({
            data,
            id: currentProduct?.id
        }).then((res) => {
            if (res?.payload)
                setState({
                    whereCanPeopleSleep: values,
                    isOpenWhereCanPeopleSleep: false
                });
        });
    };

    function deleteHouseRules() {
        props.deleteProductHouseRules(currentProduct?.id).then((res) => {
            if (res === true) setState({ houseRules: null });
        });
    }

    function deleteBreakfastDetails() {
        props.deleteProductBreakfastDetail(currentProduct?.id).then((res) => {
            if (res === true) setState({ breakfastDetails: null });
        });
    }

    function deleteFacilities() {
        props.deleteProductFacilities(currentProduct?.id).then((res) => {
            if (res === true) setState({ facilities: null });
        });
    }

    function deleteRooms() {
        props.deleteProductRooms(currentProduct?.id).then((res) => {
            if (res === true) setState({ whereCanPeopleSleep: null });
        });
    }

    return (
        <>
            <Form.Item
                className="mb-1"
                name="whereCanPeopleSleep"
                rules={[
                    {
                        required: state.whereCanPeopleSleep ? false : true,
                        message: 'Please fill rooms information'
                    }
                ]}>
                <AddRoomButton
                    name="Where can people sleep?"
                    add="Add Rooms"
                    data={state.whereCanPeopleSleep ? state.whereCanPeopleSleep : null}
                    onClick={() =>
                        setState({
                            isOpenWhereCanPeopleSleep: true
                        })
                    }
                    onDelete={deleteRooms}
                />
            </Form.Item>

            <Form.Item
                className="mb-1"
                name="facilities"
                rules={[
                    {
                        required: state.facilities ? false : true,
                        message: 'Please fill facilities information'
                    }
                ]}>
                <AddFacilityButton
                    name="What can your customers use?"
                    data={state.facilities ? state.facilities : null}
                    add="Add Facilities"
                    onClick={() => setState({ isOpenAddFacilities: true })}
                    onDelete={deleteFacilities}
                />
            </Form.Item>

            <Form.Item
                className="mb-1"
                name="breakfastDetails"
                rules={[
                    {
                        required: state.breakfastDetails ? false : true,
                        message: 'Please fill breakfast information'
                    }
                ]}>
                <AddBreakfastButton
                    name="Breakfast details"
                    data={state.breakfastDetails ? state.breakfastDetails : null}
                    onClick={() =>
                        setState({
                            isOpenBreakfastDetails: true
                        })
                    }
                    onDelete={deleteBreakfastDetails}
                />
            </Form.Item>

            <Form.Item
                className="mb-1"
                name="houseRules"
                rules={[
                    {
                        required: state.houseRules ? false : true,
                        message: 'Please fill house rules information'
                    }
                ]}>
                <AddHouseRuleButton
                    name="House rules"
                    data={state.houseRules ? state.houseRules : null}
                    onDelete={deleteHouseRules}
                    onClick={() => setState({ isOpenHouseRules: true })}
                />
            </Form.Item>

            <WhereCanPeopleSleep
                data={state.whereCanPeopleSleep}
                isOpen={state.isOpenWhereCanPeopleSleep}
                onClose={() => setState({ isOpenWhereCanPeopleSleep: false })}
                onSave={addWhereCanPeopleSleep}
                submitButtonName="Save"
            />

            <AddFacilities
                isOpen={state.isOpenAddFacilities}
                onClose={() => setState({ isOpenAddFacilities: false })}
                data={state.facilities}
                onSave={(value) => addFacilities(value)}
                submitButtonName="Save"
            />

            <BreakfastDetails
                isOpen={state.isOpenBreakfastDetails}
                onClose={() => setState({ isOpenBreakfastDetails: false })}
                data={state.breakfastDetails}
                onSave={(value) => addBreakfastDetails(value)}
                submitButtonName="Save"
                validateRequiredFormField={validateRequiredFormField}
            />

            <HouseRules
                isOpen={state.isOpenHouseRules}
                onClose={() => setState({ isOpenHouseRules: false })}
                data={state.houseRules}
                onSave={(value) => addHouseRules(value)}
            />
        </>
    );
}

AccomodationFormFields.propTypes = {
    props: PropTypes.object,
    state: PropTypes.object,
    setState: PropTypes.func,
    currentProduct: PropTypes.object,
    updateProductHouseRules: PropTypes.func,
    updateProductBreakfast: PropTypes.func,
    updateProductFacilities: PropTypes.func,
    updateProductRooms: PropTypes.func,
    deleteProductRooms: PropTypes.func,
    deleteProductFacilities: PropTypes.func,
    deleteProductBreakfastDetail: PropTypes.func,
    deleteProductHouseRules: PropTypes.func
};

const mapStateToProps = (state) => ({
    currentProduct: state.product.currentProduct
});

const mapDispatch = (dispatch) => ({
    updateProductRooms: (data) => dispatch(updateProductRooms(data)),
    updateProductFacilities: (data) => dispatch(updateProductFacilities(data)),
    updateProductBreakfast: (data) => dispatch(updateProductBreakfast(data)),
    updateProductHouseRules: (data) => dispatch(updateProductHouseRules(data)),
    deleteProductHouseRules: (id) => dispatch(deleteProductHouseRules(id)),
    deleteProductBreakfastDetail: (id) => dispatch(deleteProductBreakfastDetail(id)),
    deleteProductFacilities: (id) => dispatch(deleteProductFacilities(id)),
    deleteProductRooms: (id) => dispatch(deleteProductRooms(id))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AccomodationFormFields));

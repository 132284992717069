import React from 'react';
// import { Tag, Tooltip } from 'antd';
import {
    // stringSplitAndUppercase,
    toDecimal
} from 'utils';
import moment from 'moment';
import PropTypes from 'prop-types';

export default function PayoutPart(props) {
    const { paymentDetail } = props;
    return (
        <>
            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Overview</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Date paid</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.settlement_date
                                    ? moment(paymentDetail.settlement_date).format('MMM D, H:mm A')
                                    : 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Description</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.description || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Status</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.status || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Settled by</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.settled_by || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Amount</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`$${
                                paymentDetail?.total_amount
                                    ? toDecimal(paymentDetail?.total_amount / 100)
                                    : 0
                            }`}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Fee</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.application_fee_amount || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">ID</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{paymentDetail?.id || 'N/A'}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="mb-4">
                <div className="d-flex align-items-center">
                    <div className="pr-2">
                        <span>
                            <svg
                                height="32"
                                width="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fillRule="evenodd">
                                    <path d="M0 0h32v32H0z" fill="#e3e8ee"></path>
                                    <path
                                        d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z"
                                        fill="#697386"></path>
                                </g>
                            </svg>
                        </span>
                    </div>
                    <div>
                        <div>
                            <span className="fs-14 fw-600 pr-2 lh-1-3">
                                {paymentDetail?.bank_name || ''}
                            </span>
                            <span>
                                <Tag color="gray">
                                    {paymentDetail?.currency
                                        ? paymentDetail.currency.toUpperCase()
                                        : ''}
                                </Tag>
                            </span>
                            {paymentDetail?.default_for_currency && (
                                <span>
                                    <Tooltip
                                        title={<div>This is default account for payments</div>}>
                                        <Tag color="blue">Default</Tag>
                                    </Tooltip>
                                </span>
                            )}
                        </div>
                        <div>
                            <span style={{ fontFamily: 'monospace' }}>
                                <span>{paymentDetail?.routing_number}</span> &middot; &middot;
                                &middot; <span>{paymentDetail?.last4}</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div style={{ paddingLeft: 30 }}>
                    <div>
                        <div className="payment-stats-data-row">
                            <div className="payment-stats-data-header">
                                <span className="text-muted fs-14">type</span>
                            </div>
                            <div className="payment-stats-data-info">
                                <span className="text-gray fs-14">
                                    {paymentDetail?.object
                                        ? stringSplitAndUppercase(paymentDetail?.object)
                                        : ''}
                                </span>
                            </div>
                        </div>
                        <div className="payment-stats-data-row">
                            <div className="payment-stats-data-header">
                                <span className="text-muted fs-14">Created</span>
                            </div>
                            <div className="payment-stats-data-info">
                                <span className="text-gray fs-14">
                                    {paymentDetail?.created || 'N/A'}
                                </span>
                            </div>
                        </div>
                        <div className="payment-stats-data-row">
                            <div className="payment-stats-data-header">
                                <span className="text-muted fs-14">Origin</span>
                            </div>
                            <div className="payment-stats-data-info">
                                <span className="text-gray fs-14">{paymentDetail?.country}</span>
                            </div>
                        </div>
                        <div className="payment-stats-data-row">
                            <div className="payment-stats-data-header">
                                <span className="text-muted fs-14">Fingerprint</span>
                            </div>
                            <div className="payment-stats-data-info">
                                <span className="text-gray fs-14">
                                    {paymentDetail?.fingerprint}
                                </span>
                            </div>
                        </div>
                        <div className="payment-stats-data-row">
                            <div className="payment-stats-data-header">
                                <span className="text-muted fs-14">ID</span>
                            </div>
                            <div className="payment-stats-data-info">
                                <span className="text-gray fs-14">{paymentDetail?.id}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         */}
        </>
    );
}

PayoutPart.propTypes = {
    paymentDetail: PropTypes.object
};

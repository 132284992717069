export const GET_PRODUCT = 'GET_PRODUCT';
export const ALL_DAY_PRODUCT_CATEGORIES = 'ALL_DAY_PRODUCT_CATEGORIES';
export const WITHIN_DAY_PRODUCT_CATEGORIES = 'WITHIN_DAY_PRODUCT_CATEGORIES';
export const GET_EXISTING_MAIN_PRODUCTS = 'GET_EXISTING_MAIN_PRODUCTS';

export const CREATE_MAIN_PRODUCT = 'CREATE_MAIN_PRODUCT';
export const CREATE_MAIN_PRODUCT_ERROR = 'CREATE_MAIN_PRODUCT_ERROR';
export const UPDATE_PACKAGE_PRODUCT = 'UPDATE_PACKAGE_PRODUCT';

export const PRODUCT_DETAIL = 'PRODUCT_DETAIL';
export const ADD_MORE_PRODUCTS = 'ADD_MORE_PRODUCTS';

export const PACKAGE_DETAIL = 'PACKAGE_DETAIL';
export const RESET_PRODUCT_REDUCER = 'RESET_PRODUCT_REDUCER';
export const SET_UPDATE_PRODUCT_FIELDS = 'SET_UPDATE_PRODUCT_FIELDS';

export const GET_ALL_ARCHIVED_PRODUCT = 'GET_ALL_ARCHIVED_PRODUCT';
export const SET_CURRENT_PRODUCT = 'SET_CURRENT_PRODUCT';

export const CREATE_ADDON_PRODUCT = 'CREATE_ADDON_PRODUCT';

export const UPDATE_PRODUCT_MEALS_DRINKS = 'UPDATE_PRODUCT_MEALS_DRINKS';
export const UPDATE_PRODUCT_LANGUAGE_OFFERS = 'UPDATE_PRODUCT_LANGUAGE_OFFERS';
export const UPDATE_PRODUCT_INCLUSION_EXCLUSION = 'UPDATE_PRODUCT_INCLUSION_EXCLUSION';
export const UPDATE_PRODUCT_COVID = 'UPDATE_PRODUCT_COVID';
export const UPDATE_PRODUCT_CALENDAR_SEASON = 'UPDATE_PRODUCT_CALENDAR_SEASON';
export const UPDATE_PRODUCT_ADVENTURES = 'UPDATE_PRODUCT_ADVENTURES';
export const UPDATE_PRODUCT_OCCASSIONS = 'UPDATE_PRODUCT_OCCASSIONS';
export const UPDATE_PRODUCT_SIGHTS = 'UPDATE_PRODUCT_SIGHTS';
export const UPDATE_PRODUCT_TRAVELLER_INFORMATION = 'UPDATE_PRODUCT_TRAVELLER_INFORMATION';

export const SELECTED_EXISTING_PRODUCT = 'SELECTED_EXISTING_PRODUCT';
export const UPDATE_PRODUCTS_CALENDAR = 'UPDATE_PRODUCTS_CALENDAR';
export const UPDATE_PRODUCT_BOOKINGS_TICKETS = 'UPDATE_PRODUCT_BOOKINGS_TICKETS';
export const UPDATE_PRODUCT_PRICING = 'UPDATE_PRODUCT_PRICING';
export const UPDATE_PRODUCT_HOUSE_RULES = 'UPDATE_PRODUCT_HOUSE_RULES';
export const UPDATE_PRODUCT_ROOMS = 'UPDATE_PRODUCT_ROOMS';
export const UPDATE_PRODUCT_FACILITIES = 'UPDATE_PRODUCT_FACILITIES';
export const UPDATE_PRODUCT_BREAKFAST = 'UPDATE_PRODUCT_BREAKFAST';
export const ATTACHED_DUPLICATED_PRODUCT = 'ATTACHED_DUPLICATED_PRODUCT';
export const CURRENT_PRODUCT_FLOW = 'CURRENT_PRODUCT_FLOW';

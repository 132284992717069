/* eslint-disable no-useless-escape */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Drawer, Tooltip } from 'antd';
import './index.scss';
import {
    updateBusinessState,
    updateCompanyInformation,
    getBusinessInformation
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConfirmEditModal from 'components/molecules/confirmation-modal';
import SaveCancel from 'components/molecules/user-account/save-cancel';
import MoreInfo from 'assets/images/more_info.svg';
import { PhoneCountryCode } from 'components/atoms';

function CompanyInformation(props) {
    const { handleCancel, visible, updateBusinessState } = props;
    const [isModalClose, setIsModalClose] = useState(false);
    const [selectCode, setSelectCode] = useState('');
    const [isSelectCode, setIsSelectCode] = useState(false);

    // const [selectCodeForMobile, setSelectCodeForMobile] = useState('');
    // const [isSelectCodeForMobile, setIsSelectCodeForMobile] = useState(false);
    // const [phoneNumber, setPhoneNumber] = useState('');

    useEffect(() => {
        // Update the document title using the browser API
        if (props.update_business) {
            updateBusinessState(false);
            setSavingForm(false);
            handleCancel();
        }
    }, [props.update_business, handleCancel, updateBusinessState]);

    useEffect(() => {
        if (props.business_info?.customer_service_phone_number) {
            let countryCode = props.business_info?.customer_service_contact_code;
            if (countryCode) {
                setSelectCode(countryCode);
                //setSelectCodeForMobile(countryCode);
            }
        }

        // if (props.business_info?.contact_number) {
        //     let countryCode = props.business_info?.country_code;
        //     if (countryCode) {
        //setSelectCode(countryCode);
        // setSelectCodeForMobile(countryCode);
        // }
        // }
    }, []);

    const checkUrl = (rule, value) => {
        if (typeof value == 'string' && value.length) {
            let isMatch = value.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            );
            if (isMatch) {
                return Promise.resolve();
            }
        }
        return Promise.reject('Please enter a valid url.');
    };

    const [form] = Form.useForm();
    const [savingForm, setSavingForm] = useState(false);
    const onFinish = () => {
        setSavingForm(true);
        let data = form.getFieldsValue();
        data.customer_service_phone_number = data.phone_number;
        data.contact_number = data.mobile_number;
        // data.country_code = selectCodeForMobile;
        data.customer_service_contact_code = selectCode;
        //phone_number not exists in api payload request
        if (data?.phone_number) delete data.phone_number;
        if (data?.selectCode) delete data.selectCode;
        if (data?.mobile_number) delete data.mobile_number;
        if (data?.selectCode1) delete data.selectCode1;

        props
            .updateCompanyInformation(data)
            .then((res) => {
                if (res?.payload) props.getBusinessInformation();
            })
            .finally(() => setSavingForm(false));
    };
    const handleModal = () => {
        const {
            business_name: form_business_name,
            business_trading_name: form_business_trading_name,
            company_website: form_company_website,
            phone_number: form_customer_service_phone_number,
            // mobile_number,
            customer_service_email: form_customer_service_email,
            business_tax_id: form_business_tax_id
        } = form.getFieldsValue();
        const {
            business_name,
            business_trading_name,
            company_website,
            customer_service_phone_number,
            customer_service_email,
            business_tax_id
            // customer_mobile_number
        } = props.business_info;

        let numberAndCode = '';
        if (form_customer_service_phone_number && selectCode) {
            numberAndCode = selectCode + '-' + form_customer_service_phone_number;
        }

        // let customerMobileAndCode = '';
        // if (mobile_number && selectCodeForMobile) {
        //     customerMobileAndCode = selectCodeForMobile + '-' + mobile_number;
        // }

        if (
            form_business_name !== business_name ||
            form_business_trading_name !== business_trading_name ||
            form_company_website !== company_website ||
            numberAndCode !== customer_service_phone_number ||
            // customerMobileAndCode !== customer_mobile_number ||
            form_customer_service_email !== customer_service_email ||
            form_business_tax_id !== business_tax_id
        ) {
            return setIsModalClose(!isModalClose);
        }
        return handleCancel();
    };
    const closeAllModals = () => {
        if (visible && isModalClose) {
            handleCancel();
            form.resetFields();
            setIsModalClose(!isModalClose);
        }
    };

    const togglePhone = () => setIsSelectCode(!isSelectCode);
    // const toggleMobile = () => setIsSelectCodeForMobile(!isSelectCodeForMobile);

    // function getPhoneOrCountryCode(string, type) {
    //     string = string.split('-');
    //     if (Array.isArray(string) && string.length === 2) {
    //         if (type === 'PHONE') return string[1];
    //         else if (type === 'CODE') return string[0];
    //     } else return '';
    // }

    return (
        <div>
            {/* Edit Company Information Drawer */}
            <Drawer
                width={609}
                visible={visible}
                onClose={handleModal}
                layout="vertical"
                key="right"
                className="company-information-modal">
                <h6 className="company-information-modal__title">Edit company information</h6>
                <Form
                    form={form}
                    onValuesChange={() => {}}
                    onFinish={onFinish}
                    className="mt-3"
                    layout="vertical form-label-fw-500">
                    <Form.Item
                        label="Business name"
                        name="business_name"
                        initialValue={props.business_info ? props.business_info.business_name : ''}
                        rules={[{ required: true, message: 'Please enter business name' }]}>
                        <Input size="large" />
                    </Form.Item>

                    <Row>
                        <Col span={24}>
                            <div className="ant-form-item-label">
                                <label
                                    htmlFor="business_trading_name"
                                    className="ant-form-item-required">
                                    Business trading name
                                </label>
                                <p className="fs-12 mb-0">
                                    If you use a shorter or different name for marketing purposes,
                                    enter it here. If not, enter your legal name
                                </p>
                            </div>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="business_trading_name"
                                initialValue={
                                    props.business_info
                                        ? props.business_info.business_trading_name
                                        : ''
                                }
                                rules={[
                                    { required: true, message: 'Please enter your trading name' }
                                ]}>
                                <Input size="large" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item
                        label="Company website"
                        name="company_website"
                        initialValue={
                            props.business_info ? props.business_info.company_website : ''
                        }
                        rules={[
                            { required: true, message: 'Please enter your Company website' },
                            {
                                validator: (rule, value) =>
                                    value ? checkUrl(rule, value) : Promise.resolve()
                            }
                        ]}>
                        <Input size="large" />
                    </Form.Item>

                    <PhoneCountryCode
                        label="Customer service phone number"
                        selectedCountryCode={selectCode}
                        onChangeCountryCode={(code) => {
                            setSelectCode(code);
                            //setSelectCodeForMobile(code); //new
                            form.validateFields(['selectCode']);
                        }}
                        expandMenu={isSelectCode}
                        toggleMenu={togglePhone}
                        phoneNumber={
                            props.business_info?.customer_service_phone_number
                                ? props.business_info?.customer_service_phone_number
                                : ''
                        }
                    />

                    {/* <PhoneCountryCode
                        label="Mobile number"
                        phoneNumberFieldName="mobile_number"
                        countryCodeFieldName="selectCode1"
                        selectedCountryCode={selectCodeForMobile}
                        onChangeCountryCode={(code) => {
                            setSelectCodeForMobile(code);
                            //setSelectCode(code); //new
                            form.validateFields(['selectCode1']);
                        }}
                        expandMenu={isSelectCodeForMobile}
                        toggleMenu={toggleMobile}
                        phoneNumber={
                            props.business_info?.contact_number
                                ? props.business_info?.contact_number
                                : ''
                        }
                    /> */}

                    <Form.Item
                        label="Customer service email address"
                        name="customer_service_email"
                        initialValue={
                            props.business_info ? props.business_info.customer_service_email : ''
                        }
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'Please enter your customer service email address'
                            }
                        ]}>
                        <Input size="large" />
                    </Form.Item>

                    <div>
                        <div className="ant-form-item-label">
                            <label className="">
                                Business tax ID
                                <Tooltip
                                    overlayStyle={{ maxWidth: 320 }}
                                    title="This information is required for you to be able to process enough volume on your stripe account">
                                    <img className="ml-1 align-top" src={MoreInfo} alt="" />
                                </Tooltip>
                            </label>
                        </div>
                        <Form.Item
                            // label="Business tax ID"
                            name="business_tax_id"
                            initialValue={
                                props.business_info ? props.business_info.business_tax_id : ''
                            }
                            // rules={[{ required: true, message: 'Please enter business name' }]}
                        >
                            <Input size="large" />
                        </Form.Item>
                    </div>

                    <SaveCancel saveLoading={savingForm} cancelClick={handleModal} />
                </Form>
            </Drawer>
            {/* Confirm Edit Modal */}
            <ConfirmEditModal
                modal={isModalClose}
                handleModal={handleModal}
                closeModal={closeAllModals}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    update_business: state.profile.update_business,
    business_info: state.profile.business_info
});
const mapDispatch = (dispatch) => ({
    updateCompanyInformation: (data) => dispatch(updateCompanyInformation(data)),
    updateBusinessState: (data) => dispatch(updateBusinessState(data)),
    getBusinessInformation: () => dispatch(getBusinessInformation())
});
CompanyInformation.propTypes = {
    handleCancel: PropTypes.func,
    // handleOk: PropTypes.func,
    visible: PropTypes.bool,
    updateBusinessState: PropTypes.func,
    update_business: PropTypes.any,
    updateCompanyInformation: PropTypes.func,
    business_info: PropTypes.object,
    getBusinessInformation: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CompanyInformation));

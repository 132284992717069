import React from 'react';
import { Form, Select, Tooltip } from 'antd';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { ComponentPermissionContext } from 'context/rolesandpermission';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';

const CreateNewProduct = (props) => {
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);

    const {
        currentProductCategory,
        onChangeCategory,
        productCategories,
        allProductSubCategories,
        onChangeSubCategory,
        currentProductSubCategory,
        productCreateMethod,
        onChangeProductCreate,
        heirachy
    } = props;
    return (
        <>
            <Row>
                <Col lg={6}>
                    <Form.Item
                        className="mb-14px"
                        value={productCreateMethod}
                        name="select_products_availability"
                        rules={[{ required: true, message: 'Please select one' }]}>
                        <Select
                            className="form-control-alt d-block"
                            dropdownClassName="type-rich"
                            size="large"
                            placeholder="Select One"
                            onChange={onChangeProductCreate}
                            // getPopupContainer={(trigger) => trigger.parentElement}
                        >
                            {heirachy.map((e, key) => {
                                return (
                                    <Select.Option
                                        key={key}
                                        value={e.value}
                                        className={
                                            e.value === 'onboard_partner' &&
                                            assignComponentPermission('partner-onboarding')
                                        }
                                        disabled={e.value === 'onboard_partner' ? true : false}>
                                        {e.value === 'onboard_partner' ? (
                                            <Tooltip title="Coming Soon">
                                                <span className="disabled-link">{e.name}</span>
                                            </Tooltip>
                                        ) : (
                                            <span>{e.name}</span>
                                        )}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            {productCreateMethod === 'create_new_product' ? (
                <>
                    <Row>
                        <Col lg={6}>
                            <Form.Item
                                className="mb-14px"
                                value={currentProductCategory}
                                name="product_category_id"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select one'
                                    }
                                ]}>
                                <Select
                                    className="form-control-alt d-block"
                                    dropdownClassName="type-category"
                                    size="large"
                                    placeholder="Select Category"
                                    onChange={onChangeCategory}
                                    // getPopupContainer={(trigger) => trigger.parentElement}
                                >
                                    {productCategories &&
                                        productCategories.map((e, key) => {
                                            return (
                                                <Select.Option key={key} value={e.id}>
                                                    {e.name}
                                                </Select.Option>
                                            );
                                        })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {allProductSubCategories &&
                    Object.keys(allProductSubCategories.productSubCategories).length > 0 ? (
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    className="mb-14px"
                                    value={currentProductSubCategory}
                                    name="product_sub_category_id"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select one'
                                        }
                                    ]}>
                                    <Select
                                        className="form-control-alt d-block"
                                        dropdownClassName="type-subcategory"
                                        size="large"
                                        placeholder="Select Sub Category"
                                        onChange={onChangeSubCategory}
                                        // getPopupContainer={(trigger) => trigger.parentElement}
                                    >
                                        {allProductSubCategories &&
                                            allProductSubCategories.productSubCategories &&
                                            allProductSubCategories.productSubCategories.map(
                                                (x, key) => {
                                                    return (
                                                        <Select.Option key={key} value={x.id}>
                                                            {x.name}
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    ) : null}
                </>
            ) : null}

            {productCreateMethod === 'onboard_partner' ? <>Onboard Partner</> : null}
        </>
    );
};
CreateNewProduct.propTypes = {
    currentProductCategory: PropTypes.string,
    currentProductSubCategory: PropTypes.number,
    onChangeCategory: PropTypes.func,
    onChangeSubCategory: PropTypes.func,
    productCreateMethod: PropTypes.string,
    onChangeProductCreate: PropTypes.func,
    heirachy: PropTypes.array,
    productCategories: PropTypes.array,
    allProductSubCategories: PropTypes.object
};
export default CreateNewProduct;

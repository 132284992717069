import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreText from '../pricing/MoreText.jsx';
import DaysSelector from '../pricing/DaysSelector.jsx';
import AddPhotos from '../../../../assets/images/add_photos.svg';
import AddVideos from '../../../../assets/images/add_videos.svg';
import InfoIcon from '../../../../assets/images/info.svg';
import DamiBtn from '../pricing/DamiBtn.jsx';
import VendorDashboard from '../vendor-dashboard/index.js';
import Feedback from '../../../molecules/user-feedback';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: '41px',
        paddingLeft: '45px',
        paddingRight: '10px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    line1: {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '10px',
        color: '#333333'
    },
    line2: {
        marginBottom: '57px'
    },
    line3: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        marginBottom: '9px',
        color: '#333333'
    },
    line4: {
        width: '90%'
    },
    line5: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        marginTop: '36px',
        marginBottom: '17px',
        color: '#333333'
    },
    line6: {
        marginBottom: '36px',
        width: '90%'
    },
    line7: {
        marginTop: '36px',
        marginBottom: '9px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */

        color: '#4F4F4F'
    },
    line8: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '19px'
    },
    line9: {
        marginTop: '36px',
        marginBottom: '3px'
    },
    line10: {
        marginBottom: '26px',
        width: '100%'
    },
    addSupplement: {
        marginTop: '33px',
        marginLeft: '28px',
        marginBottom: '55px'
    },
    moreDetails: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '14px 29px'
    },
    accomodationTitle: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#333333'
    },
    recommendedPricing: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '22px',
        /* identical to box height */

        color: '#333333'
    },
    recommended: {
        background: '#A2792C',
        borderRadius: '8px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        padding: '5px 14px',
        color: '#FFFFFF'
    },
    line11: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '22px'
    },
    line12: {
        marginTop: '17px'
    },
    line13: {
        marginTop: '52px',
        marginBottom: '9px'
    },
    line14: {
        marginBottom: '17px'
    },
    line15: {
        marginTop: '17px',
        marginBottom: '38px'
    },
    line16: {
        marginTop: '52px'
    },
    lastBtnGroup: {
        marginTop: '40px',
        marginBottom: '93px'
    }
}));

export default function AddTransportOption() {
    const classes = useStyles();
    const [photos, setPhotos] = useState([]);
    const [videos, setVideos] = useState([]);

    const saveImage = (e) => {
        let images = e.target.files;
        let tempImages = [...photos];
        for (let i = 0; i < images.length; i++) {
            let name = images[i].name;
            let source = URL.createObjectURL(images[i]);
            tempImages.push({ name: name, source: source });
        }

        setPhotos([...new Set(tempImages)]);
    };

    const saveVideo = (e) => {
        let vids = e.target.files;
        let tempVids = [...videos];
        for (let i = 0; i < vids.length; i++) {
            let name = vids[i].name;
            let source = URL.createObjectURL(vids[i]);
            tempVids.push({ name: name, source: source });
        }

        setVideos([...new Set(tempVids)]);
    };

    return (
        <VendorDashboard>
            <Grid container className={classes.root} direction="column">
                <Grid item className={classes.line1}>
                    Add an Airport Transfer / Pickup
                </Grid>
                <Grid item className={classes.line2}>
                    <MoreText
                        customStyle={{
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */

                            color: '#A2792C'
                        }}
                        text="I will import Airport Transfer / Pickup information from my other transportation "
                    />
                </Grid>
                <Grid item className={classes.line3}>
                    Add Airport Transfer / Pickup title
                </Grid>
                <Grid item className={classes.line4}>
                    <input
                        style={{
                            width: '100%',
                            border: '0.5px solid #A2792C',
                            boxSizing: 'border-box',
                            borderRadius: '8px',
                            height: '50px',
                            padding: '13px'
                        }}
                        type="text"
                        placeholder="kruger national park safari, South Africa"
                    />
                </Grid>
                <Grid item className={classes.line5}>
                    Describe what travelers will see and do here if they book your experience
                </Grid>
                <Grid item className={classes.line6}>
                    <textarea
                        style={{
                            border: '0.5px solid #979797',
                            borderRadius: '8px',
                            width: '100%',
                            height: '139px',
                            padding: '20px'
                        }}
                        placeholder={`Enter a description about this Itinerary only.  For example, "Our accredited guide will meet you at the ticket booth and will accompany you up the tower, explaining what you'll see at each level."
Description is required.`}></textarea>
                </Grid>
                <Grid item className={classes.line7}>
                    Select the customers calendar days this booking apply
                </Grid>
                <Grid item className={classes.line8}>
                    This product will be availlable for customers to book on the days selected
                    below.
                </Grid>
                <Grid item>
                    <DaysSelector />
                </Grid>
                <Grid item className={classes.line9}>
                    Add Photos &amp; Videos
                </Grid>
                <Grid item className={classes.line10}>
                    Use photos that highlight what’s unique about your experience and show travelers
                    having fun. Adding 6-9 photos and at least 1 video can increase your bookings.
                </Grid>
                <Grid item>
                    <Grid container spacing={4}>
                        <Grid item>
                            <Grid
                                container
                                style={{
                                    backgroundImage: `url(${AddPhotos})`,
                                    backgroundSize: 'contain',
                                    width: '236px',
                                    height: '174px',
                                    marginRight: '10px'
                                }}>
                                <input
                                    type="file"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0
                                    }}
                                    onChange={saveImage}
                                    multiple
                                />
                            </Grid>
                            <Grid item>
                                <div>
                                    {photos.map((each, index) => (
                                        <img
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '5px',
                                                width: '50px',
                                                height: '50px'
                                            }}
                                            key={index}
                                            src={each.source}
                                            alt={each.name}
                                        />
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid
                                container
                                style={{
                                    backgroundImage: `url(${AddVideos})`,
                                    backgroundSize: 'contain',
                                    width: '236px',
                                    height: '174px',
                                    marginRight: '10px'
                                }}>
                                <input
                                    type="file"
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        opacity: 0
                                    }}
                                    onChange={saveVideo}
                                    multiple
                                />
                            </Grid>
                            <Grid item>
                                <div>
                                    {videos.map((each, index) => (
                                        <img
                                            style={{
                                                display: 'inline-block',
                                                marginRight: '5px',
                                                width: '50px',
                                                height: '50px'
                                            }}
                                            key={index}
                                            src={each.source}
                                            alt={each.name}
                                        />
                                    ))}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.addSupplement}>
                    <div className={classes.moreDetails}>
                        <Grid container justify="space-between">
                            <Grid item className={classes.recommendedPricing}>
                                Inclusions &amp; Exclusions
                                <img src={InfoIcon} />
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                            <hr />
                        </div>
                        <Grid container>
                            <MoreText
                                customStyle={{
                                    fontFamily: 'Poppins',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    /* identical to box height */

                                    color: '#333333'
                                }}
                                text="Add inclusions &amp; exclusions"
                            />
                        </Grid>
                    </div>
                </Grid>
                <Grid item className={classes.line11}>
                    Now tell us where you meet travelers for this mode of transportation?
                    <img src={InfoIcon} />
                </Grid>
                <Grid item>
                    <Grid container>
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="huey"
                                name="pickup"
                                value="huey"
                            />
                            <label htmlFor="huey">
                                Allow travellers to enter their their own pick up location. Do not
                                select this, if you only pick up from the starting point you
                                identified.
                            </label>
                        </Grid>
                        <Grid item>
                            <input
                                style={{
                                    marginRight: '10px'
                                }}
                                type="radio"
                                id="dewey"
                                name="pickup"
                                value="dewey"
                                defaultChecked
                            />
                            <label htmlFor="dewey">At a Starting Point</label>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.line12}>
                    <MoreText
                        customStyle={{
                            fontFamily: 'Poppins',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            fontSize: '16px',
                            lineHeight: '20px',
                            /* identical to box height */

                            color: '#333333'
                        }}
                        text="Add another starting point"
                    />
                </Grid>
                <Grid item className={classes.line13}>
                    Enter the maximum number of spaces available to book this transportation per day
                </Grid>
                <Grid item className={classes.line14}>
                    This will indicate the spaces available to travelers and once this is reached,
                    this transport medium will be marked as sold out.
                </Grid>
                <Grid item className={classes.line15}>
                    <input
                        type="number"
                        style={{
                            border: '0.5px solid #C64545',
                            boxSizing: 'border-box',
                            borderRadius: '5px',
                            width: '68px',
                            height: '48px'
                        }}
                    />
                </Grid>
                <Grid item>
                    <hr />
                </Grid>
                <Grid item className={classes.line16}>
                    {[
                        ['Pricing', 'Add Pricing details for your ‘Transportaion Title’', '#'],
                        [
                            'Booking and Tickets',
                            'Add Booking and Ticketing details  for your  ‘Transportaion Title’',
                            '#'
                        ],
                        [
                            'Information travellers need from you',
                            'Add any physical restrictions for this  ‘Transportaion Title’',
                            '#'
                        ],
                        ['Covid-19 Updates', 'Add Covid-19 safety updates', '#']
                    ].map((each, index) => (
                        <Grid item className={classes.addSupplement}>
                            <div className={classes.moreDetails}>
                                <Grid container justify="space-between">
                                    <Grid item className={classes.recommendedPricing}>
                                        {each[0]}
                                        <img src={InfoIcon} />
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                                    <hr />
                                </div>
                                <Grid container>
                                    <MoreText
                                        customStyle={{
                                            fontFamily: 'Poppins',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '16px',
                                            lineHeight: '20px',
                                            /* identical to box height */

                                            color: '#333333'
                                        }}
                                        text={each[1]}
                                    />
                                </Grid>
                            </div>
                        </Grid>
                    ))}
                </Grid>
                <Grid item container justify="space-between" className={classes.lastBtnGroup}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <DamiBtn text="Done" />
                            </Grid>
                            <Grid item>
                                <DamiBtn
                                    text="Cancel"
                                    customStyle={{ background: '#FFFFFF', color: '#A2792C' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {/* <DamiBtn text={`Help`} /> */}
                        <Feedback />
                    </Grid>
                </Grid>
            </Grid>
        </VendorDashboard>
    );
}

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from 'antd';
import WarningIcon from '../../../assets/images/warning-icon.svg';
import PropTypes from 'prop-types';
import { reinstateSale } from 'store/actions/availabilityActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Reinstatesale = ({
    isOpen,
    confirmationWindow,
    handleReinstateSaleToggle,
    reinstateSale,
    refreshList,
    openedRecord
}) => {
    const toggleReinstateSale = () => {
        handleReinstateSaleToggle();
    };
    const openLastWindow = () => {
        if (confirmationWindow) {
            let data = {
                id: openedRecord?.id
            };
            reinstateSale(data)
                .then(() => {
                    refreshList();
                    toggleReinstateSale();
                })
                .catch((e) => console.error(e));
        }
    };

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleReinstateSale}>
            <ModalBody className="text-center">
                <img src={WarningIcon} alt="" />
                <div className="modal-body-title-lg mt-2">
                    {confirmationWindow && 'Reinstate Sale?'}
                </div>
                <p className="modal-body-text">
                    {confirmationWindow && (
                        <span>Are you sure you want to Reinstate sale of this product?</span>
                    )}
                </p>
                <div className="mt-4">
                    {confirmationWindow && (
                        <Button
                            id="pauseSaleTooltip"
                            type="primary"
                            className="btn-mw mx-2"
                            onClick={openLastWindow}>
                            Reinstate Sale
                        </Button>
                    )}
                    <Button
                        type="dark"
                        className="btn-mw mx-2"
                        onClick={() => toggleReinstateSale()}>
                        {confirmationWindow ? 'NO' : 'OK'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

Reinstatesale.propTypes = {
    isOpen: PropTypes.bool,
    canNotArchive: PropTypes.bool,
    confirmationWindow: PropTypes.bool,
    setConfirmationWindow: PropTypes.func,
    setCanNotArchive: PropTypes.func,
    handleReinstateSaleToggle: PropTypes.func,
    refreshList: PropTypes.func,
    openedRecord: PropTypes.any,
    reinstateSale: PropTypes.func
};
const mapDispatch = (dispatch) => ({
    reinstateSale: (params) => dispatch(reinstateSale(params))
});
export default withRouter(connect(null, mapDispatch)(Reinstatesale));

import React from 'react';
import PropTypes from 'prop-types';

const ActivityIcon = ({ color }) => {
    return (
        <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.447 2.96558C17.2463 2.29851 16.8299 1.7135 16.2604 1.2988C15.6909 0.884102 14.9994 0.662199 14.29 0.666567C13.4679 0.667287 12.6758 0.969474 12.0694 1.51373C11.463 2.05798 11.0861 2.80492 11.013 3.60773H8.988C8.91386 2.80408 8.53579 2.05668 7.9281 1.51244C7.32042 0.968194 6.52707 0.666468 5.704 0.666567C4.99558 0.663158 4.30521 0.885529 3.73689 1.30018C3.16857 1.71484 2.75308 2.29932 2.553 2.96558L0.0420001 11.1695C0.0144929 11.2608 0.000351839 11.3556 0 11.4508V12.4312C0.00158836 13.731 0.52902 14.9771 1.46657 15.8961C2.40413 16.815 3.67523 17.3319 5.001 17.3332C5.97268 17.3315 6.92285 17.0525 7.73547 16.5302C8.54809 16.0079 9.18798 15.265 9.577 14.392H10.423C10.812 15.265 11.4519 16.0079 12.2645 16.5302C13.0771 17.0525 14.0273 17.3315 14.999 17.3332C16.3249 17.3319 17.5962 16.8149 18.5338 15.8957C19.4714 14.9765 19.9987 13.7302 20 12.4302V11.4508C20 11.3548 19.985 11.2606 19.958 11.1695L17.447 2.96558ZM5.001 15.3724C4.20558 15.3714 3.44303 15.0612 2.88049 14.5099C2.31796 13.9585 2.00132 13.211 2 12.4312C2 12.3567 2.017 12.2871 2.022 12.2136C2.07671 11.4752 2.41394 10.7842 2.96616 10.2792C3.51838 9.77418 4.24482 9.49232 5 9.49006C5.79565 9.49006 6.55871 9.79993 7.12132 10.3515C7.68393 10.9031 8 11.6512 8 12.4312V12.4401C7.99657 13.2181 7.67925 13.9632 7.11734 14.5126C6.55543 15.062 5.7946 15.3711 5.001 15.3724ZM14.999 15.3724C14.2054 15.3711 13.4446 15.062 12.8827 14.5126C12.3208 13.9632 12.0034 13.2181 12 12.4401V12.4312C11.9999 12.045 12.0775 11.6625 12.2282 11.3057C12.3789 10.9488 12.5998 10.6245 12.8783 10.3514C13.4408 9.7997 14.2039 9.4897 14.9995 9.48957C15.7951 9.48944 16.5583 9.79919 17.121 10.3507C17.6837 10.9022 17.9999 11.6502 18 12.4302C17.9989 13.2102 17.6824 13.958 17.1199 14.5095C16.5573 15.061 15.7946 15.3714 14.999 15.3724Z"
                fill={color || 'white'}
            />
        </svg>
    );
};

ActivityIcon.propTypes = {
    color: PropTypes.any
};

export default ActivityIcon;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Button as AntButton, Col, Drawer, Form, Input, Row } from 'antd';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import './index.scss';
import WhereCanPeopleSleep from '../where-sleep';
import AddFacilities from '../add-facilities';
import BreakfastDetails from '../breakfast-details';
import HouseRules from '../house-rules';
import PhysicalRestriction from '../physical-restriction';
import AddReviews from '../add-reviews';
import ImportAccomodation from '../../../../atoms/import-accomodation';
import Covid from '../covid';
import Pricing from '../pricing';
import Spacer from '../../../../atoms/spacer';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import { getCalendars } from '../../../../../store/actions/calendarActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Gallery from '../../../gallery';
import Plus from '../../../../../assets/images/plus.svg';
import DeleteIcon from '../../../../../assets/images/close.svg';
import {
    clearUploadedFile,
    fileUpload,
    getFiles,
    updateMainProduct
} from '../../../../../store/actions/contentActions';
import AddInformationButton from '../../../../atoms/add-information';
import AddRoomsButton from '../../../../atoms/add-rooms';
import AddBreakfastButton from '../../../../atoms/add-breakfast';
import AddFacilitiesButton from '../../../../atoms/add-facilities';
import AddHouseRulesButton from '../../../../atoms/add-house-rules';
import AddCovidButton from '../../../../atoms/add-covid';
import AddReviewsButton from '../../../../atoms/add-reviews';
import AddBookingButton from '../../../../atoms/add-booking';
import AddPricingButton from '../../../../atoms/add-pricing-accomodation';
import GoogleAutoComplete from '../../../../atoms/google-autocomplete';
import Proptypes from 'prop-types';
import BookingTickets from '../../booking-tickets';

const accomodation = [
    {
        name: 'Villa/Mansion'
    },
    {
        name: 'Hotel'
    },
    {
        name: 'Apartment'
    },
    {
        name: 'Camping'
    },
    {
        name: 'Bread & Breakfast'
    },
    {
        name: 'Chalet'
    },
    {
        name: 'Hostel'
    },
    {
        name: 'Log Cabin'
    },
    {
        name: 'Country Home'
    },
    {
        name: 'Timeshare Rentals'
    },
    {
        name: 'Cottage'
    },
    {
        name: 'Others'
    }
];

class AccomodationMinipage extends Component {
    formRefAccomodation = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            title: '',
            titleGallery: '',
            media: null,
            isOpenGallery: false,
            isOpenCategory: false,
            category: '',
            description: '',
            name: '',
            err: false,
            calendars: null,
            isOpenWhereCanPeopleSleep: false,
            isOpenAddFacilities: false,
            isOpenBreakfastDetails: false,
            isOpenHouseRules: false,
            isOpenPhysicalRestriction: false,
            isOpenCovid: false,
            isOpenPricing: false,
            isOpenBookingTickets: false,
            isOpenAddReviews: false,
            booking: null,
            reviews: null,
            whereCanPeopleSleep: null,
            facilities: null,
            breakfastDetails: null,
            houseRules: null,
            physicalRestriction: null,
            covid: null,
            pricing: null,
            address: null,
            otherCategory: '',
            destination: ''
        };
    }
    componentDidMount() {
        this.props.getCalendars();
    }

    onClose = () => this.props.onClose();
    //set import data
    setImportAccomodation = (item) => {
        this.setState({
            title: item.title,
            description: item.description,
            name: item.name,
            category: item.type || '',
            address: {
                address: item?.address,
                city: item?.city,
                country: item?.country
            },
            whereCanPeopleSleep: item.rooms,
            breakfastDetails: item.breakfast_details,
            facilities: item.facilities,
            houseRules: item.house_rules,
            physicalRestriction: item.traveller_information,
            covid: item.covid19_updates,
            media: item.photos_and_videos
        });
        this.formRefAccomodation.current.setFieldsValue({
            category: item.type || '',
            description: item.description,
            title: item.title,
            destination: `${item?.address}, ${item?.country}, ${item?.city}`,
            media: item.photos_and_videos,
            whereCanPeopleSleep: item.rooms,
            covid: item.covid19_updates
        });
    };

    onSelectCategory = (category) => {
        this.setState({ category: category });
        this.formRefAccomodation.current.setFieldsValue({
            category: category
        });
    };
    onOpenCategory = () => this.setState({ isOpenCategory: !this.state.isOpenCategory });

    //upload new media
    onChange = (e) => {
        let file = e;
        let form_data = new FormData();
        form_data.append('media', file);
        this.props.fileUpload(form_data);
    };

    onSaveMedia = (value) => this.setState({ media: value, isOpenGallery: false });

    onDeleteMedia = (index) => {
        const { media } = this.state;
        let tmp = media;
        tmp.splice(index, 1);
        this.setState({ media: tmp });
    };
    onUpdateMainProduct = (data) => {
        const { calendar_data, updateMainProduct } = this.props;

        if (calendar_data && calendar_data.data) {
            const selectCalendar = window.location.pathname.substring(
                window.location.pathname.lastIndexOf('/') + 1
            );
            let tmp = calendar_data.data.find((res) => parseInt(selectCalendar) === res.id);
            if (tmp?.mainProduct) {
                updateMainProduct(tmp.mainProduct.id, {
                    product_category: 'accomodation',
                    ...data
                });
            }
        }
    };

    onOpenGallery = () => this.setState({ isOpenGallery: !this.state.isOpenGallery });
    changeOpenWhereCanPeopleSleep = () =>
        this.setState({ isOpenWhereCanPeopleSleep: !this.state.isOpenWhereCanPeopleSleep });
    changeOpenAddFacilities = () =>
        this.setState({ isOpenAddFacilities: !this.state.isOpenAddFacilities });
    changeOpenBreakfastDetails = () =>
        this.setState({ isOpenBreakfastDetails: !this.state.isOpenBreakfastDetails });
    changeOpenHouseRules = () => this.setState({ isOpenHouseRules: !this.state.isOpenHouseRules });
    changeOpenPhysicalRestriction = () =>
        this.setState({ isOpenPhysicalRestriction: !this.state.isOpenPhysicalRestriction });
    changeOpenCovid = () => this.setState({ isOpenCovid: !this.state.isOpenCovid });
    changeOpenPricing = () => this.setState({ isOpenPricing: !this.state.isOpenPricing });
    changeOpenBookingTickets = () =>
        this.setState({ isOpenBookingTickets: !this.state.isOpenBookingTickets });
    changeOpenAddReviews = () => this.setState({ isOpenAddReviews: !this.state.isOpenAddReviews });
    shapingData = () => {
        const {
            category,
            otherCategory,
            description,
            name,
            media,
            whereCanPeopleSleep,
            facilities,
            breakfastDetails,
            houseRules,
            address,
            covid,
            pricing,
            reviews,
            physicalRestriction
        } = this.state;

        const tmpMedia = [];
        //parse to api media
        if (media && media.length > 0)
            media.map((res) => {
                tmpMedia.push({ type: res.type, url: res.url });
            });
        return {
            product_category: 'accomodation',
            type: category !== 'Other' ? category : otherCategory,
            description: description,
            name: name,
            address: address?.address || '',
            city: address?.city || '',
            country: address?.country || '',
            photos_and_videos: tmpMedia,
            rooms: whereCanPeopleSleep,
            facilities: facilities,
            breakfast_details: breakfastDetails,
            house_rules: houseRules,
            covid19_updates: covid,
            accomodation_price: pricing,
            review_links: reviews,
            traveller_information: physicalRestriction
        };
    };
    onFinish = () => {
        const { onClose, updateMainProduct, calendar_data } = this.props;

        if (calendar_data && calendar_data.data) {
            const selectCalendar = window.location.pathname.substring(
                window.location.pathname.lastIndexOf('/') + 1
            );
            let tmp = calendar_data.data.find((res) => parseInt(selectCalendar) === res.id);
            if (tmp?.mainProduct) {
                updateMainProduct(tmp.mainProduct.id, this.shapingData());
                onClose();
            }
        }
    };
    render() {
        const {
            requiredMark,
            category,
            isOpenCategory,
            isOpenGallery,
            media,
            titleGallery,
            name,
            isOpenWhereCanPeopleSleep,
            otherCategory,
            isOpenAddFacilities,
            isOpenBreakfastDetails,
            isOpenHouseRules,
            isOpenPhysicalRestriction,
            isOpenCovid,
            isOpenPricing,
            isOpenAddReviews,
            description,
            whereCanPeopleSleep,
            facilities,
            breakfastDetails,
            houseRules,
            physicalRestriction,
            isOpenBookingTickets,
            covid,
            reviews,
            booking,
            pricing
        } = this.state;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application accomodation-minipage"
                    onClose={() => {
                        this.onClose();
                    }}
                    visible={this.props.showModal}
                    bodyStyle={{ paddingBottom: 35, paddingTop: 19 }}>
                    <Form
                        ref={this.formRefAccomodation}
                        name="control-ref"
                        layout="vertical"
                        form={this.formRefAccomodation.current}
                        initialValues={{
                            requiredMark
                        }}
                        onFinish={this.onFinish}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}>
                        <Row>
                            <Col>
                                <h4 className="create-product-title">
                                    Tell us about your ‘Main Product’ Accommodation
                                </h4>
                                <ImportAccomodation
                                    calendars={this.props.calendar_data}
                                    onMainProduct={(item) => this.setImportAccomodation(item)}
                                    import="I will import accomodation information from my other activity"
                                />
                                <Spacer top={32} />
                                <div className="subTitle">Accomodation type</div>
                                <div className="subTitle fs-14">
                                    What type of accommodation are you providing to your travelers?
                                </div>

                                <Spacer top={21} />

                                <Form.Item
                                    name="category"
                                    valuePropName="checked"
                                    className="select-category"
                                    rules={[
                                        {
                                            validator: async (_, category) => {
                                                if (
                                                    !category ||
                                                    (category === 'Others' &&
                                                        this.state.otherCategory.trim() === '')
                                                )
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <Dropdown isOpen={isOpenCategory} toggle={this.onOpenCategory}>
                                        <DropdownToggle className="dropdown-category fs-14" caret>
                                            {category !== ''
                                                ? category
                                                : 'Select accomodation type'}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ width: '100%' }}>
                                            {accomodation && accomodation.length > 0
                                                ? accomodation.map((res, i) => (
                                                      <DropdownItem
                                                          key={i}
                                                          onClick={() =>
                                                              this.onSelectCategory(res.name)
                                                          }
                                                          className="category-item">
                                                          {res.name}
                                                      </DropdownItem>
                                                  ))
                                                : null}
                                        </DropdownMenu>
                                    </Dropdown>
                                    {category === 'Others' ? (
                                        <Input
                                            placeholder="Enter Others"
                                            className="input-type"
                                            value={otherCategory}
                                            onChange={(e) => {
                                                this.setState({ otherCategory: e.target.value });
                                                this.formRefAccomodation.current.setFieldsValue({
                                                    otherCategory: e.target.value
                                                });
                                            }}
                                        />
                                    ) : null}
                                </Form.Item>

                                <Spacer top={26} />

                                <Form.Item
                                    name="description"
                                    style={{ marginLeft: '5px' }}
                                    rules={[
                                        {
                                            validator: async (_, description) => {
                                                if (
                                                    !description ||
                                                    description.trim() === '' ||
                                                    description.length < 120
                                                )
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <p className="subTitle" style={{ marginLeft: '2px' }}>
                                        Description
                                    </p>
                                    <p className="subTitle fs-14" style={{ marginLeft: '2px' }}>
                                        Describe the basics of where travelers will stay overnight,
                                        e.g. the name, or details about the lodging.
                                    </p>
                                    <Input.TextArea
                                        className="input-area fs-14"
                                        placeholder="e.g. ‘ Overnight at Yosemite Lodge, a 3-star hotel’"
                                        value={description}
                                        onChange={(e) => {
                                            this.setState({ description: e.target.value });
                                            this.formRefAccomodation.current.setFieldsValue({
                                                description: e.target.value
                                            });
                                        }}
                                    />
                                    <div className="description-input">120 characters needed</div>
                                </Form.Item>

                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            validator: async (_, title) => {
                                                if (title.trim() === '')
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <div className="input-title">Accomodation Name</div>
                                    <Input
                                        className="fs-14"
                                        placeholder="Enter accomodation name"
                                        style={{
                                            border: '0.5px solid #A2792C',
                                            height: 50,
                                            borderRadius: 8
                                        }}
                                        value={name}
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                            this.formRefAccomodation.current.setFieldsValue({
                                                title: e.target.value
                                            });
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="destination"
                                    rules={[
                                        {
                                            validator: async (_, destination) => {
                                                if (!destination)
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <div className="input-title">Accomodation Location</div>
                                    <GoogleAutoComplete
                                        fontSize="14px"
                                        placeholder="Enter accomodation address"
                                        onSelectDistance={(value) => {
                                            this.setState({
                                                destination: value.description,
                                                address: value
                                            });
                                            this.formRefAccomodation.current.setFieldsValue({
                                                destination: value.description
                                            });
                                        }}
                                    />
                                </Form.Item>

                                <Spacer top={15} />

                                <Form.Item
                                    name="media"
                                    rules={[
                                        {
                                            validator: async (_, media) => {
                                                if (!media)
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <Gallery
                                        showModal={isOpenGallery}
                                        media={media}
                                        title={titleGallery}
                                        onClose={this.onOpenGallery}
                                        onSaveMedia={(value) => {
                                            this.onSaveMedia(value);
                                            this.formRefAccomodation.current.setFieldsValue({
                                                media: value
                                            });
                                        }}
                                    />
                                    <div className="d-flex flex-wrap gap-3 mb-3">
                                        <AntButton
                                            className="dash-btn"
                                            style={{ marginLeft: 0 }}
                                            id="addPhotos"
                                            type="dashed"
                                            onClick={() => {
                                                this.onOpenGallery();
                                                this.setState({ titleGallery: 'My Images' });
                                            }}>
                                            <img
                                                className="cursor-pointer img-plus"
                                                src={Plus}
                                                alt=""
                                            />{' '}
                                            Add Photos
                                        </AntButton>
                                        <AntButton
                                            className="dash-btn"
                                            style={{ marginLeft: 10 }}
                                            id="addVideos"
                                            type="dashed"
                                            onClick={() => {
                                                this.onOpenGallery();
                                                this.setState({ titleGallery: 'My Videos' });
                                            }}>
                                            <img
                                                className="cursor-pointer img-plus"
                                                src={Plus}
                                                alt=""
                                            />{' '}
                                            Add Videos
                                        </AntButton>
                                    </div>
                                    <Row>
                                        {media && media.length > 0
                                            ? media.map((res, i) => (
                                                  <div key={i} className="select-media">
                                                      <img src={res.url} alt="" />
                                                      <div className="delete-block">
                                                          <img
                                                              src={DeleteIcon}
                                                              className="delete-icon cursor-pointer"
                                                              alt=""
                                                              onClick={() => this.onDeleteMedia(i)}
                                                          />
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                    </Row>
                                </Form.Item>

                                <Spacer top={30} />

                                <div className="subTitle">
                                    What can customers use at this accomodation?
                                </div>

                                <Spacer top={15} />

                                <Form.Item>
                                    <Form.Item
                                        name="whereCanPeopleSleep"
                                        style={{ margin: '0 !important' }}
                                        rules={[
                                            {
                                                validator: async (_, whereCanPeopleSleep) => {
                                                    if (!whereCanPeopleSleep)
                                                        return Promise.reject(
                                                            new Error('Please fill this field in')
                                                        );
                                                }
                                            }
                                        ]}>
                                        <AddRoomsButton
                                            name="Where can people sleep?"
                                            add="Add Rooms"
                                            data={whereCanPeopleSleep}
                                            onClick={this.changeOpenWhereCanPeopleSleep}
                                            onDelete={() =>
                                                this.setState({ whereCanPeopleSleep: null })
                                            }
                                        />
                                    </Form.Item>
                                    <AddFacilitiesButton
                                        name="What can your customers use?"
                                        data={facilities}
                                        add="Add Facilities"
                                        onClick={this.changeOpenAddFacilities}
                                        onDelete={() => this.setState({ facilities: null })}
                                    />
                                    <AddBreakfastButton
                                        name="Breakfast details"
                                        data={breakfastDetails}
                                        onClick={this.changeOpenBreakfastDetails}
                                        onDelete={() => this.setState({ breakfastDetails: null })}
                                    />
                                    <AddHouseRulesButton
                                        name="House rules"
                                        data={houseRules}
                                        onDelete={() => this.setState({ houseRules: null })}
                                        onClick={this.changeOpenHouseRules}
                                    />
                                </Form.Item>

                                <hr style={{ marginTop: 20, marginBottom: 25 }} />

                                <Form.Item>
                                    <AddPricingButton
                                        name="Pricing"
                                        onClick={this.changeOpenPricing}
                                        data={pricing}
                                        onDelete={() => this.setState({ pricing: null })}
                                    />
                                    <AddBookingButton
                                        data={booking}
                                        onDelete={() => this.setState({ booking: null })}
                                        name="Booking & Tickets"
                                        onClick={this.changeOpenBookingTickets}
                                    />
                                    <AddInformationButton
                                        name="Information travellers need from you"
                                        data={physicalRestriction}
                                        onDelete={() =>
                                            this.setState({ physicalRestriction: null })
                                        }
                                        onClick={this.changeOpenPhysicalRestriction}
                                    />
                                    <Form.Item
                                        style={{ margin: '0 !important' }}
                                        name="covid"
                                        rules={[
                                            {
                                                validator: async (_, covid) => {
                                                    if (!covid)
                                                        return Promise.reject(
                                                            new Error('Please fill this field in')
                                                        );
                                                }
                                            }
                                        ]}>
                                        <AddCovidButton
                                            data={covid}
                                            onDelete={() => this.setState({ covid: null })}
                                            name="Covid-19 Updates"
                                            onClick={this.changeOpenCovid}
                                        />
                                    </Form.Item>
                                    <AddReviewsButton
                                        name="Add Reviews"
                                        data={reviews}
                                        onDelete={() => this.setState({ reviews: null })}
                                        isRecommended={true}
                                        onClick={this.changeOpenAddReviews}
                                    />
                                </Form.Item>

                                <WhereCanPeopleSleep
                                    data={whereCanPeopleSleep}
                                    isOpen={isOpenWhereCanPeopleSleep}
                                    onClose={this.changeOpenWhereCanPeopleSleep}
                                    onSave={(rooms) => {
                                        this.setState({
                                            whereCanPeopleSleep: rooms,
                                            isOpenWhereCanPeopleSleep: false
                                        });
                                        this.formRefAccomodation.current.setFieldsValue({
                                            whereCanPeopleSleep: rooms
                                        });
                                        this.onUpdateMainProduct({ rooms: rooms });
                                    }}
                                />
                                <AddFacilities
                                    isOpen={isOpenAddFacilities}
                                    onClose={this.changeOpenAddFacilities}
                                    data={facilities}
                                    onSave={(value) => {
                                        this.setState({
                                            facilities: value,
                                            isOpenAddFacilities: false
                                        });
                                    }}
                                />
                                <BreakfastDetails
                                    isOpen={isOpenBreakfastDetails}
                                    onClose={this.changeOpenBreakfastDetails}
                                    data={breakfastDetails}
                                    onSave={(value) => {
                                        this.setState({
                                            breakfastDetails: value,
                                            isOpenBreakfastDetails: false
                                        });
                                    }}
                                />
                                <HouseRules
                                    isOpen={isOpenHouseRules}
                                    onClose={this.changeOpenHouseRules}
                                    data={houseRules}
                                    onSave={(value) =>
                                        this.setState({
                                            houseRules: value,
                                            isOpenHouseRules: false
                                        })
                                    }
                                />
                                <PhysicalRestriction
                                    isOpen={isOpenPhysicalRestriction}
                                    onClose={this.changeOpenPhysicalRestriction}
                                    data={physicalRestriction}
                                    onSave={(value) =>
                                        this.setState({
                                            physicalRestriction: value,
                                            isOpenPhysicalRestriction: false
                                        })
                                    }
                                />
                                <Covid
                                    isOpen={isOpenCovid}
                                    onClose={this.changeOpenCovid}
                                    data={covid}
                                    calendarData={this.props.calendar_data}
                                    onSave={(value) => {
                                        this.setState({ covid: value, isOpenCovid: false });
                                        this.formRefAccomodation.current.setFieldsValue({
                                            covid: value
                                        });
                                    }}
                                />
                                <Pricing
                                    isOpen={isOpenPricing}
                                    onClose={this.changeOpenPricing}
                                    calendarData={this.props.calendar_data}
                                    data={pricing}
                                    onSave={(value) =>
                                        this.setState({ pricing: value, isOpenPricing: false })
                                    }
                                />
                                <BookingTickets
                                    data={booking}
                                    ticketData={this.shapingData()}
                                    isOpen={isOpenBookingTickets}
                                    onSave={(value) => this.setState({ booking: value })}
                                    onClose={this.changeOpenBookingTickets}
                                />
                                <AddReviews
                                    data={reviews}
                                    isOpen={isOpenAddReviews}
                                    onSave={(value) =>
                                        this.setState({ reviews: value, isOpenAddReviews: false })
                                    }
                                    onClose={this.changeOpenAddReviews}
                                />

                                <Spacer top={26} />
                            </Col>
                        </Row>
                        <div className="footer">
                            <div className="footer-btn">
                                <Button color="primary" type="submit">
                                    Update Calender & Close
                                </Button>
                                <Button color="dark" className="help-btn">
                                    <img src={HelpIcon} alt="" /> Help
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}

AccomodationMinipage.propTypes = {
    getCalendars: Proptypes.func,
    onClose: Proptypes.func,
    fileUpload: Proptypes.func,
    showModal: Proptypes.bool,
    calendar_data: Proptypes.object,
    create_calendar: Proptypes.object,
    updateMainProduct: Proptypes.func
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    create_calendar: state.calendar.create_calendar,
    success_calendar: state.calendar.success_calendar,
    selected_calendar: state.calendar.selected_calendar,
    files: state.contentDetail.files
});

const mapDispatch = (dispatch) => ({
    getCalendars: () => dispatch(getCalendars()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    getFiles: () => dispatch(getFiles()),
    clearUploadedFile: () => dispatch(clearUploadedFile()),
    updateMainProduct: (id, data) => dispatch(updateMainProduct(id, data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AccomodationMinipage));

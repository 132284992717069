import dotenv from 'dotenv';
dotenv.config();
const API_URL = process.env.REACT_APP_API_URL;
const SITE_ENV = process.env.REACT_APP_SITE_ENV;
const TRAVEL_JINNY_BASE_URL = process.env.REACT_APP_TRAVEL_URL;
const STRIPE_KEY = process.env.REACT_APP_CCM_STRIPE_API_KEY;
const INIT = 'INIT';
const LOADING = 'LOADING';
const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
// 1464177653822593

const DAYS = {
    Mon: 'Mon',
    Tue: 'Tue',
    Wed: 'Wed',
    Thu: 'Thu',
    Fri: 'Fri',
    Sat: 'Sat',
    Sun: 'Sun'
};

export {
    API_URL,
    TRAVEL_JINNY_BASE_URL,
    INIT,
    LOADING,
    SUCCESS,
    ERROR,
    DAYS,
    SITE_ENV,
    STRIPE_KEY
};

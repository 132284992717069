import React from 'react';
import PropTypes from 'prop-types';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ShieldIcon } from 'components/atoms';
import CollapseMoreLess from '../../collapse-more-less';

const EventCovidDetails = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<ShieldIcon />}
            panelTitle="Covid-19 safety updates"
            isEditable={true}
            onEdit={onEdit}>
            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                {product?.covid_19_updates?.description}
            </span>
            <h6 className="fs-18 fw-500 text-uppercase mt-2">Safety Measures</h6>
            <ul>
                {product?.covid_19_updates?.covid_info?.length > 0 ? (
                    product?.covid_19_updates.covid_info?.slice(0, 2).map((item, index) => (
                        <li key={index} className="fs-18 mb-3">
                            {item}
                        </li>
                    ))
                ) : (
                    <></>
                )}
                {product?.covid_19_updates?.covid_info?.length > 2 ? (
                    <CollapseMoreLess>
                        {product?.covid_19_updates.covid_info
                            .slice(2, product?.covid_19_updates.covid_info?.length)
                            .map((item, index) => (
                                <li key={index} className="fs-18 mb-3">
                                    {item}
                                </li>
                            ))}
                    </CollapseMoreLess>
                ) : (
                    <></>
                )}
            </ul>
        </DetailedPanel>
    );
};

EventCovidDetails.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventCovidDetails;

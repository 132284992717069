/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component } from 'react';
import { Form, Col, Drawer, Input, Button as AntButton } from 'antd';
import { Button, DropdownToggle, DropdownMenu, DropdownItem, Dropdown } from 'reactstrap';

import './index.scss';
import Spacer from '../../../atoms/spacer';
import MoreInfo from '../../../../assets/images/more_info.svg';
import Plus from '../../../../assets/images/plus.svg';
import {
    clearUploadedFile,
    fileUpload,
    getFiles,
    setMealsAndDrinks
} from '../../../../store/actions/contentActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Gallery from '../../gallery';
import DeleteIcon from '../../../../assets/images/close.svg';
import ImportItineraries from '../../../atoms/importItineraries';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { compareMealsDrinks } from 'utils';
class MealsDrinks extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            isOpenType: false,
            type: '',
            otherType: '',
            popoverOpen: false,
            mealsAndDrinks: null,
            media: [],
            description: '',
            isOpenGallery: false,
            titleGallery: '',
            confirmationModal: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.uploaded_file !== this.props.uploaded_file && this.props.uploaded_file) {
            this.addSelectPhoto(this.props.uploaded_file);
        }
        if (prevProps.onEdit !== this.props.onEdit && this.props.onEdit !== null) {
            const item = this.props.allMealsAndDrinks[this.props.onEdit];
            this.onLoad(item);
        }
    }
    //loading data for editing
    onLoad = (item) => {
        let fieldData = {};
        if (item?.type === 'Breakfast' || item?.type === 'Lunch' || item?.type === 'Dinner') {
            fieldData = {
                type: item.type,
                description: item.description,
                media: item.photos_and_videos
            };
        } else {
            fieldData = {
                type: 'Other',
                otherType: item.other_type,
                description: item.description,
                media: item.photos_and_videos
            };
        }
        this.setState(fieldData);
        this.formRef.current.setFieldsValue(fieldData);
    };

    toggle = () => this.setState({ popoverOpen: !this.state.popoverOpen });

    toggleType = () => this.setState({ isOpenType: !this.state.isOpenType });

    onSelectType = (type) => {
        this.setState({ type: type });
        this.formRef.current.setFieldsValue({
            type: type
        });
    };

    onClose = () => {
        this.onClear();
        this.props.onClose();
    };
    //add media to meals and drinks item
    addSelectPhoto = (media) => {
        const tmp = {
            type: media.type,
            url: media.url,
            name: media.original_name
        };
        this.setState({ media: [...this.state.media, tmp] });
    };

    //upload new media
    onChange = (e) => {
        let file = e;
        let form_data = new FormData();
        form_data.append('media', file);
        this.props.fileUpload(form_data);
    };

    onSaveMedia = (value) => {
        this.setState({ media: value, isOpenGallery: false });
        this.formRef.current.setFieldsValue({
            media: value
        });
    };
    //remove media from meals and drinks
    onDeleteMedia = (index) => {
        const { media } = this.state;
        let tmp = media;
        tmp.splice(index, 1);
        this.setState({ media: tmp });
    };
    onOpenGallery = () => this.setState({ isOpenGallery: !this.state.isOpenGallery });
    onClear = () => this.setState({ type: '', otherType: '', description: '', media: [] });

    //saving new or editing meals and drinks
    addMealsAndDrinks = () => {
        const { type, otherType, description, media } = this.state;
        if (type && media.length > 0 && description.length >= 120) {
            if (this.props.onEdit === null) {
                if (type === 'Other' && otherType) {
                    const tmp = {
                        type: 'Other',
                        other_type: otherType,
                        description: description,
                        photos_and_videos: media
                    };
                    this.props.onSave([...this.props.allMealsAndDrinks, tmp]);
                    this.onClose();
                } else if (type !== 'Other') {
                    const tmp = {
                        type: type,
                        description: description,
                        photos_and_videos: media
                    };
                    this.props.onSave([...this.props.allMealsAndDrinks, tmp]);
                    this.onClose();
                }
            } else {
                if (type === 'Other' && otherType) {
                    const tmp = this.props.allMealsAndDrinks;
                    tmp.splice(this.props.onEdit, 1, {
                        type: 'Other',
                        other_type: otherType,
                        description: description,
                        photos_and_videos: media
                    });
                    this.props.onSave([...tmp]);
                    this.onClose();
                } else if (type !== 'Other') {
                    const tmp = this.props.allMealsAndDrinks;
                    tmp.splice(this.props.onEdit, 1, {
                        type: type,
                        description: description,
                        photos_and_videos: media
                    });
                    this.props.onSave([...tmp]);
                    this.onClose();
                }
            }
        }
    };

    compareData = () => {
        const { type, otherType, description, media } = this.state;
        if (type && media.length > 0 && description.length >= 120) {
            if (this.props.onEdit === null) {
                if (type === 'Other' && otherType) {
                    const tmp = {
                        type: 'Other',
                        other_type: otherType,
                        description: description,
                        photos_and_videos: media
                    };
                    if (
                        compareMealsDrinks(
                            [...this.props.allMealsAndDrinks],
                            [...this.props.allMealsAndDrinks, tmp]
                        )
                    ) {
                        return this.setState({
                            confirmationModal: true
                        });
                    } else {
                        this.onClose();
                        //this.addMealsAndDrinks();
                    }
                } else if (type !== 'Other') {
                    const tmp = {
                        type: type,
                        description: description,
                        photos_and_videos: media
                    };
                    if (
                        compareMealsDrinks(
                            [...this.props.allMealsAndDrinks],
                            [...this.props.allMealsAndDrinks, tmp]
                        )
                    ) {
                        return this.setState({
                            confirmationModal: true
                        });
                    } else {
                        this.onClose();
                        //this.addMealsAndDrinks();
                    }
                }
            } else {
                if (type === 'Other' && otherType) {
                    const tmp = [...this.props.allMealsAndDrinks];
                    const editData = {
                        type: 'Other',
                        other_type: otherType,
                        description: description,
                        photos_and_videos: media
                    };
                    if (compareMealsDrinks(tmp, editData, 'edit')) {
                        return this.setState({
                            confirmationModal: true
                        });
                    } else {
                        this.onClose();
                        //this.addMealsAndDrinks();
                    }
                } else if (type !== 'Other') {
                    const tmp = [...this.props.allMealsAndDrinks];
                    const editData = {
                        type: type,
                        description: description,
                        photos_and_videos: media
                    };

                    if (compareMealsDrinks(tmp, editData, 'edit')) {
                        return this.setState({
                            confirmationModal: true
                        });
                    } else {
                        this.onClose();
                        //this.addMealsAndDrinks();
                    }
                }
            }
        }
    };

    onFinishForm = () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.addMealsAndDrinks();
    };

    render() {
        const { requiredMark, type, isOpenType, media, isOpenGallery, description, titleGallery } =
            this.state;

        return (
            // <div className="itenary-form">
            <Drawer
                className="application meals-drinks"
                onClose={() => {
                    this.onClose();
                }}
                visible={this.props.isOpen}>
                <Form
                    layout="vertical"
                    className="h-100 d-flex flex-column overflow-y-auto"
                    // initialValues={{
                    //     ...this.state
                    // }}
                    ref={this.formRef}
                    onValuesChange={this.onRequiredTypeChange}
                    onFinish={this.onFinishForm}
                    requiredMark={requiredMark}>
                    <div className="flex-grow-1 overflow-y-auto pt-3 container-fluid">
                        <Col>
                            <h6 className="create-product-title">
                                Meals & Drinks <img src={MoreInfo} alt="" />
                            </h6>
                            {this.props.hideImport ? (
                                ''
                            ) : (
                                <ImportItineraries
                                    calendars={this.props.itineraries}
                                    import="I will import meals & drinks information from my other activity"
                                    onMainProduct={(item) => this.onLoad(item.meals_and_drinks[0])}
                                />
                            )}

                            <Spacer top={24} />
                            <div className="input-title">Select type</div>
                            <Form.Item
                                name="type"
                                className="select-category inputs"
                                rules={[
                                    {
                                        validator: async (_, type) => {
                                            if (!type && type !== 'Other')
                                                return Promise.reject(
                                                    new Error('Please, select one')
                                                );
                                            else if (type === 'Other' && !this.state.otherType)
                                                return Promise.reject(
                                                    new Error('Please fill this field in')
                                                );
                                        }
                                    }
                                ]}>
                                <Dropdown isOpen={isOpenType} toggle={this.toggleType}>
                                    <DropdownToggle className="dropdown-category" caret>
                                        {type ? type : 'Select food & drinks'}
                                    </DropdownToggle>
                                    <DropdownMenu style={{ width: '100%' }}>
                                        <DropdownItem
                                            onClick={() => this.onSelectType('Breakfast')}
                                            className={`${
                                                type === 'Breakfast'
                                                    ? 'active-category'
                                                    : 'category-item'
                                            }`}>
                                            Breakfast
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => this.onSelectType('Lunch')}
                                            className={`${
                                                type === 'Lunch'
                                                    ? 'active-category'
                                                    : 'category-item'
                                            }`}>
                                            Lunch
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => this.onSelectType('Dinner')}
                                            className={`${
                                                type === 'Dinner'
                                                    ? 'active-category'
                                                    : 'category-item'
                                            }`}>
                                            Dinner
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => this.onSelectType('Other')}
                                            className={`${
                                                type === 'Other'
                                                    ? 'active-category'
                                                    : 'category-item'
                                            }`}>
                                            Other
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>

                                {type === 'Other' ? (
                                    <Input
                                        placeholder="Enter Other Meal type"
                                        className="input-type"
                                        value={this.state.otherType}
                                        onChange={(e) => {
                                            this.setState({ otherType: e.target.value });
                                        }}
                                    />
                                ) : null}
                            </Form.Item>

                            <Spacer top={36} />
                            <div className="input-title" style={{ marginLeft: '2px' }}>
                                Description
                            </div>
                            <Form.Item
                                name="description"
                                className="inputs"
                                rules={[
                                    {
                                        validator: async (_, description) => {
                                            if (
                                                !description ||
                                                description.trim() === '' ||
                                                description.length < 120
                                            )
                                                return Promise.reject(
                                                    new Error('Please fill this field in')
                                                );
                                        }
                                    }
                                ]}>
                                <Input.TextArea
                                    className="input-area"
                                    placeholder="Tell us about this meal"
                                    value={description}
                                    onChange={(e) => {
                                        this.setState({ description: e.target.value });
                                        this.formRef.current.setFieldsValue({
                                            description: e.target.value
                                        });
                                    }}
                                />
                            </Form.Item>
                            {description.length < 120 && (
                                <div className="description-input">120 characters needed</div>
                            )}
                        </Col>
                        <Spacer top={20} />

                        <Form.Item
                            name="media"
                            rules={[
                                { required: true, message: 'Please select photo and/or video' }
                            ]}>
                            <Gallery
                                showModal={isOpenGallery}
                                media={media}
                                selectMediaList={media}
                                title={titleGallery}
                                onClose={this.onOpenGallery}
                                onSaveMedia={(value) => this.onSaveMedia(value)}
                            />
                            <div className="d-flex flex-wrap gap-2 mb-3">
                                <AntButton
                                    style={{ margin: 0 }}
                                    className="dash-btn"
                                    id="addPhotos"
                                    type="dashed"
                                    onClick={() => {
                                        this.onOpenGallery();
                                        this.setState({ titleGallery: 'My Images' });
                                    }}>
                                    <img className="cursor-pointer img-plus" src={Plus} alt="" />{' '}
                                    Add Photos
                                </AntButton>
                                <AntButton
                                    style={{ margin: 0 }}
                                    className="dash-btn"
                                    id="addVideos"
                                    type="dashed"
                                    onClick={() => {
                                        this.onOpenGallery();
                                        this.setState({ titleGallery: 'My Videos' });
                                    }}>
                                    <img className="cursor-pointer img-plus" src={Plus} alt="" />{' '}
                                    Add Videos
                                </AntButton>
                            </div>
                            <div className="d-flex flex-wrap" style={{ gap: '0.5rem' }}>
                                {media && media.length > 0
                                    ? media.map((res, i) => (
                                          <div key={i} className="select-media">
                                              {res?.type === 'image' ? (
                                                  <img src={res.url} alt="" />
                                              ) : (
                                                  res?.type === 'video' && (
                                                      <video src={res.url}></video>
                                                  )
                                              )}
                                              <div className="delete-block">
                                                  <img
                                                      src={DeleteIcon}
                                                      className="delete-icon cursor-pointer"
                                                      alt=""
                                                      onClick={() => this.onDeleteMedia(i)}
                                                  />
                                              </div>
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </Form.Item>

                        <Spacer top={20} />
                    </div>

                    <div className="footer-button">
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                        <Button color="dark" onClick={this.onClose} type="button">
                            Cancel
                        </Button>
                    </div>
                </Form>

                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.addMealsAndDrinks();
                    }}
                />
            </Drawer>
            // </div>
        );
    }
}
MealsDrinks.propTypes = {
    uploaded_file: Proptypes.object,
    onEdit: Proptypes.number,
    allMealsAndDrinks: Proptypes.array,
    onClose: Proptypes.func,
    fileUpload: Proptypes.func,
    onSave: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    hideImport: Proptypes.bool,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    files: state.contentDetail.files,
    uploaded_file: state.contentDetail.uploaded_file,
    mealsAndDrinks: state.contentDetail.mealsAndDrinks,
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = (dispatch) => ({
    fileUpload: (data) => dispatch(fileUpload(data)),
    setMealsAndDrinks: (data) => dispatch(setMealsAndDrinks(data)),
    getFiles: () => dispatch(getFiles()),
    clearUploadedFile: () => dispatch(clearUploadedFile())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(MealsDrinks));

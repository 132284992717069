import React from 'react';

const EventMainProductIcon = () => {
    return (
        <span className="ml-2">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="white" />
                <path
                    d="M12.9643 7.62383V15.9998H11.2843V10.1558L9.13628 15.9998H7.67228L5.52428 10.1798V15.9998H3.83228V7.62383H5.88428L8.42828 13.9238L10.9243 7.62383H12.9643Z"
                    fill="#A2792C"
                />
                <path
                    d="M16.1883 12.8558V15.9998H14.4963V7.52783H17.5803C18.5803 7.52783 19.3323 7.76783 19.8363 8.24783C20.3483 8.72783 20.6043 9.37983 20.6043 10.2038C20.6043 11.0118 20.3403 11.6558 19.8123 12.1358C19.2923 12.6158 18.5483 12.8558 17.5803 12.8558H16.1883ZM17.4003 11.4998C18.3923 11.4998 18.8883 11.0678 18.8883 10.2038C18.8883 9.77983 18.7723 9.45583 18.5403 9.23183C18.3083 9.00783 17.9283 8.89583 17.4003 8.89583H16.1883V11.4998H17.4003Z"
                    fill="#A2792C"
                />
            </svg>
        </span>
    );
};

export default EventMainProductIcon;

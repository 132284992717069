import React from 'react';
import AddIcon from '../../../../assets/images/addMoreIcon.svg';
import { Grid } from '@material-ui/core';

export default function MoreText(props) {
    return (
        <Grid container alignItems="center" wrap="nowrap">
            <Grid item style={{ marginRight: '5px' }}>
                <img src={AddIcon} />
            </Grid>
            <Grid item style={{ ...props.customStyle }}>
                {props.text}
            </Grid>
        </Grid>
    );
}

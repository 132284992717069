import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import TicketData from '../ticket-data-accomodation';
import { Grid } from '@material-ui/core';

const ReviewsFilter = (data) => {
    const tmpData = [];
    data.map((res) =>
        res.type === 'google'
            ? tmpData.push('GOOGLE')
            : res.type === 'trip_advisor'
            ? tmpData.push('TRIP ADVISOR')
            : res.type === 'trust_pilot'
            ? tmpData.push('TRUST PILOT')
            : res.type === 'facebook'
            ? tmpData.push('FACEBOOK')
            : res.type === 'booking'
            ? tmpData.push('BOOKING.COM')
            : null
    );
    return tmpData;
};
const isAddAdditionsItinerary = (res) => {
    const additions = [];
    if (res.meals_and_drinks) additions.push('Meals & Drinks added');
    if (res.language_offered) additions.push('Language offered added');
    if (res.inclusions || res.exclusions) additions.push('Inclusions & Exclusions added');
    if (res.calendar_season) additions.push('Calendar seasons added');
    if (res.adventures) additions.push('Adventures added');
    if (res.sights) additions.push('Top Sights added');
    if (res.traveller_information) additions.push('Information travellers need from you added');

    return additions;
};
const onLoadItineraries = (itineraries) => {
    const tmpDays = [[]];
    const length = Math.max.apply(
        Math,
        itineraries.map((calendar_day) => {
            return calendar_day.calendar_day;
        })
    );
    if (length > 1) {
        for (let i = 1; i < length; i++) {
            tmpDays.push([]);
        }
    }
    itineraries.map((res) => {
        if (res.calendar_day === null) {
            tmpDays[0].push({
                id: res.id,
                itineraryName: res.title,
                additions: isAddAdditionsItinerary(res)
            });
        } else {
            tmpDays[res?.calendar_day - 1 || 0].push({
                id: res.id,
                itineraryName: res.title,
                additions: isAddAdditionsItinerary(res)
            });
        }
    });
    return tmpDays;
};
const TicketDataActivity = (props) => {
    const Pricing = (data) =>
        data && data.age_group && data.age_group.length > 0 ? (
            <Col sm={12}>
                Pricing:
                <br />
                {data.age_group.map((res, i) => (
                    <div
                        key={i}
                        style={{
                            fontSize: '12px',
                            marginBottom: data.age_group.length - 1 !== i ? 10 : 0
                        }}>
                        <Row style={{ margin: 0 }}>
                            <Col style={{ paddingLeft: 0 }} sm={2}>
                                {res.type}
                            </Col>
                            <Col sm={1}>:</Col>
                            {res.amount > 0 ? (
                                <Col sm={2}> {res.amount}</Col>
                            ) : (
                                <Col sm={2}>No Fee</Col>
                            )}
                        </Row>
                        {res.group_price && res.group_price.length > 0
                            ? res.group_price.map((groupPrice, j) => (
                                  <div key={j}>
                                      {`${res.type} Group price, ${groupPrice.min} to ${groupPrice.max} - $${groupPrice.amount}`}
                                  </div>
                              ))
                            : null}
                    </div>
                ))}
                <br />
            </Col>
        ) : null;
    const Covid = (data) => (
        <Col sm={12}>
            Covid-19 Updates:
            <br />
            <div className="fs-12">
                {data.map((res, i) => (i === data.length - 1 ? res : `${res}, `))}
            </div>
            <br />
        </Col>
    );
    const Reviews = (data) => (
        <Col sm={12}>
            Reviews:
            <br />
            <div className="fs-12">
                {ReviewsFilter(data) && ReviewsFilter(data).length > 0
                    ? ReviewsFilter(data).map((res, i) =>
                          i === ReviewsFilter(data).length - 1 ? res : `${res}, `
                      )
                    : null}
            </div>
            <br />
        </Col>
    );
    const Itinerary = (data) => {
        const days = onLoadItineraries(data);
        return (
            <div>
                Itinerary:
                <br />
                {days && days.length > 0
                    ? days.map((day, i) => (
                          <div key={i} className="fs-12">
                              <div className="fs-13 fw-600">{`Day ${i + 1}`}</div>
                              {day.map((res) => (
                                  <Grid key={res.id} item container direction="column" md={6}>
                                      <Grid item className="itinerary-name fw-500">
                                          {res.itineraryName}
                                      </Grid>
                                      {res.additions.map((added, addIndex) => (
                                          <Grid
                                              key={addIndex}
                                              item
                                              style={{ paddingLeft: '10px' }}
                                              className="itinerary-added">
                                              {added}
                                          </Grid>
                                      ))}
                                  </Grid>
                              ))}
                              <br />
                          </div>
                      ))
                    : null}
                <br />
            </div>
        );
    };
    return (
        <div className="ticket-data">
            {props.data ? (
                <div>
                    {props.data.pricings ? Pricing(props.data.pricings) : null}
                    {props.data.covid19_updates ? Covid(props.data.covid19_updates) : null}
                    {props.data.review_links ? Reviews(props.data.review_links) : null}
                    {props.data.accomodation ? <TicketData data={props.data.accomodation} /> : null}
                    {props.itineraries.data && props.itineraries.data.length > 0
                        ? Itinerary(props.itineraries.data)
                        : null}
                </div>
            ) : null}
        </div>
    );
};

TicketDataActivity.propTypes = {
    data: PropTypes.object,
    itineraries: PropTypes.array
};

export default TicketDataActivity;

import React, { useState, useEffect } from 'react';
import { Button, Input, Row } from 'antd';
import './index.scss';
import Proptypes from 'prop-types';
import GoogleAutoComplete from '../google-autocomplete';

export default function Connect(props) {
    const { add = 'CONNECT', img, name } = props;
    const [onConnect, setOnConnect] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [connectContent, setConnectContent] = useState('');
    const [googleValue, setGoogleValue] = useState('');
    useEffect(() => {
        const { data } = props;
        if (data && data.length > 0) {
            const item = data.find(
                (res) =>
                    (res.type === 'google' && name === 'GOOGLE') ||
                    (res.type === 'trip_advisor' && name === 'TRIP ADVISOR') ||
                    (res.type === 'trust_pilot' && name === 'TRUST PILOT') ||
                    (res.type === 'facebook' && name === 'FACEBOOK') ||
                    (res.type === 'booking' && name === 'BOOKING.COM')
            );
            if (item) onLoad(item);
        } else {
            setConnectContent('');
            setIsConnected(false);
        }
    }, [props.data]);
    const onLoad = (data) => {
        if (name === 'GOOGLE') {
            const geocoder = new window.google.maps.Geocoder();
            const OK = window.google.maps.GeocoderStatus.OK;
            geocoder.geocode({ placeId: data.link }, (results, status) => {
                if (status === OK) {
                    setGoogleValue(results[0].formatted_address);
                    setConnectContent(data.link);
                    setIsConnected(true);
                }
            });
        } else {
            setConnectContent(data.link);
            setIsConnected(true);
        }
    };
    const onSave = () => {
        setOnConnect(false);
        if (connectContent !== '') {
            setIsConnected(true);
            props.onSave(connectContent);
        } else setIsConnected(false);
    };
    return (
        <div className="button-connect">
            <div className="card-head">
                <div style={{ display: 'flex' }}>
                    <div className="title">
                        {name}
                        {img ? <img src={img} alt="" /> : null}
                    </div>
                </div>
                <Button onClick={() => setOnConnect(!onConnect)}>
                    {!isConnected ? add : 'CONNECTED'}
                </Button>
            </div>
            {onConnect ? (
                <div className="input-block">
                    <Row>
                        {name === 'GOOGLE' ? (
                            <div className="google-reviews">
                                <GoogleAutoComplete
                                    placeholder="Kruger National Park Safari, South Africa"
                                    defValue={googleValue}
                                    onSelectDistance={(value) => {
                                        setConnectContent(value.placeId);
                                    }}
                                />
                            </div>
                        ) : (
                            <Input
                                placeholder="Please insert a link to reviews"
                                value={connectContent}
                                onChange={(e) => {
                                    setConnectContent(e.target.value);
                                }}
                            />
                        )}
                        <Button type="primary" onClick={onSave}>
                            Done
                        </Button>
                    </Row>
                </div>
            ) : null}
        </div>
    );
}

Connect.propTypes = {
    name: Proptypes.string,
    add: Proptypes.string,
    img: Proptypes.string,
    onClick: Proptypes.func,
    onSave: Proptypes.func,
    data: Proptypes.array
};

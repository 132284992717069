import React from 'react';
import { Menu, Popover, Dropdown } from 'antd';
import { ComponentPermissionContext } from 'context/rolesandpermission';

const FreeTimeMini = () => {
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);
    return (
        <div className="timeline-freetime __icon-only">
            <Popover
                overlayClassName="popover-container"
                overlayStyle={{ width: 295 }}
                placement={window.innerWidth >= 768 ? 'right' : 'bottomLeft'}
                content={
                    <div>
                        <div className="d-flex flex-nowrap mx-n3 event-schedule">
                            <div className="px-3 event-time-window-wrapper">
                                <div className="event-time-window py-2">
                                    <div className="event-time-icon-wrapper">
                                        <svg
                                            width="43"
                                            height="43"
                                            viewBox="0 0 43 43"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle
                                                cx="21.2911"
                                                cy="21.5118"
                                                r="20.2916"
                                                stroke="#FF5260"
                                                strokeWidth="2"
                                            />
                                            <circle
                                                cx="21.2913"
                                                cy="21.5116"
                                                r="17.743"
                                                fill="black"
                                                fillOpacity="0.5"
                                            />
                                            <path
                                                d="M21.2911 9.68311C14.7687 9.68311 9.4624 14.9894 9.4624 21.5118C9.4624 28.0341 14.7687 33.3404 21.2911 33.3404C27.8134 33.3404 33.1197 28.0341 33.1197 21.5118C33.1197 14.9894 27.8134 9.68311 21.2911 9.68311ZM25.1862 27.0795L20.1082 22.0015V14.4146H22.4739V21.0221L26.8588 25.407L25.1862 27.0795Z"
                                                fill="#FF5260"
                                            />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className="px-3 flex-grow-1">
                                <div className="event-info-wrapper">
                                    <div className="timeline-info flex-wrap">
                                        <div className="timeline-duration">
                                            <span>+30 min</span>
                                        </div>
                                        <div className="timeline-title text-reset">
                                            <span>Free Time</span>
                                        </div>
                                        <div
                                            className={`timeline-action w-100 ${assignComponentPermission(
                                                'product'
                                            )}`}>
                                            <button
                                                className={`timeline-action-btn tinted ${assignComponentPermission(
                                                    'product-update'
                                                )}`}>
                                                <i>Add Product</i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-nowrap mx-n3 event-schedule">
                            <div className="px-3 event-time-window-wrapper">
                                <div className="event-time-window py-2">
                                    <div className="event-time-icon-wrapper">
                                        <Dropdown
                                            overlay={
                                                <Menu>
                                                    <Menu.Item>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://www.antgroup.com">
                                                            1st menu item
                                                        </a>
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        <a
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            href="https://www.luohanacademy.com">
                                                            2nd menu item
                                                        </a>
                                                    </Menu.Item>
                                                </Menu>
                                            }
                                            getPopupContainer={(trigger) => trigger.parentElement}>
                                            <span className="event-time-icon __with-icon __icon-position-absolute">
                                                <span>
                                                    <svg
                                                        width="24"
                                                        height="23"
                                                        viewBox="0 0 24 23"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M21.2262 2.16219C20.9595 1.35094 20.2128 0.773438 19.3328 0.773438H4.66618C3.78618 0.773438 3.05285 1.35094 2.77285 2.16219L-0.000488281 10.3984V21.3984C-0.000488281 22.1547 0.599512 22.7734 1.33285 22.7734H2.66618C3.39951 22.7734 3.99951 22.1547 3.99951 21.3984V20.0234H19.9995V21.3984C19.9995 22.1547 20.5995 22.7734 21.3328 22.7734H22.6662C23.3995 22.7734 23.9995 22.1547 23.9995 21.3984V10.3984L21.2262 2.16219ZM4.66618 15.8984C3.55951 15.8984 2.66618 14.9772 2.66618 13.8359C2.66618 12.6947 3.55951 11.7734 4.66618 11.7734C5.77285 11.7734 6.66618 12.6947 6.66618 13.8359C6.66618 14.9772 5.77285 15.8984 4.66618 15.8984ZM19.3328 15.8984C18.2262 15.8984 17.3328 14.9772 17.3328 13.8359C17.3328 12.6947 18.2262 11.7734 19.3328 11.7734C20.4395 11.7734 21.3328 12.6947 21.3328 13.8359C21.3328 14.9772 20.4395 15.8984 19.3328 15.8984ZM2.66618 9.02344L4.66618 2.83594H19.3328L21.3328 9.02344H2.66618Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </span>
                                            </span>
                                        </Dropdown>
                                    </div>
                                </div>
                            </div>
                            <div className="px-3 flex-grow-1 align-self-center">
                                <div className="event-info-wrapper">
                                    <div className="timeline-info">
                                        <div className="timeline-eta">
                                            <span>10 min</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }>
                <span
                    role="button"
                    tabIndex="0"
                    onClick={(e) => e.stopPropagation()}
                    onKeyUp={(e) => e.stopPropagation()}>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="12" fill="#FF5260" />
                        <rect x="11" y="6" width="2" height="12" fill="white" />
                        <rect
                            x="18"
                            y="11"
                            width="2"
                            height="12"
                            transform="rotate(90 18 11)"
                            fill="white"
                        />
                    </svg>
                </span>
            </Popover>
        </div>
    );
};
export default FreeTimeMini;

export const liveComponents = [
    //authentication
    { slug: 'authentication', permission: 'edit' },
    { slug: 'authentication-apply', permission: 'edit' },
    { slug: 'authentication-signup', permission: 'edit' },
    // calendar
    { slug: 'calendar', permission: 'edit' },
    { slug: 'calendar-dashboard', permission: 'edit' },
    { slug: 'calendar-customer-calendar', permission: 'edit' },
    { slug: 'calendar-create-calendar', permission: 'edit' },
    // product
    { slug: 'product', permission: 'edit' },
    { slug: 'product-update', permission: 'edit' },
    { slug: 'product-link', permission: 'edit' },
    // partner onboarding
    // { slug: 'partner-onboarding', permission: 'edit' },
    // { slug: 'partner-onboarding-add-product', permission: 'edit' },
    // //dashboard
    { slug: 'dashboard', permission: 'edit' },

    // // perfomance
    // { slug: 'performance', permission: 'edit' },
    // //account setting
    // { slug: 'account-security', permission: 'edit' },
    // // vendor account,
    // { slug: 'vendor-account', permission: 'edit' },
    // // 'partner setting',
    // { slug: 'partner-setting', permission: 'edit' },
    // // 'manage users',
    // { slug: 'manage-users', permission: 'edit' },
    // // 'language-settings',
    // { slug: 'language-settings', permission: 'edit' },
    // // 'access customer calendar',
    // { slug: 'access-customer-calendar', permission: 'edit' },
    // { slug: 'access-customer-calendar-calendar', permission: 'edit' },
    // // 'notification',
    // { slug: 'notification', permission: 'edit' },
    // { slug: 'notification-dashboard', permission: 'edit' },
    // { slug: 'notification-calendar', permission: 'edit' },
    // // 'account',
    // { slug: 'account', permission: 'edit' },
    // { slug: 'account-business-information', permission: 'edit' },
    // { slug: 'account-license-and-insurance', permission: 'edit' },
    // { slug: 'account-profile', permission: 'edit' },
    // { slug: 'account-plans', permission: 'edit' },
    // // 'bookings',
    // { slug: 'bookings', permission: 'edit' },
    // { slug: 'bookings-dashboard', permission: 'edit' },
    // { slug: 'bookings-calendar-view', permission: 'edit' },
    // { slug: 'bookings-product-view', permission: 'edit' },
    // // 'availability',
    // { slug: 'availability', permission: 'edit' },
    // { slug: 'availability-availability', permission: 'edit' },
    // { slug: 'availability-update', permission: 'edit' },
    // { slug: 'availability-sync', permission: 'edit' },
    // // 'customer-experience',
    // { slug: 'customer-experience', permission: 'edit' },
    // { slug: 'customer-experience-page', permission: 'edit' },
    // { slug: 'customer-experience-dashboard', permission: 'edit' },
    // { slug: 'customer-experience-calendar-rating', permission: 'edit' },
    // // 'finance',
    // { slug: 'finance', permission: 'edit' },
    // { slug: 'finance-add-payout', permission: 'edit' },
    // { slug: 'finance-edit-payout', permission: 'edit' },
    // { slug: 'finance-balance-detail-section', permission: 'edit' },
    // { slug: 'finance-activity', permission: 'edit' },

    // 'help',
    { slug: 'help', permission: 'edit' }
    // 'calendar-rating',
    // { slug: 'calendar-rating', permission: 'edit' },
    // { slug: 'calendar-rating-dashboard', permission: 'edit' },
    // { slug: 'calendar-rating-calendar', permission: 'edit' }

    // Finance
    // { slug: 'finance', permission: 'edit' }
];
export const developmentComponents = [
    //authentication
    { slug: 'authentication', permission: 'edit' },
    { slug: 'authentication-apply', permission: 'edit' },
    { slug: 'authentication-signup', permission: 'edit' },
    // calendar
    { slug: 'calendar', permission: 'edit' },
    { slug: 'calendar-dashboard', permission: 'edit' },
    { slug: 'calendar-customer-calendar', permission: 'edit' },
    { slug: 'calendar-create-calendar', permission: 'edit' },
    // product
    { slug: 'product', permission: 'edit' },
    { slug: 'product-update', permission: 'edit' },
    { slug: 'product-link', permission: 'edit' },
    // partner onboarding
    { slug: 'partner-onboarding', permission: 'edit' },
    { slug: 'partner-onboarding-add-product', permission: 'edit' },
    // //dashboard
    { slug: 'dashboard', permission: 'edit' },
    { slug: 'calendar-insight', permission: 'edit' },

    // // perfomance
    { slug: 'performance', permission: 'edit' },
    //account setting
    { slug: 'account-security', permission: 'edit' },
    // vendor account,
    { slug: 'vendor-account', permission: 'edit' },
    // 'partner setting',
    { slug: 'partner-setting', permission: 'edit' },
    // 'manage users',
    { slug: 'manage-users', permission: 'edit' },
    // 'language-settings',
    { slug: 'language-settings', permission: 'edit' },
    // 'access customer calendar',
    { slug: 'access-customer-calendar', permission: 'edit' },
    { slug: 'access-customer-calendar-calendar', permission: 'edit' },
    // 'notification',
    { slug: 'notification', permission: 'edit' },
    { slug: 'notification-dashboard', permission: 'edit' },
    { slug: 'notification-calendar', permission: 'edit' },
    // 'account',
    { slug: 'account', permission: 'edit' },
    { slug: 'account-business-information', permission: 'edit' },
    { slug: 'account-license-and-insurance', permission: 'edit' },
    { slug: 'account-profile', permission: 'edit' },
    { slug: 'account-plans', permission: 'edit' },
    // 'bookings',
    { slug: 'bookings', permission: 'edit' },
    { slug: 'bookings-dashboard', permission: 'edit' },
    { slug: 'bookings-calendar-view', permission: 'edit' },
    { slug: 'bookings-product-view', permission: 'edit' },
    // // 'availability',
    { slug: 'availability', permission: 'edit' },
    { slug: 'availability-availability', permission: 'edit' },
    { slug: 'availability-update', permission: 'edit' },
    { slug: 'availability-sync', permission: 'edit' },
    // 'customer-experience',
    { slug: 'customer-experience', permission: 'edit' },
    { slug: 'customer-experience-page', permission: 'edit' },
    { slug: 'customer-experience-dashboard', permission: 'edit' },
    { slug: 'customer-experience-calendar-rating', permission: 'edit' },
    // 'finance',
    { slug: 'finance', permission: 'edit' },
    { slug: 'finance-add-payout', permission: 'edit' },
    { slug: 'finance-edit-payout', permission: 'edit' },
    { slug: 'finance-balance-detail-section', permission: 'edit' },
    { slug: 'finance-activity', permission: 'edit' },

    // 'help',
    { slug: 'help', permission: 'edit' },
    // 'calendar-rating',
    { slug: 'calendar-rating', permission: 'edit' },
    { slug: 'calendar-rating-dashboard', permission: 'edit' },
    { slug: 'calendar-rating-calendar', permission: 'edit' },

    // Finance
    { slug: 'finance', permission: 'edit' }
];

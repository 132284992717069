import React, { useState, useEffect } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import MoreText from './MoreText.jsx';

const styles = {
    cell: {
        border: 'none'
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px',
        width: '68px'
    },
    input: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '89px',
        height: '48px',
        textAlign: 'center',
        marginRight: '10px'
    },
    groupName: {
        fontWeight: '500',
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        textTransform: 'capitalize',

        color: '#333333',
        border: 'none'
    }
};

export default function GroupRow(props) {
    const [amount, setAmount] = useState(1.0);
    const [AddSingle, setAddSingle] = useState(false);

    const AddSingleHandler = () => {
        setAddSingle(true);
    };
    const handleAmount = (e) => {
        setAmount(e.target.value);
    };

    useEffect(() => {
        if (AddSingle) {
            props.addSingle();
        }
    }, [AddSingle]);

    return (
        <>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={styles.cell} align="left">
                    {props.groupName}
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}>
                        <div style={styles.currency}>USD</div>
                        <input
                            style={{ ...styles.input, borderRadius: '0 5px 5px 0' }}
                            type="number"
                            value={amount}
                            min={1}
                            onChange={handleAmount}
                        />
                        <div>PP</div>
                    </div>
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={styles.currency}>USD</div>
                        <input
                            style={{ ...styles.input, borderRadius: '0 5px 5px 0' }}
                            type="number"
                            value={amount}
                            min={1}
                            onChange={handleAmount}
                        />
                        <div>PP</div>
                    </div>
                </TableCell>
                {AddSingle ? (
                    <TableCell style={styles.cell} align="left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={styles.currency}>USD</div>
                            <input
                                style={{ ...styles.input, borderRadius: '0 5px 5px 0' }}
                                type="number"
                                value={amount}
                                min={1}
                                onChange={handleAmount}
                            />
                            <div>PP</div>
                        </div>
                    </TableCell>
                ) : (
                    <TableCell
                        // style={styles.cell}
                        align="left"
                        style={{ cursor: 'pointer', border: 'none' }}
                        onClick={AddSingleHandler}>
                        <MoreText
                            customStyle={{
                                fontFamily: 'Poppins',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#A2792C'
                            }}
                            text="Add Single Supplement"
                        />
                    </TableCell>
                )}
            </TableRow>
        </>
    );
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './index.scss';
import { Button, Col, Row } from 'reactstrap';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import { MoreInfoTooltip } from 'components/atoms';

export default function AddBreakfastButton(props) {
    const { moreInfo = true } = props;

    return (
        <div className="add-breakfast">
            <div className="card-head">
                <div style={{ display: 'flex' }}>
                    <div className="title">
                        {props.name}
                        {moreInfo ? (
                            <MoreInfoTooltip
                                title={`This refers to the breakfast details served in this accomodation`}
                            />
                        ) : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                <Button color="link" onClick={props.onClick}>
                    <img className="img-plus" src={Plus} alt="" /> Add
                </Button>
            </div>
            {props.data ? (
                <Row className="actions">
                    <Col sm={10}>
                        <div>
                            {props.data.is_breakfast_serve ? (
                                <div>
                                    Breakfast included
                                    <div>
                                        {props.data.price
                                            ? `Breakfast price $${props.data.price}`
                                            : 'Breakfast included in price'}
                                    </div>
                                </div>
                            ) : (
                                'Breakfast not included'
                            )}
                        </div>
                        <div>
                            {props.data.is_breakfast_serve &&
                            props.data.types &&
                            props.data.types.length > 0
                                ? props.data.types.map((res, i) =>
                                      i === props.data.types.length - 1 ? res : `${res}, `
                                  )
                                : null}
                        </div>
                    </Col>

                    <Col sm={2} className="group-btn">
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={props.onDelete}
                            className="itinerary-img cursor-pointer"
                        />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
AddBreakfastButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any
};

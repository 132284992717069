import { callApi } from 'utils/apiCaller';

export default class CalendarService {
    static async getDuplicateCalendarTitle(calendarId) {
        try {
            let response = await callApi(`/calendars/${calendarId}/duplicate-title`, 'GET');
            if (response?.status === 200) {
                return { success: true, data: response?.data?.data };
            }
        } catch (error) {
            console.log(error);
            return { success: true, data: null };
        }
    }
}

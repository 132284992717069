import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Form, Input, Button, Radio, DatePicker, Upload, Select, message } from 'antd';
import './index.scss';
import { updateInsurranceInformation, updateInsuranceState } from 'store/actions/profileActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { API_URL } from 'utils/constants';
import ConfirmEditModal from 'components/molecules/confirmation-modal';
import SaveCancel from 'components/molecules/user-account/save-cancel';

function PublicLiabilityInsurance(props) {
    const { handleCancel, visible } = props;
    const [form] = Form.useForm();
    const dateFormat = 'YYYY-MM-DD';
    const [publicLiabilityInsurance, setPublicLiabilityInsurance] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [savingForm, setSavingForm] = useState(false);
    const [isModalClose, setIsModalClose] = useState(false);

    useEffect(() => {
        // Update the document title using the browser API
        if (props.license_info) {
            if (props.license_info.is_insurred) {
                setPublicLiabilityInsurance(1);
            }
            if (props.license_info.insurrance_photo_url) {
                setFileList([
                    {
                        uid: '-1',
                        name: props.license_info.insurrance_photo_url.substring(
                            props.license_info.insurrance_photo_url.lastIndexOf('/') + 1
                        ),
                        status: 'done',
                        url: props.license_info.insurrance_photo_url,
                        thumbUrl: props.license_info.insurrance_photo_url
                    }
                ]);
            }
        }
        if (props.update_insurance) {
            props.updateInsuranceState(false);
            setSavingForm(false);
            // handleCancel();
        }
    }, [props, props.update_insurance, props.license_info, handleCancel]);

    const onFinish = () => {
        setSavingForm(true);
        let data = form.getFieldsValue();
        if (data.insurrance_expiry_date) {
            data['insurrance_expiry_date'] = data.insurrance_expiry_date.format('YYYY-MM-DD');
        }
        props.updateInsurranceInformation(data);
    };
    const { Option } = Select;
    const publicLiabilityInsuranceHolder = (value) => {
        if (publicLiabilityInsurance !== value) setPublicLiabilityInsurance(value);
    };
    const authToken = localStorage.getItem('token');
    const propsInsurance = {
        name: 'media',
        action: `${API_URL}/media-library`,
        headers: {
            authorization: `Bearer ${authToken}`
        },
        onChange(info) {
            if (info.file.status === 'done') {
                form.setFields([
                    { name: 'insurrance_photo_url', value: info.file.response.data.url }
                ]);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'removed') {
                form.setFields([{ name: 'insurrance_photo_url', value: '' }]);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    const handleModal = () => {
        return handleCancel();
    };
    const closeAllModals = () => {
        if (visible && isModalClose) {
            handleCancel();
            setIsModalClose(!isModalClose);
        }
    };
    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };

    return (
        <div>
            <Drawer
                width={609}
                visible={visible}
                //onOk={handleOk}
                onClose={handleModal}
                layout="vertical"
                key="right"
                className="business-license-modal">
                <h6 className="business-license-modal__title">Edit public liability insurance</h6>
                <Form
                    form={form}
                    // onValuesChange={(field) => { setShowExtras(form.getFieldValue('public_insurance_holder') == 'yes') }}
                    onFinish={onFinish}
                    className="mt-3 form-label-fw-500"
                    layout="vertical">
                    <Form.Item
                        label="Do you have a public liability insurance policy?"
                        rules={[{ required: true, message: 'Please select license' }]}
                        initialValue={props?.license_info?.is_insurred}
                        name="is_insurred">
                        <Radio.Group
                            onChange={(e) => {
                                publicLiabilityInsuranceHolder(e.target.value);
                            }}>
                            <Radio className="d-block mb-2" value={1}>
                                Yes
                            </Radio>
                            <Radio className="d-block mb-2" value={0}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    {publicLiabilityInsurance === 1 ? (
                        <>
                            <Form.Item
                                label="Enter your insurance policy number"
                                name="policy_number"
                                initialValue={
                                    props.license_info ? props.license_info.policy_number : ''
                                }
                                rules={[{ required: true, message: 'Please enter policy number' }]}>
                                <Input size="large" />
                            </Form.Item>
                            <Form.Item
                                label="What Currency"
                                initialValue={props.license_info ? props.license_info.currency : ''}
                                name="currency"
                                rules={[
                                    { required: true, message: 'Please select valid currency' }
                                ]}>
                                <Select
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                    size="large"
                                    allowClear>
                                    <Option value="ZAR">ZAR</Option>
                                    <Option value="USD">USD</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Aggregate amount covered"
                                name="amount_covered"
                                initialValue={
                                    props.license_info ? props.license_info.amount_covered : ''
                                }
                                rules={[{ required: true, message: 'Please enter amount' }]}>
                                <Input
                                    type="number"
                                    size="large"
                                    prefix={<div className="site-form-item-icon">$</div>}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Amount per occurrence covered"
                                name="amount_per_occurrence"
                                initialValue={
                                    props.license_info
                                        ? props.license_info.amount_per_occurrence
                                        : ''
                                }
                                rules={[{ required: true, message: 'Please enter amount' }]}>
                                <Input
                                    type="number"
                                    size="large"
                                    prefix={<div className="site-form-item-icon">$</div>}
                                />
                            </Form.Item>
                            <Form.Item
                                label="When does your insurance policy expire?"
                                initialValue={
                                    props.license_info
                                        ? props.license_info.insurrance_expiry_date &&
                                          moment(
                                              props.license_info.insurrance_expiry_date,
                                              dateFormat
                                          )
                                        : ''
                                }
                                rules={[{ required: true, message: 'Please select' }]}
                                name="insurrance_expiry_date"
                                valuePropName="checked">
                                <DatePicker
                                    size="large"
                                    getPopupContainer={(trigger) => trigger.parentElement}
                                    disabledDate={disabledDate}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Upload your public liability insurance"
                                initialValue={
                                    props.license_info
                                        ? props.license_info.insurrance_photo_url
                                        : []
                                }
                                rules={[
                                    { required: true, message: 'Please upload your insurance' }
                                ]}
                                name="insurrance_photo_url"
                                valuePropName="checked">
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    defaultFileList={[...fileList]}
                                    {...propsInsurance}>
                                    <Button
                                        type="primary"
                                        size="small"
                                        shape="round"
                                        className="font-weight-normal">
                                        Upload File
                                    </Button>
                                </Upload>
                            </Form.Item>
                            <p className="fs-12 mt-0 mb-2">File of 5MB or less.</p>
                        </>
                    ) : (
                        ''
                    )}
                    <SaveCancel saveLoading={savingForm} cancelClick={handleModal} />
                </Form>
            </Drawer>

            {/* Confirm Edit Modal */}
            <ConfirmEditModal
                modal={isModalClose}
                handleModal={handleModal}
                closeModal={closeAllModals}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    license_info: state.profile.license_info,
    update_insurance: state.profile.license_info
});
const mapDispatch = (dispatch) => ({
    updateInsurranceInformation: (data) => dispatch(updateInsurranceInformation(data)),
    updateInsuranceState: (data) => dispatch(updateInsuranceState(data))
});
PublicLiabilityInsurance.propTypes = {
    handleCancel: PropTypes.func,
    visible: PropTypes.bool,
    license_info: PropTypes.object,
    update_insurance: PropTypes.any,
    updateInsuranceState: PropTypes.func,
    updateInsurranceInformation: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(PublicLiabilityInsurance));

/* eslint-disable react/display-name */
import { Button, Table, Pagination } from 'antd';
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import FilterIcon from 'components/atoms/icons/filter-icon';
import { Feedback, ArchivedCalendars, CalendarFilters } from 'components/molecules';
import PropTypes from 'prop-types';
import { getCalendars, updateCalendarStatus, disableCalendarConfirm } from 'store/actions';
import { connect } from 'react-redux';
import { tableColumns } from './table-columns';
import ConfirmationModal from 'components/molecules/modals/confirmation-modal';
import { Loader, Notification } from 'components/atoms';
// import { DowngradeCalendars } from 'components/molecules';
import {
    // getVendorSubscription,
    toogleDisableCalendarModal,
    changePreferences
} from 'store/actions/accessCalendar';
import SecondConfirmationModal from 'components/molecules/modals/confirmation-modal';
//const { Option } = Select;

import { ComponentPermissionContext } from 'context/rolesandpermission';

//const { Option } = Select;
// function onChange(pagination, filters, sorter, extra) {
//     console.log('params', pagination, filters, sorter, extra);
// }
class CustomerCalendars extends Component {
    static contextType = ComponentPermissionContext;
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            isConfirmationModalOpen: false,
            filter: {},
            filtersVisibility: false,
            showArchivedList: false,
            isSecondConfirmationModalOpen: false,
            secondModalText: ''
        };
    }

    componentDidMount() {
        const { page } = this.state;
        this.fetchCalenderList({ page, perPage: 10 });
        // this.props.getVendorSubscription();
    }

    componentDidUpdate(prevProp, prevState) {
        const { page, filter, filtersVisibility, showArchivedList } = this.state;
        if (prevState.showArchivedList && showArchivedList === false) {
            this.fetchCalenderList({ page, perPage: 10, ...filter });
        }

        if (prevState.filtersVisibility === true && filtersVisibility === false)
            this.setState({ filter: {}, page: 1 }, () =>
                this.fetchCalenderList({ page: this.state.page, perPage: 10, ...this.state.filter })
            );
    }

    fetchCalenderList = (query) => {
        this.props.getCalendars(query);
    };

    onMenuClick = () => {
        this.setState({ isConfirmationModalOpen: true });
    };

    onChangeFilter = ({ value, type }) => {
        this.setState(
            (prevState) => {
                return { filter: { ...prevState.filter, [type]: value ? value : '' }, page: 1 };
            },
            () =>
                this.fetchCalenderList({ page: this.state.page, perPage: 10, ...this.state.filter })
        );
    };

    onClickAction = ({ id, status }) => {
        this.setState({ updateCalendar: { id, status } });
        // updateCalendarStatus({ id, status })
    };

    updateCalendar = () => {
        const { page, filter, updateCalendar } = this.state;
        const { calendar_data } = this.props;

        if (updateCalendar) {
            let currentCalendarArray =
                calendar_data?.data?.filter((it) => it.id == updateCalendar.id) || [];
            let currentCalendar = currentCalendarArray[0];
            this.props.updateCalendarStatus(updateCalendar).then((msg) => {
                Notification(
                    'success',
                    `Calendar ${
                        updateCalendar?.status === 'draft'
                            ? 'access disabled'
                            : updateCalendar?.status === 'archive'
                            ? 'archived'
                            : updateCalendar?.status
                    } successfully.`
                );
                this.fetchCalenderList({ page, perPage: 10, ...filter });
                if (updateCalendar.status && updateCalendar.status !== 'archive') {
                    if (
                        currentCalendar &&
                        currentCalendar.calendar_status === 'live' &&
                        currentCalendar.customer_status === 'in-use'
                    ) {
                        this.setState({
                            isSecondConfirmationModalOpen: true,
                            secondModalText: msg
                        });
                    }
                }
                // this.fetchCalenderList({ page, perPage: 10 }); //updating archive list
            });
        }
    };

    disableLiveCalendarConfirm = () => {
        const { page, filter, updateCalendar } = this.state;
        if (updateCalendar) {
            this.props.disableCalendarConfirm({ id: updateCalendar.id }).then(() => {
                this.fetchCalenderList({ page, perPage: 10, ...filter });
            });
        }
    };

    onChangePage = (page) => {
        this.setState({ page }, () =>
            this.fetchCalenderList({ page: this.state.page, perPage: 10, ...this.state.filter })
        );
    };

    onClearFilter = () => {
        this.setState({ filter: {} }, () =>
            this.fetchCalenderList({ page: this.state.page, perPage: 10, ...this.state.filter })
        );
    };

    render() {
        const { calendar_data, loading } = this.props;
        return (
            <div className={`e2e-fix ${this.context.assignComponentPermission('calendar')}`}>
                <div
                    className={`bg-calendar customer-calendars-x-padded customer-calendars-y-padded _extra-padded-bottom ${this.context.assignComponentPermission(
                        'calendar-customer-calendar'
                    )}`}>
                    <div className="d-flex flex-wrap flex-lg-nowrap mb-20px">
                        <div className="flex-grow-1 w-100 mr-2">
                            <h1 className="static-heading mb-2">Customer Calendars</h1>
                        </div>
                        <div className="flex-grow-1 flex-shrink-0">
                            <div className="d-sm-flex">
                                <div>
                                    {/* {this.props.upcomingSubscription && (
                                        <Button
                                            className="mr-sm-2 btn-filter"
                                            type="link"
                                            size="small"
                                            onClick={() => this.props.toogleDisableCalendarModal()}>
                                            Disabled Calendar Preferences
                                        </Button>
                                    )} */}
                                </div>
                                <div>
                                    <Button
                                        className="px-0 mr-sm-2"
                                        type="link"
                                        size="small"
                                        onClick={() => this.setState({ showArchivedList: true })}>
                                        Archived List
                                    </Button>
                                </div>
                                <div className="ml-sm-auto">
                                    <NavLink
                                        className={`btn btn-primary btn-sm mr-sm-2 btn-block btn-sm-inline-block ${this.context.assignComponentPermission(
                                            'calendar-create-calendar'
                                        )}`}
                                        to="/get-started">
                                        Create Calendar
                                    </NavLink>
                                    <Button
                                        className="btn-filter btn-block btn-sm-inline-block"
                                        size="small"
                                        onClick={() =>
                                            this.setState({
                                                filtersVisibility: !this.state.filtersVisibility
                                            })
                                        }>
                                        <span className="name mr-2">
                                            {this.state.filtersVisibility ? 'Close' : 'Filters'}
                                        </span>
                                        <span>
                                            <FilterIcon />
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className={`customer-calendars-x-padded section-negative-top 
                ${this.context.assignComponentPermission('calendar-customer-calendar')}`}>
                    {/* Filters */}
                    {this.state.filtersVisibility ? (
                        <CalendarFilters
                            onChangeFilter={this.onChangeFilter}
                            onClearFilter={this.onClearFilter}
                            filterState={this.state.filter}
                        />
                    ) : null}
                    <div className="main-calendar-table-section mb-40px">
                        {loading && <Loader typeContainer={true} />}
                        <div>
                            <Table
                                className="customer-calendars-table "
                                columns={tableColumns({
                                    history: this.props.history,
                                    onMenuClick: this.onMenuClick,
                                    onClickAction: this.onClickAction
                                })}
                                dataSource={
                                    calendar_data?.data
                                        ? calendar_data.data.map((value, index) => {
                                              return { ...value, key: String(index) };
                                          })
                                        : []
                                }
                                pagination={false}
                                tableLayout="auto"
                                scroll={{
                                    y: this.state.filtersVisibility
                                        ? 'calc(100vh - 582px)'
                                        : 'calc(100vh - 408px)',
                                    x: 'max-content'
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between">
                        <div className="pagination-gallery mb-3">
                            <Pagination
                                defaultCurrent={1}
                                total={calendar_data?.meta?.total || 1}
                                defaultPageSize={calendar_data?.meta?.per_page || 10}
                                onChange={(page) => this.onChangePage(page)}
                                showSizeChanger={false}
                                current={this.state.page}
                            />
                        </div>
                        <Feedback buttonClassName="ml-md-auto btn-block btn-md-inline-block mb-3" />
                    </div>
                </div>

                {this.state.showArchivedList && (
                    <ArchivedCalendars
                        onClose={() => this.setState({ showArchivedList: false })}
                        visible={this.state.showArchivedList}
                    />
                )}

                <ConfirmationModal
                    isModalOpen={this.state.isConfirmationModalOpen}
                    setModalOpenState={(value) =>
                        this.setState({
                            isConfirmationModalOpen: value
                        })
                    }
                    description={`Are you sure you want to ${
                        this.state.updateCalendar?.status === 'archive' ? 'archive' : 'disable'
                    } calendar?`}
                    handleConfirmation={() => {
                        this.setState({ isConfirmationModalOpen: false });
                        this.updateCalendar();
                    }}
                />
                <SecondConfirmationModal
                    isModalOpen={this.state.isSecondConfirmationModalOpen}
                    setModalOpenState={(value) =>
                        this.setState({
                            isSecondConfirmationModalOpen: value,
                            secondModalText: ''
                        })
                    }
                    description={`${this.state.secondModalText}`}
                    handleConfirmation={() => {
                        this.setState({
                            isSecondConfirmationModalOpen: false,
                            secondModalText: ''
                        });
                        this.disableLiveCalendarConfirm();
                    }}
                />
                {/* {this.props.upcomingSubscription && (
                    <DowngradeCalendars
                        selectedPlan={this.props.upcomingSubscription || {}}
                        onConfirm={this.handlePreferenceChange}
                        type="edit"
                    />
                )} */}
            </div>
        );
    }
}
CustomerCalendars.propTypes = {
    history: PropTypes.any,
    getCalendars: PropTypes.func,
    calendar_data: PropTypes.object,
    updateCalendarStatus: PropTypes.func,
    // getVendorSubscription: PropTypes.func,
    toogleDisableCalendarModal: PropTypes.func,
    changePreferences: PropTypes.func,
    loading: PropTypes.bool,
    // upcomingSubscription: PropTypes.object,
    disableCalendarConfirm: PropTypes.func
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    loading: state.loading
    // upcomingSubscription: state.accessCalendarReducer.upcomingSubscription
});

const mapDispatch = (dispatch) => ({
    getCalendars: (query) => dispatch(getCalendars(query)),
    updateCalendarStatus: (query) => dispatch(updateCalendarStatus(query)),
    // getVendorSubscription: (query) => dispatch(getVendorSubscription(query)),
    toogleDisableCalendarModal: (query) => dispatch(toogleDisableCalendarModal(query)),
    changePreferences: (subscriptionId, selectedCalendarIds) =>
        dispatch(changePreferences(subscriptionId, selectedCalendarIds)),
    disableCalendarConfirm: (data) => dispatch(disableCalendarConfirm(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(CustomerCalendars));

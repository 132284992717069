import React from 'react';
import { Link } from 'react-router-dom';

export default function DamiBtn(props) {
    return (
        <Link to="#" style={{ textDecoration: 'none' }}>
            <div style={{ ...styles.btn, ...props.customStyle }}>{props.text}</div>
        </Link>
    );
}

const styles = {
    btn: {
        background: '#A2792C',
        border: '0.5px solid #979797',
        boxSizing: 'border-box',
        borderRadius: '60px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */

        color: '#FFFFFF',
        padding: '11px 37px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
};

/* eslint-disable no-prototype-builtins */
import React, { useRef, useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Select, Input, Radio } from 'antd';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Feedback } from 'components/molecules';
import {
    getAllDayProductCategories,
    getWithInDayProductCategories,
    getExistingMainProducts,
    selectedExistingProduct,
    switchProductType,
    getCalendarsById,
    assignExistingProduct,
    assignExistingPackage,
    unlinkProductOrPackage,
    getPackageById
} from 'store/actions';
import { Loader, Notification } from 'components/atoms';
import { stringSplitAndUppercase } from 'utils';

function ExistingMainProductModal(props) {
    const params = useParams();
    const {
        setCreateMainProductModal,
        //setCreateMainProductPackageModal,
        createMainProductModal,
        existing_main_products,
        getExistingMainProducts,
        loading,
        selectedExistingProduct,
        setOpenActivityModal,
        // setAddProductItineraryModal,
        get_calendar_data,
        setAddOnProductItineraryTimeModal,
        setSelectCalendarDaysBookingModal,
        assignExistingProduct,
        assignExistingPackage,
        switchProductType,
        unlinkProductOrPackage,
        getCalendarsById,
        getPackageById
    } = props;

    const loader = useRef();
    const pageRef = useRef('/?page=1');
    const categoryRef = useRef('');
    const TextSearchRef = useRef('');

    const [productCategory, setProductCategory] = useState('');
    const [productNameSearch, setProductNameSearch] = useState('');
    const [choosedExistingProduct, setChoosedExistingProduct] = useState();

    const observer = new IntersectionObserver(handleObserver, {
        root: null,
        rootMargin: '20px',
        threshold: 1.0
    });

    // useEffect(() => {
    //     if (loader.current && intersectingCount === 0) {
    //         observer.observe(loader.current);
    //         intersectingCount = 1;
    //     }
    // }, [existing_main_products?.meta?.current_page === 1]);

    useEffect(() => {
        if (loader.current) {
            observer.observe(loader.current);
        }
        return () => {
            if (loader.current) observer.unobserve(loader.current);
            else observer.disconnect();
        };
    }, [loader.current]);

    useEffect(() => {
        getExistingMainProducts({
            searchQuery: productNameSearch,
            productCategoryId: productCategory,
            choosedExistingProduct: choosedExistingProduct
        });
        categoryRef.current = productCategory; //creating ref's for observer
        TextSearchRef.current = productNameSearch;
    }, [productNameSearch, productCategory]);

    useEffect(() => {
        pageRef.current = existing_main_products?.meta?.next_page_url;
    }, [existing_main_products?.meta?.next_page_url]);

    function handleObserver(entities) {
        const target = entities[0];
        if (target.isIntersecting) fetchPaginatingExistingProducts();
    }

    function fetchPaginatingExistingProducts() {
        if (pageRef.current)
            getExistingMainProducts({
                searchQuery: TextSearchRef.current,
                productCategoryId: categoryRef.current,
                page: pageRef.current,
                paginating: true
            });
    }

    function onChangeProductSearchInput(e) {
        const { value } = e.target;
        setProductNameSearch(value.trim());
    }

    function isProductAlreadyLinkedToCalendar(choosed, calendar_products_list) {
        return calendar_products_list.find((value) => value.id === choosed.id);
    }

    function afterProductAssignedOrSwitched() {
        if (choosedExistingProduct?.day_type === 'all_day') setSelectCalendarDaysBookingModal(true);
        else setAddOnProductItineraryTimeModal(true);

        selectedExistingProduct({
            id: choosedExistingProduct?.id,
            productType: 'main_product'
        });
        getCalendarsById(params.id);
        setCreateMainProductModal(false);
    }

    function callAssignExistingPackage() {
        assignExistingPackage({
            calendar_id: params.id,
            package_id: choosedExistingProduct?.id,
            package_type: 'main_product'
        }).then((res) => {
            if (res) {
                getPackageById(choosedExistingProduct?.id, get_calendar_data?.data?.id);
                setOpenActivityModal(true);
                getCalendarsById(params.id);
                setCreateMainProductModal(false);
            }
        });
    }

    function callAssignOrSwitchProduct() {
        if (isProductAlreadyLinkedToCalendar(choosedExistingProduct, props.calendar_products_list))
            switchProductType({
                calendar_id: params.id,
                product_id: choosedExistingProduct?.id,
                product_type: 'main_product'
                // old_product_type: 'addon_product'
            }).then((res) => {
                if (res) afterProductAssignedOrSwitched();
            });

        assignExistingProduct({
            calendar_id: params.id,
            product_id: choosedExistingProduct?.id,
            product_type: 'main_product'
        }).then((res) => {
            if (res) afterProductAssignedOrSwitched();
        });
    }

    function onClickNext() {
        if (!choosedExistingProduct?.id) return Notification('error', 'Choose One Product', '500');

        let payload = { calendar_id: params.id };
        if (mainPackage) {
            payload.package_id = mainPackage?.id;
            payload.url = '/unlink-package';
        } else if (mainProduct) payload.product_id = mainProduct?.id;

        if (mainPackage || mainProduct)
            unlinkProductOrPackage(payload).then((res) => {
                if (res === true) {
                    //check if selected is a package or product ( if it has category then it will be product)
                    if (
                        choosedExistingProduct &&
                        choosedExistingProduct.hasOwnProperty('category_name')
                    ) {
                        callAssignOrSwitchProduct();
                    } else callAssignExistingPackage();
                }
            });
        else {
            if (choosedExistingProduct && choosedExistingProduct.hasOwnProperty('category_name')) {
                callAssignOrSwitchProduct();
            } else callAssignExistingPackage();
        }
    }

    const { Option } = Select;
    const mainProduct =
        get_calendar_data?.data?.mainProduct &&
        Array.isArray(get_calendar_data?.data?.mainProduct) &&
        get_calendar_data?.data?.mainProduct.length > 0
            ? get_calendar_data?.data?.mainProduct[0]
            : null;
    const mainPackage =
        get_calendar_data?.data?.mainPackage &&
        Array.isArray(get_calendar_data?.data?.mainPackage) &&
        get_calendar_data?.data?.mainPackage.length > 0
            ? get_calendar_data?.data?.mainPackage[0]
            : null;

    return (
        <Modal
            size="lg"
            centered
            scrollable
            isOpen={createMainProductModal}
            toggle={() => setCreateMainProductModal(!createMainProductModal)}>
            <div className="modal-header">
                <div className="flex-grow-1">
                    <h5 className="modal-title">{`${
                        mainProduct || mainPackage ? 'Replace' : 'Add'
                    } Main Product`}</h5>
                </div>
                <div className="d-flex flex-wrap justify-content-end gap-1">
                    {/* {(mainProduct || mainPackage) && (
                        <div className="flex-shrink-0" style={{ minWidth: 135 }}>
                            <div className="text-uppercase fs-14 fw-600">Customer Status</div>
                            <div className="fs-14 text-nowrap">
                                <span
                                    className={`calendar_activity-status __${get_calendar_data?.data?.customer_status
                                        .toLowerCase()
                                        .replace(/-/g, '_')}`}></span>
                                <span className="fs-14 text-gray">
                                    {stringSplitAndUppercase(
                                        get_calendar_data?.data?.customer_status
                                    )}
                                </span>
                            </div>
                        </div>
                    )} */}
                    {mainProduct && (
                        <div className="flex-shrink-0" style={{ minWidth: 135 }}>
                            <div className="text-uppercase fs-14 fw-600">Product Status</div>
                            <div className="fs-14 text-nowrap">
                                <span
                                    className={`calendar_activity-status __${mainProduct?.product_status
                                        .toLowerCase()
                                        .replace(/_/g, '_')}`}></span>
                                <span className="fs-14 text-gray">
                                    {stringSplitAndUppercase(mainProduct?.product_status, '_')}
                                </span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <ModalBody className="d-flex flex-column">
                <div>
                    <div className="d-lg-flex align-items-center">
                        <div className="mt-2 mt-lg-0 ml-lg-2 text-small">
                            {mainProduct
                                ? `${mainProduct?.productCategory?.name ?? 'N/A'}: ${
                                      mainProduct?.title
                                  }`
                                : mainPackage
                                ? `Package: ${mainPackage?.title}`
                                : null}
                        </div>
                    </div>
                    <hr className="mb-13px" />
                    <div className="fs-18 fw-500 mb-10px">{`${
                        mainProduct || mainPackage ? 'Replace' : 'Add'
                    } from existing products`}</div>
                    <div className="d-md-flex justify-content-between m-n2">
                        <div className="p-2 flex-shrink-0">
                            <Input
                                size="small"
                                suffix={
                                    <svg
                                        width="15"
                                        height="15"
                                        viewBox="0 0 15 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.5209 13.6626L10.9508 9.94962C11.8687 8.85844 12.3717 7.48552 12.3717 6.05624C12.3717 2.71688 9.65479 0 6.31543 0C2.97607 0 0.259186 2.71688 0.259186 6.05624C0.259186 9.3956 2.97607 12.1125 6.31543 12.1125C7.56907 12.1125 8.76373 11.7344 9.78513 11.0166L13.3823 14.7578C13.5326 14.9139 13.7349 15 13.9516 15C14.1567 15 14.3513 14.9218 14.499 14.7796C14.8129 14.4776 14.8229 13.9768 14.5209 13.6626ZM6.31543 1.57989C8.78374 1.57989 10.7918 3.58793 10.7918 6.05624C10.7918 8.52456 8.78374 10.5326 6.31543 10.5326C3.84712 10.5326 1.83908 8.52456 1.83908 6.05624C1.83908 3.58793 3.84712 1.57989 6.31543 1.57989Z"
                                            fill="#A2792C"
                                        />
                                    </svg>
                                }
                                placeholder="Search by Product Name"
                                style={{ minWidth: 225 }}
                                onChange={onChangeProductSearchInput}
                            />
                        </div>
                        <div className="p-2 flex-shrink-0">
                            <div className="d-flex mx-n2 justify-content-between align-items-center">
                                <div className="px-2">
                                    <span className="fs-13">Filter By</span>
                                </div>
                                <div className="px-2">
                                    <Select
                                        className="d-block"
                                        placeholder="Category"
                                        size="small"
                                        dropdownClassName="select-dropdown select-dropdown-sm"
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        style={{ width: 160 }}
                                        onChange={(value) => setProductCategory(value)}
                                        defaultValue={''}>
                                        <Option value="">All</Option>
                                        <Option value={'Accommodation'}>Accommodation</Option>
                                        <Option value={'Activity'}>Activity</Option>
                                        <Option value={'is_package'}>Package</Option>
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="position-relative flex-grow-1 d-flex flex-column pr-3"
                    style={{ overflowY: 'auto' }}>
                    {loading && <Loader typeContainer={true} />}

                    <div
                        className="flex-grow-1 scrollbar-fixed mt-3 pr-3 mr-n3"
                        style={{ overflowY: 'auto' }}>
                        <Radio.Group
                            className="d-flex flex-column"
                            // onChange={(e) => setAddProductFromExistingProducts(e.target.value)}
                            // value={addProductFromExistingProducts}
                        >
                            {existing_main_products?.data &&
                                Array.isArray(existing_main_products.data) &&
                                existing_main_products.data.map((value, ind) => {
                                    return value.id === mainProduct?.id ||
                                        value.id === mainPackage?.id ? null : (
                                        <Radio
                                            onClick={() => setChoosedExistingProduct(value)}
                                            key={ind}
                                            className="select-product-radio"
                                            value={ind}>
                                            {value.title}
                                        </Radio>
                                    );
                                })}
                        </Radio.Group>

                        {/* "Required for infinite scroll" Add Ref to Load More div  */}

                        <div className="" ref={loader} style={{ visibility: 'hidden' }}>
                            <p>Loading More</p> {/*hidden */}
                        </div>

                        {existing_main_products?.meta?.next_page_url ? (
                            <div className="">{loading && <h5>Loading More...</h5>}</div>
                        ) : existing_main_products?.meta?.total > 0 &&
                          existing_main_products?.meta?.current ===
                              existing_main_products?.meta?.last ? (
                            <div className="">
                                <h5>No more products!</h5>
                            </div>
                        ) : (
                            <div className="">
                                <h5>No Product added yet!</h5>
                            </div>
                        )}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <Button
                    //disabled={true}
                    onClick={onClickNext}
                    color="primary"
                    className="btn-mw flex-grow-1 flex-lg-grow-0">
                    Next
                </Button>
                <Button
                    color="dark"
                    className="btn-mw flex-grow-1 flex-lg-grow-0"
                    onClick={() => setCreateMainProductModal(false)}>
                    Cancel
                </Button>
                <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
            </ModalFooter>
        </Modal>
    );
}

ExistingMainProductModal.propTypes = {
    loading: PropTypes.bool,
    getAllDayProductCategories: PropTypes.func,
    getWithInDayProductCategories: PropTypes.func,
    product_categories: PropTypes.array,
    product_in_day_categories: PropTypes.array,
    setCreateMainProductModal: PropTypes.func,
    setCreateMainProductPackageModal: PropTypes.func,
    createMainProductModal: PropTypes.bool,
    existing_main_products: PropTypes.object,
    getExistingMainProducts: PropTypes.func,
    selectedExistingProduct: PropTypes.func,
    setOpenActivityModal: PropTypes.func,
    setAddProductItineraryModal: PropTypes.func,
    get_calendar_data: PropTypes.object,
    setAddOnProductItineraryTimeModal: PropTypes.func,
    setSelectCalendarDaysBookingModal: PropTypes.func,
    switchProductType: PropTypes.func,
    getCalendarsById: PropTypes.func,
    assignExistingPackage: PropTypes.func,
    calendar_products_list: PropTypes.any,
    unlinkProductOrPackage: PropTypes.func,
    getPackageById: PropTypes.func,
    assignExistingProduct: PropTypes.func
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    existing_main_products: state.product.existing_main_products,
    get_calendar_data: state.calendar.get_calendar_data,
    calendar_products_list: state.calendar.get_all_calendar_products?.data
});

const mapDispatch = (dispatch) => ({
    getAllDayProductCategories: () => dispatch(getAllDayProductCategories()),
    getWithInDayProductCategories: (type) => dispatch(getWithInDayProductCategories(type)),
    getExistingMainProducts: (query) => dispatch(getExistingMainProducts(query)),
    selectedExistingProduct: (data) => dispatch(selectedExistingProduct(data)),
    switchProductType: (data) => dispatch(switchProductType(data)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    assignExistingProduct: (data) => dispatch(assignExistingProduct(data)),
    assignExistingPackage: (data) => dispatch(assignExistingPackage(data)),
    unlinkProductOrPackage: (data) => dispatch(unlinkProductOrPackage(data)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ExistingMainProductModal));

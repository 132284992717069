/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button, Row } from 'reactstrap';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import moment from 'moment';
export default function AddHouseRuleButton(props) {
    const { add = 'Add', moreInfo = true } = props;
    const convertTime = (val) => {
        if (val) {
            return moment(val, 'HH:mm').format('hh:mm A');
        } else {
            return '';
        }
    };
    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={props.onDelete}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                )}
            </div>
            {props.data ? (
                <Row className="actions">
                    <div>
                        <div>
                            {props.data.smoking_allowed ? 'Smoking allowed, ' : null}
                            {props.data.pets_allowed ? 'Pets allowed, ' : null}
                            {props.data.events_allowed ? 'Parties/events allowed' : null}
                        </div>
                        <div>
                            {props.data.check_in &&
                            !(
                                props.data.check_in.from == '00:00' &&
                                props.data.check_in.until == '00:00'
                            )
                                ? `Check in: ${
                                      convertTime(props.data.check_in.from) || '00:00'
                                  } - ${convertTime(props.data.check_in.until) || '00:00'}`
                                : null}
                        </div>
                        <div>
                            {props.data.check_out &&
                            !(
                                props.data.check_out.from == '00:00' &&
                                props.data.check_out.until == '00:00'
                            )
                                ? `Check out: ${
                                      convertTime(props.data.check_out.from) || '00:00'
                                  } - ${convertTime(props.data.check_out.until) || '00:00'}`
                                : null}
                        </div>
                    </div>
                </Row>
            ) : null}
        </div>
    );
}
AddHouseRuleButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any
};

import React from 'react';
import { Container, Grid } from '@material-ui/core';
import Close from '../../../../assets/images/close.svg';
import DamiBtn from '../pricing/DamiBtn';

// const useStyles = makeStyles((theme) => ({}));

export default function LanguageSettings() {
    // const classes = useStyles();

    return (
        <Container>
            <Grid
                container
                direction="column"
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 'normal'
                }}>
                <Grid
                    item
                    container
                    style={{
                        marginTop: '38px'
                    }}
                    justify="flex-end">
                    <img src={Close} alt="close-icon" />
                </Grid>
                <Grid
                    item
                    style={{
                        marginTop: '23px',
                        marginBottom: '36px',
                        fontWeight: '600',
                        fontSize: '26px',
                        lineHeight: '32px',
                        color: '#333333'
                    }}>
                    Select your language
                </Grid>
                <Grid
                    item
                    style={{
                        marginBottom: '23px',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#333333'
                    }}>
                    This will select the language of this website. It will not change your product
                    or listing pages.
                </Grid>
                <Grid item container direction="column">
                    {[
                        'Bahasa Indonesia',
                        'Deutsch',
                        'English',
                        'Español',
                        'Español (Argentina)',
                        'Español (Colombia)',
                        'Español (Mexico)',
                        'Français',
                        'Italiano',
                        'Nederlands',
                        'Português',
                        'Tiếng Việt',
                        'Türkçe',
                        'Ελληνικά'
                    ].map((each, index) => (
                        <Grid item key={index}>
                            <input type="radio" id="male" name="language" value={each} />
                            <label
                                for={each}
                                style={{
                                    marginLeft: '24px'
                                }}>
                                {each}
                            </label>
                            <br />
                        </Grid>
                    ))}
                </Grid>
                <Grid
                    item
                    style={{
                        marginTop: '85px',
                        marginBottom: '85px'
                    }}>
                    <hr />
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item>
                        <DamiBtn
                            text="Save"
                            customStyle={{
                                background: '#A2792C',
                                border: '0.5px solid #979797',
                                boxSizing: 'border-box',
                                borderRadius: '60px',
                                fontWeight: '600',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */
                                color: '#FFFFFF'
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <DamiBtn
                            text="Cancel"
                            customStyle={{
                                background: '#FFFFFF',
                                border: '0.5px solid #A2792C',
                                boxSizing: 'border-box',
                                borderRadius: '60px',
                                fontWeight: '600',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */
                                color: '#A2792C'
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}

/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './index.scss';
// import { TableCell } from '@material-ui/core';
import { Col, Row } from 'reactstrap';
import DeleteIcon from 'assets/images/close.svg';
import RangePicker from '../../rangeDatePicker';
import Proptypes from 'prop-types';
import styles from './styles';
import { InputNumber, Input, Form } from 'antd';
import moment from 'moment';

const GroupRow = ({
    setAccomodationPeakSeasonSupplements,
    accomodationPeakSeasonSupplements,
    type
}) => {
    const onChange = (value, name, index) => {
        let temp = [...accomodationPeakSeasonSupplements];
        temp[index][name] = value;
        setAccomodationPeakSeasonSupplements(temp);
    };

    const removeItem = (index) => {
        if (accomodationPeakSeasonSupplements?.length <= 1) {
            return setAccomodationPeakSeasonSupplements(null);
        }
        let temp = [...accomodationPeakSeasonSupplements];
        temp.splice(index, 1);
        setAccomodationPeakSeasonSupplements([...temp]);
    };

    return (
        <>
            {accomodationPeakSeasonSupplements.map((res, i) => (
                <div key={i}>
                    <Row className="season-supplement" style={{ border: 'none', marginTop: 10 }}>
                        <Col xs="auto max-width-30" style={{ maxWidth: '30%' }}>
                            <Form.Item
                                name={`seasonpercentage${i}`}
                                rules={[
                                    {
                                        required: !res.percentage,
                                        message: 'Please all values for season supplement'
                                    }
                                ]}>
                                <div
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    className="input-percent">
                                    <InputNumber
                                        style={{ ...styles.input, paddingRight: '25px' }}
                                        value={res.percentage}
                                        onChange={(e) => onChange(e, 'percentage', i)}
                                        min={1}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                        <Col>
                            <b style={styles.betweenBlock}>From</b>
                        </Col>
                        <Col>
                            <Form.Item
                                name={`range${i}`}
                                rules={[
                                    {
                                        required: !res.start,
                                        message: 'Please enter date for season supplement'
                                    }
                                ]}>
                                <div style={{ display: 'flex' }}>
                                    <RangePicker
                                        format={'YYYY-MM-DD'}
                                        start={
                                            res.start &&
                                            moment(res.start, 'YYYY-MM-DD').format('YY-MM-DD')
                                        }
                                        end={
                                            res.end &&
                                            moment(res.end, 'YYYY-MM-DD').format('YY-MM-DD')
                                        }
                                        onChange={(start, end) => {
                                            onChange(
                                                moment(start, 'YY-MM-DD').format('YYYY-MM-DD'),
                                                'start',
                                                i
                                            );
                                            onChange(
                                                moment(end, 'YY-MM-DD').format('YYYY-MM-DD'),
                                                'end',
                                                i
                                            );
                                        }}
                                    />
                                </div>
                            </Form.Item>
                        </Col>

                        <Col>
                            <div className="delete-block">
                                <img
                                    src={DeleteIcon}
                                    className="delete-icon cursor-pointer"
                                    style={styles.deleteIcon}
                                    onClick={() => removeItem(i)}
                                    alt=""
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>Season</Col>
                        <Col xs={12}>
                            <Form.Item
                                name={`season${i}`}
                                className="w-100"
                                rules={[{ required: !res.season, message: 'Please enter season' }]}>
                                <div
                                    style={{
                                        display: 'flex',
                                        marginRight: -6,
                                        width: type === 'product' ? '94%' : '81%'
                                    }}>
                                    <Input
                                        style={styles.reason}
                                        value={res.season}
                                        onChange={(e) => onChange(e.target.value, 'season', i)}
                                    />
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            ))}
        </>
    );
};
GroupRow.propTypes = {
    accomodationPeakSeasonSupplements: Proptypes.array,
    setAccomodationPeakSeasonSupplements: Proptypes.func,
    type: Proptypes.string
};

export default GroupRow;

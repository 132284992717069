import { makeStyles } from '@material-ui/core/styles';

export const FAQresponsive = {
    mobile: {
        breakpoint: { max: 4000, min: 0 },
        items: 1
    }
};

export const styles = {
    root: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        overflow: 'hidden',
        color: '#333333'
    },
    hero: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '65% bottom'
    },
    heroContainer: {
        maxWidth: '1275px',
        paddingLeft: '15px',
        paddingRight: '15px',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    customers: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '700',
        color: '#FFFFFF'
    },
    manage: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        color: '#FFFFFF'
    },
    applyNow: {
        background: '#A2792C',
        borderRadius: '6px',
        width: '196px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#FFFFFF',
        cursor: 'pointer'
    },
    applyNowText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#FFFFFF'
    },
    benefits: {
        background: '#fff',
        justifyContent: 'space-between'
    },
    benefitsItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        flexGrow: 1
    },
    itemsHeader: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '600',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#A2792C'
    },
    itemsContent: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        textAlign: 'center',
        color: '#333333'
    },
    howItWorks: {
        justifyContent: 'center',
        alignItems: 'center'
    },
    worksContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '40px 20px'
    },
    howItWorksText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '600',
        maxWidth: '752px',
        color: '#333333',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    plan: {
        background: 'rgba(162, 121, 44, 0.06)'
    },
    faq: {
        textAlign: 'center',
        marginBottom: '30px'
    },
    getStarted: {
        background: '#A2792C',
        color: '#FFFFFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    ready: {
        fontWeight: '600',
        fontSize: '28px',
        lineHeight: '34px'
    },
    joinUs: {
        fontSize: '26px',
        lineHeight: '32px',
        textAlign: 'center'
    },
    review: {
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        textAlign: 'center',
        textDecorationLine: 'underline',
        cursor: 'pointer'
    },
    applyBtnBelow: {
        background: '#FFFFFF',
        borderRadius: '60px',
        fontWeight: '500',
        fontSize: '20px',
        lineHeight: '24px',
        padding: '12px 108px',
        color: '#A2792C',
        width: '325px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    faqSection: {
        margin: '50px 0'
    }
};

export const useStyles = makeStyles((theme) => ({
    getStarted: {
        padding: '40px 378px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 0'
        }
    },
    customers: {
        fontSize: '50px',
        lineHeight: '60px',
        // paddingLeft: "57px",
        // paddingTop: "16px",
        marginBottom: '7px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: '31px',
            lineHeight: '112%',
            marginBottom: '12px'
        }
    },
    manage: {
        fontSize: '22px',
        lineHeight: '34px',
        // paddingLeft: "57px",
        fontWeight: '600',
        maxWidth: '586px',
        marginBottom: '17px',
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '2px',
            textAlign: 'left',
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '24px',
            marginBottom: '19px'
        }
    },
    applyParent: {
        // paddingLeft: "57px",
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'left',
            marginLeft: '3px'
        }
    },
    centerMobile: {
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    benefits: {
        position: 'relative',
        zIndex: 1,
        margin: '70px auto 27px',
        width: '100%',
        padding: '0',
        maxWidth: '1275px',
        borderRadius: '20px',
        boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.15)',

        [theme.breakpoints.down('sm')]: {
            padding: '0',
            marginTop: '-52px',
            marginBottom: '20px'
        }
    },
    itemsHeader: {
        fontSize: '22px',
        lineHeight: '33px',
        marginTop: '24px',
        marginBottom: '7px',
        // minHeight: "66px",
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '24px',
            minHeight: 'unset',
            marginTop: '13px',
            marginBottom: '12px'
        }
    },
    itemsContent: {
        fontSize: '16px',
        lineHeight: '24px',
        marginBottom: '0',
        padding: '0 12px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            lineHeight: '20px',
            padding: '0 10px',
            marginTop: '-2px'
        }
    },
    howItWorksText: {
        fontSize: '38px',
        lineHeight: '45.75px',
        marginBottom: '62px',
        paddingTop: '1px',
        paddingLeft: '0px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '36px',
            fontSize: '24px',
            lineHeight: '36px',
            paddingLeft: '42px',
            paddingRight: '28px',
            marginTop: '2px',
            textTransform: 'uppercase'
        }
    }
}));

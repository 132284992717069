import React from 'react';
import PropTypes from 'prop-types';

const TransportationIcon = ({ color }) => {
    return (
        <svg
            width="18"
            height="17"
            viewBox="0 0 18 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.7278 1.15604C15.5333 0.55375 14.9889 0.125 14.3472 0.125H3.65278C3.01111 0.125 2.47639 0.55375 2.27222 1.15604L0.25 7.27083V15.4375C0.25 15.999 0.6875 16.4583 1.22222 16.4583H2.19444C2.72917 16.4583 3.16667 15.999 3.16667 15.4375V14.4167H14.8333V15.4375C14.8333 15.999 15.2708 16.4583 15.8056 16.4583H16.7778C17.3125 16.4583 17.75 15.999 17.75 15.4375V7.27083L15.7278 1.15604ZM3.65278 11.3542C2.84583 11.3542 2.19444 10.6702 2.19444 9.82292C2.19444 8.97562 2.84583 8.29167 3.65278 8.29167C4.45972 8.29167 5.11111 8.97562 5.11111 9.82292C5.11111 10.6702 4.45972 11.3542 3.65278 11.3542ZM14.3472 11.3542C13.5403 11.3542 12.8889 10.6702 12.8889 9.82292C12.8889 8.97562 13.5403 8.29167 14.3472 8.29167C15.1542 8.29167 15.8056 8.97562 15.8056 9.82292C15.8056 10.6702 15.1542 11.3542 14.3472 11.3542ZM2.19444 6.25L3.65278 1.65625H14.3472L15.8056 6.25H2.19444Z"
                fill={color || '#FFF'}
            />
        </svg>
    );
};

TransportationIcon.propTypes = {
    color: PropTypes.any
};

export default TransportationIcon;

import React from 'react';
import PropTypes from 'prop-types';
import InputBox from '../../../atoms/inputbox';

export default function InputAccommodation(props) {
    return (
        <div>
            <InputBox onChange={props.onChange} fontWeight={props.fontWeight} other={props.other} />
            <p style={{ paddingLeft: '20px' }}>{props.intro}</p>
        </div>
    );
}

InputAccommodation.propTypes = {
    onChange: PropTypes.func,
    fontWeight: PropTypes.string,
    other: PropTypes.any,
    intro: PropTypes.string
};

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ExtraInfoIcon } from 'components/atoms';
import CollapseMoreLess from '../../collapse-more-less';
const EventHouseRules = (props) => {
    const { product, onEdit } = props;

    if (!product?.house_rules)
        return (
            <DetailedPanel
                panelIcon={<ExtraInfoIcon />}
                panelTitle="House Rules"
                isEditable={true}
                onEdit={onEdit}></DetailedPanel>
        );

    return (
        <DetailedPanel
            panelIcon={<ExtraInfoIcon />}
            panelTitle="House Rules"
            isEditable={true}
            onEdit={onEdit}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Smoking Allowed</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product?.house_rules?.smoking_allowed)}
                    </span>
                </Col>
            </Row>

            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Pets Allowed</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product?.house_rules?.pets_allowed)}
                    </span>
                </Col>
            </Row>

            <CollapseMoreLess>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Events Allowed</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(product?.house_rules?.events_allowed)}
                        </span>
                    </Col>
                </Row>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Check In</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">
                            From {product?.house_rules?.check_in?.from} until{' '}
                            {product?.house_rules?.check_in?.until}
                        </span>
                    </Col>
                </Row>

                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Check Out</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">
                            From {product?.house_rules?.check_out?.from} until{' '}
                            {product?.house_rules?.check_out?.until}
                        </span>
                    </Col>
                </Row>
            </CollapseMoreLess>
        </DetailedPanel>
    );
};

EventHouseRules.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

const getYesNo = (value) => (value ? 'Yes' : 'No');

export default EventHouseRules;

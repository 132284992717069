import React, { useRef, useState } from 'react';
import './index.scss';
import '../pricing/index.scss';
import { Drawer, Form, InputNumber } from 'antd';
import { Grid } from '@material-ui/core';
import { Button, Col, Row } from 'reactstrap';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SharingGroups from '../../../atoms/pricing-age/single-supplement/sharirn-groups';
import BookingPrice from '../../../atoms/pricing-age/booking-price';
import SeasonSupplement from '../../../atoms/pricing-age/season-supplement';
import Spacer from '../../../atoms/spacer';
import Prompter from '../../../atoms/prompter';
import Proptypes from 'prop-types';
import { getMinProductAccomodationGroupBookingVal, mapValidationErrors } from 'utils';
import DaysSelector from 'components/atoms/select-day';
import { PricingMinMaxAccomodationInputs, SubmitButtonErrorPopover } from 'components/atoms';

const AccomodationPricing = ({
    showModal,
    onClose,
    productAdultPrice,
    perPersonSharing,
    perPersonSingle,
    setPerPersonSharing,
    setPerPersonSingle,
    accomodationGroupPrices,
    setAccomodationGroupPrices,
    setAccomodationPeakSeasonSupplements,
    accomodationPeakSeasonSupplements,
    type,
    gender,
    setGender,
    minTravellers,
    maxTravellers,
    setMinTravellers,
    setMaxTravellers,
    minAccomodation,
    maxAccomodation,
    setMinAccomodation,
    setMaxAccomodation,
    selectDays,
    setSelectDays,
    onSave: propOnSave,
    pricing,
    dayPriceUpdate
}) => {
    const bottomRef = useRef();

    const [errors, setErrors] = useState(null);

    const onSharingChange = (value, name, groupName) => {
        let tmp = { ...perPersonSharing };
        if (name === 'secondPrice') {
            if (groupName === 'Double') {
                tmp.double_room = { ...tmp.double_room, amount: value };
            } else {
                tmp.twin_room = { ...tmp.twin_room, amount: value };
            }
        } else if (name === 'singleSupplement') {
            if (groupName === 'Double') {
                tmp.double_room = { ...tmp.double_room, single_supplement: value };
            } else {
                tmp.twin_room = { ...tmp.twin_room, single_supplement: value };
            }
        }
        setPerPersonSharing(tmp);
    };

    const onSingleChange = (value, name, groupName) => {
        let tmp = { ...perPersonSingle };
        if (name === 'secondPrice') {
            if (groupName === 'Double') {
                tmp.double_room = { ...tmp.double_room, amount: value };
            } else {
                tmp.twin_room = { ...tmp.twin_room, amount: value };
            }
        }
        setPerPersonSingle(tmp);
    };

    const onSave = async () => {
        try {
            await form.validateFields();
            if (propOnSave) propOnSave();
        } catch (error) {
            console.log(error);
        }
    };

    const addNewBookingGroup = () => {
        setAccomodationGroupPrices(
            accomodationGroupPrices?.length && accomodationGroupPrices?.length > 0
                ? [
                      ...accomodationGroupPrices,
                      {
                          percentage: null,
                          min: getMinProductAccomodationGroupBookingVal(
                              accomodationGroupPrices.length,
                              accomodationGroupPrices
                          ),
                          max: getMinProductAccomodationGroupBookingVal(
                              accomodationGroupPrices.length,
                              accomodationGroupPrices
                          )
                      }
                  ]
                : [
                      {
                          percentage: null,
                          min: 1,
                          max: 1
                      }
                  ]
        );
    };

    const addNewPeakSeason = () => {
        setAccomodationPeakSeasonSupplements(
            accomodationPeakSeasonSupplements?.length &&
                accomodationPeakSeasonSupplements?.length > 0
                ? [
                      ...accomodationPeakSeasonSupplements,
                      {
                          percentage: null
                      }
                  ]
                : [
                      {
                          percentage: null
                      }
                  ]
        );

        setTimeout(() => {
            bottomRef.current && bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        }, 100);
    };

    const onFinishFailed = ({ errorFields }) => {
        let errorsList = mapValidationErrors(errorFields);
        if (errorsList && Object.keys(errorsList).length > 0) {
            setErrors(errorsList);
            setTimeout(() => setErrors(null), 4000);
        }
    };

    const [form] = Form.useForm();

    const genderOption = [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Both', value: 'Prefer Not to Say' }
    ];

    return (
        // <div className="itenary-form">
        <Drawer
            className="application accomodation-pricing"
            onClose={() => {
                onClose();
            }}
            visible={showModal}
            style={{
                zIndex: dayPriceUpdate ? 1060 : showModal ? 1053 : 1000
            }}
            bodyStyle={{ paddingBottom: 20, paddingTop: 20 }}>
            <Form form={form} onFinish={onSave} onFinishFailed={onFinishFailed}>
                <Grid item className="perPerson">
                    <Grid container style={{ flexDirection: 'column' }}>
                        <div className="">
                            <div className="singleSupplement">
                                {type === 'accomodation' &&
                                    `Accomodations pricing details ${
                                        dayPriceUpdate ? 'for day ' + dayPriceUpdate : ''
                                    }`}
                                {type === 'product_accomodation' &&
                                    'Product pricing details including accomodations'}
                            </div>
                            <p style={{ fontSize: 14 }}>
                                {type === 'product_accomodation' &&
                                    'Enter the additional price you will charge travelers for booking your product with your accomodation per person (PP)'}
                                {type === 'accomodation' &&
                                    'Enter the price you will charge travelers for booking accomodation per person (PP)'}
                            </p>
                        </div>
                        <div>
                            Enter {type === 'product_accomodation' && 'additional'} price for your
                            Accomodations
                        </div>
                        <hr width="100%" />
                        {type !== 'accomodation' ? (
                            <>
                                <Grid item className="perPerson" style={{ margin: 0 }}>
                                    <Grid container>
                                        <Grid item>
                                            <PricingMinMaxAccomodationInputs
                                                name={'accomodation'}
                                                minAccomodation={minAccomodation}
                                                maxAccomodation={maxAccomodation}
                                                setMinAccomodation={setMinAccomodation}
                                                setMaxAccomodation={setMaxAccomodation}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr width="100%" />
                            </>
                        ) : (
                            <>
                                <Grid item className="perPerson" style={{ margin: 0 }}>
                                    <Grid container>
                                        <Grid item>
                                            <p style={{ marginRight: 20 }}>
                                                Enter the minimum number of travelers allowed in a
                                                single booking
                                            </p>
                                            <Form.Item
                                                name="minTravellers"
                                                rules={[
                                                    {
                                                        required: !minTravellers,
                                                        message: 'Please add min travellers'
                                                    }
                                                ]}>
                                                <div>
                                                    <InputNumber
                                                        className="allowed-number travellers-min-max-input"
                                                        min={1}
                                                        value={minTravellers}
                                                        max={maxTravellers}
                                                        onChange={(value) => {
                                                            setMinTravellers(value);
                                                        }}
                                                    />
                                                </div>
                                            </Form.Item>
                                            <p>
                                                Enter the maximum number of travelers allowed in a
                                                single booking
                                            </p>
                                            <Form.Item
                                                name="maxTravellers"
                                                rules={[
                                                    {
                                                        required: !maxTravellers,
                                                        message: 'Please add max travellers'
                                                    }
                                                ]}>
                                                <div>
                                                    <InputNumber
                                                        className="allowed-number travellers-min-max-input"
                                                        min={minTravellers || 1}
                                                        value={maxTravellers}
                                                        onChange={(value) => {
                                                            setMaxTravellers(value);
                                                        }}
                                                    />
                                                </div>
                                            </Form.Item>
                                            <PricingMinMaxAccomodationInputs
                                                name={'product'}
                                                minAccomodation={minAccomodation}
                                                maxAccomodation={maxAccomodation}
                                                setMinAccomodation={setMinAccomodation}
                                                setMaxAccomodation={setMaxAccomodation}
                                            />
                                            {!dayPriceUpdate && (
                                                <Form.Item
                                                    name="selectDays"
                                                    rules={[
                                                        {
                                                            required:
                                                                Array.isArray(selectDays) &&
                                                                selectDays.length > 0
                                                                    ? false
                                                                    : true,
                                                            message: 'Please select atleast one day'
                                                        }
                                                    ]}>
                                                    <DaysSelector
                                                        mt={10}
                                                        data={selectDays}
                                                        pricing={pricing}
                                                        onSave={(value) => {
                                                            setSelectDays(value);
                                                        }}
                                                    />
                                                    <Spacer top={25} />
                                                    <Row style={{ margin: 0 }}>
                                                        <Col sm={1}>Note:</Col>
                                                        <Col sm={10}>
                                                            After you click done, you can add
                                                            another pricing details if you have a
                                                            different price that applies for a
                                                            specific day you have not selected. This
                                                            product will be unavailable on days
                                                            without a price
                                                        </Col>
                                                    </Row>
                                                </Form.Item>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr width="100%" />
                            </>
                        )}
                        <div className="pricing">
                            <Form.Item
                                name="gender"
                                label="Select Gender Options for this product"
                                rules={[
                                    {
                                        required: !gender,
                                        message: 'Please select a gender option'
                                    }
                                ]}
                                style={{ marginBottom: '0px' }}>
                                <div className="pricing group-radio">
                                    {genderOption.map((item, index) => (
                                        <div
                                            className="cursor-pointer radio-btn"
                                            key={index}
                                            style={{
                                                backgroundColor:
                                                    item.value === gender ? '#a2792c' : '#fff',
                                                color: item.value === gender ? '#fff' : '#000',
                                                width: '100px'
                                            }}
                                            tabIndex="0"
                                            role="button"
                                            onKeyDown={() => {
                                                setGender(item.value);
                                            }}
                                            onClick={() => {
                                                setGender(item.value);
                                            }}>
                                            {item.label}
                                        </div>
                                    ))}
                                </div>
                            </Form.Item>
                        </div>
                        <hr width="100%" style={{ marginTop: '0px' }} />
                        <Grid item className="perPerson" style={{ margin: 0 }}>
                            <Grid container>
                                <div style={{ fontWeight: 600 }}>Per person sharing</div>
                                <p style={{ fontSize: 14 }}>
                                    This is the price your enter below is for a person sharing a
                                    room with another person.
                                </p>
                                <Spacer top={18} />
                                <Grid item>
                                    <SharingGroups
                                        data={{
                                            double: {
                                                productPrice: productAdultPrice,
                                                secondPrice: perPersonSharing?.double_room?.amount,
                                                singleSupplement:
                                                    perPersonSharing?.double_room?.single_supplement
                                            },
                                            twin: {
                                                productPrice: productAdultPrice,
                                                secondPrice: perPersonSharing?.twin_room?.amount,
                                                singleSupplement:
                                                    perPersonSharing?.twin_room?.single_supplement
                                            }
                                        }}
                                        isSupplement={true}
                                        type={type}
                                        onChange={onSharingChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Spacer top={15} />
                        <div style={{ fontSize: 14 }}>
                            Single Suplement - An extra charge applied to a traveler who travels
                            alone but will use a room and facilities that cater for two.
                        </div>
                        <Grid item className="perPerson">
                            <Grid container>
                                <div style={{ fontWeight: 600 }}>
                                    Per Person Single / Single Room Occupancy
                                </div>
                                <p style={{ fontSize: 14 }}>
                                    This means that the price you enter below is for one person in a
                                    room.
                                </p>
                                <Spacer top={18} />
                                <Grid item>
                                    <SharingGroups
                                        data={{
                                            double: {
                                                productPrice: productAdultPrice,
                                                secondPrice: perPersonSingle?.double_room?.amount
                                            },
                                            twin: {
                                                productPrice: productAdultPrice,
                                                secondPrice: perPersonSingle?.twin_room?.amount
                                            }
                                        }}
                                        type={type}
                                        onChange={onSingleChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <hr width="100%" />
                        <Grid item className="perPerson">
                            <Grid container>
                                <Row className="book-price">
                                    <div style={{ fontWeight: 600, fontSize: 18 }}>
                                        Add Group Booking Price (Optional)
                                    </div>
                                    <Prompter
                                        fontSize={14}
                                        textColor="#A2792C"
                                        text={
                                            accomodationGroupPrices?.length &&
                                            accomodationGroupPrices?.length > 0
                                                ? `Add More`
                                                : 'Add Group'
                                        }
                                        fon
                                        onClick={addNewBookingGroup}
                                    />
                                </Row>
                            </Grid>
                            <Spacer top={20} />
                            {accomodationGroupPrices?.length &&
                                accomodationGroupPrices?.length > 0 && (
                                    <Row>
                                        <Col sm={4} className="book-subtitles">
                                            <b>Reduce The ‘per person’ rate above by</b>
                                            <div>
                                                Recommended if group price was provided for the
                                                product
                                            </div>
                                        </Col>
                                        <Col sm={8}>
                                            <BookingPrice
                                                setAccomodationGroupPrices={
                                                    setAccomodationGroupPrices
                                                }
                                                accomodationGroupPrices={accomodationGroupPrices}
                                            />
                                        </Col>
                                    </Row>
                                )}
                        </Grid>
                        <hr width="100%" />
                        <Grid item className="perPerson">
                            <Grid container>
                                <Row className="book-price">
                                    <div style={{ fontWeight: 600, fontSize: 18, maxWidth: '60%' }}>
                                        Add Price for Peak/Festive Season Supplement (Optional)
                                    </div>
                                    <Prompter
                                        fontSize={14}
                                        textColor="#A2792C"
                                        text={
                                            accomodationPeakSeasonSupplements?.length > 0
                                                ? `Add More`
                                                : 'Add Price'
                                        }
                                        fon
                                        onClick={addNewPeakSeason}
                                    />
                                </Row>
                            </Grid>

                            <Spacer top={20} />
                            <div></div>
                            {accomodationPeakSeasonSupplements?.length > 0 && (
                                <Row>
                                    <Col xs={12}>
                                        <b>Increase The ‘per person’ rate above by</b>
                                    </Col>
                                    <Col xs={12}>
                                        <SeasonSupplement
                                            setAccomodationPeakSeasonSupplements={
                                                setAccomodationPeakSeasonSupplements
                                            }
                                            accomodationPeakSeasonSupplements={
                                                accomodationPeakSeasonSupplements
                                            }
                                        />
                                    </Col>
                                </Row>
                            )}
                        </Grid>
                        <div ref={bottomRef} />
                    </Grid>
                </Grid>
                <div className="footer-button">
                    <SubmitButtonErrorPopover visible={errors} errors={errors}>
                        <Button color="primary">Save</Button>
                    </SubmitButtonErrorPopover>
                    <Button color="dark">
                        <img src={HelpIcon} alt="" /> Help
                    </Button>
                </div>
            </Form>
        </Drawer>
        // </div>
    );
};

AccomodationPricing.propTypes = {
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    showModal: Proptypes.bool,
    data: Proptypes.object,
    type: Proptypes.string,
    productAdultPrice: Proptypes.any,
    perPersonSharing: Proptypes.any,
    perPersonSingle: Proptypes.any,
    setPerPersonSharing: Proptypes.any,
    setPerPersonSingle: Proptypes.any,
    accomodationGroupPrices: Proptypes.any,
    setAccomodationGroupPrices: Proptypes.any,
    setAccomodationPeakSeasonSupplements: Proptypes.any,
    accomodationPeakSeasonSupplements: Proptypes.any,
    minTravellers: Proptypes.any,
    maxTravellers: Proptypes.any,
    setMinTravellers: Proptypes.any,
    setMaxTravellers: Proptypes.any,
    selectDays: Proptypes.any,
    setSelectDays: Proptypes.any,
    pricing: Proptypes.any,
    minAccomodation: Proptypes.number,
    maxAccomodation: Proptypes.number,
    setMinAccomodation: Proptypes.func,
    setMaxAccomodation: Proptypes.func,
    dayPriceUpdate: Proptypes.object,
    gender: Proptypes.string,
    setGender: Proptypes.func
};

const mapStateToProps = () => ({});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(AccomodationPricing));

import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import thumbs from '../../../assets/images/thumbs.svg';
import headphone from '../../../assets/images/head-phone.svg';

// top banner for pages
export default function BannerComponent({ heading, count, image, subheading }) {
    return (
        <div className="banner" style={{ backgroundImage: `url(${image})` }}>
            <p className="banner-title">{heading}</p>

            <p className="banner-sub-title">{subheading}</p>

            <div className="thumbs-up">
                <img src={thumbs} alt="" />
                {count}% of people recommended this product
            </div>

            <div className="phones">
                <img src={headphone} alt="" />
            </div>
        </div>
    );
}

BannerComponent.propTypes = {
    heading: PropTypes.any,
    count: PropTypes.any,
    image: PropTypes.any,
    subheading: PropTypes.any
};

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Form, Drawer, Checkbox } from 'antd';
import { Button, Row, Col } from 'reactstrap';

import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spacer from '../../../../atoms/spacer';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import CheckIcon from '../../../../../assets/images/check.svg';
import BulbIcon from '../../../../../assets/images/bulb.svg';
import CaretIcon from '../../../../../assets/images/caret.svg';
import Proptypes from 'prop-types';
// import { validateTravellerPersonalInfoFormField } from 'utils/validation';

const collect = [
    {
        title: 'Travellers Name',
        subTitle: '(First name)'
    },
    {
        title: 'Email',
        subTitle: ''
    }
];

class AddExtra extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSeeMore: false,
            personalInformation: [
                {
                    title: 'Full Name',
                    subtitle: '(First and last name of each traveler)',
                    value: null
                },
                {
                    title: 'Weights',
                    subtitle: '',
                    value: null
                },
                {
                    title: 'Heights',
                    subtitle: '',
                    value: null
                },
                {
                    title: 'Date of Birth',
                    subtitle: '',
                    value: null
                },
                {
                    title: 'Phone Number',
                    subtitle: '(including country code)',
                    value: null
                },
                {
                    title: 'Passport Details',
                    subtitle: '(Passport number, country, expiration date)',
                    value: null
                }
            ],
            flightInformation: [
                {
                    title: 'Arrival Flight Details',
                    subtitle: '(airline, flight number, time)',
                    value: null
                },
                {
                    title: 'Departure Flight Details',
                    subtitle: '(airline, flight number, time)',
                    value: null
                }
            ],
            shipInformation: [
                {
                    title: 'Arrival Ship Details',
                    subtitle: '(ship name, disembarkation time)',
                    value: null
                },
                {
                    title: 'Departure Ship Details',
                    subtitle: '(ship name, boarding time)',
                    value: null
                },
                {
                    title: 'Shore Excursion Details',
                    subtitle: '(ship name, disembarkation and boarding times)',
                    value: null
                }
            ],
            trainInformation: [
                {
                    title: 'Arrival Train Details',
                    subtitle: '(roil line, roil station, time)',
                    value: null
                },
                {
                    title: 'Departure Train Details',
                    subtitle: '(roil line, roil station, time)',
                    value: null
                }
            ],
            selectedInfo: []
        };
    }
    componentDidMount() {
        this.setState({ selectedInfo: this.props.data });
    }
    componentDidUpdate(prevProps) {
        if (this.props.data && prevProps.data !== this.props.data) {
            this.setState({ selectedInfo: this.props.data });
        }
    }

    isAdd = (item) => this.state.selectedInfo.findIndex((res) => res === item) !== -1;
    //adding info
    onAdd = (item) => {
        const { selectedInfo } = this.state;
        const isAdded = selectedInfo.findIndex((res) => res === item);
        if (isAdded === -1) {
            this.setState({
                selectedInfo: [...selectedInfo, item]
            });
        } else {
            let tmp = selectedInfo;
            tmp.splice(isAdded, 1);
            this.setState({
                selectedInfo: [...tmp]
            });
        }
    };

    onClose = () => {
        this.props.onClose();
    };
    onSave = () => this.props.onSave(this.state.selectedInfo);

    render() {
        const { isOpen } = this.props;
        const {
            requiredMark,
            personalInformation,
            flightInformation,
            shipInformation,
            trainInformation,
            isSeeMore
        } = this.state;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application add-extra"
                    onClose={() => {
                        this.onClose();
                    }}
                    visible={isOpen}
                    style={{ zIndex: isOpen ? 1052 : 1000 }}
                    bodyStyle={{ paddingBottom: 35 }}>
                    <Form
                        onFinish={this.onSave}
                        layout="vertical"
                        initialValues={{
                            requiredMark
                        }}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}>
                        <div className="header-add-extra">
                            <div>Add Extra Information Required From Customer?</div>
                            <p>Here's what we collect automatically:</p>
                        </div>
                        <div>
                            {collect.map((res, i) => (
                                <div key={i}>
                                    <Row style={{ margin: 0 }}>
                                        <Col sm={1} className="collect-extra">
                                            <img src={CheckIcon} alt="" />
                                        </Col>
                                        <Col className="collect-extra" sm={11}>
                                            <span>{res.title}</span>
                                            <span className="subtitle">{res.subTitle}</span>
                                        </Col>
                                    </Row>
                                    <Spacer top={23} />
                                </div>
                            ))}
                        </div>

                        <div>
                            <p style={{ fontSize: 14 }}>
                                Select any personal information you need from all travellers:
                            </p>
                            <Row className="policy-info">
                                <Col sm={2} style={{ maxWidth: '10.66667%' }}>
                                    <img src={BulbIcon} alt="" />
                                </Col>
                                <Col sm={11} style={{ maxWidth: '88.66667%' }}>
                                    {
                                        "Tip: Travellers are less likely to book if they have to enter lots of information. Only select what's absolutely necessary."
                                    }
                                </Col>
                            </Row>
                        </div>
                        <Form.Item
                            name="personalInformation"
                            // rules={[
                            //     {
                            //         validator: async () =>
                            //             validateTravellerPersonalInfoFormField({
                            //                 data: this.state.selectedInfo
                            //             })
                            //     }
                            // ]}
                        >
                            <div className="checkbox-body">
                                {personalInformation?.map((res, i) => (
                                    <Checkbox
                                        checked={this.isAdd(res.title)}
                                        onChange={() => this.onAdd(res.title)}
                                        key={i}>
                                        <div>{res.title}</div>
                                        {res.subtitle ? (
                                            <span className="subtitle">{res.subtitle}</span>
                                        ) : null}
                                    </Checkbox>
                                ))}
                            </div>
                        </Form.Item>
                        <Spacer top={20} />
                        <div>
                            {!isSeeMore ? (
                                <Button
                                    color="link"
                                    onClick={() => this.setState({ isSeeMore: true })}>
                                    See More <img src={CaretIcon} alt="" />
                                </Button>
                            ) : (
                                <div>
                                    <div style={{ fontSize: 18 }}>
                                        Select any arrival/departure information ypu need:
                                    </div>
                                    <div style={{ marginTop: '20px' }}>Flight Information</div>
                                    <div className="checkbox-body more-information">
                                        {flightInformation?.map((res, i) => (
                                            <Checkbox
                                                checked={this.isAdd(res.title)}
                                                onChange={() => this.onAdd(res.title)}
                                                key={i}>
                                                <div>{res.title}</div>
                                                {res.subtitle ? (
                                                    <span className="subtitle">{res.subtitle}</span>
                                                ) : null}
                                            </Checkbox>
                                        ))}
                                    </div>
                                    <hr width="100%" />
                                    <div>Ship Information</div>
                                    <div className="checkbox-body more-information">
                                        {shipInformation?.map((res, i) => (
                                            <Checkbox
                                                checked={this.isAdd(res.title)}
                                                onChange={() => this.onAdd(res.title)}
                                                key={i}>
                                                <div>{res.title}</div>
                                                {res.subtitle ? (
                                                    <span className="subtitle">{res.subtitle}</span>
                                                ) : null}
                                            </Checkbox>
                                        ))}
                                    </div>
                                    <hr width="100%" />
                                    <div>Train Information</div>
                                    <div className="checkbox-body more-information">
                                        {trainInformation?.map((res, i) => (
                                            <Checkbox
                                                checked={this.isAdd(res.title)}
                                                onChange={() => this.onAdd(res.title)}
                                                key={i}>
                                                <div>{res.title}</div>
                                                {res.subtitle ? (
                                                    <span className="subtitle">{res.subtitle}</span>
                                                ) : null}
                                            </Checkbox>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="footer-button">
                            <div>
                                <Button color="primary" type="submit">
                                    Save
                                </Button>
                                <Button color="dark" onClick={this.onClose}>
                                    Cancel
                                </Button>
                            </div>
                            <Button color="dark" className="help-btn">
                                <img src={HelpIcon} alt="" /> Help
                            </Button>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}
AddExtra.propTypes = {
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    data: Proptypes.any,
    isOpen: Proptypes.bool
};

const mapStateToProps = () => ({});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddExtra));

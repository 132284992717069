import React from 'react';
import { Select, Input } from 'antd';
import { angleIcon } from 'components/pages/customer-calendars/data';
import PropTypes from 'prop-types';

const { Option } = Select;
const CalendarFilters = ({ onChangeFilter, onClearFilter, filterState }) => {
    function onClickClearFilter() {
        onClearFilter();
    }

    return (
        <div className="main-calendar-table-section __padded-sm mb-40px">
            <div className="d-flex justify-content-between mb-2">
                <div className=""></div>
                <div>
                    <span
                        className="fs-12 text-primary fw-700 text-nowrap"
                        role="button"
                        tabIndex="0"
                        onKeyDown={() => onClickClearFilter()}
                        onClick={() => onClickClearFilter()}>
                        Clear all
                    </span>
                </div>
            </div>
            <div className="d-flex flex-wrap m-n2">
                <div className="p-2 flex-grow-1">
                    <Input
                        allowClear
                        value={filterState.searchQuery}
                        size="large"
                        className="bg-transparent placeholder-primary"
                        placeholder="Search by Title"
                        style={{ minWidth: 200 }}
                        onChange={(e) =>
                            onChangeFilter({ type: 'searchQuery', value: e.target.value })
                        }
                    />
                </div>
                <div className="p-2 flex-grow-1">
                    <Input
                        allowClear
                        value={filterState.calendarRef}
                        size="large"
                        className="bg-transparent placeholder-primary"
                        placeholder="Search by Reference Id"
                        style={{ minWidth: 200 }}
                        onChange={(e) =>
                            onChangeFilter({ type: 'calendarRef', value: e.target.value })
                        }
                        // onSearch={() => onChangeReferenceIdInput({ type: 'SETFILTER' })}
                    />
                </div>
                <div className="p-2 flex-grow-1">
                    <Select
                        value={filterState.ratings}
                        className="select-type-filter w-100"
                        dropdownClassName="select-basic-dropdown"
                        size="large"
                        placeholder="Star Rating"
                        suffixIcon={angleIcon}
                        allowClear
                        onChange={(value) => onChangeFilter({ type: 'ratings', value })}>
                        <Option value="">All</Option>
                        <Option value="1">1 Star</Option>
                        <Option value="2">2 Star</Option>
                        <Option value="3">3 Star</Option>
                        <Option value="4">4 Star</Option>
                        <Option value="5">5 Star</Option>
                    </Select>
                </div>
                <div className="p-2 flex-grow-1">
                    <Select
                        value={filterState.calendarStatus}
                        className="select-type-filter w-100"
                        dropdownClassName="select-basic-dropdown"
                        size="large"
                        placeholder="Calendar Status"
                        suffixIcon={angleIcon}
                        allowClear
                        onChange={(value) => onChangeFilter({ type: 'calendarStatus', value })}>
                        <Option value="">All</Option>
                        <Option value="live">Live</Option>
                        <Option value="draft">Draft</Option>
                        {/* <Option value="archive">Archive</Option> */}
                    </Select>
                </div>
                <div className="p-2 flex-grow-1">
                    <Select
                        value={filterState.customerStatus}
                        className="select-type-filter w-100"
                        dropdownClassName="select-basic-dropdown"
                        size="large"
                        placeholder="Customer Status"
                        suffixIcon={angleIcon}
                        allowClear
                        onChange={(value) => onChangeFilter({ type: 'customerStatus', value })}>
                        <Option value="">All</Option>
                        <Option value="in-use">In use</Option>
                        <Option value="not-in-use">Not in use</Option>
                    </Select>
                </div>
            </div>
        </div>
    );
};

CalendarFilters.propTypes = {
    onChangeFilter: PropTypes.func,
    onClearFilter: PropTypes.func,
    filterState: PropTypes.object
};

export default CalendarFilters;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

export default function TotalCards(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(151, 151, 151, 0.22)',
            borderRadius: '4px',
            padding: '21px 26px'
        },
        sliderParent: {
            background: 'rgba(196, 196, 196, 0.3)',
            borderRadius: '20px',
            width: '100%',
            height: '5px',
            marginTop: '7px',
            marginBottom: '7px'
        },
        slider: {
            width: props.percentage,
            height: '100%',
            background: props.color,
            borderRadius: '20px'
        },
        title: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: '#333333',
            marginBottom: '5px'
        },
        total: {
            fontWeight: '600',
            fontSize: '28px',
            lineHeight: '34px',
            marginRight: '5px',
            color: '#333333'
        },
        change: {
            fontWeight: 'normal',
            fontSize: '10px',
            lineHeight: '21px',
            /* or 210% */
            letterSpacing: '0.1px',
            color: props.changeColor
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>{props.title}</div>
            <div
                style={{
                    marginBottom: '8px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                <span className={classes.total}>{props.total}</span>
                <span className={classes.change}>{props.change}</span>
            </div>
            <div
                style={{
                    fontWeight: '500',
                    fontSize: '12px',
                    lineHeight: '21px',
                    /* or 175% */
                    letterSpacing: '0.1px',
                    color: 'rgba(51, 51, 51, 0.63)'
                }}>
                Overview of last month
            </div>
            <div className={classes.sliderParent}>
                <div className={classes.slider}></div>
            </div>
            <Grid container justify="space-between">
                <Grid
                    item
                    style={{
                        fontWeight: '500',
                        fontSize: '10px',
                        lineHeight: '21px',
                        /* or 210% */

                        letterSpacing: '0.1px',

                        color: '#333333'
                    }}>
                    Monthly
                </Grid>
                <Grid
                    item
                    style={{
                        fontWeight: 'normal',
                        fontSize: '10px',
                        lineHeight: '21px',
                        /* or 210% */

                        letterSpacing: '0.1px',

                        color: 'rgba(51, 51, 51, 0.63)'
                    }}>
                    {props.percentage}
                </Grid>
            </Grid>
        </div>
    );
}

/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './index.css';
// import ButtonDropdown from '../../../atoms/buttondrop';
import SingleCollapse from '../../../atoms/ant-collapse/single';
import { Col, Row } from 'reactstrap';
import { Button, Popover, Tooltip } from 'antd';
import {
    CheckCircleFilled,
    CloseCircleFilled,
    EyeFilled,
    EyeInvisibleFilled
} from '@ant-design/icons';
import ConfirmationModal from '../../../molecules/modals/confirmation-modal';
import { ComponentPermissionContext } from 'context/rolesandpermission';

//Mock data
// const newData = [
//     {
//         name: '1 Week Calendar: Victoria falls & Chobe Calendar Trip (013529)'
//     },
//     {
//         name: '1 Week Calendar: Victoria falls & Chobe Calendar Trip (013529)'
//     }
// ];

let noBookingStatusContent = (
    <div className="fs-18 text-primary fw-600 op-6">You have No bookings to attend to!</div>
);
let bookingStatusContent = (
    <div className="fs-18 text-primary fw-600">You have bookings to attend to!</div>
);

// const searchData = ['4 day Kruger 271597P3', '4 day Kruger 271597P3', '4 day Kruger 271597P3'];
class Bookings extends Component {
    static contextType = ComponentPermissionContext;
    static get propTypes() {
        return {
            children: PropTypes.any
        };
    }
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
            dropdown: false,
            currentWindowWidth: window.innerWidth,
            hideBookingNotificationModal: false
        };
        // this.changeDropDown = this.changeDropDown.bind(this);
    }

    //Function for dropdown

    // changeDropDown() {
    //     this.setState((prevState) => ({
    //         dropdown: !prevState.dropdown
    //     }));
    // }

    handleResize = () => {
        this.setState({ currentWindowWidth: window.innerWidth });
    };
    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
        console.log();
    }

    setHideBookingNotificationModal = () => {
        this.setState({ hideBookingNotificationModal: !this.state.hideBookingNotificationModal });
    };

    handleConfirmHideBooking = () => {
        return 'confimed hide';
    };

    render() {
        const { currentWindowWidth } = this.state;
        const eyeClosedIcon = (
            <svg
                width="24"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M21.3373 0.659844C20.9945 0.312113 20.4362 0.31701 20.0884 0.659844L16.9539 3.79432C13.0701 2.03118 8.92675 2.46217 4.97437 5.04321C2.0309 6.96308 0.243272 9.37761 0.169807 9.48046C-0.0652784 9.8037 -0.0554832 10.2445 0.194295 10.5579C1.86928 12.6149 3.62263 14.2165 5.41516 15.3331L2.6578 18.0905C2.31496 18.4333 2.31496 18.9916 2.6578 19.3394C2.82921 19.5108 3.05451 19.5989 3.2798 19.5989C3.50509 19.5989 3.73038 19.5108 3.90179 19.3394L21.3373 1.90874C21.6802 1.5659 21.6802 1.00757 21.3373 0.659844ZM9.81812 10.9302C9.69078 10.6412 9.62711 10.3277 9.62711 9.9996C9.62711 9.36781 9.87199 8.7703 10.3226 8.31972C11.0278 7.61446 12.071 7.44794 12.933 7.81526L9.81812 10.9302ZM14.2358 6.5174C12.6342 5.4889 10.4744 5.67501 9.07858 7.07083C8.29496 7.85445 7.86887 8.89274 7.86887 9.99471C7.86887 10.7979 8.09905 11.5668 8.52515 12.228L6.70813 14.045C5.1164 13.1243 3.54916 11.7627 2.02601 9.98491C2.67739 9.22088 4.05362 7.7467 5.9392 6.5174C9.20102 4.39183 12.4481 3.93145 15.612 5.14117L14.2358 6.5174Z"
                    fill="currentColor"
                />
                <path
                    d="M23.8057 9.44148C22.596 7.9526 21.3422 6.69881 20.0688 5.70459C19.6819 5.40584 19.1284 5.4744 18.8248 5.85642C18.526 6.23843 18.5946 6.79186 18.9766 7.09552C19.9855 7.88403 20.9944 8.86356 21.974 10.0096C21.396 10.6855 20.2696 11.9001 18.7415 13.0118C15.7981 15.1521 12.8252 15.9259 9.91113 15.3088C9.43606 15.206 8.96589 15.5145 8.86794 15.9896C8.76509 16.4647 9.07364 16.9348 9.5487 17.0328C10.3078 17.1944 11.0719 17.2728 11.8408 17.2728C12.9966 17.2728 14.1623 17.0916 15.323 16.7291C16.8462 16.2541 18.3595 15.4705 19.8239 14.4028C22.2923 12.5956 23.7665 10.6022 23.8302 10.519C24.0652 10.1957 24.0555 9.75493 23.8057 9.44148Z"
                    fill="currentColor"
                />
            </svg>
        );
        return (
            <div
                className={`e2e-fix ${console.log(
                    'o,mooo',
                    this.context.assignComponentPermission('bookings')
                )}`}>
                {/* If Bookings or no booking to attend */}
                <div className="bg-calendar bg-booking-padded">
                    {true ? (
                        <SingleCollapse
                            panelIcon={
                                <img
                                    src={require('../../../../assets/images/thumbs-up.svg')}
                                    alt="OK"
                                />
                            }
                            panelTitle={bookingStatusContent}>
                            <div className="overflow-y-auto overflow-x-hidden scrollbar-fixed booking-collapsible-details pr-2">
                                <div className="pt-10px pb-10px border-bottom">
                                    <Row className="align-items-center" noGutters>
                                        <Col xs={12} className="col-md">
                                            <div className="fs-15 fw-400 mr-2 mb-2 mb-md-0">
                                                <Popover
                                                    overlayClassName="popover-container popover-regular popover-light"
                                                    content={
                                                        <div className="text-center">
                                                            <div className="mb-3">
                                                                <svg
                                                                    width="46"
                                                                    height="44"
                                                                    viewBox="0 0 46 44"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M45.0442 33.4954L28.7946 3.62455C26.1839 -0.770694 19.8196 -0.776533 17.2053 3.62455L0.956556 33.4954C-1.71234 37.9866 1.51889 43.6739 6.74986 43.6739H39.2496C44.4761 43.6739 47.7131 37.9912 45.0442 33.4954ZM23 38.2833C21.5142 38.2833 20.3047 37.0738 20.3047 35.588C20.3047 34.1022 21.5142 32.8927 23 32.8927C24.4858 32.8927 25.6953 34.1022 25.6953 35.588C25.6953 37.0738 24.4858 38.2833 23 38.2833ZM25.6953 27.5021C25.6953 28.9879 24.4858 30.1974 23 30.1974C21.5142 30.1974 20.3047 28.9879 20.3047 27.5021V14.0255C20.3047 12.5397 21.5142 11.3302 23 11.3302C24.4858 11.3302 25.6953 12.5397 25.6953 14.0255V27.5021Z"
                                                                        fill="#EAA948"
                                                                    />
                                                                </svg>
                                                            </div>
                                                            <div className="fs-16 fw-500 lh-1-3">
                                                                Booking will be automatically
                                                                accepted after 1 day
                                                            </div>
                                                        </div>
                                                    }
                                                    placement="bottom"
                                                    trigger="click">
                                                    <div className="d-inline-block mr-2 cursor-pointer">
                                                        <span className="fw-600">
                                                            1 Week Calendar:
                                                        </span>{' '}
                                                        <span className="fs-14">
                                                            Victoria falls & Chobe Calendar Trip
                                                            (013529)
                                                        </span>{' '}
                                                    </div>
                                                </Popover>
                                            </div>
                                        </Col>
                                        <Col xs={12} md="auto">
                                            <Row className="m-n1 align-items-center">
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="success"
                                                        icon={<CheckCircleFilled />}
                                                        size="small">
                                                        Accept
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="danger"
                                                        icon={<CloseCircleFilled />}
                                                        size="small">
                                                        Reject
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="dark"
                                                        icon={<EyeFilled />}
                                                        size="small">
                                                        Details
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    {currentWindowWidth < 768 ? (
                                                        <Button
                                                            className="fs-14 fw-400 btn-block"
                                                            type="primary"
                                                            icon={<EyeInvisibleFilled />}
                                                            size="small"
                                                            onClick={() =>
                                                                this.setState({
                                                                    hideBookingNotificationModal: true
                                                                })
                                                            }>
                                                            Hide
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title="Hide Booking">
                                                            <Button
                                                                className="fs-14 fw-400 btn-block"
                                                                type="text"
                                                                icon={
                                                                    <span className="text-primary">
                                                                        {eyeClosedIcon}
                                                                    </span>
                                                                }
                                                                size="small"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        hideBookingNotificationModal: true
                                                                    })
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="pt-10px pb-10px border-bottom">
                                    <Row className="align-items-center" noGutters>
                                        <Col xs={12} className="col-md">
                                            <div className="fs-15 fw-400 mr-2 mb-2 mb-md-0">
                                                <div className="d-inline-block mr-2 cursor-pointer">
                                                    <span className="fw-600">
                                                        2 Weeks Calendar:
                                                    </span>{' '}
                                                    <span className="fs-14">
                                                        Victoria falls & Chobe Calendar Trip
                                                        (013529)
                                                    </span>{' '}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md="auto">
                                            <Row className="m-n1 align-items-center">
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="success"
                                                        icon={<CheckCircleFilled />}
                                                        size="small">
                                                        Accept
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="danger"
                                                        icon={<CloseCircleFilled />}
                                                        size="small">
                                                        Reject
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="dark"
                                                        icon={<EyeFilled />}
                                                        size="small">
                                                        Details
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    {currentWindowWidth < 768 ? (
                                                        <Button
                                                            className="fs-14 fw-400 btn-block"
                                                            type="primary"
                                                            icon={<EyeInvisibleFilled />}
                                                            size="small"
                                                            onClick={() =>
                                                                this.setState({
                                                                    hideBookingNotificationModal: true
                                                                })
                                                            }>
                                                            Hide
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title="Hide Booking">
                                                            <Button
                                                                className="fs-14 fw-400 btn-block"
                                                                type="text"
                                                                icon={
                                                                    <span className="text-primary">
                                                                        {eyeClosedIcon}
                                                                    </span>
                                                                }
                                                                size="small"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        hideBookingNotificationModal: true
                                                                    })
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="pt-10px pb-10px border-bottom">
                                    <Row className="align-items-center" noGutters>
                                        <Col xs={12} className="col-md">
                                            <div className="fs-15 fw-400 mr-2 mb-2 mb-md-0">
                                                <div className="d-inline-block mr-2 cursor-pointer">
                                                    <span className="fw-600">1 Week Calendar:</span>{' '}
                                                    <span className="fs-14">
                                                        Victoria falls & Chobe Calendar Trip
                                                        (013529)
                                                    </span>{' '}
                                                </div>
                                                <div className="d-inline-block">
                                                    <span
                                                        className="fs-14 fw-600 text-primary text-nowrap"
                                                        role="button"
                                                        tabIndex="0">
                                                        Refund Request
                                                    </span>{' '}
                                                    <sup>
                                                        <Tooltip title="Info">
                                                            <img
                                                                src={require('../../../../assets/images/info.svg')}
                                                                alt=""
                                                            />
                                                        </Tooltip>
                                                    </sup>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md="auto">
                                            <Row className="m-n1 align-items-center">
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="success"
                                                        icon={<CheckCircleFilled />}
                                                        size="small">
                                                        Accept
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="danger"
                                                        icon={<CloseCircleFilled />}
                                                        size="small">
                                                        Reject
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="dark"
                                                        icon={<EyeFilled />}
                                                        size="small">
                                                        Details
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    {currentWindowWidth < 768 ? (
                                                        <Button
                                                            className="fs-14 fw-400 btn-block"
                                                            type="primary"
                                                            icon={<EyeInvisibleFilled />}
                                                            size="small"
                                                            onClick={() =>
                                                                this.setState({
                                                                    hideBookingNotificationModal: true
                                                                })
                                                            }>
                                                            Hide
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title="Hide Booking">
                                                            <Button
                                                                className="fs-14 fw-400 btn-block"
                                                                type="text"
                                                                icon={
                                                                    <span className="text-primary">
                                                                        {eyeClosedIcon}
                                                                    </span>
                                                                }
                                                                size="small"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        hideBookingNotificationModal: true
                                                                    })
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="pt-10px pb-10px border-bottom">
                                    <Row className="align-items-center" noGutters>
                                        <Col xs={12} className="col-md">
                                            <div className="fs-15 fw-400 mr-2 mb-2 mb-md-0">
                                                <div className="d-inline-block mr-2 cursor-pointer">
                                                    <span className="fw-600">1 Week Calendar:</span>{' '}
                                                    <span className="fs-14">
                                                        Victoria falls & Chobe Calendar Trip
                                                        (013529)
                                                    </span>{' '}
                                                </div>
                                                <div className="d-inline-block">
                                                    <span
                                                        className="fs-14 fw-600 text-primary text-nowrap"
                                                        role="button"
                                                        tabIndex="0">
                                                        Amended By Customer
                                                    </span>{' '}
                                                    <sup>
                                                        <Tooltip title="Info">
                                                            <img
                                                                src={require('../../../../assets/images/info.svg')}
                                                                alt=""
                                                            />
                                                        </Tooltip>
                                                    </sup>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md="auto">
                                            <Row className="m-n1 align-items-center">
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="success"
                                                        icon={<CheckCircleFilled />}
                                                        size="small">
                                                        Accept
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="danger"
                                                        icon={<CloseCircleFilled />}
                                                        size="small">
                                                        Reject
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="dark"
                                                        icon={<EyeFilled />}
                                                        size="small">
                                                        Details
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    {currentWindowWidth < 768 ? (
                                                        <Button
                                                            className="fs-14 fw-400 btn-block"
                                                            type="primary"
                                                            icon={<EyeInvisibleFilled />}
                                                            size="small"
                                                            onClick={() =>
                                                                this.setState({
                                                                    hideBookingNotificationModal: true
                                                                })
                                                            }>
                                                            Hide
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title="Hide Booking">
                                                            <Button
                                                                className="fs-14 fw-400 btn-block"
                                                                type="text"
                                                                icon={
                                                                    <span className="text-primary">
                                                                        {eyeClosedIcon}
                                                                    </span>
                                                                }
                                                                size="small"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        hideBookingNotificationModal: true
                                                                    })
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                                <div className="pt-10px pb-10px border-bottom">
                                    <Row className="align-items-center" noGutters>
                                        <Col xs={12} className="col-md">
                                            <div className="fs-15 fw-400 mr-2 mb-2 mb-md-0">
                                                <div className="d-inline-block mr-2 cursor-pointer">
                                                    <span className="fw-600">1 Week Calendar:</span>{' '}
                                                    <span className="fs-14">
                                                        Victoria falls & Chobe Calendar Trip
                                                        (013529)
                                                    </span>{' '}
                                                </div>
                                            </div>
                                        </Col>
                                        <Col xs={12} md="auto">
                                            <Row className="m-n1 align-items-center">
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="success"
                                                        icon={<CheckCircleFilled />}
                                                        size="small">
                                                        Accept
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="danger"
                                                        icon={<CloseCircleFilled />}
                                                        size="small">
                                                        Reject
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    <Button
                                                        className="fs-14 fw-400 btn-block"
                                                        type="dark"
                                                        icon={<EyeFilled />}
                                                        size="small">
                                                        Details
                                                    </Button>
                                                </Col>
                                                <Col xs={6} md="auto" className="p-1">
                                                    {currentWindowWidth < 768 ? (
                                                        <Button
                                                            className="fs-14 fw-400 btn-block"
                                                            type="primary"
                                                            icon={<EyeInvisibleFilled />}
                                                            size="small"
                                                            onClick={() =>
                                                                this.setState({
                                                                    hideBookingNotificationModal: true
                                                                })
                                                            }>
                                                            Hide
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title="Hide Booking">
                                                            <Button
                                                                className="fs-14 fw-400 btn-block"
                                                                type="text"
                                                                icon={
                                                                    <span className="text-primary">
                                                                        {eyeClosedIcon}
                                                                    </span>
                                                                }
                                                                size="small"
                                                                onClick={() =>
                                                                    this.setState({
                                                                        hideBookingNotificationModal: true
                                                                    })
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </SingleCollapse>
                    ) : (
                        <SingleCollapse
                            panelIcon={
                                <img
                                    src={require('../../../../assets/images/thumbs-up.svg')}
                                    alt="OK"
                                />
                            }
                            panelTitle={noBookingStatusContent}
                        />
                    )}

                    {/* <div className="white-bg-color d-flex justify-content-between">
                        <div className="width-100">
                            <div className="d-flex">
                                <img alt="" width="4%" src={thumbsUp} />
                                <p
                                    className="up-to-date align-self-center"
                                    style={{ color: '#A2792C' }}>
                                    You are up to date
                                </p>
                            </div>
                            <div className="margin-up">
                                <>
                                    {this.state.dropdown ? (
                                        <>
                                            {newData.map((item, i) => (
                                                <table className="table" key={i}>
                                                    <tbody>
                                                        <tr>
                                                            <td
                                                                style={{
                                                                    color: '#34A939',
                                                                    fontWeight: 600
                                                                }}>
                                                                Accept
                                                        </td>
                                                            <td
                                                                style={{
                                                                    color: '#FF3D00',
                                                                    fontWeight: 600
                                                                }}>
                                                                Reject
                                                        </td>
                                                            <td>{item.name}</td>
                                                            <td
                                                                style={{
                                                                    color: '#A2792C',
                                                                    fontWeight: 600
                                                                }}>
                                                                View Details
                                                        </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            ))}
                                        </>
                                    ) : (
                                        <p>You currently have no bookings that require attention</p>
                                    )}
                                </>
                            </div>
                        </div>
                        <div style={{ paddingRight: 28 }} className="d-flex align-items-start">
                            <img
                                alt=""
                                className="cursor-pointer"
                                onClick={this.changeDropDown}
                                src={this.state.dropdown ? Caret : Down}
                            />
                        </div>
                    </div> */}
                </div>

                {/* All booking in calendar view */}
                <div
                    className={`booking-box-padded ${this.context.assignComponentPermission(
                        'bookings'
                    )}`}>
                    {/* <div className=" d-flex justify-content-between pad-cont">
                        <div>
                            <input
                                style={{ color: '#4F4F4F' }}
                                className="brown-bg"
                                placeholder="BR- e.g. 9147869"
                            />
                        </div>
                        <DateRangePicker
                            startDatePlaceholderText="From"
                            endDatePlaceholderText="To"
                            startDateId="startDate"
                            endDateId="endDate"
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            onDatesChange={({ startDate, endDate }) => {
                                this.setState({ startDate, endDate });
                            }}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={(focusedInput) => {
                                this.setState({ focusedInput });
                            }}
                        />

                        <NewDropdown
                            position="relative"
                            absolute="absolute"
                            name="Booking Status"
                            caret={Caret}
                            down={Down}
                            isCheckBox={false}
                            isSidebar={false}
                            className="brown-bg-drop cursor-pointer"
                            class={'image-icon-down'}
                            icon={'image-icon'}>
                            <NewDropdown
                                name="Accept"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                            <NewDropdown
                                name="Reject"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                            <NewDropdown
                                name="Awaiting Confirmation"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                            <NewDropdown
                                name="Ammend Request"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                            <NewDropdown
                                name="Refund Request"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                        </NewDropdown>

                        <div>
                            <input
                                style={{ color: '#4F4F4F' }}
                                className="brown-bg"
                                placeholder="Lead traveller"
                            />
                        </div>
                    </div> */}
                    {/* <div className=" d-flex pad-cont">
                        <NewDropdown
                            position="relative"
                            absolute="absolute"
                            padright="15px"
                            name="All products (3)"
                            caret={Caret}
                            down={Down}
                            isCheckBox={false}
                            isSidebar={false}
                            className="brown-bg-drop cursor-pointer"
                            class={'image-icon-down'}
                            icon={'image-icon'}>
                            <div style={{ width: '100%' }} className="product-input">
                                <div className="product-pad">
                                    <div className="input-group mb-3 product-input-text">
                                        <input
                                            type="text"
                                            className="form-control input-booking"
                                            placeholder="Search"
                                            id="Search"
                                            name="Search"
                                        />
                                        <div className="input-group-append">
                                            <span className=" grey-input input-group-text input-subtext">
                                                <img alt="" src={Search} />
                                            </span>
                                        </div>
                                    </div>
                                    {searchData.map((data, i) => (
                                        <InputBox other={data} key={i} />
                                    ))}
                                </div>

                                <hr className="hor-line" />
                                <div className="d-flex justify-content-between product-pad">
                                    <div>
                                        <p style={{ color: '#A2792C', fontWeight: '800' }}>Clear all</p>
                                    </div>
                                    <div>
                                        <button className="button-outline">Cancel</button>
                                        <button style={{ marginLeft: '20px' }} className="button-save">
                                            Apply
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </NewDropdown>
                        <NewDropdown
                            position="relative"
                            absolute="absolute"
                            relative="absolute"
                            name="New booking list"
                            caret={Caret}
                            down={Down}
                            isCheckBox={false}
                            isSidebar={false}
                            className="brown-bg-drop cursor-pointer"
                            class={'image-icon-down'}
                            icon={'image-icon'}>
                            <NewDropdown
                                name="New booking list"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                            <NewDropdown
                                name="Travel date"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                            <NewDropdown
                                name="Date submitted"
                                isCheckBox={false}
                                isSidebar={false}
                                className="brown-bg-up cursor-pointer"
                            />
                        </NewDropdown>
                    </div> */}
                    {/* <div
                        className="d-flex justify-content-between"
                        style={{ marginRight: '15%', paddingTop: 20 }}>
                        <div className="d-flex">
                            <img alt="" src={Filter} />
                            <div className="d-flex" style={{ paddingTop: '12px' }}>
                                <p className="quick-left-big">Quick filters:</p>
                                <p className="quick-left">Today |</p>
                                <p className="quick-left">Tomorrow |</p>
                                <p className="quick-left">Next 7 days</p>
                            </div>
                        </div>
                        <div className="d-flex">
                            <img alt="" src={Cancel} />
                            <div style={{ marginTop: '13px' }} className="align-items-center">
                                <p>Clear all filters</p>
                            </div>
                        </div>
                    </div>
                    <hr className="pad-cont" /> */}
                    {this.props.children}
                </div>

                {/* Confirmation Modal Popup */}
                <ConfirmationModal
                    isModalOpen={this.state.hideBookingNotificationModal}
                    setModalOpenState={this.setHideBookingNotificationModal}
                    description={'Are you sure you want to hide this booking notification?'}
                    handleConfirmation={this.handleConfirmHideBooking}
                />
            </div>
        );
    }
}

export default Bookings;

import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Form, Button, Input } from 'antd';
import WarningIcon from '../../../../assets/images/warning-icon.svg';
import PropTypes from 'prop-types';
import InfoIcon from '../../../../assets/images/info-white.svg';
import { cancelProduct } from 'store/actions/availabilityActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const CancelModal = ({ isOpen, toggleModal, refreshList, openedRecord, cancelProduct }) => {
    const [confirmationWindow, setConfirmationWindow] = useState(false);
    const [lastCallWindow, setLastCallWindow] = useState(false);
    const [cancelModalForm] = Form.useForm();

    const toggleCancelModal = () => {
        toggleModal();
        setConfirmationWindow(false);
        setLastCallWindow(false);
        cancelModalForm.resetFields();
    };

    const openLastWindow = () => {
        setConfirmationWindow(false);
        setLastCallWindow(true);
    };

    const closeAllWindows = () => {
        setLastCallWindow(false);
        setConfirmationWindow(false);
    };

    const onFinish = () => {
        let reason = cancelModalForm.getFieldsValue().reason;
        let params = [];
        if (openedRecord) {
            params.push(`productId=${openedRecord?.id}`);
        }
        if (reason) {
            params.push(`reason=${reason}`);
        }
        let data = {
            params: params.join('&')
        };
        cancelProduct(data)
            .then(() => {
                openLastWindow();
                refreshList();
            })
            .catch((e) => console.error(e));
    };

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleCancelModal}>
            <ModalBody className="text-center">
                <Form form={cancelModalForm} requiredMark={false} onFinish={onFinish}>
                    <div className="d-flex flex-row note mb-4">
                        <div className="icon-container">
                            <img src={InfoIcon} alt="" className="p-3" />
                        </div>
                        <div style={{ fontSize: 11 }} className="p-2">
                            <p className="m-0" style={{ textAlign: 'initial' }}>
                                Stop Selling the product for all customers. <br /> This includes new
                                customers and existing customers.
                            </p>
                        </div>
                    </div>

                    <img src={WarningIcon} alt="" />
                    <div className="modal-body-title-lg mt-2 mb-2">
                        {!confirmationWindow && !lastCallWindow && 'Cancel?'}
                        {confirmationWindow && 'Cancel and Refund?'}
                        {lastCallWindow && 'Cancelled'}
                    </div>
                    <p className="modal-body-text">
                        {!confirmationWindow && !lastCallWindow && (
                            <span>
                                Stop selling the product for all customers. <br /> This includes new
                                customers and existing customers.
                            </span>
                        )}
                        {confirmationWindow && (
                            <span style={{ maxWidth: '621px' }}>
                                Are you sure you want to cancel sales for this product
                                <b className="fs-18"> with all refunds</b> processed immediately to
                                existing customers?
                            </span>
                        )}
                        {lastCallWindow && (
                            <span style={{ maxWidth: '621px' }}>
                                This product will now stop selling immediately. <br />
                                Refunds will be processed to all existing customers.
                            </span>
                        )}
                    </p>
                    {confirmationWindow && (
                        <div>
                            <Form.Item
                                name="reason"
                                rules={[{ required: true, message: 'Please enter a reason' }]}>
                                <Input
                                    className="cancel__input"
                                    placeholder="If Yes, State reason"
                                />
                            </Form.Item>
                        </div>
                    )}
                    <div className="mt-4">
                        {!confirmationWindow && !lastCallWindow && (
                            <>
                                <Button
                                    id="pauseSaleTooltip1"
                                    type="primary"
                                    className="btn-mw mx-2"
                                    onClick={() => {
                                        setConfirmationWindow(true);
                                    }}>
                                    Yes
                                </Button>
                                <Button
                                    id="pauseSaleTooltip2"
                                    type="dark"
                                    className="btn-mw mx-2"
                                    onClick={() => toggleCancelModal()}>
                                    No
                                </Button>
                            </>
                        )}
                        {confirmationWindow && !lastCallWindow && (
                            <>
                                <Button
                                    id="pauseSaleTooltip3"
                                    type="primary"
                                    className="btn-mw mx-2"
                                    htmlType="submit"
                                    //onClick={() => openLastWindow()}
                                >
                                    Yes
                                </Button>
                                <Button
                                    id="pauseSaleTooltip4"
                                    type="dark"
                                    className="btn-mw mx-2"
                                    onClick={() => closeAllWindows()}>
                                    No
                                </Button>
                            </>
                        )}
                        {lastCallWindow && (
                            <Button
                                type="dark"
                                className="btn-mw mx-2"
                                onClick={() => toggleCancelModal()}>
                                OK
                            </Button>
                        )}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};
CancelModal.propTypes = {
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    openedRecord: PropTypes.any,
    cancelProduct: PropTypes.func,
    refreshList: PropTypes.func
};
const mapDispatch = (dispatch) => ({
    cancelProduct: (params) => dispatch(cancelProduct(params))
});

export default withRouter(connect(null, mapDispatch)(CancelModal));

import React from 'react';

const SuccessIcon = () => {
    return (
        <svg
            width="90"
            height="90"
            viewBox="0 0 90 90"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M76.8197 13.1801C68.3204 4.68088 57.0199 0 45 0C32.9799 0 21.6795 4.68088 13.1801 13.1801C4.68088 21.6795 0 32.9801 0 45C0 57.0199 4.68088 68.3204 13.1801 76.8197C21.6795 85.3191 32.9801 90 45 90C57.0199 90 68.3204 85.3191 76.8197 76.8199C85.3191 68.3204 90 57.0199 90 45C90 32.9801 85.3191 21.6796 76.8197 13.1801ZM66.4991 34.2902L41.351 59.4385C40.8361 59.9534 40.1613 60.2107 39.4866 60.2107C38.8118 60.2107 38.137 59.9534 37.6221 59.4385L23.5009 45.3175C22.4712 44.2879 22.4712 42.6185 23.5009 41.5886C24.5304 40.5589 26.2 40.5589 27.2297 41.5886L39.4866 53.8453L62.7703 30.5613C63.7998 29.5316 65.4694 29.5316 66.4991 30.5613C67.5288 31.5911 67.5288 33.2604 66.4991 34.2902Z"
                fill="#34A939"
            />
        </svg>
    );
};

export default SuccessIcon;

/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Link, withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Loader } from 'components/atoms';
import { Feedback } from 'components/molecules';
import UpdateSlotsModal from 'components/organisms/views/updateavailability/updateSlotsModal';
import CalendarAccessUpdateModal from 'components/organisms/views/updateavailability/calendarAccessUpdateModal';

import { disableDate } from 'utils/index';
import { getCalendarsByProduct } from 'store/actions';

import './index.css';
import { Row, Col } from 'reactstrap';
import { Table, DatePicker, Button, Pagination } from 'antd';

const UpdateAvailability = (props) => {
    const [page, setPage] = useState(1);
    const [perPage] = useState(10);
    const [specificSpacesNumber, setSpecificSpacesNumber] = useState(0);
    const [filter, setFilter] = useState({ dateQuery: moment().format('YYYY-MM-DD') });
    const [openCalendarAccessUpdateModal, setOpenCalendarAccessUpdateModal] = useState(false);
    const [openSlots, setOpenSlots] = useState(false);
    const [calendarId, setCalendarId] = useState(null);
    const [access, setAccess] = useState(null);
    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');
    const { id } = useParams();

    const { loading, calendars, getCalendarsByProduct } = props;

    const columns = [
        {
            title: 'CALENDAR ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 130
        },
        {
            title: 'CALENDAR LENGTH',
            dataIndex: 'length',
            key: 'length',
            width: 150,
            align: 'center',
            render: function displayLenght(_, record) {
                const length = record.no_of_weeks * 7 + record.no_of_days;
                return (
                    <div className="fs-14 text-nowrap">
                        <span className="fs-14 text-gray text-capitalize">{`${length} days`}</span>
                    </div>
                );
            }
        },
        {
            title: 'CALENDAR TITLE',
            dataIndex: 'title',
            key: 'title',
            width: 200,
            align: 'center',
            render: function diaplayTitle(_, record) {
                return (
                    <div className="text-truncate" title={record.title}>
                        {record.title ? record.title : 'N/A'}
                    </div>
                );
            }
        },
        {
            title: 'TYPE',
            dataIndex: 'product_type',
            key: 'type',
            width: 100,
            align: 'center',
            render: function displayType(_, record) {
                const type =
                    (record.product_type === 'addon_product' && 'Add-on Product') ||
                    (record.product_type === 'main_product' && 'Main Product') ||
                    (record.product_type === 'package' && 'Package') ||
                    (record.product_type === ('' || null || undefined) && 'N/A');

                return (
                    <div className="fs-14 text-nowrap">
                        <span className="fs-14 text-gray">{type}</span>
                    </div>
                );
            }
        },
        {
            title: 'CATEGORY',
            dataIndex: 'product_category',
            key: 'category',
            width: 140,
            align: 'center'
        },
        {
            title: 'SUB-CATEGORY',
            dataIndex: 'product_sub_category',
            key: 'subCategory',
            width: 150,
            align: 'center'
        },
        {
            title: 'PRODUCT STATUS',
            dataIndex: 'status',
            key: 'status',
            width: 200,
            align: 'center',
            render: function updateStatusName(_text, record) {
                const status =
                    record.booking_count === 0
                        ? 'Not In Use'
                        : record.booking_count > 0
                        ? 'In Use'
                        : !record.booking_count || record.booking_count === ''
                        ? 'N/A'
                        : '';

                const statusColor =
                    record.booking_count === 0
                        ? 'not_in_use'
                        : record.booking_count > 0 && 'in_use';
                return (
                    <div className="fs-14 text-nowrap">
                        <span
                            className={`calendar_activity-status __${statusColor}`}
                            style={{
                                display: statusColor === false ? 'none' : 'inline-block'
                            }}></span>
                        <span className="fs-14 text-gray text-capitalize">{status}</span>
                    </div>
                );
            }
        },
        {
            /** Update to show actual access */
            title: 'ACCESS STATUS',
            dataIndex: '',
            key: 'available',
            width: 200,
            align: 'center',
            render: function statusIndicator(_text, record) {
                const accessRecord = record.ccm_access && record.ccm_access - record.booking_count;
                const status = accessRecord <= 0 || !accessRecord ? 'no' : 'yes';

                const statusColor = accessRecord <= 0 ? 'no' : 'yes';

                return (
                    <div className="fs-14 text-nowrap">
                        <span
                            className={`calendar_activity-status __${statusColor}`}
                            style={{
                                display: statusColor === false ? 'none' : 'inline-block'
                            }}></span>
                        <span className="fs-14 text-gray text-capitalize">{status}</span>
                    </div>
                );
            }
        },
        {
            title: 'ACTIONS',
            dataIndex: '',
            key: '',
            width: 200,
            align: 'center',
            render: function updateCalendarAccessSlot(_, record) {
                return (
                    <Button
                        className="text-uppercase"
                        type="primary"
                        size="small"
                        onClick={() => {
                            setCalendarId(record.id);
                            setAccess(record.ccm_access);
                            setOpenCalendarAccessUpdateModal(!openCalendarAccessUpdateModal);
                        }}>
                        Update Access
                    </Button>
                );
            }
        }
    ];

    const onChangeFilter = ({ value, type }) => {
        setFilter({ [type]: value });
    };

    const onChangePage = (page) => {
        setPage(page);
        return getCalendarsByProduct(id, {
            page: page,
            perPage,
            ...filter
        });
    };

    useEffect(() => {
        getCalendarsByProduct(id, { page, perPage, dateQuery: today });
    }, [getCalendarsByProduct, page, perPage]);

    useEffect(() => {
        if (Object.entries(filter).length !== 0) {
            return getCalendarsByProduct(id, {
                page,
                perPage,
                ...filter
            });
        }
    }, [filter]);

    const spaces = calendars?.data?.availability?.specific_spaces_available;
    useEffect(() => {
        if (spaces) {
            setSpecificSpacesNumber(spaces);
        }
    }, [spaces]);

    const refreshList = () => {
        getCalendarsByProduct(id, { page, perPage, dateQuery: today });
    };

    return (
        <>
            <div className="bg-calendar customer-calendars-x-padded customer-calendars-y-padded _extra-padded-bottom">
                <div className="d-flex flex-wrap flex-lg-nowrap">
                    <div className="flex-grow-1 w-100 mr-2">
                        <h1 className="static-heading mb-2">Update Availability</h1>
                    </div>
                </div>
            </div>
            <div className="customer-calendars-x-padded section-negative-top">
                <div className="main-calendar-table-section mb-40px">
                    <Row className="mb-4 align-items-center justify-content-between">
                        <div
                            className="d-flex align-items-center mx-2 my-2"
                            style={{ color: '#A2792C' }}>
                            <p className="m-0" style={{ fontSize: '18px' }}>
                                <span style={{ fontWeight: 600 }}>
                                    Product: {calendars?.data?.product?.title}
                                </span>
                            </p>
                        </div>
                        <div className="date-filter mx-2 my-2">
                            <span
                                className={`hover-underline text-nowrap fs-14 ${
                                    filter.dateQuery === today && 'text-primary'
                                }`}
                                role="button"
                                onClick={() =>
                                    onChangeFilter({
                                        type: 'dateQuery',
                                        value: today
                                    })
                                }
                                tabIndex="0">
                                Today
                            </span>
                            <span className="mx-2">|</span>
                            <span
                                className={`hover-underline text-nowrap fs-14 ${
                                    filter.dateQuery === tomorrow && 'text-primary'
                                }`}
                                role="button"
                                onClick={() => {
                                    onChangeFilter({
                                        type: 'dateQuery',
                                        value: tomorrow
                                    });
                                }}
                                tabIndex="0">
                                Tomorrow
                            </span>
                            <div className="d-flex flex-wrap">
                                <div className="d-flex justify-content-between date-picker">
                                    <DatePicker
                                        placeholder="Date"
                                        style={{ maxWidth: '170px', width: '100%' }}
                                        disabledDate={disableDate}
                                        format={'YYYY-MM-DD'}
                                        onChange={(_, valueString) => {
                                            onChangeFilter({
                                                type: 'dateQuery',
                                                value: valueString !== '' ? valueString : today
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-right mx-2 my-2">
                            <Link to="/availability-data">
                                <span style={{ fontWeight: 400, fontSize: 14, color: '#A2792C' }}>
                                    Go Back
                                </span>
                            </Link>
                        </div>
                    </Row>
                    <Row className="table-section">
                        <Col sm={6} md={6} lg={4} className="mb-2">
                            <div className="left-container">
                                <span style={{ fontWeight: 600, fontSize: 13 }}>
                                    Maximum number of spaces available to book this activity per day
                                </span>
                                <br />
                                <span style={{ fontWeight: 400, fontSize: 12 }}>
                                    This will indicate the spaces available to travelers and once
                                    this is reached, this product activtity will be marked as sold
                                    out for that day
                                </span>
                                <br />
                                <div className="d-flex align-items-center">
                                    <Button
                                        className="text-uppercase my-3"
                                        type="primary"
                                        size="small"
                                        onClick={() => setOpenSlots(!openSlots)}>
                                        Update Availability
                                    </Button>
                                    <UpdateSlotsModal
                                        id={id}
                                        spaces={specificSpacesNumber}
                                        date={today}
                                        isOpen={openSlots}
                                        refreshList={refreshList}
                                        toggleModal={() => setOpenSlots(false)}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={8} className="mb-2">
                            <div className="white-bg-update" style={{ overflowY: 'scroll' }}>
                                {loading && <Loader typeContainer={true} />}
                                <div style={{ width: '150%' }}>
                                    <Table
                                        className="customer-calendars-table"
                                        columns={columns}
                                        dataSource={
                                            calendars && calendars.data && calendars.data.calendars
                                        }
                                        pagination={false}
                                        tableLayout="auto"
                                    />
                                    <CalendarAccessUpdateModal
                                        productId={id}
                                        calendarId={calendarId}
                                        access={access}
                                        date={today}
                                        refreshList={refreshList}
                                        isOpen={openCalendarAccessUpdateModal}
                                        toggleModal={() => setOpenCalendarAccessUpdateModal(false)}
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="pagination-gallery">
                        <Pagination
                            defaultCurrent={1}
                            defaultPageSize={calendars?.meta?.per_page || 10}
                            current={page}
                            showSizeChanger={false}
                            total={calendars && calendars.meta && calendars.meta.total}
                            onChange={(page) => onChangePage(page)}
                        />
                    </div>
                    <div className="">
                        <Feedback />
                    </div>
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    calendars: state.availability.product_calendars_data,
    update_availability_state: state.availability.update_availability_state,
    loading: state.loading
});

const mapDispatch = (dispatch) => ({
    getCalendarsByProduct: (id, params) => dispatch(getCalendarsByProduct(id, params))
});

UpdateAvailability.propTypes = {
    loading: PropTypes.bool,
    update_availability_state: PropTypes.bool,
    calendars: PropTypes.shape({
        data: PropTypes.any,
        meta: PropTypes.shape({
            per_page: PropTypes.number,
            total: PropTypes.number
        })
    }),
    getCalendarsByProduct: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(UpdateAvailability));

/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-prototype-builtins */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Drawer, Select } from 'antd';
import { Button, Row, Col } from 'reactstrap';
import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConfirmEditModal from 'components/molecules/confirmation-modal';
import {
    updateBankAccountInformation,
    createBankAccountInformation,
    verifyCountryCode,
    getBanksPaystack,
    verifyPaystack,
    createPaystackAccount,
    getCurretUser,
    getBusinessBankAccountInfo,
    fetchLocations
} from 'store/actions';
import SaveCancel from 'components/molecules/user-account/save-cancel';
import { SelectCountry, PhoneCountryCode, GoogleAutoComplete } from 'components/atoms';

function BusinessBankInformation(props) {
    const {
        handleCancel,
        visible,
        business_bank_account,
        banks_paystack,
        getBanksPaystack,
        locations,
        fetchLocations
    } = props;
    const [form] = Form.useForm();
    const [isModalClose, setIsModalClose] = useState(false);
    const [supportStatus, setSupportStatus] = useState({});
    const [forceUpdate, setForceUpdate] = useState(false);
    const [paystackRes, setPaystackRes] = useState(null);

    useEffect(() => {
        if (business_bank_account?.city?.name) {
            fetchLocations(business_bank_account?.city?.name);
        }
    }, []);

    useEffect(() => {
        if (visible) {
            setTimeout(() => setForceUpdate(!forceUpdate), 1000);
            if (Array.isArray(banks_paystack) && banks_paystack.length < 1) getBanksPaystack();
        }
    }, [visible]);

    // const togglePhone = () => setIsSelectCode(!isSelectCode);

    const onFinish = () => {
        const values = form.getFieldsValue();
        const { account_number, bank } = values;

        if (values?.city_id && typeof values.city_id === 'string') {
            let found = locations.find((val) => val?.city?.name === values?.city_id);
            if (found) values.city_id = found.city_id;
        }

        if (supportStatus?.gateway === 'paystack') {
            let bankDetail = banks_paystack.find((val) => val.name === bank);

            if (paystackRes?.data && paystackRes?.data.hasOwnProperty('account_name'))
                props
                    .createPaystackAccount({ account_number, bank_code: bankDetail?.code })
                    .then((res) => {
                        if (res) {
                            props.getCurretUser();
                            props.handleCancel();
                        }
                    });
            else
                props
                    .verifyPaystack({ account_number, bank_code: bankDetail?.code })
                    .then((res) => {
                        setPaystackRes(res);
                    });
        } else if (business_bank_account && Object.keys(business_bank_account).length > 0)
            props.updateBankAccountInformation(values).then((res) => {
                if (res?.payload) {
                    props.getBusinessBankAccountInfo();
                    handleCancel();
                }
            });
        else
            props.createBankAccountInformation(values).then((res) => {
                if (res?.payload) {
                    props.getBusinessBankAccountInfo();
                    handleCancel();
                }
            });
    };
    const handleModal = () => {
        const {
            business_type,
            account_address,
            account_state,
            account_zip_code,
            account_city,
            account_country
        } = business_bank_account;
        const {
            account_country: form_country,
            account_city: form_city,
            account_zip_code: form_postal_code,
            account_state: form_account_state,
            account_address: form_account_address,
            business_type: form_business_type
        } = form.getFieldsValue();
        if (
            form_country !== account_country ||
            form_city !== account_city ||
            form_postal_code !== account_zip_code ||
            form_account_address !== account_address ||
            form_account_state !== account_state ||
            form_business_type !== business_type
        ) {
            return setIsModalClose(!isModalClose);
        }
        return handleCancel();
    };
    const closeAllModals = () => {
        if (visible && isModalClose) {
            handleCancel();
            form.resetFields();
            setIsModalClose(!isModalClose);
        }
    };

    function handleOnClick() {
        window.open('https://wise.prf.hn/click/camref:1100ljSuU', '_blank');
    }

    function onChangeCountry(value) {
        let countryDetail = props.countries.find((element) => element?.name === value);
        if (countryDetail)
            props.verifyCountryCode(countryDetail?.country_code).then((res) => {
                setSupportStatus(res);
            });
    }

    let selectCode = '';
    if (form) {
        const country = form.getFieldValue().account_country;
        let countryDetail = props.countries.find((element) => element?.name === country);
        selectCode = countryDetail?.phone_code || '';
    }

    return (
        <div>
            {/* Edit Office Information Drawer */}
            <Drawer
                width={609}
                visible={visible}
                onClose={handleModal}
                layout="vertical"
                key="right"
                className="office-information-modal"
                bodyStyle={{ display: 'flex', flexDirection: 'column' }}>
                <h6 className="office-information-modal__title">Edit Bank Account Information</h6>
                <Form
                    form={form}
                    onValuesChange={() => {}}
                    onFinish={onFinish}
                    layout="vertical"
                    className="mt-3 form-label-fw-500 d-flex flex-column flex-grow-1">
                    <SelectCountry
                        fieldName="account_country"
                        label="Search country"
                        initialValue={
                            business_bank_account
                                ? business_bank_account.account_country
                                : undefined
                        }
                        onSelect={onChangeCountry}
                    />

                    {supportStatus?.message && !supportStatus?.status && (
                        <Row className="row-lg">
                            <Col>
                                <p>{supportStatus.message}</p>
                            </Col>
                        </Row>
                    )}

                    {supportStatus?.gateway === 'transferwise' ? (
                        <Row className="row-lg">
                            <Col>
                                <p>{`At the moment, we do not support direct payments to ${supportStatus.name}. Please create a USD account on Wise and use that to continue with your payout set up.`}</p>
                                {/* <p>
                                    Choose any of the currency in which you want to receive payments
                                    and open bank account{' '}
                                </p> */}
                                <div className="my-3 d-flex gap-2 align-items-center">
                                    <Button
                                        style={{ width: 100 }}
                                        onClick={() =>
                                            window.open(
                                                'https://wise.prf.hn/click/camref:1100ljSuU',
                                                '_blank'
                                            )
                                        }
                                        color="primary">
                                        USD
                                    </Button>
                                    <div>
                                        <span>United states of America</span>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ) : supportStatus?.gateway === 'paystack' ? (
                        <>
                            <Form.Item
                                // className="mb-3"
                                label="Bank account number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter bank account number'
                                    }
                                ]}
                                name="account_number">
                                <Input
                                    onChange={() => setPaystackRes(null)}
                                    placeholder="Bank account number"
                                />
                            </Form.Item>
                            {paystackRes && !paystackRes?.data?.hasOwnProperty('account_name') && (
                                <span className="text-danger">{paystackRes?.message || ''}</span>
                            )}
                            <Form.Item
                                className="mb-3"
                                label="Bank"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select bank'
                                    }
                                ]}
                                name="bank">
                                <Select
                                    autoComplete="none"
                                    className="d-block"
                                    placeholder="Bank"
                                    showSearch
                                    loading={props.loading}
                                    onChange={() => setPaystackRes(null)}>
                                    {Array.isArray(banks_paystack) &&
                                        banks_paystack.map((item, index) => (
                                            <Select.Option value={item.name} key={index}>
                                                {item.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>

                            {paystackRes?.data?.account_name && (
                                <Col md={6}>
                                    <div className="ant-col ant-form-item-label">
                                        Account holder name
                                    </div>
                                    <Input
                                        style={{ color: 'rgba(0, 0, 0, 0.60)' }}
                                        disabled
                                        placeholder="Bank account name"
                                        value={paystackRes?.data?.account_name}
                                    />
                                </Col>
                            )}
                        </>
                    ) : (
                        (supportStatus?.status ||
                            supportStatus.gateway === 'stripe' ||
                            (business_bank_account?.account_country &&
                                Object.keys(supportStatus).length < 1)) && (
                            <>
                                {/* <Form.Item
                                    label="Account holder name"
                                    name="account_holder_name"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_holder_name
                                            : ''
                                    }
                                    rules={[{ required: true, message: 'Please enter name' }]}>
                                    <Input size="large" />
                                </Form.Item>

                                <Form.Item
                                    label="Account number"
                                    name="account_number"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_number
                                            : ''
                                    }
                                    rules={[
                                        { required: true, message: 'Please enter account number' }
                                    ]}>
                                    <Input size="large" type="number" />
                                </Form.Item>

                                <Form.Item
                                    label="Account Routing number"
                                    name="account_routing_number"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_routing_number
                                            : ''
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter account routing number'
                                        }
                                    ]}>
                                    <Input size="large" type="number" />
                                </Form.Item> */}

                                <Form.Item
                                    label="Business type"
                                    name="business_type"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.business_type
                                            : null
                                    }
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter business type'
                                        }
                                    ]}>
                                    <Select className="d-block">
                                        <Select.Option value={'individual'} key={1}>
                                            Individual
                                        </Select.Option>
                                        <Select.Option value={'company'} key={2}>
                                            Company
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="city_id"
                                    rules={[{ required: true, message: 'Please select location' }]}>
                                    <div className="input-title">Search and select location</div>
                                    <GoogleAutoComplete
                                        placeholder="Search location"
                                        containerClassName="profile-field"
                                        fieldName="city_id"
                                        defValue={business_bank_account?.city?.name ?? ''}
                                        // onSelectDistance={(value, address) => {
                                        //     this.formRef.current.setFieldsValue({
                                        //         destination: value
                                        //     });
                                        //     this.setAddress(value);
                                        // }}
                                    />
                                </Form.Item>

                                {/* <Form.Item
                                    label="State"
                                    name="account_state"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_state
                                            : ''
                                    }
                                    rules={[{ required: true, message: 'Please enter state' }]}>
                                    <Input size="large" />
                                </Form.Item> */}

                                {/* <Form.Item
                                    label="City"
                                    name="account_city"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_city
                                            : ''
                                    }
                                    rules={[{ required: true, message: 'Please enter city' }]}>
                                    <Input size="large" />
                                </Form.Item> */}

                                <Form.Item
                                    label="Address"
                                    name="account_address"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_address
                                            : ''
                                    }
                                    rules={[{ required: true, message: 'Please enter address' }]}>
                                    <Input size="large" />
                                </Form.Item>

                                <Form.Item
                                    label="Zip code/postal code"
                                    name="account_zip_code"
                                    initialValue={
                                        business_bank_account
                                            ? business_bank_account.account_zip_code
                                            : ''
                                    }
                                    rules={[
                                        { required: true, message: 'Please enter zip/post code' }
                                    ]}>
                                    <Input size="large" />
                                </Form.Item>

                                <PhoneCountryCode
                                    label="Bank account holder phone number"
                                    phoneNumberFieldName="account_contact"
                                    selectedCountryCode={selectCode}
                                    disabled={true}
                                    // onChangeCountryCode={(code) => {
                                    //     setSelectCode(code);
                                    //     //setSelectCode(code); //new
                                    //     form.validateFields(['selectCode']);
                                    // }}
                                    // expandMenu={isSelectCode}
                                    // toggleMenu={togglePhone}
                                    phoneNumber={
                                        business_bank_account
                                            ? business_bank_account?.account_contact
                                            : ''
                                    }
                                />
                            </>
                        )
                    )}

                    <div className="mt-auto mb-3">
                        <SaveCancel
                            saveButtonName={
                                supportStatus?.gateway === 'transferwise'
                                    ? 'Create Wise Account'
                                    : supportStatus?.gateway === 'paystack'
                                    ? !paystackRes?.data?.hasOwnProperty('account_name')
                                        ? 'Verify'
                                        : 'Confirm and Continue'
                                    : business_bank_account &&
                                      Object.keys(business_bank_account).length > 0
                                    ? 'Update'
                                    : 'Save'
                            }
                            showSaveButton={
                                supportStatus?.status ||
                                (business_bank_account?.account_country &&
                                    Object.keys(supportStatus).length < 1)
                            }
                            saveLoading={props.loading}
                            cancelClick={handleModal}
                            onClick={
                                supportStatus?.gateway === 'transferwise' ? handleOnClick : null
                            }
                        />
                    </div>
                </Form>
            </Drawer>
            {/* Confirm Edit Modal */}
            <ConfirmEditModal
                modal={isModalClose}
                handleModal={handleModal}
                closeModal={closeAllModals}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    update_office: state.profile.update_office,
    business_bank_account: state.profile.business_bank_account || {},
    loading: state.loading,
    countries: state.profile.countries,
    banks_paystack: state.profile.banks_paystack,
    locations: state.profile.locations
});
const mapDispatch = (dispatch) => ({
    createBankAccountInformation: (data) => dispatch(createBankAccountInformation(data)),
    updateBankAccountInformation: (data) => dispatch(updateBankAccountInformation(data)),
    getBusinessBankAccountInfo: () => dispatch(getBusinessBankAccountInfo()),
    verifyCountryCode: (code) => dispatch(verifyCountryCode(code)),
    getBanksPaystack: () => dispatch(getBanksPaystack()),
    verifyPaystack: (data) => dispatch(verifyPaystack(data)),
    createPaystackAccount: (data) => dispatch(createPaystackAccount(data)),
    getCurretUser: () => dispatch(getCurretUser()),
    fetchLocations: (query) => dispatch(fetchLocations(query))
});
BusinessBankInformation.propTypes = {
    handleCancel: PropTypes.func,
    visible: PropTypes.bool,
    updateOfficeState: PropTypes.func,
    update_office: PropTypes.any,
    updateBankAccountInformation: PropTypes.func,
    business_bank_account: PropTypes.object,
    createBankAccountInformation: PropTypes.func,
    loading: PropTypes.bool,
    countries: PropTypes.array,
    verifyCountryCode: PropTypes.func,
    banks_paystack: PropTypes.array,
    getBanksPaystack: PropTypes.func,
    createPaystackAccount: PropTypes.func,
    verifyPaystack: PropTypes.func,
    getCurretUser: PropTypes.func,
    locations: PropTypes.shape([
        {
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }
    ]),
    fetchLocations: PropTypes.func,
    getBusinessBankAccountInfo: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(BusinessBankInformation));

import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import { Button } from 'reactstrap';
import LoadPaymentOptions from './loadPaymentOption';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import EnterpriseModal from './enterpriseModal';
import {
    toogleDisableCalendarModal,
    toggleUpgradePlanDrawer,
    // downgradeToFreePlan,
    getVendorSubscription,
    sendEmail,
    setPaymentProcessing
} from 'store/actions';
import SocketConnector from 'utils/socket';
import DowngradeCalendar from 'components/molecules/calendar/downgrade-info/downgrade-calendar';

const CardDetails = ({
    props,
    handleAction,
    currentPlan,
    upcomingSubscription,
    children,
    setShowUpgradeModal
}) => {
    const {
        id,
        name,
        monthly_fee,
        no_of_free_calendar,
        no_of_token,
        token_topup_price,
        price_per_customer_token,
        subscriptions
    } = props;

    const renderBtnText = (planPrice, monthlyFee, planId, currentPlanId) => {
        if (upcomingSubscription && upcomingSubscription.plan_id === planId) {
            return (
                <div className="bg-white py-2 px-3 rounded border fw-500 fs-12">
                    {`Plan in effect from ${upcomingSubscription.start_date}`}
                </div>
            );
        } else {
            return (
                <Button
                    className="black-button btn-px fw-500 fs-12 btn btn-secondary"
                    onClick={planId === 3 ? () => setShowUpgradeModal(true) : handleAction}>
                    {' '}
                    {planId === 3
                        ? 'Upgrade'
                        : planPrice < monthlyFee && currentPlanId !== 3
                        ? 'Upgrade'
                        : 'Downgrade'}
                </Button>
            );
        }
    };

    return (
        <div className="card card-plan mb-3">
            <div className="card-body">
                <div className="d-flex align-items-start">
                    <h4 className="card-title mr-2 flex-grow-1">{name}</h4>
                    {subscriptions && subscriptions.length ? (
                        <div className=" d-flex flex-column">
                            <div className="bg-white py-2 px-3 rounded border fw-500 fs-12">
                                {upcomingSubscription?.plan_id !== subscriptions[0]?.plan_id
                                    ? `Current Plan valid till ${currentPlan.expiry_date}`
                                    : 'Current Plan'}
                            </div>
                            {subscriptions[0]?.plan_id !== upcomingSubscription?.plan_id && (
                                <Button
                                    className="black-button btn-px fw-500 fs-12 btn btn-secondary mt-2"
                                    onClick={handleAction}>
                                    Resubscribe
                                </Button>
                            )}
                        </div>
                    ) : (
                        renderBtnText(currentPlan.plan_price, monthly_fee, id, currentPlan.plan_id)
                    )}
                </div>
                <ul className="list-unstyled pl-3 fw-600 fs-14 mb-2">
                    <li>
                        {name && name.toLowerCase() === 'enterprise'
                            ? 'Contact sales'
                            : `$${monthly_fee} monthly fee`}
                    </li>
                    <li>
                        {name && name.toLowerCase() === 'enterprise'
                            ? 'Unlimited'
                            : no_of_free_calendar}{' '}
                        {`${
                            (name && name.toLowerCase() === 'enterprise') || no_of_free_calendar > 1
                                ? 'Live Calendars'
                                : 'Live Calendar'
                        }`}
                    </li>
                    <li>{no_of_token} customer invites monthly</li>
                    {name && name.toLowerCase() !== 'free' && <li>Customer insights</li>}
                </ul>
                <div className="text-primary fs-14 font-weight-bold">Token Top-up</div>
                <div className="fs-12 fw-500">
                    ${token_topup_price} (Otherwise Customer Pay {`$${price_per_customer_token}`}{' '}
                    for calendar access)
                </div>
            </div>
            {children}
        </div>
    );
};

const UpgradeModal = ({
    title,
    //  allPlans,
    upcomingSubscription,
    upgradePlanDrawer,
    toggleUpgradePlanDrawer,
    sendEmail,
    onSubscriptionChange,
    setPaymentProcessing,
    paymentProcessing
    // showDowngradeCalendarPopup
}) => {
    const allPlans = useSelector((state) => state.accessCalendarReducer.allPlans);
    const [planForm, setPlanForm] = useState({
        plan: '',
        monthlyPlanCharges: null,
        planId: null,
        changeType: 'upgrade'
    });
    const [downgradeModal, setDowngradeModal] = useState(false);
    const [usedPlan, setUsedPlan] = useState();
    const [currentPlan, setCurrentPlan] = useState({});
    const [showUpgradeModal, setShowUpgradeModal] = useState(false);

    useEffect(() => {
        const socket = SocketConnector();
        socket.on(`SUBSCRIPTION_UPDATED`, async () => {
            getVendorSubscription();
        });
        socket.on(`TOKEN_ADDED`, () => {
            getVendorSubscription();
        });
    }, []);

    useEffect(() => {
        allPlans.forEach((plan) => {
            if (plan.subscriptions && plan.subscriptions.length) {
                setCurrentPlan(plan.subscriptions[0]);
            }
        });
    }, [allPlans]);

    /**
     * Handle upgrade plan drawer close
     */
    const handleDrawerClose = () => {
        toggleUpgradePlanDrawer(!upgradePlanDrawer);
    };

    const handleShowPopUp = (value) => {
        setDowngradeModal(value);
    };
    // const handlePayNow = () => {
    //     onSubscriptionChange(planForm);
    //     setPlanForm({
    //         plan: '',
    //         monthlyPlanCharges: null,
    //         planId: null,
    //         changeType: 'upgrade'
    //     });
    // };
    const handleAction = (plan, currentPlan) => {
        setUsedPlan(plan);
        setPlanForm({
            ...planForm,
            plan: plan.name ? plan.name.toLowerCase() : '',
            monthlyPlanCharges: plan.monthly_fee,
            planDetails: plan,
            planId: plan.id,
            changeType: plan.monthly_fee >= currentPlan.plan_price ? 'upgrade' : 'downgrade'
        });
        if (plan.is_free) {
            handleShowPopUp(true);
        }
    };

    function onPaymentSuccess() {
        setPlanForm({});
    }

    return (
        <Drawer
            width={609}
            placement="right"
            zIndex={1500}
            onClose={() => handleDrawerClose()}
            visible={upgradePlanDrawer}>
            <div className="d-flex flex-column h-100">
                <h4 className="mb-3 mt-2">{title}</h4>
                <div className="overflow-y-auto flex-grow-1">
                    {allPlans.map((plan, index) => (
                        <CardDetails
                            key={index}
                            props={plan}
                            currentPlan={currentPlan}
                            upcomingSubscription={upcomingSubscription}
                            handleAction={() => handleAction(plan, currentPlan)}
                            setShowUpgradeModal={setShowUpgradeModal}>
                            {!plan.is_free &&
                                planForm.plan === plan.name.toLowerCase() &&
                                planForm.changeType === 'upgrade' && (
                                    <LoadPaymentOptions
                                        planForm={planForm}
                                        handleDrawerClose={handleDrawerClose}
                                        setPaymentProcessing={setPaymentProcessing}
                                        paymentProcessing={paymentProcessing}
                                        onPaymentSuccess={onPaymentSuccess}
                                    />
                                )}
                        </CardDetails>
                    ))}
                </div>

                {showUpgradeModal && (
                    <EnterpriseModal
                        showModal={showUpgradeModal}
                        toggleModal={() => setShowUpgradeModal((prev) => !prev)}
                        sendEmail={sendEmail}
                    />
                )}

                {usedPlan && (
                    <DowngradeCalendar
                        selectedPlan={usedPlan}
                        showPopUp={downgradeModal}
                        handleShowPopUp={handleShowPopUp}
                        onConfirm={onSubscriptionChange}
                    />
                )}
            </div>
        </Drawer>
    );
};

CardDetails.propTypes = {
    props: PropTypes.object,
    title: PropTypes.string,
    handleAction: PropTypes.func,
    children: PropTypes.node,
    buttonText: PropTypes.string,
    details: PropTypes.array,
    planSubText: PropTypes.string,
    currentPlan: PropTypes.object,
    name: PropTypes.string,
    monthly_fee: PropTypes.string,
    no_of_free_calendar: PropTypes.number,
    no_of_token: PropTypes.number,
    token_topup_price: PropTypes.number,
    price_per_customer_token: PropTypes.number,
    subscriptions: PropTypes.array,
    id: PropTypes.number,
    upcomingSubscription: PropTypes.any,
    setShowUpgradeModal: PropTypes.func
};

UpgradeModal.propTypes = {
    title: PropTypes.string,
    allPlans: PropTypes.array.isRequired,
    upgradePlanDrawer: PropTypes.bool.isRequired,
    toggleUpgradePlanDrawer: PropTypes.func.isRequired,
    onSubscriptionChange: PropTypes.func,
    upcomingSubscription: PropTypes.object,
    sendEmail: PropTypes.func,
    setPaymentProcessing: PropTypes.func,
    paymentProcessing: PropTypes.bool
};

const mapStateToProps = (state) => ({
    allPlans: state.accessCalendarReducer.allPlans,
    upcomingSubscription: state.accessCalendarReducer.upcomingSubscription,
    upgradePlanDrawer: state.accessCalendarReducer.upgradePlanDrawer,
    showDowngradeCalendarPopup: state.accessCalendarReducer.showDowngradeCalendarPopup,
    paymentProcessing: state.auth.paymentProcessing
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleUpgradePlanDrawer: (value) => dispatch(toggleUpgradePlanDrawer(value)),
        sendEmail: (value) => dispatch(sendEmail(value)),
        toogleDisableCalendarModal: (value) => dispatch(toogleDisableCalendarModal(value)),
        setPaymentProcessing: (bool) => dispatch(setPaymentProcessing(bool))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal);

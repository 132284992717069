/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Info from 'assets/images/carbon_information.svg';
import 'react-circular-progressbar/dist/styles.css';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.css';
import { Container, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import {
    loginUser,
    getCurretUser,
    vendorOnboard,
    checkSubscriptionExpiry
} from 'store/actions/AuthActions';
import { fileUpload, clearUploadedFile } from 'store/actions/contentActions';
import AlertComponent from 'components/atoms/alert';
import ShareVendorRatingModal from 'components/organisms/share-modal/share-vendor-rating';
import {
    CustomersCalendarInsights,
    CustomerCalendarsCard,
    CustomerExperienceCard,
    ProductQualityCard,
    AvailableHoursCard,
    VendorRatingCard,
    ProductsAvailableHoursModal,
    ProductQualityModal,
    AvailableHoursModal,
    CustomerInterestModal,
    CustomerDemandModal,
    CalendarSalesModal,
    Feedback,
    PayoutBookings,
    CreateCustomersCalendar,
    NotificationsGroup
} from 'components/molecules';
import { getVendorRating, isFilledRequiedInfoForStripeOnboard, loading_ } from 'store/actions';
import { AddPayoutSettingsModal } from 'components/organisms';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { Loader } from 'components/atoms';

const Home = (props) => {
    const { getVendorRating } = props;
    const [isModalOpen, setModal] = useState(false);
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const [showPayoutModal, setShowPayoutModal] = useState(false);

    const toggleModal = () => {
        setModal(!isModalOpen);
    };
    const { getCurretUser, checkSubscriptionExpiry } = props;

    useEffect(() => {
        getVendorRating();
    }, []);

    useEffect(() => {
        // getCurretUser();
    }, [getCurretUser]);

    useEffect(() => {
        checkSubscriptionExpiry();
    }, []);

    const [productsAvailableHoursModal, setProductsAvailableHoursModal] = useState(false);
    const [availableHoursModal, setAvailableHoursModal] = useState(false);
    const [productQualityModal, setProductQualityModal] = useState(false);
    const [shareVendorRatingModal, setShareVendorRatingModal] = useState(false);

    // Calendar Insights
    const [viewCalendarSales, setViewCalendarSales] = useState(false);
    const [viewCustomerDemand, setViewCustomerDemand] = useState(false);
    const [viewCustomerInterest, setViewCustomerInterest] = useState(false);

    function closeAndUpdateUser() {
        props.loading_(true);
        props.getCurretUser().then(() => {
            setShowPayoutModal(false);
            props.loading_(false);
        });
    }

    return (
        <>
            <Container className="mb-40px">
                {/* loader modal */}
                {props.loading && <Loader typeTransparent={true} />}

                {/* Coming Soon Modal */}
                <Modal
                    isOpen={isModalOpen}
                    size="lg"
                    toggle={toggleModal}
                    scrollable
                    centered
                    backdropClassName="opaque">
                    <ModalHeader toggle={toggleModal}>{''}</ModalHeader>
                    <ModalBody>
                        <Row>{'Coming Soon'}</Row>
                    </ModalBody>
                </Modal>

                {/* Dashboard Master Grid, also has a fallback for older browsers */}
                <div className={`dashboard-grid ${assignComponentPermission('dashboard')}`}>
                    {/* Welcome and Verfication Grid Area */}
                    <div className="dashboard-info-area">
                        {/* Welcome User */}
                        {props?.my_profile ? (
                            <div className="d-flex flex-nowrap mt-2">
                                <h1 className="static-heading title-heading mr-2 flex-grow-1">
                                    Welcome, {props?.my_profile?.first_name}{' '}
                                    {props?.my_profile?.last_name}
                                </h1>
                                <div className={`${assignComponentPermission('notification')}`}>
                                    {/* Notifications Component */}
                                    <NotificationsGroup permission="notification-dashboard" />
                                </div>
                            </div>
                        ) : null}

                        {props?.my_profile?.is_account_verified === false ||
                        (props.my_profile?.vendor?.stripe_connected_account_id == null &&
                            props.my_profile?.vendor?.paystack_account_id == null) ? (
                            // Show only if account is not verified
                            <AlertComponent
                                id="hidden-component-permission"
                                color="primary"
                                icon={<img src={Info} alt="" />}>
                                {!props?.my_profile?.is_account_verified && (
                                    <>
                                        <h3 className="alert-heading">Verify your identity</h3>
                                        <p className="fs-13 mb-4px">
                                            For the safety of customers, we ask new partners to
                                            verify their identity. All you need to do is upload a
                                            piece of government-issued photo ID and selfie, then
                                            we’ll take care of the rest.
                                        </p>

                                        <NavLink className="alert-action-link" to="/account">
                                            Verify your identity
                                        </NavLink>
                                    </>
                                )}
                                {props.my_profile?.vendor?.stripe_connected_account_id == null &&
                                    props.my_profile?.vendor?.paystack_account_id == null && (
                                        <>
                                            <h3 className="alert-heading mt-3">
                                                Complete your payment account setup
                                            </h3>
                                            <p className="fs-13 mb-4px">
                                                To be able to send your calendar to customers, you
                                                will have to completely set up your payment account.
                                            </p>

                                            <NavLink className="alert-action-link" to="/finance">
                                                Complete payment account setup
                                            </NavLink>
                                        </>
                                    )}
                            </AlertComponent>
                        ) : null}
                    </div>

                    {/* All Ratings Area */}
                    <div
                        className={`dashboard-rating-area ${assignComponentPermission(
                            'dashboard'
                        )}`}>
                        {/* Vendor Rating Card */}
                        <VendorRatingCard
                            handleOnClick={() => setShareVendorRatingModal(true)}
                            handleOnKeyUp={() => setShareVendorRatingModal(true)}
                        />
                        {/* Avaialble hours card */}
                        <AvailableHoursCard
                            handleOnClick={() => setProductsAvailableHoursModal(true)}
                            handleOnKeyUp={() => setProductsAvailableHoursModal(true)}
                        />
                        {/* Product Quality Card */}
                        <ProductQualityCard
                            handleOnClick={() => setProductQualityModal(true)}
                            handleOnKeyUp={() => setProductQualityModal(true)}
                        />
                        {/* Customer Experience Card */}
                        <CustomerExperienceCard />
                        {/* Customer Calendars Card */}
                        <CustomerCalendarsCard />
                    </div>

                    {/* Actions and Stats Area */}
                    <div className="dashboard-stats-area">
                        {/* Start Creating Products Card */}
                        <div className="card mb-26px">
                            <div className="card-header">
                                <h5 className="card-title">
                                    Start creating products and grow your business
                                </h5>
                            </div>
                            <div className="card-body">
                                <Row noGutters>
                                    <Col
                                        xs={12}
                                        className={`col-xxl-6 ${assignComponentPermission(
                                            'calendar'
                                        )}`}>
                                        <CreateCustomersCalendar />
                                    </Col>
                                    <Col
                                        xs={12}
                                        className={`col-xxl-6 ${assignComponentPermission(
                                            'bookings'
                                        )}`}>
                                        <PayoutBookings
                                            my_profile={props.my_profile}
                                            setShowPayoutModal={setShowPayoutModal}
                                            isFilledRequiedInfoForStripeOnboard={
                                                props.isFilledRequiedInfoForStripeOnboard
                                            }
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* Customers Calendar Insights */}
                        <CustomersCalendarInsights
                            handleOnClickCalendarSales={() => {
                                setViewCalendarSales(true);
                            }}
                            handleOnClickCustomerInterest={() => {
                                setViewCustomerInterest(true);
                            }}
                            handleOnClickCustomerDemand={() => {
                                setViewCustomerDemand(true);
                            }}
                        />
                        {/* Feedback Block */}
                        <div className="d-sm-flex align-items-lg-center mt-3">
                            <div className="order-2">
                                <div className="fs-16">
                                    We want to know if there is an opportunity to drive further
                                    value in your business.
                                </div>
                            </div>
                            <div className="mt-2 mt-sm-0 mr-sm-3 order-1">
                                {/* <FixedHelpButton color="primary" /> */}
                                <Feedback />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>

            {/* Products Available hours modal popup */}
            <ProductsAvailableHoursModal
                isModalOpen={productsAvailableHoursModal}
                setModalOpenState={setProductsAvailableHoursModal}
                goBackAction={() => {
                    setProductsAvailableHoursModal(false);
                }}
                onIncreaseRating={() => {
                    setProductsAvailableHoursModal(false);
                    setAvailableHoursModal(true);
                }}
            />

            {/* Available Hours Modal Popup */}
            <AvailableHoursModal
                isModalOpen={availableHoursModal}
                setModalOpenState={setAvailableHoursModal}
                goBackAction={() => {
                    setAvailableHoursModal(false);
                    setProductsAvailableHoursModal(true);
                }}
            />

            {/* Product Quality Modal Popup */}
            <ProductQualityModal
                isModalOpen={productQualityModal}
                setModalOpenState={setProductQualityModal}
                goBackAction={() => {
                    setProductQualityModal(false);
                }}
            />

            {/* Share Vendor Rating Modal Popup */}
            <ShareVendorRatingModal
                isModalOpen={shareVendorRatingModal}
                setModalOpenState={setShareVendorRatingModal}
            />

            {/* Calendar Insights Modal Popups */}
            {/* Calendar Sales Modal */}
            <CalendarSalesModal
                isModalOpen={viewCalendarSales}
                setModalOpenState={setViewCalendarSales}
            />

            {/* Customer Demand Modal */}
            <CustomerDemandModal
                isModalOpen={viewCustomerDemand}
                setModalOpenState={setViewCustomerDemand}
            />

            {/* Customer Interest Modal */}
            <CustomerInterestModal
                isModalOpen={viewCustomerInterest}
                setModalOpenState={setViewCustomerInterest}
            />

            {/* add payment accpet account */}
            {showPayoutModal && (
                <AddPayoutSettingsModal
                    title="Add Payout Settings"
                    description="Select the country your bank account is situated"
                    showModal={showPayoutModal}
                    toggleModal={() =>
                        setShowPayoutModal((prevShowPayoutModal) => !prevShowPayoutModal)
                    }
                    closeAndUpdateUser={closeAndUpdateUser}
                />
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    auth: state.auth.auth,
    my_profile: state.auth.my_profile,
    uploaded_file: state.contentDetail.uploaded_file
});

const mapDispatch = (dispatch) => ({
    loginUserFn: (user) => dispatch(loginUser(user)),
    getCurretUser: () => dispatch(getCurretUser()),
    getVendorRating: () => dispatch(getVendorRating()),
    vendorOnboard: (data) => dispatch(vendorOnboard(data)),
    fileUpload: (data) => dispatch(fileUpload(data)),
    clearUploadedFile: () => dispatch(clearUploadedFile()),
    checkSubscriptionExpiry: () => dispatch(checkSubscriptionExpiry()),
    isFilledRequiedInfoForStripeOnboard: () => dispatch(isFilledRequiedInfoForStripeOnboard()),
    loading_: (bool) => dispatch(loading_(bool))
});
Home.propTypes = {
    getCurretUser: PropTypes.func,
    my_profile: PropTypes.object,
    checkSubscriptionExpiry: PropTypes.func.isRequired,
    getVendorRating: PropTypes.any,
    isFilledRequiedInfoForStripeOnboard: PropTypes.func,
    loading_: PropTypes.func,
    loading: PropTypes.bool
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Home));

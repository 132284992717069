import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Tag, Tooltip } from 'antd';
import { toDecimal, transformFirstLetterToUpperCase } from 'utils';
import moment from 'moment';
import PropTypes from 'prop-types';
import PayoutPart from './payout-part';
import TransferPart from './transfer-part';
import VendorCalendarProductPart from './vendor-calendar-product-part';
import RefundPart from './refund-part';
export default function PaymentDetailModal(props) {
    const { paymentDetailModal, setState, data, paymentDetail } = props;

    return (
        <>
            <Modal
                centered
                scrollable
                isOpen={paymentDetailModal}
                toggle={() =>
                    setState({
                        paymentDetailModal: undefined
                    })
                }>
                <ModalHeader
                    toggle={() =>
                        setState({
                            paymentDetailModal: undefined
                        })
                    }>
                    {transformFirstLetterToUpperCase(
                        paymentDetailModal?.tab === 'charge'
                            ? 'Payment'
                            : paymentDetailModal?.tab === 'collected-fee'
                            ? 'Collected Fee'
                            : paymentDetailModal?.tab
                    )}{' '}
                    Info
                </ModalHeader>

                <ModalBody style={{ minHeight: 300 }}>
                    {paymentDetailModal?.tab !== 'collected-fee' &&
                        paymentDetail &&
                        Object.keys(paymentDetail).length > 0 && (
                            <>
                                <div className="mb-4">
                                    <div className="pb-2 border-bottom">
                                        <div className="d-flex align-items-center">
                                            <div className="pr-2">
                                                <div className="text-muted">
                                                    <svg
                                                        aria-hidden="true"
                                                        height="12"
                                                        width="12"
                                                        viewBox="0 0 16 16"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M1 4h14c.552 0 1 .407 1 .91v8.18c0 .503-.448.91-1 .91H1c-.552 0-1-.407-1-.91V4.91C0 4.406.448 4 1 4zm1-3h12a1 1 0 0 1 1 1v1H1V2a1 1 0 0 1 1-1zm6 10.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"
                                                            fill="currentColor"></path>
                                                    </svg>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="text-muted fs-13">
                                                    <span>
                                                        {paymentDetailModal?.tab === 'charge'
                                                            ? 'PAYMENT'
                                                            : paymentDetailModal?.tab &&
                                                              paymentDetailModal?.tab.toUpperCase()}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <div className="pr-2">
                                                <span className="fs-28 fw-700 mr-2">{`$${
                                                    paymentDetail?.transaction
                                                        ?.vendor_recieved_amount
                                                        ? toDecimal(
                                                              paymentDetail?.transaction
                                                                  ?.vendor_recieved_amount / 100
                                                          )
                                                        : paymentDetail?.total_amount
                                                        ? toDecimal(
                                                              paymentDetail?.total_amount / 100
                                                          )
                                                        : 0
                                                }`}</span>
                                                <span className="fs-28">{'USD'}</span>
                                            </div>
                                            <div>
                                                <Tooltip
                                                    placement="right"
                                                    title={
                                                        <>{`This payment ${paymentDetail?.paystackData?.status}`}</>
                                                    }>
                                                    <Tag color="green">
                                                        <span className="pr-2">{`${
                                                            paymentDetail?.paystackData?.status &&
                                                            transformFirstLetterToUpperCase(
                                                                paymentDetail?.paystackData?.status
                                                            )
                                                        }`}</span>
                                                        <svg
                                                            aria-hidden="true"
                                                            height="12"
                                                            width="12"
                                                            viewBox="0 0 16 16"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M5.297 13.213L.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                                fillRule="evenodd"></path>
                                                        </svg>
                                                    </Tag>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>

                                    {paymentDetailModal?.tab === 'charge' && (
                                        <div className="d-flex flex-row flex-wrap gap-3 py-2">
                                            <div className="pr-4 border-right">
                                                <div>
                                                    <div className="d-flex flex-column justify-content-start flex-nowrap">
                                                        <div className="mt-1 ml-1">
                                                            <span className="text-gray d-inline fs-14">
                                                                <span>Date</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 ml-1">
                                                            <div className="pt-1">
                                                                <div>
                                                                    <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                                        <div className="pr-2">
                                                                            <span>{`${
                                                                                paymentDetail
                                                                                    ?.paystackData
                                                                                    ?.created_at &&
                                                                                moment(
                                                                                    paymentDetail
                                                                                        .paystackData
                                                                                        .created_at
                                                                                ).format(
                                                                                    'MMM D, H:mm A'
                                                                                )
                                                                            }`}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pr-4 border-right">
                                                <div>
                                                    <div className="d-flex flex-column justify-content-start flex-nowrap">
                                                        <div className="mt-1 ml-1">
                                                            <span className="text-gray d-inline fs-14">
                                                                <span>Customer</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 ml-1">
                                                            <div className="pt-1">
                                                                <div>
                                                                    <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                                        <div className="pr-2">
                                                                            <span>
                                                                                {(paymentDetail
                                                                                    ?.paystackData
                                                                                    ?.metadata
                                                                                    ?.customer_first_name ||
                                                                                    '') +
                                                                                    ' ' +
                                                                                    (paymentDetail
                                                                                        ?.paystackData
                                                                                        ?.metadata
                                                                                        ?.customer_last_name ||
                                                                                        '')}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="pr-4">
                                                <div>
                                                    <div className="d-flex flex-column justify-content-start flex-nowrap">
                                                        <div className="mt-1 ml-1">
                                                            <span className="text-gray d-inline fs-14">
                                                                <span>Payment method</span>
                                                            </span>
                                                        </div>
                                                        <div className="mt-1 ml-1">
                                                            <div className="pt-1">
                                                                <div>
                                                                    <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                                        <div className="pr-2">
                                                                            <div aria-hidden="true">
                                                                                <svg
                                                                                    className="SVGInline-svg SVGInline--cleaned-svg SVG-svg BrandIcon-svg BrandIcon--size--20-svg"
                                                                                    height="20"
                                                                                    width="20"
                                                                                    viewBox="0 0 32 32"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <g
                                                                                        fill="none"
                                                                                        fillRule="evenodd">
                                                                                        <path
                                                                                            d="M0 0h32v32H0z"
                                                                                            fill="#e3e8ee"></path>
                                                                                        <path
                                                                                            d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z"
                                                                                            fill="#697386"></path>
                                                                                    </g>
                                                                                </svg>
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <span className="text-body d-inline fs-14">
                                                                                <span>
                                                                                    {paymentDetail
                                                                                        ?.paystackData
                                                                                        ?.channel ||
                                                                                        'N/A'}
                                                                                </span>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {paymentDetailModal?.tab === 'charge' && (
                                    <>
                                        <div className="mb-4">
                                            <div className="pb-2 border-bottom">
                                                <div className="d-flex align-items-center">
                                                    <div className="pr-2">
                                                        <span className="fs-20 fw-700">
                                                            Payment details
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-2">
                                                <div className="payment-stats-data-row">
                                                    <div className="payment-stats-data-header">
                                                        <span className="text-muted fs-14">
                                                            Amount
                                                        </span>
                                                    </div>
                                                    <div className="payment-stats-data-info">
                                                        <span className="text-gray fs-14">
                                                            {`$${
                                                                paymentDetail?.transaction
                                                                    ?.vendor_price
                                                                    ? toDecimal(
                                                                          paymentDetail?.transaction
                                                                              ?.vendor_price / 100
                                                                      )
                                                                    : 0
                                                            }`}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="payment-stats-data-row">
                                                    <div className="payment-stats-data-header">
                                                        <span className="text-muted fs-14">
                                                            Fee
                                                        </span>
                                                    </div>
                                                    <div className="payment-stats-data-info">
                                                        <span className="text-gray fs-14">
                                                            {`$${
                                                                paymentDetail?.transaction?.metadata
                                                                    ?.customer_calendar_commission
                                                                    ? toDecimal(
                                                                          paymentDetail?.transaction
                                                                              ?.metadata
                                                                              ?.customer_calendar_commission
                                                                      )
                                                                    : 0
                                                            } `}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="payment-stats-data-row">
                                                    <div className="payment-stats-data-header">
                                                        <span className="text-muted fs-14">
                                                            Net
                                                        </span>
                                                    </div>
                                                    <div className="payment-stats-data-info">
                                                        <span className="text-gray fs-14">{`$${
                                                            paymentDetail?.transaction
                                                                ?.vendor_recieved_amount
                                                                ? toDecimal(
                                                                      paymentDetail?.transaction
                                                                          ?.vendor_recieved_amount /
                                                                          100
                                                                  )
                                                                : 0
                                                        }`}</span>
                                                    </div>
                                                </div>
                                                <div className="payment-stats-data-row">
                                                    <div className="payment-stats-data-header">
                                                        <span className="text-muted fs-14">
                                                            Status
                                                        </span>
                                                    </div>
                                                    <div className="payment-stats-data-info">
                                                        <span className="text-gray fs-14">{`${
                                                            paymentDetail?.paystackData?.status &&
                                                            transformFirstLetterToUpperCase(
                                                                paymentDetail.paystackData.status
                                                            )
                                                        }`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-4">
                                            <div className="pb-2 border-bottom">
                                                <div className="d-flex align-items-center">
                                                    <div className="pr-2">
                                                        <span className="fs-20 fw-700">
                                                            Payment method
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-2">
                                                <div className="payment-stats-data-row">
                                                    <div className="payment-stats-data-header">
                                                        <span className="text-muted fs-14">
                                                            Account
                                                        </span>
                                                    </div>
                                                    <div className="payment-stats-data-info">
                                                        <span className="text-gray fs-14">
                                                            {
                                                                paymentDetail?.paystackData
                                                                    ?.customer?.customer_code
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="payment-stats-data-row">
                                                    <div className="payment-stats-data-header">
                                                        <span className="text-muted fs-14">
                                                            Transfer
                                                        </span>
                                                    </div>
                                                    <div className="payment-stats-data-info">
                                                        <span className="text-gray fs-14">{`N/a`}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <VendorCalendarProductPart data={paymentDetail} />

                                        {Array.isArray(data?.refunds) && data.refunds.length > 0 && (
                                            <div className="mb-4">
                                                <div className="pb-2 border-bottom">
                                                    <div className="d-flex align-items-center">
                                                        <div className="pr-2">
                                                            <span className="fs-20 fw-700">
                                                                Refunds
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {data.refunds.map((val, key) => (
                                                    <div key={key} className="py-2">
                                                        <div className="payment-stats-data-row">
                                                            <div className="payment-stats-data-header">
                                                                <span className="text-muted fs-14">
                                                                    Refunded amount
                                                                </span>
                                                            </div>
                                                            <div className="payment-stats-data-info">
                                                                <span className="text-gray fs-14">
                                                                    {`$${
                                                                        val?.amount
                                                                            ? toDecimal(
                                                                                  val?.amount / 100
                                                                              )
                                                                            : 0
                                                                    } ${val?.currency || ''}`}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="payment-stats-data-row">
                                                            <div className="payment-stats-data-header">
                                                                <span className="text-muted fs-14">
                                                                    Received amount
                                                                </span>
                                                            </div>
                                                            <div className="payment-stats-data-info">
                                                                <span className="text-gray fs-14">{`$${
                                                                    val?.vendor_amount
                                                                        ? toDecimal(
                                                                              val?.vendor_amount /
                                                                                  100
                                                                          )
                                                                        : 0
                                                                } ${
                                                                    val?.vendor_currency || ''
                                                                }`}</span>
                                                            </div>
                                                        </div>

                                                        <div className="payment-stats-data-row">
                                                            <div className="payment-stats-data-header">
                                                                <span className="text-muted fs-14">
                                                                    Refund id
                                                                </span>
                                                            </div>
                                                            <div className="payment-stats-data-info">
                                                                <span className="text-gray fs-14">
                                                                    {val?.refund_id || 'N/A'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </>
                                )}

                                {paymentDetailModal?.tab === 'transfer' && (
                                    <TransferPart paymentDetail={paymentDetail} />
                                )}

                                {paymentDetailModal?.tab === 'payout' && (
                                    <PayoutPart paymentDetail={paymentDetail} />
                                )}
                            </>
                        )}

                    {data && paymentDetailModal?.tab === 'refunds' && <RefundPart data={data} />}
                </ModalBody>
            </Modal>
        </>
    );
}

PaymentDetailModal.propTypes = {
    paymentDetailModal: PropTypes.object,
    data: PropTypes.object,
    setState: PropTypes.func,
    paymentDetail: PropTypes.object
};

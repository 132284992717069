/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Form, Tabs } from 'antd';
import { Row, Col, Collapse } from 'reactstrap';

import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spacer from 'components/atoms/spacer';
import PropTypes from 'prop-types';
import TicketData from 'components/atoms/ticket-data-accomodation';
import TicketDataActivity from 'components/atoms/ticket-data-activity';

const { TabPane } = Tabs;

class TicketPreview extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            isOpenCategory: false,
            category: '',
            description: '',
            startAddress: '',
            addAnotherLocation: false,
            type: 'mobile_or_paper',
            number: 'one_per_traveller',
            logoImage: '',
            anotherLocationAddress: ''
        };
    }

    onSave = () => {
        const { category, type, number, description } = this.state;
        const tmpData = {
            ticket_type: type,
            ticket_per_booking: number,
            ticket_redemption: {
                entry_ticket: category,
                redemption_instructions: description || ''
            },
            preview_tickets: {
                type: 'paper',
                company_logo: 'http://www.traveljinni.com'
            }
        };
        this.props.onSave(tmpData);
    };

    render() {
        const { isOpen, business_info, my_profile, productTitle } = this.props;
        const { requiredMark } = this.state;

        return (
            <div className="itenary-form" style={{ background: '#f9f7f2' }}>
                <div
                    className="application ticket-builder"
                    style={{ zIndex: isOpen ? 1052 : 1000 }}>
                    <Form
                        ref={this.formRef}
                        onFinish={this.onSave}
                        layout="vertical"
                        initialValues={{
                            requiredMark
                        }}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}
                        className="flex-grow-1 d-flex flex-column overflow-y-auto px-3 mx-n3">
                        <div className="flex-grow-1 overflow-y-auto scrollbar-fixed px-3 mx-n3">
                            <Form.Item>
                                <div className="title-tickets">
                                    {"Here's a preview of your tickets"}
                                </div>
                                <div>
                                    <Tabs defaultActiveKey="1">
                                        <TabPane tab="Preview mobile" key="1">
                                            {/* <p className="fs-16 mb-2">
                                            <strong>
                                                Add your company logo
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        color: 'rgba(79, 79, 79, 0.63)',
                                                        fontWeight: 400
                                                    }}>
                                                    (optional)
                                                </span>
                                            </strong>
                                        </p>
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            onChange={this.onChange}
                                            customRequest={this.dummyRequest}
                                            showUploadList={false}>
                                            <AntButton type="secondary" shape="round">
                                                Upload file
                                            </AntButton>
                                        </Upload>
                                        <p className="fs-12 mt-2">Upload your company logo</p> */}

                                            <div className="card-body-mobile">
                                                <div className="card-ticket-body">
                                                    <div className="card-wrap-mobile">
                                                        <div className="card">
                                                            <div className="left">
                                                                <div className="logo-ticket">
                                                                    <div className="d-flex align-items-center flex-nowrap mx-n1">
                                                                        <div className="px-1">
                                                                            <div className="calendar-logo-container">
                                                                                <img
                                                                                    src={
                                                                                        business_info?.business_logo_url
                                                                                    }
                                                                                    alt="Logo"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="px-1">
                                                                            <div className="event-name-wrapper">
                                                                                <div className="event-input-wrapper">
                                                                                    <div className="ticket-holder-name">
                                                                                        {
                                                                                            business_info?.business_trading_name
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <div className="event-name-footer">
                                                                                    Powered by
                                                                                    Travel Jinni
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="body-ticket">
                                                                    <div className="mb-20px">
                                                                        <div className="fs-22 fw-600">
                                                                            Mark Doe
                                                                        </div>
                                                                        <div className="fs-12 fw-500">
                                                                            {productTitle ||
                                                                                '5 Day Kruger Big 5 Safari Experience'}
                                                                        </div>
                                                                        <div className="fs-18">
                                                                            $300
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="fs-12 mb-0 fw-500">
                                                                            Thur, Aug.20, 2020
                                                                        </div>
                                                                        <div className="fs-12 mb-0 fw-500">
                                                                            2 adults, 1 youth
                                                                        </div>
                                                                    </div>
                                                                    <Row style={{ margin: 0 }}>
                                                                        <Col sm={5}>
                                                                            {this.state
                                                                                .logoImage ? (
                                                                                <img
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        height: '100%'
                                                                                    }}
                                                                                    src={
                                                                                        this.state
                                                                                            .logoImage
                                                                                    }
                                                                                    alt=""
                                                                                />
                                                                            ) : null}
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                                <div
                                                                    className="patch"
                                                                    style={{
                                                                        width: '56px',
                                                                        height: '28px',
                                                                        backgroundColor: '#F0F0F0',
                                                                        position: 'absolute',
                                                                        top: '0',
                                                                        left: 'calc(50% - 28px)',
                                                                        borderRadius:
                                                                            '0 0 28px 28px'
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="right">
                                                                <div className="mb-40px">
                                                                    <div className="fs12 mb-1">
                                                                        Booking ref: BR-123456789
                                                                    </div>
                                                                    <div className="fs12 mb-1">
                                                                        Tour operator:
                                                                        {
                                                                            business_info?.business_trading_name
                                                                        }
                                                                    </div>
                                                                    <div className="fs12 mb-1">
                                                                        Tour operator confirmation
                                                                        no: S12345678
                                                                    </div>
                                                                </div>
                                                                <div className="text-center">
                                                                    <svg
                                                                        width="136"
                                                                        height="64"
                                                                        viewBox="0 0 181 64"
                                                                        fill="none"
                                                                        xmlns="http://www.w3.org/2000/svg">
                                                                        <path
                                                                            d="M0 0.680176H10.6303V63.3197H0V0.680176ZM17.1365 63.3197H23.1213V0.680176H17.1365V63.3197ZM27.435 63.3197H33.4079V0.680176H27.435V63.3197ZM41.2651 63.3197H54.3486V0.680176H41.2651V63.3197ZM64.8249 63.3197H71.3666V0.680176H64.8249V63.3197ZM81.511 63.3197H108.626V0.680176H81.511V63.3197ZM152.167 63.3197H161.079V0.680176H152.167V63.3197ZM168.853 0.680176V63.3197H181V0.680176H168.853ZM119.505 63.3197H125.336V0.680176H119.505V63.3197ZM133.691 63.3197H139.522V0.680176H133.691V63.3197Z"
                                                                            fill="#030104"
                                                                        />
                                                                    </svg>
                                                                    <div
                                                                        className="fs-13"
                                                                        style={{
                                                                            fontWeight: '600'
                                                                        }}>
                                                                        1234566
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="group-btn ml-auto mt-2">
                                                            <button className="ant-btn ant-btn-text">
                                                                <svg
                                                                    width="37"
                                                                    height="31"
                                                                    viewBox="0 0 37 31"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M18.8597 21.0401C18.5522 21.0401 18.2582 20.9141 18.0467 20.6921L10.1717 12.4421C9.48919 11.7281 9.99619 10.5401 10.9847 10.5401H15.1097V1.91507C15.1097 0.88157 15.9512 0.0400696 16.9847 0.0400696H20.7347C21.7682 0.0400696 22.6097 0.88157 22.6097 1.91507V10.5401H26.7347C27.7232 10.5401 28.2302 11.7281 27.5477 12.4421L19.6727 20.6921C19.4612 20.9141 19.1672 21.0401 18.8597 21.0401Z"
                                                                        fill="#A2792C"
                                                                    />
                                                                    <path
                                                                        d="M34.2345 30.0399H3.4845C2.037 30.0399 0.859497 28.8624 0.859497 27.4149V26.6649C0.859497 25.2174 2.037 24.0399 3.4845 24.0399H34.2345C35.682 24.0399 36.8595 25.2174 36.8595 26.6649V27.4149C36.8595 28.8624 35.682 30.0399 34.2345 30.0399Z"
                                                                        fill="#A2792C"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="mb-40px">
                                                        <p className="small-heading fs-14 mb-2">
                                                            Need to Make Changes or Cancel?
                                                        </p>
                                                        <p className="fs-12 mb-0">
                                                            Visit{' '}
                                                            <span className="brown-text">
                                                                https://traveljinni.com
                                                            </span>{' '}
                                                            to update your <br /> booking.
                                                        </p>
                                                    </div>

                                                    <div className="mb-40px">
                                                        <p className="small-heading fs-14 mb-2">
                                                            Tour Specific Inquiries
                                                        </p>
                                                        <div>
                                                            <span className="fs-12 d-block">
                                                                {
                                                                    business_info?.business_trading_name
                                                                }
                                                            </span>
                                                            <a
                                                                className="text-primary fs-12"
                                                                href={`tel:${my_profile.contact_number}`}>
                                                                {my_profile.contact_number}
                                                            </a>
                                                        </div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <div
                                                            className="fs-14 text-reset d-flex flex-nowrap align-items-center"
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                this.setState({
                                                                    showInclusions:
                                                                        !this.state.showInclusions
                                                                })
                                                            }
                                                            onKeyUp={() =>
                                                                this.setState({
                                                                    showInclusions:
                                                                        !this.state.showInclusions
                                                                })
                                                            }>
                                                            <div className="flex-grow-1 mr-2">
                                                                Inclusions & Exclusions
                                                            </div>
                                                            <span>
                                                                <svg
                                                                    width="9"
                                                                    height="13"
                                                                    viewBox="0 0 9 13"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0.683715 1.44992L5.26371 6.03992L0.683716 10.6299L2.09372 12.0399L8.09371 6.03992L2.09371 0.0399169L0.683715 1.44992Z"
                                                                        fill="#A2792C"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                        <Collapse
                                                            isOpen={this.state.showInclusions}>
                                                            <div>
                                                                <p className="fs-12 mb-2">
                                                                    {'TOUR OPTION DESCRIPTION'}
                                                                </p>
                                                                <TicketData
                                                                    data={this.props.ticketData}
                                                                />
                                                                <TicketDataActivity
                                                                    itineraries={
                                                                        this.props.itineraries
                                                                    }
                                                                    data={
                                                                        this.props
                                                                            .ticketDataActivity
                                                                    }
                                                                />
                                                            </div>
                                                        </Collapse>
                                                        <Spacer top={40} />
                                                        <div
                                                            className="fs-14 text-reset d-flex flex-nowrap align-items-center"
                                                            role="button"
                                                            tabIndex={0}
                                                            onClick={() =>
                                                                this.setState({
                                                                    cancellationPolicy:
                                                                        !this.state
                                                                            .cancellationPolicy
                                                                })
                                                            }
                                                            onKeyUp={() =>
                                                                this.setState({
                                                                    cancellationPolicy:
                                                                        !this.state
                                                                            .cancellationPolicy
                                                                })
                                                            }>
                                                            <div className="flex-grow-1 mr-2">
                                                                Cancellation Policy
                                                            </div>
                                                            <span>
                                                                <svg
                                                                    width="9"
                                                                    height="13"
                                                                    viewBox="0 0 9 13"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0.683715 1.44992L5.26371 6.03992L0.683716 10.6299L2.09372 12.0399L8.09371 6.03992L2.09371 0.0399169L0.683715 1.44992Z"
                                                                        fill="#A2792C"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>

                                                        <Collapse
                                                            isOpen={this.state.cancellationPolicy}>
                                                            <p className="fs-12">
                                                                {this.props?.cancellationPolicy ||
                                                                    'For a full refund, cancel at least 24 hours in advance of the start date of the experience. Learn more about cancellations.'}
                                                            </p>
                                                        </Collapse>
                                                    </div>
                                                </div>
                                            </div>
                                        </TabPane>
                                        <TabPane tab="Preview paper" key="2">
                                            {/* <p className="fs-16 mb-2">
                                            <strong>
                                                Add your company logo
                                                <span
                                                    style={{
                                                        fontSize: 14,
                                                        color: 'rgba(79, 79, 79, 0.63)',
                                                        fontWeight: 400
                                                    }}>
                                                    (optional)
                                                </span>
                                            </strong>
                                        </p>
                                        <Upload
                                            listType="picture"
                                            maxCount={1}
                                            onChange={this.onChange}
                                            customRequest={this.dummyRequest}
                                            showUploadList={false}>
                                            <AntButton type="secondary" shape="round">
                                                Upload file
                                            </AntButton>
                                        </Upload>
                                        <p className="fs-12 mt-2">Upload your company logo</p> */}

                                            <p
                                                className="fs-14"
                                                style={{
                                                    color: '#CE3838',
                                                    display: 'flex',
                                                    alignItems: 'center'
                                                }}>
                                                <svg
                                                    width="32"
                                                    height="32"
                                                    viewBox="0 0 32 32"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M17.3333 19.9999L14.6666 19.9999L14.6666 22.6666L17.3333 22.6666L17.3333 19.9999ZM15.9999 5.33325C21.8799 5.33325 26.6666 10.1199 26.6666 15.9999C26.6666 21.8799 21.8799 26.6666 15.9999 26.6666C10.1199 26.6666 5.33325 21.8799 5.33325 15.9999C5.33325 10.1199 10.1199 5.33325 15.9999 5.33325ZM15.9999 29.3333C17.7509 29.3333 19.4847 28.9884 21.1024 28.3183C22.72 27.6483 24.1899 26.6661 25.428 25.428C27.9285 22.9275 29.3333 19.5361 29.3333 15.9999C29.3333 12.4637 27.9285 9.07231 25.428 6.57183C24.1899 5.33371 22.72 4.35159 21.1024 3.68152C19.4847 3.01146 17.7509 2.66659 15.9999 2.66659C12.4637 2.66659 9.07231 4.07134 6.57182 6.57183C4.07134 9.07232 2.66658 12.4637 2.66658 15.9999C2.66658 17.7509 3.01146 19.4847 3.68152 21.1024C4.35158 22.72 5.33371 24.1899 6.57183 25.428C7.80994 26.6661 9.2798 27.6483 10.8975 28.3183C12.5151 28.9884 14.249 29.3333 15.9999 29.3333ZM17.3333 9.33325L14.6666 9.33325L14.6666 17.3333L17.3333 17.3333L17.3333 9.33325Z"
                                                        fill="#CE3838"
                                                    />
                                                </svg>
                                                YOU MUST PRINT THIS TICKET
                                            </p>
                                            <p style={{ fontSize: 14, fontWeight: '400' }}>
                                                Please download and print this ticket and bring it
                                                to the tour/activity. Mobile tickets will not be
                                                accepted.
                                            </p>
                                            <div
                                                className="card-ticket-body"
                                                style={{ width: '100%' }}>
                                                <div className="card-wrap">
                                                    <div className="card">
                                                        <div className="left">
                                                            <div className="logo-ticket">
                                                                <div className="d-flex align-items-center flex-nowrap mx-n1">
                                                                    <div className="px-1">
                                                                        <div className="calendar-logo-container">
                                                                            <img
                                                                                src={
                                                                                    business_info?.business_logo_url
                                                                                }
                                                                                alt="Logo"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-1">
                                                                        <div
                                                                            className="event-name-wrapper event-name-wrapper-paper"
                                                                            style={{
                                                                                maxWidth: 140
                                                                            }}>
                                                                            <div className="event-input-wrapper">
                                                                                <div
                                                                                    className="ticket-holder-name"
                                                                                    style={{
                                                                                        fontSize: 16
                                                                                    }}>
                                                                                    {
                                                                                        business_info?.business_trading_name
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="event-name-footer">
                                                                                Powered by Travel
                                                                                Jinni
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="body-ticket pt-3">
                                                                <div className="mb-20px">
                                                                    <div className="fs-22 fw-600">
                                                                        Mark Doe
                                                                    </div>
                                                                    <div className="fs-12 fw-500">
                                                                        {productTitle ||
                                                                            '5 Day Kruger Big 5 Safari Experience'}
                                                                    </div>
                                                                    <div className="fs-18">
                                                                        $300
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div className="fs-12 mb-0 fw-500">
                                                                        Thur, Aug.20, 2020
                                                                    </div>
                                                                    <div className="fs-12 mb-0 fw-500">
                                                                        2 adults, 1 youth
                                                                    </div>
                                                                </div>
                                                                <Row className="logo-image">
                                                                    <Col sm={5}>
                                                                        {this.state.logoImage ? (
                                                                            <img
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: '100%'
                                                                                }}
                                                                                src={
                                                                                    this.state
                                                                                        .logoImage
                                                                                }
                                                                                alt=""
                                                                            />
                                                                        ) : null}
                                                                    </Col>
                                                                </Row>
                                                            </div>

                                                            <div
                                                                className="patch"
                                                                style={{
                                                                    width: '28px',
                                                                    height: '56px',
                                                                    backgroundColor: '#F0F0F0',
                                                                    position: 'absolute',
                                                                    top: 'calc(50% - 28px)',
                                                                    left: '0',
                                                                    borderRadius: '0 28px 28px 0'
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="right">
                                                            <div>
                                                                <p className="fs12 mb0">
                                                                    Booking ref: BR-123456789
                                                                </p>
                                                                <p className="fs12 mb0">
                                                                    Tour operator:{' '}
                                                                    {
                                                                        business_info?.business_trading_name
                                                                    }
                                                                </p>
                                                                <p className="fs12 mb50">
                                                                    Tour operator confirmation no:
                                                                    S12345678
                                                                </p>
                                                            </div>
                                                            <div className="text-center">
                                                                <svg
                                                                    width="136"
                                                                    height="64"
                                                                    viewBox="0 0 181 64"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M0 0.680176H10.6303V63.3197H0V0.680176ZM17.1365 63.3197H23.1213V0.680176H17.1365V63.3197ZM27.435 63.3197H33.4079V0.680176H27.435V63.3197ZM41.2651 63.3197H54.3486V0.680176H41.2651V63.3197ZM64.8249 63.3197H71.3666V0.680176H64.8249V63.3197ZM81.511 63.3197H108.626V0.680176H81.511V63.3197ZM152.167 63.3197H161.079V0.680176H152.167V63.3197ZM168.853 0.680176V63.3197H181V0.680176H168.853ZM119.505 63.3197H125.336V0.680176H119.505V63.3197ZM133.691 63.3197H139.522V0.680176H133.691V63.3197Z"
                                                                        fill="#030104"
                                                                    />
                                                                </svg>
                                                                <div
                                                                    className="fs-13"
                                                                    style={{
                                                                        fontWeight: '600'
                                                                    }}>
                                                                    1234566
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="group-btn ml-auto mt-2">
                                                        <button className="ant-btn ant-btn-text">
                                                            <svg
                                                                width="37"
                                                                height="34"
                                                                viewBox="0 0 37 34"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M24.0377 27.1533H13.6376C12.9749 27.1533 12.4376 27.6905 12.4376 28.3533C12.4376 29.016 12.9748 29.5533 13.6376 29.5533H24.0376C24.7003 29.5533 25.2376 29.016 25.2376 28.3533C25.2376 27.6905 24.7004 27.1533 24.0377 27.1533Z"
                                                                    fill="#A2792C"
                                                                />
                                                                <path
                                                                    d="M24.0377 23.3687H13.6376C12.9749 23.3687 12.4376 23.906 12.4376 24.5687C12.4376 25.2315 12.9748 25.7688 13.6376 25.7688H24.0376C24.7003 25.7688 25.2376 25.2315 25.2376 24.5687C25.2376 23.906 24.7004 23.3687 24.0377 23.3687Z"
                                                                    fill="#A2792C"
                                                                />
                                                                <path
                                                                    d="M34.0372 8.45526H30.219V1.51014C30.219 0.847448 29.6817 0.31012 29.019 0.31012H8.65535C7.99265 0.31012 7.45532 0.847377 7.45532 1.51014V8.45526H3.63714C2.09322 8.45526 0.837158 9.71139 0.837158 11.2553V23.3281C0.837158 24.872 2.09322 26.1281 3.63714 26.1281H7.45553V32.5697C7.45553 33.2324 7.99279 33.7697 8.65556 33.7697H29.0188C29.6815 33.7697 30.2188 33.2325 30.2188 32.5697V26.1281H34.0372C35.5811 26.1281 36.8372 24.872 36.8372 23.3281V11.2553C36.8372 9.71146 35.5811 8.45526 34.0372 8.45526ZM9.8553 2.71017H27.8189V8.45526H9.8553V2.71017ZM27.8187 31.3697H9.85558C9.85558 31.1352 9.85558 21.8421 9.85558 21.5521H27.8188C27.8187 21.8494 27.8187 31.1442 27.8187 31.3697ZM29.019 15.5016H25.9644C25.3017 15.5016 24.7644 14.9644 24.7644 14.3016C24.7644 13.6388 25.3016 13.1016 25.9644 13.1016H29.019C29.6817 13.1016 30.219 13.6388 30.219 14.3016C30.219 14.9644 29.6817 15.5016 29.019 15.5016Z"
                                                                    fill="#A2792C"
                                                                />
                                                            </svg>
                                                        </button>
                                                        <button className="ant-btn ant-btn-text">
                                                            <svg
                                                                width="37"
                                                                height="31"
                                                                viewBox="0 0 37 31"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M18.8597 21.0401C18.5522 21.0401 18.2582 20.9141 18.0467 20.6921L10.1717 12.4421C9.48919 11.7281 9.99619 10.5401 10.9847 10.5401H15.1097V1.91507C15.1097 0.88157 15.9512 0.0400696 16.9847 0.0400696H20.7347C21.7682 0.0400696 22.6097 0.88157 22.6097 1.91507V10.5401H26.7347C27.7232 10.5401 28.2302 11.7281 27.5477 12.4421L19.6727 20.6921C19.4612 20.9141 19.1672 21.0401 18.8597 21.0401Z"
                                                                    fill="#A2792C"
                                                                />
                                                                <path
                                                                    d="M34.2345 30.0399H3.4845C2.037 30.0399 0.859497 28.8624 0.859497 27.4149V26.6649C0.859497 25.2174 2.037 24.0399 3.4845 24.0399H34.2345C35.682 24.0399 36.8595 25.2174 36.8595 26.6649V27.4149C36.8595 28.8624 35.682 30.0399 34.2345 30.0399Z"
                                                                    fill="#A2792C"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>

                                                <Row>
                                                    <Col
                                                        sm={12}
                                                        lg={6}
                                                        className="d-flex flex-column">
                                                        <div className="flex-grow-1">
                                                            <p className="small-heading fs-14 mb-2">
                                                                Need to Make Changes or Cancel?
                                                            </p>
                                                            <p className="fs-12 mb-0">
                                                                Visit{' '}
                                                                <span className="brown-text">
                                                                    https://traveljinni.com
                                                                </span>{' '}
                                                                to update your <br /> booking.
                                                            </p>
                                                        </div>

                                                        <div>
                                                            <hr />
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        sm={12}
                                                        lg={6}
                                                        className="d-flex flex-column">
                                                        <div className="flex-grow-1">
                                                            <p className="small-heading fs-14 mb-2">
                                                                Tour Specific Inquiries
                                                            </p>
                                                            <div>
                                                                <span className="fs-12 d-block">
                                                                    {
                                                                        business_info?.business_trading_name
                                                                    }
                                                                </span>
                                                                <a
                                                                    className="text-primary fs-12"
                                                                    href={`tel:${my_profile?.contact_number}`}>
                                                                    {my_profile?.contact_number}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <hr />
                                                        </div>
                                                    </Col>
                                                    <Col sm={12} lg={6}>
                                                        <div className="small-heading fs-14 mb-2">
                                                            Inclusions & Exclusions
                                                        </div>
                                                        <div className="fs-12 mb-2">
                                                            {'TOUR OPTION DESCRIPTION'}
                                                        </div>
                                                        <TicketData data={this.props.ticketData} />
                                                        <TicketDataActivity
                                                            itineraries={this.props.itineraries}
                                                            data={this.props.ticketDataActivity}
                                                        />
                                                    </Col>
                                                    <Col sm={12} lg={6}>
                                                        <div className="small-heading fs-14 mb-2">
                                                            Cancellation Policy
                                                        </div>
                                                        <p className="fs-12">
                                                            {this.props?.cancellationPolicy ||
                                                                'For a full refund, cancel at least 24 hours in advance of the start date of the experience. Learn more about cancellations.'}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>
                                    </Tabs>
                                </div>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

TicketPreview.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    onSave: PropTypes.func,
    calendarData: PropTypes.object,
    ticketData: PropTypes.object,
    ticketDataActivity: PropTypes.object,
    itineraries: PropTypes.array,
    data: PropTypes.object,
    business_info: PropTypes.object,
    my_profile: PropTypes.object,
    cancellationPolicy: PropTypes.string,
    productTitle: PropTypes.string
};

const mapStateToProps = (state) => ({
    itineraries: state.contentDetail.itineraries,
    business_info: state.profile.business_info,
    my_profile: state.auth.my_profile
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(TicketPreview));

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */

import React, { useState, useContext, useRef } from 'react';
import { Popover } from 'antd';
import { Button } from 'reactstrap';
import {
    EditIcon,
    ProductCategoryIcon,
    ClashIcon,
    CalendarEventCarouselArrow
} from 'components/atoms';
import Carousel from 'react-multi-carousel';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateCalendarSelectedAllDayProduct } from 'store/actions';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { useEffect } from 'react';
import { getAllDayEvents } from 'utils';

const responsive = {
    eventGallery: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};

const ActiveDaySchedule = ({
    selectedDate,
    onAdd,
    setCurrentProduct,
    updateCalendarSelectedAllDayProduct,
    calendar,
    products,
    selectedAllDayCategory
}) => {
    const carouselRef = useRef();
    const popoverRef = useRef();
    const allDayEvents = getAllDayEvents(products || [], selectedDate, calendar);

    useEffect(() => {
        // Update the selected product for all day category when user selects a particular category on dayCard Header (activityPanel)
        if (selectedAllDayCategory) {
            const productItem = (selectedCategoryProducts = allDayEvents.filter(
                (item) => item?.product_category_id === selectedAllDayCategory
            )[0]);

            if (productItem && calendar) {
                updateCalendarSelectedAllDayProduct({
                    calendar_id: calendar?.id,
                    product_id: productItem?.id,
                    day: selectedDate.split('- ')[1]
                });
            }
        }
    }, [selectedAllDayCategory]);

    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const currentDay = selectedDate.split('- ')[1];
    const currentDayNo = Number(selectedDate.split(' ')[1]);

    let selectedCategoryProducts;

    if (selectedAllDayCategory)
        selectedCategoryProducts = allDayEvents.filter(
            (item) => item?.product_category_id === selectedAllDayCategory
        );
    else
        selectedCategoryProducts = selectedCategoryProducts = allDayEvents.filter(
            (item) => item?.product_category_id === allDayEvents[0]?.product_category_id
        );

    return selectedCategoryProducts.length < 1 ? (
        <div className="active-day-schedule-wrapper"></div>
    ) : (
        <div className="active-day-schedule-wrapper">
            {/* Event Cards */}
            <div className="text-right">
                {selectedCategoryProducts.length <= 1 ? (
                    <></>
                ) : (
                    <Popover
                        ref={popoverRef}
                        overlayClassName="popover-container popover-large popover-centered popover-bg-custom-dark "
                        placement={window.innerWidth >= 768 ? 'left' : 'bottom'}
                        content={
                            <div>
                                <div className="popover-e2e mb-0">
                                    <div className="border-bottom p-2">
                                        <div className="d-flex align-items-center">
                                            <div className="flex-grow-1 mr-2 pl-3">
                                                <div className="event-day-name text-uppercase">
                                                    Day {selectedDate?.split(' ')[1]}
                                                </div>
                                            </div>
                                            <div>
                                                <Button onClick={onAdd} color="tooltip">
                                                    Add{' '}
                                                    {
                                                        selectedCategoryProducts[0]?.productCategory
                                                            ?.name
                                                    }
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="py-3">
                                    {selectedCategoryProducts.map((item, index) => (
                                        <ProductItem
                                            popoverRef={popoverRef}
                                            item={item}
                                            key={index}
                                            index={index}
                                            setCurrentProduct={setCurrentProduct}
                                            updateCalendarSelectedAllDayProduct={
                                                updateCalendarSelectedAllDayProduct
                                            }
                                            calendar={calendar}
                                            day={currentDay}
                                        />
                                    ))}
                                </div>
                            </div>
                        }>
                        <span role="button" tabIndex="0" className="see-more-products-btn">
                            <span className="mr-2">See more Products on day {currentDayNo}</span>
                            <ClashIcon size={12} />
                        </span>
                    </Popover>
                )}
            </div>
            <div
                className="event-card cursor-pointer"
                style={{ height: selectedCategoryProducts.length <= 1 ? '100%' : '90%' }}
                onClick={() => setCurrentProduct(selectedCategoryProducts[0])}
                onKeyUp={() => setCurrentProduct(selectedCategoryProducts[0])}>
                <div className="event-card-header">
                    <div className="event-card-title">
                        <span className="event-card-header-icon mr-2">
                            <ProductCategoryIcon
                                id={selectedCategoryProducts[0]?.product_category_id}
                                color={'currentColor'}
                            />
                        </span>
                        {selectedCategoryProducts[0]?.title}
                    </div>
                    <div
                        className={`event-card-header-action ${assignComponentPermission(
                            'product'
                        )}`}>
                        <span
                            role="button"
                            tabIndex="0"
                            title="Edit Event"
                            className={`event-card-header-action ${assignComponentPermission(
                                'product-update'
                            )}`}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.9881 2.01202L21.9881 5.01202L19.701 7.30002L16.701 4.30002L18.9881 2.01202Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M8 16H11L18.287 8.71301L15.287 5.71301L8 13V16Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M19 19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.896 3 5V19C3 20.104 3.897 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10.332L19 12.332V19Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                    </div>
                </div>

                {/* Event image gallery */}
                <div className="event-gallery-wrapper">
                    {selectedCategoryProducts[0]?.photos_and_videos &&
                        selectedCategoryProducts[0]?.photos_and_videos.length === 1 && (
                            <div className="event-gallery-item">
                                {selectedCategoryProducts[0]?.photos_and_videos[0].type ===
                                'image' ? (
                                    <img
                                        className="event-gallery-img"
                                        src={selectedCategoryProducts[0]?.photos_and_videos[0].url}
                                        alt=""
                                    />
                                ) : null}
                            </div>
                        )}

                    {selectedCategoryProducts[0]?.photos_and_videos &&
                        selectedCategoryProducts[0]?.photos_and_videos.length > 1 && (
                            <Carousel
                                ref={carouselRef}
                                customLeftArrow={
                                    <CalendarEventCarouselArrow
                                        onClick={() => {
                                            carouselRef.previous();
                                        }}
                                        type={'left'}
                                    />
                                }
                                customRightArrow={
                                    <CalendarEventCarouselArrow
                                        onClick={() => {
                                            carouselRef.next();
                                        }}
                                    />
                                }
                                swipeable={true}
                                draggable={false}
                                showDots={false}
                                arrows={true}
                                responsive={responsive.eventGallery}
                                // ssr={true} // means to render carousel on server-side.
                                infinite={true}
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                keyBoardControl={true}
                                // customTransition="all .5"
                                // transitionDuration={500}
                                containerClass="event-gallery-carousel"
                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                // deviceType={this.props.deviceType}
                                // dotListClass="custom-dot-list-style"
                                // itemClass="carousel-item-padding-40-px"
                            >
                                {selectedCategoryProducts[0]?.photos_and_videos.map((item, index) =>
                                    item.type === 'image' ? (
                                        <div className="event-gallery-item" key={index}>
                                            <img
                                                className="event-gallery-img"
                                                src={item.url}
                                                alt=""
                                            />
                                        </div>
                                    ) : null
                                )}
                            </Carousel>
                        )}
                </div>

                <div className="event-card-info">
                    {selectedCategoryProducts[0]?.description?.length > 50
                        ? selectedCategoryProducts[0]?.description.slice(0, 50) + '... '
                        : selectedCategoryProducts[0]?.description}
                </div>
            </div>
        </div>
    );
};

const ProductItem = ({
    item,
    index,
    calendar,
    day,
    setCurrentProduct,
    updateCalendarSelectedAllDayProduct,
    popoverRef
}) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <div
            className={`d-flex flex-nowrap mx-n2 event-schedule ${index === 0 ? '__special' : ''}`}>
            <div className="px-2 event-time-window-wrapper">
                <div className="event-time-window __all-day">
                    <div className="event-all-day-time">All Day</div>
                </div>
            </div>
            <div className="px-2 flex-grow-1">
                <div className="event-info-wrapper">
                    <div
                        className="event-title  cursor-pointer "
                        onClick={() => {
                            updateCalendarSelectedAllDayProduct({
                                calendar_id: calendar.id,
                                product_id: item.id,
                                day
                            });
                        }}>
                        <span className="event-icon mr-2">
                            <ProductCategoryIcon
                                id={item?.product_category_id}
                                color={'currentColor'}
                            />
                        </span>
                        {item.title}
                    </div>
                    <div className="event-info">
                        {item?.description?.length > 50 && !showMore
                            ? item?.description.slice(0, 50)
                            : item.description}
                        {item?.description?.length > 50 ? (
                            <i
                                className="ml-2"
                                role="button"
                                onKeyDown={() => {}}
                                tabIndex={0}
                                onClick={() => setShowMore(!showMore)}>
                                {showMore ? 'see less' : 'see more'}
                            </i>
                        ) : null}
                    </div>
                </div>
            </div>
            <div className="px-2">
                <span
                    className="text-white"
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                        setCurrentProduct(item);
                        if (popoverRef?.current?.close) popoverRef.current.close();
                    }}
                    onKeyUp={() => {
                        setCurrentProduct(item);
                        if (popoverRef?.current?.close) popoverRef.current.close();
                    }}>
                    <EditIcon />
                </span>
            </div>
        </div>
    );
};
ProductItem.propTypes = {
    item: PropTypes.any,
    index: PropTypes.any,
    setCurrentProduct: PropTypes.func,
    updateCalendarSelectedAllDayProduct: PropTypes.func,
    calendar: PropTypes.any,
    day: PropTypes.any,
    popoverRef: PropTypes.any
};
ActiveDaySchedule.propTypes = {
    allProducts: PropTypes.any,
    selectedDate: PropTypes.any,
    products: PropTypes.any,
    calendar: PropTypes.any,
    onAdd: PropTypes.func,
    setCurrentProduct: PropTypes.func,
    updateCalendarSelectedAllDayProduct: PropTypes.func,
    allDayEvents: PropTypes.any,
    selectedAllDayCategory: PropTypes.any
};

const mapStateToProps = (state) => ({
    products: state?.calendar.get_all_calendar_products?.data,
    calendar: state?.calendar.get_calendar_data?.data
});

export default connect(mapStateToProps, { updateCalendarSelectedAllDayProduct })(ActiveDaySchedule);

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { GuideIcon } from 'components/atoms';
import { getYesNo } from 'utils';

const EventGuideMeetLocation = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<GuideIcon />}
            panelTitle="Guide will meet you at"
            isEditable={true}
            onEdit={onEdit}>
            {product?.meetup_info ? (
                <>
                    <Row className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    At the accomodation provided by you
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <span className="fs-18 lh-1-7">
                                {getYesNo(product?.meetup_info?.at_accommodation)}
                            </span>
                        </Col>
                    </Row>
                    <Row className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    At a Starting Point
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <span className="fs-18 lh-1-7">
                                {getYesNo(product?.meetup_info?.at_starting_point)}
                            </span>
                        </Col>
                    </Row>
                    <Row className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    Allow travellers to enter their their own pick up location
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <span className="fs-18 lh-1-7">
                                {getYesNo(
                                    product?.meetup_info?.allow_users_to_enter_pickup_location
                                )}
                            </span>
                        </Col>
                    </Row>
                    {product?.meetup_info?.points?.length > 0 ? (
                        product?.meetup_info.points.map((item, index) => (
                            <Row
                                key={index}
                                className="border-bottom border-primary-shaded py-2"
                                noGutters>
                                <Col xs={6}>
                                    <div className="d-flex flex-nowrap">
                                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                            Starting Point {index + 1}
                                        </span>
                                        <span className="fs-18 lh-1-7 mr-4">:</span>
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <span className="fs-18 lh-1-7">{item}</span>
                                </Col>
                            </Row>
                        ))
                    ) : (
                        <></>
                    )}
                </>
            ) : (
                <></>
            )}
        </DetailedPanel>
    );
};

EventGuideMeetLocation.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventGuideMeetLocation;

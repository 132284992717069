import React, { useState, useEffect } from 'react';
import './index.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Switch, TimePicker } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import { Spacer } from 'components/atoms';
import moment from 'moment';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../../modals/confirmation-modal';
import { compareHouseRules } from 'utils';

function HouseRules(props) {
    const { selected_existing_product, data } = props;

    const [isAllowSmoking, setIsAllowSmoking] = useState(false);
    const [isAllowPets, setIsAllowPets] = useState(false);
    const [isAllowParties, setIsAllowParties] = useState(false);
    const [checkIn, setCheckIn] = useState({ from: '00:00', until: '00:00' });
    const [checkOut, setCheckOut] = useState({ from: '00:00', until: '00:00' });

    const [confirmationModal, setConfirmationModal] = useState(false);

    useEffect(() => {
        if (data && data !== null) {
            setIsAllowSmoking(data?.smoking_allowed);
            setIsAllowPets(data?.pets_allowed);
            setIsAllowParties(data?.events_allowed);
            setCheckIn({ from: data?.check_in?.from, until: data?.check_in?.until });
            setCheckOut({ from: data?.check_out?.from, until: data?.check_out?.until });
        } else {
            setIsAllowSmoking(false);
            setIsAllowPets(false);
            setIsAllowParties(false);
            setCheckIn({ from: '00:00', until: '00:00' });
            setCheckOut({ from: '00:00', until: '00:00' });
        }
    }, [data]);

    const onFinish = () => {
        form.validateFields()
            .then(() => {
                if (selected_existing_product && data)
                    if (
                        compareHouseRules(data, {
                            checkIn,
                            checkOut,
                            isAllowParties,
                            isAllowPets,
                            isAllowSmoking
                        })
                    )
                        return setConfirmationModal(true);
                    else props.onClose();
                else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onSave = () => {
        let checkInData = { ...checkIn };
        let checkOutData = { ...checkOut };
        if (checkIn && Object.keys(checkIn).length == 0) {
            checkInData = { from: '00:00', until: '00:00' };
        }
        if (checkOut && Object.keys(checkOut).length == 0) {
            checkOutData = { from: '00:00', until: '00:00' };
        }
        const houseRules = {
            smoking_allowed: isAllowSmoking,
            pets_allowed: isAllowPets,
            events_allowed: isAllowParties,
            check_in: checkInData,
            check_out: checkOutData
        };
        props.onSave(houseRules);
    };

    const onClose = () => props.onClose();

    const showNextDayMessage = (type) => {
        if (type == 'checkin') {
            if (
                checkIn?.from &&
                checkIn?.until &&
                !(checkIn.from == '00:00' && checkIn.until == '00:00')
            ) {
                let checkInFromAmPm = moment(checkIn?.from, 'HH:mm')
                    .format('hh:mm A')
                    .split(' ')[1];
                let checkInUntilAmPm = moment(checkIn?.until, 'HH:mm')
                    .format('hh:mm A')
                    .split(' ')[1];
                if (
                    checkInFromAmPm &&
                    checkInUntilAmPm &&
                    moment(checkIn?.from, 'hh:mm A').isSameOrAfter(
                        moment(checkIn?.until, 'hh:mm A')
                    )
                ) {
                    return true;
                }
            }
        } else {
            if (
                checkOut?.from &&
                checkOut?.until &&
                !(checkOut.from == '00:00' && checkOut.until == '00:00')
            ) {
                let checkOutFromAmPm = moment(checkOut?.from, 'HH:mm')
                    .format('hh:mm A')
                    .split(' ')[1];
                let checkOutUntilAmPm = moment(checkOut?.until, 'HH:mm')
                    .format('hh:mm A')
                    .split(' ')[1];
                if (
                    checkOutFromAmPm &&
                    checkOutUntilAmPm &&
                    moment(checkOut?.from, 'hh:mm A').isSameOrAfter(
                        moment(checkOut?.until, 'hh:mm A')
                    )
                ) {
                    return true;
                }
            }
        }
        return false;
    };

    const [form] = Form.useForm();
    return (
        <Modal isOpen={props.isOpen} centered size="lg">
            <Form form={form} name="house-rules-form" className="house-rules">
                <ModalHeader className="title">House Rules</ModalHeader>
                <ModalBody className="body-modal">
                    <Form.Item>
                        <p>What if my house rules change?</p>
                        <div style={{ fontSize: 14 }}>
                            You can easily customise these house rules later and additional house
                            rules can be set on the Policies page of the extranet after you complete
                            registration.
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Row className={`switch-rules${isAllowSmoking ? ' active-rules' : ''}`}>
                            <div>Smoking allowed</div>
                            <Switch
                                defaultChecked={isAllowSmoking}
                                onChange={(checked) => setIsAllowSmoking(checked)}
                            />
                        </Row>
                        <Spacer top={30} />
                        <Row className={`switch-rules${isAllowPets ? ' active-rules' : ''}`}>
                            <div>Pets allowed</div>
                            <Switch
                                defaultChecked={isAllowPets}
                                onChange={(checked) => setIsAllowPets(checked)}
                            />
                        </Row>
                        <Spacer top={30} />
                        <Row className={`switch-rules${isAllowParties ? ' active-rules' : ''}`}>
                            <div>Parties/events allowed</div>
                            <Switch
                                defaultChecked={isAllowParties}
                                onChange={(checked) => setIsAllowParties(checked)}
                            />
                        </Row>
                    </Form.Item>

                    <hr width="100%" />

                    <Form.Item className="check-style">
                        <b>Check in</b>
                        <Spacer top={5} />
                        <Row style={{ margin: 0 }}>
                            <div style={{ marginRight: 24 }}>
                                <div>From</div>
                                <Spacer top={5} />
                                <TimePicker
                                    size="large"
                                    //defaultValue={0}
                                    use12Hours
                                    format="h:mm a"
                                    placeholder="12:00 am"
                                    value={
                                        (checkIn.from == '00:00' && checkIn.until == '00:00') ||
                                        !checkIn.from
                                            ? ''
                                            : moment(checkIn.from, 'h:mm a')
                                    }
                                    style={{
                                        height: '50px',
                                        width: '216px'
                                    }}
                                    onChange={(e, dateS) => {
                                        if (dateS) {
                                            setCheckIn({
                                                from: e.format('HH:mm'),
                                                until: checkIn.until
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <div>Until</div>
                                <Spacer top={5} />
                                <TimePicker
                                    size="large"
                                    //defaultValue={0}
                                    use12Hours
                                    format="h:mm a"
                                    placeholder="12:00 am"
                                    value={
                                        (checkIn.until == '00:00' && checkIn.from == '00:00') ||
                                        !checkIn.until
                                            ? ''
                                            : moment(checkIn.until, 'h:mm a')
                                    }
                                    style={{
                                        height: '50px',
                                        width: '216px'
                                    }}
                                    onChange={(e, dateS) => {
                                        if (dateS) {
                                            setCheckIn({
                                                from: checkIn.from,
                                                until: e.format('HH:mm')
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {showNextDayMessage('checkin') && (
                                <div style={{ marginBottom: '0.75rem' }}>
                                    <div className="time-suggested-tip">
                                        Note: Selected time period has moved into the next day!
                                    </div>
                                </div>
                            )}
                        </Row>
                    </Form.Item>
                    <Form.Item className="check-style">
                        <b>Check out</b>
                        <Spacer top={5} />
                        <Row style={{ margin: 0 }}>
                            <div style={{ marginRight: 24 }}>
                                <div>From</div>
                                <Spacer top={5} />
                                <TimePicker
                                    size="large"
                                    //defaultValue={0}
                                    use12Hours
                                    format="h:mm a"
                                    placeholder="12:00 am"
                                    value={
                                        (checkOut.from == '00:00' && checkOut.until == '00:00') ||
                                        !checkOut.from
                                            ? ''
                                            : moment(checkOut.from, 'h:mm a')
                                    }
                                    style={{
                                        height: '50px',
                                        width: '216px'
                                    }}
                                    onChange={(e, dateS) => {
                                        if (dateS) {
                                            setCheckOut({
                                                from: e.format('HH:mm'),
                                                until: checkOut.until
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div>
                                <div>Until</div>
                                <Spacer top={5} />
                                <TimePicker
                                    size="large"
                                    //defaultValue={0}
                                    use12Hours
                                    format="h:mm a"
                                    placeholder="12:00 am"
                                    value={
                                        (checkOut.from == '00:00' && checkOut.until == '00:00') ||
                                        !checkOut.until
                                            ? ''
                                            : moment(checkOut.until, 'h:mm a')
                                    }
                                    style={{
                                        height: '50px',
                                        width: '216px'
                                    }}
                                    onChange={(e, dateS) => {
                                        if (dateS) {
                                            setCheckOut({
                                                from: checkOut.from,
                                                until: e.format('HH:mm')
                                            });
                                        }
                                    }}
                                />
                            </div>
                            {showNextDayMessage('checkout') && (
                                <div style={{ marginBottom: '0.75rem' }}>
                                    <div className="time-suggested-tip">
                                        Note: Selected time period has moved into the next day!
                                    </div>
                                </div>
                            )}
                        </Row>
                    </Form.Item>
                </ModalBody>
                <ModalFooter>
                    <div className="footer-btn">
                        <div>
                            <Button color="primary" onClick={onFinish}>
                                {selected_existing_product ? 'Update' : 'Save'}
                            </Button>
                            <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark" style={{ width: '137px' }}>
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </ModalFooter>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    onSave();
                    setConfirmationModal(false);
                }}
            />
        </Modal>
    );
}

HouseRules.propTypes = {
    data: Proptypes.any,
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(HouseRules));

/* eslint-disable no-useless-escape */
/* eslint-disable no-control-regex */
/* eslint-disable react/no-unescaped-entities */
import { Button, Form, Input, Upload, Checkbox } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import { connect } from 'react-redux';
import logo from 'assets/images/logos/brand.svg';
import Pic from 'assets/images/pic.svg';
import { Link, withRouter } from 'react-router-dom';
import SupplierAgreement from 'components/molecules/supplier-agreement';
import Application from 'components/organisms/homepage/application';
import Welcomeimg from 'assets/images/calendar-logo.png';
import { Container, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { loginUser, getCurretUser, vendorOnboard } from 'store/actions/AuthActions';
import { fileUpload, clearUploadedFile, fetchLegalContent } from 'store/actions/contentActions';
import qs from 'querystring';
import { PlusCircleOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';
import { API_URL } from 'utils/constants';
import ImagePreviewModal from 'components/molecules/modals/image-preview';
import { validatePasswordSignin } from 'utils/validation';
import { PhoneCountryCode } from 'components/atoms';
import { Loader } from 'components/atoms';

class Signin extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            isShowingApplicationModal: false,
            setmodalIsOpen: false,
            base64Url: null,
            uploadedFile: null,
            firstName: '',
            jobTitle: '',
            customerServicePhoneNumber: '',
            customerServiceEmailAddress: '',
            isChecked: false,
            isOpen: false,
            progressImageUpload: 0,
            validateError: {},
            loginProcessing: false,
            fileList: [],
            isPreviewImageOpen: false,
            previewImage: null,
            selectCode: '',
            isSelectCode: false
        };
    }
    handleChange = (e) => {
        const { formValues } = this.state;
        let { validations } = this.state;
        const { name, value } = e.target;
        formValues[[name]] = value;
        this.setState({ formValues, validations });
    };
    openSupplierModal = (e) => {
        e.preventDefault();
        this.setState({ isOpen: true });
    };
    closeParent = () => {
        this.setState({ isOpen: false });
    };
    openModal = (e) => {
        e.preventDefault();
        const { modalIsOpen } = this.state;
        this.setState({ modalIsOpen: !modalIsOpen });
    };
    componentDidMount() {
        const { fetchLegalContent, my_profile } = this.props;

        this.props.getCurretUser();

        fetchLegalContent();

        if (my_profile?.photo_url) {
            this.props.history.push('/home');
        }
    }
    componentDidUpdate() {
        const { auth, my_profile } = this.props;
        let { modalIsOpen } = this.state;
        if (auth?.token) {
            if (my_profile?.photo_url) {
                this.props.history.push('/home');
            }
            if (my_profile && !modalIsOpen) {
                this.setState({ modalIsOpen: true });
            }
        }

        // Update Effect moved up from render function

        if (this.props?.auth?.token && this.props?.path_value) {
            if (this.props.my_profile?.photo_url) {
                this.props.history.push('/home');
            }
        } else {
            if (this.state.loginProcessing) {
                this.setState({ loginProcessing: false });
            }
        }
    }
    closeModal = () => {
        //  setmodalIsOpen(!modalIsOpen)
        const { modalIsOpen } = this.state;
        this.setState({ setmodalIsOpen: !modalIsOpen });
    };
    handleFinish = () => {
        this.setState({ loginProcessing: true });
        const data = qs.stringify(this.formRef.current.getFieldsValue());
        this.props.loginUserFn(data);
    };
    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function () {};
    }
    onChangeImage = (info) => {
        if (info.file.status === 'done') {
            this.setState({ uploadedFile: { url: info.file.response.data.url } }, () => {
                this.handleUpdateUser();
            });
        } else if (info.file.status === 'removed') {
            this.setState({ uploadedFile: null }, () => {
                this.handleUpdateUser();
            });
        }
        let fileList = [...info.fileList];
        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        fileList = fileList.slice(-2);
        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.data.url;
            }
            return file;
        });
        this.setState({ fileList });
    };
    onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    onChange = (e) => {
        let self = this;
        let file = e.target.files[0];
        let form_data = new FormData();
        form_data.append('media', file);
        this.getBase64(file, (result) => {
            self.setState(
                { uploadedFile: null, base64Url: { url: result, name: file.name } },
                () => {
                    this.props.fileUpload(form_data);
                    // this.handleUpdateUser();
                }
            );
        });
    };
    toggleWelcomeModal = () => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen });
    };
    validateEmail = (value) => {
        const validRegex =
            /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

        return validRegex.test(value);
    };
    handleUpdateUser = () => {
        let { validateError } = this.state;
        validateError = {};
        if (!this.state.uploadedFile) {
            validateError['user_avatar'] = 'please upload user avatar.';
        }
        if (!this.state.firstName.length) {
            validateError['first_name'] = 'please enter nick name.';
        }
        if (!this.state.jobTitle.length) {
            validateError['job_title'] = 'please enter job title.';
        }
        if (!this.state.customerServicePhoneNumber.length) {
            validateError['customer_service_phone_number'] =
                'Please enter customer service phone number';
        }
        if (this.state.customerServicePhoneNumber && isNaN(this.state.customerServicePhoneNumber)) {
            validateError['customer_service_phone_number'] = 'Please enter only digits';
        }
        if (
            this.state.customerServicePhoneNumber &&
            this.state.customerServicePhoneNumber.length < 10
        ) {
            validateError['customer_service_phone_number'] = 'Min. length is 10 digits';
        }
        if (
            this.state.customerServicePhoneNumber &&
            this.state.customerServicePhoneNumber.length > 11
        ) {
            validateError['customer_service_phone_number'] = 'Max. length is 11 digits';
        }
        if (!this.validateEmail(this.state.customerServiceEmailAddress)) {
            validateError['customer_service_email'] = 'Please enter customer service email address';
        }
        if (!this.state.isChecked) {
            validateError['accept_terms'] = 'please checked the terms.';
        }
        this.setState({ validateError });
    };
    submitUpdateUser = () => {
        this.handleUpdateUser();

        if (!Object.keys(this.state.validateError).length) {
            let data = {
                photo_url: this.state.uploadedFile?.url,
                nick_name: this.state.firstName,
                accept_terms: this.state.isChecked,
                job_title: this.state.jobTitle,
                customer_service_email: this.state.customerServiceEmailAddress,
                country_code: this.state.selectCode,
                customer_service_phone_number: this.state.customerServicePhoneNumber
            };
            this.props.vendorOnboard(data);
        }
    };
    setPreviewImage = (file) => {
        this.setState({
            isPreviewImageOpen: true,
            previewImage: file
        });
    };
    togglePhone = () => {
        this.setState({
            isSelectCode: !this.state.isSelectCode
        });
    };
    render() {
        let {
            modalIsOpen,
            isShowingApplicationModal,
            validateError,
            firstName,
            jobTitle,
            customerServiceEmailAddress,
            isOpen,
            fileList
        } = this.state;
        let props = this.props;
        const authToken = localStorage.getItem('token');
        const uploadPhoto = {
            name: 'media',
            action: `${API_URL}/media-library`,
            headers: {
                authorization: `Bearer ${authToken}`
            },

            onChange: this.handleImageChange
        };

        if (props?.auth?.authLoading) return <Loader />;

        return (
            <>
                {/* Apply Now Drawer Component */}
                <Application
                    onClose={() => {
                        this.setState({ isShowingApplicationModal: false });
                    }}
                    show={isShowingApplicationModal}
                />
                <ImagePreviewModal
                    isModalOpen={this.state.isPreviewImageOpen}
                    setModalOpenState={() =>
                        this.setState({ isPreviewImageOpen: !this.state.isPreviewImageOpen })
                    }
                    image={this.state.previewImage}
                />
                {/* Wrapper */}
                <div
                    className={`signin ${
                        props?.authLoading ||
                        (!this.props.my_profile?.photo_url && props?.auth?.token)
                            ? 'd-none'
                            : ''
                    }`}>
                    {/* Custom Container for login, forgot password and create password */}
                    <Container className="container-login">
                        {/* White Box Starts */}
                        <div className="form_box">
                            <div className="form_box--inner">
                                <img className="logo" src={logo} alt="" />
                                <Form
                                    ref={this.formRef}
                                    onFinish={this.handleFinish}
                                    onValuesChange={() => this.forceUpdate()}
                                    className="form"
                                    hideRequiredMark>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'The input is not valid E-mail'
                                            },
                                            {
                                                required: true,
                                                message: 'Please enter email address'
                                            }
                                        ]}>
                                        <Input size="large" placeholder="Email Address" />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                validator: (rule, value) =>
                                                    value.length
                                                        ? validatePasswordSignin(rule, value)
                                                        : Promise.reject(
                                                              `Please enter your password`
                                                          )
                                            }
                                        ]}>
                                        <Input.Password
                                            size="large"
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    <div className="form-btn-wrapper">
                                        <Button
                                            type="primary"
                                            size="large"
                                            className="btn-block"
                                            htmlType="submit"
                                            loading={this.state.loginProcessing}>
                                            Next
                                        </Button>
                                        {props.auth &&
                                        Array.isArray(props.auth) &&
                                        props.auth.length
                                            ? props.auth.map((item, i) => (
                                                  <span
                                                      key={i}
                                                      className="invalid-feedback d-block">
                                                      {item.message}
                                                  </span>
                                              ))
                                            : null}
                                    </div>
                                </Form>
                                <div className="mt-17px text-left">
                                    <Link to="/forgot-password">Forgot Password</Link>
                                </div>
                                <div className="form-help">
                                    <span>Don't have a supplier account??</span>
                                    <Button
                                        size="sm"
                                        onClick={() => {
                                            this.setState({ isShowingApplicationModal: true });
                                        }}
                                        type="link"
                                        className="py-0 text-nowrap font-weight-normal">
                                        Apply now
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {/* White Box Ends */}
                    </Container>
                </div>
                {/* Welcome on board modal */}
                <Modal
                    isOpen={modalIsOpen}
                    size="lg"
                    // toggle={this.toggleWelcomeModal}
                    scrollable
                    centered
                    backdropClassName="opaque">
                    <ModalHeader>Welcome to Customer Calendar Manager</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col xs={{ size: 12, order: 2 }} className="col-lg" lg={{ order: 1 }}>
                                <div className="text-black" style={{ maxWidth: '550px' }}>
                                    <p className="mb-0">Congratulations</p>
                                    <p className="mb-0">
                                        Our team has succesfully reviewed your application and are
                                        happy to have you onboard.
                                    </p>
                                    <p>
                                        To complete your account setup, select your company name on
                                        the top right hand corner of the page when you get to your
                                        dashboard.
                                    </p>
                                </div>
                                <div className="d-flex">
                                    <div className="mr-4">
                                        <p className="fw-600">
                                            Your customers will like to know you.
                                            <br /> Please add your photo.
                                        </p>
                                        {/* <ImageUploaderComponent onChange={this.onChange} />
                    {validateError?.user_avatar?.length && <span className="invalid-feedback d-block">{validateError?.user_avatar}</span>} */}
                                        <ImgCrop rotate>
                                            <Upload
                                                {...uploadPhoto}
                                                listType="picture-card"
                                                fileList={fileList}
                                                maxCount={1}
                                                onPreview={(file) => {
                                                    this.setPreviewImage(file);
                                                }}
                                                onChange={this.onChangeImage}
                                                className="type-profile">
                                                <div className="img-uploader">
                                                    <div className="d-flex align-items-center justify-content-center cursor-pointer">
                                                        <PlusCircleOutlined color="#A2792C" />
                                                        <p className="mb-0 ml-3 fw-600 fs-14">
                                                            {`${
                                                                this.state.uploadedFile
                                                                    ? 'Change'
                                                                    : 'Add'
                                                            } Photo`}
                                                        </p>
                                                    </div>
                                                </div>
                                            </Upload>
                                        </ImgCrop>
                                        {validateError?.user_avatar?.length && (
                                            <span className="invalid-feedback d-block">
                                                {validateError?.user_avatar}
                                            </span>
                                        )}
                                    </div>
                                    <div className="text-center">
                                        <img alt="" className="bottom" src={Pic} />
                                        <p className="fs-14">Example Photo</p>
                                    </div>
                                </div>
                                <div className="mt-4 mb-4">
                                    <div>
                                        <h6 style={{ lineHeight: 2 }}>
                                            How would you like us to use in introducing you to
                                            customers?
                                        </h6>
                                        <p className="text-small text-primary">
                                            Customers find your local name that represents your
                                            region more appealing.
                                        </p>
                                    </div>
                                    <Row>
                                        <Col md={5}>
                                            <div className="form-group">
                                                <input
                                                    className="form-control form-control-lg form-control-standout"
                                                    value={this.state.firstName}
                                                    onChange={(e) => {
                                                        if (isNaN(e.target.value || firstName)) {
                                                            this.setState(
                                                                { firstName: e.target.value },
                                                                () => {
                                                                    this.handleUpdateUser();
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    placeholder="Enter nick name"
                                                />
                                                {validateError?.first_name?.length && (
                                                    <span className="invalid-feedback d-block">
                                                        {validateError?.first_name}
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                        <Col md={7}>
                                            <div className="form-group">
                                                <input
                                                    className="form-control form-control-lg form-control-standout"
                                                    value={this.state.jobTitle}
                                                    onChange={(e) => {
                                                        if (isNaN(e.target.value || jobTitle)) {
                                                            this.setState(
                                                                { jobTitle: e.target.value },
                                                                () => {
                                                                    this.handleUpdateUser();
                                                                }
                                                            );
                                                        }
                                                    }}
                                                    placeholder="Enter job title"
                                                />
                                                {validateError?.job_title?.length && (
                                                    <span className="invalid-feedback d-block">
                                                        {validateError?.job_title}
                                                    </span>
                                                )}
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className="mt-3 mb-3">
                                        <div>
                                            <h6 className="bold" style={{ lineHeight: 2 }}>
                                                How can customers reach you?
                                            </h6>
                                        </div>
                                        <Row>
                                            <Col md={6}>
                                                <div className="form-group">
                                                    <label
                                                        className="text-primary"
                                                        htmlFor="customer-service-number">
                                                        Customer Service Phone number
                                                    </label>
                                                    <PhoneCountryCode
                                                        phoneNumberFieldName="customer_service_phone_number"
                                                        placeHolder="Customer Service Contact number*"
                                                        selectedCountryCode={this.state.selectCode}
                                                        onChangeCountryCode={(code) => {
                                                            this.setState({
                                                                selectCode: code
                                                            });
                                                        }}
                                                        expandMenu={this.state.isSelectCode}
                                                        toggleMenu={this.togglePhone}
                                                        phoneNumber={
                                                            this.state.customerServicePhoneNumber
                                                        }
                                                        onChangePhoneNumber={(number) => {
                                                            this.setState(
                                                                {
                                                                    customerServicePhoneNumber:
                                                                        number
                                                                },
                                                                () => {
                                                                    this.handleUpdateUser();
                                                                }
                                                            );
                                                        }}
                                                    />

                                                    {validateError?.customer_service_phone_number
                                                        ?.length && (
                                                        <span className="invalid-feedback d-block error__number">
                                                            {
                                                                validateError?.customer_service_phone_number
                                                            }
                                                        </span>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={6}>
                                                <div className="form-group">
                                                    <label
                                                        className="text-primary"
                                                        htmlFor="customer-service-email">
                                                        Customer Service Email Address
                                                    </label>
                                                    <input
                                                        id="customer-service-email"
                                                        className="form-control form-control-lg form-control-standout"
                                                        value={
                                                            this.state.customerServiceEmailAddress
                                                        }
                                                        onChange={(e) => {
                                                            if (
                                                                isNaN(
                                                                    e.target.value ||
                                                                        customerServiceEmailAddress
                                                                )
                                                            ) {
                                                                this.setState(
                                                                    {
                                                                        customerServiceEmailAddress:
                                                                            e.target.value
                                                                    },
                                                                    () => {
                                                                        this.handleUpdateUser();
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                        placeholder=""
                                                    />
                                                    {validateError?.customer_service_email
                                                        ?.length && (
                                                        <span className="invalid-feedback d-block">
                                                            {validateError?.customer_service_email}
                                                        </span>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div className="mb-3">
                                        <div className="d-flex">
                                            <Checkbox
                                                className="d-flex align-items-center"
                                                onChange={(e) => {
                                                    this.setState(
                                                        { isChecked: e.target.checked },
                                                        () => {
                                                            this.handleUpdateUser();
                                                        }
                                                    );
                                                }}>
                                                I accept Customer Calendar Manager's&nbsp;
                                                <Button
                                                    type="link"
                                                    onClick={this.openSupplierModal}
                                                    className="p-0 text-nowrap font-weight-normal">
                                                    suppliers agreement
                                                </Button>
                                            </Checkbox>
                                        </div>
                                        {validateError?.accept_terms?.length && (
                                            <span className="invalid-feedback d-block">
                                                {validateError?.accept_terms}
                                            </span>
                                        )}
                                    </div>
                                    <Button
                                        onClick={this.submitUpdateUser}
                                        type="primary"
                                        size="large"
                                        className="btn-mw btn-px">
                                        Go to Dashboard
                                    </Button>
                                </div>
                            </Col>
                            <Col xs={{ size: 12, order: 1 }} lg={{ size: 'auto', order: 2 }}>
                                <div className="calendar-img-wrapper mb-3">
                                    <img className="img-fluid" src={Welcomeimg} alt="" />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
                {/* Supplier Agreement Dialog Component */}
                <SupplierAgreement
                    data={props?.legal_content?.supplierAgreement}
                    parentState={isOpen}
                    closeParent={this.closeParent}
                />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth.auth,
    my_profile: state.auth.my_profile,
    path_value: state.auth.path_value,
    legal_content: state.contentDetail.legal_data,
    uploaded_file: state.contentDetail.uploaded_file
});

const mapDispatch = (dispatch) => ({
    loginUserFn: (user) => dispatch(loginUser(user)),
    getCurretUser: () => dispatch(getCurretUser()),
    vendorOnboard: (data) => dispatch(vendorOnboard(data)),
    fileUpload: (data) => dispatch(fileUpload(data)),
    clearUploadedFile: () => dispatch(clearUploadedFile()),
    fetchLegalContent: () => dispatch(fetchLegalContent())
});

Signin.propTypes = {
    auth: PropTypes.object,
    fetchLegalContent: PropTypes.func,
    my_profile: PropTypes.object,
    getCurretUser: PropTypes.func,
    history: PropTypes.object,
    loginUserFn: PropTypes.func,
    fileUpload: PropTypes.func,
    vendorOnboard: PropTypes.func,
    path_value: PropTypes.string,
    legal_content: PropTypes.object,
    authLoading: PropTypes.any
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Signin));

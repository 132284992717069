import React from 'react';
import { ProductInfo } from 'components/atoms';
import { Drawer } from 'antd';
import { Button } from 'reactstrap';
import { Feedback } from 'components/molecules';
import PropTypes from 'prop-types';

export default function ProductInfoDrawer(props) {
    const { setState, state, get_all_calendar_products, checkPaymentInf } = props;

    function calculateProductPriceType(price) {
        if (Array.isArray(price) && price.length > 0) {
            if (Array.isArray(price[0].age_groups) && price[0].age_groups.length > 0) {
                return `For 1 ${price[0].age_groups[0].type}`;
            } else if (price[0].per_person_sharing) {
                return `For per person sharing(Double room)`;
            } else {
                return '';
            }
        } else return '';
    }

    function calculateProductPrice(price) {
        if (Array.isArray(price) && price.length > 0) {
            if (Array.isArray(price[0].age_groups) && price[0].age_groups.length > 0) {
                return `$${price[0].age_groups[0].amount || 0}`;
            } else if (price[0].per_person_sharing) {
                return `$${price[0].per_person_sharing?.double_room?.amount || 0}`;
            } else {
                return '$0';
            }
        } else return '$0';
    }

    function calculateTotalProductPrice(products) {
        if (Array.isArray(products)) {
            let total = 0;
            for (let prod of products) {
                let price =
                    prod.productType === 'main_product'
                        ? checkPaymentInf
                            ? prod?.pricings
                            : undefined
                        : prod?.pricings;

                if (Array.isArray(price) && price.length > 0) {
                    if (Array.isArray(price[0].age_groups) && price[0].age_groups.length > 0) {
                        total = total + price[0].age_groups[0].amount;
                    } else if (price[0].per_person_sharing) {
                        total = total + price[0].per_person_sharing?.double_room?.amount;
                    }
                }
            }
            return `$${total}`;
        }
        return `$0`;
    }

    let main_product =
        Array.isArray(get_all_calendar_products) &&
        get_all_calendar_products.find((value) => value?.productType === 'main_product');

    return (
        <Drawer
            width={609}
            placement="right"
            onClose={() => setState({ toggleProductInfoDrawer: false })}
            visible={state.toggleProductInfoDrawer}
            className="drawers-padding"
            bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}
            style={{ zIndex: 1060 }}>
            <h4 className="mt-2 productDetailsTitle">Products Information</h4>
            <span style={{ fontWeight: 500, color: '#A2792C', fontSize: 18 }}>
                Details <span className="small text-gray">(Raw price estimation) </span>
            </span>

            <div className="flex-grow-1 overflow-y-auto scrollbar-fixed px-3 mx-n3">
                {main_product && (
                    <ProductInfo
                        bgcolor="#F0F2FF"
                        title={main_product.title}
                        subtitle={calculateProductPriceType(main_product?.pricings)}
                        pricebgcolor="#fff"
                        fontcolor="#4F4F4F"
                        pricefontcolor="black"
                        fontweight="500"
                        pricefontweight="600"
                        titlefontsize="12px"
                        pricefontsize="12px"
                        price={
                            checkPaymentInf
                                ? calculateProductPrice(main_product?.pricings)
                                : 'Price Disabled'
                        }
                        mainproduct={true}
                    />
                )}

                {/* getting all product that are in users account using map function */}
                {Array.isArray(get_all_calendar_products) &&
                    get_all_calendar_products.map((product, index) => {
                        return product?.productType !== 'main_product' ? (
                            <ProductInfo
                                bgcolor="#F0F2FF"
                                title={product.title}
                                subtitle={calculateProductPriceType(product?.pricings)}
                                pricebgcolor="#fff"
                                fontcolor="#4F4F4F"
                                pricefontcolor="black"
                                fontweight="500"
                                pricefontweight="600"
                                titlefontsize="12px"
                                pricefontsize="12px"
                                price={calculateProductPrice(product?.pricings)}
                                key={index}
                                mainproduct="false"
                            />
                        ) : null;
                    })}

                <ProductInfo
                    bgcolor="#333"
                    title=""
                    subtitle="REVENUE PROJECTION"
                    pricebgcolor="white"
                    fontcolor="white"
                    pricefontcolor="black"
                    fontweight="700"
                    pricefontweight="700"
                    titlefontsize="15px"
                    pricefontsize="15px"
                    price={
                        Array.isArray(get_all_calendar_products) &&
                        calculateTotalProductPrice(get_all_calendar_products)
                    }
                    mainproduct="false"
                />

                {/* <div className="d-flex justify-content-between ">
        <Button
            className="send-footer-btn px-5 d-none d-sm-block left-button"
            size="large"
            onClick={() => setState({ toggleProductInfoDrawer: false })}>
            Close
    </Button>
        <div className=" pl-2 d-none d-sm-block right-button">
            <HelpButton />
        </div>
    </div> */}
            </div>
            <div className="d-flex flex-wrap justify-content-between pt-4">
                <Button
                    color="dark"
                    className="btn-mw flex-grow-1 flex-lg-grow-0"
                    type="button"
                    onClick={() => setState({ toggleProductInfoDrawer: false })}>
                    Close
                </Button>
                <Feedback
                    drawerPlacement="right"
                    buttonClassName="ml-lg-auto btn-block btn-lg-inline-block"
                />
            </div>
        </Drawer>
    );
}

ProductInfoDrawer.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    get_all_calendar_products: PropTypes.array,
    checkPaymentInf: PropTypes.bool
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';

const pencilIcon = (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.4121" cy="16.8867" r="16" fill="white" />
        <path
            d="M8.24614 19.9682L7.12022 24.8274C7.08138 25.0051 7.08271 25.1891 7.12411 25.3662C7.16552 25.5433 7.24596 25.7088 7.35954 25.8508C7.47313 25.9928 7.617 26.1076 7.78065 26.1869C7.94429 26.2661 8.12358 26.3078 8.3054 26.3089C8.39013 26.3175 8.47549 26.3175 8.56022 26.3089L13.4491 25.183L22.8358 15.8319L17.5973 10.6052L8.24614 19.9682Z"
            fill="#A2792C"
        />
        <path
            d="M25.7868 11.1504L22.2905 7.65409C22.0607 7.42538 21.7496 7.297 21.4253 7.297C21.1011 7.297 20.79 7.42538 20.5602 7.65409L18.6165 9.59779L23.849 14.8304L25.7928 12.8867C25.9065 12.7724 25.9966 12.6367 26.0579 12.4875C26.1191 12.3384 26.1504 12.1786 26.1498 12.0173C26.1493 11.856 26.1169 11.6965 26.0547 11.5477C25.9924 11.3989 25.9013 11.2639 25.7868 11.1504Z"
            fill="#A2792C"
        />
    </svg>
);

const DetailedPanel = (props) => {
    const { panelIcon, panelTitle, isEditable, onEdit } = props;
    return (
        <div className="detailed-panel bg-warm border border-secondary rounded p-3 mb-20px">
            <div className="d-flex flex-nowrap pr-md-3">
                {panelIcon ? (
                    <div className="mr-2">
                        <span className="border-icon border-icon--lg border-icon--primary rounded-circle">
                            {panelIcon}
                        </span>
                    </div>
                ) : null}
                <div className="flex-grow-1 overflow-hidden">
                    <div className="d-flex flex-nowrap">
                        <h5 className="text-uppercase text-primary fs-24 text-truncate mt-md-1 mr-2 flex-grow-1">
                            {panelTitle}
                        </h5>

                        {isEditable ? (
                            <div>
                                <span role="button" tabIndex="0" title="Edit" onClick={onEdit}>
                                    {pencilIcon}
                                </span>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="detailed-panel-body">{props.children}</div>
        </div>
    );
};

DetailedPanel.propTypes = {
    panelIcon: PropTypes.object,
    panelTitle: PropTypes.string,
    isEditable: PropTypes.bool,
    children: PropTypes.node,
    onEdit: PropTypes.func
};

export default DetailedPanel;

import moment from 'moment';
let eventGuid = 0;

export const isNightLifeProduct = (product, calendar) => {
    const bookingPeriods = product?.booking_time_periods;
    if (bookingPeriods?.time_period) {
        let timePeriodFrom = getMomentFromTimeString(bookingPeriods.time_period.from);
        let timePeriodTo = getMomentFromTimeString(bookingPeriods.time_period.to);
        if (timePeriodTo.isBefore(timePeriodFrom)) {
            return true;
        }
    }

    if (bookingPeriods?.within_time_periods) {
        for (let timePeriod of bookingPeriods.within_time_periods) {
            let timePeriodFrom = getMomentFromTimeString(timePeriod.from);
            let timePeriodTo = getMomentFromTimeString(timePeriod.to);
            if (timePeriodTo.isBefore(timePeriodFrom)) {
                return true;
            }
        }
    }

    if (!calendar) return false;

    // check resting time overlap
    const restTimeStart = getMomentFromTimeString(calendar.rest_time_start);
    const restTimeEnd = getMomentFromTimeString(calendar.rest_time_end);
    let morningRestTimeStart;
    let morningRestTimeEnd;
    let eveningRestTimeStart;
    let eveningRestTimeEnd;

    // If resting time is overnight then check for two time intervals
    if (restTimeEnd.isBefore(restTimeStart)) {
        morningRestTimeStart = moment('00:00', 'HH:mm');
        morningRestTimeEnd = restTimeEnd;

        eveningRestTimeStart = restTimeStart;
        eveningRestTimeEnd = moment('23:59', 'HH:mm');
    }
    let timePeriod;
    var from;
    var to;
    switch (bookingPeriods?.type) {
        case TimeType.Both:
        case TimeType.Specific_Time:
            //  check blocked
            from = getMomentFromTimeString(bookingPeriods.time_period.from);
            to = getMomentFromTimeString(bookingPeriods.time_period.to);

            if (!morningRestTimeStart) {
                if (checkTimePeriodOverlap(from, to, restTimeStart, restTimeEnd)) {
                    return true;
                }
            } else {
                if (checkTimePeriodOverlap(from, to, morningRestTimeStart, morningRestTimeEnd)) {
                    return true;
                }

                if (checkTimePeriodOverlap(from, to, eveningRestTimeStart, eveningRestTimeEnd)) {
                    return true;
                }
            }

            break;

        case TimeType.Within_Time_Period:
            timePeriod = bookingPeriods.within_time_periods[0];
            from = getMomentFromTimeString(timePeriod.from);
            to = getMomentFromTimeString(timePeriod.to);

            if (!morningRestTimeStart) {
                if (checkTimePeriodOverlap(from, to, restTimeStart, restTimeEnd)) {
                    return true;
                }
            } else {
                if (checkTimePeriodOverlap(from, to, morningRestTimeStart, morningRestTimeEnd)) {
                    return true;
                }

                if (checkTimePeriodOverlap(from, to, eveningRestTimeStart, eveningRestTimeEnd)) {
                    return true;
                }
            }

            break;

        default:
            break;
    }

    return false;
};

export const getMomentFromTimeString = (time) => {
    const modifier = time.split(' ')[1];
    if (modifier) {
        time = get24HourFormatTime(time);
    }

    let momentObject = moment(time, 'HH:mm');

    return momentObject;
};

export function createEventId() {
    return String(eventGuid++);
}

const getDayString = (day) => {
    //  Date(new Date(Date.now()).setDate(new Date(Date.now()).getDate() + day - 1))

    return moment()
        .add(day - 1, 'days')
        .format('YYYY-MM-DD');
};

export const getEventsByProducts = (allProducts = [], calendar) => {
    try {
        if (allProducts && calendar && calendar != null) {
            let days = {};

            // Add resting time
            for (let item of calendar.days) {
                let restTimeStart = getMomentFromTimeString(calendar?.rest_time_start);
                let restTimeEnd = getMomentFromTimeString(calendar?.rest_time_end);
                let morningRestTimeStart;
                let morningRestTimeEnd;
                let eveningRestTimeStart;
                let eveningRestTimeEnd;

                // If resting time is overnight then check for two time intervals
                if (restTimeEnd.isBefore(restTimeStart)) {
                    morningRestTimeStart = moment('00:00', 'HH:mm');
                    morningRestTimeEnd = restTimeEnd;

                    eveningRestTimeStart = restTimeStart;
                    eveningRestTimeEnd = moment('23:59', 'HH:mm');
                }

                if (eveningRestTimeStart) {
                    // If it's the last day
                    if (calendar?.days?.length === item.day) {
                        days[item.day] = [
                            {
                                id: createEventId(),
                                title: 'Resting time',
                                event_type: 'resting_time',
                                event_duration_type: 'start',
                                start:
                                    getDayString(item.day) +
                                    `T${morningRestTimeStart.format('HH:mm')}:00`,
                                end:
                                    getDayString(item.day) +
                                    `T${morningRestTimeEnd.format('HH:mm')}:00`
                            },
                            {
                                id: createEventId(),
                                title: 'Resting time',
                                event_type: 'resting_time',
                                event_duration_type: 'last-day',
                                start:
                                    getDayString(item.day) +
                                    `T${eveningRestTimeStart.format('HH:mm')}:00`,
                                end:
                                    getDayString(item.day) +
                                    `T${eveningRestTimeEnd.format('HH:mm')}:00`
                            }
                        ];
                    } else {
                        days[item.day] = [
                            {
                                id: createEventId(),
                                title: 'Resting time',
                                event_type: 'resting_time',
                                event_duration_type: 'start',
                                start:
                                    getDayString(item.day) +
                                    `T${morningRestTimeStart.format('HH:mm')}:00`,
                                end:
                                    getDayString(item.day) +
                                    `T${morningRestTimeEnd.format('HH:mm')}:00`
                            },
                            {
                                id: createEventId(),
                                title: 'Resting time',
                                event_type: 'resting_time',
                                event_duration_type: 'end',
                                start:
                                    getDayString(item.day) +
                                    `T${eveningRestTimeStart.format('HH:mm')}:00`,
                                end:
                                    getDayString(item.day) +
                                    `T${eveningRestTimeEnd.format('HH:mm')}:00`
                            }
                        ];
                    }
                } else {
                    days[item.day] = [
                        {
                            id: createEventId(),
                            title: 'Resting time',
                            event_type: 'resting_time',
                            event_duration_type: 'only_start',
                            start: getDayString(item.day) + `T${restTimeStart.format('HH:mm')}:00`,
                            end: getDayString(item.day) + `T${restTimeEnd.format('HH:mm')}:00`
                        }
                    ];
                }
            }

            const sleepDays = { ...days };

            // Don't display products that don't have booking and days and are full-day products
            allProducts = allProducts.filter(
                (item) =>
                    item.booking_time_periods != null &&
                    item.calendar_days != null &&
                    item.suggestedDay != null &&
                    item.day_type == 'within_day' &&
                    !isNightLifeProduct(item, calendar)
            );
            // Seperate products by days
            for (let product of allProducts) {
                days[product.suggestedDay] = [...days[product.suggestedDay], product];
            }
            days = Object.keys(days).map((key) => days[key]);

            let allEvents = [];
            for (let j = 0; j < days.length; j++) {
                let day = days[j];
                let suggested = [];
                let used = [];
                for (let dayProduct of day) {
                    if (dayProduct.booking_time_periods) {
                        let isOverlap = false;
                        // Check overlap of selected product with all other products
                        for (let suggestedProduct of suggested) {
                            let { start: suggestedProductFrom, end: suggestedProductTo } =
                                getMoment(suggestedProduct);
                            let { start: dayProductFrom, end: dayProductTo } =
                                getMoment(dayProduct);

                            if (
                                dayProductFrom.isBefore(suggestedProductTo) &&
                                suggestedProductFrom.isBefore(dayProductTo)
                            ) {
                                isOverlap = true;
                            }
                        }

                        if (
                            calendar?.days &&
                            calendar?.days[j]?.selected_products.indexOf(dayProduct?.id) !== -1 &&
                            !isOverlap
                        ) {
                            suggested.push(dayProduct);
                        } else {
                            used.push(dayProduct);
                        }
                    }
                }
                for (let i = 0; i < suggested.length; i++) {
                    suggested[i].moreProducts = [];
                }

                for (let usedProduct of used) {
                    let overlapIndex = null;
                    for (let i = 0; i < suggested.length; i++) {
                        let suggestedProduct = suggested[i];
                        let { start: suggestedProductFrom, end: suggestedProductTo } =
                            getMoment(suggestedProduct);
                        let { start: usedProductFrom, end: usedProductTo } = getMoment(usedProduct);

                        if (
                            usedProductFrom.isBefore(suggestedProductTo) &&
                            suggestedProductFrom.isBefore(usedProductTo)
                        ) {
                            overlapIndex = i;
                        }
                    }

                    if (overlapIndex === null) {
                        suggested.push({ ...usedProduct, moreProducts: [] });
                    } else {
                        suggested[overlapIndex] = {
                            ...suggested[overlapIndex],
                            moreProducts: suggested[overlapIndex].moreProducts
                                ? [...suggested[overlapIndex].moreProducts, usedProduct]
                                : [usedProduct]
                        };
                    }
                }
                // Turn suggested products to events
                const freeTimeEvents = getFreeTimeEvents(
                    [...sleepDays[1], ...suggested],
                    j + 1,
                    calendar
                );
                allEvents = [...allEvents, ...freeTimeEvents];

                for (let suggestedProduct of suggested) {
                    let suggestedProductFrom, suggestedProductTo;
                    if (suggestedProduct.booking_time_periods.time_period) {
                        suggestedProductFrom = moment(
                            get24HourFormatTime(
                                suggestedProduct.booking_time_periods.time_period.from
                            ),
                            'HH:mm'
                        );
                        suggestedProductTo = moment(
                            get24HourFormatTime(
                                suggestedProduct.booking_time_periods.time_period.to
                            ),
                            'HH:mm'
                        );
                    } else {
                        suggestedProductFrom = moment(
                            get24HourFormatTime(
                                suggestedProduct.booking_time_periods.within_time_periods[0].from
                            ),
                            'HH:mm'
                        );
                        suggestedProductTo = moment(
                            get24HourFormatTime(
                                suggestedProduct.booking_time_periods.within_time_periods[0].from
                            ),
                            'HH:mm'
                        ).add(suggestedProduct.booking_time_periods.duration, 'minutes');
                    }

                    allEvents.push({
                        event_type: 'main_event',
                        event_duration_type: 'start',
                        extendedProps: {
                            ...suggestedProduct
                        },
                        start:
                            getDayString(suggestedProduct.suggestedDay) +
                            `T${suggestedProductFrom.format('HH:mm')}:00`,
                        end:
                            getDayString(suggestedProduct.suggestedDay) +
                            `T${suggestedProductTo.format('HH:mm')}:00`
                    });
                }
            }

            // Return the events
            let dayEvents = Object.keys(sleepDays).map((key) => sleepDays[key]);
            for (let item of dayEvents) {
                allEvents = [...allEvents, ...item];
            }
            let clashingDays = {};
            for (let event of allEvents) {
                if (clashingDays[event.start.split('T')[0]]) {
                    clashingDays[event.start.split('T')[0]] = [
                        ...clashingDays[event.start.split('T')[0]],
                        event
                    ];
                } else {
                    clashingDays[event.start.split('T')[0]] = [event];
                }
            }

            allEvents = modifyEventsToPreventOverlap(clashingDays);
            return allEvents;
        }
        return [];
    } catch (error) {
        console.log(error);
        return [];
    }
};

const get24HourFormatTime = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    if (hours === '12') {
        hours = '00';
    }

    if (modifier === 'PM') {
        hours = String(parseInt(hours, 10) + 12);
    }

    return `${hours}:${minutes}`;
};

const getFreeTimeEvents = (suggestedProducts, day, calendar) => {
    try {
        let freeTimeEvents = [];
        let dayStart = moment('00:00', 'HH:mm');
        let dayEnd = moment('23:59', 'HH:mm');
        let dayString = getDayString(day);
        let sorted = [...suggestedProducts].reverse();

        sorted.sort((a, b) => {
            let aTime = getMoment(a).start;
            let bTime = getMoment(b).start;

            if (aTime.isBefore(bTime)) {
                return -1;
            } else {
                return 1;
            }
        });

        for (let i = 0; i < sorted.length; i++) {
            let time = getMoment(sorted[i]);

            if (i == 0) {
                if (dayStart.isBefore(time.start)) {
                    let duration = time.start.from(dayStart);
                    freeTimeEvents.push({
                        id: createEventId(),
                        title: 'Timed event',
                        event_type: 'free_time',
                        extendedProps: {
                            duration
                        },
                        imageurl:
                            'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',
                        start: dayString + `T${dayStart.format('HH:mm')}:00`,
                        end: dayString + `T${time.start.format('HH:mm')}:00`
                    });
                }
            }
            if (i == sorted.length - 1 && time.start.isBefore(time.end)) {
                if (time.end.isBefore(dayEnd)) {
                    let duration = dayEnd.from(time.end);
                    freeTimeEvents.push({
                        id: createEventId(),
                        title: 'Timed event',
                        event_type: 'free_time',
                        extendedProps: {
                            duration
                        },
                        imageurl:
                            'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',
                        start: dayString + `T${time.end.format('HH:mm')}:00`,
                        end: dayString + `T${dayEnd.format('HH:mm')}:00`
                    });
                }
            }
            if (sorted[i + 1]) {
                let next = getMoment(sorted[i + 1]);
                if (time.end.isBefore(next.start)) {
                    let duration = next.start.from(time.end);
                    freeTimeEvents.push({
                        id: createEventId(),
                        title: 'Timed event',
                        event_type: 'free_time',
                        extendedProps: {
                            duration
                        },
                        imageurl:
                            'https://res.cloudinary.com/bimbiphilips/image/upload/v1615461496/mz2qp51gpmh/izqxzepq7wrxvy2jqglc.jpg',
                        start: dayString + `T${time.end.format('HH:mm')}:00`,
                        end: dayString + `T${next.start.format('HH:mm')}:00`
                    });
                }
                // Middle events
            }
        }
        if (day < calendar?.days?.length) {
            freeTimeEvents.push({
                id: createEventId(),
                title: 'Add nightlife',
                event_type: 'night_time',
                start: dayString + 'T22:30:00',
                end: dayString + 'T23:30:00'
            });
        }
        return freeTimeEvents;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getMoment = (product) => {
    try {
        let start, end;
        if (!product.booking_time_periods) {
            start = moment(
                product.start.split('T')[1].split(':')[0] +
                    ':' +
                    product.start.split('T')[1].split(':')[1],
                'HH:mm'
            );
            end = moment(
                product.end.split('T')[1].split(':')[0] +
                    ':' +
                    product.end.split('T')[1].split(':')[1],
                'HH:mm'
            );
        } else if (product.booking_time_periods.time_period) {
            start = moment(
                get24HourFormatTime(product.booking_time_periods.time_period.from),
                'HH:mm'
            );
            end = moment(get24HourFormatTime(product.booking_time_periods.time_period.to), 'HH:mm');
        } else {
            start = moment(
                get24HourFormatTime(product.booking_time_periods.within_time_periods[0].from),
                'HH:mm'
            );
            end = moment(
                get24HourFormatTime(product.booking_time_periods.within_time_periods[0].from),
                'HH:mm'
            ).add(product.booking_time_periods.duration, 'minutes');
        }
        return { start, end };
    } catch (error) {
        console.log(error);
        return null;
    }
};

export function daysBetween(startDate, endDate) {
    let start = moment(new Date(startDate));
    let end = moment(new Date(endDate));
    start = moment(start.format('DD:MM:YYYY'), 'DD:MM:YYYY');
    end = moment(end.format('DD:MM:YYYY'), 'DD:MM:YYYY');
    let diff = end.diff(start, 'days');
    if (diff < 0) {
        diff *= -1;
    }
    return diff;
}

const checkTimePeriodOverlap = (aFrom, aTo, bFrom, bTo) => {
    if (aFrom.isBefore(bTo) && bFrom.isBefore(aTo)) {
        return true;
    }
    return false;
};

const TimeType = {
    Specific_Time: 'specific_time',
    Within_Time_Period: 'within_time_period',
    Both: 'both'
};

const modifyEventsToPreventOverlap = (clashingDays) => {
    let allEvents = [];
    clashingDays = Object.keys(clashingDays).map((key) => clashingDays[key]);
    // if duration is less than perform algorithm to pick up clashing products
    for (let day of clashingDays) {
        let sorted = [...day].reverse();

        sorted.sort((a, b) => {
            let aTime = getMoment(a).start;
            let bTime = getMoment(b).start;

            if (aTime.isBefore(bTime)) {
                return -1;
            } else {
                return 1;
            }
        });

        for (let i = 0; i < sorted.length; i++) {
            let event = sorted[i];

            let eventMoment;
            let isEnough = isDurationEnough(event);
            if (isEnough) {
                allEvents.push(event);
            } else {
                i++;
                let clashingEvents = i === sorted.length ? [event] : [event, sorted[i]];

                eventMoment = getMoment(event);
                let totalDuration = eventMoment.end.diff(eventMoment.start, 'minutes');

                event = sorted[i];

                if (event) {
                    eventMoment = getMoment(event);
                    totalDuration += eventMoment.end.diff(eventMoment.start, 'minutes');
                }
                // This for loop traverses backwards in order to fill the total duration if overlapping event is at the end of timeline
                for (let j = i + 1; j < sorted.length; j++) {
                    event = sorted[j];
                    isEnough = isDurationEnough(event);
                    eventMoment = getMoment(event);
                    if (isEnough && totalDuration > 30) {
                        break;
                    } else {
                        totalDuration += eventMoment.end.diff(eventMoment.start, 'minutes');
                        clashingEvents.push(sorted[j]);
                        i++;
                    }
                }

                if (i >= sorted.length - 1) {
                    let k = allEvents.length - 1;
                    while (totalDuration < 30) {
                        event = allEvents[k];
                        if (
                            event.event_type !== 'clashing_event' &&
                            event.event_type !== 'night_time'
                        ) {
                            eventMoment = getMoment(event);
                            totalDuration += eventMoment.end.diff(eventMoment.start, 'minutes');
                            allEvents.splice(k, 1);
                            clashingEvents.push(event);
                        }
                        k--;
                    }
                }
                if (clashingEvents.length > 1) {
                    clashingEvents.sort((a, b) => {
                        let aTime = getMoment(a).start;
                        let bTime = getMoment(b).start;

                        if (aTime.isBefore(bTime)) {
                            return -1;
                        } else {
                            return 1;
                        }
                    });
                }
                allEvents.push({
                    event_type: 'clashing_event',
                    event_duration_type: 'start',
                    extendedProps: {
                        moreProducts: clashingEvents
                    },
                    start: clashingEvents[0].start,
                    end: clashingEvents[clashingEvents.length - 1].end
                });
            }
        }
    }
    return allEvents;
};

const isDurationEnough = (event) => {
    const { start, end } = getMoment(event);

    if (event.event_type === 'resting_time') {
        if (end.diff(start, 'minutes') < 80) {
            return false;
        }
    }

    if (event.event_type === 'free_time') {
        if (end.diff(start, 'minutes') < 40) {
            return false;
        }
    }

    if (event.event_type === 'main_event') {
        if (end.diff(start, 'minutes') < 30) {
            return false;
        }
    }

    return true;
};

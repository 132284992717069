/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import AccountRow from 'components/molecules/user-account/account-row';
import { Button } from 'reactstrap';

function Connectivity() {
    // const [isEditingCompanyInfo, setEditingCompanyInfo] = useState(false);
    // const [isEditingOfficeInfo, setEditingOfficeInfo] = useState(false);

    return (
        // Connectivity Details Tab Content for User account verification
        <div>
            {/* Connectivity Details Card */}
            <AccountRow title="Connectivity details">
                <div className="d-md-flex flex-wrap mx-n2">
                    <div className="px-2 flex-grow-1">
                        <div className="fs-18">
                            <strong>API enabled: </strong> <span>Pending</span>
                        </div>
                        <div>We're in the process of completing your connection</div>
                    </div>
                    <div className="px-2 flex-shrink-0 mt-3 mt-md-0">
                        <div>
                            Download products as CSV{' '}
                            <Button
                                color="primary btn-px ml-md-2"
                                className="mw-initial btn-block btn-md-inline-block">
                                Download
                            </Button>
                        </div>
                    </div>
                </div>
            </AccountRow>
        </div>
    );
}

Connectivity.propTypes = {};

export default Connectivity;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import Info from '../../../assets/images/mdi_info.svg';
import { ReactSortable } from 'react-sortablejs';
import { NavLink } from 'react-router-dom';
import Minimizer from '../minimizer';
import PropTypes from 'prop-types';
import Prompter from '../../atoms/prompter';
import { Col, Row } from 'antd';
import Panel from '../../atoms/panel';
import './index.scss';

function DaysAccordion(props) {
    const { newTitleText, onPrompterClick, newSubTitleText } = props;
    const [state, setState] = useState({
        expandedAll: false,
        days: []
    });
    const onSortItems = (days) => {
        setState({
            ...state,
            days: recalulateDays(days)
        });
    };
    const recalulateDays = (days) => {
        return days.map((day, index) => ({
            ...day,
            day: `day ${index + 1}`
        }));
    };
    const removeDay = (index) => {
        const days = [...state.days];
        days.splice(index, 1);
        setState({
            ...state,
            days
        });
    };
    const handleClose = (index) => {
        let days = [...state.days];
        days[index].open = false;
        setState({
            ...state,
            days
        });
    };
    const addDay = () => {
        const days = [...state.days];
        days.push({
            day: `day ${days.length + 1}`,
            open: false,
            titleText: newTitleText,
            subTitleText: newSubTitleText
        });
        setState({
            ...state,
            days
        });
    };
    const expandAllDays = (newState) => {
        let days = [...state.days];
        days = days.map((day) => {
            return {
                ...day,
                open: newState
            };
        });
        setState({
            ...state,
            days,
            expandedAll: newState
        });
    };
    useEffect(() => {
        setState({
            ...state,
            days: props.days
        });
    }, [props.days, state]);
    const { expandedAll } = state;
    return (
        <div className="atc-days-accordion">
            {!expandedAll ? (
                <a className="atc-days-accordion__expand-all" onClick={() => expandAllDays(true)}>
                    Expand All Days
                </a>
            ) : (
                <a className="atc-days-accordion__expand-all" onClick={() => expandAllDays(false)}>
                    Collapse All Days
                </a>
            )}
            <ReactSortable list={state.days} setList={onSortItems}>
                {state.days.map(({ day, titleText, subTitleText, open, actionLink }, index) => (
                    <Row key={index} gutter={22} justify="center">
                        <Col span={22}>
                            <Minimizer
                                title={day}
                                open={open}
                                onClose={() => handleClose(index)}
                                action={
                                    index !== 0 ? (
                                        <span onClick={() => removeDay(index)}>Remove Day</span>
                                    ) : null
                                }>
                                {actionLink ? (
                                    <NavLink to={actionLink || ''}>
                                        <Panel
                                            title={
                                                <div className="atc-days-accordion__title">
                                                    <span>{titleText}</span>
                                                    <img alt="" src={Info}></img>
                                                </div>
                                            }
                                            hasRecommendedBadge={false}
                                            footer={<Prompter text={subTitleText} />}
                                        />
                                    </NavLink>
                                ) : (
                                    <Panel
                                        title={
                                            <div className="atc-days-accordion__title">
                                                <span>{titleText}</span>
                                                <img alt="" src={Info}></img>
                                            </div>
                                        }
                                        hasRecommendedBadge={false}
                                        footer={
                                            <Prompter
                                                onClick={onPrompterClick}
                                                text={subTitleText}
                                            />
                                        }
                                    />
                                )}
                            </Minimizer>
                        </Col>
                    </Row>
                ))}
            </ReactSortable>
            <div className="atc-days-accordion__action">
                <Row gutter={22} justify="center">
                    <Col span={22}>
                        <Prompter onClick={addDay} text="Add another day" />
                    </Col>
                </Row>
            </div>
        </div>
    );
}

DaysAccordion.propTypes = {
    days: PropTypes.array,
    newTitleText: PropTypes.string,
    newSubTitleText: PropTypes.string,
    onPrompterClick: PropTypes.func
};

export default DaysAccordion;

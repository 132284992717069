import React from 'react';
import { TableRow, TableCell, FormControl, OutlinedInput, InputAdornment } from '@material-ui/core';
import MoreText from './MoreText.jsx';

const styles = {
    cell: {
        border: 'none'
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px'
    },
    input: {
        width: '68px',
        height: '48px',

        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px'
    },

    leftInput: {
        height: '50px',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px 0px 0px 8px'
    },
    rightInput: {
        height: '50px',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '0px 8px 8px 0px'
    },
    season: {
        width: '120px'
    }
};

export default function NewGroupBookingRow(props) {
    // const [amount, setAmount] = useState(1.0);
    // const [newGroupRows, setNewGroupRows] = useState([]);

    // const handleAmount = (e) => {
    //     setAmount(e.target.value);
    // };

    // const AddNewGroup = () => {
    //     let tempNewGroup = [...newGroupRows];
    //     tempNewGroup.push({});
    //     setNewGroupRows(tempNewGroup);
    // };

    const includeNew = () => {
        props.includeNew();
    };

    return (
        <>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={{ ...styles.cell, border: 'none' }}></TableCell>
                <TableCell style={{ ...styles.cell, border: 'none' }}>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            style={styles.input}
                            // value={val}
                            // onChange={handleChange()}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight'
                            }}
                            labelWidth={0}
                        />
                    </FormControl>
                </TableCell>
                <TableCell style={{ ...styles.cell, border: 'none' }} align="left">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}>
                        <span
                            style={{
                                fontWeight: '600',
                                fontSize: '15.5px',
                                lineHeight: '19px',
                                /* identical to box height */

                                textTransform: 'capitalize',

                                color: '#333333',
                                marginRight: '10px'
                            }}>
                            From
                        </span>
                        <input type="date" style={styles.leftInput} />
                        <input type="date" style={styles.rightInput} />
                    </div>
                </TableCell>
                <TableCell style={{ ...styles.cell, border: 'none' }} align="left">
                    <input type="text" style={{ ...styles.input, ...styles.season }} />
                </TableCell>
                {props.groupLength === props.itemIndex + 1 ? (
                    <TableCell
                        // style={styles.cell}
                        align="left"
                        style={{ cursor: 'pointer', border: 'none' }}
                        onClick={includeNew}>
                        <MoreText
                            customStyle={{
                                fontFamily: 'Poppins',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#A2792C'
                            }}
                            text="Add another group price"
                        />
                    </TableCell>
                ) : null}
            </TableRow>
        </>
    );
}

/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
// import { TableCell } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import Prompter from '../../../prompter';
import Proptypes from 'prop-types';
import { Form, InputNumber } from 'antd';
import DeleteIcon from 'assets/images/close.svg';

const styles = {
    cell: {
        border: 'none',
        display: 'flex',
        // alignItems: 'center',
        padding: 8
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px',
        width: '68px',
        alignItems: 'center',
        display: 'flex'
    },
    input: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '48px',
        width: '89px',
        fontSize: '16px',
        textAlign: 'center',
        marginRight: '10px'
    },
    inputUsd: {
        border: '0.5px solid #C4C4C4',
        borderLeft: 0
    },
    disabled: {
        opacity: 0.6
    },
    deleteIconWrapper: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        height: '100%',
        marginTop: 11
    },
    deleteIcon: {
        height: 20,
        width: 20
    },
    singleSupplementToggle: {
        minHeight: 48,
        minWidth: 207.5,
        maxWidth: 207.5,
        justifyContent: 'center',
        display: 'flex',
        marginTop: 13
    },
    singleSupplementText: {
        position: 'absolute',
        top: -27,
        textAlign: 'center',
        zIndex: 6000,
        width: 157.5
    }
};

export default function GroupRow({
    productPrice,
    onChange,
    secondPrice,
    groupName,
    index,
    singleSupplement,
    type
}) {
    const [addSingle, setAddSingle] = useState(null);
    return (
        <>
            <Row form>
                <Col style={{ ...styles.cell }}>{groupName}</Col>
                {type !== 'accomodation' && (
                    <Col>
                        <Form.Item>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={styles.currency}>USD</div>
                                <InputNumber
                                    style={{
                                        ...styles.input,
                                        ...styles.inputUsd,
                                        borderRadius: '0 5px 5px 0'
                                    }}
                                    disabled={
                                        type === 'product_accomodation' ||
                                        type === 'product_supplement'
                                    }
                                    value={productPrice}
                                    onChange={(e) => onChange(e, 'productPrice', groupName)}
                                />
                                <div>PP</div>
                            </div>
                        </Form.Item>
                    </Col>
                )}
                <Col>
                    <Form.Item
                        name={`sharing${index}secondPrice`}
                        rules={[
                            {
                                required: secondPrice === null || secondPrice === undefined,
                                message: `Please add ${groupName} price`
                            }
                        ]}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={styles.currency}>USD</div>
                            <InputNumber
                                style={{
                                    ...styles.input,
                                    ...styles.inputUsd,
                                    borderRadius: '0 5px 5px 0'
                                }}
                                name={'secondPrice'}
                                value={secondPrice}
                                min={1}
                                onChange={(e) => onChange(e, 'secondPrice', groupName)}
                            />
                            <div>PP</div>
                        </div>
                    </Form.Item>
                </Col>
                {type === 'accomodation' &&
                    (!addSingle ? (
                        <Col>
                            <div style={styles.singleSupplementToggle}>
                                <Prompter
                                    fontSize={14}
                                    textColor="#A2792C"
                                    text={`Add Single Supplement`}
                                    onClick={() => setAddSingle(true)}
                                />
                            </div>
                        </Col>
                    ) : (
                        <Col>
                            {groupName === 'Double' && (
                                <div style={{ position: 'relative' }}>
                                    <div style={styles.singleSupplementText}>Single Supplement</div>
                                </div>
                            )}
                            <div className="d-flex align-items-center">
                                <Form.Item
                                    name={`sharing${index}singleSupplement`}
                                    rules={[
                                        {
                                            required:
                                                addSingle &&
                                                (secondPrice === null || secondPrice === undefined),
                                            message: `Please add ${groupName} price`
                                        }
                                    ]}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div style={styles.currency}>USD</div>
                                        <InputNumber
                                            style={{
                                                ...styles.input,
                                                ...styles.inputUsd,
                                                borderRadius: '0 5px 5px 0'
                                            }}
                                            value={singleSupplement}
                                            min={1}
                                            onChange={(e) =>
                                                onChange(e, 'singleSupplement', groupName)
                                            }
                                        />
                                        <div>PP</div>
                                    </div>
                                </Form.Item>
                                <div className="delete-block">
                                    <img
                                        src={DeleteIcon}
                                        className="delete-icon cursor-pointer"
                                        style={styles.deleteIcon}
                                        onClick={() => {
                                            setAddSingle(!addSingle);
                                            onChange(null, 'singleSupplement', groupName);
                                        }}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </Col>
                    ))}
            </Row>

            {type !== 'accomodation' &&
                (!addSingle ? (
                    <Prompter
                        fontSize={14}
                        textColor="#A2792C"
                        text={`Add Single Supplement`}
                        onClick={() => setAddSingle(true)}
                    />
                ) : (
                    <div>
                        <div className="d-flex">
                            {/* <Col sm={3} style={{ ...styles.cell, maxWidth: '21.5%' }} /> */}
                            <div style={styles.cell}>Single Supplement</div>
                        </div>
                        <div className="d-flex align-items-center">
                            <Form.Item
                                name={`sharing${index}singleSupplement`}
                                rules={[
                                    {
                                        required:
                                            addSingle &&
                                            (secondPrice === null || secondPrice === undefined),
                                        message: `Please add ${groupName} price`
                                    }
                                ]}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={styles.currency}>USD</div>
                                    <InputNumber
                                        style={{
                                            ...styles.input,
                                            ...styles.inputUsd,
                                            borderRadius: '0 5px 5px 0'
                                        }}
                                        value={singleSupplement}
                                        min={1}
                                        onChange={(e) => onChange(e, 'singleSupplement', groupName)}
                                    />
                                    <div>PP</div>
                                </div>
                            </Form.Item>
                            <div className="delete-block">
                                <img
                                    src={DeleteIcon}
                                    className="delete-icon cursor-pointer"
                                    style={styles.deleteIcon}
                                    onClick={() => {
                                        setAddSingle(false);
                                        onChange(null, 'singleSupplement', groupName);
                                    }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                ))}
        </>
    );
}
GroupRow.propTypes = {
    groupName: Proptypes.string,
    data: Proptypes.object,
    onSave: Proptypes.func,
    productPrice: Proptypes.any,
    onChange: Proptypes.any,
    secondPrice: Proptypes.any,
    singleSupplement: Proptypes.any,
    index: Proptypes.any,
    type: Proptypes.string
};

/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Tag, Tooltip } from 'antd';
import { getStripeTransfer } from 'store/actions';
import moment from 'moment';
import PropTypes from 'prop-types';
import { transformFirstLetterToUpperCase } from 'utils';

function TransfersTab(props) {
    const { stripe_transfers, getStripeTransfer } = props;

    const [prevIds, setPrevIds] = useState([]);

    useEffect(() => {
        getStripeTransfer({});
    }, []);

    function onClickNext() {
        let rawPrevIds = [...prevIds];
        if (rawPrevIds.length === 0) rawPrevIds.push(undefined);
        else rawPrevIds.push(stripe_transfers.data[stripe_transfers.data.length - 1].id);
        getStripeTransfer({
            lastId: stripe_transfers.data[stripe_transfers.data.length - 1].id
        }).then((res) => {
            if (res?.payload || rawPrevIds.length === 1) setPrevIds(rawPrevIds);
        });
    }

    function onClickPrevious() {
        let rawPrevIds = [...prevIds];
        getStripeTransfer({ lastId: rawPrevIds.pop() }).then((res) => {
            if (res?.payload) setPrevIds(rawPrevIds);
        });
    }

    const paymentColumns = [
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            render: (_text, record) => {
                return (
                    <div>
                        <span className="fw-500 mr-3">{`$${record?.amount}`}</span>{' '}
                        {`${record?.currency ? record.currency.toUpperCase() : ''}`}
                        {record?.status && (
                            <span className="ml-3">
                                <Tooltip
                                    placement="top"
                                    title={<>{`This payment is ${record?.status}`}</>}>
                                    <Tag color="green">
                                        <span className="pr-2">
                                            {transformFirstLetterToUpperCase(record?.status)}
                                        </span>
                                        <svg
                                            aria-hidden="true"
                                            height="12"
                                            width="12"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.297 13.213L.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                fillRule="evenodd"></path>
                                        </svg>
                                    </Tag>
                                </Tooltip>
                            </span>
                        )}
                    </div>
                );
            }
        },
        {
            title: 'CUSTOMER',
            dataIndex: 'customer'
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            render: (_text, record) => {
                return (
                    <div>
                        {`${record.created && moment.unix(record.created).format('MMM D, H:mm A')}`}
                    </div>
                );
            }
        },
        {
            title: 'DETAILS',
            dataIndex: 'view',
            width: 100,
            render: (_text, record) => {
                return (
                    <div>
                        <Button
                            size="small"
                            id="view-detail"
                            color="primary"
                            onClick={() => props.onClickRow({ id: record?.id, tab: 'transfer' })}>
                            View
                        </Button>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <Table
                size="small"
                columns={paymentColumns}
                dataSource={
                    stripe_transfers?.data
                        ? stripe_transfers.data.map((value, index) => {
                              return { ...value, key: String(index) };
                          })
                        : []
                }
                pagination={false}
                scroll={{ y: 500, x: 1200 }}
                onRow={(record) => {
                    return {
                        onClick: () => props.onClickRow({ id: record?.id, tab: 'transfer' }) // click row
                    };
                }}
            />
            <div className="d-flex gap-2 justify-content-end mt-3">
                <Button
                    type="primary"
                    size="small"
                    disabled={prevIds.length === 0}
                    onClick={onClickPrevious}>
                    Prev
                </Button>
                <Button
                    type="primary"
                    size="small"
                    disabled={stripe_transfers?.hasMore === false}
                    onClick={onClickNext}>
                    Next
                </Button>
            </div>
        </>
    );
}

TransfersTab.propTypes = {
    getStripeTransfer: PropTypes.func,
    stripe_transfers: PropTypes.object,
    onClickRow: PropTypes.func
};

const mapStateToProps = (state) => ({
    stripe_transfers: state.profile.stripe_transfers
});

const mapDispatch = (dispatch) => ({
    getStripeTransfer: (query) => dispatch(getStripeTransfer(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(TransfersTab));

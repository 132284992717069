import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

const NightLifeProductIcon = ({ icon }) => {
    return (
        <div className="night-icon-wrapper">
            <div className="night-icon-wrapper-inner ">{icon}</div>
        </div>
    );
};

NightLifeProductIcon.propTypes = {
    icon: PropTypes.any
};

export default NightLifeProductIcon;

import React from 'react';
import { Form, Select, Spin } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import searchIcon from '../../../assets/images/searchIcon.svg';

export function MultiSearchSelect(props) {
    const {
        label,
        required = true,
        placeHolder = 'Search country',
        options,
        initialValue,
        onSelect,
        fieldName = 'country',
        disabled = false,
        value,
        onSearch,
        loading
    } = props;

    return (
        <Form.Item
            label={label || undefined}
            name={fieldName}
            initialValue={initialValue}
            rules={[{ required: required, message: 'Please select' }]}>
            <Select
                mode="multiple"
                allowClear
                loading={loading}
                autoComplete="none"
                className="d-block"
                placeholder={placeHolder}
                showSearch
                filterOption={false}
                onSearch={onSearch}
                onSelect={onSelect}
                disabled={disabled}
                value={value}
                notFoundContent={
                    loading ? (
                        <Spin size="small" />
                    ) : options.length === 0 ? (
                        <div>Not found</div>
                    ) : null
                }
                suffixIcon={<img src={searchIcon} alt="" />}>
                {options &&
                    options.map((item, index) => (
                        <Select.Option value={item.value} key={index}>
                            {item.name}
                        </Select.Option>
                    ))}
            </Select>
        </Form.Item>
    );
}

MultiSearchSelect.propTypes = {
    options: PropTypes.shape([
        {
            name: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        }
    ]),
    label: PropTypes.string,
    required: PropTypes.bool,
    placeHolder: PropTypes.string,
    initialValue: PropTypes.string,
    selectValue: PropTypes.string,
    selectName: PropTypes.string,
    onSelect: PropTypes.func,
    fieldName: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    onSearch: PropTypes.func,
    loading: PropTypes.bool
};

const mapStateToProps = () => ({
    // options: state.profile.locations
});
const mapDispatch = () => ({
    // fetchLocations: (query) => dispatch(fetchLocations(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(MultiSearchSelect));

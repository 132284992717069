import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Drawer } from 'antd';
import HelpButton from '../../../atoms/help-button/HelpButton';

const SynkProductDrawer = (props) => {
    const { visible, onClose, productTitle } = props;

    return (
        <Drawer
            width={609}
            placement="right"
            className="basic-drawer"
            visible={visible}
            onClose={onClose}>
            <div className="d-flex flex-column h-100">
                <div className="mb-1">
                    <h4>Sync Products</h4>
                    <span className="fs-18 fw-400">{productTitle}</span>
                </div>

                <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed pr-2">
                    {props.children}
                </div>

                <div className="pt-3">
                    <div className="d-flex justify-content-between">
                        <Button color="primary" onClick={onClose}>
                            Close
                        </Button>
                        <HelpButton />
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

SynkProductDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    productTitle: PropTypes.string
};

export default SynkProductDrawer;

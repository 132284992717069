/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ActivityPanelComponent } from 'components/molecules';
import PropTypes from 'prop-types';

const ColumnHeaderText = (props) => {
    const { isDayActive, date, handleOnClick, handleOnMouseEnter, handleOnMouseLeave } = props;
    return (
        <div
            className={`${isDayActive == date.dow ? 'active-day' : ''}`}
            key={date.dow}
            role="button"
            tabIndex="0"
            onClick={handleOnClick}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}>
            <div>
                <span className="col-day-action"> Day {date.dow} </span>
            </div>

            <ActivityPanelComponent
                bookedEvents={['rest', 'drive']}
                hasMainProduct={true}
                pannel={`${isDayActive == date.dow ? 'stand-out' : ''}`}
            />
        </div>
    );
};
ColumnHeaderText.propTypes = {
    isDayActive: PropTypes.bool,
    date: PropTypes.object,
    handleOnClick: PropTypes.func,
    handleOnMouseEnter: PropTypes.func,
    handleOnMouseLeave: PropTypes.func
};
export default ColumnHeaderText;

import { ACC_DETAILS } from '../actions/accommodationdetails';

// const initialState = {

//         "calendar_id": 1,
//         "category_key": "accomodation",
//         "sub_category": "Villa/Mansion",
//         "title": "Villa Cruise",
//         "description": "Description will go here",
//         "address": "Lekki Phase 1",
//         "city": "Lagos",
//         "country": "Nigeria",
//         "start_time": "09:00",
//         "finish_time": "15:00",
//         "booking_time_periods": [
//             {
//                 "from": "08:00",
//                 "to": "12:00"
//             }
//         ],
//         "duration": 86400,
//         "calendar_days": [1, 2, 3],
//         "photos_and_videos": [
//             { "type": "image", "url": "https://res.cloudinary.com/djoifhyox/image/upload/v1601390900/jhazrorcehsltnhtylzx.jpg" }
//         ],
//         "maximum_daily_availability": 10,
//         "meals_and_drinks": [
//             {
//                 "type": "Breakfast",
//                 "description": "Description will go here",
//                 "photos_and_videos": [
//                     { "type": "image", "url": "https://res.cloudinary.com/djoifhyox/image/upload/v1601390900/jhazrorcehsltnhtylzx.jpg" }
//                 ]
//             }
//         ],
//         "language_offered": {
//             "languages": [
//                 {
//                     "name": "English",
//                     "in_person": true,
//                     "audio": false,
//                     "written": false
//                 }
//             ],
//             "guide_is_certified": true,
//             "guide_as_driver": false
//         },
//         "inclusions": [
//             {
//                 "category": "Use of equipments",
//                 "title": "Use of bicycles"
//             }
//         ],
//         "exclusions": [
//             {
//                 "category": "Transport amenities",
//                 "title": "WiFi on board",
//                 "reason": null
//             }
//         ],
//         "meetup_points": [ "23 road, festac town, Lagos, Nigeria", "another address here" ],
//         "departure_time": "09:00",
//         "departure_pickup_duration": 7200,
//         "calendar_season": {
//             "best_time": [
//                 { "from": "December", "to": "January", "reason": "winter" }
//             ],
//             "high_season": [
//                 { "from": "December", "to": "January", "reason": "winter" }
//             ],
//             "best_weather": [
//                 { "from": "December", "to": "January", "reason": "winter" }
//             ]
//         },
//         "adventures": ["accessible safari", "family safari", "dinner", "party", "romantic"],
//         "sights": [
//             {
//                 "category": "Widlife",
//                 "sub_category": "Lion",
//                 "frequency": "common",
//                 "image_url": "https://res.cloudinary.com/djoifhyox/image/upload/v1601390900/jhazrorcehsltnhtylzx.jpg"
//             },
//             {
//                 "category": "Widlife",
//                 "sub_category": "Elephant",
//                 "frequency": "common",
//                 "image_url": "https://res.cloudinary.com/djoifhyox/image/upload/v1601390900/jhazrorcehsltnhtylzx.jpg"
//             }
//         ],
//         "rooms": {
//             "pp_sharing": {
//                 "double": {
//                     "double_bed": 2,
//                     "large_bed": 3,
//                     "xl_double_bed": 3,
//                     "sofa_bed": 0,
//                     "futon_mat": 0
//                 },
//                 "twin": {
//                     "single_bed": 1,
//                     "bunk_bed": 2
//                 }
//             },
//             "pp_single": {
//                 "single": {
//                     "bed": 6
//                 },
//                 "double": {
//                     "double_bed": 4,
//                     "large_bed": 2,
//                     "xl_double_bed": 0,
//                     "sofa_bed": 0,
//                     "futon_mat": 0
//                 }
//             },
//             "no_of_bathrooms": 25
//         },
//         "facilities": {
//             "general": ["Air conditioning", "Heating"],
//             "cooking_and_cleaning": ["Kitchen"],
//             "entertainment": ["Swimming pool", "Hot tub"],
//             "outside_and_view": ["Balcony", "Garden view"]
//         },
//         "breakfast_details": {
//             "price": 300,
//             "types": ["A la carte", "American"]
//         },
//         "house_rules": {
//             "smoking_allowed": false,
//             "pets_allowed": true,
//             "events_allowed": false,
//             "check_in": {
//                 "from": "17:00",
//                 "until": "20:00"
//             },
//             "check_out": {
//                 "from": "08:00",
//                 "until": null
//             }
//         },
//         "covid19_updates": ["Wear your nose mask", "Come with hand sanitizers"],
//         "traveller_information": {
//             "accessibility": {
//                 "wheelchair": true,
//                 "stroller": false,
//                 "service_animals": false,
//                 "public_trans": true,
//                 "infant_lapping": true,
//                 "infant_seats": true
//             },
//             "health_restrictions": [],
//             "difficulty_level": 2,
//             "phone_number": "08109501764"
//         },
//         "pricings": [
//             {
//                 "age_group": [
//                     {
//                         "type": "adult",
//                         "min_age": 18,
//                         "max_age": 99,
//                         "amount": 300,
//                         "group_price": [
//                             { "amount": 300, "min": 1, "max": 3 },
//                             { "amount": 150, "min": 4, "max": 99 }
//                         ]
//                     },
//                     {
//                         "type": "child",
//                         "min_age": 1,
//                         "max_age": 17,
//                         "amount": 200,
//                         "group_price": [
//                             { "amount": 200, "min": 1, "max": 3 },
//                             { "amount": 100, "min": 4, "max": 99 }
//                         ]
//                     }
//                 ],
//                 "min_travellers": 1,
//                 "max_travellers": 20,
//                 "days": ["1", "2", "3", "4", "5", "6", "7"],
//                 "single_supplement": {
//                     "double": 400,
//                     "twin": 450
//                 },
//                 "accomodation_price": {
//                     "pp_sharing": {
//                         "double": {
//                             "price": 50,
//                             "ss_price": 80
//                         },
//                         "single": {
//                             "price": 100,
//                             "ss_price": 150
//                         }
//                     },
//                     "pp_single": {
//                         "single_price": 30,
//                         "double_price": 40
//                     },
//                     "group": [
//                         {
//                             "rate": 20,
//                             "min": 3,
//                             "max": 6
//                         },
//                         {
//                             "rate": 30,
//                             "min": 7,
//                             "max": null
//                         }
//                     ],
//                     "festive": [
//                         {
//                             "rate": 10,
//                             "start": "2020-12-25",
//                             "end": "2021-01-01",
//                             "season": "Christmas"
//                         }
//                     ]
//                 }
//             }
//         ],
//         "booking_ticket_details": {
//             "cuttoff": {
//                 "time": 3600,
//                 "type": "travel"
//             },
//             "receive_notification": true,
//             "cancellation_policy": "strict",
//             "custom_policy": [],
//             "extra_information": [],
//             "ticket": {
//                 "type": "mobile_or_paper",
//                 "number": "one_per_traveller",
//                 "redemption_locations": ["Lekki phase 1", "Satellite town"],
//                 "redemption_instructions": "What was entered in your text box should go here."
//             }
//         }

// }

export const accommodationdetailsReducer = (state = {}, action) => {
    switch (action.type) {
        case ACC_DETAILS: {
            const newState = { ...state };
            newState[action.payload.key] = action.payload.data;
            return newState;
        }
        default:
            return state;
    }
};

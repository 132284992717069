/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Display from '../../atoms/display';
import './index.scss';

import Arrow from '../../../assets/images/down-arrow.svg';

export default function Index(props) {
    const [isMinimized, setMinimizedState] = useState(true);
    const { onAction, children, title, action, open = true, onClose } = props;
    useEffect(() => {
        setMinimizedState(open);
    }, [open]);
    const setClose = () => {
        setMinimizedState(!isMinimized);
        onClose();
    };
    return (
        <div className="atc-minimizer">
            <div className="atc-minimizer-title">
                <h6 onClick={setClose}>
                    <img
                        alt=""
                        src={Arrow}
                        className={`atc-minimizer-title-icon ${
                            isMinimized
                                ? 'atc-minimizer-title-icon--up'
                                : 'atc-minimizer-title-icon--down'
                        }`}
                    />
                    <span>{title}</span>
                </h6>
                <span onClick={onAction}>{action}</span>
            </div>
            <Display show={isMinimized}>
                <div className="atc-minimizer-content">{children}</div>
            </Display>
        </div>
    );
}
Index.propTypes = {
    onAction: PropTypes.func,
    children: PropTypes.any,
    title: PropTypes.any,
    action: PropTypes.any,
    open: PropTypes.bool,
    onClose: PropTypes.func
};

import { Notification } from '../../components/atoms/notification';
import { callApi } from '../../utils/apiCaller';
import { CREATE_FEEDBACK, FEEDBACK_ERROR } from '../types/feedbackTypes';

const createFeedback_ = (payload) => ({ type: CREATE_FEEDBACK, payload });
const feedbackerror = (payload) => ({ type: FEEDBACK_ERROR, payload });

export const createFeedback = (data) => {
    return (dispatch) => {
        callApi('/feedback', 'POST', data).then((response) => {
            const status = response?.status;
            const responseData = response.data;
            const successMsg = response.data.message || response.message;

            if (status === 200 || status === 201) {
                dispatch(createFeedback_(responseData));
                return Notification('success', successMsg, status);
            } else {
                dispatch(feedbackerror(responseData));
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response?.data?.errors[0]?.message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

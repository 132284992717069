/* eslint-disable no-prototype-builtins */
import React, { useState } from 'react';
import { Button, Modal, Row, Col, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import TextArea from 'antd/lib/input/TextArea';
import { PhoneCountryCode } from 'components/atoms';
import { SuccessModal } from 'components/organisms';
import './index.css';

function EnterpriseModal(props) {
    const { showModal, toggleModal, sendEmail } = props;
    const [form] = Form.useForm();

    const [isSelectCode, setIsSelectCode] = useState(false);
    const [selectCode, setSelectCode] = useState('');
    const [showSuccess, setShowSuccess] = useState(false);

    function onFinish() {
        const { emailBody, phone_number, senderEmail, senderName } = form.getFieldsValue();
        let subject = 'Upgrade To Enterprise Plan'; //${selectCode}-
        sendEmail({
            emailBody,
            senderEmail,
            senderName,
            subject,
            phoneNumber: `${selectCode}${phone_number}`
        }).then((res) => {
            if (res) {
                setShowSuccess(true);
            }
        });
    }
    const togglePhone = () => setIsSelectCode(!isSelectCode);

    return (
        <div>
            <Modal
                zIndex={1501}
                isOpen={showModal}
                toggle={toggleModal}
                className="modal-dialog-centered">
                <ModalHeader>
                    <div className="ml-0 mr-0 px-2">
                        <span>Upgrade to enterprise</span>
                    </div>
                </ModalHeader>
                <ModalBody className="">
                    <Form
                        form={form}
                        onFinish={onFinish}
                        layout="vertical"
                        className="form-label-fw-500 mx-auto d-flex flex-column"
                        style={{ maxWidth: 600, minHeight: 400 }}>
                        <Row className="row-lg">
                            <Col xs={12}>
                                <Form.Item
                                    name="senderName"
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Name is requied`
                                        }
                                    ]}>
                                    <Input placeHolder="Name" />
                                </Form.Item>
                            </Col>

                            <Col xs={12}>
                                <Form.Item
                                    label="Email"
                                    name="senderEmail"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Email is requied`
                                        },
                                        { type: 'email', message: 'Please enter valid email' }
                                    ]}>
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Col>
                            <Col xs={12}>
                                <PhoneCountryCode
                                    label="Phone number"
                                    expandMenu={isSelectCode}
                                    toggleMenu={togglePhone}
                                    selectedCountryCode={selectCode}
                                    onChangeCountryCode={(code) => {
                                        setSelectCode(code);
                                        form.validateFields(['selectCode']);
                                    }}
                                />
                            </Col>

                            <Col xs={12}>
                                <Form.Item
                                    label="Challenges"
                                    name="emailBody"
                                    rules={[
                                        {
                                            required: true,
                                            message: `Challenges is requied`
                                        }
                                    ]}>
                                    <TextArea placeholder="What are your challenges?" />
                                </Form.Item>
                            </Col>
                        </Row>

                        <div className="d-flex flex-wrap justify-content-center gap-3 mt-auto">
                            <Button color="primary" className="text-uppercase" htmlType="submit">
                                Send Email
                            </Button>

                            <Button onClick={toggleModal} color="dark">
                                CLOSE
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>

            <SuccessModal
                title={`Your email has been sent and we will get back to you shortly`}
                showModal={showSuccess}
                handleClose={() => {
                    setShowSuccess(false);
                    toggleModal();
                }}
                zIndex={1502}
            />
        </div>
    );
}

EnterpriseModal.propTypes = {
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    sendEmail: PropTypes.func
};

const mapStateToProps = () => ({});
const mapDispatch = () => ({});
export default withRouter(connect(mapStateToProps, mapDispatch)(EnterpriseModal));

import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getAvailabilities } from 'store/actions/availabilityActions';
import PropTypes from 'prop-types';

import Availability from '../availability';
import ArchiveModal from './archiveModal';
import PauseModal from './pauseModal';
import StopModal from './stopModal';
import CancelModal from './cancelModal';
import ExportSyncModal from './exportSyncModal';
import SynkProduct from './synkProduct';
import AvailabilityFilter from './avaliabilityFilter';
import moment from 'moment';
import { Loader } from 'components/atoms';
import { Feedback } from 'components/molecules';

import { Button, Table, Dropdown, Menu, Pagination } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import './index.css';
import { ProductDetailsDrawer, CalendarEventDetailContent } from 'components/molecules';
import { setCurrentProduct } from 'store/actions';
import { ComponentPermissionContext } from 'context/rolesandpermission';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';
import ReinstateSaleModal from 'components/organisms/reinstateSaleConfirmationModal';

class AvailabilityData extends Component {
    //Initialized state
    static contextType = ComponentPermissionContext;

    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            perPage: 10,
            filter: {},
            productCategoryId: null,
            productSubCategoryId: null,
            productStatus: null,
            availabilityStatus: null,
            calendarId: null,
            dateQuery: moment().format('YYYY-MM-DD'),
            settings: false,
            selected: '',
            pause: false,
            nextPause: false,
            stop: false,
            nextStop: false,
            cancel: false,
            nextCancel: false,
            finalCancel: false,
            archive: false,
            filtersVisibility: false,
            pauseSalesConfirmModal: false,
            stopSalesConfirmModal: false,
            cancelSalesConfirmModal: false,
            archiveSalesConfirmModal: false,
            exportSyncProductConfirmModal: false,
            visibleProductSummaryDrawer: false,
            visibleProductSynkDrawer: false,
            reinstateConfirmSaleModal: false,
            canNotArchive: false,
            confirmationWindow: false,
            openedRecord: null
        };
        this.changeSettings = this.changeSettings.bind(this);
    }

    componentDidMount() {
        const { page, perPage, dateQuery } = this.state;
        this.props.getAvailabilities({ page, perPage, dateQuery });
    }

    componentDidUpdate(_, prevState) {
        const { page, perPage, filter, dateQuery, filtersVisibility } = this.state;

        if (prevState.filtersVisibility === true && filtersVisibility === false) {
            this.setState({ filter: {}, page: 1 }, () =>
                this.props.getAvailabilities({ page, perPage, dateQuery, ...filter })
            );
        }
    }

    handleFiterVisibility = () => {
        const { filtersVisibility } = this.state;
        return this.setState({ filtersVisibility: !filtersVisibility });
    };

    /** Filter for Table == start == */

    setFilterObject = (paramObject) => {
        this.setState(
            (prevState) => {
                return {
                    filter: { ...prevState.filter, [paramObject.type]: paramObject.value }
                };
            },
            () => {
                if (
                    this.state.filter[paramObject.type] === undefined ||
                    this.state.filter[paramObject.type] === null ||
                    this.state.filter[paramObject.type] === ''
                ) {
                    delete this.state.filter[paramObject.type];
                }

                return this.props.getAvailabilities({
                    page: this.state.page,
                    perPage: this.state.perPage,
                    dateQuery: this.state.dateQuery,
                    ...this.state.filter
                });
            }
        );
    };

    onChangeFilter = (filterObject) => {
        Array.isArray(filterObject)
            ? filterObject.map((item) => this.setFilterObject(item))
            : this.setFilterObject(filterObject);
    };

    clear = () => {
        return (
            this.handleFiterVisibility(),
            this.props.getAvailabilities({
                page: this.state.page,
                perPage: this.state.perPage,
                dateQuery: this.state.dateQuery,
                ...this.state.filter
            })
        );
    };

    onClearFilter = () => {
        this.setState({ filter: {} }, () => this.clear());
    };

    onChangePage = (page) => {
        this.setState({ page }, () =>
            this.props.getAvailabilities({
                page: this.state.page,
                perPage: this.state.perPage,
                dateQuery: this.state.dateQuery,
                ...this.state.filter
            })
        );
    };
    /** Filter for Table == end == */

    // table data and columns
    columns = [
        {
            title: 'PRODUCT TITLE',
            dataIndex: 'title',
            key: 'title',
            width: 155,
            render: (_text, record) => {
                return (
                    <div className="text-truncate" title={record.title} style={{ maxWidth: 140 }}>
                        {record.title ? record.title : 'N/A'}
                    </div>
                );
            }
        },
        {
            title: 'PRODUCT CATEGORY',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 200
        },
        {
            title: 'PRODUCT SUB-CATEGORY',
            dataIndex: 'sub_category_name',
            key: 'sub_category_name',
            width: 200
        },
        {
            title: 'NO. OF CALENDARS',
            dataIndex: 'calendar_count',
            key: 'calendar_count',
            align: 'center',
            width: 200
        },
        {
            title: 'PRODUCT STATUS',
            dataIndex: 'product_status',
            key: 'status',
            align: 'center',
            width: 150,
            render: (_text, record) => {
                const status =
                    (record.product_status === 'paused' && 'Paused') ||
                    (record.product_status === 'stopped' && 'Stopped') ||
                    (record.product_status === 'cancelled' && 'Cancelled') ||
                    (record.product_status === 'not_in_use' && 'Not in use') ||
                    (record.product_status === 'in_use' && 'In use') ||
                    (record.product_status === ('' || null || undefined) && 'N/A');

                return (
                    <div className="fs-14 text-nowrap">
                        {/* <span className={`calendar_activity-status __${productStatus}`}></span> */}
                        <span className="fs-14 text-gray text-capitalize">{status}</span>
                    </div>
                );
            }
        },
        {
            title: 'AVAILABLITY STATUS',
            dataIndex: 'product_availibility',
            key: 'ccm_availability',
            align: 'center',
            width: 200,
            render: (_text, record) => {
                const status = record.ccm_availability > 0 ? 'yes' : 'no';
                return (
                    <div className="fs-14 text-nowrap">
                        <span
                            className={`calendar_activity-status __${status
                                .toLowerCase()
                                .replace(/ /g, '_')}`}></span>
                        <span className="fs-14 text-gray text-capitalize">{status}</span>
                    </div>
                );
            }
        },
        {
            title: 'UPDATE AVAILABILITY',
            dataIndex: 'update',
            key: 'update',
            align: 'center',
            width: 200,
            render: (_, record) => {
                return (
                    <div>
                        <Button
                            className={`text-uppercase ${this.context.assignComponentPermission(
                                'availability-update'
                            )}`}
                            type="primary"
                            size="small"
                            onClick={() =>
                                this.props.history.push(`/availability-update/${record.id}`)
                            }>
                            UPDATE
                        </Button>
                    </div>
                );
            }
        },
        {
            title: 'SYNC PRODUCTS',
            dataIndex: 'sync',
            key: 'sync',
            align: 'center',
            width: 160,
            render: () => {
                return (
                    <div>
                        <Button
                            className={`text-uppercase ${this.context.assignComponentPermission(
                                'availability-sync'
                            )}`}
                            disabled
                            type="primary"
                            size="small"
                            onClick={() => this.setState({ visibleProductSynkDrawer: true })}>
                            SYNC
                        </Button>
                    </div>
                );
            }
        },
        {
            title: 'Actions',
            dataIndex: 'availabilityAction',
            key: 'availabilityAction',
            width: 100,
            render: (_, record) => {
                return (
                    <div className="text-center">
                        <Dropdown overlay={this.availabilityActionMenu(record)} trigger={['click']}>
                            <span role="button" tabIndex="0">
                                <MoreOutlined rotate={90} style={{ fontSize: '30px' }} />
                            </span>
                        </Dropdown>
                    </div>
                );
            }
        }
    ];
    availabilityActionMenu = (record) => {
        return (
            <Menu
                onClick={({ key }) => {
                    this.onMenuClick(key, record);
                    this.setState({ status: record.product_status, openedRecord: record });
                }}
                className="dropdown-action-menu">
                <Menu.Item key="0">View Details</Menu.Item>
                {record.product_status === 'paused' ||
                record.product_status === 'stopped' ||
                record.product_status === 'cancelled' ? (
                    <Menu.Item key="4">Reinstate Sales</Menu.Item>
                ) : (
                    <>
                        <Menu.Item key="1">Pause Sales</Menu.Item>
                        <Menu.Item key="2">Stop Sales</Menu.Item>
                        <Menu.Item key="3">Cancel Sales</Menu.Item>
                    </>
                )}
                <Menu.Item key="5">Archive Product</Menu.Item>
            </Menu>
        );
    };

    //Functions for Opening modal and Saving data
    onMenuClick(key, record) {
        if (key == 5) {
            if (record.product_status !== 'stopped' || record.product_status !== 'cancelled') {
                this.setState({ canNotArchive: true });
                this.setState({ confirmationWindow: false });
            }

            if (record.product_status === 'stopped' || record.product_status === 'cancelled') {
                this.setState({ confirmationWindow: true });
                this.setState({ canNotArchive: false });
            }
            this.setState({ archiveSalesConfirmModal: true });
        } else if (key == 3) {
            this.setState({ cancelSalesConfirmModal: true });
        } else if (key == 2) {
            this.setState({ stopSalesConfirmModal: true });
        } else if (key == 1) {
            this.setState({ pauseSalesConfirmModal: true });
        } else if (key == 4) {
            this.setState({ reinstateConfirmSaleModal: true });
        } else if (key == 0) {
            this.props.setCurrentProduct(record?.id);
            this.setState({ visibleProductSummaryDrawer: true });
        }
    }
    handleReinstateSaleToggle = () => {
        this.setState({
            reinstateConfirmSaleModal: !this.state.reinstateConfirmSaleModal
        });
    };
    toggleModal(key) {
        this.setState({ [key]: !this.state[key] });
    }

    changeSettings(key) {
        this.setState((prevState) => ({
            settings: !prevState.settings,
            selected: key
        }));
    }

    saveData(data) {
        localStorage.setItem('sync', data);
    }

    refreshList = () => {
        const { page, perPage, dateQuery } = this.state;
        this.props.getAvailabilities({ page, perPage, dateQuery });
    };

    render() {
        const {
            canNotArchive,
            confirmationWindow,
            pauseSalesConfirmModal,
            visibleProductSummaryDrawer,
            visibleProductSynkDrawer,
            stopSalesConfirmModal,
            cancelSalesConfirmModal,
            archiveSalesConfirmModal,
            exportSyncProductConfirmModal,
            filtersVisibility,
            openedRecord
        } = this.state;

        const { availability_data, loading } = this.props;

        return (
            <>
                <PauseModal
                    isOpen={pauseSalesConfirmModal}
                    toggleModal={() => this.toggleModal('pauseSalesConfirmModal')}
                    refreshList={this.refreshList}
                    openedRecord={openedRecord}
                />
                <ArchiveModal
                    isOpen={archiveSalesConfirmModal}
                    canNotArchive={canNotArchive}
                    confirmationWindow={confirmationWindow}
                    setConfirmationWindow={() => this.setState({ confirmationWindow: false })}
                    setCanNotArchive={() => this.setState({ canNotArchive: false })}
                    toggleModal={() => this.toggleModal('archiveSalesConfirmModal')}
                    refreshList={this.refreshList}
                    openedRecord={openedRecord}
                />
                <StopModal
                    isOpen={stopSalesConfirmModal}
                    toggleModal={() => this.toggleModal('stopSalesConfirmModal')}
                    refreshList={this.refreshList}
                    openedRecord={openedRecord}
                />
                <CancelModal
                    isOpen={cancelSalesConfirmModal}
                    toggleModal={() => this.toggleModal('cancelSalesConfirmModal')}
                    refreshList={this.refreshList}
                    openedRecord={openedRecord}
                />
                <ExportSyncModal
                    isOpen={exportSyncProductConfirmModal}
                    toggleModal={() => this.toggleModal('exportSyncProductConfirmModal')}
                />
                <ReinstateSaleModal
                    refreshList={this.refreshList}
                    openedRecord={this.state.openedRecord}
                    handleReinstateSaleToggle={this.handleReinstateSaleToggle}
                    confirmationWindow={true}
                    isOpen={this.state.reinstateConfirmSaleModal}
                />
                <Availability title={`Availability`}>
                    <div>
                        <div className="main-calendar-table-section mb-40px">
                            <AvailabilityFilter
                                onChangeFilter={this.onChangeFilter}
                                onClearFilter={this.onClearFilter}
                                filter={filtersVisibility}
                                setFilter={this.handleFiterVisibility}
                                filterState={this.state.filter}
                            />
                            <div
                                className="white-bg-color-table pl-1"
                                style={{
                                    marginTop: 20,
                                    overflowY:
                                        availability_data?.data.length != 0 ? 'scroll' : 'hidden'
                                }}>
                                {loading && <Loader typeContainer={true} />}
                                <div>
                                    <Table
                                        className="customer-calendars-table"
                                        columns={this.columns}
                                        dataSource={availability_data?.data}
                                        // tableLayout="auto"
                                        scroll={
                                            {
                                                // y: 'calc(100vh - 342px)',
                                                // x: 'max-content'
                                            }
                                        }
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between mb-3">
                            <div className="pagination-gallery">
                                <Pagination
                                    defaultCurrent={1}
                                    defaultPageSize={availability_data?.meta?.per_page || 10}
                                    current={this.state.page}
                                    showSizeChanger={false}
                                    total={
                                        availability_data &&
                                        availability_data.meta &&
                                        availability_data.meta.total
                                    }
                                    onChange={(page) => this.onChangePage(page)}
                                />
                            </div>
                            <div className="">
                                <Feedback />
                            </div>
                        </div>

                        {/* Product Summary Drawer */}
                        {/* <ProductSummary
                            visible={visibleProductSummaryDrawer}
                            toggleDrawer={() =>
                                this.setState({ visibleProductSummaryDrawer: false })
                            }
                        /> */}
                        <ProductDetailsDrawer
                            visible={visibleProductSummaryDrawer}
                            onClose={() => this.setState({ visibleProductSummaryDrawer: false })}>
                            <CalendarEventDetailContent
                                onClose={() =>
                                    this.setState({ visibleProductSummaryDrawer: false })
                                }
                            />
                        </ProductDetailsDrawer>

                        {/* Synk Product Drawer */}
                        <SynkProduct
                            visible={visibleProductSynkDrawer}
                            toggleDrawer={() => this.setState({ visibleProductSynkDrawer: false })}
                        />
                    </div>
                </Availability>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    availability_data: state.availability.availability_data,
    loading: state.loading
});

const mapDispatch = (dispatch) => ({
    getAvailabilities: (params) => dispatch(getAvailabilities(params)),
    setCurrentProduct: (params) => dispatch(setCurrentProduct(params))
});

AvailabilityData.propTypes = {
    getAllCategories: PropTypes.func,
    getAvailabilities: PropTypes.func,
    products: PropTypes.array,
    categories: PropTypes.array,
    availability_data: PropTypes.shape({
        data: PropTypes.array,
        meta: PropTypes.shape({
            per_page: PropTypes.number,
            total: PropTypes.number
        })
    }),
    loading: PropTypes.bool,
    history: PropTypes.object,
    setCurrentProduct: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(AvailabilityData));

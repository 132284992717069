export const productViewData = [
    {
        name: 'Nonso Chuks',
        booking: ' Awaiting confirmation',
        id: '01235',
        length: '1 wk',
        calendar_title: 'Victorial Falls & Choba Safari Experience',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Nora Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                date: ' Mon , 11 September, 2021',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                date: ' Mon , 11 September, 2021',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                date: ' Mon , 11 September, 2021',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: '3 Adult, 1 Child',
        no_of_activities: 3,
        lead_travellers: 'Buki, John Dou',
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    }
];

export const responsive = {
    eventGallery: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};

export const makingABookingData = [
    {
        name: 'Nonso Chuks',
        booking: ' Awaiting confirmation',
        id: '01235',
        length: '1 wk',
        calendar_title: 'Victorial Falls & Choba Safari Experience',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Nora Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                date: ' Mon , 11 September, 2021',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                date: ' Mon , 11 September, 2021',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                date: ' Mon , 11 September, 2021',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    },
    {
        name: 'Neville Chuks',
        booking: ' Amendment Request',
        id: '01246',
        length: '>1wk',
        calendar_title: 'Accra Beach',
        product_title: 'Paranoma Route',
        product_type: 'Main',
        product_cat: 'Activity',
        product_sub_cat: 'Activity',
        no_of_trvellers: 3,
        no_of_activities: 3,
        travellers: [
            {
                title: 'John Dou(Lead Traveller)',
                first_name: 'John',
                middle_name: 'Dave',
                last_name: 'Dou',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'Ledley Baker',
                first_name: 'Ledley',
                middle_name: 'Baker',
                last_name: 'Baker',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            },
            {
                title: 'John King',
                first_name: 'John',
                middle_name: 'King',
                last_name: 'King',
                age_group: 'Adult',
                weight: 89,
                height: 5.9,
                DOB: '09/10/1990',
                passport_details: '',
                arrival_flight_details: '',
                departure_flight_details: '',
                arrival_ship_details: '',
                shore_excursion_details: '',
                arrival_train_details: '',
                departure_train_details: ''
            }
        ],
        products: [
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'add-on',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            },
            {
                type: 'main',
                name: 'Victoria Falls & Choba Safari Experience',
                time: '7:00am-11:00am',
                main_destination: '',
                description:
                    'Kruger National Park is a South African National Park and one of the largest game reserves in Africa. It covers an area of 19,485 km² in the provinces of Limpopo and Mpumalanga in.'
            }
        ],
        product_date: '07/09/20'
    }
];

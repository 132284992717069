/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';

import React, { useState } from 'react';
import './Personalcare.scss';
import { Link } from 'react-router-dom';
import CalendarComponent from '../../../molecules/calendar/Calendar';
import VendorDashboard from '../vendor-dashboard';

export default function Personalcare() {
    const { Panel } = Collapse;
    // const [selectedDays, setselectedDays] = useState([]);
    const [days, setdays] = useState([
        {
            day: 1
        }
    ]);

    const addDay = () => {
        let newDay = days[days.length - 1].day + 1;
        setdays([...days, { day: newDay }]);
    };

    // const onChange = (e) => {};

    // const pickDay = (i) => {
    //     let present = selectedDays.find((n) => n === i);
    //     present
    //         ? setselectedDays(selectedDays.filter((d) => d !== i))
    //         : setselectedDays([...selectedDays, i]);
    // };

    return (
        <>
            <VendorDashboard>
                <div className="personal-care-wrap">
                    <div className="personal-care">
                        <p className="page-title">
                            Tell us about your ‘Product Title’ - Personal Care Service
                        </p>
                        <p className="page-sub-title">
                            Be sure to enter all key attractions in your itinerary. This helps
                            travelers find and book your experience.
                        </p>
                        <div className="brown-patch" style={{ borderRadius: 12 }}>
                            <Collapse bordered={false} defaultActiveKey={['1']}>
                                {days.map((item, i) => (
                                    <Panel header={`Day ${item.day}`} key={`day${i}`}>
                                        <div
                                            className="white-bg"
                                            style={{ padding: '15px 30px', borderRadius: 15 }}>
                                            <p
                                                className="patch-title"
                                                style={{ fontWeight: '600' }}>
                                                Spa / Wellness
                                            </p>
                                            <p className="patch-link">
                                                <Link to="/personal-care/add-wellness-service">
                                                    <span style={{ color: '#A2792C' }}>
                                                        <PlusCircleOutlined />
                                                    </span>{' '}
                                                    <span style={{ color: '#333333' }}>
                                                        Add a Spa / Wellness Service
                                                    </span>
                                                </Link>
                                            </p>
                                        </div>
                                    </Panel>
                                ))}
                            </Collapse>

                            <p
                                className="patch-link p10"
                                style={{ cursor: 'pointer', fontWeight: '500' }}
                                onClick={addDay}>
                                <PlusCircleOutlined color="#A2792C" /> Add a Spa / Wellness Service
                            </p>
                        </div>

                        <Button
                            type="primary"
                            size="large"
                            className="mt45 pl30 pr30"
                            style={{ borderRadius: 30 }}>
                            <span style={{ fontSize: 14 }}>Update Calender & Continue</span>
                        </Button>
                    </div>
                    <div className="white-bg mt40">
                        <CalendarComponent />
                    </div>
                </div>
            </VendorDashboard>
        </>
    );
}

import React from 'react';
import MoreInfo from 'assets/images/more_info.svg';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';

const MoreInfoTooltip = ({ title, icon }) => {
    return (
        <Tooltip title={title}>
            {icon ?? <img className="ml-1 align-top" src={MoreInfo} alt="" />}
        </Tooltip>
    );
};

MoreInfoTooltip.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.any
};

export default MoreInfoTooltip;

import React, { useContext } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Card, CardBody } from 'reactstrap';
import PlusIcon from 'components/atoms/icons/plusIcon';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';
import { ComponentPermissionContext } from 'context/rolesandpermission';

const CustomerExperienceCard = ({ vendorRating }) => {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    return (
        <Card
            className={`border-secondary card-sm mb-10px ${assignComponentPermission(
                'customer-experience'
            )}`}>
            <CardBody
                className={`d-flex align-items-center ${assignComponentPermission(
                    'customer-experience-dashboard'
                )}`}>
                <div
                    className={`flex-grow-1 mr-2  ${assignComponentPermission('calendar-rating')}`}>
                    <h6 className="fs-16 fw-600 mb-0">Customer Experience</h6>
                    <span
                        role="button"
                        className={`text-nowrap fs-12 ff-special text-primary fw-400 ${assignComponentPermission(
                            'calendar-rating-dashboard'
                        )}`}>
                        Increase Rating <PlusIcon />
                    </span>
                </div>
                <div style={{ width: 56 }}>
                    <CircularProgressbar
                        value={vendorRating?.customer_experience}
                        text={`${vendorRating?.customer_experience}%`}
                        styles={{
                            path: {
                                // Path color
                                stroke: `#E2C022`
                            },
                            trail: {
                                stroke: '#EDF1F7'
                            },
                            text: {
                                // Text color
                                fill: '#000',
                                // Text size
                                fontSize: '16px'
                            }
                        }}
                    />
                </div>
            </CardBody>
        </Card>
    );
};

CustomerExperienceCard.propTypes = {
    vendorRating: PropTypes.any
};

const mapStateToProps = (state) => ({
    vendorRating: state?.profile?.vendor_rating
});
export default connect(mapStateToProps)(CustomerExperienceCard);

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ClockIcon } from 'components/atoms';

const EventBookingTimePeriods = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<ClockIcon />}
            panelTitle="Booking Time Periods"
            isEditable={true}
            onEdit={onEdit}>
            <>
                {!product?.booking_time_periods?.time_period ? (
                    <></>
                ) : (
                    <Row className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6} md={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    Suggested Time
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6} md={6}>
                            <span className="fs-18 lh-1-7">
                                {product?.booking_time_periods?.time_period?.from}
                                {' to '}
                                {product?.booking_time_periods?.time_period?.to}
                            </span>
                        </Col>
                    </Row>
                )}

                {!product?.booking_time_periods?.duration ? (
                    <></>
                ) : (
                    <Row className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6} md={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Duration</span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6} md={6}>
                            <span className="fs-18 lh-1-7">
                                {product?.booking_time_periods?.duration}
                                {' mins'}
                            </span>
                        </Col>
                    </Row>
                )}

                {!product?.booking_time_periods?.within_time_periods ? (
                    <></>
                ) : (
                    <>
                        <h6 className="fs-18 fw-500 text-uppercase mt-3">
                            Available on Time Periods
                        </h6>
                        <ul>
                            {product?.booking_time_periods?.within_time_periods.map(
                                (item, index) => (
                                    <li key={index} className="fs-18 mb-3">
                                        {item?.from}
                                        {' to '}
                                        {item?.to}
                                    </li>
                                )
                            )}
                        </ul>
                    </>
                )}
            </>
        </DetailedPanel>
    );
};

EventBookingTimePeriods.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventBookingTimePeriods;

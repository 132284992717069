import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { TicketIcon, TicketPreview } from 'components/atoms';
import { Link } from 'react-router-dom';
import { getYesNo, getProductBookingPolicyContent } from 'utils';
import CollapseMoreLess from '../../collapse-more-less';

const EventBookingDetails = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<TicketIcon />}
            panelTitle="Booking & Tickets"
            isEditable={true}
            onEdit={onEdit}>
            <h6 className="fa-18 fw-500 text-uppercase mb-2">Cut-off time</h6>
            <p className="fs-18 lh-1-7">
                {product?.booking_ticket_details?.cut_off?.time}{' '}
                {product?.booking_ticket_details?.cut_off?.time_type}{' '}
                {product?.booking_ticket_details?.cut_off?.type}
            </p>
            <Link to="#" className="fs-16 fw-500 text-primary">
                <i>Cancellation Policy</i>
            </Link>
            <hr />
            <h6 className="fa-18 fw-500 text-uppercase mb-2">
                {product?.booking_ticket_details?.policy?.type}
            </h6>
            <p className="fs-18 lh-1-7">
                {getProductBookingPolicyContent(product?.booking_ticket_details?.policy?.content)}
            </p>

            <Link to="#" className="fs-16 fw-500 text-primary">
                <i>Add Policy</i>
            </Link>
            <hr />
            <p className="fs-18 lh-1-7">
                {product?.booking_ticket_details?.add_policy?.length > 0 &&
                    product?.booking_ticket_details?.add_policy.map(
                        (item, index) =>
                            `${item}${
                                index < product?.booking_ticket_details?.add_policy.length - 1
                                    ? ','
                                    : ''
                            } `
                    )}
            </p>
            <h6 className="fa-18 fw-500 text-uppercase mb-1 mt-2">Traveller Information</h6>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={6}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Full names</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={6}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product?.booking_ticket_details?.traveler_information?.full_name)}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={6}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Weights</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={6}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product?.booking_ticket_details?.traveler_information?.weights)}
                    </span>
                </Col>
            </Row>

            <CollapseMoreLess>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6} md={6}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Heights</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6} md={6}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(
                                product?.booking_ticket_details?.traveler_information?.heights
                            )}
                        </span>
                    </Col>
                </Row>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6} md={6}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Birth Dates</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6} md={6}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(
                                product?.booking_ticket_details?.traveler_information?.date_of_birth
                            )}
                        </span>
                    </Col>
                </Row>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6} md={6}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Passport Details</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6} md={6}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(
                                product?.booking_ticket_details?.traveler_information
                                    ?.passport_details
                            )}
                        </span>
                    </Col>
                </Row>
            </CollapseMoreLess>
            <h6 className="fa-18 fw-500 text-uppercase mb-1 mt-2 pt-2">Ticket Setup</h6>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Ticket Type</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {product?.booking_ticket_details?.ticket_setup?.ticket_type
                            .split('_')
                            .join(' ')}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Ticket Per Booking</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {product?.booking_ticket_details?.ticket_setup?.ticket_per_booking &&
                            product?.booking_ticket_details?.ticket_setup?.ticket_per_booking
                                .split('_')
                                .join(' ')}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Entry Ticket</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {
                            product?.booking_ticket_details?.ticket_setup?.ticket_redemption
                                ?.entry_ticket
                        }
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Redemption Instructions
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {
                            product?.booking_ticket_details?.ticket_setup?.ticket_redemption
                                ?.redemption_instructions
                        }
                    </span>
                </Col>
            </Row>
            <div className="mt-2">
                <TicketPreview
                    cancellationPolicy={getProductBookingPolicyContent(
                        product?.booking_ticket_details?.policy?.content
                    )}
                    productTitle={product?.title}
                />
            </div>
        </DetailedPanel>
    );
};

EventBookingDetails.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventBookingDetails;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, TimePicker, Select } from 'antd';
import './index.scss';
import Display from '../../atoms/display';

const HOUR_FORMAT = 'HH';
const MINUTES_FORMAT = 'mm';

function AtcTimePicker(props) {
    const { showAmPm = true } = props;
    return (
        <div className="atc-time-picker">
            <Row gutter={12}>
                <Col>
                    <TimePicker
                        size="large"
                        defaultValue={0}
                        format={HOUR_FORMAT}
                        placeholder="0"
                    />
                    <span className="atc-time-picker__tag">hours</span>
                </Col>
                <Col>
                    <TimePicker
                        size="large"
                        defaultValue={0}
                        format={MINUTES_FORMAT}
                        placeholder="0"
                    />
                    <span className="atc-time-picker__tag">minutes</span>
                </Col>
                <Display show={showAmPm}>
                    <Col>
                        <Select size="large" defaultValue="AM">
                            {['AM', 'PM'].map((item) => (
                                <Select.Option key={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Display>
            </Row>
        </div>
    );
}

AtcTimePicker.propTypes = {
    showAmPm: PropTypes.bool
};

export default AtcTimePicker;

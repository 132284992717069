import React, { Component } from 'react';
import SyncProducts from '../../../pages/sync-availability/sync-products/index.js';
import VendorDashboard from '../vendor-dashboard';

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <VendorDashboard>
                <SyncProducts />
            </VendorDashboard>
        );
    }
}

import React, { useState, useEffect } from 'react';
import './index.scss';

import { Checkbox } from 'antd';
import { Row } from 'reactstrap';
import SelectButton from '../select-button';
import Spacer from '../spacer';
import Proptypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { PriceUpdateConfirmationModal } from 'components/molecules';
import { updateProductsCalendarDay, updateProductPricing, getPackageById } from 'store/actions';
import { getValidPricingDays } from 'utils';

const DaysSelectorPackage = (props) => {
    const params = useParams();

    const { updateProductsCalendarDay, data, updateProductPricing, package_detail } = props;

    const validDays = getValidPricingDays(package_detail, props?.pricing);

    const [currentDay, setCurrentDay] = useState(null);
    const [days, setDays] = useState(validDays.map((name) => ({ name, isActive: false })));
    const [packageProductsDays, setPackageProductsDays] = useState([]);
    const [products, setProducts] = useState([]);
    const [pricedProducts, setPricedProducts] = useState([]);
    const [pricedAccmoProducts, setPricedAccmoProducts] = useState([]);

    const isSelectAll = () =>
        days.findIndex(
            (day) => day.isActive === false && packageProductsDays.indexOf(day.name) !== -1
        ) === -1;

    useEffect(() => {
        if (data) {
            setDays(validDays.map((item) => ({ name: item, isActive: data.indexOf(item) !== -1 })));
        }
    }, [data]);

    useEffect(() => {
        if (package_detail?.products && Array.isArray(package_detail?.products)) {
            const daysArray = [];
            for (let value of package_detail?.products) {
                if (value?.calendar_days?.days) daysArray.push(value.calendar_days.days);
            }
            const uniqueDays = daysArray.shift().filter(function (v) {
                return daysArray.every(function (a) {
                    return a.indexOf(v) !== -1;
                });
            });

            setPackageProductsDays(uniqueDays);
        }

        //checked for days, if still pricing and days not exisitn keep open the modal orthewise close it
        if (currentDay?.name) getProductsForDisableDay(currentDay);
    }, [package_detail?.products]);

    const onChange = (index) => {
        const tmp = days;
        tmp[index].isActive = !tmp[index].isActive;
        setDays([...tmp]);
        onSave(tmp);
    };
    const onSave = (data) => {
        const tmpSaveData = [];
        data.map((res) => {
            if (res.isActive) tmpSaveData.push(res.name);
        });
        props.onSave([...tmpSaveData]);
    };
    const onSelectAll = () => {
        if (isSelectAll()) {
            const falseTempDays = validDays.map((name) => ({
                name,
                isActive: false
            }));
            setDays([...falseTempDays]);
            props.onSave([]);
        } else {
            let tmpDays = validDays.map((name) => ({
                name,
                isActive: packageProductsDays.indexOf(name) !== -1
            }));
            setDays([...tmpDays]);
            props.onSave(validDays.filter((name) => packageProductsDays.indexOf(name) !== -1));
        }
    };

    //this fun checks if the day and price existed for a package prodcuts, and open close modal accordingly
    function getProductsForDisableDay(res) {
        const products = [];
        const pricedProducts = [];
        const pricedAccmoProducts = [];
        if (Array.isArray(package_detail?.products))
            for (let value of package_detail?.products) {
                if (value?.calendar_days?.days)
                    if (!value.calendar_days.days.includes(res.name)) {
                        if (Array.isArray(value.pricings) && value.pricings.length > 0) {
                            if (value?.product_category_id === 1)
                                pricedAccmoProducts.push({
                                    value: value.id,
                                    label: value.title,
                                    calendar_days: value?.calendar_days,
                                    pricings: value.pricings,
                                    suggestedDay: value?.suggestedDay,
                                    day_type: value?.day_type,
                                    product_category_id: value?.product_category_id
                                });
                            else
                                pricedProducts.push({
                                    value: value.id,
                                    label: value.title,
                                    calendar_days: value?.calendar_days,
                                    pricings: value.pricings,
                                    suggestedDay: value?.suggestedDay
                                });
                        } else
                            products.push({
                                value: value.id,
                                label: value.title,
                                calendar_days: value?.calendar_days,
                                suggestedDay: value?.suggestedDay
                            });
                    }
            }

        setProducts(products);
        setPricedProducts(pricedProducts);
        setPricedAccmoProducts(pricedAccmoProducts);
        if (products.length > 0 || pricedProducts.length > 0 || pricedAccmoProducts.length > 0)
            setCurrentDay(res);
        else setCurrentDay(null);
    }

    function onHandleConfirmation() {
        for (let value of products) {
            let calendar_days = {
                calendar_days: { days: [...value?.calendar_days?.days, currentDay.name] }
            };
            if (value?.suggestedDay) {
                calendar_days.suggested_day = value?.suggestedDay;

                if (params.id) calendar_days.calendar_id = Number(params.id);
            }

            updateProductsCalendarDay(calendar_days, value?.value).then((res) => {
                if (res?.payload) updateCalendarData(params.id);
            });
        }
        setCurrentDay(null);
    }

    function updateCalendarData(calendarId) {
        props.getPackageById(package_detail?.id, calendarId);
    }

    return (
        <>
            <Checkbox checked={isSelectAll()} onChange={onSelectAll}>
                Select all
            </Checkbox>

            <Spacer top={13} />
            <Row style={{ margin: 0 }}>
                {days.map((res, i) => (
                    <SelectButton
                        mt={props.mt}
                        key={i}
                        name={res.name}
                        isActive={res.isActive}
                        onClick={() => onChange(i)}
                        disabled={packageProductsDays.indexOf(res.name) === -1}
                        onDisabledClick={() => getProductsForDisableDay(res)}
                    />
                ))}
            </Row>
            <PriceUpdateConfirmationModal
                isModalOpen={currentDay}
                setModalOpenState={() => {
                    setCurrentDay(null);
                    setProducts([]);
                }}
                title={`Make Available For ${currentDay?.name}?`}
                products={products}
                pricedProducts={pricedProducts}
                pricedAccmoProducts={pricedAccmoProducts}
                currentDay={currentDay}
                updateProductPricing={updateProductPricing}
                updateProductsCalendarDay={updateProductsCalendarDay}
                handleConfirmation={async () => onHandleConfirmation()}
                updateCalendarData={updateCalendarData}
            />
        </>
    );
};
DaysSelectorPackage.propTypes = {
    onSave: Proptypes.func,
    data: Proptypes.array,
    pricing: Proptypes.any,
    updateProductsCalendarDay: Proptypes.any,
    mt: Proptypes.number,
    updateProductPricing: Proptypes.func,
    getPackageById: Proptypes.func,
    package_detail: Proptypes.object
};

const mapStateToProps = (state) => ({
    package_detail: state.product.package_detail
});

export default withRouter(
    connect(mapStateToProps, { updateProductsCalendarDay, updateProductPricing, getPackageById })(
        DaysSelectorPackage
    )
);

import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

const SaveCancel = (props) => {
    const {
        saveLoading,
        cancelClick,
        saveButtonName = 'Save',
        onClick,
        showSaveButton = true
    } = props;
    return (
        <div className="mt-50px mx-n1">
            {onClick ? (
                <Button
                    onClick={onClick}
                    type="primary"
                    className="btn-mw mx-1"
                    loading={saveLoading}>
                    {saveButtonName}
                </Button>
            ) : (
                showSaveButton && (
                    <Button
                        htmlType="submit"
                        type="primary"
                        className="btn-mw mx-1"
                        loading={saveLoading}>
                        {saveButtonName}
                    </Button>
                )
            )}

            <Button htmlType="button" type="dark" className="btn-mw mx-1" onClick={cancelClick}>
                Cancel
            </Button>
        </div>
    );
};

SaveCancel.propTypes = {
    saveLoading: PropTypes.any,
    cancelClick: PropTypes.func,
    saveButtonName: PropTypes.string,
    onClick: PropTypes.func,
    showSaveButton: PropTypes.bool
};

export default SaveCancel;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Button, Row } from 'reactstrap';
// import './index.scss';
import Edit from '../../../assets/images/edit.svg';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Proptypes from 'prop-types';
import Delete from '../../../assets/images/delete.svg';
import { ConfirmationModal } from 'components/molecules';
export default function AddTravellerInfoButton(props) {
    const { add = 'Add', moreInfo = true, data } = props;
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {data && Object.keys(data).length > 0 ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={() => setIsConfirmationModalOpen(true)}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                )}
            </div>
            {data && Object.keys(data).length > 0 ? (
                <Row className="actions">
                    <div>
                        {Object.keys(data).map((res, i) => (
                            <Row key={i}>
                                {res && res.replaceAll('_', ' ') + ' Added'}
                                <div className="group-btn">
                                    <img
                                        src={Edit}
                                        alt=""
                                        className="itinerary-img cursor-pointer"
                                        onClick={() => props.onEdit()}
                                    />
                                </div>
                            </Row>
                        ))}
                    </div>
                </Row>
            ) : null}

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setIsConfirmationModalOpen(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    setIsConfirmationModalOpen(false);
                    props.onDelete();
                }}
            />
        </div>
    );
}
AddTravellerInfoButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any
};

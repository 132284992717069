import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GroupBookingSupplementRow from './GroupBookingSupplementRow.jsx';

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        border: 'none'
    },
    body: {
        fontSize: 14,
        border: 'none'
    }
}))(TableCell);

const styles = {
    heading: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',
        textAlign: 'center',

        color: '#4F4F4F'
    }
};

// const StyledTableRow = withStyles((theme) => ({
//     root: {
//         '&:nth-of-type(odd)': {
//             backgroundColor: theme.palette.action.hover
//         }
//     }
// }))(TableRow);

const useStyles = makeStyles({
    table: {
        width: 'max-content'
    }
});

export default function CustomizedTables() {
    const classes = useStyles();

    return (
        <TableContainer>
            <Table className={classes.table} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell align="left"></StyledTableCell>
                        <StyledTableCell align="left" style={styles.heading}>
                            Min - Max booking per price
                        </StyledTableCell>
                        <StyledTableCell align="left"></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <GroupBookingSupplementRow />
                </TableBody>
            </Table>
        </TableContainer>
    );
}

import TextArea from 'antd/lib/input/TextArea';
import React, { Component } from 'react';
import {
    Button,
    Modal,
    Row,
    Col,
    FormGroup,
    Input,
    Label,
    ModalBody,
    ModalFooter
} from 'reactstrap';
import PropTypes from 'prop-types';

export default class ViewDisputeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: this.props.showModal
        };
        this.toggle = this.toggle.bind(this);
        this.openModal = this.openModal.bind(this);
    }

    toggle = () => {
        //alert("toggle");
        this.setState({ showModal: false });
    };
    openModal = () => {
        //alert("open modal");
        this.setState({ showModal: true });
    };
    render() {
        const { showModal, toggleModal } = this.props;
        return (
            <div>
                <Modal
                    isOpen={showModal}
                    toggle={toggleModal}
                    className="details-modal modal-dialog-centered">
                    <ModalBody>
                        <div className="text-left">
                            <Row className="">
                                <Col md="12">
                                    <h3>{this.props.title}</h3>
                                </Col>

                                <Col md="12">
                                    <h5>{this.props.description}</h5>
                                </Col>
                            </Row>

                            <Row className="mt-1 mb-2">
                                <Col md="12">Dispute Date</Col>
                                <Col md="3" xs="12">
                                    <FormGroup className="mr-4">
                                        <Label for="date">DD</Label>
                                        <Input
                                            type="number"
                                            id="date"
                                            name="date"
                                            value={this.props.date}
                                            className="modal-input"
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" xs="12">
                                    <FormGroup className="mr-4">
                                        <Label for="month">MM</Label>
                                        <Input
                                            type="number"
                                            id="month"
                                            name="month"
                                            value={this.props.month}
                                            className="modal-input "
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="3" xs="12">
                                    <FormGroup className="mr-4">
                                        <Label for="year">YYYY</Label>
                                        <Input
                                            type="number"
                                            id="year"
                                            name="year"
                                            value={this.props.year}
                                            className="modal-input"
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mr-4">
                                        <Label for="disputeTitle">Dispute Title</Label>
                                        <Input
                                            type="text"
                                            id="disputeTitle"
                                            name="disputeTitle"
                                            value={this.props.disputeTitle}
                                            className="modal-input"
                                            disabled
                                        />
                                    </FormGroup>
                                </Col>
                                <Col md="12">
                                    <FormGroup className="mr-4">
                                        <Label for="disputeMsg">Dispute Message</Label>
                                        <TextArea
                                            type="text"
                                            id="disputeMsg"
                                            name="disputeMsg"
                                            value={this.props.disputeMsg}
                                            className="modal-input modal-textarea"
                                            disabled></TextArea>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <div className="d-flex justify-content-center">
                            <Button color="primary" onClick={toggleModal} className="px-5">
                                OK
                            </Button>
                            <Button onClick={toggleModal} className="ml-1 close-btn px-5">
                                Cancel
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ViewDisputeModal.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    month: PropTypes.string.isRequired,
    year: PropTypes.string.isRequired,
    disputeTitle: PropTypes.string.isRequired,
    disputeMsg: PropTypes.string,
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func
};

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { ProductCategoryIcon } from 'components/atoms';
import { getAllDayEvents } from 'utils';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';
// top banner for pages
const ActivityPanelComponent = (props) => {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const {
        onAdd,
        selectedAllDayCategory,
        setSelectedAllDayCategory,
        products,
        calendar,
        selectedDate
        // active
    } = props;

    const allDayEvents = getAllDayEvents(products || [], selectedDate, calendar);
    let mainProductCategories;
    let activeEvents = {};
    // let mainProduct = null;
    if (allDayEvents) {
        mainProductCategories = [];
        for (const product of allDayEvents) {
            if (!activeEvents[product.product_category_id]) {
                activeEvents[product.product_category_id] =
                    product?.product_category_id === selectedAllDayCategory
                        ? 'stand-out'
                        : 'no-stand-out';
            }
            if (
                product.productType === 'main_product' ||
                product.productType === 'main_package_product'
            ) {
                mainProductCategories = [...mainProductCategories, product.product_category_id];
            }
        }
    }

    return (
        <>
            <div className={`activities-panel scrollable ${props.pannel} `}>
                {/* Add button */}
                {props.canAdd ? (
                    <div className="single-activity-wrapper">
                        <div
                            onClick={() => {
                                onAdd();
                            }}
                            onKeyDown={() => {}}
                            className={`btn-icon-activity _type-add 
                            ${props.buttonClass} 
                            ${assignComponentPermission('product')}`}
                            role="button"
                            tabIndex="0">
                            <div
                                className={`activity-icon ${assignComponentPermission('product')}`}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="11.5" stroke="#A2792C" />
                                    <circle cx="12" cy="12" r="10.5" fill="#A2792C" />
                                    <rect
                                        x="11.4287"
                                        y="7.42857"
                                        width="1.14286"
                                        height="9.14286"
                                        fill="white"
                                    />
                                    <rect
                                        x="16.5714"
                                        y="11.4286"
                                        width="1.14286"
                                        height="9.14286"
                                        transform="rotate(90 16.5714 11.4286)"
                                        fill="white"
                                    />
                                </svg>
                            </div>
                        </div>
                    </div>
                ) : null}
                {/* Hard coded all day product category ids [ 1,2,3,4 ] */}
                {[1, 2, 4, 3].map((id) =>
                    !activeEvents[id] ? null : (
                        <ActivityIcon
                            key={id}
                            panelProps={props}
                            activeEvents={activeEvents}
                            id={id}
                            setSelectedAllDayCategory={setSelectedAllDayCategory}
                            onAdd={onAdd}
                            mainProductCategories={mainProductCategories}
                        />
                    )
                )}
            </div>
        </>
    );
};

const ActivityIcon = ({
    panelProps,
    activeEvents,
    id,
    setSelectedAllDayCategory,
    onAdd,
    mainProductCategories
}) => {
    return (
        <div className="single-activity-wrapper" onClick={() => setSelectedAllDayCategory(id)}>
            <div
                className={`btn-icon-activity ${
                    panelProps?.pannel === 'stand-out' ||
                    (onAdd && activeEvents[id] === 'stand-out')
                        ? 'active'
                        : ''
                } ${
                    (activeEvents[id] === 'stand-out' && panelProps?.pannel === 'stand-out') ||
                    (onAdd && activeEvents[id] === 'stand-out')
                        ? '_alt'
                        : ''
                } ${panelProps?.buttonClass && panelProps?.buttonClass} ${
                    panelProps?.bookedEvents && panelProps?.bookedEvents.includes('food')
                        ? '_booked'
                        : ''
                }`}
                role="button"
                tabIndex="0">
                <div className="activity-icon">
                    <ProductCategoryIcon id={id} color={'currentColor'} />
                </div>
                {mainProductCategories && mainProductCategories.includes(id) ? (
                    <div className="featured-icon">
                        <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle cx="9" cy="9" r="8.5" fill="#A3792D" stroke="white" />
                            <path
                                d="M9.64293 6.08257V11.6666H8.52293V7.77057L7.09093 11.6666H6.11493L4.68293 7.78657V11.6666H3.55493V6.08257H4.92293L6.61893 10.2826L8.28293 6.08257H9.64293Z"
                                fill="white"
                            />
                            <path
                                d="M11.7923 9.57057V11.6666H10.6643V6.01857H12.7203C13.387 6.01857 13.8883 6.17857 14.2243 6.49857C14.5656 6.81857 14.7363 7.25323 14.7363 7.80257C14.7363 8.34123 14.5603 8.77057 14.2083 9.09057C13.8616 9.41057 13.3656 9.57057 12.7203 9.57057H11.7923ZM12.6003 8.66657C13.2616 8.66657 13.5923 8.37857 13.5923 7.80257C13.5923 7.5199 13.515 7.3039 13.3603 7.15457C13.2056 7.00523 12.9523 6.93057 12.6003 6.93057H11.7923V8.66657H12.6003Z"
                                fill="white"
                            />
                        </svg>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

ActivityIcon.propTypes = {
    panelProps: PropTypes.any,
    activeEvents: PropTypes.any,
    id: PropTypes.any,
    setSelectedAllDayCategory: PropTypes.any,
    onAdd: PropTypes.any,
    mainProductCategories: PropTypes.array.isRequired
};

ActivityPanelComponent.propTypes = {
    pannel: PropTypes.string,
    buttonClass: PropTypes.string,
    canAdd: PropTypes.bool,
    active: PropTypes.bool,
    hasMainProduct: PropTypes.bool,
    bookedEvents: PropTypes.array,
    onAdd: PropTypes.func,
    fetchAllDayProductCategories: PropTypes.func,
    date: PropTypes.string,
    products: PropTypes.any,
    selectedDate: PropTypes.any,
    all_day_product_categories: PropTypes.any,
    calendar: PropTypes.any,
    selectedAllDayCategory: PropTypes.any,
    setSelectedAllDayCategory: PropTypes.func,
    allDayEvents: PropTypes.any
};
const mapStateToProps = (state) => ({
    all_day_product_categories: state?.product?.all_day_product_categories
});
export default connect(mapStateToProps)(ActivityPanelComponent);

import React from 'react';
import Proptypes from 'prop-types';
import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';
import { Feedback } from 'components/molecules';

const CreateMainProductInitModal = (props) => {
    const { isOpen, toggleModal, setCreateMainProductPackageModal, setCreateMainProductModal } =
        props;
    return (
        <Modal size="lg" centered isOpen={isOpen}>
            <div className="modal-header flex-wrap flex-lg-nowrap">
                <h5 className="modal-title order-2 order-lg-1">Main Product</h5>
                {/* <div className="flex-shrink-0 order-1 order-lg-2 ml-lg-2">
                    <Button color="link" className="px-0 text-decoration-underline" type="button">
                        Go Back
                    </Button>
                </div> */}
            </div>
            <ModalBody className="d-flex flex-wrap justify-content-center gap-3 text-center py-lg-5">
                <div className="card card-add-new" role="button" style={{ width: 260 }}>
                    <div className="card-body">
                        <div className="mt-4">
                            <svg
                                width="49"
                                height="48"
                                viewBox="0 0 49 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="20.7413" width="8" height="48" fill="#4F4F4F" />
                                <rect
                                    x="48.7413"
                                    y="20"
                                    width="8"
                                    height="48"
                                    transform="rotate(90 48.7413 20)"
                                    fill="#4F4F4F"
                                />
                            </svg>
                        </div>
                        <div className="fs-14 mt-3">Add new product</div>

                        <div className="mt-4">
                            <Button
                                color="primary"
                                size="lg"
                                block
                                onClick={() => {
                                    setCreateMainProductPackageModal(true);
                                    toggleModal();
                                }}>
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card card-add-new" role="button" style={{ width: 260 }}>
                    <div className="card-body">
                        <div className="mt-4">
                            <svg
                                width="42"
                                height="44"
                                viewBox="0 0 42 44"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M41.6163 4.375H17.6163V7.375H41.6163V4.375Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M10.3663 4.375H0.366333V7.375H10.3663V4.375Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M6.86633 10.875L6.86633 0.875L3.86633 0.875L3.86633 10.875L6.86633 10.875Z"
                                    fill="#4F4F4F"
                                />
                                <path d="M41.6163 20.5H17.6163V23.5H41.6163V20.5Z" fill="#4F4F4F" />
                                <path
                                    d="M41.6163 36.625H17.6163V39.625H41.6163V36.625Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M5.61633 43.375C8.50383 43.375 10.8663 41.0125 10.8663 38.125C10.8663 35.2375 8.50383 32.875 5.61633 32.875C2.72883 32.875 0.366333 35.2375 0.366333 38.125C0.366333 41.0125 2.72883 43.375 5.61633 43.375ZM5.61633 35.875C6.85383 35.875 7.86633 36.8875 7.86633 38.125C7.86633 39.3625 6.85383 40.375 5.61633 40.375C4.37883 40.375 3.36633 39.3625 3.36633 38.125C3.36633 36.8875 4.37883 35.875 5.61633 35.875Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M5.61633 27.25C8.50383 27.25 10.8663 24.8875 10.8663 22C10.8663 19.1125 8.50383 16.75 5.61633 16.75C2.72883 16.75 0.366333 19.1125 0.366333 22C0.366333 24.8875 2.72883 27.25 5.61633 27.25ZM5.61633 19.75C6.85383 19.75 7.86633 20.7625 7.86633 22C7.86633 23.2375 6.85383 24.25 5.61633 24.25C4.37883 24.25 3.36633 23.2375 3.36633 22C3.36633 20.7625 4.37883 19.75 5.61633 19.75Z"
                                    fill="#4F4F4F"
                                />
                            </svg>
                        </div>
                        <div className="fs-14 mt-3">Add from an existing product</div>

                        <div className="mt-4">
                            <Button
                                color="primary"
                                size="lg"
                                block
                                onClick={() => {
                                    setCreateMainProductModal(true);
                                    toggleModal();
                                }}>
                                Add existing
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <Button
                    color="dark"
                    className="btn-mw flex-grow-1 flex-lg-grow-0"
                    onClick={toggleModal}>
                    Cancel
                </Button>
                <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
            </ModalFooter>
        </Modal>
    );
};
CreateMainProductInitModal.propTypes = {
    isOpen: Proptypes.bool,
    toggleModal: Proptypes.func,
    setCreateMainProductModal: Proptypes.func,
    setCreateMainProductPackageModal: Proptypes.func
};

export default CreateMainProductInitModal;

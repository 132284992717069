/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Row, Col, Button } from 'antd';
import './index.scss';
import Feedback from '../../../molecules/user-feedback';

import { Link } from 'react-router-dom';

import note from '../../../../assets/images/note.svg';
import Spacer from '../../../atoms/spacer';

function Index() {
    return (
        <div className="product-content-landing">
            <Row>
                <Col span={16}>
                    <h4>Step 2 of 4: Product Content</h4>
                    <p>
                        This is the fun part! In this section, for each of the product categories
                        you have entered on the previous page, you'll describe your Itinerary for
                        each day, and add details like duration, languages to your customers
                        calendar, pricing, etc.
                    </p>
                    <Link to="product-content/details">
                        <Button style={{ height: 42 }} type="primary" shape="round">
                            Create Product Activities
                        </Button>
                    </Link>
                </Col>
                <Col span={8}>
                    <div className="product-content-landing__img">
                        <img className="" src={note} alt="" />
                    </div>
                </Col>
            </Row>
            <Spacer top={60} />
            <div>
                <div className="flex-shrink-0 mt-3 mt-lg-0">
                    <div className="d-flex justify-content-end">
                        <Feedback />
                    </div>
                </div>
            </div>
        </div>
    );
}

Index.propTypes = {};

export default Index;

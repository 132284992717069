import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Marker from '../../atoms/marker';
import './index.scss';
import Proptypes from 'prop-types';

const MyGoogleMap = (props) => {
    const [state, setState] = useState({
        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [9.1021, 18.2812],
        zoom: 12,
        address: '',
        draggable: true,
        lat: null,
        lng: null
    });

    useEffect(() => {
        if (state.mapApiLoaded) {
            setInitLocation();
        }
    }, [state.mapApiLoaded, props.initPlace]);

    const onMarkerInteraction = (childKey, childProps, mouse) => {
        setState({
            ...state,
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    };
    const onMarkerInteractionMouseUp = () => {
        setState({
            ...state,
            draggable: true
        });
        _generateAddress();
    };

    const _onChange = ({ center, zoom }) => {
        setState({
            ...state,
            center: center,
            zoom: zoom
        });
    };

    const _onClick = (value) => {
        setState({
            ...state,
            lat: value.lat,
            lng: value.lng
        });
    };

    //loading map
    const apiHasLoaded = async (map, maps) => {
        setState({
            ...state,
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps
        });
        if (!props.initPlace) setCurrentLocation();
    };

    //geocoding coordinates to address
    const _generateAddress = (data) => {
        try {
            data = data || state;
            const { mapApi, lat, lng } = data;

            const geocoder = new mapApi.Geocoder();
            geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        setState({
                            ...data,
                            address: results[0].formatted_address
                        });
                        props.onSave(results[0]);
                    } else {
                        window.alert('No results found');
                    }
                } else {
                    window.alert('Geocoder failed due to: ' + status);
                }
            });
        } catch (error) {
            console.log('error in geocode', error);
        }
    };

    // Get Current Location Coordinates
    const setCurrentLocation = () => {
        if ('geolocation' in navigator) {
            new Promise((resolve) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    setState({
                        ...state,
                        center: [position.coords.latitude, position.coords.longitude],
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                });
                resolve(true);
            }).then(() => _generateAddress());
        }
    };
    //set initial location
    const setInitLocation = async () => {
        const { mapApi, mapInstance } = state;
        const { initPlace } = props;
        if (mapApi) {
            const service = new mapApi.places.PlacesService(mapInstance);
            new Promise((resolve, reject) => {
                service.getDetails({ placeId: initPlace }, (place, status) => {
                    if (status === mapApi.places.PlacesServiceStatus.OK) {
                        setState({
                            ...state,
                            center: [place.geometry.location.lat(), place.geometry.location.lng()],
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        });
                        _generateAddress({
                            ...state,
                            center: [place.geometry.location.lat(), place.geometry.location.lng()],
                            lat: place.geometry.location.lat(),
                            lng: place.geometry.location.lng()
                        });
                        resolve(true);
                    } else reject(false);
                });
            });
        }
    };

    const { height = 229 } = props;

    return (
        <div
            className="google-map"
            style={{
                height: height
            }}>
            <GoogleMapReact
                defaultCenter={state.center}
                center={state.center}
                zoom={state.zoom}
                draggable={state.draggable}
                onChange={_onChange}
                onChildMouseDown={onMarkerInteraction}
                onChildMouseUp={onMarkerInteractionMouseUp}
                onChildMouseMove={onMarkerInteraction}
                onChildClick={() => {}}
                onClick={_onClick}
                onGoogleApiLoaded={({ map, maps }) => apiHasLoaded(map, maps)}
                yesIWantToUseGoogleMapApiInternals
                bootstrapURLKeys={{
                    key: 'AIzaSyCpAFaIB2jAopbbdSpklnqMT-E9nFHqzPI',
                    libraries: ['places', 'geometry']
                }}>
                <Marker text={state.address} lat={state.lat} lng={state.lng} />
            </GoogleMapReact>
        </div>
    );
};
MyGoogleMap.propTypes = {
    height: Proptypes.number,
    initPlace: Proptypes.string,
    onSave: Proptypes.func
};

export default MyGoogleMap;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { PlusCircleOutlined } from '@ant-design/icons';
import './index.scss';

function prompter(props) {
    const { text, onClick, textColor, orientation, fontSize, classNames = '' } = props;
    let classes = `prompter ${classNames}`;
    if (orientation && orientation === 'vertical') {
        classes += ' prompter--vertical';
    }
    return (
        <div className={classes} onClick={onClick}>
            <PlusCircleOutlined />
            <span style={{ [textColor && 'color']: textColor, fontSize: fontSize || '16px' }}>
                {text}
            </span>
        </div>
    );
}

prompter.propTypes = {
    orientation: PropTypes.string,
    text: PropTypes.string,
    textColor: PropTypes.string,
    onClick: PropTypes.func
};

export default prompter;

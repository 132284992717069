import React from 'react';
import Proptypes from 'prop-types';
import { Modal, ModalBody, Button } from 'reactstrap';
import warningIcon from '../../../assets/images/warning-icon.svg';
import './index.css';
import PropTypes from 'prop-types';

/**
 * Modal That confirms If user wants to leave unsaved changes to edit or continue editing
 */
const ConfirmEditModal = ({ modal, handleModal, closeModal }) => {
    return (
        <Modal size="md" centered isOpen={modal} toggle={handleModal} className="modal">
            <ModalBody className="text-center">
                <img src={warningIcon} alt="" />
                <h3 className="modal-body-title-lg mt-2">
                    Are you sure you <br />
                    want to go back?
                </h3>
                <p className="modal-body-text text-center">Your Changes will not be saved.</p>
                <div className="mt-4 d-flex button-gap">
                    <Button color="primary" className="btn-mw mx-2" onClick={closeModal}>
                        Yes, Go back
                    </Button>
                    <Button color="dark" className="btn-mw mx-2" onClick={handleModal}>
                        Continue Editing
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
ConfirmEditModal.propTypes = {
    modal: Proptypes.bool,
    handleModal: Proptypes.func,
    closeModal: Proptypes.func
};

ConfirmEditModal.propTypes = {
    modal: PropTypes.bool,
    handleModal: PropTypes.func,
    closeModal: PropTypes.func
};

export default ConfirmEditModal;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Drawer, Menu, Dropdown } from 'antd';
import Feedback from '../../user-feedback';
import { getProductStatusColor, getProductStatusString } from 'utils';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
    setUpdateProductFields_,
    selectedExistingProduct_,
    currentProductFlow_
} from 'store/actions';
import { connect } from 'react-redux';
import LoaderComponent from 'components/atoms/loader';
import { MoreOutlined } from '@ant-design/icons';

const pencilIcon = (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="16.4121" cy="16.8867" r="16" fill="white" />
        <path
            d="M8.24614 19.9682L7.12022 24.8274C7.08138 25.0051 7.08271 25.1891 7.12411 25.3662C7.16552 25.5433 7.24596 25.7088 7.35954 25.8508C7.47313 25.9928 7.617 26.1076 7.78065 26.1869C7.94429 26.2661 8.12358 26.3078 8.3054 26.3089C8.39013 26.3175 8.47549 26.3175 8.56022 26.3089L13.4491 25.183L22.8358 15.8319L17.5973 10.6052L8.24614 19.9682Z"
            fill="#A2792C"
        />
        <path
            d="M25.7868 11.1504L22.2905 7.65409C22.0607 7.42538 21.7496 7.297 21.4253 7.297C21.1011 7.297 20.79 7.42538 20.5602 7.65409L18.6165 9.59779L23.849 14.8304L25.7928 12.8867C25.9065 12.7724 25.9966 12.6367 26.0579 12.4875C26.1191 12.3384 26.1504 12.1786 26.1498 12.0173C26.1493 11.856 26.1169 11.6965 26.0547 11.5477C25.9924 11.3989 25.9013 11.2639 25.7868 11.1504Z"
            fill="#A2792C"
        />
    </svg>
);

const ProductSummaryDrawer = (props) => {
    const {
        visible,
        onClose,
        currentProduct,
        setUpdateProductFields_,
        selectedExistingProduct_,
        setShowCurrentProductDrawer,
        setAddOnProductItineraryTimeModal,
        setSelectCalendarDaysBookingModal,
        currentProductFlow_
    } = props;
    const history = useHistory();
    const route = useRouteMatch();

    const actionsMenu = (
        <Menu
            onClick={({ key }) => {
                onMenuClick(key);
            }}
            className="dropdown-action-menu">
            <Menu.Item key="0">Duplicate</Menu.Item>
        </Menu>
    );
    const onMenuClick = (key) => {
        if (key == 0) {
            setShowCurrentProductDrawer(false);
            currentProductFlow_({ title: 'duplicate-product-summary' });
            selectedExistingProduct_(true);
            if (currentProduct?.day_type === 'all_day') setSelectCalendarDaysBookingModal(true);
            else setAddOnProductItineraryTimeModal(true);
        }
    };

    return (
        <Drawer
            width={609}
            placement="right"
            className="basic-drawer"
            closeIcon={
                <span role="img" aria-label="close" className="anticon anticon-close">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1988 11.9999L23.5439 2.65453C24.1521 2.04665 24.1521 1.06379 23.5439 0.45591C22.936 -0.15197 21.9532 -0.15197 21.3453 0.45591L11.9999 9.80132L2.65474 0.45591C2.04658 -0.15197 1.064 -0.15197 0.456124 0.45591C-0.152041 1.06379 -0.152041 2.04665 0.456124 2.65453L9.80125 11.9999L0.456124 21.3453C-0.152041 21.9532 -0.152041 22.9361 0.456124 23.544C0.759068 23.8472 1.15739 23.9995 1.55543 23.9995C1.95347 23.9995 2.35152 23.8472 2.65474 23.544L11.9999 14.1986L21.3453 23.544C21.6485 23.8472 22.0466 23.9995 22.4446 23.9995C22.8426 23.9995 23.2407 23.8472 23.5439 23.544C24.1521 22.9361 24.1521 21.9532 23.5439 21.3453L14.1988 11.9999Z"
                            fill="black"
                        />
                    </svg>
                </span>
            }
            onClose={onClose}
            visible={visible}>
            <div className="d-flex flex-column h-100">
                {currentProduct ? <></> : <LoaderComponent typeContainer={true} />}
                <div className="mb-2">
                    <h4 className="mb-3">
                        {currentProduct?.title}
                        {true && (
                            <span
                                className="pl-3"
                                role="button"
                                tabIndex="0"
                                title="Edit"
                                onClick={() => {
                                    if (
                                        route.path !== '/calendar/:id' &&
                                        currentProduct?.calendars
                                    ) {
                                        history.push(
                                            `/calendar/${currentProduct?.calendars[0].id}`
                                        );
                                    }
                                    onClose();
                                    selectedExistingProduct_(currentProduct);
                                    setUpdateProductFields_({
                                        editTitle: true
                                    });
                                }}>
                                {pencilIcon}
                            </span>
                        )}
                    </h4>
                    {true && (
                        <>
                            <span className="fs-14 fw-600">AVAILABLE: </span>
                            <span className={`calendar_activity-status __no ml-2`}></span>
                            <span className="fs-14 text-gray">No</span>
                            <span className="fs-14 fw-600 ml-4">PRODUCT STATUS: </span>
                            <span
                                className={`calendar_activity-status ${getProductStatusColor(
                                    currentProduct?.product_status
                                )} ml-2`}></span>
                            <span className="fs-14 text-gray">
                                {getProductStatusString(currentProduct?.product_status)}
                            </span>
                            <span
                                onClick={() =>
                                    history.push(`/availability-update/${currentProduct?.id}`)
                                }
                                className="pl-3"
                                role="button"
                                tabIndex="0"
                                title="Edit">
                                {pencilIcon}
                            </span>
                            <span className="ml-2">
                                <Dropdown overlay={actionsMenu} trigger={['click']}>
                                    <span className="text-primary" role="button" tabIndex="0">
                                        <MoreOutlined style={{ fontSize: '24px' }} />
                                    </span>
                                </Dropdown>
                            </span>
                        </>
                    )}
                </div>

                <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed pr-2">
                    <div className="ff-special">
                        <div>{currentProduct?.description}</div>
                    </div>
                    {props.children}
                </div>

                <div className="pt-3">
                    <div className="d-flex justify-content-between">
                        <Button color="primary" onClick={onClose}>
                            Go Back
                        </Button>
                        <Feedback drawerPlacement="right" />
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

ProductSummaryDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    product: PropTypes.any,
    children: PropTypes.node,
    forAvailability: PropTypes.bool,
    setUpdateProductFields_: PropTypes.func,
    selectedExistingProduct_: PropTypes.func,
    currentProduct: PropTypes.object,
    setShowCurrentProductDrawer: PropTypes.func,
    currentProductFlow_: PropTypes.func,
    setAddOnProductItineraryTimeModal: PropTypes.func,
    setSelectCalendarDaysBookingModal: PropTypes.func
};

const mapStateToProps = (state) => ({
    currentProduct: state.product?.currentProduct
});

export default connect(mapStateToProps, {
    setUpdateProductFields_,
    selectedExistingProduct_,
    currentProductFlow_
})(ProductSummaryDrawer);

import React, { useState, useEffect } from 'react';
import './index.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, InputNumber } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
// import { Radio } from 'antd';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import Spacer from '../../../atoms/spacer';
import ImportAccomodation from '../../../atoms/import-accomodation';
import GroupAge from '../../../atoms/pricing-age/group-age';
import SingleSupplement from '../../../atoms/pricing-age/single-supplement';
import AccomodationPricing from '../accomodation-pricing';
import Prompter from '../../../atoms/prompter';
import DaysSelector from '../../../atoms/select-day';
import AddAccomodationPricingButton from '../../../atoms/add-pricing-accomodation';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { updateProductPricing } from 'store/actions';
import SeasonSupplement from 'components/atoms/pricing-age/season-supplement';
import { DaysSelectorPackage, Loader, SubmitButtonErrorPopover } from 'components/atoms';
import { comparePricing, setErrorsOnAntFormFinishFailed } from 'utils';

function Pricing(props) {
    const [isIncludedAccomodation, setIsIncludedAccomodation] = useState(true);
    const [isOpenSingleSupplement, setIsOpenSingleSupplement] = useState(false);
    const [isOpenAccomodationPricing, setUsOpenAccomodationPricing] = useState(false);
    const [singleSupplement, setSingleSupplement] = useState(null);
    const [minTravellers, setMinTravellers] = useState(null);
    const [maxTravellers, setMaxTravellers] = useState(null);
    const [minAccomodation, setMinAccomodation] = useState(null);
    const [maxAccomodation, setMaxAccomodation] = useState(null);
    const [selectDays, setSelectDays] = useState([]);
    const [ageGroups, setAgeGroups] = useState({
        Adult: {},
        Infant: {},
        Child: {},
        Youth: {},
        Senior: {}
    });

    const [perPersonSingle, setPerPersonSingle] = useState(null);
    const [perPersonSharing, setPerPersonSharing] = useState(null);
    const [accomodationGroupPrices, setAccomodationGroupPrices] = useState(null);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [accomodationPeakSeasonSupplements, setAccomodationPeakSeasonSupplements] =
        useState(null);
    const [productPeakSeasonSupplements, setProductPeakSeasonSupplements] = useState(null);
    const [errors, setErrors] = useState(null);
    const [gender, setGender] = useState(null);

    useEffect(() => {
        if (props.data) {
            setImportData(props.data);
        } else {
            onClear();
        }
    }, [props.data]);

    useEffect(() => {
        if (selectDays?.length > 0) {
            form.setFields([
                {
                    name: 'selectDays',
                    errors: []
                }
            ]);
        }
    }, [selectDays]);

    const genderOption = [
        { label: 'Male', value: 'Male' },
        { label: 'Female', value: 'Female' },
        { label: 'Both', value: 'Prefer Not to Say' }
    ];

    const [form] = Form.useForm();

    const setImportData = (data) => {
        let ageGroups = {
            Adult: {},
            Infant: {},
            Child: {},
            Youth: {},
            Senior: {}
        };
        if (data?.age_groups) {
            data.age_groups.forEach((item) => {
                ageGroups[item.type] = {
                    minAge: item.min_age,
                    maxAge: item.max_age,
                    amount: item.amount,
                    groupPrices: item.group_price,
                    checked: true,
                    type: item.type
                };
            });
        }
        setIsIncludedAccomodation(data.is_accomodation_included);
        setSingleSupplement(data.product_accomodation_single_supplement);
        setMinTravellers(data.min_travellers);
        setMaxTravellers(data.max_travellers);
        setMinAccomodation(data.min_accomodation);
        setMaxAccomodation(data.max_accomodation);
        setSelectDays(data.days);
        setPerPersonSingle(data.per_person_single);
        setPerPersonSharing(data.per_person_sharing);
        setAccomodationGroupPrices(data.accomodation_group_prices);
        setAccomodationPeakSeasonSupplements(data.accomodation_peak_season_supplements);
        setProductPeakSeasonSupplements(data.product_peak_season_supplements);
        setAgeGroups(ageGroups);
        setGender(data.gender);
    };

    const onClear = () => {
        setIsIncludedAccomodation(true);
        setIsOpenSingleSupplement(false);
        setUsOpenAccomodationPricing(false);
        setSingleSupplement(null);
        setMinTravellers(null);
        setMaxTravellers(null);
        setMinAccomodation(null);
        setMaxAccomodation(null);
        setSelectDays([]);
        setAgeGroups({
            Adult: {},
            Infant: {},
            Child: {},
            Youth: {},
            Senior: {}
        });
        setAccomodationPeakSeasonSupplements(null);
        setAccomodationGroupPrices(null);
        setPerPersonSharing(null);
        setPerPersonSingle(null);
        setGender(null);
    };

    const onFinish = () => {
        const { selected_existing_product, data } = props;

        form.validateFields()
            .then(() => {
                if (selected_existing_product && data) {
                    let bool = comparePricing(data, {
                        selectDays,
                        minTravellers,
                        maxTravellers,
                        ageGroups,
                        isIncludedAccomodation,
                        perPersonSharing,
                        perPersonSingle,
                        gender
                    });
                    if (bool) return setConfirmationModal(true);
                    else return onSave({ showNotification: false });
                } else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onSave = (obj) => {
        form.validateFields()
            .then(() => {
                const saveData = {
                    id: props?.data?.id,
                    age_groups: Object.keys(ageGroups)
                        .map((key) => ({
                            type: key,
                            min_age: ageGroups[key].minAge,
                            max_age: ageGroups[key].maxAge,
                            amount: ageGroups[key].amount,
                            checked: ageGroups[key].checked,
                            group_price: ageGroups[key].groupPrices
                        }))
                        .filter((item) => item.checked),
                    days: selectDays,
                    min_travellers: minTravellers,
                    max_travellers: maxTravellers,
                    min_accomodation: minAccomodation,
                    max_accomodation: maxAccomodation,
                    is_accomodation_included: isIncludedAccomodation,
                    per_person_sharing: perPersonSharing,
                    per_person_single: perPersonSingle,
                    product_accomodation_single_supplement: singleSupplement
                        ? {
                              double: singleSupplement?.double,
                              twin: singleSupplement?.twin
                          }
                        : null,
                    accomodation_group_prices:
                        accomodationGroupPrices?.length > 0 ? accomodationGroupPrices : null,
                    gender: gender,
                    accomodation_peak_season_supplements:
                        accomodationPeakSeasonSupplements?.length > 0
                            ? accomodationPeakSeasonSupplements
                            : null,
                    product_peak_season_supplements:
                        productPeakSeasonSupplements?.length > 0
                            ? productPeakSeasonSupplements
                            : null
                };

                if (obj?.showNotification === false) props.onSave(saveData, false);
                else props.onSave(saveData);
                onClear();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onClose = () => {
        props.onClose();
    };

    const changeOpenAccomodationPricing = () =>
        setUsOpenAccomodationPricing(!isOpenAccomodationPricing);

    if (props?.currentProduct?.product_category_id === 1) {
        return (
            <>
                <AccomodationPricing
                    pricing={props.data}
                    showModal={props?.isOpen}
                    onClose={onClose}
                    perPersonSharing={perPersonSharing}
                    perPersonSingle={perPersonSingle}
                    accomodationGroupPrices={accomodationGroupPrices}
                    setAccomodationGroupPrices={setAccomodationGroupPrices}
                    setPerPersonSharing={setPerPersonSharing}
                    setPerPersonSingle={setPerPersonSingle}
                    type={'accomodation'}
                    accomodationPeakSeasonSupplements={accomodationPeakSeasonSupplements}
                    setAccomodationPeakSeasonSupplements={setAccomodationPeakSeasonSupplements}
                    minTravellers={minTravellers}
                    maxTravellers={maxTravellers}
                    setMinTravellers={setMinTravellers}
                    setMaxTravellers={setMaxTravellers}
                    minAccomodation={minAccomodation}
                    maxAccomodation={maxAccomodation}
                    setMinAccomodation={setMinAccomodation}
                    setMaxAccomodation={setMaxAccomodation}
                    selectDays={selectDays}
                    setSelectDays={setSelectDays}
                    onSave={onFinish}
                    dayPriceUpdate={props.dayPriceUpdate}
                    gender={gender}
                    setGender={setGender}
                />
                <ConfirmationModal
                    isModalOpen={confirmationModal}
                    setModalOpenState={(value) => setConfirmationModal(value)}
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        onSave();
                        setConfirmationModal(false);
                    }}
                />
            </>
        );
    }

    return (
        <Modal isOpen={props?.isOpen} centered size="lg" zIndex={props.dayPriceUpdate && 1060}>
            <Form
                form={form}
                name="pricing-form"
                className="pricing"
                layout="vertical"
                requiredMark={false}
                onFinish={onFinish}
                onFinishFailed={(data) => setErrorsOnAntFormFinishFailed(data, setErrors)}>
                <ModalHeader className="title-pricing">
                    Add Pricing details for your {props.title} activity{' '}
                    {props.dayPriceUpdate && ' for ' + props.dayPriceUpdate}
                    {props.hideImport ? (
                        ''
                    ) : (
                        <ImportAccomodation
                            calendars={props.calendarData}
                            onMainProduct={(value) =>
                                setImportData(value?.pricings ? value?.pricings[0] || null : null)
                            }
                            import="I will import pricing details information from my other product"
                        />
                    )}
                    <div className="subtitle">
                        The pricing detail you enter below includes all applicable fees, taxes and
                        other charges and excludes our retail commission which the customer will pay
                        for.
                    </div>
                </ModalHeader>
                <ModalBody className="body-modal">
                    {props.loading && <Loader typeTransparent />}
                    <div style={{ fontSize: 14 }}>
                        We transact using USD, however travelers can always see prices in their
                        local currency.
                    </div>
                    <hr width="100%" style={{ marginTop: '27px' }} />
                    <b>Activities pricing details excluding accomodation</b>
                    <Spacer top={8} />
                    <b>
                        Define the age groups that can participate with your price per person (PP)
                    </b>
                    <p>( Add at least Adult or Senior age group )</p>
                    <Spacer top={30} />
                    <Form.Item
                        name="groupAgeCheck"
                        rules={[
                            {
                                required:
                                    ageGroups.Senior.checked || ageGroups.Adult.checked
                                        ? false
                                        : true,
                                message: 'Please select at least Senior or Adult pricing'
                            }
                        ]}>
                        <GroupAge
                            data={ageGroups}
                            onEdit={props.onEdit}
                            form={form}
                            setAgeGroups={setAgeGroups}
                            ageGroups={ageGroups}
                        />
                    </Form.Item>
                    <hr width="100%" style={{ marginBottom: '27px' }} />
                    <Form.Item
                        name="gender"
                        label="Select Gender Options for this product"
                        rules={[
                            {
                                required: !gender,
                                message: 'Please select a gender option'
                            }
                        ]}
                        style={{ marginBottom: '0px' }}>
                        <div className="group-radio">
                            {genderOption.map((item, index) => (
                                <div
                                    className="cursor-pointer radio-btn"
                                    key={index}
                                    style={{
                                        backgroundColor: item.value === gender ? '#a2792c' : '#fff',
                                        color: item.value === gender ? '#fff' : '#000',
                                        width: '100px'
                                    }}
                                    tabIndex="0"
                                    role="button"
                                    onKeyDown={() => {
                                        setGender(item.value);
                                    }}
                                    onClick={() => {
                                        setGender(item.value);
                                    }}>
                                    {item.label}
                                </div>
                            ))}
                        </div>
                    </Form.Item>
                    <hr width="100%" style={{ marginTop: '0px' }} />
                    <div className="book-price product-peak-prompter-wrapper">
                        <div style={{ fontWeight: 600, fontSize: 18 }}>
                            Add Price for Peak/Festive Season Supplement (Optional)
                        </div>
                        <div className="flex-shrink-0">
                            <Prompter
                                fontSize={14}
                                textColor="#A2792C"
                                text={
                                    productPeakSeasonSupplements?.length > 0
                                        ? `Add More`
                                        : 'Add Season'
                                }
                                fon
                                onClick={() => {
                                    setProductPeakSeasonSupplements(
                                        productPeakSeasonSupplements?.length &&
                                            productPeakSeasonSupplements?.length > 0
                                            ? [
                                                  ...productPeakSeasonSupplements,
                                                  {
                                                      percentage: null
                                                  }
                                              ]
                                            : [
                                                  {
                                                      percentage: null
                                                  }
                                              ]
                                    );
                                }}
                            />
                        </div>
                    </div>
                    {productPeakSeasonSupplements?.length > 0 && (
                        <div>
                            <Spacer top={20} />
                            <div className="product-peak-season-wrapper ">
                                <div className="book-subtitles ">
                                    <b>Increase The ‘per person’ rate above by</b>
                                </div>
                                <div>
                                    <SeasonSupplement
                                        type={'product'}
                                        setAccomodationPeakSeasonSupplements={
                                            setProductPeakSeasonSupplements
                                        }
                                        accomodationPeakSeasonSupplements={
                                            productPeakSeasonSupplements
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <hr width="100%" style={{ marginTop: '27px' }} />
                    <Form.Item
                        name="minTravellers"
                        label="Enter the minimum number of travelers allowed in a single booking"
                        rules={[
                            {
                                required: !minTravellers,
                                message: 'Please add min travellers'
                            }
                        ]}>
                        <>
                            <InputNumber
                                className="allowed-number"
                                min={1}
                                value={minTravellers}
                                max={maxTravellers}
                                onChange={(value) => {
                                    setMinTravellers(value);
                                }}
                            />
                        </>
                    </Form.Item>
                    <Form.Item
                        name="maxTravellers"
                        label="Enter the maximum number of travelers allowed in a single booking"
                        rules={[
                            {
                                required: !maxTravellers,
                                message: 'Please add max travellers'
                            }
                        ]}>
                        <>
                            <InputNumber
                                className="allowed-number"
                                min={minTravellers || 1}
                                value={maxTravellers}
                                onChange={(value) => {
                                    setMaxTravellers(value);
                                }}
                            />
                        </>
                    </Form.Item>
                    {!props.dayPriceUpdate && (
                        <Form.Item
                            name="selectDays"
                            rules={[
                                {
                                    required: selectDays?.length > 0 ? false : true,
                                    message: 'Please select atleast one'
                                }
                            ]}>
                            {props.isForPackage ? (
                                <DaysSelectorPackage
                                    data={selectDays}
                                    pricing={props.data}
                                    onSave={(value) => {
                                        setSelectDays(value);
                                    }}
                                />
                            ) : (
                                <DaysSelector
                                    data={selectDays}
                                    pricing={props.data}
                                    onSave={(value) => {
                                        setSelectDays(value);
                                    }}
                                />
                            )}

                            <Spacer top={25} />
                            <Row style={{ margin: 0 }}>
                                <Col sm={1}>Note:</Col>
                                <Col sm={10}>
                                    After you click done, you can add another pricing details if you
                                    have a different price that applies for a specific day you have
                                    not selected. This product will be unavailable on days without a
                                    price
                                </Col>
                            </Row>
                        </Form.Item>
                    )}
                    {props.currentProduct?.day_type === 'within_day' && (
                        <>
                            <hr width="100%" />
                            <Form.Item style={{ marginBottom: 0 }}>
                                <div>Is your accomodation included in your product pricing?</div>
                                <div className="group-radio">
                                    <div
                                        className="cursor-pointer radio-btn"
                                        style={{
                                            backgroundColor: isIncludedAccomodation
                                                ? '#a2792c'
                                                : '#fff',
                                            color: isIncludedAccomodation ? '#fff' : '#000'
                                        }}
                                        tabIndex="0"
                                        role="button"
                                        onKeyDown={() => {
                                            setIsIncludedAccomodation(true);
                                        }}
                                        onClick={() => {
                                            setIsIncludedAccomodation(true);
                                        }}>
                                        Yes
                                    </div>
                                    <div
                                        className="cursor-pointer radio-btn"
                                        style={{
                                            backgroundColor: !isIncludedAccomodation
                                                ? '#a2792c'
                                                : '#fff',
                                            color: !isIncludedAccomodation ? '#fff' : '#000'
                                        }}
                                        tabIndex="0"
                                        role="button"
                                        onKeyDown={() => {
                                            setIsIncludedAccomodation(false);
                                        }}
                                        onClick={() => {
                                            setIsIncludedAccomodation(false);
                                        }}>
                                        No
                                    </div>
                                </div>
                            </Form.Item>
                            {isIncludedAccomodation ? (
                                <Prompter
                                    textColor="#A2792C"
                                    text={
                                        singleSupplement
                                            ? 'Edit Single Supplement (Optional)'
                                            : 'Add Single Supplement (Optional)'
                                    }
                                    onClick={() => setIsOpenSingleSupplement(true)}
                                />
                            ) : (
                                <AddAccomodationPricingButton
                                    data={{
                                        perPersonSingle,
                                        perPersonSharing,
                                        accomodationGroupPrices,
                                        accomodationPeakSeasonSupplements
                                    }}
                                    descriptionInclude="Accomodation pricing"
                                    name="Accomodation pricing (Optional)"
                                    onClick={changeOpenAccomodationPricing}
                                />
                            )}
                            {isOpenSingleSupplement && (
                                <SingleSupplement
                                    showModal={isOpenSingleSupplement}
                                    singleSupplement={singleSupplement}
                                    onClose={() => setIsOpenSingleSupplement(false)}
                                    setSingleSupplement={setSingleSupplement}
                                    productAdultPrice={ageGroups?.Adult?.amount}
                                    minAccomodation={minAccomodation}
                                    maxAccomodation={maxAccomodation}
                                    setMinAccomodation={setMinAccomodation}
                                    setMaxAccomodation={setMaxAccomodation}
                                />
                            )}
                            {/* {isOpenAccomodationPricing && ( */}
                            <AccomodationPricing
                                pricing={props.data}
                                showModal={isOpenAccomodationPricing}
                                onClose={changeOpenAccomodationPricing}
                                perPersonSharing={perPersonSharing}
                                perPersonSingle={perPersonSingle}
                                accomodationGroupPrices={accomodationGroupPrices}
                                setAccomodationGroupPrices={setAccomodationGroupPrices}
                                setPerPersonSharing={setPerPersonSharing}
                                setPerPersonSingle={setPerPersonSingle}
                                type={'product_accomodation'}
                                productAdultPrice={ageGroups?.Adult?.amount}
                                accomodationPeakSeasonSupplements={
                                    accomodationPeakSeasonSupplements
                                }
                                setAccomodationPeakSeasonSupplements={
                                    setAccomodationPeakSeasonSupplements
                                }
                                minAccomodation={minAccomodation}
                                maxAccomodation={maxAccomodation}
                                setMinAccomodation={setMinAccomodation}
                                setMaxAccomodation={setMaxAccomodation}
                            />
                        </>
                    )}
                    {/* )} */}
                </ModalBody>
                <ModalFooter>
                    <div className="footer-btn">
                        <div>
                            <SubmitButtonErrorPopover visible={errors} errors={errors}>
                                <Button color="primary">
                                    {props.submitButtonName ?? 'Update Calender & Continue'}
                                </Button>
                            </SubmitButtonErrorPopover>
                            <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark" style={{ width: '137px' }}>
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </ModalFooter>

                <ConfirmationModal
                    isModalOpen={confirmationModal}
                    setModalOpenState={(value) => setConfirmationModal(value)}
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        onSave();
                        setConfirmationModal(false);
                    }}
                />
            </Form>
        </Modal>
    );
}

Pricing.propTypes = {
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    data: Proptypes.object,
    isOpen: Proptypes.bool,
    onEdit: Proptypes.string,
    calendarData: Proptypes.object,
    hideImport: Proptypes.bool,
    submitButtonName: Proptypes.string,
    selected_existing_product: Proptypes.bool,
    title: Proptypes.string,
    isForPackage: Proptypes.bool,
    dayPriceUpdate: Proptypes.bool,
    currentProduct: Proptypes.object,
    loading: Proptypes.bool
};

const mapStateToProps = (state) => ({
    loading: state.loading
});

export default withRouter(connect(mapStateToProps, { updateProductPricing })(Pricing));

import React from 'react';
import './index.scss';
import Proptypes from 'prop-types';

export default function SelectButton(props) {
    const {
        width = '68px',
        height = '48px',
        name,
        isActive,
        onClick,
        disabled,
        onDisabledClick
    } = props;

    return (
        <div
            className={`select-button unselectable cursor-pointer ${
                isActive && !disabled ? 'active-select-button' : ''
            } ${disabled ? 'disabled-select-button' : ''} `}
            style={{ width: width, height: height, marginTop: props.mt }}
            tabIndex="0"
            role="button"
            onKeyDown={onClick}
            onClick={!disabled ? onClick : onDisabledClick}>
            {name}
        </div>
    );
}
SelectButton.propTypes = {
    width: Proptypes.string,
    height: Proptypes.string,
    name: Proptypes.string,
    isActive: Proptypes.bool,
    disabled: Proptypes.bool,
    onClick: Proptypes.func,
    onDisabledClick: Proptypes.func,
    mt: Proptypes.number
};

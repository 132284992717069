/* eslint-disable react/no-unescaped-entities */
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col } from 'antd';
import Prompter from '../../../../atoms/prompter';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Inclusion from '../inclusion';
import Exclusion from '../exclusion';
import Spacer from '../../../../atoms/spacer';
import ListRow from './list-row';
import './index.scss';
import Display from '../../../../atoms/display';

const InclusionAndExclusion = (props) => {
    const { handleOk, isVisible, handleCancel, width } = props;

    const [state, setState] = useState({
        inclusionIsVisible: false,
        exclusionIsVisible: false,
        inclusions: [],
        exclusions: [],
        editingExclusion: null,
        editingInclusion: null
    });

    const addExclusion = () => {
        setState({
            ...state,
            exclusionIsVisible: true
        });
    };

    const addInclusion = () => {
        setState({
            ...state,
            inclusionIsVisible: true
        });
    };

    const handleInclusionClose = () => {
        setState({
            ...state,
            inclusionIsVisible: false,
            editingInclusion: null
        });
    };

    const handleInclusionDelete = (index) => {
        const inclusions = [...state.inclusions];
        inclusions.splice(index, 1);
        setState({
            ...state,
            inclusions
        });
    };

    const handleExclusionDelete = (index) => {
        const exclusions = [...state.exclusions];
        exclusions.splice(index, 1);
        setState({
            ...state,
            exclusions
        });
    };

    const handleEditExclusion = (index) => {
        setState({
            ...state,
            exclusionIsVisible: true,
            editingExclusion: {
                ...state.exclusions[index],
                index
            }
        });
    };

    const handleEditInclusion = (index) => {
        setState({
            ...state,
            inclusionIsVisible: true,
            editingInclusion: {
                ...state.inclusions[index],
                index
            }
        });
    };

    const handleExclusionClose = () => {
        setState({
            ...state,
            exclusionIsVisible: false,
            editingExclusion: null
        });
    };

    const handleInclusionOk = (data) => {
        const inclusions = [...state.inclusions];
        if (data.index >= 0) {
            inclusions[data.index] = data;
        } else {
            inclusions.push(data);
        }
        setState({
            ...state,
            inclusionIsVisible: false,
            inclusions,
            editingInclusion: null
        });
    };

    const handleExclusionOk = (data) => {
        const exclusions = [...state.exclusions];
        if (data.index >= 0) {
            exclusions[data.index] = data;
        } else {
            exclusions.push(data);
        }
        setState({
            ...state,
            exclusionIsVisible: false,
            exclusions,
            editingExclusion: null
        });
    };

    return (
        <Modal
            width={width}
            visible={isVisible}
            centered={true}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            wrapClassName="inclusionexclusion">
            <h4>Add Inclusions & Exclusions</h4>
            <Prompter
                text="I will import inclusions & exclusions information from my other activity "
                textColor="#A2792C"
            />
            <Spacer top={14} />
            <p>
                Let travelers know what is and what isn’t provided to help them understand what
                they’re paying for.
            </p>

            <Row>
                <Col span="23">
                    <h6>What's included</h6>
                    <div className="list">
                        {state.inclusions.map((inclusion, index) => (
                            <ListRow
                                key={inclusion.title + index}
                                title={inclusion.title}
                                onDelete={() => handleInclusionDelete(index)}
                                onEdit={() => handleEditInclusion(index)}
                            />
                        ))}
                    </div>
                    <Spacer top={17} />
                    <Prompter onClick={addInclusion} text="Add an inclusion" />

                    <Spacer top={36} />
                    <h6>What's included</h6>
                    <div className="list">
                        {state.exclusions.map((exclusion, index) => (
                            <ListRow
                                title={exclusion.title}
                                key={exclusion.title + index}
                                onDelete={() => handleExclusionDelete(index)}
                                onEdit={() => handleEditExclusion(index)}
                            />
                        ))}
                    </div>
                    <Spacer top={17} />
                    <Prompter onClick={addExclusion} text="Add an exclusion" />
                </Col>
            </Row>

            <div className="buttons">
                <Button
                    style={{ height: 42, width: 135 }}
                    type="primary"
                    className="rounded-btn mr20">
                    Skip
                </Button>
                <Button
                    type="primary"
                    className="rounded-btn"
                    style={{ float: 'right', height: 42 }}>
                    <QuestionCircleOutlined /> Help
                </Button>
            </div>
            <Display show={!!state.inclusionIsVisible}>
                <Inclusion
                    handleOk={handleInclusionOk}
                    isVisible={!!state.inclusionIsVisible}
                    handleCancel={handleInclusionClose}
                    editingInclusion={state.editingInclusion}
                />
            </Display>
            <Display show={!!state.exclusionIsVisible}>
                <Exclusion
                    handleOk={handleExclusionOk}
                    isVisible={!!state.exclusionIsVisible}
                    handleCancel={handleExclusionClose}
                    editingExclusion={state.editingExclusion}
                />
            </Display>
        </Modal>
    );
};

InclusionAndExclusion.propTypes = {
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    width: PropTypes.number.isRequired
};

export default InclusionAndExclusion;

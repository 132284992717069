import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { Collapse } from 'antd';
import Proptypes from 'prop-types';

const { Panel } = Collapse;

const allTravellers = [
    {
        userid: 10,
        isLead: false,
        first_name: 'Buki',
        middleName: '',
        last_name: 'Smith',
        ageGroup: 'Adult',
        weight: '89',
        height: '5.9',
        dob: '09/10/1990',
        passportDetails: 'xxx-xxx-xx03',
        arrivalFlightDetails: 'lorem ipsum dolor sit amet'
    }
];
const TravellerDetails = (props) => {
    const { viewSummaryData } = props;
    let travellerArray = [];
    if (viewSummaryData?.lead_traveller_info) {
        travellerArray.push(viewSummaryData?.lead_traveller_info);
        travellerArray.push(allTravellers[0]);
    }
    return (
        <Collapse
            className="primary-collapse-accordion has-text-expand-icon"
            bordered={false}
            expandIcon={({ isActive }) => (
                <span
                    className="d-inline-flex align-items-center text-primary text-nowrap"
                    style={{ paddingTop: 8 }}>
                    <span className="fs-14 fw-500 lh-1-5 mr-2">View details</span>
                    <span>
                        <svg
                            width="8"
                            height="12"
                            viewBox="0 0 8 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                transform: `rotate(${isActive ? '90deg' : 0})`
                            }}>
                            <path
                                d="M0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0L0 1.41Z"
                                fill="currentColor"
                            />
                        </svg>
                    </span>
                </span>
            )}
            expandIconPosition="right"
            accordion>
            {travellerArray &&
                travellerArray.map((traveller, index) => {
                    return (
                        <Panel
                            className="primary-collapse-panel"
                            header={
                                <div className="fs-15">
                                    <span className="fw-500 mr-2">Traveler {index + 1}:</span>
                                    <span>
                                        {traveller?.first_name} {traveller?.last_name}
                                    </span>{' '}
                                    {index == 0 ? (
                                        <span className="text-nowrap">{'(Lead Traveller)'}</span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            }
                            key={traveller.userid}>
                            <Row>
                                <Col lg={6}>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">First Name:</span>
                                        <span>{traveller.first_name}</span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Middle Name:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Last Name:</span>
                                        <span>{traveller.last_name}</span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Age Group:</span>
                                        <span></span>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Weight:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Height:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Date of Birth:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Passport Details:</span>
                                        <span></span>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Arrival Flight Details:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">
                                            Departure Flight Details:
                                        </span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Arrival Ship Details:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Departure Ship Details:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">
                                            Shore Excursion Details:
                                        </span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">Arrival Train Details:</span>
                                        <span></span>
                                    </div>
                                    <div className="fs-14 mb-2">
                                        <span className="fw-600 mr-1">
                                            Departure Train Details:
                                        </span>
                                        <span></span>
                                    </div>
                                </Col>
                                <Col xs={12} className="mt-2">
                                    <Button color="dark" size="sm">
                                        Download Details
                                    </Button>
                                </Col>
                            </Row>
                        </Panel>
                    );
                })}
        </Collapse>
    );
};
TravellerDetails.propTypes = {
    viewSummaryData: Proptypes.object
};
export default TravellerDetails;

import React from 'react';
import { Form, TimePicker } from 'antd';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { Spacer } from 'components/atoms';
import moment from 'moment';

const SpecificTimePeriod = (props) => {
    const { bookActivityPeriodType, onChangeSpecificTimePeriod, specificTimePeriod } = props;
    return (
        <div className="mb-3">
            <Row>
                <Col xs={12}>
                    <div
                        className="fw-600 mb-6px"
                        hidden={
                            bookActivityPeriodType !==
                            'Suggest a specific time & Allow customers to choose within a time period in the day'
                        }>
                        Suggest a specific time for customer to book
                    </div>
                    <div className="ant-form-item-label">
                        <span className="ant-form-item-required">
                            {"What's the duration of your Activity from start to finish?"}
                        </span>
                    </div>
                </Col>
                <Col xs={12} className="overflow-x-auto overflow-y-hidden scrollbar-hidden">
                    <Row style={{ margin: 0 }}>
                        <Col md={4}>
                            <div>From</div>
                            <Spacer top={5} />
                            <Form.Item
                                name={'specific_from'}
                                className="check-style"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select'
                                    }
                                ]}>
                                <TimePicker
                                    size="large"
                                    //defaultValue={0}
                                    use12Hours
                                    format="hh:mm A"
                                    placeholder="12:00 AM"
                                    style={{
                                        height: '50px',
                                        width: '216px'
                                    }}
                                    onChange={(e) =>
                                        onChangeSpecificTimePeriod(
                                            e ? e.format('hh:mm A') : null,
                                            'from'
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>

                        <Col md={4}>
                            <div>Until</div>
                            <Spacer top={5} />
                            <Form.Item
                                name={'specific_to'}
                                className="check-style"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select'
                                    }
                                ]}>
                                <TimePicker
                                    size="large"
                                    //defaultValue={0}
                                    use12Hours
                                    format="hh:mm A"
                                    placeholder="12:00 AM"
                                    style={{
                                        height: '50px',
                                        width: '216px'
                                    }}
                                    onChange={(e) =>
                                        onChangeSpecificTimePeriod(
                                            e ? e.format('hh:mm A') : null,
                                            'to'
                                        )
                                    }
                                />
                            </Form.Item>
                        </Col>

                        {moment(specificTimePeriod.from, 'hh:mm A').isSameOrAfter(
                            moment(specificTimePeriod.to, 'hh:mm A')
                        ) && (
                            <div style={{ marginBottom: '0.75rem', marginLeft: 10 }}>
                                <div className="time-suggested-tip">
                                    Note: Selected time period has moved into the next day!
                                </div>
                            </div>
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
SpecificTimePeriod.propTypes = {
    bookActivityPeriodType: PropTypes.string,
    specificTimePeriod: PropTypes.object,
    onChangeSpecificTimePeriod: PropTypes.func
};
export default SpecificTimePeriod;

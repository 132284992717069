import React from 'react';
import PropTypes from 'prop-types';
import Availability from '../availability';
import { Dropdown, Menu, Table, Button, Input, Pagination } from 'antd';
import { Row, Col, Collapse as RsCollapse } from 'reactstrap';
import { MoreOutlined } from '@ant-design/icons';
import {
    TagIcon,
    GpsIcon,
    ClockIcon,
    PictureIcon,
    DishIcon,
    LanguageOfferedIcon,
    InclusionIcon,
    ExclusionIcon,
    GuideIcon,
    MultiDirectionIcon,
    TopSightIcon,
    ExtraInfoIcon,
    ShieldIcon,
    ThumbsUpIcon,
    TicketIcon
} from 'components/molecules/modals/calendar-summary/panel/icons';
import ProductSummaryDrawer from 'components/molecules/modals/product-details';
import DetailedPanel from 'components/molecules/modals/calendar-summary/panel';
import { Link } from 'react-router-dom';
import FilterIcon from 'components/atoms/icons/filter-icon';
// import SearchBox from 'components/atoms/searchbox/SearchBox';
import { Feedback } from 'components/molecules';

import { getAllArchivedProduct } from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'components/atoms';

import ArchiveFilter from './archive-filter';
import UnArchiveModal from 'components/organisms/unarchieveConfirmModal';

// const { Option } = Select;

// const angleIcon = (
//     <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
//         <svg
//             width="8"
//             height="12"
//             viewBox="0 0 8 12"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg">
//             <path
//                 d="M-4.62904e-07 1.41L4.58 6L-6.16331e-08 10.59L1.41 12L7.41 6L1.41 -6.1633e-08L-4.62904e-07 1.41Z"
//                 fill="#A2792C"
//             />
//         </svg>
//     </span>
// );

class Archive extends React.Component {
    //Initialized state
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            perPage: 10,
            filter: {},
            visibleProductSummaryDrawer: false,
            showMoreAdventures: false,
            showMoreExtraInfo: false,
            showMoreSafetyUpdates: false,
            unarchiveConfirmModal: false,
            filtersVisibility: false,
            openedRecord: null
        };
    }

    componentDidMount() {
        const { page, perPage } = this.state;
        this.props.getAllArchivedProduct({ page, perPage });
    }
    refreshList = () => {
        const { page, perPage, dateQuery } = this.state;
        this.props.getAllArchivedProduct({ page, perPage, dateQuery });
    };
    componentDidUpdate(prevProp, prevState) {
        const { page, perPage, filter, filtersVisibility } = this.state;

        if (prevState.filtersVisibility === true && filtersVisibility === false) {
            this.setState({ filter: {}, page: 1 }, () =>
                this.props.getAllArchivedProduct({ page, perPage, ...filter })
            );
        }
    }

    /** Filter for Table == start == */
    onChangeFilter = ({ value, type }) => {
        this.setState(
            (prevState) => {
                return { filter: { ...prevState.filter, [type]: value } };
            },
            () => {
                if (
                    this.state.filter[type] === undefined ||
                    this.state.filter[type] === null ||
                    this.state.filter[type] === ''
                ) {
                    delete this.state.filter[type];
                }

                return this.props.getAllArchivedProduct({
                    page: this.state.page,
                    perPage: this.state.perPage,
                    ...this.state.filter
                });
            }
        );
    };
    hanldUnarchienveToggle = () => {
        this.setState({
            unarchiveConfirmModal: !this.state.unarchiveConfirmModal
        });
    };

    handleFiterVisibility = () => {
        const { filtersVisibility } = this.state;
        return this.setState({ filtersVisibility: !filtersVisibility });
    };

    clear = () => {
        return (
            this.handleFiterVisibility(),
            this.props.getAllArchivedProduct({
                page: this.state.page,
                perPage: this.state.perPage,
                ...this.state.filter
            })
        );
    };

    onClearFilter = () => {
        this.setState({ filter: {} }, () => this.clear());
    };

    onChangePage = (page) => {
        this.setState({ page }, () =>
            this.props.getAllArchivedProduct({
                page: this.state.page,
                perPage: this.state.perPage,
                ...this.state.filter
            })
        );
    };
    /** Filter for Table == end == */

    columns = [
        {
            title: 'PRODUCT TITLE',
            dataIndex: 'title',
            key: 'title',
            width: 190,
            render: (_text, record) => {
                return (
                    <div className="text-truncate" title={record.title}>
                        {record.title ? record.title : 'N/A'}
                    </div>
                );
            }
        },
        {
            title: 'PRODUCT CATEGORY',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 240
        },
        {
            title: 'PRODUCT SUB-CATEGORY',
            dataIndex: 'sub_category_name',
            key: 'sub_category_name',
            width: 240
        },
        {
            title: 'Actions',
            dataIndex: 'archiveAction',
            key: 'archiveAction',
            width: 50,
            // eslint-disable-next-line react/display-name
            render: (_, record) => {
                return (
                    <div className="">
                        <Dropdown overlay={this.archiveActionmenu(record)} trigger={['click']}>
                            <span role="button" tabIndex="0">
                                <MoreOutlined rotate={90} style={{ fontSize: '30px' }} />
                            </span>
                        </Dropdown>
                    </div>
                );
            }
        }
    ];
    archiveActionmenu = (record) => (
        <Menu
            onClick={({ key }) => {
                this.onMenuClick(key);
                this.setState({ openedRecord: record });
            }}
            className="dropdown-action-menu">
            <Menu.Item key="0">View Details</Menu.Item>
            <Menu.Item key="1">Unarchive</Menu.Item>
            {/* <Menu.Item key="2">Delete</Menu.Item> */}
        </Menu>
    );

    //Functions for Opening modal and Saving data
    onMenuClick(key) {
        if (key == 2) {
            // this.setState({ stopSalesConfirmModal: true });
        } else if (key == 1) {
            this.setState({ unarchiveConfirmModal: true });
        } else if (key == 0) {
            this.setState({ visibleProductSummaryDrawer: true });
        }
    }

    render() {
        const {
            visibleProductSummaryDrawer,
            showMoreAdventures,
            showMoreExtraInfo,
            showMoreSafetyUpdates,
            filtersVisibility
        } = this.state;

        const { all_archived_products, loading } = this.props;

        return (
            <Availability title={`Archive`}>
                <div className="main-calendar-table-section mb-40px">
                    <Row className="align-items-baseline mb-2">
                        <Col sm={12} md={12} lg={9}>
                            <div className="fs-17 text-primary fw-600">Archived Products</div>
                        </Col>
                        <Col sm={12} md={12} lg={3} className="text-right">
                            <div className="archive-container">
                                <Button
                                    className="px-0 mr-sm-2"
                                    type="link"
                                    size="small"
                                    onClick={() => this.props.history.push(`/availability-data`)}>
                                    Back to Availability
                                </Button>
                                <Button
                                    className="btn-filter btn-block btn-sm-inline-block filter-button"
                                    size="small"
                                    onClick={() =>
                                        filtersVisibility
                                            ? this.onClearFilter()
                                            : this.setState({
                                                  filtersVisibility: !filtersVisibility
                                              })
                                    }>
                                    <span className="name mr-2">
                                        {filtersVisibility ? 'Close' : 'Filters'}
                                    </span>
                                    <span>
                                        <FilterIcon />
                                    </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    {filtersVisibility && (
                        <ArchiveFilter
                            onChangeFilter={this.onChangeFilter}
                            filterState={this.state.filter}
                        />
                    )}

                    <div
                        className="white-bg-color-table pl-1 mt-3"
                        style={{
                            maxHeight: '350px',
                            height: 'unset',
                            overflowY: all_archived_products?.data.length != 0 ? 'scroll' : 'hidden'
                        }}>
                        <div>
                            {loading && <Loader typeContainer={true} />}
                            <Table
                                className="customer-calendars-table"
                                columns={this.columns}
                                dataSource={all_archived_products && all_archived_products.data}
                                pagination={false}
                                tableLayout="auto"
                            />
                        </div>
                    </div>

                    {/* Product Summary Drawer */}
                    <ProductSummaryDrawer
                        forAvailability={true}
                        productTitle={`Burj Khalifa`}
                        productDesc={
                            <>
                                <p>
                                    {`Burj Khalifa is the tallest tower in the world and it's one of the top
                                        attractions to visit in Dubai. Visit our website and book your Burj
                                        Khalifa ticket.`}
                                </p>
                                <p>
                                    {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non eros
                                        ac sapien suscipit sollicitudin a finibus mauris. Donec nulla ligula,
                                        auctor a varius id, viverra sed ante. Donec sodales cursus sem, in
                                        vulputate dolor dictum ac. Fusceunt eros quis cursus commodo. Nullam
                                        ligula tortor, consectetur sit amet gravida quis, hendrerit sit amet
                                        libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                                        imperdiet nisl quis rutrum malesuada. Ut posuere tempus ante, quis
                                        ultricies nisl posuere at Lorem ipsum dolor, Nam ab aliquam esse. Quaerat cum consequuntur, debitis
                                        sed animi nobis recusandae dolor officia, eligendi at asperiores
                                        laboriosam! Facilis omnis fuga quisquam?`}

                                    {/* Read more if applicable */}
                                    <Button
                                        color="link"
                                        className="ml-2 d-inline text-nowrap p-0 ff-special text-primary"
                                        size="small">
                                        <i>Read More</i>
                                    </Button>
                                </p>
                            </>
                        }
                        visible={visibleProductSummaryDrawer}
                        onClose={() => this.setState({ visibleProductSummaryDrawer: false })}>
                        <div className="ff-special">
                            {/* amount you will get (pp) Panel */}
                            <DetailedPanel
                                panelIcon={<TagIcon />}
                                panelTitle="Amount you will get (PP)"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Adult
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">USD 50</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Infant
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">No Fee</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Child
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">USD 20</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Youth
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">USD 50</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Senior
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">USD 20</span>
                                    </Col>
                                </Row>
                            </DetailedPanel>

                            {/* Main Destination Panel */}
                            <DetailedPanel
                                panelIcon={<GpsIcon />}
                                panelTitle="Main Destination"
                                isEditable={true}>
                                <div className="fs-18 lh-1-7">
                                    Burj Park by Emaar, 1 Sheikh Mohammed bin Rashid Blvd - Downtown
                                    Dubai - Dubai - United Arab Emirates
                                </div>
                            </DetailedPanel>

                            {/* Duration Panel */}
                            <DetailedPanel
                                panelIcon={<ClockIcon />}
                                panelTitle="Duration"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Start Time
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">10:10 AM</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                End Time
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">12:30 PM</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Duration
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">2 hrs 30mins</span>
                                    </Col>
                                </Row>
                            </DetailedPanel>

                            {/* Photos & Videos Panel */}
                            <DetailedPanel
                                panelIcon={<PictureIcon />}
                                panelTitle="Photos & videos"
                                isEditable={true}>
                                Photos & Videos Gallery
                            </DetailedPanel>

                            {/* Admission Price included Panel */}
                            <DetailedPanel
                                panelIcon={<TagIcon />}
                                panelTitle="Admission Price included"
                                isEditable={true}>
                                Yes
                            </DetailedPanel>

                            {/* Meals and Drinks Panel */}
                            <DetailedPanel
                                panelIcon={<DishIcon />}
                                panelTitle="Meals and Drinks"
                                isEditable={true}>
                                Meals and Drinks
                            </DetailedPanel>

                            {/* Language Offered Panel */}
                            <DetailedPanel
                                panelIcon={<LanguageOfferedIcon />}
                                panelTitle="Language Offered"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                English
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">
                                            In-person, Audio, Written
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                French
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">Audio, Written</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Arabian
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">In-person</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Certified
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">Yes</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Serve as driver{' '}
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">Yes</span>
                                    </Col>
                                </Row>
                            </DetailedPanel>

                            {/* Inclusions Panel */}
                            <DetailedPanel
                                panelIcon={<InclusionIcon />}
                                panelTitle="Inclusions"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Use of Equipment
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">
                                            Use of SCUBA equipment, Use of Segway, Use of bicycle
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Transportation Amenities
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">
                                            Air conditioned vehicle, Private Transportation,
                                            Restroom on Board
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Fee
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">
                                            Landing and facility fees, Airport departure tax, Fuel
                                            Surcharge
                                        </span>
                                    </Col>
                                </Row>
                            </DetailedPanel>

                            {/* Exclusions Panel */}
                            <DetailedPanel
                                panelIcon={<ExclusionIcon />}
                                panelTitle="Exclusions"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Use of Equipment
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">
                                            Use of Tickkle, Use of Snorkelling equipment.
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={4}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Fee
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={8}>
                                        <span className="fs-18 lh-1-7">Gratitude</span>
                                    </Col>
                                </Row>
                            </DetailedPanel>

                            {/* Guide will meet you at Panel */}
                            <DetailedPanel
                                panelIcon={<GuideIcon />}
                                panelTitle="Guide will meet you at"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Starting Point 1
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={7}>
                                        <span className="fs-18 lh-1-7">Dubai Airport</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Starting Point 2
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={7}>
                                        <span className="fs-18 lh-1-7">Burj Khalifa</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Departure TIme
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={7}>
                                        <span className="fs-18 lh-1-7">01:30 PM</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Pickup Starts
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={7}>
                                        <span className="fs-18 lh-1-7">
                                            1 hr 30 mins before departure
                                        </span>
                                    </Col>
                                </Row>

                                {/* Choose a location */}
                                <div className="mt-3">
                                    <div className="fs-18 mb-2">Choose a location</div>
                                    <div className="d-flex">
                                        <Input
                                            className="type-transparent rounded-pill"
                                            placeholder="Explore location"
                                            prefix={
                                                <svg
                                                    width="17"
                                                    height="17"
                                                    viewBox="0 0 17 17"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        opacity="0.5"
                                                        d="M6.88044 14.6273C8.39857 14.627 9.87295 14.1188 11.0688 13.1837L14.8287 16.9434L16.0381 15.734L12.2782 11.9744C13.2139 10.7784 13.7224 9.30379 13.7228 7.78535C13.7228 4.01285 10.6531 0.943359 6.88044 0.943359C3.10774 0.943359 0.0380859 4.01285 0.0380859 7.78535C0.0380859 11.5579 3.10774 14.6273 6.88044 14.6273ZM6.88044 2.65386C9.71061 2.65386 12.0122 4.95533 12.0122 7.78535C12.0122 10.6154 9.71061 12.9168 6.88044 12.9168C4.05027 12.9168 1.74868 10.6154 1.74868 7.78535C1.74868 4.95533 4.05027 2.65386 6.88044 2.65386Z"
                                                        fill="#A3792D"
                                                    />
                                                </svg>
                                            }
                                        />
                                    </div>
                                </div>
                            </DetailedPanel>

                            {/* Adventures Panel */}
                            <DetailedPanel
                                panelIcon={<MultiDirectionIcon />}
                                panelTitle="Adventures"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Adventures
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fs-18 lh-1-7">
                                            Horseback Safari, Family Safari, Luxury Safari,
                                            Photography Safari, Gorillas & Primates
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                River Cruises
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <span className="fs-18 lh-1-7">Dinner, Party</span>
                                    </Col>
                                </Row>
                                <RsCollapse isOpen={showMoreAdventures}>
                                    <Row
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <Col xs={6}>
                                            <div className="d-flex flex-nowrap">
                                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                    Other Adventures
                                                </span>
                                                <span className="fs-18 lh-1-7 mr-4">:</span>
                                            </div>
                                        </Col>
                                        <Col xs={6}>
                                            <span className="fs-18 lh-1-7">Other Adventures</span>
                                        </Col>
                                    </Row>
                                </RsCollapse>

                                <div className="mt-2">
                                    <Button
                                        color="link"
                                        className="p-0 ff-special fw-500"
                                        onClick={() =>
                                            this.setState({
                                                showMoreAdventures: !showMoreAdventures
                                            })
                                        }>
                                        <i>View {showMoreAdventures ? 'Less' : 'More'}</i>{' '}
                                        <span>
                                            <svg
                                                width="13"
                                                height="9"
                                                viewBox="0 0 13 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    transform: `rotate(${
                                                        showMoreAdventures ? '180deg' : 0
                                                    })`
                                                }}>
                                                <path
                                                    d="M10.7463 0.943359L6.17235 5.61138L1.59842 0.943359L0.193359 2.38046L6.17235 8.49577L12.1513 2.38046L10.7463 0.943359Z"
                                                    fill="#A3792D"
                                                />
                                            </svg>
                                        </span>
                                    </Button>
                                </div>
                            </DetailedPanel>

                            {/* Top Sights Panel */}
                            <DetailedPanel
                                panelIcon={<TopSightIcon />}
                                panelTitle="Top Sights"
                                isEditable={true}>
                                {`Art and culture, Art Galleries, Architecture, Beaches, Casinos, Markets,
                                    Wildlife`}
                            </DetailedPanel>

                            {/* Extra info Panel */}
                            <DetailedPanel
                                panelIcon={<ExtraInfoIcon />}
                                panelTitle="Extra info"
                                isEditable={true}>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Wheelchair accessibility
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs="auto" style={{ minWidth: 60 }}>
                                        <span className="fs-18 lh-1-7">No</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Stroller accessible
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs="auto" style={{ minWidth: 60 }}>
                                        <span className="fs-18 lh-1-7">Yes</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Service animals Allowed
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs="auto" style={{ minWidth: 60 }}>
                                        <span className="fs-18 lh-1-7">No</span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                Can travelers easily arrive/depart on public
                                                transportation?
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs="auto" style={{ minWidth: 60 }}>
                                        <span className="fs-18 lh-1-7">Yes</span>
                                    </Col>
                                </Row>
                                <RsCollapse isOpen={showMoreExtraInfo}>
                                    <Row
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <Col>
                                            <div className="d-flex flex-nowrap">
                                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                    Are infants required to sit on laps?
                                                </span>
                                                <span className="fs-18 lh-1-7 mr-4">:</span>
                                            </div>
                                        </Col>
                                        <Col xs="auto" style={{ minWidth: 60 }}>
                                            <span className="fs-18 lh-1-7">No</span>
                                        </Col>
                                    </Row>
                                </RsCollapse>

                                <div className="mt-2">
                                    <Button
                                        color="link"
                                        className="p-0 ff-special fw-500"
                                        onClick={() =>
                                            this.setState({
                                                showMoreExtraInfo: !showMoreExtraInfo
                                            })
                                        }>
                                        <i>View {showMoreExtraInfo ? 'Less' : 'More'}</i>{' '}
                                        <span>
                                            <svg
                                                width="13"
                                                height="9"
                                                viewBox="0 0 13 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    transform: `rotate(${
                                                        showMoreExtraInfo ? '180deg' : 0
                                                    })`
                                                }}>
                                                <path
                                                    d="M10.7463 0.943359L6.17235 5.61138L1.59842 0.943359L0.193359 2.38046L6.17235 8.49577L12.1513 2.38046L10.7463 0.943359Z"
                                                    fill="#A3792D"
                                                />
                                            </svg>
                                        </span>
                                    </Button>
                                </div>
                            </DetailedPanel>

                            {/* Booking & Tickets Panel */}
                            <DetailedPanel
                                panelIcon={<TicketIcon />}
                                panelTitle="Booking & Tickets"
                                isEditable={true}>
                                <h6 className="fa-18 fw-500 text-uppercase mb-2">Cut-off time</h6>
                                <p className="fs-18 lh-1-7">10 Days before activity Starts</p>
                                <Link to="#" className="fs-16 fw-500 text-primary">
                                    <i>Cancellation Policy</i>
                                </Link>
                                <hr />
                                <h6 className="fa-18 fw-500 text-uppercase mb-2">Standard </h6>
                                <p className="fs-18 lh-1-7">
                                    To receive a full refund, travelers may cancel up to 24 hours
                                    before the experience start date in the local timezone. No
                                    refunds will be given after that time period.
                                </p>
                            </DetailedPanel>

                            {/* Covid-19 safety updates Panel */}
                            <DetailedPanel
                                panelIcon={<ShieldIcon />}
                                panelTitle="Covid-19 safety updates"
                                isEditable={true}>
                                <h6 className="fs-18 fw-500 text-uppercase">Safety Measures</h6>
                                <ul>
                                    <li className="fs-18 mb-3">
                                        Face masks required for travelers in public area
                                    </li>
                                    <li className="fs-18 mb-3">
                                        Face masks required for guides in public areas
                                    </li>
                                    <RsCollapse isOpen={showMoreSafetyUpdates}>
                                        <li className="fs-18 mb-3">
                                            Face masks provided for travelers
                                        </li>
                                        <li className="fs-18 mb-3">
                                            Hand sanitizer available to travelers
                                        </li>
                                        <li className="fs-18 mb-3">
                                            Hand sanitizer available to travelers and staff
                                        </li>
                                    </RsCollapse>
                                </ul>

                                <div className="mt-2">
                                    <Button
                                        color="link"
                                        className="p-0 ff-special fw-500"
                                        onClick={() =>
                                            this.setState({
                                                showMoreSafetyUpdates: !showMoreSafetyUpdates
                                            })
                                        }>
                                        <i>View {showMoreSafetyUpdates ? 'Less' : 'More'}</i>{' '}
                                        <span>
                                            <svg
                                                width="13"
                                                height="9"
                                                viewBox="0 0 13 9"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                style={{
                                                    transform: `rotate(${
                                                        showMoreSafetyUpdates ? '180deg' : 0
                                                    })`
                                                }}>
                                                <path
                                                    d="M10.7463 0.943359L6.17235 5.61138L1.59842 0.943359L0.193359 2.38046L6.17235 8.49577L12.1513 2.38046L10.7463 0.943359Z"
                                                    fill="#A3792D"
                                                />
                                            </svg>
                                        </span>
                                    </Button>
                                </div>
                            </DetailedPanel>

                            {/* Rating Panel */}
                            <DetailedPanel
                                panelIcon={<ThumbsUpIcon />}
                                panelTitle="Rating"
                                isEditable={true}>
                                <div className="border-bottom border-primary-shaded pb-2 mb-2">
                                    <div className="d-flex flex-nowrap align-items-center">
                                        <span className="mr-2">
                                            <img
                                                width={170}
                                                src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                                                alt=""
                                            />
                                        </span>
                                        <span className="fs-28 text-primary">3.9</span>
                                    </div>
                                    <div className="fs-18 text-primary fw-500">
                                        <i>38 Google Reviews</i>
                                    </div>
                                </div>
                                <div className="border-bottom border-primary-shaded pb-2 mb-2">
                                    <div className="d-flex flex-nowrap align-items-center">
                                        <span className="mr-2">
                                            <img
                                                width={170}
                                                src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                                                alt=""
                                            />
                                        </span>
                                        <span className="fs-28 text-primary">3.9</span>
                                    </div>
                                    <div className="fs-18 text-primary fw-500">
                                        <i>23 Facebook Reviews</i>
                                    </div>
                                </div>
                                <div className="border-bottom border-primary-shaded pb-2 mb-2">
                                    <div className="d-flex flex-nowrap align-items-center">
                                        <span className="mr-2">
                                            <img
                                                width={170}
                                                src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                                                alt=""
                                            />
                                        </span>
                                        <span className="fs-28 text-primary">3.9</span>
                                    </div>
                                    <div className="fs-18 text-primary fw-500">
                                        <i>13 Trip Advisor Reviews</i>
                                    </div>
                                </div>
                            </DetailedPanel>
                        </div>
                    </ProductSummaryDrawer>
                </div>
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="pagination-gallery">
                        <Pagination
                            defaultCurrent={1}
                            defaultPageSize={all_archived_products?.meta?.per_page || 10}
                            current={this.state.page}
                            showSizeChanger={false}
                            total={
                                all_archived_products &&
                                all_archived_products.meta &&
                                all_archived_products.meta.total
                            }
                        />
                    </div>
                    <div className="">
                        <Feedback />
                    </div>
                </div>
                <UnArchiveModal
                    refreshList={this.refreshList}
                    openedRecord={this.state.openedRecord}
                    hanldUnarchienveToggle={this.hanldUnarchienveToggle}
                    confirmationWindow={true}
                    isOpen={this.state.unarchiveConfirmModal}
                />
            </Availability>
        );
    }
}
Archive.propTypes = {
    history: PropTypes.object,
    getAllArchivedProduct: PropTypes.func,
    loading: PropTypes.bool,
    all_archived_products: PropTypes.shape({
        data: PropTypes.array,
        meta: PropTypes.shape({
            total: PropTypes.number,
            per_page: PropTypes.number
        })
    })
};

const mapStateToProps = (state) => ({
    all_archived_products: state.product.all_archived_products,
    loading: state.loading
});

const mapDispatch = (dispatch) => ({
    getAllArchivedProduct: (params) => dispatch(getAllArchivedProduct(params))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Archive));

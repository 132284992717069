/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { InfoCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import CalendarComponent from '../../../molecules/calendar/Calendar';
import TicketModal from '../../../organisms/ticketsModal/TicketModal';
import Feedback from '../../../molecules/user-feedback';

import '../personalcare/Personalcare.scss';
import VendorDashboard from '../vendor-dashboard';

export default function AddVentoText() {
    const [setTicketModal] = useState(false);
    const [selectedDays, setselectedDays] = useState([]);
    const [modalStep, setmodalStep] = useState(0);
    const [days] = useState([
        {
            day: 1
        }
    ]);

    // const addDay = () => {
    //     let newDay = days[days.length - 1].day + 1;
    //     setdays([...days, { day: newDay }]);
    // };

    const pickDay = (i) => {
        let present = selectedDays.find((n) => n === i);
        present
            ? setselectedDays(selectedDays.filter((d) => d !== i))
            : setselectedDays([...selectedDays, i]);
    };

    return (
        <VendorDashboard>
            <div className="other-vendor-details-wrap">
                <div className="personal-care">
                    <p className="page-title">Add a ‘Ventor‘s text’</p>
                    <p className="page-sub-title brown-text">
                        <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />I will
                        import ‘Ventor‘s text’ information from my other ‘Other Ventor‘s text’
                    </p>

                    <div className="fields">
                        <p className="label">Add ‘Ventor‘s text’ title</p>
                        <Input
                            style={{
                                border: '0.3px solid #a2792c'
                            }}
                        />

                        <p className="label">
                            Describe what travelers will see and do here if they book your
                            experience
                        </p>
                        <TextArea
                            style={{
                                border: '0.3px solid #a2792c'
                            }}
                            placeholder={`Enter a description about this product only.  For example, "Our accredited guide will meet you at the ticket booth and will accompany you up the tower, explaining what you'll see at each level. Description is required.`}
                            rows={6}
                        />
                        <p className="label">Select main destination</p>
                        <Input
                            style={{
                                border: '0.3px solid #a2792c',
                                borderRadius: 8
                            }}
                            suffix={
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z"
                                        fill="#927E2F"
                                    />
                                    <path
                                        d="M11.4118 8.58609C11.7908 8.96609 11.9998 9.46809 11.9998 10.0001H13.9998C14.0007 9.47451 13.8974 8.95398 13.6959 8.46857C13.4944 7.98316 13.1987 7.54251 12.8258 7.17209C11.3118 5.66009 8.68683 5.66009 7.17383 7.17209L8.58583 8.58809C9.34583 7.83009 10.6558 7.83209 11.4118 8.58609Z"
                                        fill="#927E2F"
                                    />
                                </svg>
                            }
                        />
                    </div>
                    <div className="fields">
                        <p className="label">
                            What time of the day can customers book your ‘Ventor‘s text’?
                        </p>
                        <Select
                            style={{
                                border: '0.3px solid #a2792c',
                                borderRadius: '8px 8px 0px 0px',
                                width: '100%',
                                height: 45
                            }}
                            bordered={false}
                        />
                        <p className="label">
                            Select the customers calendar days this booking apply
                        </p>
                        <p className="label_">
                            This product will be availlable for customers to book on the days
                            selected below.
                        </p>
                        <Checkbox className="mt30">Select all</Checkbox>
                        <div className="select-days">
                            {days.map((day) => (
                                <div
                                    key={day.day}
                                    onClick={() => pickDay(day.day)}
                                    className={`day ${
                                        selectedDays.includes(day.day) ? 'selected' : ''
                                    }`}>
                                    Day {day.day}
                                </div>
                            ))}
                        </div>

                        <p className="label">Add Photos & Videos</p>
                        <p className="label_">
                            Use photos that highlight what’s unique about your experience and show
                            travelers having fun. Adding 6-9 photos and at least 1 video can
                            increase your bookings.
                        </p>
                        <div className="pictures">
                            <div className="picture">
                                <PlusCircleOutlined color="#A2792C" />
                                <p>Add picture</p>
                            </div>
                            <div className="picture">
                                <PlusCircleOutlined color="#A2792C" />
                                <p>Add picture</p>
                            </div>
                        </div>
                    </div>

                    <div className="fields">
                        <div className="extra-details">
                            <div className="detail">
                                <p className="heading">
                                    Language Offered{' '}
                                    <InfoCircleFilled
                                        style={{
                                            fontSize: '12px',
                                            color: '#E2C022',
                                            position: 'relative',
                                            top: '-12px'
                                        }}
                                        size="small"
                                        color="#A2792C"
                                    />{' '}
                                </p>
                                <p style={{ color: '#333333', margin: 0 }}>
                                    <PlusCircleOutlined
                                        color="#A2792C"
                                        style={{ marginRight: 10 }}
                                    />
                                    Add language offered
                                </p>
                            </div>
                            <div className="detail">
                                <p className="heading">
                                    Inclusions & Exclusions{' '}
                                    <InfoCircleFilled
                                        style={{
                                            fontSize: '12px',
                                            color: '#E2C022',
                                            position: 'relative',
                                            top: '-12px'
                                        }}
                                        size="small"
                                        color="#A2792C"
                                    />{' '}
                                </p>
                                <p
                                    onClick={() => setTicketModal(true)}
                                    style={{ color: '#333333', margin: 0 }}
                                    className="pointer">
                                    <PlusCircleOutlined
                                        color="#A2792C"
                                        style={{ marginRight: 10 }}
                                    />
                                    Add inclusions & exclusions
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="fields">
                        <p className="label">
                            Now tell us where you meet travelers for this ‘Ventor‘s text’?
                        </p>
                        <div className="mb-12px">
                            <Checkbox>At the accomodation provided by you</Checkbox>
                        </div>
                        <div className="mb-12px">
                            <Checkbox>
                                Allow travellers to enter their their own pick up location
                                <div className="fs-14">
                                    Do not select this, if you only pick up from the your
                                    accomodation or the starting point you identified.
                                </div>
                            </Checkbox>
                        </div>
                        <div className="mb-12px">
                            <Checkbox>at a Starting Point</Checkbox>
                        </div>
                        <p className="brown-text fs14 mt20">
                            <PlusCircleOutlined color="#A2792C" style={{ marginRight: 12 }} />
                            Add another starting point
                        </p>
                        <p className="label">What is the departure time?</p>

                        <Input
                            style={{
                                width: 200,
                                border: '0.3px solid #a2792c',
                                borderRadius: 8,
                                marginRight: 12,
                                overflow: 'hidden'
                            }}
                            addonAfter={'Hours'}
                            defaultValue="0"
                            bordered={false}
                        />
                        <Input
                            style={{
                                width: 200,
                                border: '0.3px solid #a2792c',
                                borderRadius: 8,
                                marginRight: 12,
                                overflow: 'hidden'
                            }}
                            addonAfter={'Minutes'}
                            defaultValue="0"
                            bordered={false}
                        />
                        <Select
                            options={[
                                { text: 'AM', value: 'AM' },
                                { text: 'PM', value: 'PM' }
                            ]}
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: '0px 8px 8px 0px',
                                height: 45,
                                width: 80,
                                marginLeft: 15
                            }}
                            bordered={false}
                        />

                        <p className="label">How long before departure does your pickup start?</p>
                        <Input
                            style={{
                                width: 200,
                                border: '0.3px solid #a2792c',
                                borderRadius: 8,
                                marginRight: 12,
                                overflow: 'hidden'
                            }}
                            addonAfter={<Select style={{ width: 100 }} />}
                            defaultValue="0"
                            bordered={false}
                        />

                        <p className="label">
                            Enter the maximum number of spaces available to book this ‘Ventor‘s
                            text’ for the time period.
                        </p>
                        <p className="fs14">
                            This will indicate the spaces available to travelers and once this is
                            reached, this product activtity will be marked as sold out for that day
                        </p>
                        <Input
                            bordered={false}
                            style={{
                                border: '0.3px solid #a2792c',
                                width: 100,
                                height: 45,
                                borderRadius: 8
                            }}
                        />
                    </div>

                    <div className="extra-details">
                        <div className="detail">
                            <p className="heading">
                                Pricing{' '}
                                <InfoCircleFilled
                                    style={{
                                        fontSize: '12px',
                                        color: '#E2C022',
                                        position: 'relative',
                                        top: '-12px'
                                    }}
                                    size="small"
                                    color="#A2792C"
                                />{' '}
                            </p>
                            <p style={{ color: '#333333', margin: 0 }}>
                                <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />
                                Add Pricing details for your ‘Product Title’ Market Guide
                            </p>
                        </div>
                        <div className="detail">
                            <p className="heading">
                                Booking and Tickets{' '}
                                <InfoCircleFilled
                                    style={{
                                        fontSize: '12px',
                                        color: '#E2C022',
                                        position: 'relative',
                                        top: '-12px'
                                    }}
                                    size="small"
                                    color="#A2792C"
                                />{' '}
                            </p>
                            <p
                                onClick={() => setmodalStep(1)}
                                style={{ color: '#333333', margin: 0 }}
                                className="pointer">
                                <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />
                                Add Booking and Ticketing details for your ‘Product Title’ Market
                                Guide
                            </p>
                        </div>

                        <div className="detail">
                            <p className="heading">
                                Information travellers need from you{' '}
                                <InfoCircleFilled
                                    style={{
                                        fontSize: '12px',
                                        color: '#E2C022',
                                        position: 'relative',
                                        top: '-12px'
                                    }}
                                    size="small"
                                    color="#A2792C"
                                />{' '}
                            </p>
                            <p style={{ color: '#333333', margin: 0 }}>
                                <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />
                                Add any physical restrictions for this ‘Ventor‘s text’
                            </p>
                        </div>
                        <div className="detail">
                            <p className="heading">
                                Covid-19 Updates{' '}
                                <InfoCircleFilled
                                    style={{
                                        fontSize: '12px',
                                        color: '#E2C022',
                                        position: 'relative',
                                        top: '-12px'
                                    }}
                                    size="small"
                                    color="#A2792C"
                                />{' '}
                            </p>
                            <p style={{ color: '#333333', margin: 0 }}>
                                <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />
                                Add Covid-19 safety updates
                            </p>
                        </div>

                        <div className="buttons mt99 pt99">
                            <Button type="primary" className="rounded-btn mr20">
                                Done
                            </Button>
                            <Button type="ghost" className="rounded-btn">
                                Cancel
                            </Button>
                            <div style={{ float: 'right' }}>
                                <Feedback />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt40 white-bg p30">
                    <CalendarComponent />
                </div>
            </div>

            <TicketModal modalStep={modalStep} setmodalStep={setmodalStep} />
        </VendorDashboard>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import ShareIcon from 'components/atoms/icons/share-icon';
import { connect } from 'react-redux';
import { StarRatingBig } from 'components/atoms';
import { getVendorRankFromStars } from 'utils';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';
import { ComponentPermissionContext } from 'context/rolesandpermission';
const VendorRatingCard = (props) => {
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);
    const { handleOnClick, handleOnKeyUp, vendorRating } = props;
    return (
        <Card
            className={`border-secondary card-sm mb-10px ${assignComponentPermission(
                'calendar-rating'
            )}`}>
            <CardBody
                className={`text-center ${assignComponentPermission('calendar-rating-dashboard')}`}>
                <div className="fs-14 fw-600 text-primary">Vendor Rating</div>
                <div>
                    <StarRatingBig stars={Number(vendorRating?.stars)} />
                </div>
                <div className="mt-2 d-flex flex-nowrap">
                    <span className="px-0 col">&nbsp;</span>
                    <div className="px-2 col-auto">
                        <img src={require('assets/images/jinni-primary-icon.svg')} alt="Jinni" />
                        <span className="fs-20 fw-600 text-primary text-uppercase align-middle">
                            {getVendorRankFromStars(vendorRating?.stars)}
                        </span>
                    </div>
                    <div className="px-0 col align-self-end text-right">
                        <span
                            className="p-1 fs-12 text-primary"
                            role="button"
                            tabIndex="0"
                            onClick={handleOnClick}
                            onKeyUp={handleOnKeyUp}>
                            <ShareIcon /> Share
                        </span>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

VendorRatingCard.propTypes = {
    handleOnClick: PropTypes.func,
    handleOnKeyUp: PropTypes.func,
    vendorRating: PropTypes.any
};
const mapStateToProps = (state) => ({
    vendorRating: state?.profile?.vendor_rating
});
export default connect(mapStateToProps)(VendorRatingCard);

import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const CustomerStatusWrap = (props) => {
    const { currentWindowWidth, status } = props;
    let customerStatus = status ? status : 'not-in-use';
    let showCustomerStatus = customerStatus == 'not-in-use' ? 'Not In Use' : 'In Use';
    return (
        <Tooltip
            placement={currentWindowWidth >= 768 ? 'top' : 'right'}
            title={
                <div className="text-center">
                    <div className="fw-600">Customer Status:</div>
                    <div className="text-secondary fs-12">
                        Your calendar is{' '}
                        {customerStatus === 'not-in-use' ? 'not currently' : 'currently'} in use by
                        customers.
                    </div>
                </div>
            }>
            <div className="fs-14 text-nowrap">
                <span
                    className={`calendar_activity-status __${showCustomerStatus
                        .toLowerCase()
                        .replace(/ /g, '_')}`}></span>
                <span className="fs-14 text-gray">{showCustomerStatus}</span>
            </div>
        </Tooltip>
    );
};

CustomerStatusWrap.propTypes = {
    currentWindowWidth: PropTypes.number,
    status: PropTypes.string
};

export default CustomerStatusWrap;

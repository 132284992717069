import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Popover, Select } from 'antd';
import PlusIcon from '../../../../atoms/icons/plusIcon';
import BasicProgress from '../../../../atoms/basic-progress';
import { getAllCalendarProducts } from 'store/actions/calendarActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ProductQualityItem from './ProductQualityItem';
import PropTypes from 'prop-types';

const ProductQualityModal = (props) => {
    const { Option } = Select;
    const {
        isModalOpen,
        setModalOpenState,
        goBackAction,
        getAllCalendarProducts,
        get_all_calendar_products
    } = props;
    const allProducts = get_all_calendar_products?.data;
    const [currentProduct, setCurrentProduct] = useState(null);
    let calendarRating = calculateAverage(allProducts);

    useEffect(() => {
        getAllCalendarProducts(props?.match?.params?.id);
    }, []);

    const onSelect = (id) => {
        for (let product of allProducts) {
            if (product.id == id) return setCurrentProduct(product);
        }
        return setCurrentProduct(null);
    };

    return (
        <Modal
            centered
            scrollable
            isOpen={isModalOpen}
            toggle={() => {
                setModalOpenState(!isModalOpen);
            }}>
            <ModalHeader
                className="close-alt"
                toggle={() => setModalOpenState(!isModalOpen)}></ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex flex-wrap flex-lg-nowrap mb-3">
                    <h5 className="modal-title order-2 order-lg-1">
                        Product Quality{' '}
                        <Popover
                            overlayClassName="popover-container popover-large"
                            placement="top"
                            content={
                                <div className="fs-14">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Vestibulum non blandit dui. Mauris elementum ex nulla. Duis enim
                                    nulla, eleifend blandit faucibus quis,
                                </div>
                            }>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                                    fill="#333333"
                                />
                                <path
                                    d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        </Popover>
                    </h5>
                    <div className="flex-shrink-0 order-1 order-lg-2 ml-lg-2">
                        <Button
                            color="link"
                            className="px-0 text-decoration-underline"
                            type="button"
                            onClick={goBackAction}>
                            Go Back
                        </Button>
                    </div>
                </div>
                <div>
                    <Row noGutters>
                        <Col lg={6}>
                            <Form.Item className="mb-2" name="select_product">
                                <Select
                                    className="form-control-alt d-block"
                                    dropdownClassName="type-rich"
                                    placeholder="Select Product"
                                    defaultValue={JSON.stringify(null)}
                                    onSelect={onSelect}
                                    size="large">
                                    <Option key={-1} value={JSON.stringify(null)}>
                                        All Products
                                    </Option>
                                    {allProducts?.map((item, i) => (
                                        <Option key={i} value={item.id}>
                                            {item.title || 'Untitled Product'}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row noGutters>
                        <Col xs="12">
                            <span role="button" className="float-right fs-12 text-primary fw-400">
                                Select product to increase <PlusIcon />
                            </span>
                            <BasicProgress
                                className="progress-count-view-alt"
                                percent={
                                    currentProduct
                                        ? currentProduct?.rating?.average || 0
                                        : calendarRating?.average || 0
                                    // currentProduct ? currentProduct : 0
                                }
                            />
                        </Col>
                    </Row>
                </div>
                {currentProduct ? (
                    <div className="overflow-y-auto scrollbar-fixed pr-2 mr-n2">
                        <ProductQualityItem
                            key={'Customer Feedback'}
                            title={'Customer Feedback'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.customer_feedback || 0
                                    : calendarRating?.customer_feedback || 0
                            }
                        />

                        <ProductQualityItem
                            key={'High Defination Pictures and Videos'}
                            title={'High Defination Pictures and Videos'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.photos_and_videos || 0
                                    : calendarRating?.photos_and_videos || 0
                            }
                        />

                        <ProductQualityItem
                            key={'Type of Adventure'}
                            title={'Type of Adventure'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.type_of_adventure || 0
                                    : calendarRating?.type_of_adventure || 0
                            }
                        />

                        <ProductQualityItem
                            key={'Product Description'}
                            title={'Product Description'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.description || 0
                                    : calendarRating?.description || 0
                            }
                        />

                        <ProductQualityItem
                            key={'Top Sights'}
                            title={'Top Sights'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.top_sights || 0
                                    : calendarRating?.top_sights || 0
                            }
                        />

                        <ProductQualityItem
                            key={'Covid 19 Updates'}
                            title={'Covid 19 Updates'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.covid_19_updates || 0
                                    : calendarRating?.covid_19_updates || 0
                            }
                        />

                        <ProductQualityItem
                            key={'Review Connections'}
                            title={'Review Connections'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.review_connections || 0
                                    : calendarRating?.review_connections || 0
                            }
                        />

                        <ProductQualityItem
                            key={'Customer Requests'}
                            title={'Customer Requests'}
                            value={
                                currentProduct
                                    ? currentProduct?.rating?.customer_requests || 0
                                    : calendarRating?.customer_requests || 0
                            }
                        />
                    </div>
                ) : (
                    <></>
                )}
            </ModalBody>
        </Modal>
    );
};

const mapStateToProps = (state) => ({
    get_all_calendar_products: state.calendar.get_all_calendar_products
});

const mapDispatch = (dispatch) => ({
    getAllCalendarProducts: (id) => dispatch(getAllCalendarProducts(id))
});
ProductQualityModal.propTypes = {
    isModalOpen: PropTypes.any,
    setModalOpenState: PropTypes.any,
    goBackAction: PropTypes.any,
    getAllCalendarProducts: PropTypes.func,
    get_all_calendar_products: PropTypes.any,
    match: PropTypes.any
};
export default withRouter(connect(mapStateToProps, mapDispatch)(ProductQualityModal));

const calculateAverage = (allProducts) => {
    try {
        let totalRating = {
            description: 0,
            photos_and_videos: 0,
            customer_feedback: 0,
            type_of_adventure: 0,
            top_sights: 0,
            review_connections: 0,
            customer_requests: 0,
            covid_19_updates: 0,
            average: 0
        };
        if (!allProducts || allProducts?.length < 1)
            return {
                description: 0,
                photos_and_videos: 0,
                customer_feedback: 0,
                type_of_adventure: 0,
                top_sights: 0,
                review_connections: 0,
                customer_requests: 0,
                covid_19_updates: 0,
                average: 0
            };
        for (let product of allProducts) {
            totalRating.description += product?.rating?.description || 0;
            totalRating.photos_and_videos += product?.rating?.photos_and_videos || 0;
            totalRating.customer_feedback += product?.rating?.customer_feedback || 0;
            totalRating.type_of_adventure += product?.rating?.type_of_adventure || 0;
            totalRating.top_sights += product?.rating?.top_sights || 0;
            totalRating.review_connections += product?.rating?.review_connections || 0;
            totalRating.customer_requests += product?.rating?.customer_requests || 0;
            totalRating.covid_19_updates += product?.rating?.covid_19_updates || 0;
        }

        totalRating.description = totalRating.description / allProducts.length;
        totalRating.photos_and_videos = totalRating.photos_and_videos / allProducts.length;
        totalRating.customer_feedback = totalRating.customer_feedback / allProducts.length;
        totalRating.type_of_adventure = totalRating.type_of_adventure / allProducts.length;
        totalRating.top_sights = totalRating.top_sights / allProducts.length;
        totalRating.review_connections = totalRating.review_connections / allProducts.length;
        totalRating.customer_requests = totalRating.customer_requests / allProducts.length;
        totalRating.covid_19_updates = totalRating.covid_19_updates / allProducts.length;
        totalRating.average = (
            Number(
                totalRating.description +
                    totalRating.photos_and_videos +
                    totalRating.covid_19_updates +
                    totalRating.customer_feedback +
                    totalRating.type_of_adventure +
                    totalRating.top_sights +
                    totalRating.review_connections +
                    totalRating.customer_requests
            ) / 8
        ).toFixed(0);
        return totalRating;
    } catch (error) {
        return {
            rating: {
                description: 0,
                photos_and_videos: 0,
                customer_feedback: 0,
                type_of_adventure: 0,
                top_sights: 0,
                review_connections: 0,
                customer_requests: 0,
                covid_19_updates: 0,
                average: 0
            }
        };
    }
};

import { io } from 'socket.io-client';

const baseUrl = process.env.REACT_APP_SOCKET_URL;

export default function SocketConnector() {
    const token = localStorage.getItem('token');

    const socket = io(baseUrl, {
        auth: {
            token
        }
    });
    socket.on('connect', () => {
        return 'Socket connected';
    });

    return socket;
}

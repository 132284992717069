/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Form, Input, Drawer } from 'antd';
import { Button, Col, Row } from 'reactstrap';
import MoreInfo from 'assets/images/more_info.svg';
import HelpIcon from 'assets/images/helpIcon.svg';
import Spacer from 'components/atoms/spacer';
import PropTypes from 'prop-types';
import { updatePackageProduct, fetchProductDetail, packageDetail_ } from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const CreatePackageDrawer = (props) => {
    const {
        createYourPackageDrawer,
        setCreateYourPackageDrawer,
        setOpenActivityModal,
        packageDetail_,
        get_calendar_data
    } = props;
    const [createPackageForm] = Form.useForm();
    const [description, setDescription] = useState('');
    const onFinish = () => {
        let data = {
            title: createPackageForm.getFieldsValue().packageTitle,
            description: createPackageForm.getFieldsValue().packageDescription,
            calendar_id: get_calendar_data.data.id
        };
        //let id = create_addon_product?.product.id;

        //if (product_detail?.is_leading_product === 0)
        //if editing the main prodcut
        //id = product_detail.id;

        // updatePackageProduct({ data, id }).then((res) => {
        //     if (res?.payload?.data) {
        packageDetail_(data);
        setOpenActivityModal(true);
        setCreateYourPackageDrawer(false);
        createPackageForm.resetFields();
        setDescription('');
        //if (product_detail?.is_leading_product === 0) fetchProductDetail(product_detail.id);
        //     }
        // });
    };

    return (
        <Drawer
            className="application create-product"
            onClose={() => setCreateYourPackageDrawer(false)}
            visible={createYourPackageDrawer}
            zIndex={1051}>
            <Form
                layout="vertical"
                onFinish={onFinish}
                form={createPackageForm}
                requiredMark={false}>
                <Row>
                    <Col>
                        <h4 className="create-product-title">Create your package</h4>
                        <p>
                            Describe the main product you will like to use to attract travelers to
                            make a purchase. This is what gets you access to customers calendar.
                            You'll be able to add more details next.
                        </p>
                        <div className="input-title">
                            Enter your Package title <img src={MoreInfo} alt="" />
                        </div>
                        <Form.Item
                            name="packageTitle"
                            rules={[{ required: true, message: 'Please fill this field in' }]}>
                            <Input
                                size="large"
                                onChange={(e) => {
                                    createPackageForm.setFieldsValue({
                                        packageTitle: e.target.value
                                    });
                                }}
                            />
                        </Form.Item>

                        <Spacer top={26} />

                        <div className="input-title" style={{ marginLeft: '2px' }}>
                            Why should travelers book this package?
                        </div>
                        <Form.Item
                            name="packageDescription"
                            rules={[
                                { required: true, message: 'Please fill this field in' },
                                { min: 100, message: 'Minimum 100 characters needed' }
                            ]}
                            style={{ marginLeft: '5px' }}>
                            <Input.TextArea
                                className="input-area"
                                placeholder="Briefly describe what makes it unique"
                                onChange={(e) => {
                                    createPackageForm.setFieldsValue({
                                        packageDescription: e.target.value
                                    });
                                    setDescription(e.target.value);
                                }}
                            />
                        </Form.Item>
                        {description.length < 100 && (
                            <div className="description-input">
                                {100 - description.length} characters needed
                            </div>
                        )}
                        <Spacer top={26} />
                        <Form.Item>
                            <div className="footer-btn mt-3">
                                <Button color="primary" type="submit" className="mt-0">
                                    Continue
                                </Button>
                                <Button color="dark">
                                    <img src={HelpIcon} alt="" /> Help
                                </Button>
                            </div>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Drawer>
    );
};

CreatePackageDrawer.propTypes = {
    createYourPackageDrawer: PropTypes.bool,
    setCreateYourPackageDrawer: PropTypes.func,
    setOpenActivityModal: PropTypes.func,
    updatePackageProduct: PropTypes.func,
    create_addon_product: PropTypes.object,
    product_detail: PropTypes.object,
    fetchProductDetail: PropTypes.func,
    get_calendar_data: PropTypes.object,
    packageDetail_: PropTypes.func
};

const mapStateToProps = (state) => ({
    create_addon_product: state.calendar.create_addon_product,
    product_detail: state.product.product_detail,
    get_calendar_data: state.calendar.get_calendar_data
});

const mapDispatch = (dispatch) => ({
    updatePackageProduct: (data) => dispatch(updatePackageProduct(data)),
    fetchProductDetail: (id) => dispatch(fetchProductDetail(id)),
    packageDetail_: (data) => dispatch(packageDetail_(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(CreatePackageDrawer));

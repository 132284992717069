/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Drawer, Form, InputNumber } from 'antd';
import { Button } from 'reactstrap';
import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HelpIcon from 'assets/images/helpIcon.svg';
import { Grid } from '@material-ui/core';
import SharingGroups from '../single-supplement/sharirn-groups';
import Proptypes from 'prop-types';

const SingleSupplement = ({
    showModal,
    singleSupplement,
    setSingleSupplement,
    onClose,
    productAdultPrice,
    minAccomodation,
    maxAccomodation,
    setMinAccomodation,
    setMaxAccomodation
}) => {
    const onChange = (value, name, groupName) => {
        let tmp = { ...singleSupplement };
        if (name === 'secondPrice') {
            if (groupName === 'Double') {
                tmp.double = value;
            } else {
                tmp.twin = value;
            }
        }
        setSingleSupplement(tmp);
    };

    const onSave = async () => {
        try {
            await form.validateFields();
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    const [form] = Form.useForm();
    return (
        <div className="itenary-form">
            <Drawer
                className="application single-supplement"
                onClose={() => {
                    onClose();
                    setSingleSupplement(null);
                }}
                visible={showModal}
                style={{
                    zIndex: showModal ? 1065 : 1000
                }}
                bodyStyle={{ paddingBottom: 35, paddingTop: 39 }}>
                <Grid item className="perPerson">
                    <Grid container>
                        <Grid item className="singleSupplement">
                            Per person sharing - Single Supplement
                        </Grid>
                        <Grid item className="extraCharge">
                            This is an extra charge applied to a traveler who travels alone but will
                            use a room and facilities that cater for two.
                        </Grid>
                        <Grid item>
                            <Form form={form}>
                                <Form.Item name="SingleSupplement">
                                    <SharingGroups
                                        onChange={onChange}
                                        type={'product_supplement'}
                                        data={{
                                            double: {
                                                productPrice: productAdultPrice,
                                                secondPrice: singleSupplement?.double
                                            },
                                            twin: {
                                                productPrice: productAdultPrice,
                                                secondPrice: singleSupplement?.twin
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <p style={{ marginRight: 20 }}>
                                    Enter the minimum number of accommodations allowed in a single
                                    booking
                                </p>
                                <Form.Item
                                    className="min-max-input"
                                    rules={[
                                        {
                                            required: !minAccomodation,
                                            message: 'Please add min accommodations'
                                        }
                                    ]}>
                                    <InputNumber
                                        className="allowed-number travellers-min-max-input"
                                        min={1}
                                        value={minAccomodation}
                                        max={maxAccomodation}
                                        onChange={(value) => {
                                            setMinAccomodation(value);
                                        }}
                                    />
                                </Form.Item>
                                <p>
                                    Enter the maximum number of accomodations allowed in a single
                                    booking
                                </p>
                                <Form.Item
                                    className="min-max-input"
                                    rules={[
                                        {
                                            required: !maxAccomodation,
                                            message: 'Please add max accommodations'
                                        }
                                    ]}>
                                    <InputNumber
                                        className="allowed-number travellers-min-max-input"
                                        min={minAccomodation || 1}
                                        value={maxAccomodation}
                                        onChange={(value) => {
                                            setMaxAccomodation(value);
                                        }}
                                    />
                                </Form.Item>
                            </Form>
                        </Grid>
                    </Grid>
                </Grid>
                <div className="footer-button">
                    <Button color="primary" onClick={onSave}>
                        Save
                    </Button>
                    <Button color="dark">
                        <img src={HelpIcon} alt="" /> Help
                    </Button>
                </div>
            </Drawer>
        </div>
    );
};

SingleSupplement.propTypes = {
    showModal: Proptypes.bool,
    onClose: Proptypes.func,
    setSingleSupplement: Proptypes.func,
    singleSupplement: Proptypes.any,
    minAccomodation: Proptypes.number,
    maxAccomodation: Proptypes.number,
    setMinAccomodation: Proptypes.func,
    setMaxAccomodation: Proptypes.func,
    productAdultPrice: Proptypes.any
};

const mapStateToProps = () => ({});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(SingleSupplement));

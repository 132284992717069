import React from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const CustomerCalendarWrapMulti = (props) => {
    const { currentWindowWidth, userInfoPopover, multiUserInfoPopover, calendarBookingsData } =
        props;
    const firstShowTravellers = calendarBookingsData?.leadTravellers
        ? calendarBookingsData?.leadTravellers.length <= 3
            ? calendarBookingsData?.leadTravellers
            : calendarBookingsData?.leadTravellers.slice(0, 3)
        : [];
    const showRemaining = calendarBookingsData?.leadTravellers
        ? calendarBookingsData?.leadTravellers.length <= 3
            ? []
            : calendarBookingsData?.leadTravellers.slice(3)
        : [];
    return (
        <div className="d-flex mx-n1">
            {/* Loop this upto 3 */}
            {firstShowTravellers &&
                firstShowTravellers.map((item, index) => {
                    return (
                        <div className="px-1" key={index}>
                            <Popover
                                overlayClassName="popover-container popover-user-info"
                                content={userInfoPopover(item)}
                                placement={currentWindowWidth >= 768 ? 'top' : 'topRight'}>
                                <img
                                    className="calendar-user-img _sub"
                                    src={
                                        item?.photo_url
                                            ? item?.photo_url
                                            : require('assets/images/user-primary-img.svg')
                                    }
                                    alt="User"
                                />
                            </Popover>
                        </div>
                    );
                })}

            {/* <div className="px-1">
                <Popover
                    overlayClassName="popover-container popover-user-info"
                    content={userInfoPopover}
                    placement={currentWindowWidth >= 768 ? 'top' : 'topRight'}>
                    <img
                        className="calendar-user-img _sub"
                        src={`https://randomuser.me/api/portraits/women/${30}.jpg`}
                        alt="User"
                    />
                </Popover>
            </div>
            <div className="px-1">
                <Popover
                    overlayClassName="popover-container popover-user-info"
                    content={userInfoPopover}
                    placement={currentWindowWidth >= 768 ? 'top' : 'topRight'}>
                    <img
                        className="calendar-user-img _sub"
                        src={require('assets/images/user-primary-img.svg')}
                        alt="User"
                    />
                </Popover>
            </div> */}

            {/* Leftover Count */}
            {showRemaining && showRemaining.length > 0 && (
                <div className="px-1">
                    <Popover
                        overlayClassName="popover-container popover-user-info"
                        content={multiUserInfoPopover(showRemaining)}
                        placement={currentWindowWidth >= 768 ? 'bottomRight' : 'top'}>
                        <div className="calendar-user-img _sub _mask-only">+3</div>
                    </Popover>
                </div>
            )}
        </div>
    );
};
const mapStateToProps = (state) => ({
    calendarBookingsData: state.booking?.calendar_booked_details?.calendar
});
CustomerCalendarWrapMulti.propTypes = {
    currentWindowWidth: PropTypes.number,
    userInfoPopover: PropTypes.any,
    multiUserInfoPopover: PropTypes.any,
    calendarBookingsData: PropTypes.any
};
export default withRouter(connect(mapStateToProps, null)(CustomerCalendarWrapMulti));

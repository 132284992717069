import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { LanguageOfferedIcon } from 'components/atoms';

const EventLanguagesOffered = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<LanguageOfferedIcon />}
            panelTitle="Language Offered"
            isEditable={true}
            onEdit={onEdit}>
            {!(
                product?.language_offered?.is_language_offered &&
                product?.language_offered?.languages?.length
            ) > 0 ? (
                <></>
            ) : (
                product?.language_offered?.languages.map((item, index) => (
                    <Row key={index} className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6} md={4}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">{item.name}</span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6} md={8}>
                            <span className="fs-18 lh-1-7">
                                {item.in_person && 'In-person'}
                                {item.in_person && (item.written || item.audio) && ', '}
                                {item.audio && 'Audio'}
                                {item.audio && item.written && ', '}
                                {item.audio && 'Written'}
                            </span>
                        </Col>
                    </Row>
                ))
            )}
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Certified</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {product?.language_offered?.guide_is_certified ? 'Yes' : 'No'}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Serve as driver </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {' '}
                        {product?.language_offered?.guide_as_driver ? 'Yes' : 'No'}
                    </span>
                </Col>
            </Row>
        </DetailedPanel>
    );
};

EventLanguagesOffered.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventLanguagesOffered;

import React from 'react';
import { Tag, Tooltip, Button } from 'antd';
import { toDecimal, transformFirstLetterToUpperCase } from 'utils';
import PropTypes from 'prop-types';
import MoreInfo from 'assets/images/more_info.svg';
import { ComponentPermissionContext } from 'context/rolesandpermission';

export default function BalanceDetailSection(props) {
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);

    const {
        stripe_account_detail,
        my_profile,
        redirectToStripeDashboard,
        payoutsAndTransitTobank,
        fund_analysis
    } = props;
    return (
        <>
            {/* Account Stats */}
            <div
                className={`mb-4  ${assignComponentPermission(
                    'finance-balance-detail-section'
                )}   `}>
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <div className="text-muted">
                                <svg
                                    aria-hidden="true"
                                    height="12"
                                    width="12"
                                    viewBox="0 0 16 16"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3 7.5V12h10V7.5c.718 0 1.398-.168 2-.468V15a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V7.032c.602.3 1.282.468 2 .468zM0 3L1.703.445A1 1 0 0 1 2.535 0h10.93a1 1 0 0 1 .832.445L16 3a3 3 0 0 1-5.5 1.659C9.963 5.467 9.043 6 8 6s-1.963-.533-2.5-1.341A3 3 0 0 1 0 3z"
                                        fill="currentColor"
                                        fillRule="evenodd"></path>
                                </svg>
                            </div>
                        </div>
                        <div>
                            <div className="text-muted fs-13">
                                <span>Connected account</span>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-28 fw-700">
                                {stripe_account_detail?.business_profile?.name || ''}
                            </span>
                        </div>
                        <div>
                            <Tooltip
                                placement="right"
                                title={
                                    stripe_account_detail?.charges_enabled &&
                                    stripe_account_detail?.payouts_enabled &&
                                    stripe_account_detail?.details_submitted ? (
                                        <>Account setup is complete</>
                                    ) : (
                                        <div>
                                            <div className="fs-18">Click edit payouts</div>
                                            <ul>
                                                {!stripe_account_detail?.details_submitted && (
                                                    <li>Details needs to be submitted</li>
                                                )}
                                                {!stripe_account_detail?.charges_enabled && (
                                                    <li>Enable paymetns</li>
                                                )}
                                                {!stripe_account_detail?.payouts_enabled && (
                                                    <li>Enable payouts</li>
                                                )}
                                            </ul>
                                        </div>
                                    )
                                }>
                                {stripe_account_detail?.charges_enabled &&
                                stripe_account_detail?.payouts_enabled &&
                                stripe_account_detail?.details_submitted ? (
                                    <Tag color={'green'}>
                                        <span className="pr-2">Complete</span>
                                        <svg
                                            aria-hidden="true"
                                            height="12"
                                            width="12"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.297 13.213L.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                fillRule="evenodd"></path>
                                        </svg>
                                    </Tag>
                                ) : (
                                    <Tag color={'red'}>
                                        <span className="pr-2">Incomplete</span>
                                    </Tag>
                                )}
                            </Tooltip>
                        </div>
                        <div className="ml-auto">
                            {my_profile?.vendor?.stripe_connected_account_id && (
                                <Button
                                    onClick={redirectToStripeDashboard}
                                    id="payoutSettings"
                                    color="primary">
                                    Edit payout settings
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row flex-wrap gap-3 py-2">
                    <div className="pr-4 border-right">
                        <div>
                            <div className="d-flex flex-column justify-content-start flex-nowrap">
                                <div className="mt-1 ml-1">
                                    <span className="text-gray d-inline fs-14">
                                        <span>Payments</span>
                                    </span>
                                </div>
                                <div className="mt-1 ml-1">
                                    <div className="pt-1">
                                        <div>
                                            <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                <div className="pr-2">
                                                    <div aria-hidden="true">
                                                        <svg
                                                            aria-hidden="true"
                                                            height="12"
                                                            width="12"
                                                            viewBox="0 0 16 16"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z"
                                                                fillRule="evenodd"
                                                                fill={
                                                                    stripe_account_detail?.charges_enabled
                                                                        ? 'green'
                                                                        : 'grey'
                                                                }></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="text-body d-inline fs-14">
                                                        <span>
                                                            {stripe_account_detail?.charges_enabled
                                                                ? 'Enabled'
                                                                : 'Disabled '}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pr-4 border-right">
                        <div>
                            <div className="d-flex flex-column justify-content-start flex-nowrap">
                                <div className="mt-1 ml-1">
                                    <span className="text-gray d-inline fs-14">
                                        <span>Payouts</span>
                                    </span>
                                </div>
                                <div className="mt-1 ml-1">
                                    <div className="pt-1">
                                        <div>
                                            <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                <div className="pr-2">
                                                    <div aria-hidden="true">
                                                        <svg
                                                            aria-hidden="true"
                                                            height="12"
                                                            width="12"
                                                            viewBox="0 0 16 16"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M8 16A8 8 0 1 1 8 0a8 8 0 0 1 0 16zm3.083-11.005L7 9.085 5.207 7.294a1 1 0 0 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l4.79-4.798a1 1 0 1 0-1.414-1.414z"
                                                                fillRule="evenodd"
                                                                fill={
                                                                    stripe_account_detail?.payouts_enabled
                                                                        ? 'green'
                                                                        : 'grey'
                                                                }></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="text-body d-inline fs-14">
                                                        <span>
                                                            {stripe_account_detail?.settings
                                                                ?.payouts?.schedule?.interval
                                                                ? transformFirstLetterToUpperCase(
                                                                      stripe_account_detail
                                                                          ?.settings?.payouts
                                                                          ?.schedule?.interval
                                                                  )
                                                                : 'N/A'}
                                                        </span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pr-4">
                        <div>
                            <div className="d-flex flex-column justify-content-start flex-nowrap">
                                <div className="mt-1 ml-1">
                                    <span className="text-gray d-inline fs-14">
                                        <span>Lifetime total volume</span>
                                    </span>
                                </div>
                                <div className="mt-1 ml-1">
                                    <div className="pt-1">
                                        <div>
                                            <div className="d-flex gap-3 flex-wrap align-items-center">
                                                <div className="d-inline-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                    <div className="pr-2">
                                                        <span>{`$${
                                                            fund_analysis?.vendor_received_amount
                                                                ? toDecimal(
                                                                      fund_analysis?.vendor_received_amount /
                                                                          100
                                                                  )
                                                                : 0
                                                        }`}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-gray fs-13 d-inline">
                                                            {' '}
                                                            CAD
                                                        </span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className="border-right d-block h-100">
                                                        &nbsp;
                                                    </span>
                                                </div>
                                                <div className="d-inline-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                    <div className="pr-2">
                                                        <span>$0</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-gray fs-13 d-inline">
                                                            {' '}
                                                            USD
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Balance Stats */}
            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Balance</span>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-row flex-wrap gap-3 py-2 border-bottom">
                    <div className="pr-4" style={{ minWidth: 175 }}>
                        <div>
                            <div className="d-flex flex-column justify-content-start flex-nowrap">
                                <div className="mt-1 ml-1">
                                    <span className="text-gray d-inline fs-14 fw-500">
                                        <span>Total balance</span>
                                    </span>
                                </div>
                                <div className="mt-1 ml-1">
                                    <div className="pt-1">
                                        <div>
                                            <div>
                                                <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                    <div className="pr-2">
                                                        <span className="fw-500">{`$${
                                                            fund_analysis?.vendor_balance
                                                                ? toDecimal(
                                                                      fund_analysis?.vendor_balance /
                                                                          100
                                                                  )
                                                                : 0
                                                        }`}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-gray fs-13 d-inline">
                                                            {stripe_account_detail?.currency
                                                                ? stripe_account_detail?.currency.toUpperCase()
                                                                : ''}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pr-4" style={{ minWidth: 175 }}>
                        <div>
                            <div className="d-flex flex-column justify-content-start flex-nowrap">
                                <div className="mt-1 ml-1">
                                    <span className="text-gray d-inline fs-14">
                                        <span>Total payouts</span>
                                    </span>
                                </div>
                                <div className="mt-1 ml-1">
                                    <div className="pt-1">
                                        <div>
                                            <div>
                                                <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                    <div className="pr-2">
                                                        <span>{`$${
                                                            payoutsAndTransitTobank?.totalPayout
                                                                ? toDecimal(
                                                                      payoutsAndTransitTobank?.totalPayout /
                                                                          100
                                                                  )
                                                                : 0
                                                        }`}</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-gray fs-13 d-inline">
                                                            USD
                                                            {/* {` ${payoutsAndTransitTobank().currency.toUpperCase()}`} */}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pr-4" style={{ minWidth: 175 }}>
                        <div>
                            <div className="d-flex flex-column justify-content-start flex-nowrap">
                                <div className="mt-1 ml-1">
                                    <span className="text-gray d-inline fs-14">
                                        <span>In transit to bank</span>
                                    </span>
                                </div>
                                <div className="mt-1 ml-1">
                                    <div className="pt-1">
                                        <div>
                                            <div>
                                                <div className="d-flex align-items-center flex-row justify-content-start flex-nowrap">
                                                    <div className="pr-2">
                                                        <span>$0.00</span>
                                                    </div>
                                                    <div>
                                                        <span className="text-gray fs-13 d-inline">
                                                            {' '}
                                                            CAD
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4">
                    <span className="fs-14 pr-2">Debit negative balances</span>
                    <span className="pr-2">
                        <Tooltip title={<div>Balance Can be debit negative</div>}>
                            <img src={MoreInfo} alt="More info" />
                        </Tooltip>
                    </span>
                    <Tag color="blue">
                        {stripe_account_detail?.settings?.payouts?.debit_negative_balances
                            ? 'ON'
                            : 'OFF'}
                    </Tag>
                </div>
            </div>
        </>
    );
}

BalanceDetailSection.propTypes = {
    stripe_account_detail: PropTypes.object,
    my_profile: PropTypes.object,
    redirectToStripeDashboard: PropTypes.func,
    payoutsAndTransitTobank: PropTypes.func,
    fund_analysis: PropTypes.object
};

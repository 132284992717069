/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from 'react';
import { Select, Input, DatePicker, Form } from 'antd';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    calendarBookedProducts,
    calendarBookedProducts_,
    allProducts,
    calendarBookedDetails,
    calendarBookedDetails_
} from 'store/actions';

const AllFiltersWrap = (props) => {
    const {
        onChangeCalendar,
        booked_calendars,
        viewTab,
        calendarBookedProducts,
        calendarBookedProducts_,
        //rememberFiltersData,
        lead_travellers,
        allProducts,
        all_products,
        redirectToCalendarView,
        onChangeViewTab,
        viewSummaryData,
        setRedirectToCalendarView,
        calendarBookedDetails,
        calendarBookedDetails_
    } = props;
    const [selectedCalendar, setSelectedCalendar] = useState(null);
    const [referenceId, setReferenceId] = useState('');
    let [fromDate, setFromDate] = useState('');
    let [toDate, setToDate] = useState('');
    const [filtersForm] = Form.useForm();
    const [error, setError] = useState('');
    const [leadTravellerId, setLeadTravellerId] = useState('');
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [hitApi, setHitApi] = useState(false);

    const { Option } = Select;
    useEffect(() => {
        if (viewTab && !redirectToCalendarView) {
            //resetFormFields();
            calendarBookedProducts_({ data: {}, success: true });
            calendarBookedDetails_({ data: null, success: true });
        }
        //rememberFiltersData(null);
    }, [viewTab, redirectToCalendarView]);

    useEffect(() => {
        allProducts();
    }, []);

    useEffect(() => {
        if (redirectToCalendarView && viewSummaryData?.calendar_id) {
            onChangeViewTab('1');
            onChangeCalendarId(viewSummaryData?.calendar_id);
            setRedirectToCalendarView(false);
            setHitApi(true);
        }
    }, [redirectToCalendarView, viewSummaryData]);

    useEffect(() => {
        if (fromDate && toDate && hitApi && !redirectToCalendarView) {
            setHitApi(false);
            onFinish();
        }
    }, [fromDate, toDate, hitApi, redirectToCalendarView]);

    // useEffect(() => {
    //     if (fromDate) {
    //         filtersForm.setFieldsValue({
    //             fromDate: moment(fromDate)
    //         });
    //     }
    //     if (toDate) {
    //         filtersForm.setFieldsValue({
    //             toDate: moment(toDate)
    //         });
    //     }
    //
    // }, [viewTab]);

    const onFinish = () => {
        let errorMessage = checkValidationError();
        if (errorMessage) {
            setError(errorMessage);
        } else {
            setError('');
            if (selectedCalendar?.id) onChangeCalendar(selectedCalendar?.id);
            let queryParamsString = getQueryParams();

            if (viewTab == 'product') {
                calendarBookedProducts(queryParamsString);
            } else {
                calendarBookedDetails(queryParamsString);
            }
            // let rememberData = {
            //     selectedCalendar,
            //     referenceId,
            //     fromDate,
            //     toDate,
            //     leadTravellerId,
            //     selectedProducts
            // };
            //rememberFiltersData(rememberData);
        }
    };
    const getQueryParams = () => {
        let queryParams = [];
        queryParams.push(`startDate=${fromDate}`);
        queryParams.push(`endDate=${toDate}`);
        if (referenceId) {
            queryParams.push(`calendarRefNo=${referenceId}`);
        }
        if (selectedCalendar?.id) {
            queryParams.push(`calendarId=${selectedCalendar?.id}`);
        }
        if (leadTravellerId && leadTravellerId != 'all') {
            queryParams.push(`leadTraveller=${leadTravellerId}`);
        }
        if (selectedProducts.length > 0) {
            queryParams.push(`productId=${selectedProducts.join(',')}`);
        }
        return queryParams.join('&');
    };
    const resetFormFields = () => {
        filtersForm.resetFields();
        setSelectedCalendar(null);
        setReferenceId('');
        setFromDate('');
        setToDate('');
        setLeadTravellerId('');
        // setSearchProduct('');
        setSelectedProducts([]);
    };

    const checkValidationError = () => {
        if (viewTab == 'calendar' && !selectedCalendar?.id) {
            return 'Please select a calendar';
        }
        if (fromDate && toDate) {
            let newFromDate = moment(fromDate);
            let newToDate = moment(toDate);
            if (newToDate.diff(newFromDate, 'days') < 0) {
                return 'From date must be smaller than to date';
            }
        }
        if (!fromDate) {
            return 'Please select a from date';
        }
        if (!toDate) {
            return 'Please select a to date';
        }
        return '';
    };

    const onClickToday = () => {
        let newFrom = moment(new Date());
        setFromDate(newFrom.format('YYYY-MM-DD'));
        setToDate(newFrom.format('YYYY-MM-DD'));
        filtersForm.setFieldsValue({
            fromDate: newFrom
        });
        filtersForm.setFieldsValue({
            toDate: newFrom
        });
        setHitApi(true);
    };

    const onClickTomorrow = () => {
        let newFrom = moment().add(1, 'days');
        setFromDate(newFrom.format('YYYY-MM-DD'));
        setToDate(newFrom.format('YYYY-MM-DD'));
        filtersForm.setFieldsValue({
            fromDate: newFrom
        });
        filtersForm.setFieldsValue({
            toDate: newFrom
        });
        setHitApi(true);
    };

    const onClickNextSeven = () => {
        let newFrom = moment(new Date());
        let newTo = moment().add(7, 'days');
        setFromDate(newFrom.format('YYYY-MM-DD'));
        setToDate(newTo.format('YYYY-MM-DD'));
        filtersForm.setFieldsValue({
            fromDate: newFrom
        });
        filtersForm.setFieldsValue({
            toDate: newTo
        });
        setHitApi(true);
    };

    const onChangeCalendarId = (id) => {
        let calendar = booked_calendars.find((x) => x.id === id);
        setSelectedCalendar(calendar);
        filtersForm.setFieldsValue({
            selectedCalendar: id
        });
    };

    const handleProductChange = (selectedProducts) => {
        setSelectedProducts(selectedProducts);
    };
    return (
        <div className="w-100 pb-3">
            <Form requiredMark={false} form={filtersForm} onFinish={onFinish}>
                <div className="d-flex justify-content-between mb-2">
                    <div className="mr-2">
                        <div className="text-primary fs-14">
                            <span role="button" tabIndex="0" onClick={onClickToday}>
                                Today
                            </span>
                            <span className="mx-2">|</span>
                            <span role="button" tabIndex="0" onClick={onClickTomorrow}>
                                Tomorrow
                            </span>
                            <span className="mx-2">|</span>
                            <span role="button" tabIndex="0" onClick={onClickNextSeven}>
                                Next 7 days
                            </span>
                        </div>
                    </div>
                    <div onClick={resetFormFields}>
                        <span className="fs-12 text-primary fw-700" role="button" tabIndex="0">
                            Clear all
                        </span>
                    </div>
                </div>
                <div className="d-flex flex-wrap flex-xxl-nowrap m-n2">
                    <div className="p-2 col col-md col-xxl">
                        <Form.Item name="selectedCalendar">
                            <Select
                                className="filter-input-control d-block select-type-filter"
                                dropdownClassName="select-basic-dropdown type-bordered"
                                size="large"
                                placeholder="Select Calendar"
                                allowClear
                                onChange={onChangeCalendarId}>
                                {booked_calendars &&
                                    booked_calendars.length > 0 &&
                                    booked_calendars.map((value) => (
                                        <Select.Option value={value.id} key={value.id}>
                                            {value.title}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="p-2 col col-md col-xxl">
                        <Form.Item name="referenceId">
                            <Input
                                className="filter-input-control d-block placeholder-primary"
                                placeholder="BR- e.g. 9147869"
                                size="large"
                                style={{ minWidth: 160 }}
                                value={referenceId}
                                onChange={(e) => {
                                    setReferenceId(e.target.value);
                                }}
                            />
                        </Form.Item>
                    </div>
                    <div className="p-2 col col-md col-xxl">
                        <Form.Item name="fromDate">
                            <DatePicker
                                className="filter-input-control d-block placeholder-primary __with-icon"
                                placeholder="From"
                                size="large"
                                style={{ minWidth: 160 }}
                                onChange={(date, dateString) => {
                                    setFromDate(dateString);
                                    filtersForm.setFieldsValue({
                                        fromDate: date
                                    });
                                }}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </div>
                    <div className="p-2 col col-md col-xxl">
                        <Form.Item name="toDate">
                            <DatePicker
                                className="filter-input-control d-block placeholder-primary __with-icon"
                                placeholder="to"
                                size="large"
                                style={{ minWidth: 160 }}
                                onChange={(date, dateString) => {
                                    setToDate(dateString);
                                    filtersForm.setFieldsValue({
                                        toDate: date
                                    });
                                }}
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </div>
                    <div className="p-0 col-12 d-none d-lg-block d-xxl-none"></div>
                    <div className="p-2 col col-md col-xxl">
                        <Form.Item name="leadTraveller">
                            <Select
                                className="filter-input-control d-block select-type-filter"
                                dropdownClassName="select-basic-dropdown type-bordered"
                                size="large"
                                placeholder="Lead Traveler"
                                allowClear
                                onChange={(val) => {
                                    setLeadTravellerId(val);
                                    filtersForm.setFieldsValue({
                                        leadTraveller: val
                                    });
                                }}>
                                <Select.Option value="all">All Travelers</Select.Option>
                                {lead_travellers &&
                                    lead_travellers.length > 0 &&
                                    lead_travellers.map((value) => (
                                        <Select.Option value={value.id} key={value.id}>
                                            {value.first_name} {value.last_name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </div>
                    <div className="p-2 col col-md col-xxl">
                        <Form.Item name="productsDropdown">
                            <Select
                                className="filter-input-control d-block select-type-filter"
                                dropdownClassName="select-basic-dropdown type-bordered"
                                placeholder={`All Products (${all_products.length})`}
                                onChange={handleProductChange}
                                value={selectedProducts}
                                style={{ minWidth: 260 }}
                                maxTagTextLength={12}
                                maxTagCount={1}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                mode="multiple"
                                showArrow
                                allowClear>
                                {all_products &&
                                    all_products.length > 0 &&
                                    all_products.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item?.title}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>

                <div className="d-flex flex-column align-items-center mt-2">
                    {error && (
                        <div className="mb-1">
                            <small className="text-danger">{error}</small>
                        </div>
                    )}
                    <Button color="primary" className="btn-mw" type="submit">
                        Apply
                    </Button>
                </div>
            </Form>
        </div>
    );
};

AllFiltersWrap.propTypes = {
    onChangeCalendar: PropTypes.func,
    booked_calendars: PropTypes.array,
    viewTab: PropTypes.string,
    calendarBookedProducts: PropTypes.func,
    calendarBookedProducts_: PropTypes.func,
    //rememberFiltersData: PropTypes.func,
    lead_travellers: PropTypes.any,
    allProducts: PropTypes.func,
    all_products: PropTypes.array,
    redirectToCalendarView: PropTypes.bool,
    calendarBookedDetails: PropTypes.func,
    calendarBookedDetails_: PropTypes.func,
    onChangeViewTab: PropTypes.func,
    viewSummaryData: PropTypes.any,
    setRedirectToCalendarView: PropTypes.func
};
const mapStateToProps = (state) => ({
    booked_calendars: state.booking.booked_calendars,
    lead_travellers: state.booking.lead_travellers,
    all_products: state.booking.all_products
});
const mapDispatch = (dispatch) => ({
    calendarBookedProducts: (data) => dispatch(calendarBookedProducts(data)),
    calendarBookedProducts_: (data) => dispatch(calendarBookedProducts_(data)),
    allProducts: () => dispatch(allProducts()),
    calendarBookedDetails: (data) => dispatch(calendarBookedDetails(data)),
    calendarBookedDetails_: (data) => dispatch(calendarBookedDetails_(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AllFiltersWrap));

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import './index.scss';
import { CheckCircleFilled } from '@ant-design/icons';

function AccountRow(props) {
    const { children, action, title, completed } = props;
    return (
        <Row>
            <Col xs={12}>
                <div className="account-row">
                    <header className="account-row__header">
                        <h6>
                            {title}
                            {completed ? (
                                <CheckCircleFilled
                                    style={{
                                        color: '#34A939',
                                        verticalAlign: 'top',
                                        marginLeft: 5
                                    }}
                                />
                            ) : (
                                ''
                            )}
                        </h6>
                        <div className="account-row__action d-none d-md-block">{action}</div>
                    </header>
                    <div className="account-row__children">{children}</div>
                    <div className="account-row__action mt-3 d-md-none">{action}</div>
                </div>
            </Col>
        </Row>
    );
}

AccountRow.propTypes = {
    action: PropTypes.node,
    title: PropTypes.string,
    completed: PropTypes.bool,
    children: PropTypes.node
};

export default AccountRow;

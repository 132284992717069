import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DepressableBox from './DepressableBox.jsx';

const useStyles = makeStyles((theme) => ({
    selectAll: {
        marginBottom: '17px'
    }
}));

export default function DaysSelector() {
    const [allSelected, setAllSelected] = useState(false);
    const classes = useStyles();

    const ChangeAllSelected = () => {
        setAllSelected(!allSelected);
    };

    return (
        <Grid container>
            <Grid container item className={classes.selectAll}>
                <Grid item style={{ marginRight: '5px' }}>
                    <input
                        type="checkbox"
                        style={{
                            border: '0.5px solid #A2792C',
                            boxSizing: 'border-box',
                            borderRadius: '5px'
                        }}
                        onChange={ChangeAllSelected}
                    />
                </Grid>
                <Grid item>Select All</Grid>
            </Grid>
            <Grid item container spacing={2}>
                {['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'].map((each, index) => (
                    <Grid item key={index}>
                        <DepressableBox text={each} allSelected={allSelected} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
}

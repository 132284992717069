import React from 'react';
import { Button as AntButton, Tooltip } from 'antd';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import FilterIcon from 'components/atoms/icons/filter-icon';

const RightControls = (props) => {
    const {
        currentWindowWidth,
        filtersVisibility,
        handleOnClick,
        setVisibleDownloadSettingsDrawer
    } = props;
    return (
        <div className="position-absolute right-0 top-0">
            <Tooltip title="Download">
                <AntButton
                    className="mr-2"
                    type="text"
                    icon={
                        <svg
                            width="28"
                            height="24"
                            viewBox="0 0 28 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.9998 16.6668C13.7606 16.6668 13.532 16.5688 13.3675 16.3962L7.24248 9.9795C6.71165 9.42416 7.10598 8.50016 7.87481 8.50016H11.0831V1.79183C11.0831 0.987996 11.7376 0.333496 12.5415 0.333496H15.4581C16.262 0.333496 16.9165 0.987996 16.9165 1.79183V8.50016H20.1248C20.8936 8.50016 21.288 9.42416 20.7571 9.9795L14.6321 16.3962C14.4676 16.5688 14.239 16.6668 13.9998 16.6668Z"
                                fill="#A2792C"
                            />
                            <path
                                d="M25.9583 23.6667H2.04167C0.915833 23.6667 0 22.7508 0 21.625V21.0417C0 19.9158 0.915833 19 2.04167 19H25.9583C27.0842 19 28 19.9158 28 21.0417V21.625C28 22.7508 27.0842 23.6667 25.9583 23.6667Z"
                                fill="#A2792C"
                            />
                        </svg>
                    }
                    onClick={() => setVisibleDownloadSettingsDrawer(true)}
                />
            </Tooltip>
            <Button className="btn-filter" size="small" onClick={handleOnClick}>
                {currentWindowWidth >= 768 ? (
                    <span className="name mr-2">{filtersVisibility ? 'Close' : 'Filters'}</span>
                ) : null}
                <span className="filters-active">
                    <FilterIcon />
                </span>
            </Button>
        </div>
    );
};

RightControls.propTypes = {
    filtersVisibility: PropTypes.bool,
    currentWindowWidth: PropTypes.number,
    handleOnClick: PropTypes.func,
    setVisibleDownloadSettingsDrawer: PropTypes.func
};

export default RightControls;

import { combineReducers } from 'redux';
import { accommodationdetailsReducer } from './accommodationdetails';
import applicationReducer from './application';
import progressReducer from './progress';
import authReducer from './authReducer';
import contentDetail from './content';
import profileReducer from './profileReducer';
import calendarReducer from './calendarReducer';
import feedbackReducer from './feedbackReducer';
import toastReducer from './toastReducer';
import accessCalendarReducer from './accessCalendar';
import productReducer from './productReducer';
import categoryReducer from './categoryReducer';
import loadingReducer from './loadingReducer';
import availabilityReducer from './availabilityReducer';
import bookingReducer from './bookingReducer';

const rootReducer = combineReducers({
    progress: progressReducer,
    application: applicationReducer,
    accdetails: accommodationdetailsReducer,
    auth: authReducer,
    contentDetail,
    profile: profileReducer,
    calendar: calendarReducer,
    feedback: feedbackReducer,
    toast: toastReducer,
    accessCalendarReducer: accessCalendarReducer,
    product: productReducer,
    category: categoryReducer,
    loading: loadingReducer,
    availability: availabilityReducer,
    booking: bookingReducer
});

export default rootReducer;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './index.scss';
import Collapse from '../collapse';
import Proptypes from 'prop-types';
import { Button } from 'antd';
import { selectedExistingProduct_, deleteAddOnProduct, resetCalenderReducer } from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfirmationModal } from 'components/molecules';
import { ComponentPermissionContext } from 'context/rolesandpermission';
function ItinerariesPanel(props) {
    const {
        toggleAddItineraryModal,
        deleteAddOnProduct,
        setAddProductsInitModal,
        resetCalenderReducer,
        toggleOnEditPackageProduct,
        package_detail
    } = props;

    const { products } = package_detail;

    const [expandAll, setExpandAll] = useState(true);
    // const setExpand = () => setExpandAll(!expandAll);
    const addAnItinerary = (index) => {
        props.onOpenModal(index);
    };
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);
    const [isConfirmationModalOpen, setConfirmationModal] = useState(false);

    return (
        <div className="panel">
            {/* Add Leading Product Design */}
            <div className="add-leading-product">
                {/* If no product added */}
                <div className="text-center mt-20px">
                    <Button
                        type="primary"
                        onClick={() => {
                            resetCalenderReducer({
                                create_addon_product: true,
                                // update_customer_book: true,
                                update_calendar_product: true,
                                selected_existing_product: true,
                                update_products_personal_info: true
                            });
                            setAddProductsInitModal(true);
                        }}>
                        <span className={`mr-2 ${assignComponentPermission('product-update')}`}>
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0.0458984 6.66667C0.0458984 2.98467 3.03057 0 6.71257 0C10.3946 0 13.3792 2.98467 13.3792 6.66667C13.3792 10.3487 10.3946 13.3333 6.71257 13.3333C3.03057 13.3333 0.0458984 10.3487 0.0458984 6.66667ZM6.71257 1.33333C5.29808 1.33333 3.94152 1.89524 2.94133 2.89543C1.94114 3.89562 1.37923 5.25218 1.37923 6.66667C1.37923 8.08115 1.94114 9.43771 2.94133 10.4379C3.94152 11.4381 5.29808 12 6.71257 12C8.12705 12 9.48361 11.4381 10.4838 10.4379C11.484 9.43771 12.0459 8.08115 12.0459 6.66667C12.0459 5.25218 11.484 3.89562 10.4838 2.89543C9.48361 1.89524 8.12705 1.33333 6.71257 1.33333Z"
                                    fill="white"
                                />
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M7.37956 3.33333C7.37956 3.15652 7.30932 2.98695 7.1843 2.86193C7.05927 2.7369 6.8897 2.66666 6.71289 2.66666C6.53608 2.66666 6.36651 2.7369 6.24149 2.86193C6.11646 2.98695 6.04622 3.15652 6.04622 3.33333V6H3.37956C3.20275 6 3.03318 6.07024 2.90815 6.19526C2.78313 6.32028 2.71289 6.48985 2.71289 6.66666C2.71289 6.84348 2.78313 7.01304 2.90815 7.13807C3.03318 7.26309 3.20275 7.33333 3.37956 7.33333H6.04622V10C6.04622 10.1768 6.11646 10.3464 6.24149 10.4714C6.36651 10.5964 6.53608 10.6667 6.71289 10.6667C6.8897 10.6667 7.05927 10.5964 7.1843 10.4714C7.30932 10.3464 7.37956 10.1768 7.37956 10V7.33333H10.0462C10.223 7.33333 10.3926 7.26309 10.5176 7.13807C10.6427 7.01304 10.7129 6.84348 10.7129 6.66666C10.7129 6.48985 10.6427 6.32028 10.5176 6.19526C10.3926 6.07024 10.223 6 10.0462 6H7.37956V3.33333Z"
                                    fill="white"
                                />
                            </svg>
                        </span>
                        {Array.isArray(products) && products.length > 0
                            ? 'Add more Products'
                            : 'Add Products'}
                    </Button>
                </div>
            </div>
            {/* <div className="panel-head">
                <div className="btn-remove-day cursor-pointer" onClick={() => setExpand()}>
                    Collapse all days
                </div>
            </div> */}

            <Collapse
                onAddAnItinerary={(index) => addAnItinerary(index)}
                expand={expandAll}
                setExpand={() => setExpandAll(true)}
                isEdit={(value) => props.isEdit(value)}
                toggleAddItineraryModal={toggleAddItineraryModal}
                toggleOnEditPackageProduct={toggleOnEditPackageProduct}
            />

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="you want to delete"
                handleConfirmation={() => {
                    deleteAddOnProduct(props.productDetail);
                    setConfirmationModal(false);
                }}
            />
        </div>
    );
}

ItinerariesPanel.propTypes = {
    onOpenModal: Proptypes.func,
    isEdit: Proptypes.func,
    onEditPackageCategory: Proptypes.func,
    productDetail: Proptypes.object,
    toggleAddItineraryModal: Proptypes.func,
    selectedExistingProduct_: Proptypes.func,
    deleteAddOnProduct: Proptypes.func,
    resetCalenderReducer: Proptypes.func,
    setAddProductsInitModal: Proptypes.func,
    toggleOnEditPackageProduct: Proptypes.func,
    package_detail: Proptypes.object
};

const mapState = (state) => ({
    package_detail: state.product.package_detail
});

const mapDispatch = (dispatch) => ({
    selectedExistingProduct_: (data) => dispatch(selectedExistingProduct_(data)),
    deleteAddOnProduct: (data) => dispatch(deleteAddOnProduct(data)),
    resetCalenderReducer: (data) => dispatch(resetCalenderReducer(data))
});

export default withRouter(connect(mapState, mapDispatch)(ItinerariesPanel));

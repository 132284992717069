import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Upload } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Col, Row } from 'reactstrap';
import './index.scss';
import { fileUpload, clearUploadedFile } from 'store/actions/contentActions';
import { updateProfile } from 'store/actions/profileActions';
import { accountDelete } from 'store/actions/AuthActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { API_URL } from 'utils/constants';
import ImgCrop from 'antd-img-crop';
import ImagePreviewModal from 'components/molecules/modals/image-preview';
import DeactivateAccountModal from 'components/molecules/modals/deactivate-account';
// import { SelectCountry } from 'components/atoms';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            base64Url: null,
            uploadedFile: null,
            isLoading: false,
            myProfile: null,
            fileList: [],
            isDeleted: false,
            profileFileList: [],
            isPreviewImageOpen: false,
            previewImage: null,
            uploadProfilePic: false
        };
    }
    formRef = React.createRef();

    componentDidMount() {
        const { my_profile } = this.props;
        let obj = {};
        if (my_profile) {
            obj.myProfile = my_profile;
            if (my_profile?.govt_photo_url) {
                try {
                    let parse = JSON.parse(my_profile?.govt_photo_url);
                    if (Array.isArray(parse))
                        obj.fileList = parse.map((value, ind) => {
                            return {
                                uid: String(ind),
                                name: value.substring(value.lastIndexOf('/') + 1),
                                status: 'done',
                                url: value,
                                thumbUrl: value
                            };
                        });
                } catch (ex) {
                    console.log('ex: ', ex);
                }
            }
            if (my_profile?.photo_url) {
                obj.profileFileList = [
                    {
                        uid: '-1',
                        name: my_profile.photo_url.substring(
                            my_profile.photo_url.lastIndexOf('/') + 1
                        ),
                        status: 'done',
                        url: my_profile.photo_url,
                        thumbUrl: my_profile.photo_url
                    }
                ];
            }
            this.setState({
                ...obj
            });
        }
    }

    componentDidUpdate() {
        const { uploaded_file } = this.props;
        let { uploadedFile } = this.state;

        if (uploadedFile !== uploaded_file) {
            this.formRef.current.setFieldsValue({
                photo_url: uploaded_file ? uploaded_file.url : ''
            });
            this.setState({
                isLoading: false,
                uploadedFile: uploaded_file,
                progressImageUpload: 100
            });
        }
    }
    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function () {};
    }
    deleteAccount = () => {
        let data = { email: this.props.my_profile.email };
        this.props.accountDelete(data);
    };
    onChange = (e) => {
        let self = this;
        let file = e.target.files[0];
        let form_data = new FormData();
        form_data.append('media', file);
        this.getBase64(file, (result) => {
            self.setState(
                {
                    uploadedFile: null,
                    isLoading: true,
                    isDeleted: false,
                    base64Url: { url: result, name: file.name }
                },
                () => {
                    this.props.fileUpload(form_data);
                    // this.handleUpdateUser();
                }
            );
        });
    };
    deletePhoto = () => {
        this.formRef.current.setFieldsValue({ photo_url: '' });
        this.setState({ base64Url: null, uploadedFile: null, isDeleted: true }, () => {
            this.props.clearUploadedFile();
        });
    };
    onFinish = (values) => {
        this.props.updateProfile(values);
    };
    onChangeImage = (info) => {
        if (info.file.status === 'done') {
            this.formRef.current.setFieldsValue({ photo_url: info.file.response.data.url });
        } else if (info.file.status === 'removed') {
            this.formRef.current.setFieldsValue({ photo_url: '' });
        }
        let profileFileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new
        profileFileList = profileFileList.slice(-2);

        // 2. Read from response and show file link
        profileFileList = profileFileList.map((file) => {
            if (file.response) {
                file.url = file.response.data.url;
                if (this.state.uploadProfilePic) {
                    this.props.updateProfile({
                        photo_url: file.url
                    });
                }
            }
            return file;
        });
        this.setState({ profileFileList, uploadProfilePic: false });
        //this.setState({profileFileList: info.fileList })
    };
    handleChange = (info) => {
        if (info.file.status === 'done') {
            this.formRef.current.setFieldsValue({
                govt_photo_url: Array.isArray(info.fileList)
                    ? info.fileList
                          .map((value) => {
                              if (value.url) return value.url;
                              else if (value?.response?.data?.url) return value.response.data.url;
                          })
                          .filter((value) => (value ? true : false))
                    : []
            });
        } else if (info.file.status === 'removed') {
            if (Array.isArray(info.fileList) && info.fileList.length === 0)
                this.formRef.current.setFieldsValue({
                    govt_photo_url: ''
                });
            else if (Array.isArray(info.fileList) && info.fileList.length > 0)
                this.formRef.current.setFieldsValue({
                    govt_photo_url: Array.isArray(info.fileList)
                        ? info.fileList
                              .map((value) => {
                                  if (value.url) return value.url;
                                  else if (value?.response?.data?.url)
                                      return value.response.data.url;
                              })
                              .filter((value) => (value ? true : false))
                        : []
                });
        }
        let fileList = [...info.fileList];

        // 1. Limit the number of uploaded files
        // Only to show two recent uploaded files, and old ones will be replaced by the new

        fileList = fileList.slice(-5);

        // 2. Read from response and show file link
        fileList = fileList.map((file) => {
            if (file.response) {
                file.url = file.response.data.url;
            }
            return file;
        });
        this.setState({ fileList });
    };
    setPreviewImage = (file) => {
        this.setState({
            isPreviewImageOpen: true,
            previewImage: file
        });
    };

    render() {
        const { base64Url, isLoading, fileList, profileFileList, isDeleted } = this.state;
        const { my_profile } = this.props;
        const authToken = localStorage.getItem('token');
        const uploadPhoto = {
            name: 'media',
            action: `${API_URL}/media-library`,
            headers: {
                authorization: `Bearer ${authToken}`
            },
            onChange: () => {
                this.handleImageChange();
            },
            onPreview: (file) => {
                this.setPreviewImage(file);
            }
        };
        const uploadGovtUrl = {
            name: 'media',
            action: `${API_URL}/media-library`,
            headers: {
                authorization: `Bearer ${authToken}`
            },
            onChange: (info) => {
                this.handleChange(info);
            }
        };

        return (
            // Profile Information Tab Content for User account verification
            <div className="vendor-accounts-profile mb-20px">
                <ImagePreviewModal
                    isModalOpen={this.state.isPreviewImageOpen}
                    setModalOpenState={() =>
                        this.setState({ isPreviewImageOpen: !this.state.isPreviewImageOpen })
                    }
                    image={this.state.previewImage}
                />
                <Form
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    layout="vertical"
                    className="form-label-fw-500">
                    <Row className="row-lg">
                        <Col xs={12} className="flex-lg-170">
                            <div className="vendor-accounts-profile__left-panel text-center">
                                {/* Hidden generic image upload, see ant upload below */}
                                <div className="upload-img-wrapper" hidden>
                                    {/* If image uploaded */}
                                    {base64Url?.url || isDeleted ? (
                                        <img alt={base64Url?.name} src={base64Url?.url}></img>
                                    ) : my_profile && my_profile.photo_url ? (
                                        <img alt="" src={my_profile.photo_url}></img>
                                    ) : (
                                        ' '
                                    )}
                                    {/* Show while Uploading */}
                                    {isLoading ? (
                                        <div className="loading">
                                            <LoadingOutlined
                                                style={{ fontSize: 30, color: 'var(--primary)' }}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* Profile Picture upload */}
                                <Form.Item
                                    rules={[
                                        { required: true, message: 'Please upload your photo' }
                                    ]}
                                    name="photo_url"
                                    initialValue={my_profile ? my_profile.photo_url : ''}>
                                    <div>
                                        {/* Show when no image uploaded or when deleted uploaded image */}
                                        {!this.state.profileFileList.length ? (
                                            <div className="upload-img-wrapper"></div>
                                        ) : null}
                                        <ImgCrop rotate>
                                            <Upload
                                                {...uploadPhoto}
                                                listType="picture-card"
                                                fileList={profileFileList}
                                                onChange={(info) => {
                                                    this.setState(
                                                        {
                                                            uploadProfilePic: true
                                                        },
                                                        () => {
                                                            this.onChangeImage(info);
                                                        }
                                                    );
                                                }}
                                                maxCount={1}
                                                className="type-profile-alt">
                                                {/* Hide while uploading */}
                                                <div>
                                                    <label className="btn btn-link p-0 mb-0">
                                                        {' '}
                                                        {this.state.profileFileList.length
                                                            ? 'Update'
                                                            : 'Upload'}{' '}
                                                        Photo
                                                    </label>
                                                </div>
                                            </Upload>
                                        </ImgCrop>
                                    </div>
                                </Form.Item>
                            </div>
                        </Col>
                        <Col xs={12} className="flex-lg-170-rest">
                            {/* <h4>Personal Information</h4> */}

                            <Row className="row-lg">
                                <Col md={6}>
                                    <Form.Item
                                        label="First name"
                                        rules={[
                                            { required: true, message: 'Please enter first name' }
                                        ]}
                                        initialValue={my_profile ? my_profile.first_name : ''}
                                        name="first_name">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <Form.Item
                                        label="Last name"
                                        initialValue={my_profile ? my_profile.last_name : ''}
                                        rules={[
                                            { required: true, message: 'Please enter last name' }
                                        ]}
                                        name="last_name">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-lg">
                                <Col md={6}>
                                    <Form.Item
                                        label="Email Address"
                                        initialValue={my_profile ? my_profile.email : ''}
                                        rules={[{ required: true, message: 'Please enter email' }]}
                                        name="email">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <Form.Item
                                        label="Job Role"
                                        initialValue={my_profile ? my_profile.job_title : ''}
                                        rules={[
                                            { required: true, message: 'Please enter job role' }
                                        ]}
                                        name="job_title">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {/* <Row className="row-lg">
                                <Col md={6}>
                                    <Form.Item
                                        label="Address"
                                        initialValue={my_profile ? my_profile.address : ''}
                                        rules={[
                                            { required: true, message: 'Please enter your address' }
                                        ]}
                                        name="address">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col md={6}>
                                    <Form.Item
                                        label="Zip/Post code"
                                        initialValue={my_profile ? my_profile.zip_code : ''}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter zip/post code'
                                            }
                                        ]}
                                        name="zip_code">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="row-lg">
                                <Col md={6}>
                                    <SelectCountry
                                        label="Select country"
                                        placeHolder=""
                                        initialValue={my_profile ? my_profile.country : undefined}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Item
                                        label="State"
                                        initialValue={my_profile ? my_profile.state : ''}
                                        rules={[{ required: true, message: 'Please enter state' }]}
                                        name="state">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row className="row-lg">
                                <Col md={6}>
                                    <Form.Item
                                        label="City"
                                        initialValue={my_profile ? my_profile.city : ''}
                                        rules={[{ required: true, message: 'Please enter city' }]}
                                        name="city">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Row className="row-lg">
                                <Col xs={12}>
                                    <div className="ant-form-item-label">
                                        <label
                                            htmlFor="local_name"
                                            className="ant-form-item-required">
                                            What name would you like us to use in introducing you to
                                            customers?
                                        </label>
                                        <p className="fs-12 mb-0">
                                            Customers find your local name that represents your
                                            region more appealing.
                                        </p>
                                    </div>
                                </Col>
                                <Col xs={12}>
                                    <Form.Item
                                        rules={[
                                            { required: true, message: 'Please enter local name' }
                                        ]}
                                        initialValue={my_profile ? my_profile.nick_name : ''}
                                        name="nick_name">
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/* <Form.Item>
                            <Button type="primary">Submit</Button>
                        </Form.Item> */}
                            <Row className="mt-4">
                                <Col xs={12}>
                                    <div className="ant-form-item-label">
                                        <label
                                            htmlFor="upload_id"
                                            className="ant-form-item-required">
                                            Verify your identity
                                        </label>
                                        <p className="fs-12 mb-0">
                                            For the safety of our customers, we ask new partners to
                                            verify their identity. All you need to do is upload a
                                            piece of government-issued photo ID and selfie, then
                                            we’ll take care of the rest.
                                        </p>
                                    </div>
                                </Col>
                                {((Array.isArray(fileList) && fileList.length < 6) ||
                                    !fileList) && (
                                    <Col xs={12}>
                                        {/* Upload Id */}
                                        <Form.Item
                                            initialValue={
                                                my_profile ? my_profile.govt_photo_url : []
                                            }
                                            rules={[
                                                { required: true, message: 'Please upload your ID' }
                                            ]}
                                            name="govt_photo_url">
                                            <Upload
                                                {...uploadGovtUrl}
                                                listType="picture"
                                                maxCount={5}
                                                fileList={fileList}>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-link p-0">
                                                    {' '}
                                                    {Array.isArray(fileList) && fileList.length > 0
                                                        ? 'Upload More I.D.'
                                                        : 'Upload I.D.'}
                                                </button>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                )}
                            </Row>
                            <Row className="mt-5 align-items-center">
                                <Col xs="auto" className="flex-grow-1">
                                    <div className="mx-n1 mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-link p-0 mx-1"
                                            onClick={() =>
                                                this.setState({
                                                    toggleDeActivateAccountModal: true
                                                })
                                            }>
                                            {' '}
                                            Deactivate Account
                                        </button>
                                    </div>
                                </Col>
                                <Col xs="auto" className="flex-grow-1">
                                    <div className="d-flex align-items-center mx-n1">
                                        <div className="px-1 ml-auto">
                                            <div className="d-flex mx-n1">
                                                <Button
                                                    type="primary"
                                                    className="btn-mw mx-1"
                                                    htmlType="submit">
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
                {/* De-activate Account Modal */}
                <DeactivateAccountModal
                    isOpenModal={this.state.toggleDeActivateAccountModal}
                    onToggle={() =>
                        this.setState({
                            toggleDeActivateAccountModal: !this.state.toggleDeActivateAccountModal
                        })
                    }
                    onClickYes={() => this.deleteAccount}
                    onClickNo={() => this.setState({ toggleDeActivateAccountModal: false })}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    my_profile: state.auth.my_profile,
    account_delete: state.account_delete,
    uploaded_file: state.contentDetail.uploaded_file
});

const mapDispatch = (dispatch) => ({
    fileUpload: (data) => dispatch(fileUpload(data)),
    clearUploadedFile: () => dispatch(clearUploadedFile()),
    updateProfile: (data) => dispatch(updateProfile(data)),
    accountDelete: (data) => dispatch(accountDelete(data))
});
Profile.propTypes = {
    my_profile: PropTypes.object,
    uploaded_file: PropTypes.any,
    accountDelete: PropTypes.func,
    fileUpload: PropTypes.func,
    clearUploadedFile: PropTypes.func,
    updateProfile: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Profile));

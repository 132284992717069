import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import WarningIcon from '../../../../assets/images/warning-icon.svg';
import PropTypes from 'prop-types';
import InfoIcon from '../../../../assets/images/info-white.svg';
import { Form, Button, TimePicker, DatePicker, Switch } from 'antd';
import moment from 'moment';
import { pauseProduct } from 'store/actions/availabilityActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const format = 'HH:mm';

const PauseModal = ({ isOpen, toggleModal, refreshList, openedRecord, pauseProduct }) => {
    const [confirmationWindow, setConfirmationWindow] = useState(false);
    const [choosePausedDateAndTime, setChoosePausedDateAndTime] = useState(false);
    const [pauseForm] = Form.useForm();

    const togglePauseModal = () => {
        toggleModal();
        setConfirmationWindow(false);
        setChoosePausedDateAndTime(false);
        pauseForm.resetFields();
    };

    const toggleLastWindow = () => {
        setConfirmationWindow(false);
        setChoosePausedDateAndTime(false);
    };

    function onChange(checked) {
        setChoosePausedDateAndTime(checked);
    }

    const onFinish = () => {
        let pause = '';
        let restart = '';
        if (pauseForm.getFieldsValue().pauseDate) {
            pause = pauseForm.getFieldsValue().pauseDate.format('YYYY-MM-DD');
        } else {
            pause = moment().format('YYYY-MM-DD');
        }
        if (pauseForm.getFieldsValue().restartDate) {
            restart = pauseForm.getFieldsValue().restartDate.format('YYYY-MM-DD');
        }
        let params = [];
        if (pause) {
            params.push(`pauseDate=${pause}`);
        }
        if (restart) {
            params.push(`restartDate=${restart}`);
        }
        let data = {
            id: openedRecord?.id,
            params: params.join('&')
        };
        pauseProduct(data)
            .then(() => {
                refreshList();
                togglePauseModal();
            })
            .catch((e) => console.error(e));
    };

    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={togglePauseModal}>
            <ModalBody className="text-center">
                <Form form={pauseForm} requiredMark={false} onFinish={onFinish}>
                    <div className="d-flex flex-row note mb-4">
                        <div className="icon-container">
                            <img src={InfoIcon} alt="" className="p-3" />
                        </div>
                        <div style={{ fontSize: 11 }} className="p-2">
                            <p className="m-0" style={{ textAlign: 'initial' }}>
                                Pausing it will stop selling the product for specific period of
                                time. however you can choose date and time for resuming sales for
                                this product.
                            </p>
                        </div>
                    </div>
                    <img src={WarningIcon} alt="" />
                    <div className="modal-body-title-lg mt-2">
                        {confirmationWindow ? 'Resume Sale on' : 'Pause?'}
                    </div>
                    <p className="modal-body-text">
                        {confirmationWindow
                            ? 'This product will now go out of sale. Choose Restart Date before Pausing.'
                            : 'Are you sure you want to pause sales for this product?'}
                    </p>
                    {confirmationWindow && (
                        <>
                            <div className="text-center" style={{ width: '100%' }}>
                                <div className="d-inline-block mr-1">
                                    <Form.Item
                                        name="restartDate"
                                        rules={[{ required: true, message: 'Please select date' }]}>
                                        <DatePicker
                                            placeholder="Date"
                                            disabledDate={disabledDate}
                                        />
                                    </Form.Item>
                                </div>
                                <div className="d-inline-block ml-1">
                                    <Form.Item
                                        name="restartTime"
                                        rules={[{ required: true, message: 'Please select time' }]}>
                                        <TimePicker placeholder="Time" format={format} />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="mt-3 d-flex flex-column align-items-center justify-content-center">
                                <p className="m-0 mb-2">
                                    Your product will be paused immediately. <br />
                                    Would you like to change the pause date?
                                </p>
                                <div>
                                    <span className="mr-2">No</span>
                                    <Switch onChange={onChange} />
                                    <span className="ml-2">Yes</span>
                                </div>
                            </div>
                            {choosePausedDateAndTime && (
                                <div className="text-center mt-2" style={{ width: '100%' }}>
                                    <div className="d-inline-block mr-1">
                                        <Form.Item
                                            name="pauseDate"
                                            rules={[
                                                { required: true, message: 'Please select date' }
                                            ]}>
                                            <DatePicker
                                                placeholder="Date"
                                                disabledDate={disabledDate}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="d-inline-block ml-1">
                                        <Form.Item
                                            name="pauseTime"
                                            rules={[
                                                { required: true, message: 'Please select time' }
                                            ]}>
                                            <TimePicker placeholder="Time" format={format} />
                                        </Form.Item>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    <div className="mt-4">
                        {!confirmationWindow ? (
                            <>
                                <Button
                                    id="pauseSaleTooltip1"
                                    type="primary"
                                    className="btn-mw mx-2"
                                    onClick={() => setConfirmationWindow(true)}>
                                    Yes
                                </Button>
                                <Button
                                    type="dark"
                                    className="btn-mw mx-2"
                                    onClick={() => togglePauseModal()}>
                                    NO
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    id="pauseSaleTooltip2"
                                    type="primary"
                                    className="btn-mw mx-2"
                                    htmlType="submit"
                                    //onClick={() => togglePauseModal()}
                                >
                                    PAUSE
                                </Button>
                                <Button
                                    type="dark"
                                    className="btn-mw mx-2"
                                    onClick={() => toggleLastWindow()}>
                                    NO
                                </Button>
                            </>
                        )}
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    );
};

PauseModal.propTypes = {
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    refreshList: PropTypes.func,
    openedRecord: PropTypes.any,
    pauseProduct: PropTypes.func
};

const mapDispatch = (dispatch) => ({
    pauseProduct: (params) => dispatch(pauseProduct(params))
});

export default withRouter(connect(null, mapDispatch)(PauseModal));

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Button, Radio, Input } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { ReactModal } from 'components/atoms';
import './index.css';
import {
    Elements,
    useStripe,
    useElements,
    CardElement,
    PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_KEY } from 'utils';
import { callApi } from 'utils/apiCaller';
// import PropTypes from 'prop-types';
import { Notification } from 'components/atoms/notification';
const PromoButton = ({ handleButton }) => {
    return (
        <div className="border rounded mt-2" style={{ padding: '0.68rem' }}>
            <div className="d-flex align-items justify-content-end px-2">
                <button
                    className="border-0 promo-close fs-14 text-primary fw-500 mb-2"
                    onClick={handleButton}>
                    close
                </button>
            </div>
            <div className="d-flex">
                <Input size="large" placeholder="Enter Promocode" />
                <Button type="primary" className="btn-mw mx-1">
                    Apply
                </Button>
            </div>
        </div>
    );
};

export const LoadPaymentOptions = ({
    planForm,
    handleDrawerClose,
    setPaymentProcessing,
    paymentProcessing,
    onPaymentSuccess
}) => {
    useEffect(() => {
        callApi('/list-card?perPage=3', 'GET')
            .then((response) => {
                if (response.data.cards) {
                    setUserCards(response.data.cards.data);
                } else {
                    Notification('error', response.data.message);
                }
            })
            .catch((error) => {
                console.log('error:', error);

                Notification('error', 'Unable to get user cards');
            });

        //
    }, []);
    const [userCards, setUserCards] = useState([]);
    const stripePromise = loadStripe(STRIPE_KEY);
    const [selectedPlanPayment, setSelectedPlanPayment] = useState('card');
    const [paymentMethod, setPaymentMethod] = useState({
        preferedPaymentMethod: 'saved',
        addAnotherCard: false
    });
    const [newPaymentAddPromoCode, setNewPaymentAddPromoCode] = useState(false);
    //const [anotherPaymentAddPromoCode, setAnotherPaymentAddPromoCode] = useState(false);

    return (
        <div className="card-footer">
            <Elements stripe={stripePromise}>
                <Radio.Group
                    className="d-block"
                    onChange={(e) => setSelectedPlanPayment(e.target.value)}
                    value={selectedPlanPayment}>
                    <Radio className="d-flex align-items-start mb-2" value={'card'}>
                        <div className="fs-16 fw-500">{'Use card details'}</div>
                    </Radio>
                    {selectedPlanPayment === 'card' && (
                        <div className="mb-2" style={{ paddingLeft: 24 }}>
                            {/* Check to confirm is a condition for adding a card for the first time is created else create the condition for that */}
                            {userCards.length !== 0 && (
                                <UserCards
                                    userCards={userCards}
                                    payload={planForm}
                                    preferedPaymentMethod={paymentMethod.preferedPaymentMethod}
                                    handleDrawerClose={handleDrawerClose}
                                    setPaymentProcessing={setPaymentProcessing}
                                    paymentProcessing={paymentProcessing}
                                    onPaymentSuccess={onPaymentSuccess}
                                />
                            )}
                            <div className="d-flex align-items-center justify-content-between">
                                {!paymentMethod.addAnotherCard && (
                                    <button
                                        type="button"
                                        className="btn btn-link p-0 mt-2 fs-14"
                                        onClick={() =>
                                            setPaymentMethod({
                                                preferedPaymentMethod: 'new',
                                                addAnotherCard: true
                                            })
                                        }>
                                        Add Another Card
                                    </button>
                                )}

                                {!newPaymentAddPromoCode && (
                                    <button
                                        type="button"
                                        className="btn btn-link p-0 mt-2 fs-14 ml-auto"
                                        onClick={() =>
                                            setNewPaymentAddPromoCode(!newPaymentAddPromoCode)
                                        }>
                                        Got A Promocode?
                                    </button>
                                )}
                            </div>

                            {paymentMethod.addAnotherCard && (
                                <div className="border rounded mt-2" style={{ padding: '0.68rem' }}>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 flex-grow-1">
                                            <div className="fs-14 text-primary fw-500 mb-2">
                                                Add another card
                                            </div>
                                        </div>
                                        {/* Show if selected */}
                                        {paymentMethod.preferedPaymentMethod === 'new' ? (
                                            <div className="px-2">
                                                <CheckCircleFilled
                                                    style={{ fontSize: 20, color: '#34A939' }}
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>

                                    <StripeComponent
                                        payload={planForm}
                                        preferedPaymentMethod={paymentMethod.preferedPaymentMethod}
                                        handleDrawerClose={handleDrawerClose}
                                        setPaymentProcessing={setPaymentProcessing}
                                        paymentProcessing={paymentProcessing}
                                        onPaymentSuccess={onPaymentSuccess}
                                    />

                                    <div className="d-flex mx-n2">
                                        <div className="">
                                            <p className="fs-13 m-0 use-card-details">
                                                You will be charged ${planForm.monthlyPlanCharges}{' '}
                                                every month
                                            </p>
                                        </div>
                                    </div>

                                    {/* <PaymentMethodButtons payload={planForm} /> */}
                                </div>
                            )}

                            {newPaymentAddPromoCode && (
                                <PromoButton
                                    handleButton={() =>
                                        setNewPaymentAddPromoCode(!newPaymentAddPromoCode)
                                    }
                                />
                            )}
                        </div>
                    )}

                    {/* <Radio className="d-flex align-items-start mb-2" value={'another_payment'}>
                        <div className="fs-16 fw-500">{'Add another payment option'}</div>
                    </Radio>
                    {selectedPlanPayment === 'another_payment' && (
                        <div className="mb-2" style={{ paddingLeft: 24 }}>
                            <div className="d-flex align-items-center mx-n2">
                                <div className="mx-2 " role="button">
                                    <img
                                        src={require('../../../assets/images/paypal.png')}
                                        alt=""
                                    />
                                </div>
                                <div className="mx-2 " role="button">
                                    <img src={require('../../../assets/images/paytm.png')} alt="" />
                                </div>
                            </div>

                            <button
                                type="button"
                                className="btn btn-link p-0 mt-2 fs-14 ml-auto"
                                onClick={() =>
                                    setAnotherPaymentAddPromoCode(!anotherPaymentAddPromoCode)
                                }>
                                Got A Promocode?
                            </button>

                            {anotherPaymentAddPromoCode && (
                                <PromoButton
                                    handleButton={() =>
                                        setAnotherPaymentAddPromoCode(!anotherPaymentAddPromoCode)
                                    }
                                />
                            )}
                        </div>
                    )} */}
                </Radio.Group>
            </Elements>
        </div>
    );
};
export const StripeComponent = ({
    payload,
    handleDrawerClose,
    setPaymentProcessing,
    paymentProcessing,
    onPaymentSuccess
}) => {
    const stripe = useStripe();
    const elements = useElements();
    const [visibleModal, setVisibleModal] = useState(false);
    // const acceptedLocation = JSON.parse(localStorage.acceptedLocation);
    const handleSubmit = async (payload) => {
        setPaymentProcessing(true);
        try {
            //event.preventDefault();
            if (!stripe || !elements) {
                // Stripe.js has not loaded yet. Make sure to disable
                // form submission until Stripe.js has loaded.
                return;
            }
            const cardElement = elements.getElement(CardElement);
            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            });
            if (error) {
                Notification('error', 'Invalid Card details, Kindly enter the correct details');
                setPaymentProcessing(false);
                return;
            }

            const checkoutPayload = {
                ...payload,
                plan_id: payload.planId,
                payment_method_id: paymentMethod.id,
                amount: payload.monthlyPlanCharges * 100,
                payment_method_type: 'card'
            };
            callApi('/subscription-init', 'POST', checkoutPayload)
                .then(async () => {
                    Notification('success', 'Payment Successful', 'success');

                    setPaymentProcessing(false);
                    cardElement.clear();

                    onPaymentSuccess();
                    handleDrawerClose();
                })
                .catch((error) => {
                    console.log('error:', error);
                });
        } catch (error) {
            console.log('error:', error);
            setPaymentProcessing(false);
            Notification('error', 'Payment Failed', 'success');
        }
    };

    const cardInfoOptions = {
        style: {
            base: {
                'fontSize': '16px',
                'color': '#33333',
                'padding': '10rem',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#9e2146'
            }
        }
    };

    return (
        <>
            <ReactModal
                title={'Are you sure want to complete this payment?'}
                description={'This action cannot be undone'}
                onOk={() => {
                    handleSubmit(payload);
                    setVisibleModal(false);
                }}
                onClose={() => setVisibleModal(false)}
                isOpen={visibleModal}
            />
            <form
                action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    setVisibleModal(true);
                }}>
                <CardElement options={cardInfoOptions} />

                <div className="mt-3 mb-2">
                    <div className=" border-opacity-40 border-4 border-black">
                        <button
                            // type="dark"
                            disabled={paymentProcessing}
                            className="btn-px black-button fw-500 fs-12 mr-2 "
                            type="submit">
                            {paymentProcessing ? (
                                <Loader type="TailSpin" color="#000000" height={30} width={30} />
                            ) : (
                                'Pay Now'
                            )}
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export const UserCards = ({
    payload,
    // prefferedPaymentMethod,
    handleDrawerClose,
    userCards = [],
    setPaymentProcessing,
    paymentProcessing,
    onPaymentSuccess
}) => {
    const [card, setCard] = useState({});
    const [visibleModal, setVisibleModal] = useState(false);

    const onUseCard = async (cardToUsed) => {
        try {
            setPaymentProcessing(true);
            const checkoutPayload = {
                ...payload,
                plan_id: payload.planId,
                payment_method_id: cardToUsed.id,
                amount: payload.monthlyPlanCharges * 100,
                payment_method_type: 'card'
            };
            callApi('/subscription-init', 'POST', checkoutPayload)
                .then(async () => {
                    Notification('success', 'Payment Successful', 'success');
                    setPaymentProcessing(false);
                    onPaymentSuccess();
                    handleDrawerClose();
                })

                .catch((error) => {
                    setPaymentProcessing(false);

                    console.log('error:', error);
                    Notification('error', `Payment failed`);
                });
            // setPaymentProcessing(false);
        } catch (error) {
            console.log('error:', error);
            Notification('error', `Payment failed`);

            setPaymentProcessing(false);
        }
    };

    return (
        <div className="">
            <ReactModal
                title={'Are you sure want to complete this payment?'}
                description={'This action cannot be undone'}
                onOk={() => {
                    onUseCard(card);
                    setVisibleModal(false);
                }}
                onClose={() => setVisibleModal(false)}
                isOpen={visibleModal}
            />
            {userCards.map((userCard) => {
                return (
                    <div
                        className="btn__card border hover-border-primary rounded p-3 w-100 mb-2"
                        key={userCard.id}>
                        <div className="d-flex mx-n2">
                            <div className="px-2 flex-grow-1">
                                <div className="fs-14 text-primary fw-500">Use Saved Card</div>
                                <div className="fs-14">{`Card No: XXX XXX ${userCard.card.last4}`}</div>
                            </div>
                            <div className="px-2 flex-shrink-1">
                                <div className="fs-13">
                                    {' '}
                                    You will be charged ${payload.monthlyPlanCharges} every month{' '}
                                </div>
                            </div>
                            {/* Show if selected */}

                            <Button
                                disabled={paymentProcessing}
                                className="btn-px black-button fw-500 fs-12 mr-2 "
                                onClick={() => {
                                    setVisibleModal(true);
                                    setCard(userCard);
                                }}>
                                {paymentProcessing && card.id === userCard.id ? (
                                    <Loader
                                        type="TailSpin"
                                        color="#000000"
                                        height={30}
                                        width={30}
                                    />
                                ) : (
                                    'Use Card'
                                )}
                            </Button>
                            {/* {prefferedPaymentMethod === 'saved' ? (
                        <div className="px-2">
                            <CheckCircleFilled style={{ fontSize: 20, color: '#34A939' }} />
                        </div>
                    ) : (
                        ''
                    )} */}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export const PaymentMethodButtons = ({ payload }) => {
    const stripe = useStripe();
    const [clientSecret, setClientSecret] = useState('');
    const deviceInfo = JSON.parse(localStorage.deviceInfo);
    const acceptedLocation = JSON.parse(localStorage.acceptedLocation);
    const checkoutPayload = {
        ...payload,
        plan_id: payload.planId,

        payment_method: '',
        amount: payload.monthlyPlanCharges * 100,
        payment_method_type: 'card'
    };
    const prInit = stripe?.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
            label: payload.plan,
            amount: payload.monthlyPlanCharges * 100
        },
        requestPayerName: true,
        requestPayerEmail: true
    });
    const [paymentRequest, setPaymentRequest] = useState(prInit);
    useEffect(() => {
        if (stripe) {
            if (!acceptedLocation.includes(deviceInfo.loc)) {
                return;
            }
            if (stripe) {
                const pr = stripe.paymentRequest({
                    country: deviceInfo.loc,
                    currency: 'usd',
                    total: {
                        label: payload.plan,
                        amount: payload.monthlyPlanCharges * 100
                    },
                    requestPayerName: true,
                    requestPayerEmail: true
                });

                // Check the availability of the Payment Request API.
                pr.canMakePayment().then((result) => {
                    if (result) {
                        pr.on('paymentmethod', (ev) => {
                            callApi('/subscription-init', 'POST', checkoutPayload)
                                .then(({ data }) => {
                                    setClientSecret(data.charge.client_secret);
                                })
                                .catch((error) => {
                                    console.log('error:', error);
                                });
                            stripe
                                .confirmCardPayment(
                                    clientSecret,
                                    { payment_method: ev.paymentMethod.id },
                                    { handleActions: false }
                                )
                                .then(function (confirmResult) {
                                    if (confirmResult.error) {
                                        // Report to the browser that the payment failed, prompting it to
                                        // re-show the payment interface, or show an error message and close
                                        // the payment interface.
                                        ev.complete('fail');
                                    } else {
                                        ev.complete('success');
                                        if (
                                            confirmResult.paymentIntent.status === 'requires_action'
                                        ) {
                                            // Let Stripe.js handle the rest of the payment flow.

                                            stripe
                                                .confirmCardPayment(clientSecret)
                                                .then(function (result) {
                                                    if (result.error) {
                                                        // The payment failed -- ask your customer for a new payment method.
                                                    } else {
                                                        // The payment has succeeded.
                                                    }
                                                });
                                        } else {
                                            // The payment has succeeded.
                                        }
                                    }
                                });
                        });

                        setPaymentRequest(pr);
                    }
                });
            }
        }
    }, [stripe]);
    return (
        <div>{paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}</div>
    );
};
PromoButton.propTypes = {
    handleButton: PropTypes.func
};

LoadPaymentOptions.propTypes = {
    handleDrawerClose: PropTypes.func,
    planForm: PropTypes.shape({
        monthlyPlanCharges: PropTypes.number
    }),
    setPaymentProcessing: PropTypes.func,
    paymentProcessing: PropTypes.bool,
    onPaymentSuccess: PropTypes.func
};

PaymentMethodButtons.propTypes = {
    payload: PropTypes.shape({
        monthlyPlanCharges: PropTypes.number,
        planId: PropTypes.string,
        plan: PropTypes.string
    }),
    prefferedPaymentMethod: PropTypes.string
};

UserCards.propTypes = {
    handleDrawerClose: PropTypes.func,
    userCards: PropTypes.array,
    payload: PropTypes.shape({
        monthlyPlanCharges: PropTypes.number,
        planId: PropTypes.string,
        plan: PropTypes.string
    }),
    prefferedPaymentMethod: PropTypes.string,
    setPaymentProcessing: PropTypes.func,
    paymentProcessing: PropTypes.bool,
    onPaymentSuccess: PropTypes.func
};

StripeComponent.propTypes = {
    handleDrawerClose: PropTypes.func,

    payload: PropTypes.shape({
        monthlyPlanCharges: PropTypes.number,
        planId: PropTypes.string,
        plan: PropTypes.string
    }),
    prefferedPaymentMethod: PropTypes.string,
    setPaymentProcessing: PropTypes.func,
    paymentProcessing: PropTypes.bool,
    onPaymentSuccess: PropTypes.func
};

export default LoadPaymentOptions;

import React from 'react';
import './index.scss';
import { Button, Modal } from 'reactstrap';
import WarningIcon from '../../../assets/images/warning-icon.svg';
import Proptypes from 'prop-types';

export default class AcceptDelete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false
        };
    }

    onClose = (value) => {
        const { onClose } = this.props;
        onClose(value);
    };

    render() {
        return (
            //component to confirm deletion
            <Modal isOpen={this.props.isOpen} centered size="lg">
                <div className="accept-delete">
                    <img src={WarningIcon} alt="" />
                    <h3 className="title">Are you sure?</h3>
                    <div className="description">{this.props.description}</div>

                    <div className="footer">
                        <Button
                            color="primary"
                            onClick={() => {
                                this.onClose(true);
                            }}>
                            YES
                        </Button>
                        <Button
                            color="dark"
                            onClick={() => {
                                this.onClose(false);
                            }}>
                            NO
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}

AcceptDelete.propTypes = {
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    description: Proptypes.string
};

import React from 'react';
import { Card, CardBody, CardFooter, CardTitle, Col, Container, Row } from 'reactstrap';

const Plans = () => {
    return (
        <>
            <div className="e2e-fix">
                <div className="page-jumbo-heading">
                    <h1>Plans</h1>
                </div>

                <Container className="py-5">
                    <Row>
                        <Col lg={4}>
                            <Card className="border-0 bg-light h-100">
                                <CardBody className="flex-grow-1">
                                    <CardTitle className="fs-22 mb-3" tag="h5">
                                        Free
                                    </CardTitle>
                                    <ul className="list-generic list-primary list-round mb-0">
                                        <li>$0 monthly fee</li>
                                        <li>1 Live Calendar</li>
                                        <li>10 customer invites monthly</li>
                                    </ul>
                                </CardBody>
                                <CardFooter className="border-top-0">
                                    <div className="fs-20 fw-600 mb-4">Token Top-up</div>
                                    <div className="fs-16 fw-300 mb-2">$5 per Customer Invite</div>
                                    <p className="fs-12 text-muted">
                                        (Customers can be allowed to pay instead, if you run out of
                                        invites.){' '}
                                    </p>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 bg-light h-100">
                                <CardBody className="flex-grow-1">
                                    <CardTitle className="fs-22 mb-3" tag="h5">
                                        Standard
                                    </CardTitle>
                                    <ul className="list-generic list-primary list-round mb-0">
                                        <li>$199 monthly fee</li>
                                        <li>5 Live Calendars</li>
                                        <li>100 customer invites monthly</li>
                                        <li>Customer insights</li>
                                    </ul>
                                </CardBody>
                                <CardFooter className="border-top-0">
                                    <div className="fs-20 fw-600 mb-4">Token Top-up</div>
                                    <div className="fs-16 fw-300 mb-2">$2 per Customer Invite</div>
                                    <p className="fs-12 text-muted">
                                        (Customers can be allowed to pay instead, if you run out of
                                        invites.){' '}
                                    </p>
                                </CardFooter>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card className="border-0 bg-light h-100">
                                <CardBody className="flex-grow-1">
                                    <CardTitle className="fs-22 mb-3" tag="h5">
                                        Enterprise
                                    </CardTitle>
                                    <ul className="list-generic list-primary list-round mb-0">
                                        <li>Contact Sales</li>
                                        <li>Unlimited Live Calendars</li>
                                        <li>1,000 customer invites monthly</li>
                                        <li>Customer insights</li>
                                    </ul>
                                </CardBody>
                                <CardFooter className="border-top-0">
                                    <div className="fs-20 fw-600 mb-4">Token Top-up</div>
                                    <div className="fs-16 fw-300 mb-2">
                                        Contact Sales for more invites
                                    </div>
                                    <p className="fs-12 text-muted">
                                        (Customers can be allowed to pay instead, if you run out of
                                        invites.){' '}
                                    </p>
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default Plans;

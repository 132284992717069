import React from 'react';
import Proptypes from 'prop-types';
import { Modal, ModalBody, Button, ModalFooter } from 'reactstrap';
import { Feedback } from 'components/molecules';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    setCurrentProduct_,
    packageDetail_,
    selectedExistingProduct_,
    getPackageById
} from 'store/actions';
import { stringSplitAndUppercase } from 'utils';

const EditMainProductInitModal = (props) => {
    const {
        isOpen,
        toggleModal,
        setCreateMainProductModal,
        get_calendar_data,
        setSelectCalendarDaysBookingModal,
        setAddOnProductItineraryTimeModal,
        setOpenActivityModal,
        setCurrentProduct_,
        packageDetail_,
        selectedExistingProduct_,
        getPackageById
    } = props;
    const mainProduct =
        get_calendar_data?.data?.mainProduct &&
        Array.isArray(get_calendar_data?.data?.mainProduct) &&
        get_calendar_data?.data?.mainProduct.length > 0
            ? get_calendar_data?.data?.mainProduct[0]
            : null;
    const mainPackage =
        get_calendar_data?.data?.mainPackage &&
        Array.isArray(get_calendar_data?.data?.mainPackage) &&
        get_calendar_data?.data?.mainPackage.length > 0
            ? get_calendar_data?.data?.mainPackage[0]
            : null;
    function onEditMainProduct() {
        if (mainProduct?.day_type === 'all_day') setSelectCalendarDaysBookingModal(true);
        else setAddOnProductItineraryTimeModal(true);

        // mainProduct.productype = 'main_product';
        setCurrentProduct_(mainProduct);
        selectedExistingProduct_(true);
    }

    function onEditMainPackage() {
        packageDetail_(mainPackage);
        setOpenActivityModal(true);
        getPackageById(mainPackage?.id, get_calendar_data?.data?.id);
    }

    return (
        <Modal size="lg" centered isOpen={isOpen}>
            <div className="modal-header">
                <div className="w-100">
                    <div className="d-flex flex-wrap flex-lg-nowrap">
                        <h5 className="modal-title order-2 order-lg-1">Main Product</h5>
                        {/* <div className="flex-shrink-0 order-1 order-lg-2 ml-lg-2">
                            <Button
                                color="link"
                                className="px-0 text-decoration-underline"
                                type="button">
                                Go Back
                            </Button>
                        </div> */}
                    </div>
                    <div className="d-flex flex-wrap justify-content-lg-end gap-1 mt-lg-4">
                        <div className="flex-grow-1">
                            <div className="fs-24">
                                {mainProduct
                                    ? `${mainProduct?.productCategory?.name ?? 'N/A'}: ${
                                          mainProduct?.title
                                              ? mainProduct?.title
                                              : '(Unfinished product)'
                                      }`
                                    : mainPackage
                                    ? `Package: ${mainPackage?.title ?? '(Unfinished Package)'}`
                                    : ''}
                            </div>
                        </div>
                        {mainProduct && (
                            <div className="flex-shrink-0" style={{ minWidth: 135 }}>
                                <div className="text-uppercase fs-14 fw-600">Product status</div>
                                <div className="fs-14 text-nowrap">
                                    <span
                                        className={`calendar_activity-status __${
                                            mainProduct?.product_status?.length > 0
                                                ? mainProduct?.product_status
                                                      .toLowerCase()
                                                      .replace(/-/g, '_')
                                                : ''
                                        }`}></span>
                                    <span className="fs-14 text-gray">
                                        {stringSplitAndUppercase(mainProduct?.product_status, '_')}
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <ModalBody className="d-flex flex-wrap justify-content-center gap-3 text-center py-4 px-3">
                <div className="card card-add-new" role="button" style={{ width: 260 }}>
                    <div className="card-body">
                        <div className="mt-4">
                            <svg
                                width="49"
                                height="48"
                                viewBox="0 0 49 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.7274 21.8979L27.0535 30.2252L44.4651 12.8161L36.139 4.48881L18.7274 21.8979Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M47.5325 3.77523L44.8628 1.10551C43.3888 -0.368504 40.9984 -0.368504 39.5244 1.10551L37.6511 2.97667L45.9771 11.3027L47.8493 9.43044C49.3244 7.95749 49.0076 5.24924 47.5325 3.77523Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M17.4666 23.6246V31.9431H25.2231L17.4666 23.6246Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M31.3825 42.6477H5.69163V16.9568H19.1312L24.4835 11.6045H4.08595C2.3122 11.6045 0.339355 12.5069 0.339355 14.2806V44.2534C0.339355 46.026 2.3122 48 4.08595 48H34.0587C35.8314 48 36.7348 46.026 36.7348 44.2534V25.0879L31.3825 30.4402V42.6477Z"
                                    fill="#4F4F4F"
                                />
                            </svg>
                        </div>
                        <div className="fs-14 mt-3">Edit main product</div>

                        <div className="mt-4">
                            <Button
                                color="primary"
                                size="lg"
                                block
                                onClick={() => {
                                    toggleModal();
                                    setCreateMainProductModal(false);
                                    if (mainProduct) {
                                        onEditMainProduct();
                                    } else if (mainPackage) {
                                        onEditMainPackage();
                                    }
                                }}>
                                Edit
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card card-add-new" role="button" style={{ width: 280 }}>
                    <div className="card-body">
                        <div className="mt-4">
                            <svg
                                width="49"
                                height="48"
                                viewBox="0 0 49 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M6.24126 24C6.24126 21.2152 7.34751 18.5445 9.31664 16.5754C11.2858 14.6062 13.9565 13.5 16.7413 13.5H29.2813L27.0013 14.085L25.1113 15.975C24.8325 16.2537 24.6114 16.5846 24.4606 16.9488C24.3097 17.313 24.2321 17.7033 24.2321 18.0975C24.2321 18.4917 24.3097 18.882 24.4606 19.2462C24.6114 19.6104 24.8325 19.9413 25.1113 20.22C25.39 20.4987 25.7209 20.7198 26.0851 20.8707C26.4493 21.0215 26.8396 21.0992 27.2338 21.0992C27.6279 21.0992 28.0183 21.0215 28.3825 20.8707C28.7466 20.7198 29.0775 20.4987 29.3563 20.22L36.8563 12.72C37.1352 12.4414 37.3565 12.1105 37.5074 11.7463C37.6584 11.3821 37.7361 10.9917 37.7361 10.5975C37.7361 10.2032 37.6584 9.81287 37.5074 9.44867C37.3565 9.08448 37.1352 8.75361 36.8563 8.47499L29.3563 0.974993C28.7933 0.412071 28.0299 0.0958252 27.2338 0.0958252C26.4377 0.0958252 25.6742 0.412071 25.1113 0.974993C24.5483 1.53792 24.2321 2.3014 24.2321 3.09749C24.2321 3.89359 24.5483 4.65707 25.1113 5.21999L26.8663 6.97499L29.1913 7.49999H16.7413C13.56 7.49991 10.4466 8.41948 7.77587 10.148C5.10515 11.8764 2.99106 14.3401 1.68816 17.2423C0.385256 20.1445 -0.0508884 23.3615 0.432243 26.5058C0.915375 29.6502 2.29718 32.5878 4.41126 34.965C4.67325 35.2605 4.99087 35.5014 5.34599 35.6742C5.70111 35.8469 6.08677 35.948 6.48095 35.9717C6.87514 35.9953 7.27013 35.9411 7.64337 35.8122C8.01661 35.6832 8.36079 35.482 8.65627 35.22C8.95174 34.958 9.19272 34.6404 9.36544 34.2853C9.53817 33.9301 9.63925 33.5445 9.66293 33.1503C9.68661 32.7561 9.63242 32.3611 9.50345 31.9879C9.37448 31.6147 9.17325 31.2705 8.91127 30.975C7.19652 29.0558 6.24637 26.5736 6.24126 24Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M44.0112 12.9601C43.4781 12.3633 42.7298 12.0028 41.9309 11.9578C41.132 11.9128 40.348 12.187 39.7512 12.7201C39.1545 13.2531 38.794 14.0014 38.749 14.8004C38.7039 15.5993 38.9781 16.3833 39.5112 16.9801C40.8658 18.4867 41.7555 20.353 42.0731 22.3539C42.3908 24.3548 42.1228 26.4049 41.3014 28.257C40.48 30.109 39.1404 31.6838 37.4441 32.7916C35.7478 33.8993 33.7672 34.4927 31.7412 34.5001H19.7412L21.6012 33.8701L23.3412 32.1301C23.9042 31.5671 24.2204 30.8036 24.2204 30.0076C24.2204 29.2115 23.9042 28.448 23.3412 27.8851C22.7783 27.3221 22.0148 27.0059 21.2187 27.0059C20.4226 27.0059 19.6592 27.3221 19.0962 27.8851L11.5962 35.3851C11.3173 35.6637 11.096 35.9945 10.9451 36.3587C10.7941 36.7229 10.7164 37.1133 10.7164 37.5076C10.7164 37.9018 10.7941 38.2922 10.9451 38.6564C11.096 39.0206 11.3173 39.3514 11.5962 39.6301L19.0962 47.1301C19.6592 47.693 20.4226 48.0092 21.2187 48.0092C22.0148 48.0092 22.7783 47.693 23.3412 47.1301C23.9042 46.5671 24.2204 45.8036 24.2204 45.0076C24.2204 44.2115 23.9042 43.448 23.3412 42.8851L21.5112 41.0551L19.7412 40.5001H31.7412C34.9316 40.5015 38.0541 39.578 40.7304 37.8413C43.4067 36.1046 45.5221 33.6293 46.8205 30.7151C48.1189 27.8008 48.5446 24.5726 48.046 21.4214C47.5474 18.2702 46.1459 15.3311 44.0112 12.9601Z"
                                    fill="#4F4F4F"
                                />
                            </svg>
                        </div>
                        <div className="fs-14 mt-3">Replace with an existing product</div>

                        <div className="mt-4">
                            <Button
                                color="primary"
                                size="lg"
                                block
                                onClick={() => {
                                    setCreateMainProductModal(true);
                                    toggleModal();
                                }}>
                                Replace
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card card-add-new" role="button" style={{ width: 260 }}>
                    <div className="card-body">
                        <div className="mt-4">
                            <svg
                                width="49"
                                height="48"
                                viewBox="0 0 49 48"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.15158 17.0022C5.15158 15.0326 5.93398 13.1437 7.32667 11.751C8.71936 10.3584 10.6083 9.57595 12.5778 9.57595H21.4469L19.8343 9.9897L18.4976 11.3264C18.3005 11.5236 18.1441 11.7576 18.0374 12.0152C17.9307 12.2727 17.8758 12.5488 17.8758 12.8276C17.8758 13.1064 17.9307 13.3824 18.0374 13.64C18.1441 13.8976 18.3005 14.1316 18.4976 14.3287C18.6947 14.5259 18.9288 14.6823 19.1863 14.7889C19.4439 14.8956 19.72 14.9505 19.9988 14.9505C20.2775 14.9505 20.5536 14.8956 20.8112 14.7889C21.0687 14.6823 21.3028 14.5259 21.4999 14.3287L26.8044 9.02429C27.0016 8.82723 27.1582 8.59322 27.2649 8.33564C27.3717 8.07806 27.4267 7.80196 27.4267 7.52312C27.4267 7.24429 27.3717 6.96819 27.2649 6.71061C27.1582 6.45303 27.0016 6.21902 26.8044 6.02196L21.4999 0.717505C21.1018 0.319372 20.5618 0.0957031 19.9988 0.0957031C19.4357 0.0957031 18.8957 0.319372 18.4976 0.717505C18.0995 1.11564 17.8758 1.65562 17.8758 2.21867C17.8758 2.78171 18.0995 3.3217 18.4976 3.71983L19.7388 4.96107L21.3832 5.33238H12.5778C10.3278 5.33233 8.12583 5.9827 6.23694 7.20518C4.34805 8.42767 2.85283 10.1701 1.93134 12.2227C1.00985 14.2754 0.701383 16.5506 1.04308 18.7745C1.38478 20.9983 2.36208 23.076 3.85729 24.7573C4.04258 24.9663 4.26722 25.1367 4.51838 25.2589C4.76955 25.381 5.04231 25.4525 5.3211 25.4693C5.59989 25.486 5.87925 25.4477 6.14323 25.3565C6.40721 25.2653 6.65064 25.123 6.85961 24.9377C7.06859 24.7524 7.23903 24.5277 7.36119 24.2766C7.48335 24.0254 7.55484 23.7526 7.57159 23.4739C7.58834 23.1951 7.55001 22.9157 7.45879 22.6517C7.36758 22.3877 7.22526 22.1443 7.03997 21.9353C5.82719 20.578 5.15519 18.8224 5.15158 17.0022Z"
                                    fill="#4F4F4F"
                                />
                                <path
                                    d="M45.911 23.211C45.5339 22.789 45.0047 22.534 44.4396 22.5022C43.8746 22.4703 43.3201 22.6643 42.898 23.0413C42.476 23.4183 42.221 23.9476 42.1891 24.5126C42.1573 25.0776 42.3512 25.6322 42.7283 26.0542C43.6863 27.1198 44.3156 28.4398 44.5402 29.8549C44.7649 31.2701 44.5753 32.7201 43.9944 34.0299C43.4135 35.3398 42.466 36.4536 41.2663 37.2371C40.0665 38.0206 38.6658 38.4402 37.2329 38.4454H28.7457L30.0612 37.9999L31.2919 36.7692C31.69 36.3711 31.9137 35.8311 31.9137 35.2681C31.9137 34.705 31.69 34.165 31.2919 33.7669C30.8937 33.3688 30.3537 33.1451 29.7907 33.1451C29.2277 33.1451 28.6877 33.3688 28.2895 33.7669L22.9851 39.0714C22.7878 39.2684 22.6313 39.5024 22.5245 39.76C22.4178 40.0176 22.3628 40.2937 22.3628 40.5725C22.3628 40.8514 22.4178 41.1275 22.5245 41.385C22.6313 41.6426 22.7878 41.8766 22.9851 42.0737L28.2895 47.3781C28.6877 47.7763 29.2277 47.9999 29.7907 47.9999C30.3537 47.9999 30.8937 47.7763 31.2919 47.3781C31.69 46.98 31.9137 46.44 31.9137 45.877C31.9137 45.3139 31.69 44.774 31.2919 44.3758L29.9976 43.0815L28.7457 42.689H37.2329C39.4893 42.69 41.6977 42.0369 43.5905 40.8086C45.4834 39.5803 46.9796 37.8296 47.8979 35.7684C48.8162 33.7073 49.1172 31.4241 48.7646 29.1954C48.412 26.9667 47.4207 24.888 45.911 23.211Z"
                                    fill="#4F4F4F"
                                />
                                <rect
                                    x="19.0715"
                                    y="20.4988"
                                    width="14.422"
                                    height="4"
                                    fill="#4F4F4F"
                                />
                                <rect
                                    x="28.2826"
                                    y="15.2878"
                                    width="14.422"
                                    height="4"
                                    transform="rotate(90 28.2826 15.2878)"
                                    fill="#4F4F4F"
                                />
                            </svg>
                        </div>
                        <div className="fs-14 mt-3">Change with a new product</div>

                        <div className="mt-4">
                            <Button
                                onClick={() => {
                                    props.setCreateMainProductPackageModal(true);
                                    props.setChangeWithNewProduct(true);
                                    toggleModal();
                                }}
                                color="primary"
                                size="lg"
                                block>
                                Create
                            </Button>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <Button
                    color="dark"
                    className="btn-mw flex-grow-1 flex-lg-grow-0"
                    onClick={toggleModal}>
                    Cancel
                </Button>
                <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
            </ModalFooter>
        </Modal>
    );
};
EditMainProductInitModal.propTypes = {
    isOpen: Proptypes.bool,
    toggleModal: Proptypes.func,
    get_calendar_data: Proptypes.object,
    setCreateMainProductModal: Proptypes.func,
    setSelectCalendarDaysBookingModal: Proptypes.func,
    setOpenActivityModal: Proptypes.func,
    setAddOnProductItineraryTimeModal: Proptypes.func,
    setCurrentProduct_: Proptypes.func,
    packageDetail_: Proptypes.func,
    setCreateMainProductPackageModal: Proptypes.func,
    setChangeWithNewProduct: Proptypes.func,
    selectedExistingProduct_: Proptypes.func,
    getPackageById: Proptypes.func
};
const mapStateToProps = (state) => ({
    get_calendar_data: state.calendar.get_calendar_data
});

const mapDispatch = (dispatch) => ({
    setCurrentProduct_: (data) => dispatch(setCurrentProduct_(data)),
    packageDetail_: (data) => dispatch(packageDetail_(data)),
    selectedExistingProduct_: (data) => dispatch(selectedExistingProduct_(data)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(EditMainProductInitModal));

import React, { Component } from 'react';
import { Row, Col, Button } from 'reactstrap';
// import { Input } from 'antd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    topUpPayNow,
    toggleCheckPaymentInfo,
    toggleUpgradePlan,
    toggleAddTopUp,
    setTotalRevenu,
    setCalendarDate,
    reduceToken,
    setCurrentPlan,
    tgleAddTopUpPopover,
    tgleUpgradePlanPopover,
    toggleAccessCalendarModal,
    reduceLiveCalendar,
    getVendorSubscription,
    getAllPlans,
    toggleUpgradePlanDrawer,
    changeVendorSubscription,
    toogleDisableCalendarModal,
    toggleMainProductPrice,
    downgradeToFreePlan
} from 'store/actions/accessCalendar';
import SocketConnector from 'utils/socket';

// import { CopyOutlined, SyncOutlined } from '@ant-design/icons';
import PlanList from '../../../../atoms/planList/PlanList';
import UpgradeModal from '../../../upgradeSideBar/upgradeSideBar';
import { ConfirmationModal } from 'components/molecules';
import HelpDrawer from './help-drawer';
import PlanDrawer from './plan-drawer';
import TopUpDrawer from './top-up-drawer';
import ProductInfoDrawer from './product-info-drawer';
import UpgradePlanDrawer from './upgrade-plan-drawer';
import SendByComponent from './send-by-component';
import PaidNotPaidFlow from './paid-notpaid-flow';

import { getSendToCustomerState, getPlanList } from 'utils/helper';
import { MainProductI } from 'assets';
import SendByContainer from './send-by-container';
import { HelpButton, Loader, Notification } from 'components/atoms';
import {
    getPackageById,
    getProductAvailability,
    encryptUrl,
    getAllTravellersAction,
    updateAllTravellers
} from 'store/actions';

class SendToCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = getSendToCustomerState(props);

        this.handleTopUpPay = this.handleTopUpPay.bind(this);
        // this.handleSendButton = this.handleSendButton.bind(this);
        this.handleNoToken = this.handleNoToken.bind(this);
        this.handlePlan = this.handlePlan.bind(this);
        this.handleProductInfo = this.handleProductInfo.bind(this);
        this.handleUpgradePlan = this.handleUpgradePlan.bind(this);
        // this.shareLink = this.shareLink.bind(this);
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.handleHelp = this.handleHelp.bind(this);
        this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
        this.handleVendorSubscriptionChange = this.handleVendorSubscriptionChange.bind(this);
        // this.checkPlanAndShare = this.checkPlanAndShare.bind(this);
    }

    componentDidMount() {
        const {
            getVendorSubscription,
            getAllPlans,
            get_calendar_data,
            getPackageById,
            getAllTravellers
        } = this.props;
        getVendorSubscription();
        getAllPlans();
        getAllTravellers();

        const socket = SocketConnector();

        socket.on(`SUBSCRIPTION_UPDATED`, async () => {
            getVendorSubscription();

            Notification('success', `Your subscription has been successfully changed.`, 'success');
        });
        socket.on(`TOKEN_ADDED`, (tokenAdded) => {
            getVendorSubscription();

            Notification('success', `${tokenAdded} tokens has just been added.`, 'success');
        });
        //if mainpackage exists fetch all the products related to the package
        const mainPackage =
            Array.isArray(get_calendar_data?.data?.mainPackage) &&
            get_calendar_data?.data?.mainPackage.length > 0
                ? get_calendar_data?.data?.mainPackage[0]
                : null;
        if (mainPackage) getPackageById(mainPackage?.id);
    }
    /* Update plan in reducer after selecting plan 
    and show popover with updated popover messsage 
    also close upgrade plan drawer  */
    handleUpgradePlan(selectedplan) {
        if (selectedplan) {
            this.props.setCurrentPlan(selectedplan);
            this.setState({
                upgradePopupContent: `Your plan has been updated. Now you are on a  <b>${this.state.planSelected} plan<b>`
            });
            this.props.tgleUpgradePlanPopover(true);
        }
        this.props.toggleUpgradePlanDrawer(false);
    }

    /*After clicking productinfo button 
    total revenue calculation done using following function
    also opens productInfo drawer
    update total revenue in reducer*/

    handleProductInfo() {
        if (this.props.checkPaymentInf) {
            let productTotal = 0;
            this.props.productInfos.map((product) => (productTotal += product.price));
            productTotal += this.props.mainProductPrice;
            this.setState({ totalRevenu: productTotal });
        } else {
            let productTotal = 0;
            this.props.productInfos.map((product) => (productTotal += product.price));
            this.setState({ totalRevenu: productTotal });
        }
        this.setState({ toggleProductInfoDrawer: true });

        this.props.setTotalRevenu(this.state.totalRevenu);
    }

    /* Update number of customers in reducer,
    close addTopUp drawer and
    show addTopUp popover with updated message */
    async handleTopUpPay() {
        await this.props.topUpPayNow(this.state.numberOfCustomers);
        this.props.toggleAddTopUp(false);
        if (this.state.numberOfCustomers) {
            this.setState({
                addTopUpPopupContent: `<b>Top-Up Added</b>.<br/> Now you have ${this.props.vendorSubscription?.available_token} Available Tokens.`
            });
            this.props.tgleAddTopUpPopover(true);
        }
    }

    /* close plan drawer and open upgradePlan drawer */
    handlePlan() {
        this.setState({ togglePlanDrawer: false });
        this.props.toggleUpgradePlanDrawer(true);
    }

    /* close noToken drawer and open addTopUp drawer */
    handleNoToken() {
        this.setState({ toggleNoTokenDrawer: false });
        this.props.toggleAddTopUp(true);
    }

    copyToClipboard() {
        navigator.clipboard.writeText(this.state.calendarLink);
    }

    handleHelp() {
        if (this.state.showLink) {
            this.setState({ toggleHelpDrawer: true });
        }
    }

    async handleVendorSubscriptionChange(selectedRowKeys) {
        selectedRowKeys = selectedRowKeys || [];

        await this.props.changeVendorSubscription(this.state.selectedPlan.id, selectedRowKeys);
        let upgradePopupContent = 'Your plan has been updated. ';
        upgradePopupContent += this.props.upcomingSubscription
            ? `<b>${this.props.upcomingSubscription?.plan?.name}</b> plan will be in effect from <b>${this.props.upcomingSubscription?.start_date}</b>`
            : `Now you are on a  <b>${this.props.vendorSubscription?.plan?.name} plan<b>`;
        this.setState({ upgradePopupContent });
        this.props.tgleUpgradePlanPopover(true);
    }

    async handleSubscriptionChange(selectedPlan, selectedKeys = []) {
        this.setState({ selectedPlan: selectedPlan.planDetails });
        this.props.toggleUpgradePlanDrawer(false);

        const popContent = () => {
            let upgradePopupContent = `Your plan has been updated. ${
                this.props.upcomingSubscription
                    ? `<b>${this.props.upcomingSubscription?.plan?.name}</b> plan will be in effect from <b>${this.props.upcomingSubscription?.start_date}</b>`
                    : `Now you are on a  <b>${this.props.vendorSubscription?.plan?.name} plan<b>`
            }`;

            this.setState({ upgradePopupContent });
            this.props.tgleUpgradePlanPopover(true);
        };

        console.log({ selectedPlan });
        if (selectedPlan.is_free) {
            this.props.changeVendorSubscription(selectedPlan.id);
            return popContent();
        } else {
            await this.props.changeVendorSubscription(selectedPlan.planId, selectedKeys);
            return popContent();
            // let upgradePopupContent = 'Your plan has been updated. ';
            // upgradePopupContent += this.props.upcomingSubscription
            //     ? `<b>${this.props.upcomingSubscription?.plan?.name}</b> plan will be in effect from <b>${this.props.upcomingSubscription?.start_date}</b>`
            //     : `Now you are on a  <b>${this.props.vendorSubscription?.plan?.name} plan<b>`;
            // this.setState({ upgradePopupContent });
            // this.props.tgleUpgradePlanPopover(true);
        }
    }

    calculateTotalProductPrice(products) {
        if (Array.isArray(products)) {
            let total = 0;
            for (let prod of products) {
                let price =
                    prod.productType === 'main_product'
                        ? this.props.checkPaymentInf
                            ? prod?.pricings
                            : undefined
                        : prod?.pricings;

                if (Array.isArray(price) && price.length > 0) {
                    if (Array.isArray(price[0].age_groups) && price[0].age_groups.length > 0) {
                        total = total + price[0].age_groups[0].amount;
                    } else if (price[0].per_person_sharing) {
                        total = total + price[0].per_person_sharing?.double_room?.amount;
                    }
                }
            }
            return `$${total}`;
        }
        return `$0`;
    }

    calculateProductPrice(price) {
        if (Array.isArray(price) && price.length > 0) {
            if (Array.isArray(price[0].age_groups) && price[0].age_groups.length > 0) {
                return `$${price[0].age_groups[0].amount || 0}`;
            } else if (price[0].per_person_sharing) {
                return `$${price[0].per_person_sharing?.double_room?.amount || 0}`;
            } else {
                return '$0';
            }
        } else return '$0';
    }

    calculateProductPriceType(price) {
        if (Array.isArray(price) && price.length > 0) {
            if (Array.isArray(price[0].age_groups) && price[0].age_groups.length > 0) {
                return `For ${price[0].age_groups[0].type}`;
            } else if (price[0].per_person_sharing) {
                return `For per person sharing(Double room)`;
            } else {
                return '';
            }
        } else return '';
    }

    render() {
        const { get_all_calendar_products, get_calendar_data } = this.props;
        const mainProduct =
            Array.isArray(get_calendar_data?.data?.mainProduct) &&
            get_calendar_data?.data?.mainProduct.length > 0
                ? get_calendar_data?.data?.mainProduct[0]
                : null;
        const mainPackage =
            Array.isArray(get_calendar_data?.data?.mainPackage) &&
            get_calendar_data?.data?.mainPackage.length > 0
                ? get_calendar_data?.data?.mainPackage[0]
                : null;

        /**
         * If the mainpackage is not null, vendors can send link
         * If the main product is not null, check the status of main product
         */
        const valid = mainPackage
            ? true
            : mainProduct &&
              (mainProduct?.product_status === 'not_in_use' ||
                  mainProduct?.product_status === 'in_use')
            ? true
            : false;

        return (
            <div className="sendToCustomer">
                {this.state.loading && <Loader typeTransparent />}
                <div className="content content-margin">
                    <Row className="ml-0 mr-0 pl-0 pr-0">
                        <Col md="6" xs="12" className="access-plan-row">
                            <PlanList
                                type="Upgrade-Plan"
                                planList={getPlanList(this.props.vendorSubscription)}
                                titleColor="#a2792c"
                                titleWeight="600"
                                title1="Access Plan: "
                                title2={
                                    this.props.vendorSubscription?.plan?.name
                                        ? this.props.vendorSubscription?.plan?.name
                                        : '-'
                                }
                                btnTitle="Change Plan"
                                upgradePopupContent={this.state.upgradePopupContent}
                            />
                        </Col>
                        <Col md="6" xs="12" className="available-token-row">
                            <PlanList
                                type="Top-Up"
                                title1="Available Customer Invites: "
                                title2={
                                    this.props.vendorSubscription?.available_token
                                        ? this.props.vendorSubscription?.available_token
                                        : 0
                                }
                                titleColor="#333"
                                titleWeight="600"
                                btnTitle="ADD TOP-UP"
                                addTopUpPopupContent={this.state.addTopUpPopupContent}
                            />
                        </Col>
                    </Row>
                </div>

                <div style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>
                    {mainProduct === null && mainPackage === null ? (
                        <div
                            className="shadow rounded p-3 p-lg-5 my-3 my-lg-5 text-center mx-auto"
                            style={{ maxWidth: 642 }}>
                            <div className="mb-4">
                                <h5 className="ff-base fw-500 fs-18 text-primary text-center">
                                    <span>{'Please create a main product first'}</span>
                                </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button
                                    color="primary-alt"
                                    className="btn-mw mx-1 mx-lg-4"
                                    onClick={() => {
                                        this.props.toggleAccessCalendarModal(false);
                                        this.props.createMainProduct();
                                    }}>
                                    Create main product
                                </Button>
                            </div>
                        </div>
                    ) : !valid ? (
                        <div
                            className="shadow rounded p-3 p-lg-5 my-3 my-lg-5 text-center mx-auto"
                            style={{ maxWidth: 642 }}>
                            <div className="mb-4">
                                <h5 className="ff-base fw-500 fs-18 text-primary text-center">
                                    <span>
                                        {`This calendar main product has a status of ${mainProduct?.product_status
                                            .toUpperCase()
                                            .replace(/_/g, ' ')}. 
                                      `}
                                    </span>
                                </h5>
                                <p className="text-primary text-center fw-500">
                                    Please change status to NOT IN USE to share this calendar
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button
                                    color="primary-alt"
                                    className="btn-mw mx-1 mx-lg-4"
                                    onClick={() => {
                                        this.props.history.push('/availability-data');
                                    }}>
                                    Update Product Status
                                </Button>
                            </div>
                        </div>
                    ) : valid && this.state.paidOrNotPaidPrompt ? (
                        <div
                            className="shadow rounded p-3 p-lg-5 my-3 my-lg-5 text-center mx-auto"
                            style={{ maxWidth: 642 }}>
                            <div className="mb-4">
                                <h5 className="ff-base fw-500 fs-18 text-primary text-center">
                                    <span>
                                        {' '}
                                        {`Has the customer already paid for ${
                                            mainProduct?.title
                                                ? mainProduct?.title
                                                : mainPackage?.title
                                                ? mainPackage?.title
                                                : 'Product'
                                        }`}
                                    </span>
                                    <span className="mx-1">
                                        <MainProductI />
                                    </span>
                                    ?
                                </h5>
                            </div>
                            <div className="d-flex justify-content-center">
                                <Button
                                    color="primary-alt"
                                    className="btn-mw mx-1 mx-lg-4"
                                    onClick={() =>
                                        this.setState({
                                            paidOrNotPaidPrompt: false,
                                            paidOrNotPaidFlowInit: true,
                                            alreadyPaidProcess: false
                                        })
                                    }>
                                    Not Paid
                                </Button>
                                <Button
                                    color="primary-alt"
                                    className="btn-mw mx-1 mx-lg-4"
                                    onClick={() =>
                                        this.setState({
                                            paidOrNotPaidPrompt: false,
                                            paidOrNotPaidFlowInit: true,
                                            alreadyPaidProcess: true
                                        })
                                    }>
                                    Already Paid
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>

                {this.state.paidOrNotPaidFlowInit && (
                    <PaidNotPaidFlow
                        state={this.state}
                        setState={(objOrFun) => this.setState(objOrFun)}
                    />
                )}

                {this.state.isReadyToSend && (
                    <div className="pt-3">
                        <h5 className="ff-base fw-500 fs-18 text-primary text-center">
                            <span>Choose an option below</span>
                        </h5>
                        <div
                            className="d-lg-flex gap-3 flex-nowrap p-3 rounded"
                            style={{ background: '#FEFCF4', border: '1px solid #E7D99F' }}>
                            <SendByContainer
                                state={this.state}
                                setState={(objOrFun) => this.setState(objOrFun)}
                                inhertProps={this.props}
                            />

                            <div className="flex-grow-1">
                                <SendByComponent
                                    state={this.state}
                                    setState={(objOrFun) => this.setState(objOrFun)}
                                    setCalendarDate={this.props.setCalendarDate}
                                    startDate={this.props.startDate}
                                    endDate={this.props.endDate}
                                    inhertProps={this.props}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* <SendToCustomerFooterAndExtras
                    state={this.state}
                    setState={(objOrFun) => this.setState(objOrFun)}
                    inhertProps={this.props}
                    _modal={this._modal}
                    handleHelp={this.handleHelp}
                    handleNoToken={this.handleNoToken}
                /> */}
                <div className="footer-btns">
                    <Row>
                        <Col xs="12" md="6" className="text-center text-md-left mb-2 mb-md-0">
                            <Button
                                color="link"
                                className="text-dark fw-600"
                                onClick={() => this.props.toggleAccessCalendarModal(false)}>
                                Cancel
                            </Button>
                        </Col>
                        <Col xs="12" md="6" className="helpCol pl-0">
                            <HelpButton onClick={() => this.handleHelp()} />
                        </Col>
                    </Row>
                </div>

                {/* Upgrade Plan Drawer */}
                <UpgradePlanDrawer
                    state={this.state}
                    setState={(objOrFun) => this.setState(objOrFun)}
                    vendorSubscription={this.props.vendorSubscription}
                    toggleUpgradePlan={this.props.toggleUpgradePlan}
                    UpgradePlan={this.props.UpgradePlan}
                    handleUpgradePlan={this.handleUpgradePlan}
                />

                <ProductInfoDrawer
                    state={this.state}
                    setState={(objOrFun) => this.setState(objOrFun)}
                    get_all_calendar_products={get_all_calendar_products}
                    checkPaymentInf={this.props.checkPaymentInf}
                />

                <TopUpDrawer
                    state={this.state}
                    setState={(objOrFun) => this.setState(objOrFun)}
                    vendorSubscription={this.props.vendorSubscription}
                    AddTopUp={this.props.AddTopUp}
                    toggleAddTopUp={this.props.toggleAddTopUp}
                />

                <PlanDrawer
                    state={this.state}
                    setState={(objOrFun) => this.setState(objOrFun)}
                    handlePlan={this.handlePlan}
                    vendorSubscription={this.props.vendorSubscription}
                />

                <HelpDrawer state={this.state} setState={(objOrFun) => this.setState(objOrFun)} />

                <UpgradeModal
                    className="black-button table-btn list-btn"
                    title="Change Plan"
                    onSubscriptionChange={this.handleSubscriptionChange}
                />

                {/* <DowngradeCalendars
                    selectedPlan={this.state.selectedPlan}
                    onConfirm={this.handleVendorSubscriptionChange}
                /> */}
                {/* <DowngradeCalendar
                    selectedPlan={usedPlan}
                    showPopUp={downgradeModal}
                    handleShowPopUp={handleShowPopUp}
                    // showDowngradeCalendarPopup={showDowngradeCalendarPopup}
                    // toogleDisableCalendarModal={toogleDisableCalendarModal}
                    onConfirm={onSubscriptionChange}
                /> */}

                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={() => this.setState({ confirmationModal: false })}
                    title="Required"
                    description="To be able to send your calendar to customers, you will have to completely set up your payment account"
                    yesAllias="Complete payment account setup"
                    noAllias="Cancel"
                    handleConfirmation={() => this.props.history.push('/finance')}
                />
            </div>
        );
    }
}

SendToCustomer.propTypes = {
    tokenAmount: PropTypes.number.isRequired,
    totalTokenAmount: PropTypes.number.isRequired,
    availableToken: PropTypes.number.isRequired,
    mainProductPrice: PropTypes.number.isRequired,
    AddTopUp: PropTypes.bool.isRequired,
    UpgradePlan: PropTypes.bool.isRequired,
    checkPaymentInf: PropTypes.bool.isRequired,
    paymentInfo: PropTypes.object.isRequired,
    productInfos: PropTypes.array.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    currentPlan: PropTypes.string.isRequired,
    topUpPayNow: PropTypes.func,
    toggleCheckPaymentInfo: PropTypes.func,
    toggleAddTopUp: PropTypes.func,
    toggleUpgradePlan: PropTypes.func,
    setTotalRevenu: PropTypes.func,
    setCalendarDate: PropTypes.func,
    reduceToken: PropTypes.func,
    setCurrentPlan: PropTypes.func,
    tgleAddTopUpPopover: PropTypes.func,
    tgleUpgradePlanPopover: PropTypes.func,
    toggleAccessCalendarModal: PropTypes.func,
    liveCalendar: PropTypes.number,
    reduceLiveCalendar: PropTypes.func,
    myProfile: PropTypes.object,
    calendarId: PropTypes.string.isRequired,
    getVendorSubscription: PropTypes.func.isRequired,
    vendorSubscription: PropTypes.object.isRequired,
    getAllPlans: PropTypes.func.isRequired,
    toggleUpgradePlanDrawer: PropTypes.func.isRequired,
    changeVendorSubscription: PropTypes.func.isRequired,
    upcomingSubscription: PropTypes.any,
    toogleDisableCalendarModal: PropTypes.func.isRequired,
    liveCalendars: PropTypes.array,
    get_calendar_data: PropTypes.object,
    profile: PropTypes.any,
    calendar: PropTypes.any,
    toggleMainProductPrice: PropTypes.func,
    access: PropTypes.any,
    get_all_calendar_products: PropTypes.array,
    getPackageById: PropTypes.func,
    package_detail: PropTypes.object,
    getProductAvailability: PropTypes.func,
    dispatch: PropTypes.func,
    createMainProduct: PropTypes.func.isRequired,
    history: PropTypes.object,
    getAllTravellers: PropTypes.func,
    updateAllTravellers: PropTypes.func
};

const mapStateToProps = (state) => ({
    tokenAmount: state.accessCalendarReducer.tokenAmount,
    totalTokenAmount: state.accessCalendarReducer.totalTokenAmount,
    availableToken: state.accessCalendarReducer.availableToken,
    mainProductPrice: state.accessCalendarReducer.mainProductPrice,
    AddTopUp: state.accessCalendarReducer.AddTopUp,
    UpgradePlan: state.accessCalendarReducer.UpgradePlan,
    checkPaymentInf: state.accessCalendarReducer.checkPaymentInf,
    paymentInfo: state.accessCalendarReducer.paymentInfo,
    productInfos: state.accessCalendarReducer.productInfos,
    startDate: state.accessCalendarReducer.startDate,
    endDate: state.accessCalendarReducer.endDate,
    currentPlan: state.accessCalendarReducer.currentPlan,
    liveCalendar: state.accessCalendarReducer.liveCalendar,
    myProfile: state.auth.my_profile,
    vendorSubscription: state.accessCalendarReducer.vendorSubscription,
    upcomingSubscription: state.accessCalendarReducer.upcomingSubscription,
    liveCalendars: state.accessCalendarReducer.liveCalendars,
    get_calendar_data: state.calendar.get_calendar_data,
    calendar: state.calendar?.calendar_data?.data,
    profile: state.profile,
    access: state.accessCalendarReducer,
    get_all_calendar_products: state.calendar.get_all_calendar_products?.data,
    package_detail: state.product.package_detail,
    allTravellers: state.accessCalendarReducer.allTravellers
});

const mapDispatchToProps = (dispatch) => {
    return {
        topUpPayNow: (noOfCustomers) => dispatch(topUpPayNow(noOfCustomers)),
        toggleCheckPaymentInfo: (value) => dispatch(toggleCheckPaymentInfo(value)),
        toggleAddTopUp: (value) => dispatch(toggleAddTopUp(value)),
        toggleUpgradePlan: (value) => dispatch(toggleUpgradePlan(value)),
        setTotalRevenu: (totalRevenu) => dispatch(setTotalRevenu(totalRevenu)),
        setCalendarDate: (dates) => dispatch(setCalendarDate(dates)),
        reduceToken: (remainingTokens) => dispatch(reduceToken(remainingTokens)),
        setCurrentPlan: (selectedplan) => dispatch(setCurrentPlan(selectedplan)),
        tgleAddTopUpPopover: (toggleAddTopUpPopover) =>
            dispatch(tgleAddTopUpPopover(toggleAddTopUpPopover)),
        tgleUpgradePlanPopover: (toggleUpgradePlanPopover) =>
            dispatch(tgleUpgradePlanPopover(toggleUpgradePlanPopover)),
        toggleAccessCalendarModal: (value) => dispatch(toggleAccessCalendarModal(value)),
        reduceLiveCalendar: () => dispatch(reduceLiveCalendar()),
        getVendorSubscription: () => dispatch(getVendorSubscription()),
        getAllPlans: () => dispatch(getAllPlans()),
        toggleUpgradePlanDrawer: (value) => dispatch(toggleUpgradePlanDrawer(value)),
        changeVendorSubscription: (planId, selectedCalendarIds) =>
            dispatch(changeVendorSubscription(planId, selectedCalendarIds)),
        toogleDisableCalendarModal: () => dispatch(toogleDisableCalendarModal()),
        toggleMainProductPrice: ({ id, display }) =>
            dispatch(toggleMainProductPrice({ id, display })),
        getPackageById: (id) => dispatch(getPackageById(id)),
        getProductAvailability: (query) => dispatch(getProductAvailability(query)),
        encryptUrl: (string) => dispatch(encryptUrl(string)),
        downgradeToFreePlan: (data) => dispatch(downgradeToFreePlan(data)),
        getAllTravellers: () => dispatch(getAllTravellersAction()),
        updateAllTravellers: (data) => dispatch(updateAllTravellers(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SendToCustomer));

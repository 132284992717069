import React from 'react';
import propTypes from 'prop-types';

const AccommodationDropDown = (props) => {
    return (
        <div style={{ flex: 'auto' }} className={props.isSidebar ? 'd-flex p-r' : ''}>
            <button
                style={{ width: props.widthy }}
                className={
                    props.isSelected
                        ? 'pressedbutton cursor-pointer message'
                        : 'newbutton cursor-pointer message'
                }
                onClick={props.onClick}>
                <label style={{ paddingLeft: 15 }} className="form-check-label select">
                    {props.name}
                </label>
            </button>
            <div className={props.isSidebar ? 'p-a' : ''}>{props.children}</div>
        </div>
    );
};
AccommodationDropDown.propTypes = {
    isSidebar: propTypes.bool,
    isSelected: propTypes.bool,
    widthy: propTypes.any,
    onClick: propTypes.func,
    name: propTypes.any,
    children: propTypes.any
};

export default AccommodationDropDown;

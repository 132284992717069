/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import VendorDashboard from '../vendor-dashboard';
import TextDropDown from '../../../atoms/text-dropdown';
import ImportProduct from '../importproduct';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';
import Button from '../button';
import Guide from '../../../../assets/images/guide.svg';
import Double from '../../../../assets/images/double.svg';
import Single from '../../../../assets/images/single.svg';
import Bunk from '../../../../assets/images/bunk.svg';
import Plus from '../../../../assets/images/plus.svg';
import Sofa from '../../../../assets/images/sofa.svg';
import './index.css';
import AccommodationDropDown from '../../../atoms/accommodationdropdown';
import TextAreaLayout from '../../../atoms/textarea-layout';
import TextFormLayout from '../../../atoms/textform-layout';
import PropLayout from '../proplayout';
import MediaField from '../../../molecules/media-library/media-field';
import InputAccommodation from '../Inputaccommodation';
import Number from '../number';
import RadioButton from '../../../atoms/radiobutton';
import AddButton from '../add-button';
import Modal from 'react-modal';
// import FixedHelpButton from '../../../molecules/fixedhelpbutton';
import Feedback from '../../../molecules/user-feedback';

import MyGoogleMap from '../../../molecules/map';

export default function AccommodationDashBoard() {
    //   mock data for the accommodation dashboard
    const customStyles = {
        content: {
            height: '90%',
            overflowY: 'scroll',
            top: '50%',
            width: '60%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '5px'
        }
    };

    const breakfast = [
        'A la carte',
        'American',
        'Asian',
        'Buffet',
        'Continental',
        'Full English/Irish',
        'Gluten free',
        'Halal',
        'Italian',
        'Kosher',
        'Vegan',
        'Vegetarian'
    ];

    const initData = [
        {
            name: 'Villa/Mansion'
        },
        {
            name: 'Hotel'
        },
        {
            name: 'Apartment'
        },
        {
            name: 'Camping'
        },
        {
            name: 'Bread & Breakfast'
        },
        {
            name: 'Chalet'
        },
        {
            name: 'Hostel'
        },
        {
            name: 'Log Cabin'
        },
        {
            name: 'Country Home'
        },
        {
            name: 'Timeshare Rentals'
        },
        {
            name: 'Cottage'
        },
        {
            name: 'Other'
        }
    ];

    const accomodationData = [
        {
            title: 'Double bed',
            subtitle: '131 - 150 cm wide',
            image: Double
        },
        {
            title: 'Large bed(King size)',
            subtitle: '151 - 180 cm wide',
            image: Double
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: '181 - 210 cm wide',
            image: Double
        },
        {
            title: 'Sofa bed',
            subtitle: 'Variable size',
            image: Sofa
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: 'Futon Mat',
            image: Double
        }
    ];

    const accomodationTwin = [
        {
            title: 'Single Bed x 2',
            subtitle: '90 - 130 cm wide',
            image: Single
        },
        {
            title: 'Bunk bed',
            subtitle: 'Variable size',
            image: Bunk
        }
    ];

    const singleRoom = [
        {
            title: 'Single Bed',
            subtitle: '90 - 130 cm wide',
            image: Single
        }
    ];

    const doubleRoom = [
        {
            title: 'Double Bed',
            subtitle: '90 - 130 cm wide',
            image: Single
        },
        {
            title: 'Large bed (King size)',
            subtitle: '150 - 180 cm wide',
            image: Single
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: '181 - 210 cm wide',
            image: Single
        }
    ];

    const general = [
        'Air conditioning',
        'Heating',
        'Free WiFi',
        'Free on site parking',
        'Electric vehicle changing station'
    ];
    const cooking = ['Kitchen', 'Kitchinette', 'Washing Machine'];
    const entertainment = ['Flat-screen TV', 'Swimming pool', 'Hot tub', 'Minibar', 'Sauna'];
    const outside = ['Balcony', 'Garden view', 'Terrace', 'View'];

    const houserules = ['Smoking allowed', 'Pets allowed', 'Parties/events allowed'];
    // let genders;

    // fuction to set gender, add category and set days
    const [myGender, setMyGender] = useState('NO');
    const setGender = (e) => {
        // genders = e.target.value
        setMyGender(e.target.value);
    };

    const [myPay, setMyPay] = useState('NO');
    const changePay = (e) => {
        // genders = e.target.value
        setMyPay(e.target.value);
    };

    const [addCategory, setAddCategory] = useState('');

    const [data, setData] = useState(initData);
    const handleChange = () => {
        setData([
            ...data,
            {
                name: addCategory,
                picture: Guide
            }
        ]);
    };

    const [sDays, setSdays] = useState([]);
    const changeColor = (day) => {
        if (sDays.some((d) => d === day)) {
            setSdays([...sDays.filter((d) => d !== day)]);
        } else {
            setSdays([...sDays, day]);
        }
    };

    const [usage, setUsage] = useState(false);
    const changeUsage = () => {
        setUsage(true);
    };
    const closeUsage = () => {
        setUsage(false);
    };

    const [double, setDouble] = useState(false);
    const changeDouble = () => {
        setDouble(!double);
    };

    const [accommodation, setAccommodation] = useState(false);
    const changeAccommodation = () => {
        setAccommodation(!accommodation);
    };

    const [radio, setRadio] = useState(false);
    const changeRadio = () => {
        setRadio(!radio);
    };

    const [button, setButton] = useState(false);
    const changeButton = () => {
        setButton(true);
    };

    const [house, setHouse] = useState(false);
    const changeHouse = () => {
        setHouse(!house);
    };

    const [icon, setIcon] = useState([]);
    const changeAddIcon = (add) => {
        if (icon.some((a) => a === add)) {
            setIcon([...icon.filter((a) => a !== add)]);
        } else {
            setIcon([...icon, add]);
        }
    };

    // const [value, onChange] = useState('10:00');
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const openModal = () => {
        setmodalIsOpen(true);
    };
    const closeModal = () => {
        setmodalIsOpen(false);
    };

    const [options, setOptions] = useState(3);
    const [optionDown, setoptionDown] = useState(false);
    const showOption = (key) => {
        setOptions(6);
        setoptionDown(key);
    };
    const closeOption = (key) => {
        setOptions(3);
        setoptionDown(!key);
    };

    // const [time, setTime] = useState(null);
    // const changeTime = (time) => {
    //     setTime(time);
    // };

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                {modalIsOpen && (
                    <div>
                        <h6 style={{ paddingBottom: '20px' }}>Where can people sleep?</h6>
                        <InputAccommodation
                            onChange={changeAccommodation}
                            fontWeight="600"
                            intro="This is for a person sharing a room with another person"
                            other="Per Person Sharing"
                        />
                        {accommodation && (
                            <>
                                <div style={{ paddingLeft: '10%' }}>
                                    <p style={{ fontWeight: '600' }}>Double</p>
                                    <p>This refers to a bed enough for two adults</p>
                                    {accomodationData.slice(0, options).map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                    {!optionDown ? (
                                        <div
                                            onClick={() => showOption('optionDown')}
                                            className="row">
                                            <img alt="" className="image-icon-down" src={Down} />
                                            <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                More bed options
                                            </p>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => closeOption('optionDown')}
                                            className="row">
                                            <img alt="" className="image-icon" src={Caret} />
                                            <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                Close bed options
                                            </p>
                                        </div>
                                    )}
                                    <hr />
                                    <p>This refers to a bed enough for two adults</p>
                                    {accomodationTwin.map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <InputAccommodation
                            onChange={changeDouble}
                            fontWeight="600"
                            intro="This is for one person in a  room."
                            other="Per Person Single Room Occupancy"
                        />
                        {double && (
                            <>
                                <div style={{ paddingLeft: '10%' }}>
                                    <p style={{ fontWeight: '600' }}>Single</p>
                                    <p>
                                        This refers to a bed or room big enough for one average
                                        sized person.
                                    </p>
                                    {singleRoom.map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                    <hr />
                                    <p style={{ fontWeight: '600' }}>Double</p>
                                    <p>This refers to a bed big enough for two adults.</p>
                                    {doubleRoom.map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <hr style={{ marginTop: '20px' }} />
                        <p style={{ paddingBottom: '20px', fontWeight: '600' }}>
                            How many bathrooms are there?
                        </p>
                        <Number />
                        <hr style={{ marginTop: '40px' }} />
                        <div className="d-flex" style={{ paddingTop: '20px' }}>
                            <button onClick={closeModal} className="button-save">
                                Save & Continue
                            </button>
                            <button onClick={closeModal} className="button-outline">
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                isOpen={usage}
                onRequestClose={closeUsage}
                style={customStyles}
                contentLabel="Example Modal">
                {usage && (
                    <div style={{ paddingTop: '20px' }}>
                        <h4>What can your customers use at your place. </h4>
                        <p>What if I don't see a facility I offer?</p>
                        <p>
                            The facilities listed here are the ones most searched for by guests.
                            After you complete your registration, you can add more facilities from a
                            larger list in the extranet, the platform you'll use to manage your
                            property.
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>General</p>
                                {general.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>Cooking and Cleaning</p>
                                {cooking.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>General</p>
                                {entertainment.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>Cooking and Cleaning</p>
                                {outside.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                isOpen={radio}
                onRequestClose={changeRadio}
                style={customStyles}
                contentLabel="Example Modal">
                {radio && (
                    <>
                        <RadioButton
                            gender={setGender}
                            text="Do you serve guest breakfast "
                            yes="Yes"
                            no="No"
                            details="Breakfast details"
                        />

                        {myGender == 'YES' ? (
                            <>
                                <RadioButton
                                    gender={changePay}
                                    text="Is breakfast included in the price guests pay?"
                                    yes="Yes, it's included"
                                    no="No it costs extra"
                                />
                                {myPay == 'YES' ? (
                                    <>
                                        <p style={{ paddingTop: 25 }}>
                                            Breakfast price per person, per day
                                        </p>
                                        <div style={{ width: '25%' }} className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1">
                                                    $
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <p></p>
                                )}
                                <hr />
                                <h6>What type of breakfast do you offer ?</h6>
                                <p>Select all that apply</p>
                                {breakfast.map((data) => (
                                    <>
                                        <AddButton
                                            image={Plus}
                                            pressedbutton="breakfast-button-acc"
                                            newbutton="breakfast-acc "
                                            name={data}
                                            isSelected={icon.includes(data)}
                                            onClick={() => changeAddIcon(data)}
                                        />
                                    </>
                                ))}
                                <button onClick={changeButton} className="breakfast-acc">
                                    <span>
                                        <img alt="" style={{ paddingRight: 6 }} src={Plus} />
                                    </span>
                                    Other
                                </button>
                                {button && (
                                    <input
                                        className="style-input"
                                        placeholder="Enter breakfast type"
                                    />
                                )}
                            </>
                        ) : (
                            <p style={{ paddingTop: 150 }}></p>
                        )}
                        <hr style={{ marginTop: '40px' }} />
                        <div className="d-flex" style={{ paddingTop: '20px' }}>
                            <button className="button-save">Save & Continue</button>
                            <button className="button-outline">Cancel</button>
                        </div>
                    </>
                )}
            </Modal>
            <Modal
                isOpen={house}
                onRequestClose={changeHouse}
                style={customStyles}
                contentLabel="Example Modal">
                {house && (
                    <div style={{ paddingTop: 30 }}>
                        <h5>House rules</h5>
                        <p>What if my house rules changes?</p>
                        <p>
                            You can easily customise these house rules later and additional house
                            rules can be set on the Policies page of the extranet after you complete
                            registration.
                        </p>
                        {houserules.map((house, i) => (
                            <div key={i} className="row">
                                <div className="col-md-6">
                                    <p>{house}</p>
                                </div>
                                <div className="col-md-6">
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Modal>
            <VendorDashboard>
                <div className="background-sz">
                    <ImportProduct
                        create="Add Accommodation"
                        import="I will import accomodation information from my other activity "
                    />

                    <div style={{ paddingTop: '30px' }}>
                        <p style={{ fontWeight: 600 }}>Accomodation type</p>
                        <p>What type of accommodation are you providing to your travellers?</p>
                        <TextDropDown
                            name="Select accommodation type"
                            className="input-bar-dropdown"
                            caret={Caret}
                            down={Down}
                            class="image-icon-down"
                            icon="image-icon">
                            {data.map((item) => (
                                <AccommodationDropDown
                                    isSelected={sDays.includes(item.name)}
                                    onClick={() => changeColor(item.name)}
                                    name={item.name}
                                    key={item.name}
                                    formCheck="move-down "
                                />
                            ))}
                            <div className="input-curve" style={{ paddingTop: 15 }}>
                                <div className="padding-box">
                                    <input
                                        placeholder="Enter accommodation type here"
                                        style={{
                                            height: '37px',
                                            float: 'left',
                                            paddingLeft: '15px'
                                        }}
                                        className="input-bar"
                                        onChange={(e) => setAddCategory(e.target.value)}
                                        value={addCategory}
                                    />
                                    <Button onClick={handleChange} text="Done" float="right" />
                                </div>
                            </div>
                        </TextDropDown>

                        <div style={{ paddingTop: '30px' }}>
                            <p style={{ fontWeight: 600 }}>Description</p>
                            <p>
                                Describe the basics of where travelers will stay overnight, e.g. the
                                name, or details about the lodging.
                            </p>
                            <TextAreaLayout isSelected={false} height="125px" />
                            <TextFormLayout
                                layout="Accomodation Name"
                                height="37px"
                                placeholder="Enter accomodation name"
                            />
                            <div style={{ paddingTop: '50px' }}>
                                <div className="main-wrapper">
                                    <MyGoogleMap />
                                </div>
                            </div>

                            <div style={{ paddingTop: '100px' }}>
                                <div className="d-flex" style={{ paddingBottom: 30 }}>
                                    <div className="">
                                        <MediaField type="picture" />
                                    </div>
                                    <div style={{ paddingLeft: '30px' }}>
                                        <MediaField type="video" />
                                    </div>
                                </div>

                                <p style={{ fontWeight: 900 }}>
                                    What can cutomers use at this accomodation?
                                </p>
                                <div className="container">
                                    <PropLayout
                                        text="Where can people sleep?"
                                        subtext="Add rooms"
                                        top="20px"
                                        onClick={openModal}
                                    />

                                    <PropLayout
                                        text="What can your customers use at your place?"
                                        subtext="Add facilities"
                                        top="40px"
                                        onClick={changeUsage}
                                    />

                                    <PropLayout
                                        text="Breakfast details"
                                        subtext="Add breakfast"
                                        top="40px"
                                        onClick={changeRadio}
                                    />

                                    <PropLayout
                                        text="House rules"
                                        subtext="Add house rules"
                                        top="40px"
                                        onClick={changeHouse}
                                    />
                                    {/*                               
                               <hr style={{ marginTop: "40px" }} />
                                 <p>Check in</p>
                                 <div className="d-flex">
                                    <CheckIn label="From"/>
                                    <CheckIn label="Until"/>              
                                 </div>

                                 <p>Check in</p>
                                 <div className="d-flex">
                                 <TimePicker value={time} onChange={changeTime} />
                                              
                                 </div> */}
                                    <hr style={{ marginTop: '40px' }} />
                                    <div className="" style={{ paddingTop: '40px' }}>
                                        <button className="button-save">Save & Continue</button>
                                        <button className="button-outline">Cancel</button>
                                        {/* <FixedHelpButton fixed="fixed" top="57%" right="4%" /> */}
                                        <Feedback />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </VendorDashboard>
        </>
    );
}

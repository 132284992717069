/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './feedback.scss';

import halfstar_icon from '../../../assets/images/halfstar.svg';
import fullstar_icon from '../../../assets/images/fullstar.svg';
import TextArea from 'antd/lib/input/TextArea';

export default function Feedback({ stars, name, img }) {
    const [isReplying, setisReplying] = useState(false);
    return (
        <div className="mb60 feedback-wrap">
            <div className="feedback">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Avatar style={{ width: 45, height: 45 }} src={img} />
                    <div className="pl5">
                        <p className="mb3 fs18 mb0">
                            <span className="pr15">{name}</span>
                            {new Array(stars).fill().map((n) => (
                                <img alt="" src={fullstar_icon} style={{ width: 18 }} key={n} />
                            ))}
                            {new Array(5 - stars).fill().map((n) => (
                                <img alt="" src={halfstar_icon} style={{ width: 18 }} key={n} />
                            ))}
                        </p>
                        <p
                            style={{
                                color: '#4F4F4F',
                                fontSize: 14,
                                marginTop: 0,
                                position: 'relative',
                                top: -5
                            }}>
                            Aug 05, 2020
                        </p>
                    </div>
                </div>
                <p>
                    Our favorite Sunday place! All you can drink Caesars (bloody marys) on Sundays
                    which you make your own. Definitely our fave, along with the mimosa tower which
                    is always delicious
                </p>
            </div>
            {isReplying && (
                <TextArea
                    rows={5}
                    style={{
                        border: '1px solid rgba(162, 121, 44, 0.22)',
                        borderRadius: 20,
                        width: 500,
                        marginBottom: 20
                    }}
                />
            )}
            <p>
                <span
                    style={{
                        fontSize: 16,
                        color: '#A2792C',
                        cursor: 'pointer'
                    }}
                    onClick={() => setisReplying(true)}>
                    Reply
                </span>
                {isReplying && (
                    <span
                        style={{
                            fontSize: 16,
                            color: '#4F4F4F',
                            cursor: 'pointer',
                            paddingLeft: 30
                        }}
                        onClick={() => setisReplying(false)}>
                        Cancel
                    </span>
                )}
            </p>
        </div>
    );
}

Feedback.propTypes = {
    stars: PropTypes.array,
    name: PropTypes.any,
    img: PropTypes.any
};

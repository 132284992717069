import React from 'react';
import PropTypes from 'prop-types';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ThumbsUpIcon } from 'components/atoms';

const EventRating = (props) => {
    const { onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<ThumbsUpIcon />}
            panelTitle="Rating"
            isEditable={true}
            onEdit={onEdit}>
            <div className="border-bottom border-primary-shaded pb-2 mb-2">
                <div className="d-flex flex-nowrap align-items-center">
                    <span className="mr-2">
                        <img
                            width={170}
                            src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                            alt=""
                        />
                    </span>
                    <span className="fs-28 text-primary">3.9</span>
                </div>
                <div className="fs-18 text-primary fw-500">
                    <i>38 Google Reviews</i>
                </div>
            </div>
            <div className="border-bottom border-primary-shaded pb-2 mb-2">
                <div className="d-flex flex-nowrap align-items-center">
                    <span className="mr-2">
                        <img
                            width={170}
                            src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                            alt=""
                        />
                    </span>
                    <span className="fs-28 text-primary">3.9</span>
                </div>
                <div className="fs-18 text-primary fw-500">
                    <i>23 Facebook Reviews</i>
                </div>
            </div>
            <div className="border-bottom border-primary-shaded pb-2 mb-2">
                <div className="d-flex flex-nowrap align-items-center">
                    <span className="mr-2">
                        <img
                            width={170}
                            src={require(`../../../../assets/images/star-rating/primary/rating-${'04'}.svg`)}
                            alt=""
                        />
                    </span>
                    <span className="fs-28 text-primary">3.9</span>
                </div>
                <div className="fs-18 text-primary fw-500">
                    <i>13 Trip Advisor Reviews</i>
                </div>
            </div>
        </DetailedPanel>
    );
};

EventRating.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventRating;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

const AlertComponent = (props) => {
    return (
        <Alert color={props.color} className={props.icon ? 'has-icon ' : ' '}>
            {props.icon ? (
                <>
                    <div className="alert-icon-wrapper">{props.icon}</div>
                    <div className="alert-info-wrapper">{props.children}</div>
                </>
            ) : (
                props.children
            )}
        </Alert>
    );
};
AlertComponent.propTypes = {
    color: PropTypes.string,
    className: PropTypes.any,
    icon: PropTypes.any,
    children: PropTypes.any
};
export default AlertComponent;

export const getMinProductAccomodationGroupBookingVal = (index, groupPrices) => {
    if (!index) {
        return 2;
    }
    return groupPrices[index - 1]?.max + 1;
};

export const getMaxProductAccomodationGroupBookingVal = (index, groupPrices) => {
    let min = 0;
    if (groupPrices[index + 1]) {
        min = groupPrices[index + 1].min - 1;
    }
    return min <= 0 ? null : min;
};

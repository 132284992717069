import React, { useState } from 'react';
import './index.scss';
import Plus from '../../../assets/images/plus.svg';
import Min from '../../../assets/images/min.svg';
import TextDropDown from '../../atoms/select-dropdown';
import Caret from '../../../assets/images/caret.svg';
import Down from '../../../assets/images/down-caret.svg';
import Proptypes from 'prop-types';

export default function ImportAccomodation(props) {
    const [openModal, setOpenModal] = useState(false);

    const importProduct = () => {
        setOpenModal(!openModal);
    };
    const onSaveMainProduct = (item) => {
        props.onMainProduct(item);
        importProduct();
    };

    return (
        <div className="import-accomodation">
            <h3 style={{ fontSize: '20px', fontWeight: 900, marginTop: '13px' }}>{props.create}</h3>
            <div
                className="d-flex"
                tabIndex="0"
                role="button"
                onKeyDown={importProduct}
                onClick={importProduct}>
                <img className="cursor-pointer img-plus" src={!openModal ? Plus : Min} alt="" />
                <div className="cursor-pointer import">{props.import}</div>
            </div>
            {openModal && (
                <div style={{ paddingTop: '11px', paddingLeft: '1px' }}>
                    <p style={{ fontWeight: 500, fontSize: 14, lineHeight: '21px' }}>
                        Select product to resuse from another calendar
                    </p>
                    <TextDropDown
                        name="Select"
                        className="input-bar-dropdown"
                        caret={Caret}
                        down={Down}
                        class={'image-icon-down'}
                        icon={'image-icon'}>
                        {props.calendars
                            ? props.calendars.data.map((item) => {
                                  return (
                                      <TextDropDown
                                          caret={Caret}
                                          down={Down}
                                          isCheckBox={false}
                                          name={item.title}
                                          key={item.id}
                                          className="input-no-curve"
                                          class={'image-icon-down'}
                                          icon={'image-icon'}>
                                          {item.addOnProducts &&
                                              item.addOnProducts.map((child) => {
                                                  return (
                                                      <TextDropDown
                                                          name={child.title}
                                                          key={child.id}
                                                          color={'#FFFAE8'}
                                                          isSidebar={false}
                                                          className="input-no-curve"
                                                          isCheckBox={false}
                                                          click={() => onSaveMainProduct(child)}
                                                      />
                                                  );
                                              })}
                                      </TextDropDown>
                                  );
                              })
                            : null}
                    </TextDropDown>
                </div>
            )}
        </div>
    );
}

ImportAccomodation.propTypes = {
    onMainProduct: Proptypes.func,
    create: Proptypes.string,
    import: Proptypes.string,
    calendars: Proptypes.object
};

import React from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import { connect } from "react-redux";
import VendorOnboarding from 'components/pages/home';
import { connect } from 'react-redux';
import { setInitialAuth } from '../store/actions/AuthActions';
//Views
import Home from 'components/pages/dashboard';
import NavBar from '../components/organisms/views/navbar';
import Signin from 'components/pages/signin';
import ProductContent from '../components/organisms/views/product-content';
import ProductContentDetails from '../components/organisms/views/product-content/details';
import ProductContentItinerary from '../components/organisms/views/product-content/itenary';
import CalendarComponent from '../components/molecules/calendar/Calendar.js';
import Personalcare from '../components/organisms/views/personalcare/Personalcare.js';
import OtherVendorDetails from '../components/organisms/views/otherVendorDetails/OtherVendorDetails.js';
import Footer from '../components/organisms/views/footer/index.js';
// import VendorDashboard from "../components/organisms/views/vendor-dashboard/index.js";
import CalendarDashboard from '../components/organisms/views/calendar/index.js';
import ProjectCategorization from '../components/organisms/views/project-categorization/index.js';
import Accommodation from '../components/organisms/views/accommodation/index.js';
import AccommodationDashBoard from '../components/organisms/views/accommodationdashboard/index.js';
import AddVentoText from '../components/organisms/views/otherVendorDetails/AddVentoText.js';
import AddWellnessService from '../components/organisms/views/personalcare/AddWellnessService.js';
import MainProduct from '../components/pages/mainproduct/index.js';
import AccomodationPricing from '../components/organisms/views/pricing/AccomodationPricing.jsx';
import Pricing from '../components/organisms/views/pricing/Pricing.jsx';
import Review from '../components/organisms/views/review/Review.jsx';
import ReviewSuccessful from '../components/organisms/views/review/ReviewSuccessful.jsx';
import Activity from '../components/organisms/views/activity/Activity.jsx';
import AddActivityOption from '../components/organisms/views/activity/AddActivityOption.jsx';
import MealsAndDrinksLanding from '../components/organisms/views/food-drinks-details/index';
import ChooseChefService from '../components/organisms/views/food-drinks-details/choose-chef-service';
import GuideLanding from '../components/organisms/views/guide/index.js';
import GuideDetails from '../components/organisms/views/guide/details.js';
import GuideOtherVendorText from '../components/organisms/views/guide/other-vendor-text.js';
import CustomerExperience from '../components/organisms/views/customerExperience/CustomerExperience.js';
import Finance from '../components/organisms/views/finance/index.js';
import Test from '../components/organisms/views/accessCustomerCalendar/index.js';
import ManageUsers from '../components/organisms/views/manageusers/index.js';
import AddNewUser from '../components/organisms/views/manageusers/AddNewUser.js';
import FAQ from '../components/organisms/views/faq/index.js';
import Index from '../components/organisms/views/sync-availability';
import SyncAvailabilityProducts from '../components/organisms/views/sync-availability/sync-products';
// import AccomodationPricing from "../components/organisms/views/pricing/AccomodationPricing.jsx";
// import Pricing from "../components/organisms/views/pricing/Pricing.jsx";
import AccommodationDetails from '../components/organisms/views/accommodationdetails/index.js';
import AccDetails from '../components/organisms/views/accdetails/index.js';
import Publish from '../components/organisms/views/publish/index.js';
import ForgotPassword from 'components/pages/forgot-password';
import Transportation from '../components/organisms/views/transportation/Transportation.jsx';
import AddTransportOption from '../components/organisms/views/transportation/AddTransportOption.jsx';
import Security from '../components/organisms/views/security/index.js';
import LanguageSettings from '../components/organisms/views/language-settings/LanguageSettings.jsx';
//import Bookings from '../components/organisms/views/bookings/index.js';
import Availability from '../components/organisms/views/availability/index.js';
import BookingData from 'components/pages/booking-data';
import Summary from '../components/organisms/views/summary/index.js';
import Download from '../components/organisms/views/download/index.js';
import AvailabilityData from '../components/organisms/views/availabilitydata';
import BookingCalendar from '../components/organisms/views/bookingcalendar';
import Archive from '../components/organisms/views/Archive/index.js';
import AvailabilitySync from '../components/organisms/views/availabilitysync/index.js';
import EditActivityOption from '../components/organisms/views/activity/EditActivityOption.jsx';

import Account from 'components/pages/user-account';
import DeleteAccount from 'components/molecules/user-account/delete-account';
import Performance from '../components/organisms/views/performance/Performance.jsx';
import ActivitiesArrival from '../components/organisms/views/activities-arrival/ActivitiesArrival.jsx';
import UpdateAvailability from '../components/organisms/views/updateavailability/index.js';
import Covid19 from '../components/organisms/views/covid19/index.js';
import Reviews from '../components/organisms/views/reviews/index.js';
import OfferPrices from '../components/pages/offer-prices/index.js';
import ProductReviews from '../components/organisms/views/productReview/index.js';

import CreatePassword from 'components/pages/create-password';
import ProductPage from '../components/organisms/views/productpage/index.js';
import PrivacyPolicy from 'components/pages/privacy-policy';
import Terms from 'components/pages/terms';
import Components from '../components/pages/components.js';
import Plans from 'components/pages/plans';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import UserFeedback from '../components/molecules/feedback/index.js';
import CreateCalendar from '../components/pages/create-calendar/index.js';
import { CustomerCalendars } from 'components/pages';

// Pages
import { CreateNewCalendar } from 'components/pages';
const Routes = (props) => {
    const setInit = () => {
        props.setInitialAuth();
    };

    return (
        <Router>
            <NavBar setInit={setInit} />
            <div className="main-layout">
                <Switch>
                    {/* Onboarding page */}
                    <PublicRoute restricted={true} exact path="/" component={VendorOnboarding} />

                    {/* Auth page */}
                    <PublicRoute exact path="/signin" component={Signin} />
                    <PublicRoute
                        restricted={true}
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                    />
                    <PublicRoute
                        restricted={true}
                        exact
                        path="/create-password"
                        component={CreatePassword}
                    />
                    <PublicRoute
                        restricted={true}
                        exact
                        path="/account-deleted"
                        component={DeleteAccount}
                    />
                    {/* Dashboard page */}
                    <PrivateRoute exact path="/home" component={Home} />

                    {/* Calendar page - Not in use */}
                    <Route exact path="/accdetails" component={AccDetails} />
                    <Route exact path="/accommodation" component={Accommodation} />
                    <Route
                        exact
                        path="/accommodation-dashboard"
                        component={AccommodationDashBoard}
                    />
                    <Route exact path="/accommodation-details" component={AccommodationDetails} />
                    <Route exact path="/accomodation-pricing" component={AccomodationPricing} />
                    <Route exact path="/activity" component={Activity} />
                    <Route exact path="/add-activity-option" component={AddActivityOption} />
                    <Route exact path="/add-transport-option" component={AddTransportOption} />

                    {/* Availability page */}
                    <PrivateRoute exact path="/archive-availability" component={Archive} />
                    <PrivateRoute exact path="/availability-data" component={AvailabilityData} />
                    {/* <Route exact path="/availability-data" component={AvailabilityData} /> */}
                    <PrivateRoute
                        exact
                        path="/availability-update/:id"
                        component={UpdateAvailability}
                    />

                    {/* <Route exact path="/bookings" component={Bookings} /> */}
                    <Route exact path="/booking-calendar" component={BookingCalendar} />

                    <PrivateRoute exact path="/calendar/:id" component={CalendarComponent} />
                    <PrivateRoute exact path="/customers-calendars" component={CustomerCalendars} />
                    <PrivateRoute exact path="/calendar-dashboard" component={CalendarDashboard} />
                    {/* <Route exact path="/calendar-title-length" component={CalendarTitleLength} /> */}
                    <PrivateRoute exact path="/get-started" component={CreateNewCalendar} />

                    {/* Not in use */}
                    <Route exact path="/availability" component={Availability} />
                    <Route exact path="/availability-sync" component={AvailabilitySync} />

                    <Route exact path="/create-calendar" component={CreateCalendar} />
                    <Route exact path="/covid19" component={Covid19} />

                    <Route exact path="/availability-sync" component={AvailabilitySync} />

                    <Route
                        exact
                        path="/edit-activity-option/:day/:activity"
                        component={EditActivityOption}
                    />
                    {/* Not in use */}
                    <Route exact path="/guide" component={GuideLanding} />
                    <Route exact path="/guide/details" component={GuideDetails} />
                    <Route exact path="/guide/other-vendor-text" component={GuideOtherVendorText} />

                    <Route exact path="/mainprouct" component={MainProduct} />
                    <Route exact path="/meals-and-drinks" component={MealsAndDrinksLanding} />
                    <Route
                        exact
                        path="/meals-and-drinks/choose-chef-service"
                        component={ChooseChefService}
                    />
                    <Route exact path="/other-vendor-details" component={OtherVendorDetails} />
                    <Route
                        exact
                        path="/other-vendor-details/add-vento-text"
                        component={AddVentoText}
                    />
                    <Route exact path="/personal-care" component={Personalcare} />
                    <Route
                        exact
                        path="/personal-care/add-wellness-service"
                        component={AddWellnessService}
                    />
                    <Route exact path="/pricing" component={Pricing} />
                    <Route exact path="/product-content" component={ProductContent} />
                    <Route
                        exact
                        path="/product-content/details"
                        component={ProductContentDetails}
                    />
                    <Route
                        exact
                        path="/product-content/details/itinerary"
                        component={ProductContentItinerary}
                    />
                    <Route exact path="/projectcategorization" component={ProjectCategorization} />
                    <Route exact path="/publish" component={Publish} />

                    <Route exact path="/review" component={Review} />
                    <Route exact path="/reviews" component={Reviews} />

                    <Route exact path="/sync-availability" component={Index} />
                    <Route
                        exact
                        path="/sync-availability/products"
                        component={SyncAvailabilityProducts}
                    />
                    <Route exact path="/transportation" component={Transportation} />

                    {/* Booking page */}
                    <PrivateRoute exact path="/bookings" component={BookingData} />
                    {/* Not in use */}
                    <Route exact path="/booking-calendar" component={BookingCalendar} />
                    <Route exact path="/download" component={Download} />
                    <Route exact path="/summary" component={Summary} />

                    {/* Performance page - Not in use */}
                    <Route exact path="/performance" component={Performance} />

                    {/* Customer Experence page - Not in use */}
                    <Route exact path="/customer-experience" component={CustomerExperience} />

                    {/* Finance page */}
                    <PrivateRoute exact path="/finance" component={Finance} />

                    {/*test - Not in use*/}
                    <Route exact path="/test" component={Test} />

                    {/* Account page */}
                    <PrivateRoute exact path="/account" component={Account} />

                    {/* Account Security page - Not in use */}
                    <Route exact path="/security" component={Security} />

                    {/* Language Settings page - Not in use */}
                    <Route exact path="/language-settings" component={LanguageSettings} />

                    {/* Manage Users page - Not in use */}
                    <Route exact path="/add-new-users" component={AddNewUser} />
                    <Route exact path="/manage-users" component={ManageUsers} />

                    {/* Product page - Not in use */}
                    <Route exact path="/activities-arrival" component={ActivitiesArrival} />
                    <Route exact path="/offer-prices" component={OfferPrices} />
                    <Route exact path="/product-page" component={ProductPage} />
                    <Route exact path="/product-reviews" component={ProductReviews} />
                    <Route exact path="/review-successful" component={ReviewSuccessful} />

                    {/* FAQ page */}
                    <Route exact path="/faqs" component={FAQ} />

                    {/* Privacy Policy page */}
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />

                    {/* Terms & Conditions */}
                    <Route exact path="/terms-conditions" component={Terms} />

                    <Route exact path="/plans" component={Plans} />

                    <Route exact path="/components" component={Components} />
                </Switch>
            </div>
            <Footer />
            <UserFeedback />
        </Router>
    );
};
Routes.propTypes = {
    setInitialAuth: PropTypes.func
};

const mapProps = (dispatch) => ({
    setInitialAuth: () => dispatch(setInitialAuth())
});

export default connect(null, mapProps)(Routes);

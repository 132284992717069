import { Form, Select } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const CustomerDemandModal = (props) => {
    const { Option } = Select;
    const { isModalOpen, setModalOpenState } = props;
    const [isMasked, setIsMasked] = useState(true);

    return (
        <Modal
            size="lg"
            centered
            scrollable
            isOpen={isModalOpen}
            toggle={() => {
                setModalOpenState(!isModalOpen);
            }}>
            <ModalHeader className="close-alt" toggle={() => setModalOpenState(!isModalOpen)}>
                Customer Demand
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
                {/* Filters */}
                <Row form>
                    <Col>
                        <Form.Item className="mb-2" name="select_month">
                            <Select
                                defaultValue="all_year"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="all_year">All Year</Option>
                                <Option value="jan">January</Option>
                                <Option value="feb">February</Option>
                                <Option value="march">March</Option>
                                <Option value="april">April</Option>
                                <Option value="may">May</Option>
                                <Option value="june">June</Option>
                                <Option value="july">July</Option>
                                <Option value="aug">August</Option>
                                <Option value="sept">September</Option>
                                <Option value="oct">October</Option>
                                <Option value="nov">November</Option>
                                <Option value="dec">December</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item className="mb-2" name="select_year">
                            <Select
                                defaultValue="2021"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="2021">2021</Option>
                                <Option value="2020">2020</Option>
                                <Option value="2019">2019</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item className="mb-2" name="select_calendar">
                            <Select
                                defaultValue="all"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="all">All Calendars</Option>
                                <Option value="calendar_title_1">Calendar Title 1</Option>
                                <Option value="calendar_title_2">Calendar Title 2</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Grid Table */}
                <div className="d-flex flex-column flex-grow-1 overflow-auto px-2 mx-n2">
                    <div
                        className="d-flex flex-column flex-grow-1 overflow-y-auto px-2 mx-n2"
                        style={{ minWidth: 820 }}>
                        {/* Grid headers */}
                        <Row className="mx-n2 grid-view-data-row grid-view-data-row--header">
                            <Col className="p-2">
                                <div className="fs-16 text-primary fw-600 mb-0">
                                    Calendar Length
                                </div>
                            </Col>
                            <Col className="p-2 text-center">
                                <div className="fs-16 text-primary fw-600 mb-0">
                                    Live calendars (%)
                                </div>
                                <small className="d-block fs-11 text-gray">
                                    Based on you live calendars
                                </small>
                            </Col>
                            <Col className="p-2 text-center" xs={3}>
                                <div className="fs-16 text-primary fw-600 mb-0">
                                    Customer Demand (%)
                                </div>
                                <small className="d-block fs-11 text-gray">
                                    Based on how long your customers need your calendar
                                </small>
                            </Col>
                            <Col className="p-2 text-center" xs={3}>
                                <span>&nbsp;</span>
                            </Col>
                        </Row>
                        {/* Grid body */}
                        <div className="d-flex flex-column flex-grow-1 overflow-y-auto position-relative mx-n2">
                            <div className="scrollbar-fixed overflow-y-auto px-2">
                                <Row noGutters>
                                    <Col xs={12}>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">
                                                    Less than 1 week
                                                </h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">50%</div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        20%
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <Button size="sm" color="danger">
                                                    Duplicate
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">1 week</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">n/a</div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        20%
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <Button size="sm" color="danger">
                                                    Duplicate
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">2 weeks</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">35%</div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        20%
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <Button size="sm" color="success">
                                                    Great Stuff
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">
                                                    More than 2 weeks
                                                </h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">15%</div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        20%
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <Button size="sm" color="light">
                                                    No Demand
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">
                                                    More than 3 weeks
                                                </h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">20%</div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        30%
                                                    </span>
                                                </div>
                                            </Col>
                                            <Col
                                                className={`p-2 text-center ${
                                                    isMasked ? 'masked-record' : ''
                                                }`}
                                                xs={3}>
                                                <Button size="sm" color="light">
                                                    No Demand
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>

                                    {isMasked ? (
                                        <Col className="view-data-column" xs={6}>
                                            <div
                                                className="view-data-column-content"
                                                style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => setIsMasked(false)}>
                                                    View Demand Projection
                                                </Button>
                                            </div>
                                        </Col>
                                    ) : null}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <span className="d-inline-block my-1 fs-12 fw-500">
                        Got a better idea of the demand on the lenght of your customers calendar?
                    </span>
                    <Button color="primary" className="btn-block btn-md-inline-block">
                        Create Calendar
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
CustomerDemandModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func
};
export default CustomerDemandModal;

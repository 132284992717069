import React from 'react';
import PropTypes from 'prop-types';
// import { callApi } from 'utils/apiCaller';
import { assignComponentPermission as rolesAndPermission } from 'utils/rolesandpermission/accessright';
import { liveComponents, developmentComponents } from 'utils/rolesandpermission/components';
import { SITE_ENV } from 'utils';
export const ComponentPermissionContext = React.createContext({
    assignComponentPermission: () => 'hidden-component-permission'
});

export default function RolesAndPermission({ children }) {
    const [allComponents, setAllComponents] = React.useState([]);
    React.useEffect(() => {
        //fetch all users components
        getUserPermission();
        document.querySelectorAll('view-only-component-permission').forEach((item) => {
            item.addEventListener('click', () => false);
        });
        return () =>
            document.querySelectorAll('view-only-component-permission').forEach((item) => {
                item.removeEventListener('click', () => false);
            });
    }, []);

    async function getUserPermission() {
        try {
            // const { data } = await callApi('/roles/get-access-details', 'GET');
            if (SITE_ENV == 'live') setAllComponents(liveComponents);
            else setAllComponents(developmentComponents);
        } catch (error) {
            alert(JSON.stringify(error));
        }
    }

    function getPermission(data) {
        let all = rolesAndPermission(data, allComponents);
        return all;
    }

    return (
        <ComponentPermissionContext.Provider
            value={{
                assignComponentPermission: getPermission
            }}>
            {children}
        </ComponentPermissionContext.Provider>
    );
}

RolesAndPermission.propTypes = {
    children: PropTypes.any
};

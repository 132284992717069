/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import Weather from '../../../../assets/images/weather.svg';
import { Col, Row, DatePicker } from 'antd';
import DownArrow from '../../../../assets/images/mdi_arrow_drop_down.svg';
import './index.scss';

function TravelPeriod() {
    const headerTitle = 'travel period';
    const [startDatePickerActive, setStartDatePickerActive] = useState(false);
    const [endDatePickerActive, setEndDatePickerActive] = useState(false);
    const triggerStartDatePicker = () => setStartDatePickerActive(!startDatePickerActive);
    const triggerEndDatePicker = () => setEndDatePickerActive(!endDatePickerActive);
    return (
        <div className="travel-period">
            <header className="travel-period__header">
                <h6>{headerTitle}</h6>
                <div className="travel-period__forecast">
                    <img src={Weather} alt="" />
                    <p>Weather Forecast</p>
                </div>
            </header>
            <div className="travel-period__body">
                <Row gutter={6}>
                    <Col span="12">
                        <div onClick={triggerStartDatePicker} className="travel-period__calender">
                            <div className="travel-period__calender-header">
                                <h6>START DATE</h6>
                                <img src={DownArrow} alt="" />
                            </div>
                            <DatePicker open={startDatePickerActive} bordered={false} />
                        </div>
                    </Col>
                    <Col span="12">
                        <div onClick={triggerEndDatePicker} className="travel-period__calender">
                            <div className="travel-period__calender-header">
                                <h6>END DATE</h6>
                                <img src={DownArrow} alt="" />
                            </div>
                            <DatePicker open={endDatePickerActive} bordered={false} />
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

TravelPeriod.propTypes = {};

export default TravelPeriod;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ClockIcon } from 'components/atoms';

const EventFacilities = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<ClockIcon />}
            panelTitle="Facilities"
            isEditable={true}
            onEdit={onEdit}>
            {product?.facilities ? (
                <>
                    {types.map((type, index) =>
                        !product?.facilities[type] ? (
                            <></>
                        ) : (
                            <Row
                                className="border-bottom border-primary-shaded py-2"
                                noGutters
                                key={index}>
                                <Col xs={6} md={4}>
                                    <div className="d-flex flex-nowrap">
                                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                            {typeString[type]}
                                        </span>
                                        <span className="fs-18 lh-1-7 mr-4">:</span>
                                    </div>
                                </Col>
                                <Col xs={6} md={8}>
                                    <span className="fs-18 lh-1-7">
                                        {product?.facilities[type].map(
                                            (item, index) =>
                                                `${item}${
                                                    index < product?.facilities[type].length - 1
                                                        ? ','
                                                        : ''
                                                } `
                                        )}
                                    </span>
                                </Col>
                            </Row>
                        )
                    )}
                </>
            ) : (
                <></>
            )}
        </DetailedPanel>
    );
};

EventFacilities.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventFacilities;

const types = ['general', 'cooking_and_cleaning', 'entertainment', 'outside_and_view'];

const typeString = {
    general: 'General',
    cooking_and_cleaning: 'Cooking and Cleaning',
    entertainment: 'Entertainment',
    outside_and_view: 'Outside and View'
};

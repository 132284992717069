import React from 'react';
import { Popover } from 'antd';

const EventInfoTravellersPopover = () => {
    return (
        <div className="d-inline-block mr-2">
            <Popover
                overlayClassName="popover-container"
                content={
                    <div>
                        <div className="fs-14 fw-400 mb-2">All Travellers</div>
                        <div className="d-flex flex-nowrap" style={{ gap: 4 }}>
                            <div>
                                <img
                                    className="calendar-user-img _mini"
                                    src={`https://randomuser.me/api/portraits/women/${12}.jpg`}
                                    alt="User"
                                />
                            </div>
                            <div>
                                <img
                                    className="calendar-user-img _mini"
                                    src={`https://randomuser.me/api/portraits/women/${13}.jpg`}
                                    alt="User"
                                />
                            </div>
                            <div>
                                <img
                                    className="calendar-user-img _mini"
                                    src={`https://randomuser.me/api/portraits/women/${14}.jpg`}
                                    alt="User"
                                />
                            </div>
                        </div>
                    </div>
                }
                placement={'top'}>
                <div className="calendar-user-img _mini _mask-only">+3</div>
            </Popover>
        </div>
    );
};

export default EventInfoTravellersPopover;

import React, { Component } from 'react';
import OtherVendorText from '../../../organisms/guide/other-vendor-text/index';
import VendorDashboard from '../vendor-dashboard';

export default class GuideOtherVendorText extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <VendorDashboard>
                <OtherVendorText />
            </VendorDashboard>
        );
    }
}

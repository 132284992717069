import React from 'react';
import { Container } from '@material-ui/core';
import VendorDashboard from '../vendor-dashboard';
import Button from '../pricing/DamiBtn.jsx';
import Success from '../../../../assets/images/success.svg';

export default function ReviewSuccessful() {
    return (
        <VendorDashboard>
            <Container
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '70vh',
                    background: 'rgba(202, 173, 46, 0.46)'
                }}>
                <div
                    style={{
                        width: '634px',
                        height: '304px',
                        background: '#FFFFFF',
                        borderRadius: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'space-evenly'
                    }}>
                    <div
                        style={{
                            marginTop: '-110px'
                        }}>
                        <img src={Success} alt="success-icon" />
                    </div>
                    <div
                        style={{
                            fontWeight: '500',
                            fontSize: '26px',
                            lineHeight: '32px',
                            /* identical to box height */

                            textAlign: 'center',
                            textTransform: 'capitalize',

                            color: '#4F4F4F'
                        }}>
                        Review Submitted Successfully
                    </div>
                    <div>
                        <Button
                            text="Update Customer Service"
                            customStyle={{
                                fontWeight: '600',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#FFFFFF'
                            }}
                        />
                    </div>
                </div>
            </Container>
        </VendorDashboard>
    );
}

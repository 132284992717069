import React from 'react';

export const angleIcon = (
    <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-4.62904e-07 1.41L4.58 6L-6.16331e-08 10.59L1.41 12L7.41 6L1.41 -6.1633e-08L-4.62904e-07 1.41Z"
                fill="#A2792C"
            />
        </svg>
    </span>
);
export const calendarData = [
    {
        key: '1',
        calendarThumbnail: 'wild-life.png',
        calendarTitle: 'Krugar National and Wild Life Forest Park',
        calendarID: 46745,
        starRating: 4.0,
        calendarStatus: 'Live',
        customerStatus: 'In Use',
        noOfCustomers: 4,
        calendarNextAction: 'Update'
    },
    {
        key: '2',
        calendarThumbnail: 'wild-life.png',
        calendarTitle: 'Krugar National and Wild Life Forest Park',
        calendarID: 46745,
        starRating: 4.0,
        calendarStatus: 'Draft',
        customerStatus: 'Not in use',
        noOfCustomers: 4,
        calendarNextAction: 'Finish Creating'
    },
    {
        key: '3',
        calendarThumbnail: 'wild-life.png',
        calendarTitle: 'Krugar National and Wild Life Forest Park',
        calendarID: 46745,
        starRating: 4.0,
        calendarStatus: 'Live',
        customerStatus: 'Not in use',
        noOfCustomers: 4,
        calendarNextAction: 'Update'
    },
    {
        key: '4',
        calendarThumbnail: 'wild-life.png',
        calendarTitle: 'Krugar National and Wild Life Forest Park',
        calendarID: 46745,
        starRating: 4.0,
        calendarStatus: 'Live',
        customerStatus: 'Not in use',
        noOfCustomers: 4,
        calendarNextAction: 'Update'
    }
];

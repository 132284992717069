const progressReducer = (state = 10, action) => {
    switch (action.type) {
        case 'PROGRESS':
            return state + 10;
        default:
            return state;
    }
};

export default progressReducer;

import React from 'react';

const CheckCircleIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 11.5L10 14.5L15 8.5"
                stroke="#06B706"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                stroke="#06B706"
                strokeWidth="2"
            />
        </svg>
    );
};

export default CheckCircleIcon;

import { callApi } from '../../utils/apiCaller';
import {
    GET_VENDOR_SUBSCRIPTION,
    INITIALIZE_SUBSCRIPTION,
    TOP_UP_PAY_NOW,
    GET_ALL_PLANS,
    TOOGLE_UPGRADE_PLAN_DRAWER,
    UPDATE_VENDOR_SUBSCRIPTION,
    GET_UPCOMING_SUBSCRIPTION,
    SHOW_DRAFT_CALENDAR_POPUP,
    ALL_LIVE_CALENDARS,
    GET_USER_CARDS,
    PREFERED_DISBALE_CALENDARS,
    INITIALIZE_TOKEN_TOP_UP,
    ACC_LOADING,
    DOWNGRADE_TO_FREE,
    All_TRAVELLERS
} from '../types/accessCalendarTypes';
import { Notification } from '../../components/atoms/notification';
import { elseNotifyError } from 'store/actions';
import { queryBuilder } from 'utils';

export const accLoading_ = (payload) => ({
    type: ACC_LOADING,
    payload
});

export const toggleAccessCalendarModal = (payload) => {
    return {
        type: 'TOGGLE_ACCESS_CALENDAR_MODAL',
        payload: payload
    };
};

export const toggleAddTopUp = (payload) => {
    return {
        type: 'TOGGLE_ADD_TOP_UP',
        payload: payload
    };
};

export const setTotalRevenu = (payload) => {
    return {
        type: 'SET_TOTAL_REVENU',
        payload: payload
    };
};

export const setCalendarDate = (payload) => {
    return {
        type: 'SET_CALENDAR_DATE',
        payload: payload
    };
};

export const reduceToken = (payload) => {
    return {
        type: 'REDUCED_TOKENS',
        payload: payload
    };
};

export const setCurrentPlan = (payload) => {
    return {
        type: 'SET_CURRENT_PLAN',
        payload: payload
    };
};
export const reduceLiveCalendar = () => {
    return {
        type: 'REDUCE_LIVE_CALENDAR'
    };
};

export const tgleAddTopUpPopover = (payload) => {
    return {
        type: 'TOGGLE_ADD_TOP_UP_POPOVER',
        payload: payload
    };
};

export const tgleUpgradePlanPopover = (payload) => {
    return {
        type: 'TOGGLE_UPGRADE_PLAN_POPOVER',
        payload: payload
    };
};

export const toggleUpgradePlan = (payload) => {
    return {
        type: 'TOGGLE_UPGRADE_PLAN',
        payload: payload
    };
};

export const toggleCheckPaymentInfo = (payload) => {
    return {
        type: 'TOGGLE_CHECK_PAYMENT_INFO',
        payload: payload
    };
};

export const getVendorSubscription_ = (payload) => {
    return {
        type: GET_VENDOR_SUBSCRIPTION,
        payload
    };
};

export const topUpPayNow_ = (payload) => {
    return {
        type: TOP_UP_PAY_NOW,
        payload: payload
    };
};

export const getAllPlans_ = (payload) => {
    return {
        type: GET_ALL_PLANS,
        payload
    };
};

export const getUpcomingSubscription_ = (payload) => {
    return {
        type: GET_UPCOMING_SUBSCRIPTION,
        payload
    };
};
export const initailizeSubscription_ = (payload) => {
    return {
        type: INITIALIZE_SUBSCRIPTION,
        payload
    };
};
export const initailizeTokenTopUp_ = (payload) => {
    return {
        type: INITIALIZE_TOKEN_TOP_UP,
        payload
    };
};

export const clearTopInit = () => {
    return {
        type: INITIALIZE_TOKEN_TOP_UP,
        payload: {}
    };
};
export const getUserCards_ = (payload) => {
    return {
        type: GET_USER_CARDS,
        payload
    };
};
export const toggleUpgradePlanDrawer = (payload) => {
    return {
        type: TOOGLE_UPGRADE_PLAN_DRAWER,
        payload: payload
    };
};

export const changeVendorSubscription_ = (payload) => {
    return {
        type: UPDATE_VENDOR_SUBSCRIPTION,
        payload: payload
    };
};

export const fetchAllLiveCalendars_ = (payload) => {
    return {
        type: ALL_LIVE_CALENDARS,
        payload
    };
};

export const toogleDisableCalendarModal = (payload) => ({
    type: SHOW_DRAFT_CALENDAR_POPUP,
    payload
});

export const fetchPreferedDisableCalendar_ = (payload) => ({
    type: PREFERED_DISBALE_CALENDARS,
    payload
});

export const downgradeToFreePlan_ = (payload) => ({
    type: DOWNGRADE_TO_FREE,
    payload
});

export const allTravelers_ = (payload) => ({ type: All_TRAVELLERS, payload });

/**
 * Fetch vendor subscription details
 */
export const getVendorSubscription = () => async (dispatch) => {
    let response = await callApi(`/subscription/vendor-plan`, 'GET');
    const { status } = response;

    if (status === 200) {
        dispatch(getVendorSubscription_(response.data?.data?.vendorSubscription));
        dispatch(getUpcomingSubscription_(response.data?.data?.upcomingSubscription));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * To top-up vendor tokens
 *
 * @param {Number} numberOfCustomers
 */
export const topUpPayNow = (numberOfCustomers) => async (dispatch) => {
    let response = await callApi(`/subscription/topup`, 'PUT', {
        number_of_tokens: numberOfCustomers
    });
    const { status } = response;

    if (status === 200) {
        dispatch(topUpPayNow_(response.data?.data?.available_tokens));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

export const initailizeSubscription = (payload) => (dispatch) => {
    callApi('/subscription-init', 'POST', payload)
        .then(({ data }) => {
            dispatch(initailizeSubscription_(data?.data?.charge));
        })
        .catch((error) => {
            console.log('error:', error);
            Notification('error', error.message, 'error');
        });
};

export const downgradeToFreePlan = (payload) => async (dispatch) => {
    await callApi('/subscription/downgrade-to-free', 'POST', payload)
        .then(({ data }) => dispatch(downgradeToFreePlan_(data)))
        .catch((error) => {
            console.log('error:', error);
            Notification('error', error.message, 'error');
        });
};

export const initailizeTokenTopUp = (payload) => (dispatch) => {
    callApi('/init-token-top-up', 'POST', payload)
        .then(({ data }) => {
            dispatch(initailizeTokenTopUp_(data?.charge));
        })
        .catch((error) => {
            console.log('error:', error);
            Notification('error', 'Payment failed');
        });
};

export const getUserCards = () => (dispatch) => {
    callApi('/list-card?perPage=3', 'GET')
        .then((response) => {
            if (response.data.cards) {
                dispatch(getUserCards_(response.data.cards.data));
            } else {
                Notification('error', 'Unable to get user cards');
            }
        })
        .catch((error) => {
            console.log('error:', error);
            Notification('error', 'Unable to get user cards');
        });
};
/**
 * Fetch all plans
 */
export const getAllPlans = () => async (dispatch) => {
    let response = await callApi(`/subscription/all-plans`, 'GET');
    const { status } = response;

    if (status === 200) {
        dispatch(getAllPlans_(response.data?.data?.plans));
        dispatch(getUpcomingSubscription_(response.data?.data?.upcomingSubscription));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * Change vendor subscription
 */
export const changeVendorSubscription = (planId, selectedCalendarIds) => async (dispatch) => {
    let response = await callApi(`/subscription/change-plan`, 'PUT', {
        plan_id: planId,
        selected_calendar_ids: selectedCalendarIds
    });
    const { status } = response;

    if (status === 200) {
        dispatch(changeVendorSubscription_(response.data?.data?.vendorSubscription));
        dispatch(getUpcomingSubscription_(response.data?.data?.upcomingSubscription));
        dispatch(getAllPlans());
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * Fetch vendor subscription details
 */
export const fetchAllLiveCalendars = () => async (dispatch) => {
    let response = await callApi(`/calendars/fetch/live`, 'GET');
    const { status } = response;

    if (status === 200) {
        dispatch(fetchAllLiveCalendars_(response.data?.data));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * Fetch vendor subscription details
 */
export const cancelDowngrade = () => async (dispatch) => {
    let response = await callApi(`/subscription/cancel-downgrade`, 'DELETE');
    const { status } = response;

    if (status === 200) {
        dispatch(getUpcomingSubscription_({}));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * Fetch vendor subscription details
 */
export const fetchPreferedDisableCalendar = () => async (dispatch) => {
    let response = await callApi(`/subscription/prefered-disable-calendars`, 'GET');
    const { status } = response;

    if (status === 200) {
        const preferences =
            response.data?.data && response.data?.data?.disableCalendarPreferences
                ? response.data?.data?.disableCalendarPreferences
                : [];
        dispatch(fetchPreferedDisableCalendar_(preferences));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * Change vendor subscription
 */
export const changePreferences = (subscriptionId, selectedCalendarIds) => async (dispatch) => {
    let response = await callApi(`/subscription/change-preference`, 'PUT', {
        subscription_id: subscriptionId,
        selected_calendar_ids: selectedCalendarIds
    });
    const { status } = response;

    if (status === 200) {
        dispatch(fetchPreferedDisableCalendar_(response.data?.data));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

/**
 * toggle main price display
 */
export const toggleMainProductPrice =
    ({ id, display }) =>
    async (dispatch) => {
        const payload = display ? 'show' : 'hide';
        let response = await callApi(`/calendars/toggle-main-product/${id}/${payload}`, 'PUT');
        const { status } = response;

        if (status === 200) {
            dispatch(toggleCheckPaymentInfo(payload === 'show' ? true : false));
        } else {
            if (
                response?.status &&
                Array.isArray(response?.data?.errors) &&
                response?.data?.errors.length
            ) {
                return Notification('error', response.data.errors[0].message, status);
            } else {
                return Notification('error', response.data.message, status);
            }
        }
    };

export const getProductAvailability = (body) => {
    return (dispatch) => {
        dispatch(accLoading_(true));
        return callApi(`/check-availability`, 'POST', body)
            .then((response) => {
                const status = response?.status;
                if (status === 200) {
                    return response?.data?.data || [];
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(accLoading_(false)));
    };
};

export const sendEmail = (data) => {
    return (dispatch) => {
        dispatch(accLoading_(true));
        return callApi(`/send-email`, 'POST', data)
            .then((response) => {
                const status = response?.status;
                if (status === 200) {
                    Notification('success', 'Email sent successfully', 200);
                    return true;
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(accLoading_(false)));
    };
};

export const encryptUrl = (string) => {
    return () => {
        return callApi(`/encrypt-data?${string}`, 'POST').then((response) => {
            const status = response?.status;
            if (status === 200) {
                return response?.data.data;
            } else elseNotifyError(response, status);
        });
    };
};

export const getAllTravellersAction = () => async (dispatch) => {
    return callApi('/customer/travellers', 'GET')
        .then(({ data }) => dispatch(allTravelers_(data?.data)))
        .catch((error) => {
            Notification('error', error.message, 'error');
        });
};

export const updateAllTravellers = (data) => async (dispatch) => dispatch(allTravelers_(data));

import React from 'react';

const FilterIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.4714 0H1.52939C0.925972 0 0.571262 0.680076 0.917004 1.17494C0.92366 1.18438 0.712134 0.896321 5.85538 7.89717C5.97075 8.06411 6.03172 8.25945 6.03172 8.46244V15.2097C6.03172 15.8658 6.78386 16.2277 7.29388 15.8418L9.48613 14.1892C9.78863 13.9646 9.96912 13.6057 9.96912 13.2289V8.46244C9.96912 8.25945 10.0301 8.06411 10.1455 7.89717C15.2847 0.901696 15.0772 1.18431 15.0838 1.17494C15.4294 0.680295 15.0751 0 14.4714 0V0ZM9.38632 7.34703C9.1738 7.63631 9.03165 8.03964 9.03165 8.46241V13.2288C9.03165 13.3105 8.99243 13.3883 8.92681 13.4368C8.86909 13.4793 9.30623 13.1508 6.9692 14.9125V8.46244C6.9692 8.06476 6.84879 7.68221 6.62102 7.35616C6.61536 7.34806 6.77408 7.56425 3.39791 2.96871H12.6029L9.38632 7.34703ZM13.2916 2.0312H2.70921L1.9057 0.937476H14.0951L13.2916 2.0312Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default FilterIcon;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse as RsCollapse } from 'reactstrap';

const CollapseMoreLess = (props) => {
    const [showMoreContent, setShowMoreContent] = useState(false);
    return (
        <>
            <RsCollapse isOpen={showMoreContent}>{props.children}</RsCollapse>

            <div className="mt-2">
                <Button
                    color="link"
                    className="p-0 ff-special fw-500"
                    onClick={() => setShowMoreContent(!showMoreContent)}>
                    <i>View {showMoreContent ? 'Less' : 'More'}</i>{' '}
                    <span>
                        <svg
                            width="13"
                            height="9"
                            viewBox="0 0 13 9"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                                transform: `rotate(${showMoreContent ? '180deg' : 0})`
                            }}>
                            <path
                                d="M10.7463 0.943359L6.17235 5.61138L1.59842 0.943359L0.193359 2.38046L6.17235 8.49577L12.1513 2.38046L10.7463 0.943359Z"
                                fill="#A3792D"
                            />
                        </svg>
                    </span>
                </Button>
            </div>
        </>
    );
};
CollapseMoreLess.propTypes = {
    children: PropTypes.node
};
export default CollapseMoreLess;

import React from 'react';

const ErrorIcon = () => {
    return (
        <svg
            width="90"
            height="86"
            viewBox="0 0 90 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M88.1301 65.4911L56.3375 7.04807C51.2294 -1.55133 38.7776 -1.56275 33.6627 7.04807L1.87164 65.4911C-3.35011 74.2782 2.97186 85.4055 13.2064 85.4055H76.7928C87.0187 85.4055 93.3519 74.2872 88.1301 65.4911ZM45.0001 74.8587C42.093 74.8587 39.7267 72.4923 39.7267 69.5852C39.7267 66.6782 42.093 64.3118 45.0001 64.3118C47.9072 64.3118 50.2735 66.6782 50.2735 69.5852C50.2735 72.4923 47.9072 74.8587 45.0001 74.8587ZM50.2735 53.7649C50.2735 56.672 47.9072 59.0384 45.0001 59.0384C42.093 59.0384 39.7267 56.672 39.7267 53.7649V27.3977C39.7267 24.4907 42.093 22.1243 45.0001 22.1243C47.9072 22.1243 50.2735 24.4907 50.2735 27.3977V53.7649Z"
                fill="#EAA948"
            />
        </svg>
    );
};

export default ErrorIcon;

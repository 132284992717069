import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Panel = (props) => {
    const { title, badge, footer, titleInfo, hasRecommendedBadge } = props;
    return (
        <div className="atc-panel">
            <div className="atc-panel__header">
                <div className="atc-panel__header-title">
                    {title}
                    <span>{titleInfo}</span>
                </div>
                {hasRecommendedBadge ? (
                    <div className="atc-panel__header-badge">
                        <p>RECOMMENDED</p>
                    </div>
                ) : (
                    badge
                )}
            </div>
            <div className="atc-panel__footer">{footer}</div>
        </div>
    );
};

Panel.propTypes = {
    badge: PropTypes.node,
    footer: PropTypes.node,
    title: PropTypes.node,
    titleInfo: PropTypes.string,
    hasRecommendedBadge: PropTypes.bool
};

export default Panel;

import React from 'react';
import { Grid } from '@material-ui/core';

export default function Legend(props) {
    return (
        <Grid container>
            {props.items.map((each, index) => (
                <Grid key={index} item>
                    <div
                        style={{
                            fontWeight: 'normal',
                            fontSize: '10px',
                            lineHeight: '21px',
                            /* or 210% */
                            letterSpacing: '0.1px',
                            color: '#333333',
                            display: 'flex',
                            alignItems: 'center',
                            marginRight: '9px'
                        }}>
                        <span
                            style={{
                                width: '10px',
                                height: '10px',
                                backgroundColor: each.color,
                                display: 'inline-block',
                                marginRight: '5px'
                            }}></span>
                        <span>{each.text}</span>
                    </div>
                </Grid>
            ))}
        </Grid>
    );
}

import { Form, Select } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

const CalendarSalesModal = (props) => {
    const { Option } = Select;
    const { isModalOpen, setModalOpenState } = props;
    const [isMasked, setIsMasked] = useState(true);

    return (
        <Modal
            size="lg"
            centered
            scrollable
            isOpen={isModalOpen}
            toggle={() => {
                setModalOpenState(!isModalOpen);
            }}>
            <ModalHeader className="close-alt" toggle={() => setModalOpenState(!isModalOpen)}>
                Calendar Sales
            </ModalHeader>
            <ModalBody className="d-flex flex-column">
                {/* Filters */}
                <Row form>
                    <Col>
                        <Form.Item className="mb-2" name="select_sales_month">
                            <Select
                                defaultValue="all_year"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="all_year">All Year</Option>
                                <Option value="jan">January</Option>
                                <Option value="feb">February</Option>
                                <Option value="march">March</Option>
                                <Option value="april">April</Option>
                                <Option value="may">May</Option>
                                <Option value="june">June</Option>
                                <Option value="july">July</Option>
                                <Option value="aug">August</Option>
                                <Option value="sept">September</Option>
                                <Option value="oct">October</Option>
                                <Option value="nov">November</Option>
                                <Option value="dec">December</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item className="mb-2" name="select_sales_year">
                            <Select
                                defaultValue="2021"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="2021">2021</Option>
                                <Option value="2020">2020</Option>
                                <Option value="2019">2019</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item className="mb-2" name="select_all_products_category">
                            <Select
                                defaultValue="all"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="all">All Products Category</Option>
                                <Option value="activity">Activity</Option>
                                <Option value="personal_care">Personal Care</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item className="mb-2" name="select_all_products_sub_category">
                            <Select
                                defaultValue="all"
                                className="form-control-alt d-block"
                                dropdownClassName="type-rich"
                                size="large">
                                <Option value="all">All Products Sub-Category</Option>
                                <Option value="activity">Activity</Option>
                                <Option value="personal_care">Personal Care</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Grid Table */}
                <div className="d-flex flex-column flex-grow-1 overflow-auto px-2 mx-n2">
                    <div
                        className="d-flex flex-column flex-grow-1 overflow-y-auto px-2 mx-n2"
                        style={{ minWidth: 675 }}>
                        {/* Grid headers */}
                        <Row className="mx-n2 grid-view-data-row grid-view-data-row--header">
                            <Col className="p-2">
                                <div className="fs-16 text-primary fw-600 mb-0">
                                    Product Category
                                </div>
                            </Col>
                            <Col className="p-2 text-center">
                                <div className="fs-16 text-primary fw-600 mb-0">Calendar Sales</div>
                                <small className="d-block fs-11 text-gray">
                                    Based on what your customers has purchased
                                </small>
                            </Col>
                            <Col className="p-2 text-center" xs={4}>
                                <div className="fs-16 text-primary fw-600 mb-0">
                                    Product Category
                                </div>
                                <small className="d-block fs-11 text-gray">
                                    Based on what your customers has to spend
                                </small>
                            </Col>
                        </Row>
                        {/* Grid body */}
                        <div className="d-flex flex-column flex-grow-1 overflow-y-auto position-relative mx-n2">
                            <div className="scrollbar-fixed overflow-y-auto px-2">
                                <Row noGutters>
                                    <Col xs={12}>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">Accomodation</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$15000</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $12000
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">Itinerary</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$1000</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $2000
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">Personal care</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$5000</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $2000
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">
                                                    Transportation
                                                </h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$3500</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $3200
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">Accomodation</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$15000</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $12000
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">Itinerary</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$1000</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $2000
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">Personal care</h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$5000</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $2000
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="mx-n2 grid-view-data-row">
                                            <Col className="p-2">
                                                <h6 className="fs-16 fw-400 mb-0">
                                                    Transportation
                                                </h6>
                                            </Col>
                                            <Col className="p-2 text-center">
                                                <div className="fs-16 fw-600">$3500</div>
                                            </Col>
                                            <Col className="p-2 text-center" xs={4}>
                                                <div className="fs-16 fw-600">
                                                    <span
                                                        className={isMasked ? 'masked-record' : ''}>
                                                        $3200
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    {isMasked ? (
                                        <Col className="view-data-column" xs={4}>
                                            <div
                                                className="view-data-column-content"
                                                style={{ paddingLeft: 20, paddingRight: 20 }}>
                                                <Button
                                                    size="sm"
                                                    color="primary"
                                                    onClick={() => setIsMasked(false)}>
                                                    View Avg. Cus. Budget
                                                </Button>
                                            </div>
                                        </Col>
                                    ) : null}
                                </Row>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center mt-3">
                    <span className="d-inline-block my-1 fs-12 fw-500">
                        Got a better idea of your customers product spend?
                    </span>
                    <Button color="primary" className="btn-block btn-md-inline-block">
                        Add Products
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

CalendarSalesModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func
};

export default CalendarSalesModal;

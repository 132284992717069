import React, { Component } from 'react';
import { Form, Drawer } from 'antd';
import { Button, Col, Row } from 'reactstrap';

import './index.scss';
import Spacer from '../../../atoms/spacer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import DropdownSelectButton from '../../../atoms/dropdown-select-button';
import { HelpIcon } from 'assets/index';
import ImportItineraries from '../../../atoms/importItineraries';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { getAdventureCategories } from 'store/actions/calendarActions';
import { compareAdventureInformation } from 'utils';

class AddAdventure extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            isAddAdventure: false,
            isAdded: null,
            data: {},
            saveCategory: [],
            selectedAdventures: {} //new code
        };
    }
    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.data !== data && data !== null)
            this.setState({
                data: this.getCategoriesObjectFromArray(data),
                selectedAdventures: this.getCategoriesObjectFromArray(data)
            });

        if (
            this.formRef.current &&
            prevState.selectedAdventures &&
            Object.keys(prevState.selectedAdventures) !== Object.keys(this.state.selectedAdventures)
        )
            this.formRef.current.validateFields();
    }

    componentDidMount() {
        const { getAdventureCategories, adventureCategories, data } = this.props;
        if (Array.isArray(adventureCategories) && adventureCategories.length < 1)
            getAdventureCategories();

        if (data !== null)
            this.setState({
                data: this.getCategoriesObjectFromArray(data),
                selectedAdventures: this.getCategoriesObjectFromArray(data)
            });
    }

    getCategoriesObjectFromArray(array) {
        let rawData = {};
        if (Array.isArray(array))
            for (let value of array) {
                rawData[value.category] = value.sub_category;
            }
        return rawData;
    }
    //saving adventures
    onSaveData = (saveData, category) => {
        const saveCategory = [...this.state.saveCategory];
        const selectedAdventures = { ...this.state.selectedAdventures };
        if (saveData?.length > 0) {
            //new code
            selectedAdventures[category] = [...saveData];

            const isAdd = saveCategory.findIndex((item) => item === category);
            let newSaveCategory = [...saveCategory];
            if (isAdd === -1) {
                newSaveCategory = [...saveCategory, category];
            }
            this.setState({
                saveCategory: newSaveCategory,
                data: { ...selectedAdventures },
                selectedAdventures
            });
        } else if (saveData?.length === 0) {
            //new code
            delete selectedAdventures[category];

            const isAdd = saveCategory.findIndex((item) => item === category);
            if (isAdd !== -1) {
                const tmp = [...saveCategory];
                tmp.splice(isAdd, 1);
                this.setState({
                    saveCategory: [...tmp],
                    data: selectedAdventures,
                    selectedAdventures
                });
            } else {
                this.setState({
                    data: selectedAdventures,
                    selectedAdventures
                });
            }
        }
    };
    setImportData = (data) => {
        if (data.adventures)
            this.setState({ data: data.adventures, selectedAdventures: data.adventures });
    };

    onClose = () => {
        this.props.onClose();
    };

    //new code
    onSave = () => {
        const { onSave } = this.props;
        const { selectedAdventures } = this.state;
        onSave(selectedAdventures);
        this.onClose();
    };

    toggleAdventure = () => this.setState({ isAddAdventure: !this.state.isAddAdventure });

    onFinish = () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.onSave();
    };

    compareData = () => {
        const stateData = { ...this.state.selectedAdventures };
        let oldData = this.props?.data ? [...this.props?.data] : [];
        let newData = [];
        if (stateData && Object.keys(stateData).length > 0) {
            Object.keys(stateData).forEach((item) => {
                let obj = {
                    category: item,
                    sub_category: stateData[item]
                };
                newData.push(obj);
            });
        }
        if (compareAdventureInformation(oldData, newData)) {
            this.setState({ confirmationModal: true });
        } else {
            this.onClose();
            //this.onSave();
        }
    };

    render() {
        const { requiredMark, data } = this.state;
        const { validateRequiredFormField, adventureCategories } = this.props;

        return (
            // <div className="itenary-form">
            <Drawer
                className="application add-adventure"
                onClose={() => {
                    this.onClose();
                }}
                visible={this.props.isOpen}
                bodyStyle={{ paddingBottom: 35, paddingTop: 25 }}>
                <Form
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    layout="vertical"
                    initialValues={{
                        requiredMark
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}
                    className="h-100 d-flex flex-column overflow-y-auto">
                    <div className="flex-grow-1 overflow-y-auto px-3 mx-n3">
                        <h6 className="title">Add adventure in this product</h6>

                        {this.props.hideImport ? (
                            ''
                        ) : (
                            <ImportItineraries
                                calendars={this.props.itineraries}
                                import="I will import adventure information from my other activity"
                                onMainProduct={(item) => this.setImportData(item)}
                            />
                        )}

                        <Spacer top={13} />

                        <p className="subtitle">
                            Select the adventures your customers will experience on this product.
                            Please note that this is what your customers will search for when
                            booking your product.
                        </p>

                        <div>Select Adventure</div>
                        <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed">
                            <Form.Item
                                name="selectedAdventures"
                                rules={[
                                    {
                                        validator: () => {
                                            return validateRequiredFormField({
                                                data: this.state.selectedAdventures,
                                                fieldType: 'OBJECT'
                                            });
                                        }
                                    }
                                ]}>
                                <Spacer top={10} />
                                <Row className="row-cols-2" form>
                                    {Array.isArray(adventureCategories) &&
                                        adventureCategories.map((value, ind) => (
                                            <Col key={ind}>
                                                <DropdownSelectButton
                                                    onChangeData={(data) =>
                                                        this.onSaveData(data, value.name)
                                                    }
                                                    preData={data[value.name] || []}
                                                    data={value.children}
                                                    name={value.name}
                                                />
                                            </Col>
                                        ))}
                                    {/* <DropdownSelectButton
                                    onChangeData={(data) =>
                                        this.onSaveData(data, 'Arts and Crafts')
                                    }
                                    preData={data['Arts and Crafts'] || []}
                                    data={adventures['Arts and Crafts']}
                                    name="Arts and Crafts"
                                    isLeft={true}
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) => this.onSaveData(data, 'River Cruises')}
                                    preData={data['River Cruises'] || []}
                                    data={adventures['River Cruises']}
                                    name="River Cruises"
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) =>
                                        this.onSaveData(data, 'Sporting Days out')
                                    }
                                    preData={data['Sporting Days out'] || []}
                                    data={adventures['Sporting Days out']}
                                    name="Sporting Days out"
                                    isLeft={true}
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) =>
                                        this.onSaveData(data, 'Shows and Performance')
                                    }
                                    preData={data['Shows and Performance'] || []}
                                    data={adventures['Shows and Performance']}
                                    name="Shows and Performance"
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) =>
                                        this.onSaveData(data, 'Outdoor adventure')
                                    }
                                    preData={data['Outdoor adventure'] || []}
                                    data={adventures['Outdoor adventure']}
                                    name="Outdoor adventure"
                                    isLeft={true}
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) =>
                                        this.onSaveData(data, 'Water Adventure')
                                    }
                                    preData={data['Water Adventure'] || []}
                                    data={adventures['Water Adventure']}
                                    name="Water Adventure"
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) => this.onSaveData(data, 'Food and Drink')}
                                    preData={data['Food and Drink'] || []}
                                    data={adventures['Food and Drink']}
                                    name="Food and Drink"
                                    isLeft={true}
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) =>
                                        this.onSaveData(data, 'Health and Beauty')
                                    }
                                    preData={data['Health and Beauty'] || []}
                                    data={adventures['Health and Beauty']}
                                    name="Health and Beauty"
                                />
                                <DropdownSelectButton
                                    onChangeData={(data) => this.onSaveData(data, 'Occasions')}
                                    preData={data.Occasions || []}
                                    data={adventures.Occasions}
                                    name="Occasions"
                                    isLeft={true}
                                /> */}
                                </Row>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="footer-btn">
                        <div>
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            <Button
                                color="dark"
                                style={{ marginLeft: '10px' }}
                                onClick={() => this.onClose()}
                                type="button">
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark">
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </Form>

                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.onSave();
                    }}
                />
            </Drawer>
            // </div>
        );
    }
}
AddAdventure.propTypes = {
    data: Proptypes.any,
    onChangeCategory: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    hideImport: Proptypes.bool,
    onSave: Proptypes.func,
    validateRequiredFormField: Proptypes.func,
    selected_existing_product: Proptypes.bool,
    adventureCategories: Proptypes.array,
    getAdventureCategories: Proptypes.func
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product,
    adventureCategories: state.calendar.adventureCategories
});

const mapDispatch = (dispatch) => ({
    getAdventureCategories: () => dispatch(getAdventureCategories())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddAdventure));

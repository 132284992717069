import React from 'react';
import { Row, Button, Col } from 'reactstrap';
import bulletIcon from '../../../assets/images/list_bullet_triangular.png';
import { useDispatch } from 'react-redux';
import {
    tgleAddTopUpPopover,
    tgleUpgradePlanPopover,
    toggleAddTopUp,
    toggleUpgradePlan,
    toggleUpgradePlanDrawer
} from '../../../store/actions/accessCalendar';
import { Popover } from 'antd';
import successIcon from '../../../assets/images/success.svg';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PlanList = (props) => {
    const dispatch = useDispatch();

    if (props.planList != null) {
        var list = props.planList.map(function (planListItem, i) {
            return (
                <p key={i}>
                    <img className="pr-2 " src={bulletIcon} alt="" />
                    {planListItem.text}
                </p>
            );
        });
    }
    /* according to button title it opens respective drawer */
    const handleButtonClick = (btnTitle) => {
        switch (btnTitle) {
            case 'Upgrade Plan':
                dispatch(toggleUpgradePlan(true));
                break;

            case 'ADD TOP-UP':
                dispatch(toggleAddTopUp(true));
                break;
            default:
                break;
        }
    };

    /* close popover by passing value to reducer according to props type */
    const handleVisibleChange = () => {
        if (props.type == 'Upgrade-Plan') {
            dispatch(tgleUpgradePlanPopover(false));
        } else {
            dispatch(tgleAddTopUpPopover(false));
        }
    };

    /* Set Popover content according to props */
    const popupContent = (
        <div className="text-center">
            <img src={successIcon} alt="" />
            <p
                className=""
                dangerouslySetInnerHTML={{
                    __html: props.upgradePopupContent || props.addTopUpPopupContent
                }}></p>
        </div>
    );

    return (
        <div>
            <Row className="planlist-container">
                <Col md="7" style={{ color: props.titleColor }} className="planListLeftDiv">
                    {props.type == 'Upgrade-Plan' ? (
                        /* popover according to type */
                        <Popover
                            placement="bottom"
                            visible={props.toggleUpgradePlanPopover}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            content={popupContent}
                            onVisibleChange={handleVisibleChange}
                            trigger="click">
                            <span>{props.title1}</span>
                            <span style={{ fontWeight: props.titleWeight }}>{props.title2}</span>
                        </Popover>
                    ) : (
                        <Popover
                            placement="bottom"
                            className="custom-popover"
                            visible={props.toggleAddTopUpPopover}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            content={popupContent}
                            onVisibleChange={handleVisibleChange}
                            trigger="click">
                            <span>{props.title1}</span>
                            <span style={{ fontWeight: props.titleWeight }}>{props.title2}</span>
                        </Popover>
                    )}
                </Col>
                <Col md="5" className="plan-list-btn-align">
                    {props.btnTitle == 'Change Plan' ? (
                        <Button
                            color="primary-alt"
                            size="sm"
                            onClick={() => props.toggleUpgradePlanDrawer(true)}>
                            Change Plan
                        </Button>
                    ) : (
                        <Button
                            color="primary-alt"
                            size="sm"
                            onClick={() => handleButtonClick(props.btnTitle)}>
                            {props.btnTitle}
                        </Button>
                    )}
                </Col>
            </Row>
            <div className="plan-list-div pt-1 font-weight-bold">{list}</div>
        </div>
    );
};

PlanList.propTypes = {
    type: PropTypes.string.isRequired,
    planList: PropTypes.array,
    titleColor: PropTypes.string,
    titleWeight: PropTypes.string,
    title1: PropTypes.string.isRequired,
    title2: PropTypes.any.isRequired,
    btnTitle: PropTypes.string.isRequired,
    upgradePopupContent: PropTypes.string,
    addTopUpPopupContent: PropTypes.string,
    toggleUpgradePlanPopover: PropTypes.bool,
    toggleAddTopUpPopover: PropTypes.bool,
    toggleUpgradePlanDrawer: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    toggleAddTopUpPopover: state.accessCalendarReducer.toggleAddTopUpPopover,
    toggleUpgradePlanPopover: state.accessCalendarReducer.toggleUpgradePlanPopover
});

const mapDispatchToProps = (dispatch) => {
    return {
        toggleUpgradePlanDrawer: (value) => dispatch(toggleUpgradePlanDrawer(value))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanList);

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Button, Row } from 'reactstrap';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Proptypes from 'prop-types';
import Delete from '../../../assets/images/delete.svg';
import { ConfirmationModal } from 'components/molecules';
export default function AddTopSightsButton(props) {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        <img src={MoreInfo} alt="" />
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data && props.data.length > 0 ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={() => setIsConfirmationModalOpen(true)}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> Add
                    </Button>
                )}
            </div>
            {/*category output*/}
            {props.data && props.data.length > 0 ? (
                <Row className="actions">
                    <div>
                        {props.data.map((res, i) =>
                            res.category !== 'Widlife' ? (
                                <Row key={i}>
                                    {res.category + ' Added'}

                                    <div className="group-btn">
                                        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                        <img
                                            onClick={props.onClick}
                                            src={Edit}
                                            alt=""
                                            title="Edit"
                                            className="itinerary-img cursor-pointer"
                                        />
                                    </div>
                                </Row>
                            ) : null
                        )}
                        {/*subcategory output*/}
                        {props.data.findIndex((item) => item.category === 'Widlife') !== -1 ? (
                            <Row>
                                {'Widlife Added'}

                                <div className="group-btn">
                                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                    <img
                                        onClick={props.onClick}
                                        src={Edit}
                                        alt=""
                                        title="Edit"
                                        className="itinerary-img cursor-pointer"
                                    />
                                </div>
                            </Row>
                        ) : null}
                    </div>
                </Row>
            ) : null}

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setIsConfirmationModalOpen(value)}
                description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                handleConfirmation={() => {
                    setIsConfirmationModalOpen(false);
                    props.onDelete();
                }}
            />
        </div>
    );
}

AddTopSightsButton.propTypes = {
    name: Proptypes.string,
    onClick: Proptypes.func,
    isRecommended: Proptypes.bool,
    data: Proptypes.any,
    onDelete: Proptypes.func
};

import React, { useContext } from 'react';
import Notes from 'assets/images/notes.svg';
import { Link } from 'react-router-dom';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';
import { ComponentPermissionContext } from 'context/rolesandpermission';

const CreateCustomersCalendar = () => {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    return (
        <div className={`d-flex h-100 ${assignComponentPermission('calendar')}`}>
            <div
                className={`d-flex flex-wrap flex-sm-nowrap mb-26px ${assignComponentPermission(
                    'calendar-customer-calendar'
                )}`}>
                <div className="pr-16px">
                    <img src={Notes} alt="" />
                </div>
                <div className="flex-grow-1">
                    <div className="d-flex flex-column mt-15px">
                        <div className="flex-grow-1 pr-lg-3">
                            <p className="text-big fw-600 mb-8px">Create your customers calendar</p>
                            <div className="fs-14">
                                {' '}
                                Add products, activities and plan experience for travelers to book
                            </div>
                        </div>

                        <div className="flex-shrink-0 mt-12px">
                            <Link
                                className={`btn btn-primary btn-block btn-md-inline-block btn-mw btn-px mr-0 mr-md-2 ${assignComponentPermission(
                                    'calendar-create-calendar'
                                )}`}
                                to="/get-started">
                                Create
                            </Link>
                            <Link
                                className={`btn btn-primary btn-block btn-md-inline-block btn-mw btn-px ${assignComponentPermission(
                                    'calendar-customer-calendar'
                                )}`}
                                to="/customers-calendars">
                                View All Calendars
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-none d-xxl-block h-100">
                <div className="mx-2 border-right border-secondary h-100"></div>
            </div>
        </div>
    );
};

export default CreateCustomersCalendar;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import { InfoCircleFilled, PlusCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal, Radio, Select, Col, Row, Tabs } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import alert_icon from '../../../assets/images/alert.svg';
import check_icon from '../../../assets/images/check.svg';
import down_icon from '../../../assets/images/down.svg';
import white_logo from '../../../assets/images/white_logo.svg';

const { TabPane } = Tabs;

// modal to help users set up tickets
// todo => dreak steps into individual components, scroll to top once
// step changed or change to a new modal for individuL steps
export default function TicketModal({ modalStep, setmodalStep }) {
    const modal = useRef(null);
    const [showModal, setshowModal] = useState(modalStep > 0);
    const [step, setstep] = useState(modalStep);
    const [seemore, setSeemore] = useState(false);

    useEffect(() => {
        modalStep > 0 ? setshowModal(true) : setshowModal(false);
        setstep(modalStep);
        // window.screenY = 0;
        // window.screenX = 0;
    }, [modalStep]);

    return (
        <Modal
            wrapClassName="modal-wrap"
            visible={showModal}
            footer={false}
            width={'75%'}
            onCancel={() => {
                setstep(0);
                setmodalStep(0);
                setshowModal(false);
            }}>
            {step === 1 && (
                <div className="ticket-modal" ref={modal}>
                    <p className="heading mb5">
                        Add Booking and Ticketing details for your ‘Product Title’ activity
                    </p>
                    <p className="brown-text">
                        <PlusCircleOutlined color="#a2792c" /> I will import booking and ticketing
                        information from my other activity
                    </p>
                    <p className="small-heading">How would you like to set your cut-off time?</p>
                    <p className="sub-heading">
                        This is the time when you stop accepting new bookings and the products stops
                        displaying on your customers calenar and searcrh.
                    </p>
                    <p className="small-heading">Set your cut-off time</p>
                    <Input
                        style={{
                            border: '0.5px solid #A2792C',
                            borderRadius: 8,
                            marginRight: 30
                        }}
                        className="sm-imp"
                    />
                    <Select
                        defaultValue="Days"
                        bordered={false}
                        options={[
                            { text: 'Minutes', value: 'Minutes' },
                            { text: 'Hours', value: 'Hours' },
                            { text: 'Days', value: 'Days' },
                            { text: 'Weeks', value: 'Weeks' }
                        ]}
                        style={{
                            border: '0.5px solid #A2792C',
                            borderRadius: '8px 8px 0px 0px',
                            marginRight: 15
                        }}
                        className="sm-select"
                    />
                    <Select
                        bordered={false}
                        defaultValue="Before travel"
                        options={[
                            {
                                text: 'Before activity starts',
                                value: 'Before activity starts'
                            },
                            { text: 'Before travel', value: 'Before travel' }
                        ]}
                        style={{
                            border: '0.5px solid #A2792C',
                            borderRadius: '8px 8px 0px 0px',
                            marginRight: 15
                        }}
                        className="sm-select"
                    />

                    <div className="tip mt40">
                        <div className="icon-wrap pt20">
                            <p className="fs50 mb0">TIP</p>
                            <img src={alert_icon} alt="" />
                        </div>
                        <div className="tip-text p20">
                            <p>
                                All bookings will be confirmed automatically, however you have 24
                                hours to manually reject confirmed bookings. This saves you time and
                                gives your customers a better experience. Plus, your product gets
                                more exposure because we can distribute it on all customers
                                calendars.
                            </p>
                            <p>
                                Notification for each booking will appear on your dashboard and you
                                can choose whether you want to receive email notifications each time
                                a booking is made.
                            </p>
                        </div>
                    </div>

                    <Checkbox className="mt40">
                        Send us a notification email for each booking
                    </Checkbox>

                    <hr className="mb30 mt20" />
                    <p className="heading mb5">Your cancellation policy</p>
                    <p className="sub-heading">
                        Your cancellation policy <span className="brown-text fs10">Learn more</span>
                    </p>
                    <div className="brown-strip mt20 mb40">
                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25 6.25C17.2547 6.25 10.9375 12.5672 10.9375 20.3125C10.9375 23.3094 12.2563 26.4156 14.0625 29.2C15.4062 31.2687 17.0109 33.1719 18.75 34.6187V39.0625C18.75 40.7719 20.1656 42.1875 21.875 42.1875L23.4375 43.75H26.5625L28.125 42.1875C29.8344 42.1875 31.25 40.7719 31.25 39.0625V34.6187C32.9891 33.1719 34.5938 31.2687 35.9375 29.2C37.7437 26.4156 39.0625 23.3094 39.0625 20.3125C39.0625 12.5672 32.7453 6.25 25 6.25ZM25 9.375C31.0609 9.375 35.9375 14.2516 35.9375 20.3125C35.9375 22.3578 34.9297 25.0547 33.35 27.4906C31.9391 29.6625 30.0844 31.5984 28.3687 32.8125H21.6312C19.9156 31.5984 18.0609 29.6625 16.65 27.4906C15.0703 25.0547 14.0625 22.3578 14.0625 20.3125C14.0625 14.2516 18.9391 9.375 25 9.375ZM22.2656 35.9375H27.7344C27.8563 36.0234 27.9844 36.0844 28.125 36.1328V39.0625H21.875V36.1328C22.0137 36.0863 22.1452 36.0206 22.2656 35.9375Z"
                                fill="#A2792C"
                            />
                        </svg>
                        Travelers want flexibility, so we recommend the Standard Cancellation for
                        most products.
                    </div>

                    <hr className="mt20 mb30" />

                    <Radio.Group>
                        <Radio value={1}>
                            <span className="radio-label">Standard</span>{' '}
                            <span className="ml20 recommended">RECOMMENDED</span>{' '}
                            <p className="option-text">
                                To receive a full refund, travelers may cancel up to 24 hours before
                                the experience start date in the local timezone. No refunds will be
                                given after that time period.
                            </p>
                        </Radio>
                        <hr className="mb30 mt20" />
                        <Radio value={2}>
                            <span className="radio-label">Moderate</span>{' '}
                            <p className="option-text">
                                To receive a full refund, travelers may cancel up to 4 days before
                                the experience start date. No refunds will be given after that time
                                period.
                            </p>
                        </Radio>
                        <hr className="mb30 mt20" />
                        <Radio value={3}>
                            <span className="radio-label">Strict</span>{' '}
                            <p className="option-text">
                                Travelers may cancel up to 7 days before the experience start date
                                to receive a full refund, and between 3-6 days to receive a 50%
                                refund. No refunds will be given after that time period.
                            </p>
                        </Radio>
                    </Radio.Group>
                    <hr className="mb30 mt20" />
                    <p className="small-heading">
                        Add to your policy{' '}
                        <span className="fs12" style={{ fontWeight: 300 }}>
                            (optional)
                        </span>
                    </p>
                    <p className="pl40">
                        You may reserve the right to cancel a customer's booking for a full refund
                        in case of:
                    </p>
                    <Checkbox>Bad weather</Checkbox>

                    <hr className="mt30 mb50" />

                    <div className="detail">
                        <p className="heading">
                            Traveler required information?{' '}
                            <InfoCircleFilled
                                style={{
                                    fontSize: '12px',
                                    color: '#E2C022',
                                    position: 'relative',
                                    top: '-12px'
                                }}
                                size="small"
                                color="#A2792C"
                            />{' '}
                        </p>
                        <p
                            style={{ color: '#333333', margin: 0 }}
                            className="pointer"
                            onClick={() => {
                                setstep(2);
                            }}>
                            <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />
                            Add extra information required from customers
                        </p>
                    </div>

                    <div className="detail">
                        <p className="heading">
                            Ticket Builder{' '}
                            <InfoCircleFilled
                                style={{
                                    fontSize: '12px',
                                    color: '#E2C022',
                                    position: 'relative',
                                    top: '-12px'
                                }}
                            />{' '}
                        </p>
                        <p
                            style={{ color: '#333333', margin: 0 }}
                            className="pointer"
                            onClick={() => {
                                setstep(3);
                            }}>
                            <PlusCircleOutlined color="#A2792C" style={{ marginRight: 10 }} />
                            Set up your tickets
                        </p>
                    </div>

                    <div className="buttons mt100">
                        <Button type="primary" className="rounded-btn mr20">
                            Done
                        </Button>
                        <Button type="ghost" className="rounded-btn">
                            Done
                        </Button>
                        <Button type="primary" className="rounded-btn" style={{ float: 'right' }}>
                            <QuestionCircleOutlined /> Help
                        </Button>
                    </div>
                </div>
            )}

            {step === 2 && (
                <div className="ticket-modal" ref={modal}>
                    <p className="heading mb5">Add extra information required from customers?</p>
                    <p className="small-heading">Here's what we'll collect automatically:</p>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={check_icon} className="mr20" alt="" />
                        <div>
                            <p className="mb0" style={{ fontWeight: '500' }}>
                                Lead Travelers Name
                            </p>
                            <p className="fs12">(First and last name)</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={check_icon} className="mr20" alt="" />
                        <div>
                            <p className="mb0" style={{ fontWeight: '500' }}>
                                Phone Number
                            </p>
                            <p className="fs12">(including country code)</p>
                        </div>
                    </div>

                    <p className="small-heading mt40">
                        Select any personal information you need from all travelers:
                    </p>
                    <div className="brown-strip mt20">
                        <svg
                            width="50"
                            height="50"
                            viewBox="0 0 50 50"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M25 6.25C17.2547 6.25 10.9375 12.5672 10.9375 20.3125C10.9375 23.3094 12.2563 26.4156 14.0625 29.2C15.4062 31.2687 17.0109 33.1719 18.75 34.6187V39.0625C18.75 40.7719 20.1656 42.1875 21.875 42.1875L23.4375 43.75H26.5625L28.125 42.1875C29.8344 42.1875 31.25 40.7719 31.25 39.0625V34.6187C32.9891 33.1719 34.5938 31.2687 35.9375 29.2C37.7437 26.4156 39.0625 23.3094 39.0625 20.3125C39.0625 12.5672 32.7453 6.25 25 6.25ZM25 9.375C31.0609 9.375 35.9375 14.2516 35.9375 20.3125C35.9375 22.3578 34.9297 25.0547 33.35 27.4906C31.9391 29.6625 30.0844 31.5984 28.3687 32.8125H21.6312C19.9156 31.5984 18.0609 29.6625 16.65 27.4906C15.0703 25.0547 14.0625 22.3578 14.0625 20.3125C14.0625 14.2516 18.9391 9.375 25 9.375ZM22.2656 35.9375H27.7344C27.8563 36.0234 27.9844 36.0844 28.125 36.1328V39.0625H21.875V36.1328C22.0137 36.0863 22.1452 36.0206 22.2656 35.9375Z"
                                fill="#A2792C"
                            />
                        </svg>
                        Travelers want flexibility, so we recommend the Standard Cancellation for
                        most products.
                    </div>
                    <div className="mb30">
                        <Checkbox>
                            <span
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '20px'
                                }}>
                                <span className="fs16 mb0">Full Names</span> <br />
                                <span className="fs12">(First and last name of each traveler)</span>
                            </span>
                        </Checkbox>
                    </div>
                    <div className="mb30">
                        <Checkbox>
                            <span className="fs16">Weights</span>
                        </Checkbox>
                    </div>
                    <div className="mb30">
                        <Checkbox>
                            <span className="fs16">Heights</span>
                        </Checkbox>
                    </div>
                    <div className="mb0">
                        <Checkbox>
                            <span className="fs16">Dates of Birth</span>
                        </Checkbox>
                    </div>
                    <div className="mb20">
                        <Checkbox>
                            <span
                                style={{
                                    display: 'inline-block',
                                    position: 'relative',
                                    top: '20px'
                                }}>
                                <span className="fs16 mb0">Passport Details</span> <br />
                                <span className="fs12">
                                    (Passport number, country, expiration date)
                                </span>
                            </span>
                        </Checkbox>
                    </div>
                    <p
                        onClick={() => setSeemore(!seemore)}
                        className="brown-text mt40 mb70 pointer">
                        see more <img src={down_icon} alt="" />
                    </p>
                    <hr className="mb30 mt20" />

                    {seemore && (
                        <div className="more-details">
                            <p className="small-heading">
                                Select any arrival/departure information you need:
                            </p>
                            <p className="mt20 mb0" style={{ color: '#4F4F4F' }}>
                                Flight Information
                            </p>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Arrival Flight Details</span>{' '}
                                        <br />
                                        <span className="fs12">(airline, flight number, time)</span>
                                    </span>
                                </Checkbox>
                            </div>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Departure Flight Details</span>{' '}
                                        <br />
                                        <span className="fs12">(airline, flight number, time)</span>
                                    </span>
                                </Checkbox>
                                <hr className="mb30 mt40" />
                            </div>

                            <p className="mt20 mb0" style={{ color: '#4F4F4F' }}>
                                Ship Information
                            </p>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Arrival Ship Details</span>{' '}
                                        <br />
                                        <span className="fs12">
                                            (ship name, disembarkation time)
                                        </span>
                                    </span>
                                </Checkbox>
                            </div>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Departure Ship Details</span>{' '}
                                        <br />
                                        <span className="fs12">(ship name, boarding time)</span>
                                    </span>
                                </Checkbox>
                            </div>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Shore Excursion Details</span>{' '}
                                        <br />
                                        <span className="fs12">
                                            (ship name, disembarkation and boarding times)
                                        </span>
                                    </span>
                                </Checkbox>
                                <hr className="mb30 mt40" />
                            </div>

                            <p className="mt20 mb0" style={{ color: '#4F4F4F' }}>
                                Train Information
                            </p>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Arrival Train Details</span>{' '}
                                        <br />
                                        <span className="fs12">
                                            (rail line, rail station, time)
                                        </span>
                                    </span>
                                </Checkbox>
                            </div>
                            <div className="mb20">
                                <Checkbox>
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            top: '20px'
                                        }}>
                                        <span className="fs16 mb0">Departure Train Details</span>{' '}
                                        <br />
                                        <span className="fs12">
                                            (rail line, rail station, time)
                                        </span>
                                    </span>
                                </Checkbox>
                            </div>
                            <hr className="mb30 mt40" />
                        </div>
                    )}

                    <div className="buttons mt100">
                        <Button type="primary" className="rounded-btn mr20">
                            Done
                        </Button>
                        <Button
                            onClick={() => {
                                setmodalStep(1);
                            }}
                            type="ghost"
                            className="rounded-btn">
                            Cancel
                        </Button>

                        <Button type="primary" className="rounded-btn" style={{ float: 'right' }}>
                            <QuestionCircleOutlined /> Help
                        </Button>
                    </div>
                </div>
            )}

            {step === 3 && (
                <div className="ticket-modal" ref={modal}>
                    <p className="heading mb5">Let's set up your tickets</p>
                    <p className="brown-text">
                        <PlusCircleOutlined color="#a2792c" />I will import ticket set up
                        information from my other activity
                    </p>
                    <p className="sub-heading" style={{ width: '80%' }}>
                        We issue tickets for every booking so travelers have easy access to
                        important information on the day of travel.
                    </p>
                    <p className="small-heading" style={{ marginTop: 40, fontWeight: '600' }}>
                        Select a ticket type
                    </p>
                    <Radio.Group>
                        <Radio value={1} style={{ marginBottom: 12, display: 'block' }}>
                            <span className="">Mobile or paper ticket accepted</span>{' '}
                            <span className="ml20 recommended">RECOMMENDED</span>{' '}
                        </Radio>
                        <Radio value={2} style={{ marginBottom: 12, display: 'block' }}>
                            <span className="">Paper ticket only accepted</span>{' '}
                        </Radio>
                    </Radio.Group>
                    <p className="small-heading mt30" style={{ fontWeight: '600' }}>
                        How many tickets do you want us to create per booking?
                    </p>
                    <Radio.Group>
                        <Radio value={1} style={{ marginBottom: 12, display: 'block' }}>
                            <span className="">One per booking</span>{' '}
                            <span className="ml20 recommended">RECOMMENDED</span>{' '}
                        </Radio>
                        <Radio value={2} style={{ marginBottom: 12, display: 'block' }}>
                            <span className="">One per traveler</span>{' '}
                        </Radio>
                    </Radio.Group>
                    <hr className="mt30 mb50" />
                    <p className="heading mb5">Add details about your ticket redemption</p>
                    <p className="sub-heading mt40">
                        Do travelers use a separate{' '}
                        <span style={{ fontWeight: '600' }}>entry ticket?</span>
                    </p>
                    <Select
                        style={{
                            border: '0.5px solid #A2792C',
                            borderRadius: '8px',
                            width: '100%'
                        }}
                        bordered={false}
                    />
                    <p className="small-heading mt20">
                        Add or edit ticket redemption instructions (optional)
                    </p>
                    <TextArea
                        rows="6"
                        style={{
                            border: '0.5px solid #A2792C',
                            borderRadius: '8px 8px 0px 0px',
                            width: '100%'
                        }}
                    />
                    <hr className="mt30 mb30" />
                    <p className="heading mb5">Here's a preview of your tickets</p>
                    <Tabs defaultActiveKey="2">
                        <TabPane tab="Preview mobile" key="1">
                            <p className="fs30">
                                <strong>
                                    Add your company logo
                                    <span
                                        style={{
                                            fontSize: 16,
                                            color: 'color: rgba(79, 79, 79, 0.63);'
                                        }}>
                                        (optional)
                                    </span>
                                </strong>
                            </p>
                            <p className="fs18">
                                <strong>Upload file</strong>
                            </p>
                            <p>Upload your company logo</p>

                            <div className="card-wrap-mobile">
                                <div className="card">
                                    <div className="left">
                                        <img src={white_logo} style={{ width: 100 }} alt="" />
                                        <p className="fs20 mt15" style={{ fontWeight: '500' }}>
                                            Mark Doe
                                        </p>
                                        <p className="fs16 mb0" style={{ fontWeight: '500' }}>
                                            4 day Kruger
                                        </p>
                                        <p className="fs20 mb0 mt0" style={{ fontWeight: '500' }}>
                                            ( 4 day Kruger)
                                        </p>
                                        <p className="fs15 mb0 mt15">Thur, Aug.20, 2020</p>
                                        <p className="fs15">2 adults, 1 youth</p>

                                        <div
                                            className="patch"
                                            style={{
                                                width: '75px',
                                                height: '75px',
                                                backgroundColor: '#F0F0F0',
                                                position: 'absolute',
                                                top: '-40px',
                                                left: '35%',
                                                borderRadius: '100%'
                                            }}></div>
                                    </div>
                                    <div className="right">
                                        <p className="fs16 mb0">Booking ref: BR-123456789</p>
                                        <p className="fs13 mb0">Tour operator: Bimbi Philips</p>
                                        <p className="fs14 mb50">
                                            Tour operator confirmation no: S12345678
                                        </p>
                                        <svg
                                            width="181"
                                            height="64"
                                            viewBox="0 0 181 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 0.680176H10.6303V63.3197H0V0.680176ZM17.1365 63.3197H23.1213V0.680176H17.1365V63.3197ZM27.435 63.3197H33.4079V0.680176H27.435V63.3197ZM41.2651 63.3197H54.3486V0.680176H41.2651V63.3197ZM64.8249 63.3197H71.3666V0.680176H64.8249V63.3197ZM81.511 63.3197H108.626V0.680176H81.511V63.3197ZM152.167 63.3197H161.079V0.680176H152.167V63.3197ZM168.853 0.680176V63.3197H181V0.680176H168.853ZM119.505 63.3197H125.336V0.680176H119.505V63.3197ZM133.691 63.3197H139.522V0.680176H133.691V63.3197Z"
                                                fill="#030104"
                                            />
                                        </svg>
                                        <p>1234566</p>
                                    </div>
                                </div>
                            </div>
                            <Button
                                className="rounded-btn"
                                type="primary"
                                style={{ marginBottom: 40 }}>
                                Download
                            </Button>
                        </TabPane>
                        <TabPane tab="Preview paper" key="2">
                            <p className="fs30">
                                <strong>
                                    Add your company logo
                                    <span
                                        style={{
                                            fontSize: 16,
                                            color: 'color: rgba(79, 79, 79, 0.63)'
                                        }}>
                                        (optional)
                                    </span>
                                </strong>
                            </p>
                            <p className="fs18">
                                <strong>Upload file</strong>
                            </p>
                            <p>Upload your company logo</p>

                            <p
                                style={{
                                    color: '#CE3838',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 30
                                }}>
                                <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.3333 19.9999L14.6666 19.9999L14.6666 22.6666L17.3333 22.6666L17.3333 19.9999ZM15.9999 5.33325C21.8799 5.33325 26.6666 10.1199 26.6666 15.9999C26.6666 21.8799 21.8799 26.6666 15.9999 26.6666C10.1199 26.6666 5.33325 21.8799 5.33325 15.9999C5.33325 10.1199 10.1199 5.33325 15.9999 5.33325ZM15.9999 29.3333C17.7509 29.3333 19.4847 28.9884 21.1024 28.3183C22.72 27.6483 24.1899 26.6661 25.428 25.428C27.9285 22.9275 29.3333 19.5361 29.3333 15.9999C29.3333 12.4637 27.9285 9.07231 25.428 6.57183C24.1899 5.33371 22.72 4.35159 21.1024 3.68152C19.4847 3.01146 17.7509 2.66659 15.9999 2.66659C12.4637 2.66659 9.07231 4.07134 6.57182 6.57183C4.07134 9.07232 2.66658 12.4637 2.66658 15.9999C2.66658 17.7509 3.01146 19.4847 3.68152 21.1024C4.35158 22.72 5.33371 24.1899 6.57183 25.428C7.80994 26.6661 9.2798 27.6483 10.8975 28.3183C12.5151 28.9884 14.249 29.3333 15.9999 29.3333ZM17.3333 9.33325L14.6666 9.33325L14.6666 17.3333L17.3333 17.3333L17.3333 9.33325Z"
                                        fill="#CE3838"
                                    />
                                </svg>
                                YOU MUST PRINT THIS TICKET
                            </p>
                            <p style={{ fontSize: 16, fontWeight: '500' }}>
                                Please download and print this ticket and bring it to the
                                tour/activity. Mobile tickets will not be accepted.
                            </p>

                            <div className="card-wrap">
                                <div className="card">
                                    <div className="left">
                                        <img src={white_logo} style={{ width: 100 }} alt="" />
                                        <p className="fs20 mt15" style={{ fontWeight: '500' }}>
                                            Mark Doe
                                        </p>
                                        <p className="fs16 mb0" style={{ fontWeight: '500' }}>
                                            4 day Kruger
                                        </p>
                                        <p className="fs20 mb0 mt0" style={{ fontWeight: '500' }}>
                                            ( 4 day Kruger)
                                        </p>
                                        <p className="fs15 mb0 mt15">Thur, Aug.20, 2020</p>
                                        <p className="fs15">2 adults, 1 youth</p>

                                        <div
                                            className="patch"
                                            style={{
                                                width: '75px',
                                                height: '75px',
                                                backgroundColor: '#F0F0F0',
                                                position: 'absolute',
                                                top: '36%',
                                                left: '-32px',
                                                borderRadius: '100%'
                                            }}></div>
                                    </div>
                                    <div className="right">
                                        <p className="fs16 mb0">Booking ref: BR-123456789</p>
                                        <p className="fs13 mb0">Tour operator: Bimbi Philips</p>
                                        <p className="fs14 mb50">
                                            Tour operator confirmation no: S12345678
                                        </p>
                                        <svg
                                            width="181"
                                            height="64"
                                            viewBox="0 0 181 64"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M0 0.680176H10.6303V63.3197H0V0.680176ZM17.1365 63.3197H23.1213V0.680176H17.1365V63.3197ZM27.435 63.3197H33.4079V0.680176H27.435V63.3197ZM41.2651 63.3197H54.3486V0.680176H41.2651V63.3197ZM64.8249 63.3197H71.3666V0.680176H64.8249V63.3197ZM81.511 63.3197H108.626V0.680176H81.511V63.3197ZM152.167 63.3197H161.079V0.680176H152.167V63.3197ZM168.853 0.680176V63.3197H181V0.680176H168.853ZM119.505 63.3197H125.336V0.680176H119.505V63.3197ZM133.691 63.3197H139.522V0.680176H133.691V63.3197Z"
                                                fill="#030104"
                                            />
                                        </svg>
                                        1234566
                                    </div>
                                </div>

                                <div className="card-btns">
                                    <Button className="round-btn" type="primary">
                                        Print
                                    </Button>
                                    <Button className="round-btn" type="primary">
                                        Download
                                    </Button>
                                </div>
                            </div>
                        </TabPane>
                    </Tabs>
                    <Row>
                        <Col sm={24} lg={10}>
                            <p className="small-heading">Need to Make Changes or Cancel?</p>
                            <p>
                                Visit{' '}
                                <span className="brown-text">CustomerCalendarManager.com</span> to
                                update your booking.
                            </p>

                            <hr className="mt30 mb30" />
                            <p className="small-heading">Inclusions & Exclusions</p>
                            <p className="fs16">TOUR OPTION DESCRIPTION</p>
                            <p className="fs16">WHAT'S INCLUDED</p>
                            <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                                <li>
                                    {' '}
                                    <svg
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                        className="mr12"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5" cy="5" r="5" fill="#333333" />
                                    </svg>
                                    Breakfast x 1
                                </li>
                                <li>
                                    {' '}
                                    <svg
                                        width="10"
                                        height="10"
                                        className="mr12"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5" cy="5" r="5" fill="#333333" />
                                    </svg>
                                    Lunch x 1
                                </li>
                            </ul>
                            <p className="fs16">WHAT'S INCLUDED</p>
                            <ul style={{ paddingLeft: 0, listStyle: 'none' }}>
                                <li>
                                    {' '}
                                    <svg
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        className="mr12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="5" cy="5" r="5" fill="#333333" />
                                    </svg>
                                    Use of Segway
                                </li>
                            </ul>
                        </Col>
                        <Col sm={0} lg={4}></Col>
                        <Col sm={24} lg={10}>
                            <p className="small-heading">Tour Specific Inquiries</p>
                            <p>
                                <span style={{ display: 'block' }}>Bimbi Philips</span>
                                <span className="brown-text">+2348034553284</span>
                            </p>

                            <hr className="mt30 mb30" />

                            <p className="small-heading">Cancellation Policy</p>
                            <p className="fs14 mt30">
                                For a full refund, cancel at least 24 hours in advance of the start
                                date of the experience. Learn more about cancellations.
                            </p>
                        </Col>
                    </Row>
                    <br /> <br />
                    <Button className="rounded-btn" type="primary">
                        Save & Continue
                    </Button>
                    <Button type="primary" className="rounded-btn" style={{ float: 'right' }}>
                        <QuestionCircleOutlined /> Help
                    </Button>
                </div>
            )}
        </Modal>
    );
}
TicketModal.propTypes = {
    modalStep: PropTypes.number,
    setmodalStep: PropTypes.func
};

/* eslint-disable no-case-declarations */

import {
    GET_VENDOR_RATING,
    STRIPE_CONNECT,
    FUND_DETAIL,
    STRIPE_PAYMENTS,
    STRIPE_TRANSFERS,
    STRIPE_PAYOUTS,
    STRIPE_REFUNDS,
    STRIPE_PAYMENT_DETAIL,
    STRIPE_ACCOUNT_DETAIL,
    STRIPE_SINGLE_TRANS_DETAIL,
    BUSINESS_BANK_ACCOUNT_INFORMATION,
    ANALYSIS_DETAIL,
    BANKS_PAYSTACK,
    PAYSTACK_ACCOUNT_DET,
    PAYSTACK_PAYMENTS,
    PAYSTACK_TRANSFERS,
    PAYSTACK_PAYOUTS,
    PAYSTACK_REFUNDS,
    FETCH_LOCATION
} from 'store/types';

const initialState = {
    business_info: null,
    vendor_rating: null,
    countries: [],
    stripe_connect: {},
    stripe_payments: {},
    stripe_transfers: {},
    stripe_payouts: {},
    stripe_refunds: {},
    stripe_account_detail: {},
    stripe_single_tran_detail: {},
    business_bank_account: {},
    fund_analysis: {},
    banks_paystack: [],
    paystack_account_det: {},
    locations: []
};

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_VENDOR_RATING:
            state.vendor_rating = action.payload?.data;
            return { ...state };
        case 'BUSINESS_INFORMATION':
            state.business_info = action.payload?.data;
            return { ...state };

        case 'UPDATE_BUSINESS_INFORMATION':
            let storeUserObject = {
                ...state,
                update_business: true,
                business_info: {
                    ...state.business_info,
                    business_name: action.payload.business_name,
                    business_trading_name: action.payload.business_trading_name,
                    company_website: action.payload.company_website,
                    is_company_fill: 1
                }
            };
            return storeUserObject;
        case 'UPDATE_BUSINESS_STATE':
            let storeBusinessObject = {
                ...state,
                update_business: action.payload
            };
            return storeBusinessObject;

        case 'UPDATE_OFFICE_INFORMATION':
            let storeOfficeObject = {
                ...state,
                update_office: true,
                business_info: {
                    ...state.business_info,
                    // country: action.payload.country,
                    // city: action.payload.city,
                    // postal_code: action.payload.postal_code,
                    // street_address: action.payload.street_address,
                    // open_days: action.payload.open_days,
                    // alternative_address: action.payload.alternative_address,
                    is_office_fill: 1
                }
            };
            return storeOfficeObject;

        case 'UPDATE_OFFICE_STATE':
            let storeOfficeStateObject = {
                ...state,
                update_office: action.payload
            };
            return storeOfficeStateObject;

        case 'LICENSE_INFORMATION':
            state.license_info = action.payload?.data;
            return { ...state };

        case 'UPDATE_LICENSE_INFORMATION':
            let storeLicenseObject = {
                ...state,
                update_license: true,
                license_info: {
                    ...state.license_info,
                    is_license: action.payload.is_license,
                    business_reg_number: action.payload.business_reg_number
                        ? action.payload.business_reg_number
                        : null,
                    is_license_expire: action.payload.is_license_expire
                        ? action.payload.is_license_expire
                        : null,
                    license_expiry_date: action.payload.license_expiry_date
                        ? action.payload.license_expiry_date
                        : null,
                    license_photo_url: action.payload.license_photo_url
                        ? action.payload.license_photo_url
                        : null,
                    is_license_fill: 1
                }
            };
            return storeLicenseObject;

        case 'UPDATE_LICENSE_STATE':
            let storeLicenseStateObject = {
                ...state,
                update_license: action.payload
            };
            return storeLicenseStateObject;

        case 'INSURRANCE_INFORMATION':
            state.insurrance_info = action.payload?.data;
            return { ...state };

        case 'UPDATE_INSURRANCE_INFORMATION':
            let storeInsuranceObject = {
                ...state,
                update_insurance: true,
                license_info: {
                    ...state.license_info,
                    is_insurred: action.payload.is_insurred,
                    policy_number: action.payload.policy_number
                        ? action.payload.policy_number
                        : null,
                    currency: action.payload.currency ? action.payload.currency : null,
                    amount_covered: action.payload.amount_covered
                        ? action.payload.amount_covered
                        : null,
                    amount_per_occurrence: action.payload.amount_per_occurrence
                        ? action.payload.amount_per_occurrence
                        : null,
                    insurrance_expiry_date: action.payload.insurrance_expiry_date
                        ? action.payload.insurrance_expiry_date
                        : null,
                    insurrance_photo_url: action.payload.insurrance_photo_url
                        ? action.payload.insurrance_photo_url
                        : null,
                    is_insurance_fill: 1
                }
            };
            return storeInsuranceObject;

        case 'UPDATE_INSURRANCE_STATE':
            let storeInsuranceStateObject = {
                ...state,
                update_insurance: action.payload
            };
            return storeInsuranceStateObject;

        case 'GET_ALL_COUNTRIES':
            let countries = {
                ...state,
                countries: action.payload.data
            };
            return countries;
        case STRIPE_CONNECT:
            return { ...state, stripe_connect: action.payload };

        case FUND_DETAIL:
            return { ...state, fund_detail: action.payload };
        case STRIPE_PAYMENTS:
            return { ...state, stripe_payments: action.payload };
        case STRIPE_TRANSFERS:
            return { ...state, stripe_transfers: action.payload };
        case STRIPE_PAYOUTS:
            return { ...state, stripe_payouts: action.payload };
        case STRIPE_REFUNDS:
            return { ...state, stripe_refunds: action.payload };
        case STRIPE_PAYMENT_DETAIL:
            return { ...state, stripe_payment_detail: action.payload };
        case STRIPE_ACCOUNT_DETAIL:
            return { ...state, stripe_account_detail: action.payload };

        case STRIPE_SINGLE_TRANS_DETAIL:
            return { ...state, stripe_single_tran_detail: action.payload };
        case BUSINESS_BANK_ACCOUNT_INFORMATION:
            return { ...state, business_bank_account: action.payload };

        case ANALYSIS_DETAIL:
            return { ...state, fund_analysis: action.payload };
        case BANKS_PAYSTACK:
            return { ...state, banks_paystack: action.payload };
        case PAYSTACK_ACCOUNT_DET:
            return {
                ...state,
                paystack_account_det: { ...state.paystack_account_det, ...action.payload }
            };

        case PAYSTACK_PAYMENTS:
            return { ...state, paystack_payments: action.payload };
        case PAYSTACK_TRANSFERS:
            return { ...state, paystack_transfers: action.payload };
        case PAYSTACK_PAYOUTS:
            return { ...state, paystack_payouts: action.payload };
        case PAYSTACK_REFUNDS:
            return { ...state, paystack_refunds: action.payload };

        case FETCH_LOCATION:
            return { ...state, locations: action.payload };

        default:
            return state;
    }
};

export default profileReducer;

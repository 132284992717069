/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'reactstrap';
import { CloseOutlined } from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default function rangePickerComponent(props) {
    const {
        value,
        disabledDate,
        onChange,
        onPanelChange,
        showFooterHeader,
        dateAvailability,
        onOpenChange
    } = props;

    function rPExtraFooter(date, clearDates) {
        return (
            <div className="lh-1-5">
                <div className="calendar-package-data-header d-flex justify-content-between align-items-center gap-3">
                    <div>
                        <div className="calendar-package-data-header-title">
                            {Array.isArray(date) && date[0] && date[1]
                                ? `${moment(moment(date[1], 'YYYY-MM-DD')).diff(
                                      moment(date[0], 'YYYY-MM-DD'),
                                      'days'
                                  )}`
                                : '0'}{' '}
                            nights
                        </div>
                        <div className="calendar-package-data-header-duration">
                            {Array.isArray(date) &&
                                date[0] &&
                                date[1] &&
                                `${moment(date[0], 'YYYY-MM-DD').format('MMM DD,YYYY')} - ${moment(
                                    date[1],
                                    'YYYY-MM-DD'
                                ).format('MMM DD,YYYY')}`}
                        </div>
                    </div>
                    <div>
                        <div className="calendar-package-data-header-checkinout-wrapper">
                            <div className="calendar-package-data-header-checkinout">
                                <div className="d-flex align-items-center gap-1 mb-2">
                                    <div className="calendar-package-data-header-checkinout-label">
                                        CHECK-IN
                                    </div>
                                    <div className="ml-auto">
                                        <Button
                                            onClick={clearDates}
                                            size="sm"
                                            color="transparent"
                                            title="clear"
                                            className="d-block btn-icon-only p-1"
                                            aria-label="Clear">
                                            <CloseOutlined
                                                style={{ fontSize: 10, display: 'block' }}
                                            />
                                        </Button>
                                    </div>
                                </div>
                                <div className="calendar-package-data-header-checkinout-date">
                                    {Array.isArray(date) &&
                                        date[0] &&
                                        `${moment(date[0], 'YYYY-MM-DD').format('MMM DD,YYYY')}`}
                                </div>
                            </div>
                            <div className="calendar-package-data-header-checkinout">
                                <div className="d-flex align-items-center gap-1 mb-2">
                                    <div className="calendar-package-data-header-checkinout-label">
                                        CHECKOUT
                                    </div>
                                    <div className="ml-auto">
                                        <Button
                                            onClick={clearDates}
                                            size="sm"
                                            color="transparent"
                                            title="clear"
                                            className="d-block btn-icon-only p-1"
                                            aria-label="Clear">
                                            <CloseOutlined
                                                style={{ fontSize: 10, display: 'block' }}
                                            />
                                        </Button>
                                    </div>
                                </div>
                                <div className="calendar-package-data-header-checkinout-date">
                                    {Array.isArray(date) &&
                                        date[1] &&
                                        `${moment(date[1], 'YYYY-MM-DD').format('MMM DD,YYYY')}`}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    <div className="mb-2">
                        <div className="d-flex flex-wrap gap-3 align-items-center">
                            <div className="d-inline-flex align-items-center gap-1">
                                <span className="package-date-marker _unavailable"></span>
                                <div className="package-date-label">Unavailable</div>
                            </div>
                            <div className="d-inline-flex align-items-center gap-1">
                                <span className="package-date-marker _available"></span>
                                <div className="package-date-label">Available</div>
                            </div>
                            <div className="d-inline-flex align-items-center gap-1">
                                <span className="package-date-marker _sold-out"></span>
                                <div className="package-date-label _sold-out">Sold out</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex gap-2 justify-content-end align-items-start">
                            <Button
                                onClick={clearDates}
                                size="sm"
                                color="link"
                                className="text-dark">
                                Clear dates
                            </Button>
                            {/* <Button
                                size="sm"
                                color="dark">
                                Close
                            </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <RangePicker
            className="inputclass mb-1"
            size="small"
            value={
                Array.isArray(value)
                    ? value[0] && value[1]
                        ? [moment(value[0]), moment(value[1])]
                        : ['', '']
                    : ['', '']
            }
            disabledDate={disabledDate ? disabledDate : null}
            onChange={onChange ? onChange : null}
            onPanelChange={onPanelChange ? onPanelChange : null}
            onOpenChange={onOpenChange ? onOpenChange : null}
            dateRender={(current) => {
                return (
                    <div
                        className={`ant-picker-cell-inner calendar-package-date-marker 
                        ${dateAvailability && `_${dateAvailability(current)}`} `}>
                        {current.date()}
                    </div>
                );
            }}
            dropdownClassName={showFooterHeader ? 'has-calendar-package-data-header' : ''}
            renderExtraFooter={() =>
                showFooterHeader ? rPExtraFooter(value, () => onChange(null, ['', ''])) : null
            }
        />
    );
}

rangePickerComponent.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.any,
    disabledDate: PropTypes.func,
    onPanelChange: PropTypes.func,
    showFooterHeader: PropTypes.bool,
    dateAvailability: PropTypes.func,
    onOpenChange: PropTypes.func
};

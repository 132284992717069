/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Prompter from '../../../../atoms/prompter';
import Mic from '../../../../../assets/images/mic.svg';
import User from '../../../../../assets/images/user.svg';
import Calender from '../../../../../assets/images/calender.svg';
import { Modal, Button, Radio, Form, Row, Col, Checkbox, Select } from 'antd';
import './index.scss';

import { QuestionCircleOutlined } from '@ant-design/icons';
import Display from '../../../../atoms/display';
import Spacer from '../../../../atoms/spacer';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '19px'
};

const langOptions = ['kols'];

const LanguageOffered = (props) => {
    const { handleOk, isVisible, handleCancel } = props;

    let [admissionPriority, setAdmissionPriority] = useState('');

    const changeAdmissionPriority = (e) => {
        setAdmissionPriority(e.target.value);
    };
    return (
        <Modal
            width={760}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            wrapClassName="language-offered"
            footer={[
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    className="buttons mt100">
                    <div>
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="primary"
                            className="rounded-btn mr20"
                            onClick={() => handleOk({})}>
                            Add
                        </Button>
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="outline"
                            className="rounded-btn mr20"
                            onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                    <Button
                        type="primary"
                        className="rounded-btn"
                        style={{ float: 'right', height: 42 }}>
                        <QuestionCircleOutlined /> Help
                    </Button>
                </div>
            ]}>
            <h4>Add Language Offered</h4>

            <Form layout="vertical" className="mt-5">
                <Form.Item
                    label="Is admission to this place included in the price of your activity?"
                    required>
                    <Radio.Group onChange={changeAdmissionPriority}>
                        <Radio style={radioStyle} value={'yes'}>
                            Yes
                        </Radio>
                        <Radio style={radioStyle} value={'no'}>
                            No
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Display show={admissionPriority === 'yes'}>
                    <div className="language-offered--yes">
                        <h6>What type of guide is included?</h6>
                        <Prompter
                            text="I will import language offered information from my other activity"
                            textColor="#A2792C"
                        />
                        <div className="language-offered__checkboxes">
                            <Row>
                                <Col span="15">
                                    <span>Languages</span>
                                </Col>
                                <Col span="3">
                                    <img src={User} alt="atc-user"></img>
                                    <div>In-person</div>
                                </Col>
                                <Col span="3">
                                    <img src={Mic} alt="atc-mic"></img>
                                    <div>Audio</div>
                                </Col>
                                <Col span="3">
                                    <img src={Calender} alt="atc-calendar"></img>
                                    <div>Written</div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="15">
                                    <span>English</span>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="15">
                                    <span>Spanish</span>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="15">
                                    <span>French</span>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="15">
                                    <span>German</span>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                            </Row>
                            <Row>
                                <Col span="15">
                                    <span>Italian</span>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                                <Col span="3">
                                    <Checkbox className=""></Checkbox>
                                </Col>
                            </Row>
                        </div>
                        <Spacer top={50} />
                        <Form.Item required>
                            <Select size="large" placeholder="Add another Language">
                                {langOptions.map((item) => (
                                    <Select.Option key={item}>{item}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                        <Form.Item label="Are your guides licensed/certified?" required>
                            <Radio.Group>
                                <Radio style={radioStyle} value={1}>
                                    Yes
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Do your guides also serve as drivers?" required>
                            <Radio.Group>
                                <Radio style={radioStyle} value={1}>
                                    Yes
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    </div>
                </Display>
            </Form>
        </Modal>
    );
};

LanguageOffered.propTypes = {
    handleOk: PropTypes.func,
    isVisible: PropTypes.bool,
    handleCancel: PropTypes.func
};

export default LanguageOffered;

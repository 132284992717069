/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { Button, Modal, Row, Col, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import { updatePaystackAccount, getBanksPaystack, verifyPaystack } from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SelectCountry } from 'components/atoms';

class PaystackAccountUpdate extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            countryOptions: [],
            supportStatus: {}
        };
    }

    componentDidMount() {
        const { banks_paystack, getBanksPaystack } = this.props;
        if (Array.isArray(banks_paystack) && banks_paystack.length < 1) getBanksPaystack();
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.showModal && this.props.showModal === false)
            if (this.formRef.current) this.formRef.current.resetFields();
    }

    onFinish = () => {
        const { banks_paystack } = this.props;
        const { paystackRes } = this.state;

        const { account_number, bank } = this.formRef?.current.getFieldsValue();
        let bankDetail = banks_paystack.find((val) => val.name === bank);

        if (paystackRes?.data && paystackRes?.data.hasOwnProperty('account_name'))
            this.props
                .updatePaystackAccount({ account_number, bank_code: bankDetail?.code })
                .then((res) => {
                    if (res) this.props.toggleModal();
                });
        else
            this.props
                .verifyPaystack({ account_number, bank_code: bankDetail?.code })
                .then((res) => {
                    this.setState({ paystackRes: res });
                });
    };

    render() {
        const { paystackRes } = this.state;
        const { showModal, toggleModal, title, banks_paystack } = this.props;

        return (
            <div>
                <Modal isOpen={showModal} toggle={toggleModal} className="modal-dialog-centered">
                    <ModalHeader>
                        <Row className="ml-0 mr-0 pl-0 pr-0">
                            <Col>
                                <h3>{title}</h3>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody className="">
                        <Form
                            ref={this.formRef}
                            onFinish={this.onFinish}
                            layout="vertical"
                            className="form-label-fw-500 mx-auto d-flex flex-column"
                            style={{ maxWidth: 600, minHeight: 400 }}>
                            <Row className="row-lg">
                                <Col md={6}>
                                    <SelectCountry
                                        fieldName="account_country"
                                        label="Select country"
                                        placeHolder="Country"
                                        initialValue={'Nigeria'}
                                        disabled={true}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Item
                                        className="mb-3"
                                        label="Bank account number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter bank account number'
                                            }
                                        ]}
                                        name="account_number">
                                        <Input
                                            onChange={() => this.setState({ paystackRes: null })}
                                            placeholder="Bank account number"
                                        />
                                    </Form.Item>
                                    {paystackRes &&
                                        !paystackRes?.data?.hasOwnProperty('account_name') && (
                                            <span className="text-danger">
                                                {paystackRes?.message || ''}
                                            </span>
                                        )}
                                </Col>
                                <Col md={6}>
                                    <Form.Item
                                        className="mb-3"
                                        label="Bank"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select bank'
                                            }
                                        ]}
                                        name="bank">
                                        <Select
                                            autoComplete="none"
                                            className="d-block"
                                            placeholder="Bank"
                                            showSearch
                                            loading={this.props.loading}
                                            onChange={() => this.setState({ paystackRes: null })}>
                                            {Array.isArray(banks_paystack) &&
                                                banks_paystack.map((item, index) => (
                                                    <Select.Option value={item.name} key={index}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                {paystackRes?.data?.account_name && (
                                    <Col md={6}>
                                        <div className="ant-col ant-form-item-label">
                                            Account holder name
                                        </div>
                                        <Input
                                            style={{ color: 'rgba(0, 0, 0, 0.60)' }}
                                            disabled
                                            placeholder="Bank account name"
                                            value={paystackRes?.data?.account_name}
                                        />
                                    </Col>
                                )}
                            </Row>

                            <div className="d-flex justify-content-center gap-3 mt-auto">
                                {
                                    <Button color="primary" htmlType="submit">
                                        {`${
                                            !paystackRes?.data?.hasOwnProperty('account_name')
                                                ? 'Verify'
                                                : 'Confirm and Update'
                                        } `}
                                    </Button>
                                }

                                <Button onClick={toggleModal} color="dark">
                                    CLOSE
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

PaystackAccountUpdate.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    loading: PropTypes.bool,
    updatePaystackAccount: PropTypes.func,
    banks_paystack: PropTypes.array,
    getBanksPaystack: PropTypes.func,
    verifyPaystack: PropTypes.func
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    banks_paystack: state.profile.banks_paystack
});
const mapDispatch = (dispatch) => ({
    updatePaystackAccount: (data) => dispatch(updatePaystackAccount(data)),
    getBanksPaystack: () => dispatch(getBanksPaystack()),
    verifyPaystack: (data) => dispatch(verifyPaystack(data))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(PaystackAccountUpdate));

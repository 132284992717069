/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './index.css';
import Proptypes from 'prop-types';

export default function TextDropDown(props) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            onClick={props.click}
            style={{ flex: 'auto' }}
            className={props.isSidebar ? 'd-flex p-r unselectable' : 'unselectable'}>
            <div
                className={`cursor-pointer ${props.className}  message d-flex`}
                style={{ width: props.widthy, backgroundColor: props.color }}
                onClick={toggle}>
                <div className={props.formCheck}>
                    {props.isCheckBox ? (
                        props.check && (
                            <input
                                type="checkbox"
                                className="form-check-input check-box-atc"
                                id="exampleCheck1"
                            />
                        )
                    ) : (
                        <img
                            style={{ paddingLeft: '13px', width: props.width }}
                            src={props.image}
                            alt=""
                        />
                    )}
                    <label className="form-check-label select cursor-pointer">{props.name}</label>
                </div>
                {isOpen ? (
                    <img alt="" className={props.icon} src={props.caret} />
                ) : (
                    <img alt="" className={props.class} src={props.down} />
                )}
            </div>
            <div className={props.isSidebar ? 'p-a' : ''}>{isOpen && props.children}</div>
        </div>
    );
}
TextDropDown.propTypes = {
    click: Proptypes.func,
    isSidebar: Proptypes.bool,
    class: Proptypes.string,
    className: Proptypes.string,
    color: Proptypes.string,
    image: Proptypes.string,
    widthy: Proptypes.string,
    icon: Proptypes.string,
    caret: Proptypes.string,
    children: Proptypes.any,
    down: Proptypes.string,
    width: Proptypes.string,
    name: Proptypes.string,
    formCheck: Proptypes.string,
    isCheckBox: Proptypes.bool,
    check: Proptypes.bool
};

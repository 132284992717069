/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import './index.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Checkbox, Form } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, Col } from 'reactstrap';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../../modals/confirmation-modal';
import { compareFacilities } from 'utils';

const general = [
    'Air conditioning',
    'Heating',
    'Free WiFi',
    'Free on site parking',
    'Electric vehicle changing station'
];

const cooking = ['Kitchen', 'Kitchinette', 'Washing Machine'];

const entertainment = ['Flat-screen TV', 'Swimming pool', 'Hot tub', 'Minibar', 'Sauna'];

const outside = ['Balcony', 'Garden view', 'Terrace', 'View'];

function AddFacilities(props) {
    const [selectGeneral, setSelectGeneral] = useState([]);
    const [selectCooking, setSelectCooking] = useState([]);
    const [selectEntertainment, setSelectEntertainment] = useState([]);
    const [selectOutside, setSelectOutside] = useState([]);

    const [confirmationModal, setConfirmationModal] = useState(false);

    //load data
    useEffect(() => {
        if (props.data?.general) setSelectGeneral(props.data.general);
        if (props.data?.cooking_and_cleaning) setSelectCooking(props.data.cooking_and_cleaning);
        if (props.data?.entertainment) setSelectEntertainment(props.data.entertainment);
        if (props.data?.outside_and_view) setSelectOutside(props.data.outside_and_view);
    }, [props.data, props.isOpen]);

    //add new facilities
    const addFacilities = (item, type) => {
        if (type === 'general') {
            const isAdded = selectGeneral.findIndex((res) => res === item);
            if (isAdded === -1) {
                setSelectGeneral([...selectGeneral, item]);
            } else {
                let tmp = [...selectGeneral];
                tmp.splice(isAdded, 1);
                setSelectGeneral([...tmp]);
            }
        } else if (type === 'cooking') {
            const isAdded = selectCooking.findIndex((res) => res === item);
            if (isAdded === -1) {
                setSelectCooking([...selectCooking, item]);
            } else {
                let tmp = [...selectCooking];
                tmp.splice(isAdded, 1);
                setSelectCooking([...tmp]);
            }
        } else if (type === 'entertainment') {
            const isAdded = selectEntertainment.findIndex((res) => res === item);
            if (isAdded === -1) {
                setSelectEntertainment([...selectEntertainment, item]);
            } else {
                let tmp = [...selectEntertainment];
                tmp.splice(isAdded, 1);
                setSelectEntertainment([...tmp]);
            }
        } else if (type === 'outside') {
            const isAdded = selectOutside.findIndex((res) => res === item);
            if (isAdded === -1) {
                setSelectOutside([...selectOutside, item]);
            } else {
                let tmp = [...selectOutside];
                tmp.splice(isAdded, 1);
                setSelectOutside([...tmp]);
            }
        }
    };

    const [form] = Form.useForm();

    const onFinish = () => {
        const { selected_existing_product, data } = props;

        form.validateFields()
            .then(() => {
                if (selected_existing_product && data)
                    if (
                        compareFacilities(data, {
                            selectGeneral,
                            selectCooking,
                            selectEntertainment,
                            selectOutside
                        })
                    )
                        return setConfirmationModal(true);
                    else props.onClose();
                else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };
    const setApiParams = () => {
        let facilities = {};
        if (selectGeneral && selectGeneral.length > 0) {
            facilities.general = selectGeneral;
        }
        if (selectCooking && selectCooking.length > 0) {
            facilities.cooking_and_cleaning = selectCooking;
        }
        if (selectEntertainment && selectEntertainment.length > 0) {
            facilities.entertainment = selectEntertainment;
        }
        if (selectOutside && selectOutside.length > 0) {
            facilities.outside_and_view = selectOutside;
        }
        return facilities;
    };
    const onSave = () => {
        form.validateFields()
            .then(() => {
                props.onSave(setApiParams());
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onClear = () => {
        form.resetFields();
        setSelectGeneral([]);
        setSelectCooking([]);
        setSelectEntertainment([]);
        setSelectOutside([]);
    };

    const onClose = () => {
        props.onClose();
        onClear();
    };

    const customValidate = () => {
        if (
            selectGeneral.length > 0 ||
            selectCooking.length > 0 ||
            selectEntertainment.length > 0 ||
            selectOutside.length > 0
        ) {
            return false;
        }
        return true;
    };

    return (
        <Modal isOpen={props.isOpen} centered size="lg">
            <Form
                form={form}
                name="where-sleep-form"
                onFinish={onFinish}
                className="add-facilities">
                <ModalHeader className="title">
                    What can your customers use at your place?
                </ModalHeader>
                <ModalBody className="body-modal">
                    <p>What if I don’t see a facility I offer?</p>
                    <p style={{ fontSize: 14 }}>
                        The facilities listed here are the ones most searched for by guests. After
                        you complete your registration, you can add more facilities from a larger
                        list in the extranet, the platform you'll use to manage your property
                    </p>
                    {customValidate() && (
                        <div className="text-danger small">Please select atleast one</div>
                    )}
                    <hr width="100%" />
                    <Row>
                        <Col className="col-check-box">
                            <Form.Item
                                name="generalChecks"
                                rules={[
                                    {
                                        required: customValidate(),
                                        message: ''
                                    }
                                ]}>
                                <div>
                                    <div className="col-check-box__title">General</div>
                                    {general.map((res, i) => (
                                        <Checkbox
                                            key={i}
                                            onChange={() => addFacilities(res, 'general')}
                                            checked={
                                                selectGeneral.findIndex((item) => res === item) !==
                                                -1
                                            }>
                                            {res}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col className="col-check-box">
                            <Form.Item
                                name="cookingChecks"
                                rules={[
                                    {
                                        required: customValidate(),
                                        message: ''
                                    }
                                ]}>
                                <div>
                                    <div className="col-check-box__title">Cooking and cleaning</div>
                                    {cooking.map((res, i) => (
                                        <Checkbox
                                            key={i}
                                            onChange={() => addFacilities(res, 'cooking')}
                                            checked={
                                                selectCooking.findIndex((item) => res === item) !==
                                                -1
                                            }>
                                            {res}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                    <hr width="100%" />
                    <Row>
                        <Col className="col-check-box">
                            <Form.Item
                                name="entertainmentChecks"
                                rules={[
                                    {
                                        required: customValidate(),
                                        message: ''
                                    }
                                ]}>
                                <div>
                                    <div className="col-check-box__title">Entertainment</div>
                                    {entertainment.map((res, i) => (
                                        <Checkbox
                                            key={i}
                                            onChange={() => addFacilities(res, 'entertainment')}
                                            checked={
                                                selectEntertainment.findIndex(
                                                    (item) => res === item
                                                ) !== -1
                                            }>
                                            {res}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>
                        <Col className="col-check-box">
                            <Form.Item
                                name="outsideChecks"
                                rules={[
                                    {
                                        required: customValidate(),
                                        message: ''
                                    }
                                ]}>
                                <div>
                                    <div className="col-check-box__title">Outside and view</div>
                                    {outside.map((res, i) => (
                                        <Checkbox
                                            key={i}
                                            onChange={() => addFacilities(res, 'outside')}
                                            checked={
                                                selectOutside.findIndex((item) => res === item) !==
                                                -1
                                            }>
                                            {res}
                                        </Checkbox>
                                    ))}
                                </div>
                            </Form.Item>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <div className="footer-btn">
                        <div>
                            <Button color="primary" type="submit">
                                {props.submitButtonName ?? 'Update Calender & Continue'}
                            </Button>
                            <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark" style={{ width: '137px' }}>
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </ModalFooter>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                handleConfirmation={() => {
                    onSave();
                    setConfirmationModal(false);
                }}
            />
        </Modal>
    );
}
AddFacilities.propTypes = {
    data: Proptypes.any,
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    submitButtonName: Proptypes.string,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddFacilities));

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tabs, Tooltip } from 'antd';
import { Container, Modal, ModalBody } from 'reactstrap';
import './index.scss';
import warningIcon from 'assets/images/warning-icon.svg';
import { CheckCircleFilled } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getBusinessInformation,
    getLicenseInformation,
    getBusinessBankAccountInfo
} from 'store/actions/profileActions';
import { getCurretUser } from 'store/actions/AuthActions';
import {
    getVendorSubscription,
    cancelDowngrade,
    toggleUpgradePlanDrawer,
    toogleDisableCalendarModal,
    changeVendorSubscription,
    tgleUpgradePlanPopover,
    getAllPlans
} from 'store/actions/accessCalendar';
import {
    Feedback,
    BusinessInformation,
    LicenseAndInsurance,
    Connectivity,
    Profile,
    DowngradeCalendars
} from 'components/molecules';
import UpgradeModal from 'components/organisms/upgradeSideBar/upgradeSideBar';
import { ComponentPermissionContext } from 'context/rolesandpermission';

const { TabPane } = Tabs;
class Account extends Component {
    static contextType = ComponentPermissionContext;
    constructor(props) {
        super(props);

        this.state = {
            userAccountDefaultTabKey: '1',
            userAccountActiveTabKey: '1',
            userAccountTotalTabs: '4',
            selectedPlanPayment: 'card',
            prefferedPaymentMethod: 'saved',
            newPayment_addPromoCode: false,
            anotherPayment_addPromoCode: false,
            showCancelDowngradeRequestModal: false,
            selectedPlan: {},
            upgradePopupContent: ``
        };
        this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
    }
    componentDidMount() {
        this.props.getBusinessInformation();
        this.props.getLicenseInformation();
        this.props.getCurretUser();
        this.props.getVendorSubscription();
        this.props.getBusinessBankAccountInfo();
        this.props.getAllPlans();
    }
    nextButtonClick() {
        const { userAccountActiveTabKey } = this.state;
        switch (userAccountActiveTabKey) {
            case '2':
                this.setState({
                    userAccountActiveTabKey: (parseInt(userAccountActiveTabKey) + 2).toString()
                });
                break;
            default:
                this.setState({
                    userAccountActiveTabKey: (parseInt(userAccountActiveTabKey) + 1).toString()
                });
        }
    }
    previousButtonClick() {
        if (this.state.userAccountActiveTabKey == '4') {
            this.setState({
                userAccountActiveTabKey: (
                    parseInt(this.state.userAccountActiveTabKey) - 2
                ).toString()
            });
        } else {
            this.setState({
                userAccountActiveTabKey: (
                    parseInt(this.state.userAccountActiveTabKey) - 1
                ).toString()
            });
        }
    }
    handleCancelDowngrade() {
        this.setState({ showCancelDowngradeRequestModal: false });
        this.props.cancelDowngrade();
    }
    async handleVendorSubscriptionChange(selectedRowKeys) {
        selectedRowKeys = selectedRowKeys || [];

        await this.props.changeVendorSubscription(this.state.selectedPlan.id, selectedRowKeys);
        let upgradePopupContent = 'Your plan has been updated. ';
        upgradePopupContent += this.props.upcomingSubscription
            ? `<b>${this.props.upcomingSubscription?.plan?.name}</b> plan will be in effect from <b>${this.props.upcomingSubscription?.start_date}</b>`
            : `Now you are on a  <b>${this.props.vendorSubscription?.plan?.name} plan<b>`;
        this.setState({ upgradePopupContent });
        this.props.tgleUpgradePlanPopover(true);
    }
    async handleSubscriptionChange(selectedPlan) {
        this.setState({ selectedPlan: selectedPlan.planDetails });
        this.props.toggleUpgradePlanDrawer(false);

        if (selectedPlan.changeType === 'downgrade') {
            this.props.toogleDisableCalendarModal();
        } else {
            // this.handleVendorSubscriptionChange()
            await this.props.changeVendorSubscription(selectedPlan.planId, []);
            let upgradePopupContent = 'Your plan has been updated. ';
            upgradePopupContent += this.props.upcomingSubscription
                ? `<b>${this.props.upcomingSubscription?.plan?.name}</b> plan will be in effect from <b>${this.props.upcomingSubscription?.start_date}</b>`
                : `Now you are on a  <b>${this.props.vendorSubscription?.plan?.name} plan<b>`;
            this.setState({ upgradePopupContent });
            this.props.tgleUpgradePlanPopover(true);
        }
    }
    render() {
        return (
            <>
                {/* User Account Verfication section */}
                <section
                    className={`static-section ${this.context.assignComponentPermission(
                        'account'
                    )}`}>
                    <Container fluid={true} className="container-xxl container-section">
                        {/* Account Verification Header */}
                        <div className="d-flex flex-wrap mx-n2 mb-3">
                            <div className="px-2 col-12 col-md md_mb-10">
                                <h1 className="static-heading mb-0">
                                    User Account {/* Verfication Required not picking up */}
                                    {this.props?.business_info?.is_company_fill &&
                                    this.props?.business_info?.is_office_fill &&
                                    this.props?.license_info?.is_license_fill &&
                                    this.props?.license_info?.is_insurance_fill &&
                                    this.props?.my_profile?.is_account_verified ? (
                                        <span className="text-primary fs-16 fw-600">
                                            Verification completed
                                        </span>
                                    ) : (
                                        <span className="text-primary fs-16 fw-600">
                                            Verification required
                                        </span>
                                    )}
                                </h1>
                            </div>
                            <div className="px-2 col-12 col-md-auto align-self-center md_mb-10">
                                <div className="fw-600">
                                    Access Plan:
                                    {/* Plain type show beow */}
                                    {/* <span className="ml-1">FREE</span>  */}
                                    <span className="ml-1">
                                        {this.props.vendorSubscription?.plan?.name}{' '}
                                        <CheckCircleFilled
                                            style={{ color: '#34A939', verticalAlign: 'top' }}
                                        />{' '}
                                    </span>
                                </div>
                            </div>
                            <div className="px-2 col-12 col-xl">
                                <div className="d-flex justify-content-between justify-content-md-end mx-n2">
                                    {/* {!this.props.vendorSubscription?.plan?.is_free && (
                                        <Button
                                            type="link"
                                            className="mx-2 fw-400 px-0"
                                            onClick={() => {
                                                if (
                                                    this.props.upcomingSubscription &&
                                                    this.props.upcomingSubscription?.id
                                                ) {
                                                    this.setState({
                                                        showCancelDowngradeRequestModal: true
                                                    });
                                                } else {
                                                    this.setState({
                                                        toggleDowngradePlanModal: true
                                                    });
                                                }
                                            }}>
                                            {this.props.upcomingSubscription &&
                                            this.props.upcomingSubscription?.id
                                                ? 'Cancel Downgrade'
                                                : 'Downgrade'}
                                        </Button>
                                    )} */}
                                    <Button
                                        className="black-button table-btn list-btn"
                                        onClick={() => this.props.toggleUpgradePlanDrawer(true)}>
                                        Change Plan
                                    </Button>
                                    <UpgradeModal
                                        className="black-button table-btn list-btn"
                                        title="Change Plan"
                                        onSubscriptionChange={this.handleSubscriptionChange}
                                    />
                                    <DowngradeCalendars
                                        selectedPlan={this.state.selectedPlan}
                                        onConfirm={this.handleVendorSubscriptionChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Stepper Tabs Wrapper */}
                        <div className="static-content-wrapper px-lg secondary">
                            <Tabs
                                className="stepper-tabs"
                                defaultActiveKey={this.state.userAccountDefaultTabKey}
                                activeKey={this.state.userAccountActiveTabKey}
                                onChange={(key) => this.setState({ userAccountActiveTabKey: key })}>
                                {/* Business Information Tab */}
                                <TabPane
                                    tab={
                                        <span
                                            className={
                                                this.props?.business_info?.is_company_fill === 1 &&
                                                this.props?.business_info?.is_office_fill === 1
                                                    ? 'completed'
                                                    : ''
                                            }>
                                            Business Information{' '}
                                        </span>
                                    }
                                    key="1"
                                    className={`${this.context.assignComponentPermission(
                                        'account-business-information'
                                    )}`}>
                                    <BusinessInformation />
                                </TabPane>
                                {/* License And Insurance Tab */}
                                <TabPane
                                    tab={
                                        <span
                                            className={
                                                this.props?.license_info?.is_license_fill === 1 &&
                                                this.props?.license_info?.is_insurance_fill === 1
                                                    ? 'completed'
                                                    : ''
                                            }>
                                            License And Insurance
                                        </span>
                                    }
                                    key="2"
                                    className={`${this.context.assignComponentPermission(
                                        'account-license-and-insurance'
                                    )}
                                `}>
                                    <LicenseAndInsurance />
                                </TabPane>
                                {/* Connectivity Tab */}
                                <TabPane
                                    tab={
                                        <Tooltip title="Coming Soon">
                                            <span className="disabled-link">Connectivity</span>
                                        </Tooltip>
                                    }
                                    key="3"
                                    disabled={true}>
                                    <Connectivity />
                                </TabPane>
                                {/* Profile Tab */}
                                <TabPane
                                    tab={
                                        <span
                                            className={
                                                this.props?.my_profile?.is_account_verified
                                                    ? 'completed'
                                                    : ''
                                            }>
                                            Profile
                                        </span>
                                    }
                                    key="4">
                                    <Profile />
                                </TabPane>
                            </Tabs>
                            {/* Stepper Tab Controls */}
                            <div className="d-flex mx-n2 flex-wrap justify-content-between">
                                <div className="px-2 col-12 col-lg order-2 order-lg-1">
                                    <Feedback buttonClassName="btn-block btn-lg-inline-block" />
                                </div>
                                <div className="px-2 col-12 col-lg-auto order-1 order-lg-2 mb-3 mb-lg-0">
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            type="link"
                                            className="fw-400"
                                            style={{
                                                marginRight:
                                                    parseInt(this.state.userAccountActiveTabKey) ===
                                                    4
                                                        ? ''
                                                        : '1rem',
                                                display:
                                                    parseInt(this.state.userAccountActiveTabKey) ===
                                                    1
                                                        ? 'none'
                                                        : ''
                                            }}
                                            onClick={() => this.previousButtonClick()}>
                                            Previous
                                        </Button>
                                        <Button
                                            type="primary"
                                            className="btn-mw"
                                            style={{
                                                display:
                                                    parseInt(this.state.userAccountActiveTabKey) ===
                                                    parseInt(this.state.userAccountTotalTabs)
                                                        ? 'none'
                                                        : ''
                                            }}
                                            onClick={() => this.nextButtonClick()}>
                                            Next
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </section>
                {/* Downgrade Plan Modal */}
                <Modal
                    size="lg"
                    centered
                    isOpen={this.state.toggleDowngradePlanModal}
                    toggle={() =>
                        this.setState({
                            toggleDowngradePlanModal: !this.state.toggleDowngradePlanModal
                        })
                    }>
                    <ModalBody className="text-center">
                        <img src={warningIcon} alt="" />
                        <div className="modal-body-title-lg mt-2">Are you sure?</div>
                        <p className="modal-body-text mx-auto" style={{ maxWidth: 472 }}>
                            Are you sure you want to cancel your PREMIUM plan and return back to the
                            FREE plan?{' '}
                        </p>
                        <div className="mt-4">
                            <Button type="primary" className="btn-mw text-uppercase mx-2">
                                Yes
                            </Button>{' '}
                            <Button
                                type="dark"
                                className="btn-mw text-uppercase mx-2"
                                onClick={() => this.setState({ toggleDowngradePlanModal: false })}>
                                No
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>

                {/*Cancel Downgrade Request Modal */}
                <Modal
                    size="lg"
                    centered
                    isOpen={this.state.showCancelDowngradeRequestModal}
                    toggle={() =>
                        this.setState({
                            showCancelDowngradeRequestModal:
                                !this.state.showCancelDowngradeRequestModal
                        })
                    }>
                    <ModalBody className="text-center">
                        <img src={warningIcon} alt="" />
                        <div className="modal-body-title-lg mt-2">Are you sure?</div>
                        <p className="modal-body-text mx-auto" style={{ maxWidth: 472 }}>
                            Are you sure you want to cancel your downgrade request to{' '}
                            {this.props.upcomingSubscription?.plan?.name} plan effective from{' '}
                            {this.props.upcomingSubscription?.start_date}?
                        </p>
                        <div className="mt-4">
                            <Button
                                type="primary"
                                className="btn-mw text-uppercase mx-2"
                                onClick={() => this.handleCancelDowngrade()}>
                                Yes
                            </Button>{' '}
                            <Button
                                type="dark"
                                className="btn-mw text-uppercase mx-2"
                                onClick={() =>
                                    this.setState({ showCancelDowngradeRequestModal: false })
                                }>
                                No
                            </Button>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    business_info: state.profile.business_info,
    license_info: state.profile.license_info,
    insurrance_info: state.profile.insurrance_info,
    my_profile: state.auth.my_profile,
    vendorSubscription: state.accessCalendarReducer.vendorSubscription,
    upcomingSubscription: state.accessCalendarReducer.upcomingSubscription
});
const mapDispatch = (dispatch) => ({
    getBusinessInformation: () => dispatch(getBusinessInformation()),
    getLicenseInformation: () => dispatch(getLicenseInformation()),
    getCurretUser: () => dispatch(getCurretUser()),
    getVendorSubscription: () => dispatch(getVendorSubscription()),
    cancelDowngrade: () => dispatch(cancelDowngrade()),
    getBusinessBankAccountInfo: () => dispatch(getBusinessBankAccountInfo()),
    toggleUpgradePlanDrawer: (value) => dispatch(toggleUpgradePlanDrawer(value)),
    getAllPlans: () => dispatch(getAllPlans()),
    toogleDisableCalendarModal: () => dispatch(toogleDisableCalendarModal()),
    changeVendorSubscription: (planId, selectedCalendarIds) =>
        dispatch(changeVendorSubscription(planId, selectedCalendarIds)),
    tgleUpgradePlanPopover: (toggleUpgradePlanPopover) =>
        dispatch(tgleUpgradePlanPopover(toggleUpgradePlanPopover))
});
Account.propTypes = {
    getBusinessInformation: PropTypes.func,
    getLicenseInformation: PropTypes.func,
    getCurretUser: PropTypes.func,
    business_info: PropTypes.object,
    license_info: PropTypes.object,
    my_profile: PropTypes.object,
    getVendorSubscription: PropTypes.func,
    vendorSubscription: PropTypes.object,
    upcomingSubscription: PropTypes.object,
    cancelDowngrade: PropTypes.func,
    getBusinessBankAccountInfo: PropTypes.func,
    toggleUpgradePlanDrawer: PropTypes.func,
    getAllPlans: PropTypes.func.isRequired,
    tgleUpgradePlanPopover: PropTypes.func,
    changeVendorSubscription: PropTypes.func.isRequired,
    toogleDisableCalendarModal: PropTypes.func.isRequired
};

export default withRouter(connect(mapStateToProps, mapDispatch)(Account));

import { CREATE_FEEDBACK, FEEDBACK_ERROR } from '../types/feedbackTypes';

const initialState = {
    feedback_data: null,
    loader: true
};

const feedbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_FEEDBACK:
            state.feedback_data = action.payload?.data;
            state.success = true;
            return { ...state };
        case FEEDBACK_ERROR:
            state.feedback_data = action.payload?.data;
            state.success = false;
            return { ...state };

        default:
            return state;
    }
};

export default feedbackReducer;

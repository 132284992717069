/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button, Row } from 'reactstrap';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Proptypes from 'prop-types';

export default function AddButton(props) {
    const { add = 'Add', moreInfo = true } = props;

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props?.data?.perPersonSharing?.double_room?.amount ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                )}
            </div>
            {props?.data?.perPersonSharing?.double_room?.amount ? (
                <Row className="actions">
                    <div>
                        {props.data.perPersonSharing ? (
                            <div>{`Per Person Sharing (Double) - $${
                                props?.data?.perPersonSharing?.double_room?.amount
                            }${
                                props.data.perPersonSharing.double_room.single_supplement
                                    ? `. Single supplement - $${props.data.perPersonSharing.double_room.single_supplement}`
                                    : ''
                            }`}</div>
                        ) : null}
                        {props.data.perPersonSharing ? (
                            <div>{`Per Person Sharing (Twin) - $${
                                props?.data?.perPersonSharing?.twin_room?.amount
                            }${
                                props.data.perPersonSharing.double_room.single_supplement
                                    ? `. Single supplement - $${props.data.perPersonSharing.double_room.single_supplement}`
                                    : ''
                            }`}</div>
                        ) : null}
                        {props.data.perPersonSingle ? (
                            <div>{`Per Person Single (Double) - $${props?.data?.perPersonSingle?.double_room?.amount}`}</div>
                        ) : null}
                        {props.data.perPersonSingle ? (
                            <div>{`Per Person Single (Twin) - $${props?.data?.perPersonSingle?.twin_room?.amount}`}</div>
                        ) : null}
                        {props.data.accomodationGroupPrices ? (
                            <div>Including Group Booking Price</div>
                        ) : null}
                        {props.data.accomodationPeakSeasonSupplements ? (
                            <div>Including Price for Peak/Festive Season Supplement</div>
                        ) : null}
                    </div>
                </Row>
            ) : null}
        </div>
    );
}
AddButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    descriptionInclude: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any
};

import React from 'react';
import PropTypes from 'prop-types';
const RestingEventIcon = ({ dark }) => {
    return !dark ? (
        <div className="timeline-icon-border">
            <svg height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M22.7211 11.5905H12.6228V20.4265H2.52457V9.06592H0V28.0002H2.52457V24.2133H25.2457V28.0002H27.7702V16.6396C27.7702 15.3005 27.2383 14.0162 26.2914 13.0693C25.3445 12.1224 24.0602 11.5905 22.7211 11.5905ZM7.5737 19.1642C8.57804 19.1642 9.54124 18.7652 10.2514 18.055C10.9616 17.3449 11.3606 16.3817 11.3606 15.3773C11.3606 14.373 10.9616 13.4098 10.2514 12.6996C9.54124 11.9895 8.57804 11.5905 7.5737 11.5905C6.56937 11.5905 5.60617 11.9895 4.896 12.6996C4.18582 13.4098 3.78685 14.373 3.78685 15.3773C3.78685 16.3817 4.18582 17.3449 4.896 18.055C5.60617 18.7652 6.56937 19.1642 7.5737 19.1642Z"
                    fill="#333333"
                />
                <path
                    d="M19.3932 4.81963H15.6376V3.61472L17.7595 1.20491H15.6376V0H19.3932V1.20491L17.2775 3.61472H19.3932V4.81963ZM14.3858 7.22944H10.6302V6.02454L12.7521 3.61472H10.6302V2.40981H14.3858V3.61472L12.2701 6.02454H14.3858V7.22944ZM9.37836 9.63926H5.6228V8.43435L7.74469 6.02454H5.6228V4.81963H9.37836V6.02454L7.26273 8.43435H9.37836V9.63926Z"
                    fill="#333333"
                />
            </svg>
        </div>
    ) : (
        <img alt="resting_time" src={require('assets/images/Sleeping.svg')} />
    );
};

RestingEventIcon.propTypes = {
    dark: PropTypes.any
};

export default RestingEventIcon;

import React from 'react';

const TiltedEmailIcon = () => {
    return (
        <svg
            width="21"
            height="23"
            viewBox="0 0 21 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18 0.375025L3 6.37502C1.625 6.87502 0.5 8.62502 0.5 10.125V21C0.5 22.5 1.625 23.25 3 22.75L18 16.75C19.375 16.25 20.5 14.5 20.5 13V2.12503C20.5 0.625025 19.375 -0.124975 18 0.375025ZM18.75 3.62503L11.25 15.25L2.875 9.62502C2.75 9.50002 2.375 9.12502 2.625 8.75002C2.875 8.25002 3.5 8.50002 3.5 8.50002L11.375 11.375C11.375 11.375 17.375 3.50003 17.75 3.00003C17.875 2.75003 18.25 2.62503 18.625 2.87502C19 3.12502 18.875 3.50003 18.75 3.62503Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TiltedEmailIcon;

import React from 'react';
import { Button } from 'reactstrap';
import { BackI, DollarGraphI, LoopI, TrendingI, AutomationUpI } from 'assets';

export default function sendByContainer(props) {
    const { state, setState } = props;

    return (
        <div className="selected-view-card">
            {state.viewNotPaidOne && (
                <>
                    <div className="mb-3">
                        <LoopI />{' '}
                    </div>
                    <div className="mb-5">
                        <div className="fs-13 fw-600 mb-2">
                            Convert potential customers that are still window shopping
                        </div>
                        <div className="fs-11">
                            Send main product information (e.g. pictures and availability) to
                            customers, while enticing them with your free travel plan.
                        </div>
                    </div>

                    <div className="d-flex justify-content-end mt-auto">
                        <Button
                            color="outline-primary"
                            className="rounded-pill d-inline-flex align-items-center"
                            size="sm"
                            onClick={() => {
                                setState({
                                    viewNotPaidOne: false,
                                    isReadyToSend: false,
                                    paidOrNotPaidFlowInit: true,
                                    alreadyPaidProcess: false
                                });
                            }}>
                            <span>
                                <BackI />
                            </span>
                            <span className="ml-2">Back</span>
                        </Button>
                    </div>
                </>
            )}
            {state.viewNotPaidTwo && (
                <>
                    <div className="mb-3">
                        <TrendingI />
                    </div>
                    <div className="mb-5">
                        <div className="fs-13 fw-600 mb-2">
                            Increase the conversion rate of new customers by showing them more value
                        </div>
                        <div className="fs-11">
                            Send main product information (e.g. pictures and availability) to
                            customers with already agreed dates, while enticing them with your free
                            travel plan.
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                        <Button
                            color="outline-primary"
                            className="rounded-pill d-inline-flex align-items-center"
                            size="sm"
                            onClick={() => {
                                setState({
                                    viewNotPaidTwo: false,
                                    isReadyToSend: false,
                                    paidOrNotPaidFlowInit: true,
                                    alreadyPaidProcess: false
                                });
                            }}>
                            <span>
                                <BackI />
                            </span>
                            <span className="ml-2">Back</span>
                        </Button>
                    </div>
                </>
            )}
            {state.viewPaidOne && (
                <>
                    <div className="mb-3">
                        <DollarGraphI />
                    </div>
                    <div className="mb-5">
                        <div className="fs-13 fw-600 mb-2">Upsell to existing customers</div>
                        <div className="fs-11">
                            Send to exsiting customers to view your free travel plan.
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                        <Button
                            color="outline-primary"
                            className="rounded-pill d-inline-flex align-items-center"
                            size="sm"
                            onClick={() => {
                                setState({
                                    viewPaidOne: false,
                                    isReadyToSend: false,
                                    paidOrNotPaidFlowInit: true,
                                    alreadyPaidProcess: true
                                });
                            }}>
                            <span>
                                <BackI />
                            </span>
                            <span className="ml-2">Back</span>
                        </Button>
                    </div>
                </>
            )}
            {state.viewPaidTwo && (
                <>
                    <div className="mb-3">
                        <AutomationUpI />
                    </div>
                    <div className="mb-5">
                        <div className="fs-13 fw-600 mb-2">
                            Automate upsell process process to existing customers from 3rd party
                            platforms
                        </div>
                        <div className="fs-11">
                            Automate calendar link sent to customers to view your free travel plan.
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mt-auto">
                        <Button
                            color="outline-primary"
                            className="rounded-pill d-inline-flex align-items-center"
                            size="sm"
                            onClick={() => {
                                setState({
                                    viewPaidTwo: false,
                                    isReadyToSend: false,
                                    paidOrNotPaidFlowInit: true,
                                    alreadyPaidProcess: true
                                });
                            }}>
                            <span>
                                <BackI />{' '}
                            </span>
                            <span className="ml-2">Back</span>
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

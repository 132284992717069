/* eslint-disable react/jsx-key */
import RightArrow from '../../../../../assets/images/right-arrow.png';
import { Modal, Button, Select, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Display from '../../../../atoms/display';
import Spacer from '../../../../atoms/spacer';
import './index.scss';

const { Option } = Select;

const Inclusion = (props) => {
    const { isVisible, editingInclusion } = props;

    const handleCancel = () => {
        setCategory(null);
        setTitle(null);
        props.handleCancel();
    };

    const handleOk = (data) => {
        setCategory(null);
        setTitle(null);
        if (editingInclusion) {
            data.index = editingInclusion.index;
        }
        props.handleOk(data);
    };

    const CATEGORIES = {
        'Use of Equipment': [
            'Use of SCUBA equioement',
            'Use of Segway',
            'Use of Trikke',
            'Use of Snorkelling equipement',
            'Use of bicycle',
            'Other'
        ],
        'Transportation of amenities': [
            'Air-conditioned vehicle',
            'WiFi on board',
            'Private transportation',
            'Restroom on board'
        ],
        'Fees': [
            'Gratuities',
            'Landing and facility fees',
            'Airport/Departure Tax',
            'All Fees and Taxes',
            'Fuel surcharge',
            'GST (Goods and Services Tax)'
        ],
        'Other': []
    };

    let [options] = useState(Object.keys(CATEGORIES));
    let [category, setCategory] = useState(null);
    let [title, setTitle] = useState(null);

    useEffect(() => {
        if (!editingInclusion) return;
        setCategory(editingInclusion.category);
        setTitle(editingInclusion.title);
    }, [editingInclusion]);

    const selectCategory = (category) => {
        setCategory(category);
        setTitle(null);
    };

    const selectTitle = (title) => {
        setTitle(title);
    };

    const onTitleChange = (e) => {
        setTitle(e.target.value);
    };

    return (
        <Modal
            wrapClassName="inclusion"
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            footer={[
                <div className="buttons mt100">
                    <Button
                        disabled={!category || !title}
                        style={{ width: 135, height: 42 }}
                        type="primary"
                        className="rounded-btn mr20"
                        onClick={() =>
                            handleOk({
                                title,
                                category
                            })
                        }>
                        Add
                    </Button>
                    <Button
                        style={{ width: 135, height: 42 }}
                        type="outline"
                        className="rounded-btn mr20"
                        onClick={handleCancel}>
                        Cancel
                    </Button>
                </div>
            ]}>
            <h4>Add an inclusion</h4>
            <Spacer top={36} />
            <p>
                Make your list precise so travelers understand the value of this experience. Don’t
                add more than 10 items and avoid duplicates.
            </p>
            <Spacer top={29} />
            <Form
                // form={form}
                layout="vertical">
                <Form.Item label="Category">
                    <Select
                        value={category}
                        onChange={selectCategory}
                        placeholder="Select a category"
                        dropdownRender={(menu) => <div>{menu}</div>}
                        suffixIcon={<img src={RightArrow} alt="" />}>
                        {options.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                </Form.Item>
                {category && (
                    <Display show={category}>
                        <Form.Item label="Title">
                            {category != 'Other' ? (
                                <Select
                                    value={title}
                                    onChange={selectTitle}
                                    suffixIcon={<img src={RightArrow} alt="" />}
                                    placeholder="Select a title"
                                    dropdownRender={(menu) => <div>{menu}</div>}>
                                    {CATEGORIES[category].map((item) => (
                                        <Option key={item}>{item}</Option>
                                    ))}
                                </Select>
                            ) : (
                                <Input onChange={onTitleChange} />
                            )}
                        </Form.Item>
                    </Display>
                )}
            </Form>
        </Modal>
    );
};

Inclusion.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    editingInclusion: PropTypes.array.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleOk: PropTypes.func.isRequired
};

export default Inclusion;

import React, { Component } from 'react';
import { Form, Drawer } from 'antd';
import { Button, Col, Row } from 'reactstrap';

import './index.scss';
import Spacer from '../../../atoms/spacer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { HelpIcon } from 'assets/index';
import ImportItineraries from '../../../atoms/importItineraries';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { getOccassionCategories } from 'store/actions/calendarActions';
import { compareOccassionInformation } from 'utils';
import { OccassionSelectButton } from 'components/atoms';

class AddOccassion extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            isAddOccassion: false,
            isAdded: null,
            data: {},
            saveCategory: [],
            selectedOccassions: {} //new code
        };
    }
    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.data !== data && data !== null)
            this.setState({
                data: this.getCategoriesObjectFromArray(data),
                selectedOccassions: this.getCategoriesObjectFromArray(data)
            });

        if (
            this.formRef.current &&
            prevState.selectedOccassions &&
            Object.keys(prevState.selectedOccassions) !== Object.keys(this.state.selectedOccassions)
        )
            this.formRef.current.validateFields();
    }

    componentDidMount() {
        const { getOccassionCategories, occassionCategories, data } = this.props;
        if (Array.isArray(occassionCategories) && occassionCategories.length < 1)
            getOccassionCategories();

        this.setState({
            data: this.getCategoriesObjectFromArray(data),
            selectedOccassions: this.getCategoriesObjectFromArray(data)
        });
    }

    getCategoriesObjectFromArray(array) {
        let rawData = {};
        if (Array.isArray(array))
            for (let value of array) {
                rawData[value.category] = value.sub_category || [];
            }
        return rawData;
    }
    //saving occassions
    onSaveData = (saveData, category) => {
        const saveCategory = [...this.state.saveCategory];
        const selectedOccassions = { ...this.state.selectedOccassions };

        if (typeof saveData === 'boolean') {
            const isAdd = saveCategory.findIndex((item) => item === category);

            if (!saveData) {
                delete selectedOccassions[category];

                if (isAdd !== -1) {
                    const tmp = [...saveCategory];
                    tmp.splice(isAdd, 1);
                    this.setState({
                        saveCategory: [...tmp],
                        data: selectedOccassions,
                        selectedOccassions
                    });
                } else {
                    this.setState({
                        data: selectedOccassions,
                        selectedOccassions
                    });
                }
            } else {
                let newSaveCategory = [...saveCategory];
                selectedOccassions[category] = [];
                if (isAdd === -1) {
                    newSaveCategory = [...saveCategory, category];
                }
                this.setState({
                    saveCategory: newSaveCategory,
                    data: { ...selectedOccassions },
                    selectedOccassions
                });
            }
        }

        if (saveData?.length > 0) {
            //new code
            selectedOccassions[category] = [...saveData];

            const isAdd = saveCategory.findIndex((item) => item === category);
            let newSaveCategory = [...saveCategory];
            if (isAdd === -1) {
                newSaveCategory = [...saveCategory, category];
            }
            this.setState({
                saveCategory: newSaveCategory,
                data: { ...selectedOccassions },
                selectedOccassions
            });
        } else if (saveData?.length === 0) {
            //new code
            delete selectedOccassions[category];

            const isAdd = saveCategory.findIndex((item) => item === category);
            if (isAdd !== -1) {
                const tmp = [...saveCategory];
                tmp.splice(isAdd, 1);
                this.setState({
                    saveCategory: [...tmp],
                    data: selectedOccassions,
                    selectedOccassions
                });
            } else {
                this.setState({
                    data: selectedOccassions,
                    selectedOccassions
                });
            }
        }
    };
    setImportData = (data) => {
        if (data.occassions)
            this.setState({ data: data.occassions, selectedOccassions: data.occassions });
    };

    onClose = () => {
        this.props.onClose();
    };

    //new code
    onSave = () => {
        const { onSave } = this.props;
        const { selectedOccassions } = this.state;
        onSave(selectedOccassions);
        this.onClose();
    };

    toggleOccassion = () => this.setState({ isAddOccassion: !this.state.isAddOccassion });

    onFinish = () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.onSave();
    };

    compareData = () => {
        const stateData = { ...this.state.selectedOccassions };
        let oldData = this.props?.data ? [...this.props?.data] : [];
        let newData = [];
        if (stateData && Object.keys(stateData).length > 0) {
            Object.keys(stateData).forEach((item) => {
                let obj = {
                    category: item,
                    sub_category: stateData[item]
                };
                newData.push(obj);
            });
        }
        if (compareOccassionInformation(oldData, newData)) {
            this.setState({ confirmationModal: true });
        } else {
            this.onClose();
            //this.onSave();
        }
    };

    render() {
        const { requiredMark, data } = this.state;
        const { validateRequiredFormField, occassionCategories } = this.props;

        return (
            // <div className="itenary-form">
            <Drawer
                className="application add-occassion"
                onClose={() => {
                    this.onClose();
                }}
                visible={this.props.isOpen}
                bodyStyle={{ paddingBottom: 35, paddingTop: 25 }}>
                <Form
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    layout="vertical"
                    initialValues={{
                        requiredMark
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}
                    className="h-100 d-flex flex-column overflow-y-auto">
                    <div className="flex-grow-1 overflow-y-auto px-3 mx-n3">
                        <h6 className="title">Add occassion in this product</h6>

                        {this.props.hideImport ? (
                            ''
                        ) : (
                            <ImportItineraries
                                calendars={this.props.itineraries}
                                import="I will import occassion information from my other activity"
                                onMainProduct={(item) => this.setImportData(item)}
                            />
                        )}

                        <Spacer top={13} />

                        <p className="subtitle">
                            Select the occassions your customers will experience on this product.
                            Please note that this is what your customers will search for when
                            booking your product.
                        </p>

                        <div>Select Occassion</div>
                        <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed">
                            <Form.Item
                                name="selectedOccassions"
                                rules={[
                                    {
                                        validator: () => {
                                            return validateRequiredFormField({
                                                data: this.state.selectedOccassions,
                                                fieldType: 'OBJECT'
                                            });
                                        }
                                    }
                                ]}>
                                <Spacer top={10} />
                                <Row className="row-cols-2" form>
                                    {Array.isArray(occassionCategories) &&
                                        occassionCategories.map((value, ind) => (
                                            <Col key={ind}>
                                                <OccassionSelectButton
                                                    onChangeData={(data) =>
                                                        this.onSaveData(data, value.name)
                                                    }
                                                    preData={
                                                        !value.children
                                                            ? data[value.name]
                                                            : data[value.name] || []
                                                    }
                                                    data={value.children}
                                                    name={value.name}
                                                />
                                            </Col>
                                        ))}
                                </Row>
                            </Form.Item>
                        </div>
                    </div>
                    <div className="footer-btn">
                        <div>
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            <Button
                                color="dark"
                                style={{ marginLeft: '10px' }}
                                onClick={() => this.onClose()}
                                type="button">
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark">
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </Form>

                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.onSave();
                    }}
                />
            </Drawer>
            // </div>
        );
    }
}
AddOccassion.propTypes = {
    data: Proptypes.any,
    onChangeCategory: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    hideImport: Proptypes.bool,
    onSave: Proptypes.func,
    validateRequiredFormField: Proptypes.func,
    selected_existing_product: Proptypes.bool,
    occassionCategories: Proptypes.array,
    getOccassionCategories: Proptypes.func
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product,
    occassionCategories: state.calendar.occassionCategories
});

const mapDispatch = (dispatch) => ({
    getOccassionCategories: () => dispatch(getOccassionCategories())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddOccassion));

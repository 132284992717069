/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer, Form, Row, Col, Input, Button, Radio, DatePicker, Upload, message } from 'antd';
import './index.scss';
import { updateLicenseInformation, updateLicenseState } from 'store/actions/profileActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { API_URL } from 'utils/constants';
import ConfirmEditModal from 'components/molecules/confirmation-modal';
import SaveCancel from 'components/molecules/user-account/save-cancel';

function BusineesLicenseOperations(props) {
    const { handleCancel, visible } = props;
    const dateFormat = 'YYYY-MM-DD';
    const [form] = Form.useForm();
    const [licenseHolderType, setLicenseHolderType] = useState(false);
    const [doesLicenseExpires, setDoesLicenseExpires] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [savingForm, setSavingForm] = useState(false);
    const [isModalClose, setIsModalClose] = useState(false);

    useEffect(() => {
        // Update the document title using the browser API
        if (props.license_info) {
            if (props.license_info.is_license) {
                setLicenseHolderType(true);
            }
            if (props.license_info.is_license_expire) {
                setDoesLicenseExpires(true);
            }
            if (props.license_info && props.license_info.license_photo_url) {
                setFileList([
                    {
                        uid: '-1',
                        name: props.license_info.license_photo_url.substring(
                            props.license_info.license_photo_url.lastIndexOf('/') + 1
                        ),
                        status: 'done',
                        url: props.license_info.license_photo_url,
                        thumbUrl: props.license_info.license_photo_url
                    }
                ]);
            }
        }
        if (props.update_license) {
            props.updateLicenseState(false);
            setSavingForm(false);
            //handleCancel();
        }
    }, [props.update_license, props.license_info, handleCancel]);

    const authToken = localStorage.getItem('token');
    const props1 = {
        name: 'media',
        action: `${API_URL}/media-library`,
        headers: {
            authorization: `Bearer ${authToken}`
        },
        onChange(info) {
            if (info.file.status === 'done') {
                form.setFields([{ name: 'license_photo_url', value: info.file.response.data.url }]);
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'removed') {
                form.setFields([{ name: 'license_photo_url', value: '' }]);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };
    const onFinish = () => {
        setSavingForm(true);
        let data = form.getFieldsValue();
        if (data.license_expiry_date) {
            data['license_expiry_date'] = data.license_expiry_date.format('YYYY-MM-DD');
        }
        props.updateLicenseInformation(data);
    };
    const businessLicenseHolder = (value) => {
        if (!value) {
            form.resetFields();
            setDoesLicenseExpires(false);
            //setFileList([]);
            setSavingForm(false);
            setIsModalClose(false);
        }
        if (licenseHolderType !== value) setLicenseHolderType(value);
    };
    const businessLicenseExpiry = (value) => {
        if (doesLicenseExpires !== value) setDoesLicenseExpires(value);
        if (!value) {
            let expiryDate = props.license_info
                ? props.license_info.license_expiry_date &&
                  moment(props.license_info.license_expiry_date, dateFormat)
                : '';
            form.setFieldsValue({
                license_expiry_date: expiryDate
            });
        }
    };
    const handleModal = () => {
        const {
            is_license,
            is_license_expire,
            business_reg_number,
            license_expiry_date,
            license_photo_url
        } = props.license_info || {};
        const {
            is_license: form_is_license,
            is_license_expire: form_is_license_expire,
            business_reg_number: form_business_reg_number,
            license_expiry_date: form_license_expiry_date,
            license_photo_url: form_license_photo_url
        } = form.getFieldsValue();

        if (
            form_is_license != is_license ||
            form_business_reg_number !== business_reg_number ||
            form_license_photo_url !== license_photo_url ||
            form_is_license_expire != is_license_expire ||
            (form_is_license_expire &&
                form_license_expiry_date.format(dateFormat) !== license_expiry_date)
        ) {
            return setIsModalClose(!isModalClose);
        }
        return handleCancel();
    };
    const closeAllModals = () => {
        if (visible && isModalClose) {
            handleCancel();
            setIsModalClose(!isModalClose);
        }
    };
    const disabledDate = (current) => {
        return current && current < moment().endOf('day');
    };
    return (
        <div>
            {/* Edit Business License Information Drawer */}
            <Drawer
                width={609}
                visible={visible}
                //onOk={handleOk}
                onClose={handleModal}
                layout="vertical"
                key="right"
                className="business-license-modal">
                <h6 className="business-license-modal__title">Edit business license information</h6>
                <Form
                    form={form}
                    onFinish={onFinish}
                    className="mt-3 form-label-fw-500"
                    layout="vertical"
                    requiredMark={true}>
                    <Form.Item
                        label="Do you have a business license?"
                        rules={[{ required: true, message: 'Please select license' }]}
                        initialValue={Boolean(props?.license_info?.is_license)}
                        name="is_license">
                        <Radio.Group
                            onChange={(e) => {
                                businessLicenseHolder(e.target.value);
                                form.setFieldsValue({
                                    is_license: e.target.value
                                });
                            }}>
                            <Radio className="d-block mb-2" value={true}>
                                Yes
                            </Radio>
                            <Radio className="d-block mb-2" value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    {licenseHolderType === true ? (
                        <>
                            <Row>
                                <Col span={24}>
                                    <div className="ant-form-item-label">
                                        <label
                                            htmlFor="business_registration_number"
                                            className="ant-form-item-required">
                                            Enter your business registration number
                                        </label>
                                        <p className="fs-12 mb-0">
                                            We won't share this information
                                        </p>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="business_reg_number"
                                        initialValue={
                                            props.license_info
                                                ? props.license_info.business_reg_number
                                                : ''
                                        }
                                        // label= "Enter your business registration number"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Please enter your business registration number'
                                            }
                                        ]}>
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Form.Item
                                label="Does your business license expire?"
                                initialValue={Boolean(props?.license_info?.is_license_expire)}
                                rules={[{ required: true, message: 'Please select' }]}
                                name="is_license_expire">
                                <Radio.Group
                                    onChange={(e) => {
                                        businessLicenseExpiry(e.target.value);
                                        form.setFieldsValue({
                                            is_license_expire: e.target.value
                                        });
                                    }}>
                                    <Radio className="d-block mb-2" value={true}>
                                        Yes
                                    </Radio>
                                    <Radio className="d-block mb-2" value={false}>
                                        No
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                            {doesLicenseExpires === true ? (
                                <Form.Item
                                    label="When does your business license expire?"
                                    initialValue={
                                        props.license_info
                                            ? props.license_info.license_expiry_date &&
                                              moment(
                                                  props.license_info.license_expiry_date,
                                                  dateFormat
                                              )
                                            : ''
                                    }
                                    rules={[{ required: true, message: 'Please select' }]}
                                    name="license_expiry_date">
                                    <DatePicker
                                        size="large"
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        defaultValue={moment(
                                            props.license_info &&
                                                props.license_info.license_expiry_date,
                                            dateFormat
                                        )}
                                        format={dateFormat}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                            ) : (
                                ''
                            )}
                            <Form.Item
                                label="Upload your business license"
                                initialValue={
                                    props.license_info ? props.license_info.license_photo_url : ''
                                }
                                rules={[{ required: true, message: 'Please upload your license' }]}
                                name="license_photo_url"
                                valuePropName="checked">
                                <Upload
                                    listType="picture"
                                    maxCount={1}
                                    //customRequest = {uploadImage}
                                    defaultFileList={[...fileList]}
                                    {...props1}>
                                    <Button
                                        type="primary"
                                        size="small"
                                        shape="round"
                                        className="font-weight-normal">
                                        Upload File
                                    </Button>
                                </Upload>
                            </Form.Item>
                        </>
                    ) : (
                        ''
                    )}
                    <SaveCancel saveLoading={savingForm} cancelClick={handleModal} />
                </Form>
            </Drawer>
            {/* Confirm Edit Modal */}
            <ConfirmEditModal
                modal={isModalClose}
                handleModal={handleModal}
                closeModal={closeAllModals}
            />
        </div>
    );
}
const mapStateToProps = (state) => ({
    update_license: state.profile.update_license,
    license_info: state.profile.license_info
});
const mapDispatch = (dispatch) => ({
    updateLicenseInformation: (data) => dispatch(updateLicenseInformation(data)),
    updateLicenseState: (data) => dispatch(updateLicenseState(data))
});
BusineesLicenseOperations.propTypes = {
    handleCancel: PropTypes.func,
    visible: PropTypes.bool,
    license_info: PropTypes.any,
    update_license: PropTypes.any,
    updateLicenseState: PropTypes.func,
    updateLicenseInformation: PropTypes.func
};
export default withRouter(connect(mapStateToProps, mapDispatch)(BusineesLicenseOperations));

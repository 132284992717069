/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccountRow from 'components/molecules/user-account/account-row';
import { Button } from 'reactstrap';
import CompanyInformation from 'components/molecules/user-account/company-information';
import OfficeInformation from 'components/molecules/user-account/office-information';
import BusinessBankAccountInformation from 'components/molecules/user-account/business-bank-account';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getStripeDashboardLink } from 'store/actions';
import { PaystackAccountUpdateModal } from 'components/molecules';

function BusinessInformation(props) {
    const { business_info, business_bank_account, my_profile } = props;
    const history = useHistory();

    const [isEditingCompanyInfo, setEditingCompanyInfo] = useState(false);
    const [isEditingOfficeInfo, setEditingOfficeInfo] = useState(false);
    const [isEditingBankInfo, setEditingBankInfo] = useState(false);
    const [showPayoutModal, setShowPayoutModal] = useState(false);

    const EditButton = (props) => {
        const { trigger, disabled = false, text = 'Edit' } = props;
        return (
            // Drawer Trigger Button
            <Button
                disabled={disabled}
                color="primary btn-px"
                className="mw-initial btn-block btn-md-inline-block"
                onClick={() => trigger(true)}>
                {text}
            </Button>
        );
    };

    function redirectToStripeDashboard() {
        props.getStripeDashboardLink().then((res) => {
            if (res?.url) {
                window.location.assign(res?.url);
                // this.props.history.push(res?.url)
            }
        });
    }

    function openPaystackUpdateModal() {
        setShowPayoutModal(true);
    }

    return (
        // Business Information Tab Content for User account verification
        <div>
            {/* Company Information Card */}
            <AccountRow
                title="Company information"
                completed={business_info?.is_company_fill === 1 ? true : false}
                action={<EditButton trigger={setEditingCompanyInfo} />}>
                <p>
                    <span className="mr-3">Business name:</span>
                    <span>{business_info?.business_name || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Business trading name:</span>
                    <span>{business_info?.business_trading_name?.toUpperCase() || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Company website:</span>
                    <span>{business_info?.company_website || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Customer service phone number:</span>
                    <span>
                        {business_info?.customer_service_contact_code
                            ? business_info?.customer_service_contact_code
                            : ''}{' '}
                        {business_info?.customer_service_phone_number || 'N/A'}
                    </span>
                </p>

                <p>
                    <span className="mr-3">Customer service email address:</span>
                    <span>{business_info?.customer_service_email || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Business tax ID:</span>
                    <span>{business_info?.business_tax_id || 'N/A'}</span>
                </p>
            </AccountRow>
            {/* Office Information Card */}
            <AccountRow
                title="Office information"
                completed={business_info?.is_office_fill === 1 ? true : false}
                action={<EditButton trigger={setEditingOfficeInfo} />}>
                <p>
                    <span className="mr-3">Country:</span>
                    <span>{business_info?.city?.country?.name || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">State:</span>
                    <span>{business_info?.city?.state?.name || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">City:</span>
                    <span>{business_info?.city?.name || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Zip / Post code:</span>
                    <span>{business_info?.postal_code || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Main office address:</span>
                    <span>{business_info?.street_address || 'N/A'}</span>
                </p>
                <p>
                    <span className="mr-3">Additional office address:</span>
                    <span>{business_info?.alternative_address || 'N/A'}</span>
                </p>
            </AccountRow>

            {/* business bank account information  */}
            <AccountRow
                title="Bank account information"
                completed={
                    business_bank_account?.is_business_bank_account_fill === 1 ? true : false
                }
                action={
                    <EditButton
                        trigger={
                            my_profile?.vendor?.stripe_connected_account_id
                                ? redirectToStripeDashboard
                                : my_profile?.vendor?.paystack_account_id
                                ? openPaystackUpdateModal
                                : setEditingBankInfo
                        }
                        text={
                            my_profile?.vendor?.stripe_connected_account_id
                                ? 'Edit on stripe'
                                : my_profile?.vendor?.paystack_account_id
                                ? 'Edit on paystack'
                                : 'Edit'
                        }
                    />
                }>
                {my_profile?.vendor?.stripe_connected_account_id ? (
                    <p>
                        <span className="mr-3">
                            Stripe account existed - Go to{' '}
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/finance');
                                }}
                                className="text-nowrap"
                                href="">
                                Finance
                            </a>{' '}
                            to see transactions
                        </span>
                    </p>
                ) : my_profile?.vendor?.paystack_account_id ? (
                    <p>
                        <span className="mr-3">
                            Paystack account existed - Go to{' '}
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.push('/finance');
                                }}
                                className="text-nowrap"
                                href="">
                                Finance
                            </a>{' '}
                            to see transactions
                        </span>
                    </p>
                ) : (
                    <>
                        <p>
                            <span className="mr-3">Business type:</span>
                            <span>{business_bank_account?.business_type || 'N/A'}</span>
                        </p>

                        <p>
                            <span className="mr-3">Country:</span>
                            <span>{business_bank_account?.city?.country?.name || 'N/A'}</span>
                        </p>

                        <p>
                            <span className="mr-3">State:</span>
                            <span>{business_bank_account?.city?.state?.name || 'N/A'}</span>
                        </p>
                        <p>
                            <span className="mr-3">City:</span>
                            <span>{business_bank_account?.city?.name || 'N/A'}</span>
                        </p>

                        <p>
                            <span className="mr-3">Address:</span>
                            <span>{business_bank_account?.account_address || 'N/A'}</span>
                        </p>
                        <p>
                            <span className="mr-3">Zip/Post code:</span>
                            <span>{business_bank_account?.account_zip_code || 'N/A'}</span>
                        </p>

                        <p>
                            <span className="mr-3">Bank account holder phone number:</span>
                            <span>{business_bank_account?.account_contact || 'N/A'}</span>
                        </p>
                    </>
                )}
            </AccountRow>

            {/* Edit Company Information Drawer Component */}
            {isEditingCompanyInfo && (
                <CompanyInformation
                    handleCancel={() => setEditingCompanyInfo(false)}
                    visible={isEditingCompanyInfo}
                />
            )}

            {/* Edit Office Information Drawer Component */}
            {isEditingOfficeInfo && (
                <OfficeInformation
                    handleCancel={() => setEditingOfficeInfo(false)}
                    visible={isEditingOfficeInfo}
                />
            )}

            {/* Edit business bank account Information Drawer Component */}
            {isEditingBankInfo && (
                <BusinessBankAccountInformation
                    handleCancel={() => setEditingBankInfo(false)}
                    visible={isEditingBankInfo}
                />
            )}

            {showPayoutModal && (
                <PaystackAccountUpdateModal
                    title="Update Paystack account"
                    showModal={showPayoutModal}
                    toggleModal={() => setShowPayoutModal(false)}
                />
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    business_info: state.profile.business_info,
    business_bank_account: state.profile.business_bank_account || {},
    my_profile: state.auth.my_profile
});
const mapDispatch = (dispatch) => ({
    getStripeDashboardLink: () => dispatch(getStripeDashboardLink())
});
BusinessInformation.propTypes = {
    trigger: PropTypes.func,
    business_info: PropTypes.any,
    business_bank_account: PropTypes.object,
    my_profile: PropTypes.object,
    disabled: PropTypes.bool,
    text: PropTypes.string,
    getStripeDashboardLink: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(BusinessInformation));

import React from 'react';
import { Chart } from 'react-google-charts';

export default function StackedBarChart() {
    return (
        <Chart
            width={'500px'}
            height={'300px'}
            chartType="BarChart"
            loader={<div>Loading Chart</div>}
            data={[
                ['City', 'Used Hours', 'Available Hours', 'Recommended Hours'],
                ['', 8175000, 8008000, 3000000],
                ['', 3792000, 3694000, 3000000],
                ['', 2695000, 2896000, 3000000],
                ['', 2099000, 1953000, 3000000],
                ['', 1526000, 1517000, 3000000]
            ]}
            options={{
                title: '',
                chartArea: { width: '50%' },
                colors: ['#FF3D00', '#345BC8', '#4CDED5', '#A2792C'],
                isStacked: true,
                hAxis: {
                    minValue: 0
                },
                vAxis: {
                    title: ''
                }
            }}
        />
    );
}

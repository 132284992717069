import React from 'react';
import PropTypes from 'prop-types';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasCalendarMainProductOrPackage } from 'utils';

const FreeTime = ({
    eventInfo,
    setAddProductsProductTypeModal,
    setAddProductInitModalData,
    get_calendar_data,
    setFirstCreateMainProductModal
}) => {
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);
    const duration = eventInfo?.event?.extendedProps?.duration;
    const durationToDisplay = duration && duration.split('in ')[1];
    return (
        <div className="timeline-freetime with-bullets timeline-full">
            <div className="timeline-icon">
                <svg viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle
                        cx="21.7916"
                        cy="21.2916"
                        r="20.2916"
                        stroke="#FF5260"
                        strokeWidth="2"
                    />
                    <circle cx="21.7918" cy="21.2916" r="17.743" fill="#E8ECEF" />
                    <path
                        d="M21.7916 9.46289C15.2692 9.46289 9.96289 14.7692 9.96289 21.2916C9.96289 27.8139 15.2692 33.1202 21.7916 33.1202C28.3139 33.1202 33.6202 27.8139 33.6202 21.2916C33.6202 14.7692 28.3139 9.46289 21.7916 9.46289ZM25.6867 26.8593L20.6087 21.7813V14.1944H22.9744V20.8019L27.3593 25.1867L25.6867 26.8593Z"
                        fill="#FF5260"
                    />
                </svg>
            </div>
            <div className="timeline-info">
                <div className="timeline-duration">
                    <span>+{durationToDisplay === 'an hour' ? '1 hour' : durationToDisplay}</span>
                </div>
                <div className="timeline-title">
                    <span>Free Time</span>
                </div>
                <div className={`timeline-action ${assignComponentPermission('product')}`}>
                    <button
                        className={`timeline-action-btn ${assignComponentPermission(
                            'product-update'
                        )}`}
                        onClick={() => {
                            if (hasCalendarMainProductOrPackage(get_calendar_data?.data)) {
                                setAddProductInitModalData({
                                    name: 'Products available within the day',
                                    value: 'within_day'
                                });
                                setAddProductsProductTypeModal(true);
                            } else {
                                setFirstCreateMainProductModal(true);
                            }
                        }}>
                        Add Products
                    </button>
                </div>
            </div>
        </div>
    );
};

FreeTime.propTypes = {
    eventInfo: PropTypes.any,
    otherProps: PropTypes.any,
    setAddProductsProductTypeModal: PropTypes.any,
    setAddProductInitModalData: PropTypes.any,
    get_calendar_data: PropTypes.any,
    setFirstCreateMainProductModal: PropTypes.any
};
const mapStateToProps = (state) => ({
    get_calendar_data: state.calendar.get_calendar_data
});
export default withRouter(connect(mapStateToProps, null)(FreeTime));

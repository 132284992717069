// import { components } from './components';

export function assignComponentPermission(componentToAccess = '', allComponentsData = []) {
    const findComponent = allComponentsData?.find(
        (component) => component?.slug === componentToAccess.trim()
    );
    if (!findComponent) {
        return 'hidden-component-permission';
    }

    if (findComponent?.permission === 'view') {
        return 'view-only-component-permission';
    }

    return 'edit-component-permission';
}

// export function disableViewOnly(arg) {
//     // return arg.match(New RegExp()) ? 'disabled' : true
// }

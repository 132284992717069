/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import { Row, Col } from 'reactstrap';
import { Button, Select, TimePicker, DatePicker } from 'antd';
import FilterIcon from 'components/atoms/icons/filter-icon';
import SearchBox from 'components/atoms/searchbox/SearchBox';
import { disableDate } from 'utils/index';

import {
    getAllCategories,
    getCalendarForFilter,
    fetchAllDayProductCategories,
    fetchWithInDayProductCategories
} from 'store/actions';

const { Option } = Select;
const angleIcon = (
    <span role="img" aria-label="down" className="anticon anticon-down ant-select-suffix">
        <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M-4.62904e-07 1.41L4.58 6L-6.16331e-08 10.59L1.41 12L7.41 6L1.41 -6.1633e-08L-4.62904e-07 1.41Z"
                fill="#A2792C"
            />
        </svg>
    </span>
);

const AvailabilityFilter = (props) => {
    const {
        filter,
        setFilter,
        filterState,
        onChangeFilter,
        onClearFilter,
        // categories,
        calendars,
        allDayCategories,
        withinDayCategories,
        getAllCategories,
        getCalendarForFilter,
        getAllDayCategories,
        getWithInDayCategories
    } = props;
    const history = useHistory();
    const [categoryType, setCategoryType] = useState(null);

    function onClickClearFilter() {
        onClearFilter();
    }

    useEffect(() => {
        getAllCategories();
        getCalendarForFilter();
        getAllDayCategories();
        getWithInDayCategories();
    }, [getAllCategories, getCalendarForFilter, getAllDayCategories, getWithInDayCategories]);

    const productStatus = [
        { value: 'in_use', label: 'In use' },
        { value: 'not_in_use', label: 'Not in use' }
    ];

    const availabilityStatus = [
        { value: 'yes', label: 'Yes' },
        { value: 'no', label: 'No' }
    ];

    const productCategoryType = [
        { value: 'allDay', label: 'All Day' },
        { value: 'withinDay', label: 'Within Day' }
    ];

    const handleCategoryChange = (value) => {
        setCategoryType(value);
    };

    const today = moment().format('YYYY-MM-DD');
    const tomorrow = moment().add(1, 'days').format('YYYY-MM-DD');

    return (
        <>
            <div className="mb-3">
                <Row className="align-items-baseline">
                    <Col sm={12} md={12} lg={4}>
                        <div className="fs-17 text-primary fw-600">
                            Product Availability Details
                        </div>
                    </Col>
                    <Col
                        sm={12}
                        md={5}
                        lg={3}
                        className="d-flex flex-row align-items-baseline justify-content-between mb-1">
                        <span
                            className="hover-underline text-nowrap text-primary fs-14"
                            role="button"
                            onClick={() =>
                                onChangeFilter({
                                    type: 'dateQuery',
                                    value: today
                                })
                            }
                            tabIndex="0">
                            Today
                        </span>
                        <span className="mx-2">|</span>
                        <span
                            className="hover-underline text-nowrap fs-14"
                            role="button"
                            onClick={() =>
                                onChangeFilter({
                                    type: 'dateQuery',
                                    value: tomorrow
                                })
                            }
                            tabIndex="0">
                            Tomorrow
                        </span>
                        <div className="date-container d-flex justify-content-between">
                            <DatePicker
                                disabledDate={disableDate}
                                placeholder="Date"
                                onChange={(_, valueString) =>
                                    onChangeFilter({
                                        type: 'dateQuery',
                                        value: valueString
                                    })
                                }
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={3} lg={2} className="mb-2">
                        <TimePicker.RangePicker
                            format={'HH:mm'}
                            placeholder={['Start', 'End']}
                            onChange={(_, valueString) => {
                                const [startTime, endTime] = valueString;
                                const arrayElement = [
                                    {
                                        type: 'startTime',
                                        value: startTime
                                    },
                                    {
                                        type: 'endTime',
                                        value: endTime
                                    }
                                ];
                                onChangeFilter(arrayElement);
                            }}
                        />
                    </Col>
                    <Col sm={12} md={4} lg={3} className="text-right">
                        <div className="archive-container">
                            <Button
                                className="px-0 mr-sm-2"
                                type="link"
                                size="small"
                                onClick={() => history.push(`/archive-availability`)}>
                                Archived List
                            </Button>
                            <Button
                                className="btn-filter btn-block btn-sm-inline-block filter-button"
                                size="small"
                                onKeyDown={() => onClickClearFilter()}
                                onClick={() => (filter ? onClickClearFilter() : setFilter())}>
                                <span className="name mr-2">{filter ? 'Close' : 'Filters'}</span>
                                <span>
                                    <FilterIcon />
                                </span>
                            </Button>
                        </div>
                    </Col>
                </Row>
            </div>
            {filter && (
                <div className="d-flex flex-wrap m-n2">
                    <div className="p-2 flex-grow-1">
                        <SearchBox
                            inputValue={filterState.searchQuery}
                            search={(e) =>
                                onChangeFilter({ type: 'searchQuery', value: e.target.value })
                            }
                        />
                    </div>
                    <div className="p-2 flex-grow-1">
                        <Select
                            className="select-type-filter w-100"
                            dropdownClassName="select-basic-dropdown"
                            size="large"
                            placeholder="Select Category Type"
                            value={categoryType}
                            onChange={handleCategoryChange}
                            suffixIcon={angleIcon}
                            allowClear>
                            {productCategoryType.map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    {categoryType === 'allDay' && (
                        <div className="p-2 flex-grow-1">
                            <Select
                                className="select-type-filter w-100"
                                dropdownClassName="select-basic-dropdown"
                                size="large"
                                placeholder="Sort By All-Day Category"
                                optionLabelProp="label"
                                onChange={(value) => {
                                    onChangeFilter({
                                        type: 'productCategoryId',
                                        value
                                    });
                                }}
                                suffixIcon={angleIcon}>
                                <Option value="" label="All">
                                    All
                                </Option>
                                {allDayCategories.map((item) => (
                                    <Option key={item.id} value={item.id} label={item.name}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    {categoryType === 'withinDay' && (
                        <div className="p-2 flex-grow-1">
                            <Select
                                className="select-type-filter w-100"
                                dropdownClassName="select-basic-dropdown"
                                size="large"
                                placeholder="Sort By Within-Day Category"
                                optionLabelProp="label"
                                onChange={(value) =>
                                    onChangeFilter({
                                        type: 'productCategoryId',
                                        value
                                    })
                                }
                                suffixIcon={angleIcon}>
                                <Option value="" label="All">
                                    All
                                </Option>
                                {withinDayCategories.map((item) => (
                                    <Option key={item.id} value={item.id} label={item.name}>
                                        {item.name}
                                    </Option>
                                ))}
                            </Select>
                        </div>
                    )}
                    <div className="p-2 flex-grow-1">
                        <Select
                            className="select-type-filter w-100"
                            dropdownClassName="select-basic-dropdown"
                            size="large"
                            placeholder="Sort By Calendar"
                            value={filterState.calendarId}
                            onChange={(value) =>
                                onChangeFilter({
                                    type: 'calendarId',
                                    value
                                })
                            }
                            suffixIcon={angleIcon}
                            allowClear>
                            {calendars.map((item) => (
                                <Option key={item.id} value={item.id}>
                                    {item.title.replace(/ /g, ' ')}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="p-2 flex-grow-1">
                        <Select
                            className="select-type-filter w-100"
                            dropdownClassName="select-basic-dropdown"
                            size="large"
                            placeholder="Product Status"
                            value={filterState.productStatus}
                            onChange={(value) =>
                                onChangeFilter({
                                    type: 'productStatus',
                                    value
                                })
                            }
                            suffixIcon={angleIcon}
                            allowClear>
                            {productStatus.map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <div className="p-2 flex-grow-1">
                        <Select
                            className="select-type-filter w-100"
                            dropdownClassName="select-basic-dropdown"
                            size="large"
                            placeholder="Available"
                            value={filterState.availabilityStatus}
                            onChange={(value) =>
                                onChangeFilter({
                                    type: 'availabilityStatus',
                                    value
                                })
                            }
                            suffixIcon={angleIcon}
                            allowClear>
                            {availabilityStatus.map((item, index) => (
                                <Option key={index} value={item.value}>
                                    {item.label}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            )}
        </>
    );
};

AvailabilityFilter.propTypes = {
    filter: PropTypes.bool,
    setFilter: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func
    }),
    filterState: PropTypes.shape({
        searchQuery: PropTypes.string,
        productCategoryId: PropTypes.number,
        calendarId: PropTypes.number,
        productStatus: PropTypes.string,
        availabilityStatus: PropTypes.string
    }),
    onChangeFilter: PropTypes.func,
    onClearFilter: PropTypes.func,
    categories: PropTypes.object,
    calendars: PropTypes.array,
    allDayCategories: PropTypes.array,
    withinDayCategories: PropTypes.array,
    getAllCategories: PropTypes.func,
    getCalendarForFilter: PropTypes.func,
    getAllDayCategories: PropTypes.func,
    getWithInDayCategories: PropTypes.func
};

const mapStateToProps = (state) => ({
    categories: state.category.categories,
    calendars: state.calendar.calendars_for_filter,
    allDayCategories: state.product.all_day_product_categories,
    withinDayCategories: state.product?.within_day_product_categories
});

const mapDispatch = (dispatch) => ({
    getAllCategories: () => dispatch(getAllCategories()),
    getCalendarForFilter: () => dispatch(getCalendarForFilter()),
    getAllDayCategories: () => dispatch(fetchAllDayProductCategories()),
    getWithInDayCategories: () => dispatch(fetchWithInDayProductCategories())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AvailabilityFilter));

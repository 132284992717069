/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Drawer, Form, InputNumber } from 'antd';
import { Button, Row, Col } from 'reactstrap';

import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import QuestionMark from '../../../../assets/images/question_mark.svg';
import { Grid } from '@material-ui/core';
import Prompter from '../../prompter';
import Proptypes from 'prop-types';
import DeleteIcon from '../../../../assets/images/close.svg';
import MoreInfoTooltip from 'components/atoms/more-info-tooltip';

const styles = {
    title: {
        display: 'flex',
        alignItems: 'center'
    },
    cell: {
        border: 'none',
        display: 'flex',
        alignItems: 'center'
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        width: '68px',
        height: '48px'
    },
    input: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '68px',
        height: '48px',
        fontSize: '16px',
        textAlign: 'center'
    },
    inputUsd: {
        border: '0.5px solid #C4C4C4',
        borderLeft: 0,
        justifyContent: 'center',
        alignItems: 'center'
    }
};

class AddAnotherPrice extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            minAge: 0,
            maxAge: 0,
            amount: 0,
            min: 2,
            max: 2,
            newGroupRows: []
        };
    }
    componentDidMount() {
        const { data } = this.props;
        if (data) this.onLoad(data);
    }

    componentDidUpdate(prevProps) {
        const { data, minAge, maxAge, amount } = this.props;
        if (prevProps.minAge !== minAge)
            this.setState({ minAge: parseInt(this.props.minAge) || 0 });
        if (prevProps.maxAge !== maxAge)
            this.setState({ maxAge: parseInt(this.props.maxAge) || 0 });
        if (prevProps.amount !== amount)
            this.setState({ amount: parseInt(this.props.amount) || 0 });
        if (prevProps.data !== data && data) this.onLoad(data);
    }

    onLoad = (data) => {
        if (data && data.length > 0) {
            let tmp = data;
            this.setState({
                min: tmp[0] ? parseInt(tmp[0].min) : 0,
                max: tmp[0] ? parseInt(tmp[0].max) : 0,
                amount: tmp[0] ? parseInt(tmp[0].amount) : 0
            });
            if (tmp && tmp.length > 1) {
                tmp = tmp.slice(1, tmp.length);
                this.setState({
                    newGroupRows: tmp
                });
            }
        }
    };

    AddNewGroup = () => {
        const tempNewGroup = [...this.state.newGroupRows];
        tempNewGroup.push({
            amount: null,
            min: getMinValue(
                this.state.newGroupRows.length + 1,
                this.state.newGroupRows,
                this.state.max
            ),
            max: getMinValue(
                this.state.newGroupRows.length + 1,
                this.state.newGroupRows,
                this.state.max
            )
        });
        this.setState({ newGroupRows: tempNewGroup });
    };
    onDeleteAnother = (index) => {
        const tempNewGroup = [...this.state.newGroupRows];
        tempNewGroup.splice(index, 1);
        this.setState({ newGroupRows: tempNewGroup });
    };
    onChange = (item, index) => {
        const tmpNewGroupRows = this.state.newGroupRows;
        tmpNewGroupRows.splice(index, 1, item);
        this.setState({ newGroupRows: [...tmpNewGroupRows] });
    };

    onClose = () => this.props.onClose();
    onSave = () => {
        const { amount, min, max } = this.state;

        const minMax = {
            amount: amount,
            min: min,
            max: max
        };
        this.props.onSave([minMax, ...this.state.newGroupRows]);
    };

    render() {
        const { minAge, maxAge, amount, min, max } = this.state;
        const { showModal } = this.props;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application AddAnotherPrice"
                    onClose={() => {
                        this.onClose();
                    }}
                    visible={showModal}
                    style={{
                        zIndex: showModal ? 1061 : 1000
                    }}
                    bodyStyle={{ paddingBottom: 35, paddingTop: 39 }}>
                    <Grid item className="perPerson">
                        <Grid container style={{ flexDirection: 'column' }}>
                            <Grid item className="singleSupplement">
                                {this.props.title}
                            </Grid>
                            <Row style={{ marginBottom: 10, fontSize: 14, height: '28px' }}>
                                <Col sm={3} style={{ maxWidth: '16.66667%' }}>
                                    Min age
                                </Col>
                                <Col sm={2} style={{ maxWidth: '14.66667%' }}>
                                    Max age
                                </Col>
                                <Col sm={5} style={{ maxWidth: '36.66667%' }}>
                                    Amount you would get
                                    <MoreInfoTooltip
                                        title={`The amount you will get for group booking`}
                                        icon={<img alt="" src={QuestionMark} />}
                                    />
                                </Col>
                                <Col sm={3} align="left">
                                    Min-Max booking
                                    <div className="min-max-booking-tooltip-icon">
                                        <MoreInfoTooltip
                                            title={`The booking group should have a minimum value of 2`}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={2} style={{ ...styles.cell, maxWidth: '13.66667%' }}>
                                    <Form.Item className="mb-0 mt-2">
                                        <InputNumber
                                            style={{ ...styles.input, opacity: 0.6 }}
                                            size="large"
                                            value={minAge}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <div style={{ ...styles.cell, margin: '0 3px', color: '#333333' }}>
                                    -
                                </div>
                                <Col sm={2} style={{ ...styles.cell, maxWidth: '13.66667%' }}>
                                    <Form.Item className="mb-0 mt-2">
                                        <InputNumber
                                            style={{ ...styles.input, opacity: 0.6 }}
                                            size="large"
                                            value={maxAge}
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                                <Col
                                    sm={4}
                                    style={{ ...styles.cell, marginLeft: 14, maxWidth: '66%' }}>
                                    <div
                                        style={{ display: 'flex', alignItems: 'start' }}
                                        className="mt-2">
                                        <div style={styles.currency}>USD</div>
                                        <Form.Item className="mb-0">
                                            <InputNumber
                                                className="border-left-radius-0"
                                                style={{
                                                    ...styles.input,
                                                    ...styles.inputUsd,
                                                    borderRadius: '0 5px 5px 0',
                                                    width: 89,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    paddingTop: 10
                                                }}
                                                value={amount}
                                                onChange={(value) =>
                                                    this.setState({ amount: value })
                                                }
                                            />
                                        </Form.Item>
                                        <div
                                            className="align-self-center"
                                            style={{ fontWeight: 500, marginLeft: 8 }}>
                                            PP
                                        </div>
                                    </div>

                                    <Col
                                        sm={2}
                                        style={{
                                            ...styles.cell,
                                            maxWidth: '58.66667%'
                                        }}>
                                        <Form.Item className="mb-0 mt-2">
                                            <InputNumber
                                                size="large"
                                                style={styles.input}
                                                value={min}
                                                min={getMinValue(0, this.state.newGroupRows)}
                                                max={
                                                    this.state.max ||
                                                    getMaxValue(0, this.state.newGroupRows)
                                                }
                                                onChange={(e) => this.setState({ min: e })}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <div
                                        style={{
                                            ...styles.cell,
                                            color: '#333333'
                                        }}>
                                        -
                                    </div>
                                    <Col sm={2} style={{ ...styles.cell, maxWidth: '58.66667%' }}>
                                        <Form.Item className="mb-0 mt-2">
                                            <InputNumber
                                                size="large"
                                                style={styles.input}
                                                min={
                                                    this.state.min ||
                                                    getMinValue(0, this.state.newGroupRows)
                                                }
                                                max={getMaxValue(0, this.state.newGroupRows)}
                                                value={max}
                                                onChange={(e) => this.setState({ max: e })}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Col>
                                {this.state.newGroupRows.map((res, index) => (
                                    <Row
                                        key={index}
                                        style={{
                                            margin: 0,
                                            marginTop: 20,
                                            marginBottom:
                                                this.state.newGroupRows === index - 1 ? 20 : 0
                                        }}>
                                        <Col
                                            sm={4}
                                            style={{
                                                ...styles.cell,
                                                justifyContent: 'flex-end',
                                                maxWidth: '28.33333%'
                                            }}>
                                            <div
                                                style={{
                                                    fontWeight: '400',
                                                    fontSize: '16px',
                                                    lineHeight: '24px',
                                                    color: '#A2792C'
                                                }}>
                                                {this.props.title} Group price
                                            </div>
                                        </Col>
                                        <Col
                                            sm={4}
                                            style={{
                                                ...styles.cell,
                                                marginLeft: 10,
                                                maxWidth: '35%'
                                            }}>
                                            <div
                                                style={{ display: 'flex', alignItems: 'start' }}
                                                className="mt-2">
                                                <div style={styles.currency}>USD</div>
                                                <Form.Item className="mb-0">
                                                    <InputNumber
                                                        style={{
                                                            ...styles.input,
                                                            ...styles.inputUsd,
                                                            borderRadius: '0 5px 5px 0',
                                                            width: 89,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            paddingTop: 10
                                                        }}
                                                        value={res.amount}
                                                        onChange={(e) => {
                                                            this.onChange(
                                                                {
                                                                    amount: e,
                                                                    min: res.min,
                                                                    max: res.max
                                                                },
                                                                index
                                                            );
                                                        }}
                                                    />
                                                </Form.Item>
                                                <div
                                                    className="align-self-center"
                                                    style={{ fontWeight: 500, marginLeft: 8 }}>
                                                    PP
                                                </div>
                                            </div>

                                            <Col
                                                sm={2}
                                                style={{
                                                    ...styles.cell,
                                                    maxWidth: '58.66667%'
                                                }}>
                                                <Form.Item className="mb-0 mt-2">
                                                    <InputNumber
                                                        style={styles.input}
                                                        size="large"
                                                        value={res.min}
                                                        onChange={(e) => {
                                                            this.onChange(
                                                                {
                                                                    amount: res.amount,
                                                                    min: e,
                                                                    max: res.max
                                                                },
                                                                index
                                                            );
                                                        }}
                                                        min={getMinValue(
                                                            index + 1,
                                                            this.state.newGroupRows
                                                        )}
                                                        max={
                                                            res.max ||
                                                            getMaxValue(
                                                                index + 1,
                                                                this.state.newGroupRows
                                                            )
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <div
                                                style={{
                                                    ...styles.cell,
                                                    color: '#333333'
                                                }}>
                                                -
                                            </div>
                                            <Col
                                                sm={2}
                                                style={{
                                                    ...styles.cell,
                                                    maxWidth: '58.66667%'
                                                }}>
                                                <Form.Item className="mb-0 mt-2">
                                                    <InputNumber
                                                        style={styles.input}
                                                        size="large"
                                                        value={res.max}
                                                        onChange={(e) => {
                                                            this.onChange(
                                                                {
                                                                    amount: res.amount,
                                                                    min: res.min,
                                                                    max: e
                                                                },
                                                                index
                                                            );
                                                        }}
                                                        min={
                                                            res.min ||
                                                            getMinValue(
                                                                index + 1,
                                                                this.state.newGroupRows,
                                                                max
                                                            )
                                                        }
                                                        max={getMaxValue(
                                                            index + 1,
                                                            this.state.newGroupRows
                                                        )}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <div
                                                className="delete-block"
                                                style={{ height: 'unset' }}>
                                                <img
                                                    src={DeleteIcon}
                                                    className="delete-icon cursor-pointer"
                                                    style={{ top: 0 }}
                                                    onClick={() => {
                                                        this.onDeleteAnother(index);
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Row>
                            <Row style={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Col sm={6}>
                                    <div>
                                        <Prompter
                                            fontSize={14}
                                            textColor="#A2792C"
                                            text={`Add Another ${this.props.title} Group price`}
                                            onClick={this.AddNewGroup}
                                        />
                                    </div>
                                </Col>
                                <Col sm={4} style={{ maxWidth: '22.3333%' }} />
                            </Row>
                        </Grid>
                    </Grid>
                    <div className="footer-button">
                        <Button color="primary" onClick={() => this.onSave()}>
                            Save
                        </Button>
                        <Button color="dark">
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </Drawer>
            </div>
        );
    }
}
AddAnotherPrice.propTypes = {
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    showModal: Proptypes.bool,
    title: Proptypes.any,
    minAge: Proptypes.any,
    maxAge: Proptypes.any,
    amount: Proptypes.any,
    data: Proptypes.any
};

const mapStateToProps = () => ({});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddAnotherPrice));

const getMinValue = (index, groupPrices, max) => {
    if (index === 0) {
        return 2;
    }
    if (index === 1) {
        return max + 1;
    }
    return groupPrices[index - 2]?.max + 1;
};

const getMaxValue = (index, groupPrices) => {
    let min = 0;
    if (groupPrices[index]) {
        min = groupPrices[index].min - 1;
    }
    return min <= 0 ? null : min;
};

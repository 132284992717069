const initialState = {
    legal_data: null,
    homepage_content: null,
    uploaded_file: null,
    delete_file: null,
    files: null,
    mealsAndDrinks: [],
    mainProduct: null,
    itineraries: [],
    create_main_product: null,
    update_main_product: null,
    create_accomodation: null,
    c: null,
    create_itineraries: null,
    edit_itineraries: null,
    update_itineraries: null,
    delete_itineraries: null,
    file_upload_progress: {}
};

const contentDetail = (state = initialState, action) => {
    switch (action.type) {
        case 'LEGAL_CONTENT':
            state.legal_data = action.payload;
            return { ...state };

        case 'HOMEPAGE_CONTENT':
            state.homepage_content = action.payload;
            return { ...state };

        case 'FILE_UPLOAD':
            state.uploaded_file = action.payload;
            return { ...state };

        case 'DELETE_FILE':
            state.delete_file = action.payload;
            return { ...state };

        case 'GET_FILES':
            state.files = action.payload;
            return { ...state };

        case 'ADD_MEALS_AND_DRINKS':
            state.mealsAndDrinks = action.payload;
            return { ...state };

        case 'MAIN_PRODUCT':
            state.mainProduct = action.payload;
            return { ...state };

        case 'SET_ITINERARIES':
            state.itineraries = action.payload;
            return { ...state };

        case 'CREATE_MAIN_PRODUCT':
            state.create_main_product = action.payload;
            return { ...state };

        case 'UPDATE_MAIN_PRODUCT':
            state.update_main_product = action.payload;
            return { ...state };

        case 'CREATE_ACCOMODATION':
            state.create_accomodation = action.payload;
            return { ...state };

        case 'CREATE_ITINERARIES':
            state.create_itineraries = action.payload;
            return { ...state };

        case 'UPDATE_ITINERARIES':
            state.update_itineraries = action.payload;
            return { ...state };

        case 'EDIT_ITINERARIES':
            state.edit_itineraries = action.payload;
            return { ...state };

        case 'DELETE_ITINERARIES':
            state.delete_itineraries = action.payload;
            return { ...state };

        case 'FILE_UPLOAD_PROGRESS':
            return { ...state, file_upload_progress: action.payload };
        default:
            return state;
    }
};

export default contentDetail;

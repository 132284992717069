import React from 'react';
import ItenaryForm from '../../../pages/product-content/form/itenary/index.js';
import VendorDashboard from '../vendor-dashboard';
// import PropTypes from 'prop-types'

function Itenary() {
    return (
        <VendorDashboard>
            <ItenaryForm />
        </VendorDashboard>
    );
}

Itenary.propTypes = {};

export default Itenary;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailedPanel from './panel';
import { InclusionIcon } from './panel/icons';

const InclusionPanel = () => {
    return (
        <DetailedPanel panelIcon={<InclusionIcon />} panelTitle="Inclusions" isEditable={true}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Use of Equipment</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        Use of SCUBA equipment, Use of Segway, Use of bicycle
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Transportation Amenities
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        Air conditioned vehicle, Private Transportation, Restroom on Board
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Fee</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        Landing and facility fees, Airport departure tax, Fuel Surcharge
                    </span>
                </Col>
            </Row>
        </DetailedPanel>
    );
};

export default InclusionPanel;

import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

export function SelectCustomDropdown(props) {
    const {
        opened,
        dropdownRender,
        value,
        placeHolder = '',
        loading,
        dropdownMatchSelectWidth,
        onDropdownVisibleChange,
        className = ''
    } = props;

    return (
        <Select
            open={opened}
            dropdownRender={dropdownRender}
            loading={loading}
            className={`d-block ${className}`}
            placeholder={placeHolder}
            value={value}
            dropdownMatchSelectWidth={dropdownMatchSelectWidth}
            onDropdownVisibleChange={onDropdownVisibleChange}>
            <Select.Option key={'0'} value="0">
                &nbsp;
            </Select.Option>
        </Select>
    );
}

SelectCustomDropdown.propTypes = {
    opened: PropTypes.bool,
    dropdownRender: PropTypes.any,
    loading: PropTypes.bool,
    placeHolder: PropTypes.string,
    value: PropTypes.string,
    dropdownMatchSelectWidth: PropTypes.bool,
    onDropdownVisibleChange: PropTypes.func,
    className: PropTypes.string
};

const mapStateToProps = () => ({
    // options: state.profile.locations
});
const mapDispatch = () => ({
    // fetchLocations: (query) => dispatch(fetchLocations(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(SelectCustomDropdown));

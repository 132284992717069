import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import LineChart from './LineChart.jsx';

export default function Retention(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(151, 151, 151, 0.22)',
            borderRadius: '4px',
            padding: '21px 26px',
            height: '100%'
        },
        title: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: '#333333',
            marginBottom: '5px'
        },
        subTitle: {
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: 'rgba(51, 51, 51, 0.63)',
            marginBottom: '21px'
        },
        metric: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: 'rgba(51, 51, 51, 0.63)'
        },
        metricValue: {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px'
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>Net Retention (Monthly)</div>
            <div className={classes.subTitle}>
                This indicates how you have been able to keep up to maximising sales accross your
                customer calendars.
            </div>
            <Grid container style={{ marginBottom: '64px' }}>
                <Grid item md>
                    <p>
                        <span className={classes.metric}>Current Supply</span>
                        <br />
                        <span className={classes.metricValue} style={{ color: '#4CDED5' }}>
                            $6.8k
                        </span>
                    </p>
                </Grid>
                <Grid item md>
                    <p>
                        <span className={classes.metric}>Estimated Opportunity</span>
                        <br />
                        <span className={classes.metricValue} style={{ color: '#FF3D00' }}>
                            $4.74k
                        </span>
                    </p>
                </Grid>
                <Grid item md>
                    <p>
                        <span className={classes.metric}>Estimated Demand</span>
                        <br />
                        <span className={classes.metricValue} style={{ color: '#A2792C' }}>
                            $2.8k
                        </span>
                    </p>
                </Grid>
            </Grid>
            <Grid item>
                <LineChart />
            </Grid>
        </div>
    );
}

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { Form, Select } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Feedback, ConfirmationModal } from 'components/molecules';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    updateProductsCustomerBook,
    resetProductReducer,
    deleteAddOnProduct,
    getCalendarsById,
    attatchExistingProductAsAddon,
    duplicateExistingProductAsAddon,
    getAllCalendarProducts,
    setCurrentProduct_
} from 'store/actions';
import TimePeriodComponent from './time-period';
import SpecificTimePeriodComponent from './specific-time-period';
import {
    mapWithInTimePeriodArray,
    mapSpecificTimeObject,
    compareItineraryTime,
    stringToDate,
    validateWithInTimePeriod,
    validateSpecificPeriod
} from 'utils';
const AddItineraryTimeModal = (props) => {
    const {
        isModalOpen: addOnProductItineraryTimeModal,
        setModalOpenState: setAddOnProductItineraryTimeModal,
        updateAndContinue,
        setPreviousModalOpenState,
        selected_existing_product,
        setEditMainProductInitModal,
        currentProduct,
        package_detail,
        attatchExistingProductAsAddon,
        duplicateExistingProductAsAddon,
        get_calendar_data,
        calendar_products_list,
        attached_duplicated_product,
        current_product_flow,
        getAllCalendarProducts,
        setCurrentProduct_
    } = props;
    const [isConfirmationModalOpen, setConfirmationModalOpenState] = useState(false);
    const [bookActivityPeriodType, setbookActivityPeriodType] = useState();
    const [isCanceling, setIsCanceling] = useState(false);

    const [specificTimePeriod, setSpecificTimePeriod] = useState({});
    const [timePeriodsList, setTimePeriodsList] = useState([{}]);

    const [addOnProductSecondForm] = Form.useForm();
    const { Option } = Select;

    const periodType = {
        specificTime: 'specific_time',
        timePeriod: 'within_time_period',
        specificTimeAndTimePeriod: 'both'
    };

    const periodLabels = {
        specific_time: 'Suggest a specific time (only)',
        within_time_period: 'Allow customers to choose within a time period in the day (only)',
        both: 'Suggest a specific time & Allow customers to choose within a time period in the day'
    };

    useEffect(() => {
        if (selected_existing_product && currentProduct?.booking_time_periods) {
            const type = currentProduct.booking_time_periods?.type;
            setbookActivityPeriodType(type);
            addOnProductSecondForm.setFieldsValue({
                book_activity_period_type: type
            });
            if (
                (type === periodType.specificTime ||
                    type === periodType.specificTimeAndTimePeriod) &&
                currentProduct.booking_time_periods?.time_period
            ) {
                let fields = mapSpecificTimeObject(
                    currentProduct.booking_time_periods?.time_period
                );
                addOnProductSecondForm.setFieldsValue(fields);
                setSpecificTimePeriod(currentProduct.booking_time_periods?.time_period);
            }

            if (
                (type === periodType.timePeriod || type === periodType.specificTimeAndTimePeriod) &&
                currentProduct.booking_time_periods?.within_time_periods
            ) {
                let fields = mapWithInTimePeriodArray(
                    currentProduct.booking_time_periods?.within_time_periods
                );
                let hour = 0,
                    minute = 0;
                if (currentProduct?.booking_time_periods.duration) {
                    hour = Math.floor(currentProduct?.booking_time_periods.duration / 60);
                    minute = currentProduct?.booking_time_periods.duration % 60;
                }

                addOnProductSecondForm.setFieldsValue({
                    ...fields,
                    activity_duration_hour: hour,
                    activity_duration_minute: minute
                });

                setTimePeriodsList(currentProduct.booking_time_periods?.within_time_periods);
            }
        }
    }, [selected_existing_product, currentProduct?.updated_at]);

    const generalReset = ({
        resetForm,
        toggleCurrentModal,
        resetPreviousModal,
        openPreviousModal
    }) => {
        if (resetForm) {
            setbookActivityPeriodType(null);
            addOnProductSecondForm.resetFields();
        }
        if (toggleCurrentModal) {
            setAddOnProductItineraryTimeModal(!addOnProductItineraryTimeModal);
        }
        if (resetPreviousModal) {
            props.resetProductReducer({
                currentProduct: true
            });
        }
        if (openPreviousModal) {
            setPreviousModalOpenState(true);
        }
    };
    const handleGoBack = () => {
        let openPrevious = true;
        if (
            selected_existing_product &&
            currentProduct?.productType &&
            currentProduct?.productType == 'main_product'
        ) {
            openPrevious = false;
        }
        generalReset({
            resetForm: true,
            toggleCurrentModal: true,
            openPreviousModal: openPrevious
        });
        if (!openPrevious) {
            setEditMainProductInitModal(true);
        }
    };
    const handleCancelAddItineraryTime = () => {
        const { currentProduct, deleteAddOnProduct, getCalendarsById, get_calendar_data } = props;
        if (currentProduct && currentProduct?.id && !currentProduct?.title) {
            deleteAddOnProduct(currentProduct);
        }
        generalReset({ resetForm: true, toggleCurrentModal: true, resetPreviousModal: true });
        getCalendarsById(get_calendar_data.data.id);
        getAllCalendarProducts(get_calendar_data.data.id);
    };

    const getDataForSpecificTimeOnly = () => {
        let booking_time_periods = {};
        booking_time_periods.type = periodType.specificTime;
        booking_time_periods.time_period = specificTimePeriod;
        return booking_time_periods;
    };
    const getDataForWithinTimePeriodInADay = () => {
        const { activity_duration_hour, activity_duration_minute } =
            addOnProductSecondForm.getFieldsValue();
        let booking_time_periods = {
            type: periodType.timePeriod,
            within_time_periods: timePeriodsList,
            duration: activity_duration_hour * 60 + activity_duration_minute
        };

        return booking_time_periods;
    };
    const getDataForSuggestAndAllowCustomer = () => {
        const withInTimePeriod = getDataForWithinTimePeriodInADay();
        const specificTime = getDataForSpecificTimeOnly();
        let booking_time_periods = { ...withInTimePeriod, ...specificTime };
        booking_time_periods.type = periodType.specificTimeAndTimePeriod;

        return booking_time_periods;
    };

    function onChangeSpecificTimePeriod(value, type) {
        setSpecificTimePeriod((prev) => {
            return { ...prev, [type]: value };
        });
        addOnProductSecondForm.validateFields();
    }

    function onChangeTimePeriod(value, type, index) {
        let raw = JSON.parse(JSON.stringify(timePeriodsList));
        raw[index] = { ...raw[index], [type]: value };
        setTimePeriodsList(raw);

        addOnProductSecondForm.validateFields();
    }

    function onClickRemovePeriod(index) {
        let raw = JSON.parse(JSON.stringify(timePeriodsList));
        raw.splice(index, 1);
        setTimePeriodsList(raw);

        let list = {};
        for (let [index, value] of raw.entries()) {
            if (value && value.from) list[`from_${index}`] = stringToDate(value.from);
            if (value && value.to) list[`to_${index}`] = stringToDate(value.to);
        }
        addOnProductSecondForm.setFieldsValue(list);
    }

    const onFinish = (value) => {
        let data = {};
        let error = false;
        if (bookActivityPeriodType === periodType.specificTime) {
            data.booking_time_periods = getDataForSpecificTimeOnly();
            // if (data.booking_time_periods?.time_period)
            //     error = validateSpecificPeriod(data.booking_time_periods.time_period);
        } else if (bookActivityPeriodType === periodType.timePeriod) {
            data.booking_time_periods = getDataForWithinTimePeriodInADay();
            if (data.booking_time_periods?.within_time_periods) {
                let obj = validateWithInTimePeriod(
                    data.booking_time_periods.within_time_periods,
                    data.booking_time_periods.duration
                );
                if (obj?.status && obj?.error) addOnProductSecondForm.setFields(obj?.error);

                error = obj?.status || false;
            }
        } else if (bookActivityPeriodType === periodType.specificTimeAndTimePeriod) {
            data.booking_time_periods = getDataForSuggestAndAllowCustomer();
            //deleting duration in both enum
            if (data?.booking_time_periods) delete data.booking_time_periods.duration;
            if (data.booking_time_periods?.within_time_periods) {
                let obj = validateWithInTimePeriod(
                    data.booking_time_periods.within_time_periods,
                    data.booking_time_periods.duration,
                    data.booking_time_periods.time_period
                );
                if (obj?.status && obj?.error) addOnProductSecondForm.setFields(obj?.error);

                error = obj?.status || false;
            }
            if (data.booking_time_periods?.time_period && error === false) {
                //starting validation if periods has not error
                let obj = validateSpecificPeriod(
                    data.booking_time_periods.time_period,
                    data.booking_time_periods.within_time_periods
                );
                if (obj?.status && obj?.error) addOnProductSecondForm.setFields(obj?.error);

                error = obj?.status || false;
            }
        }

        if (!error) {
            if (
                selected_existing_product &&
                currentProduct?.id &&
                currentProduct?.booking_time_periods &&
                value !== 'saveData'
            ) {
                const compareData = compareItineraryTime(
                    currentProduct?.booking_time_periods,
                    data?.booking_time_periods,
                    periodType
                );

                if (compareData) return setConfirmationModalOpenState(true);
                //else return updateAndContinue();
            }
        }
        if (!error) {
            if (
                selected_existing_product &&
                currentProduct?.day_type === 'within_day' &&
                (current_product_flow?.title === 'attach-product' ||
                    current_product_flow?.title === 'duplicate-product' ||
                    current_product_flow?.title === 'duplicate-product-summary')
            ) {
                let apiType = checkAttachOrDuplicate();
                if (apiType === 'attach') {
                    attatchExistingProductAsAddon({
                        calendar_id: get_calendar_data?.data?.id,
                        product_id: currentProduct?.id
                    }).then((prod) => {
                        if (prod?.id) {
                            afterCheckAttachOrDuplicateOrJustUpdate(data, prod.id);
                            setCurrentProduct_(prod);
                        }
                    });
                } else {
                    duplicateExistingProductAsAddon({
                        calendar_id: get_calendar_data?.data?.id,
                        product_id: currentProduct?.id
                    }).then((prod) => {
                        if (prod?.id) {
                            afterCheckAttachOrDuplicateOrJustUpdate(data, prod?.id);
                            setCurrentProduct_(prod);
                        }
                    });
                }
            } //if a main product or a package flow
            else if (
                (currentProduct?.productType == 'main_product' &&
                    current_product_flow?.title === 'edit') ||
                Object.keys(package_detail).length > 0
            ) {
                afterCheckAttachOrDuplicateOrJustUpdate(data, currentProduct?.id);
            } else {
                afterCheckAttachOrDuplicateOrJustUpdate(data, currentProduct?.id);
            }
        }
    };

    const afterCheckAttachOrDuplicateOrJustUpdate = (data, id) => {
        let flag = true;
        if (
            selected_existing_product &&
            currentProduct?.id &&
            currentProduct?.booking_time_periods
        ) {
            const compareData = compareItineraryTime(
                currentProduct?.booking_time_periods,
                data?.booking_time_periods,
                periodType
            );
            if (!compareData) {
                flag = false;
            }
        }
        if (flag) {
            props.updateProductsCustomerBook(data, id).then(() => {
                updateAndContinue();
            });
        } else {
            updateAndContinue();
            // if (selected_existing_product) selectedExistingProduct({ id });
        }
    };
    const checkAttachOrDuplicate = () => {
        let filteredArray = [];
        let type = '';
        if (calendar_products_list && calendar_products_list.length > 0) {
            filteredArray = calendar_products_list.filter((it) => it.id == currentProduct?.id);
        }
        if (filteredArray.length === 0) {
            type = 'attach';
        } else {
            type = 'duplicate';
        }
        return type;
    };
    return (
        <>
            <Modal
                size="lg"
                centered
                scrollable
                isOpen={addOnProductItineraryTimeModal}
                toggle={() => {
                    generalReset({
                        resetForm: true,
                        toggleCurrentModal: false,
                        resetPreviousModal: true
                    });
                }}>
                <Form
                    className="d-flex flex-column h-100 form-label-fw-500"
                    layout="vertical"
                    requiredMark={false}
                    form={addOnProductSecondForm}
                    onFinish={onFinish}
                    preserve={false}>
                    <div className="modal-header flex-wrap flex-lg-nowrap">
                        <h5 className="modal-title order-2 order-lg-1">
                            What time of the day can customers book your product?
                        </h5>
                        <div
                            className="flex-shrink-0 order-1 order-lg-2 ml-lg-2"
                            hidden={
                                (package_detail && package_detail?.id) ||
                                current_product_flow?.title === 'duplicate-product-summary'
                            }>
                            <Button
                                color="link"
                                className="px-0 text-decoration-underline"
                                type="button"
                                onClick={() => {
                                    handleGoBack();
                                }}>
                                Go Back
                            </Button>
                        </div>
                    </div>
                    <ModalBody className="add_itenary_time">
                        {/* <Form> */}
                        <Form.Item
                            className="mb-3 mb-lg-4"
                            value={bookActivityPeriodType}
                            name="book_activity_period_type"
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select
                                className="form-control-alt d-block"
                                dropdownClassName="type-relaxed"
                                size="large"
                                placeholder="Select One"
                                onChange={(value) => {
                                    addOnProductSecondForm.resetFields();
                                    setbookActivityPeriodType(value);
                                    addOnProductSecondForm.setFieldsValue({
                                        book_activity_period_type: value
                                    });
                                }}>
                                <Option value={periodType.specificTime}>
                                    {periodLabels[periodType.specificTime]}
                                </Option>
                                <Option value={periodType.timePeriod}>
                                    {periodLabels[periodType.timePeriod]}
                                </Option>
                                <Option value={periodType.specificTimeAndTimePeriod}>
                                    {periodLabels[periodType.specificTimeAndTimePeriod]}
                                </Option>
                            </Select>
                        </Form.Item>
                        <div>
                            {bookActivityPeriodType === periodType.specificTime ||
                            bookActivityPeriodType === periodType.specificTimeAndTimePeriod ? (
                                <SpecificTimePeriodComponent
                                    bookActivityPeriodType={bookActivityPeriodType}
                                    onChangeSpecificTimePeriod={onChangeSpecificTimePeriod}
                                    specificTimePeriod={specificTimePeriod}
                                />
                            ) : null}
                            {bookActivityPeriodType === periodType.timePeriod ||
                            bookActivityPeriodType === periodType.specificTimeAndTimePeriod ? (
                                <TimePeriodComponent
                                    addOnProductSecondForm={addOnProductSecondForm}
                                    onChangeActivityDurationHour={(value) => {
                                        addOnProductSecondForm.setFieldsValue({
                                            activity_duration_hour: value
                                        });
                                        const { activity_duration_minute } =
                                            addOnProductSecondForm.getFieldsValue();
                                        if (!activity_duration_minute)
                                            addOnProductSecondForm.setFieldsValue({
                                                activity_duration_minute: 0
                                            });
                                    }}
                                    onChangeActivityDurationMinute={(value) => {
                                        addOnProductSecondForm.setFieldsValue({
                                            activity_duration_minute: value
                                        });
                                        addOnProductSecondForm.validateFields([
                                            'activity_duration_hour'
                                        ]);
                                    }}
                                    showActivityDuration={
                                        bookActivityPeriodType === periodType?.timePeriod
                                    }
                                    onChangeTimePeriod={onChangeTimePeriod}
                                    timePeriodsList={timePeriodsList}
                                    setTimePeriodsList={() =>
                                        setTimePeriodsList((prev) => {
                                            return [...prev, {}];
                                        })
                                    }
                                    onClickRemovePeriod={onClickRemovePeriod}
                                />
                            ) : null}
                        </div>
                        {/* </Form> */}
                    </ModalBody>
                    <ModalFooter className="justify-content-start">
                        <Button
                            disabled={props.loading}
                            color="primary"
                            type="submit"
                            className="btn-mw flex-grow-1 flex-lg-grow-0">
                            Update Calender & Continue
                            {props.loading && <Spinner className="ml-2" size="sm" color="light" />}
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="button"
                            onClick={() => {
                                setIsCanceling(true);
                                setConfirmationModalOpenState(true);
                            }}>
                            Cancel
                        </Button>
                        <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                    </ModalFooter>
                </Form>
            </Modal>
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={setConfirmationModalOpenState}
                description={
                    isCanceling
                        ? 'you want to cancel'
                        : 'This update will be reflected on all calendars that this product is associated with.'
                }
                handleConfirmation={() => {
                    isCanceling ? handleCancelAddItineraryTime() : onFinish('saveData');

                    setConfirmationModalOpenState(false);
                }}
            />
        </>
    );
};
AddItineraryTimeModal.propTypes = {
    isModalOpen: PropTypes.any,
    setModalOpenState: PropTypes.func,
    setPreviousModalOpenState: PropTypes.func,
    handleSecondCreateCalendar: PropTypes.func,
    updateAndContinue: PropTypes.func,
    resetProductReducer: PropTypes.func,
    updateProductsCustomerBook: PropTypes.func,
    currentProduct: PropTypes.object,
    deleteAddOnProduct: PropTypes.func,
    selected_existing_product: PropTypes.bool,
    get_calendar_data: PropTypes.object,
    getCalendarsById: PropTypes.func,
    loading: PropTypes.bool,
    setEditMainProductInitModal: PropTypes.func,
    package_detail: PropTypes.any,
    calendar_products_list: PropTypes.any,
    attatchExistingProductAsAddon: PropTypes.func,
    duplicateExistingProductAsAddon: PropTypes.func,
    attached_duplicated_product: PropTypes.any,
    current_product_flow: PropTypes.any,
    getAllCalendarProducts: PropTypes.func,
    setCurrentProduct_: PropTypes.func
};
const mapStateToProps = (state) => ({
    product_categories: state.calendar.product_categories,
    selected_existing_product: state.product.selected_existing_product,
    get_calendar_data: state.calendar.get_calendar_data,
    loading: state.loading,
    currentProduct: state.product.currentProduct,
    package_detail: state.product.package_detail,
    calendar_products_list: state.calendar.get_all_calendar_products?.data,
    attached_duplicated_product: state.product.attached_duplicated_product,
    current_product_flow: state.product.current_product_flow
});
const mapDispatch = (dispatch) => ({
    updateProductsCustomerBook: (data, id) => dispatch(updateProductsCustomerBook(data, id)),
    resetProductReducer: (data) => dispatch(resetProductReducer(data)),
    deleteAddOnProduct: (data) => dispatch(deleteAddOnProduct(data)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    attatchExistingProductAsAddon: (data) => dispatch(attatchExistingProductAsAddon(data)),
    duplicateExistingProductAsAddon: (data) => dispatch(duplicateExistingProductAsAddon(data)),
    getAllCalendarProducts: (id) => dispatch(getAllCalendarProducts(id)),
    setCurrentProduct_: (data) => dispatch(setCurrentProduct_(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddItineraryTimeModal));

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import Info from '../../../../assets/images/info.svg';
import Plus from '../../../../assets/images/plus.svg';
import { NavLink } from 'react-router-dom';

export default function PropLayout(props) {
    return (
        <div className="container-border container" style={{ marginTop: props.top }}>
            <p style={{ marginBottom: 0, marginTop: 10, fontWeight: 900 }}>
                {props.text}
                <span>
                    <img style={{ paddingBottom: '20px' }} src={Info} alt="" />
                </span>
            </p>
            <hr style={{ marginBottom: 5, marginTop: 5 }} />
            <div className="d-flex" style={{ marginTop: 10, marginBottom: 10 }}>
                {props.isSelected ? (
                    <NavLink to={props.navlink}>
                        <img onClick={props.onClick} src={Plus} alt="" />
                    </NavLink>
                ) : (
                    <img onClick={props.onClick} src={Plus} alt="" />
                )}
                <p
                    style={{
                        marginBottom: '0',
                        paddingLeft: '10px',
                        fontSize: '13px',
                        color: 'rgb(162, 121, 44)',
                        paddingTop: props.number
                    }}>
                    {props.subtext}
                </p>
            </div>
        </div>
    );
}

PropLayout.propTypes = {
    top: PropTypes.string,
    text: PropTypes.string,
    isSelected: PropTypes.bool,
    navlink: PropTypes.string,
    onClick: PropTypes.func,
    number: PropTypes.string,
    subtext: PropTypes.string
};

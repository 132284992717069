import React from 'react';
import PropTypes from 'prop-types';
import ClashingRed from 'assets/images/clashing-red.svg';
import ClashingBlack from 'assets/images/clashing-black.svg';

function ClashIcon({ primary, size }) {
    size = size || 24;
    return (
        <img
            alt="clash"
            src={primary ? ClashingRed : ClashingBlack}
            style={{ height: size, width: size }}
        />
    );
}

ClashIcon.propTypes = {
    primary: PropTypes.bool,
    size: PropTypes.any
};

export default ClashIcon;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ArrowRight from '../../../assets/images/right-arrow.png';
import './index.scss';
import Display from '../../atoms/display';
import SelectorOption from './selector-option';

function Selector(props) {
    const { title, options, onSelect } = props;
    const [isExpanded, setExpandedState] = useState(false);
    const [selected, setSelected] = useState([]);
    const expandOptions = () => {
        onSelect && onSelect(title, !isExpanded);
        setExpandedState(!isExpanded);
    };
    const handleSelect = (name) => {
        if (name in selected) {
            let _selected = [...selected];
            _selected.splice(_selected.indexOf(name), 1);
            setSelected([selected]);
        } else {
            setSelected([...selected, name]);
        }
    };
    return (
        <div className="atc-selector">
            <div onClick={expandOptions} className="atc-selector__main">
                <h5 className="atc-selector__title">{title}</h5>
                <img alt="" className="atc-selector__img" src={ArrowRight}></img>
            </div>
            <Display show={isExpanded}>
                <div className="atc-selector__options">
                    {options.map(({ icon, name }) => (
                        <SelectorOption
                            key={icon}
                            icon={icon}
                            name={name}
                            selected={selected}
                            handleSelect={handleSelect}
                        />
                    ))}
                </div>
            </Display>
        </div>
    );
}

Selector.propTypes = {
    title: PropTypes.any,
    options: PropTypes.array,
    onSelect: PropTypes.any
};

export default Selector;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';
import { DatePicker } from 'antd';
import DownArrow from '../../../assets/images/downArrow.svg';
import './index.scss';
import Proptypes from 'prop-types';
const { RangePicker } = DatePicker;

export default class Index extends PureComponent {
    static propTypes = {};

    state = {
        isOpen: false,
        from: null,
        to: null
    };
    componentDidMount() {
        const { start, end } = this.props;
        if (start) this.setState({ from: start });
        if (end) this.setState({ to: end });
    }

    //select range date
    handleOnChange = (change) => {
        this.props.onChange &&
            this.props.onChange(change[0].format('DD-MM-YY'), change[1].format('DD-MM-YY'));
        this.setState({
            isOpen: false,
            from: change[0].format('DD-MM-YY'),
            to: change[1].format('DD-MM-YY')
        });
    };

    render() {
        return (
            <div className="range-date-picker">
                <div className="range-picker" onClick={() => this.setState({ isOpen: true })}>
                    <div className="range-picker__from">
                        {!this.state.from ? <span>Start</span> : <span>{this.state.from}</span>}
                        <img src={DownArrow} alt="" />
                    </div>
                    <div className="range-picker__to">
                        {!this.state.to ? <span>End</span> : <span>{this.state.to}</span>}
                        <img src={DownArrow} alt="" />
                    </div>
                </div>
                <RangePicker onChange={this.handleOnChange} open={this.state.isOpen} />
            </div>
        );
    }
}
Index.propTypes = {
    onChange: Proptypes.func,
    start: Proptypes.string,
    end: Proptypes.string
};

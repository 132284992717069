import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'antd';
// import PropTypes from 'prop-types'
import './index.scss';
import Spacer from '../../../atoms/spacer';
import DaysAccordion from '../../../molecules/days-accodion';

function OtherVendorText() {
    const [state] = useState({
        days: [
            {
                day: 'day 1',
                open: false,
                actionLink: '/guide/details',
                titleText: 'Ventor‘s text',
                subTitleText: 'Add a ‘Ventor‘s text'
            },
            {
                day: 'day 2',
                open: false,
                actionLink: '/guide/other-vendor-text',
                titleText: 'Ventor‘s text',
                subTitleText: 'Add a ‘Ventor‘s text'
            }
        ]
    });
    return (
        <div className="other-vendor-text">
            <Row justify="center">
                <Col span={22}>
                    <Form layout="vertical">
                        <Spacer top={89} />
                        <h4>Tell us about your ‘Other Ventor‘s text’</h4>
                        <Spacer top={3} />
                        <p>
                            Be sure to enter all key attractions in your itinerary. This helps
                            travelers find and book your experience.
                        </p>
                        <Spacer top={13} />
                        <DaysAccordion
                            days={state.days}
                            newTitleText="Ventor‘s text"
                            newSubtitleText="Add a ‘Ventor‘s text"
                        />
                        <Button
                            style={{ height: 42 }}
                            className="mt-50px"
                            onClick={() => {}}
                            type="primary"
                            shape="round">
                            Update Calender & Continue
                        </Button>
                        <Spacer top={81} />
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

OtherVendorText.propTypes = {};

export default OtherVendorText;

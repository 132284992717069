/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'antd';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { ComponentPermissionContext } from 'context/rolesandpermission';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';

export default function NotificationsGroup(props) {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const [notificationsPopoverVisible, setNotificationsPopoverVisible] = useState(false);
    const [viewALlNotificationsModal, setViewALlNotificationsModal] = useState(false);
    const clearIcon = (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.54004 0C3.82395 0 0 3.82395 0 8.54004C0 13.2561 3.82395 17.0801 8.54004 17.0801C13.2561 17.0801 17.0801 13.2561 17.0801 8.54004C17.0801 3.82395 13.2561 0 8.54004 0ZM8.54004 15.6313C4.62458 15.6313 1.44876 12.4555 1.44876 8.54004C1.44876 4.62458 4.62458 1.44876 8.54004 1.44876C12.4555 1.44876 15.6313 4.62458 15.6313 8.54004C15.6313 12.4555 12.4555 15.6313 8.54004 15.6313Z"
                fill="white"
            />
            <path
                d="M12.065 5.76432C12.065 5.68044 11.9964 5.61182 11.9125 5.61182L10.6544 5.61754L8.75955 7.87645L6.86664 5.61944L5.6066 5.61372C5.52273 5.61372 5.4541 5.68044 5.4541 5.76622C5.4541 5.80244 5.46745 5.83676 5.49032 5.86535L7.97036 8.82005L5.49032 11.7728C5.46729 11.8008 5.4545 11.8358 5.4541 11.872C5.4541 11.9558 5.52273 12.0245 5.6066 12.0245L6.86664 12.0188L8.75955 9.75983L10.6525 12.0168L11.9106 12.0226C11.9945 12.0226 12.0631 11.9558 12.0631 11.8701C12.0631 11.8338 12.0498 11.7995 12.0269 11.7709L9.55065 8.81814L12.0307 5.86344C12.0536 5.83676 12.065 5.80054 12.065 5.76432Z"
                fill="white"
            />
        </svg>
    );
    return (
        <div className={`${assignComponentPermission('notification')}`}>
            <Popover
                overlayClassName="popover-container popover-notifications"
                placement={`${props.popoverPlacement ? props.popoverPlacement : 'bottomRight'}`}
                content={
                    <div className="notifications-renderer-wrapper ">
                        <div className="text-right">
                            <span role="button" tabIndex="0" aria-label="Clear All Notifications">
                                {/* {clearIcon} */}
                            </span>
                        </div>
                        <div className="notifications-renderer">
                            <div
                                className="notification-single new"
                                role="button"
                                tabIndex="0"
                                onClick={() => {
                                    setNotificationsPopoverVisible(false);
                                    setViewALlNotificationsModal(true);
                                }}>
                                <div>
                                    <div className="notification-title">
                                        A customer is trying to access this calendar, Please Top-up
                                        your customer token.
                                    </div>
                                    <div className="notification-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        aria-label="Clear Notification"
                                        onClick={(e) => e.stopPropagation()}>
                                        {clearIcon}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="notification-single"
                                role="button"
                                tabIndex="0"
                                onClick={() => {
                                    setNotificationsPopoverVisible(false);
                                    setViewALlNotificationsModal(true);
                                }}>
                                <div>
                                    <div className="notification-title">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s
                                    </div>
                                    <div className="notification-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        aria-label="Clear Notification"
                                        onClick={(e) => e.stopPropagation()}>
                                        {clearIcon}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="notification-single"
                                role="button"
                                tabIndex="0"
                                onClick={() => {
                                    setNotificationsPopoverVisible(false);
                                    setViewALlNotificationsModal(true);
                                }}>
                                <div>
                                    <div className="notification-title">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s
                                    </div>
                                    <div className="notification-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        aria-label="Clear Notification"
                                        onClick={(e) => e.stopPropagation()}>
                                        {clearIcon}
                                    </span>
                                </div>
                            </div>
                            <div
                                className="notification-single"
                                role="button"
                                tabIndex="0"
                                onClick={() => {
                                    setNotificationsPopoverVisible(false);
                                    setViewALlNotificationsModal(true);
                                }}>
                                <div>
                                    <div className="notification-title">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard dummy text ever since the 1500s
                                    </div>
                                    <div className="notification-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        role="button"
                                        tabIndex="0"
                                        aria-label="Clear Notification"
                                        onClick={(e) => e.stopPropagation()}>
                                        {clearIcon}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div className="text-right" style={{ paddingRight: 15 }}>
                            <span
                                className="d-inline-block text-secondary fs-14"
                                role="button"
                                tabIndex="0"
                                onClick={() => {
                                    setNotificationsPopoverVisible(false);
                                    setViewALlNotificationsModal(true);
                                }}>
                                View All Notifications
                            </span>
                        </div>
                    </div>
                }
                trigger="click"
                visible={notificationsPopoverVisible}
                onVisibleChange={() => setNotificationsPopoverVisible(!notificationsPopoverVisible)}
                // getPopupContainer={(trigger) => trigger.parentElement}
            >
                <span
                    className={`calendar-notification-wrapper ${assignComponentPermission(
                        props.permission ?? 'notification'
                    )}`}
                    role="button"
                    tabIndex="0">
                    <span className="calendar-notification-icon">
                        <svg
                            width="23"
                            height="27"
                            viewBox="0 0 23 27"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M21.5564 18.103C21.077 16.7041 20.8321 15.2408 20.8307 13.768V10.1287C20.8655 7.82277 20.0095 5.58631 18.4282 3.85204C16.8522 2.11504 14.6649 0.998187 12.2854 0.715512C10.9705 0.584367 9.63281 0.715512 8.36334 1.12898C7.09387 1.52059 5.93809 2.17449 4.96419 3.04696C3.98545 3.89693 3.20534 4.93678 2.67536 6.09789C2.14275 7.25854 1.86475 8.51229 1.85873 9.78085V13.788C1.85595 15.2548 1.61101 16.7119 1.13305 18.1049L0 21.327L0.907576 22.5255H7.54861C7.54861 23.4836 7.95598 24.4198 8.65892 25.0974C9.35997 25.7713 10.3358 26.1648 11.3343 26.1648C12.3309 26.1648 13.3048 25.7732 14.0077 25.0974C14.7107 24.4216 15.12 23.4836 15.12 22.5255H21.761L22.6667 21.327L21.5564 18.103ZM12.6701 23.8351C12.4957 24.0061 12.288 24.1422 12.0587 24.2356C11.8295 24.3291 11.5833 24.3781 11.3343 24.3797C11.0848 24.3781 10.8382 24.3289 10.6086 24.2352C10.379 24.1414 10.171 24.0048 9.9966 23.8333C9.81845 23.6659 9.67651 23.4662 9.57895 23.2458C9.4814 23.0255 9.43016 22.7887 9.42818 22.5492H13.2157C13.2236 22.7882 13.1793 23.0261 13.0857 23.2476C12.992 23.4691 12.8511 23.6692 12.672 23.8351H12.6701ZM2.22062 20.7205L2.92357 18.6914C3.47174 17.1106 3.75506 15.4561 3.76294 13.7899V9.78085C3.76294 8.75538 3.9903 7.75358 4.42041 6.83739C4.85051 5.90117 5.46251 5.07241 6.2564 4.39666C7.05029 3.70086 7.98061 3.17629 8.97723 2.8721C9.9966 2.54424 11.0614 2.43496 12.1035 2.54424C14.0135 2.78567 15.7646 3.69315 17.0242 5.09427C18.2841 6.49713 18.9615 8.29751 18.9265 10.1506V13.8117C18.9265 15.4674 19.1993 17.1231 19.7658 18.7132L20.4688 20.7405H2.22252V20.7186L2.22062 20.7205Z"
                                fill="#A3792D"
                            />
                        </svg>
                    </span>
                    <span className="calendar-notification-count">1</span>
                </span>
            </Popover>

            {/* All notifications modal popup */}
            <Modal centered scrollable size="lg" isOpen={viewALlNotificationsModal}>
                <ModalHeader
                    className="close-alt"
                    toggle={() => setViewALlNotificationsModal(!viewALlNotificationsModal)}>
                    Notifications
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div className="mp-notification-day-block">
                            <h5 className="mp-notification-day-title">Today</h5>
                            <div className="mp-notification-day-all-single">
                                <div className="mp-notification-content">
                                    <div className="mp-notification-single-title">
                                        A customer is trying to access this calendar, Please Top-up
                                        your customer token.
                                    </div>
                                    <div className="mp-notification-single-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div className="flex-shrink-0">
                                    <Button color="primary" size="sm" className="fs-13 fw-500">
                                        Add Top-up
                                    </Button>
                                </div>
                                <div>
                                    <span
                                        className="clost-btn-alt"
                                        role="button"
                                        tabIndex="0"></span>
                                </div>
                            </div>
                        </div>
                        <div className="mp-notification-day-block">
                            <h5 className="mp-notification-day-title">Earlier</h5>
                            <div className="mp-notification-day-all-single">
                                <div className="mp-notification-content">
                                    <div className="mp-notification-single-title">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard
                                    </div>
                                    <div className="mp-notification-single-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        className="clost-btn-alt"
                                        role="button"
                                        tabIndex="0"></span>
                                </div>
                            </div>
                            <div className="mp-notification-day-all-single">
                                <div className="mp-notification-content">
                                    <div className="mp-notification-single-title">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard
                                    </div>
                                    <div className="mp-notification-single-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        className="clost-btn-alt"
                                        role="button"
                                        tabIndex="0"></span>
                                </div>
                            </div>
                            <div className="mp-notification-day-all-single">
                                <div className="mp-notification-content">
                                    <div className="mp-notification-single-title">
                                        Lorem Ipsum is simply dummy text of the printing and
                                        typesetting industry. Lorem Ipsum has been the industry's
                                        standard
                                    </div>
                                    <div className="mp-notification-single-time-stamp">
                                        Mar 22, 2021. 10:00am
                                    </div>
                                </div>
                                <div>
                                    <span
                                        className="clost-btn-alt"
                                        role="button"
                                        tabIndex="0"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
}

NotificationsGroup.propTypes = {
    popoverPlacement: PropTypes.string,
    permission: PropTypes.string
};

import React from 'react';
// import PropTypes from 'prop-types';
import { Tabs } from 'antd';
import PremiumOffer from './premium-offer';
import Offer from './offer';
import './index.scss';

const { TabPane } = Tabs;

const PremiumOfferTitle = () => {
    return (
        <div>
            <h6>Premium Offer</h6>
            <h5>$300 or $25 Monthly pp</h5>
            <h6>
                Price:
                <span className="is-red">
                    <span className="has-strike-through is-red"> $ 900 </span>
                    (67% off)
                </span>
            </h6>
        </div>
    );
};

const OfferTitle = () => {
    return (
        <div>
            <h6>Offer</h6>
            <h5>$ 450 pp</h5>
            <h6>
                Price:
                <span className="is-red">
                    <span className="has-strike-through is-red"> $ 900 </span>
                    (50% off)
                </span>
            </h6>
        </div>
    );
};

function OfferPrices() {
    return (
        <div className="offer-prices">
            <Tabs type="card">
                <TabPane tab={<PremiumOfferTitle />} key="1">
                    <PremiumOffer />
                </TabPane>
                <TabPane tab={<OfferTitle />} key="2">
                    <Offer />
                </TabPane>
            </Tabs>
        </div>
    );
}

OfferPrices.propTypes = {};

export default OfferPrices;

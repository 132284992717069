/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Avatar, Col, Input, List, Row, Select } from 'antd';
import { DatePicker } from 'antd';
import './CustomerMessages.scss';
import star_icon from '../../../../../../assets/images/star.svg';
import envelop_icon from '../../../../../../assets/images/envelop.svg';
import bin_icon from '../../../../../../assets/images/bin.svg';
import archive_icon from '../../../../../../assets/images/archive.svg';
import label_icon from '../../../../../../assets/images/label.svg';
import users_icon from '../../../../../../assets/images/users.svg';
import mapmarker_icon from '../../../../../../assets/images/mapmarker.svg';
import attachment_icon from '../../../../../../assets/images/attachment.svg';
import magnifyingglass_icon from '../../../../../../assets/images/magnifyingglass.svg';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MessagePreview from '../../../../messagePreview/MessagePreview';
import FullMessage from '../../../../../atoms/fullMessage/FullMessage';
import { SmileOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker;

export default function CustomerMessages({ isAdmin }) {
    const data = [
        {
            title: 'Ant Design Title 1'
        },
        {
            title: 'Ant Design Title 2'
        },
        {
            title: 'Ant Design Title 3'
        },
        {
            title: 'Ant Design Title 4'
        },
        {
            title: 'Ant Design Title 1'
        },
        {
            title: 'Ant Design Title 2'
        },
        {
            title: 'Ant Design Title 3'
        },
        {
            title: 'Ant Design Title 4'
        },
        {
            title: 'Ant Design Title 1'
        },
        {
            title: 'Ant Design Title 2'
        },
        {
            title: 'Ant Design Title 3'
        },
        {
            title: 'Ant Design Title 4'
        }
    ];
    const dropdownItems = isAdmin
        ? [
              {
                  text: 'All Cases',
                  value: 'All Cases'
              },
              {
                  text: 'Open Cases',
                  value: 'Open Cases'
              },
              {
                  text: 'Closed Cases',
                  value: 'Closed Cases'
              }
          ]
        : [
              {
                  text: 'All Conversations',
                  value: 'All Conversations'
              },
              {
                  text: 'Unread',
                  value: 'Unread'
              },
              {
                  text: 'Starred',
                  value: 'Starred'
              },
              {
                  text: 'Archived',
                  value: 'Archived'
              }
          ];
    const [selectedChat, setselectedChat] = useState(0);
    return (
        <div className="customer-messages">
            <Row gutter={20} style={{ padding: '0 30px' }}>
                <Col lg={6} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Select size="large" style={{ width: '100%' }} />
                </Col>
                <Col lg={6} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Select size="large" style={{ width: '100%' }} />
                </Col>
                <Col lg={8} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <RangePicker size="large" style={{ width: '100%' }} />
                </Col>
                <Col lg={4} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <Select size="large" style={{ width: '100%' }} />
                </Col>
            </Row>
            <Row style={{ border: '1px solid rgba(151, 151, 151, 0.22)', marginTop: 25 }}>
                <Col xs={24} sm={12} md={6}>
                    <Select
                        options={dropdownItems}
                        bordered={false}
                        size="large"
                        style={{ width: '60%', marginTop: 20 }}
                    />
                    <img
                        src={magnifyingglass_icon}
                        style={{
                            float: 'right',
                            marginTop: 27,
                            marginRight: 20,
                            width: 25
                        }}
                        alt=""
                    />
                </Col>
                <Col xs={24} md={18}>
                    <div className="mobile-row">
                        <div
                            className="pt15 pl20"
                            style={{
                                borderLeft: '1px solid rgba(151, 151, 151, 0.22)'
                            }}>
                            <p className="mb0" style={{ position: 'relative', top: 5 }}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        width: 12,
                                        height: 12,
                                        borderRadius: '50%',
                                        border: '1px solid #A2792C',
                                        marginRight: 5,
                                        position: 'relative',
                                        top: 0
                                    }}></div>
                                <strong>Buki</strong>
                            </p>
                            <p
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                    color: 'rgba(79, 79, 79, 0.63)',
                                    marginTop: 5
                                }}>
                                <span
                                    style={{
                                        paddingRight: 23,
                                        borderRight: '1px solid rgba(79, 79, 79, 0.63)'
                                    }}>
                                    Offline
                                </span>
                                <span style={{ paddingLeft: 23 }}>Local time Aug 25, 4:39 AM</span>
                            </p>
                        </div>
                        <div className="wide">
                            <span
                                style={{
                                    borderRight: '1px solid rgba(151, 151, 151, 0.22)',
                                    paddingRight: 12
                                }}>
                                <img
                                    style={{ margin: '0 5px', width: 20 }}
                                    src={star_icon}
                                    alt=""
                                />
                            </span>
                            <span style={{ padding: '0 12px' }}>
                                <img
                                    style={{ margin: '0 5px', width: 20 }}
                                    src={envelop_icon}
                                    alt=""
                                />
                                <img
                                    style={{ margin: '0 5px', width: 20 }}
                                    src={archive_icon}
                                    alt=""
                                />
                                <img style={{ margin: '0 5px', width: 20 }} src={bin_icon} alt="" />
                            </span>
                            <span
                                style={{
                                    borderLeft: '1px solid rgba(151, 151, 151, 0.22)',
                                    paddingLeft: 12
                                }}>
                                <img
                                    style={{ margin: '0 5px', width: 20 }}
                                    src={label_icon}
                                    alt=""
                                />
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    md={6}
                    style={{
                        border: '1px solid rgba(151, 151, 151, 0.22)',
                        height: 600,
                        overflow: 'scroll'
                    }}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                            <div onClick={() => setselectedChat(index)}>
                                <MessagePreview
                                    isAdmin={isAdmin}
                                    index={index}
                                    selected={selectedChat === index}
                                    setselectedChat={setselectedChat}
                                />
                            </div>
                        )}
                    />
                </Col>
                <Col
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    md={11}
                    style={{
                        borderRight: '1px solid rgba(151, 151, 151, 0.22)',
                        borderBottom: '1px solid rgba(151, 151, 151, 0.22)',
                        height: 600,
                        overflow: 'hidden'
                    }}>
                    <div
                        style={{
                            position: 'relative',
                            height: 500,
                            overflow: 'scroll'
                        }}>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item) => (
                                <FullMessage
                                    img={'https://randomuser.me/api/portraits/men/86.jpg'}
                                    date={'Aug 24, 5:19 PM'}
                                    item={item}
                                    message={`
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis neque voluptas sed, molestiae numquam delectus voluptatibus aliquam veniam vero, repellat at velit magnam consequatur, ex ducimus ullam cumque autem fuga.`}
                                />
                            )}
                        />
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '100%',
                            backgroundColor: '#fff',
                            padding: '15px 20px'
                        }}>
                        <Input
                            style={{
                                width: '100%',
                                height: 40,
                                marginBottom: 12,
                                borderRadius: 8,
                                border: '1px solid rgba(162, 121, 44, 0.22)'
                            }}
                        />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                            }}>
                            <div>
                                <SmileOutlined
                                    style={{
                                        fontSize: '22px',
                                        color: '#979797',
                                        cursor: 'pointer'
                                    }}
                                />
                                <img
                                    src={attachment_icon}
                                    alt=""
                                    className="ml15 pointer"
                                    style={{ marginTop: -10 }}
                                />
                            </div>
                            <button
                                style={{ border: 0, backgroundColor: '#fff', color: '#4F4F4F' }}>
                                send
                            </button>
                        </div>
                    </div>
                </Col>
                <Col
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    md={7}
                    style={{
                        borderRight: '1px solid rgba(151, 151, 151, 0.22)',
                        height: 600,
                        overflow: 'hidden'
                    }}>
                    {/* isAdmin toggles content based on users role and active tab */}
                    {!isAdmin ? (
                        <div>
                            <div className="p20">
                                <p style={{ fontSize: 16, fontWeight: '600', marginBottom: 4 }}>
                                    Upcoming Booking
                                    <span style={{ fontSize: 14, fontWeight: '300' }}>
                                        Past Bookings (1)
                                    </span>
                                </p>
                                <p style={{ fontSize: 14 }}>
                                    <strong>1 Week Calendar:</strong> Victoria falls & Chobe
                                    Calendar Trip (013529)
                                </p>
                                <p className="fs14 mb4">
                                    <strong>No. of Products/Activities Booked:</strong> 3
                                </p>
                                <p className="fs14 mb4">
                                    <strong>Date: </strong> 12th of August 2021
                                </p>
                                <p className="fs14 mb4">
                                    <strong>No. of Travellers: </strong> 3 Adult, 1 Child
                                </p>
                            </div>

                            <hr />

                            <div style={{ textAlign: 'center', paddingTop: 60 }}>
                                <Avatar
                                    size="large"
                                    style={{ width: 80, height: 80, marginBottom: 15 }}
                                    src={`https://randomuser.me/api/portraits/women/${30}.jpg`}
                                />
                                <p className="mb40" style={{ fontWeight: '600' }}>
                                    <strong>Buki</strong>
                                </p>
                                <p
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        padding: '0 30px',
                                        marginBottom: 12
                                    }}>
                                    from{' '}
                                    <span style={{ float: 'right', fontWeight: 600 }}>
                                        United States
                                    </span>
                                </p>
                                <p
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        padding: '0 30px',
                                        marginBottom: 0
                                    }}>
                                    Language{' '}
                                    <span style={{ float: 'right', fontWeight: 600 }}>English</span>
                                </p>
                                <p
                                    style={{
                                        width: '100%',
                                        textAlign: 'left',
                                        padding: '0 30px',
                                        marginBottom: 0
                                    }}>
                                    Feedback{' '}
                                    <span style={{ float: 'right', fontWeight: 600 }}>
                                        {' '}
                                        <strong>5.0</strong>{' '}
                                        <span style={{ fontWeight: '300' }}>(21)</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div
                                style={{
                                    borderBottom: '0.5px solid rgba(151, 151, 151, 0.22)',
                                    padding: '30px',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                <Avatar
                                    src={`https://randomuser.me/api/portraits/women/${30}.jpg`}
                                    style={{ width: 70, height: 70 }}
                                />
                                <div style={{ paddingLeft: 12 }}>
                                    <p
                                        style={{
                                            fontSize: 20,
                                            fontWeight: '600',
                                            marginBottom: 0,
                                            marginTop: 12
                                        }}>
                                        Buki
                                    </p>
                                    <p>ATC Admin Manager</p>
                                </div>
                            </div>
                            <div style={{ padding: 30 }}>
                                <p>
                                    <img className="mr10" src={mapmarker_icon} alt="" /> Case
                                    number: 0797451113
                                </p>
                                <p>
                                    <img className="mr10" src={users_icon} alt="" /> Case title:
                                    Refunds - Customer resolution
                                </p>
                                <p>
                                    <img className="mr10" src={users_icon} alt="" /> Related
                                    customer booking (if applicable):
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    <b>1 Week Calendar:</b> Victoria falls & Chobe Calendar Trip
                                    (013529)
                                </p>
                                <p style={{ paddingLeft: 30 }}>
                                    No. of Products/Activities Booked: 3
                                </p>
                                <p style={{ paddingLeft: 30 }}>Date: 12th of August 2021</p>
                                <p style={{ paddingLeft: 30 }}>
                                    No. of Travellers: 3 Adult, 1 Child
                                </p>
                            </div>
                        </div>
                    )}
                </Col>
            </Row>
        </div>
    );
}
CustomerMessages.propTypes = {
    isAdmin: PropTypes.bool
};

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { DatePicker, Drawer, Form, Select } from 'antd';
import Feedback from '../../user-feedback';
import DownloadIcon from '../../../atoms/icons/download-icon';
import TiltedEmailIcon from '../../../atoms/icons/tilted-email-icon';
import { saveSettings } from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { API_URL } from 'utils/constants.js';

const DownloadSettingsDrawer = (props) => {
    const { visible, onClose, saveSettings, vendor_email } = props;
    const [downloadDetailsForm] = Form.useForm();
    const [emailBookingForm] = Form.useForm();
    const onFinishDownloadDetails = () => {
        let dateRange = downloadDetailsForm.getFieldsValue().dateRange;
        //let dateType = downloadDetailsForm.getFieldsValue().dateType;
        let format = downloadDetailsForm.getFieldsValue().format;
        let startDate = dateRange[0].format('YYYY-MM-DD');
        let endDate = dateRange[1].format('YYYY-MM-DD');
        let paramData = `format=${format}&startDate=${startDate}&endDate=${endDate}`;
        downloadFile(paramData, format);
    };

    const downloadFile = async (paramData, format) => {
        const downloader = document.createElement('a');
        await fetch(`${API_URL}/calendar-bookings/download-booking-summary?${paramData}`, {
            headers: {
                authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => res.blob())
            .then((res) => URL.createObjectURL(res))
            .then((res) => (downloader.href = res))
            .then((downloader.download = `filing.${format == 'csv' ? 'csv' : 'xlsx'}`))
            .then(() => downloader.click())
            .catch((e) => {
                console.log(e);
            });
    };

    const onCloseDrawer = () => {
        downloadDetailsForm.resetFields();
        emailBookingForm.resetFields();
        onClose();
    };

    const onFinishSettings = () => {
        let data = {
            email: emailBookingForm.getFieldsValue().emailReportTo,
            format: emailBookingForm.getFieldsValue().format,
            frequency: emailBookingForm.getFieldsValue().frequency,
            days: emailBookingForm.getFieldsValue().bookingVisibility
        };
        saveSettings(data);
    };

    return (
        <Drawer
            width={609}
            placement="right"
            className="basic-drawer"
            closeIcon={
                <span role="img" aria-label="close" className="anticon anticon-close">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1988 11.9999L23.5439 2.65453C24.1521 2.04665 24.1521 1.06379 23.5439 0.45591C22.936 -0.15197 21.9532 -0.15197 21.3453 0.45591L11.9999 9.80132L2.65474 0.45591C2.04658 -0.15197 1.064 -0.15197 0.456124 0.45591C-0.152041 1.06379 -0.152041 2.04665 0.456124 2.65453L9.80125 11.9999L0.456124 21.3453C-0.152041 21.9532 -0.152041 22.9361 0.456124 23.544C0.759068 23.8472 1.15739 23.9995 1.55543 23.9995C1.95347 23.9995 2.35152 23.8472 2.65474 23.544L11.9999 14.1986L21.3453 23.544C21.6485 23.8472 22.0466 23.9995 22.4446 23.9995C22.8426 23.9995 23.2407 23.8472 23.5439 23.544C24.1521 22.9361 24.1521 21.9532 23.5439 21.3453L14.1988 11.9999Z"
                            fill="black"
                        />
                    </svg>
                </span>
            }
            onClose={onCloseDrawer}
            visible={visible}>
            <div className="d-flex flex-column h-100">
                <div>
                    <h4 className="mb-3">Download Settings</h4>
                </div>

                <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed pr-2">
                    <Form
                        className="form-label-fw-500"
                        form={downloadDetailsForm}
                        requiredMark={false}
                        onFinish={onFinishDownloadDetails}
                        layout="vertical">
                        <div className="d-flex align-items-start flex-wrap">
                            <h5 className="fs-18 fw-600 mr-2 flex-grow-1">
                                <span className="text-primary mr-2">
                                    <DownloadIcon />
                                </span>
                                <span>Download Booking Details</span>
                            </h5>
                        </div>
                        <Form.Item
                            name="dateType"
                            label="Show Only"
                            initialValue=""
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select className="d-block" size="large" placeholder="Choose One">
                                <Select.Option value="" disabled>
                                    Choose One
                                </Select.Option>
                                <Select.Option value="booking_date">Booking date</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="dateRange"
                            label="Select Duration"
                            rules={[{ required: true, message: 'Please select date range' }]}>
                            <DatePicker.RangePicker size="large" />
                        </Form.Item>
                        <Form.Item
                            name="format"
                            label="Format"
                            initialValue=""
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select className="d-block" size="large" placeholder="Choose One">
                                <Select.Option value="" disabled>
                                    Choose One
                                </Select.Option>
                                <Select.Option value="csv">CSV</Select.Option>
                                <Select.Option value="excel">Excel</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button color="primary" className="btn-mw" type="submit">
                            Download
                        </Button>
                    </Form>

                    <hr />

                    <Form
                        form={emailBookingForm}
                        onFinish={onFinishSettings}
                        requiredMark={false}
                        layout="vertical">
                        <div className="d-flex align-items-start flex-wrap">
                            <h5 className="fs-18 fw-600 mr-2 flex-grow-1">
                                <span className="text-primary mr-2">
                                    <TiltedEmailIcon />
                                </span>
                                <span>Email Booking Details</span>
                            </h5>
                        </div>
                        <Form.Item
                            name="frequency"
                            label="Frequency"
                            initialValue=""
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select className="d-block" size="large" placeholder="Choose One">
                                <Select.Option value="" disabled>
                                    Choose One
                                </Select.Option>
                                <Select.Option value="never">Never</Select.Option>
                                <Select.Option value="weekly">Weekly</Select.Option>
                                <Select.Option value="monthly">Monthly</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="emailReportTo"
                            label="Email Report to"
                            initialValue=""
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select className="d-block" size="large" placeholder="Choose One">
                                <Select.Option value="" disabled>
                                    Choose One
                                </Select.Option>
                                <Select.Option value={vendor_email}>{vendor_email}</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="bookingVisibility"
                            label="Show Only"
                            initialValue=""
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select className="d-block" size="large" placeholder="Choose One">
                                <Select.Option value="" disabled>
                                    Choose One
                                </Select.Option>
                                <Select.Option value="1">
                                    Booking made in last 24 hours
                                </Select.Option>
                                <Select.Option value="2">
                                    Booking for the following day
                                </Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="format"
                            label="Format"
                            initialValue=""
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Select className="d-block" size="large" placeholder="Choose One">
                                <Select.Option value="" disabled>
                                    Choose One
                                </Select.Option>
                                <Select.Option value="csv">CSV</Select.Option>
                                <Select.Option value="xlsx">Excel</Select.Option>
                            </Select>
                        </Form.Item>
                        <Button color="primary" className="btn-mw" type="submit">
                            Save Settings
                        </Button>
                    </Form>
                </div>

                <div className="pt-3">
                    <div className="d-flex justify-content-between">
                        <Button color="primary" onClick={onCloseDrawer}>
                            Go Back
                        </Button>
                        <Feedback drawerPlacement="right" />
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

DownloadSettingsDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    saveSettings: PropTypes.func,
    vendor_email: PropTypes.string
};
const mapDispatch = (dispatch) => ({
    saveSettings: (data) => dispatch(saveSettings(data))
});
const mapStateToProps = (state) => ({
    vendor_email: state?.auth?.my_profile?.email
});
export default withRouter(connect(mapStateToProps, mapDispatch)(DownloadSettingsDrawer));

import { callApi } from '../../utils/apiCaller';
import { Notification } from '../../components/atoms/notification';
import { queryBuilder } from 'utils';

import { toggleCheckPaymentInfo } from './accessCalendar';

import {
    CALENDAR_DATA,
    ARCHIVED_CALENDAR_DATA,
    CREATE_CALENDAR,
    UPDATE_SUCCESS_CALENDAR,
    CREATE_CALENDAR_ERROR,
    UPDATE_ERROR_CALENDAR,
    BUSINESS_DATA,
    UPDATE_BUSINESS_DATA,
    UPDATE_BUSINESS_DATA_LOADING,
    PRODUCT_CATEGORIES,
    PRODUCT_IN_DAY_CATEGORIES,
    RESET_CALENDAR_REDUCER,
    UPDATE_CALENDAR_TITLE,
    GET_CALENDAR_BY_ID,
    GET_ALL_CALENDAR_PRODUCTS,
    UPDATE_CALENDAR_RESTING_TIME,
    UPDATE_CALENDAR_LENGTH,
    UPDATE_CALENDAR_LG_SUCCESS,
    GET_CALENDAR_BY_UUID,
    GET_EXISTING_PRODUCTS,
    GET_INCLUSION_CATEGORIES,
    GET_ADVENTURE_CATEGORIES,
    GET_OCCASSION_CATEGORIES,
    GET_SIGHT_CATEGORIES,
    GET_ALL_VENDOR_CALENDARS,
    GET_CALENDAR_FOR_FILTER
} from 'store/types';
import { loading_ } from 'store/actions';

export const updateCalendarDaySelectedProducts = (body) => async (dispatch) => {
    try {
        let response = await callApi(`/calendar-day/selected-products`, 'PUT', body);
        const { status } = response;

        if (status === 200) {
            dispatch(getCalendarsById_(response.data));
            return Notification('success', response.data.message, status);
        } else {
            if (
                response?.data &&
                Array.isArray(response?.data?.errors) &&
                response?.data?.errors.length
            ) {
                Notification('error', response.data.errors[0].message, status);
            } else {
                Notification('error', response.data.message, status);
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const duplicateCalendar = (calendarId, body) => async () => {
    try {
        let response = await callApi(`/calendars/${calendarId}/duplicate`, 'PUT', body);
        const { status } = response;

        if (status === 200) {
            return { success: true, calendarId: response?.data?.data?.id };
        } else {
            if (
                response?.data &&
                Array.isArray(response?.data?.errors) &&
                response?.data?.errors.length
            ) {
                Notification('error', response.data.errors[0].message, status);
            } else {
                Notification('error', response.data.message, status);
            }
        }
    } catch (error) {
        console.log(error);
    }
    return { success: false, calendarId: null };
};

export const getCalendars_ = (payload) => ({ type: CALENDAR_DATA, payload });
export const getCalendars = (query) => {
    ///api/v1/me=
    return (dispatch) => {
        let url = `/me/calendars`;
        if (query) {
            query = queryBuilder(query);
            url = `/me/calendars${query}`;
        }

        dispatch(loading_(true));
        callApi(url, 'GET')
            .then((response) => {
                const status = response?.status;

                if (status === 200) {
                    dispatch(getCalendars_(response.data));
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response?.data?.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getCalendarsById_ = (payload) => ({ type: GET_CALENDAR_BY_ID, payload });
export const getCalendarsById = (calendarId, history) => {
    return (dispatch) => {
        return callApi(`/me/calendars/${calendarId}`, 'GET').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(
                    toggleCheckPaymentInfo(response.data.data.show_price === 'show' ? true : false)
                );
                return dispatch(getCalendarsById_(response.data));
            } else {
                if (history && response?.data?.message === 'This calendar does not exist!') {
                    return history.push('/customers-calendars');
                }
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getAllVendorCalendars_ = (payload) => ({ type: GET_ALL_VENDOR_CALENDARS, payload });
export const getAllVendorCalendars = (nextPageUrl) => async (dispatch) => {
    try {
        let response = await callApi(`/me/calendars${nextPageUrl || '/?page=1'}&perPage=10`, 'GET');

        const status = response?.status;

        if (status === 200) {
            dispatch(getAllVendorCalendars_(response.data));
        } else {
            if (
                response?.data &&
                Array.isArray(response?.data?.errors) &&
                response?.data?.errors.length
            ) {
                return Notification('error', response.data.errors[0].message, status);
            } else {
                return Notification('error', response.data.message, status);
            }
        }
    } catch (error) {
        console.log(error);
    }
};

export const getAllCalendarProducts_ = (payload) => ({ type: GET_ALL_CALENDAR_PRODUCTS, payload });
export const getAllCalendarProducts = (calendarId) => async (dispatch) => {
    let response = await callApi(
        `${!calendarId ? '/me/all-products/' : '/get-products-by-calendarId/'}?${
            calendarId ? `calendarId=${calendarId}` : ''
        }`,
        'GET'
    );
    const status = response?.status;

    if (status === 200) {
        dispatch(getAllCalendarProducts_(response.data));
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

export const updateCalendarRestingTime_ = (payload) => ({
    type: UPDATE_CALENDAR_RESTING_TIME,
    payload
});
export const updateCalendarRestingTime = (calendarId, restTime) => async (dispatch) => {
    let response = await callApi(`/calendars/${calendarId}/resting-time`, 'PUT', restTime);
    const { status } = response;

    if (status === 200) {
        await dispatch(getCalendarsById(calendarId));
        Notification('success', response.data.message, status);
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

export const getCalendarsIdByUuid_ = (payload) => ({ type: GET_CALENDAR_BY_UUID, payload });
export const getCalendarsIdByUuid = (uuid) => {
    ///api/v1/me
    return (dispatch) => {
        callApi(`/{{baseUrl}}/calendars/${uuid}`, 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getCalendarsIdByUuid_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getAllDayProductCategories_ = (payload) => ({ type: PRODUCT_CATEGORIES, payload });
export const getAllDayProductCategories = () => {
    return (dispatch) => {
        callApi('/allday-product-categories ', 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getAllDayProductCategories_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getWithInDayProductCategories_ = (payload) => ({
    type: PRODUCT_IN_DAY_CATEGORIES,
    payload
});
export const getWithInDayProductCategories = (type) => {
    return (dispatch) => {
        callApi('/withinday-product-categories', 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                if (type === 'INDAYCAT') dispatch(getWithInDayProductCategories_(response.data));
                else dispatch(getAllDayProductCategories_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getBusinessNameLogo_ = (payload) => ({ type: BUSINESS_DATA, payload });
export const getBusinessNameLogo = () => {
    ///api/v1/me
    return (dispatch) => {
        callApi('/get-business-name-and-logo', 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                return dispatch(getBusinessNameLogo_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const updateBusinessNameLogoLoading_ = (payload) => ({
    type: UPDATE_BUSINESS_DATA_LOADING,
    payload
});
export const updateBusinessNameLogo_ = (payload) => ({ type: UPDATE_BUSINESS_DATA, payload });
export const updateBusinessNameLogo = (data) => {
    return (dispatch) => {
        dispatch(updateBusinessNameLogoLoading_(true));
        callApi('/update-brand-logo-and-trading-name', 'POST', data)
            .then((response) => {
                const { status } = response;

                if (status === 200) {
                    dispatch(updateBusinessNameLogo_(response.data));
                    return Notification(
                        'success',
                        response?.data?.message || response?.message,
                        status
                    );
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response.data.message, status);
                    }
                }
            })
            .finally(() => {
                dispatch(updateBusinessNameLogoLoading_(false));
            });
    };
};

export const createCalendars_ = (payload) => ({ type: CREATE_CALENDAR, payload });
export const createErrorCalendars_ = (payload) => ({ type: CREATE_CALENDAR_ERROR, payload });
export const createCalendars = (data) => {
    return (dispatch) => {
        callApi('/calendars', 'POST', data).then((response) => {
            const { status } = response;
            const successMsg =
                response?.data?.message || response?.message || 'Calendar created successfully.';

            if (status === 200) {
                dispatch(createCalendars_({ data: response.data, success: true }));
                return Notification('success', successMsg, status);
            } else {
                dispatch(createErrorCalendars_({ data: data, error: true }));
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const updateCalendarTitle_ = (payload) => ({
    type: UPDATE_CALENDAR_TITLE,
    payload
});
export const updateCalendarTitle = (data, id) => {
    return (dispatch) => {
        callApi(`/calendars/${id}/title`, 'PUT', data).then((response) => {
            const { status } = response;
            const successMsg =
                response?.data?.message || response?.message || 'Calendar created successfully.';

            if (status === 200) {
                dispatch(updateCalendarTitle_({ data: response.data, success: true }));
                return Notification('success', successMsg, status);
            } else {
                dispatch(updateCalendarTitle_({ data: data, error: true }));
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const updateCalendarLength_ = (payload) => ({
    type: UPDATE_CALENDAR_LENGTH,
    payload
});
export const updateCalendarLength = (data, id) => {
    return (dispatch) => {
        callApi(`/calendars/${id}/length`, 'PUT', data).then((response) => {
            const { status } = response;
            const successMsg =
                response?.data?.message ||
                response?.message ||
                'Calendar length saved successfully.';

            if (status === 200) {
                dispatch(updateCalendarLength_({ data: response.data, success: true }));
                return Notification('success', successMsg, status);
            } else {
                // dispatch(updateCalendarLength_({ data: data, error: true }));
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const updateSuccessCalendar = (payload) => ({ type: UPDATE_SUCCESS_CALENDAR, payload });
export const updateErrorCalendar = (payload) => ({ type: UPDATE_ERROR_CALENDAR, payload });

export const updateCalendarLgSuccess = (payload) => ({ type: UPDATE_CALENDAR_LG_SUCCESS, payload });

export const resetCalenderReducer = (payload = {}) => ({
    type: RESET_CALENDAR_REDUCER,
    payload
});

export const getExistingProducts_ = (payload) => ({ type: GET_EXISTING_PRODUCTS, payload });
export const getExistingProducts = ({
    searchQuery = '',
    productCategoryId = '',
    productSubCategoryId = '',
    page = '/?page=1',
    paginating = false,
    dayType = 'all_day'
}) => {
    return (dispatch, getState) => {
        dispatch(loading_(true));
        callApi(
            `/products${page}&perPage=10&searchQuery=${searchQuery}&productCategoryId=${productCategoryId}&productSubCategoryId=${productSubCategoryId}&dayType=${dayType}`,
            'GET'
        )
            .then((response) => {
                const { status } = response;

                if (status === 200) {
                    const prevState = getState().calendar.existing_products;
                    if (
                        prevState &&
                        Array.isArray(prevState.data) &&
                        response.data?.data &&
                        paginating
                    ) {
                        if (response.data.data.meta?.current_page > prevState.meta?.current_page)
                            dispatch(
                                getExistingProducts_({
                                    data: prevState.data.concat(response.data.data.data),
                                    meta: response.data.data.meta
                                })
                            );
                    } else dispatch(getExistingProducts_(response.data.data));
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response.data.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getInclusionCategories_ = (payload) => ({ type: GET_INCLUSION_CATEGORIES, payload });
export const getInclusionCategories = () => {
    return (dispatch) => {
        callApi(`/get-inclusions`, 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getInclusionCategories_(response.data.inclusions));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getAdventureCategories_ = (payload) => ({ type: GET_ADVENTURE_CATEGORIES, payload });
export const getAdventureCategories = () => {
    return (dispatch) => {
        callApi(`/products-adventures`, 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getAdventureCategories_(response.data.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getOccassionCategories_ = (payload) => ({ type: GET_OCCASSION_CATEGORIES, payload });
export const getOccassionCategories = () => {
    return (dispatch) => {
        callApi(`/products-occassions`, 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getOccassionCategories_(response.data.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getSightCategories_ = (payload) => ({ type: GET_SIGHT_CATEGORIES, payload });
export const getSightCategories = () => {
    return (dispatch) => {
        callApi(`/products-sights`, 'GET').then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getSightCategories_(response.data.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

// export const getSightCategories_ = (payload) => ({ type: GET_SIGHT_CATEGORIES, payload });
export const updateCalendarStatus = ({ id, status }) => {
    return () => {
        return callApi(`/calendars/${id}/${status === 'archive' ? 'status' : 'disable'}`, 'PUT', {
            status
        }).then((response) => {
            const { status } = response;
            if (status === 200) {
                if (status === 'archive') {
                    return true;
                } else {
                    return response?.data?.message;
                }
                // dispatch(getSightCategories_(response.data.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const disableCalendarConfirm = ({ id }) => {
    return () => {
        return callApi(`/calendars/${id}/draft`, 'PUT').then((response) => {
            const { status } = response;
            if (status === 200) {
                return true;
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const getArchivedCalendars_ = (payload) => ({ type: ARCHIVED_CALENDAR_DATA, payload });
export const getArchivedCalendars = (query) => {
    return (dispatch) => {
        dispatch(loading_(true));
        callApi(`/calendars/fetch/archived${queryBuilder(query)}`, 'GET')
            .then((response) => {
                const { status } = response;

                if (status === 200) {
                    dispatch(getArchivedCalendars_(response.data?.data));
                } else {
                    if (
                        response?.data &&
                        Array.isArray(response?.data?.errors) &&
                        response?.data?.errors.length
                    ) {
                        return Notification('error', response.data.errors[0].message, status);
                    } else {
                        return Notification('error', response.data.message, status);
                    }
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getCalendarForFilter_ = (payload) => ({ type: GET_CALENDAR_FOR_FILTER, payload });
export const getCalendarForFilter = () => {
    return (dispatch) => {
        callApi('/calendars-filter', 'GET').then((response) => {
            const status = response.status;
            if (status === 200) {
                return dispatch(getCalendarForFilter_(response.data));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

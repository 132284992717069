import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { BackI, DollarGraphI, LoopI, NextI, TrendingI, AutomationUpI } from 'assets';

export default function PaidNotPaidFlow(props) {
    const { state, setState } = props;
    return (
        <div className="pt-3">
            <div
                className="d-flex align-items-start flex-wrap mb-3 mx-auto"
                style={{ maxWidth: 'calc(250px + 250px + 1rem)' }}>
                <Button
                    color="outline-primary"
                    className="rounded-pill d-inline-flex align-items-center mr-2"
                    size="sm"
                    style={{ minWidth: 95 }}
                    onClick={() =>
                        setState({
                            paidOrNotPaidFlowInit: false,
                            paidOrNotPaidPrompt: true
                        })
                    }>
                    <span>
                        <BackI />
                    </span>
                    <span className="ml-2">Back</span>
                </Button>
                <h5 className="ff-base fw-500 fs-18 text-primary text-center mx-auto">
                    <span>Choose an option below</span>
                </h5>
                <div style={{ minWidth: 95 }}>&nbsp;</div>
            </div>

            {/* For Not Paid Flow */}
            {!state.alreadyPaidProcess && (
                <div className="d-flex flex-wrap gap-3 justify-content-center">
                    <div
                        className="p-3 rounded d-flex flex-column"
                        style={{
                            width: 250,
                            background: '#FEFCF4',
                            border: '1px solid #E7D99F'
                        }}>
                        <div className="mb-3">
                            <LoopI />
                        </div>
                        <div className="mb-5">
                            <div className="fs-13 fw-600 mb-2">
                                Convert potential customers that are still window shopping
                            </div>
                            <div className="fs-11">
                                Send main product information (e.g. pictures and availability) to
                                customers, while enticing them with your free travel plan.
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-auto">
                            <Button
                                color="outline-primary"
                                className="rounded-pill d-inline-flex align-items-center"
                                size="sm"
                                onClick={() =>
                                    setState({
                                        paidOrNotPaidFlowInit: false,
                                        isReadyToSend: true,
                                        viewNotPaidOne: true,
                                        showWhatsapp: true
                                    })
                                }>
                                <span className="mr-2">Select</span>
                                <span>
                                    <NextI />
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div
                        className="p-3 rounded d-flex flex-column"
                        style={{
                            width: 250,
                            background: '#FEFCF4',
                            border: '1px solid #E7D99F'
                        }}>
                        <div className="mb-3">
                            <TrendingI />
                        </div>
                        <div className="mb-5">
                            <div className="fs-13 fw-600 mb-2">
                                Increase the conversion rate of new customers by showing them more
                                value
                            </div>
                            <div className="fs-11">
                                Send main product information (e.g. pictures and availability) to
                                customers with already agreed dates, while enticing them with your
                                free travel plan.
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-auto">
                            <Button
                                color="outline-primary"
                                className="rounded-pill d-inline-flex align-items-center"
                                size="sm"
                                onClick={() =>
                                    setState({
                                        paidOrNotPaidFlowInit: false,
                                        isReadyToSend: true,
                                        viewNotPaidTwo: true,
                                        showWhatsapp: true
                                    })
                                }>
                                <span className="mr-2">Select</span>
                                <span>
                                    <NextI />
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {/* For Already Paid Flow */}
            {state.alreadyPaidProcess && (
                <div className="d-flex flex-wrap gap-3 justify-content-center">
                    <div
                        className="p-3 rounded d-flex flex-column"
                        style={{
                            width: 250,
                            background: '#FEFCF4',
                            border: '1px solid #E7D99F'
                        }}>
                        <div className="mb-3">
                            <DollarGraphI />
                        </div>
                        <div className="mb-5">
                            <div className="fs-13 fw-600 mb-2">Upsell to existing customers</div>
                            <div className="fs-11">
                                Send to exsiting customers to view your free travel plan.
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-auto">
                            <Button
                                color="outline-primary"
                                className="rounded-pill d-inline-flex align-items-center"
                                size="sm"
                                onClick={() =>
                                    setState({
                                        paidOrNotPaidFlowInit: false,
                                        isReadyToSend: true,
                                        viewPaidOne: true,
                                        showWhatsapp: true
                                    })
                                }>
                                <span className="mr-2">Select</span>
                                <span>
                                    <NextI />
                                </span>
                            </Button>
                        </div>
                    </div>
                    <div
                        className="p-3 rounded d-flex flex-column"
                        style={{
                            width: 250,
                            background: '#FEFCF4',
                            border: '1px solid #E7D99F'
                        }}>
                        <div className="mb-3">
                            <AutomationUpI />
                        </div>
                        <div className="mb-5">
                            <div className="fs-13 fw-600 mb-2">
                                Automate upsell process process to existing customers from 3rd party
                                platforms
                            </div>
                            <div className="fs-11">
                                Automate calendar link sent to customers to view your free travel
                                plan.
                            </div>
                        </div>

                        <div className="d-flex justify-content-end mt-auto">
                            <Button
                                color="outline-primary"
                                className="rounded-pill d-inline-flex align-items-center"
                                size="sm"
                                onClick={() =>
                                    setState({
                                        paidOrNotPaidFlowInit: false,
                                        isReadyToSend: true,
                                        viewPaidTwo: true,
                                        showLink: true
                                    })
                                }>
                                <span className="mr-2">Select</span>
                                <span>
                                    <NextI />
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

PaidNotPaidFlow.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func
};

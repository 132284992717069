import { Col, Row } from 'antd';
import React from 'react';
import Spacer from '../../../../atoms/spacer';
// import PropTypes from 'prop-types'
import './index.scss';

function ItenaryCalenderSession() {
    return (
        <div className="itenary-calender-session ">
            <h6>Itinerary Calender Season</h6>
            <p className="itenary-calender-session__subtitle">
                Travelers will be informed of the activity’s season according to the dates they
                search for.
            </p>
            <div className="itenary-calender-session__month-box">
                <Row>
                    <Col span="16">
                        <Row>
                            <Col span="6">JAN</Col>
                            <Col span="6">FEB</Col>
                            <Col span="6">MAR</Col>
                            <Col className="fair" span="6">
                                APR
                            </Col>
                            <Col className="fair" span="6">
                                MAY
                            </Col>
                            <Col className="good" span="6">
                                JUN
                            </Col>
                            <Col span="6">JUL</Col>
                            <Col span="6">AUG</Col>
                            <Col span="6">SEP</Col>
                            <Col className="good" span="6">
                                OCT
                            </Col>
                            <Col className="good" span="6">
                                NOV
                            </Col>
                            <Col className="good" span="6">
                                DEC
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginTop: 10 }} span="8">
                        <p className="excellent">Excellent</p>
                        <p className="good">Good</p>
                        <p className="fair">Fair</p>
                    </Col>
                </Row>
            </div>
            <Spacer top={10} />
            <h6 className="itenary-calender-session__summary">Summary</h6>
            <p>
                <b>Excellent</b> - best time to visit, best weather and <b>not</b> in the high
                season.
            </p>
            <p>
                <b>Good</b> - best time to visit, best weather and in the high season.
            </p>
            <p>
                <b>Fair</b> - best time to visit, not the best weather and in the high season.
            </p>
        </div>
    );
}

ItenaryCalenderSession.propTypes = {};

export default ItenaryCalenderSession;

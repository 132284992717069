import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Checkbox, Row, Col, Select, Button } from 'antd';
import Prompter from '../../../atoms/prompter';
import Panel from '../../../atoms/panel';
import './index.scss';
import Spacer from '../../../atoms/spacer';
import MediaField from '../../../molecules/media-library/media-field/index';
import AtcTimePicker from '../../../atoms/time-picker';
// import LanguageOffered from '../organisms/views/syn';
import CurvedButton from '../../../organisms/views/curvedbutton';
import { withRouter } from 'react-router-dom';

import Feedback from '../../../molecules/user-feedback';
import InputBox from '../../../atoms/inputbox';

const { TextArea } = Input;
const { Option } = Select;

// const radioStyle = {
//     display: 'block',
//     height: '30px',
//     lineHeight: '30px'
// };

const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

const locationOptions = [
    { label: 'At the accomodation provided by you', value: 'Apple' },
    { label: 'Allow travellers to enter their their own pick up location', value: 'Pear' },
    { label: 'at a Starting Point', value: 'Orange' }
];

class GuideDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            activitySessionIsVisible: false,
            foodAndDrinksIsVisible: false,
            languageOfferedIsVisible: false
        };
    }

    showModal = (key) => {
        this.setState({
            [key]: true
        });
    };

    closeModal = (key) => {
        this.setState({
            [key]: false
        });
    };

    handleOk = () => {
        // this.setState({ loading: true });
        // setTimeout(() => {
        this.setState({ visible: false });
        // }, 3000);
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    componentDidMount() {}

    onRequiredTypeChange = ({ requiredMark }) => {
        this.setRequiredMarkType(requiredMark);
    };

    setRequiredMarkType = (requiredMark) => {
        this.setState({
            requiredMark
        });
    };

    render() {
        const {
            // form,
            requiredMark
        } = this.state;

        return (
            <div className="GuideDetails-form">
                <Form
                    // form={form}
                    layout="vertical"
                    initialValues={{
                        requiredMark
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}>
                    <Row justify="center">
                        <Col span="22">
                            <h4 className="GuideDetails-form__title">Add a Market Guide</h4>
                            <Prompter
                                textColor="#A2792C"
                                text="I will import Market Guide information from my other Guides"
                            />
                            <Spacer top={57} />
                            <Form.Item label="Add Market Guide title" required>
                                <Input
                                    style={{
                                        border: '0.3px solid #A2792C',
                                        height: 40,
                                        borderRadius: 8
                                    }}
                                    placeholder="Kruger National Park Safari, South Africa"
                                />
                            </Form.Item>

                            <Form.Item
                                className="mt-57"
                                label="Describe what travelers will see and do here if they book your experience"
                                required>
                                <TextArea
                                    rows={5}
                                    placeholder="
                                    Enter a description about this product only.  For example, 'Our accredited guide will meet you at the ticket booth and will accompany you up the tower, explaining what you\'ll see at each level.'
                                    Description is required.
                                "
                                />
                            </Form.Item>

                            <Form.Item label="Select main destination" required>
                                <Input
                                    style={{
                                        border: '0.3px solid #a2792c',
                                        height: 40,
                                        borderRadius: 8
                                    }}
                                    placeholder="Kruger National Park Safari, South Africa"
                                    suffix={
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z"
                                                fill="#927E2F"
                                            />
                                            <path
                                                d="M11.4118 8.58609C11.7908 8.96609 11.9998 9.46809 11.9998 10.0001H13.9998C14.0007 9.47451 13.8974 8.95398 13.6959 8.46857C13.4944 7.98316 13.1987 7.54251 12.8258 7.17209C11.3118 5.66009 8.68683 5.66009 7.17383 7.17209L8.58583 8.58809C9.34583 7.83009 10.6558 7.83209 11.4118 8.58609Z"
                                                fill="#927E2F"
                                            />
                                        </svg>
                                    }
                                />
                            </Form.Item>

                            <Form.Item
                                label="What time of the day can customers book your Resturant Booking Service?"
                                required>
                                <Select placeholder="Select One">
                                    {[1, 2].map((item) => (
                                        <Option key={item}>{item}</Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <h3 style={{ fontSize: '20px', fontWeight: 900 }}>
                                Select the customers calendar days this accommodation apply
                            </h3>
                            <p style={{ fontSize: '15px' }}>
                                For example, you can have different accomodations for each the
                                activity days.{' '}
                            </p>
                            <InputBox />
                            <div style={{ paddingTop: 15 }}>
                                {days.map((day, i) => (
                                    <CurvedButton
                                        key={i}
                                        pressedbutton="pressedbutton-acc"
                                        newbutton="newbutton-acc"
                                        name={day}
                                        onClick={() => {}}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>

                    <Spacer top={36} />
                    <hr></hr>
                    <Spacer top={36} />
                    <Row justify="center">
                        <Col span="22">
                            <Spacer top={36} />
                            <h6>Add Photos & Videos</h6>
                            <p>
                                Use photos that highlight what’s unique about your experience and
                                show travelers having fun. Adding 6-9 photos and at least 1 video
                                can increase your bookings.
                            </p>
                            <Row gutter={12}>
                                <Col>
                                    <MediaField type="picture" />
                                </Col>
                                <Col>
                                    <MediaField type="video" />
                                </Col>
                            </Row>
                            <Spacer top={28} />
                        </Col>
                    </Row>

                    <div className="GuideDetails-form__panels">
                        <hr></hr>
                        <Spacer top={36} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title="Language Offered"
                                    footer={
                                        <Prompter
                                            onClick={() =>
                                                this.showModal('languageOfferedIsVisible')
                                            }
                                            text="Add language offered"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Spacer top={17} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title="Inclusions & Exclusions"
                                    footer={<Prompter text="Add inclusions and exclusions" />}
                                />
                            </Col>
                        </Row>
                        <Spacer top={36} />
                        <hr></hr>
                    </div>

                    <Spacer top={40} />

                    <div style={{ padding: '0px 42px' }}>
                        <Form.Item
                            label="Now tell us where you meet travelers for this market guide?"
                            required>
                            <Checkbox.Group options={locationOptions} />
                        </Form.Item>

                        <Prompter text="Add another starting point" />

                        <h6 className="mt-10px">What is the departure time?</h6>
                        <AtcTimePicker />

                        <Spacer top={32} />
                        <h6>How long before departure does your pickup start?</h6>
                        <AtcTimePicker showAmPm={false} />

                        <Spacer top={36} />
                        <hr></hr>
                        <Spacer top={60} />
                        <p>
                            Enter the maximum number of spaces available to book this Market Guide
                            for the time period.
                        </p>
                        <p>
                            This will indicate the spaces available to travelers and once this is
                            reached, this product activtity will be marked as sold out for that day
                        </p>
                        <Form.Item label="" required>
                            <Select placeholder="">
                                {[1, 2].map((item) => (
                                    <Option key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <Spacer top={38} />
                    <hr></hr>
                    <Spacer top={36} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Pricing"
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add Pricing details for your ‘Product Title’ Market Guide"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Spacer top={17} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Booking and Tickets"
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add Booking and Ticketing details  for your ‘Product Title’ Market Guide"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Spacer top={17} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Information travellers need from you"
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add any physical restrictions for this Market Guide"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Spacer top={17} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Covid-19 Updates"
                                footer={
                                    <Prompter
                                        onClick={() => this.props.history.push('/covid19')}
                                        text="Add Covid-19 safety updates"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <div className="GuideDetails__action">
                        <div>
                            <Button
                                style={{ width: 135, height: 42 }}
                                type="primary"
                                className="rounded-btn mr20">
                                Done
                            </Button>
                            <Button
                                style={{ width: 135, height: 42 }}
                                type="primary"
                                className="rounded-btn mr20">
                                Cancel
                            </Button>
                        </div>
                        <div style={{ float: 'right' }}>
                            <Feedback />
                        </div>
                    </div>
                </Form>
                {/* <LanguageOffered 
                    handleCancel={() => this.closeModal('languageOfferedIsVisible')}
                    isVisible={languageOfferedIsVisible}
                /> */}
            </div>
        );
    }
}

GuideDetails.propTypes = {
    history: PropTypes.object
};

export default withRouter(GuideDetails);

/* eslint-disable-next-line react/jsx-key */
import React, { Component } from 'react';
import './index.scss';
import Proptypes from 'prop-types';
import { SearchSelect } from 'components/atoms';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchLocations } from 'store/actions';

let timeout = undefined;
class GoogleAutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            address: '',
            isOpenGoogle: false,
            isOpenDropDown: false,
            firstPlaceId: '',
            latlng: null
        };
    }

    // componentDidMount() {
    //     if (this.props.defValue) {
    //         this.setState({ address: this.props.defValue });
    //     }
    // }
    // componentDidUpdate(prevProps) {
    //     const { defValue = '' } = this.props;
    //     if (defValue !== prevProps.defValue && defValue !== '') {
    //         this.setState({ address: defValue });
    //     }
    // }

    onSearch = (val) => {
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            this.props.fetchLocations(val);
        }, 500);
    };

    onSelect = (_val, option) => {
        const { locations, onSelectDistance } = this.props;
        const key = option?.key;
        if (key) {
            if (locations[Number(key)])
                onSelectDistance?.(locations[Number(key)], locations[Number(key)]);
        }
    };

    render() {
        const { defValue, locations, fieldName = 'city_id', containerClassName = '' } = this.props;
        return (
            <div className={`google-maps-block ${containerClassName}`}>
                <SearchSelect
                    options={locations.map((res) => {
                        return { name: res.name, value: res.city_id };
                    })}
                    onSearch={this.onSearch}
                    onSelect={this.onSelect}
                    fieldName={fieldName}
                    initialValue={defValue}
                    value={defValue}
                />
            </div>
        );
    }
}

GoogleAutoComplete.propTypes = {
    defValue: Proptypes.string,
    placeholder: Proptypes.string,
    fontSize: Proptypes.string,
    onSelectDistance: Proptypes.func,
    fieldName: Proptypes.string,
    containerClassName: Proptypes.string,
    locations: Proptypes.shape([
        {
            name: Proptypes.string,
            value: Proptypes.oneOfType([Proptypes.string, Proptypes.number])
        }
    ]),
    fetchLocations: Proptypes.func
};

const mapStateToProps = (state) => ({
    locations: state.profile.locations
});
const mapDispatch = (dispatch) => ({
    fetchLocations: (query) => dispatch(fetchLocations(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(GoogleAutoComplete));

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DropDown from '../../../../assets/images/dropDown.svg';
import MoreText from '../pricing/MoreText.jsx';
import MoreInfoIcon from '../../../../assets/images/more_info.svg';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

const Accordion = withStyles({
    root: {
        'boxShadow': 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        'backgroundColor': '#E7D99F',
        'marginBottom': -1,
        'minHeight': 56,
        '&$expanded': {
            minHeight: 56
        },
        'fontWeight': '600',
        'fontSize': '18px',
        'lineHeight': '22px',
        /* identical to box height */
        'fontStyle': 'normal',
        'color': '#000000'
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        }
    },
    expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        borderRadius: '8px'
    }
}))(MuiAccordionDetails);

export default function CustomizedAccordions() {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion
                style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal'
                }}
                square
                expanded={expanded === 'panel1'}
                onChange={handleChange('panel1')}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography>
                        <img src={DropDown} /> Airport Transfer / Pickup Transportation
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container direction="column">
                        <Grid item>
                            Airport Transfer / Pickup Transportation
                            <span>
                                <img src={MoreInfoIcon} />
                            </span>
                        </Grid>
                        <Grid item style={{ margin: '19px 0' }}>
                            <hr />
                        </Grid>
                        <NavLink to="/add-transport-option">
                            <Grid item style={{ cursor: 'pointer' }}>
                                <MoreText text="Add an airport transfer / pickup" />
                            </Grid>
                        </NavLink>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography>
                        <img src={DropDown} /> Chauffer Service Transportation
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        backgroundColor: '#E7D99F'
                    }}>
                    <Grid
                        container
                        direction="column"
                        style={{
                            backgroundColor: '#ffffff',
                            borderRadius: '8px',
                            padding: '14px 28px'
                        }}>
                        <Grid item>
                            Chauffer Service Transportation
                            <span>
                                <img src={MoreInfoIcon} />
                            </span>
                        </Grid>
                        <Grid item style={{ margin: '19px 0' }}>
                            <hr />
                        </Grid>
                        <Grid item style={{ cursor: 'pointer' }}>
                            <MoreText text="Add a chauffer service" />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Col, Input, Radio, Row, Select } from 'antd';
import Feedback from '../../../molecules/user-feedback';
import React from 'react';
import './index.scss';

export default function AddNewUser() {
    return (
        <div className="add-user">
            <div className="white-bg">
                <p className="heading">Manage Users</p>
                <p className="sub-heading mt30">CONTACT DETAILS</p>
                <Radio.Group>
                    <Radio value={1}>Name</Radio>
                    <Radio value={2}>Department</Radio>
                </Radio.Group>

                <Row gutter={30} style={{ marginTop: 20 }}>
                    <Col xs={24} md={9}>
                        <p className="label">First Name (Optional)</p>
                        <Input
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6
                            }}
                        />
                    </Col>
                    <Col xs={24} md={9}>
                        <p className="label">Last Name</p>
                        <Input
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6
                            }}
                        />
                    </Col>
                    <Col xs={24} md={9}>
                        <p className="label">Title / Details (Optional)</p>
                        <Input
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6
                            }}
                        />
                        <span style={{ fontSize: 14, color: 'rgba(79, 79, 79, 0.63)' }}>
                            Title, location or relevant information for the user
                        </span>
                    </Col>
                    <Col xs={24} md={9}></Col>
                    <Col xs={24} md={9}>
                        <p className="label">Email</p>
                        <Input
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6
                            }}
                        />
                        <span style={{ fontSize: 14, color: 'rgba(79, 79, 79, 0.63)' }}>
                            Unique email address for the user
                        </span>
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }} gutter={30}>
                    <Col span={3}>
                        <Select
                            bordered={false}
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6,
                                width: '100%'
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6
                            }}
                        />
                    </Col>
                    <Col span={5}>
                        <Select
                            bordered={false}
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6,
                                width: '100%'
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{ marginTop: 15 }} gutter={30}>
                    <Col span={3}>
                        <p className="label mt0">Fax (Optional)</p>
                        <Select
                            bordered={false}
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6,
                                width: '100%'
                            }}
                        />
                    </Col>
                    <Col span={6}>
                        <p className="label mt0">.</p>
                        <Input
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6
                            }}
                        />
                    </Col>
                </Row>
                <p className="sub-heading mt30">NEWSLETTERS</p>
                <p>Select the newsletters that this user wishes to receive</p>
                <div>
                    <Checkbox>
                        Strategic Communications{' '}
                        <span style={{ fontSize: 12, color: '#E2C022' }}>
                            <InfoCircleFilled />
                        </span>
                    </Checkbox>
                </div>
                <div className="mt10">
                    <Checkbox>
                        Operational Communications{' '}
                        <span style={{ fontSize: 12, color: '#E2C022' }}>
                            <InfoCircleFilled />
                        </span>
                    </Checkbox>
                </div>

                <p className="sub-heading mt30">PERMISSIONS</p>
                <p>Select the newsletters that this user wishes to receive</p>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>Manage Product Bookings </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Manage Product Availability </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Manage Product Availability </Checkbox>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>View Booking Reports </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Manage Users </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Manage Users </Checkbox>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>Products</Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Account details </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Account details </Checkbox>
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} md={8}>
                        <p className="label">
                            <b>Default Landing Page.</b> (Optional)
                        </p>
                        <Select
                            bordered={false}
                            size="large"
                            style={{
                                border: '0.5px solid #A2792C',
                                borderRadius: 6,
                                width: '100%'
                            }}
                        />
                        <span style={{ fontSize: 14, color: 'rgba(79, 79, 79, 0.63)' }}>
                            Select the default page to be displayed after login
                        </span>
                    </Col>
                </Row>

                <p className="sub-heading mt30">ROLES</p>
                <p>Select the roles that this user will perform</p>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>Primary Contact </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox> Enquiry</Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Reservation </Checkbox>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>Refunds & Complaints </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Emergency </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>User Admin </Checkbox>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>Cancellation </Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox> Operations</Checkbox>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Checkbox>Executive Management </Checkbox>
                    </Col>
                </Row>
                <Row style={{ marginBottom: 10 }}>
                    <Col xs={24} sm={8}>
                        <Checkbox>XML Email Robot </Checkbox>
                    </Col>
                </Row>

                <p className="sub-heading mt30">PRODUCTS</p>
                <div className="alert_">
                    Booking emails will be sent to all users assigned to the appropriate product. If
                    there are no assigned users for a product the booking email will be sent to all
                    users with the Reservations role. If no users have this role, the Primary
                    Contact will receive booking emails.
                </div>
                <p className="sub-heading fs14 mt30" style={{ fontWeight: '300' }}>
                    Select the products that this user will receive email bookings for (auto-send).
                </p>
                <Checkbox>Select All Products</Checkbox>

                <div className="buttons mt40">
                    <Button type="primary" className="rounded-btn" style={{ marginRight: 12 }}>
                        Save
                    </Button>
                    <Button type="ghost" className="rounded-btn">
                        Cancel
                    </Button>

                    {/* <Button type="primary" className="rounded-btn" style={{ float: 'right' }}>
                        <QuestionCircleOutlined />
                        Help
                    </Button> */}
                    <div className="flex-shrink-0 mt-3 mt-lg-0">
                        <div className="d-flex justify-content-end">
                            <Feedback />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

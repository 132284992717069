/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react';
import './index.scss';
import { Drawer, Form, Button, Col, Row, Input, Select, message } from 'antd';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actions from 'store/actions';
import { PhoneCountryCode, SelectCountry, Notification } from 'components/atoms';
const { Option } = Select;

const index = (props) => {
    const [form] = Form.useForm();

    const [selectCode, setSelectCode] = useState('');
    const [isSelectCode, setIsSelectCode] = useState(false);

    const onFinish = () => {
        props.setSubmitState(true);
        props
            .saveApplication()
            .then((res) => {
                if (res.status == 200) {
                    props.setSubmitState(false);
                    Notification('success', 'Successfully created vendor!', 200);

                    props.onClose();
                    if (props?.onDialog) {
                        props.onDialog();
                    }
                    form.resetFields();
                } else {
                    props.setSubmitState(false);
                    Notification('error', res?.data?.message, res?.status);
                }
            })
            .catch((ex) => {
                props.setSubmitState(false);
                Notification(
                    'error',
                    ex?.response?.data?.message ?? 'Something went wrong! Please try again',
                    500
                );
            });
    };

    const checkUrl = (rule, value) => {
        // let text_name = rule.field.split('_').join('');
        if (typeof value == 'string' && value.length) {
            let isMatch = value.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
            );
            if (isMatch) {
                return Promise.resolve();
            }
        }
        return Promise.reject('Please enter a valid URL.');
    };

    const togglePhone = () => setIsSelectCode(!isSelectCode);

    const checkText = (_, value) => {
        let text_name = _.field.split('_').join('');
        if (isNaN(value)) {
            return Promise.resolve();
        }
        return Promise.reject(`Please enter ${text_name}`);
    };

    const { show, onClose, isSubmitting, setApplicationField, save_application } = props;
    return (
        // Apply Now Drawer
        <Drawer
            forceRender
            className="application"
            onClose={onClose}
            visible={show}
            bodyStyle={{ paddingBottom: 80 }}>
            <h3 className="application-header-text">Apply for a Partner Account </h3>
            <p className="application-subheader-text">
                By completing this form you can apply for a partner account. Our team will review
                your application. When we have verified your application we will send you further
                instructions for the next step in the application process.
            </p>
            {save_application &&
            Array.isArray(save_application?.errors) &&
            save_application?.errors.length
                ? save_application.errors.map((item, i) => (
                      <span className="invalid-feedback d-block" key={i}>
                          {item.message}
                      </span>
                  ))
                : null}
            {/* Apply Now form */}
            <Form
                form={form}
                onValuesChange={(field) => setApplicationField(field)}
                onFinish={onFinish}
                hideRequiredMark>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="first_name"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        value
                                            ? checkText(rule, value)
                                            : Promise.reject(`Please enter first name`)
                                }
                            ]}>
                            <Input placeholder="First name*" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="last_name"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        value
                                            ? checkText(rule, value)
                                            : Promise.reject(`Please enter last name`)
                                }
                            ]}>
                            <Input placeholder="Last name*" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        {/* <Form.Item name="contact_number" rules={[{ validator: checkNumber }]}>
                            <Input placeholder="Contact number*" />
                        </Form.Item> */}
                        <PhoneCountryCode
                            phoneNumberFieldName="contact_number"
                            placeHolder="Contact number*"
                            selectedCountryCode={selectCode}
                            onChangeCountryCode={(code) => {
                                setSelectCode(code);
                                setApplicationField({ country_code: code });
                                form.validateFields(['selectCode']);
                            }}
                            expandMenu={isSelectCode}
                            toggleMenu={togglePhone}
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                    message: 'Please enter email address'
                                }
                            ]}>
                            <Input placeholder="Email address*" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="company_name"
                            rules={[{ required: true, message: 'Please enter company name' }]}>
                            <Input placeholder="Company name*" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item name="company_url" rules={[{ validator: checkUrl }]}>
                            <Input placeholder="Company / Business Product's URL*" />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col span={24}>
                        <SelectCountry
                            label=""
                            placeHolder="Country"
                            fieldName="country_id"
                            selectValue="id"
                        />
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="main_service_provided"
                            rules={[
                                { required: true, message: 'Please enter main service provided' }
                            ]}>
                            <Select
                                placeholder="Main Service Provided"
                                getPopupContainer={(trigger) => trigger.parentElement}>
                                <Option value="Tours/Activities">Tours/Activities</Option>
                                <Option value="Accommodation">Accommodation</Option>
                                <Option value="Restaurant service or Meals & Drinks">
                                    Restaurant service or Meals & Drinks
                                </Option>
                                <Option value="Personal Care">Personal Care</Option>
                                <Option value="Guide">Guide</Option>
                                <Option value="other">Other</Option>
                            </Select>
                        </Form.Item>
                        {form.getFieldValue('main_service_provided') == 'other' && (
                            <Form.Item
                                className="alt-input"
                                name="other_main_service"
                                rules={[{ required: true, message: 'Please enter other service' }]}>
                                <Input placeholder="Enter Other service" />
                            </Form.Item>
                        )}
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="trip_advisor_listing_url"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        value ? checkUrl(rule, value) : Promise.resolve()
                                }
                            ]}>
                            <Input placeholder="Tripadvisor listing URL" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="booking_com_listing_url"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        value ? checkUrl(rule, value) : Promise.resolve()
                                }
                            ]}>
                            <Input placeholder="Booking.com listing URL" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                            name="other_listing_urls"
                            rules={[
                                {
                                    validator: (rule, value) =>
                                        value ? checkUrl(rule, value) : Promise.resolve()
                                }
                            ]}>
                            <Input placeholder="Other sites listing URL" />
                        </Form.Item>
                    </Col>
                </Row>
                <Button htmlType="submit" size="large" type="primary" loading={isSubmitting}>
                    Submit
                </Button>
                <p className="form-bottom-line">
                    By clicking the 'Submit’ button you agree to our
                    <Link to="/terms-conditions" target={'_blank'}>
                        {' '}
                        Terms of use{' '}
                    </Link>{' '}
                    and
                    <Link to="/privacy-policy" target={'_blank'}>
                        {' '}
                        Privacy Policy{' '}
                    </Link>
                </p>
            </Form>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        isSubmitting: state.application.isSubmitting,
        applicationForm: state.application.application,
        save_application: state.application.save_application
    };
};

export default connect(mapStateToProps, {
    setSubmitState: actions.setSubmitState,
    saveApplication: actions.saveApplication,
    setApplicationField: actions.setApplicationField
})(index);

/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Tag, Tooltip } from 'antd';
import { getPaystackPayouts } from 'store/actions';
import moment from 'moment';
import PropTypes from 'prop-types';
import { transformFirstLetterToUpperCase, toDecimal } from 'utils';

function PayoutsTab(props) {
    const { paystack_payouts, getPaystackPayouts } = props;

    const perPage = 10;
    const [page, setPage] = useState(1);

    useEffect(() => {
        getPaystackPayouts({ page, perPage });
    }, []);

    function onClickNext() {
        getPaystackPayouts({ page: page + 1, perPage }).then((res) => {
            if (res?.payload?.meta?.page <= res?.payload?.meta?.pageCount)
                setPage((prev) => prev + 1);
        });
    }

    function onClickPrevious() {
        getPaystackPayouts({ page: page - 1, perPage }).then((res) => {
            if (res?.payload?.meta?.page > 1) setPage((prev) => prev - 1);
        });
    }

    const paymentColumns = [
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            render: (_text, record) => {
                return (
                    <div>
                        <span className="fw-500 mr-3">{`$${
                            record?.total_amount ? toDecimal(record?.total_amount / 100) : 0
                        }`}</span>{' '}
                        {`${'USD'}`}
                        {record?.status && (
                            <span className="ml-3">
                                <Tooltip
                                    placement="top"
                                    title={<>{`This payment is ${record?.status}`}</>}>
                                    <Tag color={record.status === 'pending' ? 'grey' : 'green'}>
                                        <span className="pr-2">
                                            {transformFirstLetterToUpperCase(record?.status)}
                                        </span>
                                        <svg
                                            aria-hidden="true"
                                            height="12"
                                            width="12"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.297 13.213L.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                fillRule="evenodd"></path>
                                        </svg>
                                    </Tag>
                                </Tooltip>
                            </span>
                        )}
                    </div>
                );
            }
        },
        // {
        //     title: 'EXTERNAL ACCOUNT',
        //     dataIndex: 'ExternalAccount'
        // },
        {
            title: 'STATEMENT DESC.',
            dataIndex: 'statement_descriptor',
            key: 'statement_descriptor'
        },
        {
            title: 'INITIATED',
            dataIndex: 'initiated',
            render: (_text, record) => {
                return (
                    <div>
                        {`${
                            record.settlement_date &&
                            moment(record.settlement_date).format('MMM D, H:mm A')
                        }`}
                    </div>
                );
            }
        },
        {
            title: 'EST. ARRIVAL',
            dataIndex: 'estArrival',
            render: (_text, record) => {
                return (
                    <div>{`${
                        record.settlement_date && moment(record.settlement_date).format('MMM D')
                    }`}</div>
                );
            }
        },
        {
            title: 'DETAILS',
            dataIndex: 'view',
            width: 100,
            render: (_text, record) => {
                return (
                    <div>
                        <Button
                            size="small"
                            id="view-detail"
                            color="primary"
                            onClick={() => props.onClickRow({ id: record?.id, tab: 'payout' })}>
                            View
                        </Button>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <Table
                size="small"
                columns={paymentColumns}
                dataSource={
                    paystack_payouts?.data
                        ? paystack_payouts.data.map((value, index) => {
                              return { ...value, key: String(index) };
                          })
                        : []
                }
                pagination={false}
                scroll={{ y: 500, x: 1800 }}
                onRow={(record) => {
                    return {
                        onClick: () => props.onClickRow({ id: record?.id, tab: 'payout' }) // click row
                    };
                }}
            />
            <div className="d-flex gap-2 justify-content-end mt-3">
                <Button
                    type="primary"
                    size="small"
                    disabled={!paystack_payouts?.meta?.page > 1}
                    onClick={onClickPrevious}>
                    Prev
                </Button>
                <Button
                    type="primary"
                    size="small"
                    disabled={paystack_payouts?.meta?.page < paystack_payouts?.meta?.pageCount}
                    onClick={onClickNext}>
                    Next
                </Button>
            </div>
        </>
    );
}

PayoutsTab.propTypes = {
    getPaystackPayouts: PropTypes.func,
    paystack_payouts: PropTypes.object,
    onClickRow: PropTypes.func
};

const mapStateToProps = (state) => ({
    paystack_payouts: state.profile.paystack_payouts
});

const mapDispatch = (dispatch) => ({
    getPaystackPayouts: (query) => dispatch(getPaystackPayouts(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(PayoutsTab));

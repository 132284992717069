/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import {
    // toogleDisableCalendarModal,
    downgradeToFreePlan,
    fetchAllLiveCalendars,
    fetchPreferedDisableCalendar
} from 'store/actions/accessCalendar';
// import { Link } from 'react-router-dom';
import { Table, Button } from 'antd';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const columns = [
    {
        title: 'CALENDAR ID',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'CALENDAR TITLE',
        dataIndex: 'title',
        key: 'title'
    }
];

const DowngradeCalendar = (props) => {
    const {
        showPopUp,
        handleShowPopUp,
        // toogleDisableCalendarModal,
        fetchAllLiveCalendars,
        liveCalendars,
        // showDowngradeCalendarPopup,
        selectedPlan,
        onConfirm,
        type,
        fetchPreferedDisableCalendar,
        preferedDisableCalendars,
        vendorSubscription
    } = props;

    const [allLiveCalendars, setAllLiveCalendars] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [noOfCalToDisable, setNoOfCalToDisable] = useState(0);

    useEffect(() => {
        fetchAllLiveCalendars();
        if (type === 'edit') {
            fetchPreferedDisableCalendar();
        }
    }, [type]);

    useEffect(() => {
        if (type === 'edit') {
            const preferenceKeys = preferedDisableCalendars.map(
                (preference) => preference.calendar_id
            );
            setSelectedRowKeys(preferenceKeys);
        } else {
            setSelectedRowKeys([]);
        }
    }, [preferedDisableCalendars, type, showPopUp]);

    useEffect(() => {
        let calendars = [];
        if (liveCalendars && liveCalendars.length) {
            calendars = liveCalendars.map((cal) => ({ ...cal, key: cal.id }));
        }
        const noOfCals =
            liveCalendars.length > selectedPlan.no_of_free_calendar
                ? liveCalendars.length - selectedPlan.no_of_free_calendar
                : 0;
        setNoOfCalToDisable(noOfCals);
        setAllLiveCalendars(calendars);
    }, [selectedPlan, liveCalendars]);

    const handleConfirmation = () => {
        // const data = {
        //     plan_id: plan.id
        // };
        // downgradeToFreePlan(data);
        onConfirm(selectedPlan);
        handleShowPopUp();
        // toogleDisableCalendarModal();
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys) => {
            setSelectedRowKeys(selectedKeys);
        }
    };

    const getNextPlanDate = () => {
        if (!vendorSubscription.expiry_date) {
            return '';
        }
        return (
            ' i.e. from ' +
            moment(vendorSubscription.expiry_date).add(1, 'day').format('YYYY-MM-DD')
        );
    };

    return (
        <Modal
            centered
            scrollable
            zIndex={1501}
            isOpen={showPopUp}
            toggle={() => {
                handleShowPopUp(false);
            }}>
            <ModalHeader className="close-alt" toggle={() => handleShowPopUp(false)}></ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex flex-wrap flex-lg-nowrap mb-3">
                    <h5 className="modal-title order-2 order-lg-1 overflow-hidden">
                        {type === 'edit' ? 'Disable calendar preferences' : 'Proceed to Downgrade'}
                    </h5>
                </div>
                <div>
                    <Row noGutters>
                        <Col xs={12}>
                            {type !== 'edit'
                                ? 'You choose to downgrade your plan to ' +
                                  selectedPlan?.name +
                                  ' plan and will be effective on next billing cycle' +
                                  getNextPlanDate()
                                : null}
                            {liveCalendars &&
                            liveCalendars.length &&
                            liveCalendars.length > selectedPlan.no_of_free_calendar ? (
                                <div>
                                    <p>
                                        You currently have {liveCalendars.length} Live calendars and
                                        your new plan will only allow you to have{' '}
                                        {selectedPlan.no_of_free_calendar}{' '}
                                        {selectedPlan.no_of_free_calendar > 1
                                            ? 'live calendars'
                                            : 'live calendar'}
                                    </p>
                                    <p>
                                        <i>
                                            Please choose {noOfCalToDisable} calendar
                                            {noOfCalToDisable > 1 ? 's' : ''} you want to disable
                                            from next billing cycle.
                                        </i>
                                    </p>
                                    <Table
                                        className="disable-calendars-on-downgrade"
                                        rowSelection={rowSelection}
                                        columns={columns}
                                        dataSource={allLiveCalendars}
                                        pagination={false}
                                        tableLayout="auto"
                                        /* onRow={(record) => ({
                                            onClick: () => {
                                            selectRow(record);
                                            },
                                        })} */
                                    />

                                    {/* showMaxErrorMsg && (
                                        <span>
                                            You can disable maximum of {noOfCalToDisable} calendar
                                            {noOfCalToDisable > 1 ? 's' : ''} to downgrade plan
                                    {noOfCalToDisable > selectedRowKeys.length && (
                                        <span className="disable-cal-error">
                                            You have to select a minimum of {noOfCalToDisable} calendar{noOfCalToDisable > 1 ? 's' : ''} to be disabled.

                                        </span>
                                    ) */}
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mt-3">
                            <Button
                                color="primary"
                                className="btn btn-secondary preference-action-btns"
                                disabled={noOfCalToDisable > selectedRowKeys.length}
                                onClick={handleConfirmation}>
                                Confirm
                            </Button>
                            <Button
                                color="secondary"
                                className="close-btn ml-3 preference-action-btns"
                                onClick={() => handleShowPopUp(false)}>
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    );
};

DowngradeCalendar.propTypes = {
    isModalOpen: PropTypes.bool,
    toogleDisableCalendarModal: PropTypes.func,
    fetchAllLiveCalendars: PropTypes.func,
    get_calendar_data: PropTypes.object,
    upcomingSubscription: PropTypes.object,
    showDowngradeCalendarPopup: PropTypes.bool,
    selectedPlan: PropTypes.object.isRequired,
    liveCalendars: PropTypes.array,
    onConfirm: PropTypes.func.isRequired,
    type: PropTypes.string,
    fetchPreferedDisableCalendar: PropTypes.func.isRequired,
    preferedDisableCalendars: PropTypes.array,
    vendorSubscription: PropTypes.object,
    showPopUp: PropTypes.bool,
    handleShowPopUp: PropTypes.func
};

const mapStateToProps = (state) => ({
    // showDowngradeCalendarPopup: state.accessCalendarReducer.showDowngradeCalendarPopup,
    liveCalendars: state.accessCalendarReducer.liveCalendars,
    preferedDisableCalendars: state.accessCalendarReducer.preferedDisableCalendars,
    vendorSubscription: state.accessCalendarReducer.vendorSubscription,

    upcomingSubscription: state.accessCalendarReducer.upcomingSubscription
});

const mapDispatch = (dispatch) => ({
    // toogleDisableCalendarModal: (value) => dispatch(toogleDisableCalendarModal(value)),
    downgradeToFreePlan: (data) => dispatch(downgradeToFreePlan(data)),
    fetchAllLiveCalendars: () => dispatch(fetchAllLiveCalendars()),
    fetchPreferedDisableCalendar: () => dispatch(fetchPreferedDisableCalendar())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(DowngradeCalendar));

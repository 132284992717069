import React from 'react';

const EventCardHeaderIcon = () => {
    return (
        <span className="event-card-header-icon mr-2">
            <svg
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M18.3193 4.38278C18.1087 3.68236 17.6714 3.0681 17.0734 2.63266C16.4755 2.19723 15.7493 1.96423 15.0045 1.96882C14.1413 1.96957 13.3095 2.28687 12.6728 2.85833C12.0361 3.4298 11.6405 4.21409 11.5636 5.05704H9.4374C9.35955 4.21321 8.96258 3.42844 8.32451 2.85698C7.68644 2.28552 6.85343 1.96871 5.9892 1.96882C5.24536 1.96524 4.52047 2.19873 3.92373 2.63411C3.327 3.0695 2.89073 3.6832 2.68065 4.38278L0.0441001 12.9969C0.0152175 13.0928 0.000369431 13.1923 0 13.2923V14.3217C0.00166777 15.6865 0.555471 16.9948 1.5399 17.9598C2.52433 18.9247 3.85899 19.4674 5.25105 19.4688C6.27131 19.467 7.26899 19.1741 8.12224 18.6257C8.97549 18.0773 9.64738 17.2971 10.0558 16.3805H10.9441C11.3526 17.2971 12.0245 18.0773 12.8778 18.6257C13.731 19.1741 14.7287 19.467 15.749 19.4688C17.1412 19.4674 18.476 18.9246 19.4605 17.9594C20.4449 16.9943 20.9986 15.6856 21 14.3207V13.2923C21 13.1914 20.9842 13.0926 20.9559 12.9969L18.3193 4.38278ZM5.25105 17.4099C4.41586 17.4088 3.61518 17.0832 3.02452 16.5043C2.43385 15.9254 2.10139 15.1405 2.1 14.3217C2.1 14.2435 2.11785 14.1704 2.1231 14.0932C2.18055 13.3178 2.53464 12.5924 3.11447 12.0621C3.6943 11.5318 4.45706 11.2359 5.25 11.2335C6.08543 11.2335 6.88665 11.5589 7.47739 12.138C8.06813 12.7172 8.4 13.5027 8.4 14.3217V14.331C8.39639 15.1479 8.06321 15.9302 7.4732 16.5071C6.8832 17.084 6.08433 17.4086 5.25105 17.4099ZM15.749 17.4099C14.9157 17.4086 14.1168 17.084 13.5268 16.5071C12.9368 15.9302 12.6036 15.1479 12.6 14.331V14.3217C12.5999 13.9162 12.6813 13.5146 12.8396 13.1399C12.9978 12.7652 13.2298 12.4247 13.5222 12.1379C14.1129 11.5586 14.914 11.2331 15.7495 11.233C16.5849 11.2328 17.3862 11.5581 17.977 12.1371C18.5679 12.7162 18.8999 13.5016 18.9 14.3207C18.8989 15.1397 18.5665 15.9248 17.9758 16.5039C17.3852 17.083 16.5843 17.4088 15.749 17.4099Z"
                    fill="white"
                />
            </svg>
        </span>
    );
};

export default EventCardHeaderIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';

import { PlusIcon, BasicProgress } from 'components/atoms';
import { getCalendarsById_ } from 'store/actions';
import { connect } from 'react-redux';

const CalendarItem = (props) => {
    const { onIncreaseRating, calendar, getCalendarsById_ } = props;

    return (
        <Row className="align-items-lg-center py-3 border-bottom border-primary-shaded" noGutters>
            <Col xs={12} className="flex-lg-150-rest">
                <Row>
                    <Col xs={12} lg={8}>
                        <div className="fs-16 fw-500 text-truncate">{calendar?.title}</div>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className="flex-lg-150">
                <BasicProgress
                    className="progress-title-stand-out"
                    percent={calendar?.rating?.average || 0}
                />
                <div
                    className="float-right position-relative"
                    style={{ marginTop: -36, paddingBottom: 12 }}>
                    <span
                        role="button"
                        tabIndex="0"
                        className="fs-12 text-primary fw-400 ff-special"
                        onClick={() => {
                            getCalendarsById_({ data: calendar });
                            onIncreaseRating();
                        }}
                        onKeyUp={onIncreaseRating}>
                        Increase <PlusIcon />
                    </span>
                </div>
            </Col>
        </Row>
    );
};

CalendarItem.propTypes = {
    calendar: PropTypes.any,
    onIncreaseRating: PropTypes.any,
    getCalendarsById_: PropTypes.func
};

export default connect(null, { getCalendarsById_ })(CalendarItem);

import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { ArrowRightOutlined } from '@ant-design/icons';
import EmbedCodeHelpVideo from '../../../../../assets/videos/embed_code_help.mp4';
import Helpvtt from '../../../../../assets/videos/help.vtt';

export default function HelpDrawer(props) {
    const { setState, state } = props;

    return (
        <Drawer
            width={609}
            placement="right"
            onClose={() => setState({ toggleHelpDrawer: false })}
            visible={state.toggleHelpDrawer}
            className="drawers-padding"
            style={{ zIndex: 1060 }}>
            <div style={{ color: '#A2792C' }} className="fs-28 fw-600 mt-5">
                How to use embed link in AirBnb and Booking.com
            </div>
            <div className="fs-18 fw-400">
                <strong className="pl-4">For Booking.com</strong>
                <ol>
                    <li>Login into your admin.booking.com/hotel account</li>
                    <li>
                        Navigate to <i>Property</i>{' '}
                        <ArrowRightOutlined className="fs-16 rt-arrow-icon" />{' '}
                        <i>Messaging preferences</i>{' '}
                    </li>
                    <li>
                        Go to <i>Template Scheduler</i> tab and click on <i>Schedule a template</i>
                        button
                    </li>
                    <li>
                        Select a trigger and then click on <i>Create a new template</i> hyperlink
                    </li>
                    <li>
                        Paste the calendar onboarding link in the temaplate body and add the short
                        code for customer name, start date and end date.
                    </li>
                </ol>
                <strong className="pl-4">For AirBnb</strong>
                <ol>
                    <li>
                        Visit to your{' '}
                        <a
                            href="https://www.airbnb.co.in/hosting"
                            target="_blank"
                            rel="noopener noreferrer">
                            Airbnb account
                        </a>
                    </li>
                    <li>
                        Then go to <i>Inbox</i>{' '}
                        <ArrowRightOutlined className="fs-16 rt-arrow-icon" />{' '}
                        <i>Scheduled messages</i>{' '}
                    </li>
                    <li>
                        Create/Edit a scheduled message and paste the copied link in <i>Message</i>{' '}
                        field
                    </li>
                    <li>Add Shortcodes in the link as explained in the video</li>
                    <video
                        width="100%"
                        src={EmbedCodeHelpVideo}
                        autoPlay={false}
                        controls={true}
                        className="pt-4">
                        <track
                            src={Helpvtt}
                            kind="captions"
                            srcLang="en"
                            label="english_captions"
                        />
                    </video>
                </ol>
            </div>
        </Drawer>
    );
}

HelpDrawer.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func
};

import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from 'antd';
import WarningIcon from '../../../../assets/images/warning-icon.svg';
import PropTypes from 'prop-types';
import { archiveProduct } from 'store/actions/availabilityActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const ArchiveModal = ({
    isOpen,
    canNotArchive,
    confirmationWindow,
    setConfirmationWindow,
    setCanNotArchive,
    toggleModal,
    archiveProduct,
    refreshList,
    openedRecord
}) => {
    const [lastWindow, setLastWindow] = useState(false);

    const toggleArchiveModal = () => {
        setLastWindow(false);
        setConfirmationWindow();
        setCanNotArchive();
        toggleModal();
    };

    const openLastWindow = () => {
        if (confirmationWindow) {
            let data = {
                id: openedRecord?.id
            };
            archiveProduct(data)
                .then(() => {
                    setLastWindow(true);
                    setConfirmationWindow();
                    setCanNotArchive();

                    refreshList();
                })
                .catch((e) => console.error(e));
        }
    };

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleArchiveModal}>
            <ModalBody className="text-center">
                <img src={WarningIcon} alt="" />
                <div className="modal-body-title-lg mt-2">
                    {canNotArchive && 'Cannot archive!'}
                    {confirmationWindow && 'Archive?'}
                    {lastWindow && 'Archived'}
                </div>
                <p className="modal-body-text">
                    {canNotArchive && (
                        <span>
                            Only stopped and cancelled products can be archived.
                            <br />
                            <b className="fs-18">Stop</b> or <b className="fs-18">Cancel</b> the
                            product and then you will be able to archive it.
                        </span>
                    )}
                    {confirmationWindow && (
                        <span>Are you sure you want to archive this product?</span>
                    )}
                    {lastWindow && (
                        <span>
                            This product is now archived. <br /> Check your{' '}
                            <b className="fs-18">archived list</b> to restore the product.
                        </span>
                    )}
                </p>
                <div className="mt-4">
                    {confirmationWindow && (
                        <Button
                            id="pauseSaleTooltip"
                            type="primary"
                            className="btn-mw mx-2"
                            onClick={openLastWindow}>
                            ARCHIVE
                        </Button>
                    )}
                    <Button
                        type="dark"
                        className="btn-mw mx-2"
                        onClick={() => toggleArchiveModal()}>
                        {confirmationWindow ? 'NO' : 'OK'}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

ArchiveModal.propTypes = {
    isOpen: PropTypes.bool,
    canNotArchive: PropTypes.bool,
    confirmationWindow: PropTypes.bool,
    setConfirmationWindow: PropTypes.func,
    setCanNotArchive: PropTypes.func,
    toggleModal: PropTypes.func,
    refreshList: PropTypes.func,
    openedRecord: PropTypes.any,
    archiveProduct: PropTypes.func
};
const mapDispatch = (dispatch) => ({
    archiveProduct: (params) => dispatch(archiveProduct(params))
});
export default withRouter(connect(null, mapDispatch)(ArchiveModal));

import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import { NavLink } from 'react-router-dom';

//Mock data
// const data = ['Accra Beach & Service Route', 'Deep tissue Abuja '];
// const calendar = ['All customers calendars', '1 week Victoria falls & CHobe experience'];
export default function Availability(props) {
    return (
        <>
            <div className="e2e-fix">
                <div className="bg-calendar customer-calendars-x-padded customer-calendars-y-padded _extra-padded-bottom">
                    <div className="d-flex flex-wrap flex-lg-nowrap">
                        <div className="flex-grow-1 w-100 mr-2">
                            <h1 className="static-heading mb-2">{props.title}</h1>
                        </div>
                    </div>
                </div>
                <div className="customer-calendars-x-padded section-negative-top">
                    <div className="main-calendar-table-section mb-40px">
                        <p>
                            Your products will be automatically available for bookings, for the next
                            18 months. To avoid overbookings and operate effectively across multiple
                            sites, you can use an iCal link to import reserved or unavailable dates.
                            These dates will be blocked in your customers calendar, so guests cannot
                            reserve your property on AfricantTravel.club
                        </p>
                    </div>
                </div>
            </div>
            <div className="connect-channel mb-3">
                <div className=" d-flex float-right align-items-center">
                    <h6 className="m-0" style={{ fontWeight: '400', color: '#A3792D' }}>
                        Sync your availability with other websites
                    </h6>
                    <NavLink
                        className="btn btn-primary btn-sm mr-sm-2 btn-block btn-sm-inline-block ml-4"
                        to="/get-started">
                        Connect with Channel Manager
                    </NavLink>
                </div>
            </div>
            {props.children}
        </>
    );
}
Availability.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string
};

import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import warningIcon from 'assets/images/warning-icon.svg';

const DeactivateAccountModal = (props) => {
    const { isOpenModal, onToggle, onClickYes, onClickNo } = props;
    return (
        <Modal size="lg" centered isOpen={isOpenModal} toggle={onToggle}>
            <ModalBody className="text-center">
                <img src={warningIcon} alt="" />
                <div className="modal-body-title-lg mt-2">Are you sure?</div>
                <p className="modal-body-text">Are you sure you want to De-activate Account? </p>
                <div className="mt-4">
                    <Button
                        type="primary"
                        className="btn-mw text-uppercase mx-2"
                        onClick={onClickYes}>
                        Yes
                    </Button>{' '}
                    <Button type="dark" className="btn-mw text-uppercase mx-2" onClick={onClickNo}>
                        No
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
DeactivateAccountModal.propTypes = {
    isOpenModal: PropTypes.bool,
    onClickYes: PropTypes.func,
    onClickNo: PropTypes.func,
    onToggle: PropTypes.func
};

export default DeactivateAccountModal;

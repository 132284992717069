/* eslint-disable react/no-unescaped-entities */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { Input, TimePicker } from 'antd';
import { disabledDate, dateAvailability, disabledDateAvailability } from 'utils/validation';
import moment from 'moment';
import {
    WhatsAppOutlined,
    LinkOutlined,
    MailOutlined,
    SyncOutlined,
    // CopyOutlined,
    CalendarOutlined,
    SwapRightOutlined,
    PlusCircleOutlined,
    MinusCircleOutlined
} from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import SendToCustomerFooterAndExtras from './send-to-customer-footer-and-extras';
import PakcageProductsDrawer from './package-products-drawer';
import { validatePacakgeProductFieldsForLink, getDynamicError, isSelectedRangeValid } from 'utils';
import { DatePickerComponent, RangePickerComponent, SelectCustomDropdown } from 'components/atoms';

export default function SendByComponent(props) {
    const { setState, state, setCalendarDate, startDate, endDate, inhertProps } = props;
    const { errors } = state;
    const mainProduct =
        Array.isArray(inhertProps?.get_calendar_data?.data?.mainProduct) &&
        inhertProps?.get_calendar_data?.data?.mainProduct.length > 0
            ? inhertProps?.get_calendar_data?.data?.mainProduct[0]
            : null;
    const mainPackage =
        Array.isArray(inhertProps?.get_calendar_data?.data?.mainPackage) &&
        inhertProps?.get_calendar_data?.data?.mainPackage.length > 0
            ? inhertProps?.get_calendar_data?.data?.mainPackage[0]
            : null;

    useEffect(() => {
        setState({ calendarLink: '', sendBtn: 'Send' });
        if (state?.viewPaidTwo) {
            setState({ sendBtn: 'Copy Link', linkTitle: 'Link is copied' });
            onResetPaidTwo();
        }

        //unmounting
        return () => goBack();
    }, []);

    useEffect(() => {
        if (mainPackage && Array.isArray(inhertProps?.package_detail?.products)) {
            let products = [];
            for (let val of inhertProps.package_detail.products) {
                products.push({ day_type: val?.day_type, title: val?.title, id: val?.id });
            }
            setState({ packageProducts: products });
        }
    }, [inhertProps?.package_detail]);
    /* set the button active if that option is selected and others inactive 
    set the message to show on link modal 
    and set the send button value accordinf to selection */
    function handleSendBy(opt) {
        switch (opt) {
            case 'link':
                setState({
                    showLink: true,
                    showWhatsapp: false,
                    showEmail: false,
                    linkTitle: 'Link copied Successfully',
                    sendBtn: 'Copy Link',
                    calendarLink: '',
                    shortendUrl: null
                });
                break;
            case 'whatsapp':
                setState({
                    showLink: false,
                    showWhatsapp: true,
                    showEmail: false,
                    linkTitle: 'Whatsapp link copied Successfully',
                    sendBtn: 'Send',
                    calendarLink: '',
                    // firstname: '',
                    errors: [],
                    shortendUrl: null
                });
                break;
            case 'email':
                setState({
                    showLink: false,
                    showWhatsapp: false,
                    showEmail: true,
                    linkTitle: 'Email Copied Successfully',
                    sendBtn: 'Send',
                    calendarLink: '',
                    // email: '',
                    // firstname: '',
                    errors: [],
                    shortendUrl: null
                });
                break;
            default:
                break;
        }
        // setCalendarDate({
        //     startDate: '',
        //     endDate: ''
        // });
    }

    function removeError(fieldName) {
        let err = [];
        if (Array.isArray(errors)) err = errors.filter((value) => value !== fieldName);
        setState({ errors: err });
    }

    function removeErrors(fieldName1, fieldName2) {
        let err = [];
        if (Array.isArray(errors)) {
            err = errors.filter((value) => value !== fieldName1);
            err = err.filter((value) => value !== fieldName2);
        }
        setState({ errors: err });
    }

    function onReset() {
        setState({
            messageTemplate:
                'We look forward to having you and have also taken some time to help plan your trip to make it a memorable one using Travel Jinni. \nThis travel plan details things you can do on your trip, and it is included for FREE as part of your purchase.'
        });
    }

    function getDynamicText(products, mainProduct) {
        return (
            <>
                <div className="mb-3">
                    Hello <b>*insert first name (short code)*</b>,
                </div>
                <div className="mb-3">{`Thank you for booking ${
                    mainProduct?.title || mainPackage?.title
                }`}</div>
                <div className="mb-3">
                    We look forward to having you and have also taken some time to help plan your
                    trip to make it a memorable one using Travel Jinni. This travel plan details
                    things you can do on your trip, and it is included for <b>FREE</b> as part of
                    your purchase.
                </div>

                <div className="mb-3">
                    You will be required to provide the information below to confirm your details to
                    access it.
                </div>
                {mainProduct ? (
                    <>
                        <div>
                            First Name: <b>*insert first name (short code)*</b>
                        </div>
                        {mainProduct.day_type === 'within_day' && (
                            <div>
                                Date: <b>*insert date (short code)*</b>
                            </div>
                        )}
                        <div>
                            Start Time: <b>*insert start time (short code)*</b>
                        </div>
                        <div>
                            End Time: <b>*insert end time (short code)*</b>
                        </div>
                        <div>
                            No of Guests: <b>*insert no of guests (short code)*</b>
                        </div>
                        <div>
                            Price: <b>*insert price (short code)*</b>
                        </div>
                    </>
                ) : (
                    <>
                        <div>{mainPackage?.title || ''}</div>
                        <div>
                            Package Start Date: <b>*insert Start date (short code)*</b>
                        </div>
                        <div>
                            Package End Date: <b>*insert Start date (short code)*</b>
                        </div>
                        {Array.isArray(products) &&
                            products.map((val, i) =>
                                val?.day_type === 'all_day' ? (
                                    <div key={i} className="mt-3">
                                        <div>{val?.title || ''}</div>
                                        <div>
                                            Check In: <b>*insert check in date (short code)*</b>
                                        </div>
                                        <div>
                                            Check Out: <b>*insert check out date (short code)*</b>
                                        </div>
                                    </div>
                                ) : (
                                    <div key={i} className="mt-3">
                                        <div>{val?.title || ''}</div>
                                        <div>
                                            Date: <b>*insert date (short code)*</b>
                                        </div>
                                        <div>
                                            Start Time: <b>*insert start time (short code)*</b>
                                        </div>
                                        <div>
                                            End Time: <b>*insert end time (short code)*</b>
                                        </div>
                                    </div>
                                )
                            )}
                    </>
                )}

                <div className="mb-3 mt-3">Access it using the link below</div>

                <div className="mb-3">
                    <b>*Link inserted*</b>
                </div>
                <div>Kind Regards,</div>
                <div>{inhertProps?.myProfile?.first_name || ''}</div>
                <div>
                    {inhertProps?.myProfile?.job_title || 'Customer Service Manager'},{' '}
                    {inhertProps?.profile?.business_info?.business_trading_name || ''}
                </div>
            </>
        );
    }

    function onResetPaidTwo() {
        let element = getDynamicText(state?.packageProducts, mainProduct);
        setState({ linkPreviewMessage: null });
        setTimeout(() => setState({ linkPreviewMessage: element }), 500);
    }

    // function copyToClipboard() {
    //     navigator.clipboard.writeText(state.calendarLink);
    // }

    function handleNoToken() {
        setState({ toggleNoTokenDrawer: false });
        inhertProps.toggleAddTopUp(true);
    }

    function onChangPP(value, i, key) {
        setState((prev) => {
            const pp = prev.packageProducts;
            pp[i] = { ...pp[i], [key]: value };
            return {
                packageProducts: pp
            };
        });
        removeError(`${key}${i}`);
    }

    function validatePackageFields() {
        let obj = validatePacakgeProductFieldsForLink(state, inhertProps);
        if (Array.isArray(obj?.errors) && obj.errors.length > 0)
            return setState({ errors: obj.errors });
        else setState({ productDetailsDrawerToggle: false });
    }

    function goBack() {
        setState({
            firstname: '',
            messageTemplate:
                'We look forward to having you and have also taken some time to help plan your trip to make it a memorable one using Travel Jinni. \nThis travel plan details things you can do on your trip, and it is included for FREE as part of your purchase.',
            email: '',
            productStartDate: null,
            productEndDate: null,
            productDate: null,
            productStartTime: null,
            productEndTime: null,
            packageStartDate: null,
            packageEndDate: null,
            showEmail: false,
            showLink: false,
            shortendUrl: null,
            linkTitle: 'Whatsapp link is copied',
            errors: []
        });
        inhertProps.setCalendarDate({
            startDate: '',
            endDate: ''
        });
    }

    function onPanelChange(date, productId, calendarId, isForRange) {
        //only checking availabilty of future dates
        if (!moment().isAfter(date, 'day')) {
            let endDate, startDate;

            if (isForRange) {
                startDate = moment(date).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
                endDate = moment(date).add(1, 'month').endOf('month').format('YYYY-MM-DD');
            } else {
                startDate = moment(date).startOf('month').format('YYYY-MM-DD');
                endDate = moment(date).endOf('month').format('YYYY-MM-DD');
            }

            inhertProps
                .getProductAvailability({ startDate, endDate, productId, calendarId })
                .then((res) => {
                    if (Array.isArray(res?.availabilityArray))
                        setState({ availabilityData: res.availabilityArray });
                });
        }
    }

    function onChangPPRange(startDate, endDate, i, startKey, endKey, isPaidOne) {
        if (isPaidOne && !isSelectedRangeValid(startDate, endDate, state.availabilityData))
            setState((prev) => {
                const pp = prev.packageProducts;
                pp[i] = { ...pp[i], [startKey]: '', [endKey]: '' };
                return {
                    errors: [...prev.errors, `ppRangeInvalid${i}`],
                    packageProducts: pp
                };
            });
        else {
            setState((prev) => {
                const pp = prev.packageProducts;
                pp[i] = { ...pp[i], [startKey]: startDate, [endKey]: endDate };
                return {
                    packageProducts: pp
                };
            });
            removeErrors(`${startKey}${i}`, `${endKey}${i}`);
        }
    }

    function onChangeRangeDate(startDate, endDate, startKey, endKey, isPaidOne) {
        if (isPaidOne && !isSelectedRangeValid(startDate, endDate, state.availabilityData))
            setState((prev) => {
                return {
                    errors: [...prev.errors, 'psedRangeInvalid'],
                    [startKey]: '',
                    [endKey]: ''
                };
            });
        else {
            setState({
                [startKey]: startDate,
                [endKey]: endDate
            });
            removeErrors(startKey, endKey);
        }
    }

    function handleCount(index, increase) {
        const rawTravellers = [...inhertProps.allTravellers];
        const count = rawTravellers[index]?.count ?? 0;
        rawTravellers[index].count = increase ? count + 1 : count > 1 ? count - 1 : 0;
        inhertProps.updateAllTravellers(rawTravellers);
        removeError('guests');
    }

    function getSelectedGuests() {
        let str = '';
        if (Array.isArray(inhertProps?.allTravellers))
            inhertProps?.allTravellers.forEach((traveller, ind) => {
                if (traveller?.count)
                    str += `${ind !== 0 ? ', ' : ''}${traveller?.count} ${traveller?.name}`;
            });

        return str ? str : undefined;
    }

    return (
        <div className="send-by-wrapper">
            <div className="d-flex flex-wrap gap-3 mb-3">
                <div className="d-flex flex-wrap send-by-options mt-0 flex-grow-1">
                    <div className="sendByCol pt-2">
                        <span className="send-by-text">Send By</span>
                    </div>
                    <div>
                        <div className="sendbtnsCol d-flex">
                            {state?.viewPaidTwo ? (
                                <Button
                                    className={state.showLink ? 'sendby-btn-active' : 'sendby-btn'}
                                    onClick={() => handleSendBy('link')}
                                    id="link-btn">
                                    <LinkOutlined className="mr-2 fs-20 send-btn-icon" />
                                    Link
                                </Button>
                            ) : (
                                <>
                                    <Button
                                        className={
                                            state.showWhatsapp ? 'sendby-btn-active' : 'sendby-btn'
                                        }
                                        onClick={() => handleSendBy('whatsapp')}>
                                        <WhatsAppOutlined className="mr-1 fs-20 send-btn-icon" />
                                        Whatsapp
                                    </Button>
                                    <Button
                                        className={
                                            state.showEmail ? 'sendby-btn-active' : 'sendby-btn'
                                        }
                                        onClick={() => handleSendBy('email')}>
                                        <MailOutlined className="mr-3 fs-20 send-btn-icon" />
                                        Email
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div>
                    {/* <Button color="primary" className="btn-mw">
                        Send
                    </Button> */}
                    <SendToCustomerFooterAndExtras
                        state={state}
                        setState={setState}
                        inhertProps={inhertProps}
                        handleNoToken={handleNoToken}
                    />
                </div>
            </div>

            <div
                className="scrollbar-fixed flex-grow-1"
                style={{
                    overflowY: 'auto',
                    paddingRight: 10
                }}>
                <div className="fs-14 fw-500 text-muted">
                    {`${
                        state?.viewNotPaidOne
                            ? 'For customers that want to check availability, view details and pay for'
                            : state?.viewNotPaidTwo
                            ? 'For customers that that have already agreed dates with you and want a link to pay for'
                            : state?.viewPaidOne
                            ? 'For customers that has booked'
                            : state?.viewPaidTwo
                            ? 'For customers that will book'
                            : ''
                    } ${
                        mainProduct?.title
                            ? mainProduct?.title
                            : mainPackage?.title
                            ? mainPackage?.title
                            : 'Product'
                    } ${
                        state?.viewPaidOne
                            ? 'directly with you'
                            : state?.viewPaidTwo
                            ? 'through third party sites'
                            : ''
                    }.`}
                </div>
                <hr className="my-2" />
                <Row>
                    {!state.viewPaidTwo && (
                        <div className="col-lg">
                            <div className="sendbtnsCol emailCol">
                                {/* Show if email option is selected */}
                                {/* {state.showEmail ? ( */}
                                <div>
                                    {(state.viewNotPaidTwo || state.viewPaidOne) && mainPackage && (
                                        <Row form>
                                            <Col xs={12}>
                                                <div className="fs-14 fw-500 mb-2 text-truncate">
                                                    Package Dates
                                                </div>
                                                <div className="mb-3 overflow-hidden">
                                                    <Button
                                                        color="white"
                                                        className="fs-12 fw-400 text-muted border-primary text-truncate d-inline-flex align-items-center text-left justify-content-between gap-2"
                                                        size="sm"
                                                        block
                                                        onClick={() =>
                                                            setState({
                                                                productDetailsDrawerToggle: true
                                                            })
                                                        }>
                                                        <span className="d-inline-flex w-50">
                                                            <span className="mr-2">
                                                                {state.packageStartDate ||
                                                                    'Start date'}
                                                            </span>
                                                            <span className="ml-auto">
                                                                <SwapRightOutlined
                                                                    style={{ fontSize: 14 }}
                                                                />
                                                            </span>
                                                        </span>
                                                        <span className="d-inline-flex w-50">
                                                            <span className="mr-2">
                                                                {state.packageEndDate || 'End date'}
                                                            </span>

                                                            <span className="ml-auto">
                                                                <CalendarOutlined />
                                                            </span>
                                                        </span>
                                                    </Button>
                                                    {getDynamicError(errors, [
                                                        {
                                                            f: 'packageStartDate',
                                                            m: 'Date(s) of booking is required'
                                                        },
                                                        {
                                                            f: 'packageEndDate',
                                                            m: 'Date(s) of booking is required'
                                                        },
                                                        {
                                                            f: 'pasedNotInTRange',
                                                            m: 'Package dates should be between the trip dates'
                                                        },
                                                        {
                                                            f: 'fillPackageProducts',
                                                            m: 'Please fill package products properly'
                                                        }
                                                    ])}
                                                </div>
                                            </Col>
                                        </Row>
                                    )}

                                    {/* Product Date */}
                                    {(state.viewNotPaidTwo || state.viewPaidOne) && mainProduct && (
                                        <>
                                            {mainProduct?.day_type === 'within_day' && (
                                                <>
                                                    <div className="fs-14 fw-500 mb-2">
                                                        Product Date
                                                    </div>
                                                    <div className="mb-3">
                                                        <DatePickerComponent
                                                            value={state.productDate}
                                                            disabledDate={(current) =>
                                                                state.viewPaidOne
                                                                    ? disabledDateAvailability(
                                                                          current,
                                                                          state.availabilityData
                                                                      )
                                                                    : disabledDate(current)
                                                            }
                                                            onChange={(date, dateString) => {
                                                                setState({
                                                                    productDate: dateString
                                                                });
                                                                removeError('productDate');
                                                            }}
                                                            onPanelChange={(date) =>
                                                                state.viewPaidOne &&
                                                                onPanelChange(
                                                                    date,
                                                                    mainProduct?.id,
                                                                    inhertProps?.calendarId
                                                                )
                                                            }
                                                            onOpenChange={(bool) =>
                                                                bool &&
                                                                state.viewPaidOne &&
                                                                onPanelChange(
                                                                    moment(),
                                                                    mainProduct?.id,
                                                                    inhertProps?.calendarId
                                                                )
                                                            }
                                                            dateAvailability={(current) =>
                                                                state.viewPaidOne &&
                                                                dateAvailability(
                                                                    current,
                                                                    state.availabilityData
                                                                )
                                                            }
                                                            showFooterHeader={
                                                                state.viewPaidOne ? true : false
                                                            }
                                                        />
                                                        {getDynamicError(errors, [
                                                            {
                                                                f: 'productDate',
                                                                m: 'Date of booking is required'
                                                            },
                                                            {
                                                                f: 'pdNotInTRange',
                                                                m: 'Product date should be between the trip dates'
                                                            }
                                                        ])}
                                                    </div>

                                                    {/* Product times */}
                                                    <div className="mb-3">
                                                        <Row form>
                                                            <Col>
                                                                <div className="fs-14 fw-500 mb-2 text-truncate">
                                                                    Product Start Time
                                                                </div>
                                                                <div>
                                                                    <TimePicker
                                                                        className="inputclass mb-1"
                                                                        placeholder="Select start time"
                                                                        size="small"
                                                                        use12Hours
                                                                        format="hh:mm A"
                                                                        onChange={(e) => {
                                                                            setState({
                                                                                productStartTime: e
                                                                                    ? e.format(
                                                                                          'hh:mm A'
                                                                                      )
                                                                                    : null
                                                                            });
                                                                            removeError(
                                                                                'productStartTime'
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div className="fs-14 fw-500 mb-2 text-truncate">
                                                                    Product End Time
                                                                </div>
                                                                <div>
                                                                    <TimePicker
                                                                        className="inputclass mb-1"
                                                                        placeholder="Select end time"
                                                                        size="small"
                                                                        use12Hours
                                                                        format="hh:mm A"
                                                                        onChange={(e) => {
                                                                            setState({
                                                                                productEndTime: e
                                                                                    ? e.format(
                                                                                          'hh:mm A'
                                                                                      )
                                                                                    : null
                                                                            });
                                                                            removeError(
                                                                                'productEndTime'
                                                                            );
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col>
                                                                {getDynamicError(errors, [
                                                                    {
                                                                        f: 'productStartTime',
                                                                        m: 'Time of booking is required'
                                                                    },
                                                                    {
                                                                        f: 'productEndTime',
                                                                        m: 'Time of booking is required'
                                                                    }
                                                                ])}
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </>
                                            )}

                                            {/* Product Dates  */}
                                            {mainProduct?.day_type === 'all_day' && (
                                                <Row form>
                                                    <Col xs={12}>
                                                        <div className="fs-14 fw-500 mb-2">
                                                            Product Dates
                                                        </div>
                                                    </Col>
                                                    <Col xs={12}>
                                                        <RangePickerComponent
                                                            value={[
                                                                state.productStartDate,
                                                                state.productEndDate
                                                            ]}
                                                            onChange={(date, dateString) =>
                                                                onChangeRangeDate(
                                                                    dateString[0],
                                                                    dateString[1],
                                                                    'productStartDate',
                                                                    'productEndDate',
                                                                    state.viewPaidOne
                                                                )
                                                            }
                                                            disabledDate={(current) =>
                                                                state.viewPaidOne
                                                                    ? disabledDateAvailability(
                                                                          current,
                                                                          state.availabilityData
                                                                      )
                                                                    : disabledDate(current)
                                                            }
                                                            onPanelChange={(date) =>
                                                                state.viewPaidOne &&
                                                                onPanelChange(
                                                                    date[0] ? date[0] : date[1],
                                                                    mainProduct?.id,
                                                                    inhertProps?.calendarId,
                                                                    true
                                                                )
                                                            }
                                                            onOpenChange={(bool) =>
                                                                bool &&
                                                                state.viewPaidOne &&
                                                                onPanelChange(
                                                                    moment(),
                                                                    mainProduct?.id,
                                                                    inhertProps?.calendarId,
                                                                    true
                                                                )
                                                            }
                                                            dateAvailability={(current) =>
                                                                state.viewPaidOne &&
                                                                dateAvailability(
                                                                    current,
                                                                    state.availabilityData
                                                                )
                                                            }
                                                            showFooterHeader={
                                                                state.viewPaidOne ? true : false
                                                            }
                                                        />
                                                        {getDynamicError(errors, [
                                                            {
                                                                f: 'productStartDate',
                                                                m: 'Date(s) of booking is required'
                                                            },
                                                            {
                                                                f: 'productEndDate',
                                                                m: 'Date(s) of booking is required'
                                                            },
                                                            {
                                                                f: 'psedNotInTRange',
                                                                m: 'Product dates should be between the trip dates'
                                                            },
                                                            {
                                                                f: 'psedRangeInvalid',
                                                                m: 'Please select a valid range of dates'
                                                            }
                                                        ])}
                                                    </Col>
                                                </Row>
                                            )}
                                        </>
                                    )}

                                    {/* Customer's trip */}
                                    <Row form>
                                        <Col xs={12}>
                                            <div className="fs-14 fw-500 mb-2">
                                                How Long Is The Customer’s Trip?
                                            </div>
                                        </Col>
                                        <Col xs={12} className="mb-3">
                                            <RangePickerComponent
                                                value={[startDate, endDate]}
                                                onChange={(date, dateString) => {
                                                    setCalendarDate({
                                                        startDate: dateString[0],
                                                        endDate: dateString[1]
                                                    });
                                                    removeErrors('startDate', 'endDate');
                                                }}
                                                disabledDate={(current) => disabledDate(current)}
                                                showFooterHeader={false}
                                            />

                                            {getDynamicError(errors, [
                                                {
                                                    f: 'startDate',
                                                    m: `Customer's trip length is required`
                                                },
                                                {
                                                    f: 'endDate',
                                                    m: `Customer's trip length is required`
                                                }
                                            ])}
                                        </Col>
                                    </Row>

                                    {/* Customer Name */}
                                    <div className="fs-14 fw-500 mb-2">Customer’s First Name</div>
                                    <div className="mb-3">
                                        <Input
                                            type="text"
                                            className="inputclass mb-1"
                                            size="small"
                                            onChange={(e) => {
                                                setState({ firstname: e.target.value });
                                                removeError('firstname');
                                            }}
                                            placeholder="Enter customer's first name"
                                        />
                                        {errors.includes('firstname') && (
                                            <span className="error-input">
                                                Customer's name is required
                                            </span>
                                        )}
                                    </div>

                                    {state.viewPaidOne && (
                                        <>
                                            <div className="">
                                                <div className="fs-14 fw-500 mb-2">
                                                    Amount paid by customer
                                                </div>
                                                <div className="mb-3">
                                                    <Input
                                                        type="number"
                                                        className="inputclass mb-1"
                                                        size="small"
                                                        onChange={(e) => {
                                                            setState({
                                                                amountPaid: e.target.value
                                                            });
                                                            removeError('amountPaid');
                                                        }}
                                                        placeholder="Enter Amount"
                                                    />
                                                    {errors.includes('amountPaid') && (
                                                        <span className="error-input">
                                                            Amount is required
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="mb-4">
                                                <div className="fs-14 fw-500 mb-2">Guests</div>
                                                <SelectCustomDropdown
                                                    loading={false}
                                                    opened={state.showGuestSelector}
                                                    onDropdownVisibleChange={(bool) =>
                                                        setState({ showGuestSelector: bool })
                                                    }
                                                    placeHolder="Select Guests"
                                                    dropdownMatchSelectWidth={false}
                                                    className="guest-select-dropdown"
                                                    value={getSelectedGuests()}
                                                    dropdownRender={() => (
                                                        <div className=" p-3">
                                                            {Array.isArray(
                                                                inhertProps?.allTravellers
                                                            ) ? (
                                                                inhertProps.allTravellers.map(
                                                                    (value, index) => (
                                                                        <Row
                                                                            key={index}
                                                                            className=" mb-2 align-items-center">
                                                                            <Col lg={6}>
                                                                                <div className="fs-14 ">
                                                                                    {value?.name ??
                                                                                        ''}
                                                                                </div>
                                                                                <div className=" text-gray fs-10">
                                                                                    {value?.age_group ??
                                                                                        ''}
                                                                                </div>
                                                                            </Col>
                                                                            <Col
                                                                                lg={6}
                                                                                className="">
                                                                                <Row className=" align-items-center">
                                                                                    <Col
                                                                                        span={3}
                                                                                        className={`d-flex align-items-center  justify-content-end ${
                                                                                            value?.count >
                                                                                            0
                                                                                                ? 'cursor-pointer'
                                                                                                : 'cursor-not-allowed op-5'
                                                                                        }`}
                                                                                        onClick={() =>
                                                                                            handleCount(
                                                                                                index,
                                                                                                false
                                                                                            )
                                                                                        }>
                                                                                        <MinusCircleOutlined
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    '1.2rem'
                                                                                            }}
                                                                                        />
                                                                                    </Col>

                                                                                    <Col
                                                                                        span={6}
                                                                                        className=" text-center">
                                                                                        {value?.count ??
                                                                                            0}
                                                                                    </Col>
                                                                                    <Col
                                                                                        onClick={() =>
                                                                                            handleCount(
                                                                                                index,
                                                                                                true
                                                                                            )
                                                                                        }
                                                                                        span={3}
                                                                                        className={`d-flex align-items-center  cursor-pointer`}>
                                                                                        <PlusCircleOutlined
                                                                                            style={{
                                                                                                fontSize:
                                                                                                    '1.2rem'
                                                                                            }}
                                                                                        />
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    )
                                                                )
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    )}
                                                />
                                                {errors.includes('guests') && (
                                                    <span className="error-input">
                                                        At least one guest is required
                                                    </span>
                                                )}
                                            </div>
                                        </>
                                    )}

                                    {/* Customer's Email */}
                                    {state.showEmail && (
                                        <>
                                            <div className="fs-14 fw-500 mb-2">
                                                Customer’s Email Address
                                            </div>
                                            <div className="mb-3">
                                                <Input
                                                    type="email"
                                                    className="inputclass mb-1"
                                                    size="small"
                                                    onChange={(e) => {
                                                        setState({ email: e.target.value });
                                                        removeError('email');
                                                    }}
                                                    placeholder="Enter customer's email address"
                                                />
                                                {errors.includes('invalidEmail') ? (
                                                    <span className="error-input">
                                                        Please enter valid email
                                                    </span>
                                                ) : errors.includes('email') ? (
                                                    <span className="error-input">
                                                        Email address is required
                                                    </span>
                                                ) : null}
                                            </div>
                                        </>
                                    )}

                                    {/* Custome message */}
                                    <div className="d-flex align-items-center flex-nowrap gap-2 mb-2">
                                        <div className="fs-14 fw-500 flex-grow-1">
                                            Custom message
                                        </div>
                                        <div>
                                            <span
                                                className="reset-text"
                                                role="button"
                                                tabIndex="0"
                                                onClick={onReset}
                                                onKeyDown={onReset}>
                                                <SyncOutlined className="mr-1 fs-20" />
                                                Reset
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <TextArea
                                            className="content-4 mb-0"
                                            id="editMsg"
                                            rows={8}
                                            value={state.messageTemplate}
                                            onChange={(e) => {
                                                setState({ messageTemplate: e.target.value });
                                                removeError('messageTemplate');
                                            }}
                                        />
                                        {errors.includes('messageTemplate') && (
                                            <span className="error-input">Message is Required</span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-lg">
                        {state?.viewPaidTwo ? (
                            <>
                                <Row className="mb-2">
                                    <Col>
                                        <span htmlFor="editMsg" className="edit-text">
                                            Edit Messsage
                                        </span>
                                    </Col>
                                    <Col className="text-right">
                                        <span
                                            className="reset-text"
                                            role="button"
                                            tabIndex="0"
                                            onClick={onResetPaidTwo}
                                            onKeyDown={onResetPaidTwo}>
                                            <SyncOutlined className="mr-1 fs-20" />
                                            Reset
                                        </span>
                                    </Col>
                                </Row>
                                <div
                                    className="content-4 mb-0 rounded p-2 fs-12 border border-primary outline-none"
                                    id="cTextArea"
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}>
                                    {state.linkPreviewMessage}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="fs-14 fw-500 mb-2">Preview Messsage</div>
                                <div
                                    className="rounded p-2 fs-12 bg-light"
                                    style={{ border: '1px solid #999999' }}>
                                    <div className="mb-3">
                                        {`Hello ${state.firstname || 'Guest'},`}{' '}
                                    </div>
                                    <p>{`${
                                        state.viewPaidOne
                                            ? 'Thanks you for booking'
                                            : state.viewNotPaidTwo
                                            ? 'You can now pay for'
                                            : 'Check availability, pictures and details for'
                                    }  ${mainProduct?.title || 'Product'} ${
                                        !state.viewPaidOne ? 'using the link below.' : ''
                                    }`}</p>
                                    <p>{state.messageTemplate}</p>
                                    <p>
                                        <b>
                                            {state?.shortendUrl
                                                ? state?.shortendUrl
                                                : '*Link inserted*'}
                                        </b>
                                    </p>
                                    <div>
                                        Kind Regards, <br />
                                        {inhertProps?.myProfile?.first_name || ''}
                                        <br />{' '}
                                        {`${
                                            inhertProps?.myProfile?.job_title ||
                                            'Customer Service Manager'
                                        }, ${
                                            inhertProps?.profile?.business_info
                                                ?.business_trading_name || ''
                                        }`}
                                    </div>
                                </div>
                            </>
                        )}

                        {/* {state.calendarLink && (
                            <p>
                                <Row className="content-2-row align-items-center mt-3">
                                    <Col md="9" className="content-2-text-col">
                                        <b>Travel Jinny Calendar Link</b>
                                    </Col>
                                    <Col md="3" className="product-info-btn">
                                        <Button
                                            type="button"
                                            className="btn-px black-button fw-500 fs-12 mr-2"
                                            onClick={copyToClipboard}>
                                            <CopyOutlined className="mr-2 fs-20 send-btn-icon" />
                                            Copy To clipboard
                                        </Button>
                                    </Col>
                                </Row>
                                <br />
                                <span>{state.calendarLink}</span>
                            </p>
                        )} */}
                    </div>
                </Row>
            </div>

            {state.productDetailsDrawerToggle && (
                <PakcageProductsDrawer
                    state={state}
                    setState={setState}
                    onChangPP={onChangPP}
                    onChangPPRange={onChangPPRange}
                    onPanelChange={onPanelChange}
                    removeErrors={removeErrors}
                    validatePackageFields={validatePackageFields}
                    inhertProps={inhertProps}
                />
            )}
        </div>
    );
}

SendByComponent.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    setCalendarDate: PropTypes.func,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    inhertProps: PropTypes.object
};

import React from 'react';
import { Chart } from 'react-google-charts';

export default function VerticalBarChart() {
    return (
        <Chart
            width={'300px'}
            height={'300px'}
            chartType="Bar"
            loader={<div>Loading Chart</div>}
            data={[
                ['Sales', 'Entry Barrier', 'Actual Sales', 'Projected', 'Av. Customer Budget'],
                ['2014', 600, 0, 0, 0],
                ['2015', 0, 1300, 0, 0],
                ['2016', 0, 0, 1800, 0],
                ['2017', 0, 0, 0, 2500]
            ]}
            options={{
                // Material design options
                colors: ['#FF3D00', '#345BC8', '#4CDED5', '#A2792C'],
                chart: {
                    title: '',
                    subtitle: ''
                },
                chartArea: { width: '50px' },
                legend: { position: 'none' }
            }}
        />
    );
}

import React from 'react';
import VendorDashboard from '../vendor-dashboard';
import { Form, Col, Row } from 'antd';
import Minimizer from '../../../molecules/minimizer';
import Panel from '../../../atoms/panel';
import Prompter from '../../../atoms/prompter';
import { NavLink } from 'react-router-dom';
import './index.css';

export default function AccommodationDetails() {
    const data = [
        {
            day: 'Villa / Mansion Accommodation',
            title: 'Villa/ Mansion',
            footer: 'Add a villa/mansion',
            to: '/accdetails'
        },
        { day: 'Lodge Accomodation', title: 'Lodge', footer: 'Add a lodge', to: '/accdetails' }
    ];
    return (
        <VendorDashboard>
            <div className="background-sz ">
                <h3 style={{ fontSize: '20px' }}>Accommodation details</h3>
                <p>
                    Be sure to enter all key information in your accomodation. This helps travelers
                    find and book your experience.
                </p>
                <Form className="mt-5" hideRequiredMark>
                    <div className="accommodation-days">
                        {data.map(({ day, title, footer, to }, index) => (
                            <Row key={index} gutter={22} justify="center">
                                <Col span={22}>
                                    <div style={{ paddingBottom: 20 }}>
                                        <Minimizer
                                            title={day}
                                            action={index !== 0 ? <span>Remove Day</span> : null}>
                                            <NavLink to={to}>
                                                <Panel
                                                    title={title}
                                                    hasRecommendedBadge={true}
                                                    footer={<Prompter text={footer} />}
                                                />
                                            </NavLink>
                                        </Minimizer>
                                    </div>
                                </Col>
                            </Row>
                        ))}
                    </div>
                </Form>
            </div>
        </VendorDashboard>
    );
}

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailedPanel from './panel';
import { GuideIcon } from './panel/icons';
import { Input } from 'antd';

const GuidePanel = () => {
    return (
        <DetailedPanel
            panelIcon={<GuideIcon />}
            panelTitle="Guide will meet you at"
            isEditable={true}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={5}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Starting Point 1</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={7}>
                    <span className="fs-18 lh-1-7">Dubai Airport</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={5}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Starting Point 2</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={7}>
                    <span className="fs-18 lh-1-7">Burj Khalifa</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={5}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Departure TIme</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={7}>
                    <span className="fs-18 lh-1-7">01:30 PM</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={5}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Pickup Starts</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={7}>
                    <span className="fs-18 lh-1-7">1 hr 30 mins before departure</span>
                </Col>
            </Row>

            {/* Choose a location */}
            <div className="mt-3">
                <div className="fs-18 mb-2">Choose a location</div>
                <div className="d-flex">
                    <Input
                        className="type-transparent rounded-pill"
                        placeholder="Explore location"
                        prefix={
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    opacity="0.5"
                                    d="M6.88044 14.6273C8.39857 14.627 9.87295 14.1188 11.0688 13.1837L14.8287 16.9434L16.0381 15.734L12.2782 11.9744C13.2139 10.7784 13.7224 9.30379 13.7228 7.78535C13.7228 4.01285 10.6531 0.943359 6.88044 0.943359C3.10774 0.943359 0.0380859 4.01285 0.0380859 7.78535C0.0380859 11.5579 3.10774 14.6273 6.88044 14.6273ZM6.88044 2.65386C9.71061 2.65386 12.0122 4.95533 12.0122 7.78535C12.0122 10.6154 9.71061 12.9168 6.88044 12.9168C4.05027 12.9168 1.74868 10.6154 1.74868 7.78535C1.74868 4.95533 4.05027 2.65386 6.88044 2.65386Z"
                                    fill="#A3792D"
                                />
                            </svg>
                        }
                    />
                </div>
            </div>
        </DetailedPanel>
    );
};

export default GuidePanel;

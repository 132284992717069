import React, { Component } from 'react';
import './index.scss';
import Proptypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from 'react-google-maps';

const getLocation = () => {
    let location = { lat: 25.276987, lng: 55.296249 };
    if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((pos) => {
            const coords = pos.coords;
            location = { lat: coords.latitude, lng: coords.longitude };
        });
    }
    return location;
};

const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
        <GoogleMap
            defaultZoom={12}
            center={props.points[props.points.length - 1] || getLocation()}
            defaultCenter={props.points[0] || getLocation()}>
            {props.isMarkerShown &&
                props.points.map((res, i) => (
                    <Marker
                        draggable
                        onDragEnd={(drag) => props.onDragEndPin(drag, i)}
                        key={i}
                        position={{ lat: res.lat, lng: res.lng }}
                    />
                ))}
            <Polyline
                path={props.points}
                options={{
                    strokeColor: '#ff2343',
                    strokeOpacity: '1.0',
                    strokeWeight: 2,
                    icons: [
                        {
                            icon: 'hello',
                            offset: '0',
                            repeat: '10px'
                        }
                    ]
                }}
            />
        </GoogleMap>
    ))
);

class MyGoogleMap extends Component {
    constructor(props) {
        super(props);

        this.state = {
            points: []
        };
    }
    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data !== prevProps.data && data && data.length > 0) this.geocodeByLatLng();
    }

    onDragPin = (pin, index) => {
        const tmpData = [...this.state.points];
        tmpData.splice(index, 1, {
            lat: pin.latLng.lat(),
            lng: pin.latLng.lng()
        });
        this.setState({ points: tmpData });
        this.geocodeByAddress(pin.latLng.lat(), pin.latLng.lng(), index);
    };
    geocodeByAddress = (lat, lng, index) => {
        let geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: { lat: lat, lng: lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.props.onSave(results[0].formatted_address, index);
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }
        });
    };
    geocodeByLatLng = () => {
        const { data } = this.props;
        const geocoder = new window.google.maps.Geocoder();

        const tmpData = [];
        if (data.length === 1 && !data[0]) this.setState({ points: [] });
        else
            data.map((res) =>
                geocoder.geocode({ address: res }, (results, status) => {
                    if (status === 'OK') {
                        tmpData.push({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng()
                        });
                    } else if (res === '') tmpData.push('');
                    this.setState({ points: [...tmpData] });
                })
            );
    };

    render() {
        const { points } = this.state;
        return (
            <div>
                <MyMapComponent
                    isMarkerShown
                    points={points}
                    onDragEndPin={this.onDragPin}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCaCkZ1qfckJPG_GIDifZCEFZC4PPg70EA"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `229px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        );
    }
}

MyGoogleMap.propTypes = {
    height: Proptypes.number,
    initPlace: Proptypes.string,
    onSave: Proptypes.func,
    data: Proptypes.array
};

export default MyGoogleMap;

import React from 'react';
import { Button } from 'reactstrap';
import helpIcon from '../../../assets/images/helpIcon.svg';
import Proptypes from 'prop-types';

const HelpButton = ({ onClick }) => {
    return (
        <Button className="btn-help" onClick={onClick}>
            <img src={helpIcon} className="mr-2" alt="" />
            Help
        </Button>
    );
};

HelpButton.propTypes = {
    onClick: Proptypes.func
};

export default HelpButton;

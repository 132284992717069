/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import './index.scss';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Proptypes from 'prop-types';
import Delete from '../../../assets/images/delete.svg';
import { ConfirmationModal } from 'components/molecules';
export default function AddInclusionExclusionButton(props) {
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [currentDeleteType, setCurrentDeleteType] = useState('');

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        <img src={MoreInfo} alt="" />
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {(props.inclusion && props.inclusion.length > 0) ||
                (props.exclusion && props.exclusion.length > 0) ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> Add
                    </Button>
                )}
            </div>

            {props.inclusion && props.inclusion.length > 0 ? (
                <div className="inclusion-exclusion-group">
                    Inclusion{' '}
                    <img
                        src={Edit}
                        alt=""
                        title="Edit"
                        className="itinerary-img cursor-pointer"
                        onClick={props.onEdit}
                    />
                    <img
                        src={Delete}
                        alt=""
                        title="Delete"
                        onClick={() => {
                            setCurrentDeleteType('inclusion');
                            setIsConfirmationModalOpen(true);
                            //props.onDelete('inclusion');
                        }}
                        className="itinerary-img cursor-pointer"
                    />
                    {props.inclusion.map((res, i) => (
                        <div key={i} className="added">
                            {res.title}
                        </div>
                    ))}
                </div>
            ) : null}

            {props.exclusion && props.exclusion.length > 0 ? (
                <div className="inclusion-exclusion-group">
                    Exclusion{' '}
                    <img
                        src={Edit}
                        alt=""
                        title="Edit"
                        className="itinerary-img cursor-pointer"
                        onClick={props.onEdit}
                    />
                    <img
                        src={Delete}
                        alt=""
                        title="Delete"
                        onClick={() => {
                            setCurrentDeleteType('exclusion');
                            setIsConfirmationModalOpen(true);
                            //props.onDelete('exclusion');
                        }}
                        className="itinerary-img cursor-pointer"
                    />
                    {props.exclusion.map((res, i) => (
                        <div key={i} className="added">
                            {res.title}
                        </div>
                    ))}
                </div>
            ) : null}

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setIsConfirmationModalOpen(value)}
                description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                handleConfirmation={() => {
                    setIsConfirmationModalOpen(false);
                    props.onDelete(currentDeleteType);
                }}
            />
        </div>
    );
}

AddInclusionExclusionButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    isRecommended: Proptypes.bool,
    inclusion: Proptypes.array,
    exclusion: Proptypes.array,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func
};

const initialState = {
    toggle_toast: false
};

const toastReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_TOAST':
            state.toggle_toast = action.payload;
            return { ...state };
        default:
            return state;
    }
};

export default toastReducer;

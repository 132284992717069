import React, { useState } from 'react';
import { Row, Col, Button, Form } from 'antd';
// import PropTypes from 'prop-types'
import './index.scss';
import Spacer from '../../../atoms/spacer';
import DaysAccordion from '../../../molecules/days-accodion';

function MealsAndDrinksLanding() {
    let [days] = useState([
        {
            day: 'day 1',
            titleText: 'Personal Chef Service',
            subTitleText: 'Add a Personal Chef Service',
            actionLink: '/meals-and-drinks/choose-chef-service'
        },
        {
            day: 'day 2',
            titleText: 'Groceries Shopper',
            subTitleText: 'Add an Groceries Shopper',
            actionLink: ''
        }
    ]);

    // let removeDay = (index) => {
    //     let newDays = [...days];
    //     newDays.splice(index, 1);
    //   (newDays);
    // };

    return (
        <div className="meals-and-drinks-landing">
            <Form layout="vertical">
                <Row>
                    <Col span={24}>
                        <h4>Tell us about your Meals & Drinks</h4>
                        <Spacer top={3} />
                        <p>
                            Be sure to enter all key attractions in your itinerary. This helps
                            travelers find and book your experience.
                        </p>
                        <Spacer top={13} />
                        <DaysAccordion
                            days={days}
                            newTitleText="Groceries Shopper"
                            newSubTitleText="Add an Groceries Shopper"
                        />
                        <Button className="mt-50px" onClick={() => {}} type="primary" shape="round">
                            Update Calender & Continue
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

MealsAndDrinksLanding.propTypes = {};

export default MealsAndDrinksLanding;

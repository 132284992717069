import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {}
    },
    input: {
        'borderRadius': 4,
        'position': 'relative',
        'backgroundColor': 'white',
        'fontSize': 16,
        'transition': theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        'fontFamily': [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
        }
    }
}))(InputBase);

const useStyles = makeStyles((theme) => ({
    margin: {
        border: 'none'
    }
}));

export default function CustomizedSelects() {
    const classes = useStyles();
    const [age, setAge] = React.useState('');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <div>
            <FormControl className={classes.margin}>
                <NativeSelect
                    id="demo-customized-select-native"
                    value={age}
                    onChange={handleChange}
                    IconComponent={ExpandMoreIcon}
                    style={{
                        width: '100%',
                        height: '50px',
                        border: '0.5px solid #A2792C',
                        boxSizing: 'border-box',
                        borderRadius: '8px',
                        padding: '0 17px'
                    }}
                    input={<BootstrapInput />}>
                    <option className={classes.option} aria-label="None" value="">
                        Select One
                    </option>
                    <option className={classes.option} value={10}>
                        Suggest a specific time (only)
                    </option>
                    <option className={classes.option} value={20}>
                        Allow customers to choose within a time period in the day (only)
                    </option>
                    <option className={classes.option} value={30}>
                        Suggest a specific time &amp; Allow customers to choose within a time period
                        in the day
                    </option>
                </NativeSelect>
            </FormControl>
        </div>
    );
}

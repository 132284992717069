import React from 'react';
import { Row, Col, Button, Form } from 'antd';
// import PropTypes from 'prop-types'
import './index.scss';

import Spacer from '../../../atoms/spacer';
import Panel from '../../../atoms/panel';
import SyncRow from '../sync-row';
import Feedback from '../../../molecules/user-feedback';

function SyncProducts() {
    return (
        <div className="sync-products">
            <Row justify="center">
                <Col span={20}>
                    <Form layout="vertical">
                        <Spacer top={62} />
                        <h4 className="sync-products__header">
                            <span className="sync-products__header-text">Sync Availability</span>
                            <span className="sync-products__header-info">
                                Where do I find my iCal link?
                            </span>
                        </h4>
                        <Spacer top={36} />
                        <h6 className="sync-products__sub-header-text">Sync Main Product</h6>
                        <Spacer top={36} />
                        <Panel
                            title="Activity"
                            footer={
                                <div>
                                    <SyncRow title="Itinerary title 1" />
                                    <SyncRow title="Itinerary title 2" />
                                    <SyncRow title="Itinerary title 3" />
                                    <SyncRow title="Itinerary title 4" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Accomodation"
                            footer={
                                <div>
                                    <SyncRow title="Accomodation 1" />
                                    <SyncRow title="Accomodation 2" />
                                </div>
                            }
                        />
                        <Spacer top={45} />
                        <hr></hr>
                        <Spacer top={45} />
                        <h4 className="sync-products__header">
                            <span className="sync-products__sub-header-text">
                                Sync Add-on Products
                            </span>
                            {/* <span className="sync-products__header-info">Where do I find my iCal link?</span> */}
                        </h4>
                        <Panel
                            title="Activity"
                            footer={
                                <div>
                                    <SyncRow title="Itinerary title 1" />
                                    <SyncRow title="Itinerary title 2" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Accomodation"
                            footer={
                                <div>
                                    <SyncRow title="Accomodation title 1" />
                                    <SyncRow title="Accomodation title 2" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Transportation"
                            footer={
                                <div>
                                    <SyncRow title="Airport pickup title" />
                                    <SyncRow title="Car hire title" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Meals & Drinks"
                            footer={
                                <div>
                                    <SyncRow title="Resturant booking title" />
                                    <SyncRow title="Groceries Shopper title" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Guide"
                            footer={
                                <div>
                                    <SyncRow title="Guide title 1" />
                                    <SyncRow title="Guide title 2" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Personal Care"
                            footer={
                                <div>
                                    <SyncRow title="Personal care title 1" />
                                    <SyncRow title="Wellness /Spa title 2" />
                                </div>
                            }
                        />
                        <Spacer top={20} />
                        <Panel
                            title="Other"
                            footer={
                                <div>
                                    <SyncRow title="Other title 1" />
                                    <SyncRow title="Other title 2" />
                                </div>
                            }
                        />
                        <Spacer top={111} />
                        <div className="sync-products__action">
                            <Button
                                style={{ width: 135, height: 42 }}
                                type="primary"
                                className="rounded-btn mr20">
                                Continue
                            </Button>
                            <div style={{ float: 'right' }}>
                                <Feedback />
                            </div>
                        </div>
                    </Form>
                    <Spacer bottom={30} />
                </Col>
            </Row>
        </div>
    );
}

SyncProducts.propTypes = {};

export default SyncProducts;

/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import './index.scss';
import { Button, Modal, ModalBody } from 'reactstrap';
import successIcon from '../../../assets/images/success.svg';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
const { Panel } = Collapse;

export default function successModal(props) {
    const { showModal, title, handleClose, showEmail, zIndex } = props;
    return (
        <div>
            <Modal
                zIndex={zIndex ? zIndex : null}
                isOpen={showModal}
                className="link-modal modal-dialog-centered">
                <ModalBody className="py-4">
                    <div className="text-center">
                        <img src={successIcon} alt="" />
                        <h3 className="title-line-height">{title}</h3>
                    </div>
                    <div className="d-flex justify-content-center ">
                        <Button color="primary" onClick={handleClose} className="px-5">
                            OK
                        </Button>
                    </div>

                    {showEmail && (
                        <Collapse
                            className="mt-4 fs-12 primary-collapse-accordion"
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIconPosition="right">
                            <Panel
                                className="primary-collapse-panel no-padded-panel"
                                header="Not redirected to your email?"
                                key="1">
                                <div
                                    className="scrollbar-fixed p-3"
                                    style={{ maxHeight: 200, overflowY: 'auto' }}>
                                    <h6>Solution 1:</h6>
                                    <ol className="pl-3 fs-12">
                                        <li>Reset your default app's setting on system</li>
                                        <li>Choose email as default app to open emails</li>
                                    </ol>
                                    <h6>Solution 2:</h6>
                                    <ol className="pl-3 fs-12">
                                        <li>Open Chrome and navigate to "Settings."</li>
                                        <li>
                                            Click "Content settings" under "Privacy and security."
                                        </li>
                                        <li>Select "Handlers" and switch on the Ask protocol.</li>
                                        <li>
                                            Open Gmail in Chrome and click the Protocol Handler
                                            icon.
                                        </li>
                                        <li>Allow Gmail to open all email links.</li>
                                    </ol>
                                </div>
                            </Panel>
                        </Collapse>
                    )}
                </ModalBody>
            </Modal>
        </div>
    );
}

successModal.propTypes = {
    title: PropTypes.string.isRequired,
    showModal: PropTypes.bool,
    handleClose: PropTypes.func,
    showEmail: PropTypes.bool,
    zIndex: PropTypes.number
};

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Edit from '../../../../../../assets/images/edit.svg';
import Delete from '../../../../../../assets/images/del.svg';
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function ListRow(props) {
    const { title, onEdit, onDelete } = props;
    return (
        <div className="list-row">
            <span className="list-row__title">{title}</span>
            <div className="list-row__actions">
                <span onClick={onEdit}>
                    <img src={Edit} alt="" />
                </span>
                <span onClick={onDelete}>
                    <img src={Delete} alt="" />
                </span>
            </div>
        </div>
    );
}

ListRow.propTypes = {
    title: PropTypes.string.isRequired,
    onEdit: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
};

export default ListRow;

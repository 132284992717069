import React from 'react';
import PropTypes from 'prop-types';
import {
    LeftoverCount,
    EventContentMoreProducts,
    RestingTime,
    EventInfoTravellersPopover,
    EventImageGallery
} from 'components/molecules';
import { EventCardHeaderIcon, ExpandIcon, EventMainProductIcon } from 'components/atoms';

const EventContent = (props) => {
    const { eventInfo, responsive } = props;
    return (
        <>
            {eventInfo?.event?.extendedProps?.event_type == 'main_event' && (
                <div
                    className={`with-bullets h-100 ${
                        eventInfo?.event?.extendedProps?.hasMoreProducts ? 'has-more-products' : ''
                    }`}>
                    {/* <b>{eventInfo.timeText}</b>
          <i>{eventInfo.event.title}</i>
          <div><img src={eventInfo.event.extendedProps.imageurl}></img></div> */}
                    {eventInfo?.event?.extendedProps?.hasMoreProducts && (
                        <EventContentMoreProducts />
                    )}
                    <div className="event-card">
                        <div className="event-card-header">
                            <div className="event-card-title">
                                {/* Name Icon */}
                                <EventCardHeaderIcon />
                                <span className="name">{eventInfo.event.title}</span>
                                {eventInfo?.event?.extendedProps?.bookingStatus ? (
                                    <span className="ml-2 text-booked">
                                        ({eventInfo?.event?.extendedProps?.bookingStatus})
                                    </span>
                                ) : null}
                                {eventInfo?.event?.extendedProps?.is_main_product ? (
                                    <EventMainProductIcon />
                                ) : null}
                            </div>
                            <div className="event-card-header-action">
                                {(eventInfo.event.end - eventInfo.event.start) / (3600 * 1000) <=
                                    1 &&
                                eventInfo?.event?.extendedProps?.travellers &&
                                eventInfo?.event?.extendedProps?.travellers.length ? (
                                    <EventInfoTravellersPopover />
                                ) : null}
                                <span role="button" tabIndex="0" title="View Event">
                                    <ExpandIcon />
                                </span>
                            </div>
                        </div>
                        {/* Event image gallery */}
                        <EventImageGallery eventInfo={eventInfo} responsive={responsive} />

                        {(eventInfo.event.end - eventInfo.event.start) / (3600 * 1000) > 1 &&
                        eventInfo?.event?.extendedProps?.travellers &&
                        eventInfo?.event?.extendedProps?.travellers.length ? (
                            <LeftoverCount />
                        ) : null}
                    </div>
                </div>
            )}
            {eventInfo?.event?.extendedProps?.event_type == 'resting_time' && (
                <RestingTime eventInfo={eventInfo} />
            )}
        </>
    );
};
EventContent.propTypes = {
    eventInfo: PropTypes.object,
    responsive: PropTypes.object
};
export default EventContent;

/* eslint-disable react/no-unescaped-entities */
import { Form, Input, Checkbox, Radio, Row, Col } from 'antd';
import InclusionAndExclusion from '../inclusion-and-exclusion.js';
import MediaField from '../../../../molecules/media-library/media-field';
import MdiInfo from '../../../../../assets/images/mdi_info.svg';
import ActivitySession from '../activity-session/index';
import FoodAndDrink from '../food-and-drink/index';
import LanguageOffered from '../language-offered';
import AtcTimePicker from '../../../../atoms/time-picker';
import React, { Component } from 'react';
import Prompter from '../../../../atoms/prompter';
import Adventure from '../adventure';
import Spacer from '../../../../atoms/spacer';
import Panel from '../../../../atoms/panel';
import Map from '../../../../molecules/map';
import './index.scss';
import Display from '../../../../atoms/display/index.js';
import PhysicalRestrictons from '../physical-restricitons/index.js';
import TopSights from '../top-sights/index.js';

const { TextArea } = Input;
const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};
const locationOptions = [
    {
        label: 'At the accomodation provided by you',
        value: 'Apple'
    },
    {
        label: 'Allow travellers to enter their their own pick up location',
        value: 'Pear'
    },
    {
        label: 'at a Starting Point',
        value: 'Orange'
    }
];

export default class Itenary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {},
            requiredMark: 'optional',
            adventureIsVisible: false,
            foodAndDrinksIsVisible: false,
            physicalRestricitionIsVisible: false,
            activitySessionIsVisible: false,
            languageOfferedIsVisible: false,
            topSightIsVisible: false,
            inclusionAndexclusionIsVisible: false
        };
    }

    showModal(key) {
        this.setState({
            [key]: true
        });
    }

    closeModal(key) {
        this.setState({
            [key]: false
        });
    }

    handleOk() {
        this.setState({ visible: false });
    }

    handleAdventureOk() {
        this.closeModal('adventureIsVisible');
    }

    handleActivitySessionOk() {
        this.closeModal('activitySessionIsVisible');
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    handleLanguageOfferedOk() {
        // save data to state
        this.closeModal('languageOfferedIsVisible');
    }

    handleTopSightOk() {
        // save data to state
        this.closeModal('topSightIsVisible');
    }

    handlePhysicalRestrictonsOk() {
        // save data to state
        this.closeModal('physicalRestricitionIsVisible');
    }

    onRequiredTypeChange({ requiredMark }) {
        this.setRequiredMarkType(requiredMark);
    }

    setRequiredMarkType(requiredMark) {
        this.setState({
            requiredMark
        });
    }

    render() {
        const {
            // form,
            requiredMark,
            adventureIsVisible,
            foodAndDrinksIsVisible,
            languageOfferedIsVisible,
            topSightIsVisible,
            activitySessionIsVisible,
            physicalRestricitionIsVisible,
            inclusionAndexclusionIsVisible
        } = this.state;

        return (
            <div className="itenary-form">
                <Form
                    // form={form}
                    layout="vertical"
                    initialValues={{
                        requiredMark
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}>
                    <Row>
                        <Col span="18">
                            <h4 className="itenary-form__title">Add an Itinerary</h4>
                            <Prompter
                                textColor="#A2792C"
                                text="I will import Itinerary information from my other activity "
                            />
                            <Spacer top={57} />
                            <Form.Item label="Add Itinerary title" required>
                                <Input
                                    style={{
                                        border: '0.3px solid #A2792C',
                                        height: 40,
                                        borderRadius: 8
                                    }}
                                    placeholder="Kruger National Park Safari, South Africa"
                                />
                            </Form.Item>

                            <Form.Item
                                className="mt-57"
                                label="Describe what travelers will see and do here if they book your experience"
                                required>
                                <TextArea
                                    rows={5}
                                    placeholder="
                    Enter a description about this Itinerary only.  
                    For example, 'Our accredited guide will meet you at the ticket 
                    booth and will accompany you up the tower explaining what youll s
                    ee at each level Description is required'
                  "
                                />
                            </Form.Item>

                            <Form.Item label="Select main destination" required>
                                <Input
                                    style={{
                                        border: '0.3px solid #a2792c',
                                        height: 40,
                                        borderRadius: 8
                                    }}
                                    placeholder="Kruger National Park Safari, South Africa"
                                    suffix={
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z"
                                                fill="#927E2F"
                                            />
                                            <path
                                                d="M11.4118 8.58609C11.7908 8.96609 11.9998 9.46809 11.9998 10.0001H13.9998C14.0007 9.47451 13.8974 8.95398 13.6959 8.46857C13.4944 7.98316 13.1987 7.54251 12.8258 7.17209C11.3118 5.66009 8.68683 5.66009 7.17383 7.17209L8.58583 8.58809C9.34583 7.83009 10.6558 7.83209 11.4118 8.58609Z"
                                                fill="#927E2F"
                                            />
                                        </svg>
                                    }
                                />
                            </Form.Item>
                            {/* <div style={{ paddingTop: "50px" }}> */}
                            <Map
                                google={{}}
                                center={{ lat: 6.5244, lng: 3.3792 }}
                                height="300px"
                                zoom={15}
                            />
                            {/* </div> */}
                        </Col>
                    </Row>

                    <Spacer top={36} />
                    <hr></hr>
                    <Spacer top={66} />
                    <Row>
                        <Col span="18">
                            <h6>What's the duration of your Activity from start to finish?</h6>
                            <AtcTimePicker />

                            <AtcTimePicker />
                            <Spacer top={36} />
                            <h6>Add Photos & Videos</h6>
                            <p>
                                Use photos that highlight what’s unique about your experience and
                                show travelers having fun. Adding 6-9 photos and at least 1 video
                                can increase your bookings.
                            </p>
                            <Row gutter={12}>
                                <Col>
                                    <MediaField onSelectMedia={() => {}} type="picture" />
                                </Col>
                                <Col>
                                    <MediaField onSelectMedia={() => {}} type="video" />
                                </Col>
                            </Row>
                            <Spacer top={28} />
                            <Form.Item
                                label="Is admission to this place included in the price of your activity?"
                                required>
                                <Radio.Group>
                                    <Radio style={radioStyle} value={1}>
                                        Yes
                                    </Radio>
                                    <Radio style={radioStyle} value={2}>
                                        No
                                    </Radio>
                                    <Radio style={radioStyle} value={3}>
                                        N/A
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className="itenary-form__panels">
                        <hr></hr>
                        <Spacer top={36} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title={
                                        <div>
                                            <span>Meals & Drinks</span>
                                            <img
                                                style={{ marginTop: '-16px', marginLeft: '3px' }}
                                                src={MdiInfo}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    footer={
                                        <Prompter
                                            onClick={() => this.showModal('foodAndDrinksIsVisible')}
                                            text="Add a meal"
                                        />
                                    }
                                />
                            </Col>
                        </Row>

                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title={
                                        <div>
                                            <span>Language Offered</span>
                                            <img
                                                style={{ marginTop: '-16px', marginLeft: '3px' }}
                                                src={MdiInfo}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    footer={
                                        <Prompter
                                            onClick={() =>
                                                this.showModal('languageOfferedIsVisible')
                                            }
                                            text="Add language offered"
                                        />
                                    }
                                />
                            </Col>
                        </Row>

                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title={
                                        <div>
                                            <span>Inclusions & Exclusions</span>
                                            <img
                                                style={{ marginTop: '-16px', marginLeft: '3px' }}
                                                src={MdiInfo}
                                                alt=""
                                            />
                                        </div>
                                    }
                                    footer={
                                        <Prompter
                                            onClick={() =>
                                                this.showModal('inclusionAndexclusionIsVisible')
                                            }
                                            text="Add inclusions and exclusions"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Spacer top={36} />
                        <hr></hr>
                    </div>

                    <Spacer top={40} />

                    <Form.Item
                        label="Now tell us where you meet travelers for this Itinerary?"
                        required>
                        <Checkbox.Group options={locationOptions} />
                    </Form.Item>

                    {/* <div style={{ paddingTop: "50px" }}>
              <Map
                  google={{}}
                  center={{ lat: 6.5244, lng: 3.3792 }}
                  height='300px'
                  zoom={15}
              />
          </div> */}

                    <Prompter text="Add another starting point" />

                    <h6 className="mt-10px">What is the departure time?</h6>
                    <AtcTimePicker />

                    <Spacer top={32} />
                    <h6>How long before departure does your pickup start?</h6>
                    <AtcTimePicker showAmPm={false} />

                    <Spacer top={36} />
                    <hr></hr>
                    <Spacer top={36} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title={
                                    <div>
                                        <span>Itinerary Calender Season</span>
                                        <img
                                            style={{ marginTop: '-16px', marginLeft: '3px' }}
                                            src={MdiInfo}
                                            alt=""
                                        />
                                    </div>
                                }
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add Itinerary calendar season"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title={
                                    <div>
                                        <span>Type of Adventure on this Itinerary</span>
                                        <img
                                            style={{ marginTop: '-16px', marginLeft: '3px' }}
                                            src={MdiInfo}
                                            alt=""
                                        />
                                    </div>
                                }
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('adventureIsVisible')}
                                        text="Add type of adventure"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title={
                                    <div>
                                        <span>Top Sights to see on this Itinerary</span>
                                        <img
                                            style={{ marginTop: '-16px', marginLeft: '3px' }}
                                            src={MdiInfo}
                                            alt=""
                                        />
                                    </div>
                                }
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('topSightIsVisible')}
                                        text="Add top sights"
                                    />
                                }
                            />
                        </Col>
                    </Row>

                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title={
                                    <div>
                                        <span>Information travellers need from you</span>
                                        <img
                                            style={{ marginTop: '-16px', marginLeft: '3px' }}
                                            src={MdiInfo}
                                            alt=""
                                        />
                                    </div>
                                }
                                footer={
                                    <Prompter
                                        onClick={() =>
                                            this.showModal('physicalRestricitionIsVisible')
                                        }
                                        text="Add any physical restrictions for this Itinerary"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                </Form>
                <Display show={foodAndDrinksIsVisible}>
                    <FoodAndDrink
                        handleCancel={() => this.closeModal('foodAndDrinksIsVisible')}
                        isVisible={foodAndDrinksIsVisible}
                    />
                </Display>
                <PhysicalRestrictons
                    handleCancel={() => this.closeModal('physicalRestricitionIsVisible')}
                    isVisible={physicalRestricitionIsVisible}
                    handleOk={this.handlePhysicalRestrictonsOk}
                />
                <LanguageOffered
                    handleCancel={() => this.closeModal('languageOfferedIsVisible')}
                    isVisible={languageOfferedIsVisible}
                    handleOk={this.handleLanguageOfferedOk}
                />
                <ActivitySession
                    width={1288}
                    handleCancel={() => this.closeModal('activitySessionIsVisible')}
                    handleOk={this.handleActivitySessionOk}
                    isVisible={activitySessionIsVisible}
                />
                <InclusionAndExclusion
                    width={590}
                    handleCancel={() => this.closeModal('inclusionAndexclusionIsVisible')}
                    isVisible={inclusionAndexclusionIsVisible}
                />
                <Adventure
                    width={590}
                    isVisible={adventureIsVisible}
                    handleCancel={() => this.closeModal('adventureIsVisible')}
                    handleOk={this.handleAdventureOk}
                />
                <TopSights
                    width={590}
                    isVisible={topSightIsVisible}
                    handleCancel={() => this.closeModal('topSightIsVisible')}
                    handleOk={this.handleTopSightOk}
                />
            </div>
        );
    }
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './index.scss';
import bookings from '../../../../assets/images/bookings.svg';
import facebook from '../../../../assets/images/facebook.svg';
import google from '../../../../assets/images/google.svg';
import tripadvisor from '../../../../assets/images/tripadvisor.svg';
import atc from '../../../../assets/images/palms.svg';
import star_icon from '../../../../assets/images/fullstar.svg';
import cloud from '../../../../assets/images/cloud.svg';
import { Button, Col, DatePicker, Radio, Row, Select } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import BannerComponent from '../../../molecules/banner';
import hero from '../../../../assets/images/Hero_Image.svg';
import Overview from '../overview';
const { Option } = Select;

export default function ProductReviews() {
    const [activeOffer, setactiveOffer] = useState(0);
    const [overview, setOverview] = useState(true);
    const [activity, setActivities] = useState(false);
    const [review, setReviews] = useState(false);

    const handleOverview = () => {
        setOverview(true);
        setActivities(false);
        setReviews(false);
    };

    const handleActivity = () => {
        setOverview(false);
        setActivities(true);
        setReviews(false);
    };

    const handleReview = () => {
        setOverview(false);
        setActivities(false);
        setReviews(true);
    };

    const types = [
        {
            text: 'atc',
            img: atc
        },
        {
            text: 'facebook',
            img: facebook
        },
        {
            text: 'google',
            img: google
        },
        {
            text: 'tripadvisor',
            img: tripadvisor
        },
        {
            text: 'bookings',
            img: bookings
        }
    ];

    const benefits = [
        'Product deals up to 75% off.',
        'Flexi-pay, with no interest fees (no credit check).',
        'Refund guarantee (up to one week before travel).',
        'Personalised travel experience.',
        'Trip planner & organiser.',
        'Travel help & support.',
        'Member wallet & cashback.',
        'No refund cancellation fee.'
    ];

    return (
        <div className="product-reviews">
            <BannerComponent
                count={96}
                image={hero}
                heading="7-Day Safari (Including Serengeti) & Zanzibar Extension"
            />

            <div className="page-content">
                <div className="left">
                    <div className="d-flex justify-content tabs">
                        <div
                            onClick={handleOverview}
                            className={
                                overview
                                    ? 'tile-active cursor-pointer col-md-4'
                                    : 'tile cursor-pointer col-md-4'
                            }>
                            <p className="m-0">Overview</p>
                        </div>
                        <div
                            onClick={handleActivity}
                            className={
                                activity
                                    ? 'tile-active cursor-pointer col-md-4'
                                    : 'tile cursor-pointer col-md-4'
                            }>
                            <p className="m-0">Activities</p>
                        </div>
                        <div
                            onClick={handleReview}
                            className={
                                review
                                    ? 'tile-active cursor-pointer col-md-4'
                                    : 'tile cursor-pointer col-md-4'
                            }>
                            <p className="m-0">Reviews</p>
                        </div>
                    </div>
                    <div className="content-wrap">
                        {review && (
                            <>
                                <div className="buttons">
                                    <Button className="white-btn">View All</Button>
                                    {types.map((type, i) => (
                                        <div key={i} className="shadw">
                                            <img src={type.img} alt="" />
                                        </div>
                                    ))}
                                </div>
                                <div className="mobile-btns">
                                    <Select style={{ width: '100%' }}>
                                        <Option style={{ textAlign: 'center' }} value="view all">
                                            View all
                                        </Option>
                                        {types.map((type, i) => (
                                            <Option
                                                key={i}
                                                style={{ textAlign: 'center' }}
                                                value={type.text}>
                                                <div className="shadw">
                                                    <img src={type.img} alt="" />
                                                </div>
                                            </Option>
                                        ))}
                                    </Select>
                                </div>
                                <div className="inner-wrap">
                                    {[1, 2, 3, 2, 1, 2, 3, 4, 5].map((rev) => (
                                        <div className="review" key={rev}>
                                            <div className="info">
                                                <div className="who">
                                                    <Avatar
                                                        style={{ width: 50, height: 50 }}
                                                        src={`https://randomuser.me/api/portraits/men/${12}.jpg`}
                                                    />
                                                    <div className="details">
                                                        <div className="name">
                                                            Buki{' '}
                                                            {[1, 2, 3, 4, 5].map((st, i) => (
                                                                <img
                                                                    src={star_icon}
                                                                    alt=""
                                                                    key={i}
                                                                />
                                                            ))}
                                                        </div>
                                                        <div className="date">Sep 02, 2020</div>
                                                    </div>
                                                </div>
                                                <div className="where">
                                                    <img src={google} alt="" />
                                                </div>
                                            </div>
                                            <div className="text">
                                                Our favorite Sunday place! All you can drink Caesars
                                                (bloody marys) on Sundays which you make your own.
                                                Definitely our fave, along with the mimosa tower
                                                which is always delicious.
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        {activity && (
                            <div>
                                <p>activity</p>
                            </div>
                        )}
                        {overview && <Overview />}
                    </div>
                </div>
                <div className="right">
                    <div className="progress-wrap">
                        <div className="count"></div>
                    </div>
                    <p className="progress-caveat">
                        <span>25% Claimed</span>
                        <strong>Deal Expires In 7h:28m:43s</strong>
                    </p>

                    <div className="shadowed">
                        <div className="offers">
                            <div
                                className={`offer ${activeOffer === 0 && 'active'}`}
                                onClick={() => setactiveOffer(0)}>
                                <p className="offer-type">Premium Offer</p>
                                <p className="price">£300 or £25 Monthly</p>
                                <p className="bonus">Price: £ 900 (67% off)</p>
                            </div>
                            <div
                                className={`offer ${activeOffer === 1 && 'active'}`}
                                onClick={() => setactiveOffer(1)}>
                                <p className="offer-type">Premium Offer</p>
                                <p className="price">£300 or £25 Monthly</p>
                                <p className="bonus">Price: £ 900 (67% off)</p>
                            </div>
                        </div>
                        <div className="shabow-content">
                            <div className="benefits">
                                <p className="benefit-heading">MEMBERSHIP BENEFITS & DETAILS</p>
                                <div className="benefits-wrap">
                                    {benefits.map((itm, i) => (
                                        <div className="benefit" key={i}>
                                            <CheckCircleFilled
                                                style={{ color: '#CAAD2E', marginRight: 10 }}
                                            />
                                            {itm}
                                            <InfoCircleFilled
                                                style={{ color: '#CAAD2E', marginLeft: 10 }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div className="benefits" style={{ marginTop: 20 }}>
                                <p className="benefit-heading weather">
                                    TRAVEL PERIOD
                                    <div className="cloud">
                                        <p className="date">
                                            <b>Thu</b> 2nd
                                        </p>
                                        <div className="temp-wrap">
                                            <img src={cloud} alt="" />
                                            <div className="temp">
                                                <span className="degs">25 &#730;</span>
                                                <span className="deg">15 &#730;</span>
                                            </div>
                                        </div>
                                        <p className="forcast">Weather Forecast</p>
                                    </div>
                                </p>
                                <div className="benefits-wrap">
                                    <Row gutter={30} style={{ width: '100%', margin: 0 }}>
                                        <Col md={12}>
                                            <p style={{ marginBottom: 5 }}>
                                                START DATE
                                                <svg
                                                    width="12"
                                                    height="6"
                                                    viewBox="0 0 12 6"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        float: 'right',
                                                        position: 'relative',
                                                        top: 10
                                                    }}>
                                                    <path
                                                        d="M0.760742 0L6.2157 5.45117L11.6707 0H0.760742Z"
                                                        fill="#4F4F4F"
                                                    />
                                                </svg>
                                            </p>
                                            <DatePicker
                                                bordered={false}
                                                style={{ width: '100%', paddingLeft: 0 }}
                                            />
                                        </Col>
                                        <Col md={12}>
                                            <p style={{ marginBottom: 5 }}>
                                                END DATE
                                                <svg
                                                    width="12"
                                                    height="6"
                                                    viewBox="0 0 12 6"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    style={{
                                                        float: 'right',
                                                        position: 'relative',
                                                        top: 10
                                                    }}>
                                                    <path
                                                        d="M0.760742 0L6.2157 5.45117L11.6707 0H0.760742Z"
                                                        fill="#4F4F4F"
                                                    />
                                                </svg>
                                            </p>
                                            <DatePicker
                                                bordered={false}
                                                style={{ width: '100%', paddingLeft: 0 }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <div className="benefits" style={{ marginTop: 30 }}>
                                <p className="benefit-heading">PAYMENT SUMMARY</p>
                                <p className="benefit-heading">
                                    <span style={{ display: 'block' }}>
                                        GUEST
                                        <InfoCircleFilled
                                            style={{ color: '#CAAD2E', marginLeft: 10 }}
                                        />
                                    </span>
                                    <span style={{ display: 'block' }}>
                                        Adults -2 ($50) , Children-2 ($25)
                                    </span>
                                </p>
                                <p
                                    className="benefit-heading"
                                    style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div style={{ width: '60%' }}>
                                        <span style={{ display: 'block', marginBottom: 10 }}>
                                            TOTAL AMOUNT
                                        </span>
                                        <span style={{ display: 'block' }}>
                                            <span style={{ fontSize: 20, marginRight: 20 }}>
                                                $ 900
                                            </span>
                                            <span>Price: $ 2,700 (67% off)</span>
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            borderLeft: '0.2px solid rgba(151, 151, 151, 0.228431)',
                                            paddingLeft: 25
                                        }}>
                                        <span style={{ display: 'block', marginBottom: 10 }}>
                                            TOTAL SAVINGS
                                        </span>
                                        <span style={{ display: 'block' }}>
                                            <span style={{ fontSize: 20 }}>$ 1800</span>
                                        </span>
                                    </div>
                                </p>
                            </div>

                            <div className="benefits" style={{ marginTop: 30 }}>
                                <p className="benefit-heading">PAYMENT TYPE</p>
                                <div style={{ width: '100%' }}>
                                    <Radio.Group>
                                        <Radio
                                            style={{ width: '100%', margin: '10px 20px' }}
                                            value={1}>
                                            <span
                                                style={{
                                                    color: '#4F4F4F',
                                                    fontSize: 16,
                                                    fontWeight: '600'
                                                }}>
                                                FLEXI-PAY
                                            </span>
                                            <span
                                                style={{
                                                    fontSize: 12,
                                                    color: '#BC992E',
                                                    marginLeft: 15,
                                                    fontWeight: '600'
                                                }}>
                                                12 X Flexi-payments of $25.00
                                            </span>
                                            <span
                                                style={{
                                                    display: 'block',
                                                    marginLeft: 22,
                                                    fontSize: 12
                                                }}>
                                                Secure your trip and Pay for your trip overtime.
                                            </span>
                                            <p
                                                style={{
                                                    background: 'rgba(235, 231, 242, 0.58)',
                                                    border: '1px solid rgba(151, 151, 151, 0.2284)',
                                                    padding: '12px 20px',
                                                    marginTop: 20,
                                                    marginBottom: 0,
                                                    marginLeft: '-20px',
                                                    marginRight: 20
                                                }}>
                                                Today - pay only $75.00
                                            </p>
                                            <p
                                                style={{
                                                    background: 'rgba(235, 231, 242, 0.58)',
                                                    border: '1px solid rgba(151, 151, 151, 0.2284)',
                                                    padding: '12px 20px',
                                                    marginBottom: 0,
                                                    marginLeft: '-20px',
                                                    marginRight: 20
                                                }}>
                                                <span style={{ display: 'block' }}>
                                                    REPAYMENT FREQUENCY
                                                </span>
                                                <span style={{ fontWeight: '600' }}>
                                                    Monthly (£75.00) x 12
                                                </span>
                                            </p>
                                        </Radio>
                                        <Radio
                                            style={{ width: '100%', margin: '10px 20px' }}
                                            value={2}>
                                            <span
                                                style={{
                                                    color: '#4F4F4F',
                                                    fontSize: 16,
                                                    fontWeight: '600'
                                                }}>
                                                ONE -OFF PAYMENT
                                            </span>
                                            <span
                                                style={{
                                                    display: 'block',
                                                    marginLeft: 22,
                                                    fontSize: 12
                                                }}>
                                                Secure your trip and Pay for your trip overtime.
                                            </span>
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import JinniIcon from '../../../../assets/images/jinni-primary-icon.svg';
import { Input, Tabs } from 'antd';

const ShareVendorRatingModal = (props) => {
    const { TabPane } = Tabs;
    const { isModalOpen, setModalOpenState } = props;
    const [isAlreadyPlaqueRequested, setIsAlreadyPlaqueRequested] = useState(false);
    const shareRatingVector = (
        <svg
            width="289"
            height="65"
            viewBox="0 0 289 65"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ maxWidth: '100%' }}>
            <path
                d="M49.1194 24.7163L33.9025 22.4209L27.1002 8.10729C26.9144 7.71539 26.6087 7.39814 26.2312 7.2053C25.2842 6.7201 24.1335 7.12444 23.6601 8.10729L16.8577 22.4209L1.64086 24.7163C1.22133 24.7785 0.837763 24.9838 0.544093 25.2948C0.189064 25.6736 -0.00657405 26.1831 0.000168686 26.7115C0.00691142 27.2399 0.215483 27.7439 0.580053 28.1128L11.5897 39.2539L8.98858 54.9858C8.92759 55.3518 8.9666 55.7282 9.10121 56.0723C9.23581 56.4164 9.46062 56.7145 9.75014 56.9327C10.0397 57.151 10.3823 57.2807 10.7392 57.3071C11.0961 57.3335 11.453 57.2556 11.7695 57.0822L25.3801 49.6548L38.9908 57.0822C39.3624 57.2875 39.7939 57.3559 40.2074 57.2812C41.2502 57.0946 41.9515 56.0682 41.7717 54.9858L39.1706 39.2539L50.1802 28.1128C50.4799 27.808 50.6776 27.4099 50.7376 26.9744C50.8994 25.8858 50.1682 24.8781 49.1194 24.7163Z"
                fill="#E2C022"
            />
            <path
                d="M133.16 22.7806L113.594 19.8291L104.847 1.42382C104.608 0.919892 104.215 0.511953 103.729 0.26399C102.512 -0.359917 101.032 0.160006 100.423 1.42382L91.6766 19.8291L72.1099 22.7806C71.5705 22.8606 71.0772 23.1246 70.6996 23.5245C70.2431 24.0115 69.9915 24.6667 70.0002 25.3462C70.0089 26.0256 70.2771 26.6737 70.7459 27.148L84.9026 41.4738L81.558 61.7028C81.4796 62.1734 81.5298 62.6574 81.7028 63.0998C81.8759 63.5423 82.165 63.9256 82.5373 64.2062C82.9095 64.4869 83.3501 64.6536 83.8091 64.6876C84.268 64.7216 84.7269 64.6214 85.1338 64.3984L102.635 54.8478L120.137 64.3984C120.614 64.6624 121.169 64.7504 121.701 64.6544C123.042 64.4144 123.943 63.0946 123.712 61.7028L120.368 41.4738L134.524 27.148C134.91 26.756 135.164 26.2441 135.241 25.6842C135.449 24.2844 134.509 22.9886 133.16 22.7806Z"
                fill="#E2C022"
            />
            <path
                d="M217.157 22.7793L197.591 19.8279L188.845 1.42374C188.606 0.91984 188.213 0.511924 187.728 0.263975C186.51 -0.359896 185.03 0.159997 184.422 1.42374L175.675 19.8279L156.11 22.7793C155.57 22.8593 155.077 23.1233 154.7 23.5232C154.243 24.0102 153.992 24.6653 154 25.3447C154.009 26.0241 154.277 26.6722 154.746 27.1464L168.902 41.4715L165.557 61.6993C165.479 62.1698 165.529 62.6538 165.702 63.0962C165.875 63.5387 166.164 63.922 166.537 64.2026C166.909 64.4832 167.349 64.6499 167.808 64.6839C168.267 64.7179 168.726 64.6177 169.133 64.3947L186.633 54.8447L204.134 64.3947C204.611 64.6587 205.166 64.7467 205.698 64.6507C207.039 64.4107 207.94 63.091 207.709 61.6993L204.365 41.4715L218.521 27.1464C218.906 26.7545 219.16 26.2426 219.237 25.6827C219.445 24.283 218.505 22.9873 217.157 22.7793Z"
                fill="#E2C022"
            />
            <path
                d="M287.122 24.7173L271.904 22.4217L265.102 8.10735C264.916 7.71543 264.61 7.39816 264.233 7.20531C263.286 6.72008 262.135 7.12444 261.661 8.10735L254.859 22.4217L239.641 24.7173C239.221 24.7795 238.838 24.9848 238.544 25.2958C238.189 25.6746 237.993 26.1842 238 26.7126C238.007 27.241 238.215 27.745 238.58 28.1139L249.59 39.2556L246.989 54.9883C246.928 55.3543 246.967 55.7307 247.102 56.0748C247.236 56.419 247.461 56.7171 247.751 56.9353C248.04 57.1536 248.383 57.2833 248.74 57.3097C249.097 57.3361 249.454 57.2582 249.77 57.0848L263.381 49.657L276.993 57.0848C277.364 57.2901 277.796 57.3585 278.21 57.2839C279.252 57.0972 279.954 56.0708 279.774 54.9883L277.173 39.2556L288.183 28.1139C288.482 27.8091 288.68 27.4109 288.74 26.9755C288.902 25.8868 288.171 24.879 287.122 24.7173Z"
                fill="#E2C022"
            />
        </svg>
    );

    const tabHeading = <div className="fs-28 fw-600 mt-md-3">Share</div>;
    const tabEmbed = (
        <div className="d-flex flex-column align-items-center text-center">
            <div className="shape shape-circle shape-white">
                <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M23.7546 9.38264L18.2046 3.50259C17.8635 3.14081 17.2941 3.12431 16.9323 3.4657C16.5711 3.80682 16.5543 4.37649 16.8957 4.73799L21.8629 10L16.8957 15.2627C16.5543 15.6242 16.5711 16.1936 16.9323 16.535C17.1063 16.6991 17.3286 16.7804 17.55 16.7804C17.7891 16.7804 18.0279 16.6856 18.2047 16.4986L23.7547 10.6183C24.0819 10.2713 24.0819 9.72942 23.7546 9.38264Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M7.10467 15.2624L2.13786 10L7.10467 4.73771C7.44579 4.37621 7.42929 3.80649 7.06778 3.46542C6.70656 3.12431 6.13656 3.14081 5.79549 3.50231L0.245462 9.38236C-0.0818208 9.72914 -0.0818208 10.2713 0.245462 10.6181L5.79578 16.4984C5.97278 16.6859 6.21128 16.7804 6.45039 16.7804C6.67178 16.7804 6.89411 16.6988 7.06778 16.5347C7.42961 16.1936 7.44579 15.6239 7.10467 15.2624Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M13.456 0.470191C12.9648 0.3958 12.5052 0.732974 12.4302 1.22409L9.79022 18.5042C9.71522 18.9956 10.0527 19.4549 10.5441 19.5299C10.5903 19.5368 10.6359 19.5401 10.6812 19.5401C11.1183 19.5401 11.5017 19.2213 11.5698 18.776L14.2099 1.49592C14.2849 1.00448 13.9474 0.545191 13.456 0.470191Z"
                        fill="#A2792C"
                    />
                </svg>
            </div>
            <div>
                <span className="fs-12 d-block mt-1 ws-normal">Embed</span>
            </div>
        </div>
    );
    const tabFacebook = (
        <div className="d-flex flex-column align-items-center text-center">
            <div className="shape shape-circle shape-facebook">
                <svg
                    width="13"
                    height="24"
                    viewBox="0 0 13 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M9.99701 3.98499H12.188V0.168992C11.81 0.116992 10.51 -7.62939e-06 8.99601 -7.62939e-06C5.83701 -7.62939e-06 3.67301 1.98699 3.67301 5.63899V8.99999H0.187012V13.266H3.67301V24H7.94701V13.267H11.292L11.823 9.00099H7.94601V6.06199C7.94701 4.82899 8.27901 3.98499 9.99701 3.98499Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div>
                <span className="fs-12 d-block mt-1 ws-normal">Facebook</span>
            </div>
        </div>
    );
    const tabTwitter = (
        <div className="d-flex flex-column align-items-center text-center">
            <div className="shape shape-circle shape-twitter">
                <svg
                    width="24"
                    height="20"
                    viewBox="0 0 24 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M24 2.5585C23.1075 2.95 22.1565 3.2095 21.165 3.3355C22.185 2.7265 22.9635 1.7695 23.3295 0.616C22.3785 1.183 21.3285 1.5835 20.2095 1.807C19.3065 0.8455 18.0195 0.25 16.6155 0.25C13.8915 0.25 11.6985 2.461 11.6985 5.1715C11.6985 5.5615 11.7315 5.9365 11.8125 6.2935C7.722 6.094 4.1025 4.1335 1.671 1.147C1.2465 1.8835 0.9975 2.7265 0.9975 3.634C0.9975 5.338 1.875 6.8485 3.183 7.723C2.3925 7.708 1.617 7.4785 0.96 7.117C0.96 7.132 0.96 7.1515 0.96 7.171C0.96 9.562 2.6655 11.548 4.902 12.0055C4.5015 12.115 4.065 12.1675 3.612 12.1675C3.297 12.1675 2.979 12.1495 2.6805 12.0835C3.318 14.032 5.127 15.4645 7.278 15.511C5.604 16.8205 3.4785 17.6095 1.1775 17.6095C0.774 17.6095 0.387 17.5915 0 17.542C2.1795 18.9475 4.7625 19.75 7.548 19.75C16.602 19.75 21.552 12.25 21.552 5.749C21.552 5.5315 21.5445 5.3215 21.534 5.113C22.5105 4.42 23.331 3.5545 24 2.5585Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div>
                <span className="fs-12 d-block mt-1 ws-normal">Twitter</span>
            </div>
        </div>
    );
    const tabEmail = (
        <div className="d-flex flex-column align-items-center text-center">
            <div className="shape shape-circle shape-gray">
                <svg
                    width="24"
                    height="18"
                    viewBox="0 0 24 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.9089 9.123L23.9999 14.238V3.792L15.9089 9.123Z" fill="white" />
                    <path d="M0 3.792V14.238L8.091 9.123L0 3.792Z" fill="white" />
                    <path
                        d="M22.4999 0.75H1.49992C0.751422 0.75 0.157422 1.308 0.0449219 2.0265L11.9999 9.903L23.9549 2.0265C23.8424 1.308 23.2484 0.75 22.4999 0.75Z"
                        fill="white"
                    />
                    <path
                        d="M14.5351 10.029L12.4126 11.427C12.2866 11.5095 12.1441 11.55 12.0001 11.55C11.8561 11.55 11.7136 11.5095 11.5876 11.427L9.4651 10.0275L0.0480957 15.984C0.163596 16.6965 0.754596 17.25 1.5001 17.25H22.5001C23.2456 17.25 23.8366 16.6965 23.9521 15.984L14.5351 10.029Z"
                        fill="white"
                    />
                </svg>
            </div>
            <div>
                <span className="fs-12 d-block mt-1 ws-normal">Email</span>
            </div>
        </div>
    );
    const tabRequestPlaque = (
        <div className="d-flex flex-column align-items-center text-center">
            <div className="shape shape-circle shape-white">
                <svg
                    width="22"
                    height="38"
                    viewBox="0 0 22 38"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M11.0474 26.6392L7.59996 27.8144L2.89893 8.2268L11.0474 2.66392L19.1175 8.2268L14.1031 27.8144L11.0474 26.6392Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M2.27216 8.46186L0 7.4433L5.87629 28.4412L7.01237 28.0495L2.27216 8.46186Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M2.54639 7.71753L0.195876 6.50309L10.6165 0V2.03711L2.54639 7.71753Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M19.7049 8.54021L21.9771 7.52165L16.1008 28.5196L14.9647 28.1278L19.7049 8.54021Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M19.4307 7.79588L21.7812 6.58145L11.3606 0.0783539V2.11547L19.4307 7.79588Z"
                        fill="#A2792C"
                    />
                    <path
                        d="M10.9689 27.3052L1.91943 30.635V36.5505L20.0576 36.2763V30.635L10.9689 27.3052Z"
                        fill="#A2792C"
                    />
                    <rect
                        x="0.234863"
                        y="36.9031"
                        width="21.3113"
                        height="1.09691"
                        rx="0.548454"
                        fill="#A2792C"
                    />
                </svg>
            </div>
            <div>
                <span className="fs-12 d-block mt-1 ws-normal" style={{ maxWidth: 60 }}>
                    Request Plaque
                </span>
            </div>
        </div>
    );
    return (
        <Modal
            centered
            scrollable
            isOpen={isModalOpen}
            toggle={() => {
                setModalOpenState(!isModalOpen);
            }}>
            <ModalHeader
                className="close-alt empty"
                toggle={() => setModalOpenState(!isModalOpen)}></ModalHeader>
            <ModalBody className="py-4 d-flex flex-column align-items-center text-center">
                <div className="border-bottom border-secondary pb-3">{shareRatingVector}</div>
                <div className="mt-2">
                    <img src={JinniIcon} alt="Jinni" />
                    <span className="fs-30 fw-600 text-primary text-uppercase align-middle">
                        SUPERIOR
                    </span>
                </div>
            </ModalBody>
            <ModalFooter className="align-items-start justify-content-center bg-warm p-4">
                <div>{tabHeading}</div>
                <Tabs className="ant-tabs-share" defaultActiveKey="1" centered>
                    <TabPane tab={tabEmbed} key="1">
                        <Input
                            suffix={
                                <Button
                                    color="link"
                                    className="text-decoration-none p-0 fw-600"
                                    size="sm">
                                    COPY
                                </Button>
                            }
                            defaultValue="Lorem ipsum dolor sit amet"
                        />
                    </TabPane>
                    <TabPane tab={tabFacebook} key="2">
                        Share via Facebook
                    </TabPane>
                    <TabPane tab={tabTwitter} key="3">
                        Share via Twitter
                    </TabPane>
                    <TabPane tab={tabEmail} key="4">
                        Share via Email
                    </TabPane>
                    <TabPane tab={tabRequestPlaque} key="5">
                        {!isAlreadyPlaqueRequested ? (
                            <div className="d-md-flex justify-content-center align-items-center">
                                <div className="fs-17 mr-md-2 mb-2 mb-md-0">
                                    You are entitled to a Free Plaque
                                </div>
                                <Button
                                    color="primary"
                                    size="sm"
                                    className="btn-block btn-md-inline-block"
                                    onClick={() => setIsAlreadyPlaqueRequested(true)}>
                                    Request Now
                                </Button>
                            </div>
                        ) : (
                            <div className="d-md-flex flex-wrap align-items-start justify-content-between">
                                <div className="mr-md-2 mb-2 mb-md-0">
                                    <span className="fs-14">Please confirm your Address</span>
                                    <br />
                                    <span className="fs-16">
                                        1011 US Hwy 72 East, Athens AL 35611
                                    </span>
                                    <br />
                                    <Button color="link" className="p-0">
                                        Change Your Address
                                    </Button>
                                </div>
                                <Button
                                    color="primary"
                                    size="sm"
                                    className="btn-block btn-md-inline-block">
                                    Send to Address
                                </Button>
                            </div>
                        )}
                    </TabPane>
                </Tabs>
            </ModalFooter>
        </Modal>
    );
};

ShareVendorRatingModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func
};

export default ShareVendorRatingModal;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Legend from './Legend.jsx';
import StackedBarChart from './StackedBarChart.jsx';

const legendItems = [
    {
        color: '#4CDED5',
        text: 'Used Hours'
    },
    {
        color: '#FF3D00',
        text: 'Available Hours'
    },
    {
        color: '#A2792C',
        text: 'Recommended Hours'
    }
];

export default function CalendarAvailability(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(151, 151, 151, 0.22)',
            borderRadius: '4px',
            padding: '21px 26px',
            height: '100%'
        },
        title: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: '#333333',
            marginBottom: '5px'
        },
        subTitle: {
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: 'rgba(51, 51, 51, 0.63)',
            marginBottom: '21px'
        },
        metric: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: 'rgba(51, 51, 51, 0.63)'
        },
        metricValue: {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px'
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>Customer Calender Availability</div>
            <div className={classes.subTitle}>
                This chat shows the hours used by products that you have added for sale and hours
                available to maximise more sales. The chat also indicates growing demand on your
                customers calendars for you to scale to.
            </div>
            <Grid container style={{ marginBottom: '21px' }}>
                <Grid item md>
                    <div>
                        <span className={classes.metric}>Used Hours</span>
                        <br />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: '#333333',
                                    marginRight: '4px'
                                }}>
                                $6.8k
                            </span>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    /* identical to box height */
                                    color: '#FF0202',
                                    marginRight: '4px'
                                }}>
                                + 0.7%
                            </span>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    /* identical to box height */
                                    color: 'rgba(51, 51, 51, 0.63)'
                                }}>
                                Last month
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item md>
                    <div>
                        <span className={classes.metric}>Used Hours</span>
                        <br />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: '#333333',
                                    marginRight: '4px'
                                }}>
                                $6.8k
                            </span>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    /* identical to box height */
                                    color: '#FF0202',
                                    marginRight: '4px'
                                }}>
                                + 0.7%
                            </span>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    /* identical to box height */
                                    color: 'rgba(51, 51, 51, 0.63)'
                                }}>
                                Last month
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item md>
                    <div>
                        <span className={classes.metric}>Used Hours</span>
                        <br />
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center'
                            }}>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '600',
                                    fontSize: '20px',
                                    lineHeight: '24px',
                                    color: '#333333',
                                    marginRight: '4px'
                                }}>
                                $6.8k
                            </span>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    /* identical to box height */
                                    color: '#FF0202',
                                    marginRight: '4px'
                                }}>
                                + 0.7%
                            </span>
                            <span
                                className={classes.metricValue}
                                style={{
                                    fontWeight: '500',
                                    fontSize: '12px',
                                    lineHeight: '15px',
                                    /* identical to box height */
                                    color: 'rgba(51, 51, 51, 0.63)'
                                }}>
                                Last month
                            </span>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <div
                style={{
                    paddingLeft: '309px',
                    marginBottom: '23px'
                }}>
                <Legend items={legendItems} />
            </div>
            <div>
                <hr />
            </div>
            <div
                style={{
                    marginTop: '30px'
                }}>
                <StackedBarChart />
            </div>
        </div>
    );
}

/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Table, Button, Tag, Tooltip, Input } from 'antd';
import { getStripePayments } from 'store/actions';
import moment from 'moment';
import PropTypes from 'prop-types';
import { transformFirstLetterToUpperCase, toDecimal, parseAndGetKeyValue } from 'utils';
import { SearchOutlined } from '@ant-design/icons';

function PaymentsTab(props) {
    const { stripe_payments, getStripePayments } = props;

    const perPage = 10;
    const financeStatus = 'completed';
    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        getStripePayments({ page, perPage, financeStatus });
    }, []);

    function onClickNext() {
        getStripePayments({ page: page + 1, perPage, searchQuery, financeStatus }).then((res) => {
            if (res?.payload?.meta?.next_page_url) setPage((prev) => prev + 1);
        });
    }

    function onClickPrevious() {
        let newPage = page > 1 ? page - 1 : 1;
        getStripePayments({ page: newPage, perPage, searchQuery, financeStatus }).then((res) => {
            if (res?.payload?.meta) setPage(newPage);
        });
    }

    function onChangeSearchQuery(e) {
        const { value } = e.target;
        getStripePayments({ page: 1, perPage, searchQuery: value, financeStatus }).then((res) => {
            if (res?.payload) {
                setPage(1);
                setSearchQuery(value);
            }
        });
    }

    const paymentColumns = [
        {
            title: 'AMOUNT',
            dataIndex: 'amount',
            key: 'amount',
            render: (_text, record) => {
                return (
                    <div>
                        <span className="fw-500 mr-3">{`$${
                            record?.vendor_recieved_amount
                                ? toDecimal(record?.vendor_recieved_amount / 100)
                                : 0
                        }`}</span>{' '}
                        {`${
                            parseAndGetKeyValue(record?.metadata, 'base_currency')
                                ? parseAndGetKeyValue(
                                      record?.metadata,
                                      'base_currency'
                                  ).toUpperCase()
                                : ''
                        }`}
                        {record?.status && (
                            <span className="ml-3">
                                <Tooltip
                                    placement="top"
                                    title={<>{`This payment is ${record?.status}`}</>}>
                                    <Tag color="green">
                                        <span className="pr-2">
                                            {transformFirstLetterToUpperCase(record?.status)}
                                        </span>
                                        <svg
                                            aria-hidden="true"
                                            height="12"
                                            width="12"
                                            viewBox="0 0 16 16"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M5.297 13.213L.293 8.255c-.39-.394-.39-1.033 0-1.426s1.024-.394 1.414 0l4.294 4.224 8.288-8.258c.39-.393 1.024-.393 1.414 0s.39 1.033 0 1.426L6.7 13.208a.994.994 0 0 1-1.402.005z"
                                                fillRule="evenodd"></path>
                                        </svg>
                                    </Tag>
                                </Tooltip>
                            </span>
                        )}
                    </div>
                );
            }
        },
        {
            title: 'CUSTOMER',
            dataIndex: 'customer',
            key: 'customer',
            render: (_text, record) => {
                return (
                    <div>
                        {`${record.customer_first_name || ''} ${record.customer_last_name || ''}`}
                    </div>
                );
            }
        },
        {
            title: 'CUSTOMER EMAIL',
            dataIndex: 'customer_email',
            key: 'customer_email'
        },
        {
            title: 'DATE',
            dataIndex: 'date',
            key: 'date',
            render: (_text, record) => {
                return (
                    <div>
                        {`${
                            record.created_at && moment(record.created_at).format('MMM D, H:mm A')
                        }`}
                    </div>
                );
            }
        },
        {
            title: 'DETAILS',
            dataIndex: 'view',
            key: 'view',
            width: 100,
            render: (_text, record) => {
                return (
                    <div>
                        <Button
                            size="small"
                            id="view-detail"
                            color="primary"
                            onClick={() =>
                                props.onClickRow({
                                    id: record?.id,
                                    tab: 'charge',
                                    vendor_recieved_amount: record?.vendor_recieved_amount,
                                    base_currency: parseAndGetKeyValue(
                                        record?.metadata,
                                        'base_currency'
                                    )
                                })
                            }>
                            View
                        </Button>
                    </div>
                );
            }
        }
    ];

    return (
        <>
            <div className="d-flex justify-content-end mb-3 tab-filter-top">
                <div>
                    <Input
                        onChange={onChangeSearchQuery}
                        allowClear
                        placeholder="Search"
                        size="small"
                        prefix={<SearchOutlined />}
                    />
                </div>
            </div>
            <Table
                size="small"
                columns={paymentColumns}
                dataSource={
                    stripe_payments?.data
                        ? stripe_payments.data.map((value, index) => {
                              return { ...value, key: String(index) };
                          })
                        : []
                }
                pagination={false}
                scroll={{ y: 500, x: 1300 }}
                onRow={(record) => {
                    return {
                        onClick: () =>
                            props.onClickRow({
                                id: record?.stripe_payment_id,
                                tab: 'charge',
                                vendor_recieved_amount: record?.vendor_recieved_amount,
                                base_currency: parseAndGetKeyValue(
                                    record?.metadata,
                                    'base_currency'
                                )
                            }) // click row
                    };
                }}
            />
            <div className="d-flex gap-2 justify-content-end mt-3">
                <Button
                    type="primary"
                    size="small"
                    disabled={!stripe_payments?.meta?.previous_page_url}
                    onClick={onClickPrevious}>
                    Prev
                </Button>
                <Button
                    type="primary"
                    size="small"
                    disabled={!stripe_payments?.meta?.next_page_url}
                    onClick={onClickNext}>
                    Next
                </Button>
            </div>
        </>
    );
}

PaymentsTab.propTypes = {
    getStripePayments: PropTypes.func,
    stripe_payments: PropTypes.object,
    onClickRow: PropTypes.func
};

const mapStateToProps = (state) => ({
    stripe_payments: state.profile.stripe_payments
});

const mapDispatch = (dispatch) => ({
    getStripePayments: (query) => dispatch(getStripePayments(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(PaymentsTab));

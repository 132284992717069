import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

export default function PlanDrawer(props) {
    const { setState, state, vendorSubscription, handlePlan } = props;

    return (
        <Drawer
            width={609}
            placement="right"
            onClose={() => setState({ togglePlanDrawer: false })}
            visible={state.togglePlanDrawer}
            className="drawers-padding"
            style={{ zIndex: 1060 }}>
            <div style={{ color: '#A2792C' }} className="fs-28 fw-600 mt-5">
                Upgrade to send this calendar
            </div>
            <div className="fs-18 fw-400">
                You are currently on a {vendorSubscription?.plan?.name} plan which allows you to
                send {vendorSubscription?.no_of_free_calendar} free calendar. To send another
                calendar please upgrade your plan.
            </div>

            <div className="mt-4">
                <Button className="send-footer-btn px-4" onClick={handlePlan}>
                    Upgrade Plan
                </Button>
            </div>
        </Drawer>
    );
}

PlanDrawer.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    handlePlan: PropTypes.func,
    vendorSubscription: PropTypes.object
};

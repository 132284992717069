/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { Collapse, CardBody, Card, Button } from 'reactstrap';
import './index.scss';

// import MoreInfo from '../../../assets/images/more_info.svg';
// import Plus from '../../../assets/images/plus.svg';
import DropDown from '../../../assets/images/dropDown.svg';
import { Grid } from '@material-ui/core';
// import UpDown from '../../../assets/images/UpDown.svg';
import Edit from '../../../assets/images/edit.svg';
// import Copy from '../../../assets/images/Copy.svg';
import Close from '../../../assets/images/close-primary.svg';
import AcceptDelete from '../accept-delete';
import SelectTime from '../select-time';
import moment from 'moment';
import Proptypes from 'prop-types';
import {
    updateItineraries,
    adjustTimeItineraries,
    deleteItineraries,
    createItineraries,
    createItineraries_,
    editItineraries_,
    getItineraries,
    setCurrentProduct_,
    unlinkPackageProduct,
    getCalendarsById,
    selectedExistingProduct_,
    getPackageById
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Popover, Form } from 'antd';

function CollapseItem(props) {
    const { get_calendar_data, package_detail } = props;

    const [isOpen, setIsOpen] = useState(['Day 1']);
    const [isOpenAcceptDelete, setOpenIsAcceptDelete] = useState(false);
    const [isOpenSelectTime, setIsOpenSelectTime] = useState(false);

    const [tmp, setTmp] = useState([]);
    const [days, setDays] = useState([]);
    const [forDelete, setForDelete] = useState();
    const [dayProducts, setDayProducts] = useState();
    const [withoutSuggestedDayP, setWithoutSuggestedDayP] = useState([]);

    useEffect(() => {
        if (
            get_calendar_data?.data?.days &&
            package_detail &&
            Array.isArray(package_detail?.products)
        )
            mapDaysWithCalendarAndProductDay(get_calendar_data.data.days, package_detail.products);
    }, [props.get_calendar_data, package_detail]);

    const startFinishTime = (data) => {
        const array = [];
        if (data.type === 'specific_time')
            array.push(
                `${timeConverter(data.time_period?.from)} - ${timeConverter(data.time_period?.to)}`
            );
        else if (data.type === 'within_time_period') {
            if (Array.isArray(data.within_time_periods))
                for (let value of data.within_time_periods) {
                    array.push(`${timeConverter(value?.from)} - ${timeConverter(value?.to)}`);
                }
        } else if (data.type === 'both') {
            array.push(
                `${timeConverter(data.time_period?.from)} - ${timeConverter(data.time_period?.to)}`
            );
            if (Array.isArray(data.within_time_periods))
                for (let value of data.within_time_periods) {
                    array.push(`${timeConverter(value?.from)} - ${timeConverter(value?.to)}`);
                }
        }
        return array;
    };

    function AddedAdditions(res) {
        const additions = [];
        if (res.photos_and_videos) additions.push('Photos & Videos added');
        if (res.meals_and_drinks) additions.push('Meals & Drinks added');
        if (res.language_offered) additions.push('Language offered added');
        if (res.inclusions || res.exclusions) additions.push('Inclusions & Exclusions added');
        if (res.calendar_season) additions.push('Calendar seasons added');
        if (res.adventures) additions.push('Adventures added');
        if (res.sights) additions.push('Top Sights added');
        if (res.traveller_information) additions.push('Information travellers need from you added');

        return additions;
    }

    useEffect(() => {
        if (!props.expand) {
            setIsOpen([]);
        }
    }, [props.expand]);

    function mapDaysWithCalendarAndProductDay(calendarDays, packageProduct = []) {
        const uniqueWeekDays = [];
        let withoutSuggestedDayPRaw = [];

        for (let value of calendarDays) {
            if (!uniqueWeekDays.includes(value.day_of_week)) uniqueWeekDays.push(value.day_of_week);
        }
        setDays(uniqueWeekDays);

        //map days with package product
        const daysProducts = {};

        for (let value of uniqueWeekDays) {
            //for allday
            for (let i of packageProduct) {
                if (
                    i.day_type === 'all_day' &&
                    Array.isArray(i?.calendar_days?.days) &&
                    i.calendar_days.days.includes(value)
                )
                    if (daysProducts[value]) daysProducts[value].push(i);
                    else daysProducts[value] = [i];
            }
        }

        for (let i of packageProduct) {
            //for withIn day
            if (i.day_type === 'within_day') {
                //if suggested day in not exists for within day products, collecting for update
                if (!i?.suggestedDay) withoutSuggestedDayPRaw.push(i);

                const found = calendarDays.find((element) => element.day === i?.suggestedDay);
                if (found)
                    if (daysProducts[found.day_of_week]) daysProducts[found.day_of_week].push(i);
                    else daysProducts[found.day_of_week] = [i];
            }
        }

        setWithoutSuggestedDayP(withoutSuggestedDayPRaw);
        setDayProducts(daysProducts);
    }

    const toggle = (e) => {
        if (indexCollapse(e) !== -1) {
            let tmp = isOpen;
            tmp.splice(indexCollapse(e), 1);
            setIsOpen([...tmp]);
            props.setExpand();
        } else setIsOpen([...isOpen, e]);
    };
    const indexCollapse = (e) => isOpen.findIndex((item) => e === item);

    //add new day
    // const addDay = () => {
    //     let tempDays = [...days];
    //     tempDays.push([]);
    //     setDays(tempDays);
    // };

    // const removeDay = (index) => {
    //     let tempDays = [...days];
    //     tempDays.splice(index, 1);
    //     setDays(tempDays);
    // };

    //converting to 12 hour time format

    const timeConverter = (time) => {
        return moment(time, 'hh:mm A').format('h:mma');
    };

    //sorting itineraries by day by time
    const timeCompare = (a, b) => {
        const parseA = parseInt(moment(a.startTime).format('HH'));
        const parseB = parseInt(moment(b.startTime).format('HH'));
        if (parseA > parseB) {
            return 1;
        }
        if (parseA < parseB) {
            return -1;
        }
        if (parseA === parseB) {
            return 0;
        }
    };

    //set drag item
    const dragActivities = (e, index, timeIndex) => {
        setTmp([index, timeIndex]);
        let activityContent = days[index][timeIndex];
        let activityContentAdded = { ...activityContent, index, timeIndex };
        let movingObject = JSON.stringify(activityContentAdded);
        e.dataTransfer.setData('activity', movingObject);
    };

    //moving and drop item in day
    const dropActivities = (e, index) => {
        e.preventDefault();
        e.stopPropagation();
        let data = JSON.parse(e.dataTransfer.getData('activity'));
        let tempDays = [...days];
        tempDays[index].push(data);
        tempDays[data.index].splice(data.timeIndex, 1);
        setDays(tempDays);

        const timeIndex = days[index].sort(timeCompare).findIndex((res) => res === data);
        setTmp([index, timeIndex]);
        props.updateItineraries(days[index][timeIndex].id, { calendar_day: index + 1 });
        setIsOpenSelectTime(true);
    };

    //remove itinerary
    const deleteActivity = (accept) => {
        setOpenIsAcceptDelete(false);
        if (accept && forDelete) {
            props
                .unlinkPackageProduct({
                    product_id: forDelete.id,
                    package_id: package_detail?.id
                })
                .then((res) => {
                    if (res) {
                        props.getPackageById(package_detail?.id, get_calendar_data?.data?.id);
                    }
                });
        }
    };

    //set editing itinerary
    const onEdit = (each) => {
        props.setCurrentProduct_(each);
        props.toggleOnEditPackageProduct(each.day_type);
        props.selectedExistingProduct_(true);
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    const onOpenSelectTime = () => setIsOpenSelectTime(!isOpenSelectTime);
    //editing time in itinerary
    const onEditTime = (start, end) => {
        setIsOpenSelectTime(false);
        let tempDays = [...days];
        let tempItineraries = days[tmp[0]][tmp[1]];
        tempItineraries.startTime = moment(start, 'HH:mm');
        tempItineraries.endTime = moment(end, 'HH:mm');
        props.adjustTimeItineraries(tempItineraries.id, {
            start_time: moment(start, 'HH:mm')._i,
            finish_time: moment(end, 'HH:mm')._i
        });
        tempDays[tmp[0]].splice(tmp[1], 1, tempItineraries);
        setDays(tempDays);
    };

    return (
        <div className="collapse-days">
            {days.length > 0 &&
                days.map((each, index) => {
                    if (dayProducts[each]) {
                        return (
                            <div key={index}>
                                <div className="head-days">
                                    <div
                                        className="btn-day cursor-pointer"
                                        onClick={() => {
                                            toggle(`Day ${index + 1}`);
                                            props.setExpand();
                                        }}>
                                        <span
                                            className="d-inline-block"
                                            role="button"
                                            style={{
                                                transform:
                                                    indexCollapse(`Day ${index + 1}`) !== -1
                                                        ? 'rotate(180deg)'
                                                        : 'rotate(0)'
                                            }}>
                                            <img src={DropDown} alt="" />
                                        </span>
                                        <span className="ml-2">{`Day ${index + 1} - ${each}`}</span>
                                    </div>
                                    {/* {index !== 0 && days.length > 1 ? (
                                        <div
                                            className="btn-remove-day cursor-pointer"
                                            onClick={() => removeDay(index)}>
                                            Remove Day
                                        </div>
                                    ) : null} */}
                                </div>

                                {dayProducts[each].length > 0 && (
                                    <Collapse
                                        isOpen={indexCollapse(`Day ${index + 1}`) !== -1}
                                        style={{ padding: '0px 30px 20px 30px' }}>
                                        <Card className="border-0">
                                            <CardBody>
                                                {/* <div className="card-head">
                                    <div className="title">
                                        Itineraries
                                        <img src={MoreInfo} alt="" />
                                    </div>
                                    <Button
                                        color="link"
                                        onClick={() => {
                                            props.onAddAnItinerary(index + 1);
                                        }}>
                                        <img className="img-plus" src={Plus} alt="" /> Add an
                                        Itinerary
                                    </Button>
                                </div> */}
                                                {/* {days[index].length > 0 ? (
                                    <hr style={{ margin: '14px 0' }} />
                                ) : null} */}
                                                <Grid
                                                    item
                                                    id={index}
                                                    className={
                                                        dayProducts[each].length > 0
                                                            ? ''
                                                            : 'empty-day'
                                                    }
                                                    onDrop={(e) => dropActivities(e, index)}
                                                    onDragOver={(e) => allowDrop(e)}>
                                                    {dayProducts[each].map((each, timeIndex) => (
                                                        // days[index]
                                                        //     .sort(timeCompare)
                                                        //     .map((each, timeIndex) => (
                                                        <div
                                                            key={timeIndex}
                                                            draggable="true"
                                                            onDragStart={(e) =>
                                                                dragActivities(e, index, timeIndex)
                                                            }
                                                            className="drag">
                                                            <Grid container spacing={1}>
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        flex: '0 0 120px',
                                                                        width: '120px'
                                                                    }}
                                                                    className="times cursor-pointer">
                                                                    {each.day_type === 'all_day'
                                                                        ? 'All day'
                                                                        : each.booking_time_periods && (
                                                                              <>
                                                                                  {startFinishTime(
                                                                                      each.booking_time_periods
                                                                                  )
                                                                                      .slice(0, 1)
                                                                                      .map(
                                                                                          (
                                                                                              value,
                                                                                              index
                                                                                          ) => (
                                                                                              <div
                                                                                                  key={
                                                                                                      index
                                                                                                  }>
                                                                                                  {
                                                                                                      value
                                                                                                  }
                                                                                              </div>
                                                                                          )
                                                                                      )}
                                                                                  {startFinishTime(
                                                                                      each.booking_time_periods
                                                                                  ).length > 1 && (
                                                                                      <Popover
                                                                                          overlayClassName="popover-container"
                                                                                          content={startFinishTime(
                                                                                              each.booking_time_periods
                                                                                          )
                                                                                              .slice(
                                                                                                  1
                                                                                              )
                                                                                              .map(
                                                                                                  (
                                                                                                      value,
                                                                                                      index
                                                                                                  ) => (
                                                                                                      <div
                                                                                                          className="fs-14"
                                                                                                          key={
                                                                                                              index
                                                                                                          }>
                                                                                                          {
                                                                                                              value
                                                                                                          }
                                                                                                      </div>
                                                                                                  )
                                                                                              )}>
                                                                                          <span>
                                                                                              +
                                                                                              {startFinishTime(
                                                                                                  each.booking_time_periods
                                                                                              )
                                                                                                  .length -
                                                                                                  1}{' '}
                                                                                              more
                                                                                          </span>
                                                                                      </Popover>
                                                                                  )}
                                                                              </>
                                                                          )}
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    container
                                                                    direction="column"
                                                                    style={{
                                                                        flexBasis: 0,
                                                                        flexGrow: 1,
                                                                        maxWidth: '100%'
                                                                    }}>
                                                                    <Grid
                                                                        item
                                                                        className="itinerary-name">
                                                                        {each.title}
                                                                    </Grid>
                                                                    {AddedAdditions(each).map(
                                                                        (added, addIndex) => (
                                                                            <Grid
                                                                                key={addIndex}
                                                                                item
                                                                                className="itinerary-added">
                                                                                {added}
                                                                            </Grid>
                                                                        )
                                                                    )}
                                                                </Grid>
                                                                <AcceptDelete
                                                                    isOpen={isOpenAcceptDelete}
                                                                    onClose={(accept) => {
                                                                        deleteActivity(accept);
                                                                    }}
                                                                    description="Are you sure you want to remove it?"
                                                                />
                                                                <SelectTime
                                                                    isOpen={isOpenSelectTime}
                                                                    onSave={(start, end) =>
                                                                        onEditTime(start, end)
                                                                    }
                                                                    onClose={onOpenSelectTime}
                                                                />
                                                                <Grid
                                                                    item
                                                                    style={{
                                                                        flex: '0 0 auto',
                                                                        width: 'auto'
                                                                    }}>
                                                                    {/* <img
                                                                        src={UpDown}
                                                                        alt=""
                                                                        title="Move"
                                                                        className="itinerary-img cursor-pointer"
                                                                        onClick={() => {
                                                                        onOpenSelectTime();
                                                                        setTmp([
                                                                            index,
                                                                            timeIndex
                                                                        ]);
                                                                        }}
                                                                    /> */}
                                                                    <img
                                                                        src={Edit}
                                                                        alt=""
                                                                        title="Edit"
                                                                        className="itinerary-img cursor-pointer"
                                                                        onClick={() => onEdit(each)}
                                                                    />
                                                                    {/* <img
                                                                        src={Copy}
                                                                        title="Copy"
                                                                        alt=""
                                                                        onClick={() =>
                                                                        duplicateActivity(
                                                                            each,
                                                                            index,
                                                                            timeIndex
                                                                        )
                                                                        }
                                                                        className="itinerary-img cursor-pointer"
                                                                    /> */}
                                                                    <img
                                                                        src={Close}
                                                                        title="Remove"
                                                                        alt=""
                                                                        onClick={() => {
                                                                            setOpenIsAcceptDelete(
                                                                                true
                                                                            );
                                                                            setForDelete({
                                                                                id: each.id,
                                                                                productType:
                                                                                    each.productType
                                                                            });
                                                                            // setTmp([index, timeIndex]);
                                                                        }}
                                                                        className="itinerary-img cursor-pointer"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                style={{
                                                                    marginBottom: '12px'
                                                                }}></Grid>
                                                        </div>
                                                    ))}
                                                </Grid>
                                            </CardBody>
                                        </Card>
                                    </Collapse>
                                )}
                                <hr className="my-1" />
                            </div>
                        );
                    } else {
                        return null;
                    }
                })}
            {/* <div className="footer">
                <Button size="lg" onClick={addDay}>
                    Add Another Day
                </Button>
            </div> */}

            {Array.isArray(withoutSuggestedDayP) && withoutSuggestedDayP.length > 0 && (
                <div style={{ padding: '16px 30px 20px' }}>
                    <div className="mb-2">
                        <div className="fs-20 fw-600 text-danger">Action required!</div>
                        <div className="fs-12 fw-500">Please update suggested day for above.</div>
                    </div>
                    <Card className="border-0">
                        {withoutSuggestedDayP.map((value, ind) => (
                            <CardBody
                                key={ind}
                                className="d-flex gap-3 flex-nowrap align-items-start">
                                <h5 className="ff-base fs-15 fw-600 text-truncate flex-grow-1">
                                    {value?.title || 'Unknown'}
                                </h5>
                                <Button
                                    onClick={() => onEdit(value)}
                                    color="icon"
                                    size="sm"
                                    title="Edit">
                                    <img src={Edit} alt="" />
                                </Button>
                            </CardBody>
                        ))}
                    </Card>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please update suggested day'
                            }
                        ]}
                        name="without-suggested-day-product"
                        className="hidden-input-form-item">
                        <input type="hidden" />
                    </Form.Item>
                </div>
            )}
        </div>
    );
}

CollapseItem.propTypes = {
    onClick: Proptypes.func,
    onAddAnItinerary: Proptypes.func,
    isEdit: Proptypes.func,
    isRecommended: Proptypes.bool,
    setExpand: Proptypes.func,
    expand: Proptypes.bool,
    adjustTimeItineraries: Proptypes.func,
    deleteItineraries: Proptypes.func,
    createItineraries: Proptypes.func,
    createItineraries_: Proptypes.func,
    updateItineraries: Proptypes.func,
    editItineraries_: Proptypes.func,
    getItineraries: Proptypes.func,
    setCurrentProduct_: Proptypes.func,
    toggleAddItineraryModal: Proptypes.func,
    unlinkPackageProduct: Proptypes.func,
    get_calendar_data: Proptypes.object,
    getCalendarsById: Proptypes.func,
    toggleOnEditPackageProduct: Proptypes.func,
    selectedExistingProduct_: Proptypes.func,
    package_detail: Proptypes.object,
    getPackageById: Proptypes.func
};

const mapStateToProps = (state) => ({
    get_calendar_data: state.calendar.get_calendar_data,
    package_detail: state.product.package_detail
});

const mapDispatch = (dispatch) => ({
    createItineraries: (data) => dispatch(createItineraries(data)),
    createItineraries_: (data) => dispatch(createItineraries_(data)),
    editItineraries_: (data) => dispatch(editItineraries_(data)),
    updateItineraries: (id, data) => dispatch(updateItineraries(id, data)),
    adjustTimeItineraries: (id, data) => dispatch(adjustTimeItineraries(id, data)),
    deleteItineraries: (id) => dispatch(deleteItineraries(id)),
    getItineraries: (data) => dispatch(getItineraries(data)),
    setCurrentProduct_: (data) => dispatch(setCurrentProduct_(data)),
    unlinkPackageProduct: (data) => dispatch(unlinkPackageProduct(data)),
    getCalendarsById: (data) => dispatch(getCalendarsById(data)),
    selectedExistingProduct_: (data) => dispatch(selectedExistingProduct_(data)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(CollapseItem));

import React from 'react';
// import { TableCell } from '@material-ui/core';
import { Row, Col } from 'reactstrap';
import Proptypes from 'prop-types';
import { Form, InputNumber } from 'antd';

const styles = {
    cell: {
        border: 'none',
        display: 'flex',
        // alignItems: 'center',
        padding: 8
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px',
        width: '68px',
        alignItems: 'center',
        display: 'flex'
    },
    input: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '48px',
        width: '89px',
        fontSize: '16px',
        textAlign: 'center',
        marginRight: '10px'
    },
    inputUsd: {
        border: '0.5px solid #C4C4C4',
        borderLeft: 0
    },
    disabled: {
        opacity: 0.6
    }
};

export default function GroupRow({ productPrice, onChange, secondPrice, groupName, index, type }) {
    return (
        <>
            <Row style={{ border: 'none' }}>
                <Col style={{ ...styles.cell }}>{groupName}</Col>
                {type !== 'accomodation' && (
                    <Form.Item>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={styles.currency}>USD</div>
                            <InputNumber
                                disabled={
                                    type === 'product_accomodation' || type === 'product_supplement'
                                }
                                style={{
                                    ...styles.input,
                                    ...styles.inputUsd,
                                    borderRadius: '0 5px 5px 0'
                                }}
                                value={type === 'accomodation' ? secondPrice : productPrice}
                                onChange={(e) => onChange(e, 'productPrice', groupName)}
                            />
                            <div>PP</div>
                        </div>
                    </Form.Item>
                    // </TableCell>
                )}
                {/* <TableCell style={styles.cell} align="left"> */}
                <Form.Item
                    name={`${index}secondPrice`}
                    rules={[
                        {
                            required: secondPrice === null || secondPrice === undefined,
                            message: `Please add ${groupName} price`
                        }
                    ]}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={styles.currency}>USD</div>
                        <InputNumber
                            style={{
                                ...styles.input,
                                ...styles.inputUsd,
                                borderRadius: '0 5px 5px 0'
                            }}
                            name={'secondPrice'}
                            value={secondPrice}
                            min={1}
                            onChange={(e) => onChange(e, 'secondPrice', groupName)}
                        />
                        <div>PP</div>
                    </div>
                </Form.Item>
                {/* </TableCell> */}
            </Row>
        </>
    );
}
GroupRow.propTypes = {
    groupName: Proptypes.string,
    onSave: Proptypes.func,
    data: Proptypes.object,
    index: Proptypes.number,
    type: Proptypes.string,
    productPrice: Proptypes.any,
    onChange: Proptypes.any,
    secondPrice: Proptypes.any
};

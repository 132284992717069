/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { EditIcon, ProductCategoryIcon } from 'components/atoms';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateCalendarDaySelectedProducts } from 'store/actions';
import { getMoment } from 'utils';

const ProductItem = ({
    product,
    calendar,
    active,
    updateCalendarDaySelectedProducts,
    setCurrentProduct,
    selectable,
    popoverRef
}) => {
    const time = getMoment(product);
    const [showMore, setShowMore] = React.useState(false);
    return (
        <div className={`d-flex flex-nowrap mx-n2 event-schedule ${active && '__special'}`}>
            <div className="px-2 event-time-window-wrapper">
                <div className="event-time-window">
                    <div className="event-start-time">{time.start.format('HH:mm')}</div>
                    <div className="event-end-time">{time.end.format('HH:mm')}</div>
                </div>
            </div>
            <div className="px-2 flex-grow-1">
                <div className="event-info-wrapper">
                    <div
                        className="event-title"
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                            if (!active && selectable) {
                                await updateCalendarDaySelectedProducts({
                                    product_id: product?.id,
                                    calendar_id: calendar?.id
                                });
                            }
                        }}>
                        <span className="event-icon mr-2">
                            <ProductCategoryIcon
                                id={product?.product_category_id}
                                color={'currentColor'}
                            />
                        </span>
                        {product?.title}
                    </div>
                    <div className="event-info">
                        {product?.description && !showMore
                            ? product?.description.slice(0, 40)
                            : product?.description}
                        {product?.description?.length > 40 && !showMore && '...'}
                        {product?.description?.length > 40 ? (
                            !showMore ? (
                                <i role="button" tabIndex="0" onClick={() => setShowMore(true)}>
                                    {' '}
                                    see more
                                </i>
                            ) : (
                                <i role="button" tabIndex="0" onClick={() => setShowMore(false)}>
                                    {' '}
                                    see less
                                </i>
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
            <div className="px-2">
                <span
                    className="text-white"
                    role="button"
                    tabIndex="0"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setCurrentProduct(product);
                        if (popoverRef?.current) popoverRef.current.close();
                    }}>
                    <EditIcon />
                </span>
            </div>
        </div>
    );
};

ProductItem.propTypes = {
    product: PropTypes.object,
    active: PropTypes.bool,
    calendar: PropTypes.any,
    updateCalendarDaySelectedProducts: PropTypes.func,
    setCurrentProduct: PropTypes.any,
    key: PropTypes.any,
    popoverRef: PropTypes.any,
    selectable: PropTypes.bool
};

const mapStateToProps = (state) => ({
    calendar: state?.calendar.get_calendar_data?.data
});

export default connect(mapStateToProps, { updateCalendarDaySelectedProducts })(ProductItem);

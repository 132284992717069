/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';

export default function Number() {
    const [number, setNumber] = useState(0);
    //Function to add and substract numbers
    const addNumber = () => {
        setNumber(number + 1);
    };
    const substractNumber = () => {
        if (number >= 1) {
            setNumber(number - 1);
        }
    };

    return (
        <div className="d-flex">
            <div className="small-card" onClick={substractNumber}>
                <p className="small-card-details minus">-</p>
            </div>
            <div className="no-small-card">
                <p className="no-small-card-details">{number}</p>
            </div>
            <div className="small-card" onClick={addNumber}>
                <p className="small-card-details">+</p>
            </div>
        </div>
    );
}

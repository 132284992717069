/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import { MoreInfoTooltip } from 'components/atoms';

export default function AddFacilityButton(props) {
    const [facilities, setFacilities] = useState([]);
    const { add = 'Add', moreInfo = true } = props;

    useEffect(() => {
        if (props.data) {
            const { data } = props;
            const tmpData = [];
            tmpData.push.apply(tmpData, data.general);
            tmpData.push.apply(tmpData, data.cooking_and_cleaning);
            tmpData.push.apply(tmpData, data.entertainment);
            tmpData.push.apply(tmpData, data.outside_and_view);
            setFacilities(tmpData);
        } else setFacilities([]);
    }, [props.data]);

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? (
                            <MoreInfoTooltip
                                title={`This refers to the facilities a customer has access to in this accommodation`}
                            />
                        ) : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                <Button color="link" onClick={props.onClick}>
                    <img className="img-plus" src={Plus} alt="" /> {add}
                </Button>
            </div>
            {facilities && facilities.length > 0 ? (
                <Row className="actions">
                    <Col style={{ paddingLeft: 0 }}>
                        {facilities.map((res, i) =>
                            i === facilities.length - 1 ? res : `${res}, `
                        )}
                    </Col>

                    <Col xs="auto" style={{ paddingRight: 0, alignItems: 'flex-end' }}>
                        <div className="group-btn">
                            <img
                                src={Edit}
                                alt=""
                                title="Edit"
                                className="itinerary-img cursor-pointer"
                                onClick={props.onClick}
                            />
                            <img
                                src={Delete}
                                alt=""
                                title="Delete"
                                onClick={props.onDelete}
                                className="itinerary-img cursor-pointer"
                            />
                        </div>
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
AddFacilityButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any
};

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import '../../add-an-itinerary/index.scss';
import { Button, ModalFooter, ModalBody, Spinner, Row, Col } from 'reactstrap';
// import HelpIcon from 'assets/images/helpIcon.svg';
import { GoogleAutoComplete, Spacer } from 'components/atoms';
import { Form, Input, InputNumber, Tooltip, Popover, TimePicker } from 'antd';
import {
    updateItineraries,
    createItineraries_,
    editItineraries_,
    getItineraries
} from 'store/actions/contentActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import { resetCalenderReducer } from 'store/actions';
import MoreInfo from 'assets/images/more_info.svg';
import { getItineraryState, getItineraryFormPageOneState, mapValidationErrors } from 'utils/helper';
import AddMediaToItenary from './add-itenary-media';
import AccomodationFormFields from './accomodation-form-fields';
import BookingPricingForm from './booking-pricing-form';
import { Feedback } from 'components/molecules';
import moment from 'moment';

class ItineraryFormPageOne extends React.Component {
    formRef = React.createRef();
    durationInputEndRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = getItineraryFormPageOneState();
    }
    componentDidUpdate(prevProps) {
        const { selected_existing_product, edit_itineraries, currentProduct } = this.props;

        if (edit_itineraries && prevProps.edit_itineraries !== edit_itineraries) {
            this.onSetMainProduct(edit_itineraries);
            this.props.setState({ page: 1 });
        }
        if (this.formRef.current) {
            if (this.state.breakfastDetails) {
                this.formRef.current.setFields([
                    {
                        name: 'breakfastDetails',
                        errors: []
                    }
                ]);
            }
            if (this.state.booking) {
                this.formRef.current.setFields([
                    {
                        name: 'booking',
                        errors: []
                    }
                ]);
            }
            if (this.state.pricing) {
                this.formRef.current.setFields([
                    {
                        name: 'pricing',
                        errors: []
                    }
                ]);
            }
            if (this.state.whereCanPeopleSleep) {
                this.formRef.current.setFields([
                    {
                        name: 'whereCanPeopleSleep',
                        errors: []
                    }
                ]);
            }
            if (this.state.facilities) {
                this.formRef.current.setFields([
                    {
                        name: 'facilities',
                        errors: []
                    }
                ]);
            }
            if (this.state.houseRules) {
                this.formRef.current.setFields([
                    {
                        name: 'houseRules',
                        errors: []
                    }
                ]);
            }
        }

        //existing product
        if (
            (prevProps.selected_existing_product === false && selected_existing_product) ||
            (prevProps.selected_existing_product &&
                selected_existing_product &&
                prevProps.currentProduct?.id !== currentProduct?.id)
        ) {
            this.onSetMainProduct(currentProduct);
        }

        // if (selected_existing_product === false && currentProduct) {
        //     if (this.props?.state?.page !== prevProps.state?.page) {
        //         this.onSetMainProduct(currentProduct);
        //     }
        // }
    }

    componentDidMount() {
        const { selected_existing_product, showUpdateProductFields, currentProduct } = this.props;
        if (selected_existing_product) {
            this.onSetMainProduct(currentProduct);
        }
        if (selected_existing_product === false && currentProduct) {
            this.onSetMainProduct(currentProduct);
        }

        if (showUpdateProductFields?.editPricing) this.setState({ isOpenPricing: true });
        if (showUpdateProductFields?.editDuration)
            this.durationInputEndRef.current &&
                this.durationInputEndRef.current.scrollIntoView({ behaviour: 'smooth' });
        if (showUpdateProductFields?.editRooms) this.setState({ isOpenWhereCanPeopleSleep: true });
        if (showUpdateProductFields?.editBreakfast) this.setState({ isOpenBreakfastDetails: true });
        if (showUpdateProductFields?.editHouseRules) this.setState({ isOpenHouseRules: true });
        if (showUpdateProductFields?.editFacilities) this.setState({ isOpenAddFacilities: true });
        if (showUpdateProductFields?.editBookingDetails)
            this.setState({ isOpenBookingTickets: true });

        if (
            showUpdateProductFields?.editAdmissionPrice ||
            showUpdateProductFields?.editMealsAndDrinks ||
            showUpdateProductFields?.editExclusions ||
            showUpdateProductFields?.editLanguagesOffered ||
            showUpdateProductFields?.editGuideMeetLocation ||
            showUpdateProductFields?.editCalendarSeason ||
            showUpdateProductFields?.editAdventures ||
            showUpdateProductFields?.editSights ||
            showUpdateProductFields?.editOccassions ||
            showUpdateProductFields?.editCovidDetails ||
            showUpdateProductFields?.editRating ||
            showUpdateProductFields?.editTravellerInfo ||
            showUpdateProductFields?.editInclusions
        )
            this.props.setState({ page: 2 });
    }

    //set import data
    onSetMainProduct = async (item) => {
        if (item) {
            let anotherStart = [];
            let checkBoxValues = [];
            if (item?.meetup_info?.at_accommodation) {
                checkBoxValues.push('1');
            }
            if (item?.meetup_info?.allow_users_to_enter_pickup_location) {
                checkBoxValues.push('2');
            }
            if (item?.meetup_info?.at_starting_point) {
                checkBoxValues.push('3');
            }
            if (item?.meetup_info?.points && item?.meetup_info?.points.length > 0) {
                anotherStart = item?.meetup_info?.points || [];
                //anotherStart.splice(0, 1);
            }

            this.setState({
                isCheckBox: checkBoxValues
            });

            let itineraryState = await getItineraryState({ item, anotherStart });

            this.setState(itineraryState);

            if (this.formRef.current) {
                this.formRef.current.setFieldsValue({
                    title: item?.title,
                    describe: item?.description,
                    media: item?.photos_and_videos,
                    destination: item?.address ?? '',

                    // startTimeHours: moment(
                    //     (itineraryState?.start === null || itineraryState?.start === '') ? '12:00 am' : itineraryState?.start,
                    //     'h:mm a'
                    // ),
                    startTimeHours:
                        itineraryState?.start === null || itineraryState?.start === ''
                            ? ''
                            : moment(itineraryState?.start, 'h:mm a'),
                    // finishTimeHours: moment(
                    //     (itineraryState?.finish === null || itineraryState?.finish === '') ? '12:00 am' : itineraryState?.finish,
                    //     'h:mm a'
                    // ),
                    finishTimeHours:
                        itineraryState?.finish === null || itineraryState?.finish === ''
                            ? ''
                            : moment(itineraryState?.finish, 'h:mm a')
                });
            }

            if (!(Object.keys(this.props.showUpdateProductFields || {}).length > 0))
                this.props.setState({ page: 1 });
        }
    };

    setAddress = (value) => {
        this.setState({
            destination: value
        });
    };

    onClear = () => {
        this.formRef.current.resetFields();
        this.setState(getItineraryFormPageOneState);
    };

    onFinish = async () => {
        this.props.onFinish({ state: this.state });

        if (!this.props.state?.page === 1) {
            this.onClear();
        }
    };

    onFinishFailed = ({ errorFields }) => {
        let errorsList = mapValidationErrors(errorFields);
        if (errorsList && Object.keys(errorsList).length > 0)
            this.setState({ showValidationPopover: errorsList }, () => {
                setTimeout(() => this.setState({ showValidationPopover: null }), 4000);
            });
    };

    showNextDayMessage = () => {
        const { start, finish } = this.state;
        if (start && finish) {
            // let startTimeAmPm = moment(start, 'HH:mm').format('hh:mm A').split(' ')[1];

            // let endTimeAmPm = moment(finish, 'HH:mm').format('hh:mm A').split(' ')[1];

            // if (startTimeAmPm == 'PM' && endTimeAmPm == 'AM' && finish != '12:00') {
            //     return true;
            // }
            if (moment(start, 'hh:mm A').isSameOrAfter(moment(finish, 'hh:mm A'))) {
                return true;
            }
        }
        return false;
    };

    render() {
        const {
            title,
            describe,
            media,
            start,
            finish,
            destination,
            maxDailyAvailability,
            showValidationPopover = false
        } = this.state;
        const { product_categories, currentProduct, selected_existing_product, package_detail } =
            this.props;
        let previousSelectedCategory = null;
        let previousSelectedSubCategory = null;
        if (currentProduct) {
            let categoryId = currentProduct.product_category_id;
            let subcategoryId = currentProduct.product_sub_category_id;

            previousSelectedCategory = product_categories.filter((c) => c.id == categoryId)[0];
            if (categoryId && previousSelectedCategory?.productSubCategories) {
                previousSelectedSubCategory = previousSelectedCategory.productSubCategories.filter(
                    (s) => s.id == subcategoryId
                )[0];
            }
        }

        return (
            <Form
                className="create-product d-flex flex-column overflow-y-auto"
                ref={this.formRef}
                name="itinerary-ref"
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                initialValues={{
                    ...this.state,
                    startTime: start,
                    endTime: finish
                }}
                layout="vertical"
                requiredMark={false}>
                <ModalBody className="pt-0">
                    <Form.Item
                        name="title"
                        rules={[{ required: true, message: 'Please fill title' }]}>
                        <div className="input-title">Add Title</div>
                        {/* TODO: Remove inline styling */}
                        <Input
                            style={{
                                border: '0.5px solid #A2792C',
                                height: 50,
                                borderRadius: 8
                            }}
                            value={title}
                            placeholder="Kruger National Park Safari, South Africa"
                            onChange={(e) => {
                                this.formRef.current.setFieldsValue({
                                    title: e.target.value
                                });
                                this.setState({ title: e.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="describe"
                        rules={[{ required: true, message: 'Please fill description' }]}>
                        <Spacer top={10} />
                        <div
                            className="input-title"
                            style={{ marginLeft: '2px', marginBottom: '8px' }}>
                            <span className="">
                                {
                                    'Describe what travelers will see and do here if they book your experience'
                                }
                            </span>

                            <Tooltip
                                title={
                                    <div className="text-center">
                                        {'Add atleast 100 words for maximum rating'}
                                    </div>
                                }>
                                <sup>
                                    <img src={MoreInfo} alt="" />
                                </sup>
                            </Tooltip>
                        </div>
                        <Input.TextArea
                            style={{
                                height: '139px',
                                padding: '17px 51px 10px 25px'
                            }}
                            className="input-area"
                            value={describe}
                            placeholder='Enter a description about this only. For example, "Our accredited
                                guide will meet you at the ticket booth and will accompany you up the tower, explaining what
                                 you"ll see at each level." Description is required.'
                            onChange={(e) => {
                                this.formRef.current.setFieldsValue({
                                    describe: e.target.value
                                });
                                this.setState({ describe: e.target.value });
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="destination"
                        rules={[{ required: true, message: 'Please fill destination' }]}>
                        <div className="input-title">Select main destination</div>
                        <GoogleAutoComplete
                            placeholder="Kruger National Park Safari, South Africa"
                            defValue={destination}
                            fieldName={'destination'}
                            onSelectDistance={(value) => {
                                this.formRef.current.setFieldsValue({
                                    destination: value?.name
                                });
                                this.setAddress(value);
                            }}
                        />
                    </Form.Item>

                    <AddMediaToItenary
                        initialMedia={media}
                        formRef={this.formRef}
                        onSaveItineraryMedia={(media) => this.setState({ media })}
                    />

                    {currentProduct && currentProduct.day_type === 'all_day' && (
                        <>
                            <div ref={this.durationInputEndRef} />
                            <h6 className="inpu .t-title">
                                What time period in the day can this product be booked?
                            </h6>
                            <Row>
                                <Col xs="auto">
                                    <Form.Item
                                        name="startTimeHours"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select start time'
                                            }
                                        ]}
                                        label="From">
                                        <TimePicker
                                            size="large"
                                            //defaultValue={0}
                                            use12Hours
                                            format="h:mm a"
                                            placeholder="12:00 am"
                                            value={
                                                start === null || start === ''
                                                    ? ''
                                                    : moment(start, 'h:mm a')
                                            }
                                            style={{
                                                height: '50px',
                                                width: '216px'
                                            }}
                                            onChange={(e, dateS) => {
                                                if (dateS) {
                                                    this.setState({ start: e.format('HH:mm') });
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs="auto">
                                    <Form.Item
                                        name="finishTimeHours"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select finish time'
                                            }
                                        ]}
                                        label="Until">
                                        <TimePicker
                                            size="large"
                                            //defaultValue={0}
                                            use12Hours
                                            format="h:mm a"
                                            placeholder="12:00 am"
                                            value={
                                                finish === null || finish === ''
                                                    ? ''
                                                    : moment(finish, 'h:mm a')
                                            }
                                            style={{
                                                height: '50px',
                                                width: '216px'
                                            }}
                                            onChange={(e, dateS) => {
                                                if (dateS) {
                                                    this.setState({ finish: e.format('HH:mm') });
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {this.showNextDayMessage() && (
                                <div style={{ marginTop: -12, marginBottom: '0.75rem' }}>
                                    <div className="time-suggested-tip">
                                        Note: Selected time period has moved into the next day!
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                    {package_detail && Object.keys(package_detail).length === 0 ? (
                        <BookingPricingForm
                            state={this.state}
                            setState={(objOrfunc) => this.setState(objOrfunc)}
                            formRef={this.formRef}
                            previousSelectedSubCategory={previousSelectedSubCategory}
                        />
                    ) : null}

                    {((previousSelectedCategory && previousSelectedCategory.id === 1) ||
                        (selected_existing_product &&
                            currentProduct?.productCategory?.id === 1)) && (
                        <AccomodationFormFields
                            state={this.state}
                            setState={(objOrfunc) => this.setState(objOrfunc)}
                        />
                    )}
                    {package_detail && Object.keys(package_detail).length === 0 && (
                        <Form.Item
                            name="maxDailyAvailability"
                            rules={[
                                {
                                    required: !maxDailyAvailability,
                                    message: 'Please fill this field in'
                                }
                            ]}>
                            <div>
                                <p>
                                    This will indicate the spaces available to travelers and once
                                    this is reached, this product will be marked as sold out for
                                    that day
                                </p>
                                <InputNumber
                                    className="input-number"
                                    value={maxDailyAvailability}
                                    min={1}
                                    onChange={(value) => {
                                        this.formRef.current.setFieldsValue({
                                            maxDailyAvailability: value
                                        });
                                        this.setState({ maxDailyAvailability: value });
                                    }}
                                />
                            </div>
                        </Form.Item>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Popover
                        trigger="focus"
                        content={
                            <div>
                                <h6 className="text-white">Please fill form correctly!</h6>
                                {
                                    <ol>
                                        {showValidationPopover &&
                                            Object.keys(showValidationPopover).length > 0 &&
                                            Object.keys(showValidationPopover).map((value, key) => (
                                                <li key={key}>{showValidationPopover[value]}</li>
                                            ))}
                                    </ol>
                                }
                            </div>
                        }
                        visible={showValidationPopover}>
                        <Button
                            disabled={this.props.loading}
                            color="primary"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="submit">
                            Update Calender & Continue
                            {this.props.loading && (
                                <Spinner className="ml-2" size="sm" color="light" />
                            )}
                        </Button>
                    </Popover>
                    <Button
                        color="dark"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        type="button"
                        onClick={() =>
                            this.props.setState({
                                isConfirmationModalOpen: true
                            })
                        }>
                        Cancel
                    </Button>
                    <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                </ModalFooter>
            </Form>
        );
    }
}

ItineraryFormPageOne.propTypes = {
    state: Proptypes.object,
    setState: Proptypes.func,
    onFinish: Proptypes.func,
    product_categories: Proptypes.array,
    itineraries: Proptypes.any,
    edit_itineraries: Proptypes.object,
    editItineraries_: Proptypes.func,
    updateItineraries: Proptypes.func,
    getItineraries: Proptypes.func,
    createItineraries_: Proptypes.func,
    resetCalenderReducer: Proptypes.func,
    currentProduct: Proptypes.object,
    selected_existing_product: Proptypes.bool,
    calendar_data: Proptypes.object,
    showUpdateProductFields: Proptypes.any,
    loading: Proptypes.bool,
    showValidationPopover: Proptypes.bool,
    package_detail: Proptypes.any
};

const mapStateToProps = (state) => ({
    product_categories: state.calendar.product_categories,
    edit_itineraries: state.contentDetail.edit_itineraries,
    itineraries: state.contentDetail.itineraries,
    selected_existing_product: state.product.selected_existing_product,
    calendar_data: state.calendar.calendar_data,
    update_calendar_product: state.calendar.update_calendar_product,
    package_detail: state.product.package_detail,
    showUpdateProductFields: state?.product?.showUpdateProductFields,
    loading: state.loading,
    currentProduct: state.product.currentProduct
});

const mapDispatch = (dispatch) => ({
    updateItineraries: (id, data) => dispatch(updateItineraries(id, data)),
    getItineraries: (data) => dispatch(getItineraries(data)),
    resetCalenderReducer: (data) => dispatch(resetCalenderReducer(data)),
    createItineraries_: (data) => dispatch(createItineraries_(data)),
    editItineraries_: (data) => dispatch(editItineraries_(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ItineraryFormPageOne));

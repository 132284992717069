import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { disableDate } from 'utils/index';
import moment from 'moment';

import { Button, Checkbox, DatePicker, TimePicker, InputNumber } from 'antd';
import WarningIcon from 'assets/images/warning-icon.svg';

import { updateProductAvailability } from 'store/actions';

const UpdateSlotsModal = (props) => {
    const {
        isOpen,
        toggleModal,
        id,
        spaces,
        date,
        update_availability_state,
        updateProductAvailability,
        refreshList
    } = props;
    const [spacesAvailable, setSpacesAvailable] = useState(spaces);
    const [specificDates, setSpecificDates] = useState(false);
    const [newDate, setNewDate] = useState(date);
    const [newStartTime, setNewStartTime] = useState(null);
    const [newEndTime, setNewEndTime] = useState(null);
    const format = 'YYYY-MM-DD';

    const handleSpecificSpaces = (value) => {
        return !isNaN(value) ? setSpacesAvailable(value) : null;
    };

    const onChange = (e) => {
        setSpecificDates(e.target.checked);
    };

    const handleDateChange = (_, valueString) => {
        return setNewDate(valueString);
    };

    const handleTimeChange = (_, valueString) => {
        const [startTime, endTime] = valueString;
        setNewStartTime(startTime);
        setNewEndTime(endTime);
    };

    const toggleUpdateModal = () => {
        toggleModal();
        setSpecificDates(false);
        setSpacesAvailable(spaces);
        setNewDate(date);
        setNewStartTime(null);
        setNewEndTime(null);
    };

    const submitUpdatedAvailabiltyData = () => {
        const payload = {
            spacesAvailable,
            ...(newDate && { dateQuery: newDate }),
            ...(newStartTime &&
                newEndTime && {
                    startTimeQuery: newStartTime,
                    endTimeQuery: newEndTime
                })
        };
        updateProductAvailability(id, payload).then(() => {
            refreshList();
            toggleUpdateModal();
        });
    };

    const disableCalendar = !spacesAvailable;

    useEffect(() => {
        if (update_availability_state) {
            return toggleUpdateModal();
        }
    }, [update_availability_state]);

    useEffect(() => {
        if (spaces) {
            setSpacesAvailable(spaces);
        }
    }, [spaces]);

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleUpdateModal}>
            <ModalBody className="text-center">
                <img src={WarningIcon} alt="" />
                <div className="modal-body-title-lg mt-2">
                    {/* modal title */}
                    Update?
                </div>
                <div className="modal-body-text">
                    {/* modal description */}
                    <p className="m-0 my-3">This will only reflect for today - {newDate}</p>

                    <div className="d-flex align-items-center justify-content-center">
                        <InputNumber
                            placeholder="Enter number of spaces available"
                            id="available_spaces"
                            name="available_spaces"
                            className="num-input num-input--large num-input--full-width"
                            defaultValue={spacesAvailable}
                            onChange={handleSpecificSpaces}
                        />
                    </div>

                    <div className="mt-4 d-flex flex-column align-items-center">
                        <Checkbox className="d-flex align-items-center" onChange={onChange}>
                            <span style={{ fontSize: '14px' }}>
                                Change Date and Time duration of Availability
                            </span>
                        </Checkbox>
                        {specificDates && (
                            <div
                                className="d-flex align-items-center flex-wrap date-filter__modal"
                                style={{ gap: '10px' }}>
                                <DatePicker
                                    disabledDate={disableDate}
                                    className="my-3"
                                    format={format}
                                    defaultValue={moment(newDate, format)}
                                    onChange={handleDateChange}
                                />
                                <div className="d-flex align-items-center justify-content-center">
                                    <TimePicker.RangePicker
                                        format={'HH:mm'}
                                        placeholder={['Start', 'End']}
                                        onChange={handleTimeChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4 d-flex flex-wrap justify-content-center modal__button">
                    {/* modal buttons */}
                    <>
                        <Button
                            id="pauseSaleTooltip"
                            type="primary"
                            className="btn-mw mx-2"
                            disabled={disableCalendar}
                            onClick={() => submitUpdatedAvailabiltyData()}>
                            UPDATE
                        </Button>
                        <Button
                            type="dark"
                            className="btn-mw mx-2"
                            onClick={() => toggleUpdateModal()}>
                            CANCEL
                        </Button>
                    </>
                </div>
            </ModalBody>
        </Modal>
    );
};
UpdateSlotsModal.propTypes = {
    loading: PropTypes.bool,
    id: PropTypes.any,
    spaces: PropTypes.number,
    date: PropTypes.string,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    update_product_availability: PropTypes.any,
    update_availability_state: PropTypes.bool,
    updateProductAvailability: PropTypes.func,
    refreshList: PropTypes.func
};
const mapStateToProps = (state) => ({
    loading: state.loading,
    update_product_availability: state.availability.update_product_availability
});

const mapDispatch = (dispatch) => ({
    updateProductAvailability: (id, data) => dispatch(updateProductAvailability(id, data))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(UpdateSlotsModal));

export default {
    title: {
        display: 'flex'
    },
    cell: {
        border: 'none',
        display: 'flex',
        fontSize: '14px'
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        width: '68px',
        height: '42px'
    },
    input: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '68px',
        height: '48px',
        fontSize: '16px',
        textAlign: 'center'
    },
    inputUsd: {
        border: '0.5px solid #C4C4C4',
        borderLeft: 0
    }
};

/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Form, Drawer, Radio } from 'antd';
import { Button, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';

import './index.scss';
import Spacer from '../../../atoms/spacer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Gallery from '../../gallery';
import ImportItineraries from '../../../atoms/importItineraries';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { getSightCategories } from 'store/actions/calendarActions';
import { Col } from 'reactstrap/lib';
import { ArrowDown } from 'assets/index';
import { topSightCategoryIcons, compareTopSights } from 'utils/helper';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};

class AddTopSights extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            addedTopSights: [],
            isOpenGallery: false,
            rawTopSight: {},
            toggledDropdownIndex: null,
            showFrequency: false
        };
    }

    componentDidMount() {
        const { data, sightCategories, getSightCategories } = this.props;
        if (Array.isArray(data)) this.setState({ addedTopSights: data });

        if (Array.isArray(sightCategories) && sightCategories.length < 1) getSightCategories();
    }
    componentDidUpdate(prevProps, prevState) {
        const { data } = this.props;
        if (prevProps.data !== data && data !== null) this.setState({ addedTopSights: data });

        if (
            this.formRef.current &&
            Array.isArray(prevState.addedTopSights) &&
            Array.isArray(this.state.addedTopSights) &&
            prevState.addedTopSights.length !== this.state.addedTopSights.length
        )
            this.formRef.current.validateFields();
    }

    onClose = () => this.props.onClose();

    onOpenGallery = () => this.setState({ isOpenGallery: !this.state.isOpenGallery });

    //saving media animal
    onSaveMedia = (media) => {
        const { rawTopSight } = this.state;
        if ((Array.isArray(media), media.length > 0)) {
            const temp = { ...rawTopSight, image_url: media[0].url };
            this.setState((prevState) => {
                return {
                    addedTopSights: [...prevState.addedTopSights, temp],
                    isOpenGallery: false,
                    rawTopSight: {}
                };
            });
        } else this.setState({ isOpenGallery: false, rawTopSight: {} });
    };

    onSave = () => {
        this.props.onSave(this.state.addedTopSights);
    };

    onAddCategory = ({ category, sub_category }) => {
        const index = this.isCategorySubcategorySelected({ category, sub_category });
        if (index > -1) this.removeTopSight({ category, sub_category });
        //removing if already selected
        else {
            // if (sub_category)
            this.setState({ rawTopSight: { category, sub_category }, showFrequency: true }); //before frequecy only worked
            //adding to temp if it's dropdown
            // else
            //     this.setState((prevState) => {
            //         //adding directly to state
            //         return {
            //             addedTopSights: [...prevState.addedTopSights, { category }]
            //         };
            //     });
        }
    };

    removeTopSight = ({ category, sub_category }) => {
        const { addedTopSights } = this.state;

        const index = this.isCategorySubcategorySelected({ category, sub_category });

        if (index > -1) {
            addedTopSights.splice(index, 1);
            this.setState({ addedTopSights: addedTopSights });
        }
    };

    isCategorySubcategorySelected = ({ category, sub_category }) => {
        const { addedTopSights } = this.state;
        return addedTopSights.findIndex((res) => {
            if (res.sub_category && sub_category) {
                return (
                    res.category?.toLocaleLowerCase() === category?.toLocaleLowerCase() &&
                    res.sub_category?.toLocaleLowerCase() === sub_category?.toLocaleLowerCase()
                );
            } else {
                if (res.category && category)
                    return res.category?.toLocaleLowerCase() === category?.toLocaleLowerCase();
                else return false;
            }
        });
    };

    toggleDropDown = (i) =>
        this.setState((prevState) => {
            return {
                toggledDropdownIndex: prevState.toggledDropdownIndex === null ? i : null
            };
        });

    //select frequency animal
    handleChangeFrequency = (e) =>
        this.setState((prevState) => {
            return {
                rawTopSight: { ...prevState.rawTopSight, frequency: e.target.value },
                isOpenGallery: true,
                showFrequency: false
            };
        });

    onFinish = () => {
        const { selected_existing_product, data } = this.props;
        if (selected_existing_product && data)
            if (compareTopSights(data, this.state.addedTopSights))
                this.setState({ confirmationModal: true });
            else this.onClose();
        else this.onSave();
    };

    render() {
        const {
            requiredMark,
            isOpenGallery,
            rawTopSight,
            toggledDropdownIndex,
            showFrequency,
            addedTopSights
        } = this.state;
        const { validateRequiredFormField, sightCategories } = this.props;

        return (
            <div className="add-top-sights-drawer">
                <Drawer
                    contentWrapperStyle={{ overflowX: 'hidden' }}
                    className="application add-top-sights"
                    onClose={() => {
                        this.onClose();
                    }}
                    visible={this.props.isOpen}>
                    <Form
                        ref={this.formRef}
                        onFinish={this.onFinish}
                        layout="vertical"
                        initialValues={{
                            requiredMark
                        }}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}
                        className="h-100 d-flex flex-column overflow-y-auto">
                        <h6 className="title">Add top sights to see in this product</h6>
                        <div className="flex-grow-1 overflow-y-auto overflow-x-hidden pr-3 scrollbar-fixed">
                            {this.props.hideImport ? (
                                ''
                            ) : (
                                <ImportItineraries
                                    calendars={this.props.itineraries}
                                    import="I will import top sights information from my other activity"
                                    onMainProduct={(item) =>
                                        this.setState({ addedTopSights: item?.sights || [] })
                                    }
                                />
                            )}

                            <Spacer top={13} />

                            <p className="subtitle">
                                Select sights your customers will see on this product. Please note
                                that this is what your customers will search for when booking your
                                product.
                            </p>

                            <div>Top Sights</div>

                            <Spacer top={14} />
                            <Form.Item
                                name="addedTopSights"
                                rules={[
                                    {
                                        validator: () =>
                                            validateRequiredFormField({
                                                data: this.state.addedTopSights,
                                                fieldType: 'ARRAY'
                                            })
                                    }
                                ]}>
                                <Row noGutters style={{ gap: '0.5rem' }}>
                                    {Array.isArray(sightCategories) &&
                                        sightCategories.map((res, i) =>
                                            Array.isArray(res.children) && res.children < 1 ? (
                                                <Col xs="auto" key={i}>
                                                    <div
                                                        className={`sights cursor-pointer unselectable ${
                                                            this.isCategorySubcategorySelected({
                                                                category: res.name
                                                            }) > -1
                                                                ? 'active-sights'
                                                                : ''
                                                        }`}
                                                        onClick={() =>
                                                            this.onAddCategory({
                                                                category: res.name
                                                            })
                                                        }>
                                                        <img
                                                            src={topSightCategoryIcons[res.name]}
                                                            alt=""
                                                        />
                                                        {res.name}
                                                    </div>
                                                </Col>
                                            ) : (
                                                <Dropdown
                                                    key={i}
                                                    isOpen={toggledDropdownIndex === i}
                                                    toggle={() => this.toggleDropDown(i)}>
                                                    {/* Toggle dropdown */}
                                                    <DropdownToggle
                                                        style={{
                                                            backgroundColor:
                                                                this.isCategorySubcategorySelected({
                                                                    category: res.name
                                                                }) > -1
                                                                    ? '#E7D99F'
                                                                    : 'white'
                                                        }}
                                                        className={`sights cursor-pointer unselectable ${
                                                            this.isCategorySubcategorySelected({
                                                                category: res.name
                                                            }) > -1
                                                                ? 'active-sights'
                                                                : ''
                                                        }`}>
                                                        <div>
                                                            <img
                                                                src={
                                                                    topSightCategoryIcons[res.name]
                                                                }
                                                                alt=""
                                                            />
                                                            {res.name}
                                                        </div>
                                                        <img
                                                            src={ArrowDown}
                                                            alt=""
                                                            style={{ width: 12 }}
                                                        />
                                                    </DropdownToggle>

                                                    <DropdownMenu
                                                    // style={{ width: '517px' }}
                                                    // className="category-menu"
                                                    >
                                                        <div className="animal-row">
                                                            {Array.isArray(res.children) &&
                                                            res.children.length > 0
                                                                ? res.children.map((item, j) => (
                                                                      <div key={j}>
                                                                          <div
                                                                              className={`sights cursor-pointer unselectable ${
                                                                                  this.isCategorySubcategorySelected(
                                                                                      {
                                                                                          category:
                                                                                              res.name,
                                                                                          sub_category:
                                                                                              item.name
                                                                                      }
                                                                                  ) !== -1
                                                                                      ? 'active-sights-animal'
                                                                                      : ''
                                                                              }`}
                                                                              id="animal"
                                                                              onClick={() =>
                                                                                  this.onAddCategory(
                                                                                      {
                                                                                          category:
                                                                                              res.name,
                                                                                          sub_category:
                                                                                              item.name
                                                                                      }
                                                                                  )
                                                                              }>
                                                                              <img
                                                                                  src={
                                                                                      topSightCategoryIcons[
                                                                                          item.name
                                                                                      ]
                                                                                  }
                                                                                  alt=""
                                                                              />
                                                                              {item.name}
                                                                          </div>
                                                                      </div>
                                                                  ))
                                                                : 'Nothing'}
                                                        </div>
                                                    </DropdownMenu>
                                                </Dropdown>
                                            )
                                        )}
                                </Row>
                            </Form.Item>

                            <div className="fs-16 mb-2">Top Sights added</div>
                            <div>
                                <Row
                                    className="row-cols-2 row-cols-md-3 scrollbar-fixed"
                                    style={{ maxHeight: 200, overflowY: 'auto' }}>
                                    {Array.isArray(addedTopSights) &&
                                        addedTopSights.map((value, ind) => {
                                            return (
                                                <Col className="mb-3" key={ind}>
                                                    <div className="top-sight-item">
                                                        <div className="sight-action top-right-action">
                                                            <button
                                                                className="btn btn-white btn-circle"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    this.removeTopSight({
                                                                        category: value.category,
                                                                        sub_category:
                                                                            value?.sub_category
                                                                    });
                                                                }}>
                                                                <img
                                                                    width={20}
                                                                    src={require('../../../../assets/images/close.svg')}
                                                                    alt="Close"
                                                                />
                                                            </button>
                                                        </div>
                                                        <img
                                                            className="top-sight-img"
                                                            src={value.image_url}
                                                            alt=""
                                                        />
                                                        <div className="sight-action bottom-left-action">
                                                            <div className="sight-frequency-wrapper">
                                                                <div>
                                                                    <img
                                                                        className="sight-frequency-icon"
                                                                        width={15}
                                                                        src={value.image_url}
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="flex-grow-1">
                                                                    <div className="sight-frequency-title">
                                                                        {value?.sub_category
                                                                            ? value?.sub_category
                                                                            : value?.category}
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <div
                                                                        className={`sight-frequency-badge __${`${value?.frequency}`
                                                                            .toLowerCase()
                                                                            .replace(
                                                                                / /g,
                                                                                '_'
                                                                            )}`}></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            );
                                        })}
                                </Row>
                            </div>
                        </div>

                        <div>
                            <div className="pt-3">
                                <Button className="btn-mw" color="primary">
                                    Save
                                </Button>
                                <Button
                                    className="btn-mw"
                                    color="dark"
                                    style={{ marginLeft: '10px' }}
                                    onClick={() => this.onClose()}>
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </Form>

                    <ConfirmationModal
                        isModalOpen={this.state.confirmationModal}
                        setModalOpenState={(value) =>
                            this.setState({
                                confirmationModal: value
                            })
                        }
                        description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                        handleConfirmation={() => {
                            this.setState({ confirmationModal: false });
                            this.onSave();
                        }}
                    />

                    {showFrequency && (
                        <div className="frequency-select-wrapper">
                            <div
                                className="frequency-select"
                                style={{
                                    visibility: 'visible'
                                }}>
                                <span
                                    className="float-right p-2"
                                    onClick={() =>
                                        this.setState({
                                            rawTopSight: {},
                                            showFrequency: false
                                        })
                                    }>
                                    <img
                                        alt="close-icon"
                                        width="16"
                                        src={require('../../../../assets/images/close.svg')}
                                    />
                                </span>
                                <div className="frequency-title">Select Frequency</div>
                                <Radio.Group
                                    className="frequency-btn"
                                    onChange={this.handleChangeFrequency}
                                    value={rawTopSight.frequency}>
                                    <Radio style={radioStyle} value={'Common'}>
                                        <span className={`frequency-btn__common`}>Common</span>
                                    </Radio>
                                    <Radio style={radioStyle} value={'Occasional'}>
                                        <span className={`frequency-btn__occasional`}>
                                            Occassional
                                        </span>
                                    </Radio>
                                    <Radio style={radioStyle} value={'Rare'}>
                                        <span className={`frequency-btn__rare`}>Rare</span>
                                    </Radio>
                                </Radio.Group>
                            </div>
                            <div
                                className="frequency-select-overlay"
                                onClick={() =>
                                    this.setState({
                                        rawTopSight: {},
                                        showFrequency: false
                                    })
                                }></div>
                        </div>
                    )}
                </Drawer>

                <Gallery
                    showModal={isOpenGallery}
                    onClose={() => {
                        this.onOpenGallery();
                        this.setState({ rawTopSight: {} });
                    }}
                    media={[]}
                    selectMediaList={[]}
                    onSaveMedia={this.onSaveMedia}
                    classNameTitle="add-top-sights-title"
                    title="Show travellers what they are going to see"
                    subTitle="Recommended:Travelers love to see pictures of what to expect."
                    selectMultiple={false}
                />
            </div>
        );
    }
}
AddTopSights.propTypes = {
    data: Proptypes.any,
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    hideImport: Proptypes.bool,
    validateRequiredFormField: Proptypes.func,
    selected_existing_product: Proptypes.bool,
    sightCategories: Proptypes.array,
    getSightCategories: Proptypes.func
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product,
    sightCategories: state.calendar.sightCategories
});

const mapDispatch = (dispatch) => ({
    getSightCategories: () => dispatch(getSightCategories())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddTopSights));

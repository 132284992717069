/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import './index.scss';

import { Checkbox, Form, InputNumber, Radio, Tooltip } from 'antd';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import AddExtra from './extra-information';
import TicketBuilder from './ticket-builder';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import WarningIcon from '../../../../assets/images/alert.svg';
import BulbIcon from '../../../../assets/images/bulb.svg';
import Spacer from '../../../atoms/spacer';
import ImportAccomodation from '../../../atoms/import-accomodation';
import AddExtraButton from '../../../atoms/add-extra';
import AddTicketButton from '../../../atoms/add-ticket';
import Proptypes from 'prop-types';
import { updateMainProduct } from '../../../../store/actions/contentActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ConfirmationModal from '../../modals/confirmation-modal';
import {
    mapTravellerInformation,
    cancellationPolicyDetail,
    compareBookingAndTickets
} from 'utils/helper';
import ChipInput from 'material-ui-chip-input';

import MoreInfo from 'assets/images/more_info.svg';

const period = ['Minutes', 'Hours', 'Days', 'Weeks'];
const beforeTravel = ['Before travel', 'Before activity starts'];
const radioStyle = {
    display: 'flex'
};

function BookingTickets(props) {
    const [form] = Form.useForm();

    const [isOpenPeriod, setIsOpenPeriod] = useState(false);
    const [isOpenBeforeTravel, setIsOpenBeforeTravel] = useState(false);
    const [isOpenExtra, setIsOpenExtra] = useState(false);
    const [isOpenTicketBuilder, setIsOpenTicketBuilder] = useState(false);
    const [isNotification, setIsNotification] = useState(false);
    // const [isBadWeather, setIsBadWeather] = useState(false);
    const [cancellationPolicy, setCancellationPolicy] = useState('standard');
    const [selectedPeriod, setSelectedPeriod] = useState('Days');
    const [time, setTime] = useState(0);
    const [ticket, setTicket] = useState(null);
    const [extraInfo, setExtraInfo] = useState([]);
    const [selectedBeforeTravel, setSelectedBeforeTravel] = useState('Before travel');
    const toggleDays = () => setIsOpenPeriod(!isOpenPeriod);
    const toggleBeforeTravel = () => setIsOpenBeforeTravel(!isOpenBeforeTravel);
    const changeOpenExtra = () => setIsOpenExtra(!isOpenExtra);
    const changeOpenTicketBuilder = () => setIsOpenTicketBuilder(!isOpenTicketBuilder);

    const [confirmationModal, setConfirmationModal] = useState(false);

    const [processRefunds, setProcessRefunds] = useState(false);
    const [confirmRefunds, setConfirmRefunds] = useState(false);
    const [chips, setChips] = useState(['Bad Weather']);
    // new custom variables
    const [customDuration, setCustomDuration] = useState(0);
    const [isOpenDurationType, setIsOpenDurationType] = useState(false);
    const toggleDurationType = () => setIsOpenDurationType(!isOpenDurationType);
    const [selectedDurationType, setSelectedDurationType] = useState('Days');
    const [selectedRefundTypes, setSelectedRefundTypes] = useState(0);

    const handleProcessRefunds = (e) => {
        setProcessRefunds(e.target.value);
    };

    const handleConfirmRefunds = (e) => {
        setConfirmRefunds(e.target.value);
    };

    useEffect(() => {
        return !processRefunds ? setConfirmRefunds(false) : '';
    }, [processRefunds]);

    useEffect(() => {
        if (props.data) {
            setIsNotification(props.data.receive_notification || false);
            setCancellationPolicy(props.data?.policy?.type || 'standard');
            setExtraInfo(mapTravellerInformation(props.data.traveler_information) || []);
            setTicket(props.data.ticket_setup || null);
            setTime(props.data?.cut_off?.time || 0);
            form.setFields([{ name: 'cutOffTime', value: props.data?.cut_off?.time || 0 }]);
            setSelectedBeforeTravel(props.data?.cut_off?.type || 'Before travel');
            setSelectedPeriod(props.data?.cut_off?.time_type || 'Minutes');
            setChips(props.data?.custom_policy || []);
            if (props.data?.policy?.type == 'custom' && props.data?.policy?.content) {
                setCustomDuration(Number(props.data?.policy?.content?.duration) || 0);
                setSelectedDurationType(props.data?.policy?.content?.duration_type || 'Days');
                setSelectedRefundTypes(
                    setRefundTypes(props.data?.policy?.content?.refund_type) || 0
                );
                form.setFields([
                    {
                        name: 'customDuration',
                        value: Number(props.data?.policy?.content?.duration) || 0
                    }
                ]);
            }
        }
    }, [props.data]);

    const onFinish = () => {
        const { selected_existing_product, data } = props;

        form.validateFields()
            .then(() => {
                if (selected_existing_product && data)
                    if (
                        compareBookingAndTickets(data, {
                            extraInfo,
                            ticket,
                            selectedBeforeTravel,
                            time,
                            selectedPeriod,
                            isNotification,
                            cancellationPolicy,
                            chips,
                            customDuration,
                            selectedDurationType,
                            refund_type: `${selectedRefundTypes}%`
                        })
                    )
                        return setConfirmationModal(true);
                    else return props.onClose();
                else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };
    const setRefundTypes = (initialVal) => {
        if (initialVal) {
            let newVal = initialVal.toString().replace('%', '');
            form.setFields([
                {
                    name: 'refundTypeField',
                    value: Number(newVal) || 0
                }
            ]);
            return newVal;
        } else {
            return false;
        }
    };
    const onSave = () => {
        form.validateFields()
            .then(() => {
                let policyData = {
                    type: cancellationPolicy
                };
                if (cancellationPolicy == 'custom') {
                    policyData['content'] = {
                        refund_type: `${selectedRefundTypes}%`,
                        duration: customDuration,
                        duration_type: selectedDurationType
                    };
                }
                const tmpData = {
                    cut_off: {
                        time: time,
                        type: selectedBeforeTravel,
                        time_type: selectedPeriod
                    },
                    send_email: isNotification,
                    customer_can_process_refund: processRefunds,
                    confirm_before_refund_is_processed: confirmRefunds,
                    policy: policyData,
                    custom_policy: chips,
                    ticket_setup: ticket
                };
                let traveler_information = {
                    full_name: false,
                    weights: false,
                    heights: false,
                    date_of_birth: false,
                    passport_details: false,
                    first_name: true,
                    email: true
                };
                for (let value of extraInfo) {
                    traveler_information[value.replaceAll(' ', '_').toLowerCase()] = true;
                }
                tmpData.traveler_information = traveler_information;

                if (!props.isAddOnProduct && props.calendar_data && props.calendar_data.data) {
                    const selectCalendar = window.location.pathname.substring(
                        window.location.pathname.lastIndexOf('/') + 1
                    );
                    let tmp = props.calendar_data.data.find(
                        (res) => parseInt(selectCalendar) === res.id
                    );
                    if (tmp?.mainProduct) {
                        props.updateMainProduct(tmp.mainProduct.id, {
                            booking_ticket_details: tmpData
                        });
                        onClose();
                    }
                }
                props.onSave(tmpData);
                // onClose();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };
    const onClear = () => {
        form.resetFields();
        setIsOpenPeriod(false);
        setIsOpenBeforeTravel(false);
        setIsOpenExtra(false);
        setIsOpenTicketBuilder(false);
        setIsNotification(false);
        setProcessRefunds(false);
        setConfirmRefunds(false);
        setChips([]);
        setCancellationPolicy('standard');
        setSelectedPeriod('Days');
        setTime(0);
        setTicket(null);
        setExtraInfo([]);
        setSelectedBeforeTravel('Before travel');
        setCustomDuration(0);
        setIsOpenDurationType(false);
        setSelectedDurationType('Days');
        setSelectedRefundTypes(0);
    };
    const onClose = () => {
        props.onClose();
        onClear();
    };

    function handleAddChip(value) {
        let rawChips = [...chips];
        if (!chips.includes(value)) {
            rawChips.push(value);
            setChips(rawChips);
        }
    }

    function handleDeleteChip(index) {
        let rawChips = [...chips];
        rawChips.splice(index, 1);
        setChips(rawChips);
    }

    return (
        <Modal isOpen={props.isOpen} centered size="lg">
            <Form
                form={form}
                layout="vertical"
                requiredMark={false}
                name="booking-tickets-form"
                className="booking-tickets">
                <ModalHeader className="title-pricing">
                    Add Booking and Ticketing details for your {props.title}
                    {props.hideImport ? (
                        ''
                    ) : (
                        <ImportAccomodation
                            calendars={props.calendarData}
                            onMainProduct={() => {}}
                            import="I will import booking and ticketing information from my other activity"
                        />
                    )}
                </ModalHeader>
                <ModalBody className="body-modal">
                    <Spacer top={19} />
                    <div style={{ fontWeight: 500 }}>Set your cut-off time</div>
                    <Spacer top={15} />
                    <Row>
                        <Form.Item
                            name={`cutOffTime`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill'
                                }
                            ]}>
                            <InputNumber
                                className="allowed-number"
                                min={0}
                                value={time}
                                placeholder={'0'}
                                onChange={(value) => setTime(value)}
                                style={{ marginRight: 46 }}
                            />
                        </Form.Item>

                        <Dropdown
                            style={{ marginRight: 46 }}
                            isOpen={isOpenPeriod}
                            toggle={toggleDays}>
                            <DropdownToggle className="dropdown-category" caret>
                                {selectedPeriod}
                            </DropdownToggle>
                            <DropdownMenu>
                                {period.map((res, i) => (
                                    <DropdownItem
                                        key={i}
                                        onClick={() => setSelectedPeriod(res)}
                                        className={`${
                                            selectedPeriod === res
                                                ? 'active-category'
                                                : 'category-item'
                                        }`}>
                                        {res}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>

                        <Dropdown isOpen={isOpenBeforeTravel} toggle={toggleBeforeTravel}>
                            <DropdownToggle
                                style={{ width: '331px' }}
                                className="dropdown-category"
                                caret>
                                {selectedBeforeTravel}
                            </DropdownToggle>
                            <DropdownMenu style={{ width: '331px' }}>
                                {beforeTravel.map((res, i) => (
                                    <DropdownItem
                                        key={i}
                                        onClick={() => setSelectedBeforeTravel(res)}
                                        className={`${
                                            selectedBeforeTravel === res
                                                ? 'active-category'
                                                : 'category-item'
                                        }`}>
                                        {res}
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Row>
                    <Spacer top={30} />
                    <Row>
                        <Col sm={2} className="warning-col">
                            <img src={WarningIcon} alt="" />
                        </Col>
                        <Col sm={10} className="time-description">
                            All bookings will be confirmed automatically, however you have 24 hours
                            to manually reject confirmed bookings. This saves you time and gives
                            your customers a better experience. Plus, your product gets more
                            exposure because we can distribute it on all customers calendars.
                            <br />
                            <br />
                            Notification for each booking will appear on your dashboard and you can
                            choose whether you want to receive email notifications each time a
                            booking is made.
                        </Col>
                    </Row>
                    <Spacer top={20} />
                    <Checkbox
                        checked={isNotification}
                        onChange={() => setIsNotification(!isNotification)}>
                        Send us a notification email for each booking
                    </Checkbox>
                    {/* <hr width="100%" /> */}
                    <div>
                        <div className="mt-3 refunds--radio-options">
                            <p className="m-0 mb-2">Would you like customers to process refunds?</p>
                            <Radio.Group
                                name="processRefunds"
                                value={processRefunds}
                                onChange={handleProcessRefunds}>
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                            </Radio.Group>
                        </div>
                        {processRefunds && (
                            <div className="mt-3 refunds--radio-options">
                                <p className="m-0 mb-2">
                                    Would you like to confirm refunds first before it is processed?
                                </p>
                                <Radio.Group
                                    name="confirmRefunds"
                                    value={confirmRefunds}
                                    onChange={handleConfirmRefunds}>
                                    <Radio value={true}>Yes</Radio>
                                    <Radio value={false}>No</Radio>
                                </Radio.Group>
                            </div>
                        )}
                        {confirmRefunds && (
                            <p
                                className="m-0 mt-3 font-weight-bold font-italic"
                                style={{ fontSize: '14px', maxWidth: '600px' }}>
                                {' '}
                                Please Note: If refunds are not confirmed by you after 7 days, the
                                system will automatically process the refunds for the customers
                            </p>
                        )}
                    </div>

                    <hr width="100%" />

                    <div className="policy-title">Your cancellation policy</div>
                    {/* <div className="policy-link">
                        Your cancellation policy <Button color="link">Learn more</Button>
                    </div> */}
                    <Spacer top={15} />
                    <Row className="policy-info">
                        <Col sm={1}>
                            <img src={BulbIcon} alt="" />
                        </Col>
                        <Col sm={11}>
                            Travelers want flexibility, so we recommend the Standard Cancellation
                            for most products.
                        </Col>
                    </Row>

                    <hr width="100%" />

                    <div className="select-type-booking">
                        <Radio.Group
                            value={cancellationPolicy}
                            onChange={(e) => {
                                setCancellationPolicy(e.target.value);
                            }}>
                            <Radio style={radioStyle} value={'standard'}>
                                <h6 style={{ marginTop: 3 }}>
                                    Standard <div className="recommended">RECOMMENDED</div>
                                </h6>
                                <p>{cancellationPolicyDetail('standard')}</p>
                            </Radio>
                            <Radio style={radioStyle} value={'moderate'}>
                                <h6 style={{ marginTop: 3 }}>Moderate</h6>
                                <p>{cancellationPolicyDetail('moderate')}</p>
                            </Radio>
                            <Radio style={radioStyle} value={'strict'}>
                                <h6 style={{ marginTop: 3 }}>Strict</h6>
                                <p>{cancellationPolicyDetail('strict')}</p>
                            </Radio>
                            <Radio style={radioStyle} value={'custom'}>
                                <h6 style={{ marginTop: 3 }}>
                                    Custom
                                    <Tooltip
                                        title={
                                            <div className="text-center">
                                                {
                                                    'This allows you to specify how long a customer can initiate a cancellation request and what percentage of refunds would be made available to customers'
                                                }
                                            </div>
                                        }>
                                        <sup>
                                            <img src={MoreInfo} alt="" />
                                        </sup>
                                    </Tooltip>
                                </h6>
                                <p>{cancellationPolicyDetail('custom')}</p>

                                {cancellationPolicy == 'custom' && (
                                    <>
                                        <Row>
                                            <div>
                                                <div className="ant-col ant-form-item-label">
                                                    <label
                                                        htmlFor="booking-tickets-form_customDuration"
                                                        className="ant-form-item-required"
                                                        title="Duration of cancellation">
                                                        Duration of cancellation
                                                    </label>
                                                </div>
                                                <div className="d-flex">
                                                    <Form.Item
                                                        name={`customDuration`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please fill'
                                                            }
                                                        ]}
                                                        style={{ marginRight: 20 }}>
                                                        <InputNumber
                                                            className="allowed-number"
                                                            min={0}
                                                            value={customDuration}
                                                            placeholder={'0'}
                                                            onChange={(value) =>
                                                                setCustomDuration(value)
                                                            }
                                                        />
                                                    </Form.Item>

                                                    <Form.Item
                                                        name={`durationTypeField`}
                                                        style={{ marginRight: 20 }}>
                                                        <Dropdown
                                                            isOpen={isOpenDurationType}
                                                            toggle={toggleDurationType}>
                                                            <DropdownToggle
                                                                className="dropdown-category"
                                                                caret>
                                                                {selectedDurationType}
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                {period.slice(1).map((res, i) => (
                                                                    <DropdownItem
                                                                        key={i}
                                                                        onClick={() =>
                                                                            setSelectedDurationType(
                                                                                res
                                                                            )
                                                                        }
                                                                        className={`${
                                                                            selectedDurationType ===
                                                                            res
                                                                                ? 'active-category'
                                                                                : 'category-item'
                                                                        }`}>
                                                                        {res}
                                                                    </DropdownItem>
                                                                ))}
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    </Form.Item>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="ant-col ant-form-item-label">
                                                    <label
                                                        className="ant-form-item-required"
                                                        title="Refund percentage">
                                                        Refund percentage
                                                    </label>
                                                </div>
                                                <div className="d-flex gap-2">
                                                    <Form.Item
                                                        name={`refundTypeField`}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please fill'
                                                            }
                                                        ]}>
                                                        <InputNumber
                                                            className="allowed-number"
                                                            placeholder={'0'}
                                                            min={0}
                                                            max={100}
                                                            value={selectedRefundTypes}
                                                            onChange={(value) => {
                                                                setSelectedRefundTypes(value);
                                                            }}
                                                        />
                                                    </Form.Item>
                                                    <div>
                                                        <div className="fs-20 fw-600 mt-2">%</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </>
                                )}
                            </Radio>
                        </Radio.Group>
                    </div>

                    <hr width="100%" />

                    <div>
                        <div style={{ display: 'flex' }}>
                            Add to your policy <div className="optional-hover-text">(optional)</div>
                        </div>
                        <div className="description-text">
                            {
                                "You may reserve the right to cancel a customer's booking for a full refund in case of:"
                            }
                        </div>

                        <ChipInput
                            value={chips}
                            onAdd={(chip) => handleAddChip(chip)}
                            onDelete={(chip, index) => handleDeleteChip(index)}
                            placeholder="Type and press Enter to add keyword"
                            style={{ minWidth: 300 }}
                        />
                    </div>

                    <hr width="100%" style={{ marginBottom: 30 }} />

                    <div>
                        <AddExtraButton
                            data={extraInfo}
                            onDelete={() => setExtraInfo([])}
                            name="Traveler's required information"
                            add="Add extra info"
                            onClick={changeOpenExtra}
                            infoText={`These are the personal information you would like the customer to provide you with`}
                        />
                        <Form.Item
                            name={`ticket`}
                            rules={[
                                {
                                    required: ticket ? false : true,
                                    message: 'Please fill'
                                }
                            ]}>
                            <AddTicketButton
                                name="Ticket Builder (powered by TravelJinni)"
                                add="Setup your tickets"
                                data={ticket}
                                onDelete={() => setTicket([])}
                                moreInfo={false}
                                onClick={changeOpenTicketBuilder}
                            />
                        </Form.Item>
                    </div>

                    <AddExtra
                        isOpen={isOpenExtra}
                        onClose={changeOpenExtra}
                        data={extraInfo}
                        onSave={(value) => {
                            setExtraInfo(value);
                            setIsOpenExtra(false);
                            // form.validateFields(['extraInfo']);
                        }}
                    />
                    <TicketBuilder
                        isOpen={isOpenTicketBuilder}
                        onSave={(value) => {
                            setTicket(value);
                            setIsOpenTicketBuilder(false);
                            form.validateFields(['ticket']);
                        }}
                        onClose={changeOpenTicketBuilder}
                        ticketData={props.ticketData}
                        ticketDataActivity={props.ticketDataActivity}
                        data={ticket}
                        cancellationPolicy={cancellationPolicyDetail(cancellationPolicy)}
                    />
                </ModalBody>
                <ModalFooter>
                    <div className="footer-btn">
                        <div>
                            <Button color="primary" onClick={onFinish}>
                                {props.submitButtonName ?? 'Update Calender & Continue'}
                            </Button>
                            <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark" style={{ width: '137px' }}>
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </ModalFooter>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    onSave();
                    setConfirmationModal(false);
                }}
            />
        </Modal>
    );
}
BookingTickets.propTypes = {
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    isOpen: Proptypes.bool,
    updateMainProduct: Proptypes.func,
    calendarData: Proptypes.object,
    calendar_data: Proptypes.object,
    create_calendar: Proptypes.object,
    data: Proptypes.any,
    hideImport: Proptypes.bool,
    isAddOnProduct: Proptypes.bool,
    submitButtonName: Proptypes.string,
    ticketData: Proptypes.object,
    ticketDataActivity: Proptypes.object,
    selected_existing_product: Proptypes.bool,
    title: Proptypes.string
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    create_calendar: state.calendar.create_calendar,
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = (dispatch) => ({
    updateMainProduct: (id, data) => dispatch(updateMainProduct(id, data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(BookingTickets));

import React, { useState, useEffect, useRef } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { CalendarEventContent, CalendarActiveDaySchedule } from 'components/atoms';
import {
    ActivityPanelComponent,
    ProductDetailsDrawer,
    CalendarEventDetailContent
} from 'components/molecules';

import moment from 'moment';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { connect } from 'react-redux';
import {
    getAllCalendarProducts,
    getCalendarsById,
    getCalendarsById_,
    setCurrentProduct_
} from 'store/actions';

import {
    daysBetween,
    getEventsByProducts,
    getAllDayEvents,
    disableCalendarScroll,
    hasCalendarMainProductOrPackage
} from 'utils';

function FullCalendarComponent({
    setAddProductsProductTypeModal,
    setAddProductInitModalData,
    allProducts,
    calendar,
    getAllCalendarProducts,
    getCalendarsById,
    getCalendarsById_,
    onClickAddMoreProduct,
    setFirstCreateMainProductModal,
    setCurrentProduct_,
    setSelectCalendarDaysBookingModal,
    setAddOnProductItineraryTimeModal
}) {
    const route = useRouteMatch();
    const history = useHistory();
    const calendarRef = useRef();

    const [events, setEvents] = useState([]);

    const getUniqueWeekDays = () => {
        const uniqueWeekDays = [];
        const calendarDataDays = calendar.days;

        calendarDataDays.forEach((item) => {
            if (!uniqueWeekDays.includes(item.day_of_week)) {
                uniqueWeekDays.push(item.day_of_week);
            }
        });
        return uniqueWeekDays;
    };

    const [sideDayArray, setSideDayArray] = useState([]);
    const [selectedDowsArray, setSelectedDowsArray] = useState([]);
    const [duration, setDuration] = useState(3);
    const [selectedDate, setSelectedDate] = useState({
        date: new Date(Date.now()),
        dow: new Date(Date.now()).getDay()
    });
    const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth);
    const [isDayActive, setIsDayActive] = useState(new Date(Date.now()).getDay());
    const [activeDate, setActiveDate] = useState(null);
    const [showCurrentProductDrawer, setShowCurrentProductDrawer] = useState(null);
    const [selectedAllDayCategory, setSelectedAllDayCategory] = useState(null);

    const mainProduct =
        calendar?.mainProduct &&
        Array.isArray(calendar?.mainProduct) &&
        calendar?.mainProduct.length > 0
            ? calendar?.mainProduct[0]
            : null;
    const packgeDetails = calendar?.packgeDetails || null;

    useEffect(() => {
        getCalendarsById_(null);
        getAllCalendarProducts(route.params.id);
        getCalendarsById(route.params.id, history);
    }, []);

    useEffect(() => {
        const eventsToSet = getEventsByProducts(allProducts, calendar);
        setEvents(eventsToSet);
        if ((allProducts, calendar)) {
            const allDayEventsToSet = getAllDayEvents(
                allProducts,
                getDayName(activeDate?.date || selectedDate?.date),
                calendar
            );
            if (!selectedAllDayCategory)
                setSelectedAllDayCategory(allDayEventsToSet[0]?.product_category_id);
        }
    }, [allProducts, calendar]);

    useEffect(() => {
        windowResize();

        function handleResize() {
            setCurrentWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        windowResize();
    }, [selectedDate]);

    useEffect(() => {
        if (calendar && calendar.days && sideDayArray.length === 0) {
            let value = getUniqueWeekDays();
            setSideDayArray(value);
        }
    }, [calendar]);

    const columnHeaderText = (date) => {
        return (
            <div
                className={`${isDayActive == date.dow ? 'active-day' : ''}`}
                key={date.dow}
                role="button"
                tabIndex="0"
                onKeyUp={() => {
                    dateClickEvent(date);
                }}
                onClick={() => {
                    dateClickEvent(date);
                }}
                onMouseEnter={() => {
                    handleMouseEnter(date);
                }}
                onMouseLeave={() => {
                    handleMouseLeave(date);
                }}>
                <div>
                    <span className="col-day-action"> {getDayName(date.date)} </span>
                </div>

                <ActivityPanelComponent
                    products={allProducts}
                    setSelectedAllDayCategory={setSelectedAllDayCategory}
                    selectedAllDayCategory={selectedAllDayCategory}
                    hasMainProduct={false}
                    pannel={`${isDayActive == date.dow ? 'stand-out' : ''}`}
                    selectedDate={getDayName(date?.date || date?.date)}
                    active={
                        getDayName(date?.date || date?.date) ===
                        getDayName(activeDate?.date || selectedDate?.date)
                    }
                />
            </div>
        );
    };

    const dateClickEvent = (date) => {
        setSelectedDate(date);
        setIsDayActive(date.dow);
    };

    // Logic in sycn with css also
    const getDurationByWindowSize = (width) => {
        if (width >= 2560) {
            return 6;
        } else if (width < 2560 && width >= 1920) {
            return 4;
        } else if (width < 1920 && width >= 1440) {
            return 3;
        } else if (width < 1440 && width >= 1200) {
            return 2;
        } else {
            return 1;
        }
    };

    const windowResize = () => {
        let durationToSet = getDurationByWindowSize(window.innerWidth);
        setDuration(durationToSet);
    };

    // let dowArray = [];
    const dayHeaderDidMount = () => {
        let view = calendarRef?.current?.getApi()?.view;
        if (view) {
            const dowArray = [
                getDayName(view.activeStart).split('- ')[1],
                getDayName(moment(view.activeEnd).subtract(1, 'days').toDate()).split('- ')[1]
            ];
            dowArray[1] =
                dowArray[1] == 'undefined' && calendar?.days && calendar?.days.length
                    ? calendar?.days[calendar?.days.length - 1].day_of_week
                    : dowArray[1];
            setSelectedDowsArray(dowArray);
        } else if (calendar?.days && calendar?.days.length) {
            const durationToSet = getDurationByWindowSize(window.innerWidth);
            setSelectedDowsArray([
                calendar.days[0].day_of_week,
                calendar.days[durationToSet - 1]
                    ? calendar.days[durationToSet - 1].day_of_week
                    : calendar.days[calendar.days.length - 1].day_of_week
            ]);
        }
    };

    const getDayName = (date) => {
        const daynum = daysBetween(new Date(Date.now()), date);

        return calendar?.days && calendar?.days.length
            ? 'Day ' + Number(Number(daynum) + 1) + ' - ' + calendar?.days[daynum]?.day_of_week
            : '';
    };

    const handleMouseEnter = (date) => {
        setActiveDate(date);
        setIsDayActive(date.dow);
    };

    const handleMouseLeave = () => {
        if (selectedDate) {
            setIsDayActive(selectedDate.dow);
        } else {
            setIsDayActive('');
        }
        setActiveDate(null);
    };

    const datesSet = (arg) => {
        const noOfDays = daysBetween(new Date(Date.now()), arg.view.activeEnd);
        const length = daysBetween(arg.view.activeStart, arg.view.activeEnd);
        const nowMoment = moment(moment(moment.now()).format('YYYY-MM-DD'), 'YYYY-MM-DD');
        const durationToset = getDurationByWindowSize(window.innerWidth);

        if (
            length === calendar?.days?.length % durationToset &&
            calendar?.days?.length % durationToset &&
            noOfDays < calendar?.days?.length &&
            !nowMoment.isAfter(moment(arg.view.activeEnd).subtract(1, 'day'))
        ) {
            setDuration(durationToset);
            return calendarRef.current?.getApi().gotoDate(
                moment(moment.now())
                    .add(
                        calendar?.days?.length -
                            (calendar?.days?.length % durationToset) -
                            durationToset,
                        'days'
                    )
                    .format('YYYY-MM-DD')
            );
        }

        if (
            noOfDays >= calendar?.days?.length &&
            calendar?.days?.length % durationToset &&
            length != calendar?.days?.length % durationToset
        ) {
            return setDuration(calendar?.days?.length % durationToset);
        }

        if (nowMoment.isAfter(moment(arg.view.activeEnd).subtract(1, 'day'))) {
            return calendarRef.current?.getApi().next();
        }

        if (noOfDays > calendar?.days?.length) {
            return calendarRef.current?.getApi().prev();
        }
    };

    const onSetCurrentProduct = (product) => {
        if (product) setCurrentProduct_(product);
        setShowCurrentProductDrawer(product ? true : false);
    };

    const fixedWidthMainCalendarWrap = (
        <div className="fixed-width-calendar d-flex flex-column">
            {duration && calendar && allProducts && (
                <FullCalendar
                    contentHeight="unset"
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    hiddenDays={[]}
                    headerToolbar={{
                        left: 'prev,next',
                        center: '',
                        right: ''
                    }}
                    ref={calendarRef}
                    initialView="timeGrid"
                    // defaultView={'basicWeek'}
                    dateClick={dateClickEvent}
                    eventClick={() => {
                        // console.log('event');
                    }}
                    duration={{ days: duration }}
                    windowResize={windowResize}
                    editable={false}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    dayHeaderDidMount={dayHeaderDidMount}
                    // columnHeaderFormat={{ weekday: 'short' }}
                    dayHeaderContent={columnHeaderText}
                    weekends={true}
                    events={events} // alternatively, use the `events` setting to fetch from a feed
                    eventContent={(eventInfo) =>
                        CalendarEventContent(eventInfo, {
                            setAddProductsProductTypeModal,
                            setAddProductInitModalData,
                            onClickAddMoreProduct,
                            setFirstCreateMainProductModal,
                            setCurrentProduct: onSetCurrentProduct
                        })
                    }
                    datesSet={datesSet}
                    allDaySlot={false}
                />
            )}
        </div>
    );

    // Disable calendar scroll on every render for a couple of milliseconds
    disableCalendarScroll();
    return (
        <div className="master-event-content d-flex">
            <div className="selected-days-row mt-40px mr-3 d-none d-xl-block">
                <div className="day-title">Day</div>
                <div className="selected-days-wrapper">
                    {/* <div className="selected-days-band" data-start="1" data-end="3"></div> */}
                    {sideDayArray.map((data, index) => (
                        <div
                            key={index}
                            className={
                                'selected-day _sm ' +
                                (data == selectedDowsArray[0] ? 'start' : '') +
                                (data == selectedDowsArray[selectedDowsArray.length - 1]
                                    ? 'end'
                                    : '')
                            }>
                            {data}
                        </div>
                    ))}
                </div>
            </div>
            {currentWindowWidth >= 768
                ? fixedWidthMainCalendarWrap
                : !isDayActive
                ? fixedWidthMainCalendarWrap
                : null}
            {currentWindowWidth >= 768 || isDayActive ? (
                <div className="active-day-panel">
                    {currentWindowWidth < 768 ? (
                        <span
                            className="ghost-btn-icon"
                            role="button"
                            tabIndex="0"
                            onKeyUp={() => setIsDayActive(false)}
                            onClick={() => setIsDayActive(false)}
                            style={{ position: 'absolute', left: 0 }}>
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="16" fill="black" fillOpacity="0.7" />
                                <path
                                    d="M5.29289 15.2929C4.90237 15.6834 4.90237 16.3166 5.29289 16.7071L11.6569 23.0711C12.0474 23.4616 12.6805 23.4616 13.0711 23.0711C13.4616 22.6805 13.4616 22.0474 13.0711 21.6569L7.41421 16L13.0711 10.3431C13.4616 9.95262 13.4616 9.31945 13.0711 8.92893C12.6805 8.53841 12.0474 8.53841 11.6569 8.92893L5.29289 15.2929ZM26 15L6 15L6 17L26 17L26 15Z"
                                    fill="white"
                                />
                            </svg>
                        </span>
                    ) : null}
                    <div className="active-day-title-holder">
                        <div className="active-day-title">
                            {getDayName(activeDate?.date || selectedDate?.date)} (All Day Events)
                        </div>
                    </div>
                    <ActivityPanelComponent
                        products={allProducts}
                        setSelectedAllDayCategory={setSelectedAllDayCategory}
                        selectedAllDayCategory={selectedAllDayCategory}
                        buttonClass={'_alt _sm'}
                        canAdd={true}
                        selectedDate={getDayName(activeDate?.date || selectedDate?.date)}
                        onAdd={() => {
                            if (mainProduct || packgeDetails) {
                                setAddProductInitModalData({
                                    name: 'Products available all day',
                                    value: 'all_day'
                                });
                                setAddProductsProductTypeModal(true);
                            } else {
                                setFirstCreateMainProductModal(true);
                            }
                        }}
                        calendar={calendar}
                    />
                    <CalendarActiveDaySchedule
                        selectedDate={getDayName(activeDate?.date || selectedDate?.date)}
                        products={allProducts}
                        selectedAllDayCategory={selectedAllDayCategory}
                        onAdd={() => {
                            if (hasCalendarMainProductOrPackage(calendar)) {
                                setAddProductInitModalData({
                                    name: 'Products available all day',
                                    value: 'all_day'
                                });
                                setAddProductsProductTypeModal(true);
                            } else {
                                setFirstCreateMainProductModal(true);
                            }
                        }}
                        setCurrentProduct={(product) => onSetCurrentProduct(product)}
                    />
                </div>
            ) : null}
            <ProductDetailsDrawer
                visible={showCurrentProductDrawer}
                onClose={() => onSetCurrentProduct(null)}
                setShowCurrentProductDrawer={setShowCurrentProductDrawer}
                setSelectCalendarDaysBookingModal={setSelectCalendarDaysBookingModal}
                setAddOnProductItineraryTimeModal={setAddOnProductItineraryTimeModal}>
                <CalendarEventDetailContent onClose={() => onSetCurrentProduct(null)} />
            </ProductDetailsDrawer>
        </div>
    );
}

FullCalendarComponent.propTypes = {
    getAllCalendarProducts: PropTypes.any,
    getCalendarsById: PropTypes.any,
    calendar: PropTypes.any,
    allProducts: PropTypes.any,
    setAddProductsProductTypeModal: PropTypes.func,
    getCalendarsById_: PropTypes.func,
    setAddProductInitModalData: PropTypes.any,
    setFirstCreateMainProductModal: PropTypes.any,
    onClickAddMoreProduct: PropTypes.func,
    setCurrentProduct_: PropTypes.func,
    setSelectCalendarDaysBookingModal: PropTypes.func,
    setAddOnProductItineraryTimeModal: PropTypes.func
};

const mapStateToProps = (state) => ({
    allProducts: state?.calendar.get_all_calendar_products?.data,
    calendar: state?.calendar.get_calendar_data?.data
});

export default connect(mapStateToProps, {
    getAllCalendarProducts,
    getCalendarsById,
    getCalendarsById_,
    setCurrentProduct_
})(FullCalendarComponent);

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import './index.css';
import StarRatings from 'react-star-ratings';
import Line from '../../../../assets/images/Line.svg';
import Line2 from '../../../../assets/images/Line2.svg';
import Marker from '../../../../assets/images/marker.svg';
import Family from '../../../../assets/images/family.svg';
import Beach from '../../../../assets/images/beach.svg';
import Honeymoon from '../../../../assets/images/honeymoon.svg';
import Gorilla from '../../../../assets/images/gorilla.svg';
import Walking from '../../../../assets/images/safari.svg';
import Car from '../../../../assets/images/car.svg';
import Chimp from '../../../../assets/images/chimp.svg';
import Photography from '../../../../assets/images/photography.svg';
import Canoe from '../../../../assets/images/canoesafari.svg';
import Horseback from '../../../../assets/images/horseback.svg';
import Birding from '../../../../assets/images/birding.svg';
import Golf from '../../../../assets/images/golf.svg';
import HeaderDotted from '../../../atoms/headerdotted';
import Lion from '../../../../assets/images/lion.svg';
import Elephant from '../../../../assets/images/elephant.svg';
import Buffalo from '../../../../assets/images/buffalo.svg';
import Leopard from '../../../../assets/images/leopard.svg';
import WildDog from '../../../../assets/images/wilddog.svg';
import WildBeast from '../../../../assets/images/wildbeast.svg';
import Zebra from '../../../../assets/images/zebra.svg';
import Outdoors from '../../../../assets/images/outdoors.svg';
import Green from '../../../../assets/images/green.svg';
import Grey from '../../../../assets/images/grey.svg';
import Orange from '../../../../assets/images/orange.svg';
import RedInfo from '../../../../assets/images/redinfo.svg';
import SA from '../../../../assets/images/kenya.svg';
import Kenya from '../../../../assets/images/sa.svg';
import Check from '../../../../assets/images/checks.svg';
import Left from '../../../../assets/images/left.svg';
import Right from '../../../../assets/images/right.svg';
import Tree from '../../../../assets/images/tree.svg';
import Girl from '../../../../assets/images/girl.svg';

//Mock data for the application
const data = [
    {
        name: 'Cape Town',
        route: 'Start',
        weight: true
    },
    {
        name: 'Kruger',
        route: 'Day 1',
        weight: false
    },
    {
        name: 'Full Day Kruger Park Safari',
        route: 'Day 2',
        weight: false
    },
    {
        name: 'Paranoma Route',
        route: 'Day 3',
        weight: false
    },
    {
        name: 'Soweto Tour and Depature',
        route: 'Day 4',
        weight: false
    },
    {
        name: 'Nairobi City Centre (Day 5)',
        route: 'End',
        weight: true
    }
];

const adventure = [
    {
        icon: Family,
        name: 'Family'
    },
    {
        icon: Beach,
        name: 'Beach Time'
    },
    {
        icon: Honeymoon,
        name: 'Honey Moon'
    },
    {
        icon: Gorilla,
        name: 'Gorilla Trekking'
    },
    {
        icon: Walking,
        name: 'Walking Safari'
    },
    {
        icon: Car,
        name: 'Self Drive'
    },
    {
        icon: Chimp,
        name: 'Chimp Trekking'
    },
    {
        icon: Photography,
        name: 'Photographic Safari'
    },
    {
        icon: Canoe,
        name: 'Canoe Safari'
    },
    {
        icon: Horseback,
        name: 'Horseback Safari'
    },
    {
        icon: Birding,
        name: 'Birding Tour'
    },
    {
        icon: Golf,
        name: 'Golf & Wildlife'
    }
];

const TopSights = [
    {
        icon: Lion,
        name: 'Lion',
        circle: Green
    },
    {
        icon: Elephant,
        name: 'Elephant',
        circle: Green
    },
    {
        icon: Buffalo,
        name: 'Buffalo',
        circle: Green
    },
    {
        icon: Leopard,
        name: 'Leopard',
        circle: Green
    },
    {
        icon: WildDog,
        name: 'Wild Dog',
        circle: Green
    },
    {
        icon: WildBeast,
        name: 'Wild Beast',
        circle: Orange
    },
    {
        icon: Zebra,
        name: 'Zebra',
        circle: Grey
    },
    {
        icon: Outdoors,
        name: 'Outdoors',
        circle: Green
    }
];

const Accommodation = [
    {
        day: '1',
        title: 'The Ark (Lodge)',
        subtitle: 'Mid-range lodge inside aberdare NP',
        color: '#fff'
    },
    {
        day: '2-3',
        title: 'Ashnil Samburu Camp',
        subtitle: 'Luxury tented camp bordering Samburu NR without fences',
        color: '#FAF7EA'
    },
    {
        day: '4',
        title: 'Lake Nakuru Lodge',
        subtitle: 'Mid-range lodge inside Lake Nakuru NP',
        color: '#fff'
    },

    {
        day: '5-6',
        title: 'Ilkeliani Camp',
        subtitle: 'Mid-range tented bush camp just outside Masal Mara NP',
        color: '#FAF7EA'
    },
    {
        day: '7',
        title: 'End of tour',
        subtitle: '(No accommodation)',
        color: '#fff'
    }
];

const City = [
    {
        name: 'Cape town',
        sub: 'South Africa',
        icon: Kenya
    },
    {
        name: 'Bellville',
        sub: 'South Africa',
        icon: Kenya
    },
    {
        name: 'Nairobi',
        sub: 'Kenya',
        icon: SA
    }
];

const covid = [
    'Face masks required for travelers in public areas',
    'Face masks required for guides in public areas',
    'Face masks provided for travelers',
    'Hand sanitizer available to travelers and staff',
    'Social distancing enforced throughout experience',
    'Regularly sanitized high-traffic areas',
    'Gear/equipment sanitized between use',
    'Transportation vehicles regularly sanitized',
    'Guides required to regularly wash hands',
    'Regular temperature checks for staff',
    'Temperature checks for travelers upon arrival',
    'Paid stay-at-home policy for staff with symptoms',
    'Contactless payments for gratuities and add-ons'
];

const slider = [
    {
        name: 'Nife',
        image: Girl,
        rating: 4,
        date: 'Sep 02, 2020',
        description:
            'Very helpful! Received 65% discount off my 5 day Kruger Big 5 Safari Experience trip, with 12 instalmental flexi payment, and made use of the book now pay later service.',
        Lasttime: 'Last week',
        Member: 'March, 2020'
    },
    {
        name: 'Neville',
        image: Girl,
        rating: 4.5,
        date: 'Sep 02, 2020',
        description:
            'Very helpful! Received 65% discount off my 5 day Kruger Big 5 Safari Experience trip, with 12 instalmental flexi payment, and made use of the book now pay later service.',
        Lasttime: 'Last week',
        Member: 'March, 2020'
    },
    {
        name: 'Bimbi',
        image: Girl,
        rating: 5,
        date: 'Sep 02, 2020',
        description:
            'Very helpful! Received 65% discount off my 5 day Kruger Big 5 Safari Experience trip, with 12 instalmental flexi payment, and made use of the book now pay later service.',
        Lasttime: 'Last week',
        Member: 'March, 2020'
    }
];

export default function Overview() {
    // const places = [
    //     { latitude: 25.8103146, longitude: -80.1751609 },
    //     { latitude: 27.9947147, longitude: -82.5943645 },
    //     { latitude: 28.4813018, longitude: -81.4387899 }
    // ];

    // const { loadingElement, containerElement, mapElement, defaultCenter, defaultZoom } = props;

    // Function for adding and reducing number
    const [number, setNumber] = useState(0);

    const addNumber = () => {
        if (number < slider.length - 1) {
            setNumber(number + 1);
        }
    };

    const reduceNumber = () => {
        if (number > 0) {
            setNumber(number - 1);
        }
    };

    return (
        <>
            <div>
                <h5 className="bold-font">5 Day Kruger Big 5 Safari Experience</h5>
                <p className="smaller-font">
                    One of the largest and oldest National Park in Africa. With a variety of mammal
                    species found in this famous park and your chances of spotting the big five is
                    achievable. Enjoy game drives in Kruger National Park, tour of the scenic
                    panorama route with stops at various iconic viewpoints such as Three Rondavels,
                    God’s Window, Bourke’s Luck Potholes.
                </p>
                <p className="normal-font">
                    by <span className="gold-font">African Sunsets Travel</span>
                </p>
            </div>
            <HeaderDotted title="Activities Map Guide" line={Line} top="30px" />

            <div className="row">
                <div className="col-md-6">
                    <h5 className="bold-font">Route</h5>

                    <div className="row d-flex align-items-center">
                        {data.map((item) => (
                            <>
                                <div style={{ textAlign: 'center' }} className="col-md-1">
                                    <img src={Marker} alt="" />
                                </div>
                                <div className="col-md-3">
                                    <p style={{ fontWeight: item.weight && 900 }} className="route">
                                        {item.route}
                                    </p>
                                </div>
                                <div className="col-md-8">
                                    <p className="decoration">{item.name}</p>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center">
                    <div style={{ paddingTop: 50 }} className="main-wrapper">
                        <iframe
                            src="https://www.atlistmaps.com/map/8a5ef58f-e05c-4381-8e0b-24a230ea9ac5?share=true"
                            allow="geolocation"
                            width="100%"
                            height="200px"
                            frameBorder="0"
                            scrolling="no"></iframe>
                    </div>
                </div>
            </div>
            <>
                <HeaderDotted title="Types of Adventure" line={Line} top="-126px" />

                <div className="row">
                    {adventure.map((item) => (
                        <>
                            <div className="col-md-3">
                                <div className="adventure-box">
                                    <p className="m-0">
                                        <span style={{ paddingRight: 10 }}>
                                            <img src={item.icon} alt="" />
                                        </span>
                                        {item.name}
                                    </p>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </>

            <>
                <HeaderDotted title="Top Sights" line={Line} top="30px" />

                <div className="row">
                    {TopSights.map((item) => (
                        <>
                            <div className="col-md-3">
                                <div className="topsight-box">
                                    <div className="m-0">
                                        <span style={{ paddingRight: 10 }}>
                                            <img src={item.icon} alt="" />
                                        </span>
                                        <span className="decoration" style={{ fontSize: 11 }}>
                                            {item.name}
                                        </span>
                                        <span style={{ paddingLeft: 10 }}>
                                            <img src={item.circle} alt="" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>

                <>
                    <div
                        style={{ paddingTop: 30, paddingLeft: 40, paddingRight: 40 }}
                        className="row d-flex align-items-center">
                        <div className="col-md-3">
                            <img className="move-left" src={Line2} alt="" />
                        </div>
                        <div className="col-md-6">
                            <h5 style={{ textAlign: 'center' }} className="bold-font">
                                Accommodation On This Experience
                            </h5>
                        </div>
                        <div className="col-md-3">
                            <img className="move-right" src={Line2} alt="" />
                        </div>
                    </div>
                    <h5 style={{ paddingTop: 10 }} className="bold-font">
                        Accommodation & Meals
                    </h5>
                    <p className="normal-font m-0">
                        <span style={{ paddingRight: 10 }}>
                            <img src={RedInfo} alt="" />
                        </span>
                        by Additional accommodation before and at the end of the tour can be
                        arranged for an extra cost.
                    </p>
                    <table style={{ marginTop: 40 }} className="table table-striped">
                        <thead className="header-table">
                            <tr className="rounded-border">
                                <th
                                    style={{ borderTopLeftRadius: 10, fontWeight: 900 }}
                                    className="no-border"
                                    scope="col">
                                    Day
                                </th>
                                <th
                                    style={{ borderTopRightRadius: 10, fontWeight: 900 }}
                                    className="no-border"
                                    scope="col">
                                    Accommodation
                                </th>
                            </tr>
                        </thead>
                        {Accommodation.map((item) => (
                            <tbody className="no-border" key={item.day}>
                                <tr
                                    style={{ backgroundColor: item.color }}
                                    className="no-border rounded-border">
                                    <th
                                        style={{ borderTopLeftRadius: 10 }}
                                        className="no-border"
                                        scope="row">
                                        {item.day}
                                    </th>
                                    <td className="no-border" style={{ borderTopRightRadius: 10 }}>
                                        <div className="no-border">{item.title}</div>
                                        <div className="no-border">{item.subtitle}</div>
                                    </td>
                                </tr>
                            </tbody>
                        ))}
                    </table>
                </>
            </>

            <>
                <HeaderDotted title="City / Country" line={Line} top="30px" />

                <div className="row">
                    {City.map((item) => (
                        <>
                            <div className="col-md-3">
                                <div className="city-box">
                                    <div className="d-flex justify-content-center">
                                        <div
                                            className="d-flex align-items-center"
                                            style={{ paddingRight: 10 }}>
                                            <img src={item.icon} alt="" />
                                        </div>
                                        <div className="">
                                            <p style={{ textAlign: 'left' }} className="m-0">
                                                {' '}
                                                {item.name}
                                            </p>
                                            <p style={{ textAlign: 'left' }} className="m-0">
                                                {' '}
                                                {item.sub}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ))}
                </div>
            </>
            <>
                <HeaderDotted title="Covid-19 Update" line={Line} top="30px" />
                {covid.map((item, i) => (
                    <p className="normal-font m-0" key={i}>
                        <span style={{ paddingRight: 10 }}>
                            <img src={Check} alt="" />
                        </span>
                        {item}
                    </p>
                ))}
            </>

            <>
                <HeaderDotted title="Membership Review" line={Line} top="30px" />
                <div style={{ paddingTop: 30 }} className="container">
                    <div className="row d-flex align-items-center">
                        <div className="col-md-1 d-flex justify-content-end">
                            <img
                                onClick={reduceNumber}
                                className="d-flex align-items-center cursor-pointer"
                                src={Left}
                                alt=""
                            />
                        </div>
                        <div className="col-md-10">
                            {slider.map((item, index) => (
                                <>
                                    {index == number && (
                                        <div className="border-product">
                                            <div className="row">
                                                <div className="col-md-2 d-flex justify-content-start">
                                                    <img src={Girl} alt="" />
                                                </div>
                                                <div className="col-md-6 my-auto p-left">
                                                    <p className="name-weight m-0">
                                                        {item.name}
                                                        <span style={{ paddingLeft: 10 }}>
                                                            <StarRatings
                                                                rating={item.rating}
                                                                starDimension="19px"
                                                                starEmptyColor="#ccc"
                                                                starRatedColor="#CAAD2E"
                                                                numOfStars="5"
                                                                starSpacing="5px"
                                                            />
                                                        </span>
                                                    </p>
                                                    <p className="name-normal-weight">
                                                        {item.date}
                                                    </p>
                                                </div>
                                                <div className="col-md-4 d-flex justify-content-end">
                                                    <img src={Tree} alt="" />
                                                </div>
                                            </div>
                                            <div>
                                                <p className="bold-font">{item.description}</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6 ">
                                                    <p className="normal-font">
                                                        Last time online: {item.Lasttime}
                                                    </p>
                                                </div>
                                                <div className="col-md-6 d-flex justify-content-end">
                                                    <p className="normal-font">
                                                        Member Since: {item.Member}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </>
                            ))}
                        </div>
                        <div className="col-md-1 cursor-pointer">
                            <img onClick={addNumber} src={Right} alt="" />
                        </div>
                    </div>
                </div>
            </>
        </>
    );
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

const FiltersMobile = (props) => {
    const { onClickMenu, onClickClose, allFiltersWrap } = props;
    return (
        <div className="calendar-menu-wrapper" style={{ top: -1 }}>
            <div className="calendar-menu-overlay" role="dialog" onClick={onClickMenu}></div>
            <div className="calendar-menu-content">
                <div className="clearfix">
                    <button
                        className="float-right op-full close mt-2"
                        type="button"
                        aria-label="close"
                        onClick={onClickClose}>
                        <span className="close-bg-icon"></span>
                    </button>
                    <div className="float-left">
                        <span className="fs-16 fw-500 mt-2">Filters</span>
                    </div>
                </div>
                <div className="mt-3">{allFiltersWrap}</div>
            </div>
        </div>
    );
};
FiltersMobile.propTypes = {
    allFiltersWrap: PropTypes.node,
    onClickMenu: PropTypes.func,
    onClickClose: PropTypes.func
};
export default FiltersMobile;

import React from 'react';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SelectIcon from '../../../../assets/images/SelectIcon.svg';
import TotalCards from './TotalCards';
import PieCards from './PieCards';
import Retention from './Retention.jsx';
import KeyConversion from './KeyConversion.jsx';
import CalendarAvailability from './CalendarAvailability';
import Revenue from './Revenue.jsx';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Poppins',
        fontStyle: 'normal'
    },
    line1: {
        height: '237px',
        background: 'rgba(162, 121, 44, 0.8)',
        borderRadius: '4px 4px 0px 0px'
    },
    title: {
        fontWeight: '600',
        fontSize: '28px',
        lineHeight: '34px',
        color: '#FFFFFF'
    },
    select: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '16px 22px',
        backgroundImage: `url(${SelectIcon})`,
        backgroundRepeat: 'no-repeat',
        WebkitAppearance: 'none',
        backgroundPosition: 'right center',
        backgroundOrigin: 'content-box',
        fontWeight: 'normal',
        fontSize: '20px',
        lineHeight: '24px',
        color: '#333333'
    },
    overView: {
        marginTop: '-33px',
        zIndex: 3,
        marginBottom: '65px'
    }
}));
export default function Performance() {
    const classes = useStyles();

    return (
        <Grid container direction="column" className={classes.root}>
            <Grid
                item
                container
                className={classes.line1}
                justify="space-evenly"
                alignItems="center">
                <Grid item md={4} className={classes.title}>
                    Performance Dashboard
                </Grid>
                <Grid item container md={7} spacing={2} justify="flex-end">
                    <Grid item md={6}>
                        <select
                            name="cars"
                            id="cars"
                            className={classes.select}
                            style={{
                                width: '100%'
                            }}>
                            <option value="volvo">All customers calendars</option>
                        </select>
                    </Grid>
                    <Grid item md={4}>
                        <select
                            name="cars"
                            id="cars"
                            className={classes.select}
                            style={{
                                width: '100%'
                            }}>
                            <option value="volvo">All time</option>
                        </select>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                style={{
                    background: 'rgba(162, 121, 44, 0.06)',
                    zIndex: 1
                }}>
                <Container className={classes.overView}>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginBottom: '26px'
                        }}>
                        <Grid item md>
                            <TotalCards
                                percentage="40%"
                                color="#A2792C"
                                title="Total Customers"
                                total="15.89k"
                                change="(+28%)"
                                changeColor="#02820F"
                            />
                        </Grid>
                        <Grid item md>
                            <TotalCards
                                percentage="60%"
                                color="#4CDED5"
                                title="Customers with Main Products only"
                                total="10k"
                                change="(+18%)"
                                changeColor="#02820F"
                            />
                        </Grid>
                        <Grid item md>
                            <TotalCards
                                percentage="30%"
                                color="#FF3D00"
                                title="Customers with Add-on Products"
                                total="9.89k"
                                change="(+18%)"
                                changeColor="#02820F"
                            />
                        </Grid>
                        <Grid item md>
                            <TotalCards
                                percentage="50%"
                                color="#FEC14D"
                                title="Total Revenue"
                                total="$2.9M"
                                change="(+18%)"
                                changeColor="#02820F"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        style={{
                            marginBottom: '25px'
                        }}>
                        <Grid item container md spacing={2}>
                            <Grid item md>
                                <PieCards
                                    title="Main Products Revenue"
                                    total="$345.9k"
                                    change="(+28%)"
                                    pieColor="#A2792C"
                                />
                            </Grid>
                            <Grid item md>
                                <PieCards
                                    title="Add-on Products Revenue"
                                    total="$345.8k"
                                    change="(+28%)"
                                    pieColor="#4CDED5"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Retention />
                            </Grid>
                        </Grid>
                        <Grid item md>
                            <KeyConversion />
                        </Grid>
                    </Grid>
                    <div container>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ flexBasis: '65%', marginRight: '1.3%' }}>
                                <CalendarAvailability />
                            </div>
                            <div style={{ flexBasis: '40%' }}>
                                <Revenue />
                            </div>
                        </div>
                        <Grid
                            item
                            style={{
                                background: '#FFFFFF',
                                boxShadow: '0px 4px 4px rgba(151, 151, 151, 0.22)',
                                borderRadius: '4px',
                                padding: '21px 26px',
                                marginTop: '2px'
                            }}>
                            <div
                                style={{
                                    marginTop: '72px'
                                }}>
                                <hr />
                            </div>
                            <div
                                style={{
                                    fontWeight: 'normal',
                                    fontSize: '20px',
                                    lineHeight: '21px',
                                    /* identical to box height, or 105% */
                                    letterSpacing: '0.1px',
                                    color: 'rgba(79, 79, 79, 0.63)',
                                    marginTop: '66px',
                                    marginBottom: '66px',
                                    textAlign: 'center',
                                    zIndex: 3
                                }}>
                                To view details, please select either Customer Calendar Availability
                                or Revenue Projection Deatils above.
                            </div>
                            <div
                                style={{
                                    marginBottom: '72px'
                                }}>
                                <hr />
                            </div>
                        </Grid>
                    </div>
                </Container>
            </Grid>
        </Grid>
    );
}

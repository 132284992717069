import React, { Component } from 'react';
import ChefService from '../../../pages/meals-drinks-details/choose-chef/index';
import VendorDashboard from '../vendor-dashboard';

export default class ChooseChefService extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <VendorDashboard>
                <ChefService />
            </VendorDashboard>
        );
    }
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable-next-line react/jsx-key */
import React, { useEffect, useState } from 'react';
import { Button, Row } from 'reactstrap';
import './index.scss';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Proptypes from 'prop-types';
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import { ConfirmationModal } from 'components/molecules';
export default function AddSeasonButton(props) {
    const { data, onChange } = props;
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    useEffect(() => {
        //run for validation
        if (onChange && Array.isArray(data) && data.length > 0) onChange(data);
    }, [data]);

    const isAddGood = () => props.data && props.data.findIndex((res) => res.type === 'good') !== -1;
    const isAddExcellent = () =>
        props.data && props.data.findIndex((res) => res.type === 'excellent') !== -1;
    const isAddFair = () => props.data && props.data.findIndex((res) => res.type === 'fair') !== -1;
    return (
        <div className="add-seasons">
            <div className="card-head">
                <div style={{ display: 'flex' }}>
                    <div className="title">
                        {props.name}
                        <img src={MoreInfo} alt="" />
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data && props.data.length > 0 ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={() => setIsConfirmationModalOpen(true)}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> Add
                    </Button>
                )}
            </div>
            <Row>
                {isAddExcellent()
                    ? [
                          // eslint-disable-next-line react/jsx-key
                          <div className="title-type" key={'exce'}>
                              {'EXCELLENT: '}
                          </div>,
                          props.data.map((res, i) =>
                              res.type === 'excellent' ? (
                                  <div key={`ex${i}`} className="added">
                                      {` ${res.name}`}
                                  </div>
                              ) : null
                          )
                      ]
                    : null}
            </Row>
            <Row>
                {isAddGood()
                    ? [
                          // eslint-disable-next-line react/jsx-key
                          <div className="title-type" key={'goo'}>
                              {'GOOD: '}
                          </div>,
                          props.data.map((res, i) =>
                              res.type === 'good' ? (
                                  <div key={`go${i}`} className="added">
                                      {`${res.name}`}
                                  </div>
                              ) : null
                          )
                      ]
                    : null}
            </Row>
            <Row>
                {isAddFair()
                    ? [
                          // eslint-disable-next-line react/jsx-key
                          <div className="title-type" key={'fai'}>
                              {'FAIR: '}
                          </div>,
                          props.data.map((res, i) =>
                              res.type === 'fair' ? (
                                  <div key={`fa${i}`} className="added">
                                      {` ${res.name}`}
                                  </div>
                              ) : null
                          )
                      ]
                    : null}
            </Row>

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setIsConfirmationModalOpen(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    setIsConfirmationModalOpen(false);
                    props.onDelete();
                }}
            />
        </div>
    );
}

AddSeasonButton.propTypes = {
    name: Proptypes.string,
    onClick: Proptypes.func,
    isRecommended: Proptypes.bool,
    data: Proptypes.any,
    onChange: Proptypes.func,
    onDelete: Proptypes.func
};

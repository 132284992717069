import { Route, Redirect } from 'react-router-dom';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from 'components/atoms';
class PrivateRoute extends Component {
    componentDidMount() {
        document.body.classList.remove('target-main-layout');
    }

    render() {
        const { component: Component, ...rest } = this.props;
        let isLoggedIn = this.props.isLoggedIn;
        let isFirstTime = !(this.props.my_profile?.photo_url?.length > 1) && isLoggedIn;
        return (
            <Route
                {...rest}
                render={(props) => {
                    if (this.props.loading) return <Loader />;

                    if (isFirstTime) {
                        return <Redirect to="/signin" />;
                    } else {
                        return isLoggedIn ? <Component {...props} /> : <Redirect to="/" />;
                    }
                }}
            />
        );
    }
}
PrivateRoute.propTypes = {
    component: PropTypes.any,
    isLoggedIn: PropTypes.any,
    my_profile: PropTypes.any,
    loading: PropTypes.any
};
const mapState = (state) => {
    return {
        isLoggedIn: state?.auth?.auth?.token || false,
        my_profile: state.auth.my_profile,
        loading: state?.auth?.auth?.authLoading
    };
};

export default connect(mapState)(PrivateRoute);

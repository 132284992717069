/* eslint-disable react/no-unescaped-entities */
import React, { Component } from 'react';
import { Form, Input, Row, Col, Drawer } from 'antd';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import ImportMainProduct from '../../../organisms/views/importMainProduct';
import './index.scss';
import Spacer from '../../../atoms/spacer';
import MoreInfo from '../../../../assets/images/more_info.svg';
import Activity from '../../../../assets/images/activity.svg';
import Accomodation from '../../../../assets/images/accomodation.svg';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import { getCalendars } from '../../../../store/actions/calendarActions';
import {
    setMainProduct,
    getItineraries,
    createMainProduct
} from '../../../../store/actions/contentActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Proptypes from 'prop-types';

class CreateMainProduct extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            title: '',
            isOpenCategory: false,
            category: '',
            description: '',
            calendars: null
        };
    }
    componentDidMount() {
        this.props.getCalendars();
        // this.props.getItineraries();
    }
    componentDidUpdate(prevProps) {
        const { create_main_product } = this.props;
        if (prevProps.create_main_product !== create_main_product && create_main_product) {
            if (create_main_product?.product_category === 'activity') {
                this.props.getCalendars();
                this.props.onOpenActivity();
            } else if (create_main_product?.product_category === 'accomodation') {
                this.props.getCalendars();
                this.props.onOpenAccomodation();
            }
        }
    }

    onClose = () => this.props.onClose();

    onTitle = (value) => {
        this.setState({ title: value });
        this.formRef.current.setFieldsValue({
            title: value
        });
    };
    onDescription = (value) => {
        this.setState({ description: value });
        this.formRef.current.setFieldsValue({
            descriptionArea: value
        });
    };
    onSelectCategory = (category) => {
        this.formRef.current.setFieldsValue({
            category: category
        });
        this.setState({ category: category });
    };
    //set import data
    onImportProduct = (item) => {
        this.props.setMainProduct(item);
        this.setState({
            title: item.title,
            description: item.description,
            category: item.product_category
        });
        this.formRef.current.setFieldsValue({
            title: item.title,
            descriptionArea: item.description,
            category: item.product_category
        });
    };
    onOpenCategory = () => this.setState({ isOpenCategory: !this.state.isOpenCategory });

    onFinish = (values) => {
        const selectCalendar = window.location.pathname.substring(
            window.location.pathname.lastIndexOf('/') + 1
        );
        const product = {
            calendar_id: parseInt(selectCalendar),
            title: values.title,
            product_category: values.category,
            description: values.descriptionArea
        };
        this.props.createMainProduct(product);
    };

    render() {
        const { requiredMark, category, isOpenCategory, title, description } = this.state;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application create-product"
                    onClose={() => {
                        this.onClose();
                    }}
                    visible={this.props.showModal}
                    bodyStyle={{ paddingBottom: 35, paddingTop: 19 }}>
                    <Form
                        ref={this.formRef}
                        name="control-ref"
                        layout="vertical"
                        initialValues={{
                            requiredMark
                        }}
                        form={this.formRef.current}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}>
                        <Row>
                            <Col>
                                <h4 className="create-product-title">Create your main product</h4>
                                <ImportMainProduct
                                    calendars={this.props.calendar_data}
                                    onMainProduct={(item) => this.onImportProduct(item)}
                                    import="I will import a main product information from my other calendar"
                                />
                                <Spacer top={12} />
                                <p>
                                    Describe the main product you will like to use to attract
                                    travelers to make a purchase. This is what gets you access to
                                    customers calendar. You'll be able to add more details next.
                                </p>

                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            validator: async (_, title) => {
                                                if (title.trim() === '')
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}
                                    initialValue={title}>
                                    <div className="input-title">
                                        Enter Your Product Title <img src={MoreInfo} alt="" />
                                    </div>
                                    <Input
                                        style={{
                                            border: '0.5px solid #A2792C',
                                            height: 50,
                                            borderRadius: 8
                                        }}
                                        value={title}
                                        onChange={(e) => this.onTitle(e.target.value)}
                                    />
                                </Form.Item>

                                <Spacer top={21} />

                                <Form.Item
                                    name="category"
                                    rules={[{ required: true, message: 'Please, select one' }]}
                                    initialValue={category}
                                    className="select-category">
                                    <div className="input-title">
                                        Select Category <img src={MoreInfo} alt="" />
                                    </div>
                                    <Dropdown isOpen={isOpenCategory} toggle={this.onOpenCategory}>
                                        <DropdownToggle className="dropdown-category" caret>
                                            {category !== '' ? (
                                                <div>
                                                    <img
                                                        src={
                                                            category === 'activity'
                                                                ? Activity
                                                                : Accomodation
                                                        }
                                                        alt=""
                                                        style={{
                                                            marginBottom:
                                                                category === 'activity' ? 0 : '5px'
                                                        }}
                                                    />{' '}
                                                    {category}
                                                </div>
                                            ) : (
                                                'Select One'
                                            )}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ width: '100%' }}>
                                            <DropdownItem
                                                onClick={() => this.onSelectCategory('activity')}
                                                className={`${
                                                    category === 'activity'
                                                        ? 'active-category'
                                                        : 'category-item'
                                                }`}>
                                                <img src={Activity} alt="" /> Activity
                                            </DropdownItem>
                                            <DropdownItem
                                                onClick={() =>
                                                    this.onSelectCategory('accomodation')
                                                }
                                                className={`${
                                                    category === 'accomodation'
                                                        ? 'active-category'
                                                        : 'category-item'
                                                }`}>
                                                <img
                                                    src={Accomodation}
                                                    style={{ marginBottom: '5px' }}
                                                    alt=""
                                                />{' '}
                                                Accomodation
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </Form.Item>

                                <Spacer top={26} />

                                <Form.Item
                                    name="descriptionArea"
                                    rules={[
                                        {
                                            validator: async (_, descriptionArea) => {
                                                if (
                                                    !descriptionArea ||
                                                    descriptionArea.trim() === '' ||
                                                    descriptionArea.length < 100
                                                )
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}
                                    initialValue={description}
                                    style={{ marginLeft: '5px' }}>
                                    <div className="input-title" style={{ marginLeft: '2px' }}>
                                        Why should travelers book this tour?
                                    </div>
                                    <Input.TextArea
                                        className="input-area"
                                        placeholder="Briefly describe what makes it unique"
                                        onChange={(e) => this.onDescription(e.target.value)}
                                        value={description}
                                    />
                                    <div className="description-input">100 characters needed</div>
                                </Form.Item>

                                <Spacer top={26} />
                                <Form.Item>
                                    <div className="footer-btn">
                                        <Button color="primary" htmlType="submit">
                                            Continue
                                        </Button>
                                        <Button color="dark">
                                            <img src={HelpIcon} alt="" /> Help
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
            </div>
        );
    }
}
CreateMainProduct.propTypes = {
    getCalendars: Proptypes.func,
    getItineraries: Proptypes.func,
    onClose: Proptypes.func,
    setMainProduct: Proptypes.func,
    onOpenActivity: Proptypes.func,
    onOpenAccomodation: Proptypes.func,
    showModal: Proptypes.bool,
    calendar_data: Proptypes.object,
    create_main_product: Proptypes.object,
    createMainProduct: Proptypes.func
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    success_calendar: state.calendar.success_calendar,
    create_main_product: state.contentDetail.create_main_product
});

const mapDispatch = (dispatch) => ({
    getCalendars: () => dispatch(getCalendars()),
    setMainProduct: (data) => dispatch(setMainProduct(data)),
    getItineraries: (data) => dispatch(getItineraries(data)),
    createMainProduct: (data) => dispatch(createMainProduct(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(CreateMainProduct));

import React, { useEffect } from 'react';
// import Button from "@material-ui/core/Button";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import './index.css';
import PropTypes from 'prop-types';

export default function SupplierAgreement(props) {
    const [open, setOpen] = React.useState(false);
    const parentState = props.parentState;

    useEffect(() => {
        setOpen(props.parentState);
    }, [parentState]);

    const handleClose = () => {
        setOpen(false);
        props.closeParent();
    };

    return (
        <>
            {/* Supplier Agreement Modal Dialog */}
            <Modal isOpen={open} size="lg" centered scrollable toggle={handleClose}>
                <ModalHeader>
                    {props?.data?.title && ReactHtmlParser(props?.data?.title)}
                </ModalHeader>
                <ModalBody>
                    <div className="text-body fs-16 mb-3 fw-500">
                        <em>
                            {props?.data?.body && ReactHtmlParser(props?.data?.body)}{' '}
                            {/* <a className="text-nowrap" href="#">{'Please Click Here'}</a> */}
                        </em>
                    </div>

                    {!props?.data?.body && (
                        <>
                            <div className="mb-3">
                                <Button color="link" className="p-0 box-shadow-none fw-500">
                                    <em>Download these terms as PDF</em>
                                </Button>
                            </div>
                            <div className="text-body fs-14 mb-3">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                quae ab illo inventore veritatis et quasi architecto beatae vitae
                                dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                                eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                                qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit,
                                sed quia non numquam eius modi tempora incidunt ut labore et dolore
                                magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis
                                nostrum exercitationem ullam corporis suscipit laboriosam
                            </div>
                            <div className="text-body fs-14 mb-3">
                                Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                                accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
                                quae ab illo inventore veritatis et quasi architecto beatae vitae
                                dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
                                aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
                                eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                                qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                            </div>
                        </>
                    )}
                </ModalBody>
                <ModalFooter className="justify-content-start">
                    <Button color="primary minw-126" size="lg" onClick={handleClose}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}

SupplierAgreement.propTypes = {
    parentState: PropTypes.any,
    closeParent: PropTypes.func,
    data: PropTypes.object
};

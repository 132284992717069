import React from 'react';
import PropTypes from 'prop-types';

export default function TransferPart(props) {
    const { paymentDetail } = props;
    return (
        <>
            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Transfer overview</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Date paid</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{paymentDetail?.pain || 'N/A'}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Amount</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`$${paymentDetail?.amount}`}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Fee</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.application_fee_amount || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">ID</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">N/A</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Source transaction</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{'N/A'}</span>
                        </div>
                    </div>

                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Transfer group</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{'N/A'}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Destination</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Account</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.account || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Payment</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`$${paymentDetail?.payment}`}</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

TransferPart.propTypes = {
    paymentDetail: PropTypes.object
};

import React from 'react';
import Feedback from '../../../../../organisms/feedback/Feedback';

export default function CustomerFeedback() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Feedback img="https://randomuser.me/api/portraits/men/86.jpg" stars={4} name="Nonso" />
            <Feedback img="https://randomuser.me/api/portraits/men/86.jpg" stars={4} name="Nonso" />
            <Feedback img="https://randomuser.me/api/portraits/men/86.jpg" stars={4} name="Nonso" />
            <Feedback img="https://randomuser.me/api/portraits/men/86.jpg" stars={4} name="Nonso" />
            <Feedback img="https://randomuser.me/api/portraits/men/86.jpg" stars={4} name="Nonso" />
        </div>
    );
}

import MediaCropper from '../media-cropper';
import React, { useState } from 'react';
import Prompter from '../../../atoms/prompter';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import './index.scss';

function MediaField(props) {
    const { type, text, height, width } = props;
    const [isCroppingPhoto, setIsCroppingPhoto] = useState(false);
    const [loadedImg, setLoadedImg] = useState('');
    const [photoFile, setPhotoFile] = useState({});
    let prompt = type === 'picture' ? 'Add Photos' : 'Add Videos';
    if (text) prompt = text;
    const acceptedFiles = type == 'picture' ? 'image/*' : 'video/mp4,video/x-m4v,video/*';
    const closeCropper = (crop) => {
        setPhotoFile({
            file: photoFile
        });
        setIsCroppingPhoto(false);
        props.onSelectMedia && props.onSelectMedia(photoFile, crop, loadedImg);
    };
    const handleMediaChange = (e) => {
        const file = e[0];
        if (file) {
            // set photo in store/form or start upload
            const fileReader = new FileReader();
            fileReader.onloadend = () => {
                // setFormField({
                // 	formField: getImagePreviewKey(formField),
                // 	formValue: fileReader.result
                // })

                // file reader result base64

                setLoadedImg(fileReader.result);
                if (type === 'picture') {
                    setIsCroppingPhoto(true);
                    setPhotoFile({
                        file
                    });
                } else {
                    props.onSelectMedia && props.onSelectMedia(file, {});
                }
            };
            fileReader.readAsDataURL(file);
        }
    };
    return (
        <div
            style={{ [width && 'width']: width, [height && 'height']: height }}
            className="media-field">
            {isCroppingPhoto && (
                <MediaCropper
                    onCrop={closeCropper}
                    img={loadedImg}
                    isVisible={isCroppingPhoto}
                    handleCancel={closeCropper}
                />
            )}
            <Dropzone
                maxFiles={1}
                accept={acceptedFiles}
                onDropAccepted={(e) => handleMediaChange(e)}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps()} />
                        <Prompter orientation="vertical" text={prompt} />
                    </div>
                )}
            </Dropzone>
        </div>
    );
}

MediaField.propTypes = {
    type: PropTypes.oneOf(['picture', 'video']),
    onSelectMedia: PropTypes.func,
    text: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number
};

export default MediaField;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { Form, Button, Col, Row, Select } from 'antd';
import Panel from '../../../atoms/panel';
import Prompter from '../../../atoms/prompter';
import './index.scss';
import { NavLink } from 'react-router-dom';
import DaysAccordion from '../../../molecules/days-accodion';
import Spacer from '../../../atoms/spacer';

export default class Index extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            days: [
                {
                    day: 'day 1',
                    open: false,
                    titleText: 'Itineraries',
                    subTitleText: 'Add an Itinerary',
                    actionLink: '/product-content/details/itinerary'
                },
                {
                    day: 'day 2',
                    open: false,
                    titleText: 'Itineraries',
                    subTitleText: 'Add an Itinerary',
                    actionLink: '/product-content/details/itinerary'
                }
            ],
            bookWithoutAccomodation: '',
            expandedAll: false
        };
    }

    // addItenary (index) {};

    showModal() {
        this.setState({ show: true });
    }

    hideModal() {
        this.setState({ show: false });
    }

    removeDay(index) {
        const days = [...this.state.days];
        days.splice(index, 1);
        this.setState({
            days
        });
    }

    addDay() {
        const days = [...this.state.days];
        days.push({
            day: `day ${days.length + 1}`,
            open: false
        });
        this.setState({
            days
        });
    }

    expandAllDays(state) {
        let days = [...this.state.days];
        days = days.map((day) => {
            return {
                ...day,
                open: state
            };
        });
        this.setState({
            days,
            expandedAll: state
        });
    }

    handleClose(index) {
        let days = [...this.state.days];
        days[index].open = false;
        this.setState({
            days
        });
    }

    render() {
        const { days } = this.state;
        return (
            <>
                <div className="product-content-form">
                    <h4 className="product-content-form__title">
                        Tell us about your ‘Main Product Title’ Activity
                    </h4>
                    <p className="product-content-form__subtitle">
                        Be sure to enter all key attractions in your itinerary. This helps travelers
                        find and book your experience.
                    </p>

                    <Form className="mt-5" hideRequiredMark>
                        <DaysAccordion
                            days={days}
                            newTitleText="Itineraries"
                            newSubtitleText="Add an Itinerary"
                        />

                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <NavLink to="/accommodation">
                                    <Panel
                                        title="Accommodation"
                                        hasRecommendedBadge={true}
                                        footer={<Prompter text="Add accommodation" />}
                                    />
                                </NavLink>
                            </Col>
                        </Row>
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <NavLink to="/pricing">
                                    <Panel
                                        title="Pricing"
                                        footer={
                                            <Prompter text="Add Pricing details for your ‘Product Title’" />
                                        }
                                    />
                                </NavLink>
                            </Col>
                        </Row>
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <NavLink to="personal-care">
                                    <Panel
                                        title="Booking and Tickets"
                                        footer={
                                            <Prompter text="Add Booking and Ticketing details  for your ‘Product Title’" />
                                        }
                                    />
                                </NavLink>
                            </Col>
                        </Row>
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <NavLink to="/Covid19">
                                    <Panel
                                        title="Covid-19 Updates"
                                        footer={<Prompter text="Add Covid-19 safety updates" />}
                                    />
                                </NavLink>
                            </Col>
                        </Row>

                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <p>
                                    Enter the maximum number of spaces available to book this
                                    activity per day
                                </p>

                                <p>
                                    This will indicate the spaces available to travelers and once
                                    this is reached, this product activtity will be marked as sold
                                    out for that day
                                </p>

                                <Select className="spaces-count">
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item) => (
                                        <Select.Option key={item}>{item}</Select.Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Spacer top={36} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <p>Can travelers book this activity without acoomodation?</p>

                                <p style={{ color: '#A2792C' }}>
                                    Yes is recommeded if travelers can take part in this activity
                                    (Itineraries) without accomodation.
                                </p>

                                <div className="selector">
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                bookWithoutAccomodation: 'yes'
                                            })
                                        }
                                        className={`selector__yes ${
                                            this.state.bookWithoutAccomodation == 'yes'
                                                ? 'selector__selected'
                                                : ''
                                        }`}>
                                        {' '}
                                        Yes
                                    </div>
                                    <div
                                        onClick={() =>
                                            this.setState({
                                                bookWithoutAccomodation: 'no'
                                            })
                                        }
                                        className={`selector__no ${
                                            this.state.bookWithoutAccomodation == 'no'
                                                ? 'selector__selected'
                                                : ''
                                        }`}>
                                        {' '}
                                        No
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Spacer top={36} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title="Add Reviews"
                                    hasRecommendedBadge={true}
                                    footer={
                                        <NavLink to="/reviews">
                                            <Prompter text="Add your reviews for any/all of the activities and accomodations above" />
                                        </NavLink>
                                    }
                                />
                            </Col>
                        </Row>

                        <div className="product-content-form__actions">
                            <Button
                                className="mt-50px"
                                onClick={() => {}}
                                type="primary"
                                shape="round">
                                Update Calender & Continue
                            </Button>
                            {/* <FixedHelpButton fixed="fixed" top="50%" right="4%" /> */}
                        </div>
                    </Form>
                </div>
            </>
        );
    }
}

import { Grid, Container } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ActivitiesAccordion from './ActivitiesAccordion';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary
    },
    line1: {
        border: '1px dashed #E2C022',
        width: '280px',
        height: 0,
        marginLeft: '10px',
        marginRight: '10px'
    }
}));

export default function ActivitiesArrival() {
    const classes = useStyles();

    return (
        <Container>
            <div className={classes.root}>
                <Grid container spacing={4}>
                    <Grid item md>
                        <Paper className={classes.paper}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center'
                                }}>
                                <div className={classes.line1}></div>
                                <div
                                    style={{
                                        fontWeight: '600',
                                        fontSize: '18px',
                                        lineHeight: '22px',
                                        textAlign: 'center',
                                        color: '#333333'
                                    }}>
                                    Arrival
                                </div>
                                <div className={classes.line1}></div>
                            </div>
                            <div
                                style={{
                                    marginTop: '18px',
                                    fontWeight: 'normal',
                                    fontSize: '14px',
                                    lineHeight: '17px',
                                    color: '#4F4F4F',
                                    textAlign: 'left'
                                }}>
                                Airport transfer can be arranged at an extra cost before and after
                                the tour starts. Accommodation is included with this booking.
                                Accommodation can be arranged at an extra cost before and after the
                                tour starts.
                            </div>
                            <div
                                style={{
                                    marginTop: '23px'
                                }}>
                                <ActivitiesAccordion />
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item md>
                        <Paper className={classes.paper}>B</Paper>
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
}

import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import { Button, Tabs } from 'antd';
import './index.scss';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getFundDetail,
    getStripePaymentDetail,
    getCurretUser,
    getStripeDashboardLink,
    getVendorStripeAccountDetails,
    getStripeSingleTransaction,
    getBusinessBankAccountInfo,
    isFilledRequiedInfoForStripeOnboard,
    getVendorFundAnalysis,
    loading_
} from 'store/actions';
import PaymentsTab from './tabs/payments-tab';
import TransfersTab from './tabs/transfers-tab';
import PayoutsTab from './tabs/payouts-tab';
import RefundsTab from './tabs/refunds-tab';
import BalanceDetailSection from './balance-detail-section';
import BanksDetailSection from './banks-detail-section';
import PaymentDetailModal from './payment-detail-modal';
import PayStackFinance from './paystack/paystack';
import { AddPayoutSettingsModal } from 'components/organisms';
import { AlertComponent, Loader } from 'components/atoms';
import Info from 'assets/images/carbon_information.svg';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { EditOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;

class Finance extends Component {
    static contextType = ComponentPermissionContext;
    state = {
        showModal: false,
        showDetailsModal: false,
        activeTab: '1',
        stripeRequiedDataAlert: null
    };

    componentDidMount() {
        const {
            getCurretUser,
            getBusinessBankAccountInfo,
            my_profile,
            getFundDetail,
            getVendorStripeAccountDetails,
            getVendorFundAnalysis
        } = this.props;
        getCurretUser();
        getBusinessBankAccountInfo();

        if (!my_profile?.vendor?.stripe_connected_account_id)
            this.props.isFilledRequiedInfoForStripeOnboard().then((res) => {
                if (res && Array.isArray(res) && res.length > 0)
                    this.setState({ stripeRequiedDataAlert: res });
            });

        if (my_profile?.vendor?.stripe_connected_account_id) {
            getFundDetail();
            getVendorStripeAccountDetails();
            getVendorFundAnalysis();
        }
    }

    componentDidUpdate(prevProp, prevState) {
        const { my_profile, getVendorStripeAccountDetails, getFundDetail, getVendorFundAnalysis } =
            this.props;
        const { paymentDetailModal } = this.state;
        if (paymentDetailModal && paymentDetailModal.id)
            if (!prevState.paymentDetailModal) {
                this.props.getStripeSingleTransaction({
                    id: paymentDetailModal.id,
                    url: paymentDetailModal.tab
                });
                if (paymentDetailModal.tab === 'charge' || paymentDetailModal.tab === 'refunds')
                    this.props.getStripePaymentDetail(paymentDetailModal.id, paymentDetailModal);
            } else if (paymentDetailModal.id !== prevState.paymentDetailModal?.id) {
                this.props.getStripeSingleTransaction({
                    id: paymentDetailModal.id,
                    url: paymentDetailModal.tab
                });
                if (paymentDetailModal.tab === 'charge' || paymentDetailModal.tab === 'refunds')
                    this.props.getStripePaymentDetail(paymentDetailModal.id, paymentDetailModal);
            }

        if (
            my_profile?.vendor?.stripe_connected_account_id !==
                prevProp?.my_profile?.vendor?.stripe_connected_account_id &&
            my_profile?.vendor?.stripe_connected_account_id
        ) {
            getFundDetail();
            getVendorStripeAccountDetails();
            getVendorFundAnalysis();
        }
    }

    handleRetrieveClick = () => {
        this.setState({ showModal: true });
    };

    toggle = (activeKey) => {
        this.setState({
            activeTab: activeKey
        });
    };

    redirectToStripeDashboard = () => {
        this.props.getStripeDashboardLink().then((res) => {
            if (res?.url) {
                window.location.assign(res?.url);
                // this.props.history.push(res?.url)
            }
        });
    };

    mapAccountKeys(key) {
        if (key === 'businessInformation.businessTaxId') return 'Business Tax Id';
        else if (key === 'businessInformation.businessName') return 'Business Name';
        else if (key === 'businessInformation.companyWebsite') return 'Company Website';
        else if (key === 'businessInformation.businessTradingName') return 'Business Trading Name';
        else if (key === 'businessInformation.customerServiceEmail')
            return 'Customer Service Email';
        else if (key === 'businessInformation.customerServicePhoneNumber')
            return 'Customer Service Phone Number';
        else if (key === 'vendor.email') return 'Vendor Email';
    }

    closeAndUpdateUser = () => {
        this.props.loading_(true);
        this.props.getCurretUser().then(() => {
            this.setState({ showPayoutModal: false });
            this.props.loading_(false);
        });
    };

    render() {
        const { my_profile, loading, stripe_account_detail, fund_analysis } = this.props;
        const { stripeRequiedDataAlert } = this.state;
        return (
            <>
                {!my_profile?.vendor?.stripe_connected_account_id &&
                    !my_profile?.vendor?.paystack_account_id && (
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center text-center">
                            <div style={{ maxWidth: 600 }}>
                                <h4> Please setup your payment account </h4>
                                <Button
                                    disabled={Array.isArray(stripeRequiedDataAlert)}
                                    id="payoutSettings"
                                    color="primary"
                                    onClick={() => this.setState({ showPayoutModal: true })}>
                                    Add payout settings
                                </Button>
                                <div className="my-2"></div>
                                {stripeRequiedDataAlert && (
                                    <AlertComponent
                                        color="primary"
                                        icon={<img src={Info} alt="" />}>
                                        <div className="text-left">
                                            <h3 className="alert-heading">Information requied</h3>
                                            <p className="fs-13 mb-8px">
                                                Please fill the required information, before
                                                onboarding.
                                            </p>
                                            {Array.isArray(stripeRequiedDataAlert) &&
                                                stripeRequiedDataAlert.map((val, ind) => (
                                                    <NavLink
                                                        key={ind}
                                                        className="alert-action-link"
                                                        to="/account">
                                                        <EditOutlined className="mr-2 mb-1" />

                                                        {this.mapAccountKeys(val)}
                                                    </NavLink>
                                                ))}
                                        </div>
                                    </AlertComponent>
                                )}
                            </div>
                        </div>
                    )}
                <div className={`${this.context.assignComponentPermission('finance')}`}>
                    {loading && <Loader typeTransparent={true} />}

                    {my_profile?.vendor?.stripe_connected_account_id ? (
                        <div>
                            <BalanceDetailSection
                                stripe_account_detail={stripe_account_detail}
                                fund_analysis={fund_analysis}
                                my_profile={my_profile}
                                redirectToStripeDashboard={this.redirectToStripeDashboard}
                                payoutsAndTransitTobank={this.props.fund_detail}
                            />
                            <div className="my-5"></div>

                            <div
                                className={`mb-4  ${this.context.assignComponentPermission(
                                    'finance-activity'
                                )}  `}>
                                <div className="d-flex gap-3">
                                    <div className="fs-20 fw-700 pb-2">
                                        <span>Activity</span>
                                    </div>
                                </div>

                                <Tabs
                                    size="small"
                                    className="padded-tabs has-filter-top"
                                    defaultActiveKey="payments">
                                    <TabPane tab="Payments" key="payments">
                                        <PaymentsTab
                                            onClickRow={(data) =>
                                                this.setState({ paymentDetailModal: data })
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tab="Transfers" key="transfers">
                                        <TransfersTab
                                            onClickRow={(data) =>
                                                this.setState({ paymentDetailModal: data })
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tab="Payouts" key="payouts">
                                        <PayoutsTab
                                            onClickRow={(data) =>
                                                this.setState({ paymentDetailModal: data })
                                            }
                                        />
                                    </TabPane>
                                    <TabPane tab="Refunds" key="refunds">
                                        <RefundsTab
                                            onClickRow={(data) =>
                                                this.setState({ paymentDetailModal: data })
                                            }
                                        />
                                    </TabPane>
                                </Tabs>
                            </div>

                            <BanksDetailSection
                                stripe_account_detail={stripe_account_detail}
                                redirectToStripeDashboard={this.redirectToStripeDashboard}
                            />
                        </div>
                    ) : my_profile?.vendor?.paystack_account_id ? (
                        <PayStackFinance />
                    ) : null}

                    <PaymentDetailModal
                        setState={(data) => this.setState(data)}
                        paymentDetailModal={this.state.paymentDetailModal}
                        data={this.props.stripe_payment_detail}
                        paymentDetail={this.props.stripe_single_tran_detail}
                    />

                    {this.state.showPayoutModal && (
                        <AddPayoutSettingsModal
                            title="Add Payout Settings"
                            description="select your country"
                            showModal={this.state.showPayoutModal}
                            toggleModal={() =>
                                this.setState((prevState) => {
                                    return { showPayoutModal: !prevState.showPayoutModal };
                                })
                            }
                            closeAndUpdateUser={this.closeAndUpdateUser}
                        />
                    )}

                    {/* View Stats Modal */}
                </div>
            </>
        );
    }
}

Finance.propTypes = {
    getFundDetail: PropTypes.func,
    fund_detail: PropTypes.object,
    getStripePaymentDetail: PropTypes.func,
    stripe_payment_detail: PropTypes.object,
    loading: PropTypes.bool,
    stripe_account_detail: PropTypes.object,
    getCurretUser: PropTypes.func,
    my_profile: PropTypes.object,
    getVendorStripeAccountDetails: PropTypes.func,
    getStripeDashboardLink: PropTypes.func,
    getStripeSingleTransaction: PropTypes.func,
    stripe_single_tran_detail: PropTypes.object,
    getBusinessBankAccountInfo: PropTypes.func,
    isFilledRequiedInfoForStripeOnboard: PropTypes.func,
    getVendorFundAnalysis: PropTypes.func,
    fund_analysis: PropTypes.object,
    loading_: PropTypes.func
};

const mapStateToProps = (state) => ({
    fund_detail: state.profile.fund_detail,
    stripe_payment_detail: state.profile.stripe_payment_detail,
    my_profile: state.auth.my_profile,
    loading: state.loading,
    stripe_account_detail: state.profile.stripe_account_detail,
    stripe_single_tran_detail: state.profile.stripe_single_tran_detail,
    fund_analysis: state.profile.fund_analysis
});

const mapDispatch = (dispatch) => ({
    getFundDetail: () => dispatch(getFundDetail()),
    getStripePaymentDetail: (id, data) => dispatch(getStripePaymentDetail(id, data)),
    getCurretUser: () => dispatch(getCurretUser()),
    getStripeDashboardLink: () => dispatch(getStripeDashboardLink()),
    getVendorStripeAccountDetails: () => dispatch(getVendorStripeAccountDetails()),

    getStripeSingleTransaction: (query) => dispatch(getStripeSingleTransaction(query)),
    getBusinessBankAccountInfo: () => dispatch(getBusinessBankAccountInfo()),
    isFilledRequiedInfoForStripeOnboard: () => dispatch(isFilledRequiedInfoForStripeOnboard()),
    getVendorFundAnalysis: () => dispatch(getVendorFundAnalysis()),

    loading_: (bool) => dispatch(loading_(bool))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Finance));

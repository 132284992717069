export const CALENDAR_DATA = 'CALENDAR_DATA';
export const ARCHIVED_CALENDAR_DATA = 'ARCHIVED_CALENDAR_DATA';
export const CREATE_CALENDAR = 'CREATE_CALENDAR';
export const UPDATE_SUCCESS_CALENDAR = 'UPDATE_SUCCESS_CALENDAR';

export const CREATE_CALENDAR_ERROR = 'CREATE_CALENDAR_ERROR';
export const UPDATE_ERROR_CALENDAR = 'UPDATE_ERROR_CALENDAR';
export const BUSINESS_DATA = 'BUSINESS_DATA';
export const UPDATE_BUSINESS_DATA = 'UPDATE_BUSINESS_DATA';
export const UPDATE_BUSINESS_DATA_LOADING = 'UPDATE_BUSINESS_DATA_LOADING';
export const PRODUCT_CATEGORIES = 'PRODUCT_CATEGORIES';
export const PRODUCT_IN_DAY_CATEGORIES = 'PRODUCT_IN_DAY_CATEGORIES';
export const UPDATE_PRODUCTS_CALENDAR = 'UPDATE_PRODUCTS_CALENDAR';
export const UPDATE_PRODUCTS_CALENDAR_ERROR = 'UPDATE_PRODUCTS_CALENDAR_ERROR';
export const UPDATE_PRODUCTS_CUSTOMER_BOOK = 'UPDATE_PRODUCTS_CUSTOMER_BOOK';
export const UPDATE_PRODUCTS_CUSTOMER_BOOK_ERROR = 'UPDATE_PRODUCTS_CUSTOMER_BOOK_ERROR';
export const RESET_CALENDAR_REDUCER = 'RESET_CALENDAR_REDUCER';
export const UPDATE_PRODUCTS_PERSONAL_INFO = 'UPDATE_PRODUCTS_PERSONAL_INFO';
export const UPDATE_PRODUCTS_PERSONAL_INFO_ERROR = 'UPDATE_PRODUCTS_PERSONAL_INFO_ERROR';
export const UPDATE_CALENDAR_TITLE = 'UPDATE_CALENDAR_TITLE';
export const GET_CALENDAR_BY_ID = 'GET_CALENDAR_BY_ID';
export const GET_ALL_CALENDAR_PRODUCTS = 'GET_ALL_CALENDAR_PRODUCTS';
export const UPDATE_CALENDAR_RESTING_TIME = 'UPDATE_CALENDAR_RESTING_TIME';
export const UPDATE_CALENDAR_LENGTH = 'UPDATE_CALENDAR_LENGTH';
export const UPDATE_CALENDAR_LG_SUCCESS = 'UPDATE_CALENDAR_LG_SUCCESS';
export const GET_CALENDAR_BY_UUID = 'GET_CALENDAR_BY_UUID';
export const GET_EXISTING_PRODUCTS = 'GET_EXISTING_PRODUCTS';
export const UPDATE_PRODUCT_HOUSE_RULES = 'UPDATE_PRODUCT_HOUSE_RULES';
export const UPDATE_PRODUCT_HOUSE_RULES_ERROR = 'UPDATE_PRODUCT_HOUSE_RULES_ERROR';
export const UPDATE_PRODUCT_ROOMS = 'UPDATE_PRODUCT_ROOMS';
export const UPDATE_PRODUCT_ROOMS_ERROR = 'UPDATE_PRODUCT_ROOMS_ERROR';
export const UPDATE_PRODUCT_FACILITIES = 'UPDATE_PRODUCT_FACILITIES';
export const UPDATE_PRODUCT_FACILITIES_ERROR = 'UPDATE_PRODUCT_FACILITIES_ERROR';
export const UPDATE_PRODUCT_BREAKFAST = 'UPDATE_PRODUCT_BREAKFAST';
export const UPDATE_PRODUCT_BREAKFAST_ERROR = 'UPDATE_PRODUCT_BREAKFAST_ERROR';
export const GET_INCLUSION_CATEGORIES = 'GET_INCLUSION_CATEGORIES';
export const GET_ADVENTURE_CATEGORIES = 'GET_ADVENTURE_CATEGORIES';
export const GET_OCCASSION_CATEGORIES = 'GET_OCCASSION_CATEGORIES';
export const GET_SIGHT_CATEGORIES = 'GET_SIGHT_CATEGORIES';
export const DUPLICATE_CALENDAR = 'DUPLICATE_CALENDAR';
export const GET_ALL_VENDOR_CALENDARS = 'GET_ALL_VENDOR_CALENDARS';

export const GET_CALENDAR_FOR_FILTER = 'GET_CALENDAR_FOR_FILTER';

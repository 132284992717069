/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Proptypes from 'prop-types';
import { Button, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import { Form, Radio } from 'antd';

import {
    updateProductMealsDrinks,
    updateProductLanguageOffers,
    updateProductInclusionExclusion,
    updateProductCovid,
    deleteCovidUpdates,
    deleteProductLanguageOffers,
    deleteProductMealsDrinks,
    deleteProductInclusionExclusion
} from 'store/actions';

import {
    AddButton,
    AddLanguageButton,
    AddInclusionExclusionButton,
    AddCovidButton,
    AddReviewButton
} from 'components/atoms';

import {
    MealsDrinksDrawer,
    LanguageOfferedDrawer,
    InclusionAndExclusionDrawer,
    CovidModal,
    AddReviewsModal,
    Feedback
} from 'components/molecules';

import { validateRequiredFormField } from 'utils/validation';
import { getItineraryState, getItineraryFormPageTwoState } from 'utils/helper';
import MarketGuide from './market-guide';
import ItineraryFormPageTwoPartTwo from './itinerary-form-page-two-part-two';
import ConfirmationModal from 'components/molecules/modals/confirmation-modal';

class ItineraryFormPageTwo extends React.Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);
        this.state = getItineraryFormPageTwoState();
        this.guideMeelLocationInput = React.createRef();
    }

    componentDidMount() {
        const { currentProduct, showUpdateProductFields } = this.props;
        if (currentProduct) {
            this.onSetMainProduct(currentProduct);
        }
        // Open modals for edit product here
        // page 2
        if (showUpdateProductFields?.editAdmissionPrice) this.props.setState({ page: 2 });
        if (showUpdateProductFields?.editMealsAndDrinks) {
            this.setState({ openMealsAndDrinks: true });
            setTimeout(() => this.setState({ mealsAndDrinksEdit: 0 }), 10);
        }
        if (showUpdateProductFields?.editLanguagesOffered)
            this.setState({ showLanguageSelectModal: true });
        if (showUpdateProductFields?.editCovidDetails) this.setState({ isOpenCovid: true });
        if (showUpdateProductFields?.editCalendarSeason)
            this.setState({ isOpenCalendarSeason: true });
        if (showUpdateProductFields?.editAdventures) this.setState({ isOpenAddAdventure: true });
        if (showUpdateProductFields?.editOccassions) this.setState({ isOpenAddOccassion: true });
        if (showUpdateProductFields?.editSights) this.setState({ isOpenAddTopSights: true });
        if (showUpdateProductFields?.editRating) this.setState({ isOpenAddReviews: true });
        if (showUpdateProductFields?.editTravellerInfo)
            this.setState({ isOpenAddInformation: true });

        if (showUpdateProductFields?.editExclusions || showUpdateProductFields?.editInclusions)
            this.setState({ isOpenInclusionExclusion: true });
        if (showUpdateProductFields?.editGuideMeetLocation)
            this.guideMeelLocationInput.current &&
                this.guideMeelLocationInput.current.scrollIntoView({ behaviour: 'smooth' });
    }

    componentDidUpdate(prevProps) {
        const { currentProduct, edit_itineraries } = this.props;

        if (edit_itineraries && prevProps.edit_itineraries !== edit_itineraries) {
            this.onSetMainProduct(edit_itineraries);
        }

        //existing product
        if (
            (prevProps.currentProduct === null && currentProduct) ||
            (prevProps.currentProduct &&
                currentProduct &&
                prevProps.currentProduct.id !== currentProduct.id)
        ) {
            this.onSetMainProduct(currentProduct);
        }
    }

    componentDidCatch(error, info) {
        // Display fallback UI
        // You can also log the error to an error reporting service
        console.log('Error in Catch', error, info);
    }

    //set import data
    onSetMainProduct = async (item) => {
        let anotherStart = [];
        // if (item.meetup_points && item.meetup_points.length > 0) {
        //     anotherStart = item.meetup_points;
        //     anotherStart.splice(0, 1);
        //     this.setState({ isCheckBox: 3 });
        // }

        let checkBoxValues = [];
        if (item?.meetup_info?.at_accommodation) {
            checkBoxValues.push('1');
        }
        if (item?.meetup_info?.allow_users_to_enter_pickup_location) {
            checkBoxValues.push('2');
        }
        if (item?.meetup_info?.at_starting_point) {
            checkBoxValues.push('3');
        }
        if (item?.meetup_info?.points && item?.meetup_info?.points.length > 0) {
            anotherStart = item?.meetup_info?.points || [];
            //anotherStart.splice(0, 1);
        }
        this.setState({ isCheckBox: checkBoxValues });

        let itineraryState = await getItineraryState({ item, anotherStart });

        this.setState(itineraryState);
    };

    onOpenMealsAndDrinks = () =>
        this.setState({ openMealsAndDrinks: !this.state.openMealsAndDrinks });

    //remove meals and drinks
    deleteMealsAndDrinks = (index) => {
        const { mealsAndDrinks } = this.state;
        const { currentProduct, deleteProductMealsDrinks } = this.props;
        let deleteData = {
            id: currentProduct.id,
            data: {
                meals_and_drinks: [mealsAndDrinks[index]]
            }
        };
        deleteProductMealsDrinks(deleteData).then((res) => {
            if (res === true) {
                const tmp = mealsAndDrinks;
                tmp.splice(index, 1);
                this.setState({ mealsAndDrinks: [...tmp] });
            }
        });
    };

    //set meals and drinks
    addMealsAndDrinks = (mealsAndDrinks) => {
        const { currentProduct, updateProductMealsDrinks } = this.props;

        const mappedMealsAndDrinks = mealsAndDrinks.map((md) => {
            return {
                ...md,
                photos_and_videos: md.photos_and_videos.map((pv) => ({
                    type: pv.type,
                    url: pv.url
                }))
            };
        });

        const data = {
            meals_and_drinks: mappedMealsAndDrinks
        };
        updateProductMealsDrinks(data, currentProduct.id).then((res) => {
            if (res?.payload)
                this.setState({ mealsAndDrinks: mealsAndDrinks, mealsAndDrinksEdit: null });
        });
    };

    onOpenLanguageOffered = () =>
        this.setState({ showLanguageSelectModal: !this.state.showLanguageSelectModal });
    //remove languages
    deleteLanguages = () => {
        // const { languages } = this.state;
        // const tmp = languages;
        // tmp.splice(index, 1);
        const { currentProduct, deleteProductLanguageOffers } = this.props;
        const tmp = [];
        deleteProductLanguageOffers(currentProduct.id).then((res) => {
            if (res === true) this.setState({ languages: [...tmp] });
        });
    };

    addLanguageOffered = (formData) => {
        const { currentProduct, updateProductLanguageOffers } = this.props;

        let language_offers = {
            is_language_offered: formData.isLanguageOffered
        };
        if (formData?.isLanguageOffered)
            language_offers = {
                is_language_offered: formData.isLanguageOffered,
                languages: formData.languages,
                ...formData.languageGuide
            };

        updateProductLanguageOffers({ language_offers }, currentProduct.id).then((res) => {
            if (res?.payload)
                this.setState({
                    languages: formData.isLanguageOffered ? formData.languages : [],
                    languageGuide: formData.languageGuide,
                    isLanguageOffered: formData.isLanguageOffered
                });
        });
    };

    onCloseInclusionExclusion = () =>
        this.setState({ isOpenInclusionExclusion: !this.state.isOpenInclusionExclusion });

    //set inclusions and exclusions
    addInclusionExclusion(inclusionExclusion) {
        const { updateProductInclusionExclusion, currentProduct } = this.props;

        if (inclusionExclusion.inclusions.length > 0) {
            let inclusionParams = {
                type: 'inclusion',
                data: {
                    inclusions: inclusionExclusion.inclusions
                },
                id: currentProduct.id
            };
            updateProductInclusionExclusion(inclusionParams).then((res) => {
                if (res?.payload)
                    this.setState({
                        inclusion: inclusionExclusion.inclusions,
                        exclusion: inclusionExclusion.exclusions,
                        isOpenInclusionExclusion: false
                    });
            });
        }
        if (inclusionExclusion.exclusions.length > 0) {
            let exclusionParams = {
                type: 'exclusion',
                data: {
                    exclusions: inclusionExclusion.exclusions
                },
                id: currentProduct.id
            };
            updateProductInclusionExclusion(exclusionParams).then((res) => {
                if (res?.payload)
                    this.setState({
                        inclusion: inclusionExclusion.inclusions,
                        exclusion: inclusionExclusion.exclusions,
                        isOpenInclusionExclusion: false
                    });
            });
        }

        this.formRef.current.setFieldsValue({
            'Inclusion & Exclusion': [
                ...inclusionExclusion.inclusions,
                ...inclusionExclusion.exclusions
            ]
        });
        this.formRef.current.validateFields();
    }

    addCovidInformation = (value) => {
        const { updateProductCovid, currentProduct } = this.props;
        this.formRef.current.setFieldsValue({
            covid: value
        });
        let data = {
            covid_19_updates: value
        };

        updateProductCovid({
            data,
            id: currentProduct.id
        }).then((res) => {
            if (res?.payload) this.setState({ covid: value, isOpenCovid: false });
        });
    };

    addReviews = (value) => {
        this.setState({ reviews: value, isOpenAddReviews: false });
    };

    onDeleteAnotherStart = (index) => {
        const tmpData = [...this.state.anotherStart];
        tmpData.splice(index, 1);
        this.setState({ anotherStart: tmpData });
    };

    onAddAnotherStart = () => {
        const tmp = this.state.anotherStart;
        tmp.push('');
        this.setState({ anotherStart: [...tmp] });
    };

    onClear = () => {
        this.formRef.current.resetFields();
        this.setState(getItineraryFormPageTwoState);
    };

    onSave = () => {
        this.props.onFinish({ state: this.state });

        if (!this.props.state?.page === 2) {
            this.onClear();
        }
    };

    compareData = () => {
        const { currentProduct } = this.props;
        let admissionWithPrice =
            currentProduct?.is_admission_included === 'Yes'
                ? 1
                : currentProduct?.is_admission_included === 'No'
                ? 2
                : currentProduct?.is_admission_included === 'NIL'
                ? 3
                : null;
        let admission_with_price_state = this.state.admission_with_price;
        if (admissionWithPrice != admission_with_price_state) {
            this.setState({ confirmationModal: true });
        } else {
            this.onSave();
        }
    };

    onFinish = async () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.onSave();
    };

    deleteCovidInfo = () => {
        const { currentProduct, deleteCovidUpdates } = this.props;
        deleteCovidUpdates(currentProduct.id).then((res) => {
            if (res === true) this.setState({ covid: null });
        });
    };

    onDeleteInclusionExclusion = (type) => {
        const {
            currentProduct,

            deleteProductInclusionExclusion
        } = this.props;
        deleteProductInclusionExclusion({
            id: currentProduct.id,
            type: type
        }).then((res) => {
            if (res === true) this.setState({ [type]: [] });
        });
    };

    render() {
        const {
            admission_with_price,
            mealsAndDrinks,
            openMealsAndDrinks,
            mealsAndDrinksEdit,
            languages,
            showLanguageSelectModal,
            languageGuide,
            inclusion,
            exclusion,
            isOpenInclusionExclusion,
            covid,
            isOpenCovid,
            reviews,
            isOpenAddReviews
        } = this.state;
        const { package_detail } = this.props;

        return (
            <>
                <Form
                    className="create-product d-flex flex-column overflow-y-auto"
                    ref={this.formRef}
                    name="itinerary-ref"
                    onFinish={this.onFinish}
                    // initialValues={{
                    //     ...this.state
                    // }}
                >
                    <ModalBody className="pt-0">
                        <div className="input-title">
                            Is admission to this place included in the price of your product?
                        </div>
                        <Form.Item
                            name="admission"
                            // rules={[
                            //     { required: true, message: 'Please fill this field in' }]}
                        >
                            <Radio
                                onChange={() =>
                                    this.setState({
                                        admission_with_price: 1
                                    })
                                }
                                className="radio-input-block"
                                value={1}
                                checked={admission_with_price === 1}>
                                Yes
                            </Radio>
                            <Radio
                                onChange={() =>
                                    this.setState({
                                        admission_with_price: 2
                                    })
                                }
                                className="radio-input-block"
                                value={2}
                                checked={admission_with_price === 2}>
                                No
                            </Radio>
                            <Radio
                                onChange={() =>
                                    this.setState({
                                        admission_with_price: 3
                                    })
                                }
                                className="radio-input-block"
                                value={3}
                                checked={admission_with_price === 3}>
                                N/A
                            </Radio>
                        </Form.Item>

                        <Form.Item
                            name="mealsAndDrinks"
                            className="mb-0"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please fill this field in'
                            //     }]}
                        >
                            <AddButton
                                name="Meals & Drinks"
                                data={mealsAndDrinks ? mealsAndDrinks : []}
                                onEdit={(index) => {
                                    this.setState({
                                        mealsAndDrinksEdit: index,
                                        openMealsAndDrinks: true
                                    });
                                }}
                                onDelete={(index) => this.deleteMealsAndDrinks(index)}
                                onClick={this.onOpenMealsAndDrinks}
                            />
                        </Form.Item>

                        <MealsDrinksDrawer
                            isOpen={openMealsAndDrinks}
                            allMealsAndDrinks={mealsAndDrinks}
                            onEdit={mealsAndDrinksEdit}
                            itineraries={this.props.itineraries}
                            onClose={() => {
                                this.onOpenMealsAndDrinks();
                                this.setState({ mealsAndDrinksEdit: null });
                            }}
                            onSave={(value) => this.addMealsAndDrinks(value)}
                            hideImport
                        />

                        <Form.Item
                            name="languages"
                            className="mb-0"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please fill this field in'
                            //     }]}
                        >
                            <AddLanguageButton
                                name="Languages"
                                data={languages ? languages : []}
                                isLanguageOffered={this.state.isLanguageOffered}
                                onEdit={this.onOpenLanguageOffered}
                                onDelete={this.deleteLanguages}
                                onClick={this.onOpenLanguageOffered}
                            />
                        </Form.Item>

                        <LanguageOfferedDrawer
                            isOpen={showLanguageSelectModal}
                            selectedLanguages={languages}
                            languageGuide={languageGuide}
                            isLanguageOffered={this.state.isLanguageOffered}
                            onSave={(value, guide, isLanguageOffered) =>
                                this.addLanguageOffered({
                                    languages: value,
                                    languageGuide: guide,
                                    isLanguageOffered
                                })
                            }
                            onClose={this.onOpenLanguageOffered}
                            validateRequiredFormField={validateRequiredFormField}
                        />

                        <Form.Item
                            name="Inclusion & Exclusion"
                            className="mb-0"
                            trigger={['onFinish', 'onChange']}
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please fill this field in'
                            //     }]}
                        >
                            <AddInclusionExclusionButton
                                inclusion={inclusion ? inclusion : []}
                                exclusion={exclusion ? exclusion : []}
                                onEdit={this.onCloseInclusionExclusion}
                                name="Inclusion & Exclusion"
                                onClick={this.onCloseInclusionExclusion}
                                onDelete={(type) => this.onDeleteInclusionExclusion(type)}
                            />
                        </Form.Item>

                        <InclusionAndExclusionDrawer
                            data={[inclusion, exclusion]}
                            itineraries={this.props.itineraries}
                            onClose={this.onCloseInclusionExclusion}
                            showModal={isOpenInclusionExclusion}
                            onSave={(value) => this.addInclusionExclusion(value)}
                            hideImport
                        />

                        {package_detail && Object.keys(package_detail).length === 0 && (
                            <>
                                <Form.Item
                                    name="covid"
                                    className="mb-0"
                                    // rules={[
                                    //     {
                                    //         required: covid ? false : true,
                                    //         message: 'Please fill this field in'
                                    //     }]}
                                >
                                    <AddCovidButton
                                        data={covid ? covid : null}
                                        onDelete={this.deleteCovidInfo}
                                        name="Covid-19 Updates"
                                        onClick={() => this.setState({ isOpenCovid: true })}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="reviews"
                                    className="mb-0"
                                    // rules={[
                                    //     {
                                    //         required: reviews ? false : true,
                                    //         message: 'Please fill this field in'
                                    //     }]}
                                >
                                    <AddReviewButton
                                        name="Add Reviews"
                                        data={reviews}
                                        onDelete={() => this.setState({ reviews: null })}
                                        isRecommended={true}
                                        onClick={() => this.setState({ isOpenAddReviews: true })}
                                    />
                                </Form.Item>
                            </>
                        )}

                        <CovidModal
                            isOpen={isOpenCovid}
                            onClose={() => this.setState({ isOpenCovid: false })}
                            data={covid}
                            calendarData={this.props.calendar_data}
                            onSave={(value) => this.addCovidInformation(value)}
                            submitButtonName="Save"
                            hideImport
                        />

                        <AddReviewsModal
                            isOpen={isOpenAddReviews}
                            onSave={(value) => this.addReviews(value)}
                            onClose={() => this.setState({ isOpenAddReviews: false })}
                            submitButtonName="Save"
                        />

                        <div ref={this.guideMeelLocationInput} />

                        <MarketGuide
                            formRef={this.formRef}
                            setState={(objOrFun) => this.setState(objOrFun)}
                            state={this.state}
                            onDeleteAnotherStart={this.onDeleteAnotherStart}
                            onAddAnotherStart={this.onAddAnotherStart}
                        />

                        <ItineraryFormPageTwoPartTwo
                            setState={(objOrFun) => this.setState(objOrFun)}
                            state={this.state}
                            itineraries={this.props.itineraries}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            disabled={this.props.loading}
                            color="primary"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="submit">
                            Update Calender & Continue
                            {this.props.loading && (
                                <Spinner className="ml-2" size="sm" color="primary" />
                            )}
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="button"
                            onClick={() =>
                                this.props.setState({
                                    isConfirmationModalOpen: true
                                })
                            }>
                            Cancel
                        </Button>
                        <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                    </ModalFooter>
                </Form>
                <ConfirmationModal
                    isModalOpen={this.state?.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.onSave();
                    }}
                />
            </>
        );
    }
}

ItineraryFormPageTwo.propTypes = {
    state: Proptypes.object,
    setState: Proptypes.func,
    onFinish: Proptypes.func,
    edit_itineraries: Proptypes.object,
    selected_existing_product: Proptypes.bool,
    itineraries: Proptypes.any,
    updateProductMealsDrinks: Proptypes.func,
    updateProductLanguageOffers: Proptypes.func,
    calendar_data: Proptypes.object,
    updateProductCovid: Proptypes.func,
    updateProductInclusionExclusion: Proptypes.func,
    deleteCovidUpdates: Proptypes.func,
    deleteProductLanguageOffers: Proptypes.func,
    deleteProductMealsDrinks: Proptypes.func,
    deleteProductInclusionExclusion: Proptypes.func,
    loading: Proptypes.bool,
    package_detail: Proptypes.any,
    showUpdateProductFields: Proptypes.any,
    currentProduct: Proptypes.object
};

const mapStateToProps = (state) => ({
    edit_itineraries: state.contentDetail.edit_itineraries,
    itineraries: state.contentDetail.itineraries,
    selected_existing_product: state.product.selected_existing_product,
    calendar_data: state.calendar.calendar_data,
    package_detail: state.product.package_detail,
    showUpdateProductFields: state?.product?.showUpdateProductFields,
    loading: state.loading,
    currentProduct: state.product.currentProduct
});

const mapDispatch = (dispatch) => ({
    updateProductMealsDrinks: (data, id) => dispatch(updateProductMealsDrinks(data, id)),
    updateProductLanguageOffers: (data, id) => dispatch(updateProductLanguageOffers(data, id)),
    updateProductInclusionExclusion: (data) => dispatch(updateProductInclusionExclusion(data)),
    updateProductCovid: (data) => dispatch(updateProductCovid(data)),
    deleteCovidUpdates: (id) => dispatch(deleteCovidUpdates(id)),
    deleteProductLanguageOffers: (id) => dispatch(deleteProductLanguageOffers(id)),
    deleteProductMealsDrinks: (data) => dispatch(deleteProductMealsDrinks(data)),
    deleteProductInclusionExclusion: (data) => dispatch(deleteProductInclusionExclusion(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ItineraryFormPageTwo));

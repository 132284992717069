import React from 'react';
import PropTypes from 'prop-types';

export default function InputBox(props) {
    return (
        <div style={{ paddingLeft: props.padleft }} className="form-check input-box">
            <input
                onChange={props.onChange}
                type="checkbox"
                className="form-check-input"
                id="exampleCheck1"
            />
            <label
                style={{ fontWeight: props.fontWeight }}
                className="form-check-label"
                htmlFor="exampleCheck1">
                {props.other}
            </label>
        </div>
    );
}

InputBox.propTypes = {
    padleft: PropTypes.string,
    onChange: PropTypes.func,
    fontWeight: PropTypes.string,
    other: PropTypes.string
};

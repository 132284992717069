/* eslint-disable no-prototype-builtins */
import moment from 'moment';
export const websiteRegex =
    '^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?';

export const emailRegex =
    "/^[a-zA-Z0-9.#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/";

export const getQueryString = ({ data, url }) => {
    if (data && data.length > 0) {
        let args = data[0];
        if (Object.keys(args).length > 0) {
            Object.keys(args).forEach((key, index) => {
                if (index == 0) {
                    url += `?${key}=${args[key]}`;
                } else {
                    url += `&${key}=${args[key]}`;
                }
            });
        }
    }
    return url;
};

export const checkIfHaveParams = ({ data, param }) => {
    let hasParam = false;
    if (Array.isArray(data) && data.length > 0) {
        let items = data[0];
        if (items.hasOwnProperty(param)) {
            hasParam = true;
        }
    }
    return hasParam;
};

export const validatePassword = (rule, value) => {
    var p = value;
    return new Promise((resolve, reject) => {
        if (p.length < 8) {
            return reject('Your password must be at least 8 characters');
        } else if (/[a-z]/.test(p) < false) {
            return reject('Your password must contain at least one lowercase letter.');
        } else if (/[A-Z]/.test(p) === false) {
            return reject('Your password must contain at least one uppercase letter.');
        } else if (p.search(/[?=.*?[#?!@$%^&*-]/i) < 0) {
            return reject('Your password must contain at least one special letter.');
        } else if (p.search(/[0-9]/) < 0) {
            return reject('Your password must contain at least one digit.');
        } else {
            return resolve();
        }
    });
};

export const validatePasswordSignin = (rule, value) => {
    var p = value;
    return new Promise((resolve, reject) => {
        if (p.length <= 0) {
            return reject('Please enter your password');
        } else if (/[a-z]/.test(p) < false) {
            return reject('Your password must contain at least one lowercase letter.');
        } else if (/[A-Z]/.test(p) === false) {
            return reject('Your password must contain at least one uppercase letter.');
        } else if (p.search(/[?=.*?[#?!@$%^&*-]/i) < 0) {
            return reject('Your password must contain at least one special letter.');
        } else if (p.search(/[0-9]/) < 0) {
            return reject('Your password must contain at least one digit.');
        } else {
            return resolve();
        }
    });
};

export function validateRequiredFormField({ data, fieldType }) {
    if (fieldType === 'ARRAY')
        if (!Array.isArray(data)) return Promise.reject(new Error('Please fill this field in'));
        else if (data.length < 1) return Promise.reject(new Error('Select atleast one'));
    if (fieldType === 'OBJECT')
        if (!data) return Promise.reject(new Error('Please fill this field in'));
        else if (Object.keys(data).length < 1)
            return Promise.reject(new Error('Please fill this field in'));

    return Promise.resolve();
}

export function validateTravellerPersonalInfoFormField({ data }) {
    if (!Array.isArray(data)) return Promise.reject(new Error('Please fill this field in'));
    else if (
        data.includes('Full Name') ||
        data.includes('Weights') ||
        data.includes('Heights') ||
        data.includes('Date of Birth') ||
        data.includes('Passport Details')
    )
        return Promise.resolve();
    else return Promise.reject(new Error('Select atleast one'));
}

/**
 *
 * @param {object} errorFields - contains an object you want to test
 * @returns {Array<string>} [string]
 */
export function checkErrors(errorFields = {}) {
    const keys = Object.keys(errorFields);
    const emailTest =
        "^[a-zA-Z0-9.#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$";
    if (!keys.length) return [];
    return keys.reduce((acc, val) => {
        const result = errorFields[val].trim();
        const testEmail = new RegExp(emailTest, 'ig').test(result);
        if (val === 'email' && !testEmail) {
            acc.push('invalidEmail');
        }
        if (!result.length) {
            acc.push(val);
        }
        return acc;
    }, []);
}

const TWENTY_FOUR_HOURS = 60 * 60 * 1000 * 24;
export const DATE_POSITION = {
    START: 'START',
    END: 'END'
};
/**
 * used to filter datepicker range from start to end
 * @param {Date} current - each date on the current calendar
 * @param {Date} disabled - date you wish to disable
 * @param {"START"|"END"} placeHolder - if start date should be END if end date should be start
 * @returns (Boolean) if true disable current date
 */
export function disabledDate(current, disabled, placeHolder = '') {
    current = new Date(current);
    const day = current.getDate();
    const month = current.getMonth();
    const year = current.getFullYear();
    const currentDayOnCalender = new Date(year, month, day).getTime() + TWENTY_FOUR_HOURS;
    const incomingDisabledDate = disabled ? new Date(disabled).getTime() : new Date().getTime();
    const today = new Date().getTime();

    if (placeHolder === DATE_POSITION.START && disabled) {
        return today > currentDayOnCalender || currentDayOnCalender > incomingDisabledDate
            ? true
            : false;
    }
    return currentDayOnCalender < incomingDisabledDate;
}

export function dateAvailability(date, availabilityData) {
    const dateS = moment(date).format('YYYY-MM-DD');
    const found = availabilityData.find((val) => val?.date === dateS);
    if (found?.cutOffPassed === true) return 'unavailable';
    return found ? found.availability : '';
}

export function disabledDateAvailability(date, availabilityData) {
    //If date is history
    if (moment().isAfter(date) && !moment().isSame(date, 'day')) return true;
    //checking availability
    const availability = dateAvailability(date, availabilityData);
    if (availability === 'available') return false;
    else return true;
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button, Row } from 'reactstrap';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Delete from '../../../assets/images/delete.svg';
import Edit from '../../../assets/images/edit.svg';
import Proptypes from 'prop-types';

export default function AddButton(props) {
    const { moreInfo = true } = props;
    const isAddedAccessibility = (data) =>
        data.wheelchair ||
        data.stroller ||
        data.service_animals ||
        data.public_trans ||
        data.infant_lapping ||
        data.infant_seats;

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={props.onDelete}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> Add
                    </Button>
                )}
            </div>
            {props.data ? (
                <Row className="actions">
                    <div>
                        <div>
                            {isAddedAccessibility(props.data.accessibility)
                                ? 'Accessibility Added'
                                : null}
                        </div>
                        <div>
                            {props.data.health_restrictions[0] ? `Health Restrictions Added` : null}
                        </div>
                        <div>
                            {props.data.difficulty_level ? `Physical Difficulty Added` : null}
                        </div>
                        <div>{props.data.phone_number ? 'Phone Number Added' : null}</div>
                    </div>
                </Row>
            ) : null}
        </div>
    );
}
AddButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.array
};

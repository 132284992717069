import React from 'react';
import PropTypes from 'prop-types';
const StarRatingsBig = ({ stars }) => {
    return (
        <svg
            width="214"
            height="43"
            viewBox="0 0 214 43"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M25.6548 17.6143L17.7071 16.4154L14.1543 8.93945C14.0573 8.73476 13.8976 8.56906 13.7004 8.46834C13.2058 8.21492 12.6048 8.42611 12.3575 8.93945L8.80471 16.4154L0.857013 17.6143C0.637895 17.6468 0.437559 17.754 0.284177 17.9164C0.098747 18.1142 -0.00343359 18.3804 8.81037e-05 18.6564C0.0036098 18.9323 0.112546 19.1956 0.302959 19.3882L6.05322 25.2072L4.69469 33.4239C4.66284 33.615 4.68321 33.8116 4.75352 33.9913C4.82382 34.1711 4.94124 34.3267 5.09245 34.4407C5.24366 34.5547 5.42262 34.6225 5.60904 34.6363C5.79545 34.6501 5.98186 34.6094 6.14713 34.5188L13.2559 30.6395L20.3647 34.5188C20.5588 34.626 20.7842 34.6618 21.0001 34.6228C21.5448 34.5253 21.911 33.9892 21.8171 33.4239L20.4586 25.2072L26.2089 19.3882C26.3654 19.229 26.4687 19.0211 26.5 18.7937C26.5845 18.2251 26.2026 17.6987 25.6548 17.6143Z"
                fill="#E2C022"
                fillOpacity={stars >= 1 ? '1' : '0.3'}
            />
            <path
                d="M70.7715 16.5545L60.6562 15.0287L56.1344 5.51383C56.0109 5.25332 55.8077 5.04243 55.5568 4.91424C54.9273 4.59171 54.1624 4.86049 53.8476 5.51383L49.3259 15.0287L39.2106 16.5545C38.9317 16.5959 38.6768 16.7323 38.4816 16.9391C38.2456 17.1908 38.1155 17.5296 38.12 17.8808C38.1245 18.232 38.2631 18.5671 38.5055 18.8123L45.824 26.2182L44.0949 36.6758C44.0544 36.9191 44.0803 37.1693 44.1698 37.398C44.2593 37.6268 44.4087 37.8249 44.6012 37.97C44.7936 38.1151 45.0214 38.2013 45.2586 38.2189C45.4959 38.2364 45.7332 38.1846 45.9435 38.0694L54.991 33.1321L64.0386 38.0694C64.2856 38.2058 64.5724 38.2513 64.8473 38.2017C65.5405 38.0776 66.0067 37.3953 65.8871 36.6758L64.1581 26.2182L71.4766 18.8123C71.6758 18.6096 71.8073 18.345 71.8471 18.0555C71.9547 17.3319 71.4687 16.662 70.7715 16.5545Z"
                fill="#E2C022"
                fillOpacity={stars >= 2 ? '1' : '0.3'}
            />
            <path
                d="M128.969 15.1415L115.964 13.1797L110.15 0.946364C109.992 0.611422 109.73 0.340278 109.408 0.175465C108.598 -0.239225 107.615 0.106351 107.21 0.946364L101.396 13.1797L88.3912 15.1415C88.0326 15.1947 87.7048 15.3701 87.4538 15.636C87.1504 15.9597 86.9832 16.3952 86.9889 16.8468C86.9947 17.2984 87.1729 17.7291 87.4845 18.0444L96.894 27.5663L94.671 41.0118C94.6189 41.3246 94.6522 41.6463 94.7673 41.9404C94.8823 42.2345 95.0744 42.4892 95.3219 42.6758C95.5693 42.8623 95.8622 42.9731 96.1672 42.9957C96.4722 43.0183 96.7773 42.9517 97.0477 42.8035L108.68 36.4555L120.313 42.8035C120.63 42.9789 120.999 43.0374 121.353 42.9736C122.244 42.8141 122.843 41.9369 122.69 41.0118L120.466 27.5663L129.876 18.0444C130.132 17.7838 130.301 17.4436 130.352 17.0714C130.491 16.141 129.866 15.2798 128.969 15.1415Z"
                fill="#E2C022"
                fillOpacity={stars >= 3 ? '1' : '0.3'}
            />
            <path
                d="M175.677 16.5545L165.561 15.0287L161.04 5.51383C160.916 5.25332 160.713 5.04243 160.462 4.91424C159.833 4.59171 159.068 4.86049 158.753 5.51383L154.231 15.0287L144.116 16.5545C143.837 16.5959 143.582 16.7323 143.387 16.9391C143.151 17.1908 143.021 17.5296 143.025 17.8808C143.03 18.232 143.168 18.5671 143.411 18.8123L150.729 26.2182L149 36.6758C148.96 36.9191 148.986 37.1693 149.075 37.398C149.165 37.6268 149.314 37.8249 149.506 37.97C149.699 38.1151 149.927 38.2013 150.164 38.2189C150.401 38.2364 150.638 38.1846 150.849 38.0694L159.896 33.1321L168.944 38.0694C169.191 38.2058 169.478 38.2513 169.753 38.2017C170.446 38.0776 170.912 37.3953 170.792 36.6758L169.063 26.2182L176.382 18.8123C176.581 18.6096 176.713 18.345 176.752 18.0555C176.86 17.3319 176.374 16.662 175.677 16.5545Z"
                fill="#E2C022"
                fillOpacity={stars >= 4 ? '1' : '0.3'}
            />
            <path
                d="M212.772 17.6143L204.824 16.4154L201.271 8.93945C201.174 8.73476 201.014 8.56906 200.817 8.46834C200.323 8.21492 199.722 8.42611 199.474 8.93945L195.921 16.4154L187.974 17.6143C187.755 17.6468 187.554 17.754 187.401 17.9164C187.215 18.1142 187.113 18.3804 187.117 18.6564C187.12 18.9323 187.229 19.1956 187.42 19.3882L193.17 25.2072L191.811 33.4239C191.78 33.615 191.8 33.8116 191.87 33.9913C191.941 34.1711 192.058 34.3267 192.209 34.4407C192.36 34.5547 192.539 34.6225 192.726 34.6363C192.912 34.6501 193.099 34.6094 193.264 34.5188L200.373 30.6395L207.481 34.5188C207.675 34.626 207.901 34.6618 208.117 34.6228C208.662 34.5253 209.028 33.9892 208.934 33.4239L207.575 25.2072L213.326 19.3882C213.482 19.229 213.585 19.0211 213.617 18.7937C213.701 18.2251 213.319 17.6987 212.772 17.6143Z"
                fill="#E2C022"
                fillOpacity={stars >= 5 ? '1' : '0.3'}
            />
        </svg>
    );
};
StarRatingsBig.propTypes = {
    stars: PropTypes.number
};
export default StarRatingsBig;

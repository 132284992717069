import React from 'react';
// import PropTypes from 'prop-types';
import TravelPeriod from '../travel-period';
import MembershipBenefits from '../membership-benefits';
import './index.scss';

function PremiumOffer() {
    return (
        <div className="premium-offer">
            <MembershipBenefits />
            <TravelPeriod />
        </div>
    );
}

PremiumOffer.propTypes = {};

export default PremiumOffer;

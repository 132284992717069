import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ClockIcon } from 'components/atoms';

const EventCalendarSeason = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<ClockIcon />}
            panelTitle="Calendar Seasons"
            isEditable={true}
            onEdit={onEdit}>
            {product?.calendar_season ? (
                <>
                    {types.map((type, index) =>
                        !product?.calendar_season[type]?.length > 0 ? (
                            <></>
                        ) : (
                            <div key={index}>
                                <p className="fs-20 lh-1-7 flex-grow-1 mt-2 mb-0 p-0">
                                    {typeString[type]}
                                </p>
                                {product?.calendar_season[type].map((time, i) => (
                                    <div key={i}>
                                        <Row
                                            className="border-bottom border-primary-shaded py-2"
                                            noGutters>
                                            <Col xs={6} md={4}>
                                                <div className="d-flex flex-nowrap">
                                                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                                        {time?.reason}
                                                    </span>
                                                    <span className="fs-18 lh-1-7 mr-4">:</span>
                                                </div>
                                            </Col>
                                            <Col xs={6} md={8}>
                                                <span className="fs-18 lh-1-7">
                                                    {time?.from} to {time?.to}
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        )
                    )}
                </>
            ) : (
                <></>
            )}
        </DetailedPanel>
    );
};

EventCalendarSeason.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventCalendarSeason;

const types = ['best_time', 'high_season', 'best_weather'];

const typeString = {
    best_time: 'Best Time',
    high_season: 'High Season',
    best_weather: 'Best Weather'
};

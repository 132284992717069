export default {
    cell: {
        border: 'none',
        display: 'flex',
        // alignItems: 'center',
        padding: 0
    },
    input: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '48px',
        width: '68px',
        fontSize: '16px',
        textAlign: 'center'
    },
    betweenBlock: {
        marginBottom: 11
    },
    headText: {
        fontSize: 14,
        lineHeight: '21px',
        fontWeight: 400,
        marginBottom: 2
    },
    deleteIcon: {
        height: 20,
        width: 20,
        marginBottom: 11
    }
};

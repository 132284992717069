import React from 'react';
import DetailedPanel from './panel';
import { PictureIcon } from './panel/icons';
import CollapseMoreLess from '../../collapse-more-less';
import PropTypes from 'prop-types';

const PhotosVideosPanel = (props) => {
    const { setViewGalleryItemModal } = props;
    return (
        <DetailedPanel panelIcon={<PictureIcon />} panelTitle="Photos & videos" isEditable={true}>
            <div className="gallery-grid">
                <div
                    className="gallery-item item-type-img"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => setViewGalleryItemModal(true)}
                    onClick={() => setViewGalleryItemModal(true)}>
                    <img src={require('../../../../assets/images/gallery-image-01.png')} alt="" />
                </div>
                <div
                    className="gallery-item item-type-img"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => setViewGalleryItemModal(true)}
                    onClick={() => setViewGalleryItemModal(true)}>
                    <img src={require('../../../../assets/images/gallery-image-02.png')} alt="" />
                </div>
                <div
                    className="gallery-item item-type-img"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => setViewGalleryItemModal(true)}
                    onClick={() => setViewGalleryItemModal(true)}>
                    <img src={require('../../../../assets/images/gallery-image-03.png')} alt="" />
                </div>
                <div
                    className="gallery-item item-type-video"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => setViewGalleryItemModal(true)}
                    onClick={() => setViewGalleryItemModal(true)}>
                    <img src={require('../../../../assets/images/gallery-image-04.png')} alt="" />
                </div>
                <div
                    className="gallery-item item-type-img"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => setViewGalleryItemModal(true)}
                    onClick={() => setViewGalleryItemModal(true)}>
                    <img src={require('../../../../assets/images/gallery-image-05.png')} alt="" />
                </div>
                <div
                    className="gallery-item item-type-img"
                    role="button"
                    tabIndex="0"
                    onKeyUp={() => setViewGalleryItemModal(true)}
                    onClick={() => setViewGalleryItemModal(true)}>
                    <img src={require('../../../../assets/images/gallery-image-06.png')} alt="" />
                </div>
            </div>
            <div className="gallery-gap-y"></div>
            <CollapseMoreLess>
                <div className="gallery-grid">
                    <div
                        className="gallery-item item-type-img"
                        role="button"
                        tabIndex="0"
                        onKeyUp={() => setViewGalleryItemModal(true)}
                        onClick={() => setViewGalleryItemModal(true)}>
                        <img
                            src={require('../../../../assets/images/gallery-image-03.png')}
                            alt=""
                        />
                    </div>
                    <div
                        className="gallery-item item-type-video"
                        role="button"
                        tabIndex="0"
                        onKeyUp={() => setViewGalleryItemModal(true)}
                        onClick={() => setViewGalleryItemModal(true)}>
                        <img
                            src={require('../../../../assets/images/gallery-image-04.png')}
                            alt=""
                        />
                    </div>
                    <div
                        className="gallery-item item-type-img"
                        role="button"
                        tabIndex="0"
                        onKeyUp={() => setViewGalleryItemModal(true)}
                        onClick={() => setViewGalleryItemModal(true)}>
                        <img
                            src={require('../../../../assets/images/gallery-image-05.png')}
                            alt=""
                        />
                    </div>
                    <div
                        className="gallery-item item-type-img"
                        role="button"
                        tabIndex="0"
                        onKeyUp={() => setViewGalleryItemModal(true)}
                        onClick={() => setViewGalleryItemModal(true)}>
                        <img
                            src={require('../../../../assets/images/gallery-image-06.png')}
                            alt=""
                        />
                    </div>
                </div>
            </CollapseMoreLess>
        </DetailedPanel>
    );
};
PhotosVideosPanel.propTypes = {
    setViewGalleryItemModal: PropTypes.func
};
export default PhotosVideosPanel;

/* eslint-disable react/jsx-key */
import { Modal, Button, Select, Divider, Input, Form, Row, Col } from 'antd';
import MediaField from '../../../../molecules/media-library/media-field';
import MediaList from '../../../../molecules/media-library/media-list';
import React, { useState, useEffect, useRef } from 'react';
import Prompter from '../../../../atoms/prompter';
import Spacer from '../../../../atoms/spacer';
import RightArrow from '../../../../../assets/images/rightArrow.svg';
import PropTypes from 'prop-types';
import './index.scss';

const { Option } = Select;

const { TextArea } = Input;

const FOODS_AND_DRINKS_OPTIONS = ['Breakfast', 'Lunch', 'Dinner', 'Other'];

const FoodAndDrink = (props) => {
    const { handleOk, isVisible, handleCancel } = props;
    let [options, setOption] = useState(FOODS_AND_DRINKS_OPTIONS);
    let [mediasToUpload, setMediasToUpload] = useState([]);
    let [title, setTitle] = useState(null);
    let [desc, setDesc] = useState(null);
    let [remaining, setRemaining] = useState(null);
    let textAreaRef = useRef(null);
    const maxLength = 200;
    let [newOption, setNewOption] = useState('');

    useEffect(() => {
        setRemaining(maxLength);
        if (textAreaRef.current) {
            const maxLength = textAreaRef.current.props.maxLength;
            const usedLength = textAreaRef.current.state.value.length;
            setRemaining(maxLength - usedLength);
        }
    }, [desc]);

    const addOption = () => {
        setOption([...options, newOption]);
        setNewOption('');
    };

    const handleTitleChange = (title) => {
        setTitle(title);
    };

    const handleDescChange = (e) => {
        setDesc(e.target.value);
    };

    const onHandleRemoveMedia = (index) => {
        let medias = [...mediasToUpload];
        medias.splice(index, 1);
        setMediasToUpload(medias);
    };

    const updateImagesToUpload = (file, crop) => {
        let medias = [...mediasToUpload];
        if (!file || !crop.croppedImageUrl) return;
        medias.push({
            file,
            src: crop.croppedImageUrl,
            caption: 'Add Caption',
            badge: !medias.length && 'First'
        });
        setMediasToUpload(medias);
    };

    return (
        <Modal
            width={530}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            wrapClassName="food-and-drink"
            footer={[
                <Button
                    onClick={handleCancel}
                    style={{ width: 135, height: 42 }}
                    type="primary"
                    className="rounded-btn"
                    disabled={!title || !desc}>
                    Add
                </Button>,
                <Button
                    style={{ width: 135, height: 42 }}
                    type="outline"
                    className="rounded-btn mr20"
                    onClick={handleCancel}>
                    Cancel
                </Button>
            ]}>
            <h4>Add meals & drinks</h4>
            <Prompter
                text="I will import meals & drinks information from my other activity"
                textColor="#A2792C"
            />
            <Spacer top={28} />
            <Form
                // form={form}
                layout="vertical">
                <Form.Item label="Meal type" required>
                    <Select
                        value={title}
                        onChange={handleTitleChange}
                        suffixIcon={<img alt="" src={RightArrow} />}
                        placeholder="Select food & drinks"
                        dropdownRender={(menu) => (
                            <div>
                                {menu}
                                <Divider style={{ margin: '4px 0' }} />
                                <div
                                    style={{
                                        display: 'flex',
                                        flexWrap: 'nowrap',
                                        padding: 8,
                                        justifyContent: 'space-between'
                                    }}>
                                    <Input
                                        placeholder="Enter meal type here. E.g. drinks only"
                                        style={{ flex: 'auto', height: 47, borderRadius: 8 }}
                                        value={newOption}
                                        onChange={(v) => setNewOption(v.target.value)}
                                    />
                                    <Button
                                        style={{
                                            width: 109,
                                            height: 42,
                                            borderRadius: '60px',
                                            marginLeft: 20
                                        }}
                                        type="primary"
                                        onClick={addOption}>
                                        Done
                                    </Button>
                                </div>
                            </div>
                        )}>
                        {options.map((item) => (
                            <Option key={item}>{item}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item className="mt-57" label="Description" required>
                    <TextArea
                        ref={textAreaRef}
                        maxLength={maxLength}
                        value={desc}
                        onChange={handleDescChange}
                        rows={5}
                        placeholder="Tell us about this meal"
                    />
                    <p>{remaining} character(s) remaining</p>
                </Form.Item>

                <Row gutter={12}>
                    <Col>
                        <MediaField onSelectMedia={updateImagesToUpload} type="picture" />
                    </Col>
                    <Col>
                        <MediaField type="video" />
                    </Col>
                </Row>
                <MediaList medias={mediasToUpload} removeMedia={onHandleRemoveMedia} />
            </Form>
        </Modal>
    );
};

FoodAndDrink.propTypes = {
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired
};

export default FoodAndDrink;

import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailedPanel from './panel';
import { DishIcon } from './panel/icons';
import Carousel from 'react-multi-carousel';
import { Button } from 'reactstrap';

const responsive = {
    eventGallery: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};

const MealsDrinksPanel = () => {
    return (
        <DetailedPanel panelIcon={<DishIcon />} panelTitle="Meals and Drinks" isEditable={true}>
            <Row className="row-cols-1 row-cols-md-3">
                {[1, 1, 1].map((index) => {
                    return (
                        <Col key={index}>
                            <Row>
                                <Col xs={4} md={12}>
                                    <Carousel
                                        swipeable={true}
                                        draggable={false}
                                        showDots={false}
                                        arrows={true}
                                        responsive={responsive.eventGallery}
                                        infinite={true}
                                        autoPlay={false}
                                        autoPlaySpeed={3000}
                                        keyBoardControl={true}
                                        containerClass="event-gallery-carousel h-auto mb-3">
                                        <div className="meals-drinks-item item-type-img">
                                            <img
                                                className="meals-drinks-img"
                                                src={require('../../../../assets/images/meals-01.png')}
                                                alt=""
                                            />
                                        </div>
                                        <div className="meals-drinks-item item-type-video">
                                            <img
                                                className="meals-drinks-img"
                                                src={require('../../../../assets/images/meals-02.png')}
                                                alt=""
                                            />
                                        </div>
                                        <div className="meals-drinks-item item-type-img">
                                            <img
                                                className="meals-drinks-img"
                                                src={require('../../../../assets/images/meals-03.png')}
                                                alt=""
                                            />
                                        </div>
                                    </Carousel>
                                </Col>
                                <Col xs={8} md={12}>
                                    <div className="fs-18 fw-500">Breakfast</div>
                                    <div className="fs-15 text-uppercase">Baith Tamat</div>
                                    <div className="fs-13 lh-1-3">
                                        {`Emirati Scrambled Eggs with Tomatoes. ... “Baid” means eggs in Arabic, and “tamat” or “tamatar...`}
                                    </div>
                                    <Button color="link" className="fs-13 fw-500 px-0" size="small">
                                        <i>Read More</i>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    );
                })}
            </Row>
        </DetailedPanel>
    );
};

export default MealsDrinksPanel;

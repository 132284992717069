/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Form, Select } from 'antd';
import PlusIcon from '../../../../atoms/icons/plusIcon';
import BasicProgress from '../../../../atoms/basic-progress';
import { getCalendarsById } from '../../../../../store/actions/calendarActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const AvailableHoursModal = (props) => {
    const { Option } = Select;
    const {
        isModalOpen,
        setModalOpenState,
        goBackAction,
        setAddProductsInitModal,
        productTitle,
        getCalendarsById,
        get_calendar_data
    } = props;

    useEffect(() => {
        if (props.match.params.id) {
            getCalendarsById(props.match.params.id);
        }
    }, []);

    const [currentDay, setCurrentDay] = useState(null);
    const calendarHours = totalCalendarHours(get_calendar_data);

    const onSelect = (day) => {
        try {
            if (Number(day) > 0 && get_calendar_data) {
                for (let item of get_calendar_data.data.days) {
                    if (item.day === day) {
                        return setCurrentDay(totalHours(item));
                    }
                }
            }
            return setCurrentDay(null);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal
            centered
            scrollable
            isOpen={isModalOpen}
            toggle={() => {
                setModalOpenState(!isModalOpen);
            }}>
            <ModalHeader
                className="close-alt"
                toggle={() => setModalOpenState(!isModalOpen)}></ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex flex-wrap flex-lg-nowrap mb-3">
                    <h5 className="modal-title order-2 order-lg-1 overflow-hidden">
                        {productTitle || get_calendar_data?.data?.title ? (
                            <>
                                <span className="text-truncate d-block mr-lg-5">
                                    {productTitle || get_calendar_data?.data?.title}
                                </span>
                            </>
                        ) : (
                            ''
                        )}
                        Available Hours{' '}
                    </h5>
                    <div className="flex-shrink-0 order-1 order-lg-2 ml-lg-2">
                        <Button
                            color="link"
                            className="px-0 text-decoration-underline"
                            type="button"
                            onClick={goBackAction}>
                            Go Back
                        </Button>
                    </div>
                </div>
                <div>
                    <Row noGutters>
                        <Col lg={6}>
                            <Form.Item className="mb-2" name="select_day">
                                <Select
                                    defaultValue="-1"
                                    className="form-control-alt d-block"
                                    dropdownClassName="type-rich"
                                    size="large"
                                    onSelect={onSelect}>
                                    <Option key={-1} value="-1">
                                        All Days
                                    </Option>
                                    {get_calendar_data?.data?.days ? (
                                        get_calendar_data?.data?.days?.map((item, i) => (
                                            <Option value={item.day} key={i}>
                                                Day {item.day} - {item.day_of_week}
                                            </Option>
                                        ))
                                    ) : (
                                        <></>
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row noGutters>
                        <Col xs={12}>
                            <span
                                role="button"
                                tabIndex="0"
                                className="float-right fs-12 text-primary fw-400"
                                onClick={() => {
                                    setModalOpenState(false);
                                    setAddProductsInitModal(true);
                                }}
                                onKeyUp={() => {
                                    setModalOpenState(false);
                                    setAddProductsInitModal(true);
                                }}>
                                Add Products <PlusIcon />
                            </span>
                            <BasicProgress
                                percent={
                                    currentDay
                                        ? (currentDay.used * 100) / currentDay.total
                                        : (calendarHours.used * 100) / calendarHours.total
                                }
                                showInfo={false}
                            />
                            <div className="mt-0">
                                {currentDay ? currentDay.used : calendarHours?.used || 0} hrs of{' '}
                                {currentDay ? currentDay.total : calendarHours?.total || 0} hrs
                                consumed
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="overflow-y-auto scrollbar-fixed pr-2 mr-n2">
                    <Row noGutters className="py-2 border-bottom border-primary-shaded">
                        <Col>
                            <h5 className="fs-16 fw-500 mb-0">Total Hours</h5>
                        </Col>
                        <Col xs="auto">
                            <div className="fs-16 fw-500">
                                {currentDay ? currentDay?.total : calendarHours?.total}
                            </div>
                        </Col>
                    </Row>
                    <Row noGutters className="py-2 border-bottom border-primary-shaded">
                        <Col>
                            <h5 className="fs-16 fw-500 mb-0">Used Hours</h5>
                        </Col>
                        <Col xs="auto">
                            <div className="fs-16 fw-500">
                                {currentDay ? currentDay?.used : calendarHours?.used || 0}
                            </div>
                        </Col>
                    </Row>
                    <Row noGutters className="py-2 border-bottom border-primary-shaded">
                        <Col>
                            <h5 className="fs-16 fw-500 mb-0">Available Hours</h5>
                        </Col>
                        <Col xs="auto">
                            <div className="fs-16 fw-500">
                                {currentDay ? currentDay?.available : calendarHours?.available || 0}
                            </div>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    );
};

AvailableHoursModal.propTypes = {
    isModalOpen: PropTypes.bool,
    productTitle: PropTypes.string,
    setModalOpenState: PropTypes.func,
    goBackAction: PropTypes.func,
    setAddProductsInitModal: PropTypes.func,
    getCalendarsById: PropTypes.func,
    get_calendar_data: PropTypes.object,
    match: PropTypes.any
};

const mapStateToProps = (state) => ({
    get_calendar_data: state.calendar.get_calendar_data
});

const mapDispatch = (dispatch) => ({
    getCalendarsById: (id) => dispatch(getCalendarsById(id))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AvailableHoursModal));

const totalHours = (day) => {
    let hours = {
        total: 0,
        used: 0,
        available: 0
    };
    try {
        for (let hour of day.hours) {
            if (hour.status !== 'blocked') {
                hours.total += 1;
                if (hour.status === 'available') {
                    hours.available += 1;
                } else {
                    hours.used += 1;
                }
            }
        }
        return hours;
    } catch (error) {
        console.log(error);
        return hours;
    }
};

const totalCalendarHours = (calendar) => {
    let hours = {
        total: 0,
        used: 0,
        available: 0
    };

    try {
        if (calendar?.data?.days) {
            for (let day of calendar.data.days) {
                for (let hour of day.hours) {
                    if (hour.status !== 'blocked') {
                        hours.total += 1;
                        if (hour.status === 'available') {
                            hours.available += 1;
                        } else {
                            hours.used += 1;
                        }
                    }
                }
            }
        }
        return hours;
    } catch (error) {
        console.log(error);
        return hours;
    }
};

import React from 'react';

const DownloadIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.9551 7.293C11.8741 7.114 11.6961 7 11.5001 7H9.50006V0.5C9.50006 0.224 9.27606 0 9.00006 0H7.00006C6.72406 0 6.50006 0.224 6.50006 0.5V7H4.50006C4.30406 7 4.12606 7.115 4.04506 7.293C3.96306 7.472 3.99506 7.681 4.12406 7.829L7.62406 11.829C7.71906 11.938 7.85606 12 8.00006 12C8.14406 12 8.28106 11.937 8.37606 11.829L11.8761 7.829C12.0061 7.682 12.0361 7.472 11.9551 7.293Z"
                fill="currentColor"
            />
            <path
                d="M13.5 11V14H2.5V11H0.5V15C0.5 15.553 0.948 16 1.5 16H14.5C15.053 16 15.5 15.553 15.5 15V11H13.5Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default DownloadIcon;

import React, { useState, useEffect, useContext } from 'react';
import Proptypes from 'prop-types';
import Bookings from 'components/organisms/views/bookings';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { INITIAL_EVENTS } from './event-utils';
import { Tabs, Dropdown, Form, Tooltip } from 'antd';
import {
    CalendarSummaryDrawer,
    DownloadSettingsDrawer,
    AllFiltersWrap,
    RightControls,
    CustomerCalendarWrapMulti,
    DropdownActionMenu,
    CalendarStatusWrap,
    CustomerStatusWrap,
    EventContent,
    ColumnHeaderText,
    CalendarViewTab,
    ProductViewTab,
    FiltersMobile
} from 'components/molecules';
import { MoreOutlined } from '@ant-design/icons';
import { makingABookingData, responsive, productViewData } from './data';
import {
    calendarTitleAndLengthWrap,
    calendarLogoWrap,
    customerCalendarWrapSingle,
    multiUserInfoPopover,
    userInfoPopover
} from './elements';
import {
    bookedCalendars,
    getCalendars,
    otherBookedProducts,
    leadTravellers,
    getCalendarsById,
    getAllCalendarProducts
    // allProducts
} from 'store/actions';
import moment from 'moment';
import { ComponentPermissionContext } from 'context/rolesandpermission';
//import SelectCalendarModal from 'components/molecules/modals/select-calendar';

const { TabPane } = Tabs;

function renderEventContent(eventInfo) {
    return <EventContent eventInfo={eventInfo} responsive={responsive} />;
}
const BookingData = (props) => {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const [settings, setSettings] = useState(false);
    const [selected, setSelected] = useState(false);
    const [filtersVisibility, setFiltersVisibility] = useState(false);
    const [sideDayArray] = useState([1, 2, 3, 4, 5, 6, 7]);
    const [selectedDowsArray, setSelectedDowsArray] = useState([]);
    const [duration, setDuration] = useState(false);
    const [selectedEventData, setSelectedEventData] = useState(false);
    const [currentWindowWidth, setCurrentWindowWidth] = useState(window.innerWidth);
    const [calendarMenuVisibility, setCalendarMenuVisibility] = useState(false);
    // const [removed, setRemoved] = useState(false);
    const [selectedDate] = useState(false);
    const [isDayActive, setIsDayActive] = useState(false);
    const calendarRef = React.createRef();
    // const [fileList, setFileList] = useState([]);
    const [businessForm] = Form.useForm();
    const changeSettings = (key) => {
        setSettings(!settings);
        setSelected(key);
    };
    const [visibleCalendarSummaryDrawer, setVisibleCalendarSummaryDrawer] = useState(false);
    const [visibleDownloadSettingsDrawer, setVisibleDownloadSettingsDrawer] = useState(false);
    //const [isOpenSpecificCalendarModal, setIsOpenSpecificCalendarModal] = useState(false);
    const [defaultSelectedCalendar, setDefaultSelectedCalendar] = useState(null);
    const [viewTab, setViewTab] = useState('calendar');
    const [viewSummaryData, setViewSummaryData] = useState(null);
    const [redirectToCalendarView, setRedirectToCalendarView] = useState(false);
    // const { business_data, getBusinessNameLogo } = props;

    useEffect(() => {
        props.getCalendars();
        props.bookedCalendars();
        props.leadTravellers();
        //props.calendarBookings({ id: 1 });
        if (!filtersVisibility) {
            setFiltersVisibility(true);
        }
        setDurationByWindowSize(window.innerWidth);
        function handleResize() {
            setCurrentWindowWidth(window.innerWidth);
        }
        window.addEventListener('resize', handleResize);
        adjustCalendarNameFontSize(24);
    }, []);

    const setDurationByWindowSize = (width) => {
        if (width >= 2560) {
            setDuration(6);
        } else if (width < 2560 && width >= 1920) {
            setDuration(4);
        } else if (width < 1920 && width >= 1440) {
            setDuration(3);
        } else if (width < 1440 && width >= 1200) {
            setDuration(2);
        } else {
            setDuration(1);
        }
    };
    const windowResize = () => {
        setDurationByWindowSize(window.innerWidth);
    };
    let dowArray = [];
    const dayHeaderDidMount = (data) => {
        dowArray.push(data.dow);
        setSelectedDowsArray(dowArray);
    };
    const columnHeaderText = (date) => {
        return (
            <ColumnHeaderText
                isDayActive={isDayActive}
                date={date}
                handleOnClick={() => {
                    dateClickEvent(date);
                }}
                handleOnMouseEnter={() => {
                    handleMouseEnter(date);
                }}
                handleOnMouseLeave={() => {
                    handleMouseLeave(date);
                }}
            />
        );
    };
    const dateClickEvent = (date) => {
        // setSelectedDate(date);
        let data = date.view.getCurrentData().eventStore.defs;
        let eventData = Object.keys(data).map((key) => data[key]);
        setSelectedEventData({ selectedDate: date.dow, events: eventData });
    };
    const saveData = (data) => {
        localStorage.setItem('data', data);
    };

    function adjustCalendarNameFontSize(calendarNameExtraWidth) {
        const inputElementWidth = 120;
        const minAllowedFontSize = 8;
        const maxAllowedFontSize = 20;
        const inputElement = document.getElementById('main_calendar_name_input');
        const inputText = document.createElement('pre');
        document.body.appendChild(inputText);
        inputText.style.fontFamily = 'Montserrat,sans-serif';
        inputText.style.textTransform = 'uppercase';
        inputText.style.fontSize = maxAllowedFontSize + 'px';
        inputText.style.height = 'auto';
        inputText.style.width = 'auto';
        inputText.style.position = 'absolute';
        inputText.style.left = -1000 + 'px';
        inputText.style.top = -1000 + 'px';
        inputText.style.whiteSpace = 'no-wrap';
        inputText.innerHTML = inputElement ? inputElement.value : '';
        let inputStringWidth = Math.ceil(inputText.clientWidth);
        if (inputStringWidth > inputElementWidth + calendarNameExtraWidth) {
            let acceptedFontSize =
                (maxAllowedFontSize * (inputElementWidth + calendarNameExtraWidth)) /
                inputStringWidth;
            if (acceptedFontSize > minAllowedFontSize) {
                inputElement.style.fontSize =
                    (maxAllowedFontSize * (inputElementWidth + calendarNameExtraWidth)) /
                        inputStringWidth +
                    'px';
            } else {
                inputElement.style.fontSize = minAllowedFontSize + 'px';
            }
        } else {
            if (inputElement) {
                inputElement.style.fontSize = maxAllowedFontSize + 'px';
            }
        }
        document.body.removeChild(inputText);
    }
    const handleMouseEnter = (date) => {
        let data = date.view.getCurrentData().eventStore.defs;
        let eventData = Object.keys(data).map((key) => data[key]);
        setSelectedEventData({ selectedDate: date.dow, events: eventData });
        setIsDayActive(date.dow);
    };
    const handleMouseLeave = () => {
        if (selectedDate) {
            let data = selectedDate.view.getCurrentData().eventStore.defs;
            let eventData = Object.keys(data).map((key) => data[key]);
            setSelectedEventData({ selectedDate: selectedDate.dow, events: eventData });
            setIsDayActive('');
        }
    };
    const onBusinessFinish = () => {
        // setCalendarNameUpdated(false);
        adjustCalendarNameFontSize(24);
        let data = businessForm.getFieldsValue();
        props.updateBusinessNameLogo(data);
    };
    //Mock Data for Making a booking
    const data = makingABookingData;
    const productViewColumns = [
        {
            title: 'Booked Date',
            dataIndex: 'product_date',
            key: 'product_date',
            width: 120
        },
        {
            title: 'Product Title',
            dataIndex: 'product_title',
            key: 'product_title',
            width: 150,
            sorter: {
                compare: (a, b) => a.product_title - b.product_title
            }
        },
        {
            title: 'Product Sub Cat.',
            dataIndex: 'product_sub_cat',
            key: 'product_sub_cat',
            width: 180,
            sorter: {
                compare: (a, b) => a.product_sub_cat - b.product_sub_cat
            }
        },
        {
            title: 'Calendar Title',
            dataIndex: 'calendar_title',
            key: 'calendar_title',
            width: 155,
            render: function renderCalendarTitle(_text, record) {
                return (
                    <div
                        className="text-truncate"
                        title={record.calendar_title}
                        style={{ maxWidth: 140 }}>
                        {record.calendar_title}
                    </div>
                );
            },
            sorter: {
                compare: (a, b) => a.calendar_title - b.calendar_title
            }
        },
        {
            title: 'Product Type',
            dataIndex: 'product_type',
            key: 'product_type',
            width: 145,
            sorter: {
                compare: (a, b) => {
                    return a.product_type - b.product_type;
                }
            }
        },
        {
            title: 'No. of Travellers',
            dataIndex: 'no_of_trvellers',
            key: 'no_of_trvellers',
            width: 180,
            sorter: {
                compare: (a, b) => a.no_of_trvellers - b.no_of_trvellers
            }
        },
        {
            title: 'Lead Travellers',
            dataIndex: 'lead_travellers',
            key: 'lead_travellers',
            width: 170,
            sorter: {
                compare: (a, b) => a.lead_travellers - b.lead_travellers
            }
        },
        {
            title: 'Actions',
            dataIndex: 'calendarAction',
            key: 'calendarAction',
            width: 85,
            render: function renderCalendarAction(_text, record, index) {
                return (
                    <div className="text-center">
                        <Dropdown
                            overlay={() => productActionMenu(record, index)}
                            // onVisibleChange={(flag) => setCalendarDropdownFlag(flag)}
                            // visible={calendarDropdownFlag}
                            trigger={['click']}>
                            <span className="text-primary" role="button" tabIndex="0">
                                <MoreOutlined style={{ fontSize: '30px' }} />
                            </span>
                        </Dropdown>
                    </div>
                );
            }
            // fixed: window.innerWidth >= 992 ? 'right' : ''
        }
    ];
    const productActionMenu = (record) => {
        let start = moment().startOf('year').format('YYYY-MM-DD');
        let paramData = `productId=0&calendarId=${record.calendar_id}&leadTraveller=${record.lead_traveller_id}&startDate=${start}`;
        return (
            <DropdownActionMenu
                //onMenuClick={() => setCalendarDropdownFlag(false)}
                // setCalendarDropdownFlag={setCalendarDropdownFlag}
                onClickViewCalendar={() => {
                    setViewSummaryData(record);
                    setTimeout(() => {
                        setRedirectToCalendarView(true);
                    }, 1000);
                }}
                onClickViewSummary={() => {
                    setVisibleCalendarSummaryDrawer(true);
                    setViewSummaryData(record);
                    props.otherBookedProducts(paramData);
                }}
                onClickDownloadSettings={() => setVisibleDownloadSettingsDrawer(true)}
            />
        );
    };
    // All DOMS that can be used multiple times
    const calendarNameWrap = (calendar) => (
        <Form form={businessForm} onSubmitCapture={onBusinessFinish}>
            <div className="event-name-wrapper">
                <div className="event-input-wrapper">
                    <Tooltip title={calendar?.vendor_name}>
                        <span className="event-name-only">{calendar?.vendor_name}</span>
                    </Tooltip>
                </div>
                <div className="event-name-footer">Powered by Travel Jinni</div>
            </div>
        </Form>
    );
    const calendarStatusWrap = (
        <CalendarStatusWrap
            currentWindowWidth={currentWindowWidth}
            status={defaultSelectedCalendar?.calendar_status}
        />
    );
    const customerStatusWrap = (
        <CustomerStatusWrap
            currentWindowWidth={currentWindowWidth}
            status={defaultSelectedCalendar?.customer_status}
        />
    );
    const fixedWidthMainCalendarWrap = (calendarBookedDetail) => (
        <div className="fixed-width-calendar d-flex flex-column _type-booking">
            {duration && calendarBookedDetail && props.calendar && props.allProducts && (
                <FullCalendar
                    contentHeight="unset"
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                    hiddenDays={[0]}
                    headerToolbar={{
                        left: 'prev,next',
                        center: '',
                        right: ''
                    }}
                    initialView="timeGrid"
                    // defaultView={'basicWeek'}
                    dateClick={dateClickEvent}
                    duration={{ days: duration }}
                    windowResize={windowResize}
                    editable={true}
                    selectable={true}
                    selectMirror={true}
                    dayMaxEvents={true}
                    dayHeaderContent={columnHeaderText}
                    dayHeaderDidMount={dayHeaderDidMount}
                    weekends={true}
                    initialEvents={INITIAL_EVENTS} // alternatively, use the `events` setting to fetch from a feed
                    //events={getEventsByProductsNew(calendarBookedDetail, props.calendar, props.allProducts)}
                    eventContent={renderEventContent} // custom render function
                    // eventClick={handleEventClick}
                    allDaySlot={false}
                />
            )}
        </div>
    );

    const customerCalendarWrapMulti = (
        <CustomerCalendarWrapMulti
            currentWindowWidth={currentWindowWidth}
            userInfoPopover={userInfoPopover}
            multiUserInfoPopover={multiUserInfoPopover}
        />
    );
    const onChangeViewTab = (key) => {
        windowResize();
        if (key == '1') {
            setViewTab('calendar');
        } else {
            setViewTab('product');
        }
    };
    const allFiltersWrap = (
        <AllFiltersWrap
            onChangeCalendar={(id) => {
                let selected = props?.booked_calendars.find((x) => x.id === id);
                setDefaultSelectedCalendar(selected);
                props.getCalendarsById(id);
                props.getAllCalendarProducts(id);
            }}
            viewTab={viewTab}
            // rememberFiltersData={rememberFiltersData}
            redirectToCalendarView={redirectToCalendarView}
            onChangeViewTab={onChangeViewTab}
            viewSummaryData={viewSummaryData}
            setRedirectToCalendarView={setRedirectToCalendarView}
        />
    );

    const displayPermission = (tab) => {
        let tabView = assignComponentPermission(
            tab === 'calendar' ? 'bookings-calendar-view' : 'bookings-product-view'
        );
        if (tabView === 'hidden-component-permission') {
            return '';
        }
        return tab === 'calendar' ? 'Calendar View' : 'Product View';
    };
    // All DOMS that can be used multiple times ends
    return (
        <Bookings>
            <div className="position-relative">
                <div>
                    <Tabs
                        className={`booking-tabs has-filters ${
                            currentWindowWidth >= 768 && filtersVisibility
                                ? 'tabs-content-flex-wrap'
                                : ''
                        }`}
                        defaultActiveKey="1"
                        activeKey={viewTab === 'calendar' ? '1' : '2'}
                        onChange={onChangeViewTab}>
                        <div
                            hidden={!(currentWindowWidth >= 768 && filtersVisibility)}
                            className={assignComponentPermission(
                                viewTab === 'calendar'
                                    ? 'bookings-calendar-view'
                                    : 'bookings-product-view'
                            )}>
                            {allFiltersWrap}
                        </div>
                        <TabPane
                            tab={displayPermission('calendar')}
                            key="1"
                            className={`${assignComponentPermission('bookings-calendar-view')}`}>
                            <CalendarViewTab
                                currentWindowWidth={currentWindowWidth}
                                calendarMenuVisibility={calendarMenuVisibility}
                                onClickMenu={() => setCalendarMenuVisibility(false)}
                                onClickClose={() => setCalendarMenuVisibility(false)}
                                calendarLogoWrap={calendarLogoWrap}
                                calendarNameWrap={calendarNameWrap(defaultSelectedCalendar)}
                                calendarTitleAndLengthWrap={calendarTitleAndLengthWrap(
                                    defaultSelectedCalendar
                                )}
                                calendarStatusWrap={calendarStatusWrap}
                                customerStatusWrap={customerStatusWrap}
                                customerCalendarWrapSingle={customerCalendarWrapSingle}
                                customerCalendarWrapMulti={customerCalendarWrapMulti}
                                onClickCalendarMenu={() => setCalendarMenuVisibility(true)}
                                sideDayArray={sideDayArray}
                                selectedDowsArray={selectedDowsArray}
                                isDayActive={isDayActive}
                                fixedWidthMainCalendarWrap={fixedWidthMainCalendarWrap(
                                    props.calendar_booked_details
                                )}
                                selectedEventData={selectedEventData}
                                onClickGhost={() => setIsDayActive(false)}
                                responsive={responsive}
                                calendarBookingsData={props.calendar_booked_details}
                                defaultSelectedCalendar={defaultSelectedCalendar}
                                viewTab={viewTab}
                            />
                        </TabPane>
                        <TabPane
                            tab={displayPermission('product')}
                            key="2"
                            className={`${assignComponentPermission('bookings-product-view')}`}>
                            <ProductViewTab
                                currentWindowWidth={currentWindowWidth}
                                productViewColumns={productViewColumns}
                                productViewData={productViewData}
                                showData={data}
                                handleOnClickImage={(index) => changeSettings(index)}
                                handleOnClickSummary={(datum) => saveData(JSON.stringify(datum))}
                                settings={settings}
                                selected={selected}
                                calendarBookingsData={props.calendar_booked_products}
                                viewTab={viewTab}
                            />
                        </TabPane>
                    </Tabs>
                    {/* Filters for mobile */}
                    {currentWindowWidth < 768 && filtersVisibility ? (
                        <FiltersMobile
                            onClickMenu={() => setFiltersVisibility(false)}
                            onClickClose={() => setFiltersVisibility(false)}
                            allFiltersWrap={allFiltersWrap}
                        />
                    ) : null}
                </div>
                {/* Right Controls */}
                <RightControls
                    handleOnClick={() => setFiltersVisibility(!filtersVisibility)}
                    currentWindowWidth={currentWindowWidth}
                    filtersVisibility={filtersVisibility}
                    setVisibleDownloadSettingsDrawer={setVisibleDownloadSettingsDrawer}
                />
            </div>
            {/* Calendar Summary Drawer */}
            <CalendarSummaryDrawer
                visible={visibleCalendarSummaryDrawer}
                onClose={() => setVisibleCalendarSummaryDrawer(false)}
                viewSummaryData={viewSummaryData}
                setRedirectToCalendarView={setRedirectToCalendarView}
            />
            {/* Download Settings Drawer */}
            <DownloadSettingsDrawer
                visible={visibleDownloadSettingsDrawer}
                onClose={() => setVisibleDownloadSettingsDrawer(false)}
            />
        </Bookings>
    );
};
const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    booked_calendars: state.booking.booked_calendars,
    calendar_booked_products: state.booking.calendar_booked_products,
    calendar_booked_details: state.booking.calendar_booked_details,
    allProducts: state?.calendar.get_all_calendar_products?.data,
    calendar: state?.calendar.get_calendar_data?.data
});
const mapDispatch = (dispatch) => ({
    bookedCalendars: () => dispatch(bookedCalendars()),
    getCalendars: () => dispatch(getCalendars()),
    otherBookedProducts: (data) => dispatch(otherBookedProducts(data)),
    leadTravellers: () => dispatch(leadTravellers()),
    getCalendarsById: (data) => dispatch(getCalendarsById(data)),
    getAllCalendarProducts: (data) => dispatch(getAllCalendarProducts(data))
});
BookingData.propTypes = {
    updateBusinessNameLogo: Proptypes.func,
    business_data: Proptypes.object,
    bookedCalendars: Proptypes.func,
    calendar_data: Proptypes.object,
    booked_calendars: Proptypes.array,
    getCalendars: Proptypes.func,
    otherBookedProducts: Proptypes.func,
    calendar_booked_products: Proptypes.any,
    leadTravellers: Proptypes.func,
    calendar_booked_details: Proptypes.any,
    allProducts: Proptypes.any,
    calendar: Proptypes.any,
    getCalendarsById: Proptypes.any,
    getAllCalendarProducts: Proptypes.any
};

export default withRouter(connect(mapStateToProps, mapDispatch)(BookingData));

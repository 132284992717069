/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useContext } from 'react';
import { useLocation, withRouter, useHistory } from 'react-router-dom';
import { Drawer, notification, Progress } from 'antd';
import PropTypes from 'prop-types';

import Logo from '../../../../assets/images/logos/brand.svg';
import Flag from '../../../../assets/images/flag.svg';
import Application from '../../homepage/application';
import './index.css';
import { Button, ModalFooter } from 'reactstrap';
import { Link, NavLink } from 'react-router-dom';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Row,
    Container,
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    UncontrolledDropdown,
    UncontrolledCollapse,
    DropdownToggle,
    DropdownMenu,
    Collapse
} from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Radio, Button as AntButton, Tooltip } from 'antd';

import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { getBusinessInformation } from '../../../../store/actions/profileActions';
import { getCurretUser } from '../../../../store/actions/AuthActions';
import { ComponentPermissionContext } from 'context/rolesandpermission';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';

const MobileNav = ({ children }) => {
    const [visible, setVisible] = useState(false);
    const showNav = () => {
        return setVisible(true);
    };

    const onClose = () => {
        return setVisible(false);
    };

    const history = useHistory();

    useEffect(() => {
        return history.listen(() => {
            setVisible(false);
        });
    }, [history]);

    return (
        <div>
            <NavbarToggler onClick={showNav} />
            <Drawer
                placement="right"
                onClose={onClose}
                visible={visible}
                width={500}
                className="drawer-nav">
                {children}
            </Drawer>
        </div>
    );
};

const NavBar = (props) => {
    const { business_info, my_profile, auth } = props;

    const defaultLanguage = {
        value: 'en',
        name: 'English'
    };
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const [isShowingApplicationModal, setShowApplicationModal] = React.useState(false);
    const [isShowDialogModal, setShowDialogModal] = React.useState(false);
    const [isModalOpen, setModal] = useState(false);
    const [isSelectLangModalOpen, setSelectLangModal] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(defaultLanguage);
    const [savingLanguage, setSavingLanguage] = useState(false);
    const { pathname } = useLocation();
    const hideNav = [
        '/create-password',
        '/signin',
        '/',
        '/terms',
        '/privacy-policy',
        '/forgot-password'
    ];

    // const routewithNoNav = [
    //     '/calendar-dashboard',
    //     '/projectcategorization',
    //     '/accommodation',
    //     '/accommodation-dashboard',
    //     '/publish',
    //     '/get-started',
    //     '/mainprouct',
    //     '/pricing',
    //     '/Covid19',
    //     '/reviews',
    //     '/product-content/details/itinerary',
    //     '/accommodation-details',
    //     '/personal-care',
    //     '/other-vendor-details',
    //     '/sync-availability'
    // ];

    const allLanguages = [
        {
            value: 'bh',
            name: 'Bahasa Indonesia'
        },
        {
            value: 'de',
            name: 'Deutsch'
        },
        {
            value: 'en',
            name: 'English'
        },
        {
            value: 'es',
            name: 'Español'
        },
        {
            value: 'es_argentina',
            name: 'Español (Argentina)'
        },
        {
            value: 'es_colombia',
            name: 'Español (Colombia)'
        },
        {
            value: 'es_mexico',
            name: 'Español (Mexico)'
        },
        {
            value: 'fr',
            name: 'Français'
        },
        {
            value: 'it',
            name: 'Italiano'
        },
        {
            value: 'ne',
            name: 'Nederlands'
        },
        {
            value: 'po',
            name: 'Português'
        },
        {
            value: 'ti',
            name: 'Tiếng Việt'
        },
        {
            value: 'tu',
            name: 'Türkçe'
        },
        {
            value: 'ey',
            name: 'Ελληνικά'
        }
    ];

    const fullNavHidden = !hideNav.includes(pathname) && localStorage.getItem('token');

    const toggleModal = () => {
        setModal(!isModalOpen);
    };

    const toggleSelectLangModal = () => {
        setSelectLangModal(!isSelectLangModalOpen);
    };

    const changeLanguage = (e) => {
        let selectedLangIndex = allLanguages.findIndex((item) => item.value === e.target.value);
        setSelectedLanguage(allLanguages[selectedLangIndex]);
    };

    const saveLanguage = () => {
        setSavingLanguage(true);
        setTimeout(() => {
            setSavingLanguage(false);
            setSelectLangModal(false);
        }, 1000);
    };

    const logoutAction = () => {
        localStorage.clear();
        window.location.replace('/');
    };

    useEffect(() => {
        if (localStorage.getItem('token')) {
            if (!my_profile && auth) {
                props.getCurretUser();
            }
            if (my_profile && business_info === null) {
                props.getBusinessInformation();
            }
        }
    }, [business_info, my_profile]);

    useEffect(() => {
        const { file_upload_progress } = props;
        if (file_upload_progress && Object.keys(file_upload_progress).length > 0)
            notification.open({
                key: 'fileUploadProgress',
                message: `Uploading ...`,
                description: (
                    <div>
                        <h6>{file_upload_progress?.name}</h6>
                        <Progress
                            percent={
                                file_upload_progress?.total && file_upload_progress.loaded
                                    ? Math.floor(
                                          (file_upload_progress.loaded /
                                              file_upload_progress.total) *
                                              100
                                      )
                                    : 0
                            }
                        />{' '}
                    </div>
                ),
                duration: null,
                placement: 'topLeft'
            });
        else setTimeout(() => notification.close('fileUploadProgress'), 2000);
    }, [props?.file_upload_progress?.loaded]);

    return (
        <>
            {/* Coming Soon Modal Popup */}
            <Modal
                isOpen={isModalOpen}
                size="lg"
                toggle={toggleModal}
                scrollable
                centered
                backdropClassName="opaque">
                <ModalHeader toggle={toggleModal}>{''}</ModalHeader>
                <ModalBody>
                    <Row>{'Coming Soon'}</Row>
                </ModalBody>
            </Modal>

            <Application
                onClose={() => setShowApplicationModal(false)}
                show={isShowingApplicationModal}
                onDialog={() => setShowDialogModal(true)}
            />

            {/* Thank You Dialog */}
            <Dialog
                open={isShowDialogModal}
                fullWidth={true}
                maxWidth={'md'}
                onClose={() => setShowDialogModal(false)}
                className="standard-width-dialog text-center padded-y-lg"
                aria-labelledby="thank-you-dialog-title"
                aria-describedby="thank-you-dialog-description">
                <DialogTitle id="thank-you-dialog-title">Thank You</DialogTitle>
                <DialogContent className="padded-normal">
                    <DialogContentText
                        className="text-body fs-23"
                        id="thank-you-dialog-description">
                        Your application has now been sent for review. <br /> We will get back to
                        you in due course
                    </DialogContentText>
                </DialogContent>
                <DialogActions className="justify-content-center">
                    <Button color="primary btn-mw" onClick={() => setShowDialogModal(false)}>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Master Navigation */}
            {fullNavHidden ? (
                // Show when User logged in
                <Navbar
                    className="hero-navbar sticky-top justify-content-between"
                    key={'home-navbar'}
                    color="faded"
                    light
                    expand="xl">
                    <Container fluid={true}>
                        <NavbarBrand tag={Link} to={'/home'}>
                            <img src={Logo} alt="" className="img-fluid" />
                        </NavbarBrand>

                        <MobileNav>
                            <ul className="navbar-nav">
                                {true ? (
                                    <>
                                        <li
                                            className={`nav-item m-0 ${assignComponentPermission(
                                                'calendar'
                                            )}`}
                                            key={'customer-calendars'}>
                                            <NavLink
                                                activeClassName=" active-bar"
                                                className="nav-link"
                                                to="/customers-calendars">
                                                {'Calendars'}
                                            </NavLink>
                                        </li>
                                        <li
                                            className={`nav-item m-0 ${assignComponentPermission(
                                                'bookings'
                                            )}`}
                                            key={'booking-key'}>
                                            <NavLink
                                                activeClassName=" active-bar"
                                                className="nav-link"
                                                to="/bookings">
                                                {'Bookings'}
                                            </NavLink>
                                        </li>
                                        <li
                                            key={'availability-key'}
                                            className={`nav-item m-0 ${assignComponentPermission(
                                                'availability'
                                            )}`}>
                                            {/* <Tooltip title="Coming Soon"> */}
                                            {/* <p className="nav-link disabled-link m-0">
                                                {'Availability'}
                                            </p> */}
                                            {/* </Tooltip> */}
                                            <NavLink
                                                activeClassName=" active-bar"
                                                className="nav-link"
                                                to="/availability-data">
                                                {'Availability'}
                                            </NavLink>
                                        </li>
                                        <li
                                            key={'customer-key'}
                                            className={`nav-item m-0 ${assignComponentPermission(
                                                'customer-experience'
                                            )}`}>
                                            {/* <Tooltip title="Coming Soon"> */}
                                            <p className="nav-link disabled-link m-0">
                                                {'Customer Experience'}
                                            </p>
                                            {/* </Tooltip> */}
                                        </li>
                                        <li
                                            className={`nav-item m-0 ${assignComponentPermission(
                                                'finance'
                                            )}`}
                                            key={'finance-key'}>
                                            <NavLink
                                                to="/finance"
                                                ss
                                                activeClassName=" active-bar"
                                                className="nav-link">
                                                Finance
                                            </NavLink>
                                        </li>
                                        <li className="nav-item dropdownMenu" key={'travels-key'}>
                                            <p
                                                id="toggle-nav"
                                                className="nav-link d-flex align-items-center dropdown cursor-pointer m-0">
                                                {business_info?.business_trading_name ??
                                                    'Safari Travels'}{' '}
                                                <DownOutlined />
                                            </p>
                                            <UncontrolledCollapse toggler="#toggle-nav">
                                                <NavLink
                                                    key={'dashboard-key'}
                                                    to="/home"
                                                    className={`${assignComponentPermission(
                                                        'dashboard'
                                                    )}`}>
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button">
                                                        Dashboard
                                                    </button>
                                                </NavLink>
                                                <NavLink
                                                    key={'performance-key'}
                                                    to="/home"
                                                    className={`${assignComponentPermission(
                                                        'performance' //change this to perfomance when applicable
                                                    )}`}>
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button">
                                                        Performance
                                                    </button>
                                                </NavLink>
                                                <NavLink
                                                    key={'account-key'}
                                                    to="/account"
                                                    className={`${assignComponentPermission(
                                                        'account'
                                                    )}`}>
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button">
                                                        Account
                                                    </button>
                                                </NavLink>
                                                <NavLink
                                                    // to="/security"
                                                    key={'security-key'}
                                                    to="/home"
                                                    className={`${assignComponentPermission(
                                                        'account-security'
                                                    )}`}>
                                                    {' '}
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button">
                                                        Account Security
                                                    </button>
                                                </NavLink>
                                                <NavLink
                                                    // to="/language-settings"
                                                    key={'language-key'}
                                                    to="/home"
                                                    className={`${assignComponentPermission(
                                                        'language-settings'
                                                    )}`}>
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button">
                                                        Language Settings
                                                    </button>
                                                </NavLink>
                                                <NavLink
                                                    // to="/manage-users"
                                                    key={'managers-key'}
                                                    to="/home"
                                                    className={`${assignComponentPermission(
                                                        'manage-users'
                                                    )}`}>
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button">
                                                        Managers Users
                                                    </button>
                                                </NavLink>

                                                <NavLink key={'logout-key'} to="/">
                                                    <button
                                                        className="dropdown-item cursor-pointer"
                                                        type="button"
                                                        onClick={logoutAction}>
                                                        Log out
                                                    </button>
                                                </NavLink>
                                            </UncontrolledCollapse>
                                        </li>
                                    </>
                                ) : (
                                    <li key={'save-button-key'}>
                                        <NavLink className="nav-link " key={'save-key'} to="/">
                                            <p className="brown-nav-item p-0">Save & Exit</p>
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </MobileNav>
                        <Collapse navbar>
                            <Nav className="ml-auto" navbar>
                                <ul className="navbar-nav align-items-lg-center margin-list">
                                    {true ? (
                                        <>
                                            <li
                                                key={'customer-calendars'}
                                                className={`nav-item ${assignComponentPermission(
                                                    'calendar'
                                                )}`}>
                                                <NavLink
                                                    activeClassName=" active-bar"
                                                    className="nav-link"
                                                    to="/customers-calendars">
                                                    {'Calendars'}
                                                </NavLink>
                                            </li>
                                            <li
                                                className={`nav-item ${assignComponentPermission(
                                                    'bookings'
                                                )}`}
                                                key={'booking-key'}>
                                                <NavLink
                                                    activeClassName=" active-bar"
                                                    className="nav-link"
                                                    to="/bookings">
                                                    {'Bookings'}
                                                </NavLink>
                                            </li>
                                            <li
                                                // onClick={toggleModal}
                                                key={'availability-key'}
                                                className={`nav-item ${assignComponentPermission(
                                                    'availability'
                                                )}`}>
                                                {/* <Tooltip title="Coming Soon">
                                                    <p className="nav-link disabled-link m-0">
                                                        {'Availability'}
                                                    </p>
                                                </Tooltip> */}
                                                <NavLink
                                                    activeClassName=" active-bar"
                                                    className="nav-link"
                                                    to="/availability-data">
                                                    {'Availability'}
                                                </NavLink>
                                            </li>
                                            <li
                                                // onClick={toggleModal}
                                                key={'customer-key'}
                                                className={`nav-item ${assignComponentPermission(
                                                    'customer-experience'
                                                )}`}>
                                                <Tooltip title="Coming Soon">
                                                    <p className="nav-link disabled-link m-0">
                                                        {'Customer Experience'}
                                                    </p>
                                                </Tooltip>
                                            </li>
                                            <li
                                                className={`nav-item ${assignComponentPermission(
                                                    'finance'
                                                )}`}
                                                key={'finance-key'}>
                                                <NavLink
                                                    to="/finance"
                                                    // ss
                                                    activeClassName=" active-bar"
                                                    className="nav-link">
                                                    Finance
                                                </NavLink>
                                            </li>
                                            <li className="nav-item" key={'travels-key'}>
                                                <UncontrolledDropdown>
                                                    <DropdownToggle
                                                        key={'safari-dropdown'}
                                                        color="secondary"
                                                        size="lg"
                                                        className="btn-mw"
                                                        caret>
                                                        {business_info?.business_trading_name ??
                                                            'Safari Travels'}
                                                    </DropdownToggle>
                                                    <DropdownMenu right key={'dashboard-dropdown'}>
                                                        <NavLink
                                                            key={'dashboard-key'}
                                                            to="/home"
                                                            className={`${assignComponentPermission(
                                                                'dashboard'
                                                            )}`}>
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button">
                                                                Dashboard
                                                            </button>
                                                        </NavLink>
                                                        <NavLink
                                                            key={'performance-key'}
                                                            to="/home"
                                                            className={`${assignComponentPermission(
                                                                'performance'
                                                            )}`}>
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button">
                                                                Performance
                                                            </button>
                                                        </NavLink>
                                                        <NavLink
                                                            key={'account-key'}
                                                            to="/account"
                                                            className={`${assignComponentPermission(
                                                                'account'
                                                            )}`}>
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button">
                                                                Account
                                                            </button>
                                                        </NavLink>
                                                        <NavLink
                                                            // to="/security"
                                                            key={'security-key'}
                                                            to="/home"
                                                            className={`${assignComponentPermission(
                                                                'account-security'
                                                            )}`}>
                                                            {' '}
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button">
                                                                Account Security
                                                            </button>
                                                        </NavLink>
                                                        <NavLink
                                                            // to="/language-settings"
                                                            key={'language-key'}
                                                            to="/home"
                                                            className={`${assignComponentPermission(
                                                                'language-settings'
                                                            )}`}>
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button">
                                                                Language Settings
                                                            </button>
                                                        </NavLink>
                                                        <NavLink
                                                            // to="/manage-users"
                                                            key={'managers-key'}
                                                            to="/home"
                                                            className={`${assignComponentPermission(
                                                                'manage-users'
                                                            )}`}>
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button">
                                                                Managers Users
                                                            </button>
                                                        </NavLink>

                                                        <NavLink key={'logout-key'} to="/">
                                                            <button
                                                                className="dropdown-item cursor-pointer"
                                                                type="button"
                                                                onClick={logoutAction}>
                                                                Log out
                                                            </button>
                                                        </NavLink>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        </>
                                    ) : (
                                        <li key={'save-button-key'}>
                                            <NavLink className="nav-link " key={'save-key'} to="/">
                                                <p className="brown-nav-item p-0">Save & Exit</p>
                                            </NavLink>
                                        </li>
                                    )}
                                    <li className="nav-item" key={'save-button-key'}>
                                        <Button
                                            color="transparent"
                                            className="btn-icon-only p-0"
                                            size="lg"
                                            onClick={toggleSelectLangModal}>
                                            <img src={Flag} alt="" />
                                        </Button>
                                    </li>
                                </ul>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            ) : (
                // Show when User not logged in
                <Navbar
                    key={'logo-navbar'}
                    className="hero-navbar sticky-top justify-content-between"
                    color="faded"
                    light
                    expand="md">
                    <Container fluid={true}>
                        <NavbarBrand tag={Link} to={'/'}>
                            <img src={Logo} className="img-fluid" alt="" />
                        </NavbarBrand>

                        <MobileNav>
                            <Nav className="navbar-nav align-items-lg-center margin-list" navbar>
                                <NavItem key={'apply-key'}>
                                    <span
                                        onClick={() => {
                                            setShowApplicationModal(true);
                                        }}
                                        role="button"
                                        className="nav-link as-btn type-primary">
                                        Apply Now{' '}
                                    </span>
                                </NavItem>
                                <NavItem key={'signin-key'}>
                                    <NavLink
                                        className="nav-link as-btn type-secondary cursor-pointer"
                                        to="/signin">
                                        Sign in
                                    </NavLink>
                                </NavItem>
                                <NavItem key={'popup-key'}>
                                    <Button
                                        color="transparent"
                                        className="p-0"
                                        size="lg"
                                        onClick={toggleSelectLangModal}>
                                        <img src={Flag} alt="" />
                                        &nbsp; Choose Preferred Language
                                    </Button>
                                </NavItem>
                            </Nav>
                        </MobileNav>
                        <Collapse navbar>
                            <Nav className="ml-auto mt-2 flex-row justify-content-center" navbar>
                                <NavItem key={'apply-key'} style={{ marginRight: '14px' }}>
                                    <span
                                        onClick={() => {
                                            setShowApplicationModal(true);
                                        }}
                                        role="button"
                                        className="nav-link as-btn type-primary">
                                        Apply Now{' '}
                                    </span>
                                </NavItem>
                                <NavItem key={'signin-key'} style={{ marginRight: '18px' }}>
                                    <NavLink
                                        className="nav-link as-btn type-secondary cursor-pointer"
                                        to="/signin">
                                        Sign in
                                    </NavLink>
                                </NavItem>
                                <NavItem key={'popup-key'}>
                                    <Button
                                        color="transparent"
                                        className="btn-icon-only p-0"
                                        size="lg"
                                        onClick={toggleSelectLangModal}>
                                        <img src={Flag} alt="" />
                                    </Button>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </Container>
                </Navbar>
            )}

            {/* Select Language Modal */}
            <Modal
                isOpen={isSelectLangModalOpen}
                size="lg"
                toggle={toggleSelectLangModal}
                scrollable
                centered>
                <ModalHeader toggle={toggleSelectLangModal}>Select Your Language</ModalHeader>
                <ModalBody>
                    <p>
                        This will select the language of this website. It will not change your
                        product or listing pages.
                    </p>
                    <Radio.Group value={selectedLanguage.value} onChange={changeLanguage}>
                        {allLanguages.map((item, index) => (
                            <Radio className="d-block mb-2" key={index} value={item.value}>
                                {item.name}
                            </Radio>
                        ))}
                    </Radio.Group>
                </ModalBody>
                <ModalFooter className="justify-content-start">
                    <AntButton
                        type="primary"
                        className="btn-px"
                        size="large"
                        onClick={saveLanguage}
                        loading={savingLanguage}>
                        Save
                    </AntButton>
                    <Button color="dark" size="lg" onClick={toggleSelectLangModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

MobileNav.propTypes = {
    key: PropTypes.number,
    children: PropTypes.node
};

NavBar.propTypes = {
    business_info: PropTypes.object,
    my_profile: PropTypes.object,
    getBusinessInformation: PropTypes.func,
    auth: PropTypes.object,
    getCurretUser: PropTypes.func,
    file_upload_progress: PropTypes.object
};

const mapStateToProps = (state) => ({
    business_info: state.profile.business_info,
    my_profile: state.auth.my_profile,
    auth: state.auth?.auth,
    file_upload_progress: state.contentDetail.file_upload_progress
});

const mapDispatch = (dispatch) => ({
    getBusinessInformation: () => dispatch(getBusinessInformation()),
    getCurretUser: () => dispatch(getCurretUser())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(NavBar));

import React from 'react';
import { Popover } from 'antd';
import PropTypes from 'prop-types';

const SubmitButtonErrorPopover = ({ visible, errors, children }) => {
    return (
        <Popover
            trigger="focus"
            content={
                <div>
                    <h6 className="text-white">Please fill form correctly!</h6>
                    {
                        <ol>
                            {errors &&
                                Object.keys(errors).length > 0 &&
                                Object.keys(errors).map((value, key) => (
                                    <li key={key}>{errors[value]}</li>
                                ))}
                        </ol>
                    }
                </div>
            }
            visible={visible}>
            {children}
        </Popover>
    );
};

SubmitButtonErrorPopover.propTypes = {
    visible: PropTypes.any,
    errors: PropTypes.any,
    children: PropTypes.any
};

export default SubmitButtonErrorPopover;

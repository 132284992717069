import React from 'react';
import PropTypes from 'prop-types';
import { Row, Button, Col, Card, CardBody } from 'reactstrap';
import { ComponentPermissionContext } from 'context/rolesandpermission';

const CustomersCalendarInsights = (props) => {
    const { assignComponentPermission } = React.useContext(ComponentPermissionContext);
    const {
        handleOnClickCalendarSales,
        handleOnClickCustomerInterest,
        handleOnClickCustomerDemand
    } = props;
    return (
        <div className={`${assignComponentPermission('calendar-insight')}`}>
            <h5 className="card-title mb-3">Customers Calendar Insight</h5>
            <Row>
                <Col className="mb-12px" md lg={12} xl>
                    <Card className="card-sm">
                        <CardBody>
                            <div className="text-primary text-nowrap fw-600">Top Calendar Sale</div>
                            <div className="fs-20 fw-600">$5,000</div>
                            <div className="mt-2 text-right">
                                <Button
                                    color="link"
                                    className="fs-14 text-decoration-underline"
                                    onClick={handleOnClickCalendarSales}>
                                    View Details
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-12px" md lg={12} xl>
                    <Card className="card-sm">
                        <CardBody>
                            <div className="text-primary text-nowrap fw-600">
                                Top Customers Interest
                            </div>
                            <div className="fs-20 fw-600">Safari 50%</div>
                            <div className="mt-2 text-right">
                                <Button
                                    color="link"
                                    className="fs-14 text-decoration-underline"
                                    onClick={handleOnClickCustomerInterest}>
                                    View Details
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col className="mb-12px" md lg={12} xl>
                    <Card className="card-sm">
                        <CardBody>
                            <div className="text-primary text-nowrap fw-600">
                                Highest Calendar Duration (%)
                            </div>
                            <div className="fs-20 fw-600">1 week calendar 60%</div>
                            <div className="mt-2 text-right">
                                <Button
                                    color="link"
                                    className="fs-14 text-decoration-underline"
                                    onClick={handleOnClickCustomerDemand}>
                                    View Details
                                </Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

CustomersCalendarInsights.propTypes = {
    handleOnClickCalendarSales: PropTypes.func,
    handleOnClickCustomerDemand: PropTypes.func,
    handleOnClickCustomerInterest: PropTypes.func
};

export default CustomersCalendarInsights;

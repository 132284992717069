/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Prompter from '../../../../atoms/prompter';
import Close from '../../../../../assets/images/close.svg';
import { Modal, Button, Radio, Form, Row, Col, Checkbox, Select, Input } from 'antd';
import './index.scss';

import { QuestionCircleOutlined } from '@ant-design/icons';
import Spacer from '../../../../atoms/spacer';

const radioStyle = {
    display: 'flex'
    // height: '30px',
    // lineHeight: '30px',
};

const PhysicalRestrictons = (props) => {
    const { handleOk, isVisible, handleCancel } = props;

    let [state, setState] = useState({
        accessibility: [
            {
                name: 'Is it Wheelchair accessible?',
                value: ''
            },
            {
                name: 'Is it stroller accessible?',
                value: ''
            },
            {
                name: 'Are service animals allowed?',
                value: ''
            },
            {
                name: 'Can travelers easily arrive/depart on public transportation?',
                value: ''
            },
            {
                name: 'Are infants required to sit on laps?',
                value: ''
            },
            {
                name: 'Are infant seats available?',
                value: ''
            }
        ],
        healthRestricitons: [
            {
                name: 'Not recommended for travelers with back problems',
                value: ''
            },
            {
                name: 'Not recommended for pregnant travelers',
                value: ''
            },
            {
                name: 'Not recommended for travelers with heart problems or other serious medical conditions',
                value: ''
            }
        ]
    });

    const addNewHealthRestricition = () => {
        const newState = { ...state };

        newState.healthRestricitons.push({
            name: '',
            value: '',
            added: true
        });
        setState(newState);
    };

    const addNewAccessibility = () => {
        const newState = { ...state };

        newState.accessibility.push({
            name: '',
            value: '',
            added: true
        });
        setState(newState);
    };

    const removeHealthRestricition = (index) => {
        const newState = { ...state };

        newState.healthRestricitons.splice(index, 1);
        setState(newState);
    };

    const removeAccessibility = (index) => {
        const newState = { ...state };

        newState.accessibility.splice(index, 1);
        setState(newState);
    };

    const setAccessibility = (index, e) => {
        const newState = { ...state };

        newState.accessibility[index].name = e.target.value;
        setState(newState);
    };

    const setHealthRestriction = (index, e) => {
        const newState = { ...state };

        newState.healthRestricitons[index].name = e.target.value;
        setState({
            ...state,
            ...newState
        });
    };

    return (
        <Modal
            width={760}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            wrapClassName="language-offered"
            footer={[
                <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                    className="buttons mt100">
                    <div>
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="primary"
                            className="rounded-btn mr20"
                            onClick={() => handleOk({})}>
                            Save
                        </Button>
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="outline"
                            className="rounded-btn mr20"
                            onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                    <Button
                        type="primary"
                        className="rounded-btn"
                        style={{ float: 'right', height: 42 }}>
                        <QuestionCircleOutlined /> Help
                    </Button>
                </div>
            ]}>
            <h4>Add any physical restrictions for this Itinerary</h4>
            <Prompter
                textColor="#A2792C"
                text="I will import physical restriction information from my other activity"
            />
            <Spacer top={26} />
            <p>This information will help travelers know if this is a good product for them.</p>

            <Form layout="vertical" className="">
                <div className="physical-restriction">
                    <div className="">
                        <Row className="physical-restriction__row">
                            <Col span="21">
                                <span>Accessibility</span>
                            </Col>
                            <Col className="physical-restricition__selection" span="3">
                                <span>Yes</span>
                                <span>No</span>
                            </Col>
                        </Row>
                        {state.accessibility.map((item, index) => (
                            <Row className="physical-restriction__row">
                                <Col span="21">
                                    {item.added ? (
                                        <Input
                                            suffix={
                                                <img
                                                    height="12"
                                                    width="12"
                                                    src={Close}
                                                    onClick={() => removeAccessibility(index)}
                                                    alt=""
                                                />
                                            }
                                            style={{
                                                width: 420,
                                                border: '0.5px solid #A2792C',
                                                boxSizing: 'border-box',
                                                borderRadius: '8px'
                                            }}
                                            onChange={(e) => setAccessibility(index, e)}
                                            value={item.name}
                                        />
                                    ) : (
                                        <span>{item.name}</span>
                                    )}
                                </Col>
                                <Col span="3">
                                    <Radio.Group onChange={() => {}}>
                                        <Radio value={1}></Radio>
                                        <Radio value={2}></Radio>
                                    </Radio.Group>
                                </Col>
                            </Row>
                        ))}
                        <Spacer top={15} />
                        <Prompter
                            onClick={addNewAccessibility}
                            textColor="#A2792C"
                            text="Add another"
                        />
                    </div>
                    <Spacer top={50} />
                    <div>
                        <h6>Health restrictions</h6>
                        <p>Check all that apply</p>
                        {state.healthRestricitons.map((item, index) => (
                            <p key={item.name + index} style={{ marginBottom: '10px' }}>
                                <Checkbox style={{ display: 'flex', alignItems: 'center' }}>
                                    {item.added ? (
                                        <Input
                                            suffix={
                                                <img
                                                    height="12"
                                                    width="12"
                                                    src={Close}
                                                    onClick={() => removeHealthRestricition(index)}
                                                    alt=""
                                                />
                                            }
                                            style={{
                                                width: 420,
                                                border: '0.5px solid #A2792C',
                                                boxSizing: 'border-box',
                                                borderRadius: '8px'
                                            }}
                                            onChange={(e) => setHealthRestriction(index, e)}
                                            value={item.name}
                                        />
                                    ) : (
                                        item.name
                                    )}
                                </Checkbox>
                            </p>
                        ))}
                        <Prompter
                            onClick={addNewHealthRestricition}
                            textColor="#A2792C"
                            text="Add another"
                        />
                    </div>
                    <Spacer top={36} />
                    <div className="difficulty">
                        <h6>Select the physical difficulty level</h6>
                        <Radio.Group onChange={() => {}}>
                            <Radio style={radioStyle} value={1}>
                                <h6>Easy</h6>
                                <p>Most travelers can participate</p>
                            </Radio>
                            <Radio style={radioStyle} value={2}>
                                <h6>Moderate</h6>
                                <p>Travelers should have a moderate physical fitness level</p>
                            </Radio>
                            <Radio style={radioStyle} value={3}>
                                <h6>Challenging</h6>
                                <p>Travelers should have a moderate physical fitness level</p>
                            </Radio>
                        </Radio.Group>
                    </div>
                    <Spacer top={36} />
                    <div>
                        <h6>Your phone number</h6>
                        <p>
                            This is the number travelers will call if they need to reach you on the
                            day of the travel
                        </p>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}>
                            <Select
                                style={{
                                    width: 86,
                                    height: 50
                                }}>
                                <Select.Option>+1</Select.Option>
                                <Select.Option>+234</Select.Option>
                                <Select.Option>+41</Select.Option>
                                {/* <Select.Option>+</Select.Option>
                                <Select.Option>+1</Select.Option>
                                <Select.Option>+1</Select.Option> */}
                            </Select>
                            <Input
                                style={{
                                    width: '85%',
                                    border: '0.5px solid #A2792C',
                                    boxSizing: 'border-box',
                                    borderRadius: '8px',
                                    height: 50
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Form>
        </Modal>
    );
};

PhysicalRestrictons.propTypes = {
    handleOk: PropTypes.func,
    isVisible: PropTypes.bool,
    handleCancel: PropTypes.func
};

export default PhysicalRestrictons;

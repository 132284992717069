/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState, useEffect } from 'react';
import '../../add-an-itinerary/index.scss';
import { Button as AntButton, Form } from 'antd';
import Gallery from '../../../gallery';
import Plus from 'assets/images/plus.svg';
import DeleteIcon from 'assets/images/close.svg';
import PropTypes from 'prop-types';

const AddMediaToItenary = ({ formRef, initialMedia, onSaveItineraryMedia }) => {
    const [isOpenGallery, setIsOpenGallery] = useState(false);
    const [titleGallery, setTitleGallery] = useState('');
    const [media, setMedia] = useState(initialMedia);
    //save media

    useEffect(() => {
        setMedia(initialMedia);
    }, [initialMedia]);

    const onSaveMedia = (value) => {
        formRef.current.setFieldsValue({
            media: value
        });
        setMedia(value);
        onSaveItineraryMedia(value);
        setIsOpenGallery(false);
    };

    //remove item in media
    const onDeleteMedia = (index) => {
        let tmp = media;
        tmp.splice(index, 1);
        formRef.current.setFieldsValue({
            media: tmp
        });
        setMedia(tmp);
    };

    return (
        <Form.Item
            name="media"
            rules={[
                {
                    validator: async (_, media) => {
                        if (!media)
                            return Promise.reject(new Error('Please select photo and/or video'));
                        else if (media.length < 6)
                            return Promise.reject(
                                new Error('You need to add at least 6 photos/videos')
                            );
                    }
                }
            ]}>
            <h6 className="input-title">Add Photos & Videos</h6>
            <p className="input-description">
                Use photos that highlight what’s unique about your experience and show travelers
                having fun. You need to add at least 6 items, it will help you to increase your
                bookings.
            </p>
            <Gallery
                showModal={isOpenGallery}
                media={media}
                onClose={() => setIsOpenGallery(!isOpenGallery)}
                title={titleGallery}
                onSaveMedia={(value) => onSaveMedia(value)}
            />
            <div className="d-flex flex-wrap gap-2 mb-3">
                <AntButton
                    style={{ margin: 0 }}
                    className="dash-btn"
                    id="addPhotos"
                    type="dashed"
                    onClick={() => {
                        setIsOpenGallery(!isOpenGallery);
                        setTitleGallery('My Images');
                    }}>
                    <img className="cursor-pointer img-plus" src={Plus} alt="" /> Add Photos
                </AntButton>
                <AntButton
                    style={{ margin: 0 }}
                    className="dash-btn"
                    id="addVideos"
                    type="dashed"
                    onClick={() => {
                        setIsOpenGallery(!isOpenGallery);
                        setTitleGallery('My Videos');
                    }}>
                    <img className="cursor-pointer img-plus" src={Plus} alt="" /> Add Videos
                </AntButton>
            </div>
            <div className="d-flex flex-wrap" style={{ gap: '0.5rem' }}>
                {media && media.length > 0
                    ? media.map((res, i) => (
                          <div
                              key={i}
                              className={`select-media ${res.type === 'video' ? 'has-video' : ''}`}>
                              {res?.type === 'image' ? (
                                  <img src={res.url} alt="" />
                              ) : (
                                  res?.type === 'video' && <video src={res.url}></video>
                              )}

                              <div className="delete-block">
                                  <img
                                      src={DeleteIcon}
                                      className="delete-icon cursor-pointer"
                                      alt=""
                                      onClick={() => onDeleteMedia(i)}
                                  />
                              </div>
                          </div>
                      ))
                    : null}
            </div>
        </Form.Item>
    );
};
AddMediaToItenary.propTypes = {
    media: PropTypes.any,
    formRef: PropTypes.any,
    initialMedia: PropTypes.any,
    onSaveMedia: PropTypes.func,
    onSaveItineraryMedia: PropTypes.func
};
export default AddMediaToItenary;

import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Spacer from '../spacer';
import './index.scss';
import Proptypes from 'prop-types';

function ItenaryCalenderSeason(props) {
    const { summary } = props;
    const [month, setMonth] = useState([
        {
            name: 'JAN',
            type: ''
        },
        {
            name: 'FEB',
            type: ''
        },
        {
            name: 'MAR',
            type: ''
        },
        {
            name: 'APR',
            type: ''
        },
        {
            name: 'MAY',
            type: ''
        },
        {
            name: 'JUN',
            type: ''
        },
        {
            name: 'JUL',
            type: ''
        },
        {
            name: 'AUG',
            type: ''
        },
        {
            name: 'SEP',
            type: ''
        },
        {
            name: 'OCT',
            type: ''
        },
        {
            name: 'NOV',
            type: ''
        },
        {
            name: 'DEC',
            type: ''
        }
    ]);

    useEffect(() => {
        setColor();
    }, [summary]);
    //set color on calendar
    const setColor = () => {
        let tmp = month;
        summary.map((res, i) => {
            if (res.time && res.weather && !res.highSeason) {
                tmp[i].type = 'excellent';
            } else if (res.time && res.weather && res.highSeason) {
                tmp[i].type = 'good';
            } else if (res.time && !res.weather && res.highSeason) {
                tmp[i].type = 'fair';
            } else {
                tmp[i].type = '';
            }
        });
        setMonth([...tmp]);
        props.onSaveType([...tmp]);
    };

    return (
        <div className="itenary-calender-season ">
            <Row>
                <Col xs={24} md={10}>
                    <h6>Calender Season</h6>
                    <p className="itenary-calender-season__subtitle">
                        Travelers will be informed of the activity’s season according to the dates
                        they search for.
                    </p>
                </Col>
                <Col xs={24} md={14}>
                    <div className="itenary-calender-season__month-box">
                        <Col span="18">
                            <Row style={{ justifyContent: 'center' }}>
                                <Col className={month[0].type} span="6">
                                    JAN
                                </Col>
                                <Col className={month[1].type} span="6">
                                    FEB
                                </Col>
                                <Col className={month[2].type} span="6">
                                    MAR
                                </Col>
                                <Col className={month[3].type} span="6">
                                    APR
                                </Col>
                                <Col className={month[4].type} span="6">
                                    MAY
                                </Col>
                                <Col className={month[5].type} span="6">
                                    JUN
                                </Col>
                                <Col className={month[6].type} span="6">
                                    JUL
                                </Col>
                                <Col className={month[7].type} span="6">
                                    AUG
                                </Col>
                                <Col className={month[8].type} span="6">
                                    SEP
                                </Col>
                                <Col className={month[9].type} span="6">
                                    OCT
                                </Col>
                                <Col className={month[10].type} span="6">
                                    NOV
                                </Col>
                                <Col className={month[11].type} span="6">
                                    DEC
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ marginTop: 10 }} span="7">
                            <p className="excellent">Excellent</p>
                            <p className="good">Good</p>
                            <p className="fair">Fair</p>
                        </Col>
                    </div>
                </Col>
            </Row>
            <Spacer top={14} />
            <div className="itenary-calender-season__summary">Summary</div>
            <Spacer top={8} />
            <div className="itenary-calender-season__subsummary">
                <b>Excellent</b> - best time to visit, best weather and not in the high season.
            </div>
            <div className="itenary-calender-season__subsummary">
                <b>Good</b> - best time to visit, best weather and in the high season.
            </div>
            <div className="itenary-calender-season__subsummary">
                <b>Fair</b> - best time to visit, not the best weather and in the high season.
            </div>
        </div>
    );
}

ItenaryCalenderSeason.propTypes = {
    summary: Proptypes.array,
    onSaveType: Proptypes.func
};

export default ItenaryCalenderSeason;

import React from 'react';
// import PropTypes from 'prop-types';

function Offer() {
    return <div className="offer"></div>;
}

Offer.propTypes = {};

export default Offer;

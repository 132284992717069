import React from 'react';

const NightLifePlusIcon = () => {
    return (
        <div className="night-life-plus-icon">
            <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <rect x="5" width="2" height="12" fill="white" />
                <rect x="12" y="5" width="2" height="12" transform="rotate(90 12 5)" fill="white" />
            </svg>
        </div>
    );
};

export default NightLifePlusIcon;

import React, { useState, useEffect } from 'react';
import './index.scss';

import { Form } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import GoogleIcon from '../../../../../assets/images/google.svg';
import TripadvisorIcon from '../../../../../assets/images/tripadvisor.svg';
import TrustpilotIcon from '../../../../../assets/images/trustpilot.svg';
import FacebookIcon from '../../../../../assets/images/facebook.svg';
import BookingsIcon from '../../../../../assets/images/bookings.svg';
import Connect from '../../../../atoms/connect-block';
import Proptypes from 'prop-types';
import { Feedback } from 'components/molecules';

export default function AddReviews(props) {
    const [reviews, setReviews] = useState(props.data || []);
    useEffect(() => {
        setReviews(props.data || []);
    }, [props.data]);
    const onAdd = (value, type) => {
        const index = reviews.findIndex((item) => item.type === type);
        const tmpData = reviews;
        const dataItem = {
            type: type,
            link: value
        };
        if (index === -1) {
            tmpData.push(dataItem);
            setReviews([...tmpData]);
        } else {
            tmpData.splice(index, 1, dataItem);
            setReviews([...tmpData]);
        }
    };

    const onFinish = () => props.onSave(reviews);
    const onClose = () => props.onClose();

    const [form] = Form.useForm();
    return (
        <Modal isOpen={props.isOpen} scrollable centered size="lg">
            <Form form={form} name="add-reviews-form" className="add-reviews">
                <ModalHeader className="title">Available Connections</ModalHeader>
                <ModalBody className="body-modal">
                    <Connect
                        name="GOOGLE"
                        img={GoogleIcon}
                        data={reviews}
                        onSave={(value) => onAdd(value, 'google')}
                    />
                    <Connect
                        name="TRIP ADVISOR"
                        img={TripadvisorIcon}
                        data={reviews}
                        onSave={(value) => onAdd(value, 'trip_advisor')}
                    />
                    <Connect
                        name="TRUST PILOT"
                        img={TrustpilotIcon}
                        data={reviews}
                        onSave={(value) => onAdd(value, 'trust_pilot')}
                    />
                    <Connect
                        name="FACEBOOK"
                        img={FacebookIcon}
                        data={reviews}
                        onSave={(value) => onAdd(value, 'facebook')}
                    />
                    <Connect
                        name="BOOKING.COM"
                        img={BookingsIcon}
                        data={reviews}
                        onSave={(value) => onAdd(value, 'booking')}
                    />
                    {/*<Connect name="AVVO" />*/}
                    {/*<Connect name="YELP" />*/}
                    {/*<Connect name="YELLOW PAGES" />*/}
                    {/*<Connect name="ZILLOW" />*/}
                    {/*<Connect name="YELL" />*/}
                    {/*<Connect name="BBB" />*/}
                    {/*<Connect name="HOMEADVISOR" />*/}
                    {/*<Connect name="THUMBTACK" />*/}
                    {/*<Connect name="SITEJABBER" />*/}
                    {/*<Connect name="HOUZZ" />*/}
                    {/*<Connect name="THE KNOT" />*/}
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        onClick={onFinish}>
                        {props.submitButtonName ?? 'Update Calender & Continue'}
                    </Button>
                    <Button
                        color="dark"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        type="button"
                        onClick={onClose}>
                        Cancel
                    </Button>
                    <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                </ModalFooter>
            </Form>
        </Modal>
    );
}
AddReviews.propTypes = {
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    isOpen: Proptypes.bool,
    data: Proptypes.object,
    submitButtonName: Proptypes.string
};

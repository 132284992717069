/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { InfoCircleFilled, PlusCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React, { useState } from 'react';
import VendorDashboard from '../../views/vendor-dashboard';
import ImageUploaderComponent from '../../../atoms/imageUploader/ImageUploader';
import TicketModal from '../../../organisms/ticketsModal/TicketModal';
import Feedback from '../../../molecules/user-feedback';

export default function AddWellnessService() {
    const { Panel } = Collapse;
    const [addDetails, setaddDetails] = useState(true);
    const [selectedDays, setselectedDays] = useState([]);

    const [modalStep, setmodalStep] = useState(0);
    const [days, setdays] = useState([
        {
            day: 1
        }
    ]);

    const addDay = () => {
        let newDay = days[days.length - 1].day + 1;
        setdays([...days, { day: newDay }]);
    };

    const onChange = () => {};

    const pickDay = (i) => {
        let present = selectedDays.find((n) => n === i);
        present
            ? setselectedDays(selectedDays.filter((d) => d !== i))
            : setselectedDays([...selectedDays, i]);
    };

    return (
        <>
            <VendorDashboard>
                <div className="personal-care-wrap">
                    {!addDetails ? (
                        <div className="personal-care">
                            <p className="page-title">
                                Tell us about your ‘Product Title’ - Personal Care Service
                            </p>
                            <p className="page-sub-title">
                                Be sure to enter all key attractions in your itinerary. This helps
                                travelers find and book your experience.
                            </p>
                            <div className="brown-patch">
                                <Collapse bordered={false} defaultActiveKey={['1']}>
                                    {days.map((item, i) => (
                                        <Panel header={`Day ${item.day}`} key={`day${i}`}>
                                            <div
                                                className="white-bg"
                                                style={{ padding: '15px 30px' }}>
                                                <p className="patch-title">Spa / Wellness</p>
                                                <p
                                                    className="patch-link"
                                                    onClick={() => setaddDetails(true)}>
                                                    <PlusCircleOutlined color="#A2792C" /> Add a Spa
                                                    / Wellness Service
                                                </p>
                                            </div>
                                        </Panel>
                                    ))}
                                </Collapse>

                                <p
                                    className="patch-link p10"
                                    style={{ cursor: 'pointer' }}
                                    onClick={addDay}>
                                    <PlusCircleOutlined color="#A2792C" /> Add a Spa / Wellness
                                    Service
                                </p>
                            </div>

                            <Button
                                type="primary"
                                size="large"
                                className="mt45 pl30 pr30"
                                style={{ borderRadius: 30 }}>
                                <span style={{ fontSize: 14 }}>Update Calender & Continue</span>
                            </Button>
                        </div>
                    ) : (
                        <div className="personal-care">
                            <p className="page-title">Add a Spa / Wellness Service</p>
                            <p className="page-sub-title brown-text">
                                <PlusCircleOutlined color="#A2792C" /> I will import Spa / Wellness
                                Service information from my other Personal Care Service
                            </p>

                            <div className="fields">
                                <p className="label">Add Spa / Wellness Service title</p>
                                <Input />

                                <p className="label">
                                    Describe what travelers will see and do here if they book your
                                    experience
                                </p>
                                <TextArea rows={6} />

                                <p className="label">Select main destination</p>
                                <Input search />
                            </div>

                            <div className="fields">
                                <p className="label">Add Spa / Wellness Service title</p>
                                <Select
                                    size="large"
                                    style={{ width: '100%', borderRadius: 8 }}
                                    options={[{ text: 'ooo', value: 'jhy' }]}
                                />

                                <p className="label">
                                    Select the customers calendar days this booking apply
                                </p>
                                <p className="label_">
                                    This product will be availlable for customers to book on the
                                    days selected below.
                                </p>
                                <Checkbox className="mt30">Select all</Checkbox>
                                <div className="select-days">
                                    {days.map((day) => (
                                        <div
                                            onClick={() => pickDay(day.day)}
                                            className={`day ${
                                                selectedDays.includes(day.day) ? 'selected' : ''
                                            }`}>
                                            Day {day.day}
                                        </div>
                                    ))}
                                </div>

                                <p className="label">Add Photos & Videos</p>
                                <p className="label_">
                                    Use photos that highlight what’s unique about your experience
                                    and show travelers having fun. Adding 6-9 photos and at least 1
                                    video can increase your bookings.
                                </p>
                                <div className="pictures">
                                    <ImageUploaderComponent onChange={onChange} />
                                    <ImageUploaderComponent onChange={onChange} />
                                </div>
                            </div>
                            <div className="fields">
                                <p className="label">
                                    Now tell us where you meet travelers for this market guide?
                                </p>
                                <div className="mb-12px">
                                    <Checkbox>At the accomodation provided by you</Checkbox>
                                </div>
                                <div className="mb-12px">
                                    <Checkbox>
                                        Allow travellers to enter their their own pick up location
                                        <div className="fs-14">
                                            Do not select this, if you only pick up from the your
                                            accomodation or the starting point you identified.
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="mb-12px">
                                    <Checkbox>at a Starting Point</Checkbox>
                                </div>
                                <p className="page-sub-title brown-text">
                                    <PlusCircleOutlined color="#A2792C" />
                                    Add another starting point
                                </p>

                                <p className="label mt25">What is the Depature time?</p>
                                <div>
                                    <Input
                                        style={{
                                            width: 200,
                                            border: '0.3px solid #a2792c',
                                            borderRadius: 8,
                                            marginRight: 12,
                                            overflow: 'hidden'
                                        }}
                                        addonAfter={'Hours'}
                                        defaultValue="0"
                                        bordered={false}
                                    />
                                    <Input
                                        style={{
                                            width: 200,
                                            border: '0.3px solid #a2792c',
                                            borderRadius: 8,
                                            overflow: 'hidden'
                                        }}
                                        addonAfter={'Minutes'}
                                        defaultValue="0"
                                        bordered={false}
                                    />
                                </div>
                                <p className="label mt25">
                                    How long before departure does your pickup start?
                                </p>
                                <div>
                                    <Input
                                        style={{
                                            width: 200,
                                            border: '0.3px solid #a2792c',
                                            borderRadius: 8,
                                            marginRight: 12,
                                            overflow: 'hidden'
                                        }}
                                        addonAfter={'Minutes'}
                                        defaultValue="0"
                                        bordered={false}
                                    />
                                </div>

                                <p className="label mt25" style={{ fontWeight: 600 }}>
                                    Enter the maximum number of spaces available to book this Market
                                    Guide for the time period.
                                </p>
                                <p className="label_">
                                    This will indicate the spaces available to travelers and once
                                    this is reached, this product activtity will be marked as sold
                                    out for that day
                                </p>
                                <Input
                                    style={{
                                        width: 150,
                                        border: '0.3px solid #a2792c',
                                        borderRadius: 8,
                                        marginRight: 12,
                                        overflow: 'hidden'
                                    }}
                                    defaultValue="0"
                                />
                            </div>

                            <div className="extra-details">
                                <div className="detail">
                                    <p className="heading">
                                        Pricing{' '}
                                        <InfoCircleFilled
                                            style={{
                                                fontSize: '12px',
                                                color: '#E2C022',
                                                position: 'relative',
                                                top: '-12px'
                                            }}
                                            size="small"
                                            color="#A2792C"
                                        />{' '}
                                    </p>
                                    <p style={{ color: '#333333', margin: 0 }}>
                                        <PlusCircleOutlined
                                            color="#A2792C"
                                            style={{ marginRight: 10 }}
                                        />
                                        Add Pricing details for your ‘Product Title’ Market Guide
                                    </p>
                                </div>
                                <div className="detail">
                                    <p className="heading">
                                        Booking and Tickets{' '}
                                        <InfoCircleFilled
                                            style={{
                                                fontSize: '12px',
                                                color: '#E2C022',
                                                position: 'relative',
                                                top: '-12px'
                                            }}
                                            size="small"
                                            color="#A2792C"
                                        />{' '}
                                    </p>
                                    <p
                                        onClick={() => {
                                            setmodalStep(1);
                                        }}
                                        style={{ color: '#333333', margin: 0 }}
                                        className="pointer">
                                        <PlusCircleOutlined
                                            color="#A2792C"
                                            style={{ marginRight: 10 }}
                                        />
                                        Add Booking and Ticketing details for your ‘Product Title’
                                        Market Guide
                                    </p>
                                </div>
                                <div className="detail">
                                    <p className="heading">
                                        Information travellers need from you{' '}
                                        <InfoCircleFilled
                                            style={{
                                                fontSize: '12px',
                                                color: '#E2C022',
                                                position: 'relative',
                                                top: '-12px'
                                            }}
                                            size="small"
                                            color="#A2792C"
                                        />{' '}
                                    </p>
                                    <p style={{ color: '#333333', margin: 0 }}>
                                        <PlusCircleOutlined
                                            color="#A2792C"
                                            style={{ marginRight: 10 }}
                                        />
                                        Add any physical restrictions for this Market Guide
                                    </p>
                                </div>
                                <div className="detail">
                                    <p className="heading">
                                        Covid-19 Updates{' '}
                                        <InfoCircleFilled
                                            style={{
                                                fontSize: '12px',
                                                color: '#E2C022',
                                                position: 'relative',
                                                top: '-12px'
                                            }}
                                            size="small"
                                            color="#A2792C"
                                        />{' '}
                                    </p>
                                    <p style={{ color: '#333333', margin: 0 }}>
                                        <PlusCircleOutlined
                                            color="#A2792C"
                                            style={{ marginRight: 10 }}
                                        />
                                        Add Covid-19 safety updates
                                    </p>
                                </div>

                                <div className="buttons mt100">
                                    <Button type="primary" className="rounded-btn mr20">
                                        Done
                                    </Button>
                                    <Button type="ghost" className="rounded-btn">
                                        Done
                                    </Button>
                                    <div style={{ float: 'right' }}>
                                        <Feedback />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="white-bg mt40">{/* <CalendarComponent /> */}</div>
                </div>

                <TicketModal modalStep={modalStep} setmodalStep={setmodalStep} />
            </VendorDashboard>
        </>
    );
}

import React from 'react';
import './index.scss';

import { Form } from 'antd';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import Spacer from '../../../../atoms/spacer';
import Proptypes from 'prop-types';
import { Grid } from '@material-ui/core';
import SharingGroups from '../../../../atoms/pricing-age/single-supplement/sharirn-groups';
import Prompter from '../../../../atoms/prompter';
import BookingPrice from '../../../../atoms/pricing-age/booking-price';
import SeasonSupplement from '../../../../atoms/pricing-age/season-supplement';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import SharingGroupRow from './sharingGroupRow';
import Table from '@material-ui/core/Table';

export default class Pricing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            newGroupRows: [{}],
            addNewBooking: [
                {
                    rate: 0,
                    min: 0,
                    max: 0
                }
            ],
            addNewSeasonSupplement: [
                {
                    rate: 0,
                    start: '',
                    end: '',
                    season: ''
                }
            ],
            addSingle: false,
            pp_sharing: {
                double: {},
                single: {}
            },
            pp_single: {
                double: {},
                twin: {}
            }
        };
    }
    componentDidMount() {
        const { data } = this.props;
        if (data) this.setData(data);
    }
    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (data && data !== prevProps.data) this.setData(data);
        else if (!data && data !== prevProps.data)
            this.setState({
                newGroupRows: [{}],
                addNewBooking: [
                    {
                        rate: 0,
                        min: 0,
                        max: 0
                    }
                ],
                addNewSeasonSupplement: [
                    {
                        rate: 0,
                        start: '',
                        end: '',
                        season: ''
                    }
                ],
                addSingle: false,
                pp_sharing: {
                    double: {},
                    single: {}
                },
                pp_single: {
                    double: {},
                    twin: {}
                }
            });
    }
    setData = (data) => {
        this.setState({
            pp_sharing: data.pp_sharing || {
                double: {},
                single: {}
            },
            pp_single: data.pp_single || {
                double: {},
                twin: {}
            },
            addNewBooking: data.group || [
                {
                    rate: 0,
                    min: 0,
                    max: 0
                }
            ],
            addNewSeasonSupplement: data.festive || [
                {
                    rate: 0,
                    start: '',
                    end: '',
                    season: ''
                }
            ]
        });
    };
    //adding new book price
    AddNewBook = () => {
        let tempNewGroup = [...this.state.addNewBooking];
        tempNewGroup.push({
            rate: 0,
            min: 0,
            max: 0
        });
        this.setState({ addNewBooking: [...tempNewGroup] });
    };
    //adding new season price
    addNewSeason = () => {
        let tempNewGroup = [...this.state.addNewSeasonSupplement];
        tempNewGroup.push({
            rate: 0,
            start: '',
            end: '',
            season: ''
        });
        this.setState({ addNewSeasonSupplement: [...tempNewGroup] });
    };
    //saving sharing price
    saveSharingGroup = (value, index) => {
        const { pp_sharing } = this.state;
        let tmp = pp_sharing;
        if (index === 0) tmp.double = value;
        else tmp.single = value;
        this.setState({ pp_sharing: { ...tmp } });
    };
    //saving single price
    saveSingle = (value, index) => {
        let tmp = this.state.pp_single;
        if (index === 0)
            tmp.double = {
                single_price: parseInt(value.single_price),
                double_price: parseInt(value.double_price)
            };
        else
            tmp.twin = {
                single_price: parseInt(value.single_price),
                double_price: parseInt(value.double_price)
            };
        this.setState({ pp_single: { ...tmp } });
    };

    onClose = () => this.props.onClose();
    onFinish = () => {
        const { pp_sharing, pp_single, addNewBooking, addNewSeasonSupplement } = this.state;
        const tmpPricing = {};
        if (
            pp_sharing.double.price > 0 ||
            pp_sharing.double.ss_price > 0 ||
            pp_sharing.single.price > 0 ||
            pp_sharing.single.ss_price > 0
        )
            tmpPricing.pp_sharing = pp_sharing;
        if (pp_single.double.double_price > 0 || pp_single.double.single_price > 0)
            tmpPricing.pp_single = pp_single;
        if (addNewBooking[0].rate > 0 || addNewBooking[0].min > 0 || addNewBooking[0].max > 0)
            tmpPricing.group = addNewBooking;
        if (
            addNewSeasonSupplement[0].rate > 0 ||
            addNewSeasonSupplement[0].start ||
            addNewSeasonSupplement[0].end ||
            addNewSeasonSupplement[0].season
        )
            tmpPricing.festive = addNewSeasonSupplement;
        this.props.onSave(tmpPricing);
        this.props.onClose();
    };

    render() {
        const { isOpen } = this.props;
        const { pp_sharing, pp_single } = this.state;
        return (
            <Modal isOpen={isOpen} centered size="lg">
                <Form name="covid-form" className="pricing-accomodation">
                    <ModalHeader className="title">
                        Accomodation pricing details
                        <div style={{ fontSize: 14 }}>
                            Enter the additional price you will charge travelers for booking your
                            activity with the your accomodationa per person (PP)
                        </div>
                        <div className="subtitle">
                            The pricing detail you enter below includes all applicable fees, taxes
                            and other charges and excludes our retail commission which the customer
                            will pay for.
                        </div>
                    </ModalHeader>
                    <ModalBody className="body-modal">
                        <Grid item className="perPerson">
                            <Grid container style={{ flexDirection: 'column' }}>
                                <div style={{ fontSize: 14 }}>
                                    Enter additional price for your Accomodations
                                </div>

                                <Grid item className="perPerson">
                                    <Grid container>
                                        <div style={{ fontWeight: 600 }}>Per person sharing</div>
                                        <p style={{ fontSize: 14 }}>
                                            This is the price your enter below is for a person
                                            sharing a room with another person.
                                        </p>
                                        <Spacer top={18} />
                                        <Grid item>
                                            <Table
                                                style={{ width: 'max-content' }}
                                                aria-label="customized table">
                                                <TableHead>
                                                    <Row>
                                                        <Col sm={3} />
                                                        <Col align="left">Activity price</Col>
                                                        {this.state.addSingle ? (
                                                            <Col align="left">Single Suplement</Col>
                                                        ) : null}
                                                    </Row>
                                                </TableHead>
                                                <TableBody>
                                                    {['Double', 'Twin Room'].map((each, index) => (
                                                        <SharingGroupRow
                                                            key={index}
                                                            data={
                                                                index === 0
                                                                    ? pp_sharing.double
                                                                    : pp_sharing.single
                                                            }
                                                            groupName={each}
                                                            onSave={(value) =>
                                                                this.saveSharingGroup(value, index)
                                                            }
                                                            addSingleHandler={() =>
                                                                this.setState({ addSingle: true })
                                                            }
                                                        />
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Spacer top={15} />
                                <div style={{ fontSize: 14 }}>
                                    Single Suplement - An extra charge applied to a traveler who
                                    travels alone but will use a room and facilities that cater for
                                    two.
                                </div>
                                <Grid item className="perPerson">
                                    <Grid container>
                                        <div style={{ fontWeight: 600 }}>
                                            Per Person Single / Single Room Occupancy
                                        </div>
                                        <p style={{ fontSize: 14 }}>
                                            This means that the price you enter below is for one
                                            person in a room.
                                        </p>
                                        <Spacer top={18} />
                                        <Grid item>
                                            <SharingGroups
                                                data={pp_single}
                                                onSave={(value, index) =>
                                                    this.saveSingle(value, index)
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <hr width="100%" />
                                <Grid item className="perPerson">
                                    <Grid container>
                                        <Row className="book-price">
                                            <div style={{ fontWeight: 600, fontSize: 18 }}>
                                                Add Group Booking Price
                                            </div>
                                            <Prompter
                                                fontSize={14}
                                                textColor="#A2792C"
                                                text={`Add More`}
                                                fon
                                                onClick={this.AddNewBook}
                                            />
                                        </Row>
                                    </Grid>
                                    <Spacer top={20} />
                                    <Row>
                                        <Col sm={4} className="book-subtitles">
                                            <b>Reduce The ‘per person’ rate above by</b>
                                            <div>Reduce The ‘per person’ rate above by</div>
                                        </Col>
                                        <Col sm={8}>
                                            <BookingPrice
                                                addNewBooking={this.state.addNewBooking}
                                                onSave={(value) =>
                                                    this.setState({ addNewBooking: value })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                                <hr width="100%" />
                                <Grid item className="perPerson">
                                    <Grid container>
                                        <Row className="book-price">
                                            <div style={{ fontWeight: 600, fontSize: 18 }}>
                                                Add Price for Peak/Festive Season Supplement
                                            </div>
                                            <Prompter
                                                fontSize={14}
                                                textColor="#A2792C"
                                                text={`Add More`}
                                                fon
                                                onClick={this.addNewSeason}
                                            />
                                        </Row>
                                    </Grid>
                                    <Spacer top={20} />
                                    <Row>
                                        <Col sm={3} className="book-subtitles">
                                            <b>Reduce The ‘per person’ rate above by</b>
                                        </Col>
                                        <Col sm={9}>
                                            <SeasonSupplement
                                                addNewBooking={this.state.addNewSeasonSupplement}
                                                onSave={(value) =>
                                                    this.setState({ addNewSeasonSupplement: value })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ModalBody>
                    <ModalFooter>
                        <div className="footer-btn">
                            <div>
                                <Button color="primary" onClick={this.onFinish}>
                                    Update Calender & Continue
                                </Button>
                                <Button
                                    color="dark"
                                    style={{ marginLeft: '8px' }}
                                    onClick={this.onClose}>
                                    Cancel
                                </Button>
                            </div>
                            <Button color="dark" style={{ width: '137px' }}>
                                <img src={HelpIcon} alt="" /> Help
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}
Pricing.propTypes = {
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    data: Proptypes.object,
    calendarData: Proptypes.object
};

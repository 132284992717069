/*eslint no-prototype-builtins: "error"*/
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import moment from 'moment';

import {
    Wildlife,
    Elephant,
    Buffalo,
    Hyena,
    Lion,
    Leopard,
    Giraffe,
    Rhino,
    Cheetah,
    Hippo,
    Dog,
    Beast,
    Zebra,
    Localfavorites,
    TouristAttractions,
    History,
    HistoricAttractions,
    Markets,
    Casino,
    Beaches,
    AmusementPark,
    Museums,
    Architecture,
    ArtGallery,
    ArtsAndCulture,
    Aquaria,
    AccessibleSafari,
    FamilySafari,
    HorseSafari,
    MapCanoe,
    Cycling,
    FlyinSafari,
    Golf,
    ControllerClassic,
    LuxurySafari,
    Camera,
    Gorilla,
    WalkingSafari,
    Dance,
    SingingClasses,
    Jewelery,
    Textiles,
    PaperCraft,
    Pottery,
    Woodcraft,
    Dinner,
    LocalTaxi,
    Party,
    Romantic,
    HorseRiding,
    Orienteering,
    PeopleTeam,
    Carnivals,
    Concerts,
    Theatre,
    Archery,
    Biking,
    Camping,
    HillWalk,
    Scrambling,
    Climbing,
    Skiing,
    Duckies,
    Kayaking,
    PowerBoating,
    Sailing,
    Standup,
    Swimming,
    Brewery,
    Cookery,
    FoodTasting,
    Picnics,
    WineTasting,
    Meditation,
    Spa,
    Photoshoot,
    KidsFriendly,
    MothersDay,
    Bachelorette,
    Honeymoon,
    FamilyFriendly,
    Bachelor,
    FathersDay,
    Christmas,
    Birthdays,
    Anniversary,
    LocalPerformance
} from 'assets/index';

export async function getItineraryState({ item, anotherStart }) {
    // let convertedStartTime = item?.start_time
    //     ? moment(item?.start_time, 'HH:mm').format('hh:mm A')
    //     : '';
    // let convertedEndTime = item?.finish_time
    //     ? moment(item?.finish_time, 'HH:mm').format('hh:mm A')
    //     : '';
    return {
        title: item?.title,
        describe: item?.description,
        media: item?.photos_and_videos,
        mealsAndDrinks: item?.meals_and_drinks || [],
        // languages: item?.language_offered?.languages || [],
        inclusion: item?.inclusions || [],
        exclusion: item?.exclusions || [],
        adventure: item?.adventures,
        occassion: item?.occassions,
        sights: getSightsFromResponse(item?.sights),
        start: item?.start_time ? item?.start_time : '',
        finish: item?.finish_time ? item?.finish_time : '',
        //startTimeAmPm: convertedStartTime ? `${convertedStartTime.split(' ')[1]}` : '',
        //endTimeAmPm: convertedEndTime ? `${convertedEndTime.split(' ')[1]}` : '',
        admission_with_price:
            item?.is_admission_included &&
            (item?.is_admission_included === 'Yes'
                ? 1
                : item?.is_admission_included === 'No'
                ? 2
                : item?.is_admission_included === 'NIL'
                ? 3
                : null),
        information: item?.traveller_information,
        calendar_season: item?.calendar_season || null,
        departure_time: item?.departure_time ? item?.departure_time : '', //am_pm: 'AM', hour: 12, minute: 50
        departure_pickup_duration: item?.departure_pickup_duration
            ? secondsToHms(item.departure_pickup_duration)
            : {}, // item?.departure_pickup_duration,    hour: 12, minute: 50
        //these values going to be used for api
        destination: {
            name: item?.address ?? '',
            city_id: item?.city_id
        },
        address: {
            address: item?.address,
            city: item?.city,
            country: item?.country
        },
        atAStartPoint: item?.meetup_info?.points ? item?.meetup_info?.points[0] : '',
        anotherStart: anotherStart || [],

        //if selected existed product
        pricing: Array.isArray(item?.pricings) ? item?.pricings[0] : item?.pricings,
        booking: item?.booking_ticket_details,
        whereCanPeopleSleep: item?.rooms,
        facilities: item?.facilities,
        breakfastDetails: item?.breakfast_details,
        houseRules: item?.house_rules,
        isLanguageOffered: item?.language_offered?.is_language_offered,
        languages: Array.isArray(item?.language_offered?.languages)
            ? item?.language_offered.languages
            : [],
        languageGuide: {
            guide_is_certified: item?.language_offered?.guide_is_certified === false ? false : true,
            guide_as_driver: item?.language_offered?.guide_as_driver === false ? false : true
        },
        calendar_season_type: getCalendarSeasonType(item?.calendar_season),
        covid: item?.covid_19_updates || item?.covid19_updates || null,
        maxDailyAvailability: item?.spaces_available ?? 1
    };
}

export function getCalendarSeasonType(summary) {
    try {
        const month = [
            { name: 'January' },
            { name: 'February' },
            { name: 'March' },
            { name: 'April' },
            { name: 'May' },
            { name: 'June' },
            { name: 'July' },
            { name: 'August' },
            { name: 'September' },
            { name: 'October' },
            { name: 'November' },
            { name: 'December' }
        ];

        const { best_time, high_season, best_weather } = summary;
        let tmp = [
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            },
            {
                time: false,
                highSeason: false,
                weather: false
            }
        ];

        let tmpFromToTime = [];
        let tmpFromToSeason = [];
        let tmpFromToWeather = [];

        best_time.map((res) => {
            const from = month.findIndex((item) => item.name === res.from);
            const to = month.findIndex((item) => item.name === res.to);
            if (from !== -1 && to !== -1) {
                for (let j = from; j <= month.length; j++) {
                    if (j === month.length) j = 0;
                    tmpFromToTime = [...tmpFromToTime, j];
                    if (j === to) break;
                }
            }
        });
        high_season.map((res) => {
            const from = month.findIndex((item) => item.name === res.from);
            const to = month.findIndex((item) => item.name === res.to);
            if (from !== -1 && to !== -1) {
                for (let j = from; j <= month.length; j++) {
                    if (j === month.length) j = 0;
                    tmpFromToSeason = [...tmpFromToSeason, j];
                    if (j === to) break;
                }
            }
        });
        best_weather.map((res) => {
            const from = month.findIndex((item) => item.name === res.from);
            const to = month.findIndex((item) => item.name === res.to);
            if (from !== -1 && to !== -1) {
                for (let j = from; j <= month.length; j++) {
                    if (j === month.length) j = 0;
                    tmpFromToWeather = [...tmpFromToWeather, j];
                    if (j === to) break;
                }
            }
        });

        tmp.map((res, i) => {
            res.time = tmpFromToTime.indexOf(i) !== -1;
            res.highSeason = tmpFromToSeason.indexOf(i) !== -1;
            res.weather = tmpFromToWeather.indexOf(i) !== -1;
        });

        // this.setState({ calendarSeason: [...tmp] });

        let tmpData = [
            {
                name: 'JAN',
                type: ''
            },
            {
                name: 'FEB',
                type: ''
            },
            {
                name: 'MAR',
                type: ''
            },
            {
                name: 'APR',
                type: ''
            },
            {
                name: 'MAY',
                type: ''
            },
            {
                name: 'JUN',
                type: ''
            },
            {
                name: 'JUL',
                type: ''
            },
            {
                name: 'AUG',
                type: ''
            },
            {
                name: 'SEP',
                type: ''
            },
            {
                name: 'OCT',
                type: ''
            },
            {
                name: 'NOV',
                type: ''
            },
            {
                name: 'DEC',
                type: ''
            }
        ];

        tmp.map((res, i) => {
            if (res.time && res.weather && !res.highSeason) {
                tmpData[i].type = 'excellent';
            } else if (res.time && res.weather && res.highSeason) {
                tmpData[i].type = 'good';
            } else if (res.time && !res.weather && res.highSeason) {
                tmpData[i].type = 'fair';
            } else {
                tmpData[i].type = '';
            }
        });

        return [...tmpData];
    } catch (ex) {
        return [];
    }
}

export function getItineraryFormPageTwoState() {
    return {
        openMealsAndDrinks: false,
        admission_with_price: 3,
        mealsAndDrinks: [],
        mealsAndDrinksEdit: null,

        languages: [],
        languageOffered: false,

        isOpenInclusionExclusion: false,
        inclusion: [],
        exclusion: [],
        covid: null,
        isOpenCovid: false,
        reviews: null,
        isOpenAddReviews: false,

        isCheckBox: [],
        atAStartPoint: '',
        anotherStart: [],

        departure_time: '',
        departure_pickup_duration: {},

        calendar_season_type: null,
        calendar_season: null,
        isOpenCalendarSeason: false,

        adventure: null,
        isOpenAddAdventure: false,

        sights: null,
        isOpenAddTopSights: false,

        occassion: null,
        isOpenAddOccassion: false,

        information: null,
        isOpenAddInformation: false,

        isRecommended: null
    };
}

export function getItineraryFormPageOneState() {
    return {
        title: '',
        describe: '',
        media: null,
        isOpenGallery: false,
        languageGuide: null,
        titleGallery: '',
        start: null,
        finish: null,
        destination: '',
        address: null,
        atAStartPointLocations: [],
        // page: 1,
        pricing: null,
        pricingEdit: '',
        isOpenPricing: false,
        booking: null,
        isOpenBookingTickets: false,
        houseRules: null,
        isOpenHouseRules: false,
        whereCanPeopleSleep: null,
        isOpenWhereCanPeopleSleep: false,
        facilities: null,
        isOpenAddFacilities: false,
        breakfastDetails: null,
        isOpenBreakfastDetails: false,
        isConfirmationModalOpen: false,
        maxDailyAvailability: 1,
        startTimeAmPm: '',
        endTimeAmPm: ''
    };
}

export const topSightCategoryIcons = {
    'Art and Culture': ArtsAndCulture,
    Aquaria,
    'Art galleries': ArtGallery,
    Architecture,
    Museums,
    'Amusement Parks': AmusementPark,
    Beaches,
    'Casinos': Casino,
    Markets,
    'Historic  Attractions': HistoricAttractions,
    'Attractions': HistoricAttractions,
    History,
    'Cultural': ArtsAndCulture,
    'Tourist': TouristAttractions,
    'Cultural Tourist Attractions': TouristAttractions,
    'Local Favourities': Localfavorites,

    Wildlife,
    Elephant,
    Buffalo,
    Hyena,
    Lion,
    Leopard,
    Giraffe,
    'Black Rhino': Rhino,
    'White Rhino': Rhino,
    Cheetah,
    Hippo,
    'Wild Dog': Dog,
    'Wild Beast': Beast,
    Zebra
};

export const adventureCategoryIcons = {
    'Accessible Safari': AccessibleSafari,
    'Family Safari': FamilySafari,
    'Horseback Safari': HorseSafari,
    'Canoe Safari': MapCanoe,
    'Cycling Safari': Cycling,
    'Fly-in-Safari': FlyinSafari,
    'Fly-in Safari': FlyinSafari,
    'Golf & Wildlife': Golf,
    'Classic Safari': ControllerClassic,
    'Luxury Safari': LuxurySafari,
    'Photographic Safari': Camera,
    'Gorillas & Primates': Gorilla,
    'Walking Safari': WalkingSafari,
    'Dance Classes': Dance,
    'Singing Classes': SingingClasses,
    'Jewelery Making': Jewelery,
    Textiles,
    'Paper Craft': PaperCraft,
    'Paper & Glass Craft': Pottery,
    Woodcraft,
    Dinner,
    'Local': LocalTaxi,
    Party,
    Romantic,
    'Horse Racing': HorseRiding,
    Orienteering,
    'Team Building': PeopleTeam,
    Carnivals,
    Concerts,
    'Local Performance': LocalPerformance,
    Theatre,
    Archery,
    Biking,
    Camping,
    'Hill Walk': HillWalk,
    'Gorge Walking/Scrambling': Scrambling,
    'Gorillas & Primates,': Scrambling,
    Climbing,
    'Sking/Snow Boarding': Skiing,
    'Canoeing': MapCanoe,
    Duckies,
    Kayaking,
    'Power Boating': PowerBoating,
    Sailing,
    'Stand up Paddle Boarding': Standup,
    Swimming,
    'Brewery Tour': Brewery,
    'Cookery Courses': Cookery,
    'Food Tasting': FoodTasting,
    Picnics,
    'Wine Tasting': WineTasting,
    Meditation,
    Photoshoot,
    'Spa Treatment': Spa,
    'Kids Friendly': KidsFriendly,
    "Mother's Day": MothersDay,
    'Bachelorette/Hen': Bachelorette,
    'Honeyoon': Honeymoon,
    'Family Friendly': FamilyFriendly,
    'Bachelor/Stag': Bachelor,
    "Father's Day": FathersDay,
    Christmas,
    Birthdays,
    Anniversary
};

export function mapTravellerInformation(data) {
    let res = [];
    if (data && Object.keys(data).length > 0)
        for (let key of Object.keys(data)) {
            if (key === 'date_of_birth' && data[key]) res.push('Date of Birth');
            if (key === 'full_name' && data[key]) res.push('Full Name');
            if (key === 'heights' && data[key]) res.push('Heights');
            if (key === 'passport_details' && data[key]) res.push('Passport Details');
            if (key === 'weights' && data[key]) res.push('Weights');
            if (key === 'phone_number' && data[key]) res.push('Phone Number');
        }
    return res;
}

export function stringSplitAndUppercase(str, spliter = '-') {
    if (!str) return '';
    const words = str.split(spliter);
    if (Array.isArray(words))
        words.forEach(function (i, index) {
            words[index] = i[0].toUpperCase() + i.slice(1);
        });

    return words.join(' ');
}

export function transformFirstLetterToUpperCase(str) {
    if (!str) return '';
    if (typeof str !== 'string') return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function queryBuilder(object) {
    let query = '';
    if (object && typeof object === 'object' && Object.keys(object).length > 0) {
        for (let [ind, [key, value]] of Object.entries(Object.entries(object))) {
            if (ind === '0') query += `?${key}=${value}`;
            else query += `&${key}=${value}`;
        }
    }
    return query;
}

export function getSightsFromResponse(array) {
    if (!Array.isArray(array)) return [];

    let sights = [];
    for (let value of array) {
        if (Array.isArray(value.sub_category) && value.sub_category.length > 0) {
            for (let ele of value.sub_category) {
                sights.push({
                    category: value.category,
                    sub_category: ele.name,
                    frequency: ele.frequency,
                    image_url: ele.image_url
                });
            }
        } else
            sights.push({
                category: value.category,
                frequency: value.frequency,
                image_url: value.image_url
            });
    }
    return sights;
}

export function mapSightsForResponse(array) {
    if (Array.isArray(array) && array.length > 0) {
        let mappedSights = [];

        for (let ele of array) {
            const existed = mappedSights.findIndex((item) => item.category === ele.category);
            if (existed > -1) {
                mappedSights[existed].sub_category.push({
                    name: ele.sub_category,
                    frequency: ele.frequency,
                    image_url: ele.image_url
                });
            } else if (ele.sub_category)
                mappedSights.push({
                    ...ele,
                    status: true,
                    sub_category: [
                        {
                            name: ele.sub_category,
                            frequency: ele.frequency,
                            image_url: ele.image_url
                        }
                    ]
                });
            else mappedSights.push({ ...ele, status: true });
        }

        return mappedSights;
    } else return [];
}

export function cancellationPolicyDetail(type = 'standard') {
    if (type === 'standard' || type === 'moderate')
        return `To receive a full refund, travelers may cancel up to ${
            type === 'standard' ? '24 hours' : '4 days'
        }
    before the experience start date in the local timezone. No
    refunds will be given after that time period.`;
    else if (type === 'strict')
        return `Travelers may cancel up to 7 days before the experience start
        date to receive a full refund, and between 3-6 days to receive a
        50% refund. No refunds will be given after that time period.`;
}

export function mapWithInTimePeriodArray(array) {
    const withInTimePeriods = {};
    if (Array.isArray(array))
        for (let [index, value] of array.entries()) {
            withInTimePeriods[`from_${index}`] = stringToDate(value.from);
            withInTimePeriods[`to_${index}`] = stringToDate(value.to);
        }

    return withInTimePeriods;
}

export function mapSpecificTimeObject(time_period) {
    return {
        specific_from: stringToDate(time_period.from),
        specific_to: stringToDate(time_period.to)
    };
}

export function stringToDate(string, format = 'hh:mm A') {
    return moment(string, format);
}

export function getTimeMinuteAmPmFromStrig(str, type = 'HOUR') {
    if (!str) return '';
    const array = str.split(/\s|:/);
    if (Array.isArray(array) && array.length > 0) {
        if (type === 'HOUR') return array[0] || '';
        if (type === 'MINUTE') return array[1] || '';
        if (type === 'AM/PM') return array[2] || '';
    } else return '';
}

export function disableDate(current) {
    return current && current < moment().endOf('day');
}

export function compareItineraryTime(existedPeriods, currentPeriods, periodType) {
    const { time_period, within_time_periods, duration, type } = existedPeriods;

    if (!existedPeriods) return true;
    else if (!currentPeriods) return true;

    if (type !== currentPeriods.type) return true;

    if (type === currentPeriods.type) {
        if (type === periodType.specificTime || type === periodType.specificTimeAndTimePeriod) {
            if (
                !moment(time_period.from, 'hh:mm A').isSame(
                    moment(currentPeriods?.time_period.from, 'hh:mm A')
                )
            )
                return true;

            if (
                !moment(time_period.to, 'hh:mm A').isSame(
                    moment(currentPeriods?.time_period.to, 'hh:mm A')
                )
            )
                return true;
        }

        if (type === periodType.timePeriod || type === periodType.specificTimeAndTimePeriod) {
            if (duration !== currentPeriods?.duration) return true;
            else if (within_time_periods.length !== currentPeriods?.within_time_periods.length)
                return true;
            else if (within_time_periods.length === currentPeriods?.within_time_periods.length)
                for (let [index, value] of within_time_periods.entries()) {
                    if (
                        !moment(value.from, 'hh:mm A').isSame(
                            moment(currentPeriods?.within_time_periods[index]?.from, 'hh:mm A')
                        )
                    )
                        return true;

                    if (
                        !moment(value.to, 'hh:mm A').isSame(
                            moment(currentPeriods?.within_time_periods[index]?.to, 'hh:mm A')
                        )
                    )
                        return true;
                }
        }
    }

    return false;
}

export function compareHouseRules(existedRules, rules) {
    const { smoking_allowed, pets_allowed, events_allowed, check_in, check_out } = existedRules;
    const { checkIn, checkOut, isAllowParties, isAllowPets, isAllowSmoking } = rules;

    if (smoking_allowed !== isAllowSmoking) return true;

    if (pets_allowed !== isAllowPets) return true;

    if (events_allowed !== isAllowParties) return true;

    if (check_in?.from !== checkIn?.from) return true;

    if (check_in?.until !== checkIn?.until) return true;

    if (check_out?.from !== checkOut?.from) return true;

    if (check_out?.until !== checkOut?.until) return true;

    return false;
}

export function compareBreakFast(existedBreakFast, breakFast) {
    const { types, is_breakfast_serve, is_price_include } = existedBreakFast;
    const { addedBreakfast, isServeBreakfast, isIncludedPrice } = breakFast;

    if (is_breakfast_serve !== isServeBreakfast) return true;

    if (is_price_include !== isIncludedPrice) return true;

    if (Array.isArray(types) && Array.isArray(addedBreakfast)) {
        if (types.length !== addedBreakfast.length) return true;
        else {
            for (let value of types) {
                if (!addedBreakfast.includes(value)) return true;
            }
        }
    }

    return false;
}

export function validatePackageProductsPricings(package_detail) {
    const daysArray = [];
    for (let value of package_detail?.products) {
        if (value?.calendar_days?.days) daysArray.push(value.calendar_days.days);
    }

    //common days for which product was added
    const uniqueDays = daysArray.shift().filter(function (v) {
        return daysArray.every(function (a) {
            return a.indexOf(v) !== -1;
        });
    });

    //error is not common days
    if (uniqueDays.length < 1)
        return {
            status: true,
            message: `No Days is common in products, Please update days.`
        };

    //package days for which price has been added
    let pricedDays = [];
    if (Array.isArray(package_detail.pricings)) {
        for (let value of package_detail.pricings) {
            if (value.days) pricedDays = pricedDays.concat(value.days);
        }
    }

    let notUnique = [];
    for (let value of pricedDays) {
        if (!uniqueDays.includes(value)) {
            notUnique.push(value);
        }
    }

    if (notUnique.length > 0)
        return {
            status: true,
            message: `${notUnique.join(
                ', '
            )} are not common in all of products, Please remove pricing for these or add days.`
        };

    let pricingNotAdded = [];
    for (let value of uniqueDays) {
        if (!pricedDays.includes(value)) {
            pricingNotAdded.push(value);
        }
    }

    if (pricingNotAdded.length > 0)
        return {
            status: true,
            message: `Please add pricing for common day ${pricingNotAdded.join(', ')}`
        };

    return { status: false }; //by default return not show validation
}

export function compareMealsDrinks(existedData, newData, type = 'add') {
    if (type === 'add') {
        if (existedData.length !== newData.length) return true;
    } else {
        let oldData = existedData.filter((item) => item.type === newData.type);
        if (oldData.length > 0) {
            if (oldData[0].type !== newData.type) return true;
            if (oldData[0].description !== newData.description) return true;
            if (oldData[0].photos_and_videos.length !== newData.photos_and_videos.length)
                return true;
            if (
                oldData[0].other_type &&
                newData.other_type &&
                oldData[0].other_type !== newData.other_type
            )
                return true;
        } else if (oldData.length == 0 && newData.type) {
            return true;
        }
    }
    return false;
}

export function compareCovidData(oldData, newData) {
    if (oldData.length !== newData.length) {
        return true;
    } else {
        let sortedOldData = oldData.sort();
        let sortedNewData = newData.sort();
        let flag = false;
        for (let i = 0; i < sortedNewData.length; i++) {
            if (sortedNewData[i] !== sortedOldData[i]) {
                flag = true;
                break;
            }
        }
        return flag;
    }
}

export function sortObjectsByTitle(a, b) {
    if (a.title < b.title) {
        return -1;
    }
    if (a.title > b.title) {
        return 1;
    }
    return 0;
}

export function compareInclusionExclusion(oldData, newData) {
    let oldInclusion = oldData[0].sort(sortObjectsByTitle) || [];
    let oldExclusion = oldData[1].sort(sortObjectsByTitle) || [];
    let newInclusion = newData[0].sort(sortObjectsByTitle) || [];
    let newExclusion = newData[1].sort(sortObjectsByTitle) || [];

    if (
        oldInclusion.length !== newInclusion.length ||
        oldExclusion.length !== newExclusion.length
    ) {
        return true;
    } else {
        let inclusionFlag = false;
        let exclusionFlag = false;

        for (let i = 0; i < oldInclusion.length; i++) {
            if (oldInclusion[i].title !== newInclusion[i].title) {
                inclusionFlag = true;
                break;
            }
            if (oldInclusion[i].category !== newInclusion[i].category) {
                inclusionFlag = true;
                break;
            }
        }
        for (let i = 0; i < oldExclusion.length; i++) {
            if (oldExclusion[i].title !== newExclusion[i].title) {
                exclusionFlag = true;
                break;
            }
            if (oldExclusion[i].category !== newExclusion[i].category) {
                exclusionFlag = true;
                break;
            }
        }
        return inclusionFlag || exclusionFlag;
    }
}

export function sortObjectsByName(a, b) {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }
    return 0;
}

export function compareLanguagesOffered(oldData, newData) {
    let oldSortedLanguages = oldData.selectedLanguages.sort(sortObjectsByName);
    let newSortedLanguages = newData.selectedLanguages.sort(sortObjectsByName);

    if (oldData.guide_is_certified !== newData.guide_is_certified) return true;

    if (oldData.guide_as_driver !== newData.guide_as_driver) return true;

    if (oldData.is_language_offered !== newData.is_language_offered) return true;

    if (oldSortedLanguages.length !== newSortedLanguages.length) {
        return true;
    } else {
        let flag = false;
        for (let i = 0; i < oldSortedLanguages.length; i++) {
            if (oldSortedLanguages[i].name !== newSortedLanguages[i].name) {
                flag = true;
                break;
            }
            if (oldSortedLanguages[i].audio !== newSortedLanguages[i].audio) {
                flag = true;
                break;
            }
            if (oldSortedLanguages[i].in_person !== newSortedLanguages[i].in_person) {
                flag = true;
                break;
            }
            if (oldSortedLanguages[i].written !== newSortedLanguages[i].written) {
                flag = true;
                break;
            }
        }
        return flag;
    }
}

export function sortObjectsByCategory(a, b) {
    if (a.category < b.category) {
        return -1;
    }
    if (a.category > b.category) {
        return 1;
    }
    return 0;
}

export function compareAdventureInformation(oldData, newData) {
    oldData = oldData.sort(sortObjectsByCategory);
    newData = newData.sort(sortObjectsByCategory);

    if (JSON.stringify(oldData) !== JSON.stringify(newData)) return true;

    return false;
}

export function compareOccassionInformation(oldData, newData) {
    oldData = oldData.sort(sortObjectsByCategory);
    newData = newData.sort(sortObjectsByCategory);

    if (JSON.stringify(oldData) !== JSON.stringify(newData)) return true;

    return false;
}

export function sortObjectsByFrom(a, b) {
    if (a.from < b.from) {
        return -1;
    }
    if (a.from > b.from) {
        return 1;
    }
    return 0;
}

export function compareCalendarSeason(oldData, newData) {
    oldData.best_time = oldData.best_time.sort(sortObjectsByFrom);
    oldData.best_weather = oldData.best_weather.sort(sortObjectsByFrom);
    oldData.high_season = oldData.high_season.sort(sortObjectsByFrom);

    newData.best_time = newData.best_time.sort(sortObjectsByFrom);
    newData.best_weather = newData.best_weather.sort(sortObjectsByFrom);
    newData.high_season = newData.high_season.sort(sortObjectsByFrom);

    if (
        oldData.best_time.length !== newData.best_time.length ||
        oldData.best_weather.length !== newData.best_weather.length ||
        oldData.high_season.length !== newData.high_season.length
    ) {
        return true;
    } else {
        let bestTimeFlag = false;
        let bestWeatherFlag = false;
        let highSeasonFlag = false;

        for (let i = 0; i < oldData.best_time.length; i++) {
            if (oldData.best_time[i].from !== newData.best_time[i].from) {
                bestTimeFlag = true;
                break;
            }
            if (oldData.best_time[i].to !== newData.best_time[i].to) {
                bestTimeFlag = true;
                break;
            }
            if (oldData.best_time[i].reason !== newData.best_time[i].reason) {
                bestTimeFlag = true;
                break;
            }
        }
        for (let i = 0; i < oldData.best_weather.length; i++) {
            if (oldData.best_weather[i].from !== newData.best_weather[i].from) {
                bestWeatherFlag = true;
                break;
            }
            if (oldData.best_weather[i].to !== newData.best_weather[i].to) {
                bestWeatherFlag = true;
                break;
            }
            if (oldData.best_weather[i].reason !== newData.best_weather[i].reason) {
                bestWeatherFlag = true;
                break;
            }
        }
        for (let i = 0; i < oldData.high_season.length; i++) {
            if (oldData.high_season[i].from !== newData.high_season[i].from) {
                highSeasonFlag = true;
                break;
            }
            if (oldData.high_season[i].to !== newData.high_season[i].to) {
                highSeasonFlag = true;
                break;
            }
            if (oldData.high_season[i].reason !== newData.high_season[i].reason) {
                highSeasonFlag = true;
                break;
            }
        }
        return bestTimeFlag || bestWeatherFlag || highSeasonFlag;
    }

    //return _.isEqual(newData, oldData);
    //if (JSON.stringify(oldData) !== JSON.stringify(newData)) return true;

    //return false;
}

export function compareFacilities(existedFacilities, facilities) {
    const { general, cooking_and_cleaning, entertainment, outside_and_view } = existedFacilities;
    const { selectGeneral, selectCooking, selectEntertainment, selectOutside } = facilities;

    if (
        Array.isArray(general) &&
        Array.isArray(selectGeneral) &&
        general.length === selectGeneral.length
    ) {
        for (let value of selectGeneral) {
            if (!general.includes(value)) return true;
        }
    } else {
        return true;
    }

    if (
        Array.isArray(cooking_and_cleaning) &&
        Array.isArray(selectCooking) &&
        cooking_and_cleaning.length === selectCooking.length
    ) {
        for (let value of selectCooking) {
            if (!cooking_and_cleaning.includes(value)) return true;
        }
    } else {
        return true;
    }

    if (
        Array.isArray(entertainment) &&
        Array.isArray(selectEntertainment) &&
        entertainment.length === selectEntertainment.length
    ) {
        for (let value of selectEntertainment) {
            if (!entertainment.includes(value)) return true;
        }
    } else {
        return true;
    }

    if (
        Array.isArray(outside_and_view) &&
        Array.isArray(selectOutside) &&
        outside_and_view.length === selectOutside.length
    ) {
        for (let value of selectOutside) {
            if (!outside_and_view.includes(value)) return true;
        }
    } else {
        return true;
    }

    return false;
}

export function compareBookingAndTickets(existedBookingTickets, bookingTickets) {
    const { cut_off, policy, custom_policy, ticket_setup, traveler_information } =
        existedBookingTickets;
    const {
        extraInfo,
        ticket,
        selectedBeforeTravel,
        time,
        selectedPeriod,
        cancellationPolicy,
        chips,
        customDuration,
        selectedDurationType,
        refund_type
    } = bookingTickets;

    if (cut_off) {
        if (cut_off.time !== time) return true;
        if (cut_off.time_type !== selectedPeriod) return true;
        if (cut_off.type !== selectedBeforeTravel) return true;
    } else return true;

    if (policy) {
        if (policy.type !== cancellationPolicy) return true;
        if (policy.type == 'custom' && policy.content) {
            if (policy.content.duration != customDuration) {
                return true;
            }
            if (policy.content.duration_type != selectedDurationType) {
                return true;
            }
            if (policy.content.refund_type != refund_type) {
                return true;
            }
        }
    } else return true;

    if (Array.isArray(custom_policy)) {
        if (custom_policy.length === chips.length) {
            for (let value of custom_policy) {
                if (!chips.includes(value)) return true;
            }
        } else return true;
    }

    if (ticket_setup) {
        if (ticket_setup.ticket_type !== ticket?.ticket_type) return true;

        if (ticket_setup.ticket_per_booking !== ticket?.ticket_per_booking) return true;

        if (
            ticket_setup.ticket_redemption?.entry_ticket !== ticket?.ticket_redemption?.entry_ticket
        )
            return true;

        if (
            ticket_setup.ticket_redemption?.redemption_instructions &&
            ticket?.ticket_redemption?.redemption_instructions
        ) {
            if (
                ticket_setup.ticket_redemption?.redemption_instructions.length !==
                ticket?.ticket_redemption?.redemption_instructions.length
            )
                return true;
        } else if (
            ticket_setup.ticket_redemption?.redemption_instructions ||
            ticket?.ticket_redemption?.redemption_instructions
        )
            return true;
    }

    if (traveler_information) {
        if (extraInfo && Object.keys(traveler_information).length === extraInfo.length) {
            for (let value of extraInfo) {
                let transform = value.replaceAll(' ', '_').toLowerCase();
                if (!Object.prototype.hasOwnProperty.call(traveler_information, transform))
                    return true;
            }
        } else return true;
    }

    return false;
}

export function compareRooms(existedRooms, rooms) {
    const { no_of_bathrooms, pp_sharing, pp_single } = existedRooms;
    const {
        countBathroom,
        accomodationData,
        doubleMoreOption,
        accomodationTwin,
        singleRoom,
        doubleRoom,
        double,
        perPerson
    } = rooms;

    const ppSingle = {
        single: {
            bed: singleRoom[0].count
        },
        double: {
            double_bed: doubleRoom[0].count,
            large_bed: doubleRoom[1].count,
            xl_double_bed: doubleRoom[2].count,
            sofa_bed: doubleMoreOption[0].countSingle,
            futon_mat: doubleMoreOption[1].countSingle
        }
    };

    const ppSharing = {
        double: {
            double_bed: accomodationData[0].count,
            large_bed: accomodationData[1].count,
            xl_double_bed: accomodationData[2].count,
            sofa_bed: doubleMoreOption[0].countSharing,
            futon_mat: doubleMoreOption[1].countSharing
        },
        twin: {
            single_bed: accomodationTwin[0].count,
            bunk_bed: accomodationTwin[1].count
        }
    };

    if (no_of_bathrooms !== countBathroom) return true;

    if (pp_single && !double) return true;
    else if (double && !pp_single) return true;
    else if (pp_single && ppSingle) {
        if (ppSingle?.single?.bed !== pp_single?.single?.bed) return true;

        if (ppSingle?.double?.double_bed !== pp_single?.double.double_bed) return true;

        if (ppSingle?.double?.large_bed !== pp_single?.double.large_bed) return true;

        if (ppSingle?.double?.xl_double_bed !== pp_single?.double.xl_double_bed) return true;

        if (ppSingle?.double?.sofa_bed !== pp_single?.double.sofa_bed) return true;

        if (ppSingle?.double?.futon_mat !== pp_single?.double.futon_mat) return true;
    }

    if (pp_sharing && !perPerson) return true;
    else if (perPerson && !pp_sharing) return true;
    else if (pp_sharing && ppSharing) {
        if (ppSharing?.double?.double_bed !== pp_sharing?.double?.double_bed) return true;

        if (ppSharing?.double?.large_bed !== pp_sharing?.double?.large_bed) return true;

        if (ppSharing?.double?.xl_double_bed !== pp_sharing?.double?.xl_double_bed) return true;

        if (ppSharing?.double?.sofa_bed !== pp_sharing?.double?.sofa_bed) return true;

        if (ppSharing?.double?.futon_mat !== pp_sharing?.double?.futon_mat) return true;

        if (ppSharing?.twin?.single_bed !== pp_sharing?.twin?.single_bed) return true;

        if (ppSharing?.twin?.bunk_bed !== pp_sharing?.twin?.bunk_bed) return true;
    }

    return false;
}

export function compareInformationTraveller(existedInfo, info) {
    const { accessibility, difficulty_level, health_restrictions, phone_number } = existedInfo;
    const { asses, difficulty, healthRestricitons, phoneNumber } = info;

    if (phone_number !== phoneNumber) return true;

    if (difficulty_level?.type !== difficulty) return true;

    if (healthRestricitons) {
        let rawHealthRestricitons = [];
        healthRestricitons.map((res) => {
            if (res.value) rawHealthRestricitons.push(res.name);
        });

        if (Array.isArray(health_restrictions))
            if (rawHealthRestricitons.length === health_restrictions.length) {
                for (let value of rawHealthRestricitons) {
                    if (!health_restrictions.includes(value)) return true;
                }
            } else return true;
        else return true;
    }

    if (asses) {
        const rawAsses = {
            wheelchair: asses[0].value,
            stroller: asses[1].value,
            service_animals: asses[2].value,
            public_trans: asses[3].value,
            infant_lapping: asses[4].value,
            infant_seats: asses[5].value
        };

        for (let obj of Object.keys(rawAsses)) {
            if (rawAsses[obj] !== accessibility[obj]) return true;
        }
    }
    return false;
}

export function compareTopSights(existedSights, sights) {
    if (Array.isArray(existedSights) && Array.isArray(sights)) {
        if (existedSights.length === sights.length) {
            for (let value of existedSights) {
                let existedCategory = sights.find(
                    (obj) =>
                        obj.category === value.category && obj.sub_category === value.sub_category
                );
                if (!existedCategory) return true;
                else if (existedCategory && existedCategory.frequency !== value.frequency)
                    return true;
            }
        } else return true;
    } else return true;
    return false;
}

export function comparePricing(existedPrice, price) {
    const {
        days,
        max_travellers,
        min_travellers,
        age_groups,
        is_accomodation_included,
        per_person_sharing,
        per_person_single
    } = existedPrice;
    const {
        selectDays,
        minTravellers,
        maxTravellers,
        ageGroups,
        isIncludedAccomodation,
        perPersonSingle,
        perPersonSharing
    } = price;

    if (is_accomodation_included !== isIncludedAccomodation) return true;

    if (max_travellers !== maxTravellers) return true;

    if (min_travellers !== minTravellers) return true;

    if (Array.isArray(days) && Array.isArray(selectDays) && days.length === selectDays.length) {
        for (let value of days) {
            if (!selectDays.includes(value)) return true;
        }
    } else return true;

    if (Array.isArray(age_groups) && age_groups.length > 0) {
        let rawAgeGroup = Object.keys(ageGroups)
            .map((key) => ({
                type: key,
                min_age: ageGroups[key].minAge,
                max_age: ageGroups[key].maxAge,
                amount: ageGroups[key].amount,
                checked: ageGroups[key].checked,
                group_price: ageGroups[key].groupPrices
            }))
            .filter((item) => item.checked);

        if (
            Array.isArray(age_groups) &&
            Array.isArray(rawAgeGroup) &&
            age_groups.length === rawAgeGroup.length
        ) {
            for (let value of age_groups) {
                let obj = rawAgeGroup.find((i) => i.type === value.type);
                if (obj) {
                    if (obj.amount !== value.amount) return true;
                    if (obj.max_age !== value.max_age) return true;
                    if (obj.min_age !== value.min_age) return true;
                } else return true;
            }
        } else return true;
    }

    if (per_person_sharing) {
        if (per_person_sharing?.double_room?.amount !== perPersonSharing?.double_room?.amount)
            return true;
        if (per_person_sharing?.twin_room?.amount !== perPersonSharing?.twin_room?.amount)
            return true;
    }

    if (per_person_single) {
        if (per_person_single?.double_room?.amount !== perPersonSingle?.double_room?.amount)
            return true;
        if (per_person_single?.twin_room?.amount !== perPersonSingle?.twin_room?.amount)
            return true;
    }

    return false;
}

export function mapValidationErrors(errors) {
    let raw = {};
    if (Array.isArray(errors)) {
        for (let val of errors) {
            let name = val?.name[0];
            // raw.hasOwnProperty(name)
            if (!Object.prototype.hasOwnProperty.call(raw, name)) raw[name] = val?.errors[0];
        }
    }
    return raw;
}

function secondsToHms(d) {
    d = Number(d);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    // let s = Math.floor(d % 3600 % 60);

    let hour = h > 0 ? h : 0;
    let minute = m > 0 ? m : 0;
    // let second = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return { hour, minute };
}

export function validateWithInTimePeriod(within_time_periods, duration, time_period) {
    for (let [index, value] of within_time_periods.entries()) {
        // if (moment(value.from, 'hh:mm A').isSameOrAfter(moment(value.to, 'hh:mm A'))) {
        //     addOnProductSecondForm.setFields([
        //         {
        //             name: `booking_end_hours_${index}`,
        //             errors: ['End time should be greater than start!']
        //         }
        //     ]);
        //     return true;
        // } else {
        for (let [ind, val] of within_time_periods.entries()) {
            let finish = moment(val.from, 'hh:mm A').isSameOrAfter(moment(val.to, 'hh:mm A'))
                ? moment(val.to, 'hh:mm A').add(1, 'day')
                : moment(val.to, 'hh:mm A');

            if (
                index !== ind &&
                (moment(value.from, 'hh:mm A').isBetween(moment(val.from, 'hh:mm A'), finish) ||
                    moment(value.to, 'hh:mm A').isBetween(moment(val.from, 'hh:mm A'), finish))
            )
                return {
                    status: true,
                    error: [
                        {
                            name: `from_${index}`,
                            errors: ['Periods timing should not intersect each others!']
                        }
                    ]
                };
        }
        // }
    }

    let rawTimePeriod = [...within_time_periods];
    if (time_period) rawTimePeriod.push(time_period);

    rawTimePeriod = sortTimePeriods(rawTimePeriod);

    for (let value of rawTimePeriod) {
        let finish = moment(value.from, 'hh:mm A').isSameOrAfter(moment(value.to, 'hh:mm A'))
            ? moment(value.to, 'hh:mm A').add(1, 'day')
            : moment(value.to, 'hh:mm A');
        if (
            duration > finish.diff(moment(value.from, 'hh:mm A'), 'minutes') &&
            !(duration === finish.diff(moment(value.from, 'hh:mm A'), 'minutes'))
        )
            return {
                status: true,
                error: [
                    {
                        name: `activity_duration_hour`,
                        errors: ['Activity duration should be less every time period!']
                    }
                ]
            };
    }

    return { status: false };
}

export function validateSpecificPeriod(time_period, within_time_periods) {
    // if (moment(time_period.from, 'hh:mm A').isSameOrAfter(moment(time_period.to, 'hh:mm A'))) {
    //     addOnProductSecondForm.setFields([
    //         {
    //             name: 'activity_end_hours',
    //             errors: ['Finish time should be greater than start!']
    //         }
    //     ]);
    //     return true;
    // } else {
    if (Array.isArray(within_time_periods)) {
        let existedInside = false;
        let withInTimePeriods = sortTimePeriods(within_time_periods);

        for (let val of withInTimePeriods) {
            let finish = moment(val.from, 'hh:mm A').isSameOrAfter(moment(val.to, 'hh:mm A'))
                ? moment(val.to, 'hh:mm A').add(1, 'day')
                : moment(val.to, 'hh:mm A');
            finish.add(1, 'minute');

            if (
                moment(time_period.from, 'hh:mm A').isBetween(
                    moment(val.from, 'hh:mm A').subtract(1, 'minute'),
                    finish
                ) &&
                moment(time_period.to, 'hh:mm A').isBetween(
                    moment(val.from, 'hh:mm A').subtract(1, 'minute'),
                    finish
                )
            ) {
                existedInside = true;
                break;
            }
        }
        if (!existedInside) {
            return {
                status: true,
                error: [
                    {
                        name: `specific_from`,
                        errors: ['Specific time period should be between atleast one time period!']
                    }
                ]
            };
        }
    }
    // }
    return { status: false };
}

function sortTimePeriods(array) {
    let rawArray = array.sort(function (a, b) {
        if (moment(a.from, 'hh:mm A').isSameOrAfter(moment(b.from, 'hh:mm A'))) {
            return 1;
        } else return -1;
    });

    let rawPeriod = [];
    for (let value of rawArray) {
        let existed = rawPeriod.findIndex((element) => element.to === value.from);
        if (existed > -1) {
            rawPeriod[existed] = { ...rawPeriod[existed], to: value.to };
        } else rawPeriod.push(value);
    }
    return rawPeriod;
}

export function getSendToCustomerState(props) {
    return {
        showLink: false,
        showWhatsapp: true,
        showEmail: false,
        linkTitle: 'Whatsapp link is copied',
        sendBtn: 'Send Using Whatsapp',
        toggleProductInfoDrawer: false,
        toggleNoTokenDrawer: false,
        togglePlanDrawer: false,
        toggleHelpDrawer: false,
        monthlyPlanCharges: 0,
        numberOfCustomers: 0,
        totalTokenAmount: props.totalTokenAmount,

        /* messageTemplate:
            'Hello,\nWe have taken some time to help plan your trip to make it a memorable one. Find below a link to things you can do on your trip.\nAccess your calendar.', */
        messageTemplate:
            'We look forward to having you and have also taken some time to help plan your trip to make it a memorable one using Travel Jinni. \nThis travel plan details things you can do on your trip, and it is included for FREE as part of your purchase.',
        orgMessageTemplate:
            'We have taken some time to help plan your trip to make it a memorable one. Find below a link to things you can do on your trip.',
        email: '',
        totalRevenu: 0,
        planSelected: '',
        paynowbutton: 'Close',
        upgradePopupContent: ``,
        addTopUpPopupContent: ``,
        selectedPlanPayment: 'card',
        prefferedPaymentMethod: 'saved',
        addAnotherCard: false,
        sendAnyway: false,
        calendar_id: '3',
        firstname: '',
        calendarLink: '',
        mainProductName: '',
        mainProductDate: '',
        vendorPlan: {
            planName: 'Free',
            available_token: 0,
            price_per_customer_token: 20,
            token_topup_price: 5,
            plan: {
                name: 'Free',
                no_of_free_calendar: 1,
                no_of_token: 10
            }
        },
        planList1: [],
        selectedPlan: {},
        showAddToPreference: false,
        errors: [],
        paidOrNotPaidPrompt: true,
        availabilityData: [],
        linkPreviewMessage: null
    };
}

export function getPlanList(vendorSubscription) {
    const noOfFreeCalendar =
        vendorSubscription.no_of_free_calendar === 0
            ? 'Unlimited'
            : vendorSubscription.no_of_free_calendar;
    return [
        {
            id: 1,
            text: `${
                vendorSubscription.plan_price && vendorSubscription.no_of_free_calendar === 0
                    ? 'Contact Sales'
                    : `$${vendorSubscription.plan_price} Monthly fee`
            }`
        },
        {
            id: 2,
            text: `${noOfFreeCalendar} Live Calendar${
                vendorSubscription.no_of_free_calendar === 1 ? '' : 's'
            }`
        },
        {
            id: 3,
            text: `${
                vendorSubscription.plan?.no_of_token ? vendorSubscription.plan?.no_of_token : '10'
            } Customer Invites Monthly`
        }
    ];
}

export const setErrorsOnAntFormFinishFailed = ({ errorFields }, setState) => {
    let errorsList = mapValidationErrors(errorFields);
    if (errorsList && Object.keys(errorsList).length > 0) {
        setState(errorsList);
        setTimeout(() => setState(null), 4000);
    }
};

export function validateAndCreateLink(state, props, productType) {
    const {
        productEndDate,
        productStartDate,
        productDate,
        productStartTime,
        productEndTime,
        packageStartDate,
        packageEndDate,
        amountPaid
    } = state;
    const { startDate, endDate, calendarId, allTravellers = [] } = props;
    let errors = [];
    let link = `calendarId=${calendarId}`;

    if (!state?.messageTemplate) errors.push('messageTemplate');

    if (!state.viewPaidTwo) {
        if (!state?.firstname) errors.push('firstname');
        else link += `&customerName=${state.firstname}`;

        // if (!Array.isArray(state?.packageProducts) || state?.packageProducts.length < 1) {
        if (!startDate) errors.push('startDate');
        else link += `&startDate=${startDate}`;

        if (!startDate) errors.push('endDate');
        else link += `&endDate=${endDate}`;
        // }

        if (state?.showEmail)
            if (!state?.email) errors.push('email');
            else link += `&email=${state?.email}`;

        if (state.viewNotPaidTwo || state.viewPaidOne) {
            if (productType === 'all_day') {
                if (!productStartDate) errors.push('productStartDate');
                else link += `&productStartDate=${productStartDate}`;

                if (!productEndDate) errors.push('productEndDate');
                else link += `&productEndDate=${productEndDate}`;

                //if the product start and end between trip start end
                if (productStartDate && productEndDate && startDate && endDate) {
                    if (
                        !(
                            moment(productStartDate).isSameOrAfter(startDate, 'day') &&
                            moment(productEndDate).isSameOrBefore(endDate, 'day')
                        )
                    )
                        errors.push('psedNotInTRange');
                }
            } else if (productType === 'within_day') {
                if (!productDate) errors.push('productDate');
                else link += `&productDate=${productDate}`;

                if (!productStartTime) errors.push('productStartTime');
                else link += `&productStartTime=${productStartTime}`;

                if (!productEndTime) errors.push('productEndTime');
                else link += `&productEndTime=${productEndTime}`;

                //if the product date between trip start end
                if (productDate && startDate && endDate) {
                    if (
                        !(
                            moment(productDate).isSameOrAfter(startDate, 'day') &&
                            moment(productDate).isSameOrBefore(endDate, 'day')
                        )
                    )
                        errors.push('pdNotInTRange');
                }
            } else if (Array.isArray(state?.packageProducts) && state?.packageProducts.length > 0) {
                if (!packageStartDate) errors.push('packageStartDate');
                else link += `&packageStartDate=${packageStartDate}`;

                if (!packageEndDate) errors.push('packageEndDate');
                else link += `&packageEndDate=${packageEndDate}`;

                //if the package start and end between trip start end
                if (packageStartDate && packageEndDate && startDate && endDate) {
                    if (
                        !(
                            moment(packageStartDate).isSameOrAfter(startDate, 'day') &&
                            moment(packageEndDate).isSameOrBefore(endDate, 'day')
                        )
                    )
                        errors.push('pasedNotInTRange');
                }

                let arr = [];
                for (let [i, val] of state.packageProducts.entries()) {
                    if (val.day_type === 'all_day') {
                        if (!val?.productStartDate) errors.push(`productStartDate${i}`);
                        if (!val?.productEndDate) errors.push(`productEndDate${i}`);

                        //if the product start and end between trip start end
                        if (
                            val?.productStartDate &&
                            val?.productEndDate &&
                            packageStartDate &&
                            packageEndDate
                        ) {
                            if (
                                !(
                                    moment(val?.productStartDate).isSameOrAfter(
                                        packageStartDate,
                                        'day'
                                    ) &&
                                    moment(val?.productEndDate).isSameOrBefore(
                                        packageEndDate,
                                        'day'
                                    )
                                )
                            )
                                errors.push(`psedNotInTRange${i}`);
                        }

                        if (val?.productStartDate && val?.productEndDate)
                            arr.push({
                                productStartDate: val.productStartDate,
                                productEndDate: val.productEndDate
                            });
                    } else if (val.day_type === 'within_day') {
                        if (!val?.productDate) errors.push(`productDate${i}`);
                        if (!val?.productStartTime) errors.push(`productStartTime${i}`);
                        if (!val?.productEndTime) errors.push(`productEndTime${i}`);

                        //if the product date between trip start end
                        if (val?.productDate && packageStartDate && packageEndDate) {
                            if (
                                !(
                                    moment(val?.productDate).isSameOrAfter(
                                        packageStartDate,
                                        'day'
                                    ) &&
                                    moment(val?.productDate).isSameOrBefore(packageEndDate, 'day')
                                )
                            )
                                errors.push(`pdNotInTRange${i}`);
                        }

                        if (val?.productDate && val?.productStartTime && val?.productEndTime)
                            arr.push({
                                productDate: val.productDate,
                                productStartTime: val.productStartTime,
                                productEndTime: val.productEndTime,
                                title: val?.title
                            });
                    }
                }
                if (arr.length > 0) link += `&packageProducts=${JSON.stringify(arr)}`;
            }

            //Vendor needs to input the detail what customer paid to him
            if (state.viewPaidOne) {
                if (!amountPaid) errors.push('amountPaid');
                else link += `&amount=${amountPaid}`;
                const guests = allTravellers.filter((val) => val?.count);
                if (Array.isArray(guests) && guests.length) {
                    for (let guest of guests) {
                        link += `&${guest?.name ?? ''}=${guest?.count ?? 0}`;
                    }
                } else errors.push('guests');
            }
        }
    }

    //paid or not paid
    if (state?.viewPaidOne || state.viewPaidTwo) link += `&linkType=paid`;
    else link += `&linkType=not_paid`;

    if (productType === 'all_day') link += `&productType=all_day`;
    else if (productType === 'within_day') link += `&productType=within_day`;
    else link += `&productType=package`;

    if (state.showLink) link += `&embed=true`;
    else link += `&embed=false`;

    return { errors, link };
}

export function validatePacakgeProductFieldsForLink(state, props) {
    const { packageStartDate, packageEndDate } = state;
    const { startDate, endDate } = props;

    let errors = [];
    let link = ``;

    if (Array.isArray(state?.packageProducts) && state?.packageProducts.length > 0) {
        if (!state?.packageStartDate) errors.push('packageStartDate');
        else link += `&packageStartDate=${state?.packageStartDate}`;

        if (!state?.packageEndDate) errors.push('packageEndDate');
        else link += `&packageEndDate=${state?.packageEndDate}`;

        //if the package start and end between trip start end
        if (packageStartDate && packageEndDate && startDate && startDate) {
            if (
                !(
                    moment(packageStartDate).isSameOrAfter(startDate, 'day') &&
                    moment(packageEndDate).isSameOrBefore(endDate, 'day')
                )
            )
                errors.push('pasedNotInTRange');
        }

        let arr = [];
        for (let [i, val] of state.packageProducts.entries()) {
            if (val.day_type === 'all_day') {
                if (!val?.productStartDate) errors.push(`productStartDate${i}`);
                if (!val?.productEndDate) errors.push(`productEndDate${i}`);

                //if the product start and end between trip start end
                if (
                    val?.productStartDate &&
                    val?.productEndDate &&
                    packageStartDate &&
                    packageEndDate
                ) {
                    if (
                        !(
                            moment(val?.productStartDate).isSameOrAfter(packageStartDate, 'day') &&
                            moment(val?.productEndDate).isSameOrBefore(packageEndDate, 'day')
                        )
                    )
                        errors.push(`psedNotInTRange${i}`);
                }

                if (val?.productStartDate && val?.productEndDate)
                    arr.push({
                        productStartDate: val.productStartDate,
                        productEndDate: val.productEndDate
                    });
            } else if (val.day_type === 'within_day') {
                if (!val?.productDate) errors.push(`productDate${i}`);
                if (!val?.productStartTime) errors.push(`productStartTime${i}`);
                if (!val?.productEndTime) errors.push(`productEndTime${i}`);

                //if the product date between trip start end
                if (val?.productDate && packageStartDate && packageEndDate) {
                    if (
                        !(
                            moment(val?.productDate).isSameOrAfter(packageStartDate, 'day') &&
                            moment(val?.productDate).isSameOrBefore(packageEndDate, 'day')
                        )
                    )
                        errors.push(`pdNotInTRange${i}`);
                }

                if (val?.productDate && val?.productStartTime && val?.productEndTime)
                    arr.push({
                        productDate: val.productDate,
                        productStartTime: val.productStartTime,
                        productEndTime: val.productEndTime
                    });
            }
        }
        if (arr.length > 0) link += `&packageProducts=${JSON.stringify(arr)}`;
    }

    return { errors, link };
}

export function compareCalendarDays(existedDays, days, existedSuggDay, suggDay, day_type) {
    //comparing suggested day if product type withinday type
    if (day_type === 'within_day' && existedSuggDay !== Number(suggDay)) {
        return true;
    }

    //comparing day
    if (Array.isArray(existedDays) && Array.isArray(days)) {
        if (existedDays.length === days.length) {
            for (let value of existedDays) {
                let included = days.includes(value);
                if (!included) return true;
            }
        } else return true;
    } else return true;

    return false;
}

export function parseAndGetKeyValue(data, key) {
    try {
        if (data && key) {
            let parse = JSON.parse(data);
            if (parse) return parse[key];
        }
        return '';
    } catch (ex) {
        return '';
    }
}

export function toDecimal(value, places = 2) {
    return value.toLocaleString(undefined, {
        minimumFractionDigits: places,
        maximumFractionDigits: places + 2
    });
}

export function getDynamicError(errors, fields) {
    let message = 'Required field';
    if (Array.isArray(errors) && Array.isArray(fields)) {
        for (let val of fields) {
            if (errors.includes(val.f))
                return <span className="error-input">{val.m ? val.m : message}</span>;
        }
    }
    return null;
}

export function getDatesListBetweenDates(startDate, endDate) {
    let now = moment(startDate, 'YYYY-MM-DD'),
        dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('YYYY-MM-DD'));
        now.add(1, 'days');
    }
    return dates;
}

export function getAvailableDateFromAvailabilityData(data) {
    let dates = [];
    if (Array.isArray(data))
        for (let val of data) {
            if (val?.availability === 'available' && val?.cutOffPassed === false)
                dates.push(val?.date);
        }
    return dates;
}

export function isSelectedRangeValid(startDate, endDate, availabilityData) {
    const selectedDates = getDatesListBetweenDates(startDate, endDate);
    const availableDates = getAvailableDateFromAvailabilityData(availabilityData);

    if (Array.isArray(selectedDates) && Array.isArray(availableDates))
        for (let val of selectedDates) {
            if (!availableDates.includes(val)) return false;
        }

    return true;
}

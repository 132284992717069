import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { TagIcon } from 'components/atoms';

const EventPricings = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<TagIcon />}
            panelTitle="Amount you will get (PP)"
            isEditable={true}
            onEdit={onEdit}>
            {!(product?.pricings?.length > 0) ? (
                <></>
            ) : (
                product?.pricings.map((item, index) => (
                    <div key={index}>
                        <h6 className="fa-18 fw-500 text-uppercase mt-3">
                            {item.days.map(
                                (day, i) => `${day}${i < item.days.length - 1 ? ',' : ''} `
                            )}
                        </h6>
                        {item?.age_groups?.length > 0 &&
                            item?.age_groups.map((ageGroup, ageIndex) => (
                                <Row
                                    key={ageIndex}
                                    className="border-bottom border-primary-shaded py-2"
                                    noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                {ageGroup.type} ({ageGroup?.min_age} -{' '}
                                                {ageGroup?.max_age})
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">USD {ageGroup?.amount}</span>
                                    </Col>
                                </Row>
                            ))}

                        <Row className="border-bottom border-primary-shaded py-2" noGutters>
                            <Col xs={6} md={5}>
                                <div className="d-flex flex-nowrap">
                                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                        Min Travellers
                                    </span>
                                    <span className="fs-18 lh-1-7 mr-4">:</span>
                                </div>
                            </Col>
                            <Col xs={6} md={7}>
                                <span className="fs-18 lh-1-7">{item.min_travellers}</span>
                            </Col>
                        </Row>
                        <Row className="border-bottom border-primary-shaded py-2" noGutters>
                            <Col xs={6} md={5}>
                                <div className="d-flex flex-nowrap">
                                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                        Max Travellers
                                    </span>
                                    <span className="fs-18 lh-1-7 mr-4">:</span>
                                </div>
                            </Col>
                            <Col xs={6} md={7}>
                                <span className="fs-18 lh-1-7">{item.max_travellers}</span>
                            </Col>
                        </Row>

                        {item.min_accomodation ? (
                            <>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                Min Accomodations
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">
                                            {item.min_accomodation}
                                        </span>
                                    </Col>
                                </Row>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                Max Accomodations
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">
                                            {item.max_accomodation}
                                        </span>
                                    </Col>
                                </Row>
                            </>
                        ) : (
                            <></>
                        )}

                        {/* Product Peak Season Supplements */}
                        {!item.product_peak_season_supplements ? (
                            <></>
                        ) : (
                            <>
                                <p className="fs-20 lh-1-7 flex-grow-1 mr-2  text-capitalize mt-2 mb-0 pb-0">
                                    Product Peak Season Supplements
                                </p>

                                {item.product_peak_season_supplements.map((season, seasonIndex) => (
                                    <Row
                                        key={seasonIndex}
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2 ">
                                                Increase prices by {season.percentage}% from{' '}
                                                {season.start.split('T')[0]} to{' '}
                                                {season.end.split('T')[0]} for {season.season}
                                            </span>
                                        </div>
                                    </Row>
                                ))}
                            </>
                        )}

                        {product.product_category_id === 1 ? (
                            <></>
                        ) : (
                            <>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                Accomodation Included
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">
                                            {item.is_accomodation_included ? 'Yes' : 'No'}
                                        </span>
                                    </Col>
                                </Row>
                                {!item.product_accomodation_single_supplement ? (
                                    <></>
                                ) : (
                                    <>
                                        <Row
                                            className="border-bottom border-primary-shaded py-2"
                                            noGutters>
                                            <Col xs={6} md={5}>
                                                <div className="d-flex flex-nowrap">
                                                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                        Double room supplement
                                                    </span>
                                                    <span className="fs-18 lh-1-7 mr-4">:</span>
                                                </div>
                                            </Col>
                                            <Col xs={6} md={7}>
                                                <span className="fs-18 lh-1-7">
                                                    {
                                                        item.product_accomodation_single_supplement
                                                            .double
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row
                                            className="border-bottom border-primary-shaded py-2"
                                            noGutters>
                                            <Col xs={6} md={5}>
                                                <div className="d-flex flex-nowrap">
                                                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                        twin room supplement
                                                    </span>
                                                    <span className="fs-18 lh-1-7 mr-4">:</span>
                                                </div>
                                            </Col>
                                            <Col xs={6} md={7}>
                                                <span className="fs-18 lh-1-7">
                                                    {
                                                        item.product_accomodation_single_supplement
                                                            .twin
                                                    }
                                                </span>
                                            </Col>
                                        </Row>
                                    </>
                                )}
                            </>
                        )}

                        {!item.per_person_sharing ? (
                            <></>
                        ) : (
                            <>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                PP Sharing (Double room)
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">
                                            USD {item.per_person_sharing.double_room.amount}
                                        </span>
                                    </Col>
                                </Row>
                                {!item?.per_person_sharing?.double_room?.single_supplement ? (
                                    <></>
                                ) : (
                                    <Row
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <Col xs={6} md={5}>
                                            <div className="d-flex flex-nowrap">
                                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                    PP Sharing (Double room) supplement
                                                </span>
                                                <span className="fs-18 lh-1-7 mr-4">:</span>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <span className="fs-18 lh-1-7">
                                                USD{' '}
                                                {
                                                    item.per_person_sharing.double_room
                                                        .single_supplement
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                                {!item.per_person_sharing?.twin_room?.amount ? (
                                    <></>
                                ) : (
                                    <Row
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <Col xs={6} md={5}>
                                            <div className="d-flex flex-nowrap">
                                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                    PP Sharing (Twin room)
                                                </span>
                                                <span className="fs-18 lh-1-7 mr-4">:</span>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <span className="fs-18 lh-1-7">
                                                USD {item.per_person_sharing.twin_room.amount}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                                {item?.per_person_sharing?.twin_room?.single_supplement ? (
                                    <></>
                                ) : (
                                    <Row
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <Col xs={6} md={5}>
                                            <div className="d-flex flex-nowrap">
                                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                    PP Sharing (Twin room) supplement
                                                </span>
                                                <span className="fs-18 lh-1-7 mr-4">:</span>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={7}>
                                            <span className="fs-18 lh-1-7">
                                                USD{' '}
                                                {
                                                    item.per_person_sharing.twin_room
                                                        .single_supplement
                                                }
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}

                        {!item.per_person_single ? (
                            <></>
                        ) : (
                            <>
                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                PP Single (Double room)
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">
                                            USD {item.per_person_single.double_room.amount}
                                        </span>
                                    </Col>
                                </Row>

                                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                                    <Col xs={6} md={5}>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2  text-capitalize">
                                                PP Single (twin room)
                                            </span>
                                            <span className="fs-18 lh-1-7 mr-4">:</span>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={7}>
                                        <span className="fs-18 lh-1-7">
                                            USD {item.per_person_single.twin_room.amount}
                                        </span>
                                    </Col>
                                </Row>
                            </>
                        )}
                        {!item.accomodation_peak_season_supplements ? (
                            <></>
                        ) : (
                            <>
                                <p className="fs-20 lh-1-7 flex-grow-1 mr-2  text-capitalize mt-2 mb-0 pb-0">
                                    Accomodation Peak Season Supplements
                                </p>

                                {item.accomodation_peak_season_supplements.map((season, key) => (
                                    <Row
                                        key={key}
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2 ">
                                                Increase prices by {season.percentage}% from{' '}
                                                {season.start.split('T')[0]} to{' '}
                                                {season.end.split('T')[0]} for {season.season}
                                            </span>
                                        </div>
                                    </Row>
                                ))}
                            </>
                        )}

                        {!item.accomodation_group_prices ? (
                            <></>
                        ) : (
                            <>
                                {' '}
                                <p className="fs-20 lh-1-7 flex-grow-1 mr-2  text-capitalize mt-2 mb-0 pb-0">
                                    Accomodation Group Booking
                                </p>
                                {item.accomodation_group_prices.map((group, key) => (
                                    <Row
                                        key={key}
                                        className="border-bottom border-primary-shaded py-2"
                                        noGutters>
                                        <div className="d-flex flex-nowrap">
                                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2 ">
                                                Decrease prices by {group.percentage}% for a booking
                                                of {group.min} - {group.max} persons
                                            </span>
                                        </div>
                                    </Row>
                                ))}
                            </>
                        )}
                    </div>
                ))
            )}
        </DetailedPanel>
    );
};

EventPricings.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventPricings;

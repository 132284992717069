import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CircularProgressbar } from 'react-circular-progressbar';
import caret_icon from '../../../../../../assets/images/caret.svg';
import './CustomerService.scss';
const { Panel } = Collapse;

// component to help display circular progress
export const ProgressComponent = ({ count, width, strokeWidth }) => {
    return (
        <CircularProgressbar
            value={count}
            text={`${count}%`}
            strokeWidth={strokeWidth || 2}
            styles={{
                path: {
                    stroke: count === 100 ? '#34a939b3' : '#E2C022'
                },
                root: {
                    width: width || 160
                },
                text: {
                    fill: '#000',
                    fontSize: '16px'
                }
            }}
        />
    );
};
ProgressComponent.propTypes = {
    count: PropTypes.number,
    width: PropTypes.any,
    strokeWidth: PropTypes.any
};

export default function CustomerService() {
    const [pickedCountry, setpickedCountry] = useState(null);
    const [pickedMetric, setPickedMetric] = useState(null);
    const [viewMetrics, setviewMetrics] = useState(false);
    const [setactiveCollapseTab] = useState(null);
    const componentData = [
        {
            country: 'South Africa',
            rating: 50,
            metrics: [
                {
                    name: 'Things to Check',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                },
                {
                    name: 'Things to Pack',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                },
                {
                    name: 'Things to Learn',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                }
            ]
        },
        {
            country: 'Kenya',
            rating: 50,
            metrics: [
                {
                    name: 'Things to Check',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                },
                {
                    name: 'Things to Pack',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                },
                {
                    name: 'Things to Learn',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                }
            ]
        },
        {
            country: 'Zimbabwe',
            rating: 100,
            metrics: [
                {
                    name: 'Things to Check',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                },
                {
                    name: 'Things to Pack',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                },
                {
                    name: 'Things to Learn',
                    score: 50,
                    steps: [
                        {
                            heading:
                                'Important Documentation: Passports,	Visas	&	Inoculation	Certificates',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Getting Around: Driving & Flights',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading: 'Health & Wellness Information',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        },
                        {
                            heading:
                                'Money Matters: Travel Insurance, Traveller’s  Cheques, Safety, Currency, Banks, Credit Cards & Tipping',
                            innerHeading: 'Please confirm your statement below to your customers',
                            subHeading: `A valid, machine-readable passport issued by the country
              of which you are a citizen is mandatory for all international
              travel. This includes travellers under the age of 18.`,
                            value: true
                        }
                    ]
                }
            ]
        }
    ];

    const switchCollapse = (e) => {
        setactiveCollapseTab(e[e.length - 1]);
    };

    return (
        <div className="customer-service-tab">
            <div className="crumbs">
                <div
                    className="fs18"
                    style={{
                        borderBottom: '4px solid #a2792c',
                        fontWeight: '600',
                        width: 'max-content',
                        paddingBottom: 6
                    }}>
                    Operating Countries:{' '}
                    <span style={{ fontWeight: '400' }}>
                        {viewMetrics && componentData[pickedCountry].country}
                    </span>
                </div>
                <Button type="primary" className="rounded-btn">
                    Add Item
                </Button>
            </div>

            <div className="ratings">
                {!pickedMetric && (
                    <Row gutter={30}>
                        {!viewMetrics
                            ? componentData.map((country, i) => (
                                  <Col sm={24} md={8} key={`country${i}`}>
                                      <div className="rating">
                                          <p className="fs18" style={{ fontWeight: '600' }}>
                                              {country.country}
                                          </p>
                                          <ProgressComponent count={country.rating} />
                                          <div style={{ textAlign: 'center', paddingTop: 30 }}>
                                              <Button
                                                  type="primary"
                                                  onClick={() => {
                                                      setviewMetrics(true);
                                                      setpickedCountry(i);
                                                  }}
                                                  style={{
                                                      backgroundColor:
                                                          country.rating === 100
                                                              ? '#34a939b3'
                                                              : '#A2792C',
                                                      borderColor:
                                                          country.rating === 100
                                                              ? '#34a939b3'
                                                              : '#A2792C'
                                                  }}
                                                  className="rounded-btn">
                                                  Update
                                              </Button>
                                          </div>
                                      </div>
                                  </Col>
                              ))
                            : componentData[pickedCountry].metrics.map((country, i) => (
                                  <Col sm={24} md={8} key={`country${i}`}>
                                      <div className="rating">
                                          <p className="fs18" style={{ fontWeight: '600' }}>
                                              {country.name}
                                          </p>
                                          <ProgressComponent count={country.score} />
                                          <div style={{ textAlign: 'center', paddingTop: 30 }}>
                                              <Button
                                                  type="primary"
                                                  onClick={() => {
                                                      setviewMetrics(true);
                                                      setPickedMetric(i);
                                                  }}
                                                  style={{
                                                      backgroundColor:
                                                          country.rating === 100
                                                              ? '#34a939b3'
                                                              : '#A2792C',
                                                      borderColor:
                                                          country.rating === 100
                                                              ? '#34a939b3'
                                                              : '#A2792C'
                                                  }}
                                                  className="rounded-btn">
                                                  Update
                                              </Button>
                                          </div>
                                      </div>
                                  </Col>
                              ))}
                    </Row>
                )}
                {pickedMetric && (
                    <div>
                        <Collapse
                            bordered={false}
                            expandIconPosition="right"
                            expandIcon={() => <img style={{ width: 15 }} src={caret_icon} alt="" />}
                            className="site-collapse-custom-collapse"
                            onChange={switchCollapse}>
                            {componentData[pickedCountry].metrics[pickedMetric].steps.map(
                                (step, i) => (
                                    <Panel
                                        header={<p>{step.heading}</p>}
                                        key={i}
                                        className="site-collapse-custom-panel">
                                        <div style={{ width: 500, margin: 'auto', padding: 40 }}>
                                            <p style={{ fontSize: 20, fontWeight: '600' }}>
                                                Please confirm your statement below to your
                                                customers
                                            </p>
                                            {step.value ? (
                                                <p style={{ fontSize: 16 }}>{step.subHeading}</p>
                                            ) : (
                                                <TextArea
                                                    defaultValue={step.subHeading}
                                                    style={{ width: 400, textAlign: 'left' }}
                                                    rows={6}
                                                />
                                            )}
                                            <Button
                                                className="rounded-btn"
                                                type={step.value ? 'primary' : 'ghost'}
                                                style={{ float: 'left' }}>
                                                True
                                            </Button>
                                            <Button
                                                type={!step.value ? 'primary' : 'ghost'}
                                                className="rounded-btn"
                                                style={{ float: 'right' }}>
                                                False
                                            </Button>
                                        </div>
                                        <div style={{ marginTop: 70, textAlign: 'left' }}>
                                            <span
                                                style={{
                                                    marginRight: 12,
                                                    color: 'rgba(79, 79, 79, 0.4)'
                                                }}>
                                                <ArrowLeftOutlined /> Prev
                                            </span>
                                            <span>
                                                Next <ArrowRightOutlined />
                                            </span>

                                            <span></span>
                                        </div>
                                    </Panel>
                                )
                            )}
                        </Collapse>
                    </div>
                )}
            </div>
        </div>
    );
}

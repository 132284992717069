import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import { Modal, ModalBody } from 'reactstrap';
const responsive = {
    galleryMedia: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};
const GalleryMediaModal = (props) => {
    const { isModalOpen, setModalOpenState } = props;
    return (
        <Modal
            className="modal-gallery"
            size="lg"
            centered
            isOpen={isModalOpen}
            toggle={setModalOpenState}>
            <ModalBody className="text-center bg-transparent">
                <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={false}
                    arrows={true}
                    responsive={responsive.galleryMedia}
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={3000}
                    keyBoardControl={true}
                    containerClass="">
                    {props.children}
                </Carousel>
            </ModalBody>
        </Modal>
    );
};

GalleryMediaModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func,
    children: PropTypes.node
};

export default GalleryMediaModal;

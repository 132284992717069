/* eslint-disable no-case-declarations */
import {
    ALL_DAY_PRODUCT_CATEGORIES,
    WITHIN_DAY_PRODUCT_CATEGORIES,
    GET_PRODUCT,
    GET_EXISTING_MAIN_PRODUCTS,
    UPDATE_PRODUCTS_PERSONAL_INFO,
    UPDATE_PRODUCTS_PERSONAL_INFO_ERROR,
    PRODUCT_DETAIL,
    ADD_MORE_PRODUCTS,
    PACKAGE_DETAIL,
    RESET_PRODUCT_REDUCER,
    SET_UPDATE_PRODUCT_FIELDS,
    GET_ALL_ARCHIVED_PRODUCT,
    SET_CURRENT_PRODUCT,
    UPDATE_PRODUCT_MEALS_DRINKS,
    UPDATE_PRODUCT_LANGUAGE_OFFERS,
    UPDATE_PRODUCT_INCLUSION_EXCLUSION,
    UPDATE_PRODUCT_COVID,
    UPDATE_PRODUCT_CALENDAR_SEASON,
    UPDATE_PRODUCT_ADVENTURES,
    UPDATE_PRODUCT_OCCASSIONS,
    UPDATE_PRODUCT_SIGHTS,
    UPDATE_PRODUCT_TRAVELLER_INFORMATION,
    SELECTED_EXISTING_PRODUCT,
    UPDATE_PRODUCT_BOOKINGS_TICKETS,
    UPDATE_PRODUCT_PRICING,
    UPDATE_PRODUCT_HOUSE_RULES,
    UPDATE_PRODUCT_ROOMS,
    UPDATE_PRODUCT_FACILITIES,
    UPDATE_PRODUCT_BREAKFAST,
    ATTACHED_DUPLICATED_PRODUCT,
    CURRENT_PRODUCT_FLOW
} from 'store/types';

const initialState = {
    products: [],
    all_day_product_categories: [],
    within_day_product_categories: [],
    existing_main_products: null,
    product_detail: {},
    add_more_products: [],
    package_detail: {},
    showUpdateProductFields: {},
    all_archived_products: null,
    currentProduct: null,
    selected_existing_product: false,
    attached_duplicated_product: null,
    current_product_flow: null
};

const productReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CURRENT_PRODUCT:
            return mapReducerForPricingRequirement(state, action);

        case SET_UPDATE_PRODUCT_FIELDS:
            return {
                ...state,
                showUpdateProductFields: action.payload
            };

        case GET_PRODUCT:
            return {
                ...state,
                products: action.payload?.data?.products
            };
        case ALL_DAY_PRODUCT_CATEGORIES:
            return {
                ...state,
                all_day_product_categories: action.payload?.data?.product_categories
            };
        case WITHIN_DAY_PRODUCT_CATEGORIES:
            return {
                ...state,
                within_day_product_categories: action.payload?.data?.product_categories
            };
        case GET_EXISTING_MAIN_PRODUCTS:
            return {
                ...state,
                existing_main_products: action.payload
            };
        case UPDATE_PRODUCTS_PERSONAL_INFO:
            return { ...state, update_products_personal_info: action.payload?.data };
        case UPDATE_PRODUCTS_PERSONAL_INFO_ERROR:
            return { ...state, error_update_products_personal_info: action.payload?.error };
        case PRODUCT_DETAIL:
            return { ...state, product_detail: action.payload };
        case ADD_MORE_PRODUCTS:
            return { ...state, add_more_products: action.payload };
        case PACKAGE_DETAIL:
            return { ...state, package_detail: action.payload };
        case RESET_PRODUCT_REDUCER:
            if (action.payload && Object.keys(action.payload).length > 0) {
                Object.keys(action.payload).forEach((key) => {
                    state[key] = initialState[key];
                });
            }
            return { ...state };
        case GET_ALL_ARCHIVED_PRODUCT:
            return { ...state, all_archived_products: action.payload.data };

        case UPDATE_PRODUCT_MEALS_DRINKS:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_LANGUAGE_OFFERS:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_INCLUSION_EXCLUSION:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_COVID:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_CALENDAR_SEASON:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_ADVENTURES:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_OCCASSIONS:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_SIGHTS:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_TRAVELLER_INFORMATION:
            return mapReducerForPricingRequirement(state, action);
        case SELECTED_EXISTING_PRODUCT:
            return {
                ...state,
                selected_existing_product: action.payload
            };
        case UPDATE_PRODUCT_BOOKINGS_TICKETS:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_PRICING:
            return mapReducerForPricingRequirement(state, action);

        case UPDATE_PRODUCT_HOUSE_RULES:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_ROOMS:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_FACILITIES:
            return mapReducerForPricingRequirement(state, action);
        case UPDATE_PRODUCT_BREAKFAST:
            return mapReducerForPricingRequirement(state, action);
        case ATTACHED_DUPLICATED_PRODUCT:
            return {
                ...state,
                attached_duplicated_product: action.payload
            };
        case CURRENT_PRODUCT_FLOW:
            return {
                ...state,
                current_product_flow: action.payload
            };
        default:
            return state;
    }
};

function mapReducerForPricingRequirement(state, action) {
    if (state.currentProduct?.id === action.payload?.id)
        return { ...state, currentProduct: { ...state.currentProduct, ...action.payload } };

    return { ...state, currentProduct: action.payload };
}

export default productReducer;

import React from 'react';
import { Input } from 'reactstrap';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import searchIcon from 'assets/images/search.svg';
import propTypes from 'prop-types';

const SearchBox = ({ inputValue, search }) => {
    return (
        <InputGroup className="search-input">
            <Input placeholder="Search" value={inputValue} onChange={search} />
            <InputGroupAddon addonType="append">
                <InputGroupText className="icon-append">
                    <img src={searchIcon} alt="" />
                </InputGroupText>
            </InputGroupAddon>
        </InputGroup>
    );
};
SearchBox.propTypes = {
    search: propTypes.func,
    inputValue: propTypes.string
};
export default SearchBox;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Checkbox, InputNumber, TimePicker } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
// import moment from 'moment';
import DeleteIcon from 'assets/images/close.svg';
import {
    // Button,
    // ModalFooter,
    // ModalBody,
    Col,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row
    // Spinner
} from 'reactstrap';

import {
    GoogleAutoComplete,
    // TimeBlock,
    GoogleMapItinerary,
    Spacer
} from 'components/atoms';
// import { getTimeMinuteAmPmFromStrig } from 'utils/helper';
import moment from 'moment';

function MarketGuide(props) {
    const { formRef, setState, onDeleteAnotherStart, onAddAnotherStart } = props;
    const { isCheckBox, atAStartPoint, anotherStart, departure_time, departure_pickup_duration } =
        props.state;

    useEffect(() => {
        if (formRef.current) {
            formRef.current.setFieldsValue({
                //startTimeHours: departure_time?.hour,
                //startTimeMinutes: departure_time?.minute,
                //startTimeAmPm: departure_time?.am_pm,
                departureTime: departure_time ? moment(departure_time, 'h:mm a') : '',
                activity_duration_hour: departure_pickup_duration?.hour,
                activity_duration_minute: departure_pickup_duration?.minute
            });
        }
    }, [departure_time]);

    function onChangeDepartureDurationTime(value, type) {
        setState((prevState) => {
            return {
                departure_pickup_duration: {
                    ...prevState.departure_pickup_duration,
                    minute: prevState.departure_pickup_duration?.minute || 0,
                    hour: prevState.departure_pickup_duration?.hour || 0,
                    [type]: value
                }
            };
        });
        if (type === 'minute' && !departure_pickup_duration?.hour && formRef?.current)
            formRef.current.setFieldsValue({
                activity_duration_hour: 0
            });
        else if (type === 'hour' && !departure_pickup_duration?.minute && formRef?.current)
            formRef.current.setFieldsValue({
                activity_duration_minute: 0
            });
    }
    return (
        <>
            <hr style={{ marginBottom: '25px' }} />
            <Checkbox.Group
                className="checkbox-block"
                onChange={(checkedValues) => {
                    setState({ isCheckBox: checkedValues });
                }}
                value={isCheckBox}>
                <p className="label input-title">
                    Now tell us where you meet travelers for this product guide?
                </p>
                <div className="mb-12px">
                    <Checkbox
                        // checked={isCheckBox === 1}
                        // onChange={() => setState({ isCheckBox: 1 })}
                        value="1">
                        At the accomodation provided by you
                    </Checkbox>
                </div>
                <div className="mb-12px">
                    <Checkbox value="2">
                        Allow travellers to enter their their own pick up location
                        <div className="fs-14">
                            Do not select this, if you only pick up from the your accomodation or
                            the starting point you identified.
                        </div>
                    </Checkbox>
                </div>
                <div className="mb-12px">
                    <Checkbox value="3">At a Starting Point</Checkbox>
                </div>
                {isCheckBox && Array.isArray(isCheckBox) && isCheckBox.includes('3') ? (
                    <div>
                        <div style={{ fontWeight: 500 }}>
                            Search for point of interest or address
                        </div>
                        <Form.Item
                            name="atAStartPoint"
                            className="mb-2"
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Please fill this field in'
                            //     }]}
                        >
                            <GoogleAutoComplete
                                defValue={atAStartPoint}
                                fieldName="atAStartPoint"
                                onSelectDistance={(value) => {
                                    formRef.current.setFieldsValue({
                                        atAStartPoint: value?.name
                                    });
                                    setState({
                                        atAStartPoint: value
                                    });
                                }}
                            />
                        </Form.Item>
                        <Spacer bottom={15} />
                    </div>
                ) : null}
                {anotherStart.length > 0 && (
                    <div>
                        <div style={{ fontWeight: 500 }}>Add another starting point</div>
                        {anotherStart.map((res, index) => (
                            <div key={index}>
                                <div className="d-flex flex-nowrap gap-2">
                                    <div className="flex-grow-1">
                                        <Form.Item
                                            //key={index}
                                            name={`anotherStart${index}`}
                                            // rules={[
                                            //     {
                                            //         required: true,
                                            //         message:
                                            //             'Please fill this field in'
                                            //     }]}
                                        >
                                            <GoogleAutoComplete
                                                fieldName={`anotherStart${index}`}
                                                defValue={res}
                                                onSelectDistance={(value) => {
                                                    const tmp = anotherStart;
                                                    tmp.splice(index, 1, value);
                                                    setState({
                                                        anotherStart: [...tmp]
                                                    });
                                                    formRef.current.setFieldsValue({
                                                        [`anotherStart${index}`]: value?.name
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className="delete-block-autocomplete">
                                        <img
                                            src={DeleteIcon}
                                            className="delete-icon cursor-pointer"
                                            onClick={() => {
                                                onDeleteAnotherStart(index);
                                            }}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <p
                    className="page-sub-title brown-text add-another cursor-pointer"
                    onClick={onAddAnotherStart}>
                    <PlusCircleOutlined color="#A2792C" />
                    Add another starting point
                </p>
                {isCheckBox && Array.isArray(isCheckBox) && isCheckBox.includes('3') ? (
                    <div>
                        <div style={{ fontWeight: 500 }}>
                            If your starting point doesn't look right, you can drag the pin to
                            adjust its location.
                        </div>
                        <GoogleMapItinerary
                            data={anotherStart}
                            onSave={(value) => {
                                setState({
                                    atAStartPoint: value.formatted_address
                                });
                            }}
                        />
                    </div>
                ) : null}
            </Checkbox.Group>

            <p className="label mt25 input-title">What is the Depature time?</p>

            <Row className="flex-nowrap">
                <Col xs="auto">
                    <Form.Item
                        name="departureTime"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please select departure time'
                        //     }
                        // ]}
                    >
                        <TimePicker
                            size="large"
                            //defaultValue={0}
                            use12Hours
                            format="h:mm a"
                            placeholder="12:00 am"
                            value={!departure_time ? '' : moment(departure_time, 'h:mm a')}
                            style={{
                                height: '50Bookingpx',
                                width: '216px'
                            }}
                            onChange={(e, dateS) => {
                                if (dateS) {
                                    setState((prevState) => {
                                        return {
                                            ...prevState,
                                            departure_time: e.format('HH:mm')
                                        };
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                </Col>
            </Row>

            <p className="label mt25 input-title">
                How long before departure does your pickup start?
            </p>

            {/* <Form.Item
                style={{ marginBottom: 0 }}
                name="departure_pickup_duration"
            // rules={[{ required: true, message: 'Please select time' }]}
            >
                <TimeBlock
                    data={moment.unix(departure_pickup_duration).utc().format('HH:mm')}
                    isReturnSeconds={true}
                    onSeconds={(e) => {
                        formRef.current.setFieldsValue({
                            departure_pickup_duration: e
                        });
                        setState({ departure_pickup_duration: e });
                    }}
                    showAmPm={false}
                />
            </Form.Item> */}

            <Row className="flex-nowrap">
                <Col xs="auto">
                    <InputGroup className="_type-time _type-time-stable mb-10px">
                        <Form.Item
                            className="mb-0"
                            name="activity_duration_hour"
                            rules={[
                                {
                                    required:
                                        (departure_pickup_duration?.minute ||
                                            departure_pickup_duration?.minute === 0) &&
                                        !departure_pickup_duration?.hour,
                                    message: 'Please select'
                                }
                            ]}>
                            <InputNumber
                                size="large"
                                min={0}
                                max={23}
                                placeholder={'00'}
                                value={departure_pickup_duration?.hour}
                                onChange={(value) => onChangeDepartureDurationTime(value, 'hour')}
                                formatter={(value) =>
                                    `${value && value < 10 ? '0' + value : value}`
                                }
                            />
                        </Form.Item>
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <span className="d-none d-lg-inline">{'hours'}</span>
                                <span className={'d-lg-none'}>{'hr'}</span>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>

                <Col xs="auto">
                    <InputGroup className="_type-time _type-time-stable mb-10px">
                        <Form.Item
                            className="mb-0"
                            name="activity_duration_minute"
                            rules={[
                                {
                                    required:
                                        (departure_pickup_duration?.hour ||
                                            departure_pickup_duration?.hour === 0) &&
                                        !departure_pickup_duration?.minute,
                                    message: 'Please select'
                                }
                            ]}>
                            <InputNumber
                                size="large"
                                min={0}
                                max={59}
                                placeholder={'00'}
                                value={departure_pickup_duration?.minute}
                                onChange={(value) => onChangeDepartureDurationTime(value, 'minute')}
                                formatter={(value) =>
                                    `${value && value < 10 ? '0' + value : value}`
                                }
                            />
                        </Form.Item>
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <span className="d-none d-lg-inline">{'minutes'}</span>
                                <span className={'d-lg-none'}>{'min'}</span>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                </Col>
            </Row>

            <hr style={{ marginBottom: '25px' }} />
        </>
    );
}

MarketGuide.propTypes = {
    state: PropTypes.object,
    props: PropTypes.object,
    formRef: PropTypes.any,
    setState: PropTypes.func,
    onDeleteAnotherStart: PropTypes.func,
    onAddAnotherStart: PropTypes.func
};
export default MarketGuide;

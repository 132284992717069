/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Collapse } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CalendarComponent from '../../../molecules/calendar/Calendar';

import '../personalcare/Personalcare.scss';
import VendorDashboard from '../vendor-dashboard';

const { Panel } = Collapse;

export default function OtherVendorDetails() {
    const [setStep] = useState(1);
    const [days, setdays] = useState([
        {
            day: 1
        }
    ]);

    const addDay = () => {
        let newDay = days[days.length - 1].day + 1;
        setdays([...days, { day: newDay }]);
    };

    return (
        <VendorDashboard>
            <div className="other-vendor-details-wrap">
                <div className="other-vendor-details">
                    <p className="page-title">Tell us about your ‘Other Vendor’s Text’</p>
                    <p className="page-sub-title">
                        Be sure to enter all key attractions in your itinerary. This helps travelers
                        find and book your experience.
                    </p>

                    <div className="brown-patch" style={{ borderRadius: 12 }}>
                        <Collapse defaultActiveKey={['1']}>
                            {days.map((item, i) => (
                                <Panel header={`Day ${item.day}`} key={`day${i}`}>
                                    <div
                                        className="white-bg"
                                        style={{ padding: '15px 30px', borderRadius: 15 }}>
                                        <p className="patch-title">‘Ventor‘s text’</p>
                                        <p className="patch-link">
                                            <Link to="/other-vendor-details/add-vento-text">
                                                <PlusCircleOutlined
                                                    fill="#A2792C"
                                                    color="#A2792C"
                                                />{' '}
                                                Add a Ventor‘s text
                                            </Link>
                                        </p>
                                    </div>
                                </Panel>
                            ))}
                        </Collapse>
                        <p
                            className="patch-link p10"
                            style={{ cursor: 'pointer', fontWeight: '500' }}
                            onClick={addDay}>
                            <PlusCircleOutlined color="#A2792C" /> Add another day
                        </p>
                    </div>

                    <Button
                        onClick={() => setStep(2)}
                        type="primary"
                        className="rounded-btn pl30 pr30 mt30">
                        Update Calender & Continue
                    </Button>
                </div>

                <div className="mt40 white-bg p30">
                    <CalendarComponent />
                </div>
            </div>
        </VendorDashboard>
    );
}

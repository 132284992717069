import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toggleToastAble } from '../../../store/actions/toastActions';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import SuccessIcon from '../icons/success-icon';
import ErrorIcon from '../icons/error-icon';
import PropTypes from 'prop-types';
class Toastable extends Component {
    render() {
        const { toggle_toast } = this.props;
        if (!toggle_toast || !toggle_toast.toast) {
            return null;
        }

        return (
            <div>
                <Modal
                    isOpen={toggle_toast}
                    centered
                    className="text-center"
                    toggle={() => this.props.toggleToastAble({ toast: false })}>
                    <ModalBody className="pb-0">
                        <div className="mb-3">
                            {toggle_toast?.success === true ? <SuccessIcon /> : <ErrorIcon />}
                        </div>
                        <p className="text-big mb-0 mx-auto">{toggle_toast.message}</p>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button
                            color="primary minw-126"
                            size="lg"
                            onClick={() => {
                                this.props.toggleToastAble({ toast: false });
                            }}>
                            OK
                        </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

const mapState = (state) => ({
    toggle_toast: state.toast.toggle_toast
});

const mapProps = (dispatch) => ({
    toggleToastAble: (data) => dispatch(toggleToastAble(data))
});

export default connect(mapState, mapProps)(Toastable);

Toastable.propTypes = {
    toggle_toast: PropTypes.any,
    toggleToastAble: PropTypes.func
};

import { notification } from 'antd';

export const Notification = (type, messageDesc, status) => {
    if (!status) status = type;
    let msg = '';
    if (typeof messageDesc === 'string') msg = messageDesc;
    else if (Array.isArray(messageDesc) && messageDesc.length) msg = messageDesc[0]?.message ?? '';

    if (msg)
        return notification[type]({
            message: status === 200 || status === 'success' ? 'Success' : 'Error',
            description: msg,
            top: 110,
            duration: 5
        });
    else <></>;
};

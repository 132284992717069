import React from 'react';
import { Chart } from 'react-google-charts';

export default function PieChart(props) {
    return (
        <Chart
            width={'100%'}
            height={'100%'}
            chartType="PieChart"
            loader={<div>Loading Chart</div>}
            data={[
                ['Revenue', 'Percentage'],
                ['', 75],
                ['', 25]
            ]}
            options={{
                legend: 'none',
                pieSliceText: 'none',
                pieStartAngle: 135,
                tooltip: { trigger: 'none' },
                slices: {
                    0: { color: props.pieColor },
                    1: { color: '#C4C4C4' }
                }
            }}
            rootProps={{ 'data-testid': '6' }}
        />
    );
}

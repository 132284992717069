import React, { useContext, useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Payment from 'assets/images/pay.svg';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { AlertComponent } from 'components/atoms';
import Info from 'assets/images/carbon_information.svg';
import { EditOutlined } from '@ant-design/icons';

const PayoutBookings = (props) => {
    const { my_profile, setShowPayoutModal } = props;
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const [stripeRequiedDataAlert, setStripeRequiedDataAlert] = useState(null);

    useEffect(() => {
        if (!my_profile?.vendor?.stripe_connected_account_id)
            props.isFilledRequiedInfoForStripeOnboard().then((res) => {
                if (res && Array.isArray(res) && res.length > 0) setStripeRequiedDataAlert(res);
            });
    }, []);

    function mapAccountKeys(key) {
        if (key === 'businessInformation.businessTaxId') return 'Business Tax Id';
        else if (key === 'businessInformation.businessName') return 'Business Name';
        else if (key === 'businessInformation.companyWebsite') return 'Company Website';
        else if (key === 'businessInformation.businessTradingName') return 'Business Trading Name';
        else if (key === 'businessInformation.customerServiceEmail')
            return 'Customer Service Email';
        else if (key === 'businessInformation.customerServicePhoneNumber')
            return 'Customer Service Phone Number';
        else if (key === 'vendor.email') return 'Vendor Email';
    }

    return (
        <div
            className={`d-flex flex-wrap flex-sm-nowrap mb-26px ${assignComponentPermission(
                'bookings-dashboard'
            )}`}>
            <div className="pr-16px">
                <img src={Payment} alt="" />
            </div>
            {!my_profile?.vendor?.stripe_connected_account_id &&
            !my_profile?.vendor?.paystack_account_id ? (
                <div className="flex-grow-1">
                    <div className="d-flex flex-column mt-15px">
                        <div className="flex-grow-1 pr-lg-3">
                            <p className="text-big fw-600 mb-8px">Tell us how to pay you</p>
                            <div className="fs-14">
                                {' '}
                                Select your preferred currency and add your bank details
                            </div>
                        </div>

                        <div className="flex-shrink-0 mt-12px">
                            <Button
                                disabled={Array.isArray(stripeRequiedDataAlert)}
                                onClick={() => setShowPayoutModal(true)}
                                color="primary"
                                className="btn-block btn-md-inline-block btn-mw btn-px mb-3">
                                Add payout settings
                            </Button>

                            {stripeRequiedDataAlert && (
                                <AlertComponent color="primary" icon={<img src={Info} alt="" />}>
                                    <div className="text-left">
                                        <h3 className="alert-heading">Information requied</h3>
                                        <p className="fs-13 mb-8px">
                                            Please fill the requied info, before onboarding.
                                        </p>
                                        {Array.isArray(stripeRequiedDataAlert) &&
                                            stripeRequiedDataAlert.map((val, ind) => (
                                                <NavLink
                                                    key={ind}
                                                    className="alert-action-link"
                                                    to="/account">
                                                    <EditOutlined className="mr-2 mb-1" />

                                                    {mapAccountKeys(val)}
                                                </NavLink>
                                            ))}
                                    </div>
                                </AlertComponent>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div className="flex-grow-1 overflow-hidden">
                    <div className="d-flex flex-column mt-15px">
                        <div className="flex-grow-1 pr-lg-3">
                            <p className="text-big fw-600 mb-8px">Upcoming Booking</p>
                            <div className="fs-14">
                                <div className="d-flex">
                                    <span className="fw-500 text-nowrap mr-1">
                                        1 Week Calendar:
                                    </span>{' '}
                                    <span className="text-truncate">
                                        Victoria falls & Chobe Calendar Trip
                                    </span>{' '}
                                    (013529)
                                </div>
                                <span>Date: Today, 12th of August 2021</span>
                            </div>
                        </div>

                        <div className="flex-shrink-0 mt-12px">
                            <Link
                                color="primary"
                                to="/bookings"
                                className="btn btn-primary btn-block btn-md-inline-block btn-mw btn-px">
                                View Booking
                            </Link>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

PayoutBookings.propTypes = {
    my_profile: PropTypes.object,
    setShowPayoutModal: PropTypes.func,
    isFilledRequiedInfoForStripeOnboard: PropTypes.func
};
export default PayoutBookings;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import Carousel from 'react-multi-carousel';
import { DishIcon } from 'components/atoms';

const responsive = {
    eventGallery: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};

const EventMealsAndDrinks = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<DishIcon />}
            panelTitle="Meals and Drinks"
            isEditable={true}
            onEdit={onEdit}>
            <Row className="row-cols-1 row-cols-md-3">
                {product?.meals_and_drinks?.length > 0 ? (
                    product?.meals_and_drinks.map((item, index) => (
                        <MealItem key={index} item={item} index={index} />
                    ))
                ) : (
                    <></>
                )}
            </Row>
        </DetailedPanel>
    );
};

EventMealsAndDrinks.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventMealsAndDrinks;

const MealItem = ({ item, index }) => {
    const [showMore, setShowMore] = useState(false);

    return (
        <Col key={index}>
            <Row>
                <Col xs={4} md={12}>
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        arrows={true}
                        responsive={responsive.eventGallery}
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        containerClass="event-gallery-carousel h-auto mb-3">
                        {item?.photos_and_videos?.length > 0 ? (
                            item?.photos_and_videos.map((media, newIndex) => (
                                <div key={newIndex} className="meals-drinks-item item-type-img">
                                    <img
                                        className={`meals-drinks-${
                                            media?.type === 'video' ? 'video' : 'img'
                                        }`}
                                        src={media?.url}
                                        alt=""
                                    />
                                </div>
                            ))
                        ) : (
                            <></>
                        )}
                    </Carousel>
                </Col>
                <Col xs={8} md={12}>
                    <div className="fs-18 fw-500">{item?.type}</div>
                    <div className="fs-13 lh-1-3">{item?.description.slice(0, 30)}</div>
                    {item?.description.length > 30 && (
                        <Button
                            key={1}
                            color="link"
                            className="fs-13 fw-500 px-0"
                            size="small"
                            onClick={() => {
                                setShowMore(!showMore);
                            }}>
                            <i>Read More</i>
                        </Button>
                    )}
                </Col>
            </Row>
        </Col>
    );
};

MealItem.propTypes = {
    item: PropTypes.any,
    index: PropTypes.any
};

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Container } from 'reactstrap';
import { Button, Col, Form, Input, Row, Select, Tooltip, TimePicker } from 'antd';
import Feedback from 'components/molecules/user-feedback';
import MoreInfo from 'assets/images/more_info.svg';
import AddMoreIcon from 'assets/images/addMoreIcon.svg';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    getCalendars,
    createCalendars,
    updateSuccessCalendar,
    updateErrorCalendar
} from 'store/actions/calendarActions';
import moment from 'moment';
import { CalendarService } from 'services';
import { MultiLocationSearch } from 'components/molecules';

const CalendarTitleLength = (props) => {
    const [useCal, setUseCal] = useState(false);
    const [refCode, setRefCode] = useState(false);

    // Resting Time
    const [restTimeEnd, setRestTimeEnd] = useState('12:00 am');

    const { Option } = Select;

    const weeks = ['0', '1', '2', '3', '4'];
    const days = ['0', '1', '2', '3', '4', '5', '6'];
    const startDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

    const handleUseCal = () => {
        setUseCal(!useCal);
    };

    const handleRefCode = () => {
        setRefCode(!refCode);
    };

    const onChangeCalendar = (id) => {
        let selected = props?.calendar_data?.data.find((x) => x.id === id);
        CalendarService.getDuplicateCalendarTitle(selected?.id).then((res) => {
            if (res.success) {
                let startTime = moment(selected.rest_time_start, 'HH:mm');
                let endTime = moment(selected.rest_time_end, 'HH:mm');
                let duration = moment.duration(endTime.diff(startTime));
                let formattedEndTime = endTime.format('h:mm a');
                setRestTimeEnd(formattedEndTime);

                form.setFieldsValue({
                    title: res.data,
                    rest_time: Math.round(Math.abs(parseInt(duration.asHours()))).toString(),
                    no_of_days: selected.no_of_days.toString(),
                    no_of_weeks: selected.no_of_weeks.toString(),
                    ready_by: selected.ready_by,
                    calendar_ref: selected.calendar_ref,
                    start_day: selected.start_day
                });

                if (selected.calendar_ref) {
                    setRefCode(true);
                } else {
                    setRefCode(false);
                }
            }
        });
    };

    const cancelImport = () => {
        form.resetFields();
        setRestTimeEnd('12:00 am');
        handleUseCal();
    };

    useEffect(() => {
        if (props.success_calendar) {
            setSavingForm(false);
            props.updateSuccessCalendar(false);
            props.history.push(`/calendar/${props.create_calendar.id}`);
        } else if (props.error_calendar) {
            setSavingForm(false);
            props.updateErrorCalendar();
        } else {
            props.getCalendars();
        }
    }, [props.success_calendar, props.error_calendar, props.create_calendar]);

    const [form] = Form.useForm();
    const [savingForm, setSavingForm] = useState(false);
    const onFinish = () => {
        setSavingForm(true);
        let formValues = form.getFieldsValue();
        const destinations = [];
        if (Array.isArray(formValues?.destinations)) {
            for (let city of formValues?.destinations)
                destinations.push({
                    cityId: city
                });
        }
        props.createCalendars({
            ...formValues,
            rest_time_start: moment(restTimeEnd, 'HH:mm')
                .subtract(formValues.rest_time, 'hours')
                .format('HH:mm'),
            rest_time_end: moment(restTimeEnd, 'HH:mm').format('HH:mm'),
            destinations
        });
    };

    function validateWeeksAndDays(value, type) {
        if (type === 'DAYS')
            if (value === '0') {
                if (form.getFieldValue('no_of_weeks') === '0') {
                    return Promise.reject('Calendar should have atleast 1 day!');
                } else return Promise.resolve();
            } else {
                setTimeout(() => form.validateFields(['no_of_weeks']), 300);
                return Promise.resolve();
            }

        if (type === 'WEEKS')
            if (value === '0') {
                if (form.getFieldValue('no_of_days') === '0') {
                    return Promise.reject('Calendar should have atleast 1 day!');
                } else return Promise.resolve();
            } else {
                setTimeout(() => form.validateFields(['no_of_days']), 300);
                return Promise.resolve();
            }
    }

    return (
        <>
            <div className="signin e2e-fix">
                <Container className="container-login">
                    <div className="box-padded">
                        <div className="d-flex justify-content-end">
                            <Button
                                className="mr-2"
                                type="primary"
                                size="small"
                                onClick={() => props.history.go(-1)}>
                                Go Back
                            </Button>
                        </div>
                        <Form
                            className="form-label-fw-500"
                            layout="vertical"
                            requiredMark={true}
                            form={form}
                            onFinish={onFinish}>
                            <h1 className="box-title">Let's get started</h1>
                            <div className="d-flex justify-content-between align-items-start">
                                <div
                                    className="text-primary fs-500 d-flex align-items-start"
                                    onClick={handleUseCal}
                                    role="button">
                                    <div className="mr-2">
                                        <img src={AddMoreIcon} width={14} alt="" />
                                    </div>
                                    <p className="mb-1">
                                        I will import calendar information from my other calendar
                                    </p>
                                </div>
                                {useCal && (
                                    <Button type="link" size="small" onClick={cancelImport}>
                                        Cancel Import
                                    </Button>
                                )}
                            </div>
                            {useCal ? (
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <div className="ant-form-item-label">
                                                <label
                                                    htmlFor="another_calendar"
                                                    className="ant-form-item-required">
                                                    Select calendar to resuse from another calendar
                                                </label>
                                                {/* <p className="fs-12 mb-0">Additional text</p> */}
                                            </div>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item
                                                name="another_calendar"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please Select Calendar Type'
                                                    }
                                                ]}>
                                                <Select size="large" onChange={onChangeCalendar}>
                                                    {props?.calendar_data?.data.map((value) => (
                                                        <Option value={value.id} key={value.id}>
                                                            {value.title}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </>
                            ) : null}
                            <Row className="mb-3">
                                <Col span={24}>
                                    <div className="ant-form-item-label">
                                        <label htmlFor="title" className="ant-form-item-required">
                                            Enter customers calendar title{' '}
                                        </label>
                                        <Tooltip
                                            title={`This is the title of the calendar you are about to create. The title of this calendar must be unique`}>
                                            <img className="ml-1 align-top" src={MoreInfo} alt="" />
                                        </Tooltip>
                                        {/* <p className="fs-12 mb-0">Additional text</p> */}
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        name="title"
                                        initialValue={props?.selected_calendar?.title}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your customer calendar title'
                                            }
                                        ]}>
                                        <Input size="large" />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <div
                                        className="mb-2 mt-n2"
                                        role="button"
                                        onClick={handleRefCode}>
                                        <span className="text-primary fs-14 fw-500">
                                            Add your customers calendar reference code
                                            <span className="fs-12 text-muted">(Optional)</span>
                                        </span>
                                        <Tooltip
                                            title={`This code can be used by you to recognise this calendar when created`}>
                                            <img className="ml-1 align-top" src={MoreInfo} alt="" />
                                        </Tooltip>
                                    </div>
                                </Col>
                                {refCode ? (
                                    <Col span={24}>
                                        <Form.Item
                                            name="calendar_ref"
                                            rules={[
                                                { required: false, message: 'Add reference code' }
                                            ]}>
                                            <Input size="large" />
                                        </Form.Item>
                                    </Col>
                                ) : null}
                            </Row>

                            <Row>
                                <Col span={24}>
                                    <div className="d-flex gap-2 flex-wrap">
                                        <div>
                                            <div className="fs-16 fw-500 mb-2">
                                                Calendar Length
                                                <Tooltip
                                                    title={`This is the recommended number of days and weeks the calendar can be used`}>
                                                    <img
                                                        className="ml-1 align-top"
                                                        src={MoreInfo}
                                                        alt=""
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <Form.Item
                                                    name="no_of_weeks"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Please select calendar week length'
                                                        },
                                                        {
                                                            validator(_, value) {
                                                                return validateWeeksAndDays(
                                                                    value,
                                                                    'WEEKS'
                                                                );
                                                            }
                                                        }
                                                    ]}>
                                                    <Select
                                                        className="d-block"
                                                        size="large"
                                                        style={{ minWidth: 140 }}>
                                                        {weeks.map((item, index) => (
                                                            <Option key={index} value={item}>
                                                                {`${item} ${
                                                                    Number(item) === 0 ||
                                                                    Number(item) > 1
                                                                        ? 'weeks'
                                                                        : 'week'
                                                                }`}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                                <Form.Item
                                                    name="no_of_days"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message:
                                                                'Please select calendar day length'
                                                        },
                                                        {
                                                            validator(_, value) {
                                                                return validateWeeksAndDays(
                                                                    value,
                                                                    'DAYS'
                                                                );
                                                            }
                                                        }
                                                    ]}>
                                                    <Select
                                                        className="d-block"
                                                        size="large"
                                                        style={{ minWidth: 122 }}>
                                                        {days.map((item, index) => (
                                                            <Option key={index} value={item}>
                                                                {`${item} ${
                                                                    Number(item) === 0 ||
                                                                    Number(item) > 1
                                                                        ? 'days'
                                                                        : 'day'
                                                                }`}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="fs-16 fw-500 mb-2">
                                                <span>
                                                    {' '}
                                                    First day of Calendar
                                                    <Tooltip
                                                        title={`This is the first day the calendar will start with`}>
                                                        <img
                                                            className="ml-1 align-top"
                                                            src={MoreInfo}
                                                            alt=""
                                                        />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            <div className="d-flex gap-2">
                                                <Form.Item
                                                    name="start_day"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select start day'
                                                        }
                                                    ]}>
                                                    <Select
                                                        className="d-block"
                                                        size="large"
                                                        style={{ minWidth: 140 }}>
                                                        {startDays.map((item, index) => (
                                                            <Option key={index} value={item}>
                                                                {item}
                                                            </Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex mx-n2 mt-n1">
                                        <div className="px-2 flex-grow-1 flex-sm-grow-0">
                                            {/* <div className="ant-form-item-label">
                      <label htmlFor="calendar_day_length" className="ant-form-item-required fs-14 font-weight-normal">Night/Morning</label>
                    </div> */}
                                        </div>
                                        <div className="px-2 flex-grow-1 flex-sm-grow-0">
                                            {/* <div className="ant-form-item-label">
                      <label htmlFor="calendar_day_length" className="ant-form-item-required fs-14 font-weight-normal">Ready by</label>
                    </div> */}
                                        </div>
                                        <div className="px-2 flex-grow-1 flex-sm-grow-0 d-none d-sm-flex"></div>
                                    </div>
                                </Col>
                                {/* <Col span={24}>
                                    <div className="fs-16 fw-500 mb-2 d-flex d-sm-none">
                                        First day of Calendar
                                        <Tooltip title="Tooltip">
                                            <img className="ml-1 align-top" src={MoreInfo} alt="" />
                                        </Tooltip>
                                    </div>
                                    <div className="mx-n2 mt-n1 d-flex d-sm-none">
                                        <div className="px-2 flex-grow-1 flex-sm-grow-0">
                                            <Form.Item
                                                name="start_day"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select start day'
                                                    }
                                                ]}>
                                                <Select size="large" style={{ minWidth: 140 }}>
                                                    <Option value="Mon">Mon</Option>
                                                    <Option value="Tue">Tue</Option>
                                                    <Option value="Wed">Wed</Option>
                                                    <Option value="Thu">Thu</Option>
                                                    <Option value="Fri">Fri</Option>
                                                    <Option value="Sat">Sat</Option>
                                                    <Option value="Sun">Sun</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                    </div>
                                </Col> */}

                                <Col span={24}>
                                    <div className="fs-16 fw-500 mb-2">
                                        Recommended customer’s daily rest time
                                        <Tooltip
                                            title={`This is the rest time that would be recommended to your customers when they use this calendar`}>
                                            <img className="ml-1 align-top" src={MoreInfo} alt="" />
                                        </Tooltip>
                                    </div>
                                    <div className="d-flex mx-n2 mt-2">
                                        <div className="px-2 flex-grow-1 flex-sm-grow-0">
                                            <div className="ant-form-item-label">
                                                <label
                                                    htmlFor="calendar_hour"
                                                    className="ant-form-item-required fs-14 font-weight-normal">
                                                    Night/Morning
                                                </label>
                                            </div>
                                            <Form.Item
                                                name="rest_time"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select calendar hour'
                                                    }
                                                ]}>
                                                <Select size="large" style={{ minWidth: 140 }}>
                                                    <Option value="0">0 hours</Option>
                                                    <Option value="1">1 hours</Option>
                                                    <Option value="2">2 hours</Option>
                                                    <Option value="3">3 hours</Option>
                                                    <Option value="4">4 hours</Option>
                                                    <Option value="5">5 hours</Option>
                                                    <Option value="6">6 hours</Option>
                                                    <Option value="7">7 hours</Option>
                                                    <Option value="8">8 hours</Option>
                                                    <Option value="9">9 hours</Option>
                                                    <Option value="10">10 hours</Option>
                                                    <Option value="11">11 hours</Option>
                                                    <Option value="12">12 hours</Option>
                                                    <Option value="13">13 hours</Option>
                                                    <Option value="14">14 hours</Option>
                                                    <Option value="15">15 hours</Option>
                                                    <Option value="16">16 hours</Option>
                                                </Select>
                                            </Form.Item>
                                        </div>
                                        <div className="px-2 lex-grow-1 flex-sm-grow-0">
                                            <div className="ant-form-item-label">
                                                <label
                                                    htmlFor="ready_by"
                                                    className="ant-form-item-required fs-14 font-weight-normal">
                                                    Ready by
                                                </label>
                                            </div>
                                            <TimePicker
                                                size="large"
                                                placeholder={''}
                                                use12Hours
                                                format="h:mm a"
                                                getPopupContainer={(trigger) =>
                                                    trigger.parentElement
                                                }
                                                onSelect={(e) => setRestTimeEnd(e.format('HH:mm'))}
                                                value={moment(restTimeEnd, 'h:mm a')}
                                            />
                                        </div>
                                    </div>
                                </Col>

                                <Col span={12}>
                                    <div className="fs-16 fw-500 mb-2">
                                        Destination of calendar
                                        <Tooltip title={`Destination of calendar`}>
                                            <img className="ml-1 align-top" src={MoreInfo} alt="" />
                                        </Tooltip>
                                    </div>

                                    <MultiLocationSearch fieldName={'destinations'} />
                                </Col>
                            </Row>
                            <div className="d-sm-flex flex-wrap m-n2 justify-content-between align-items-start pt-4">
                                <div className="p-2">
                                    <Button
                                        type="primary"
                                        loading={savingForm}
                                        size="large"
                                        htmlType="submit"
                                        block>
                                        Create Customers Calendar
                                    </Button>
                                </div>
                                <div className="p-2">
                                    {/* <FixedHelpButton /> */}
                                    <Feedback buttonSize="large" buttonBlock={true} />
                                </div>
                            </div>
                        </Form>
                    </div>
                </Container>
            </div>
        </>
    );
};

CalendarTitleLength.propTypes = {
    error_calendar: PropTypes.bool,
    updateErrorCalendar: PropTypes.func,
    getCalendars: PropTypes.func,
    createCalendars: PropTypes.func,
    success_calendar: PropTypes.bool,
    selected_calendar: PropTypes.shape({
        title: PropTypes.string
    }),
    updateSuccessCalendar: PropTypes.func,
    calendar_data: PropTypes.shape({
        data: PropTypes.array
    }),
    history: PropTypes.shape({
        push: PropTypes.func,
        go: PropTypes.func
    }),
    create_calendar: PropTypes.shape({
        id: PropTypes.number
    })
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    create_calendar: state.calendar.create_calendar,
    success_calendar: state.calendar.success_calendar,
    selected_calendar: state.calendar.selected_calendar,
    error_calendar: state.calendar.error_calendar
});

const mapDispatch = (dispatch) => ({
    getCalendars: () => dispatch(getCalendars()),
    createCalendars: (data) => dispatch(createCalendars(data)),
    updateSuccessCalendar: (data) => dispatch(updateSuccessCalendar(data)),
    updateErrorCalendar: (data) => dispatch(updateErrorCalendar(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(CalendarTitleLength));

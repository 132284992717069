/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '@ramonak/react-progress-bar';
import './index.css';
import { Link, NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function VendorDashboard(props) {
    const myProgress = useSelector((state) => state.progress);
    const [isOpen, setIsOpen] = useState(false);
    const [isProductOpen, setIsProductOpen] = useState(false);
    const [confirmOpen, isConfirmOpen] = useState(false);
    const [confirmSubmit, isConfirmSubmit] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    const productToggle = () => {
        setIsProductOpen(!isProductOpen);
    };

    const confirmToggle = () => {
        isConfirmOpen(!confirmOpen);
    };

    const submitToggle = () => {
        isConfirmSubmit(!confirmSubmit);
    };

    return (
        <div>
            <div className="row " style={{ paddingTop: '25px', marginLeft: '0', marginRight: '0' }}>
                <div className="col-md-3" style={{ paddingRight: '0', paddingLeft: '0' }}>
                    <div className="sidebar-item top-bar unselectedBackground">
                        <p style={{ color: '#333333', fontWeight: '600' }}>
                            {myProgress}% Complete
                        </p>
                        <div className="pad-bottom " style={{ width: '90%' }}>
                            <ProgressBar completed={myProgress} bgcolor="#A2792C" height={12} />
                        </div>
                    </div>
                    <>
                        <div
                            className={isOpen ? 'background' : 'unselectedBackground'}
                            onClick={toggle}>
                            <p
                                style={{ color: 'black' }}
                                className={
                                    isOpen
                                        ? 'left-atc-sidebar cursor-pointer'
                                        : 'closed cursor-pointer'
                                }>
                                CUSTOMERS CALENDAR
                            </p>
                        </div>

                        {isOpen && (
                            <div>
                                <NavLink to="/get-started">
                                    <p className="left-atc-items">Calendar title and Length</p>
                                </NavLink>
                                <NavLink to="/projectcategorization">
                                    {' '}
                                    <p className="left-atc-items">Products and Categorization</p>
                                </NavLink>
                            </div>
                        )}
                    </>
                    <>
                        <div
                            className={isProductOpen ? 'background' : 'unselectedBackground'}
                            onClick={productToggle}>
                            <p
                                className={
                                    isProductOpen
                                        ? 'left-atc-sidebar cursor-pointer'
                                        : 'closed cursor-pointer'
                                }>
                                PRODUCT CONTENT
                            </p>
                        </div>
                        {isProductOpen && (
                            <div>
                                <NavLink to="/product-content">
                                    <p className="left-atc-items">Main Product details</p>
                                </NavLink>
                                <NavLink to="/accommodation-details">
                                    <p className="left-atc-items">Accommodation details</p>
                                </NavLink>
                                <NavLink to="/meals-and-drinks">
                                    <p className="left-atc-items">Meals & Drinks Details</p>
                                </NavLink>
                                <NavLink to="/guide">
                                    <p className="left-atc-items">Guide details</p>
                                </NavLink>
                                <NavLink to="/personal-care">
                                    <p className="left-atc-items">Personal Care details </p>
                                </NavLink>
                                <NavLink to="/other-vendor-details">
                                    <p className="left-atc-items">Other Vendor details</p>
                                </NavLink>
                            </div>
                        )}
                    </>

                    <>
                        <div
                            className={confirmOpen ? 'background' : 'unselectedBackground'}
                            onClick={confirmToggle}>
                            <p
                                className={
                                    confirmOpen
                                        ? 'left-atc-sidebar cursor-pointer'
                                        : 'closed cursor-pointer'
                                }>
                                CONFIRM CALENDAR
                            </p>
                        </div>
                        {confirmOpen && (
                            <div>
                                <p className="left-atc-items">Add more Products</p>
                                <Link to="/sync-availability">
                                    {' '}
                                    <p className="left-atc-items cursor-pointer">
                                        Sync Availability
                                    </p>
                                </Link>
                            </div>
                        )}
                    </>
                    <>
                        <div
                            className={confirmSubmit ? 'background' : 'unselectedBackground'}
                            onClick={submitToggle}>
                            <p
                                className={
                                    confirmSubmit
                                        ? 'left-atc-sidebar cursor-pointer'
                                        : 'closed cursor-pointer'
                                }>
                                FINISH
                            </p>
                        </div>
                        {confirmSubmit && (
                            <div>
                                <NavLink to="/publish">
                                    <p className="left-atc-items">Publish</p>
                                </NavLink>
                                <NavLink to="/review">
                                    <p className="left-atc-items">Submit for Review</p>
                                </NavLink>
                            </div>
                        )}
                    </>
                </div>
                <div className="col-md-9 background-calendar">
                    <div className="white-bg">{props.children}</div>
                </div>
                {/* <div  className="d-flex justify-content-end">
        <CalendarComponent/>


        </div> */}
            </div>
        </div>
    );
}

VendorDashboard.propTypes = {
    children: PropTypes.any
};

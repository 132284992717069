import React from 'react';
import PropTypes from 'prop-types';

export default function CurvedButton(props) {
    return (
        <button
            className={props.isSelected ? props.pressedbutton : props.newbutton}
            onClick={props.onClick}>
            {props.name}
        </button>
    );
}
CurvedButton.propTypes = {
    isSelected: PropTypes.bool,
    pressedbutton: PropTypes.string,
    newbutton: PropTypes.string,
    onClick: PropTypes.func,
    name: PropTypes.any
};

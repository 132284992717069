import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from './Accordion.jsx';
import Button from '../button/index';
import VendorDashboard from '../vendor-dashboard/index.js';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        padding: '89px 42px 0 42px'
    },
    line1: {
        fontSize: '26px',
        lineHeight: '32px',
        fontWeight: '600',
        color: '#333333'
    },
    line2: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        // paddingBottom: '3px',
        paddingBottom: '13px',
        color: '#333333'
    },
    line3: {
        background: '#E7D99F',
        opacity: '0.8',
        borderRadius: '8px',
        paddingTop: '26px',
        paddingLeft: '31px',
        paddingBottom: '42px',
        paddingRight: '29px'
    },
    line4: {
        marginTop: '70px',
        marginBottom: '43px'
    }
}));

export default function Transportation() {
    const classes = useStyles();

    return (
        <VendorDashboard>
            <Grid container className={classes.root}>
                <Grid item className={classes.line1}>
                    Tell us about your Transportation
                </Grid>
                <Grid item className={classes.line2}>
                    Be sure to enter all key attractions in your itinerary. This helps travelers
                    find and book your experience.
                </Grid>
                <Grid item className={classes.line3}>
                    <Accordion />
                </Grid>
                <Grid item className={classes.line4}>
                    <Button text="Update Calender &amp; Continue" />
                </Grid>
            </Grid>
        </VendorDashboard>
    );
}

import React, { useState, useEffect } from 'react';

export default function DepressableBox(props) {
    const [selected, setSelected] = useState(false);

    const handleSelect = () => {
        setSelected(!selected);
    };

    useEffect(() => {
        if (props.allSelected) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [props.allSelected]);

    return (
        <div onClick={handleSelect} style={selected ? styles.selected : styles.root}>
            {props.text}
        </div>
    );
}

const styles = {
    root: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '68px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    selected: {
        background: '#A2792C',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '68px',
        height: '48px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        color: '#FFFFFF'
    }
};

import { callApi, callGuestApi } from '../../utils/apiCaller';
import { Notification } from '../../components/atoms/notification';
import { loading_ } from 'store/actions';

export const LEGAL_CONTENT = 'LEGAL_CONTENT';
export const HOMEPAGE_CONTENT = 'HOMEPAGE_CONTENT';
export const FILE_UPLOAD = 'FILE_UPLOAD';
export const DELETE_FILE = 'DELETE_FILE';
export const GET_FILES = 'GET_FILES';
export const ADD_MEALS_AND_DRINKS = 'ADD_MEALS_AND_DRINKS';
export const MAIN_PRODUCT = 'MAIN_PRODUCT';
export const SET_ITINERARIES = 'SET_ITINERARIES';
export const CREATE_MAIN_PRODUCT = 'CREATE_MAIN_PRODUCT';
export const CREATE_ACCOMODATION = 'CREATE_ACCOMODATION';
export const UPDATE_MAIN_PRODUCT = 'UPDATE_MAIN_PRODUCT';
export const CREATE_ITINERARIES = 'CREATE_ITINERARIES';
export const UPDATE_ITINERARIES = 'UPDATE_ITINERARIES';
export const DELETE_ITINERARIES = 'DELETE_ITINERARIES';
export const EDIT_ITINERARIES = 'EDIT_ITINERARIES';
export const FILE_UPLOAD_PROGRESS = 'FILE_UPLOAD_PROGRESS';

export const getFiles_ = (data) => ({ type: GET_FILES, payload: data });
export const fileUpload_ = (data) => ({ type: FILE_UPLOAD, payload: data });
export const deleteFile_ = (data) => ({ type: DELETE_FILE, payload: data });
export const clearUploadedFile = () => ({ type: FILE_UPLOAD, payload: null });
export const setMealsAndDrinks = (details) => ({
    type: ADD_MEALS_AND_DRINKS,
    payload: details
});
export const setMainProduct = (details) => ({ type: MAIN_PRODUCT, payload: details });
export const getItineraries_ = (details) => ({ type: SET_ITINERARIES, payload: details });
export const createMainProduct_ = (details) => ({ type: CREATE_MAIN_PRODUCT, payload: details });
export const createAccomodation_ = (details) => ({ type: CREATE_ACCOMODATION, payload: details });
export const updateMainProduct_ = (details) => ({ type: UPDATE_MAIN_PRODUCT, payload: details });
export const createItineraries_ = (details) => ({ type: CREATE_ITINERARIES, payload: details });
export const updateItineraries_ = (details) => ({ type: UPDATE_ITINERARIES, payload: details });
export const deleteItineraries_ = (details) => ({ type: DELETE_ITINERARIES, payload: details });
export const editItineraries_ = (details) => ({ type: EDIT_ITINERARIES, payload: details });
export const fileUploadProgress_ = (details) => ({ type: FILE_UPLOAD_PROGRESS, payload: details });

export const fileUpload = (data) => {
    return (dispatch) => {
        dispatch(loading_(true));
        callApi('/media-library', 'POST', data, true, dispatch)
            .then((response) => {
                if (response?.status == 200) {
                    if (response?.data?.data) {
                        return dispatch(fileUpload_(response.data.data));
                    }
                } else {
                    dispatch(fileUpload_(response.data));
                }
                return Notification(
                    'success',
                    response?.data?.message || response?.message,
                    response.status
                );
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getFiles = (page = 1, perPage = 10) => {
    return (dispatch) => {
        dispatch(loading_(true));
        callApi(`/media-library?page=${page}&perPage=${perPage}`, 'GET')
            .then((response) => {
                if (response?.status === 200) {
                    if (response?.data?.data) {
                        return dispatch(getFiles_(response.data.data.data));
                    }
                } else {
                    return dispatch(getFiles_(response.data));
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};
export const getFilesType = (type, page = 1, perPage = 10) => {
    return (dispatch) => {
        dispatch(loading_(true));
        callApi(`/media-library?page=${page}&perPage=${perPage}&type=${type}`, 'GET')
            .then((response) => {
                if (response?.status === 200) {
                    if (response?.data?.data) {
                        return dispatch(getFiles_(response.data.data));
                    }
                } else {
                    return dispatch(getFiles_(response.data));
                }
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const onDeleteMedia = (id) => {
    return (dispatch) => {
        callApi(`/delete-media/${id}`, 'DELETE').then((response) => {
            if (response?.status === 200) {
                dispatch(deleteFile_(response.data.data));
            } else {
                dispatch(deleteFile_(response.data));
            }

            return Notification(
                'success',
                response?.data?.message || response?.message,
                response.status
            );
        });
    };
};
export const getItineraries = () => {
    return (dispatch) => {
        callApi(`/itineraries`, 'GET').then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(getItineraries_(response.data.data));
                }
            } else {
                //return dispatch(getItineraries_(response.data));
            }
        });
    };
};
export const createMainProduct = (data) => {
    return (dispatch) => {
        callApi(`/main-products`, 'POST', data).then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(createMainProduct_(response.data.data));
                }
            } else {
                dispatch(createMainProduct_(response.data));
                return Notification(
                    'error',
                    response.data.message || response?.message,
                    response.status
                );
            }
        });
    };
};
export const updateMainProduct = (id, data) => {
    return (dispatch) => {
        callApi(`/main-products/${id}`, 'PUT', data).then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(updateMainProduct_(response.data.data));
                }
            } else {
                return dispatch(updateMainProduct_(response.data));
            }

            return Notification(
                'success',
                response?.data?.message || response?.message,
                response.status
            );
        });
    };
};
export const createAccomodation = (data) => {
    return (dispatch) => {
        callApi(`/accomodations`, 'POST', data).then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(createAccomodation_(response.data.data));
                }
            } else {
                return dispatch(createAccomodation_(response.data));
            }

            return Notification(
                'success',
                response?.data?.message || response?.message,
                response.status
            );
        });
    };
};

export const createItineraries = (data) => {
    return (dispatch) => {
        callApi(`/itineraries`, 'POST', data).then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(createItineraries_(response.data.data));
                }
            } else {
                //return dispatch(createItineraries_(response.data));
            }

            // return Notification(
            //     'success',
            //     response?.data?.message || response?.message,
            //     response.status
            // );
        });
    };
};
export const updateItineraries = (id, data) => {
    return (dispatch) => {
        callApi(`/itineraries/${id}`, 'PUT', data).then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(updateItineraries_(response.data.data));
                }
            } else {
                //return dispatch(updateItineraries_(response.data));
            }

            // return Notification(
            //     'success',
            //     response?.data?.message || response?.message,
            //     response.status
            // );
        });
    };
};
export const adjustTimeItineraries = (id, data) => {
    return (dispatch) => {
        callApi(`/itineraries/adjust-time/${id}`, 'PUT', data).then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(updateItineraries_(response.data.data));
                }
            } else {
                //return dispatch(updateItineraries_(response.data));
            }
        });
    };
};
export const deleteItineraries = (id) => {
    return (dispatch) => {
        callApi(`/itineraries/${id}`, 'DELETE').then((response) => {
            if (response?.status === 200) {
                if (response?.data?.data) {
                    return dispatch(deleteItineraries_(response.data.data));
                }
            } else {
                //return dispatch(deleteItineraries_(response.data));
            }

            // return Notification(
            //     'success',
            //     response?.data?.message || response?.message,
            //     response.status
            // );
        });
    };
};

export const fetchLegalContent_ = (data) => {
    return {
        type: LEGAL_CONTENT,
        payload: data
    };
};
export const fetchLegalContent = () => {
    return (dispatch) => {
        callGuestApi('/legal-content', 'GET').then((response) => {
            if (response?.status == 200) {
                if (response?.data?.data) {
                    return dispatch(fetchLegalContent_(response.data.data));
                }
            } else {
                //toast.error(response.data[0].message);
            }
        });
    };
};

export const fetchhomepageContent_ = (data) => {
    return {
        type: HOMEPAGE_CONTENT,
        payload: data
    };
};

export const fetchhomepageContent = () => {
    return (dispatch) => {
        callApi('/home-page', 'GET').then((response) => {
            if (response?.status == 200) {
                if (response?.data?.data) {
                    return dispatch(fetchhomepageContent_(response.data.data));
                }
            } else {
                //toast.error(response.data[0].message);
            }
        });
    };
};

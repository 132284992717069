import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';
import {
    AddAdventureButton,
    AddOccassionButton,
    AddTopSightsButton,
    AddSeasonButton,
    AddTravellerInfoButton
} from 'components/atoms';

import {
    updateProductCalendarSeason,
    updateProductSights,
    updateProductTravellerInformation,
    updateProductAdventures,
    updateProductOccassions,
    deleteProductAdventures,
    deleteProductOccassions,
    deleteProductCalendarSeason,
    deleteProductSight,
    deleteProductTravellerInformation
} from 'store/actions';

import {
    CalendarSeasonDrawer,
    AddAdventureDrawer,
    AddOccassionDrawer,
    AddTopSightsDrawer,
    AddInformationDrawer
} from 'components/molecules';

import { validateRequiredFormField, mapSightsForResponse } from 'utils';

function ItineraryFormPageTwoPartTwo(props) {
    const {
        setState,
        updateProductCalendarSeason,
        updateProductAdventures,
        updateProductSights,
        updateProductOccassions,
        updateProductTravellerInformation,
        deleteProductAdventures,
        deleteProductOccassions,
        deleteProductCalendarSeason,
        deleteProductSight,
        deleteProductTravellerInformation,
        currentProduct
    } = props;
    const {
        calendar_season_type,
        calendar_season,
        isOpenCalendarSeason,
        adventure,
        sights,
        isOpenAddTopSights,
        isOpenAddAdventure,
        occassion,
        isOpenAddOccassion,
        information,
        // isRecommended,
        isOpenAddInformation
    } = props.state;

    const onOpenCalendarSeason = () => setState({ isOpenCalendarSeason: !isOpenCalendarSeason });

    const addCalendarSeason = (value) => {
        let data = {
            calendar_season: { ...value }
        };
        updateProductCalendarSeason({
            data,
            id: currentProduct.id
        }).then((res) => {
            if (res?.payload) setState({ calendar_season: value, isOpenCalendarSeason: false });
        });
    };

    const onOpenAddAdventure = () => setState({ isOpenAddAdventure: !isOpenAddAdventure });

    const onOpenAddOccassion = () => setState({ isOpenAddOccassion: !isOpenAddOccassion });

    const addProductAdventure = (value) => {
        let keys = Object.keys(value);
        if (keys.length > 0) {
            let mappedData = keys.map((k) => {
                return {
                    category: k,
                    sub_category: value[k]
                };
            });
            let data = {
                adventures: mappedData
            };
            updateProductAdventures({
                data,
                id: currentProduct.id
            }).then((res) => {
                if (res?.payload) setState({ adventure: mappedData });
            });
        }
    };

    const addProductOccassion = (value) => {
        let keys = Object.keys(value);
        if (keys.length > 0) {
            let mappedData = keys.map((k) => {
                return {
                    category: k,
                    sub_category: value[k]
                };
            });
            let data = {
                occassions: mappedData
            };
            updateProductOccassions({
                data,
                id: currentProduct.id
            }).then((res) => {
                if (res?.payload) setState({ occassion: mappedData });
            });
        }
    };

    const onOpenAddTopSights = () => setState({ isOpenAddTopSights: !isOpenAddTopSights });

    const addTopSights = (value) => {
        if (Array.isArray(value) && value.length > 0) {
            let mappedSights = mapSightsForResponse(value);

            updateProductSights({
                data: { sights: mappedSights },
                id: currentProduct.id
            }).then((res) => {
                if (res?.payload) setState({ sights: value, isOpenAddTopSights: false });
            });
        }
    };

    const onOpenAddInformation = () => setState({ isOpenAddInformation: !isOpenAddInformation });

    const addTravellerInformation = (value) => {
        let data = {
            traveller_information: {
                ...value
            }
        };
        updateProductTravellerInformation({
            data,
            id: currentProduct.id
        }).then((res) => {
            if (res?.payload) setState({ information: value });
        });
    };

    // const onRecommended = (value) => setState({ isRecommended: value });

    const deleteProductAdventuresInfo = () => {
        deleteProductAdventures(currentProduct.id).then((res) => {
            if (res === true) setState({ adventure: [] });
        });
    };

    const deleteProductOccassionsInfo = () => {
        deleteProductOccassions(currentProduct.id).then((res) => {
            if (res === true) setState({ occassion: [] });
        });
    };

    const deleteProductCalendarSeasonInfo = () => {
        deleteProductCalendarSeason(currentProduct.id).then((res) => {
            if (res === true) setState({ calendar_season: null, calendar_season_type: null });
        });
    };

    const deleteProductSightInfo = () => {
        deleteProductSight(currentProduct.id).then((res) => {
            if (res === true) setState({ sights: [] });
        });
    };

    const deleteProductTravellerInfo = () => {
        deleteProductTravellerInformation(currentProduct.id).then((res) => {
            if (res === true) setState({ information: null });
        });
    };

    return (
        <>
            <Form.Item
                name="calendar_season"
                className="mb-0"
                // rules={[
                //     {
                //         validator: async () =>
                //             validateRequiredFormField({
                //                 data: calendar_season,
                //                 fieldType: 'OBJECT'
                //             })
                //     }]}
            >
                <AddSeasonButton
                    name="Calendar Season"
                    data={calendar_season_type ? calendar_season_type : []}
                    onClick={onOpenCalendarSeason}
                    onDelete={deleteProductCalendarSeasonInfo}
                />
            </Form.Item>

            <CalendarSeasonDrawer
                itineraries={props.itineraries}
                data={calendar_season}
                onSave={(value) => addCalendarSeason(value)}
                onSaveType={(value) => {
                    setState({ calendar_season_type: value });
                }}
                isOpen={isOpenCalendarSeason}
                onClose={onOpenCalendarSeason}
                hideImport
            />

            <Form.Item
                name="adventureCategory"
                className="mb-0"
                // rules={[
                //     {
                //         required: true,
                //         message: 'Please fill this field in'
                //     }]}
            >
                <AddAdventureButton
                    name="Type of Adventure on this product"
                    data={adventure ? adventure : []}
                    onClick={onOpenAddAdventure}
                    onDelete={deleteProductAdventuresInfo}
                />
            </Form.Item>

            <AddAdventureDrawer
                itineraries={props.itineraries}
                data={adventure}
                isOpen={isOpenAddAdventure}
                onClose={onOpenAddAdventure}
                // onChangeCategory={(value) =>
                //     setState({ adventureCategory: value })
                // }
                onSave={(value) => addProductAdventure(value)}
                hideImport
                validateRequiredFormField={validateRequiredFormField}
            />

            <Form.Item name="sights" className="mb-0">
                <AddTopSightsButton
                    data={
                        Array.isArray(sights) && sights.length > 0
                            ? mapSightsForResponse(sights)
                            : []
                    }
                    name="Top Sights to see on this product"
                    onClick={onOpenAddTopSights}
                    onDelete={deleteProductSightInfo}
                />
            </Form.Item>

            <AddTopSightsDrawer
                itineraries={props.itineraries}
                data={sights}
                onSave={(value) => addTopSights(value)}
                isOpen={isOpenAddTopSights}
                onClose={onOpenAddTopSights}
                hideImport
                validateRequiredFormField={validateRequiredFormField}
            />

            <Form.Item name="occassions" className="mb-0">
                <AddOccassionButton
                    name="Type of Occassions for this product"
                    data={occassion ? occassion : []}
                    onClick={onOpenAddOccassion}
                    onDelete={deleteProductOccassionsInfo}
                />
            </Form.Item>

            <AddOccassionDrawer
                itineraries={props.itineraries}
                data={occassion}
                isOpen={isOpenAddOccassion}
                onClose={onOpenAddOccassion}
                onSave={(value) => addProductOccassion(value)}
                hideImport
                validateRequiredFormField={validateRequiredFormField}
            />

            {currentProduct?.product_category_id === 1 && (
                <Form.Item>
                    <AddTravellerInfoButton
                        name="Information travellers need from you"
                        onClick={onOpenAddInformation}
                        data={information}
                        onEdit={onOpenAddInformation}
                        onDelete={deleteProductTravellerInfo}
                    />
                </Form.Item>
            )}

            <AddInformationDrawer
                isOpen={isOpenAddInformation}
                onClose={onOpenAddInformation}
                data={information}
                itineraries={props.itineraries}
                onSave={(value) => addTravellerInformation(value)}
                hideImport
            />

            {/* {(currentProduct && !currentProduct.?.is_leading_product)  && (
                <>
                    <p>Can travelers book this activity without accomodation?</p>

                    <p style={{ color: '#A2792C' }}>
                        Yes is recommended if travelers can take part in this activity (Itineraries)
                        without accomodation.
                    </p>

                    <div className="group-radio">
                        <div
                            className="cursor-pointer radio-btn"
                            style={{
                                backgroundColor: isRecommended ? '#a2792c' : '#fff',
                                color: isRecommended ? '#fff' : '#000'
                            }}
                            tabIndex="0"
                            role="button"
                            onKeyDown={() => {
                                onRecommended(true);
                            }}
                            onClick={() => {
                                onRecommended(true);
                            }}>
                            Yes
                        </div>
                        <div
                            className="cursor-pointer radio-btn"
                            style={{
                                backgroundColor:
                                    !isRecommended && isRecommended !== null ? '#a2792c' : '#fff',
                                color: !isRecommended && isRecommended !== null ? '#fff' : '#000'
                            }}
                            tabIndex="0"
                            role="button"
                            onKeyDown={() => {
                                onRecommended(false);
                            }}
                            onClick={() => {
                                onRecommended(false);
                            }}>
                            No
                        </div>
                    </div>
                </>
            )} */}
        </>
    );
}

ItineraryFormPageTwoPartTwo.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    props: PropTypes.object,
    updateProductCalendarSeason: PropTypes.func,
    updateProductAdventures: PropTypes.func,
    updateProductOccassions: PropTypes.func,
    updateProductSights: PropTypes.func,
    updateProductTravellerInformation: PropTypes.func,
    itineraries: PropTypes.any,
    deleteProductAdventures: PropTypes.func,
    deleteProductOccassions: PropTypes.func,
    deleteProductCalendarSeason: PropTypes.func,
    deleteProductSight: PropTypes.func,
    deleteProductTravellerInformation: PropTypes.func,
    currentProduct: PropTypes.object
};

const mapStateToProps = (state) => ({
    currentProduct: state.product.currentProduct
});

const mapDispatch = (dispatch) => ({
    updateProductCalendarSeason: (data) => dispatch(updateProductCalendarSeason(data)),
    updateProductAdventures: (data) => dispatch(updateProductAdventures(data)),
    updateProductSights: (data) => dispatch(updateProductSights(data)),
    updateProductOccassions: (data) => dispatch(updateProductOccassions(data)),
    updateProductTravellerInformation: (data) => dispatch(updateProductTravellerInformation(data)),
    deleteProductAdventures: (id) => dispatch(deleteProductAdventures(id)),
    deleteProductOccassions: (id) => dispatch(deleteProductOccassions(id)),
    deleteProductCalendarSeason: (id) => dispatch(deleteProductCalendarSeason(id)),
    deleteProductSight: (id) => dispatch(deleteProductSight(id)),
    deleteProductTravellerInformation: (id) => dispatch(deleteProductTravellerInformation(id))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ItineraryFormPageTwoPartTwo));

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';
import WarningIcon from 'assets/images/warning-icon.svg';
import './index.scss';

const ReactModal = ({ onOk, title, description, onClose, isOpen }) => {
    return (
        <Modal isOpen={isOpen} centered size="lg" zIndex={1501}>
            <ModalBody className="react-modal">
                <img src={WarningIcon} alt="" />
                <h3 className="title">{title}</h3>
                <p className="description m-0">{description}</p>

                <div className="footer d-flex flex-wrap justify-content-center gap-3 mt-3">
                    <Button color="primary" onClick={() => onOk()}>
                        YES
                    </Button>
                    <Button color="dark" onClick={() => onClose()}>
                        NO
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

ReactModal.propTypes = {
    onOk: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool
};

export default ReactModal;

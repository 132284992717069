import {
    GET_VENDOR_SUBSCRIPTION,
    TOP_UP_PAY_NOW,
    GET_ALL_PLANS,
    TOOGLE_UPGRADE_PLAN_DRAWER,
    UPDATE_VENDOR_SUBSCRIPTION,
    GET_UPCOMING_SUBSCRIPTION,
    SHOW_DRAFT_CALENDAR_POPUP,
    ALL_LIVE_CALENDARS,
    PREFERED_DISBALE_CALENDARS,
    INITIALIZE_SUBSCRIPTION,
    INITIALIZE_TOKEN_TOP_UP,
    GET_USER_CARDS,
    ACC_LOADING,
    All_TRAVELLERS
} from '../types/accessCalendarTypes';

const initialState = {
    accessCustomercalendar: false,
    tokenAmount: 5,
    totalTokenAmount: 0,
    availableToken: 10,
    mainProductPrice: 400,
    AddTopUp: false,
    UpgradePlan: false,
    checkPaymentInf: false,
    paymentInfo: {
        title: 'Payment for main product price disabled',
        discription:
            'Select this if your customer already paid for the main product and you want to display the main product for information only without the price.'
    },
    productInfos: [
        {
            title: 'Add-on Product',
            subtitle: 'Meals & Drinks - Palera Restaurant and Dinner',
            price: 80
        },
        {
            title: 'Add-on Product',
            subtitle: 'Meals & Drinks - Palera Restaurant and Dinner',
            price: 60
        },
        {
            title: 'Add-on Product',
            subtitle: 'Meals & Drinks - Palera Restaurant and Dinner',
            price: 20
        },
        {
            title: 'Add-on Product',
            subtitle: 'Meals & Drinks - Palera Restaurant and Dinner',
            price: 40
        }
    ],
    totalRevenu: 0,
    startDate: '',
    endDate: '',
    currentPlan: 'FREE',
    toggleAddTopUpPopover: false,
    toggleUpgradePlanPopover: false,
    liveCalendar: 1,
    vendorSubscription: {},
    allPlans: [],
    upcomingSubscription: {},
    upgradePlanDrawer: false,
    showDowngradeCalendarPopup: false,
    liveCalendars: [],
    preferedDisableCalendars: [],
    initailizeSubscription: {},
    initailizeTokenTopup: {},
    userCards: [],
    acc_loading: false,
    allTravellers: []
};

const accessCalendarReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'TOGGLE_ACCESS_CALENDAR_MODAL': {
            return {
                ...state,
                accessCustomercalendar: action.payload
            };
        }

        case 'TOGGLE_ADD_TOP_UP': {
            return {
                ...state,
                AddTopUp: action.payload
            };
        }

        case 'SET_CURRENT_PLAN': {
            return {
                ...state,
                currentPlan: action.payload.toUpperCase()
            };
        }

        case 'REDUCED_TOKENS': {
            return {
                ...state,
                availableToken: action.payload
            };
        }

        case 'TOGGLE_ADD_TOP_UP_POPOVER': {
            return {
                ...state,
                toggleAddTopUpPopover: action.payload
            };
        }
        case 'REDUCE_LIVE_CALENDAR': {
            return {
                ...state,
                liveCalendar: state.liveCalendar - 1
            };
        }

        case 'TOGGLE_UPGRADE_PLAN_POPOVER': {
            return {
                ...state,
                toggleUpgradePlanPopover: action.payload
            };
        }

        case 'SET_CALENDAR_DATE': {
            return {
                ...state,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate
            };
        }

        case 'TOGGLE_UPGRADE_PLAN': {
            return {
                ...state,
                UpgradePlan: action.payload
            };
        }
        case 'TOGGLE_CHECK_PAYMENT_INFO': {
            if (action.payload) {
                return {
                    ...state,
                    checkPaymentInf: action.payload,
                    paymentInfo: {
                        title: 'Payment for main product price enabled',
                        discription: 'Select this if your customer not paid for the main product.'
                    }
                };
            } else {
                return {
                    ...state,
                    checkPaymentInf: action.payload,
                    paymentInfo: {
                        title: 'Payment for main product price disabled',
                        discription:
                            'Select this if your customer already paid for the main product and you want to display the main product for information only without the price.'
                    }
                };
            }
        }
        case 'SET_TOTAL_REVENU': {
            return {
                ...state,
                totalRevenu: action.payload
            };
        }
        case GET_VENDOR_SUBSCRIPTION: {
            return {
                ...state,
                vendorSubscription: action.payload
            };
        }
        case INITIALIZE_SUBSCRIPTION: {
            return {
                ...state,
                initailizeSubscription: action.payload
            };
        }
        case INITIALIZE_TOKEN_TOP_UP: {
            return {
                ...state,
                initailizeTokenTopup: action.payload
            };
        }
        case GET_USER_CARDS: {
            return {
                ...state,
                userCards: action.payload
            };
        }
        case TOP_UP_PAY_NOW: {
            return {
                ...state,
                vendorSubscription: { ...state.vendorSubscription, available_token: action.payload }
            };
        }
        case GET_ALL_PLANS: {
            return {
                ...state,
                allPlans: action.payload
            };
        }
        case GET_UPCOMING_SUBSCRIPTION: {
            return {
                ...state,
                upcomingSubscription: action.payload
            };
        }
        case TOOGLE_UPGRADE_PLAN_DRAWER: {
            return {
                ...state,
                upgradePlanDrawer: action.payload
            };
        }
        case UPDATE_VENDOR_SUBSCRIPTION: {
            return {
                ...state,
                vendorSubscription: action.payload
            };
        }
        case SHOW_DRAFT_CALENDAR_POPUP: {
            return {
                ...state,
                showDowngradeCalendarPopup: !state.showDowngradeCalendarPopup
            };
        }
        case ALL_LIVE_CALENDARS: {
            return {
                ...state,
                liveCalendars: action.payload
            };
        }
        case PREFERED_DISBALE_CALENDARS: {
            return {
                ...state,
                preferedDisableCalendars: action.payload
            };
        }
        case ACC_LOADING:
            return { ...state, acc_loading: action.payload };

        case All_TRAVELLERS:
            return { ...state, allTravellers: action.payload };
        default:
            return {
                ...state
            };
    }
};

export default accessCalendarReducer;

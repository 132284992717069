/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

function SelectorOption(props) {
    const { handleSelect, selected, icon, name, height } = props;
    return (
        <div
            style={{ [height && 'height']: height }}
            onClick={() => handleSelect(name)}
            className={`atc-selector__option ${
                selected.includes(name) ? 'atc-selector__option--selected' : ''
            }`}>
            <img alt="" src={icon} />
            <span>{name}</span>
        </div>
    );
}

SelectorOption.propTypes = {
    handleSelect: PropTypes.func,
    selected: PropTypes.bool,
    name: PropTypes.string,
    icon: PropTypes.string,
    height: PropTypes.any
};

export default SelectorOption;

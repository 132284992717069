import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import SharingGroupsWithSupplement from './SharingGroupsWithSupplement.jsx';
import PerPerson from './PerPerson.jsx';
import DamiBtn from './DamiBtn.jsx';
import GroupBooking from './GroupBooking.jsx';
import FestiveSeason from './FestiveSeason.jsx';
import VendorDashboard from '../vendor-dashboard/index.js';
import Feedback from '../../../molecules/user-feedback';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: '600',
        paddingTop: '80px',
        paddingLeft: '32px'
    },
    line1: {
        fontSize: '20px',
        lineHeight: '24px',
        marginBottom: '9px'
    },
    line2: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px'
    },
    line3: {
        fontWeight: '600',
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        textTransform: 'capitalize',

        color: '#333333',
        marginTop: '36px',
        marginBottom: '31px'
    },
    line4: {
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        color: '#333333',
        marginBottom: '13px'
    },
    line5: {
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */

        color: '#333333',
        marginBottom: '20px',
        fontWeight: 'normal'
    },
    line6: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',

        color: '#333333',
        marginTop: '9px',
        marginBottom: '36px'
    },
    line7: {
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        textTransform: 'capitalize',

        color: '#333333',
        marginTop: '36px',
        marginBottom: '13px'
    },
    line8: {
        marginBottom: '16px',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */

        color: '#333333'
    },
    line9: {
        marginTop: '47.5px',
        marginBottom: '27.5px'
    },
    line10: {
        fontStyle: 'normal',
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '24px',

        color: '#333333'
    },
    lastBtnGroup: {
        marginTop: '40px',
        marginBottom: '93px'
    },
    line11: {
        fontWeight: '600',
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        color: '#333333'
    },
    line12: {
        fontWeight: '600',
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        color: '#333333',
        marginTop: '38px',
        marginBottom: '9px'
    },
    line13: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',

        color: '#333333'
    }
}));

export default function AccomodationPricing() {
    const classes = useStyles();

    return (
        <VendorDashboard>
            <Grid container className={classes.root} direction="column">
                <Grid item className={classes.line1}>
                    Activities pricing details including accomodations
                </Grid>
                <Grid item className={classes.line2}>
                    Enter the additional price you will charge travelers for booking your activity
                    with the your accomodationa per person (PP)
                </Grid>
                <Grid item className={classes.line3}>
                    Enter additional price for your Accomodations
                </Grid>
                <Grid item className={classes.line4}>
                    Per Person Sharing
                </Grid>
                <Grid item className={classes.line5}>
                    This is the price your enter below is for a person sharing a room with another
                    person.
                </Grid>
                <Grid item>
                    <SharingGroupsWithSupplement />
                </Grid>
                <Grid item className={classes.line6}>
                    Single Suplement - An extra charge applied to a traveler who travels alone but
                    will use a room and facilities that cater for two.
                </Grid>
                <Grid item className={classes.line7}>
                    Person Single / Single Room Occupancy
                </Grid>
                <Grid item className={classes.line8}>
                    This means that the price you enter below is for one person in a room.
                </Grid>
                <Grid item>
                    <PerPerson />
                </Grid>
                <Grid item className={classes.line9}>
                    <hr />
                </Grid>
                <Grid item className={classes.line10}>
                    Add Group Booking Price
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={3} className={classes.line11}>
                            <div className={classes.line12}>
                                Reduce The ‘per person’ rate above by
                            </div>
                            <div className={classes.line13}>
                                Recommeded if group price was provided for the activities
                            </div>
                        </Grid>
                        <Grid item sm={12} md={9}>
                            <GroupBooking />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item className={classes.line9}>
                    <hr />
                </Grid>
                <Grid item className={classes.line10}>
                    Add Price for Peak/Festive Season Supplement
                </Grid>
                <Grid item>
                    <FestiveSeason />
                </Grid>
                <Grid item className={classes.line9}>
                    <hr />
                </Grid>
                <Grid item container justify="space-between" className={classes.lastBtnGroup}>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item>
                                <DamiBtn text="Save" />
                            </Grid>
                            <Grid item>
                                <DamiBtn
                                    text="Cancel"
                                    customStyle={{ background: '#FFFFFF', color: '#A2792C' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Feedback />
                    </Grid>
                </Grid>
            </Grid>
        </VendorDashboard>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableHead from '@material-ui/core/TableHead';
import SharingGroupRow from '../sharing-group-row';
import SharingGroupRowSupplement from '../sharing-group-row-supplemnt';
import { Col, Row } from 'reactstrap';
import Proptypes from 'prop-types';

const useStyles = makeStyles({
    table: {
        width: 'max-content'
    },
    title: {
        marginLeft: -20
    },
    title10: {
        marginLeft: -14
    },
    accomodationTitle: {
        marginLeft: 36
    }
});

export default function CustomizedTables({ isSupplement = false, type, onChange, data }) {
    const classes = useStyles();
    return (
        <>
            <Row>
                <Col sm={3} />

                <Col align="left">
                    <div
                        className={
                            type === 'accomodation' && !isSupplement
                                ? classes.accomodationTitle
                                : classes.title
                        }>
                        {type === 'product_supplement' || type === 'product_accomodation'
                            ? 'Product Adult Price'
                            : 'Accomodation Price'}
                    </div>
                </Col>

                {type !== 'accomodation' && (
                    <Col align="left">
                        {type === 'product_accomodation' || type === 'accomodation' ? (
                            <div
                                className={
                                    type !== 'accomodation' ? classes.title10 : { marginLeft: 0 }
                                }>
                                Accomodation Price
                            </div>
                        ) : (
                            'Single Suplement'
                        )}
                    </Col>
                )}
            </Row>
            {['Double', 'Twin Room'].map((each, index) =>
                !isSupplement ? (
                    <SharingGroupRow
                        key={index}
                        index={index}
                        groupName={each}
                        productPrice={
                            index === 0 ? data.double.productPrice : data.twin.productPrice
                        }
                        secondPrice={index === 0 ? data.double.secondPrice : data.twin.secondPrice}
                        onChange={onChange}
                        type={type}
                    />
                ) : (
                    <SharingGroupRowSupplement
                        key={index}
                        index={index}
                        groupName={each}
                        productPrice={
                            index === 0 ? data.double.productPrice : data.twin.productPrice
                        }
                        secondPrice={index === 0 ? data.double.secondPrice : data.twin.secondPrice}
                        singleSupplement={
                            index === 0 ? data.double.singleSupplement : data.twin.singleSupplement
                        }
                        onChange={onChange}
                        type={type}
                    />
                )
            )}
        </>
    );
}
CustomizedTables.propTypes = {
    isSupplement: Proptypes.bool,
    onSave: Proptypes.func,
    data: Proptypes.object,
    type: Proptypes.string,
    onChange: Proptypes.any
};

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Legend from './Legend.jsx';
import VerticalBarChart from './VerticalBarChart.jsx';

const legendItems = [
    {
        color: '#FF3D00',
        text: 'Entry Barrier'
    },
    {
        color: '#4CDED5',
        text: 'Actual Sales'
    },
    {
        color: '#345BC8',
        text: 'Projected'
    },
    {
        color: '#A2792C',
        text: 'Av. Customer Budget'
    }
];

export default function Revenue(props) {
    const useStyles = makeStyles((theme) => ({
        root: {
            background: '#FFFFFF',
            boxShadow: '0px 4px 4px rgba(151, 151, 151, 0.22)',
            borderRadius: '4px',
            padding: '21px 26px',
            height: '100%'
        },
        title: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: '#333333',
            marginBottom: '5px'
        },
        subTitle: {
            fontWeight: '500',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: 'rgba(51, 51, 51, 0.63)',
            marginBottom: '21px'
        },
        metric: {
            fontWeight: '600',
            fontSize: '12px',
            lineHeight: '21px',
            /* identical to box height, or 175% */
            letterSpacing: '0.1px',
            color: 'rgba(51, 51, 51, 0.63)'
        },
        metricValue: {
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '24px'
        }
    }));

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.title}>Revenue Projection</div>
            <div>
                <Legend items={legendItems} />
            </div>
            <div
                style={{
                    marginTop: '30px'
                }}>
                <VerticalBarChart />
            </div>
        </div>
    );
}

import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Feedback, ConfirmationModal } from 'components/molecules';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getCalendarsById } from 'store/actions';
import { ComponentPermissionContext } from 'context/rolesandpermission';

const AddProductsInitModal = (props) => {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const {
        isModalOpen,
        setModalOpenState,
        productsAvailabilityType,
        setAddProductInitModalData,
        setAddProductsProductTypeModal,
        addProductInitModalData,
        getCalendarsById,
        get_calendar_data
    } = props;
    const [addProductsInitForm] = Form.useForm();
    const [productsAvailability, setProductsAvailability] = useState();
    const [isConfirmationModalOpen, setConfirmationModalOpenState] = useState(false);

    useEffect(() => {
        if (addProductInitModalData === null) {
            addProductsInitForm.resetFields();
            setProductsAvailability('');
        }
    }, [addProductInitModalData]);

    return (
        <>
            <Modal
                size="lg"
                centered
                isOpen={isModalOpen}
                toggle={() => {
                    setModalOpenState(!isModalOpen);
                    //Set data for parent component
                    setAddProductInitModalData(null);
                }}>
                <Form
                    className={`d-flex flex-column h-100 ${assignComponentPermission(
                        'product-update'
                    )}`}
                    form={addProductsInitForm}>
                    <ModalHeader>Add Products</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col lg={6}>
                                <Form.Item
                                    value={productsAvailability}
                                    name="select_products_availability"
                                    rules={[{ required: true, message: 'Please select one' }]}>
                                    <Select
                                        className="form-control-alt d-block"
                                        dropdownClassName="type-rich"
                                        size="large"
                                        placeholder="Select One"
                                        onChange={(value) => {
                                            setProductsAvailability(value);
                                        }}
                                        // getPopupContainer={(trigger) => trigger.parentElement}
                                    >
                                        {productsAvailabilityType.map((e, key) => {
                                            return (
                                                <Select.Option key={key} value={e.value}>
                                                    {e.name}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="submit"
                            onClick={() => {
                                //Open next step modal
                                if (productsAvailability) {
                                    let selected = productsAvailabilityType.filter(
                                        (i) => i.value === productsAvailability
                                    );
                                    setAddProductInitModalData(selected[0]);
                                    setModalOpenState(false);
                                    setAddProductsProductTypeModal(true);
                                }
                            }}>
                            Next
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="button"
                            onClick={() => setConfirmationModalOpenState(true)}>
                            Cancel
                        </Button>
                        <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                    </ModalFooter>
                </Form>
            </Modal>
            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={setConfirmationModalOpenState}
                description="you want to cancel"
                handleConfirmation={() => {
                    setModalOpenState(false);
                    //Set data for parent component
                    setAddProductInitModalData(null);
                    setConfirmationModalOpenState(false);
                    getCalendarsById(get_calendar_data.data.id);
                }}
            />
        </>
    );
};
const mapStateToProps = (state) => ({
    get_calendar_data: state.calendar.get_calendar_data
});
const mapDispatch = (dispatch) => ({
    getCalendarsById: (id) => dispatch(getCalendarsById(id))
});
AddProductsInitModal.propTypes = {
    isModalOpen: PropTypes.any,
    setModalOpenState: PropTypes.func,
    productsAvailabilityType: PropTypes.any,
    setAddProductInitModalData: PropTypes.func,
    setAddProductsProductTypeModal: PropTypes.func,
    addProductInitModalData: PropTypes.any,
    getCalendarsById: PropTypes.func,
    get_calendar_data: PropTypes.object
};
export default withRouter(connect(mapStateToProps, mapDispatch)(AddProductsInitModal));

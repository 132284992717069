import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { TopSightIcon } from 'components/atoms';

const EventOccassions = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<TopSightIcon />}
            panelTitle="Occassions"
            isEditable={true}
            onEdit={onEdit}>
            <div className="event-occassions">
                {product?.occassions?.length > 0 &&
                    product.occassions.map((item) => (
                        <>
                            <div className="occassion-row">
                                <div>
                                    <p className="fs-20 fw-500 text-uppercase mb-2">
                                        {item.category}
                                    </p>
                                    <Row className="row-cols-1 row-cols-md-3">
                                        {item.sub_category?.length > 0 ? (
                                            item.sub_category.map((item, index) => (
                                                <SightItem key={index} item={item} index={index} />
                                            ))
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </div>
                            </div>
                        </>
                    ))}
            </div>
        </DetailedPanel>
    );
};

const SightItem = ({ item, index }) => {
    return (
        <Col key={index}>
            <Row>
                <Col xs={4} md={12}>
                    <img className={`meals-drinks-img`} src={item?.image_url} alt="" />
                </Col>
                <Col xs={8} md={12}>
                    <div className="fs-18 fw-500">{item?.name}</div>
                    <div className="fs-13 lh-1-3">{item?.frequency}</div>
                </Col>
            </Row>
        </Col>
    );
};

SightItem.propTypes = {
    item: PropTypes.any,
    index: PropTypes.any
};

EventOccassions.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventOccassions;

import React from 'react';
import { Form, InputNumber } from 'antd';
import Proptypes from 'prop-types';
import './index.scss';

const MinMaxAccomodationFields = ({
    minAccomodation,
    maxAccomodation,
    setMinAccomodation,
    setMaxAccomodation,
    name
}) => {
    return (
        <div className="min-max-accomodation-inputs">
            <p style={{ marginRight: 20 }}>
                Enter the minimum number of accomodations allowed in a single booking
            </p>
            <Form.Item
                name={`${name}minAccomodation`}
                rules={[
                    {
                        required: !minAccomodation,
                        message: 'Please add min accommodations'
                    }
                ]}>
                <div>
                    <InputNumber
                        className="allowed-number travellers-min-max-input"
                        min={1}
                        value={minAccomodation}
                        max={maxAccomodation}
                        onChange={(value) => {
                            setMinAccomodation(value);
                        }}
                    />
                </div>
            </Form.Item>
            <p>Enter the maximum number of accomodations allowed in a single booking</p>
            <Form.Item
                name={`${name}maxAccomodation`}
                rules={[
                    {
                        required: !maxAccomodation,
                        message: 'Please add max accommodations'
                    }
                ]}>
                <div>
                    <InputNumber
                        className="allowed-number travellers-min-max-input"
                        min={minAccomodation || 1}
                        value={maxAccomodation}
                        onChange={(value) => {
                            setMaxAccomodation(value);
                        }}
                    />
                </div>
            </Form.Item>
        </div>
    );
};

MinMaxAccomodationFields.propTypes = {
    minAccomodation: Proptypes.number,
    maxAccomodation: Proptypes.number,
    setMinAccomodation: Proptypes.func,
    setMaxAccomodation: Proptypes.func,
    name: Proptypes.string
};

export default MinMaxAccomodationFields;

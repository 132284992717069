/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import { ConfirmationModal } from 'components/molecules';
export default function AddCovidButton(props) {
    const { add = 'Add', moreInfo = true } = props;
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={() => setIsConfirmationModalOpen(true)}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button
                        color="link"
                        onClick={() => {
                            if (props.isAddDisabled) {
                                props.toggleShowValidation();
                            } else {
                                props.onClick();
                            }
                        }}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                )}
            </div>
            {props.data ? (
                <Row className="actions">
                    <Col style={{ paddingLeft: 0 }} sm={10}>
                        {Array.isArray(props.data?.covid_info) &&
                            props.data.covid_info.map((res, i) =>
                                i === props.data.length - 1 ? res : `${res}, `
                            )}
                    </Col>
                    <Col style={{ paddingLeft: 0 }} sm={10}>
                        {props.data?.description}
                    </Col>
                </Row>
            ) : null}

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setIsConfirmationModalOpen(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    setIsConfirmationModalOpen(false);
                    props.onDelete();
                }}
            />
        </div>
    );
}
AddCovidButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any,
    isAddDisabled: Proptypes.bool,
    toggleShowValidation: Proptypes.func
};

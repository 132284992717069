import React, { Component } from 'react';
import Details from '../../../organisms/guide/details/index';
import VendorDashboard from '../vendor-dashboard';

export default class GuideDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <VendorDashboard>
                <Details />
            </VendorDashboard>
        );
    }
}

import React from 'react';
//import { BasicTable } from 'components/molecules';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

const ProductViewTab = (props) => {
    const { productViewColumns, calendarBookingsData, viewTab } = props;
    const getProductType = (type) => {
        let newType = type;
        if (type == 'main_product') {
            newType = 'Main Product';
        } else if (type == 'addon_product') {
            newType = 'Add-On Product';
        } else if (type == 'package') {
            newType = 'Package';
        }
        return newType;
    };
    let tableData = [];
    if (calendarBookingsData && calendarBookingsData.length > 0) {
        tableData = calendarBookingsData.map((item, index) => {
            return {
                key: index + '-' + item?.product_id,
                product_date: moment(item?.booking_date).format('DD/MM/YY'),
                product_title: item?.title,
                product_sub_cat: item?.sub_category,
                calendar_title: item?.calendar_title ? item?.calendar_title : '',
                product_type: getProductType(item?.product_type),
                id: item?.product_id,
                no_of_trvellers: item?.no_of_travellers ? item?.no_of_travellers : 0,
                lead_travellers: item?.lead_traveller
                    ? `${item?.lead_traveller?.first_name} ${item?.lead_traveller?.last_name}`
                    : '',
                calendar_weeks: item?.weeks,
                calendar_days: item?.days,
                lead_traveller_id: item?.lead_traveller ? item?.lead_traveller?.userid : 0,
                calendar_id: item?.calendar_id,
                calendar_ref_id: item?.calendar_ref,
                age_group: item?.age_group,
                lead_traveller_info: item?.lead_traveller
            };
        });
    }
    return (
        <>
            {/* {currentWindowWidth >= 768 && filtersVisibility ? allFiltersWrap : ''} */}
            {viewTab == 'product' && (
                <Table
                    className="customer-calendars-table _colored"
                    columns={productViewColumns}
                    dataSource={tableData}
                    pagination={false}
                    tableLayout="auto"
                    scroll={{
                        y:
                            window.innerWidth >= 1200
                                ? 'calc(100vh - 380px)'
                                : window.innerWidth >= 768
                                ? 'calc(100vh - 340px)'
                                : 'calc(100vh - 294px)',
                        x: 'max-content'
                    }}
                />
            )}
        </>
    );
};

ProductViewTab.propTypes = {
    productViewColumns: PropTypes.array,
    productViewData: PropTypes.array,
    currentWindowWidth: PropTypes.number,
    calendarBookingsData: PropTypes.any,
    viewTab: PropTypes.string
};

export default ProductViewTab;

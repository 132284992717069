import React from 'react';
import { Row } from 'reactstrap';
import { Input, Tooltip } from 'antd';
import SynkProductDrawer from '../../../molecules/modals/synk-products-modal';
import PropTypes from 'prop-types';

const synkModalContent = (
    <div className="d-flex flex-column align-items-center p-2">
        <span style={{ color: 'black' }} className="text-center fs-16 fw-400">
            You can find instructions on how to export your calendar in an iCal file format in the
            help section of the other site where your property is listed.
        </span>
    </div>
);

const SynkProduct = ({ visible, toggleDrawer }) => {
    return (
        <SynkProductDrawer
            visible={visible}
            onClose={toggleDrawer}
            productTitle={`kruger national park safari tour, South Africa`}>
            <div className="ff-special ml-3 mr-3">
                <Row style={{ justifyContent: 'flex-end' }} className="mb-1 mt-2 mr-2">
                    <button
                        className="modal-button"
                        onClick={() => this.toggleModal('exportSyncProductConfirmModal')}>
                        Export
                    </button>
                </Row>
                <Row className="mb-4">
                    <span className="fs-16 fw-500 mb-2 modal-lable">Website Name</span>
                    <Input placeholder={'Website Name'} />
                </Row>
                <Row className="mb-2">
                    <span className="fs-16 fw-500 mb-2 modal-lable">Paste your iCal link</span>
                    <Input placeholder={'Paste here'} />
                    <div style={{ width: '100%' }}>
                        <Tooltip title={synkModalContent} placement="bottom" className="mr-2">
                            <a href="/" style={{ color: '#A2792C', float: 'right' }}>
                                Where do I find my iCal link?
                            </a>
                        </Tooltip>
                    </div>
                </Row>
                <Row className="mb-4 mt-2 mr-2">
                    <button className="modal-button">Import</button>
                </Row>
                <Row>
                    <div className="ical-links-container p-4 curved-btn">
                        <span className="fs-16 fw-600">Added iCal links</span>
                        <div className="d-flex flex-row justify-content-between mt-1 mb-1 pt-2 pb-2 pl-3 pr-3 ical-link">
                            <span>Booking.com</span>
                            <a href="/">Cancel</a>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-1 mb-1 pt-2 pb-2 pl-3 pr-3 ical-link">
                            <span>Booking.com</span>
                            <a href="/">Cancel</a>
                        </div>
                        <div className="d-flex flex-row justify-content-between mt-1 mb-1 pt-2 pb-2 pl-3 pr-3 ical-link">
                            <span>Booking.com</span>
                            <a href="/">Cancel</a>
                        </div>
                    </div>
                </Row>
            </div>
        </SynkProductDrawer>
    );
};

SynkProduct.propTypes = {
    visible: PropTypes.bool,
    toggleDrawer: PropTypes.func
};

export default SynkProduct;

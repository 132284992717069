import React, { Component } from 'react';
import { Form, Row, Col, Drawer, Radio, Checkbox, Input } from 'antd';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

import './index.scss';
import Spacer from '../../../atoms/spacer';
import MoreInfo from '../../../../assets/images/more_info.svg';
import { setMealsAndDrinks } from '../../../../store/actions/contentActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import HelpIcon from '../../../../assets/images/helpIcon.svg';
import UserIcon from '../../../../assets/images/user.svg';
import MicIcon from '../../../../assets/images/mic.svg';
import CalendarIcon from '../../../../assets/images/calender.svg';
import Languages from '../../../../languages.json';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { Feedback } from 'components/molecules';
import { compareLanguagesOffered } from 'utils';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '19px'
};

class LanguageOffered extends Component {
    formRef = React.createRef();

    constructor(props) {
        super(props);

        this.state = {
            isAddLanguage: true,
            isLicensed: true,
            isDrivers: true,
            isAddLanguages: false,
            languages: this.returnNewInitialLanguage(),
            selectLanguages: [],
            confirmationModal: false,
            searchLanguage: ''
        };
    }

    componentDidUpdate(prevProps) {
        const { selectedLanguages, languageGuide, isLanguageOffered } = this.props;
        if (prevProps.selectedLanguages !== selectedLanguages && selectedLanguages) {
            if (Array.isArray(selectedLanguages))
                this.setLanguages({ selectedLanguages, languageGuide, isLanguageOffered });
        }
    }
    componentDidMount() {
        const { selectedLanguages, languageGuide, isLanguageOffered } = this.props;
        if (Array.isArray(selectedLanguages))
            this.setLanguages({ selectedLanguages, languageGuide, isLanguageOffered });
    }

    setLanguages({ selectedLanguages, languageGuide, isLanguageOffered }) {
        const isEdit = [...this.state.languages];
        isEdit.map((res) => {
            const index = selectedLanguages.findIndex((item) => res.name === item.name);
            if (index === -1) {
                res.in_person = false;
                res.audio = false;
                res.written = false;
                this.addLanguages(res);
            } else {
                res.in_person = selectedLanguages[index]?.in_person || false;
                res.audio = selectedLanguages[index]?.audio || false;
                res.written = selectedLanguages[index]?.written || false;
                this.addLanguages(res);
            }
        });

        this.setState((prev) => {
            return {
                languages: [...isEdit],
                isLicensed: languageGuide?.guide_is_certified === false ? false : true,
                isDrivers: languageGuide?.guide_as_driver === false ? false : true,
                isAddLanguage: isLanguageOffered === false ? false : prev.isAddLanguage
            };
        });

        if (isLanguageOffered === false)
            this.formRef.current.setFieldsValue({
                isAddLanguage: false
            });
    }

    returnNewInitialLanguage() {
        return [
            {
                name: 'English',
                in_person: false,
                audio: false,
                written: false
            },
            {
                name: 'Spanish',
                in_person: false,
                audio: false,
                written: false
            },
            {
                name: 'French',
                in_person: false,
                audio: false,
                written: false
            },
            {
                name: 'German',
                in_person: false,
                audio: false,
                written: false
            },
            {
                name: 'Italian',
                in_person: false,
                audio: false,
                written: false
            }
        ];
    }

    onClose = () => {
        this.setState({
            isAddLanguage: this.props?.isLanguageOffered === false ? false : true,
            isLicensed: true,
            isDrivers: true,
            isAddLanguages: false,
            selectLanguages: [],
            languages: this.returnNewInitialLanguage(),
            searchLanguage: ''
        });
        this.props.onClose();
    };

    compareData = () => {
        const { selectedLanguages, languageGuide, isOpen } = this.props;
        const { selectLanguages, isLicensed, isDrivers, isAddLanguage } = this.state;
        let oldData = {
            selectedLanguages: selectedLanguages ? [...selectedLanguages] : [],
            guide_is_certified: languageGuide?.guide_is_certified ? true : false,
            guide_as_driver: languageGuide?.guide_as_driver ? true : false,
            is_language_offered: isOpen
        };
        let newData = {
            selectedLanguages: selectLanguages ? [...selectLanguages] : [],
            guide_is_certified: isLicensed,
            guide_as_driver: isDrivers,
            is_language_offered: isAddLanguage
        };
        if (compareLanguagesOffered(oldData, newData)) {
            this.setState({ confirmationModal: true });
        } else {
            //this.onSave();
            this.props.onClose();
        }
    };

    onFinish = () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.onSave();
    };

    onSave = () => {
        const { selectLanguages, isLicensed, isDrivers, isAddLanguage } = this.state;
        this.props.onSave(
            selectLanguages,
            {
                guide_is_certified: isLicensed,
                guide_as_driver: isDrivers
            },
            isAddLanguage
        );
        this.props.onClose();
    };
    //adding a language from the list
    onAddLanguages = (language, index) => {
        let tmp = {
            name: language,
            in_person: false,
            audio: false,
            written: false
        };
        this.setState({ languages: [...this.state.languages, tmp] });
        Languages.languages.splice(index, 1);
    };
    toggleLanguages = () => this.setState({ isAddLanguages: !this.state.isAddLanguages });
    //changing language settings
    addLanguages = (item) => {
        const { selectLanguages } = this.state;
        const isAdded = selectLanguages.findIndex((res) => res?.name === item.name);
        if (isAdded === -1) {
            if (item.in_person || item.audio || item.written)
                this.setState((prevState) => {
                    return { selectLanguages: [...prevState.selectLanguages, item] };
                });
        } else if (item.in_person || item.audio || item.written) {
            let tmp = [...selectLanguages];
            tmp.splice(isAdded, 1, item);
            this.setState({ selectLanguages: [...tmp] });
        } else if (isAdded !== -1 && !item.in_person && !item.audio && !item.written) {
            let tmp = [...selectLanguages];
            tmp.splice(isAdded, 1);
            this.setState({ selectLanguages: [...tmp] });
        }
    };

    render() {
        const { requiredMark, isAddLanguage, isLicensed, isDrivers, languages, isAddLanguages } =
            this.state;
        const { validateRequiredFormField } = this.props;

        return (
            // <div className="itenary-form">
            <Drawer
                className="application language-offered"
                onClose={() => {
                    this.onClose();
                }}
                visible={this.props.isOpen}>
                <Form
                    ref={this.formRef}
                    name="language-offered-form"
                    className="h-100 d-flex flex-column overflow-y-auto"
                    onFinish={this.onFinish}
                    layout="vertical"
                    initialValues={{
                        ...this.state
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}>
                    <div className="flex-grow-1 overflow-y-auto pt-3 container-fluid">
                        <h6 className="title">
                            Language Offered <img src={MoreInfo} alt="" />
                        </h6>

                        <Spacer top={35} />

                        <Form.Item
                            name="isAddLanguage"
                            rules={[{ required: true, message: 'Please fill this field in' }]}>
                            <Radio.Group
                                value={isAddLanguage}
                                onChange={(e) => this.setState({ isAddLanguage: e.target.value })}>
                                <Radio style={radioStyle} value={true}>
                                    Yes
                                </Radio>
                                <Radio style={radioStyle} value={false}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        {isAddLanguage ? (
                            <Col>
                                <Form.Item
                                    validateTrigger={['onChange']}
                                    name="selectLanguages"
                                    rules={[
                                        {
                                            //inbuilt 'selectLanguages' is only working for initial values
                                            validator: async () =>
                                                validateRequiredFormField({
                                                    data: this.state.selectLanguages,
                                                    fieldType: 'ARRAY'
                                                })
                                        }
                                    ]}>
                                    <div>
                                        <Spacer top={21} />
                                        <Row gutter={[16, 16]}>
                                            <Col flex="none">
                                                <div className="input-title">
                                                    What type of guide is included?
                                                </div>
                                                <Spacer top={10} />
                                                <div
                                                    className="input-description"
                                                    style={{ color: 'rgba(79, 79, 79, 0.63)' }}>
                                                    Languages
                                                </div>
                                            </Col>
                                            <Col flex="none" style={{ marginLeft: 'auto' }}>
                                                <Row className="title-row">
                                                    <Col flex="none" className="title-type">
                                                        <img src={UserIcon} alt="" />
                                                        In-person
                                                    </Col>
                                                    <Col flex="none" className="title-type">
                                                        <img src={MicIcon} alt="" />
                                                        Audio
                                                    </Col>
                                                    <Col flex="none" className="title-type">
                                                        <img src={CalendarIcon} alt="" />
                                                        Written
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Spacer top={20} />
                                        <hr />
                                        {languages && languages.length > 0
                                            ? languages.map((res, i) => (
                                                  <Row
                                                      key={i}
                                                      gutter={[16, 16]}
                                                      style={{ rowGap: 0 }}>
                                                      <Col flex="none">{res.name}</Col>
                                                      <Col
                                                          flex="none"
                                                          style={{ marginLeft: 'auto' }}>
                                                          <Row className="checkbox-row">
                                                              <Col className="title-type-col">
                                                                  <Checkbox
                                                                      checked={res.in_person}
                                                                      onChange={() => {
                                                                          res.in_person =
                                                                              !res.in_person;
                                                                          this.addLanguages(res);
                                                                      }}
                                                                  />
                                                              </Col>
                                                              <Col className="title-type-col">
                                                                  <Checkbox
                                                                      checked={res.audio}
                                                                      onChange={() => {
                                                                          res.audio = !res.audio;
                                                                          this.addLanguages(res);
                                                                      }}
                                                                  />
                                                              </Col>
                                                              <Col className="title-type-col">
                                                                  <Checkbox
                                                                      checked={res.written}
                                                                      onChange={() => {
                                                                          res.written =
                                                                              !res.written;
                                                                          this.addLanguages(res);
                                                                      }}
                                                                  />
                                                              </Col>
                                                          </Row>
                                                      </Col>

                                                      <Spacer top={20} />
                                                      <hr style={{ width: '100%' }} />
                                                  </Row>
                                              ))
                                            : null}
                                    </div>
                                </Form.Item>

                                <Form.Item>
                                    <Dropdown isOpen={isAddLanguages} toggle={this.toggleLanguages}>
                                        <DropdownToggle className="dropdown-category" caret>
                                            Select a language
                                        </DropdownToggle>
                                        <DropdownMenu
                                            style={{ width: '100%', maxHeight: '200px' }}
                                            className="category-menu">
                                            <Input
                                                type="search"
                                                size="small"
                                                placeholder="Search Languages"
                                                value={this.state.searchLanguage}
                                                onChange={(e) =>
                                                    this.setState({
                                                        searchLanguage: e.target.value
                                                    })
                                                }
                                            />
                                            {Languages.languages && Languages.languages.length > 0
                                                ? Languages.languages
                                                      .filter((it) => {
                                                          if (this.state.searchLanguage) {
                                                              if (
                                                                  it.name
                                                                      .toLowerCase()
                                                                      .includes(
                                                                          this.state.searchLanguage.toLowerCase()
                                                                      )
                                                              ) {
                                                                  return true;
                                                              } else {
                                                                  return false;
                                                              }
                                                          } else {
                                                              return true;
                                                          }
                                                      })
                                                      .map((res, i) => (
                                                          <DropdownItem
                                                              onClick={() =>
                                                                  this.onAddLanguages(res.name, i)
                                                              }
                                                              key={i}
                                                              className="category-item">
                                                              {res.name}
                                                          </DropdownItem>
                                                      ))
                                                : null}
                                        </DropdownMenu>
                                    </Dropdown>
                                </Form.Item>

                                <Form.Item
                                    name="isLicensed"
                                    rules={[
                                        { required: true, message: 'Please fill this field in' }
                                    ]}>
                                    <div className="input-title">
                                        Are your guides licensed/certified?
                                    </div>
                                    <Spacer top={10} />
                                    <Radio.Group
                                        value={isLicensed}
                                        onChange={(e) =>
                                            this.setState({ isLicensed: e.target.value })
                                        }>
                                        <Radio style={radioStyle} value={true}>
                                            Yes
                                        </Radio>
                                        <Radio style={radioStyle} value={false}>
                                            No
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Spacer top={20} />

                                <Form.Item
                                    name="isDrivers"
                                    rules={[
                                        { required: true, message: 'Please fill this field in' }
                                    ]}>
                                    <div className="input-title">
                                        Do your guides also serve as drivers?
                                    </div>
                                    <Spacer top={10} />
                                    <Radio.Group
                                        value={isDrivers}
                                        onChange={(e) =>
                                            this.setState({ isDrivers: e.target.value })
                                        }>
                                        <Radio style={radioStyle} value={true}>
                                            Yes
                                        </Radio>
                                        <Radio style={radioStyle} value={false}>
                                            No
                                        </Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Spacer top={20} />
                            </Col>
                        ) : null}
                    </div>

                    <div className="d-flex gap-1 flex-wrap">
                        <Button
                            color="primary"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="submit">
                            Save
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            onClick={() => this.onClose()}>
                            Cancel
                        </Button>
                        {isAddLanguage ? (
                            <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                        ) : (
                            ''
                        )}
                    </div>
                </Form>

                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.onSave();
                    }}
                />
            </Drawer>
            // </div>
        );
    }
}
LanguageOffered.propTypes = {
    selectedLanguages: Proptypes.array,
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    isOpen: Proptypes.bool,
    languageGuide: Proptypes.object,
    validateRequiredFormField: Proptypes.func,
    selected_existing_product: Proptypes.bool,
    isLanguageOffered: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = (dispatch) => ({
    setMealsAndDrinks: (data) => dispatch(setMealsAndDrinks(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(LanguageOffered));

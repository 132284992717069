/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import './index.scss';

import { Checkbox, Col, Drawer, Form, Input, Radio, Row } from 'antd';
import { Button } from 'reactstrap';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import Spacer from '../../../atoms/spacer';
import Prompter from '../../../atoms/prompter';
import Close from '../../../../assets/images/close.svg';
import Proptypes from 'prop-types';
import MoreInfo from '../../../../assets/images/more_info.svg';
import ImportItineraries from '../../../atoms/importItineraries';
import { compareInformationTraveller } from 'utils';
import { ConfirmationModal } from 'components/molecules';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { PhoneCountryCode } from 'components/atoms';

const radioStyle = {
    display: 'flex'
};

function PhysicalRestriction(props) {
    const [form] = Form.useForm();

    const [state, setState] = useState({
        accessibility: [
            {
                name: 'Is it Wheelchair accessible?',
                value: false
            },
            {
                name: 'Is it stroller accessible?',
                value: false
            },
            {
                name: 'Are service animals allowed?',
                value: false,
                info: true
            },
            {
                name: 'Can travelers easily arrive/depart on public transportation?',
                value: false
            },
            {
                name: 'Are infants required to sit on laps?',
                value: false
            },
            {
                name: 'Are infant seats available?',
                value: false
            }
        ],
        accessibleAnother: [
            {
                name: 'Is all transportation wheelchair accessible?',
                value: false
            },
            {
                name: 'Are all surfaces wheelchair accessible (no uneven terrain, stairs, etc.)?',
                value: false
            }
        ],
        healthRestricitons: [
            {
                name: 'Not recommended for travelers with back problems',
                value: false
            },
            {
                name: 'Not recommended for pregnant travelers',
                value: false
            },
            {
                name: 'Not recommended for travelers with heart problems or other serious medical conditions',
                value: false
            }
        ]
    });
    const [isAnotherAccessibility, setIsAnotherAccessibility] = useState(false);
    const [isSelectCode, setIsSelectCode] = useState(false);
    const [selectCode, setSelectCode] = useState('');
    const [healthRestricitonsInput, setHealthRestricitonsInput] = useState('');
    const [accessibilityInput, setAccessibilityInput] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [difficulty, setDifficulty] = useState('Easy');

    const [confirmationModal, setConfirmationModal] = useState(false);

    useEffect(() => {
        if (props.data) onSetImportData(props.data);
    }, [props.data]);

    //set import data
    const onSetImportData = (item) => {
        const newState = { ...state };
        if (item) {
            newState.accessibility[0].value = item?.accessibility.wheelchair;
            newState.accessibility[1].value = item?.accessibility.stroller;
            newState.accessibility[2].value = item?.accessibility.service_animals;
            newState.accessibility[3].value = item?.accessibility.public_trans;
            newState.accessibility[4].value = item?.accessibility.infant_lapping;
            newState.accessibility[5].value = item?.accessibility.infant_seats;
            if (item?.health_restrictions && item.health_restrictions.length > 0) {
                newState.healthRestricitons.map((res) => {
                    res.value = item.health_restrictions.includes(res.name);
                });
            }
            setState(newState);
            setDifficulty(item?.difficulty_level?.type);
            if (item.phone_number) {
                setSelectCode(item?.phone_number.substring(0, item?.phone_number.indexOf('-')));
                setPhoneNumber(item?.phone_number.substring(item?.phone_number.indexOf('-') + 1));
            } else {
                setSelectCode('');
                setPhoneNumber('');
            }
            // props.onSave(item);
        }
    };

    const addNewHealthRestricition = () => {
        const newState = { ...state };

        newState.healthRestricitons.push({
            name: '',
            value: '',
            added: true
        });
        setState(newState);
    };
    const addNewAccessibility = () => {
        const newState = { ...state };

        newState.accessibility.push({
            name: '',
            value: '',
            added: true
        });
        setState(newState);
    };

    const removeHealthRestricition = (index) => {
        const newState = { ...state };

        newState.healthRestricitons.splice(index, 1);
        setState(newState);
        setHealthRestricitonsInput('');
    };
    const removeAccessibility = (index) => {
        const newState = { ...state };

        newState.accessibility.splice(index, 1);
        setState(newState);
        setAccessibilityInput('');
    };

    const setAccessibility = (index, e) => {
        const newState = { ...state };
        if (e.code === 'Enter' && accessibilityInput.trim() !== '') {
            newState.accessibility[index].name = accessibilityInput.trim();
            newState.accessibility[index].added = false;
            setState(newState);
            setAccessibilityInput('');
        }
    };
    const setHealthRestriction = (index, e) => {
        if (e.code === 'Enter' && healthRestricitonsInput.trim() !== '') {
            const tmp = state;
            tmp.healthRestricitons[index].name = healthRestricitonsInput.trim();
            tmp.healthRestricitons[index].added = false;
            setState({
                ...tmp
            });
            setHealthRestricitonsInput('');
        }
    };

    const changeAccessibility = (value, index) => {
        let newState = { ...state };
        state.accessibility[index].value = value;
        setState(newState);
    };
    const changeAccessibilityAnother = (value, index) => {
        let newState = { ...state };
        state.accessibleAnother[index].value = value;
        setState(newState);
    };
    const changeHealthRestricitons = (value, index) => {
        let newState = { ...state };
        state.healthRestricitons[index].value = value;
        setState(newState);
    };

    const togglePhone = () => setIsSelectCode(!isSelectCode);

    const onFinish = () => {
        const { selected_existing_product, data } = props;
        form.validateFields()
            .then(() => {
                if (selected_existing_product && data)
                    if (
                        compareInformationTraveller(data, {
                            difficulty,
                            healthRestricitons: state.healthRestricitons,
                            asses: state.accessibility,
                            phoneNumber: `${selectCode}-${phoneNumber}`
                        })
                    )
                        return setConfirmationModal(true);
                    else onClose();
                else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onSave = () => {
        let healthRestricitons = [];
        state.healthRestricitons.map((res) => {
            if (res.value) healthRestricitons.push(res.name);
        });
        let difficulty_level = {
            type: 'Easy',
            content: 'Most travelers can participate'
        };
        if (difficulty === 'Moderate') {
            difficulty_level = {
                type: 'Moderate',
                content: 'Travelers should have a moderate physical fitness level'
            };
        } else if (difficulty === 'Challenging')
            difficulty_level = {
                type: 'Challenging',
                content: 'Travelers should have a moderate physical fitness level'
            };

        const tmpInformation = {
            accessibility: {
                wheelchair: state.accessibility[0].value,
                stroller: state.accessibility[1].value,
                service_animals: state.accessibility[2].value,
                public_trans: state.accessibility[3].value,
                infant_lapping: state.accessibility[4].value,
                infant_seats: state.accessibility[5].value
            },
            health_restrictions: healthRestricitons.length > 0 ? healthRestricitons : [''],
            difficulty_level: difficulty_level
        };
        if (selectCode && phoneNumber) tmpInformation.phone_number = `${selectCode}-${phoneNumber}`;
        props.onSave(tmpInformation);
        onClose();
    };
    const onClose = () => props.onClose();

    function validateHealthRestricitonField({ data }) {
        if (!Array.isArray(data)) return Promise.reject(new Error('Please fill this field in'));
        else if (Array.isArray(data)) {
            let atleastSelected = data.some((element) => element.value);
            if (!atleastSelected) return Promise.reject(new Error('Select atleast one'));
        }

        return Promise.resolve();
    }

    return (
        // <div className="itenary-form">
        <Drawer
            className="application add-information"
            onClose={() => {
                onClose();
            }}
            visible={props.isOpen}
            bodyStyle={{ paddingBottom: 35, paddingTop: 70 }}>
            <div className="title">Add any physical restrictions for this Itinerary</div>
            <p>This information will help travelers know if this is a good product for them.</p>
            {props.hideImport ? (
                ''
            ) : (
                <ImportItineraries
                    calendars={props.itineraries}
                    import="I will import physical restriction information from my other activity"
                    onMainProduct={(item) => onSetImportData(item.traveller_information)}
                />
            )}
            <Form layout="vertical" onFinish={onFinish} form={form}>
                <div>
                    <Row className="physical-restriction__row">
                        <Col span="21">
                            <span>Accessibility</span>
                        </Col>
                        <Col className="physical-restricition__selection" span="3">
                            <span>Yes</span>
                            <span>No</span>
                        </Col>
                    </Row>
                    {state.accessibility.map((item, index) => (
                        <Row
                            className="physical-restriction__row"
                            style={{ fontSize: 14 }}
                            key={index}>
                            <Col span="21">
                                {item.added ? (
                                    <Input
                                        suffix={
                                            <img
                                                height="12"
                                                width="12"
                                                src={Close}
                                                onClick={() => removeAccessibility(index)}
                                                alt=""
                                            />
                                        }
                                        className="physical-restriction-input"
                                        value={accessibilityInput}
                                        onChange={(e) => setAccessibilityInput(e.target.value)}
                                        onKeyPress={(e) => setAccessibility(index, e)}
                                    />
                                ) : isAnotherAccessibility && index === 0 ? (
                                    <div>
                                        <span
                                            style={{ marginBottom: '15px' }}
                                            className="accessible-name">
                                            {item.name}
                                        </span>
                                        <span
                                            style={{ marginBottom: '10px' }}
                                            className="accessible-name">
                                            {state.accessibleAnother[0].name}
                                        </span>
                                        <span className="accessible-name">
                                            {state.accessibleAnother[1].name}
                                        </span>
                                    </div>
                                ) : (
                                    <span className="accessible">
                                        {item.name}
                                        {item?.info ? <img src={MoreInfo} alt="" /> : null}
                                    </span>
                                )}
                            </Col>
                            {isAnotherAccessibility && index === 0 && !item.added ? (
                                <Col span="3">
                                    <Radio.Group
                                        value={state.accessibility[0].value}
                                        onChange={(e) => {
                                            if (item === state.accessibility[0]) {
                                                setIsAnotherAccessibility(e.target.value);
                                            }
                                            changeAccessibility(e.target.value, 0);
                                        }}>
                                        <Radio value={true} />
                                        <Radio value={false} />
                                    </Radio.Group>
                                    <Spacer top={10} />
                                    <Radio.Group
                                        value={state.accessibleAnother[0].value}
                                        onChange={(e) => {
                                            changeAccessibilityAnother(e.target.value, 0);
                                        }}>
                                        <Radio value={true} />
                                        <Radio value={false} />
                                    </Radio.Group>
                                    <Spacer top={10} />
                                    <Radio.Group
                                        value={state.accessibleAnother[1].value}
                                        onChange={(e) => {
                                            changeAccessibilityAnother(e.target.value, 1);
                                        }}>
                                        <Radio value={true} />
                                        <Radio value={false} />
                                    </Radio.Group>
                                </Col>
                            ) : !item.added ? (
                                <Col span="3">
                                    <Radio.Group
                                        value={item.value}
                                        onChange={(e) => {
                                            if (item === state.accessibility[0]) {
                                                setIsAnotherAccessibility(e.target.value);
                                            }
                                            changeAccessibility(e.target.value, index);
                                        }}>
                                        <Radio value={true} />
                                        <Radio value={false} />
                                    </Radio.Group>
                                </Col>
                            ) : null}
                        </Row>
                    ))}
                    <Spacer top={15} />
                    <Prompter
                        onClick={addNewAccessibility}
                        textColor="#A2792C"
                        text="Add another"
                    />
                </div>
                <Spacer top={50} />
                <div className="health-restricitons">
                    <h6>Health restrictions</h6>
                    <p>Check all that apply</p>
                    <Form.Item
                        name="healthRestriciton"
                        rules={[
                            {
                                validator: async () =>
                                    validateHealthRestricitonField({
                                        data: state.healthRestricitons
                                    })
                            }
                        ]}>
                        {state.healthRestricitons.map((item, index) => (
                            <p key={item.name + index} style={{ marginBottom: '10px' }}>
                                {item.added ? (
                                    <Input
                                        suffix={
                                            <img
                                                height="12"
                                                width="12"
                                                alt=""
                                                src={Close}
                                                onClick={() => removeHealthRestricition(index)}
                                            />
                                        }
                                        style={{
                                            width: 420,
                                            border: '0.5px solid #A2792C',
                                            boxSizing: 'border-box',
                                            borderRadius: '8px'
                                        }}
                                        onChange={(e) => setHealthRestricitonsInput(e.target.value)}
                                        value={healthRestricitonsInput}
                                        onKeyPress={(e) => setHealthRestriction(index, e)}
                                    />
                                ) : (
                                    <Checkbox
                                        checked={item.value}
                                        onChange={(e) => {
                                            changeHealthRestricitons(e.target.checked, index);
                                            form.validateFields(['healthRestriciton']);
                                        }}
                                        style={{ display: 'flex', alignItems: 'center' }}>
                                        {item.name}
                                    </Checkbox>
                                )}
                            </p>
                        ))}
                    </Form.Item>
                    <Prompter
                        onClick={addNewHealthRestricition}
                        textColor="#A2792C"
                        text="Add another"
                    />
                </div>
                <Spacer top={36} />
                <div className="difficulty">
                    <h6 style={{ fontWeight: 600 }}>Select the physical difficulty level</h6>
                    <Radio.Group
                        value={difficulty}
                        onChange={(value) => setDifficulty(value.target.value)}>
                        <Radio style={radioStyle} value="Easy">
                            <h6 style={{ marginTop: 3 }}>Easy</h6>
                            <p>Most travelers can participate</p>
                        </Radio>
                        <Radio style={radioStyle} value="Moderate">
                            <h6 style={{ marginTop: 3 }}>Moderate</h6>
                            <p>Travelers should have a moderate physical fitness level</p>
                        </Radio>
                        <Radio style={radioStyle} value="Challenging">
                            <h6 style={{ marginTop: 3 }}>Challenging</h6>
                            <p>Travelers should have a moderate physical fitness level</p>
                        </Radio>
                    </Radio.Group>
                </div>
                <Spacer top={36} />
                <div>
                    <h6>Your phone number</h6>
                    <p>
                        This is the number travelers will call if they need to reach you on the day
                        of the travel
                    </p>

                    <PhoneCountryCode
                        // label="Customer service phone number"
                        selectedCountryCode={selectCode}
                        onChangeCountryCode={(code) => {
                            setSelectCode(code);
                            form.validateFields(['selectCode']);
                        }}
                        expandMenu={isSelectCode}
                        toggleMenu={togglePhone}
                        phoneNumber={phoneNumber ? phoneNumber : ''}
                        onChangePhoneNumber={(value) => setPhoneNumber(value)}
                    />
                    {/* <div style={{ display: 'flex' }}>
                        <Form.Item
                            name="selectCode"
                            rules={[
                                {
                                    required: selectCode ? false : true,
                                    message: 'Required'
                                }
                            ]}>
                            <Dropdown isOpen={isSelectCode} toggle={togglePhone}>
                                <DropdownToggle className="dropdown-category" caret>
                                    {selectCode === '' ? '' : selectCode}
                                </DropdownToggle>
                                <DropdownMenu
                                    style={{ width: '300px', maxHeight: '200px' }}
                                    className="category-menu">
                                    {PhonesCode.phones && PhonesCode.phones.length > 0
                                        ? PhonesCode.phones.map((res, i) => (
                                              <DropdownItem
                                                  onClick={() => {
                                                      setSelectCode(res.dial_code);
                                                      form.validateFields(['selectCode']);
                                                  }}
                                                  style={{
                                                      backgroundColor:
                                                          res.dial_code === selectCode
                                                              ? '#E7D99F'
                                                              : '#fff'
                                                  }}
                                                  key={i}
                                                  className="category-item">
                                                  {res.dial_code + ' ' + res.name}
                                              </DropdownItem>
                                          ))
                                        : null}
                                </DropdownMenu>
                            </Dropdown>
                        </Form.Item>
                        <Form.Item
                            initialValue={phoneNumber}
                            name="phone"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill this field in'
                                }
                            ]}>
                            <Input
                                type="phone"
                                className="input-phone"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </Form.Item>
                    </div>
                */}
                </div>
                <div className="footer-btn">
                    <div>
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                        <Button
                            color="dark"
                            style={{ marginLeft: '8px' }}
                            onClick={onClose}
                            type="button">
                            Cancel
                        </Button>
                    </div>
                    <Button color="dark" style={{ width: '137px' }}>
                        <img src={HelpIcon} alt="" /> Help
                    </Button>
                </div>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    onSave();
                    setConfirmationModal(false);
                }}
            />
        </Drawer>
        // </div>
    );
}
PhysicalRestriction.propTypes = {
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    data: Proptypes.any,
    hideImport: Proptypes.bool,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(PhysicalRestriction));

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import Selected from '../../../../../assets/images/selected.svg';
import { Modal, Button, Form, Row, Col, Input } from 'antd';
import MediaField from '../../../../molecules/media-library/media-field';
import Search from '../../../../../assets/images/search.svg';
import Delete from '../../../../../assets/images/delete--white.svg';
import MediaList from '../../../../molecules/media-library/media-list';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Spacer from '../../../../atoms/spacer';
import './index.scss';

const ShowTravellers = (props) => {
    const options = [
        {
            name: 'lion',
            img: '/lion-small.png'
        },
        {
            name: 'elephant',
            img: '/elephants.png'
        },
        {
            name: 'tiger',
            img: '/tiger.png'
        },
        {
            name: 'elephant',
            img: '/elephants.png'
        },
        {
            name: 'tiger',
            img: '/tiger.png'
        }
    ];
    const { handleOk, isVisible, handleCancel } = props;
    const [selected, setSelected] = useState([]);
    const [selectedFiltered, setSelectedFiltered] = useState(options);
    const [uploadingMedias, setUploadingMedias] = useState([]);

    const isSelected = (option) => {
        const newSelected = [...selected];
        return newSelected.findIndex((i) => i.name === option.name);
    };

    const removeUploadingMedia = (index) => {
        const newUploadingMedias = [...uploadingMedias];

        newUploadingMedias.splice(index, 1);

        setUploadingMedias(newUploadingMedias);
    };

    const updateImagesToUpload = (file, crop) => {
        let newUploadingMedias = [...uploadingMedias];
        if (!file || !crop.croppedImageUrl) return;
        newUploadingMedias.push({
            file,
            src: crop.croppedImageUrl,
            caption: 'Add Caption',
            badge: !newUploadingMedias.length && 'First'
        });
        setUploadingMedias(newUploadingMedias);
    };

    const select = (option) => {
        let newSelected = [...selected];
        const exists = isSelected(option);
        if (exists >= 0) {
            newSelected.splice(exists, 1);
        } else {
            newSelected = [option];
        }
        setSelected(newSelected);
    };

    const filter = (e) => {
        const search = e.target.value;
        const filtered = options.filter((i) => i.name.includes(search));
        setSelectedFiltered(filtered);
    };

    return (
        <Modal
            width={574}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={false}
            wrapClassName="show-travellers"
            footer={[
                <Button
                    style={{ width: 135, height: 42 }}
                    type="outline"
                    className="rounded-btn mr20"
                    onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    onClick={() => handleOk(selected[0])}
                    style={{ width: 135, height: 42 }}
                    type="primary"
                    className="rounded-btn">
                    Save
                </Button>
            ]}>
            <h4 className="show-travellers__title">Show travellers what they are going to see</h4>
            <Spacer top={16} />
            <p className="show-travellers__subtitle">
                Recommended:Travelers love to see pictures of what to expect.
            </p>
            <Spacer top={30} />
            <div className="show-travellers__form-wrapper">
                <Form layout="vertical">
                    <Input
                        onChange={filter}
                        placeholder="Search our photo library"
                        className="show-travellers__search"
                        suffix={<img src={Search} alt=""></img>}
                    />
                    <Row>
                        <Col className="show-travellers__options">
                            {selectedFiltered.map((option, i) => (
                                <div
                                    key={i}
                                    onClick={() => select(option)}
                                    className="show-travellers__item">
                                    <img
                                        className="show-travellers__item-img"
                                        src={option.img}
                                        alt=""></img>
                                    <img
                                        style={{
                                            display: isSelected(option) >= 0 ? 'block' : 'none'
                                        }}
                                        className="show-travellers__item--selected"
                                        src={Selected}
                                        alt=""></img>
                                </div>
                            ))}
                        </Col>
                        <Col className="show-travellers__options--selected">
                            {selected.map((option, i) => (
                                <div className="show-travellers__item" key={i}>
                                    <img
                                        className="show-travellers__item-img"
                                        src={option.img}
                                        alt=""></img>
                                    <img
                                        onClick={() => select(option)}
                                        className="show-travellers__item-delete"
                                        src={Delete}
                                        alt=""></img>
                                </div>
                            ))}
                        </Col>
                    </Row>
                    <hr />
                    <Row>
                        <Col span={12}>
                            <MediaField
                                type="picture"
                                text="Add Photos from file"
                                height={104}
                                width={214}
                                onSelectMedia={updateImagesToUpload}
                            />
                        </Col>
                        <Col span={12} className="show-travellers__uploading-photos">
                            <MediaList
                                medias={uploadingMedias}
                                removeMedia={removeUploadingMedia}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
};

ShowTravellers.propTypes = {
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired
};

export default ShowTravellers;

import React, { Component } from 'react';
import { Tabs } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal, Row, Col, ModalBody } from 'reactstrap';
// import '../views/test/index.scss';

import SendToCustomer from '../views/accessCustomerCalendar/Tab1/SendToCustomer';
import ReachMoreCustomers from '../views/accessCustomerCalendar/Tab2/ReachMoreCustomers';

const { TabPane } = Tabs;

class AccessCustomerCalendarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // showModal: this.props.showModal,
            activeTab: this.props.activeTab
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle = (key) => {
        this.setState({
            activeTab: key
        });
    };

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.accessCustomercalendar}
                    className="access-customer-modal"
                    centered
                    size="lg">
                    <ModalBody>
                        <div className="text-left">
                            <Row className="ml-0 mr-0 pl-0 pr-0">
                                <Col className="p-0">
                                    <h3>{this.props.title}</h3>
                                </Col>
                            </Row>
                            <div>
                                <Tabs
                                    defaultActiveKey={this.state.activeTab}
                                    onChange={this.toggle}>
                                    <TabPane tab="Send To Customer" key="1">
                                        <SendToCustomer
                                            calendarId={this.props.calendarId}
                                            createMainProduct={this.props.createMainProduct}
                                        />
                                    </TabPane>
                                    <TabPane tab="Reach More Customer" key="2">
                                        <ReachMoreCustomers />
                                    </TabPane>
                                </Tabs>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

AccessCustomerCalendarModal.propTypes = {
    title: PropTypes.string.isRequired,
    activeTab: PropTypes.string,
    accessCustomercalendar: PropTypes.bool.isRequired,
    calendarId: PropTypes.string.isRequired,
    createMainProduct: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    accessCustomercalendar: state.accessCalendarReducer.accessCustomercalendar
});

export default connect(mapStateToProps)(AccessCustomerCalendarModal);

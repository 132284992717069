import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

const CalendarStatusWrap = (props) => {
    const { currentWindowWidth, status } = props;
    let calendarStatus = status ? status : 'draft';
    calendarStatus = calendarStatus.charAt(0).toUpperCase() + calendarStatus.slice(1);
    return (
        <Tooltip
            placement={currentWindowWidth >= 768 ? 'top' : 'right'}
            title={
                <div className="text-center">
                    <div className="fw-600">Calendar Status:</div>
                    <div className="text-secondary fs-12">
                        {calendarStatus === 'Live' ? 'A' : 'No'} link has been generated for your
                        calendar and it is {calendarStatus === 'Live' ? 'now' : 'not'} active for
                        customers to access
                    </div>
                </div>
            }>
            <div className="fs-14 text-nowrap">
                <span
                    className={`calendar_activity-status __${calendarStatus
                        .toLowerCase()
                        .replace(/ /g, '_')}`}></span>
                <span className="fs-14 text-gray">{calendarStatus}</span>
            </div>
        </Tooltip>
    );
};

CalendarStatusWrap.propTypes = {
    currentWindowWidth: PropTypes.number,
    status: PropTypes.string
};

export default CalendarStatusWrap;

import { callApi } from 'utils/apiCaller';
import { Notification } from 'components/atoms/notification';
import {
    GET_CALENDAR_BOOKINGS,
    GET_BOOKED_CALENDARS,
    GET_CALENDAR_BOOKED_PRODUCTS,
    RESET_BOOKING_REDUCER,
    GET_OTHER_BOOKED_PRODUCTS,
    GET_LEAD_TRAVELLERS,
    GET_ALL_PRODUCTS,
    GET_CALENDAR_BOOKED_DETAILS
} from 'store/types';
import { elseNotifyError } from 'store/actions';

export const bookedCalendars_ = (payload) => ({
    type: GET_BOOKED_CALENDARS,
    payload
});

export const bookedCalendars = () => {
    return (dispatch) => {
        return callApi(`/calendar-bookings/calendars`, 'GET').then((response) => {
            const status = response?.status;
            const data = response?.data?.data || [];
            if (status === 200) {
                return dispatch(bookedCalendars_({ data: data, success: true }));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const calendarBookedProducts_ = (payload) => ({
    type: GET_CALENDAR_BOOKED_PRODUCTS,
    payload
});

export const calendarBookedProducts = (data) => {
    return (dispatch) => {
        return callApi(`/calendar-bookings/booked-products?${data}`, 'GET').then((response) => {
            const status = response?.status;
            const data = response?.data?.data || [];
            if (status === 200) {
                return dispatch(calendarBookedProducts_({ data: data, success: true }));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const otherBookedProducts_ = (payload) => ({
    type: GET_OTHER_BOOKED_PRODUCTS,
    payload
});

export const otherBookedProducts = (data) => {
    return (dispatch) => {
        return callApi(`/calendar-bookings/other-products?${data}`, 'GET').then((response) => {
            const status = response?.status;
            const data = response?.data?.data || [];
            if (status === 200) {
                return dispatch(otherBookedProducts_({ data: data, success: true }));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const leadTravellers_ = (payload) => ({
    type: GET_LEAD_TRAVELLERS,
    payload
});

export const leadTravellers = () => {
    return (dispatch) => {
        return callApi(`/calendar-bookings/lead-travellers`, 'GET').then((response) => {
            const status = response?.status;
            const data = response?.data?.data || [];
            if (status === 200) {
                return dispatch(leadTravellers_({ data: data, success: true }));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const allProducts_ = (payload) => ({
    type: GET_ALL_PRODUCTS,
    payload
});

export const allProducts = () => {
    return (dispatch) => {
        return callApi(`/me/all-products`, 'GET').then((response) => {
            const status = response?.status;
            const data = response?.data?.data || [];
            if (status === 200) {
                return dispatch(allProducts_({ data: data, success: true }));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const saveSettings = (data) => {
    return () => {
        return callApi(`/calendar-bookings/save-notification-settings`, 'POST', data).then(
            (response) => {
                const status = response?.status;
                const successMsg =
                    response?.data?.message || response?.message || 'Settings saved successfully.';
                if (status === 200) {
                    return Notification('success', successMsg, status);
                }
            }
        );
    };
};

export const calendarBookedDetails_ = (payload) => ({
    type: GET_CALENDAR_BOOKED_DETAILS,
    payload
});

export const calendarBookedDetails = (data) => {
    return (dispatch) => {
        return callApi(`/calendar-bookings/calendar-bookings/?${data}`, 'GET').then((response) => {
            const status = response?.status;
            const data = response?.data?.data || [];
            if (status === 200) {
                return dispatch(calendarBookedDetails_({ data: data, success: true }));
            } else {
                return elseNotifyError(response, status);
            }
        });
    };
};

export const resetBookingReducer = (payload = {}) => ({
    type: RESET_BOOKING_REDUCER,
    payload
});

export const calendarBookings_ = (payload) => ({
    type: GET_CALENDAR_BOOKINGS,
    payload
});

export const calendarBookings = (data) => {
    return (dispatch) => {
        return callApi(`/calendar-bookings`, 'POST', data).then(() => {
            //const status = response?.status;
            const data = []; //response.data
            //if (status === 200) {
            return dispatch(calendarBookings_({ data: data, success: true }));
            // } else {
            //   return elseNotifyError(response, status);
            // }
        });
    };
};

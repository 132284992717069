import React from 'react';
import PropTypes from 'prop-types';

export default function Button(props) {
    return (
        <button
            onClick={props.onClick}
            style={{ float: props.float }}
            className="add-button cursor-pointer">
            <span style={{ paddingRight: props.padRight }}>
                <img src={props.image} style={{ width: props.width }} alt="" />
            </span>
            {props.text}
        </button>
    );
}
Button.propTypes = {
    onClick: PropTypes.func,
    float: PropTypes.any,
    padRight: PropTypes.any,
    image: PropTypes.string,
    width: PropTypes.any,
    text: PropTypes.any
};

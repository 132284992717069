import React from 'react';
import { Tabs } from 'antd';
import './customerExperience.scss';
import CustomerService from './Tabs/Tab1/CustomerService';
import CustomerMessages from './Tabs/Tab2/CustomerMessages';
import CustomerFeedback from './Tabs/Tab3/CustomerFeedback';

const { TabPane } = Tabs;
export default function CustomerExperience() {
    return (
        <>
            <div className="customer-experience">
                <div className="white-bg padded">
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Customer Service" key="1">
                            <CustomerService />
                        </TabPane>
                        <TabPane tab="Customer Messages" key="2">
                            <CustomerMessages />
                        </TabPane>
                        <TabPane tab="Customer Feedback" key="3">
                            <CustomerFeedback />
                        </TabPane>
                        <TabPane tab="ATC Admin message" key="4">
                            <CustomerMessages isAdmin={true} />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    );
}

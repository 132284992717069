import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';

const ImagePreviewModal = (props) => {
    const { isModalOpen, setModalOpenState, image } = props;
    return (
        <Modal
            className="modal-gallery"
            size="lg"
            centered
            isOpen={isModalOpen}
            toggle={setModalOpenState}>
            <ModalBody className="text-center bg-transparent">
                {image && image.url && <img src={image.url} alt="user" className="img-fluid" />}
            </ModalBody>
        </Modal>
    );
};

ImagePreviewModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func,
    image: PropTypes.object
};

export default ImagePreviewModal;

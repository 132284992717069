/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import Bookings from '../bookings';
import Back from '../../../../assets/images/back.svg';
import Caret from '../../../../assets/images/down-caret.svg';
import Down from '../../../../assets/images/caret.svg';
import White from '../../../../assets/images/white-caret.svg';
import { Link } from 'react-router-dom';

import './index.css';

export default function Summary() {
    //Scroll to the top function
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    //Function to get data from local storage
    var data = localStorage.getItem('data');
    data = JSON.parse(data);

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState('');

    const [product, setProduct] = useState(false);
    const [proSelected, setProSelected] = useState('');

    const changeDropDown = (key) => {
        setOpen(!open);
        setSelected(key);
    };

    const changeProduct = (key) => {
        setProduct(!product);
        setProSelected(key);
    };

    return (
        <Bookings>
            <div className="white-bg-r" style={{ marginBottom: 50 }}>
                <div className="d-flex justify-content-between">
                    <p style={{ fontWeight: 700, marginBottom: 0 }}>
                        1 week Calendar:
                        <span style={{ paddingLeft: 7, marginBottom: 0, fontWeight: 100 }}>
                            {data.calendar_title}{' '}
                        </span>
                    </p>

                    <Link to="/bookings">
                        <p style={{ color: '#333333', fontWeight: 900, paddingRight: 30 }}>
                            Back
                            <span style={{ paddingLeft: 7, fontWeight: 100 }}>
                                <img src={Back} alt="" />
                            </span>
                        </p>
                    </Link>
                </div>
                <hr style={{ marginTop: 0 }} />
                <div>
                    <p style={{ fontWeight: 900, paddingRight: 30 }}>
                        Booking Status:
                        <span style={{ paddingLeft: 7, fontWeight: 100 }}> {data.booking}</span>
                    </p>
                </div>
                <hr />
                <div>
                    <p style={{ fontWeight: 900, paddingRight: 30 }}>
                        Date:
                        <span style={{ paddingLeft: 7, fontWeight: 100 }}> {data.booking}</span>
                    </p>
                </div>
            </div>
            <div className="d-flex justify-content-between" style={{ marginBottom: 50 }}>
                <div className="white-bg-r" style={{ width: '100%', marginRight: 30 }}>
                    <p>No of Travellers: {data.no_of_trvellers} Adult</p>
                    <p style={{ fontSize: 12, color: '#A2792C' }}>Download details</p>
                </div>
                <div className="white-bg-r" style={{ width: '100%', paddingRight: 30 }}>
                    {data.travellers.map((item, index) => (
                        <>
                            <div
                                className={
                                    open && selected === index
                                        ? ''
                                        : 'd-flex justify-content-between'
                                }
                                style={{ width: '100%' }}>
                                {open && selected === index ? (
                                    <div className="d-inline">
                                        <div
                                            className="d-flex justify-content-between change-yellow"
                                            style={{ backgroundColor: '#E2C022', width: '100%' }}>
                                            <p style={{ fontWeight: '800', marginBottom: 0 }}>
                                                Traveller {index + 1}:{' '}
                                                <span style={{ fontWeight: '100' }}>
                                                    {' '}
                                                    {item.title}
                                                </span>
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    color: '#A2792C',
                                                    marginBottom: 0,
                                                    marginTop: 0
                                                }}>
                                                <span>
                                                    <img
                                                        className="cursor-pointer"
                                                        onClick={() => changeDropDown(index)}
                                                        width={
                                                            open && selected === index
                                                                ? '15px'
                                                                : '10px'
                                                        }
                                                        style={{ marginLeft: 10 }}
                                                        src={
                                                            open && selected === index
                                                                ? White
                                                                : White
                                                        }
                                                    />
                                                </span>
                                            </p>
                                        </div>
                                        <div
                                            className="d-flex justify-content-between"
                                            style={{ backgroundColor: '#fff', padding: 13 }}>
                                            <div>
                                                <p>
                                                    First Name: <span>{item.first_name}</span>
                                                </p>
                                                <p>
                                                    Middle Name: <span>{item.middle_name}</span>
                                                </p>
                                                <p>
                                                    Last Name: <span>{item.last_name}</span>
                                                </p>
                                                <p>
                                                    Age Group: <span>{item.age_group}</span>
                                                </p>
                                                <p>
                                                    Weight: <span>{item.weight}</span>
                                                </p>
                                                <p>
                                                    Height: <span>{item.height}</span>
                                                </p>
                                                <p>
                                                    Date of Birth: <span>{item.DOB}</span>
                                                </p>
                                                <p>
                                                    Passport Details:{' '}
                                                    <span>{item.passport_details}</span>
                                                </p>
                                            </div>
                                            <div>
                                                <p>
                                                    Arrival Flight Details:{' '}
                                                    <span>{item.arrival_flight_details}</span>
                                                </p>
                                                <p>
                                                    Departure Flight Details:{' '}
                                                    <span>{item.depature_flight_details}</span>
                                                </p>
                                                <p>
                                                    Arrival Ship Details:{' '}
                                                    <span>{item.last_name}</span>
                                                </p>
                                                <p>
                                                    Depature Ship Details:{' '}
                                                    <span>{item.depature_ship_details}</span>
                                                </p>
                                                <p>
                                                    Shore Excursion Details:{' '}
                                                    <span>{item.shore_excursion_detais}</span>
                                                </p>
                                                <p>
                                                    Arrival Train Details:{' '}
                                                    <span>{item.arrival_train_details}</span>
                                                </p>
                                                <p>
                                                    Departure Train Details:{' '}
                                                    <span>{item.departure_train_details}</span>
                                                </p>
                                                <p>
                                                    Passport Details:{' '}
                                                    <span>{item.passport_details}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <p
                                                className="cursor-pointer"
                                                style={{ color: '#A2792C' }}>
                                                Download Details
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <p style={{ fontWeight: '800' }}>
                                            Traveller {index + 1}:{' '}
                                            <span style={{ fontWeight: '100' }}> {item.title}</span>
                                        </p>
                                        <p
                                            className="cursor-pointer"
                                            style={{
                                                fontSize: 12,
                                                color: '#A2792C',
                                                marginBottom: 0,
                                                marginTop: 0
                                            }}>
                                            View details
                                            <span>
                                                <img
                                                    className="cursor-pointer"
                                                    onClick={() => changeDropDown(index)}
                                                    width={
                                                        open && selected === index ? '15px' : '10px'
                                                    }
                                                    style={{ marginLeft: 10 }}
                                                    src={open && selected === index ? Down : Caret}
                                                />
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                            <hr style={{ marginTop: 10, marginBottom: 10 }} />
                        </>
                    ))}
                </div>
            </div>

            <div className="d-flex justify-content-between">
                <div className="white-bg-r" style={{ width: '100%', marginRight: 30 }}>
                    <p>No of Products/Activities Booked: 3: {data.no_of_activities} Adult</p>
                    <p style={{ fontSize: 12, color: '#A2792C' }}>Download details</p>
                </div>
                <div className="white-bg-r" style={{ width: '100%', paddingRight: 30 }}>
                    {data.products.map((item, index) => (
                        <>
                            <div
                                className={
                                    product && proSelected === index
                                        ? ''
                                        : 'd-flex justify-content-between'
                                }
                                style={{ width: '100%' }}>
                                {product && proSelected === index ? (
                                    <div className="d-inline">
                                        <div
                                            className="d-flex justify-content-between change-yellow"
                                            style={{ backgroundColor: '#E2C022', width: '100%' }}>
                                            <p style={{ fontWeight: '800', marginBottom: 0 }}>
                                                Product:{' '}
                                                <span style={{ fontWeight: '100' }}>
                                                    {' '}
                                                    {item.name}
                                                </span>
                                            </p>
                                            <p
                                                style={{
                                                    fontSize: 12,
                                                    color: '#A2792C',
                                                    marginBottom: 0,
                                                    marginTop: 0
                                                }}>
                                                <span>
                                                    <img
                                                        className="cursor-pointer"
                                                        onClick={() => changeProduct(index)}
                                                        width={
                                                            product && proSelected === index
                                                                ? '15px'
                                                                : '10px'
                                                        }
                                                        style={{ marginLeft: 10 }}
                                                        src={
                                                            product && proSelected === index
                                                                ? White
                                                                : White
                                                        }
                                                    />
                                                </span>
                                            </p>
                                        </div>
                                        <div style={{ backgroundColor: '#fff', padding: 13 }}>
                                            <div>
                                                <p>
                                                    Date: <span>{item.date}</span>
                                                </p>
                                                <p>
                                                    Time: <span>{item.time}</span>
                                                </p>
                                                <p>
                                                    Main Destination:{' '}
                                                    <span>{item.main_destination}</span>
                                                </p>
                                                <p>
                                                    Description: <span>{item.description}</span>
                                                </p>
                                                <p>Language offered added: </p>
                                                <p>Inclusions & Exclusions added: </p>
                                            </div>
                                        </div>
                                        <div style={{ textAlign: 'center' }}>
                                            <p
                                                className="cursor-pointer"
                                                style={{ color: '#A2792C' }}>
                                                View Calendar
                                            </p>
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <p style={{ fontWeight: '800' }}>
                                            Traveller {index + 1}:{' '}
                                            <span style={{ fontWeight: '100' }}> {item.name}</span>
                                        </p>
                                        <p
                                            className="cursor-pointer"
                                            style={{
                                                fontSize: 12,
                                                color: '#A2792C',
                                                marginBottom: 0,
                                                marginTop: 0
                                            }}>
                                            View details
                                            <span>
                                                <img
                                                    className="cursor-pointer"
                                                    onClick={() => changeProduct(index)}
                                                    width={
                                                        product && proSelected === index
                                                            ? '15px'
                                                            : '10px'
                                                    }
                                                    style={{ marginLeft: 10 }}
                                                    src={
                                                        product && proSelected === index
                                                            ? Down
                                                            : Caret
                                                    }
                                                />
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                            <hr style={{ marginTop: 10, marginBottom: 10 }} />
                        </>
                    ))}
                </div>
            </div>
        </Bookings>
    );
}

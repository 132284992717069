import React from 'react';
import { Container, makeStyles, Grid } from '@material-ui/core';
import ReviewIcon from '../../../../assets/images/review.svg';
import LabelReview from '../../../../assets/images/label-review.svg';
import VendorDashboard from '../vendor-dashboard';
import DamiBtn from '../pricing/DamiBtn.jsx';
import Feedback from '../../../molecules/user-feedback';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal'
    },
    line1: {
        fontWeight: '600',
        fontSize: '30px',
        lineHeight: '37px',
        /* identical to box height */
        color: '#333333',
        marginTop: '63px',
        marginBottom: '23px'
    },
    line2: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px',
        paddingRight: '17px'
    },
    line3: {
        marginLeft: '17px',
        flexGrow: 1
    },
    line4: {
        marginTop: '17px',
        marginBottom: '18px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333'
    },
    line5: {
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '18px'
    },
    line6: {
        marginBottom: '40px',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#333333'
    },
    line7: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */
        color: '#333333',
        marginBottom: '19px'
    },
    line8: {
        marginBottom: '81px'
    },
    list: {
        paddingLeft: '20px'
    },
    listItem: {
        marginBottom: '10px',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#333333'
    },
    lastBtnGroup: {
        marginTop: '40px',
        marginBottom: '93px'
    }
}));

export default function Review() {
    const classes = useStyles();

    return (
        <VendorDashboard>
            <Container>
                <Grid container direction="column" className={classes.root}>
                    <Grid item className={classes.line1}>
                        Submit for review
                    </Grid>
                    <Grid item className={classes.line2}>
                        <Grid container alignItems="center">
                            <Grid item>
                                <img src={ReviewIcon} alt="review-icon" />
                            </Grid>
                            <Grid item className={classes.line3}>
                                <Grid
                                    container
                                    direction="column"
                                    spacing={2}
                                    justify="space-evenly">
                                    <Grid
                                        item
                                        style={{
                                            fontWeight: '600',
                                            fontSize: '14.5px',
                                            lineHeight: '18px',
                                            /* identical to box height */

                                            color: '#333333'
                                        }}>
                                        Customers calendar title
                                    </Grid>
                                    <Grid item container justify="space-between">
                                        <Grid item>
                                            <img
                                                src={LabelReview}
                                                alt="label-review-icon"
                                                style={{
                                                    marginRight: '8px',
                                                    fontSize: '16px',
                                                    lineHeight: '20px',
                                                    /* identical to box height */

                                                    color: '#333333'
                                                }}
                                            />
                                            Customers Calendar Quality Review cost
                                        </Grid>
                                        <Grid item>
                                            <span
                                                style={{
                                                    marginRight: '20px',
                                                    fontSize: '13.5px',
                                                    lineHeight: '16px',
                                                    textDecorationLine: 'line-through',

                                                    color: '#E70B0B'
                                                }}>
                                                $29.00
                                            </span>
                                            <span
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    lineHeight: '20px',
                                                    /* identical to box height */
                                                    color: '#333333'
                                                }}>
                                                $0.00
                                            </span>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Grid container justify="space-between">
                                            <Grid
                                                item
                                                style={{
                                                    fontWeight: '600',
                                                    fontSize: '16px',
                                                    lineHeight: '20px',
                                                    /* identical to box height */

                                                    color: '#333333'
                                                }}>
                                                Your total:
                                            </Grid>
                                            <Grid item>{`$0.00`}</Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.line4}>
                        You're eligible for a free customers calendar review!
                    </Grid>
                    <Grid item className={classes.line5}>
                        You're almost done! Now you can submit your customers calendar for review.
                    </Grid>
                    <Grid item className={classes.line6}>
                        Our team will review your product within 7 days to make sure it's set up to
                        succeed with our travelers. Learn more about what's included in the Product
                        Quality Review.
                    </Grid>
                    <Grid item className={classes.line7}>
                        Here's what you can expect next:
                    </Grid>
                    <Grid item className={classes.line8}>
                        <ul className={classes.list}>
                            <li className={classes.listItem}>
                                If your customers calendar does not require any changes, it will be
                                published within 7 days
                            </li>
                            <li className={classes.listItem}>
                                If we need to change or add information, we'll contact you by email
                            </li>
                            <li className={classes.listItem}>
                                Customers calendars that don't meet our guidelines will be rejected
                            </li>
                        </ul>
                    </Grid>
                    <Grid item container justify="space-between" className={classes.lastBtnGroup}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <DamiBtn text="Submit" />
                                </Grid>
                                <Grid item>
                                    <DamiBtn
                                        text="Save as draft"
                                        customStyle={{
                                            background: '#FFFFFF',
                                            border: 'none',
                                            color: '#A2792C'
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Feedback />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </VendorDashboard>
    );
}

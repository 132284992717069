import React from 'react';
import { Input, Radio, Select } from 'antd';
import { Loader } from 'components/atoms';
import PropTypes from 'prop-types';

const ExistingProduct = (props) => {
    //const { Option } = Select;
    const {
        onChangeProductSearchInput,
        onChangeProductCategory,
        productCategories,
        allProductSubCategories,
        onChangeProductSubCategory,
        loader,
        loading,
        existingProducts,
        setChoosedExistingProduct
    } = props;
    return (
        <>
            <hr className="d-none d-lg-block mb-14px mt-0 w-100" />
            <div className="d-flex flex-column flex-lg-row justify-content-lg-between m-n2">
                <div className="p-2 flex-shrink-0 order-last order-lg-first">
                    <Input
                        size="small"
                        suffix={
                            <svg
                                width="15"
                                height="15"
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.5209 13.6626L10.9508 9.94962C11.8687 8.85844 12.3717 7.48552 12.3717 6.05624C12.3717 2.71688 9.65479 0 6.31543 0C2.97607 0 0.259186 2.71688 0.259186 6.05624C0.259186 9.3956 2.97607 12.1125 6.31543 12.1125C7.56907 12.1125 8.76373 11.7344 9.78513 11.0166L13.3823 14.7578C13.5326 14.9139 13.7349 15 13.9516 15C14.1567 15 14.3513 14.9218 14.499 14.7796C14.8129 14.4776 14.8229 13.9768 14.5209 13.6626ZM6.31543 1.57989C8.78374 1.57989 10.7918 3.58793 10.7918 6.05624C10.7918 8.52456 8.78374 10.5326 6.31543 10.5326C3.84712 10.5326 1.83908 8.52456 1.83908 6.05624C1.83908 3.58793 3.84712 1.57989 6.31543 1.57989Z"
                                    fill="#A2792C"
                                />
                            </svg>
                        }
                        placeholder="Search by Product Name"
                        style={{ minWidth: 225 }}
                        onChange={onChangeProductSearchInput}
                    />
                </div>
                <div className="p-2 mb-n3 flex-shrink-0 order-first order-lg-last">
                    <div className="d-sm-flex mx-n2 align-items-center">
                        <div className="px-2 mb-2 mb-sm-3">
                            <span className="fs-13">Filter By</span>
                        </div>
                        <div className="px-2 mb-3 ml-sm-auto">
                            <Select
                                className="d-block w-100"
                                placeholder="Category"
                                size="small"
                                dropdownClassName="select-dropdown select-dropdown-sm"
                                // getPopupContainer={(trigger) => trigger.parentElement}
                                defaultValue={''}
                                style={{ minWidth: 160 }}
                                onChange={onChangeProductCategory}>
                                <Select.Option key={productCategories?.length || 9911} value="">
                                    {/*addedExtrafield "ALL" */}
                                    All
                                </Select.Option>
                                {productCategories &&
                                    productCategories.map((e, key) => {
                                        return (
                                            <Select.Option key={key} value={e.id}>
                                                {e.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </div>
                        {allProductSubCategories && (
                            <div className="px-2 mb-3">
                                {Object.keys(allProductSubCategories.productSubCategories).length >
                                    0 && (
                                    <Select
                                        className="d-block w-100"
                                        placeholder="Type"
                                        size="small"
                                        dropdownClassName="select-dropdown select-dropdown-sm"
                                        // getPopupContainer={(trigger) => trigger.parentElement}
                                        defaultValue={''}
                                        style={{ minWidth: 200 }}
                                        onChange={onChangeProductSubCategory}>
                                        <Select.Option
                                            key={productCategories?.length || 9911}
                                            value="">
                                            {/*addedExtrafield "ALL" */}
                                            All
                                        </Select.Option>
                                        {allProductSubCategories &&
                                            allProductSubCategories.productSubCategories &&
                                            allProductSubCategories.productSubCategories.map(
                                                (x, key) => {
                                                    return (
                                                        <Select.Option key={key} value={x.id}>
                                                            {x.name}
                                                        </Select.Option>
                                                    );
                                                }
                                            )}
                                    </Select>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div
                className="position-relative flex-grow-1 d-flex flex-column pr-3"
                style={{ overflowY: 'auto' }}>
                {loading && <Loader typeContainer={true} />}

                <div
                    className="flex-grow-1 scrollbar-fixed mt-3 pr-3 mr-n3"
                    style={{ overflowY: 'auto' }}>
                    <Radio.Group
                        className="d-flex flex-column"
                        // onChange={(e) => setAddProductFromExistingProducts(e.target.value)}
                        // value={addProductFromExistingProducts}
                    >
                        {existingProducts?.data &&
                            Array.isArray(existingProducts.data) &&
                            existingProducts.data.map((value, ind) => (
                                <Radio
                                    onClick={() => {
                                        setChoosedExistingProduct(value);
                                    }}
                                    key={ind}
                                    className="select-product-radio"
                                    value={ind}>
                                    {value.title ?? '(Unfinished product)'}
                                </Radio>
                            ))}
                    </Radio.Group>

                    {/* "Required for infinite scroll" Add Ref to Load More div  */}

                    <div className="" ref={loader} style={{ visibility: 'hidden' }}>
                        <p>Loading More</p> {/*hidden */}
                    </div>

                    {existingProducts?.meta?.next_page_url ? (
                        <div className="">{loading && <h5>Loading More...</h5>}</div>
                    ) : existingProducts?.meta?.total > 0 &&
                      existingProducts?.meta?.current === existingProducts?.meta?.last ? (
                        <div className="">
                            <h5>No more products!</h5>
                        </div>
                    ) : (
                        <div className="">
                            <h5>No Product added yet!</h5>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
ExistingProduct.propTypes = {
    loader: PropTypes.any,
    productCategories: PropTypes.array,
    loading: PropTypes.bool,
    onChangeProductSearchInput: PropTypes.func,
    onChangeProductCategory: PropTypes.func,
    allProductSubCategories: PropTypes.object,
    onChangeProductSubCategory: PropTypes.func,
    existingProducts: PropTypes.object,
    setChoosedExistingProduct: PropTypes.func
};
export default ExistingProduct;

import React, { Component } from 'react';
import { Col, Drawer, Form, Row } from 'antd';
import { Button } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import './index.scss';
import HelpIcon from 'assets/images/helpIcon.svg';
import Proptypes from 'prop-types';
import {
    updateMainProduct,
    fetchProductDetail,
    updateProductCovid,
    updateProductMaxAvailabilityAccomodation,
    updatePackageCovid,
    updatePackageMaxAvailabilityAccomodation,
    getCalendarsById,
    getPackageById,
    deletePackageCovid19
} from 'store/actions';
import {
    Spacer,
    AddCovidButton,
    AddReviewButton,
    ItinerariesPanel,
    SubmitButtonErrorPopover
} from 'components/atoms';
import {
    CovidModal,
    AddReviewsModal,
    AddAnItinerary,
    BookingPricingForm
} from 'components/molecules';
import {
    getItineraryState,
    validatePackageProductsPricings,
    setErrorsOnAntFormFinishFailed
} from 'utils';

class ItineraryForm extends Component {
    formRefActivity = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            category: '',
            isRecommended: null,
            isOpenModal: false,
            isOpenCovid: false,
            isOpenAddReviews: false,
            isOpenAddAccomodation: false,
            isOpenPricing: false,
            isOpenBookingTickets: false,
            calendarDay: 1,
            booking: null,
            reviews: null,
            accomodation: null,
            covid: null,
            pricing: null,
            pricingEdit: '',
            maxDailyAvailability: 1,
            showValidation: false
        };
    }

    componentDidMount() {}
    async componentDidUpdate(prevProp) {
        const {
            showModal,
            create_addon_product,
            fetchProductDetail,
            product_detail,
            get_calendar_data
        } = this.props;
        if (prevProp.showModal === false && showModal)
            if (product_detail?.id) {
                fetchProductDetail(product_detail?.id);
            } else if (create_addon_product?.product?.id) {
                fetchProductDetail(create_addon_product.product.id);
            }

        if (product_detail && prevProp.product_detail?.updated_at !== product_detail.updated_at) {
            let anotherStart = [''];
            if (product_detail.meetup_points && product_detail.meetup_points.length > 0) {
                anotherStart = product_detail.meetup_points;
                anotherStart.splice(0, 1);
                this.setState({ isCheckBox: 3 });
            }
            getItineraryState({ item: product_detail, anotherStart }).then((res) =>
                this.setState(res)
            );
        }

        if (
            (prevProp.showModal === false && showModal && get_calendar_data?.mainPackage) ||
            (get_calendar_data && prevProp.get_calendar_data !== get_calendar_data)
        ) {
            let anotherStart = [''];
            if (
                Array.isArray(get_calendar_data?.mainPackage) &&
                get_calendar_data?.mainPackage.length > 0
            )
                getItineraryState({
                    item: get_calendar_data.mainPackage[0],
                    anotherStart
                }).then((res) => this.setState(res));
        }
    }

    onClose = () => this.props.onClose();

    onOpenModal = (e) => this.setState({ isOpenModal: !this.state.isOpenModal, calendarDay: e });
    changeOpenAddReviews = () => this.setState({ isOpenAddReviews: !this.state.isOpenAddReviews });
    onRecommended = (value) => this.setState({ isRecommended: value });
    onOpenAnotherDrawers = (value) => this.onOpenModal(value);

    addCovidInformation = (value) => {
        const {
            updateProductCovid,
            product_detail,
            package_detail,
            updatePackageCovid,
            get_calendar_data
        } = this.props;

        if (package_detail && package_detail.id) {
            updatePackageCovid({
                data: {
                    covid_19_updates: value
                },
                id: package_detail.id
            }).then((res) => {
                if (res) this.setState({ covid: value, isOpenCovid: false });
                getPackageById(package_detail.id, get_calendar_data?.id);
            });
        } else {
            updateProductCovid({
                data: {
                    covid_19_updates: value
                },
                id: product_detail.id
            }).then((res) => {
                if (res?.payload?.data) this.setState({ covid: value, isOpenCovid: false });
            });
        }
    };

    onFinish = () => {
        const { isRecommended, maxDailyAvailability } = this.state;
        const {
            product_detail,
            updateProductMaxAvailabilityAccomodation,
            //updatePackageMaxAvailabilityAccomodation,
            package_detail,
            getCalendarsById,
            get_calendar_data
        } = this.props;

        if (package_detail?.pricings && Array.isArray(package_detail?.pricings)) {
            const validate = validatePackageProductsPricings(package_detail);
            if (validate && validate.status) {
                this.formRefActivity.current.setFields([
                    {
                        name: `productPricing`,
                        errors: [validate.message]
                    }
                ]);
                setErrorsOnAntFormFinishFailed(
                    { errorFields: [{ name: 'productPricing', errors: [validate.message] }] },
                    (value) => this.setState({ errors: value })
                );
                return;
            }
        } else {
            this.formRefActivity.current.setFields([
                {
                    name: `productPricing`,
                    errors: ['Please add a product first in package!']
                }
            ]);
            setErrorsOnAntFormFinishFailed(
                {
                    errorFields: [
                        {
                            name: 'productPricing',
                            errors: ['Please add a product first in package!']
                        }
                    ]
                },
                (value) => this.setState({ errors: value })
            );

            return;
        }

        if (package_detail && package_detail.id) {
            // updatePackageMaxAvailabilityAccomodation(
            //     {
            //         book_activity_without_accomodation: isRecommended,
            //         max_daily_availability: maxDailyAvailability
            //     },
            //     package_detail.id
            // ).then((res) => {
            //     if (res === true) this.onClose();
            this.onClose();
            getCalendarsById(get_calendar_data.id);
            //});
        } else {
            updateProductMaxAvailabilityAccomodation(
                {
                    book_activity_without_accomodation: isRecommended,
                    max_daily_availability: maxDailyAvailability
                },
                product_detail.id
            ).then((res) => {
                if (res === true) this.onClose();
            });
        }
    };

    toggleShowValidation = () => {
        this.setState((prev) => ({
            showValidation: !prev.showValidation
        }));
    };

    deletePackageCovidInfo = (id) => {
        this.props.deletePackageCovid19(id).then((res) => {
            if (res === true) this.setState({ covid: null });
        });
    };

    render() {
        const {
            requiredMark,
            //isRecommended,
            isOpenCovid,
            isOpenAddReviews,
            covid,
            reviews,
            calendarDay,
            showValidation
        } = this.state;

        const { product_detail, toggleOnEditPackageProduct, package_detail } = this.props;

        const isAddDisabled =
            Array.isArray(package_detail?.products) && package_detail?.products.length > 0
                ? false
                : true;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application activity-form"
                    onClose={this.onClose}
                    visible={this.props.showModal}>
                    <div className="header-itenary">
                        <h4 className="create-product-title">
                            {`Tell us about your ‘Main Product’ Package`}
                        </h4>
                        <Spacer top={10} />
                        <p className="mb-3">
                            Be sure to enter all key attractions in your itinerary. This helps
                            travelers find and book your experience.
                        </p>
                    </div>
                    <Form
                        layout="vertical"
                        initialValues={{
                            requiredMark
                        }}
                        name="activity-ref"
                        ref={this.formRefActivity}
                        form={this.formRefActivity.current}
                        onFinish={this.onFinish}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}
                        className="d-flex flex-column overflow-y-auto flex-grow-1 mr-n2"
                        onFinishFailed={(data) =>
                            setErrorsOnAntFormFinishFailed(data, (value) =>
                                this.setState({ errors: value })
                            )
                        }>
                        <div className="flex-grow-1 overflow-y-auto scrollbar-fixed pr-2">
                            <Row>
                                <Col xs={24}>
                                    <ItinerariesPanel
                                        onOpenModal={this.onOpenModal}
                                        isEdit={(value) =>
                                            this.setState({ isOpenModal: true, calendarDay: value })
                                        }
                                        onEditPackageCategory={this.props.onEditPackageCategory}
                                        productDetail={product_detail || {}}
                                        toggleAddItineraryModal={this.props.toggleAddItineraryModal}
                                        setAddProductsInitModal={this.props.setAddProductsInitModal}
                                        toggleOnEditPackageProduct={toggleOnEditPackageProduct}
                                    />

                                    <AddAnItinerary
                                        isOpen={this.state.isOpenModal}
                                        onClose={this.onOpenModal}
                                        onOpenAnotherDrawer={this.onOpenAnotherDrawers}
                                        calendarDay={calendarDay}
                                    />

                                    <Spacer top={20} />
                                    {isAddDisabled && showValidation && (
                                        <span className="text-danger">
                                            Please add atleast one product first!
                                        </span>
                                    )}

                                    <BookingPricingForm
                                        state={this.state}
                                        setState={(objOrfunc) => this.setState(objOrfunc)}
                                        formRef={this.formRefActivity}
                                        previousSelectedSubCategory={
                                            product_detail.productSubCategory ?? {}
                                        }
                                        isAddDisabled={isAddDisabled}
                                        toggleShowValidation={this.toggleShowValidation}
                                        isForPackage={true}
                                    />

                                    <Form.Item name="covid">
                                        <AddCovidButton
                                            data={covid ? covid : null}
                                            onDelete={() =>
                                                this.deletePackageCovidInfo(package_detail?.id)
                                            }
                                            name="Covid-19 Updates"
                                            onClick={() => this.setState({ isOpenCovid: true })}
                                            isAddDisabled={isAddDisabled}
                                            toggleShowValidation={this.toggleShowValidation}
                                        />
                                    </Form.Item>

                                    <CovidModal
                                        isOpen={isOpenCovid}
                                        onClose={() => this.setState({ isOpenCovid: false })}
                                        data={covid}
                                        calendarData={this.props.calendar_data}
                                        onSave={(value) => this.addCovidInformation(value)}
                                        submitButtonName="Save"
                                        hideImport
                                    />
                                    {/* <p>
                                        This will indicate the slots available to travelers and once
                                        this is reached, this product activtity will be marked as
                                        sold out for that day
                                    </p>

                                    <InputNumber
                                        className="input-number"
                                        value={this.state.maxDailyAvailability}
                                        min={1}
                                        onChange={(value) => {
                                            this.formRefActivity.current.setFieldsValue({
                                                maxDailyAvailability: value
                                            });
                                            this.setState({ maxDailyAvailability: value });
                                        }}
                                    /> */}

                                    {/* <p>Can travelers book this activity without acoomodation?</p>

                                    <p style={{ color: '#A2792C' }}>
                                        Yes is recommeded if travelers can take part in this
                                        activity (Itineraries) without accomodation.
                                    </p>

                                    <div className="group-radio">
                                        <div
                                            className="cursor-pointer radio-btn"
                                            style={{
                                                backgroundColor: isRecommended ? '#a2792c' : '#fff',
                                                color: isRecommended ? '#fff' : '#000'
                                            }}
                                            tabIndex="0"
                                            role="button"
                                            onKeyDown={() => {
                                                this.onRecommended(true);
                                            }}
                                            onClick={() => {
                                                this.onRecommended(true);
                                            }}>
                                            Yes
                                        </div>
                                        <div
                                            className="cursor-pointer radio-btn"
                                            style={{
                                                backgroundColor:
                                                    !isRecommended && isRecommended !== null
                                                        ? '#a2792c'
                                                        : '#fff',
                                                color:
                                                    !isRecommended && isRecommended !== null
                                                        ? '#fff'
                                                        : '#000'
                                            }}
                                            tabIndex="0"
                                            role="button"
                                            onKeyDown={() => {
                                                this.onRecommended(false);
                                            }}
                                            onClick={() => {
                                                this.onRecommended(false);
                                            }}>
                                            No
                                        </div>
                                    </div> */}
                                    <AddReviewButton
                                        name="Add Reviews"
                                        data={reviews}
                                        onDelete={() => this.setState({ reviews: null })}
                                        isRecommended={true}
                                        onClick={() => this.setState({ isOpenAddReviews: true })}
                                        isAddDisabled={isAddDisabled}
                                        toggleShowValidation={this.toggleShowValidation}
                                    />

                                    <AddReviewsModal
                                        isOpen={isOpenAddReviews}
                                        onSave={(value) => {
                                            this.setState({
                                                reviews: value,
                                                isOpenAddReviews: false
                                            });
                                            getPackageById(
                                                package_detail?.id,
                                                this.props.get_calendar_data?.id
                                            );
                                        }}
                                        onClose={this.changeOpenAddReviews}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="footer-button">
                            <SubmitButtonErrorPopover
                                visible={this.state.errors || false}
                                errors={this.state.errors}>
                                <Button
                                    color="primary btn-update"
                                    type="submit"
                                    disabled={isAddDisabled}>
                                    Update Calender & Close
                                </Button>
                            </SubmitButtonErrorPopover>

                            <Button color="dark" className="help-btn" onClick={() => {}}>
                                <img src={HelpIcon} alt="" /> Help
                            </Button>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}
ItineraryForm.propTypes = {
    onClose: Proptypes.func,
    onOpenAnotherDrawer: Proptypes.func,
    updateMainProduct: Proptypes.func,
    showModal: Proptypes.bool,
    calendar_data: Proptypes.object,
    create_calendar: Proptypes.object,
    onEditPackageCategory: Proptypes.func,
    fetchProductDetail: Proptypes.func,
    create_addon_product: Proptypes.object,
    product_detail: Proptypes.object,
    toggleAddItineraryModal: Proptypes.func,
    updateProductCovid: Proptypes.func,
    product_categories: Proptypes.array,
    setAddProductsInitModal: Proptypes.func,
    updateProductMaxAvailabilityAccomodation: Proptypes.func,
    package_detail: Proptypes.object,
    updatePackageCovid: Proptypes.func,
    updatePackageMaxAvailabilityAccomodation: Proptypes.func,
    get_calendar_data: Proptypes.object,
    getCalendarsById: Proptypes.func,
    toggleOnEditPackageProduct: Proptypes.func,
    deletePackageCovid19: Proptypes.func
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    create_calendar: state.calendar.create_calendar,
    create_addon_product: state.calendar.create_addon_product,
    product_detail: state.product.product_detail,
    product_categories: state.calendar.product_categories,
    package_detail: state.product.package_detail,
    get_calendar_data: state.calendar.get_calendar_data?.data
});

const mapDispatch = (dispatch) => ({
    updateMainProduct: (id, data) => dispatch(updateMainProduct(id, data)),
    fetchProductDetail: (id) => dispatch(fetchProductDetail(id)),
    updateProductCovid: (data) => dispatch(updateProductCovid(data)),
    updateProductMaxAvailabilityAccomodation: (data, id) =>
        dispatch(updateProductMaxAvailabilityAccomodation(data, id)),
    updatePackageCovid: (data) => dispatch(updatePackageCovid(data)),
    updatePackageMaxAvailabilityAccomodation: (data, id) =>
        dispatch(updatePackageMaxAvailabilityAccomodation(data, id)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId)),
    deletePackageCovid19: (id) => dispatch(deletePackageCovid19(id))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ItineraryForm));

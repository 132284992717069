import React, { Component } from 'react';
import Bookings from '../bookings';
import Downloaded from '../../../../assets/images/download.svg';
import TextDropDown from '../../../atoms/text-dropdown';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';
import Email from '../../../../assets/images/email.svg';
import Back from '../../../../assets/images/back.svg';

import './index.css';
import { DatePicker } from 'antd';
import { Link } from 'react-router-dom';
// import { DateRangePicker } from 'react-dates';

const { RangePicker } = DatePicker;

const data = ['Travel date', 'Booking date'];

const datum = ['Excel', 'Csv'];
const day = ['Nightly', 'Never'];

const email = ['neville.chukumah@gmail.com', 'odusol@bimbiphillips@gmail.com'];

const type = ['Bookings made in the last 24 hours', 'Bookings for the following day'];

export default class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focusedInput: null,
            dropdown: false,
            selected: ' Choose one',
            format: 'Excel',
            email: 'Never',
            report: 'Choose one',
            showOnly: 'Bookings made in the last 24hours',
            type: 'Excel',
            typebool: false,
            showbool: false,
            emailreport: false,
            more: false
        };

        this.passData = this.passData.bind(this);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }
    passData = (key) => {
        this.setState({ selected: key });
    };

    passFormat = (key) => {
        this.setState({
            format: key
        });
    };

    dropBar(key) {
        this.setState((prevState) => ({
            email: key,
            more: !prevState.more
        }));
    }

    changeReport(key) {
        this.setState((prevState) => ({
            report: key,
            emailreport: !prevState.emailreport
        }));
    }

    changeOnly(key) {
        this.setState((prevState) => ({
            showOnly: key,
            showbool: !prevState.showbool
        }));
    }

    changeType(key) {
        this.setState((prevState) => ({
            type: key,
            typebool: !prevState.typebool
        }));
    }

    open = () => {
        this.setState((prevState) => ({
            more: !prevState.more
        }));
    };

    render() {
        return (
            <Bookings>
                <div className="d-flex justify-content-end pad-cont">
                    <Link to="/bookings">
                        <p style={{ fontWeight: 900, paddingRight: 30 }}>
                            Back
                            <span style={{ paddingLeft: 7, fontWeight: 100 }}>
                                <img alt="" src={Back} />
                            </span>
                        </p>
                    </Link>
                </div>

                <div className="d-flex justify-content-between" style={{ marginRight: '15%' }}>
                    <div style={{ width: '50%', paddingRight: '10%' }}>
                        <div className="d-flex">
                            <img alt="" src={Downloaded} />
                            <div className="align-self-end" style={{ paddingLeft: 20 }}>
                                <h5>Download bookings details</h5>
                            </div>
                        </div>
                        <div style={{ paddingLeft: '15%' }}>
                            <div style={{ paddingTop: '40px' }}>
                                <p>Show only</p>
                                <TextDropDown
                                    widthy="100%"
                                    name={this.state.selected}
                                    className="input-bar-product"
                                    caret={Caret}
                                    down={Down}
                                    class={'image-icon-down'}
                                    icon={'image-icon'}>
                                    {data.map((item, i) => (
                                        <TextDropDown
                                            key={i}
                                            click={() => this.passData(item)}
                                            widthy="100%"
                                            name={item}
                                            className="input-bar-product-drop-item"
                                        />
                                    ))}
                                </TextDropDown>
                            </div>

                            <div style={{ paddingTop: '40px' }}>
                                <p>Date range</p>
                                <RangePicker
                                    placeholder={['From', 'To']}
                                    onChange={this.handleDateChange}
                                    allowClear={false}
                                    style={{
                                        height: '37px',
                                        width: '100%',
                                        border: '0.5px solid #979797',
                                        borderRadius: '6px',
                                        cursor: 'pointer',
                                        fontSize: '17px',
                                        margin: '0px',
                                        paddingLeft: '15px',
                                        paddingRight: '15px',
                                        backgroundColor: '#fff'
                                    }}
                                />
                            </div>
                            <div style={{ paddingTop: '40px' }}>
                                <p>Format</p>
                                <TextDropDown
                                    widthy="100%"
                                    name={this.state.format}
                                    className="input-bar-product"
                                    caret={Caret}
                                    down={Down}
                                    class={'image-icon-down'}
                                    icon={'image-icon'}>
                                    {datum.map((item, i) => (
                                        <TextDropDown
                                            key={i}
                                            click={() => this.passFormat(item)}
                                            widthy="100%"
                                            name={item}
                                            className="input-bar-product-drop-item"
                                        />
                                    ))}
                                </TextDropDown>
                            </div>

                            <div style={{ paddingTop: '40px' }}>
                                <button className="btn-download">Download</button>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '50%', paddingLeft: '10%' }}>
                        <div>
                            <div className="d-flex">
                                <img alt="" src={Email} />
                                <div className="align-self-end" style={{ paddingLeft: 20 }}>
                                    <h5>Email bookings details</h5>
                                </div>
                            </div>
                            <div style={{ paddingTop: '40px', paddingLeft: '15%' }}>
                                <p>Frequency</p>
                                <TextDropDown
                                    widthy="100%"
                                    click={this.open}
                                    name={this.state.email}
                                    className="input-bar-product"
                                    caret={Caret}
                                    down={Down}
                                    class={'image-icon-down'}
                                    icon={'image-icon'}>
                                    {day.map((item, i) => (
                                        <TextDropDown
                                            key={i}
                                            click={() => this.dropBar(item)}
                                            widthy="100%"
                                            name={item}
                                            className="input-bar-product-drop-item"
                                        />
                                    ))}
                                </TextDropDown>
                            </div>

                            {this.state.more && (
                                <>
                                    <div style={{ paddingTop: '40px', paddingLeft: '15%' }}>
                                        <p>Email report to</p>
                                        <TextDropDown
                                            widthy="100%"
                                            name={this.state.report}
                                            className="input-bar-product"
                                            caret={Caret}
                                            down={Down}
                                            class={'image-icon-down'}
                                            icon={'image-icon'}>
                                            {email.map((item, i) => (
                                                <TextDropDown
                                                    key={i}
                                                    click={() => this.changeReport(item)}
                                                    widthy="100%"
                                                    name={item}
                                                    className="input-bar-product-drop-item"
                                                />
                                            ))}
                                        </TextDropDown>
                                    </div>
                                    <div style={{ paddingTop: '40px', paddingLeft: '15%' }}>
                                        <p>Show Only</p>
                                        <TextDropDown
                                            widthy="100%"
                                            name={this.state.showOnly}
                                            className="input-bar-product"
                                            caret={Caret}
                                            down={Down}
                                            class={'image-icon-down'}
                                            icon={'image-icon'}>
                                            {type.map((item, i) => (
                                                <TextDropDown
                                                    key={i}
                                                    click={() => this.changeOnly(item)}
                                                    widthy="100%"
                                                    name={item}
                                                    className="input-bar-product-drop-item"
                                                />
                                            ))}
                                        </TextDropDown>
                                    </div>
                                    <div style={{ paddingTop: '40px', paddingLeft: '15%' }}>
                                        <p>Format</p>
                                        <TextDropDown
                                            widthy="100%"
                                            name={this.state.type}
                                            className="input-bar-product"
                                            caret={Caret}
                                            down={Down}
                                            class={'image-icon-down'}
                                            icon={'image-icon'}>
                                            {datum.map((item, i) => (
                                                <TextDropDown
                                                    key={i}
                                                    click={() => this.changeType(item)}
                                                    widthy="100%"
                                                    name={item}
                                                    className="input-bar-product-drop-item"
                                                />
                                            ))}
                                        </TextDropDown>
                                    </div>
                                </>
                            )}
                            <div style={{ paddingTop: '40px', paddingLeft: '15%' }}>
                                <button className="btn-download">Save settings</button>
                            </div>
                        </div>
                    </div>
                </div>
            </Bookings>
        );
    }
}

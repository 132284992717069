import React from 'react';
import PropTypes from 'prop-types';

const CalendarEventCarouselArrow = ({ onClick, type }) => {
    if (type === 'left') {
        return (
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    if (onClick) onClick();
                }}
                aria-label="Go to previous slide"
                className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
                type="button"></button>
        );
    } else {
        return (
            <button
                onClick={(e) => {
                    e.stopPropagation();
                    if (onClick) onClick();
                }}
                aria-label="Go to next slide"
                className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
                type="button"></button>
        );
    }
};

CalendarEventCarouselArrow.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string
};

export default CalendarEventCarouselArrow;

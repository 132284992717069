import React, { useState, useEffect } from 'react';
import { Grid, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreText from './MoreText.jsx';
import AgeGroups from './AgeGroups.jsx';
import DepressableAnswer from './DepressableAnswer.jsx';
import DamiBtn from './DamiBtn.jsx';
// import HelpIcon from '../../../../assets/images/helpIcon.svg';
import SharingGroups from './SharingGroups.jsx';
import InfoIcon from '../../../../assets/images/info.svg';
import { Link } from 'react-router-dom';
import DaysSelector from './DaysSelector.jsx';
import VendorDashboard from '../vendor-dashboard/index.js';
import Feedback from '../../../molecules/user-feedback';

const useStyles = makeStyles((theme) => ({
    root: {
        flexDirection: 'column',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        padding: '33px 0 0 0'
    },
    heading: {
        fontWeight: '600',
        fontSize: '24px',
        lineHeight: '29px',
        marginBottom: '17px'
    },
    transact: {
        marginBottom: '79px'
    },
    heading1: {
        fontWeight: '600',
        fontSize: '20px',
        lineHeight: '24px',
        marginTop: '49px',
        marginBottom: '37px'
    },
    heading2: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        marginBottom: '17px'
    },
    heading3: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        marginTop: '35px',
        marginBottom: '17px'
    },
    input: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '89px',
        height: '48px'
    },
    note: {
        fontWeight: '600',
        fontSize: '15.5px',
        lineHeight: '19px',
        /* identical to box height */

        textTransform: 'capitalize',

        color: '#333333'
    },
    noteText: {
        fontWeight: '500',
        fontSize: '16px',
        lineHeight: '20px',

        color: '#4F4F4F'
    },
    noteBox: {
        marginBottom: '75px',
        marginTop: '17px'
    },
    accomodation: {
        marginTop: '60px',
        marginBottom: '16px'
    },
    accomodationQuestion: {
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        /* identical to box height */

        color: '#333333',
        marginBottom: '13px'
    },
    lastBtnGroup: {
        marginTop: '40px',
        marginBottom: '93px'
    },
    addSupplement: {
        marginTop: '33px',
        marginBottom: '16px'
    },
    perPerson: {
        marginTop: '16px'
    },
    moreDetails: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px',
        padding: '14px 29px'
    },
    accomodationTitle: {
        fontSize: '20px',
        lineHeight: '24px',
        fontWeight: '600',
        color: '#333333'
    },
    recommendedPricing: {
        fontWeight: '600',
        fontSize: '18px',
        lineHeight: '22px',
        /* identical to box height */

        color: '#333333'
    },
    recommended: {
        background: '#A2792C',
        borderRadius: '8px',
        fontWeight: '600',
        fontSize: '16px',
        lineHeight: '20px',
        padding: '5px 14px',
        color: '#FFFFFF'
    },
    singleSupplement: {
        marginBottom: '13px'
    },
    extraCharge: {
        marginBottom: '13px'
    }
}));

export default function Pricing() {
    const [accomodationWithPricing, setAccomodationWithPricing] = useState();
    const [supplement, setSupplement] = useState(false);

    useEffect(() => {
        if (accomodationWithPricing && accomodationWithPricing === 1) {
            setSupplement(false);
        }
        if (accomodationWithPricing && accomodationWithPricing === 0) {
            setSupplement(true);
        }
    }, [accomodationWithPricing]);

    const classes = useStyles();

    const changeAnswer = (index) => {
        setAccomodationWithPricing(index);
    };

    const addSupplements = () => {
        setSupplement(true);
    };

    return (
        <VendorDashboard>
            <Container>
                <Grid container className={classes.root}>
                    <Grid item>
                        <div className={classes.heading}>
                            Add Pricing details for your ‘Product Title’ activity
                        </div>
                        <div
                            style={{
                                marginBottom: '24px'
                            }}>
                            <MoreText
                                customStyle={{
                                    fontFamily: 'Poppins',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    /* identical to box height */

                                    color: '#A2792C'
                                }}
                                text="I will import pricing details information from my other activity "
                            />
                        </div>
                    </Grid>
                    <Grid item className={classes.transact}>
                        We transact using USD, however travelers can always see prices in their
                        local currency.
                    </Grid>
                    <Grid item>
                        <hr />
                    </Grid>
                    <Grid item>
                        <div className={classes.heading1}>
                            Activities pricing details excluding accomodation
                        </div>
                    </Grid>
                    <Grid item>
                        <div className={classes.heading2}>
                            Define the age groups that can participate with your price per person
                            (PP)
                        </div>
                    </Grid>
                    <Grid item style={{ maxWidth: '100%' }}>
                        <AgeGroups />
                    </Grid>
                    <Grid item>
                        <div className={classes.heading3}>
                            Enter the minimum number of travelers allowed in a single booking
                        </div>
                        <input className={classes.input} type="number" variant="outlined" min={1} />
                    </Grid>
                    <Grid item>
                        <div className={classes.heading3}>
                            Enter the maximum number of travelers allowed in a single booking
                        </div>
                        <input className={classes.input} type="number" variant="outlined" min={1} />
                    </Grid>
                    <Grid item>
                        <div className={classes.heading3}>Select the days these prices apply</div>
                        <div className={classes.heading3}>
                            For example, you can have different weekday and weekend prices.
                        </div>
                    </Grid>
                    <Grid item>
                        <DaysSelector />
                    </Grid>
                    <Grid item className={classes.noteBox}>
                        <span className={classes.note}>Note: </span>
                        <span className={classes.noteText}>
                            After you click done, you can add another pricing details if you have a
                            different price that applies for a specific day you have not selected.
                        </span>
                    </Grid>
                    <Grid item>
                        <hr />
                    </Grid>
                    <Grid item container className={classes.accomodation}>
                        <Grid item className={classes.accomodationQuestion}>
                            <p>Is your accomodation included in your activity pricing?</p>
                        </Grid>
                        <Grid item container spacing={2}>
                            {['Yes', 'No'].map((each, index) => (
                                <Grid
                                    item
                                    key={index}
                                    onClick={() => {
                                        changeAnswer(index);
                                    }}>
                                    <DepressableAnswer
                                        key={index}
                                        index={index}
                                        text={each}
                                        accomodationWithPricing={accomodationWithPricing}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                        {accomodationWithPricing === 0 ? (
                            <Grid item className={classes.addSupplement} onClick={addSupplements}>
                                <MoreText
                                    customStyle={{
                                        fontFamily: 'Poppins',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        /* identical to box height */

                                        color: '#A2792C'
                                    }}
                                    text="Add Single Supplement"
                                />
                            </Grid>
                        ) : null}
                    </Grid>
                    {supplement ? (
                        <Grid item className={classes.perPerson}>
                            <Grid container>
                                <Grid item className={classes.singleSupplement}>
                                    Per person sharing - Single Supplement
                                </Grid>
                                <Grid item className={classes.extraCharge}>
                                    This is an extra charge applied to a traveler who travels alone
                                    but will use a room and facilities that cater for two.
                                </Grid>
                                <Grid item>
                                    <SharingGroups />
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : null}
                    {accomodationWithPricing === 1 ? (
                        <Grid item className={classes.addSupplement}>
                            <p className={classes.accomodationTitle}>
                                Add accomodation pricing to Activities pricing details above
                            </p>
                            <div className={classes.moreDetails}>
                                <Grid container justify="space-between">
                                    <Grid item className={classes.recommendedPricing}>
                                        Accommodations Pricing
                                        <img src={InfoIcon} />
                                    </Grid>
                                    <Grid item className={classes.recommended}>
                                        RECOMMENDED
                                    </Grid>
                                </Grid>
                                <div style={{ marginTop: '18px', marginBottom: '18px' }}>
                                    <hr />
                                </div>
                                <Link to="/accomodation-pricing" style={{ textDecoration: 'none' }}>
                                    <Grid container>
                                        <MoreText
                                            customStyle={{
                                                fontFamily: 'Poppins',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                fontSize: '16px',
                                                lineHeight: '20px',
                                                /* identical to box height */

                                                color: '#333333'
                                            }}
                                            text="Add accommodation price to activities"
                                        />
                                    </Grid>
                                </Link>
                            </div>
                        </Grid>
                    ) : null}
                    <Grid item>
                        <hr />
                    </Grid>
                    <Grid item container justify="space-between" className={classes.lastBtnGroup}>
                        <Grid item>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <DamiBtn text="Done" />
                                </Grid>
                                <Grid item>
                                    <DamiBtn
                                        text="Cancel"
                                        customStyle={{ background: '#FFFFFF', color: '#A2792C' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Feedback />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </VendorDashboard>
    );
}

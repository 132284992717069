/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button, Col, Row } from 'reactstrap';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import { MoreInfoTooltip } from 'components/atoms';

export default function AddRoomButton(props) {
    const { add = 'Add', moreInfo = true } = props;

    return (
        <div className="add-breakfast">
            <div className="card-head">
                <div style={{ display: 'flex' }}>
                    <div className="title">
                        {props.name}
                        {moreInfo ? (
                            <MoreInfoTooltip
                                title={`This refers to the type of bed available in the room`}
                            />
                        ) : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                <Button color="link" onClick={props.onClick}>
                    <img className="img-plus" src={Plus} alt="" /> {add}
                </Button>
            </div>
            {props.data ? (
                <Row className="actions">
                    <Col sm={10}>
                        <div>
                            {props.data.pp_sharing ? (
                                <div>
                                    Per person Sharing
                                    <ul>
                                        {props.data.pp_sharing.double.double_bed > 0 ? (
                                            <li>
                                                Double bed x
                                                {props.data.pp_sharing.double.double_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_sharing.double.large_bed > 0 ? (
                                            <li>
                                                Large bed(King size) x
                                                {props.data.pp_sharing.double.large_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_sharing.double.xl_double_bed > 0 ? (
                                            <li>
                                                Extra-large double bed (Super-king-size) x
                                                {props.data.pp_sharing.double.xl_double_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_sharing.double.sofa_bed > 0 ? (
                                            <li>
                                                Sofa bed x{props.data.pp_sharing.double.sofa_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_sharing.double.futon_mat > 0 ? (
                                            <li>
                                                Futon Mat x{props.data.pp_sharing.double.futon_mat}
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            {props.data.pp_single ? (
                                <div>
                                    Per person Single room occupancy
                                    <ul>
                                        {props.data.pp_single.double.double_bed > 0 ? (
                                            <li>
                                                Double bed x{props.data.pp_single.double.double_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_single.double.large_bed > 0 ? (
                                            <li>
                                                Large bed(King size) x
                                                {props.data.pp_single.double.large_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_single.double.xl_double_bed > 0 ? (
                                            <li>
                                                Extra-large double bed (Super-king-size) x
                                                {props.data.pp_single.double.xl_double_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_single.double.sofa_bed > 0 ? (
                                            <li>
                                                Sofa bed x{props.data.pp_single.double.sofa_bed}
                                            </li>
                                        ) : null}
                                        {props.data.pp_single.double.futon_mat > 0 ? (
                                            <li>
                                                Futon Mat x{props.data.pp_single.double.futon_mat}
                                            </li>
                                        ) : null}
                                    </ul>
                                </div>
                            ) : null}
                        </div>
                        <div>
                            {props.data.no_of_bathrooms
                                ? `Bathrooms x${props.data.no_of_bathrooms}`
                                : null}
                        </div>
                    </Col>

                    <Col sm={2} className="group-btn">
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={props.onDelete}
                            className="itinerary-img cursor-pointer"
                        />
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
AddRoomButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any
};

import React, { useEffect } from 'react';
import VendorDashboard from '../vendor-dashboard';
import Calendar from '../../../../assets/images/calendar.svg';
import { NavLink } from 'react-router-dom';
import { progress } from '../../../../store/actions/progress';
import { useDispatch } from 'react-redux';
import Feedback from '../../../molecules/user-feedback';

export default function CalendarDashboard() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const dispatch = useDispatch();
    return (
        <VendorDashboard>
            <div className="row background-sz" style={{ paddingTop: '40px' }}>
                <div className="col-md-7">
                    <h3 style={{ fontSize: '20px', fontWeight: 900 }}>
                        Step 1 of 4: Customers Calendar
                    </h3>
                    <p style={{ fontSize: '15px' }}>
                        First, enter the calendar title you will use to compile your products on
                        your customer’s plan.{' '}
                    </p>
                    <p style={{ fontSize: '15px' }}>
                        Then create your products by entering their title and category.{' '}
                    </p>
                    <NavLink to="/get-started">
                        <button
                            onClick={() => dispatch(progress())}
                            style={{ marginTop: '50px' }}
                            className="add-button cursor-pointer">
                            {' '}
                            Create Customers Calendar
                        </button>
                    </NavLink>
                </div>
                <div className="col-md-5">
                    <img width="120px" src={Calendar} alt="" />
                </div>
                <div style={{ float: 'right', position: 'relative' }}>
                    <Feedback />
                </div>
            </div>
        </VendorDashboard>
    );
}

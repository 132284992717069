import React, { Component } from 'react';
import { Form, Drawer, Input } from 'antd';
import { Button, ButtonGroup } from 'reactstrap';

import './index.scss';
import Spacer from '../../../atoms/spacer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
// import HelpIcon from '../../../../assets/images/helpIcon.svg';
import ItenaryCalenderSeason from '../../../atoms/itenary-calender-season';
import RangeMonthPicker from '../../../atoms/range-month-picker';
import Prompter from '../../../atoms/prompter';
import ImportItineraries from '../../../atoms/importItineraries';
import DeleteIcon from '../../../../assets/images/close.svg';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../modals/confirmation-modal';
import { Feedback } from 'components/molecules';
import { compareCalendarSeason } from 'utils';

const month = [
    { name: 'January' },
    { name: 'February' },
    { name: 'March' },
    { name: 'April' },
    { name: 'May' },
    { name: 'June' },
    { name: 'July' },
    { name: 'August' },
    { name: 'September' },
    { name: 'October' },
    { name: 'November' },
    { name: 'December' }
];

class CalendarSeason extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            radioButton: 1,
            bestTimeToVisit: [
                {
                    from: null,
                    to: null,
                    reason: null
                }
            ],
            highSeasonToVisit: [
                {
                    from: null,
                    to: null,
                    reason: null
                }
            ],
            bestWeatherToVisit: [
                {
                    from: null,
                    to: null,
                    reason: null
                }
            ],
            calendarSeason: [
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                },
                {
                    time: false,
                    highSeason: false,
                    weather: false
                }
            ],
            err: false,
            saveType: [],
            confirmationModal: false
        };
    }
    componentDidMount() {
        const { data } = this.props;
        if (data !== null) this.onLoad(data);
    }

    componentDidUpdate(prevProps, prevState) {
        const { data, onSaveType } = this.props;
        const { saveType, bestTimeToVisit, bestWeatherToVisit, highSeasonToVisit } = this.state;
        if (prevProps.data !== data && data !== null && data) {
            this.onLoad(data);
        }
        if (prevState.saveType !== saveType) {
            onSaveType(saveType);
        }

        if (Array.isArray(bestTimeToVisit))
            this.validatingRequiredFileds(bestTimeToVisit, 'bestTime');

        if (Array.isArray(bestWeatherToVisit))
            this.validatingRequiredFileds(bestWeatherToVisit, 'bestWeather');

        if (Array.isArray(highSeasonToVisit))
            this.validatingRequiredFileds(highSeasonToVisit, 'highTime');
    }

    validatingRequiredFileds = async (data, name) => {
        if (this.formRef.current) {
            for (let [ind, field] of data.entries()) {
                if (field.to === null || field.from === null) {
                    this.formRef.current.setFieldsValue({
                        [`${name}ToFrom${ind}`]: '',
                        [`${name}Reason${ind}`]: field.reason
                    });
                    this.formRef.current.validateFields([
                        `${name}ToFrom${ind}`,
                        `${name}Reason${ind}`
                    ]);
                } else {
                    this.formRef.current.setFieldsValue({
                        [`${name}ToFrom${ind}`]: { to: field.to, from: field.from },
                        [`${name}Reason${ind}`]: field.reason
                    });
                }
            }

            const isVisitfilled = data.find(
                (element) =>
                    element.to === null ||
                    element.from === null ||
                    element.reason === '' ||
                    element.reason === null
            );
            this.formRef.current.setFieldsValue({ [name]: isVisitfilled ? '' : name });
        }
    };

    onLoad = (data) => {
        const defaultData = [
            {
                from: null,
                to: null,
                reason: null
            }
        ];
        this.setState({
            bestTimeToVisit: data?.best_time
                ? JSON.parse(JSON.stringify(data?.best_time))
                : defaultData,
            highSeasonToVisit: data?.high_season
                ? JSON.parse(JSON.stringify(data?.high_season))
                : defaultData,
            bestWeatherToVisit: data?.best_weather
                ? JSON.parse(JSON.stringify(data?.best_weather))
                : defaultData
        });
        setTimeout(() => this.isAdded(), 1);
    };
    onClose = () => this.props.onClose();
    //saving range month seasons
    onSave = () => {
        // this.formRef.current.validateFields()

        const { bestTimeToVisit, highSeasonToVisit, bestWeatherToVisit } = this.state;
        const bestTimeErr =
            bestTimeToVisit.findIndex(
                (item) => item.from !== null && (item.to === null || item.reason === null)
            ) === -1;
        const highSeasonErr =
            highSeasonToVisit.findIndex(
                (item) => item.from !== null && (item.to === null || item.reason === null)
            ) === -1;
        const bestWeatherErr =
            bestWeatherToVisit.findIndex(
                (item) => item.from !== null && (item.to === null || item.reason === null)
            ) === -1;
        if (!bestTimeErr || !highSeasonErr || !bestWeatherErr) {
            this.setState({ err: true });
            setTimeout(() => {
                this.setState({ err: false });
            }, 2000);
        } else {
            this.props.onSave({
                best_time: bestTimeToVisit,
                high_season: highSeasonToVisit,
                best_weather: bestWeatherToVisit
            });
        }
    };
    onRadioButton = (value) => this.setState({ radioButton: value });
    //calculation of months from - to by criteria
    isAdded = () => {
        const bestTimeToVisit = [...this.state.bestTimeToVisit];
        const highSeasonToVisit = [...this.state.highSeasonToVisit];
        const bestWeatherToVisit = [...this.state.bestWeatherToVisit];

        const { calendarSeason } = this.state;
        let tmp = [...calendarSeason];
        let tmpFromToTime = [];
        let tmpFromToSeason = [];
        let tmpFromToWeather = [];
        bestTimeToVisit.map((res) => {
            const from = month.findIndex((item) => item.name === res.from);
            const to = month.findIndex((item) => item.name === res.to);
            if (from !== -1 && to !== -1) {
                for (let j = from; j <= month.length; j++) {
                    if (j === month.length) j = 0;
                    tmpFromToTime = [...tmpFromToTime, j];
                    if (j === to) break;
                }
            }
        });
        highSeasonToVisit.map((res) => {
            const from = month.findIndex((item) => item.name === res.from);
            const to = month.findIndex((item) => item.name === res.to);
            if (from !== -1 && to !== -1) {
                for (let j = from; j <= month.length; j++) {
                    if (j === month.length) j = 0;
                    tmpFromToSeason = [...tmpFromToSeason, j];
                    if (j === to) break;
                }
            }
        });
        bestWeatherToVisit.map((res) => {
            const from = month.findIndex((item) => item.name === res.from);
            const to = month.findIndex((item) => item.name === res.to);
            if (from !== -1 && to !== -1) {
                for (let j = from; j <= month.length; j++) {
                    if (j === month.length) j = 0;
                    tmpFromToWeather = [...tmpFromToWeather, j];
                    if (j === to) break;
                }
            }
        });

        tmp.map((res, i) => {
            res.time = tmpFromToTime.indexOf(i) !== -1;
            res.highSeason = tmpFromToSeason.indexOf(i) !== -1;
            res.weather = tmpFromToWeather.indexOf(i) !== -1;
        });
        this.setState({ calendarSeason: [...tmp] });
    };
    //adding new time season
    addNewTime = () => {
        if (this.state.radioButton === 1) this.addTime();
        else if (this.state.radioButton === 2) this.addSeason();
        else if (this.state.radioButton === 3) this.addWeather();
    };
    addTime = () =>
        this.setState({
            bestTimeToVisit: [...this.state.bestTimeToVisit, { from: null, to: null, reason: null }]
        });
    addSeason = () =>
        this.setState({
            highSeasonToVisit: [
                ...this.state.highSeasonToVisit,
                { from: null, to: null, reason: null }
            ]
        });
    addWeather = () =>
        this.setState({
            bestWeatherToVisit: [
                ...this.state.bestWeatherToVisit,
                { from: null, to: null, reason: null }
            ]
        });
    //remove item from best time season
    onDeleteTime = (index) => {
        if (this.state.bestTimeToVisit.length > 1) {
            let tmp = [...this.state.bestTimeToVisit];
            tmp.splice(index, 1);
            this.setState({ bestTimeToVisit: [...tmp] });
        } else {
            const empty = [{ from: null, to: null, reason: null }];
            this.setState({ bestTimeToVisit: [...empty] });
        }

        if (this.formRef.current)
            //resetting and assigning again
            this.formRef.current.resetFields();
    };
    //remove item from high season
    onDeleteHighSeason = (index) => {
        if (this.state.highSeasonToVisit.length > 1) {
            let tmp = [...this.state.highSeasonToVisit];
            tmp.splice(index, 1);
            this.setState({ highSeasonToVisit: [...tmp] });
        } else {
            const empty = [{ from: null, to: null, reason: null }];
            this.setState({ highSeasonToVisit: [...empty] });
        }
    };
    //remove item from best weather season
    onDeleteWeather = (index) => {
        if (this.state.bestWeatherToVisit.length > 1) {
            let tmp = [...this.state.bestWeatherToVisit];
            tmp.splice(index, 1);
            this.setState({ bestWeatherToVisit: [...tmp] });
        } else {
            const empty = [{ from: null, to: null, reason: null }];
            this.setState({ bestWeatherToVisit: [...empty] });
        }
    };

    onFinish = () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.onSave();
    };

    compareData = () => {
        let defaultOldData = {
            best_time: [],
            best_weather: [],
            high_season: []
        };
        let oldData = this.props.data ? { ...this.props.data } : defaultOldData;
        let newData = {
            best_time: this.state.bestTimeToVisit || [],
            best_weather: this.state.bestWeatherToVisit || [],
            high_season: this.state.highSeasonToVisit || []
        };
        if (compareCalendarSeason(oldData, newData)) {
            this.setState({ confirmationModal: true });
        } else {
            this.onClose();
        }
    };

    render() {
        const {
            requiredMark,
            radioButton,
            bestTimeToVisit,
            highSeasonToVisit,
            bestWeatherToVisit,
            calendarSeason,
            err
        } = this.state;

        return (
            // <div className="itenary-form">
            <Drawer
                className="application calendar-season"
                onClose={() => {
                    this.onClose();
                }}
                visible={this.props.isOpen}>
                <Form
                    className="h-100 d-flex flex-column overflow-y-auto"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    layout="vertical"
                    initialValues={{
                        requiredMark
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}>
                    <div className="flex-grow-1 overflow-y-auto px-3 mx-n3">
                        <h6 className="title">Add Calendar Season</h6>

                        {this.props.hideImport ? (
                            ''
                        ) : (
                            <ImportItineraries
                                calendars={this.props.itineraries}
                                import="I will import itinerary calendar season information from my other activity"
                                onMainProduct={(item) => this.onLoad(item?.calendar_season)}
                            />
                        )}

                        <Spacer top={22} />

                        <ItenaryCalenderSeason
                            summary={calendarSeason}
                            onSaveType={(values) => this.setState({ saveType: values })}
                        />

                        <Spacer top={12} />

                        {/* <Form.Item> */}
                        <div className="overflow-x-auto mw-100">
                            <ButtonGroup>
                                <Form.Item
                                    name="bestTime"
                                    //validateTrigger={['onFinish']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill this field in'
                                        }
                                    ]}>
                                    <Button
                                        // style={{ width: '28%' }}
                                        className={radioButton === 1 ? 'active' : ''}
                                        onClick={() => this.onRadioButton(1)}>
                                        Best Time to Visit
                                    </Button>
                                </Form.Item>
                                <Form.Item
                                    name="highTime"
                                    //validateTrigger={['onFinish']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill this field in'
                                        }
                                    ]}>
                                    <Button
                                        className={radioButton === 2 ? 'active' : ''}
                                        onClick={() => this.onRadioButton(2)}>
                                        High Season to Visit
                                    </Button>
                                </Form.Item>

                                <Form.Item
                                    name="bestWeather"
                                    //validateTrigger={['onFinish']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please fill this field in'
                                        }
                                    ]}>
                                    <Button
                                        className={radioButton === 3 ? 'active' : ''}
                                        onClick={() => this.onRadioButton(3)}>
                                        Best Weather to Visit
                                    </Button>
                                </Form.Item>
                            </ButtonGroup>
                        </div>
                        {/* </Form.Item> */}

                        <Spacer top={15} />

                        <div>
                            {radioButton === 1
                                ? bestTimeToVisit?.map((entry, i) => (
                                      <div
                                          className="d-flex gap-2"
                                          key={`btseason${i}}`}
                                          style={{ marginBottom: 10 }}>
                                          <div>
                                              <h6>Time Of Year</h6>
                                              <Form.Item
                                                  name={`bestTimeToFrom${i}`}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          message: 'Please fill this field in'
                                                      }
                                                  ]}>
                                                  <RangeMonthPicker
                                                      defaultFrom={entry.from}
                                                      defaultTo={entry.to}
                                                      fromMonth={(month) => {
                                                          const tmp = [...bestTimeToVisit];
                                                          tmp[i].from = month;
                                                          this.setState({
                                                              bestTimeToVisit: [...tmp]
                                                          });
                                                      }}
                                                      toMonth={(month) => {
                                                          const tmp = [...bestTimeToVisit];
                                                          tmp[i].to = month;
                                                          this.setState({
                                                              bestTimeToVisit: [...tmp]
                                                          });
                                                          this.isAdded();
                                                      }}
                                                  />
                                              </Form.Item>
                                          </div>
                                          <div>
                                              <h6>Reason</h6>
                                              <Form.Item
                                                  initialValue={entry.reason}
                                                  name={`bestTimeReason${i}`}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          message: 'Please fill this field in'
                                                      }
                                                  ]}>
                                                  <Input
                                                      value={entry.reason}
                                                      placeholder="e.g. Dry season"
                                                      style={{ border: '0.3px solid #a2792c' }}
                                                      onChange={(e) => {
                                                          const tmp = [...bestTimeToVisit];
                                                          tmp[i].reason = e.target.value;
                                                          this.setState({
                                                              bestTimeToVisit: [...tmp]
                                                          });
                                                      }}
                                                  />
                                              </Form.Item>
                                          </div>
                                          <div>
                                              <div className="delete-block">
                                                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                                  <img
                                                      src={DeleteIcon}
                                                      className="delete-icon cursor-pointer"
                                                      onClick={() => {
                                                          this.onDeleteTime(i);
                                                          this.isAdded();
                                                      }}
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  ))
                                : radioButton === 2
                                ? highSeasonToVisit?.map((entry, i) => (
                                      <div
                                          className="d-flex gap-2"
                                          key={`hseason${i}}`}
                                          style={{ marginBottom: 10 }}>
                                          <div>
                                              <h6>Time Of Year</h6>
                                              <Form.Item
                                                  name={`highTimeToFrom${i}`}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          message: 'Please fill this field in'
                                                      }
                                                  ]}>
                                                  <RangeMonthPicker
                                                      defaultFrom={entry.from}
                                                      defaultTo={entry.to}
                                                      fromMonth={(month) => {
                                                          const tmp = [...highSeasonToVisit];
                                                          tmp[i].from = month;
                                                          this.setState({
                                                              highSeasonToVisit: [...tmp]
                                                          });
                                                      }}
                                                      toMonth={(month) => {
                                                          const tmp = [...highSeasonToVisit];
                                                          tmp[i].to = month;
                                                          this.setState({
                                                              highSeasonToVisit: [...tmp]
                                                          });
                                                          this.isAdded();
                                                      }}
                                                  />
                                              </Form.Item>
                                          </div>
                                          <div>
                                              <h6>Reason</h6>
                                              <Form.Item
                                                  initialValue={entry.reason}
                                                  name={`highTimeReason${i}`}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          message: 'Please fill this field in'
                                                      }
                                                  ]}>
                                                  <Input
                                                      value={entry.reason}
                                                      placeholder="e.g. Dry season"
                                                      style={{
                                                          border: '0.3px solid #a2792c'
                                                      }}
                                                      onChange={(e) => {
                                                          const tmp = [...highSeasonToVisit];
                                                          tmp[i].reason = e.target.value;
                                                          this.setState({
                                                              highSeasonToVisit: [...tmp]
                                                          });
                                                      }}
                                                  />
                                              </Form.Item>
                                          </div>
                                          <div>
                                              <div className="delete-block">
                                                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                                  <img
                                                      src={DeleteIcon}
                                                      className="delete-icon cursor-pointer"
                                                      onClick={() => {
                                                          this.onDeleteHighSeason(i);
                                                          this.isAdded();
                                                      }}
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  ))
                                : bestWeatherToVisit?.map((entry, i) => (
                                      <div
                                          className="d-flex gap-2"
                                          key={`bwseason${i}}`}
                                          style={{ marginBottom: 10 }}>
                                          <div>
                                              <h6>Time Of Year</h6>
                                              <Form.Item
                                                  name={`bestWeatherToFrom${i}`}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          message: 'Please fill this field in'
                                                      }
                                                  ]}>
                                                  <RangeMonthPicker
                                                      defaultFrom={entry.from}
                                                      defaultTo={entry.to}
                                                      fromMonth={(month) => {
                                                          const tmp = [...bestWeatherToVisit];
                                                          tmp[i].from = month;
                                                          this.setState({
                                                              bestWeatherToVisit: [...tmp]
                                                          });
                                                      }}
                                                      toMonth={(month) => {
                                                          const tmp = [...bestWeatherToVisit];
                                                          tmp[i].to = month;
                                                          this.setState({
                                                              bestWeatherToVisit: [...tmp]
                                                          });
                                                          this.isAdded();
                                                      }}
                                                  />
                                              </Form.Item>
                                          </div>
                                          <div>
                                              <h6>Reason</h6>
                                              <Form.Item
                                                  initialValue={entry.reason}
                                                  name={`bestWeatherReason${i}`}
                                                  rules={[
                                                      {
                                                          required: true,
                                                          message: 'Please fill this field in'
                                                      }
                                                  ]}>
                                                  <Input
                                                      value={entry.reason}
                                                      placeholder="e.g. Dry season"
                                                      style={{
                                                          border: '0.3px solid #a2792c'
                                                      }}
                                                      onChange={(e) => {
                                                          const tmp = [...bestWeatherToVisit];
                                                          tmp[i].reason = e.target.value;
                                                          this.setState({
                                                              bestWeatherToVisit: [...tmp]
                                                          });
                                                      }}
                                                  />
                                              </Form.Item>
                                          </div>
                                          <div>
                                              <div className="delete-block">
                                                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                                  <img
                                                      src={DeleteIcon}
                                                      className="delete-icon cursor-pointer"
                                                      onClick={() => {
                                                          this.onDeleteWeather(i);
                                                          this.isAdded();
                                                      }}
                                                      alt=""
                                                  />
                                              </div>
                                          </div>
                                      </div>
                                  ))}
                            <Prompter
                                onClick={() => this.addNewTime()}
                                text={
                                    radioButton === 1
                                        ? 'Add another Best Time to visit'
                                        : radioButton === 2
                                        ? 'Add another High Season to visit'
                                        : 'Add another Best Weather to visit'
                                }
                                textColor="#A2792C"
                            />
                        </div>
                        <Spacer top={15} />
                    </div>
                    <div className="d-flex flex-wrap gap-1 justify-content-start">
                        {err ? (
                            <div className="invalid-fields w-100">
                                Please, fill in the field Reason
                            </div>
                        ) : null}
                        <Button
                            color="primary"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            type="submit">
                            Save
                        </Button>
                        <Button
                            color="dark"
                            className="btn-mw flex-grow-1 flex-lg-grow-0"
                            onClick={() => this.onClose()}>
                            Cancel
                        </Button>
                        <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                    </div>
                </Form>
                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.onSave();
                    }}
                />
            </Drawer>
            // </div>
        );
    }
}
CalendarSeason.propTypes = {
    data: Proptypes.any,
    itineraries: Proptypes.any,
    onSave: Proptypes.func,
    onSaveType: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    hideImport: Proptypes.bool,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(CalendarSeason));

import React from 'react';
import PropTypes from 'prop-types';
import { toDecimal, transformFirstLetterToUpperCase } from 'utils';
import VendorCalendarProductPart from './vendor-calendar-product-part';
import moment from 'moment';

export default function RefundPart(props) {
    const { data } = props;

    function destructureData(data) {
        if (
            data &&
            Object.keys(data).length > 0 &&
            data.transaction &&
            Object.keys(data).length > 0
        )
            return { ...data, ...data.transaction };

        if (data && Object.keys(data).length > 0) return { ...data };

        return data;
    }

    return (
        <>
            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Transfer overview</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Refunded amount</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`$${
                                data?.amount ? toDecimal(data?.amount / 100) : 0
                            } ${data?.currency || ''}`}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Received amount</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`$${
                                data?.vendor_amount ? toDecimal(data?.vendor_amount / 100) : 0
                            } ${data?.vendor_currency || ''}`}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Refund id</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{data?.refund_id || 'N/A'}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Date</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {data?.created && moment.unix(data.created).format('MMM D, H:mm A')}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Status</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {transformFirstLetterToUpperCase(data?.status)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <VendorCalendarProductPart data={destructureData(data)} />
        </>
    );
}

RefundPart.propTypes = {
    data: PropTypes.object
};

import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './index.scss';

import { Checkbox, Form } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Spacer from '../../../../atoms/spacer';
import ImportAccomodation from '../../../../atoms/import-accomodation';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../../modals/confirmation-modal';
import { Feedback } from 'components/molecules';
import { compareCovidData } from 'utils';

const covidData = [
    'Face masks required for travelers in public areas',
    'Face masks required for guides in public areas',
    'Face masks provided for travelers',
    'Hand sanitizer available to travelers and staff',
    'Social distancing enforced throughout experience',
    'Regularly sanitized high-traffic areas',
    'Gear/equipment sanitized between use',
    'Transportation vehicles regularly sanitized',
    'Guides required to regularly wash hands',
    'Regular temperature checks for staff',
    'Temperature checks for travelers upon arrival',
    'Paid stay-at-home policy for staff with symptoms',
    'Contactless payments for gratuities and add-ons'
];

function Covid(props) {
    const [form] = Form.useForm();

    const [addedUpdates, setAddedUpdates] = useState([]);
    const [confirmationModal, setConfirmationModal] = useState(false);
    const [description, setDescription] = useState('');
    // check if added
    const isAdd = (item) => addedUpdates.findIndex((res) => res === item) !== -1;
    // adding covid item
    const onAdd = (item) => {
        const isAdded = addedUpdates.findIndex((res) => res === item);
        if (isAdded === -1) {
            setAddedUpdates([...addedUpdates, item]);
        } else {
            let tmp = [...addedUpdates];
            tmp.splice(isAdded, 1);
            setAddedUpdates([...tmp]);
        }
        setTimeout(() => form.setFieldsValue({ covidData: addedUpdates }), 700);
    };
    useEffect(() => {
        setAddedUpdates(props.data?.covid_info || []);
        setDescription(props.data?.description || '');
    }, [props.data]);

    const compareData = () => {
        const oldData = props.data?.covid_info ? [...props.data?.covid_info] : [];
        const newData = [...addedUpdates] || [];

        if (compareCovidData(oldData, newData)) {
            setConfirmationModal(true);
        } else {
            //onSave();
            onClose();
        }
    };

    const onFinish = () => {
        const { selected_existing_product } = props;
        if (selected_existing_product) compareData();
        else onSave();
    };
    const onClose = () => props.onClose();
    const onSave = () => props.onSave({ covid_info: addedUpdates, description });

    return (
        <Modal isOpen={props.isOpen} scrollable centered size="lg">
            <Form
                initialValues={addedUpdates}
                form={form}
                name="covid-form"
                className="covid"
                onFinish={onFinish}>
                <ModalHeader className="title">
                    COVID-19 safety updates
                    {props.hideImport ? (
                        ''
                    ) : (
                        <ImportAccomodation
                            calendars={props.calendarData}
                            onMainProduct={(item) => setAddedUpdates(item.covid_19_updates || [])}
                            import="I will import accomodation information from my other activity"
                        />
                    )}
                </ModalHeader>
                <ModalBody className="body-modal">
                    <Form.Item
                        className="covid-select"
                        name="covidData"
                        rules={[
                            {
                                required:
                                    Array.isArray(addedUpdates) && addedUpdates.length > 0
                                        ? false
                                        : true,
                                message: 'Please fill this field in'
                            }
                        ]}>
                        <Spacer top={9} />
                        <b>Tell travelers about your COVID-19 safety measures</b>
                        <Spacer top={5} />
                        <div>Select all that apply</div>
                        {covidData.map((res, i) => (
                            <Checkbox checked={isAdd(res)} onChange={() => onAdd(res)} key={i}>
                                {res}
                            </Checkbox>
                        ))}
                    </Form.Item>

                    <Form.Item>
                        <b>Additional safety measures (optional)</b>
                        <p style={{ fontSize: 14 }}>
                            Use the text box below to include any additional safety measure you
                            think travelers should know about.
                        </p>
                        <textarea
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className="input-bar"
                            name="covid-message"
                            rows="10"
                            cols="80"
                        />
                    </Form.Item>
                </ModalBody>
                <ModalFooter>
                    <Button
                        color="primary"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        type="submit">
                        {props.submitButtonName ?? 'Update Calender & Continue'}
                    </Button>
                    <Button
                        color="dark"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        type="button"
                        onClick={onClose}>
                        Cancel
                    </Button>
                    <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                </ModalFooter>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    setConfirmationModal(false);
                    onSave();
                }}
            />
        </Modal>
    );
}
Covid.propTypes = {
    data: Proptypes.any,
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    calendarData: Proptypes.object,
    submitButtonName: Proptypes.string,
    hideImport: Proptypes.bool,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(Covid));

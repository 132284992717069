import React from 'react';
// import Frame from './frame'
import VendorDashboard from '../vendor-dashboard';
import Form from '../../../pages/product-content/form';
// import PropTypes from 'prop-types'

function Details() {
    return (
        <VendorDashboard>
            <Form />
        </VendorDashboard>
    );
}

Details.propTypes = {};

export default Details;

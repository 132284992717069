import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

const ThankYouModal = (props) => {
    const { isShowDialogModal, setShowDialogModal } = props;
    return (
        <Modal
            isOpen={isShowDialogModal}
            size="lg"
            centered
            className="text-center"
            toggle={() => setShowDialogModal(false)}>
            <ModalHeader className="justify-content-center">Thank you.</ModalHeader>
            <ModalBody>
                <p className="text-big mb-0 mx-auto" style={{ maxWidth: 574 }}>
                    Your application has now been sent for review. We will get back to you in due
                    course
                </p>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                <Button
                    color="primary minw-126"
                    size="lg"
                    onClick={() => setShowDialogModal(false)}>
                    OK
                </Button>
            </ModalFooter>
        </Modal>
    );
};

ThankYouModal.propTypes = {
    isShowDialogModal: PropTypes.bool,
    setShowDialogModal: PropTypes.func
};

export default ThankYouModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from 'antd';
import { Button, Modal, ModalBody } from 'reactstrap';
import { TRAVEL_JINNY_BASE_URL } from 'utils/constants';
import TinyURL from 'tinyurl';
import { checkErrors, validateAndCreateLink, validatePacakgeProductFieldsForLink } from 'utils';
import { checkVendorPlan } from '../../../../../lib/helpers/accessCalendarHelper';
import InfoIcon from '../../../../../assets/images/more_info.svg';
import { SuccessModal } from 'components/organisms';
import { Notification } from 'components/atoms';

let remainingTokens = 0;

export default function SendToCustomerFooterAndExtras(props) {
    const { setState, inhertProps, state, handleNoToken } = props;
    const mainProduct =
        Array.isArray(inhertProps?.get_calendar_data?.data?.mainProduct) &&
        inhertProps?.get_calendar_data?.data?.mainProduct.length > 0
            ? inhertProps?.get_calendar_data?.data?.mainProduct[0]
            : null;

    const mainPackage =
        Array.isArray(inhertProps?.get_calendar_data?.data?.mainPackage) &&
        inhertProps?.get_calendar_data?.data?.mainPackage.length > 0
            ? inhertProps?.get_calendar_data?.data?.mainPackage[0]
            : null;
    /* On clickof send button check if available token are there or not
 if there then according to button value message can be share using selected platform
 also reaming token reduce by one and set in reducer
 else no token drawer will open
  */

    async function handleSendButton(validateFields) {
        if (
            inhertProps.liveCalendars &&
            inhertProps.liveCalendars.length &&
            inhertProps.upcomingSubscription &&
            inhertProps.liveCalendars.length >
                inhertProps.upcomingSubscription.no_of_free_calendar &&
            inhertProps.get_calendar_data?.data?.calendar_status !== 'live'
        ) {
            setState({ showAddToPreference: true });
        } else {
            if (validateFields) {
                let obj = validateAndCreateLink(state, inhertProps, mainProduct?.day_type);
                if (Array.isArray(obj?.errors) && obj.errors.length > 0) {
                    if (
                        Array.isArray(state?.packageProducts) &&
                        state?.packageProducts.length > 0
                    ) {
                        //checking for package product not filled
                        let packageErros = validatePacakgeProductFieldsForLink(state, inhertProps);
                        if (Array.isArray(packageErros?.errors) && packageErros.errors.length > 0)
                            obj.errors.push('fillPackageProducts');
                    }
                    return setState({ errors: obj.errors });
                } else setState({ errors: [] });
            }
            checkPlanAndShare();
        }
    }

    async function handleDisablePreferenceConfirm() {
        setState({ showAddToPreference: false });
        checkPlanAndShare();
    }

    async function checkPlanAndShare() {
        setState({ loading: true });

        const travelUrl = TRAVEL_JINNY_BASE_URL;

        const query = validateAndCreateLink(state, inhertProps, mainProduct?.day_type).link;

        const encryped = await inhertProps.encryptUrl(query);

        const calendarLink = encodeURI(travelUrl + `/onboarding?${encryped || ''}`);

        let shortendUrl = calendarLink;
        try {
            shortendUrl = await TinyURL.shorten(calendarLink);
        } catch (error) {
            console.log('ERROR: ', error);
        }

        if (state.showEmail) {
            let { firstname, email } = state;
            let hasEmail = email;
            const errors = checkErrors({
                firstname,
                email: hasEmail
                // startDate: inhertProps.startDate,
                // endDate: inhertProps.endDate
            });
            if (errors.length) {
                return setState((prevState) => ({
                    ...prevState,
                    loading: false,
                    errors
                }));
            }
            setState((prevState) => ({
                ...prevState,
                errors: []
            }));
        }

        try {
            const validPlanResponse = await checkVendorPlan(inhertProps.calendarId);

            if (validPlanResponse?.status === 404) {
                throw new Error(validPlanResponse?.data?.message);
            }
            if (validPlanResponse.data && !validPlanResponse.data.data?.haveFreeLiveCalendars) {
                setState({ toggleNoTokenDrawer: false, togglePlanDrawer: true, loading: false });
            } else if (
                validPlanResponse.data &&
                !validPlanResponse.data.data?.haveAvailableTokens &&
                !state.sendAnyway
            ) {
                setState({ toggleNoTokenDrawer: true, togglePlanDrawer: false, loading: false });
            } else if (
                validPlanResponse.data &&
                !validPlanResponse.data.data?.haveAvailableTokens &&
                state.sendAnyway
            ) {
                shareLink(shortendUrl);
                setState({
                    toggleNoTokenDrawer: false,
                    togglePlanDrawer: false,
                    sendAnyway: false
                });
            } else {
                shareLink(shortendUrl);
                remainingTokens = inhertProps.availableToken - 1;
                inhertProps.reduceToken(remainingTokens);
                setState({
                    toggleNoTokenDrawer: false,
                    togglePlanDrawer: false,
                    sendAnyway: false
                });
            }
        } catch (error) {
            setState({ loading: false });
            Notification('error', error?.response?.data?.message ?? error?.message);
        }
    }

    /**
     * Share or copy travel jinny URl as per the selected method
     *
     * @param {String} shortendUrl
     * @param {String} calendarLink
     */

    async function shareLink(shortendUrl) {
        const {
            profile: { business_info },
            myProfile
        } = inhertProps;
        //saving to state to show in preview
        setState({ shortendUrl: shortendUrl });
        if (state.showLink) {
            navigator.clipboard.writeText(shortendUrl);
            setState({ showModal: true });
        } else if (state.showWhatsapp) {
            const message = getDynamicMessage({ shortendUrl, myProfile, business_info });
            window.open(
                '//api.whatsapp.com/send?preview_url=true&text=' + encodeURIComponent(message),
                '_blank'
            );

            navigator.clipboard.writeText(message);
            setState({ showModal: true });
        } else if (state.showEmail) {
            /* const mailto = `mailto:` + state.email + `?subject=${subject}&` + `body=` + encodeURIComponent(message); */
            const subject = `${business_info?.business_trading_name} - Access your calendar`;
            // const message = `<div style='padding: 0;'><p>Hello ${state.firstname}</p><p>${state.messageTemplate}</p><p><a href='${calendarLink}' >Access your calendar </a> \nor open this link in you browser ${calendarLink}</p></div>`;
            const message = getDynamicMessage({ shortendUrl, myProfile, business_info });

            const mailto = `mailto:${state.email}?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(message)}`;
            window.location.href = mailto;

            navigator.clipboard.writeText(message);
            setState({ showModal: true });
        }
        setState({ loading: false });
    }

    function getDynamicMessage({ shortendUrl, myProfile, business_info }) {
        return `Hello ${state.firstname}, \n\n${
            state.viewPaidOne
                ? 'Thanks you for booking'
                : state.viewNotPaidTwo
                ? 'You can now pay for'
                : 'Check availability, pictures and details for'
        } ${mainProduct?.title || mainPackage?.title} ${
            !state.viewPaidOne && 'using the link below'
        }. \n\n${
            state.messageTemplate
        } \n\nAccess it using the link below. ${shortendUrl} \n\nKind Regards, \n${
            myProfile.first_name
        } \n${myProfile?.job_title || 'Customer Service Manager'}, ${
            business_info?.business_trading_name
        }`;
    }

    function handleSendToCustomerToPay() {
        setState({ sendAnyway: true });
        setTimeout(() => handleSendButton(), 700);
    }

    return (
        <>
            {/* <div className="footer-btns"> */}
            {/* <Row>
                    <Col xs="12" md="6"> */}
            <Button
                color="primary"
                className="btn-mw"
                onClick={() =>
                    inhertProps?.myProfile?.vendor?.stripe_connected_account_id ||
                    inhertProps?.myProfile?.vendor?.paystack_account_id
                        ? handleSendButton(true)
                        : setState({ confirmationModal: true })
                }>
                {state.sendBtn}
            </Button>

            {/* </Col>
                    
                </Row> */}
            {/* </div> */}

            <SuccessModal
                title={state.linkTitle}
                showModal={state.showModal}
                handleClose={() => setState({ showModal: false })}
                showEmail={state.showEmail}
            />

            {/* No token drawer */}
            <Drawer
                width={589}
                placement="right"
                onClose={() => setState({ toggleNoTokenDrawer: false })}
                visible={state.toggleNoTokenDrawer}
                className="drawers-padding"
                style={{ zIndex: 1060 }}>
                <div style={{ color: '#A2792C' }} className="fs-28 fw-600 mt-5 pt-2">
                    Are you sure you want to send?
                </div>
                <div className="fs-18 fw-400">
                    You have no Available tokens. Your customer would have to pay $
                    {inhertProps.vendorSubscription?.price_per_customer_token} to get access to your
                    planned calendar.
                </div>

                <div className="mt-4 pt-2">
                    <Button className="send-footer-btn px-4" onClick={handleNoToken}>
                        Add Top-up
                    </Button>
                    <Button
                        className="send-footer-btn px-4 send-to-customer-margin"
                        onClick={handleSendToCustomerToPay}>
                        Send to customer to pay
                    </Button>
                </div>
            </Drawer>

            {/* confirm Modal */}
            <Modal
                size="lg"
                centered
                isOpen={state.showAddToPreference}
                toggle={() => setState({ showAddToPreference: !this.state.showAddToPreference })}>
                <ModalBody className="text-center">
                    <img src={InfoIcon} alt="Info" height="100" />
                    <div className="modal-body-title-lg mt-2">Are you sure?</div>
                    <p className="modal-body-text">
                        It is to confirm that this calendar will be disabled automatically with
                        effectiveness of your {inhertProps.upcomingSubscription?.plan?.name} plan on{' '}
                        {inhertProps.upcomingSubscription?.start_date}
                    </p>
                    <div className="mt-4">
                        <Button
                            type="dark"
                            className="btn-mw mx-2 pl-8 pr-8"
                            onClick={() => handleDisablePreferenceConfirm()}>
                            Confirm
                        </Button>
                        <Button
                            type="primary"
                            className="close-btn"
                            onClick={() => setState({ showAddToPreference: false })}>
                            Cancel
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    );
}

SendToCustomerFooterAndExtras.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    inhertProps: PropTypes.object,
    handleNoToken: PropTypes.func
};

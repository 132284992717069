import React from 'react';
import { Row, Col, Button, Form, Radio } from 'antd';
import './index.scss';
import Spacer from '../../../atoms/spacer';
import { NavLink } from 'react-router-dom';
import Feedback from '../../../molecules/user-feedback';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px'
};

function SyncAvaliabilityLanding() {
    return (
        <div className="background-sz">
            <Form layout="vertical">
                <Row>
                    <Col span={22}>
                        <Spacer top={62} />
                        <h4>Sync Availability</h4>
                        <Spacer top={36} />
                        <p>
                            Your property will be automatically available for bookings, for the next
                            18 months - excluding days you import as marked unavailable. You can
                            update your synced availability after registration.
                        </p>
                        <Spacer top={36} />
                        <h6>
                            <b>Want to sync your availability with another websites?</b>
                        </h6>
                        <Form.Item>
                            <Radio.Group>
                                <Radio style={radioStyle} value={'yes'}>
                                    Yes, I’ll import a calender from another website
                                    <span
                                        style={{
                                            padding: '5px 14px',
                                            background: '#A2792C',
                                            marginLeft: 10,
                                            borderRadius: 8,
                                            color: '#fff'
                                        }}>
                                        RECOMMENDED
                                    </span>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <p>
                            To avoid overbookings and operate effectively across multiple sites, you
                            can use an iCal link to import reserved or unavailable dates. These
                            dates will be blocked in your customers calendar, so guests cannot
                            reserve your property on Customer Calendar Manager
                        </p>
                        <Form.Item label="Where do I find my iCal link?" required>
                            <Radio.Group>
                                <Radio style={radioStyle} value={'yes'}>
                                    Yes, I’ll connect with a channel manager
                                </Radio>
                                <Radio style={radioStyle} value={'no'}>
                                    No, I won’t sync my availability
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <p>
                            Not ready to sync your availability right now? That’s okay. You can sync
                            your availability with other websites after registration
                        </p>
                    </Col>
                </Row>
                <div className="sync-availability-landing__action">
                    <NavLink to="/sync-availability/products">
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="primary"
                            className="rounded-btn mr20">
                            Continue
                        </Button>
                    </NavLink>
                    <div className="flex-shrink-0 mt-3 mt-lg-0">
                        <div className="d-flex justify-content-end">
                            <Feedback />
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}

SyncAvaliabilityLanding.propTypes = {};

export default SyncAvaliabilityLanding;

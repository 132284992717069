/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import 'components/molecules/calendar/Calendar.css';
import { duplicateCalendar, getCalendarsById, getAllCalendarProducts } from 'store/actions';
// import TimeComponent from '../../molecules/time/time.js';
// import add_circle from '../../../assets/images/add_circle.svg';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { CalendarService } from 'services';

const DuplicateCalendarModal = ({
    form,
    duplicateCalendarModalPopup,
    setDuplicateCalendarModalPopup,
    duplicateCalendar,
    getCalendarsById,
    calendar,
    history,
    getAllCalendarProducts,
    setLoading
}) => {
    const [title, setTitle] = useState(null);
    const values = form.getFieldsValue();

    useEffect(() => {
        if (!calendar?.id) return setTitle(null);
        if (!duplicateCalendarModalPopup) return;
        CalendarService.getDuplicateCalendarTitle(calendar?.id).then((res) => {
            if (res.success) {
                setTitle(res.data || '');
            }
        });
    }, [calendar, duplicateCalendarModalPopup]);

    async function onClickDuplicate() {
        setLoading(true);
        let { success, calendarId } = await duplicateCalendar(calendar?.id, values, history);
        if (success) {
            history.push(`/calendar/${calendarId}`);
            setDuplicateCalendarModalPopup(false);
            const promises = [];

            promises.push(getCalendarsById(calendarId));
            promises.push(getAllCalendarProducts(calendarId));
            Promise.all(promises).then((values) => {
                if (values && values[0] && values[0].payload) setLoading(false);
            });
        }
    }

    return (
        <Modal
            size="lg"
            centered
            isOpen={duplicateCalendarModalPopup}
            toggle={() => setDuplicateCalendarModalPopup(!duplicateCalendarModalPopup)}>
            <ModalHeader>
                <div className="text-truncate">{title}</div>
            </ModalHeader>
            <ModalBody>
                <p>
                    You are about to duplicate this calendar with a new calendar length for{' '}
                    {values?.no_of_weeks} week{values?.no_of_weeks < 2 ? '' : 's'} and{' '}
                    {values?.no_of_days} day{values?.no_of_days < 2 ? '' : 's'}.
                    <br />
                    Don’t worry, updates to the current calendar would be saved.
                </p>
            </ModalBody>
            <ModalFooter className="justify-content-start">
                <Button
                    color="primary"
                    className="btn-mw btn-block btn-md-inline-block"
                    onClick={onClickDuplicate}>
                    Duplicate
                </Button>
                <Button
                    color="dark"
                    className="btn-mw btn-block btn-md-inline-block"
                    onClick={() => setDuplicateCalendarModalPopup(false)}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

DuplicateCalendarModal.propTypes = {
    form: PropTypes.any,
    duplicateCalendarModalPopup: PropTypes.bool,
    setDuplicateCalendarModalPopup: PropTypes.func,
    duplicateCalendar: PropTypes.func,
    getCalendarsById: PropTypes.func,
    calendar: PropTypes.any,
    history: PropTypes.any,
    setLoading: PropTypes.func,
    getAllCalendarProducts: PropTypes.func
};

const mapStateToProps = (state) => ({
    calendar: state?.calendar.get_calendar_data?.data
});

export default withRouter(
    connect(mapStateToProps, { duplicateCalendar, getCalendarsById, getAllCalendarProducts })(
        DuplicateCalendarModal
    )
);

import React from 'react';
import { TableRow, TableCell, Grid } from '@material-ui/core';

const styles = {
    cell: {
        border: 'none'
    },
    input: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '8px 0px 0px 8px',
        width: '58px',
        height: '50px',
        textAlign: 'center'
    },
    textBox: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '0px 8px 8px 0px',
        width: '132px',
        height: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    ampm: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '0px 8px 8px 0px',
        width: '76px',
        height: '50px',
        background: '#FFFFFF'
    }
};

export default function DurationRow(props) {
    // const [amount, setAmount] = useState(1.0);
    // const [newGroupRows, setNewGroupRows] = useState([]);

    // const handleAmount = (e) => {
    //     setAmount(e.target.value);
    // };

    return (
        <>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={styles.cell} align="left">
                    From
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <Grid container>
                        <Grid item>
                            <input style={styles.input} type="number" variant="outlined" min={1} />
                        </Grid>
                        <Grid item style={styles.textBox}>
                            hours
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <Grid container>
                        <Grid item>
                            <input style={styles.input} type="number" variant="outlined" min={1} />
                        </Grid>
                        <Grid item style={styles.textBox}>
                            minutes
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell style={styles.cell}>
                    <select name="time" id="hours" style={styles.ampm}>
                        <option value="saab">AM</option>
                        <option value="mercedes">PM</option>
                    </select>
                </TableCell>
            </TableRow>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={styles.cell} align="left">
                    To
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <Grid container>
                        <Grid item>
                            <input style={styles.input} type="number" variant="outlined" min={1} />
                        </Grid>
                        <Grid item style={styles.textBox}>
                            hours
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <Grid container>
                        <Grid item>
                            <input style={styles.input} type="number" variant="outlined" min={1} />
                        </Grid>
                        <Grid item style={styles.textBox}>
                            minutes
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell style={styles.cell}>
                    <select name="time" id="hours" style={styles.ampm}>
                        <option value="saab">AM</option>
                        <option value="mercedes">PM</option>
                    </select>
                </TableCell>
            </TableRow>
        </>
    );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';
import warningIcon from '../../../../assets/images/warning-icon.svg';
import { Checkbox, Tooltip } from 'antd';
import { Pricing, ConfirmationModal } from 'components/molecules';
import { useParams } from 'react-router-dom';

const CheckboxGroup = Checkbox.Group;

// const productOptions = [
//     { label: 'Apple', value: 'apple' },
//     { label: 'Pear', value: 'pear' },
//     { label: 'Orange', value: 'orange' }
// ];
const PriceUpdateConfirmationModal = (props) => {
    const params = useParams();

    const {
        isModalOpen,
        setModalOpenState,
        products,
        handleConfirmation,
        title,
        currentDay,
        pricedProducts,
        pricedAccmoProducts
    } = props;

    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    const [checkedListPro, setCheckedListPro] = useState([]);
    const [indeterminatePro, setIndeterminatePro] = useState(true);
    const [checkAllPro, setCheckAllPro] = useState(false);

    const [checkedListAccom, setCheckedListAccom] = useState([]);
    const [indeterminateAccom, setIndeterminateAccom] = useState(true);
    const [checkAllAccom, setCheckAllAccom] = useState(false);

    const [isOpenPricing, setIsOpenPricing] = useState(false);
    const [isOpenPricingAccom, setIsOpenPricingAccom] = useState(false);
    const [priceData, setPriceData] = useState({});

    const [isConfirmationModalOpen, setConfirmationModalOpenState] = useState(false);
    const [isConfirmationModalOpenAccom, setConfirmationModalOpenAccom] = useState(false);

    const onChangeCheckboxGroup = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < pricedProducts.length);
        setCheckAll(list.length === pricedProducts.length);
    };

    const onChangeCheckboxGroupAccom = (list) => {
        setCheckedListAccom(list);
        setIndeterminateAccom(!!list.length && list.length < pricedAccmoProducts.length);
        setCheckAllAccom(list.length === pricedAccmoProducts.length);
    };

    const onClickCheckAllCheckbox = (e) => {
        setCheckedList(e.target.checked ? pricedProducts?.map((value) => value.value) : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const onClickCheckAllCheckboxAccom = (e) => {
        setCheckedListAccom(
            e.target.checked ? pricedAccmoProducts?.map((value) => value.value) : []
        );
        setIndeterminateAccom(false);
        setCheckAllAccom(e.target.checked);
    };

    const onChangeCheckboxGroupPro = (list) => {
        setCheckedListPro(list);
        setIndeterminatePro(!!list.length && list.length < products.length);
        setCheckAllPro(list.length === products.length);
    };

    const onClickCheckAllCheckboxPro = (e) => {
        setCheckedListPro(e.target.checked ? products?.map((value) => value.value) : []);
        setIndeterminatePro(false);
        setCheckAllPro(e.target.checked);
    };

    function addPricingInformation(value) {
        const promises = [];
        for (let i of checkedList) {
            value.days = [currentDay.name];
            const found = pricedProducts.find((res) => res.value === i);
            if (found) {
                //updating the days
                let calendar_days = {
                    calendar_days: { days: [...found?.calendar_days?.days, currentDay.name] }
                };
                if (found?.suggestedDay) {
                    calendar_days.suggested_day = found?.suggestedDay;
                    if (params.id) calendar_days.calendar_id = Number(params.id);
                }
                promises.push(props.updateProductsCalendarDay(calendar_days, i));

                //updating pricing
                if (value?.id) delete value.id;
                promises.push(
                    props.updateProductPricing({
                        newPricing: value,
                        product: { id: i, pricings: found.pricings }
                    })
                );
            }
        }
        Promise.all(promises).then((values) => {
            if (values && values[0] && values[0].payload) props.updateCalendarData(params.id);
        });
    }

    function addPricingInformationAccom(value) {
        const promises = [];
        for (let i of checkedListAccom) {
            value.days = [currentDay.name];
            const found = pricedAccmoProducts.find((res) => res.value === i);
            if (found) {
                //updating the days
                let calendar_days = {
                    calendar_days: { days: [...found?.calendar_days?.days, currentDay.name] }
                };
                if (found?.suggestedDay) {
                    calendar_days.suggested_day = found?.suggestedDay;
                    if (params.id) calendar_days.calendar_id = Number(params.id);
                }
                promises.push(props.updateProductsCalendarDay(calendar_days, i));

                //updating pricing
                if (value?.id) delete value.id;
                promises.push(
                    props.updateProductPricing({
                        newPricing: value,
                        product: { id: i, pricings: found.pricings }
                    })
                );
            }
        }
        Promise.all(promises).then((values) => {
            if (values && values[0] && values[0].payload) props.updateCalendarData(params.id);
        });
    }

    function updateProductsCalendarDay() {
        const promises = [];

        for (let i of checkedListPro) {
            const value = products.find((res) => res.value === i);
            let calendar_days = {
                calendar_days: { days: [...value?.calendar_days?.days, currentDay.name] }
            };
            if (value?.suggestedDay) {
                calendar_days.suggested_day = value?.suggestedDay;
                if (params.id) calendar_days.calendar_id = Number(params.id);
            }

            promises.push(props.updateProductsCalendarDay(calendar_days, i));
        }

        Promise.all(promises).then((values) => {
            if (values && values[0] && values[0].payload) props.updateCalendarData(params.id);
        });
    }

    function onClickUpdateDayPricing() {
        if (checkedList?.length === 1) {
            setConfirmationModalOpenState(true);
        } else setIsOpenPricing(true);
    }

    function onClickUpdateDayPricingAccom() {
        if (checkedListAccom?.length === 1) {
            setConfirmationModalOpenAccom(true);
        } else setIsOpenPricingAccom(true);
    }

    function handleReuseConfirmation() {
        setPriceData(pricedProducts[0].pricings[0]);
        setConfirmationModalOpenState(false);
        setIsOpenPricing(true);
    }

    function handleReuseConfirmationAccom() {
        setPriceData(pricedAccmoProducts[0].pricings[0]);
        setConfirmationModalOpenAccom(false);
        setIsOpenPricingAccom(true);
    }

    return (
        <div>
            <Modal size="lg" centered isOpen={isModalOpen} zIndex={1059}>
                <ModalBody className="text-center">
                    <img src={warningIcon} alt="" />
                    <h5 style={{ marginBottom: 10 }} className="modal-body-title-lg mt-2">
                        {' '}
                        {title || 'Are you sure?'}
                    </h5>

                    {(Array.isArray(pricedProducts) && pricedProducts.length > 0) ||
                    (Array.isArray(pricedAccmoProducts) && pricedAccmoProducts.length > 0) ? (
                        <>
                            {Array.isArray(pricedProducts) && pricedProducts.length > 0 && (
                                <div className="d-flex flex-nowrap py-4">
                                    <div className="text-left d-flex flex-column gap-2 mr-3">
                                        <Checkbox
                                            indeterminate={indeterminate}
                                            onChange={onClickCheckAllCheckbox}
                                            checked={checkAll}>
                                            Select all
                                        </Checkbox>
                                        <CheckboxGroup
                                            className="d-flex flex-column gap-2"
                                            options={pricedProducts}
                                            value={checkedList}
                                            onChange={onChangeCheckboxGroup}
                                        />
                                    </div>

                                    <div className="ml-auto">
                                        {checkedList && checkedList.length === 0 ? (
                                            <Tooltip
                                                placement="top"
                                                title={'Please Select One...'}
                                                trigger={'click'}>
                                                <Button className="op-5" color="primary">
                                                    Update Day Pricing
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Button
                                                onClick={onClickUpdateDayPricing}
                                                color="primary">
                                                Update Day Pricing
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}

                            {Array.isArray(pricedAccmoProducts) && pricedAccmoProducts.length > 0 && (
                                <div className="d-flex flex-nowrap py-4">
                                    <div className="text-left d-flex flex-column gap-2 mr-3">
                                        <Checkbox
                                            indeterminate={indeterminateAccom}
                                            onChange={onClickCheckAllCheckboxAccom}
                                            checked={checkAllAccom}>
                                            Select all
                                        </Checkbox>
                                        <CheckboxGroup
                                            className="d-flex flex-column gap-2"
                                            options={pricedAccmoProducts}
                                            value={checkedListAccom}
                                            onChange={onChangeCheckboxGroupAccom}
                                        />
                                    </div>

                                    <div className="ml-auto">
                                        {checkedListAccom && checkedListAccom.length === 0 ? (
                                            <Tooltip
                                                placement="top"
                                                title={'Please Select One...'}
                                                trigger={'click'}>
                                                <Button className="op-5" color="primary">
                                                    Update Day Pricing (Accommodation)
                                                </Button>
                                            </Tooltip>
                                        ) : (
                                            <Button
                                                onClick={onClickUpdateDayPricingAccom}
                                                color="primary">
                                                Update Day Pricing (Accommodation)
                                            </Button>
                                        )}
                                    </div>
                                </div>
                            )}

                            {Array.isArray(products) && products.length > 0 && (
                                <div className="text-left py-4">
                                    <h6>{"All of these products don't have existing pricing!"}</h6>
                                    <div className="d-flex flex-nowrap">
                                        <div className="text-left d-flex flex-column gap-2 mr-3">
                                            <Checkbox
                                                indeterminate={indeterminatePro}
                                                onChange={onClickCheckAllCheckboxPro}
                                                checked={checkAllPro}>
                                                Select all
                                            </Checkbox>
                                            <CheckboxGroup
                                                className="d-flex flex-column gap-2"
                                                options={products}
                                                value={checkedListPro}
                                                onChange={onChangeCheckboxGroupPro}
                                            />
                                        </div>

                                        <div className="ml-auto">
                                            {checkedListPro && checkedListPro.length === 0 ? (
                                                <Tooltip
                                                    placement="top"
                                                    title={'Please Select One...'}
                                                    trigger={'click'}>
                                                    <Button className="op-5" color="primary">
                                                        Update Day
                                                    </Button>
                                                </Tooltip>
                                            ) : (
                                                <Button
                                                    onClick={() => updateProductsCalendarDay()}
                                                    color="primary">
                                                    Update Day
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        products.map((value, index) => (
                            <p
                                style={{ marginBottom: 0 }}
                                key={index}
                                className="modal-body-text">{`Do you want to make product '${value.label}' available on ${currentDay?.name}?`}</p>
                        ))
                    )}

                    <div className="mt-4">
                        {Array.isArray(pricedProducts) && pricedProducts.length > 0 ? (
                            <Button
                                color="dark"
                                className="btn-mw mx-2"
                                onClick={() => setModalOpenState(false)}>
                                Cancel
                            </Button>
                        ) : (
                            <>
                                <Button
                                    color="primary"
                                    className="btn-mw mx-2"
                                    onClick={handleConfirmation}>
                                    Yes
                                </Button>{' '}
                                <Button
                                    color="dark"
                                    className="btn-mw mx-2"
                                    onClick={() => setModalOpenState(false)}>
                                    No{' '}
                                </Button>
                            </>
                        )}
                    </div>
                </ModalBody>
            </Modal>

            {/* update price for all other product rather than accommodation category */}
            {currentDay?.name && isOpenPricing && (
                <Pricing
                    isOpen={isOpenPricing}
                    data={priceData}
                    calendarData={[]}
                    onClose={() => setIsOpenPricing(false)}
                    onEdit={true}
                    onSave={(value) => addPricingInformation(value)}
                    submitButtonName="Save"
                    hideImport
                    title={'Product'}
                    dayPriceUpdate={currentDay?.name}
                    currentProduct={pricedProducts[0]}
                    selected_existing_product={true}
                />
            )}

            {/* Price template for accommodation template */}
            {currentDay?.name && isOpenPricingAccom && (
                <Pricing
                    isOpen={isOpenPricingAccom}
                    data={priceData}
                    calendarData={[]}
                    onClose={() => setIsOpenPricingAccom(false)}
                    onEdit={true}
                    onSave={(value) => addPricingInformationAccom(value)}
                    submitButtonName="Save"
                    hideImport
                    title={'Product'}
                    dayPriceUpdate={currentDay?.name}
                    currentProduct={pricedAccmoProducts[0]}
                    selected_existing_product={true}
                />
            )}

            {(isConfirmationModalOpen || isConfirmationModalOpenAccom) && (
                <ConfirmationModal
                    isModalOpen={isConfirmationModalOpen || isConfirmationModalOpenAccom}
                    setModalOpenState={() => {
                        isConfirmationModalOpen
                            ? setIsOpenPricing(true)
                            : setIsOpenPricingAccom(true);
                        setConfirmationModalOpenState(false);
                        setConfirmationModalOpenAccom(false);
                        setPriceData({});
                    }}
                    title={' '}
                    description={'Do you want to reuse previous price!'}
                    handleConfirmation={
                        isConfirmationModalOpen
                            ? handleReuseConfirmation
                            : handleReuseConfirmationAccom
                    }
                    size={'sm'}
                />
            )}
        </div>
    );
};

PriceUpdateConfirmationModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func,
    products: PropTypes.array,
    title: PropTypes.string,
    handleConfirmation: PropTypes.func,
    currentDay: PropTypes.object,
    pricedProducts: PropTypes.array,
    updateProductPricing: PropTypes.func,
    updateProductsCalendarDay: PropTypes.func,
    updateCalendarData: PropTypes.func,
    pricedAccmoProducts: PropTypes.array
};

export default PriceUpdateConfirmationModal;

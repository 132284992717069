// import { Button, Input } from "antd";
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'components/pages/signin/index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Form, Button, Input } from 'reactstrap';
import { forgotPassword, setInitialAuth } from 'store/actions/AuthActions';
import { toggleToastAble } from 'store/actions/toastActions';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            emailAddress: null,
            errorMessage: '',
            isValid: true
        };
    }
    updateForm = (e) => {
        let value = e.target.value;
        this.setState(
            {
                emailAddress: value
            },
            () => {
                this.checkValidation(this.state.emailAddress);
            }
        );
    };

    submitRequest = (e) => {
        e.preventDefault();
        let email = this.state.emailAddress;
        if (email != '' && email !== null) {
            this.props.forgotPassword({ email });
        }
    };
    checkValidation = (email) => {
        let msg = '';
        let validator = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (email == undefined || email == '') {
            msg = 'Enter Email Address';
            this.setState({
                errorMessage: msg,
                isValid: false
            });
        } else if (!validator.test(email)) {
            msg = 'Enter Valid Email Address';
            this.setState({
                errorMessage: msg,
                isValid: false
            });
        } else {
            this.setState({
                isValid: true
            });
        }
    };

    componentDidMount() {
        localStorage.clear();
        this.props.setInitialAuth();
    }

    componentDidUpdate(prevProps) {
        let nexProps = this.props;
        // Typical usage (don't forget to compare props):
        if (nexProps.forgot_msg !== prevProps.forgot_msg) {
            let msg = '';
            if (nexProps?.forgot_msg?.[0]?.message) {
                msg = nexProps.forgot_msg[0].message;
                this.setState({
                    errorMessage: msg,
                    isValid: false
                });
            } else if (nexProps?.forgot_msg?.message) {
                msg = nexProps.forgot_msg.message;
                //toast.success(msg);
                toggleToastAble({ message: msg, success: true, toast: true });
                nexProps.history.push({ pathname: '/', state: { data: msg } });
            }
        }
    }

    render() {
        return (
            // Wrapper
            <div className="signin">
                {/* Custom Container for login, forgot password and create password */}
                <Container className="container-login">
                    {/* White Box Starts */}
                    <div className="form_box">
                        <div className="form_box--inner">
                            <h4 className="mb-15px">Forgot Password</h4>
                            <p className="text-big text-left mb-14px">
                                Please, enter your email address on the field below. You will
                                receive a link in your email to create a new password.
                            </p>
                            <div className="text-left">
                                <Form>
                                    <Input
                                        bsSize="lg"
                                        placeholder="Email Address"
                                        onChange={(e) => this.updateForm(e)}
                                        required={true}
                                    />
                                    {this.state.isValid ? (
                                        ''
                                    ) : (
                                        <span className="invalid-feedback d-block">
                                            {this.state.errorMessage}
                                        </span>
                                    )}
                                    <div className="form-btn-wrapper">
                                        <Button
                                            color="primary"
                                            size="lg"
                                            block
                                            disabled={
                                                this.state.emailAddress == '' ||
                                                this.state.emailAddress == null ||
                                                !this.state.isValid
                                            }
                                            onClick={this.submitRequest}>
                                            Send
                                        </Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    {/* White Box Ends */}
                </Container>
            </div>
        );
    }
}
const mapState = (state) => ({
    forgot_msg: state.auth.forgot_password
});

const mapProps = (dispatch) => ({
    forgotPassword: (data) => dispatch(forgotPassword(data)),
    setInitialAuth: () => dispatch(setInitialAuth())
});

ForgotPassword.propTypes = {
    forgotPassword: PropTypes.func,
    setInitialAuth: PropTypes.func,
    forgot_msg: PropTypes.array,
    history: PropTypes.object
};

export default withRouter(connect(mapState, mapProps)(ForgotPassword));

import { DAYS } from './constants';

export const getProductStatusString = (status) => {
    switch (status) {
        case 'in_use':
            return 'In Use';
        case 'not_in_use':
            return 'Not In Use';
        case 'stopped':
            return 'Stopped';
        case 'cancelled':
            return 'Cancelled';
        case 'paused':
            return 'Paused';
        case 'archived':
            return 'Archived';
        default:
            return status;
    }
};

export const getYesNo = (value) => (value ? 'Yes' : 'No');

export const getProductStatusColor = (status) => {
    switch (status) {
        case 'in_use':
            return '__in_use';
        case 'not_in_use':
            return '__not_in_use';
        default:
            return '__disabled';
    }
};

export const getProductBookingPolicyContent = (content) => {
    if (!content) return '';
    return `Travelers may cancel up to ${content?.duration} ${
        content?.duration_type
    } before the experience start date to receive a ${
        content?.refund_type === '100%' ? 'full' : content?.refund_type
    } refund, and between 3-6 days to receive a 50% refund. No refunds will be given after that time period.`;
};

// Pricing
export const getValidPricingDays = (product, currentPricing, options) => {
    const pricings = product?.pricings;
    let pricingDays = [];

    if (pricings) {
        for (let p of pricings) {
            // Skip a specific pricing item if that item is currently being edited
            if (p.id === currentPricing?.id) continue;
            pricingDays = [...pricingDays, ...p.days];
        }
    }

    let validDays;
    // Only return the days that product is available on and don't have pricing
    if (options?.filterUnAvailableDays) {
        validDays = Object.values(DAYS).filter(
            (item) =>
                pricingDays.indexOf(item) === -1 &&
                product?.calendar_days?.days &&
                product?.calendar_days?.days.indexOf(item) !== -1
        );
    } else {
        // Return all the days wether the product is available on those days or not
        validDays = Object.values(DAYS).filter((item) => pricingDays.indexOf(item) === -1);
    }

    return validDays;
};

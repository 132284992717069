import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ClockIcon } from 'components/atoms';

const EventBreakfastDetails = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<ClockIcon />}
            panelTitle="Breakfast Details"
            isEditable={true}
            onEdit={onEdit}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={6}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Is Breakfast served?</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={6}>
                    <span className="fs-18 lh-1-7">
                        {product?.breakfast_details?.is_breakfast_serve ? 'Yes' : 'No'}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={6}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Is price included?</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={6}>
                    <span className="fs-18 lh-1-7">
                        {product?.breakfast_details?.is_price_include ? 'Yes' : 'No'}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={6}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Price</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={6}>
                    <span className="fs-18 lh-1-7">USD {product?.breakfast_details?.price}</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={6}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Types</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={6}>
                    <span className="fs-18 lh-1-7">
                        {product?.breakfast_details?.types.length > 0 &&
                            product?.breakfast_details?.types.map(
                                (item, index) =>
                                    `${item}${
                                        index < product?.breakfast_details?.types.length - 1
                                            ? ','
                                            : ''
                                    } `
                            )}
                    </span>
                </Col>
            </Row>
        </DetailedPanel>
    );
};

EventBreakfastDetails.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventBreakfastDetails;

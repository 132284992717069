import React, { useState } from 'react';
import { TableRow, TableCell, FormControl, OutlinedInput, InputAdornment } from '@material-ui/core';
import MoreText from './MoreText.jsx';
import NewGroupBookingRow from './NewGroupBookingRow.jsx';

export default function GroupRow(props) {
    // const [amount, setAmount] = useState(1.0);
    const [newGroupRows, setNewGroupRows] = useState([]);

    // const handleAmount = (e) => {
    //   setAmount(e.target.value);
    // };

    const styles = {
        input: {
            width: '68px',
            height: '48px',

            background: '#FFFFFF',
            border: '0.5px solid #A2792C',
            boxSizing: 'border-box',
            borderRadius: '5px'
        }
    };

    const AddNewGroup = () => {
        let tempNewGroup = [...newGroupRows];
        tempNewGroup.push({});
        setNewGroupRows(tempNewGroup);
    };

    const includeNew = () => {
        let tempNewGroup = [...newGroupRows];
        tempNewGroup.push({});
        setNewGroupRows(tempNewGroup);
    };

    return (
        <>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={{ ...styles.cell, border: 'none' }}>
                    <FormControl variant="outlined">
                        <OutlinedInput
                            id="outlined-adornment-weight"
                            style={styles.input}
                            // value={val}
                            // onChange={handleChange()}
                            endAdornment={<InputAdornment position="end">%</InputAdornment>}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                                'aria-label': 'weight'
                            }}
                            labelWidth={0}
                        />
                    </FormControl>
                </TableCell>
                <TableCell style={{ ...styles.cell, border: 'none' }} align="left">
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}>
                        <span
                            style={{
                                fontWeight: '600',
                                fontSize: '15.5px',
                                lineHeight: '19px',
                                /* identical to box height */

                                textTransform: 'capitalize',

                                color: '#333333',
                                marginRight: '10px'
                            }}>
                            From
                        </span>
                        <input type="number" style={styles.input} />
                        <span
                            style={{
                                margin: '0 11px',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#000000'
                            }}>
                            -
                        </span>
                        <input type="number" style={styles.input} />
                    </div>
                </TableCell>
                {newGroupRows.length > 0 ? null : (
                    <TableCell
                        align="left"
                        style={{ ...styles.cell, cursor: 'pointer', border: 'none' }}
                        onClick={AddNewGroup}>
                        <MoreText
                            customStyle={{
                                fontFamily: 'Poppins',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#A2792C'
                            }}
                            text="Add another group price"
                        />
                    </TableCell>
                )}
            </TableRow>
            {newGroupRows.map((each, index) => (
                <NewGroupBookingRow
                    groupLength={newGroupRows.length}
                    itemIndex={index}
                    includeNew={includeNew}
                />
            ))}
        </>
    );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import AccountRow from 'components/molecules/user-account/account-row';
import BusineesLicenseOperations from 'components/molecules/user-account/business-license';
import { Button } from 'reactstrap';
import PublicLiabilityInsurance from 'components/molecules/user-account/public-liability';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

function LicenseAndInsurance(props) {
    const [isEditingBusinessLicense, setEditingBusinessLicense] = useState(false);
    const [isEditingPublicLiability, setEditingPublicLiability] = useState(false);
    const EditButton = (props) => {
        const { trigger } = props;
        return (
            // Drawer Trigger Button
            <Button
                color="primary btn-px"
                className="mw-initial btn-block btn-md-inline-block"
                onClick={() => trigger(true)}
                type="primary">
                Edit
            </Button>
        );
    };

    return (
        // License and Operations Tab Content for User account verification
        <div>
            {/* Business license and operations Card */}
            <AccountRow
                title="Business license and operations"
                completed={Boolean(props?.license_info?.is_license_fill)}
                action={<EditButton trigger={setEditingBusinessLicense} />}>
                {props.license_info && props.license_info.is_license !== 0 && (
                    <>
                        {' '}
                        <p>
                            <span className="mr-3">Business Registration Number:</span>
                            <span>
                                {props.license_info
                                    ? props.license_info.business_reg_number
                                    : 'N/A'}
                            </span>
                        </p>
                        {props.license_info.license_expiry_date && (
                            <p>
                                <span className="mr-3">License expiry date:</span>
                                <span>
                                    {props.license_info
                                        ? props.license_info.license_expiry_date &&
                                          moment(props.license_info.license_expiry_date).format(
                                              'YYYY-MM-DD'
                                          )
                                        : 'N/A'}
                                </span>
                            </p>
                        )}
                        <div className="d-flex">
                            <span className="mr-3">License photo:</span>
                            <span>
                                {' '}
                                <div className="licence-img-wrapper ">
                                    {props.license_info && props.license_info.license_photo_url ? (
                                        <img
                                            src={props.license_info.license_photo_url}
                                            alt=""></img>
                                    ) : (
                                        'N/A'
                                    )}
                                </div>
                            </span>
                        </div>{' '}
                    </>
                )}

                {props?.license_info?.is_license === 0 && <p>No information provided</p>}
            </AccountRow>

            {/* Public liability insurance Card */}
            <AccountRow
                title="Public liability insurance"
                completed={Boolean(props?.license_info?.is_insurance_fill)}
                action={<EditButton trigger={setEditingPublicLiability} />}>
                {props.license_info && props.license_info.is_insurred !== 0 && (
                    <>
                        {' '}
                        <p>
                            <span className="mr-3">Policy Number: </span>
                            <span>
                                {props.license_info
                                    ? props.license_info.policy_number || 'N/A'
                                    : 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className="mr-3">Currency: </span>
                            <span>
                                {props.license_info ? props.license_info.currency || 'N/A' : 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className="mr-3">Amount covered: </span>
                            <span>
                                {props.license_info
                                    ? props.license_info.amount_covered || 'N/A'
                                    : 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className="mr-3">Amount per occurrence: </span>
                            <span>
                                {props.license_info
                                    ? props.license_info.amount_per_occurrence || 'N/A'
                                    : 'N/A'}
                            </span>
                        </p>
                        <p>
                            <span className="mr-3"> Insurance expiry date: </span>
                            <span>
                                {props.license_info
                                    ? (props.license_info.insurrance_expiry_date &&
                                          moment(props.license_info.insurrance_expiry_date).format(
                                              'YYYY-MM-DD'
                                          )) ||
                                      'N/A'
                                    : 'N/A'}
                            </span>
                        </p>
                        <div className="d-flex">
                            <span className="mr-3">Insurance photo:</span>
                            <span>
                                {' '}
                                <div className="licence-img-wrapper ">
                                    {props.license_info &&
                                    props.license_info.insurrance_photo_url ? (
                                        <img
                                            src={props.license_info.insurrance_photo_url}
                                            alt=""></img>
                                    ) : (
                                        'N/A'
                                    )}
                                </div>
                            </span>
                        </div>
                    </>
                )}

                {props?.insurrance_info?.is_insurred === 0 && <p>No information provided</p>}
            </AccountRow>

            {/* Edit business license information Drawer Component */}
            <BusineesLicenseOperations
                handleCancel={() => setEditingBusinessLicense(false)}
                visible={isEditingBusinessLicense}
            />

            {/* Edit Public Laibility Insurance Drawer Component */}
            <PublicLiabilityInsurance
                handleCancel={() => setEditingPublicLiability(false)}
                visible={isEditingPublicLiability}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    license_info: state.profile.license_info,
    insurrance_info: state.profile.insurrance_info
});

const mapDispatch = () => ({});

LicenseAndInsurance.propTypes = {
    trigger: PropTypes.func,
    license_info: PropTypes.any,
    insurrance_info: PropTypes.any
};

export default withRouter(connect(mapStateToProps, mapDispatch)(LicenseAndInsurance));

import React from 'react';
import PropTypes from 'prop-types';
import bulletIcon from '../../../../../assets/images/list_bullet_triangular.png';
import { Row, Col, Button } from 'reactstrap';
import { Drawer, Radio, Form, Input, Checkbox, DatePicker, Popover } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { HelpButton } from 'components/atoms';
const content1 = (
    <div className="fs-14 text-center">
        From the calendars created,
        <br />
        you have available 1 free
        <br />
        calendar to send to your
        <br />
        customers.
    </div>
);
const content2 = (
    <div className="fs-14 text-center">
        From the calendars created,
        <br />
        you have available 6 free
        <br />
        calendar to send to your
        <br />
        customers.
    </div>
);
const content3 = (
    <div className="fs-14 text-center">
        From the calendars created,
        <br />
        you have unlimited calendars
        <br />
        available to send to your
        <br />
        customers.
    </div>
);

export default function UpgradePlanDrawer(props) {
    const {
        setState,
        state,
        vendorSubscription,
        toggleUpgradePlan,
        UpgradePlan,
        handleUpgradePlan
    } = props;

    function loadPaymentOptions() {
        return (
            <div className="card-footer">
                <Radio.Group
                    className="d-block"
                    onChange={(e) => this.setState({ selectedPlanPayment: e.target.value })}
                    value={this.state.selectedPlanPayment}>
                    <Radio className="d-flex align-items-start mb-2" value={'card'}>
                        <div className="fs-16 fw-500">Use card details</div>
                    </Radio>
                    {this.state.selectedPlanPayment === 'card' ? (
                        <div className="mb-2" style={{ paddingLeft: 24 }}>
                            <div
                                className="border hover-border-primary rounded p-3 "
                                role="button"
                                tabIndex="0"
                                onClick={() =>
                                    this.setState({
                                        prefferedPaymentMethod: 'saved',
                                        addAnotherCard: false
                                    })
                                }
                                onKeyDown={() =>
                                    this.setState({
                                        prefferedPaymentMethod: 'saved',
                                        addAnotherCard: false
                                    })
                                }>
                                <div className="d-flex mx-n2">
                                    <div className="px-2 flex-grow-1">
                                        <div className="fs-14 text-primary fw-500">
                                            Use Saved Card
                                        </div>
                                        <div className="fs-14">Card No: XXX XXX 4365</div>
                                    </div>
                                    <div className="px-2 flex-shrink-1">
                                        <div className="fs-13">
                                            You will be charged ${this.state.monthlyPlanCharges}
                                        </div>
                                    </div>
                                    {/* Show if selected */}
                                    {this.state.prefferedPaymentMethod === 'saved' ? (
                                        <div className="px-2">
                                            <CheckCircleFilled
                                                style={{ fontSize: 20, color: '#34A939' }}
                                            />
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                            {this.state.addAnotherCard ? (
                                <div className="border rounded p-3 mt-2">
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 flex-grow-1">
                                            <div className="fs-14 text-primary fw-500 mb-2">
                                                Add another card
                                            </div>
                                        </div>
                                        {/* Show if selected */}
                                        {this.state.prefferedPaymentMethod === 'new' ? (
                                            <div className="px-2">
                                                <CheckCircleFilled
                                                    style={{ fontSize: 20, color: '#34A939' }}
                                                />
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <Row form>
                                        <Col sm={6}>
                                            <Form.Item
                                                className="mb-10px"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter card holder name'
                                                    }
                                                ]}
                                                name="card_holder_name">
                                                <Input placeholder="Card Holder Name" />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={6}>
                                            <Form.Item
                                                className="mb-10px"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter card number'
                                                    }
                                                ]}
                                                name="card_number">
                                                <Input type="number" placeholder="Card Number" />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={6} md={4}>
                                            <Form.Item
                                                className="mb-10px"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter card expiry date'
                                                    }
                                                ]}
                                                name="card_expiry">
                                                <DatePicker
                                                    picker="month"
                                                    format="MM/YY"
                                                    placeholder="MM/YY"
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col sm={6} md={4}>
                                            <Form.Item
                                                className="mb-10px"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter CVV code'
                                                    }
                                                ]}
                                                name="cvv_number">
                                                <Input type="number" placeholder="CVV" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div className="d-flex mx-n2">
                                        <div className="px-2 flex-grow-1 align-self-end">
                                            <Checkbox className="fs-15 text-nowrap">
                                                Save my card for faster checkout
                                            </Checkbox>
                                        </div>
                                        <div className="px-2">
                                            <div className="fs-13">
                                                You will be charged ${this.state.monthlyPlanCharges}{' '}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <button
                                    type="button"
                                    className="btn btn-link p-0 mt-2 fs-14"
                                    onClick={() =>
                                        this.setState({
                                            prefferedPaymentMethod: 'new',
                                            addAnotherCard: true
                                        })
                                    }>
                                    Add Another Card
                                </button>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                    <Radio className="d-flex align-items-start mb-2" value={'another_payment'}>
                        <div className="fs-16 fw-500">Add another payment option</div>
                    </Radio>
                    {this.state.selectedPlanPayment === 'another_payment' ? (
                        <div className="mb-2" style={{ paddingLeft: 24 }}>
                            <div className="d-flex align-items-center mx-n2">
                                <div className="mx-2 " role="button">
                                    <img
                                        src={require('../../../../../assets/images/paypal.png')}
                                        alt=""
                                    />
                                </div>
                                <div className="mx-2 " role="button">
                                    <img
                                        src={require('../../../../../assets/images/paytm.png')}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </Radio.Group>
            </div>
        );
    }

    return (
        <Drawer
            width={595}
            placement="right"
            onClose={() => toggleUpgradePlan(false)}
            visible={UpgradePlan}
            className="drawers-padding"
            style={{ zIndex: 1060 }}>
            <h4 className="mb-3 mt-2">Access Plans</h4>

            {/* Free Plan */}
            <div className="card card-plan mb-3">
                <div className="card-body">
                    <div className="d-flex align-items-start">
                        <h4 className="card-title mr-2 mb-0 flex-grow-1">Free</h4>
                        <div className="bg-white py-2 px-3 mt-1 mr-1 rounded border fw-500 fs-12">
                            Current Plan
                        </div>
                    </div>
                    <ul className="list-unstyled fw-600 fs-14 mb-2 ml-1">
                        <li>
                            <img className="pr-2 " src={bulletIcon} alt="" />$
                            {vendorSubscription?.plan_price} monthly fee
                        </li>
                        <li>
                            <img className="pr-2 " src={bulletIcon} alt="" />
                            {vendorSubscription?.no_of_free_calendar} Free Calendar
                            <Popover
                                placement="bottom"
                                getPopupContainer={(trigger) => trigger.parentNode}
                                content={content1}
                                trigger="click">
                                <ExclamationCircleFilled
                                    className="pl-1"
                                    style={{ color: '#E2C022' }}
                                />
                            </Popover>
                        </li>

                        <li>
                            <img className="pr-2 " src={bulletIcon} alt="" />
                            {vendorSubscription?.available_token} Free Travel Jinni Customer Token
                        </li>
                    </ul>
                    <div className="text-primary fs-14 font-weight-bold">Token Top-up</div>
                    <div className="fs-12 fw-500">
                        ${vendorSubscription?.token_topup_price} per Customer Token (Instead of
                        Customer Pay ${vendorSubscription?.price_per_customer_token})
                    </div>
                </div>
            </div>

            {/* Premium Plan */}
            <div className="card card-plan mb-3">
                <div className="card-body">
                    <div className="d-flex align-items-start">
                        <h4 className="card-title  mb-0 flex-grow-1">Premium</h4>
                        <Button
                            type="dark"
                            className="btn-px black-button fw-500 fs-12 mr-2 "
                            onClick={() =>
                                setState({
                                    planSelected: 'premium',
                                    monthlyPlanCharges: 20,
                                    paynowbutton: 'Pay Now'
                                })
                            }>
                            Upgrade
                        </Button>
                    </div>
                    <ul className="list-unstyled fw-600 fs-14 mb-2 ml-1">
                        <li>
                            <img className="pr-2" src={bulletIcon} alt="" />
                            $20 monthly fee <span>(Cancel at anytime)</span>
                        </li>
                        <li>
                            <img className="pr-2" src={bulletIcon} alt="" />6 Free Calendars
                            <Popover
                                placement="bottom"
                                getPopupContainer={(trigger) => trigger.parentNode}
                                content={content2}
                                trigger="click">
                                <ExclamationCircleFilled
                                    className="pl-1"
                                    style={{ color: '#E2C022' }}
                                />
                            </Popover>
                        </li>
                        <li>
                            <img className="pr-2" src={bulletIcon} alt="" />
                            20 Free Travel Jinni Customer Token
                        </li>
                    </ul>
                    <div className="text-primary fs-14 font-weight-bold">Token Top-up</div>
                    <div className="fs-12 fw-500">
                        $2.50 per Customer Token (Instead of Customer Pay $20)
                    </div>
                </div>
                {/* Payment Options */}
                {state.planSelected === 'premium' ? loadPaymentOptions() : ''}
            </div>

            {/* Enterprise Plan */}
            <div className="card card-plan mb-3">
                <div className="card-body">
                    <div className="d-flex align-items-start">
                        <h4 className="card-title  mb-0 flex-grow-1">Enterprise</h4>
                        <Button
                            type="dark"
                            className="btn-px black-button fw-500 mr-2 fs-12 "
                            onClick={() =>
                                setState({
                                    planSelected: 'enterprise',
                                    monthlyPlanCharges: 50,
                                    paynowbutton: 'Pay Now'
                                })
                            }>
                            Upgrade
                        </Button>
                    </div>
                    <ul className="list-unstyled fw-600 fs-14 mb-2 ml-1">
                        <li>
                            <img className="pr-2" src={bulletIcon} alt="" />
                            $50 monthly fee <span>(Cancel at anytime)</span>
                        </li>
                        <li>
                            <img className="pr-2" src={bulletIcon} alt="" />
                            Unlimited Calendars
                            <Popover
                                placement="bottom"
                                getPopupContainer={(trigger) => trigger.parentNode}
                                content={content3}
                                trigger="click">
                                <ExclamationCircleFilled
                                    className="pl-1"
                                    style={{ color: '#E2C022' }}
                                />
                            </Popover>
                        </li>
                        <li>
                            <img className="pr-2" src={bulletIcon} alt="" />
                            50 Free Travel Jinni Customer Token
                        </li>
                    </ul>
                    <div className="text-primary fs-14 font-weight-bold">Token Top-up</div>
                    <div className="fs-12 fw-500">
                        $1 per Customer Token (Instead of Customer Pay $20)
                    </div>
                </div>
                {/* Payment Options */}
                {state.planSelected === 'enterprise' ? loadPaymentOptions() : ''}
            </div>

            <div className="d-flex justify-content-between upgrade-plan-btn-div">
                <Button
                    className="send-footer-btn upgrade-close "
                    size="large"
                    onClick={() => handleUpgradePlan(state.planSelected)}>
                    {state.paynowbutton}
                </Button>
                <div className="d-none d-sm-block">
                    <HelpButton />
                </div>
            </div>
        </Drawer>
    );
}

UpgradePlanDrawer.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    toggleUpgradePlan: PropTypes.func,
    vendorSubscription: PropTypes.object,
    UpgradePlan: PropTypes.bool,
    handleUpgradePlan: PropTypes.func
};

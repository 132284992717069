/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect } from 'react';
import './index.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form, Input, Radio } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import Spacer from '../../../../atoms/spacer';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../../modals/confirmation-modal';
import { compareBreakFast } from 'utils';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '19px',
    marginBottom: '7px'
};

function BreakfastDetails(props) {
    const { selected_existing_product, data } = props;

    const [form] = Form.useForm();

    const [breakfast, setBreakfast] = useState([
        'A la carte',
        'American',
        'Asian',
        'Buffet',
        'Continental',
        'Full English/Irish',
        'Gluten free',
        'Halal',
        'Italian',
        'Kosher',
        'Vegan',
        'Vegetarian',
        'Other'
    ]);
    const [isServeBreakfast, setIsServeBreakfast] = useState(true);
    const [isIncludedPrice, setIsIncludedPrice] = useState(true);
    const [addedBreakfast, setAddedBreakfast] = useState([]);
    const [otherBreakfast, setOtherBreakfast] = useState('');
    const [breakfastPrice, setBreakfastPrice] = useState('');

    const [confirmationModal, setConfirmationModal] = useState(false);

    // adding breakfast
    const onAddBreakfast = (item) => {
        const index = isAddBreakfast(item);
        if (index === -1) setAddedBreakfast([...addedBreakfast, item]);
        else {
            let tmp = [...addedBreakfast];
            tmp.splice(index, 1);
            setAddedBreakfast([...tmp]);
        }

        form.validateFields(['addedBreakfast']);
    };
    // adding other breakfast
    const onAddOtherCategory = () => {
        const isAdded = breakfast.findIndex((res) => res === otherBreakfast);
        if (isAdded === -1 && otherBreakfast.trim() !== '') {
            const tmp = breakfast;
            tmp.splice(breakfast.length - 1, 0, otherBreakfast);
            setBreakfast([...tmp]);
            onAddBreakfast('Other');
            setOtherBreakfast('');
        } else {
            onAddBreakfast('Other');
            setOtherBreakfast('');
        }
    };

    const isAddBreakfast = (item) => addedBreakfast.findIndex((res) => res === item);

    useEffect(() => {
        if (data) {
            if (data.is_breakfast_serve) {
                setIsServeBreakfast(data.is_breakfast_serve);
                if (!data?.is_price_include) {
                    setIsIncludedPrice(false);
                    setBreakfastPrice(data.price);
                } else setIsIncludedPrice(true);
                if (data?.types) setAddedBreakfast(data?.types);
            } else setIsServeBreakfast(data.is_breakfast_serve || false);
        } else {
            setIsIncludedPrice(true);
            setBreakfastPrice('');
            setAddedBreakfast([]);
        }
    }, [data]);

    const onFinish = () => {
        form.validateFields()
            .then(() => {
                if (selected_existing_product && data)
                    if (
                        compareBreakFast(data, {
                            addedBreakfast,
                            breakfastPrice,
                            isIncludedPrice,
                            isServeBreakfast
                        })
                    )
                        return setConfirmationModal(true);
                    else props.onClose();
                else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onSave = () => {
        form.validateFields()
            .then(() => {
                if (isServeBreakfast) {
                    if (!isIncludedPrice) {
                        const breakfast = {
                            included: isServeBreakfast,
                            price: breakfastPrice,
                            types: addedBreakfast,
                            isIncludedPrice: false
                        };
                        props.onSave(breakfast);
                        onClear();
                    } else {
                        const breakfast = {
                            included: isServeBreakfast,
                            types: addedBreakfast,
                            isIncludedPrice: true
                        };
                        props.onSave(breakfast);
                        onClear();
                    }
                } else {
                    const breakfast = {
                        included: isServeBreakfast
                        // price: breakfastPrice,
                        // types: addedBreakfast
                    };
                    props.onSave(breakfast);
                    onClear();
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onClear = () => {
        form.resetFields();
    };

    const onClose = () => {
        props.onClose();
        onClear();
    };

    const isDisable = !isIncludedPrice && breakfastPrice === '';
    return (
        <Modal isOpen={props.isOpen} centered size="lg">
            <Form
                form={form}
                name="breakfast-details-form"
                className="breakfast-details"
                initialValues={{ breakfastPrice }}>
                <ModalHeader className="title">Breakfast Details</ModalHeader>
                <ModalBody className="body-modal">
                    <Form.Item>
                        <div>Do you serve guests breakfast?</div>
                        <Spacer top={10} />
                        <Radio.Group
                            value={isServeBreakfast}
                            onChange={(e) => setIsServeBreakfast(e.target.value)}>
                            <Radio style={radioStyle} value={true}>
                                Yes
                            </Radio>
                            <Radio style={radioStyle} value={false}>
                                No
                            </Radio>
                        </Radio.Group>
                    </Form.Item>
                    {isServeBreakfast ? (
                        <div>
                            <Form.Item>
                                <div className="included-price">
                                    <div>Is breakfast included in the price guests pay?</div>
                                    <Spacer top={10} />
                                    <Radio.Group
                                        value={isIncludedPrice}
                                        onChange={(e) => setIsIncludedPrice(e.target.value)}>
                                        <Radio style={radioStyle} value={true}>
                                            Yes, it's included
                                        </Radio>
                                        <Radio style={radioStyle} value={false}>
                                            No, it costs extra
                                        </Radio>
                                    </Radio.Group>
                                </div>
                            </Form.Item>

                            {!isIncludedPrice && <div>Breakfast price per person, per day</div>}

                            {!isIncludedPrice ? (
                                <Form.Item
                                    name="breakfastPrice"
                                    style={{ marginBottom: 5 }}
                                    rules={[{ required: true, message: 'Required' }]}>
                                    <Input
                                        type="number"
                                        className="input-price"
                                        addonBefore="$"
                                        value={breakfastPrice}
                                        onChange={(e) => setBreakfastPrice(e.target.value)}
                                        style={{ width: 'auto' }}
                                    />
                                </Form.Item>
                            ) : null}
                            {!isIncludedPrice && (
                                <div className="input-price-description">
                                    Including all fees and taxes
                                </div>
                            )}

                            <hr width="100%" />
                            <Form.Item
                                name="addedBreakfast"
                                rules={[
                                    {
                                        validator: () =>
                                            props.validateRequiredFormField({
                                                data: addedBreakfast,
                                                fieldType: 'ARRAY'
                                            })
                                    }
                                ]}>
                                <div className="select-title-breakfast">
                                    What type of breakfast do you offer?
                                </div>
                                <div className="select-subtitle-breakfast">
                                    Select all that apply
                                </div>

                                <Row style={{ marginLeft: 0 }}>
                                    {breakfast.map((res, i) => (
                                        <div
                                            className={`breakfast cursor-pointer unselectable ${
                                                isAddBreakfast(res) !== -1 ? 'active-breakfast' : ''
                                            }`}
                                            key={i}
                                            onClick={() => onAddBreakfast(res)}>
                                            {res}
                                        </div>
                                    ))}
                                </Row>
                                <Spacer top={10} />
                                {isAddBreakfast('Other') !== -1 ? (
                                    <div>
                                        <div style={{ fontWeight: 500 }}>
                                            Enter type of breakfast
                                        </div>
                                        <Spacer top={10} />
                                        <Input
                                            placeholder="Enter type of breakfast"
                                            className="input-type"
                                            value={otherBreakfast}
                                            onChange={(e) => {
                                                setOtherBreakfast(e.target.value);
                                            }}
                                        />
                                        <Spacer top={10} />
                                        <Button
                                            style={{ width: '111px' }}
                                            color="primary"
                                            onClick={onAddOtherCategory}>
                                            Done
                                        </Button>
                                    </div>
                                ) : null}
                            </Form.Item>
                        </div>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    <div className="footer-btn">
                        <div>
                            <Button disabled={isDisable} color="primary" onClick={onFinish}>
                                {props.submitButtonName ?? 'Update Calender & Continue'}
                            </Button>
                            <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark" style={{ width: '137px' }}>
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </ModalFooter>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                handleConfirmation={() => {
                    onSave();
                    setConfirmationModal(false);
                }}
            />
        </Modal>
    );
}
BreakfastDetails.propTypes = {
    data: Proptypes.any,
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    submitButtonName: Proptypes.string,
    selected_existing_product: Proptypes.bool,
    validateRequiredFormField: Proptypes.func
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(BreakfastDetails));

import React from 'react';
import PropTypes from 'prop-types';
import { Menu, Dropdown } from 'antd';

const EventSchedule = ({ duration }) => {
    return (
        <div className="d-flex flex-nowrap mx-n2 event-schedule">
            <div className="px-2 event-time-window-wrapper">
                <div className="event-time-window padded__travel-mode">
                    <div className="event-time-icon-wrapper">
                        <Dropdown
                            overlay={
                                <Menu>
                                    <Menu.Item>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.antgroup.com">
                                            1st menu item
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="https://www.luohanacademy.com">
                                            2nd menu item
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            }
                            getPopupContainer={(trigger) => trigger.parentElement}>
                            <span className="event-time-icon __with-icon __icon-position-absolute">
                                <span>
                                    <svg
                                        width="24"
                                        height="23"
                                        viewBox="0 0 24 23"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M21.2262 2.16219C20.9595 1.35094 20.2128 0.773438 19.3328 0.773438H4.66618C3.78618 0.773438 3.05285 1.35094 2.77285 2.16219L-0.000488281 10.3984V21.3984C-0.000488281 22.1547 0.599512 22.7734 1.33285 22.7734H2.66618C3.39951 22.7734 3.99951 22.1547 3.99951 21.3984V20.0234H19.9995V21.3984C19.9995 22.1547 20.5995 22.7734 21.3328 22.7734H22.6662C23.3995 22.7734 23.9995 22.1547 23.9995 21.3984V10.3984L21.2262 2.16219ZM4.66618 15.8984C3.55951 15.8984 2.66618 14.9772 2.66618 13.8359C2.66618 12.6947 3.55951 11.7734 4.66618 11.7734C5.77285 11.7734 6.66618 12.6947 6.66618 13.8359C6.66618 14.9772 5.77285 15.8984 4.66618 15.8984ZM19.3328 15.8984C18.2262 15.8984 17.3328 14.9772 17.3328 13.8359C17.3328 12.6947 18.2262 11.7734 19.3328 11.7734C20.4395 11.7734 21.3328 12.6947 21.3328 13.8359C21.3328 14.9772 20.4395 15.8984 19.3328 15.8984ZM2.66618 9.02344L4.66618 2.83594H19.3328L21.3328 9.02344H2.66618Z"
                                            fill="white"
                                        />
                                    </svg>
                                </span>
                            </span>
                        </Dropdown>
                    </div>
                </div>
            </div>
            <div className="px-2 flex-grow-1 align-self-center">
                <div className="event-info-wrapper padded__travel-mode">
                    <div className="timeline-info">
                        <div className="timeline-eta">
                            <span>{duration}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

EventSchedule.propTypes = {
    duration: PropTypes.any
};

export default EventSchedule;

import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import Media from '../media';

function MediaList(props) {
    const { medias, removeMedia } = props;
    return (
        <div className="media-list">
            {medias.map((media, index) => (
                <Media
                    removeMedia={() => removeMedia(index)}
                    src={media.src}
                    badge={media.badge}
                    caption={media.caption}
                    key={media.src + index}
                />
            ))}
        </div>
    );
}

MediaList.propTypes = {
    removeMedia: PropTypes.func,
    medias: PropTypes.arrayOf({
        src: PropTypes.string,
        caption: PropTypes.string
    })
};

export default MediaList;

import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import { fetchLegalContent } from 'store/actions/contentActions';
import { setInitialAuth } from 'store/actions/AuthActions';
import { connect } from 'react-redux';
import LoaderComponent from 'components/atoms/loader';
import { Skeleton } from 'antd';
import PropTypes from 'prop-types';

const Terms = (props) => {
    const [didMount, setdidMount] = useState(false);
    const [legalContent, setLegalContent] = useState({});

    useEffect(() => {
        if (!didMount) {
            props.setInitialAuth();
            localStorage.clear();
            props.fetchLegalContent();
            setdidMount(true);
        } else {
            setLegalContent(props.legal_content);
        }
    }, [props.legal_content]);

    return (
        <>
            {/* Loader Component */}
            {!legalContent?.termsAndCondition ? <LoaderComponent /> : ''}

            {/* Static section for all static pages e.g Privacy Policy, Terms etc. */}
            <section className="static-section">
                <Container className="container-section">
                    <h1 className="static-heading">
                        {legalContent?.termsAndCondition?.title &&
                            ReactHtmlParser(legalContent?.termsAndCondition?.title)}
                    </h1>
                    <div className="static-content-wrapper">
                        <Skeleton active loading={!legalContent?.termsAndCondition}>
                            {legalContent?.termsAndCondition?.body &&
                                ReactHtmlParser(legalContent?.termsAndCondition?.body)}
                        </Skeleton>
                    </div>
                </Container>
            </section>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        legal_content: state.contentDetail.legal_data
    };
};

const mapProps = (dispatch) => ({
    fetchLegalContent: () => dispatch(fetchLegalContent()),
    setInitialAuth: () => dispatch(setInitialAuth())
});

Terms.propTypes = {
    legal_content: PropTypes.object,
    fetchLegalContent: PropTypes.func,
    setInitialAuth: PropTypes.func
};

export default connect(mapStateToProps, mapProps)(Terms);

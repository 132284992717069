import React, { Component } from 'react';
import { Tabs } from 'antd';
import '../index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
    getPaystackTotalBal,
    getPaystackOtherBal,
    getStripeDashboardLink,
    getPaystackSingleTransaction,
    loading_
} from 'store/actions';
import PaymentsTab from './tabs/payments-tab';
// import TransfersTab from './tabs/transfers-tab';
import PayoutsTab from './tabs/payouts-tab';
import BalanceDetailSection from './balance-detail-section';
import BanksDetailSection from './banks-detail-section';
import PaymentDetailModal from './payment-detail-modal';
import { ComponentPermissionContext } from 'context/rolesandpermission';
import { PaystackAccountUpdateModal } from 'components/molecules';

const { TabPane } = Tabs;

class Paystack extends Component {
    static contextType = ComponentPermissionContext;
    state = {
        showModal: false,
        showDetailsModal: false,
        activeTab: '1'
    };

    componentDidMount() {
        const { my_profile, getPaystackTotalBal, getPaystackOtherBal } = this.props;

        if (my_profile?.vendor?.paystack_account_id) {
            getPaystackTotalBal();
            getPaystackOtherBal();
        }
    }

    componentDidUpdate(prevProp, prevState) {
        const { my_profile, getPaystackTotalBal, getPaystackOtherBal } = this.props;
        const { paymentDetailModal } = this.state;
        if (paymentDetailModal && paymentDetailModal.id)
            if (!prevState.paymentDetailModal) {
                this.props.getPaystackSingleTransaction({
                    id: paymentDetailModal.id,
                    url: paymentDetailModal.tab
                });
            } else if (paymentDetailModal.id !== prevState.paymentDetailModal?.id) {
                this.props.getPaystackSingleTransaction({
                    id: paymentDetailModal.id,
                    url: paymentDetailModal.tab
                });
            }

        if (
            my_profile?.vendor?.paystack_account_id !==
                prevProp?.my_profile?.vendor?.paystack_account_id &&
            my_profile?.vendor?.paystack_account_id
        ) {
            getPaystackTotalBal();
            getPaystackOtherBal();
        }
    }

    handleRetrieveClick = () => {
        this.setState({ showModal: true });
    };

    toggle = (activeKey) => {
        this.setState({
            activeTab: activeKey
        });
    };

    redirectToStripeDashboard = () => {
        this.props.getStripeDashboardLink().then((res) => {
            if (res?.url) {
                window.location.assign(res?.url);
                // this.props.history.push(res?.url)
            }
        });
    };

    render() {
        const { my_profile, paystack_account_det } = this.props;
        return (
            <div className={`${this.context.assignComponentPermission('finance')}`}>
                <div>
                    <BalanceDetailSection
                        paystack_account_det={paystack_account_det}
                        my_profile={my_profile}
                        openUpdateModal={() => this.setState({ showPayoutModal: true })}
                    />
                    <div className="my-5"></div>

                    <div
                        className={`mb-4  ${this.context.assignComponentPermission(
                            'finance-activity'
                        )}  `}>
                        <div className="d-flex gap-3">
                            <div className="fs-20 fw-700 pb-2">
                                <span>Activity</span>
                            </div>
                        </div>

                        <Tabs
                            size="small"
                            className="padded-tabs has-filter-top"
                            defaultActiveKey="payments">
                            <TabPane tab="Payments" key="payments">
                                <PaymentsTab
                                    onClickRow={(data) =>
                                        this.setState({ paymentDetailModal: data })
                                    }
                                />
                            </TabPane>
                            {/* <TabPane tab="Transfers" key="transfers">
                                <TransfersTab
                                    onClickRow={(data) =>
                                        this.setState({ paymentDetailModal: data })
                                    }
                                />
                            </TabPane> */}
                            <TabPane tab="Payouts" key="payouts">
                                <PayoutsTab
                                    onClickRow={(data) =>
                                        this.setState({ paymentDetailModal: data })
                                    }
                                />
                            </TabPane>
                        </Tabs>
                    </div>

                    <BanksDetailSection
                        stripe_account_detail={{}}
                        openUpdateModal={() => this.setState({ showPayoutModal: true })}
                    />
                </div>

                <PaymentDetailModal
                    setState={(data) => this.setState(data)}
                    paymentDetailModal={this.state.paymentDetailModal}
                    data={[]}
                    paymentDetail={this.props.stripe_single_tran_detail}
                />

                {this.state.showPayoutModal && (
                    <PaystackAccountUpdateModal
                        title="Update Paystack account"
                        showModal={this.state.showPayoutModal}
                        toggleModal={() =>
                            this.setState((prevState) => {
                                return { showPayoutModal: !prevState.showPayoutModal };
                            })
                        }
                    />
                )}
            </div>
        );
    }
}

Paystack.propTypes = {
    getPaystackTotalBal: PropTypes.func,
    getPaystackOtherBal: PropTypes.func,
    loading: PropTypes.bool,
    my_profile: PropTypes.object,
    getStripeDashboardLink: PropTypes.func,
    getPaystackSingleTransaction: PropTypes.func,
    stripe_single_tran_detail: PropTypes.object,
    loading_: PropTypes.func,
    paystack_account_det: PropTypes.func
};

const mapStateToProps = (state) => ({
    my_profile: state.auth.my_profile,
    paystack_account_det: state.profile.paystack_account_det,
    loading: state.loading,
    stripe_single_tran_detail: state.profile.stripe_single_tran_detail
});

const mapDispatch = (dispatch) => ({
    getPaystackTotalBal: () => dispatch(getPaystackTotalBal()),
    getPaystackOtherBal: () => dispatch(getPaystackOtherBal()),
    getStripeDashboardLink: () => dispatch(getStripeDashboardLink()),

    getPaystackSingleTransaction: (query) => dispatch(getPaystackSingleTransaction(query)),
    loading_: (bool) => dispatch(loading_(bool))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Paystack));

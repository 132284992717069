/* eslint-disable react/jsx-key */
import ControllerClassic from '../../../../../assets/images/mdi_controller-classic.svg';
import FamilyeSafari from '../../../../../assets/images/ic_baseline-family-restroom.svg';
import AccessibleSafari from '../../../../../assets/images/ic_baseline-accessible.svg';
import LocalPerformance from '../../../../../assets/images/local-performance.svg';
import Gorilla from '../../../../../assets/images/emojione-monotone_gorilla.svg';
import WalkingSafari from '../../../../../assets/images/fa-solid_walking.svg';
import FamilyFriendly from '../../../../../assets/images/family-friendly.svg';
import SingingClasses from '../../../../../assets/images/singing-classes.svg';
import Camera from '../../../../../assets/images/ic_round-photo-camera.svg';
import HorseSafari from '../../../../../assets/images/mdi_horse-human.svg';
import KidsFriendly from '../../../../../assets/images/kids-friendly.svg';
import PowerBoating from '../../../../../assets/images/power-boating.svg';
import LuxurySafari from '../../../../../assets/images/luxurysafari.svg';
import HorseRiding from '../../../../../assets/images/horse-riding.svg';
import Orienteering from '../../../../../assets/images/orientation.svg';
import FoodTasting from '../../../../../assets/images/food-tasting.svg';
import WineTasting from '../../../../../assets/images/wine-tasting.svg';
import Anniversary from '../../../../../assets/images/anniversary.svg';
import PeopleTeam from '../../../../../assets/images/people-team.svg';
import FathersDay from '../../../../../assets/images/fathers-day.svg';
import MothersDay from '../../../../../assets/images/mothers-day.svg';
import Cycling from '../../../../../assets/images/bx_bx-cycling.svg';
import Meditation from '../../../../../assets/images/meditation.svg';
import Photoshoot from '../../../../../assets/images/photoshoot.svg';
import Scrambling from '../../../../../assets/images/scrambling.svg';
import Papercraft from '../../../../../assets/images/papercraft.svg';
import LocalTaxi from '../../../../../assets/images/local-taxi.svg';
import Honeymoon from '../../../../../assets/images/honey-moon.svg';
import Carnivals from '../../../../../assets/images/carnivals.svg';
import Birthdays from '../../../../../assets/images/birthdays.svg';
import Christmas from '../../../../../assets/images/christmas.svg';
import Woodcraft from '../../../../../assets/images/woodcraft.svg';
import MapCanoe from '../../../../../assets/images/map_canoe.svg';
import HillWalk from '../../../../../assets/images/hill-walk.svg';
import FlyinSafari from '../../../../../assets/images/la_fly.svg';
import Romantic from '../../../../../assets/images/romantic.svg';
import Kayaking from '../../../../../assets/images/kayaking.svg';
import Bachelor from '../../../../../assets/images/bachelor.svg';
import Swimming from '../../../../../assets/images/swimming.svg';
import Climbing from '../../../../../assets/images/climbing.svg';
import Jewellery from '../../../../../assets/images/jewelry.svg';
import Textiles from '../../../../../assets/images/textiles.svg';
import Golf from '../../../../../assets/images/vaadin_golf.svg';
import Bachelorette from '../../../../../assets/images/hen.svg';
import Standup from '../../../../../assets/images/stand-up.svg';
import Theatre from '../../../../../assets/images/theatre.svg';
import Brewery from '../../../../../assets/images/brewery.svg';
import Cookery from '../../../../../assets/images/cookery.svg';
import Picnics from '../../../../../assets/images/picnics.svg';
import Duckies from '../../../../../assets/images/duckies.svg';
import Sailing from '../../../../../assets/images/sailing.svg';
import Concert from '../../../../../assets/images/concert.svg';
import Archery from '../../../../../assets/images/archery.svg';
import Camping from '../../../../../assets/images/camping.svg';
import Pottery from '../../../../../assets/images/pottery.svg';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Biking from '../../../../../assets/images/biking.svg';
import Skiing from '../../../../../assets/images/skiing.svg';
import Dinner from '../../../../../assets/images/dinner.svg';
import Dance from '../../../../../assets/images/dance.svg';
import Party from '../../../../../assets/images/party.svg';
import { Modal, Button, Form, Row, Col } from 'antd';
import Spa from '../../../../../assets/images/spa.svg';
import Selector from '../../../../molecules/selector';
import Prompter from '../../../../atoms/prompter';
import React, { useState } from 'react';
import Spacer from '../../../../atoms/spacer';
import PropTypes from 'prop-types';
import Display from '../../../../atoms/display';
import './index.scss';

const Adventure = (props) => {
    const { handleOk, isVisible, handleCancel } = props;
    const [activeSelection, setActiveSelection] = useState(null);
    const adventures = {
        'safari': [
            {
                icon: AccessibleSafari,
                name: 'Accessible safari'
            },
            {
                icon: FamilyeSafari,
                name: 'Family Safari'
            },
            {
                icon: HorseSafari,
                name: 'Horseback Safari'
            },
            {
                icon: MapCanoe,
                name: 'Canoe Safari'
            },
            {
                icon: Cycling,
                name: 'Cycling Safari'
            },
            {
                icon: Golf,
                name: 'Golf & Wildlife'
            },
            {
                icon: ControllerClassic,
                name: 'Classic Safari'
            },
            {
                icon: FlyinSafari,
                name: 'Fly-in Safari'
            },
            {
                icon: LuxurySafari,
                name: 'Luxury Safari'
            },
            {
                icon: Camera,
                name: 'Photographic Safari'
            },
            {
                icon: Gorilla,
                name: 'Gorillas and Primates'
            },
            {
                icon: WalkingSafari,
                name: 'Walking Safari'
            }
        ],
        'Arts and Crafts': [
            {
                icon: Dance,
                name: 'Dance classes'
            },
            {
                icon: SingingClasses,
                name: 'Singing classes'
            },
            {
                icon: Jewellery,
                name: 'Jewellery Making'
            },
            {
                icon: Textiles,
                name: 'Textiles'
            },
            {
                icon: Papercraft,
                name: 'Papercraft'
            },
            {
                icon: Pottery,
                name: 'Pottery and Glass Crafts'
            },
            {
                icon: Woodcraft,
                name: 'Woodcraft'
            }
        ],
        'River Cruises': [
            {
                icon: Dinner,
                name: 'Dinner'
            },
            {
                icon: LocalTaxi,
                name: 'Local'
            },
            {
                icon: Party,
                name: 'Party'
            },
            {
                icon: Romantic,
                name: 'Romantic'
            }
        ],
        'Sporting Days out': [
            {
                icon: HorseRiding,
                name: 'Horse Racing'
            },
            {
                icon: Orienteering,
                name: 'Orienteering'
            },
            {
                icon: PeopleTeam,
                name: 'Team Building'
            }
        ],
        'Shows and Performance': [
            {
                icon: Carnivals,
                name: 'Carnivals'
            },
            {
                icon: Concert,
                name: 'Concerts'
            },
            {
                icon: LocalPerformance,
                name: 'Local Performance'
            },
            {
                icon: Theatre,
                name: 'Theatre'
            }
        ],
        'Outdoor adventure': [
            {
                icon: Archery,
                name: 'Archery'
            },
            {
                icon: Biking,
                name: 'Biking'
            },
            {
                icon: Camping,
                name: 'Camping'
            },
            {
                icon: HillWalk,
                name: 'Hill walk'
            },
            {
                icon: Scrambling,
                name: 'Gorge Walking / Scrambling'
            },
            {
                icon: Climbing,
                name: 'Climbing / Abseil'
            },
            {
                icon: Skiing,
                name: 'Skiing / Snowboarding'
            }
        ],
        'Water Adventure': [
            {
                icon: MapCanoe,
                name: 'Canoeing'
            },
            {
                icon: Duckies,
                name: 'Duckies'
            },
            {
                icon: Kayaking,
                name: 'Kayaking'
            },
            {
                icon: PowerBoating,
                name: 'Power boating'
            },
            {
                icon: Sailing,
                name: 'Sailing'
            },
            {
                icon: Standup,
                name: 'Stand Up Paddle boarding'
            },
            {
                icon: Swimming,
                name: 'Swimming'
            }
        ],
        'Food and Drink': [
            {
                icon: Brewery,
                name: 'Brewery Tour'
            },
            {
                icon: Cookery,
                name: 'Cookery courses'
            },
            {
                icon: FoodTasting,
                name: 'Food tasting'
            },
            {
                icon: Picnics,
                name: 'Picnics'
            },
            {
                icon: WineTasting,
                name: 'Wine tasting'
            }
        ],
        'Health and Beauty': [
            {
                icon: Meditation,
                name: 'Meditation'
            },
            {
                icon: Photoshoot,
                name: 'Photoshoot'
            },
            {
                icon: Spa,
                name: 'Spa treatment'
            }
        ],
        'Occasions': [
            {
                icon: Anniversary,
                name: 'Anniversary'
            },
            {
                icon: Birthdays,
                name: 'Birthdays'
            },
            {
                icon: Christmas,
                name: 'Christmas'
            },
            {
                icon: FathersDay,
                name: 'Fathers day'
            },
            {
                icon: Bachelor,
                name: 'Bachelor / Stag'
            },
            {
                icon: FamilyFriendly,
                name: 'Family friendly'
            },
            {
                icon: Honeymoon,
                name: 'Honeymoon/ Romantic'
            },
            {
                icon: Bachelorette,
                name: 'Bachelorette / Hen'
            },
            {
                icon: MothersDay,
                name: 'Mothers day'
            },
            {
                icon: KidsFriendly,
                name: 'Kids friendly'
            }
        ]
    };

    const handleSelection = (title, isExpanded) => {
        if (isExpanded) {
            setActiveSelection(title);
        } else {
            setActiveSelection(null);
        }
    };

    return (
        <Modal
            width={730}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            wrapClassName="adventure"
            footer={[
                <Button
                    onClick={handleOk}
                    style={{ width: 135, height: 42 }}
                    type="primary"
                    className="rounded-btn">
                    Add
                </Button>,
                <Button
                    style={{ width: 135, height: 42 }}
                    type="outline"
                    className="rounded-btn mr20"
                    onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    type="primary"
                    className="rounded-btn"
                    style={{ float: 'right', height: 42 }}>
                    <QuestionCircleOutlined /> Help
                </Button>
            ]}>
            <h4>Add adventure on this Itinerary</h4>
            <Prompter
                text="I will import adventure information from my other activity "
                textColor="#A2792C"
            />
            <Spacer top={16} />
            <p>Select the adventures your customers will experience on this itinerary.</p>
            <p>
                {' '}
                Please note that this is what your customers will search for when booking your
                product.
            </p>
            <Spacer top={36} />
            <div className="adventure__form-wrapper">
                <Form
                    // form={form}
                    layout="vertical">
                    <h6>Select Adventure</h6>
                    <Row gutter={8}>
                        {Object.keys(adventures).map((adventure, index) => (
                            <Display
                                key={index}
                                forceRender={true}
                                show={
                                    (adventure && activeSelection == adventure) || !activeSelection
                                }>
                                <Col span="12">
                                    <Selector
                                        onSelect={handleSelection}
                                        options={adventures[adventure]}
                                        title={adventure}
                                    />
                                </Col>
                            </Display>
                        ))}
                    </Row>
                </Form>
            </div>
        </Modal>
    );
};

Adventure.propTypes = {
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired
};

export default Adventure;

import React from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';

const EventImageGallery = (props) => {
    const { eventInfo, responsive } = props;
    return (
        <div className="event-gallery-wrapper">
            {eventInfo?.event?.extendedProps?.imageurl && (
                <div className="event-gallery-item">
                    <img
                        className="event-gallery-img"
                        src={eventInfo.event.extendedProps.imageurl}
                        alt=""
                    />
                </div>
            )}

            {eventInfo?.event?.extendedProps?.imageGallery &&
                eventInfo?.event?.extendedProps?.imageGallery.length && (
                    <Carousel
                        swipeable={true}
                        draggable={false}
                        showDots={false}
                        arrows={true}
                        responsive={responsive.eventGallery}
                        // ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={false}
                        autoPlaySpeed={3000}
                        keyBoardControl={true}
                        // customTransition="all .5"
                        // transitionDuration={500}
                        containerClass="event-gallery-carousel"
                        // removeArrowOnDeviceType={["tablet", "mobile"]}
                        // deviceType={this.props.deviceType}
                        // dotListClass="custom-dot-list-style"
                        // itemClass="carousel-item-padding-40-px"
                    >
                        <div className="event-gallery-item">
                            <img
                                className="event-gallery-img"
                                src={eventInfo.event.extendedProps.imageGallery[0]}
                                alt=""
                            />
                        </div>
                        <div className="event-gallery-item">
                            <img
                                className="event-gallery-img"
                                src={eventInfo.event.extendedProps.imageGallery[0]}
                                alt=""
                            />
                        </div>
                        <div className="event-gallery-item">
                            <img
                                className="event-gallery-img"
                                src={eventInfo.event.extendedProps.imageGallery[0]}
                                alt=""
                            />
                        </div>
                        <div className="event-gallery-item">
                            <img
                                className="event-gallery-img"
                                src={eventInfo.event.extendedProps.imageGallery[0]}
                                alt=""
                            />
                        </div>
                    </Carousel>
                )}
        </div>
    );
};

EventImageGallery.propTypes = {
    eventInfo: PropTypes.object,
    responsive: PropTypes.object
};

export default EventImageGallery;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import PlusIcon from '../../../../atoms/icons/plusIcon';
import BasicProgress from '../../../../atoms/basic-progress';
import AttentionCircleIcon from '../../../../atoms/icons/attention-circle-icon';
import CheckCircleIcon from '../../../../atoms/icons/check-circle-icon';
import WarningCircleIcon from '../../../../atoms/icons/warning-circle-icon';

const ProductQualityItem = ({ title, value }) => {
    return (
        <Row className="align-items-lg-center py-3 border-bottom border-primary-shaded" noGutters>
            <Col xs={12} className="flex-lg-150-rest">
                <div className="fs-16 fw-500">
                    <span className="quality-status-icon mr-3">
                        {value < 33 ? (
                            <AttentionCircleIcon />
                        ) : value < 67 ? (
                            <WarningCircleIcon />
                        ) : (
                            <CheckCircleIcon />
                        )}
                    </span>
                    <span
                        className={
                            value < 33
                                ? 'text-danger'
                                : value < 67
                                ? 'text-warning'
                                : 'text-success'
                        }>
                        {title}
                    </span>
                </div>
            </Col>
            <Col xs={12} className="flex-lg-150">
                <BasicProgress className="progress-title-stand-out" percent={value} />
                <div
                    className="float-right position-relative"
                    style={{ marginTop: -36, paddingBottom: 12 }}>
                    <span role="button" className="fs-12 text-primary fw-400">
                        Increase <PlusIcon />
                    </span>
                </div>
            </Col>
        </Row>
    );
};

ProductQualityItem.propTypes = {
    title: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired
};

export default ProductQualityItem;

/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Button, Popover } from 'antd';
import PropTypes from 'prop-types';

import CloseIcon from '../../../../assets/images/close.svg';

const exportSynkProductContent = (
    <div className="d-flex flex-column align-items-center p-2">
        <span style={{ color: 'black' }} className="text-center fs-16 fw-400">
            Link copied to clipboard. Please go to the other channel and paste the link to complete
            your calendar connection.
        </span>
    </div>
);

const ExportSyncModal = ({ isOpen, toggleModal }) => {
    const [confirmationWindow, setConfirmationWindow] = useState(false);

    const toggleExportModal = () => {
        toggleModal();
        setConfirmationWindow();
    };

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleExportModal}>
            <ModalBody>
                <div style={{ float: 'right' }}>
                    <img src={CloseIcon} alt="" />
                </div>
                <div className="text-left mb-2 mt-2">
                    <div className="modal-body-title-lg">Export</div>
                </div>

                <p className="fs-16 fw-400">
                    Having accurate availability on your calendar is crucial to avoid overbookings
                    and manage your property. Copy the below iCal link and paste it to the other
                    site's 'Import calendar' area. After finishing exporting, you will have the
                    calendars connected to sync your availability across the two channels.
                </p>
                <p className="fs-16 fw-500">Your Booking.com calendar link</p>
                <div className="export-link">
                    <span>
                        https://vendor.africantravel.club/hotel/hoteladmin/ical.html?t=639ae
                    </span>
                </div>
                <div className="mt-4">
                    <Popover
                        content={exportSynkProductContent}
                        trigger="click"
                        overlayClassName="popover-width">
                        {!confirmationWindow ? (
                            <Button
                                id="pauseSaleTooltip"
                                type="primary"
                                className="btn-mw mx-2"
                                onClick={() => setConfirmationWindow(true)}>
                                Copy Link
                            </Button>
                        ) : (
                            <Button
                                id="pauseSaleTooltip"
                                type="primary"
                                className="btn-mw mx-2"
                                onClick={() => toggleExportModal()}>
                                DONE
                            </Button>
                        )}
                    </Popover>
                    <Button type="dark" className="btn-mw mx-2" onClick={() => toggleExportModal()}>
                        Cancel
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};
ExportSyncModal.propTypes = {
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func
};
export default ExportSyncModal;

import { callApi, callGuestApi } from '../../utils/apiCaller';
import { Notification } from '../../components/atoms/notification';
import { SET_AUTH_TOKEN, SET_PAYMENT_PROCESSING } from 'store/types';
export const USER_LOGIN = 'USER_LOGIN';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const CURRENT_USER = 'CURRENT_USER';
export const VENDOR_ON_BOARD = 'VENDOR_ON_BOARD';
export const SET_INITIAL_AUTH_NEW = 'SET_INITIAL_AUTH_NEW';
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE';
export const UPCOMING_SUBSCRIPTION = 'UPCOMING_SUBSCRIPTION';

export const setInitialAuth = () => (dispatch) =>
    dispatch({ type: SET_INITIAL_AUTH_NEW, payload: null });

export const setAuthToken_ = (payload) => (dispatch) => dispatch({ type: SET_AUTH_TOKEN, payload });

export const getCurretUser_ = (payload) => ({ type: CURRENT_USER, payload });
export const getCurretUser = () => {
    ///api/v1/me
    return (dispatch) => {
        return callApi('/me', 'GET').then((response) => {
            const status = response?.status;

            if (status == 200) {
                let res_data = response.data.data;

                if (!res_data?.photo_urlll) {
                    localStorage.setItem('path_enable', false);
                } else {
                    localStorage.setItem('path_enable', true);
                }

                dispatch(getCurretUser_(response.data.data));
                return dispatch(setAuthToken_(localStorage.getItem('token')));
            } else {
                if (response?.status === 401 && response?.data?.code === 'E_UNAUTHORIZED_ACCESS') {
                    dispatch(setAuthToken_(null));
                    localStorage.removeItem('token');
                }
                // if (
                //     response?.data &&
                //     Array.isArray(response?.data?.errors) &&
                //     response?.data?.errors.length
                // ) {
                //     return Notification('error', response.data.errors[0].message, status);
                // } else {
                //     return Notification('error', response?.data?.message, status);
                // }
            }
        });
    };
};

export const vendorOnboard_ = (payload) => ({ type: VENDOR_ON_BOARD, payload });
export const vendorOnboard = (data) => {
    return (dispatch) => {
        callApi('/vendor-onboard', 'POST', data).then((response) => {
            const status = response.status;

            if (status == 200) {
                dispatch(getCurretUser());
                return Notification(
                    'success',
                    response?.data?.message || response?.message,
                    status
                );
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

/*
 login User
*/
export const loginUser_ = (payload) => {
    return {
        type: USER_LOGIN,
        payload
    };
};

export const loginUser = (data) => {
    return (dispatch) => {
        callApi('/auth/login', 'POST', data).then((response) => {
            const status = response.status;

            if (status == 200) {
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token);
                    dispatch(loginUser_(response.data));
                    dispatch(getCurretUser());
                }

                return Notification('success', 'login successfully!', status);
            } else {
                dispatch(loginUser_(response.data));
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const forgotPassword_ = (data) => {
    return {
        type: FORGOT_PASSWORD,
        payload: data
    };
};

export const forgotPassword = (data) => {
    return (dispatch) => {
        callApi('/auth/forgot-password', 'POST', data).then((response) => {
            const status = response.status;

            if (status == 200) {
                dispatch(forgotPassword_(response.data));

                return Notification('success', response.data.message, status);
            } else {
                dispatch(forgotPassword_(response.data));
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const createPassword_ = (data) => {
    return {
        type: UPDATE_PASSWORD,
        payload: data
    };
};

export const createPassword = (data, history) => {
    return (dispatch) => {
        callApi('/auth/update-password', 'POST', data).then((response) => {
            const status = response.status;

            if (status == 200) {
                dispatch(createPassword_(response.data));
                history.push('/signin');

                return Notification('success', 'Password is created successfully.', status);
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response.data.message, status);
                }
            }
        });
    };
};

export const accountDelete_ = (data) => {
    return {
        type: ACCOUNT_DELETE,
        payload: data
    };
};

export const accountDelete = (data) => {
    return (dispatch) => {
        callGuestApi('/vendor-applications/delete-rejected', 'POST', data).then((response) => {
            const status = response.status;

            if (status === 200) {
                localStorage.clear();
                dispatch(setInitialAuth());
                dispatch(accountDelete_({ success: true, data: response.data }));

                return Notification('success', 'Account is deleted Successfully', status);
            } else if (status === 500) {
                dispatch(accountDelete_({ success: false, data: 'Internal Server Errorr' }));
                return Notification('error', 'Internal Server Error', status);
            } else {
                if (response?.data?.error && !Array.isArray(response?.data?.error)) {
                    dispatch(accountDelete_({ success: false, data: response.data.error }));
                    return Notification('error', response.data.error, status);
                }
            }
        });
    };
};

export const setUpcomingSubscription = (payload) => ({ type: UPCOMING_SUBSCRIPTION, payload });

export const checkSubscriptionExpiry = () => async (dispatch) => {
    const response = await callApi(`/subscription/check-expiry`, 'GET');
    const status = response?.status;

    if (status == 200) {
        if (response.data.data?.showDisableCalendars) {
            dispatch(setUpcomingSubscription(response.data.data?.subscription_data));
        }
        return true;
    } else {
        if (
            response?.data &&
            Array.isArray(response?.data?.errors) &&
            response?.data?.errors.length
        ) {
            return Notification('error', response.data.errors[0].message, status);
        } else {
            return Notification('error', response.data.message, status);
        }
    }
};

export const setPaymentProcessing = (bool) => (dispatch) =>
    dispatch({ type: SET_PAYMENT_PROCESSING, payload: bool });

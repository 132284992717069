import React from 'react';
import PropTypes from 'prop-types';

export default function AddButton(props) {
    return (
        <button
            className={props.isSelected ? props.pressedbutton : props.newbutton}
            onClick={props.onClick}>
            <span>
                <img style={{ paddingRight: 6 }} src={props.image} alt="" />
            </span>
            {props.name}
        </button>
    );
}
AddButton.propTypes = {
    isSelected: PropTypes.bool,
    pressedbutton: PropTypes.any,
    newbutton: PropTypes.any,
    onClick: PropTypes.func,
    image: PropTypes.string,
    name: PropTypes.string
};

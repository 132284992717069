import React from 'react';
import PropTypes from 'prop-types';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { GpsIcon, TagIcon } from 'components/atoms';

import EventPricings from './event-pricings';
import EventDuration from './event-duration';
import EventPhotosAndVideos from './event-photos-and-videos';
import EventLanguagesOffered from './event-language-offered';
import EventInclusions from './event-inclusions';
import EventExclusions from './event-exclusions';
import EventGuideMeetLocation from './event-guide';
import EventMealsAndDrinks from './event-meals-and-drinks';
import EventOccassions from './event-occassions';
import EventCalendarSeason from './event-calendar-season';
import EventAdventures from './event-adventures';
import EventSights from './event-sights';
import EventFacilities from './event-facilities';
import EventTravellerInfo from './event-traveller-info';
import EventBreakfastDetails from './event-breakfast-details';
import EventBookingDetails from './event-booking-details';
import EventRooms from './event-rooms';
import EventRating from './event-rating';
import EventDays from './event-days';
import EventBookingTimePeriods from './event-booking-time-periods';
import './event-detail.scss';

import { connect } from 'react-redux';
import { selectedExistingProduct_, setUpdateProductFields_ } from 'store/actions';
import EventCovidDetails from './event-covid-details';
import EventHouseRules from './event-house-rules';
import { useHistory, useRouteMatch } from 'react-router-dom';

const EventDetailContent = (props) => {
    const route = useRouteMatch();
    const history = useHistory();
    const { onClose, selectedExistingProduct_, setUpdateProductFields_, currentProduct } = props;
    // const [visibleProductSummaryDrawer, setVisibleProductSummaryDrawer] = useState(false);
    // const [viewGalleryItemModal, setViewGalleryItemModal] = useState(false);
    const onEdit = (field) => {
        if (route.path !== '/calendar/:id' && currentProduct?.calendars) {
            history.push(`/calendar/${currentProduct?.calendars[0].id}`);
        }
        onClose();
        setUpdateProductFields_({ [field]: true });
        selectedExistingProduct_(true);
    };

    return (
        <div className="ff-special mt-2">
            {/* Photos & Videos Panel */}
            <EventPhotosAndVideos
                product={currentProduct}
                onEdit={() => onEdit('editPhotosAndVideos')}
            />

            {/* Main Destination Panel */}
            <DetailedPanel
                panelIcon={<GpsIcon />}
                panelTitle="Main Destination"
                isEditable={true}
                onEdit={() => onEdit('editMainDestination')}>
                <div className="fs-18 lh-1-7">{currentProduct?.address}</div>
            </DetailedPanel>

            {/* Duration Panel */}
            {currentProduct?.day_type === 'all_day' ? (
                <EventDuration product={currentProduct} onEdit={() => onEdit('editDuration')} />
            ) : (
                <EventBookingTimePeriods
                    product={currentProduct}
                    onEdit={() => onEdit('editBookingTimePeriods')}
                />
            )}

            <EventDays product={currentProduct} onEdit={() => onEdit('editDays')} />

            {/* Admission Price included Panel */}
            <DetailedPanel
                panelIcon={<TagIcon />}
                panelTitle="Admission Price included"
                isEditable={true}
                onEdit={() => onEdit('editAdmissionPrice')}>
                {currentProduct?.is_admission_included}
            </DetailedPanel>

            {/* amount you will get (pp) Panel */}
            <EventPricings product={currentProduct} onEdit={() => onEdit('editPricing')} />

            {/* Display meals and drinks for a general product and show breakfast details for an accomodation */}
            {currentProduct?.product_category_id === 1 ? (
                <>
                    <EventHouseRules
                        product={currentProduct}
                        onEdit={() => onEdit('editHouseRules')}
                    />
                    <EventRooms product={currentProduct} onEdit={() => onEdit('editRooms')} />
                    {/*   Event Breakfast */}
                    <EventBreakfastDetails
                        product={currentProduct}
                        onEdit={() => onEdit('editBreakfast')}
                    />
                    <EventFacilities
                        product={currentProduct}
                        onEdit={() => onEdit('editFacilities')}
                    />
                    {/* Extra info Panel */}
                    <EventTravellerInfo
                        product={currentProduct}
                        onEdit={() => onEdit('editTravellerInfo')}
                    />
                </>
            ) : (
                //  Meals and Drinks Panel
                <EventMealsAndDrinks
                    product={currentProduct}
                    onEdit={() => onEdit('editMealsAndDrinks')}
                />
            )}

            {/* Language Offered Panel */}
            <EventLanguagesOffered
                product={currentProduct}
                onEdit={() => onEdit('editLanguagesOffered')}
            />
            {/* Inclusions Panel */}
            <EventInclusions product={currentProduct} onEdit={() => onEdit('editInclusions')} />

            {/* Exclusions Panel */}
            <EventExclusions product={currentProduct} onEdit={() => onEdit('editExclusions')} />

            {/* Guide will meet you at Panel */}
            <EventGuideMeetLocation
                product={currentProduct}
                onEdit={() => onEdit('editGuideMeetLocation')}
            />

            <EventCalendarSeason
                product={currentProduct}
                onEdit={() => onEdit('editCalendarSeason')}
            />

            {/* Adventures Panel */}
            <EventAdventures product={currentProduct} onEdit={() => onEdit('editAdventures')} />

            {/* Top Sights Panel */}
            <EventSights product={currentProduct} onEdit={() => onEdit('editSights')} />

            {/* Top Sights Panel */}
            <EventOccassions product={currentProduct} onEdit={() => onEdit('editOccassions')} />

            {/* Booking & Tickets Panel */}
            <EventBookingDetails
                product={currentProduct}
                onEdit={() => onEdit('editBookingDetails')}
            />

            <EventCovidDetails product={currentProduct} onEdit={() => onEdit('editCovidDetails')} />

            {/* Rating Panel */}
            <EventRating product={currentProduct} onEdit={() => onEdit('editRating')} />
        </div>
    );
};

EventDetailContent.propTypes = {
    currentProduct: PropTypes.any,
    onClose: PropTypes.func,
    selectedExistingProduct_: PropTypes.func,
    setUpdateProductFields_: PropTypes.func
};

const mapStateToProps = (state) => ({
    currentProduct: state.product?.currentProduct
});

export default connect(mapStateToProps, { selectedExistingProduct_, setUpdateProductFields_ })(
    EventDetailContent
);

import React, { useState, useEffect } from 'react';
import { Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Row } from 'reactstrap';
import DownArrow from '../../../assets/images/down-arrow.svg';
import './index.scss';
import Proptypes from 'prop-types';

const month = [
    { name: 'January' },
    { name: 'February' },
    { name: 'March' },
    { name: 'April' },
    { name: 'May' },
    { name: 'June' },
    { name: 'July' },
    { name: 'August' },
    { name: 'September' },
    { name: 'October' },
    { name: 'November' },
    { name: 'December' }
];

export default function RangeMonthPicker(props) {
    const [isFrom, setIsFrom] = useState(false);
    const [isTo, setIsTo] = useState(false);
    const [from, setFrom] = useState(props.defaultFrom || '');
    const [to, setTo] = useState(props.defaultTo || '');

    const toggleFrom = () => setIsFrom(!isFrom);
    const toggleTo = () => {
        if (from !== '') setIsTo(!isTo);
    };
    useEffect(() => {
        if (props.defaultFrom === null) {
            setFrom('');
            setTo('');
        }
    }, [props.defaultFrom]);

    //select from month
    const onChangeFrom = (month) => {
        setFrom(month);
        props.fromMonth(month);
    };
    //select to month
    const onChangeTo = (tomonth, index) => {
        const indexFrom = month.findIndex((res) => res.name === from);
        setTo(tomonth);
        props.toMonth(tomonth, index, indexFrom);
    };

    return (
        <Row className="range-month-picker flex-nowrap">
            <Dropdown isOpen={isFrom} toggle={toggleFrom}>
                <DropdownToggle
                    className="from-btn"
                    style={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0
                    }}>
                    {from === '' ? 'From' : from.substr(-20, 3).toLocaleUpperCase()}
                    <img src={DownArrow} alt="" />
                </DropdownToggle>
                <DropdownMenu>
                    {month.map((res, i) => (
                        <DropdownItem key={i} onClick={() => onChangeFrom(res.name, i)}>
                            {res.name.substr(-20, 3).toLocaleUpperCase()}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
            <Dropdown isOpen={isTo} toggle={toggleTo}>
                <DropdownToggle
                    className="to-btn"
                    style={{
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0
                    }}>
                    {to === '' ? 'To' : to.substr(-20, 3).toLocaleUpperCase()}
                    <img src={DownArrow} alt="" />
                </DropdownToggle>
                <DropdownMenu>
                    {month.map((res, i) => (
                        <DropdownItem key={i} onClick={() => onChangeTo(res.name, i)}>
                            {res.name.substr(-20, 3).toLocaleUpperCase()}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </Row>
    );
}
RangeMonthPicker.propTypes = {
    defaultFrom: Proptypes.string,
    defaultTo: Proptypes.string,
    fromMonth: Proptypes.func,
    toMonth: Proptypes.func
};

/* eslint-disable react/jsx-key */
import { Modal, Button, Form, Radio } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';

const Frequency = (props) => {
    const { handleOk, isVisible, handleCancel, handleChange, value } = props;
    // let [state, setState] = useState(null);

    // useEffect(() => {
    //   setRemaining(maxLength)
    //   if (textAreaRef.current) {
    //     const maxLength = textAreaRef.current.props.maxLength
    //     const usedLength = textAreaRef.current.state.value.length
    //     setRemaining(maxLength - usedLength)
    //   }
    // }, [desc])

    const radioStyle = {
        display: 'block',
        height: '30px',
        lineHeight: '30px'
    };

    return (
        <Modal
            width={298}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            maskClosable={false}
            closable={true}
            wrapClassName="frequency_wrapper"
            footer={[
                <Button
                    onClick={handleOk}
                    style={{ width: 135, height: 42 }}
                    type="primary"
                    className="rounded-btn"
                    disabled={!value}>
                    Ok
                </Button>
            ]}>
            <h4>Select Frequency</h4>
            <Form layout="vertical">
                <Form.Item required>
                    <Radio.Group className="frequency" onChange={handleChange} value={value}>
                        <Radio value={'common'}>
                            <span className={`frequency__common`}>Common</span>
                        </Radio>
                        <Radio style={radioStyle} value={'occasional'}>
                            <span className={`frequency__occasional`}>Occassional</span>
                        </Radio>
                        <Radio style={radioStyle} value={'rare'}>
                            <span className={`frequency__rare`}>Rare</span>
                        </Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

Frequency.propTypes = {
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired
};

export default Frequency;

import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';
import React from 'react';
import Proptypes from 'prop-types';

const LoaderComponent = ({ typeContainer, typeTransparent }) => {
    return (
        <div
            className={`outer-loader ${typeContainer ? '__container' : ''}`}
            style={{ opacity: typeTransparent ? 0.5 : 1 }}>
            <Space size="middle">
                {/* Loader type 1 */}
                {/* <Spin size="large" /> */}
                {/* Loader type 2 */}
                <Spin size="large" indicator={<LoadingOutlined spin />} />
            </Space>
        </div>
    );
};

LoaderComponent.propTypes = {
    typeContainer: Proptypes.bool,
    typeTransparent: Proptypes.bool
};

export default LoaderComponent;

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import CollapseMoreLess from '../../collapse-more-less';
import { MultiDirectionIcon } from 'components/atoms';

const EventAdventures = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<MultiDirectionIcon />}
            panelTitle="Adventures"
            isEditable={true}
            onEdit={onEdit}>
            {product?.adventures?.length > 0 ? (
                product?.adventures.slice(0, 1).map((item, index) => (
                    <Row key={index} className="border-bottom border-primary-shaded py-2" noGutters>
                        <Col xs={6}>
                            <div className="d-flex flex-nowrap">
                                <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                    {item.category}
                                </span>
                                <span className="fs-18 lh-1-7 mr-4">:</span>
                            </div>
                        </Col>
                        <Col xs={6}>
                            <span className="fs-18 lh-1-7">
                                {item.sub_category.map(
                                    (title, index) =>
                                        `${title}${
                                            index < item.sub_category.length - 2
                                                ? ','
                                                : item.sub_category.length > 1 &&
                                                  index < item.sub_category.length - 1
                                                ? ' and '
                                                : ''
                                        }`
                                )}
                            </span>
                        </Col>
                    </Row>
                ))
            ) : (
                <></>
            )}

            <CollapseMoreLess>
                {product?.adventures?.length > 1 ? (
                    product?.adventures.slice(1, product?.adventures.length).map((item, index) => (
                        <Row
                            key={index}
                            className="border-bottom border-primary-shaded py-2"
                            noGutters>
                            <Col xs={6}>
                                <div className="d-flex flex-nowrap">
                                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                        {item.category}
                                    </span>
                                    <span className="fs-18 lh-1-7 mr-4">:</span>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <span className="fs-18 lh-1-7">
                                    {item.sub_category.map(
                                        (title, index) =>
                                            `${title}${
                                                index < item.sub_category.length - 2
                                                    ? ','
                                                    : item.sub_category.length > 1 &&
                                                      index < item.sub_category.length - 1
                                                    ? ' and '
                                                    : ''
                                            }`
                                    )}
                                </span>
                            </Col>
                        </Row>
                    ))
                ) : (
                    <></>
                )}
            </CollapseMoreLess>
        </DetailedPanel>
    );
};

EventAdventures.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventAdventures;

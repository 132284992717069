import React, { Component } from 'react';
import AccessCustomerCalendarModal from '../../accessCustomerCalendarModal/AccessCustomerCalendarModal.js';
import './index.scss';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { toggleAccessCalendarModal } from '../../../../store/actions/accessCalendar';
import PropTypes from 'prop-types';

class index extends Component {
    state = {
        // showModal: false,
        // showDetailsModal: false,
        activeTab: '1'
    };

    render() {
        return (
            <div className="accessCustomerCalendarModal">
                <AccessCustomerCalendarModal
                    title="Access Customer Calendar"
                    // showModal={this.state.showDetailsModal}
                    activeTab={this.state.activeTab}
                />
                <Button
                    className="black-button"
                    onClick={() => this.props.toggleAccessCalendarModal(true)}>
                    Access customer Calendar
                </Button>
            </div>
        );
    }
}

index.propTypes = {
    toggleAccessCalendarModal: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleAccessCalendarModal: (value) => dispatch(toggleAccessCalendarModal(value))
    };
};

export default connect(null, mapDispatchToProps)(index);

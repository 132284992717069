import React, { useState, useEffect } from 'react';
import VendorDashboard from '../vendor-dashboard';
import Button from '../button';
import InputBox from '../../../atoms/inputbox';
import './index.css';
import CurvedButton from '../curvedbutton';
import { NavLink } from 'react-router-dom';
// import FixedHelpButton from '../../../molecules/fixedhelpbutton';
import Feedback from '../../../molecules/user-feedback';

export default function Accommodation() {
    //Function to check all days
    const [checked, setChecked] = useState(false);
    const [sDays, setSdays] = useState([]);

    //Mock array for number of days
    const [days, setDays] = useState([]);

    //Move to the top of the page
    useEffect(() => {
        window.scrollTo(0, 0);
        setDays(['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7']);
    }, []);
    useEffect(() => {
        if (checked) {
            setSdays([...days]);
        } else {
            setSdays([]);
        }
    }, [checked, days]);
    const selectAll = () => {
        setChecked(!checked);
    };

    //ChangeColor function
    const changeColor = (day) => {
        if (sDays.some((d) => d === day)) {
            setSdays([...sDays.filter((d) => d !== day)]);
        } else {
            setSdays([...sDays, day]);
        }
    };

    return (
        <VendorDashboard>
            <div className="row background-sz" style={{ paddingTop: '40px' }}>
                <div className="col-md-12">
                    <h3 style={{ fontSize: '20px', fontWeight: 900 }}>
                        Select the customers calendar days this accommodation apply
                    </h3>
                    <p style={{ fontSize: '15px' }}>
                        For example, you can have different accomodations for each the activity
                        days.{' '}
                    </p>
                    <InputBox other="Select all" onChange={selectAll} />
                    <div style={{ paddingTop: 15 }}>
                        {days.map((day, i) => (
                            <CurvedButton
                                key={i}
                                pressedbutton="pressedbutton-acc"
                                newbutton="newbutton-acc"
                                name={day}
                                isSelected={sDays.includes(day)}
                                onClick={() => changeColor(day)}
                            />
                        ))}
                    </div>

                    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                        <NavLink to="/accommodation-dashboard">
                            <Button float={'left'} text={'Add Accommodation'} />
                        </NavLink>
                        {/* <FixedHelpButton fixed="fixed" top="42%" right="4%" /> */}
                        <Feedback />
                    </div>
                </div>
            </div>
        </VendorDashboard>
    );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import './index.css';
import PropTypes from 'prop-types';
export default function TextDropDown(props) {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div
            onClick={props.click}
            style={{ flex: 'auto' }}
            className={props.isSidebar ? 'd-flex p-r' : ''}>
            <div
                className={`cursor-pointer ${props.className}  message d-flex`}
                style={{ width: props.widthy }}
                onClick={toggle}>
                <div className={props.formCheck}>
                    {props.isCheckBox ? (
                        props.check && (
                            <input
                                type="checkbox"
                                className="form-check-input check-box-atc"
                                id="exampleCheck1"
                            />
                        )
                    ) : (
                        <img
                            style={{ paddingLeft: '13px', width: props.width }}
                            src={props.image}
                            alt=""
                        />
                    )}
                    <label className="form-check-label select">{props.name}</label>
                </div>
                {isOpen ? (
                    <img alt="" className={props.icon} src={props.caret} />
                ) : (
                    <img alt="" className={props.class} src={props.down} />
                )}
            </div>
            <div className={props.isSidebar ? 'p-a' : ''}>{isOpen && props.children}</div>
        </div>
    );
}

TextDropDown.propTypes = {
    click: PropTypes.func,
    isSidebar: PropTypes.bool,
    className: PropTypes.string,
    widthy: PropTypes.string,
    formCheck: PropTypes.string,
    isCheckBox: PropTypes.bool,
    check: PropTypes.bool,
    width: PropTypes.string,
    image: PropTypes.any,
    name: PropTypes.string,
    icon: PropTypes.string,
    caret: PropTypes.string,
    class: PropTypes.string,
    down: PropTypes.string,
    children: PropTypes.any
};

// import React, { useState } from 'react'
// import './index.css'
// import Caret from '../../../assets/images/caret.svg'
// import Down from '../../../assets/images/down-caret.svg'

// export default function TextDropDown(props) {
//     const [isOpen, setIsOpen] = useState(false);

//     const toggle = () => {
//         setIsOpen(!isOpen)
//     };

//     return (
//         <div style={{flex: "auto"}} className={props.isSidebar ? "d-flex" : ""}>
//             <div style={{marginLeft:"0"}} className={`cursor-pointer ${props.className} message row`} onClick={toggle} >
//                 <div style={{textAlign:"left"}} className="col-md-2">
//                 <img className="image-left" src={props.image}/>
//                 </div>
//                 <div className="col-md-5">
//                 <p className="select">{props.name}</p>
//                 </div>
//                 <div  style={{textAlign:"right"}} className="col-md-5" >
//                 {isOpen ? <img className="image-icon" src={Caret} /> : <img className="image-icon-down" src={Down} />}
//                 </div>

//             </div>

//                 {isOpen && props.children}

//         </div>

//     )
// }

import React, { useEffect, useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import PlusIcon from 'components/atoms/icons/plusIcon';
import { Link } from 'react-router-dom';
import { getAllVendorCalendars } from 'store/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Waypoint } from 'react-waypoint';
import { StarRatingSmall } from 'components/atoms';
import { ComponentPermissionContext } from 'context/rolesandpermission';
// import { assignComponentPermission } from 'utils/rolesandpermission/accessright';

const CustomerCalendarsCard = ({ vendorCalendars, getAllVendorCalendars }) => {
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    useEffect(() => {
        getAllVendorCalendars(vendorCalendars?.meta?.next_page_url);
    }, []);

    return (
        <Card
            className={`border-secondary card-sm mb-10px ${assignComponentPermission('calendar')}`}>
            <CardBody className={`${assignComponentPermission('calendar-customer-calendar')}`}>
                <h6 className="fs-16 fw-600 mb-0">Customers Calendars</h6>
                <div className="overflow-y-auto scrollbar-fixed mt-2" style={{ maxHeight: 120 }}>
                    {vendorCalendars?.data?.map((item, index) => (
                        <div key={`${item?.id}-${index}`} className="fs-14 text-decoration-none">
                            <div className="customers-rating-block__card-item cursor-pointer">
                                <div className="text-truncate fs-14 text-gray w-75">
                                    {item?.title}
                                </div>
                                <div className="d-flex justify-content-between">
                                    <div className="mr-2">
                                        <div className="text-nowrap">
                                            <StarRatingSmall stars={item?.rating?.stars} />
                                            <span className="fs-12 text-gray ml-2">
                                                {item?.rating?.stars}.0
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className={`${assignComponentPermission(
                                            'calendar-rating'
                                        )}`}>
                                        <Link
                                            to={`/calendar/${item?.id}?increaseRating=true`}
                                            className=" text-decoration-none">
                                            <span
                                                role="button"
                                                className={`text-nowrap fs-12 ff-special text-primary fw-400 ${assignComponentPermission(
                                                    'calendar-rating-dashboard'
                                                )}`}>
                                                Increase Rating <PlusIcon />
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    <Waypoint
                        onEnter={() => {
                            console.log('load more ', vendorCalendars?.meta);
                            if (vendorCalendars?.meta?.next_page_url)
                                getAllVendorCalendars(vendorCalendars?.meta?.next_page_url);
                        }}
                    />
                </div>
                <div className="text-right mt-2">
                    <Link to={'/customers-calendars'} className="fs-14 text-decoration-underline">
                        View All
                    </Link>
                </div>
            </CardBody>
        </Card>
    );
};

CustomerCalendarsCard.propTypes = {
    vendorCalendars: PropTypes.any,
    getAllVendorCalendars: PropTypes.func
};

const mapStateToProps = (state) => ({
    vendorCalendars: state?.calendar?.vendor_calendars
});

export default connect(mapStateToProps, { getAllVendorCalendars })(CustomerCalendarsCard);

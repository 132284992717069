import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Popover } from 'antd';
import ProductItem from './product-item';

import { getMoment } from 'utils';
import { ClashIcon } from 'components/atoms';
import EventSchedule from './event-schedule';

const MoreProducts = ({ eventInfo, setCurrentProduct }) => {
    const popoverRef = useRef();

    const moreProducts = eventInfo?.event?.extendedProps?.moreProducts;
    if (!moreProducts || !moreProducts.length > 0) {
        return <></>;
    }
    let sorted = [...moreProducts].reverse();

    sorted.sort((a, b) => {
        let aTime = getMoment(a).start;
        let bTime = getMoment(b).start;

        if (aTime.isBefore(bTime)) {
            return -1;
        } else {
            return 1;
        }
    });

    return (
        <div className="text-right">
            <Popover
                ref={popoverRef}
                overlayClassName="popover-container popover-large popover-centered"
                placement={window.innerWidth >= 768 ? 'left' : 'bottom'}
                content={
                    <div>
                        <ProductItem
                            product={eventInfo?.event?.extendedProps}
                            active
                            setCurrentProduct={setCurrentProduct}
                            popoverRef={popoverRef}
                        />
                        {eventInfo?.event?.extendedProps &&
                            getMoment(eventInfo?.event?.extendedProps).end.isBefore(
                                getMoment(sorted[0]).start
                            ) && <EventSchedule />}
                        {sorted.map((product, index) => (
                            <div key={index}>
                                {sorted[index - 1] &&
                                    getMoment(sorted[index - 1]).end.isBefore(
                                        getMoment(product).start
                                    ) && (
                                        <EventSchedule
                                            duration={getMoment(product).start.from(
                                                getMoment(sorted[index - 1]).end
                                            )}
                                        />
                                    )}
                                <ProductItem
                                    popoverRef={popoverRef}
                                    product={product}
                                    setCurrentProduct={setCurrentProduct}
                                    selectable
                                />
                            </div>
                        ))}
                    </div>
                }>
                <span
                    role="button"
                    tabIndex="0"
                    className="see-more-products-btn"
                    onKeyUp={(e) => e.stopPropagation()}
                    onClick={(e) => e.stopPropagation()}>
                    <span className="mr-2">See more Products</span>
                    <ClashIcon size={12} />
                </span>
            </Popover>
        </div>
    );
};

MoreProducts.propTypes = {
    eventInfo: PropTypes.any,
    setCurrentProduct: PropTypes.any
};

export default MoreProducts;

import React, { useState, useEffect } from 'react';
import { TableCell } from '@material-ui/core';
import { Row } from 'reactstrap';
import Prompter from '../../../../../atoms/prompter';
import Proptypes from 'prop-types';

const styles = {
    cell: {
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: 8
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px',
        width: '68px',
        alignItems: 'center',
        display: 'flex'
    },
    input: {
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '48px',
        width: '89px',
        fontSize: '16px',
        textAlign: 'center',
        marginRight: '10px'
    },
    inputUsd: {
        border: '0.5px solid #C4C4C4',
        borderLeft: 0
    },
    prompter: {
        display: 'flex',
        alignItems: 'center'
    }
};

export default function GroupRow(props) {
    const [activePrice, setActivePrice] = useState(props.data.price || 0);
    const [secondActive, setSecondActive] = useState(props.data.ss_price || 0);
    const [addSingle, setAddSingle] = useState(!!props.data.ss_price);

    useEffect(() => {
        setTimeout(() => {
            onLoad(props.data);
        }, 1);
    }, [props.data]);
    //load data price
    const onLoad = (data) => {
        if (data?.price && data.price !== activePrice) setActivePrice(data.price || 0);
        if (data?.ss_price && data.ss_price !== secondActive) {
            setSecondActive(data.ss_price || 0);
            if (!addSingle) setAddSingle(true);
        }
    };
    const addSingleHandler = () => {
        setAddSingle(!addSingle);
        props.addSingleHandler();
    };
    //save change
    const onChange = () => {
        const price = {
            price: activePrice,
            ss_price: addSingle ? secondActive : null
        };
        props.onSave(price);
    };
    useEffect(() => {
        onChange();
    }, [activePrice, secondActive]);

    return (
        <>
            <Row style={{ border: 'none' }}>
                <div style={{ ...styles.cell, width: '104px' }}>{props.groupName}</div>
                <TableCell style={styles.cell} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={styles.currency}>USD</div>
                        <input
                            style={{
                                ...styles.input,
                                ...styles.inputUsd,
                                borderRadius: '0 5px 5px 0'
                            }}
                            type="number"
                            value={activePrice}
                            min={1}
                            onChange={(e) => setActivePrice(e.target.value)}
                        />
                        <div>PP</div>
                    </div>
                </TableCell>
                {!addSingle ? (
                    <div style={styles.prompter}>
                        <Prompter
                            fontSize={14}
                            textColor="#A2792C"
                            text={`Add Single Supplement`}
                            onClick={addSingleHandler}
                        />
                    </div>
                ) : (
                    <TableCell style={styles.cell} align="left">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={styles.currency}>USD</div>
                            <input
                                style={{
                                    ...styles.input,
                                    ...styles.inputUsd,
                                    borderRadius: '0 5px 5px 0'
                                }}
                                type="number"
                                value={secondActive}
                                min={1}
                                onChange={(e) => setSecondActive(e.target.value)}
                            />
                            <div>PP</div>
                        </div>
                    </TableCell>
                )}
            </Row>
        </>
    );
}
GroupRow.propTypes = {
    groupName: Proptypes.string,
    addSingle: Proptypes.bool,
    addSingleHandler: Proptypes.func,
    onSave: Proptypes.func,
    data: Proptypes.object
};

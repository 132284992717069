import React, { useState, useEffect } from 'react';
import './index.scss';

import { Checkbox } from 'antd';
import { Row } from 'reactstrap';
import SelectButton from '../../atoms/select-button';
import Spacer from '../../atoms/spacer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, withRouter } from 'react-router-dom';
import { ConfirmationModal } from 'components/molecules';
import { updateProductsCalendarDay } from 'store/actions';
import { getValidPricingDays } from 'utils';

const DaysSelector = (props) => {
    const params = useParams();
    const { product, updateProductsCalendarDay, data } = props;

    const validDays = getValidPricingDays(product, props?.pricing);

    const [currentDay, setCurrentDay] = useState(null);
    const [days, setDays] = useState(validDays.map((name) => ({ name, isActive: false })));
    const isSelectAll = () =>
        days.findIndex(
            (day) =>
                day.isActive === false &&
                product?.calendar_days?.days &&
                product?.calendar_days?.days.indexOf(day.name) !== -1
        ) === -1;

    useEffect(() => {
        if (data) {
            setDays(validDays.map((item) => ({ name: item, isActive: data.indexOf(item) !== -1 })));
        }
    }, [data]);

    const onChange = (index) => {
        const tmp = days;
        tmp[index].isActive = !tmp[index].isActive;
        setDays([...tmp]);
        onSave(tmp);
    };
    const onSave = (data) => {
        const tmpSaveData = [];
        data.map((res) => {
            if (res.isActive) tmpSaveData.push(res.name);
        });
        props.onSave([...tmpSaveData]);
    };
    const onSelectAll = () => {
        if (isSelectAll()) {
            const falseTempDays = validDays.map((name) => ({
                name,
                isActive: false
            }));
            setDays([...falseTempDays]);
            props.onSave([]);
        } else {
            let tmpDays = validDays.map((name) => ({
                name,
                isActive: product?.calendar_days.days.indexOf(name) !== -1
            }));
            setDays([...tmpDays]);
            props.onSave(
                validDays.filter((name) => product?.calendar_days.days.indexOf(name) !== -1)
            );
        }
    };
    return (
        <>
            <Checkbox checked={isSelectAll()} onChange={onSelectAll}>
                Select all
            </Checkbox>

            <Spacer top={13} />
            <Row style={{ margin: 0 }}>
                {days.map((res, i) => (
                    <SelectButton
                        mt={props.mt}
                        key={i}
                        name={res.name}
                        isActive={res.isActive}
                        onClick={() => onChange(i)}
                        disabled={
                            product?.calendar_days
                                ? !product?.calendar_days?.days ||
                                  product?.calendar_days?.days.indexOf(res.name) === -1
                                : false
                        }
                        onDisabledClick={() => setCurrentDay(res)}
                    />
                ))}
            </Row>
            <ConfirmationModal
                description={`Your product is not available on ${currentDay?.name}. Do you want to make it available on ${currentDay?.name}?`}
                isModalOpen={currentDay}
                setModalOpenState={() => setCurrentDay(null)}
                title={`Add pricing for ${currentDay?.name}?`}
                handleConfirmation={async () => {
                    if (product?.calendar_days?.days) {
                        await updateProductsCalendarDay(
                            {
                                calendar_days: {
                                    days: [...product?.calendar_days?.days, currentDay.name]
                                },
                                suggested_day: product?.suggestedDay,
                                calendar_id: params?.id
                            },
                            product?.id
                        );
                    }
                    setCurrentDay(null);
                }}
            />
        </>
    );
};
DaysSelector.propTypes = {
    onSave: PropTypes.func,
    data: PropTypes.array,
    product: PropTypes.any,
    pricing: PropTypes.any,
    updateProductsCalendarDay: PropTypes.any,
    mt: PropTypes.number
};

const mapStateToProps = (state) => ({
    product: state.product?.currentProduct
});

export default withRouter(connect(mapStateToProps, { updateProductsCalendarDay })(DaysSelector));

/* eslint-disable react/display-name */
import { Button, Table, Space } from 'antd';
import Feedback from '../../../molecules/user-feedback';

import React from 'react';
import './index.scss';

export default function ManageUsers() {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <a>{text}</a>
        },
        {
            title: 'Age',
            dataIndex: 'age',
            key: 'age'
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address'
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a>Invite {record.name}</a>
                    <a>Delete</a>
                </Space>
            )
        }
    ];

    const data = [
        {
            key: '1',
            name: 'John Brown',
            age: 32,
            address: 'New York No. 1 Lake Park',
            tags: ['nice', 'developer']
        },
        {
            key: '2',
            name: 'Jim Green',
            age: 42,
            address: 'London No. 1 Lake Park',
            tags: ['loser']
        },
        {
            key: '3',
            name: 'Joe Black',
            age: 32,
            address: 'Sidney No. 1 Lake Park',
            tags: ['cool', 'teacher']
        }
    ];
    return (
        <div className="manage-users">
            <div className="white-bg">
                <p className="heading">Manage Users</p>
                <div className="alert">
                    Manage users by controlling who has access to what products and tabs within the
                    Viator Management Center.
                    <Button type="primary" className="rounded-btn">
                        Add User
                    </Button>
                </div>

                <Table columns={columns} dataSource={data} />

                {/* <Button type="primary" className="rounded-btn" style={{ alignSelf: 'flex-end' }}>
                    <QuestionCircleOutlined />
                    Help
                </Button> */}
                <Feedback />
            </div>
        </div>
    );
}

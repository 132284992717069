import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Notification, StarRating } from 'components/atoms';
import { Button, Drawer, Tooltip, Pagination } from 'antd';
import { getArchivedCalendars, updateCalendarStatus } from 'store/actions';

const ArchivedCalendars = (props) => {
    const { onClose, visible, archived_calendar_data, getArchivedCalendars, updateCalendarStatus } =
        props;

    const [page, setPage] = useState(1);

    // useEffect(() => {
    //     fetchCalendars({ page, perPage: 10 });
    // }, []);

    useEffect(() => {
        fetchCalendars({ page, perPage: 10 });
    }, [page, visible === true]);

    function fetchCalendars(query) {
        getArchivedCalendars(query);
    }

    function updateCalendar(id) {
        updateCalendarStatus({
            id,
            status: 'draft'
        })
            .then(() => {
                fetchCalendars({ page, perPage: 10 });
                Notification('success', `Calendar unarchive successfully.`);
            })
            .catch(() => {
                Notification('error', `Something went wrong! Please try again later`);
            });
    }

    return (
        <Drawer
            title="Archived Calendars"
            width={609}
            placement="right"
            className="basic-drawer"
            closeIcon={
                <span role="img" aria-label="close" className="anticon anticon-close">
                    <svg
                        width="24"
                        height="27"
                        viewBox="0 0 24 27"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1988 13.4683L23.5439 3.5314C24.1521 2.88505 24.1521 1.83998 23.5439 1.19363C22.936 0.547274 21.9532 0.547274 21.3453 1.19363L11.9999 11.1305L2.65474 1.19363C2.04658 0.547274 1.064 0.547274 0.456124 1.19363C-0.152041 1.83998 -0.152041 2.88505 0.456124 3.5314L9.80125 13.4683L0.456124 23.4052C-0.152041 24.0515 -0.152041 25.0966 0.456124 25.7429C0.759068 26.0654 1.15739 26.2273 1.55543 26.2273C1.95347 26.2273 2.35152 26.0654 2.65474 25.7429L11.9999 15.8061L21.3453 25.7429C21.6485 26.0654 22.0466 26.2273 22.4446 26.2273C22.8426 26.2273 23.2407 26.0654 23.5439 25.7429C24.1521 25.0966 24.1521 24.0515 23.5439 23.4052L14.1988 13.4683Z"
                            fill="black"
                        />
                    </svg>
                </span>
            }
            onClose={onClose}
            visible={visible}>
            <div className="d-flex flex-column h-100">
                <div>
                    <hr style={{ borderTop: '1px solid #DEC492' }} />
                </div>

                <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed pr-2">
                    {Array.isArray(archived_calendar_data?.data) &&
                        archived_calendar_data.data.map((value, ind) => (
                            <div
                                key={ind}
                                className="archived-calendar-list-item d-flex flex-column flex-md-row">
                                <div className="flex-grow-1 overflow-hidden mr-4">
                                    <div className="d-flex">
                                        <div
                                            className="flex-shrink-0 mr-3"
                                            style={{ flexBasis: 120, maxWidth: 120 }}>
                                            {/* <CalendarThumbnail calendar={value} /> */}

                                            <div
                                                className={`calendar-thumbnail-wrap ${
                                                    Array.isArray(value.products) &&
                                                    value.products.length &&
                                                    Array.isArray(
                                                        value.products[0].photos_and_videos
                                                    ) &&
                                                    value.products[0].photos_and_videos.length &&
                                                    value.products[0].photos_and_videos[0]?.url
                                                        ? '__no-image'
                                                        : ''
                                                }`}>
                                                {Array.isArray(value.products) &&
                                                value.products.length &&
                                                Array.isArray(
                                                    value.products[0].photos_and_videos
                                                ) &&
                                                value.products[0].photos_and_videos.length &&
                                                value.products[0].photos_and_videos[0]?.url ? (
                                                    <img
                                                        src={
                                                            value.products[0].photos_and_videos[0]
                                                                ?.url
                                                                ? value.products[0]
                                                                      .photos_and_videos[0]?.url
                                                                : require(`assets/images/no-image.svg`)
                                                        }
                                                        alt="img"
                                                    />
                                                ) : (
                                                    <></>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 text-truncate">
                                            <Tooltip title="4 Days Kruger and went on continue list">
                                                <div className="fs-18 fw-600 text-truncate">
                                                    {value.title}
                                                </div>
                                            </Tooltip>
                                            <span className="text-small">
                                                Calendar ID : {value.id}
                                            </span>
                                            <br />
                                            <div className="text-nowrap d-inline-flex align-items-center">
                                                {/* <img
                                                    src={require(`assets/images/star-rating/secondary/rating-04.svg`)}
                                                    alt=""
                                                /> */}
                                                <StarRating
                                                    value={value?.rating?.stars ?? 1}
                                                    iconHeight={16}
                                                    iconWidth={16}
                                                />
                                                <span className="fs-12 text-gray ml-2">
                                                    {value?.rating?.stars ?? 1}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-shrink-0 mt-3 mt-md-0">
                                    <div className="my-n2">
                                        <Button
                                            onClick={() => updateCalendar(value.id)}
                                            type="primary"
                                            className="btn-mw d-block fs-13 fw-500 my-2"
                                            block>
                                            Unarchive
                                        </Button>
                                        {/* <Button
                                        type="primary"
                                        className="btn-mw d-block fs-13 fw-500 my-2"
                                        block>
                                        Delete
                                    </Button> */}
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>

                <div className="pagination-gallery pt-3">
                    <Pagination
                        defaultCurrent={1}
                        total={archived_calendar_data?.meta?.total || 1}
                        defaultPageSize={archived_calendar_data?.meta?.per_page || 10}
                        onChange={(page) => setPage(page)}
                        showSizeChanger={false}
                    />
                </div>
            </div>
        </Drawer>
    );
};

ArchivedCalendars.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    getArchivedCalendars: PropTypes.func,
    archived_calendar_data: PropTypes.object,
    updateCalendarStatus: PropTypes.func
};

const mapStateToProps = (state) => ({
    archived_calendar_data: state.calendar.archived_calendar_data
});

const mapDispatch = (dispatch) => ({
    getArchivedCalendars: (query) => dispatch(getArchivedCalendars(query)),
    updateCalendarStatus: (query) => dispatch(updateCalendarStatus(query))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ArchivedCalendars));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'reactstrap';
import { Drawer, Input } from 'antd';
import { STRIPE_KEY } from 'utils/constants';

import { loadStripe } from '@stripe/stripe-js';
import {
    Elements,
    useStripe,
    useElements,
    CardElement,
    PaymentRequestButtonElement
} from '@stripe/react-stripe-js';
import Loader from 'react-loader-spinner';

import { setDeviceInfo } from 'utils/apiCaller';
import { HelpButton, Notification, ReactModal } from 'components/atoms';
import { CheckCircleFilled } from '@ant-design/icons';
import { getUserCards, initailizeTokenTopUp, clearTopInit } from 'store/actions/accessCalendar';
import { useDispatch, useSelector } from 'react-redux';

setDeviceInfo();
export default function TopUpDrawer(props) {
    const { setState, state, vendorSubscription, toggleAddTopUp, AddTopUp } = props;
    const stripe = loadStripe(STRIPE_KEY);

    return (
        <Drawer
            width={592}
            placement="right"
            onClose={() => toggleAddTopUp(false)}
            visible={AddTopUp}
            className="drawers-padding"
            style={{ zIndex: 1060 }}>
            <Elements stripe={stripe}>
                <h4 className="addTopUpTitle">Add Top-Up</h4>
                <div style={{ color: '#A2792C' }} className="fs-18 fw-500">
                    ${vendorSubscription?.token_topup_price} per Customer Token (Instead of Customer
                    Pay ${vendorSubscription?.price_per_customer_token})
                </div>
                <Row className="noOfCustomerDiv">
                    <Col md="6" className="noOfcustomerInput">
                        <label className="fs-16" htmlFor="no-of-customers">
                            No. Of Customer Invites
                        </label>
                        <Input
                            id="no-of-customers"
                            type="number"
                            className="inputclass add-top-up-input"
                            placeholder="10"
                            onChange={(e) =>
                                setState({
                                    numberOfCustomers: e.target.value,
                                    totalTokenAmount:
                                        e.target.value * vendorSubscription?.token_topup_price
                                    /* Calculating total token amount */
                                })
                            }
                        />
                    </Col>

                    <Col md="5">
                        <div
                            style={{
                                backgroundColor: 'white',
                                minHeight: '65px',
                                border: '1px solid #dee2e6'
                            }}
                            className="p-0 total-price d-flex justify-content-center align-items-center rounded-lg">
                            <p className="m-0 fs-20 fw-500 text-center">
                                Total Price : ${state.totalTokenAmount}
                            </p>
                        </div>
                    </Col>
                </Row>

                <div className="radiogroup-container">
                    {' '}
                    {/* Add top up payment option */}
                    {state.selectedPlanPayment === 'card' ? (
                        <>
                            <div className="">
                                <button type="button" className="btn p-0 mt-2 fs-14">
                                    Use Existing Card
                                </button>
                                {state.prefferedPaymentMethod === 'new' ? (
                                    <div className="px-2">
                                        <CheckCircleFilled
                                            style={{
                                                fontSize: 20,
                                                color: '#34A939'
                                            }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="my-2">
                                <UserCards
                                    setState={setState}
                                    price={state.totalTokenAmount}
                                    toggleAddTopUp={toggleAddTopUp}
                                    monthlyPlanCharges={state.monthlyPlanCharges}
                                    prefferedPaymentMethod={state.prefferedPaymentMethod}
                                    payload={{
                                        number_of_tokens: state.numberOfCustomers
                                    }}
                                />
                            </div>
                        </>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-link p-0 mt-2 fs-14"
                            onClick={() =>
                                setState({
                                    selectedPlanPayment: 'card',
                                    addAnotherCard: false
                                })
                            }>
                            Use Existing Card
                        </button>
                    )}
                    {state.selectedPlanPayment === 'new' ? (
                        <div className="border rounded p-3 mt-2 add-card-details">
                            <div className="d-flex mx-n2">
                                <div className="px-2 flex-grow-1">
                                    {/* <div className="fs-14 text-primary fw-500 mb-2">
                                        Add another card
                                    </div> */}
                                </div>
                                {/* Show if selected */}
                                {state.prefferedPaymentMethod === 'new' ? (
                                    <div className="px-2">
                                        <CheckCircleFilled
                                            style={{
                                                fontSize: 20,
                                                color: '#34A939'
                                            }}
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>

                            <StripeComponent
                                title={'Top-Up'}
                                payload={{
                                    number_of_tokens: state.numberOfCustomers
                                }}
                                setState={setState}
                                toggleAddTopUp={toggleAddTopUp}
                                price={state.totalTokenAmount} // handleSubmit={handleSubmit}
                            />

                            <div className="d-flex">
                                {/* <div className="d-flex mx-n2"> */}
                                {/* <div className="px-2 flex-grow-1 align-self-end">
                                                    <Checkbox className="fs-15 text-nowrap">
                                                        Save my card for faster checkout
                                                    </Checkbox>
                                                </div> */}
                                <div className="">
                                    <div className="fs-13">
                                        You will be charged ${state.totalTokenAmount}{' '}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <button
                            type="button"
                            className="btn btn-link p-0 mt-2 fs-14"
                            onClick={() =>
                                setState({
                                    selectedPlanPayment: 'new',
                                    addAnotherCard: true
                                })
                            }>
                            Add Another Card
                        </button>
                    )}
                    <PaymentMethodButtons
                        setState={setState}
                        title={'Token Top Up'}
                        payload={{ number_of_tokens: state.numberOfCustomers }}
                        price={state.totalTokenAmount}
                    />
                </div>

                <div className="d-flex justify-content-between ">
                    <div className="text-right pl-2 d-none d-sm-block right-button">
                        <HelpButton />
                    </div>
                </div>
            </Elements>
        </Drawer>
    );
}

TopUpDrawer.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    toggleAddTopUp: PropTypes.func,
    vendorSubscription: PropTypes.object,
    AddTopUp: PropTypes.bool
};

export const UserCards = ({ price, payload, toggleAddTopUp, setState }) => {
    const dispatch = useDispatch();
    const initData = useSelector((state) => state.accessCalendarReducer.initailizeTokenTopup);
    const userCards = useSelector((state) => state.accessCalendarReducer.userCards);
    const [processing, setProcessing] = useState(false);
    const [card, setCard] = useState({});
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        dispatch(getUserCards());
    }, []);

    useEffect(() => {
        if (initData?.client_secret) {
            confirmFunction();
            dispatch(clearTopInit());
        }
    }, [initData?.client_secret]);

    const confirmFunction = async () => {
        try {
            const response = await stripe?.confirmCardPayment(initData.client_secret, {
                payment_method: card?.id
            });

            if (response?.error) {
                console.log('response?.error:', response?.error);
                Notification('error', `Payment Failed`);
            } else {
                toggleAddTopUp(false);
                Notification('success', 'Payment Successful', 'success');
                setState({
                    numberOfCustomers: 0,
                    totalTokenAmount: 0
                });
            }
            setProcessing(false);
        } catch (error) {
            console.log('error:', error);
            Notification('error', `Payment Failed`);
            setProcessing(false);
        }
    };

    const stripe = useStripe();

    const onUseCard = async (payload) => {
        setProcessing(true);
        const checkoutPayload = {
            ...payload,
            payment_method_id: card.id,
            amount: price * 100,
            payment_method_type: 'card'
        };
        dispatch(initailizeTokenTopUp(checkoutPayload));
    };
    return (
        <div className="">
            <ReactModal
                title={'Are you sure want to complete this payment?'}
                description={'This action cannot be undone'}
                onOk={() => {
                    onUseCard(payload);
                    setVisibleModal(false);
                }}
                onClose={() => setVisibleModal(false)}
                isOpen={visibleModal}
            />
            {userCards.length ? (
                <div className="">
                    {userCards.map((currentCard) => {
                        return (
                            <div
                                className="border hover-border-primary rounded p-3 "
                                role="button"
                                tabIndex="0"
                                key={currentCard.id}>
                                <div className="d-flex mx-n2">
                                    <div className="px-2 flex-grow-1">
                                        <div className="fs-14 text-primary fw-500">
                                            Use Saved Card
                                        </div>
                                        <div className="mb-2 fs-14">{`Card No: XXX XXX ${currentCard.card.last4}`}</div>
                                        <div className="fs-13">You will be charged ${price} </div>
                                    </div>
                                    {/* Show if selected */}

                                    <Button
                                        disabled={processing || !+payload.number_of_tokens}
                                        className="btn-px black-button fw-500 fs-12 mr-2 "
                                        onClick={() => {
                                            setVisibleModal(true);
                                            setCard(currentCard);
                                        }}>
                                        {processing && card.id === currentCard.id ? (
                                            <Loader
                                                type="TailSpin"
                                                color="#000000"
                                                height={30}
                                                width={30}
                                            />
                                        ) : (
                                            'Use Card'
                                        )}
                                    </Button>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

UserCards.propTypes = {
    price: PropTypes.number,
    setState: PropTypes.func,
    toggleAddTopUp: PropTypes.func,
    payload: PropTypes.shape({
        number_of_tokens: PropTypes.number,
        plan: PropTypes.string
    })
};

export const PaymentMethodButtons = ({ title, price, payload }) => {
    const stripe = useStripe();
    const deviceInfo = JSON.parse(localStorage.deviceInfo);
    const acceptedLocation = JSON.parse(localStorage.acceptedLocation);
    const dispatch = useDispatch();
    const checkoutPayload = {
        ...payload,
        amount: price * 100,
        payment_method_type: 'card'
    };
    const prInit = stripe?.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
            label: title,
            amount: price * 100
        },
        requestPayerName: true,
        requestPayerEmail: true
    });
    const [paymentRequest, setPaymentRequest] = useState(prInit);
    const initData = useSelector((state) => state.accessCalendarReducer.initailizeTokenTopup);
    useEffect(() => {
        if (stripe) {
            if (!acceptedLocation.includes(deviceInfo.loc)) {
                return;
            }
            if (stripe) {
                const pr = stripe.paymentRequest({
                    country: deviceInfo.loc,
                    currency: 'usd',
                    total: {
                        label: title || '',
                        amount: price * 100
                    },
                    requestPayerName: true,
                    requestPayerEmail: true
                });

                // Check the availability of the Payment Request API.
                pr.canMakePayment().then((result) => {
                    if (result) {
                        pr.on('paymentmethod', (ev) => {
                            dispatch(initailizeTokenTopUp(checkoutPayload));

                            stripe
                                .confirmCardPayment(
                                    initData.client_secret,
                                    { payment_method: ev.paymentMethod.id },
                                    { handleActions: false }
                                )
                                .then(function (confirmResult) {
                                    if (confirmResult.error) {
                                        ev.complete('fail');
                                    } else {
                                        ev.complete('success');
                                        if (
                                            confirmResult.paymentIntent.status === 'requires_action'
                                        ) {
                                            // Let Stripe.js handle the rest of the payment flow.

                                            stripe
                                                .confirmCardPayment(initData.client_secret)
                                                .then(function (result) {
                                                    if (result.error) {
                                                        // The payment failed -- ask your customer for a new payment method.
                                                        Notification(
                                                            'error',
                                                            'Payment Failed try again',
                                                            'error'
                                                        );
                                                    } else {
                                                        Notification(
                                                            'success',
                                                            'Payment Successful',
                                                            'success'
                                                        );
                                                        // The payment has succeeded.
                                                    }
                                                });
                                        } else {
                                            // The payment has succeeded.
                                        }
                                    }
                                });
                        });

                        setPaymentRequest(pr);
                    }
                });
            }
        }
    }, [stripe]);
    return (
        <div>{paymentRequest && <PaymentRequestButtonElement options={{ paymentRequest }} />}</div>
    );
};

PaymentMethodButtons.propTypes = {
    price: PropTypes.number,
    title: PropTypes.string,
    payload: PropTypes.shape({
        number_of_tokens: PropTypes.number,
        plan: PropTypes.string
    }),
    prefferedPaymentMethod: PropTypes.string
};

export const StripeComponent = ({ price, payload, toggleAddTopUp, setState }) => {
    const dispatch = useDispatch();
    const stripe = useStripe();
    const elements = useElements();
    const [processing, setProcessing] = useState(false);
    const initData = useSelector((state) => state.accessCalendarReducer.initailizeTokenTopup);
    const [paymentMethod, setpaymentMethod] = useState('');
    const confirmFunction = async () => {
        try {
            if (!elements || !stripe || !initData.client_secret) {
                setProcessing(false);
                return;
            }
            const cardElement = elements.getElement(CardElement);

            await stripe.confirmCardPayment(initData.client_secret, {
                payment_method: paymentMethod.id
            });
            setProcessing(false);
            toggleAddTopUp(false);
            Notification('success', 'Payment Successful', 'success');
            setState({
                numberOfCustomers: 0,
                totalTokenAmount: 0
            });
            cardElement.clear();
        } catch (error) {
            console.log('error', error);
            Notification('error', 'Payment failed');
        }
    };
    const [visibleModal, setVisibleModal] = useState(false);

    useEffect(() => {
        if (initData?.client_secret) {
            confirmFunction();
            dispatch(clearTopInit());
        }
    }, [initData?.client_secret]);

    const handleSubmit = async (payload) => {
        setProcessing(true);
        try {
            const cardElement = elements.getElement(CardElement);

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            });
            setpaymentMethod(paymentMethod);
            if (error) {
                Notification('error', 'Unable to create payment method');
            }
            const checkoutPayload = {
                ...payload,
                payment_method_id: paymentMethod.id,
                amount: price * 100,
                payment_method_type: 'card'
            };
            dispatch(initailizeTokenTopUp(checkoutPayload));
        } catch (error) {
            console.log('error:', error);
        }
    };

    const cardInfoOptions = {
        style: {
            base: {
                'fontSize': '16px',
                'color': '#33333',
                'padding': '10rem',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#9e2146'
            }
        }
    };

    return (
        <>
            <ReactModal
                title={'Are you sure want to complete this payment?'}
                description={'This action cannot be undone'}
                onOk={() => {
                    handleSubmit(payload);
                    setVisibleModal(false);
                }}
                onClose={() => setVisibleModal(false)}
                isOpen={visibleModal}
            />
            <form
                action=""
                onSubmit={(e) => {
                    e.preventDefault();
                    setVisibleModal(true);
                }}>
                <CardElement options={cardInfoOptions} />

                <div className="mt-3 mb-2">
                    <div className=" border-opacity-40 border-4 border-black">
                        <Button
                            disabled={processing || !+payload.number_of_tokens}
                            className="btn-px black-button fw-500 fs-12 mr-2 "
                            type="submit">
                            {processing ? (
                                <Loader type="TailSpin" color="#000000" height={30} width={30} />
                            ) : (
                                'Pay Now'
                            )}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};

StripeComponent.propTypes = {
    price: PropTypes.number,
    title: PropTypes.string,
    setState: PropTypes.func,
    toggleAddTopUp: PropTypes.func,
    payload: PropTypes.shape({
        number_of_tokens: PropTypes.number,
        plan: PropTypes.string
    }),
    prefferedPaymentMethod: PropTypes.string
};

import axios from 'axios';
import { API_URL } from './constants';
import { fileUploadProgress_ } from 'store/actions';

export const callApi = (endpoint, method = 'POST', body, fileToUpload = false, dispatch) => {
    localStorage.setItem('loader', true);
    let headers = {};
    let authToken = false;
    let baseUrl = `${API_URL}${endpoint}`;

    localStorage.setItem('loader', true);
    let isTokenAvailable = localStorage.getItem('token');

    if (isTokenAvailable) {
        authToken = isTokenAvailable;
    }

    if (method) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }
    headers['Accept'] = 'application/json';
    headers['Access-Control-Allow-Origin'] = '*';
    if (fileToUpload) {
        headers['Content-Type'] = 'multipart/form-data';
    }

    // else {
    //     headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    // }

    if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
    }

    let config = {
        method: method,
        url: baseUrl,
        data: body,
        headers: headers
    };
    if (fileToUpload)
        config.onUploadProgress = (progressEvent) => {
            dispatch(
                fileUploadProgress_({
                    total: Number(progressEvent?.total),
                    loaded: Number(progressEvent?.loaded),
                    timeStamp: Number(progressEvent?.timeStamp),
                    name: body.get('media').name
                })
            );
            progressEvent.loaded / progressEvent.total === 1 && dispatch(fileUploadProgress_({}));
        };
    return axios(config)
        .then((response) => {
            if (response.status === 401 && response?.data?.code === 'E_UNAUTHORIZED_ACCESS') {
                // reload
                // Every api call goes through this CallApi method and whenever this code: E_UNAUTHORIZED_ACCESS is recieved I am removing the token because it is no longer valid
                // But through this place I cannot update the redux store to reflect the change in app's UI
                // If I were to dispatch an action then I would have to write that piece of code in every single api call method. Which is not a choice.
                // This apiCaller method does accept a dispatch prop but that's not passed in all the actions this method is used.
                // Therefore I came up with this solution to reload the application.
                // Now since token will be null when the application reloads then the app will automatically be logged out.
                if (localStorage.getItem('token')) {
                    localStorage.removeItem('token');
                    window.location.reload(true);
                }
            }
            return response;
        })
        .catch((err) => {
            if (err.response) {
                // Request made and server responded
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
            } else if (err.request) {
                // The request was made but no response was received
                console.log(err.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', err.message);
            }
            return err.response; // added  this here
        });
};

export const callGuestApi = (endpoint, method = 'POST', body, fileToUpload = false) => {
    localStorage.setItem('loader', true);
    let headers = {};
    let baseUrl = `${API_URL}${endpoint}`;
    if (method) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }
    headers['Accept'] = 'application/json';
    headers['Access-Control-Allow-Origin'] = '*';
    if (fileToUpload) {
        headers['Content-Type'] = 'multipart/form-data';
    }
    return axios({
        method: method,
        url: baseUrl,
        data: body,
        headers: headers
    })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err.response; // added  this here
        });
};

export const callApiAdvance = ({ endpoint, method, body, fileToUpload, loader }) => {
    localStorage.setItem('loader', loader);

    let headers = {};
    let authToken = false;
    let baseUrl = `${API_URL}${endpoint}`;

    localStorage.setItem('loader', loader);

    let isTokenAvailable = localStorage.getItem('token');

    if (isTokenAvailable) {
        authToken = isTokenAvailable;
    }

    if (method) {
        headers['X-Requested-With'] = 'XMLHttpRequest';
    }

    if (fileToUpload) {
        headers['Content-Type'] = 'multipart/form-data';
    } else {
        headers['Content-Type'] = 'application/json';
        // headers['Content-Type'] = 'application/json;charset=UTF-8';
    }

    // headers['Accept'] = 'application/json, text/plain, */*';
    // headers['Cookie'] = 'authorization=79d285ce70983b1cb3417280411ebbd88286a356; authenticate=79d285ce70983b1cb3417280411ebbd88286a356; csrftoken=jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';
    // headers['Connection'] = 'keep-alive';
    // headers['X-CSRFToken'] = 'jjxUaDsynURUZ2P8pQR3wKPpQsaun8Yb';

    if (authToken) {
        headers['Authorization'] = `Token ${authToken}`;
    }

    return axios({
        method: method,
        url: baseUrl,
        data: body,
        headers: headers
    })
        .then((response) => {
            localStorage.setItem('loader', false);
            return response;
        })
        .catch((err) => err);
};

export const setDeviceInfo = async () => {
    try {
        const res = await fetch('https://www.cloudflare.com/cdn-cgi/trace', {
            mode: 'cors'
        }).then((res) => res.text());

        const arr = res
            .trim()
            .split('\n')
            .map((e) => e.split('='));
        const data = Object.fromEntries(arr);
        localStorage.deviceInfo = JSON.stringify(data);
        localStorage.acceptedLocation = JSON.stringify([
            'AE',
            'AT',
            'AU',
            'BE',
            'BG',
            'BR',
            'CA',
            'CH',
            'CI',
            'CR',
            'CY',
            'CZ',
            'DE',
            'DK',
            'DO',
            'EE',
            'ES',
            'FI',
            'FR',
            'GB',
            'GI',
            'GR',
            'GT',
            'HK',
            'HU',
            'ID',
            'IE',
            'IN',
            'IT',
            'JP',
            'LI',
            'LT',
            'LU',
            'LV',
            'MT',
            'MX',
            'MY',
            'NL',
            'NO',
            'NZ',
            'PE',
            'PH',
            'PL',
            'PT',
            'RO',
            'SE',
            'SG',
            'SI',
            'SK',
            'SN',
            'TH',
            'TT',
            'US',
            'UY'
        ]);
        return data;
    } catch (error) {
        console.log('-------------------error:', error);
    }
};

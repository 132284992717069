import React, { PureComponent } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';

const data = [
    {
        name: 'Mon',
        uv: 1300,
        pv: 2400,
        amt: 2400
    },
    {
        name: 'Tue',
        uv: 3000,
        pv: 1398,
        amt: 2210
    },
    {
        name: 'Wed',
        uv: 2000,
        pv: 9800,
        amt: 2290
    },
    {
        name: 'Thur',
        uv: 2780,
        pv: 3908,
        amt: 2000
    },
    {
        name: 'Fri',
        uv: 1890,
        pv: 4800,
        amt: 2181
    },
    {
        name: 'Sat',
        uv: 2390,
        pv: 3800,
        amt: 2500
    },
    {
        name: 'Sun',
        uv: 1350,
        pv: 4300,
        amt: 2100
    }
];

export default class Example extends PureComponent {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/Lrffmzfc/';

    render() {
        return (
            <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="uv" stroke="#ffffff" fill="#A2792C" />
            </AreaChart>
        );
    }
}

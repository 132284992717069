/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import VendorDashboard from '../vendor-dashboard';
import ImportProduct from '../importproduct';
import TextFormLayout from '../../../atoms/textform-layout';
import InputBox from '../../../atoms/inputbox';
import CurvedButton from '../curvedbutton';
import { NavLink } from 'reactstrap';
import { Button } from '@material-ui/core';
import MediaField from '../../../molecules/media-library/media-field';
import PropLayout from '../proplayout';
import InputAccommodation from '../Inputaccommodation';
import Number from '../number';
import Double from '../../../../assets/images/double.svg';
import Single from '../../../../assets/images/single.svg';
import Bunk from '../../../../assets/images/bunk.svg';
import Plus from '../../../../assets/images/plus.svg';
import Sofa from '../../../../assets/images/sofa.svg';
import RadioButton from '../../../atoms/radiobutton';
import AddButton from '../add-button';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setDetails } from '../../../../store/actions/accommodationdetails';
import MyGoogleMap from '../../../molecules/map';

export default function AccDetails() {
    //Custom Style for Popup Modal
    const customStyles = {
        content: {
            height: '90%',
            overflowY: 'scroll',
            top: '50%',
            width: '60%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '5px'
        }
    };

    // Mock Data for Accommodation details

    const breakfast = [
        'A la carte',
        'American',
        'Asian',
        'Buffet',
        'Continental',
        'Full English/Irish',
        'Gluten free',
        'Halal',
        'Italian',
        'Kosher',
        'Vegan',
        'Vegetarian'
    ];

    // const initData = [
    //     {
    //         name: 'Villa/Mansion'
    //     },
    //     {
    //         name: 'Hotel'
    //     },
    //     {
    //         name: 'Apartment'
    //     },
    //     {
    //         name: 'Camping'
    //     },
    //     {
    //         name: 'Bread & Breakfast'
    //     },
    //     {
    //         name: 'Chalet'
    //     },
    //     {
    //         name: 'Hostel'
    //     },
    //     {
    //         name: 'Log Cabin'
    //     },
    //     {
    //         name: 'Country Home'
    //     },
    //     {
    //         name: 'Timeshare Rentals'
    //     },
    //     {
    //         name: 'Cottage'
    //     },
    //     {
    //         name: 'Other'
    //     }
    // ];

    const accomodationData = [
        {
            title: 'Double bed',
            subtitle: '131 - 150 cm wide',
            image: Double
        },
        {
            title: 'Large bed(King size)',
            subtitle: '151 - 180 cm wide',
            image: Double
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: '181 - 210 cm wide',
            image: Double
        },
        {
            title: 'Sofa bed',
            subtitle: 'Variable size',
            image: Sofa
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: 'Futon Mat',
            image: Double
        }
    ];

    const accomodationTwin = [
        {
            title: 'Single Bed x 2',
            subtitle: '90 - 130 cm wide',
            image: Single
        },
        {
            title: 'Bunk bed',
            subtitle: 'Variable size',
            image: Bunk
        }
    ];

    const singleRoom = [
        {
            title: 'Single Bed',
            subtitle: '90 - 130 cm wide',
            image: Single
        }
    ];

    const doubleRoom = [
        {
            title: 'Double Bed',
            subtitle: '90 - 130 cm wide',
            image: Single
        },
        {
            title: 'Large bed (King size)',
            subtitle: '150 - 180 cm wide',
            image: Single
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: '181 - 210 cm wide',
            image: Single
        }
    ];

    const general = [
        'Air conditioning',
        'Heating',
        'Free WiFi',
        'Free on site parking',
        'Electric vehicle changing station'
    ];

    const cooking = ['Kitchen', 'Kitchinette', 'Washing Machine'];

    const entertainment = ['Flat-screen TV', 'Swimming pool', 'Hot tub', 'Minibar', 'Sauna'];

    const outside = ['Balcony', 'Garden view', 'Terrace', 'View'];

    const houserules = ['Smoking allowed', 'Pets allowed', 'Parties/events allowed'];

    // UseStates for Accommodation details
    const [myGender, setMyGender] = useState('NO');
    const setGender = (e) => {
        setMyGender(e.target.value);
    };

    const [myPay, setMyPay] = useState('NO');
    const [days, setDays] = useState([]);
    const changePay = (e) => {
        setMyPay(e.target.value);
    };

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        setDays(['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7']);
    }, []);

    useEffect(() => {
        if (checked) {
            setSdays([...days]);
        } else {
            setSdays([]);
        }
    }, [checked, days]);
    const selectAll = () => {
        setChecked(!checked);
    };

    const [sDays, setSdays] = useState([]);
    const changeColor = (day) => {
        let newDays = [];
        if (sDays.some((d) => d === day)) {
            newDays = [...sDays.filter((d) => d !== day)];
        } else {
            newDays = [...sDays, day];
        }
        setSdays(newDays);
        saveDetails('days', newDays);
    };

    const [usage, setUsage] = useState(false);
    const changeUsage = () => {
        setUsage(true);
    };
    const closeUsage = () => {
        setUsage(false);
    };

    const [double, setDouble] = useState(false);
    const changeDouble = () => {
        setDouble(!double);
    };

    const [accommodation, setAccommodation] = useState(false);
    const changeAccommodation = () => {
        setAccommodation(!accommodation);
    };

    const [radio, setRadio] = useState(false);
    const changeRadio = () => {
        setRadio(!radio);
    };

    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const openModal = () => {
        setmodalIsOpen(true);
    };
    const closeModal = () => {
        setmodalIsOpen(false);
    };

    const [options, setOptions] = useState(3);
    const [optionDown, setoptionDown] = useState(false);
    const showOption = (key) => {
        setOptions(6);
        setoptionDown(key);
    };
    const closeOption = (key) => {
        setOptions(3);
        setoptionDown(!key);
    };

    const [button, setButton] = useState(false);
    const changeButton = () => {
        setButton(!button);
    };

    const [house, setHouse] = useState(false);
    const changeHouse = () => {
        setHouse(!house);
    };

    // const [covid, setCovid] = useState(false);
    // const changeCovid = () => {
    //     setCovid(!covid);
    // };

    const [icon, setIcon] = useState([]);
    const changeAddIcon = (add) => {
        if (icon.some((a) => a === add)) {
            setIcon([...icon.filter((a) => a !== add)]);
        } else {
            setIcon([...icon, add]);
        }
    };

    // Usedispatch function for dispatching an action to the store
    const dispatch = useDispatch();

    const accDetails = useSelector((state) => state.accdetails);
    const saveDetails = (key, data) => {
        // Dispatch setdetails function
        dispatch(setDetails({ key, data }));
    };

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                {modalIsOpen && (
                    <div>
                        <h6 style={{ paddingBottom: '20px', fontWeight: 900 }}>
                            Where can people sleep?
                        </h6>
                        <InputAccommodation
                            onChange={changeAccommodation}
                            fontWeight="600"
                            intro="This is for a person sharing a room with another person"
                            other="Per Person Sharing"
                        />
                        {accommodation && (
                            <>
                                <div style={{ paddingLeft: '10%' }}>
                                    <p style={{ fontWeight: '600' }}>Double</p>
                                    <p>This refers to a bed enough for two adults</p>
                                    {accomodationData.slice(0, options).map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img src={item.image} alt="" />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                    {!optionDown ? (
                                        <div
                                            onClick={() => showOption('optionDown')}
                                            className="row">
                                            <img className="image-icon-down" src={Down} alt="" />
                                            <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                More bed options
                                            </p>
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => closeOption('optionDown')}
                                            className="row">
                                            <img className="image-icon" src={Caret} alt="" />
                                            <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                Close bed options
                                            </p>
                                        </div>
                                    )}
                                    <hr />
                                    <p>This refers to a bed enough for two adults</p>
                                    {accomodationTwin.map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img src={item.image} alt="" />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <InputAccommodation
                            onChange={changeDouble}
                            fontWeight="600"
                            intro="This is for one person in a  room."
                            other="Per Person Single Room Occupancy"
                        />
                        {double && (
                            <>
                                <div style={{ paddingLeft: '10%' }}>
                                    <p style={{ fontWeight: '600' }}>Single</p>
                                    <p>
                                        This refers to a bed or room big enough for one average
                                        sized person.
                                    </p>
                                    {singleRoom.map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                    <hr />
                                    <p style={{ fontWeight: '600' }}>Double</p>
                                    <p>This refers to a bed big enough for two adults.</p>
                                    {doubleRoom.map((item, i) => (
                                        <div key={i} className="row" style={{ paddingBottom: 24 }}>
                                            <div className="col-md-2">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-4">
                                                <p
                                                    style={{
                                                        marginBottom: 0,
                                                        fontSize: 15,
                                                        fontWeight: '600'
                                                    }}>
                                                    {item.title}
                                                </p>
                                                <p style={{ marginBottom: 0, fontSize: 15 }}>
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-6">
                                                <Number />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                        <hr style={{ marginTop: '20px' }} />
                        <p style={{ paddingBottom: '20px', fontWeight: '600' }}>
                            How many bathrooms are there?
                        </p>
                        <Number />
                        <hr style={{ marginTop: '40px' }} />
                        <div className="d-flex" style={{ paddingTop: '20px' }}>
                            <button onClick={closeModal} className="button-save">
                                Save & Continue
                            </button>
                            <button onClick={closeModal} className="button-outline">
                                Cancel
                            </button>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                isOpen={usage}
                onRequestClose={closeUsage}
                style={customStyles}
                contentLabel="Example Modal">
                {usage && (
                    <div style={{ paddingTop: '20px' }}>
                        <h4>What can your customers use at your place. </h4>
                        <p>What if I don't see a facility I offer?</p>
                        <p>
                            The facilities listed here are the ones most searched for by guests.
                            After you complete your registration, you can add more facilities from a
                            larger list in the extranet, the platform you'll use to manage your
                            property.
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>General</p>
                                {general.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>Cooking and Cleaning</p>
                                {cooking.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>General</p>
                                {entertainment.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                            <div className="col-md-6">
                                <p style={{ fontWeight: '600' }}>Cooking and Cleaning</p>
                                {outside.map((items, i) => (
                                    <InputAccommodation other={items} key={i} />
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </Modal>
            <Modal
                isOpen={radio}
                onRequestClose={changeRadio}
                style={customStyles}
                contentLabel="Example Modal">
                {radio && (
                    <>
                        <RadioButton
                            gender={setGender}
                            text="Do you serve guest breakfast "
                            yes="Yes"
                            no="No"
                            details="Breakfast details"
                        />

                        {myGender == 'YES' ? (
                            <>
                                <RadioButton
                                    gender={changePay}
                                    text="Is breakfast included in the price guests pay?"
                                    yes="Yes, it's included"
                                    no="No it costs extra"
                                />
                                {myPay == 'YES' ? (
                                    <>
                                        <p style={{ paddingTop: 25 }}>
                                            Breakfast price per person, per day
                                        </p>
                                        <div style={{ width: '25%' }} className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span
                                                    className="input-group-text"
                                                    id="basic-addon1">
                                                    $
                                                </span>
                                            </div>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder=""
                                                aria-label=""
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <p></p>
                                )}
                                <hr />
                                <h6>What type of breakfast do you offer ?</h6>
                                <p>Select all that apply</p>
                                {breakfast.map((data) => (
                                    <>
                                        <AddButton
                                            image={Plus}
                                            pressedbutton="breakfast-button-acc"
                                            newbutton="breakfast-acc "
                                            name={data}
                                            isSelected={icon.includes(data)}
                                            onClick={() => changeAddIcon(data)}
                                        />
                                    </>
                                ))}
                                <button onClick={changeButton} className="breakfast-acc">
                                    <span>
                                        <img style={{ paddingRight: 6 }} src={Plus} alt="" />
                                    </span>
                                    Other
                                </button>
                                {button && (
                                    <input
                                        className="style-input"
                                        placeholder="Enter breakfast type"
                                    />
                                )}
                            </>
                        ) : (
                            <p style={{ paddingTop: 150 }}></p>
                        )}
                        <hr style={{ marginTop: '40px' }} />
                        <div className="d-flex" style={{ paddingTop: '20px' }}>
                            <button className="button-save">Save & Continue</button>
                            <button className="button-outline">Cancel</button>
                        </div>
                    </>
                )}
            </Modal>
            <Modal
                isOpen={house}
                onRequestClose={changeHouse}
                style={customStyles}
                contentLabel="Example Modal">
                {house && (
                    <div style={{ paddingTop: 30 }}>
                        <h5>House rules</h5>
                        <p>What if my house rules changes?</p>
                        <p>
                            You can easily customise these house rules later and additional house
                            rules can be set on the Policies page of the extranet after you complete
                            registration.
                        </p>
                        {houserules.map((house, i) => (
                            <div className="row" key={i}>
                                <div className="col-md-6">
                                    <p>{house}</p>
                                </div>
                                <div className="col-md-6">
                                    <label className="switch">
                                        <input type="checkbox" />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </Modal>
            <VendorDashboard>
                <div className="background-sz">
                    <ImportProduct
                        create="Add Villa / Mansion"
                        import=" I will import Villa / Mansion information from my other accomodation"
                    />
                    <div style={{ paddingTop: 30 }}>
                        <h6>Description</h6>
                        <p>
                            Describe the basics of where travelers will stay overnight, e.g. the
                            name, or details about the lodging.
                        </p>
                        <textarea
                            style={{
                                height: 125,
                                paddingLeft: '20px',
                                paddingTop: '10px'
                            }}
                            value={accDetails.description || ''}
                            onChange={({ target: { value } }) => saveDetails('description', value)}
                            className="input-bar"
                            placeholder="e.g. ‘ Overnight at Yosemite Lodge, a 3-star hotel’"
                            name="message"
                            rows="10"
                            cols="80"></textarea>
                        <p style={{ fontSize: 10 }}>100 characters needed</p>
                    </div>
                    <TextFormLayout
                        placeholder="Enter accomodation name"
                        layout="Accomodation Name"
                    />

                    <div style={{ paddingTop: 50 }} className="main-wrapper">
                        <MyGoogleMap />
                    </div>

                    <div style={{ paddingTop: 90 }}>
                        <div className="row" style={{ paddingTop: '40px' }}>
                            <div className="col-md-12">
                                <h3 style={{ fontSize: '20px' }}>
                                    Select the customers calendar days this booking apply
                                </h3>
                                <p style={{ fontSize: '15px' }}>
                                    This product will be availlable for customers to book on the
                                    days selected below.{' '}
                                </p>
                                <InputBox other="Select All" onChange={selectAll} />
                                <div style={{ paddingTop: 15 }}>
                                    {days.map((day, i) => (
                                        <CurvedButton
                                            key={i}
                                            pressedbutton="pressedbutton-acc"
                                            newbutton="newbutton-acc"
                                            name={day}
                                            isSelected={sDays.includes(day)}
                                            onClick={() => changeColor(day)}
                                        />
                                    ))}
                                </div>

                                <div style={{ paddingTop: '10px', paddingBottom: '40px' }}>
                                    <NavLink to="/accommodation-dashboard">
                                        <Button float={'left'} text={'Add Accommodation'} />
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex" style={{ paddingBottom: 30 }}>
                            <div>
                                <MediaField type="picture" />
                            </div>
                            <div style={{ paddingLeft: '30px' }}>
                                <MediaField type="video" />
                            </div>
                        </div>
                        <div className="container">
                            <PropLayout
                                text="Where can people sleep?"
                                subtext="Add rooms"
                                top="20px"
                                onClick={openModal}
                            />

                            <PropLayout
                                text="What can your customers use at your place?"
                                subtext="Add facilities"
                                top="40px"
                                onClick={changeUsage}
                            />

                            <PropLayout
                                text="Breakfast details"
                                subtext="Add breakfast"
                                top="40px"
                                onClick={changeRadio}
                            />

                            <PropLayout
                                text="House rules"
                                subtext="Add house rules"
                                top="40px"
                                onClick={changeHouse}
                            />

                            <hr style={{ marginTop: '40px' }} />

                            <PropLayout
                                number="3px"
                                navlink="/pricing"
                                isSelected={true}
                                to="/pricing"
                                text="Pricing"
                                subtext="Add Pricing details for your ‘Product Title’ tour"
                                top="40px"
                            />
                            <PropLayout
                                number="3px"
                                navlink="/personal-care"
                                isSelected={true}
                                to="/pricing"
                                text="Booking and Tickets"
                                subtext="Add Booking and Ticketing details  for your ‘Product Title’ tour"
                                top="40px"
                            />
                            <PropLayout
                                number="3px"
                                navlink="/pricing"
                                isSelected={true}
                                to="/pricing"
                                text="Information travellers need from you"
                                subtext="Add any physical restrictions for this Itinerary"
                                top="40px"
                            />
                            <PropLayout
                                number="3px"
                                isSelected={true}
                                navlink="/covid19"
                                to="/pricing"
                                text="Covid-19 Updates"
                                subtext="Add Covid-19 safety updates"
                                top="40px"
                            />
                        </div>
                    </div>
                </div>
            </VendorDashboard>
        </>
    );
}

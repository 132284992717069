import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, TimePicker, Select } from 'antd';
import './index.scss';
import Display from '../../atoms/display';
import moment from 'moment';

const HOUR_FORMAT = 'HH';
const MINUTES_FORMAT = 'mm';

function TimeBlock(props) {
    const { showAmPm = true, padding = '6px', data, isReturnSeconds = false, onSeconds } = props;
    const [hour, setHour] = useState('00');
    const [minutes, setMinutes] = useState('00');
    useEffect(() => {
        if (data !== null && data) {
            setHour(`${data[0]}${data[1]}`);
            setMinutes(`${data[3]}${data[4]}`);
        }
    }, [data]);
    return (
        <div className="atc-time-picker">
            <Row gutter={12}>
                <Col style={{ paddingRight: padding }}>
                    <TimePicker
                        size="large"
                        format={HOUR_FORMAT}
                        value={data === null ? undefined : moment(hour, HOUR_FORMAT)}
                        placeholder="00"
                        style={{
                            height: '50px'
                        }}
                        onChange={(e, timeString) => {
                            setHour(timeString);
                            if (isReturnSeconds)
                                onSeconds(
                                    moment(e._d, 'HH:mm:ss').diff(
                                        moment().startOf('day'),
                                        'seconds'
                                    )
                                );
                            else props.onTime(`${timeString}:${minutes}`);
                        }}
                    />
                    <span className="atc-time-picker__tag" style={{ height: '50px' }}>
                        hours
                    </span>
                </Col>
                <Col>
                    <TimePicker
                        size="large"
                        format={MINUTES_FORMAT}
                        placeholder="00"
                        value={data === null ? undefined : moment(minutes, MINUTES_FORMAT)}
                        style={{
                            height: '50px'
                        }}
                        onChange={(e, timeString) => {
                            setMinutes(timeString);
                            if (!isReturnSeconds) props.onTime(`${hour}:${timeString}`);
                        }}
                    />
                    <span className="atc-time-picker__tag" style={{ height: '50px' }}>
                        minutes
                    </span>
                </Col>
                <Display show={showAmPm}>
                    <Col>
                        <Select size="large" defaultValue="AM" style={{ height: '50px' }}>
                            {['AM', 'PM'].map((item) => (
                                <Select.Option key={item}>{item}</Select.Option>
                            ))}
                        </Select>
                    </Col>
                </Display>
            </Row>
        </div>
    );
}

TimeBlock.propTypes = {
    showAmPm: PropTypes.bool,
    onTime: PropTypes.func,
    onSeconds: PropTypes.func,
    padding: PropTypes.string,
    isReturnSeconds: PropTypes.bool,
    data: PropTypes.string
};

export default TimeBlock;

import React from 'react';
import { Popover } from 'antd';

const LeftoverCount = () => {
    return (
        <div className="event-card-info p-2 __type-time-block">
            <div className="d-flex flex-nowrap" style={{ gap: 4 }}>
                {/* Loop this upto 3 */}
                <div>
                    <img
                        className="calendar-user-img _mini"
                        src={`https://randomuser.me/api/portraits/women/${30}.jpg`}
                        alt="User"
                    />
                </div>
                <div>
                    <img
                        className="calendar-user-img _mini"
                        src={`https://randomuser.me/api/portraits/women/${30}.jpg`}
                        alt="User"
                    />
                </div>
                <div>
                    <img
                        className="calendar-user-img _mini"
                        src={require('assets/images/user-primary-img.svg')}
                        alt="User"
                    />
                </div>
                {/* Leftover Count */}
                <div>
                    <Popover
                        overlayClassName="popover-container"
                        content={
                            <div>
                                <div className="fs-14 fw-400 mb-2">All Travellers</div>
                                <div className="d-flex flex-nowrap" style={{ gap: 4 }}>
                                    <div>
                                        <img
                                            className="calendar-user-img _mini"
                                            src={`https://randomuser.me/api/portraits/women/${12}.jpg`}
                                            alt="User"
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="calendar-user-img _mini"
                                            src={`https://randomuser.me/api/portraits/women/${13}.jpg`}
                                            alt="User"
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="calendar-user-img _mini"
                                            src={`https://randomuser.me/api/portraits/women/${14}.jpg`}
                                            alt="User"
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="calendar-user-img _mini"
                                            src={`https://randomuser.me/api/portraits/women/${15}.jpg`}
                                            alt="User"
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="calendar-user-img _mini"
                                            src={`https://randomuser.me/api/portraits/women/${16}.jpg`}
                                            alt="User"
                                        />
                                    </div>
                                    <div>
                                        <img
                                            className="calendar-user-img _mini"
                                            src={`https://randomuser.me/api/portraits/women/${17}.jpg`}
                                            alt="User"
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                        placement={'top'}>
                        <div className="calendar-user-img _mini _mask-only">+3</div>
                    </Popover>
                </div>
            </div>
        </div>
    );
};

export default LeftoverCount;

/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import './index.scss';
import { InputNumber, Form } from 'antd';
// import { Col, Row } from 'reactstrap';
import DeleteIcon from 'assets/images/close.svg';
import Proptypes from 'prop-types';
import styles from './styles';
import {
    getMinProductAccomodationGroupBookingVal,
    getMaxProductAccomodationGroupBookingVal
} from 'utils';

export default function GroupRow({ accomodationGroupPrices, setAccomodationGroupPrices }) {
    const onChange = (value, name, index) => {
        let temp = [...accomodationGroupPrices];
        temp[index][name] = value;
        setAccomodationGroupPrices(temp);
    };

    const removeItem = (index) => {
        if (accomodationGroupPrices?.length <= 1) {
            return setAccomodationGroupPrices(null);
        }
        let temp = [...accomodationGroupPrices];
        temp.splice(index, 1);
        setAccomodationGroupPrices([...temp]);
    };

    return (
        <>
            {accomodationGroupPrices.map((res, i) => (
                <div key={i} className="booking-price mb-3">
                    <div className="d-flex gap-2">
                        <div>
                            <div style={styles.headText}>USD</div>
                            <div className="d-flex align-items-center gap-2">
                                <Form.Item
                                    name={`bookingpercent${i}`}
                                    rules={[
                                        {
                                            required: !res.percentage,
                                            message: 'Please add percentage for group price'
                                        }
                                    ]}>
                                    <div
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        className="input-percent">
                                        <InputNumber
                                            style={{ ...styles.input, paddingRight: '25px' }}
                                            value={res.percentage}
                                            min={1}
                                            onChange={(e) => onChange(e, 'percentage', i)}
                                        />
                                    </div>
                                </Form.Item>

                                <b style={styles.betweenBlock}>From</b>
                            </div>
                        </div>
                        <div>
                            <div style={styles.headText}>Min - Max booking per price</div>
                            <div className="d-flex align-items-center gap-2">
                                <div>
                                    <Form.Item
                                        name={`bookingmin${i}`}
                                        rules={[
                                            {
                                                required: !res.min,
                                                message: 'Please add min booking for group price'
                                            }
                                        ]}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <InputNumber
                                                style={{ ...styles.input }}
                                                value={res.min}
                                                min={getMinProductAccomodationGroupBookingVal(
                                                    i,
                                                    accomodationGroupPrices
                                                )}
                                                max={
                                                    res.max ||
                                                    getMaxProductAccomodationGroupBookingVal(
                                                        i,
                                                        accomodationGroupPrices
                                                    )
                                                }
                                                onChange={(e) => e != null && onChange(e, 'min', i)}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div>
                                    <div style={styles.betweenBlock}>-</div>
                                </div>
                                <div>
                                    <Form.Item
                                        name={`bookingmin${i}`}
                                        rules={[
                                            {
                                                required: !res.max,
                                                message: 'Please add max booking for group price'
                                            }
                                        ]}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <InputNumber
                                                style={{ ...styles.input }}
                                                value={res.max}
                                                min={
                                                    res.min ||
                                                    getMinProductAccomodationGroupBookingVal(
                                                        i,
                                                        accomodationGroupPrices
                                                    )
                                                }
                                                max={getMaxProductAccomodationGroupBookingVal(
                                                    i,
                                                    accomodationGroupPrices
                                                )}
                                                onChange={(e) => e != null && onChange(e, 'max', i)}
                                            />
                                        </div>
                                    </Form.Item>
                                </div>
                                <div>
                                    <div className="delete-block">
                                        <img
                                            src={DeleteIcon}
                                            className="delete-icon cursor-pointer"
                                            style={styles.deleteIcon}
                                            onKeyUp={() => removeItem(i)}
                                            onClick={() => removeItem(i)}
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

GroupRow.propTypes = {
    addNewBooking: Proptypes.array,
    onSave: Proptypes.func,
    accomodationGroupPrices: Proptypes.any,
    setAccomodationGroupPrices: Proptypes.any
};

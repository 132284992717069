/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button } from 'antd';
import React, { useState } from 'react';
import './index.scss';

import { Collapse } from 'antd';

const { Panel } = Collapse;

export default function FAQ() {
    const [activelink, setactivelink] = useState(0);
    const [activeInnerLink, setactiveInnerLink] = useState(0);
    const faqs = [
        {
            title: 'Help Center',
            children: ['Ask Your Travel Planner', 'Ask a Top Ranked Member', 'Ask the Vendor']
        },
        {
            title: 'FAQ',
            children: [
                'Ordering',
                'Account',
                'Technical',
                'Promotion',
                'Reviews',
                'Safety & Security'
            ]
        },
        {
            title: 'General Information',
            children: [
                'Pay Monthly',
                'Product Replacement Plan',
                'Warranty Payment Plan',
                'Competitions & Promotions',
                'Terms & Conditions',
                'Privacy Statement',
                'Data Privacy',
                'Cookie Policy'
            ]
        }
    ];
    return (
        <div className="faqs">
            <div className="faq-links">
                <p>{activelink !== null ? faqs[activelink].children[activeInnerLink] : ''}</p>
                {faqs.map((link, i) => (
                    <>
                        <div
                            className={`single-link ${activelink === i && 'current'}`}
                            onClick={() => {
                                activelink === i ? setactivelink(null) : setactivelink(i);
                                setactiveInnerLink(0);
                            }}>
                            <p style={{ fontSize: 18, fontWeight: '600' }}>{link.title}</p>
                            <svg
                                width="16"
                                height="10"
                                viewBox="0 0 21 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M17.825 0.825012L10.175 8.45834L2.52505 0.825012L0.175049 3.17501L10.175 13.175L20.175 3.17501L17.825 0.825012Z"
                                    fill="#E2C022"
                                />
                            </svg>
                        </div>
                        {activelink !== null && activelink === i
                            ? faqs[activelink].children.map((child, i) => (
                                  <div
                                      key={i}
                                      onClick={() => setactiveInnerLink(i)}
                                      className={`inner-link ${
                                          i === activeInnerLink ? 'active' : ''
                                      }`}>
                                      {child}
                                  </div>
                              ))
                            : null}
                    </>
                ))}
            </div>
            <div className="contents">
                <p style={{ fontSize: 30, fontWeight: '600', marginTop: 40 }}>
                    {activelink !== null ? faqs[activelink].children[activeInnerLink] : ''}
                </p>
                {activelink === 0 && (
                    <div
                        style={{
                            border: '0.5px solid #cacaca',
                            borderRadius: 15,
                            padding: 30
                        }}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. It
                        was popularised in the 1960s with the release
                        <div
                            style={{
                                border: '0.5px solid #cacaca',
                                borderRadius: 15,
                                padding: 30,
                                marginTop: 30
                            }}>
                            <p style={{ color: '#333333', fontSize: 16, fontWeight: '500' }}>
                                Ask Your Travel Planner
                            </p>
                            <p style={{ color: '#4F4F4F', fontSize: 14 }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's standard dummy text
                                ever since the 1500s, when an unknown printer took a galley of type
                                and scrambled it to make a type specimen book. It has survived not
                                only five
                            </p>

                            <Button type="primary" className="rounded-btn">
                                Ask My Travel Planner
                            </Button>
                        </div>
                    </div>
                )}
                {activelink === 1 && (
                    <div
                        style={{
                            border: '0.5px solid #cacaca',
                            borderRadius: 15,
                            padding: 30
                        }}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book. It has survived not only five centuries, but also
                        the leap into electronic typesetting, remaining essentially unchanged. It
                        was popularised in the 1960s with the release
                        <hr />
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIcon={({ isActive }) => (
                                <svg
                                    width="16"
                                    height="10"
                                    viewBox="0 0 21 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{
                                        marginTop: isActive ? 0 : -5,
                                        transform: !isActive ? 'rotate(-90deg)' : '',
                                        transition: !isActive ? 'linear all 0.15s' : ''
                                    }}>
                                    <path
                                        d="M17.825 0.825012L10.175 8.45834L2.52505 0.825012L0.175049 3.17501L10.175 13.175L20.175 3.17501L17.825 0.825012Z"
                                        fill="#E2C022"
                                    />
                                </svg>
                            )}
                            className="site-collapse-custom-collapse">
                            <Panel
                                header="This is panel header 1"
                                key="1"
                                className="site-collapse-custom-panel">
                                <p>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem
                                    dignissimos dolores nulla quo odit itaque minus sequi adipisci
                                    labore tenetur. Nostrum hic esse numquam odio nulla cupiditate,
                                    illum sit necessitatibus!
                                </p>
                            </Panel>
                            <Panel
                                header="This is panel header 2"
                                key="2"
                                className="site-collapse-custom-panel">
                                <p>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem
                                    dignissimos dolores nulla quo odit itaque minus sequi adipisci
                                    labore tenetur. Nostrum hic esse numquam odio nulla cupiditate,
                                    illum sit necessitatibus!
                                </p>
                            </Panel>
                            <Panel
                                header="This is panel header 3"
                                key="3"
                                className="site-collapse-custom-panel">
                                <p>
                                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem
                                    dignissimos dolores nulla quo odit itaque minus sequi adipisci
                                    labore tenetur. Nostrum hic esse numquam odio nulla cupiditate,
                                    illum sit necessitatibus!
                                </p>
                            </Panel>
                        </Collapse>
                    </div>
                )}
                {activelink === 2 && (
                    <div
                        style={{
                            border: '0.5px solid #cacaca',
                            borderRadius: 15,
                            padding: 30
                        }}>
                        <p className="fs18" style={{ fontWeight: '600' }}>
                            Spread the cost, interest-free
                        </p>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the
                        1500s, when an unknown printer took a galley of type and
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}>
                            <div
                                style={{
                                    border: '0.5px solid #cacaca',
                                    borderRadius: 15,
                                    padding: 30,
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '48%'
                                }}>
                                <p
                                    style={{
                                        color: '#A90000',
                                        fontSize: 20,
                                        fontWeight: '600',
                                        marginBottom: 5
                                    }}>
                                    Flexi-Pay
                                </p>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an un
                                </p>
                                <Button
                                    style={{
                                        backgroundColor: '#A90000',
                                        width: '100%',
                                        color: '#fff',
                                        borderRadius: '5px'
                                    }}>
                                    Find Out More
                                </Button>
                            </div>

                            <div
                                style={{
                                    border: '0.5px solid #cacaca',
                                    borderRadius: 15,
                                    padding: 30,
                                    marginTop: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '48%'
                                }}>
                                <p
                                    style={{
                                        color: '#A90000',
                                        fontSize: 20,
                                        fontWeight: '600',
                                        marginBottom: 5
                                    }}>
                                    Flexi-Pay
                                </p>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an un
                                </p>
                                <Button
                                    style={{
                                        backgroundColor: '#A90000',
                                        width: '100%',
                                        color: '#fff',
                                        borderRadius: '5px'
                                    }}>
                                    Find Out More
                                </Button>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                marginTop: 12
                            }}>
                            <Button
                                style={{
                                    background: '#9B9B9B',
                                    border: '0.5px solid #979797',
                                    borderRadius: 8,
                                    width: '48%',
                                    color: '#fff'
                                }}>
                                Why Choose Flexi-Pay?
                            </Button>

                            <Button
                                style={{
                                    background: '#9B9B9B',
                                    border: '0.5px solid #979797',
                                    borderRadius: 8,
                                    width: '48%',
                                    color: '#fff'
                                }}>
                                Why Choose Flexi-Pay?
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

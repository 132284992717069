import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';

const isAddedAccessibility = (data) =>
    data.wheelchair ||
    data.stroller ||
    data.service_animals ||
    data.public_trans ||
    data.infant_lapping ||
    data.infant_seats;
const ReviewsFilter = (data) => {
    const tmpData = [];
    data.map((res) =>
        res.type === 'google'
            ? tmpData.push('GOOGLE')
            : res.type === 'trip_advisor'
            ? tmpData.push('TRIP ADVISOR')
            : res.type === 'trust_pilot'
            ? tmpData.push('TRUST PILOT')
            : res.type === 'facebook'
            ? tmpData.push('FACEBOOK')
            : res.type === 'booking'
            ? tmpData.push('BOOKING.COM')
            : null
    );
    return tmpData;
};
const TicketData = (props) => {
    const Rooms = (data) => (
        <Col sm={12}>
            Where can people sleep:
            <br />
            <div className="fs-12">
                {data.pp_sharing ? (
                    <div>
                        Per person Sharing
                        <ul>
                            {data.pp_sharing.double.double_bed > 0 ? (
                                <li>Double bed x{data.pp_sharing.double.double_bed}</li>
                            ) : null}
                            {data.pp_sharing.double.large_bed > 0 ? (
                                <li>Large bed(King size) x{data.pp_sharing.double.large_bed}</li>
                            ) : null}
                            {data.pp_sharing.double.xl_double_bed > 0 ? (
                                <li>
                                    Extra-large double bed (Super-king-size) x
                                    {data.pp_sharing.double.xl_double_bed}
                                </li>
                            ) : null}
                            {data.pp_sharing.double.sofa_bed > 0 ? (
                                <li>Sofa bed x{data.pp_sharing.double.sofa_bed}</li>
                            ) : null}
                            {data.pp_sharing.double.futon_mat > 0 ? (
                                <li>Futon Mat x{data.pp_sharing.double.futon_mat}</li>
                            ) : null}
                        </ul>
                    </div>
                ) : null}
            </div>
            <div className="fs-12">
                {data.pp_single ? (
                    <div>
                        Per person Single room occupancy
                        <ul>
                            {data.pp_single.double.double_bed > 0 ? (
                                <li>Double bed x{data.pp_single.double.double_bed}</li>
                            ) : null}
                            {data.pp_single.double.large_bed > 0 ? (
                                <li>Large bed(King size) x{data.pp_single.double.large_bed}</li>
                            ) : null}
                            {data.pp_single.double.xl_double_bed > 0 ? (
                                <li>
                                    Extra-large double bed (Super-king-size) x
                                    {data.pp_single.double.xl_double_bed}
                                </li>
                            ) : null}
                            {data.pp_single.double.sofa_bed > 0 ? (
                                <li>Sofa bed x{data.pp_single.double.sofa_bed}</li>
                            ) : null}
                            {data.pp_single.double.futon_mat > 0 ? (
                                <li>Futon Mat x{data.pp_single.double.futon_mat}</li>
                            ) : null}
                        </ul>
                    </div>
                ) : null}
            </div>
            <div className="fs-12">
                {data.no_of_bathrooms ? `Bathrooms x${data.no_of_bathrooms}` : null}
            </div>
            <br />
        </Col>
    );
    const Facilities = (data) => {
        const tmpData = [];
        tmpData.push.apply(tmpData, data.general);
        tmpData.push.apply(tmpData, data.cooking_and_cleaning);
        tmpData.push.apply(tmpData, data.entertainment);
        tmpData.push.apply(tmpData, data.outside_and_view);
        return (
            <Col sm={12}>
                What can your customers use:
                <br />
                <div className="fs-12">
                    {tmpData.map((res, i) => (i === tmpData.length - 1 ? res : `${res}, `))}
                </div>
                <br />
            </Col>
        );
    };
    const Breakfast = (data) => (
        <Col sm={12}>
            Breakfast details:
            <br />
            <div className="fs-12">
                {data.included ? (
                    <div>
                        Breakfast included
                        <div>
                            {data.price
                                ? `Breakfast price $${data.price}`
                                : 'Breakfast included in price'}
                        </div>
                    </div>
                ) : (
                    'Breakfast not included'
                )}
            </div>
            <div className="fs-12">
                {data.types && data.types.length > 0
                    ? data.types.map((res, i) => (i === data.types.length - 1 ? res : `${res}, `))
                    : null}
            </div>
            <br />
        </Col>
    );
    const HouseRules = (data) => (
        <Col sm={12}>
            House rules:
            <br />
            <div className="fs-12">
                {data.smoking_allowed ? 'Smoking allowed, ' : null}
                {data.pets_allowed ? 'Pets allowed, ' : null}
                {data.events_allowed ? 'Parties/events allowed' : null}
            </div>
            <div className="fs-12">
                {data.check_in
                    ? `Check in: ${data.check_in.from || '00:00'} - ${
                          data.check_in.until || '00:00'
                      }`
                    : null}
            </div>
            <div className="fs-12">
                {data.check_out
                    ? `Check out: ${data.check_out.from || '00:00'} - ${
                          data.check_out.until || '00:00'
                      }`
                    : null}
            </div>
            <br />
        </Col>
    );
    const Pricing = (data) => (
        <Col sm={12}>
            Pricing:
            <br />
            {data.pp_sharing ? (
                <div className="fs-12">{`Active price (Double) - $${data.pp_sharing.double.price}.${
                    data.pp_sharing.double.ss_price
                        ? ` Single supplement - $${data.pp_sharing.double.ss_price}`
                        : ''
                }`}</div>
            ) : null}
            {data.group ? <div className="fs-12">Including Add Group Booking Price</div> : null}
            {data.festive ? (
                <div className="fs-12">Add Price for Peak/Festive Season Supplement</div>
            ) : null}
            <br />
        </Col>
    );
    const Information = (data) => (
        <Col sm={12}>
            Information travellers need from you:
            <br />
            <div className="fs-12">
                {isAddedAccessibility(data.accessibility) ? 'Accessibility Added' : null}
            </div>
            <div className="fs-12">
                {data.health_restrictions[0] ? `Health Restrictions Added` : null}
            </div>
            <div className="fs-12">
                {data.difficulty_level ? `Physical Difficulty Added` : null}
            </div>
            <div className="fs-12">{data.phone_number ? 'Phone Number Added' : null}</div>
            <br />
        </Col>
    );
    const Covid = (data) => (
        <Col sm={12}>
            Covid-19 Updates:
            <br />
            <div className="fs-12">
                {data.map((res, i) => (i === data.length - 1 ? res : `${res}, `))}
            </div>
            <br />
        </Col>
    );
    const Reviews = (data) => (
        <Col sm={12}>
            Reviews:
            <br />
            <div className="fs-12">
                {ReviewsFilter(data) && ReviewsFilter(data).length > 0
                    ? ReviewsFilter(data).map((res, i) =>
                          i === ReviewsFilter(data).length - 1 ? res : `${res}, `
                      )
                    : null}
            </div>
            <br />
        </Col>
    );
    return (
        <div className="ticket-data">
            {props.data ? (
                <div>
                    {props.data.rooms ? Rooms(props.data.rooms) : null}
                    {props.data.facilities ? Facilities(props.data.facilities) : null}
                    {props.data.breakfast_details ? Breakfast(props.data.breakfast_details) : null}
                    {props.data.house_rules ? HouseRules(props.data.house_rules) : null}
                    {props.data.accomodation_price ? Pricing(props.data.accomodation_price) : null}
                    {props.data.traveller_information
                        ? Information(props.data.traveller_information)
                        : null}
                    {props.data.covid19_updates ? Covid(props.data.covid19_updates) : null}
                    {props.data.review_links ? Reviews(props.data.review_links) : null}
                </div>
            ) : null}
        </div>
    );
};

TicketData.propTypes = {
    data: PropTypes.object
};

export default TicketData;

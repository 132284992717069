import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TimePicker, Popover } from 'antd';
import { connect } from 'react-redux';
import { updateCalendarRestingTime } from 'store/actions';
import { useRouteMatch } from 'react-router-dom';
import { RestingEventIcon } from 'components/atoms';
import './index.scss';

const RestingTime = ({ eventInfo, updateCalendarRestingTime, dark }) => {
    const route = useRouteMatch();
    const [restTimeStart, setRestTimeStart] = useState(null);
    const [restTimeEnd, setRestTimeEnd] = useState(null);
    const [visible, setVisible] = useState(false);
    const onChange = async () => {
        if (restTimeStart !== null && restTimeEnd != null) {
            await updateCalendarRestingTime(route.params.id, {
                rest_time_start: restTimeStart,
                rest_time_end: restTimeEnd
            });
            setRestTimeStart(null);
            setRestTimeEnd(null);
        }
    };

    const eventDurationType = eventInfo?.event?.extendedProps?.event_duration_type;

    return (
        <div
            className={`timeline-sticky ${
                eventDurationType === 'end' || eventDurationType === 'last-day' ? 'h-100' : ''
            }`}
            style={{ top: 3 }}>
            <div
                className={`timeline-suggested-block ${
                    eventDurationType === 'start' || eventDurationType === 'only_start'
                        ? 'type-start'
                        : ''
                } ${eventDurationType === 'last-day' ? 'type-last-day' : ''} ${
                    eventDurationType === 'end' ? 'type-end' : ''
                }`}>
                {eventDurationType === 'last-day' ? (
                    <></>
                ) : (
                    <>
                        {' '}
                        <div className="mr-2" style={{ marginLeft: '-25px' }}>
                            <RestingEventIcon dark={dark} />
                        </div>
                        <div className="mt-1">
                            <div className="fw-500 mb-1 fs-16">
                                <span>
                                    Resting{' '}
                                    {eventDurationType === 'end'
                                        ? '( Start ) '
                                        : eventDurationType === 'start'
                                        ? '( End ) '
                                        : ''}
                                    time
                                </span>
                            </div>
                            <div>
                                <div className="popover-container-adjust-resting-time">
                                    <Popover
                                        visible={visible}
                                        onVisibleChange={(e) => setVisible(e)}
                                        overlayClassName="popover-container-adjust-resting-time"
                                        placement={
                                            window.innerWidth >= 768 ? 'bottomLeft' : 'bottom'
                                        }
                                        trigger="click"
                                        content={
                                            <div className={'popover-bg-dark '}>
                                                <div className="d-flex align-items-center mx-n2 mb-2">
                                                    <div className="px-2 flex-grow-1">
                                                        <div className="fs-15 text-khaki fw-500 fst-italic ff-special">
                                                            Start Time
                                                        </div>
                                                    </div>
                                                    <div className="px-2">
                                                        <TimePicker
                                                            className="tooltip-timepicker"
                                                            use12Hours
                                                            format="h:mm a"
                                                            getPopupContainer={(trigger) =>
                                                                trigger.parentElement
                                                            }
                                                            onChange={onChange}
                                                            onSelect={(e) =>
                                                                setRestTimeStart(e.format('HH:mm'))
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mx-n2">
                                                    <div className="px-2 flex-grow-1">
                                                        <div className="fs-15 text-khaki fw-500 fst-italic ff-special">
                                                            End Time
                                                        </div>
                                                    </div>
                                                    <div className="px-2">
                                                        <TimePicker
                                                            className="tooltip-timepicker"
                                                            use12Hours
                                                            format="h:mm a"
                                                            getPopupContainer={(trigger) =>
                                                                trigger.parentElement
                                                            }
                                                            onSelect={(e) =>
                                                                setRestTimeEnd(e.format('HH:mm'))
                                                            }
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }>
                                        <span
                                            className={`fs-14 btn btn-link p-0 ${
                                                dark && 'resting-time-text-dark'
                                            }`}
                                            role="button"
                                            tabIndex="0">
                                            <i>Adjust</i>
                                        </span>{' '}
                                    </Popover>
                                    <span className="fs-12">for better travel Experience.</span>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
RestingTime.propTypes = {
    eventInfo: PropTypes.any,
    updateCalendarRestingTime: PropTypes.func,
    dark: PropTypes.bool
};

export default connect(null, { updateCalendarRestingTime })(RestingTime);

import React from 'react';
import PropTypes from 'prop-types';

function Spacer(props) {
    const { top, bottom } = props;
    return (
        <div
            className="atc-spacer"
            style={{ marginTop: `${top || 0}px`, marginBottom: `${bottom || 0}px` }}
        />
    );
}

Spacer.propTypes = {
    top: PropTypes.any,
    bottom: PropTypes.any
};

export default Spacer;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Row, Col, Select, Button } from 'antd';
import Prompter from '../../../atoms/prompter';
import Panel from '../../../atoms/panel';
import './index.scss';
import Spacer from '../../../atoms/spacer';
import MediaField from '../../../molecules/media-library/media-field/index';
import AtcTimePicker from '../../../atoms/time-picker';
// import LanguageOffered from '../organisms/views/syn';
import CurvedButton from '../../../organisms/views/curvedbutton';
import InclusionAndExclusion from '../../../pages/product-content/form/inclusion-and-exclusion.js';
import LanguageOffered from '../../../pages/product-content/form/language-offered';
import { withRouter } from 'react-router-dom';

import Feedback from '../../../molecules/user-feedback';
import InputBox from '../../../atoms/inputbox';

const { TextArea } = Input;
const { Option } = Select;

const days = ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7'];

// const locationOptions = [
//     { label: 'At the accomodation provided by you', value: 'Apple' },
//     { label: 'Allow travellers to enter their their own pick up location', value: 'Pear' },
//     { label: 'at a Starting Point', value: 'Orange' }
// ];

class ChooseChefServiceDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            activitySessionIsVisible: false,
            foodAndDrinksIsVisible: false,
            languageOfferedIsVisible: false,
            adventureIsVisible: false,
            inclusionAndexclusionIsVisible: false
        };
    }

    showModal(key) {
        this.setState({
            [key]: true
        });
    }

    closeModal(key) {
        this.setState({
            [key]: false
        });
    }

    handleOk() {
        // this.setState({ loading: true });
        // setTimeout(() => {
        this.setState({ visible: false });
        // }, 3000);
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    onRequiredTypeChange({ requiredMark }) {
        this.setRequiredMarkType(requiredMark);
    }

    setRequiredMarkType(requiredMark) {
        this.setState({
            requiredMark
        });
    }

    render() {
        const {
            // form,
            requiredMark,
            languageOfferedIsVisible,
            inclusionAndexclusionIsVisible
        } = this.state;

        return (
            <div className="ChooseChefServiceDetails-form">
                <Form
                    // form={form}
                    layout="vertical"
                    initialValues={{
                        requiredMark
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}>
                    <Row justify="center">
                        <Col span="22">
                            <h4 className="ChooseChefServiceDetails-form__title">
                                Add a Personal Chef Service
                            </h4>
                            <Prompter
                                textColor="#A2792C"
                                text="I will import Personal Chef Service information from my other Meals & Drinks "
                            />
                            <Spacer top={57} />
                            <Form.Item label="Add Personal Chef Service title" required>
                                <Input
                                    style={{
                                        border: '0.3px solid #A2792C',
                                        height: 40,
                                        borderRadius: 8
                                    }}
                                    placeholder="Kruger National Park Safari, South Africa"
                                />
                            </Form.Item>

                            <Form.Item
                                className="mt-57"
                                label="Describe what travelers will see and do here if they book your experience"
                                required>
                                <TextArea
                                    rows={5}
                                    placeholder="
                                    Enter a description about this product only.  For example, 'Our accredited guide will meet you at the ticket booth and will accompany you up the tower, explaining what you\'ll see at each level.'
                                    Description is required.
                                "
                                />
                            </Form.Item>

                            <h3 style={{ fontSize: '20px' }}>
                                Select the customers calendar days this booking apply
                            </h3>
                            <p style={{ fontSize: '15px' }}>
                                This product will be availlable for customers to book on the days
                                selected below.
                            </p>
                            <InputBox />
                            <div style={{ paddingTop: 15 }}>
                                {days.map((day, i) => (
                                    <CurvedButton
                                        key={i}
                                        pressedbutton="pressedbutton-acc"
                                        newbutton="newbutton-acc"
                                        name={day}
                                        onClick={() => {}}
                                    />
                                ))}
                            </div>
                        </Col>
                    </Row>

                    <Spacer top={36} />
                    <hr></hr>
                    <Spacer top={36} />
                    <Row justify="center">
                        <Col span="22">
                            <Spacer top={36} />
                            <h6>Add Photos & Videos</h6>
                            <p>
                                Use photos that highlight what’s unique about your experience and
                                show travelers having fun. Adding 6-9 photos and at least 1 video
                                can increase your bookings.
                            </p>
                            <Row gutter={12}>
                                <Col>
                                    <MediaField type="picture" />
                                </Col>
                                <Col>
                                    <MediaField type="video" />
                                </Col>
                            </Row>
                            <Spacer top={28} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span="22">
                            <Spacer top={36} />
                            <h6 className="mt-10px">
                                What time period in the day can this Personal Chef Service be
                                booked?
                            </h6>
                            <AtcTimePicker />
                            <AtcTimePicker />
                            <Prompter
                                textColor="#A2792C"
                                text="Add another time period in the day."
                            />
                            <Spacer top={28} />
                        </Col>
                    </Row>

                    <div className="ChooseChefServiceDetails-form__panels">
                        <hr></hr>
                        <Spacer top={36} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title="Language Offered"
                                    footer={
                                        <Prompter
                                            onClick={() =>
                                                this.showModal('languageOfferedIsVisible')
                                            }
                                            text="Add language offered"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Spacer top={17} />
                        <Row gutter={22} justify="center">
                            <Col span={22}>
                                <Panel
                                    title="Inclusions & Exclusions"
                                    footer={
                                        <Prompter
                                            onClick={() =>
                                                this.showModal('inclusionAndexclusionIsVisible')
                                            }
                                            text="Add inclusions and exclusions"
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Spacer top={36} />
                        <hr></hr>
                    </div>

                    <Spacer top={40} />

                    <div style={{ padding: '0px 42px' }}>
                        <p>
                            Enter the maximum number of spaces available to book this Personal Chef
                            Service for the time period.
                        </p>
                        <p>
                            This will indicate the spaces available to travelers and once this is
                            reached, this Personal Chef Service will be marked as sold out.
                        </p>
                        <Form.Item label="" required>
                            <Select placeholder="">
                                {[1, 2].map((item) => (
                                    <Option key={item}>{item}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>

                    <Spacer top={38} />
                    <hr></hr>
                    <Spacer top={36} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Pricing"
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add Pricing details for your ‘Product Title’ Market Guide"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Spacer top={17} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Booking and Tickets"
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add Booking and Ticketing details  for your ‘Product Title’ Market Guide"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Spacer top={17} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Information travellers need from you"
                                footer={
                                    <Prompter
                                        onClick={() => this.showModal('activitySessionIsVisible')}
                                        text="Add any physical restrictions for this Market Guide"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <Spacer top={17} />
                    <Row gutter={22} justify="center">
                        <Col span={22}>
                            <Panel
                                title="Covid-19 Updates"
                                footer={
                                    <Prompter
                                        onClick={() => this.props.history.push('/covid19')}
                                        text="Add Covid-19 safety updates"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                    <div className="ChooseChefServiceDetails__action">
                        <div>
                            <Button
                                style={{ width: 135, height: 42 }}
                                type="primary"
                                className="rounded-btn mr20">
                                Done
                            </Button>
                            <Button
                                style={{ width: 135, height: 42 }}
                                type="primary"
                                className="rounded-btn mr20">
                                Cancel
                            </Button>
                        </div>
                        <div style={{ float: 'right' }}>
                            <Feedback />
                        </div>
                    </div>
                </Form>
                <LanguageOffered
                    handleCancel={() => this.closeModal('languageOfferedIsVisible')}
                    isVisible={languageOfferedIsVisible}
                    handleOk={this.handleLanguageOfferedOk}
                />
                <InclusionAndExclusion
                    width={590}
                    handleCancel={() => this.closeModal('inclusionAndexclusionIsVisible')}
                    isVisible={inclusionAndexclusionIsVisible}
                />
            </div>
        );
    }
}

ChooseChefServiceDetails.propTypes = {
    history: PropTypes.object
};

export default withRouter(ChooseChefServiceDetails);

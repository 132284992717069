/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { SleepIcon, ExpandIcon } from 'components/atoms';
import Carousel from 'react-multi-carousel';
import { Popover } from 'antd';
import { ActivityPanelComponent } from 'components/molecules';
import PropTypes from 'prop-types';

const MasterEventContent = (props) => {
    const {
        sideDayArray,
        selectedDowsArray,
        currentWindowWidth,
        isDayActive,
        fixedWidthMainCalendarWrap,
        selectedEventData,
        onClickGhost,
        responsive
    } = props;
    return (
        <div className="master-event-content d-flex">
            <div className="selected-days-row mt-40px mr-3 d-none d-xl-block">
                <div className="day-title">MAR</div>
                <div className="selected-days-wrapper">
                    {/* <div className="selected-days-band" data-start="1" data-end="3"></div> */}
                    {sideDayArray.map((data, index) => (
                        <div
                            key={index}
                            className={
                                'selected-day ' +
                                (data == selectedDowsArray[0] ? 'start' : '') +
                                (data == selectedDowsArray[selectedDowsArray.length - 1]
                                    ? 'end'
                                    : '')
                            }>
                            {data}
                        </div>
                    ))}
                </div>
            </div>
            {currentWindowWidth >= 768
                ? fixedWidthMainCalendarWrap
                : !isDayActive
                ? fixedWidthMainCalendarWrap
                : null}
            {currentWindowWidth >= 768 || isDayActive ? (
                <div className="active-day-panel">
                    {currentWindowWidth < 768 ? (
                        <span
                            className="ghost-btn-icon"
                            role="button"
                            tabIndex="0"
                            onClick={onClickGhost}
                            style={{ position: 'absolute', left: 0 }}>
                            <svg
                                width="25"
                                height="25"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <circle cx="16" cy="16" r="16" fill="black" fillOpacity="0.7" />
                                <path
                                    d="M5.29289 15.2929C4.90237 15.6834 4.90237 16.3166 5.29289 16.7071L11.6569 23.0711C12.0474 23.4616 12.6805 23.4616 13.0711 23.0711C13.4616 22.6805 13.4616 22.0474 13.0711 21.6569L7.41421 16L13.0711 10.3431C13.4616 9.95262 13.4616 9.31945 13.0711 8.92893C12.6805 8.53841 12.0474 8.53841 11.6569 8.92893L5.29289 15.2929ZM26 15L6 15L6 17L26 17L26 15Z"
                                    fill="white"
                                />
                            </svg>
                        </span>
                    ) : null}
                    <div className="active-day-title-holder">
                        <div className="active-day-title">
                            Day {selectedEventData?.selectedDate} (All Day Events)
                        </div>
                    </div>
                    <ActivityPanelComponent
                        pannel={'stand-out'}
                        bookedEvents={['care']}
                        hasMainProduct={true}
                    />
                    <div className="active-day-schedule-wrapper _type-booking">
                        {/* Event Cards */}
                        <div>
                            {selectedEventData &&
                                selectedEventData?.events.length &&
                                selectedEventData?.events.map((event) =>
                                    event.extendedProps.event_type === 'main_event' ? (
                                        <div className="mb-3">
                                            <div className="text-right">
                                                <Popover
                                                    overlayClassName="popover-container popover-large popover-centered"
                                                    placement={
                                                        window.innerWidth >= 768
                                                            ? 'leftTop'
                                                            : 'bottom'
                                                    }
                                                    content={
                                                        <div>
                                                            <div className="popover-e2e mb-0">
                                                                <div className="border-bottom p-2">
                                                                    <div className="d-flex align-items-center px-3">
                                                                        <div className="flex-grow-1 mr-2">
                                                                            <div className="event-day-name text-uppercase">
                                                                                Day 1
                                                                            </div>
                                                                        </div>
                                                                        <div>
                                                                            <div className="fs-18 fw-500 ff-special text-booked text-uppercase">
                                                                                Booked
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="py-3">
                                                                <div className="d-flex flex-nowrap mx-n2 event-schedule __special">
                                                                    <div className="px-2 event-time-window-wrapper">
                                                                        <div className="event-time-window __all-day">
                                                                            <div className="event-all-day-time">
                                                                                All Day
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="event-info-wrapper">
                                                                            <div className="event-title">
                                                                                <span className="event-icon mr-2">
                                                                                    <SleepIcon />
                                                                                </span>
                                                                                burj al khalifa
                                                                            </div>
                                                                            <div className="event-info">
                                                                                Burj Khalifa is the
                                                                                tallest tower in the
                                                                                world and it's one
                                                                                of...{' '}
                                                                                <i
                                                                                    role="button"
                                                                                    tabIndex={0}>
                                                                                    see more
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <span
                                                                            className="text-white"
                                                                            role="button"
                                                                            tabIndex={0}>
                                                                            <ExpandIcon />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-nowrap mx-n2 event-schedule">
                                                                    <div className="px-2 event-time-window-wrapper">
                                                                        <div className="event-time-window __all-day">
                                                                            <div className="event-all-day-time">
                                                                                All Day
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="event-info-wrapper">
                                                                            <div className="event-title">
                                                                                <span className="event-icon mr-2">
                                                                                    <SleepIcon />
                                                                                </span>
                                                                                Hilton hotel &
                                                                                towers
                                                                            </div>
                                                                            <div className="event-info">
                                                                                Burj Khalifa is the
                                                                                tallest tower in the
                                                                                world and it's one
                                                                                of...{' '}
                                                                                <i
                                                                                    role="button"
                                                                                    tabIndex={0}>
                                                                                    see more
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <span
                                                                            className="text-white"
                                                                            role="button"
                                                                            tabIndex={0}>
                                                                            <ExpandIcon />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex flex-nowrap mx-n2 event-schedule">
                                                                    <div className="px-2 event-time-window-wrapper">
                                                                        <div className="event-time-window __all-day">
                                                                            <div className="event-all-day-time">
                                                                                All Day
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2 flex-grow-1">
                                                                        <div className="event-info-wrapper">
                                                                            <div className="event-title">
                                                                                <span className="event-icon mr-2">
                                                                                    <SleepIcon />
                                                                                </span>
                                                                                john jill air b&b
                                                                            </div>
                                                                            <div className="event-info">
                                                                                Burj Khalifa is the
                                                                                tallest tower in the
                                                                                world and it's one
                                                                                of...{' '}
                                                                                <i
                                                                                    role="button"
                                                                                    tabIndex={0}>
                                                                                    see more
                                                                                </i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="px-2">
                                                                        <span
                                                                            className="text-white"
                                                                            role="button"
                                                                            tabIndex={0}>
                                                                            <ExpandIcon />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }>
                                                    <span
                                                        role="button"
                                                        tabIndex="0"
                                                        className="see-more-products-btn">
                                                        <span className="mr-2">
                                                            See more Products
                                                        </span>
                                                        <svg
                                                            width="12"
                                                            height="12"
                                                            viewBox="0 0 12 12"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect
                                                                x="5"
                                                                width="2"
                                                                height="12"
                                                                fill="currentColor"
                                                            />
                                                            <rect
                                                                x="12"
                                                                y="5"
                                                                width="2"
                                                                height="12"
                                                                transform="rotate(90 12 5)"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </span>
                                                </Popover>
                                            </div>
                                            <div
                                                className="event-card"
                                                style={{
                                                    maxHeight: 'unset'
                                                }}>
                                                <div className="event-card-header">
                                                    <div className="event-card-title">
                                                        <span className="event-card-header-icon mr-2">
                                                            <svg
                                                                width="21"
                                                                height="15"
                                                                viewBox="0 0 21 15"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path
                                                                    d="M17.1818 2.28018H9.54545V8.962H1.90909V0.371094H0V14.6893H1.90909V11.8256H19.0909V14.6893H21V6.09837C21 5.08572 20.5977 4.11455 19.8817 3.3985C19.1656 2.68246 18.1945 2.28018 17.1818 2.28018ZM5.72727 8.00746C6.48676 8.00746 7.21513 7.70575 7.75217 7.16872C8.28921 6.63168 8.59091 5.9033 8.59091 5.14382C8.59091 4.38434 8.28921 3.65596 7.75217 3.11892C7.21513 2.58189 6.48676 2.28018 5.72727 2.28018C4.96779 2.28018 4.23941 2.58189 3.70238 3.11892C3.16534 3.65596 2.86364 4.38434 2.86364 5.14382C2.86364 5.9033 3.16534 6.63168 3.70238 7.16872C4.23941 7.70575 4.96779 8.00746 5.72727 8.00746Z"
                                                                    fill="currentColor"
                                                                />
                                                            </svg>
                                                        </span>
                                                        {event.title}
                                                    </div>
                                                    <div className="event-card-header-action">
                                                        <span
                                                            role="button"
                                                            tabIndex="0"
                                                            title="View Event">
                                                            <ExpandIcon />
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Event image gallery */}
                                                <div className="event-gallery-wrapper">
                                                    {event.extendedProps.imageurl && (
                                                        <div className="event-gallery-item">
                                                            <img
                                                                className="event-gallery-img"
                                                                src={event.extendedProps.imageurl}
                                                                alt=""
                                                            />
                                                        </div>
                                                    )}

                                                    {event.extendedProps.imageGallery &&
                                                        event.extendedProps.imageGallery.length && (
                                                            <Carousel
                                                                swipeable={true}
                                                                draggable={false}
                                                                showDots={false}
                                                                arrows={true}
                                                                responsive={responsive.eventGallery}
                                                                infinite={true}
                                                                autoPlay={false}
                                                                autoPlaySpeed={3000}
                                                                keyBoardControl={true}
                                                                containerClass="event-gallery-carousel">
                                                                <div className="event-gallery-item">
                                                                    <img
                                                                        className="event-gallery-img"
                                                                        src={
                                                                            event.extendedProps
                                                                                .imageGallery[0]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="event-gallery-item">
                                                                    <img
                                                                        className="event-gallery-img"
                                                                        src={
                                                                            event.extendedProps
                                                                                .imageGallery[0]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="event-gallery-item">
                                                                    <img
                                                                        className="event-gallery-img"
                                                                        src={
                                                                            event.extendedProps
                                                                                .imageGallery[0]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                                <div className="event-gallery-item">
                                                                    <img
                                                                        className="event-gallery-img"
                                                                        src={
                                                                            event.extendedProps
                                                                                .imageGallery[0]
                                                                        }
                                                                        alt=""
                                                                    />
                                                                </div>
                                                            </Carousel>
                                                        )}
                                                </div>
                                                <div className="event-card-info">
                                                    Burj Khalifa is the tallest tower in the world
                                                    and it's one of the top attractions to visit
                                                    in...
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <></>
                                    )
                                )}
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};
MasterEventContent.propTypes = {
    sideDayArray: PropTypes.array,
    selectedDowsArray: PropTypes.array,
    currentWindowWidth: PropTypes.number,
    isDayActive: PropTypes.bool,
    fixedWidthMainCalendarWrap: PropTypes.node,
    selectedEventData: PropTypes.any,
    onClickGhost: PropTypes.func,
    responsive: PropTypes.object
};
export default MasterEventContent;

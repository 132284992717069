import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { ClashIcon } from 'components/atoms';
import ProductItem from '../more-products/product-item';
import { Popover } from 'antd';
import { getMoment } from 'utils';
import { RestingTime } from 'components/atoms';
import './index.scss';

const ClashingEvent = ({
    eventInfo,
    setCurrentProduct,
    setAddProductsProductTypeModal,
    setAddProductInitModalData
}) => {
    const popoverRef = useRef(null);
    const moreProducts = eventInfo?.event?.extendedProps?.moreProducts;
    if (!moreProducts || !moreProducts.length > 0) {
        return <></>;
    }

    let sorted = [...moreProducts].reverse();

    sorted.sort((a, b) => {
        let aTime = getMoment(a).start;
        let bTime = getMoment(b).start;

        if (aTime.isBefore(bTime)) {
            return -1;
        } else {
            return 1;
        }
    });

    return (
        <div className="timeline-clashing-event with-bullets ">
            <div className="timeline-icon bg-transparent">
                <div className="clashing-icon">
                    <Popover
                        ref={popoverRef}
                        overlayClassName="popover-container popover-large popover-centered"
                        placement={window.innerWidth >= 768 ? 'left' : 'bottom'}
                        content={
                            <div>
                                {sorted.map((product, index) =>
                                    product.event_type === 'main_event' ? (
                                        <ProductItem
                                            popoverRef={popoverRef}
                                            product={product.extendedProps}
                                            setCurrentProduct={setCurrentProduct}
                                            key={index}
                                        />
                                    ) : product.event_type === 'free_time' ? (
                                        <FreeTime
                                            onClick={() => {
                                                popoverRef?.current?.close &&
                                                    popoverRef.current.close();
                                                setAddProductInitModalData({
                                                    name: 'Products available within the day',
                                                    value: 'within_day'
                                                });
                                                setAddProductsProductTypeModal(true);
                                            }}
                                            product={product}
                                            key={index}
                                        />
                                    ) : product.event_type === 'resting_time' ? (
                                        <RestingTimeWrapper key={index} product={product}>
                                            <RestingTime dark />
                                        </RestingTimeWrapper>
                                    ) : (
                                        <div key={index}></div>
                                    )
                                )}
                            </div>
                        }>
                        <div role="button" tabIndex="0">
                            <ClashIcon primary />
                        </div>
                    </Popover>
                </div>
            </div>
        </div>
    );
};

ClashingEvent.propTypes = {
    eventInfo: PropTypes.any,
    otherProps: PropTypes.any,
    setAddProductsProductTypeModal: PropTypes.any,
    setAddProductInitModalData: PropTypes.any,
    setCurrentProduct: PropTypes.any,
    children: PropTypes.any,
    product: PropTypes.any
};

export default ClashingEvent;

const FreeTime = ({ product, onClick }) => {
    const durationToDisplay =
        product?.extendedProps?.duration && product?.extendedProps?.duration.split('in ')[1];

    return (
        <div className={`d-flex flex-nowrap mx-n2 event-schedule `}>
            <div className="px-2 event-time-window-wrapper">
                <div className="event-time-window">
                    <div className="event-time-icon-wrapper">
                        <svg
                            width="43"
                            height="43"
                            viewBox="0 0 43 43"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="21.2911"
                                cy="21.5118"
                                r="20.2916"
                                stroke="#FF5260"
                                strokeWidth="2"
                            />
                            <circle
                                cx="21.2913"
                                cy="21.5116"
                                r="17.743"
                                fill="black"
                                fillOpacity="0.5"
                            />
                            <path
                                d="M21.2911 9.68311C14.7687 9.68311 9.4624 14.9894 9.4624 21.5118C9.4624 28.0341 14.7687 33.3404 21.2911 33.3404C27.8134 33.3404 33.1197 28.0341 33.1197 21.5118C33.1197 14.9894 27.8134 9.68311 21.2911 9.68311ZM25.1862 27.0795L20.1082 22.0015V14.4146H22.4739V21.0221L26.8588 25.407L25.1862 27.0795Z"
                                fill="#FF5260"
                            />
                        </svg>
                    </div>
                </div>
            </div>
            <div className="px-2 flex-grow-1">
                <div className="event-info-wrapper">
                    <div className="timeline-info flex-wrap">
                        <div className="timeline-duration">
                            <span>
                                +{durationToDisplay === 'an hour' ? '1 hour' : durationToDisplay}
                            </span>
                        </div>
                        <div className="timeline-title text-reset">
                            <span>Free Time</span>
                        </div>
                        <div className="timeline-action w-100">
                            <button className="timeline-action-btn tinted" onClick={onClick}>
                                <i>Add Product</i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
FreeTime.propTypes = {
    product: PropTypes.any,
    onClick: PropTypes.func,
    index: PropTypes.number
};

const RestingTimeWrapper = ({ children, product }) => {
    let time = getMoment(product);
    return (
        <div className={`d-flex flex-nowrap mx-n2 event-schedule `}>
            <div className="px-2 event-time-window-wrapper">
                <div className="event-time-window">
                    <div className="event-start-time">
                        {time?.start && time.start.format('HH:mm')}
                    </div>
                    <div className="event-end-time">{time?.end && time.end.format('HH:mm')}</div>
                </div>
            </div>
            <div className="px-2 flex-grow-1">
                <div className="event-info-wrapper ml--15">{children}</div>
            </div>
        </div>
    );
};

RestingTimeWrapper.propTypes = {
    children: PropTypes.any,
    product: PropTypes.any
};

/* eslint-disable react/display-name */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Button, Menu, Dropdown, Tooltip } from 'antd';
import PlusIcon from 'components/atoms/icons/plusIcon';
import MoreInfo from 'assets/images/more_info.svg';
import { MoreOutlined } from '@ant-design/icons';
import { StarRating } from 'components/atoms';
import { stringSplitAndUppercase } from 'utils';

export function tableColumns({ history, onMenuClick, onClickAction }) {
    return [
        {
            title: 'Thumbnail',
            dataIndex: 'calendarThumbnail',
            key: 'calendarThumbnail',
            width: 130,
            render: (_text, record) => {
                const product = record.products;
                let image;
                const modProduct = product
                    .filter(
                        (product) =>
                            product.productType === 'main_product' && product.photos_and_videos
                    )
                    .map((productItem) => {
                        if (productItem.photos_and_videos) {
                            image =
                                productItem.photos_and_videos[0].type === 'image'
                                    ? productItem.photos_and_videos[0].url
                                    : null;
                        }
                    });
                return (
                    // <CalendarThumbnail calendar={record} />
                    <div className={`calendar-thumbnail-wrap ${!image ? '__no-image' : ''}`}>
                        <img
                            src={
                                modProduct.lenght !== 0 && image
                                    ? image
                                    : require(`assets/images/no-image.svg`)
                            }
                            alt=""
                        />
                    </div>
                );
            }
        },
        {
            title: 'Calendar Title',
            dataIndex: 'title',
            key: 'title',
            width: 155,
            render: (_text, record) => {
                return (
                    <div className="text-truncate" title={record?.title} style={{ maxWidth: 140 }}>
                        {record.title}
                    </div>
                );
            }
        },
        {
            title: 'Calendar ID',
            dataIndex: 'id',
            key: 'id',
            width: 115
        },
        {
            title: 'Star Rating',
            dataIndex: 'starRating',
            key: 'starRating',
            width: 160,
            render: (_text, record) => {
                return (
                    <div>
                        <div className="d-inline-flex align-items-center text-nowrap mb-2">
                            {/* <img
                                src={require(`assets/images/star-rating/secondary/rating-04.svg`)}
                                alt=""
                            /> */}
                            <StarRating
                                value={record?.rating?.stars || 1}
                                iconHeight={16}
                                iconWidth={16}
                            />
                            <span className="fs-12 text-gray ml-2">
                                {record?.rating?.stars || 1}
                            </span>
                        </div>
                        <span
                            role="button"
                            className="text-nowrap fs-12 ff-special text-primary fw-400"
                            onClick={() =>
                                history.push(`/calendar/${record?.id}?increaseRating=true`)
                            }>
                            Increase Rating <PlusIcon />
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'Calendar Status',
            dataIndex: 'calendarStatus',
            key: 'calendarStatus',
            width: 150,
            render: (_text, record) => {
                return (
                    <div className="fs-14 text-nowrap">
                        <span
                            className={`calendar_activity-status __${record.calendar_status
                                .toLowerCase()
                                .replace(/ /g, '_')}`}></span>
                        <span className="fs-14 text-gray">
                            {stringSplitAndUppercase(record.calendar_status)}
                        </span>
                    </div>
                );
            }
        },
        {
            title: 'Customer Status',
            dataIndex: 'customerStatus',
            key: 'customerStatus',
            width: 160,
            render: (_text, record) => {
                return (
                    <div className="fs-14 text-nowrap">
                        <span
                            className={`calendar_activity-status __${record.customer_status
                                .toLowerCase()
                                .replace(/-/g, '_')}`}></span>
                        <span className="fs-14 text-gray">
                            {stringSplitAndUppercase(record.customer_status)}
                        </span>
                        <sup className="ml-1">
                            <Tooltip
                                title={
                                    <div className="text-center">
                                        <div className="fw-600">Last in use date</div>
                                        <div className="fw-600 text-secondary">April 2, 2021</div>
                                    </div>
                                }>
                                <img src={MoreInfo} alt="More info" />
                            </Tooltip>
                        </sup>
                    </div>
                );
            }
        },
        {
            title: 'No. of Customers',
            dataIndex: 'totalCustomerCount',
            key: 'totalCustomerCount',
            width: 155,
            render: (_text, record) => {
                return <div>{record.totalCustomerCount || 0}</div>;
            }
        },
        {
            title: 'No. of Products',
            dataIndex: 'totalProductCount',
            key: 'totalProductCount',
            width: 155
        },
        {
            title: ' ',
            dataIndex: 'calendarNextAction',
            key: 'calendarNextAction',
            width: 160,
            className: 'table-next-action',
            render: (_text, record) => {
                return (
                    <div className="text-center">
                        <Button
                            className="text-uppercase"
                            type="primary"
                            size="small"
                            onClick={() => history.push(`/calendar/${record.id}`)}>
                            {record.calendar_status === 'live' ? 'Update' : 'Finish Creating'}
                        </Button>
                    </div>
                );
            },
            fixed: window.innerWidth >= 992 ? 'right' : ''
        },
        {
            title: 'Actions',
            dataIndex: 'calendarAction',
            key: 'calendarAction',
            width: 85,
            render: (_text, record) => {
                const { calendar_status, id } = record;
                return (
                    <div className="text-center">
                        <Dropdown
                            overlay={() =>
                                calendarActionmenu({
                                    calendar_status,
                                    onMenuClick,
                                    id,
                                    onClickAction
                                })
                            }
                            trigger={['click']}>
                            <span role="button" tabIndex="0">
                                <MoreOutlined rotate={90} style={{ fontSize: '30px' }} />
                            </span>
                        </Dropdown>
                    </div>
                );
            },
            fixed: window.innerWidth >= 992 ? 'right' : ''
        }
    ];
}

function calendarActionmenu({ calendar_status, onMenuClick, id, onClickAction }) {
    return (
        <Menu
            onClick={({ key }) => {
                onMenuClick(key);
                // onClickAction({ id, status: 'draft' });
            }}
            className="dropdown-action-menu">
            {/* <Menu.Item
                onClick={() => onClickAction({ id, status: 'delete' })}
                disabled={checkActionDisablity({ calendar_status, type: 'DELETE' })}
                key="0">
                Delete
            </Menu.Item> */}
            <Menu.Item
                onClick={() => onClickAction({ id, status: 'archive' })}
                disabled={checkActionDisablity({ calendar_status, type: 'ARCHIVE' })}
                key="1">
                Archive
            </Menu.Item>
            <Menu.Item
                onClick={() => onClickAction({ id, status: 'draft' })}
                disabled={checkActionDisablity({ calendar_status, type: 'DRAFT' })}
                key="2">
                Disable Access
            </Menu.Item>
        </Menu>
    );
}

function checkActionDisablity({ calendar_status, type }) {
    if (type === 'DELETE') {
        if (calendar_status === 'live') return true;
    } else if (type === 'ARCHIVE') {
        if (calendar_status === 'live') return true;
    } else if (type === 'DRAFT') {
        if (calendar_status === 'draft') return true;
    }

    return false;
}

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button, Row } from 'reactstrap';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import { MoreInfoTooltip } from 'components/atoms';
import Proptypes from 'prop-types';

export default function AddButton(props) {
    const { add = 'Add', moreInfo = true, infoText = '' } = props;

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <MoreInfoTooltip title={infoText} /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data && props.data.length > 0 ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        {/* <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={props.onDelete}
                            className="itinerary-img cursor-pointer"
                        /> */}
                    </div>
                ) : (
                    <Button color="link" onClick={props.onClick}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                )}
            </div>
            {props.data && props.data.length > 0 ? (
                <Row className="actions" style={{ padding: '0 0 10px 15px' }}>
                    <div>
                        {props.data.map((res, i) =>
                            i === props.data.length - 1 ? res : `${res}, `
                        )}
                    </div>
                </Row>
            ) : null}
        </div>
    );
}
AddButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.array,
    infoText: Proptypes.string
};

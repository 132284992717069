import React, { Component } from 'react';
import { Form, Drawer, Modal } from 'antd';
import { Button } from 'reactstrap';
import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import HelpIcon from '../../../../assets/images/helpIcon.svg';
import Spacer from '../../../atoms/spacer';
import AddButton from '../../../atoms/add-with-category';
import WarningIcon from '../../../../assets/images/warning-icon.svg';
import ImportItineraries from '../../../atoms/importItineraries';
import Proptypes from 'prop-types';
import { getInclusionCategories } from 'store/actions/calendarActions';
import ConfirmationModal from '../../modals/confirmation-modal';
import { compareInclusionExclusion } from 'utils';

const { confirm } = Modal;

class InclusionAndExclusion extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            isAddIncluded: false,
            isAddExcluded: false,
            onDelete: {},
            included: [],
            excluded: [],
            confirmationModal: false
        };
    }
    componentDidUpdate(prevProps) {
        const { data } = this.props;
        if (prevProps.data !== data) {
            this.setState({ included: data[0], excluded: data[1] });
            if (this.formRef.current)
                this.formRef.current.setFieldsValue({ included: data[0], excluded: data[1] });
        }
    }

    componentDidMount() {
        const { getInclusionCategories } = this.props;
        getInclusionCategories();
    }

    setIsAddIncluded = () => this.setState({ isAddIncluded: !this.state.isAddIncluded });
    setIsAddExcluded = () => this.setState({ isAddExcluded: !this.state.isAddExcluded });
    //set included data
    setIncluded = (item) => {
        this.setState(
            {
                included: [...this.state.included, item],
                isAddIncluded: !this.state.isAddIncluded
            },
            () => this.validateField({ name: 'included', value: this.state.included })
        );
    };

    validateField = ({ name, value }) => {
        this.formRef.current.setFieldsValue({ [name]: value });
        this.formRef.current.validateFields();
    };
    //set excluded data
    setExcluded = (item) => {
        this.setState(
            {
                excluded: [...this.state.excluded, item],
                isAddExcluded: !this.state.isAddExcluded
            },
            () => this.validateField({ name: 'excluded', value: this.state.excluded })
        );
    };
    //remove included excluded data
    deleteIncludedExcluded = () => {
        const { included, excluded, onDelete } = this.state;

        if (onDelete.type === 'included') {
            let tmp = included;
            tmp.splice(onDelete.index, 1);
            this.setState({ included: tmp });
        } else {
            let tmp = excluded;
            tmp.splice(onDelete.index, 1);
            this.setState({ excluded: tmp });
        }
    };
    //remove item with accept
    deleteItem = (index, type) => {
        this.setState(
            {
                onDelete: {
                    index: index,
                    type: type
                }
            },
            () => this.validateField({ name: type, value: this.state[type] })
        );
        this.showPropsConfirm();
    };
    //confirmation window for removing
    showPropsConfirm = () => {
        confirm({
            title: 'Are you sure?',
            icon: <img src={WarningIcon} alt="" />,
            content: 'Are you sure you want to remove it?',
            okText: 'YES',
            okType: 'primary',
            cancelText: 'NO',
            maskStyle: { display: 'none' },
            centered: true,
            width: '910px',
            onOk: () => {
                this.deleteIncludedExcluded();
                return new Promise((resolve) => {
                    resolve(true);
                }).catch();
            },
            onCancel() {
                return false;
            }
        });
    };
    //set editing included
    onEditIncluded = (value, index) => {
        let tmp = [...this.state.included];
        tmp.splice(index, 1, value);
        this.setState({ included: tmp, isAddIncluded: !this.state.isAddIncluded });
    };
    //set editing excluded
    onEditExcluded = (value, index) => {
        let tmp = [...this.state.excluded];
        tmp.splice(index, 1, value);
        this.setState({ excluded: tmp, isAddExcluded: !this.state.isAddExcluded });
    };

    onFinish = () => {
        const { selected_existing_product } = this.props;
        if (selected_existing_product) {
            this.compareData();
        } else this.onSave();
    };

    onSave = () => {
        const { included, excluded } = this.state;
        const save = {
            inclusions: included,
            exclusions: excluded
        };
        this.props.onSave(save);
    };

    compareData = () => {
        const oldData = this.props?.data ? [...this.props.data] : [];
        const { included, excluded } = this.state;
        const newData = [included, excluded];

        if (compareInclusionExclusion(oldData, newData)) {
            this.setState({ confirmationModal: true });
        } else {
            this.props.onClose();
            //this.onSave();
        }
    };

    render() {
        const { requiredMark, isAddIncluded, isAddExcluded, included, excluded } = this.state;
        const { inclusionCategories } = this.props;

        return (
            // <div className="itenary-form">
            <Drawer
                className="application inclusion-exclusion"
                onClose={() => {
                    this.props.onClose();
                }}
                visible={this.props.showModal}>
                <Form
                    className="h-100 d-flex flex-column overflow-y-auto"
                    ref={this.formRef}
                    onFinish={this.onFinish}
                    layout="vertical"
                    initialValues={{
                        ...this.state
                    }}
                    onValuesChange={this.onRequiredTypeChange}
                    requiredMark={requiredMark}>
                    <div className="flex-grow-1 overflow-y-auto pt-3 container-fluid">
                        <div className="header-title">Add inclusion & Exclusion</div>
                        {this.props.hideImport ? (
                            ''
                        ) : (
                            <ImportItineraries
                                calendars={this.props.itineraries}
                                import="I will import inclusions & exclusions information from my other activity"
                                onMainProduct={(item) =>
                                    this.setState({
                                        included: item.inclusions,
                                        excluded: item.exclusions
                                    })
                                }
                            />
                        )}

                        <Spacer top={25} />
                        <p className="description">
                            Let travelers know what is and what isn’t provided to help them
                            understand what they’re paying for.
                        </p>

                        <Form.Item
                            name="included"
                            rules={[
                                {
                                    required:
                                        (Array.isArray(included) && included.length > 0) ||
                                        (Array.isArray(excluded) && excluded.length > 0)
                                            ? false
                                            : true,
                                    message: 'Please fill this field in'
                                }
                            ]}>
                            <div>
                                <AddButton
                                    name="What Included"
                                    isAdd={isAddIncluded}
                                    categories={inclusionCategories}
                                    value={included}
                                    onAdd={(value) => this.setIncluded(value)}
                                    onEdit={(value, index) => this.onEditIncluded(value, index)}
                                    onDelete={(index) => this.deleteItem(index, 'included')}
                                    onClickAdd={this.setIsAddIncluded}
                                    onCancel={this.setIsAddIncluded}
                                />
                            </div>
                        </Form.Item>

                        <Form.Item
                            name="excluded"
                            rules={[
                                {
                                    required:
                                        (Array.isArray(included) && included.length > 0) ||
                                        (Array.isArray(excluded) && excluded.length > 0)
                                            ? false
                                            : true,
                                    message: 'Please fill this field in'
                                }
                            ]}>
                            <div>
                                <AddButton
                                    name="What Excluded"
                                    isAdd={isAddExcluded}
                                    categories={inclusionCategories}
                                    value={excluded}
                                    onAdd={(value) => this.setExcluded(value)}
                                    onEdit={(value, index) => this.onEditExcluded(value, index)}
                                    onDelete={(index) => this.deleteItem(index, 'excluded')}
                                    onClickAdd={this.setIsAddExcluded}
                                    onCancel={this.setIsAddExcluded}
                                />
                            </div>
                        </Form.Item>
                    </div>
                    <div className="footer-button">
                        <Button color="primary" type="submit">
                            Save
                        </Button>
                        <Button color="dark">
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </Form>

                <ConfirmationModal
                    isModalOpen={this.state.confirmationModal}
                    setModalOpenState={(value) =>
                        this.setState({
                            confirmationModal: value
                        })
                    }
                    description="This update will be reflected on all calendars that this product is associated with. This will not be updated for customers who has booked this product."
                    handleConfirmation={() => {
                        this.setState({ confirmationModal: false });
                        this.onSave();
                    }}
                />
            </Drawer>
            // </div>
        );
    }
}
InclusionAndExclusion.propTypes = {
    data: Proptypes.array,
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    showModal: Proptypes.bool,
    itineraries: Proptypes.any,
    hideImport: Proptypes.bool,
    getInclusionCategories: Proptypes.func,
    inclusionCategories: Proptypes.array,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    inclusionCategories: state.calendar.inclusionCategories,
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = (dispatch) => ({
    getInclusionCategories: () => dispatch(getInclusionCategories())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(InclusionAndExclusion));

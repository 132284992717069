import { Avatar, List } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

export default function FullMessage({ img, date, message }) {
    return (
        <List.Item style={{ padding: '15px', border: 0, marginBottom: 60 }}>
            <List.Item.Meta
                avatar={<Avatar size="large" src={img} />}
                title={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <div>
                            <span style={{ fontSize: 16, fontWeight: 600 }}>Olawalle</span>
                            <span
                                className="fs14"
                                style={{
                                    color: 'rgba(79, 79, 79, 0.63)',
                                    fontWeight: 300,
                                    paddingLeft: 12
                                }}>
                                {date}
                            </span>
                        </div>
                    </div>
                }
                description={
                    <p
                        style={{
                            marginBottom: 0
                        }}>
                        {message}
                    </p>
                }
            />
        </List.Item>
    );
}

FullMessage.propTypes = {
    img: PropTypes.any,
    date: PropTypes.string,
    message: PropTypes.string
};

/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Prompter from '../../../../atoms/prompter';
import { Modal, Button, Input, Form, Row, Col } from 'antd';
import ItenaryCalenderSession from '../itenary-calender-session';
// import DownArrow from '../../../../assets/images/downArrow.svg'
import './index.scss';
import RangePicker from '../../../../atoms/activity-range-picker';
// const { RangePicker } = DatePicker;

import { QuestionCircleOutlined } from '@ant-design/icons';

const ActivitySession = (props) => {
    const { width, handleOk, isVisible, handleCancel } = props;

    const [state, setState] = useState({
        bestTimeToVisit: [
            {
                from: null,
                to: null,
                reason: null
            }
        ],
        highSeasonToVisit: [
            {
                from: null,
                to: null,
                reason: null
            }
        ],
        bestWeatherToVisit: [
            {
                from: null,
                to: null,
                reason: null
            }
        ]
    });

    const addRow = (key) => {
        let newState = { ...state };

        newState[key].push({
            from: null,
            to: null,
            reason: null
        });

        setState(newState);
    };

    // const handleOk = () => {

    // }

    // const handleCancel = () => {

    // }

    return (
        <Modal
            centered
            visible={isVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={width || 592}
            footer={[
                <div
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: 50 }}
                    className="buttons">
                    <div>
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="primary"
                            className="rounded-btn mr20"
                            onClick={() => handleOk({})}>
                            Save
                        </Button>
                        <Button
                            style={{ width: 135, height: 42 }}
                            type="outline"
                            className="rounded-btn mr20"
                            onClick={handleCancel}>
                            Cancel
                        </Button>
                    </div>
                    <Button
                        type="primary"
                        className="rounded-btn"
                        style={{ float: 'right', height: 42 }}>
                        <QuestionCircleOutlined /> Help
                    </Button>
                </div>
            ]}>
            <h4>Add Itinerary Calendar Season</h4>
            <Prompter
                text="I will import itinerary calendar season information from my other activity "
                textColor="#A2792C"
            />

            <Form
                // form={form}
                layout="vertical">
                <Row gutter={10}>
                    <Col span={16}>
                        <Row className="entry-row">
                            <Col span={6}>
                                <h6>Best Time to visit</h6>
                                <p>
                                    Best time to visit is the time this activity can be enjoyed the
                                    most
                                </p>
                            </Col>
                            <Col style={{ paddingLeft: 20 }} span={18}>
                                <div className="time_wrapper">
                                    {state.bestTimeToVisit.map(() => (
                                        <Row style={{ marginBottom: 15 }}>
                                            <Col span={10}>
                                                <h6>Time Of Year</h6>
                                                <RangePicker />
                                            </Col>
                                            <Col span={14}>
                                                <h6>Reason</h6>
                                                <Input
                                                    placeholder="e.g. Dry season"
                                                    style={{
                                                        border: '0.3px solid #a2792c'
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                <Row>
                                    <Col>
                                        <Prompter
                                            onClick={() => addRow('bestTimeToVisit')}
                                            text="Add another Best Time to visit"
                                            textColor="#A2792C"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="entry-row">
                            <Col span={6}>
                                <h6>High Season to visit</h6>
                                <p>
                                    High Season to visit is this activity’s peak period for
                                    visitors.
                                </p>
                            </Col>
                            <Col style={{ paddingLeft: 20 }} span="18">
                                <div className="time_wrapper">
                                    {state.highSeasonToVisit.map(() => (
                                        <Row style={{ marginBottom: 15 }}>
                                            <Col span={10}>
                                                <h6>Time Of Year</h6>
                                                <RangePicker />
                                            </Col>
                                            <Col span={14}>
                                                <h6>Reason</h6>
                                                <Input
                                                    placeholder="e.g. Dry season"
                                                    style={{
                                                        border: '0.3px solid #a2792c'
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </div>

                                <Row>
                                    <Col>
                                        {/* <Spacer top={10} /> */}
                                        <Prompter
                                            onClick={() => addRow('highSeasonToVisit')}
                                            text="Add another High Season to visit"
                                            textColor="#A2792C"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="entry-row">
                            <Col span={6}>
                                <h6>Best Weather to visit</h6>
                                <p>Best Weather to visit for this activity</p>
                            </Col>
                            <Col style={{ paddingLeft: 20 }} span="18">
                                {state.bestWeatherToVisit.map(() => (
                                    <Row style={{ marginBottom: 15 }}>
                                        <Col span={10}>
                                            <h6>Time Of Year</h6>
                                            <RangePicker />
                                        </Col>
                                        <Col span={14}>
                                            <h6>Reason</h6>
                                            <Input
                                                placeholder="e.g. Dry season"
                                                style={{
                                                    border: '0.3px solid #a2792c'
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col>
                                        <Prompter
                                            onClick={() => addRow('bestWeatherToVisit')}
                                            text="Add another best weather to visit"
                                            textColor="#A2792C"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <ItenaryCalenderSession />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

ActivitySession.propTypes = {
    width: PropTypes.number.isRequired,
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired
};

export default ActivitySession;

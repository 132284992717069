import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { accountDelete } from 'store/actions/AuthActions';
import queryString from 'query-string';
import { Skeleton } from 'antd';

const DeleteAccount = (props) => {
    useEffect(() => {
        let { email, application_ref } = queryString.parse(props.location.search);
        props.accountDelete({ email, application_ref });
    }, [props]);
    return (
        <section className="static-section">
            <Container className="container-section">
                <h1 className="static-heading">Delete Account</h1>
                <div className="static-content-wrapper">
                    {props?.account_delete?.success && (
                        <Skeleton active loading={!props?.account_delete}>
                            Your account has been deleted successfully.
                        </Skeleton>
                    )}
                    {props?.account_delete?.success === false && (
                        <Skeleton active loading={!props?.account_delete}>
                            {props?.account_delete?.data}
                        </Skeleton>
                    )}
                </div>
            </Container>
        </section>
    );
};

const mapStateToProps = (state) => ({
    account_delete: state.auth.account_delete
});
const mapDispatch = (dispatch) => ({
    accountDelete: (data) => dispatch(accountDelete(data))
});
DeleteAccount.propTypes = {
    location: PropTypes.object,
    accountDelete: PropTypes.func,
    account_delete: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatch)(DeleteAccount));

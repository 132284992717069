import React from 'react';
import VendorDashboard from '../vendor-dashboard';
import Button from '../button';
import './index.css';
import { DatePicker } from 'antd';
import QuestionMark from '../../../../assets/images/mark.svg';
import Calendar from '../../../../assets/images/cal.svg';
import Feedback from '../../../molecules/user-feedback';

//Date changer function
const onChange = () => {};

export default function Publish() {
    return (
        <VendorDashboard>
            <div className="background-sz">
                <h6 style={{ fontSize: '26px' }}>Publish </h6>
                <div className="row">
                    <div className="col-md-6">
                        <p style={{ fontWeight: '500' }}>You have 15 avaiablable hours</p>
                        <p>Maximise your sales by adding more products</p>
                    </div>
                    <div className="col-md-6">
                        <button className="button-publish"> Add more products</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <p style={{ fontWeight: '500' }}>
                            You have 3 products availability not synced
                        </p>
                        <p>
                            Sync to avoid overbookings and operate effectively across multiple sites
                        </p>
                    </div>
                    <div className="col-md-6">
                        <button className="button-publish"> Sync Availabilty</button>
                    </div>
                </div>
                <div style={{ paddingTop: '30px' }}>
                    <p>When does your schedule start?</p>
                    <div className="d-flex">
                        <DatePicker
                            className="input-time"
                            suffixIcon={<img src={Calendar} alt="" />}
                            onChange={onChange}
                        />
                    </div>
                    <p style={{ color: '#A2792C', paddingTop: '10px' }}>
                        Give the schedule an end date{' '}
                        <span style={{ color: '#ccc' }}>(optional)</span>{' '}
                        <span>
                            <img src={QuestionMark} alt="" />
                        </span>
                    </p>
                    <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                        <Button float={'left'} text={'Update Calendar and Continue'} />
                        <div style={{ float: 'right' }}>
                            <Feedback />
                        </div>
                    </div>
                </div>
            </div>
        </VendorDashboard>
    );
}

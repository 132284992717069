import { Avatar, List } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

import star_icon from '../../../assets/images/star.svg';

export default function MessagePreview({ index, selected, isAdmin }) {
    return (
        <List.Item
            style={{
                padding: '15px',
                cursor: 'pointer',
                background: selected ? 'rgba(162, 121, 44, 0.13)' : '#fff',
                borderBottom: '1px solid rgba(151, 151, 151, 0.22)'
            }}>
            <List.Item.Meta
                avatar={
                    <Avatar
                        size="large"
                        src={`https://randomuser.me/api/portraits/men/${12 + index}.jpg`}
                    />
                }
                title={
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                        <div>Olawalle</div>
                        <div>
                            {isAdmin ? (
                                <span
                                    style={{
                                        background: index % 2 ? '#A2792C' : '#fff',
                                        border: '1px solid #A2792C',
                                        borderRadius: 20,
                                        color: index % 2 !== 1 ? '#A2792C' : '#fff',
                                        fontSize: 12,
                                        padding: '1px 5px'
                                    }}>
                                    open
                                </span>
                            ) : (
                                <span>1hr</span>
                            )}
                            <img src={star_icon} style={{ width: 15, marginLeft: 12 }} alt="" />
                            {isAdmin && (
                                <p
                                    className="m0 fs10"
                                    style={{ color: '#4F4F4F', fontWeight: 300 }}>
                                    2 months
                                </p>
                            )}
                        </div>
                    </div>
                }
                description={
                    <p
                        style={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            marginBottom: 0
                        }}>
                        Me: Ant Design, a design language for background
                    </p>
                }
            />
        </List.Item>
    );
}
MessagePreview.propTypes = {
    index: PropTypes.number,
    selected: PropTypes.bool,
    isAdmin: PropTypes.bool
};

import React from 'react';
// import Table from '@material-ui/core/Table';
// import TableBody from '@material-ui/core/TableBody';
// import TableHead from '@material-ui/core/TableHead';
import QuestionMark from '../../../../assets/images/question_mark.svg';
import GroupRow from '../group-row';
import './index.scss';
import { Col, Row } from 'reactstrap';
import Proptypes from 'prop-types';
import MoreInfoTooltip from 'components/atoms/more-info-tooltip';

export default function GroupAge(props) {
    const { form, ageGroups, setAgeGroups, prevAgeGroups } = props;
    return (
        <div aria-label="customized table" className="group-age">
            <Row>
                <Col sm={2} style={{ maxWidth: '14.66667%' }} />
                <Col sm={2} style={{ maxWidth: '12.6667%' }}>
                    Min age
                </Col>
                <Col sm={2} style={{ maxWidth: '11.66667%' }}>
                    Max age
                </Col>
                <Col sm={4} align="left">
                    Amount you will like to get
                    <MoreInfoTooltip
                        title="Amount you will get for the age group"
                        icon={<img alt="" src={QuestionMark} />}
                    />
                </Col>
                <Col sm={2} align="left" />
            </Row>
            {['Adult', 'Infant', 'Child', 'Youth', 'Senior'].map((each, index) => (
                <GroupRow
                    key={index}
                    groupName={each}
                    checkBoxData={props.checkBoxData}
                    data={props.data}
                    index={index}
                    form={form}
                    ageGroups={ageGroups}
                    setAgeGroups={setAgeGroups}
                    prevAgeGroups={prevAgeGroups}
                />
            ))}
        </div>
    );
}
GroupAge.propTypes = {
    data: Proptypes.object,
    checkBoxData: Proptypes.array,
    onSaveCheckBoxData: Proptypes.func,
    onEdit: Proptypes.string,
    onSave: Proptypes.func,
    form: Proptypes.any,
    ageGroups: Proptypes.any,
    setAgeGroups: Proptypes.any,
    prevAgeGroups: Proptypes.any
};

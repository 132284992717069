/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Button } from 'reactstrap';
import { MoreInfoTooltip } from 'components/atoms';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import { DAYS } from 'utils';
import './index.scss';
export default function AddPricingButton(props) {
    const { add = 'Add', moreInfo = true, product } = props;
    const pricings = product?.pricings;
    let pricingDays = [];

    if (pricings) {
        for (let p of pricings) {
            pricingDays = [...pricingDays, ...p.days];
        }
    }

    const validDays = Object.values(DAYS).filter((item) => pricingDays.indexOf(item) === -1);

    return (
        <div className="button-add add-pricing-button-info">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}

                        {moreInfo ? (
                            <MoreInfoTooltip
                                title={`This allows you to create/customize the price you would like customers to purchase your product`}
                            />
                        ) : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {validDays?.length > 0 ? (
                    <Button
                        color="link"
                        onClick={() => {
                            if (props.isAddDisabled) {
                                props.toggleShowValidation();
                            } else {
                                props.onEdit(null);
                                props.onClick();
                            }
                        }}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                ) : (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={() => {
                                props.onEdit(pricings[0]);
                                props.onClick();
                            }}
                        />
                    </div>
                )}
            </div>
            {pricings?.length > 0 && (
                <div className="pricings">
                    <div style={{ width: '100%' }}>
                        <div className="">
                            {pricings.map((item, i) => (
                                <div key={i} className="pricing-item">
                                    <div className="column" style={{ paddingLeft: 0 }} sm={2}>
                                        {item.days.map(
                                            (day, i) =>
                                                `${day}${i !== item.days.length - 1 ? ',' : ''} `
                                        )}

                                        {item.age_groups?.length > 0 && (
                                            <>
                                                <div className="mt-2">Age Groups</div>
                                                <div className="ml-2">
                                                    {item.age_groups.map((group, i) => (
                                                        <div key={i}>
                                                            {group?.type} ( {group?.min_age} -{' '}
                                                            {group?.max_age} ) ${group.amount}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>
                                        )}

                                        <div className="mt-2">
                                            Min Travellers - {item.min_travellers}
                                        </div>
                                        <div>Max Travellers - {item.max_travellers}</div>

                                        {item.min_accomodation ? (
                                            <>
                                                <div className="mt-2">
                                                    Min Accomodations - {item.min_accomodation}
                                                </div>
                                                <div>
                                                    Max Accomodations - {item.max_accomodation}
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}

                                        {product?.day_type === 'within_day' && (
                                            <>
                                                {item?.age_groups?.length > 0 && (
                                                    <div className="mt-2">
                                                        Accomodation is{' '}
                                                        {item.is_accomodation_included === false &&
                                                            'not'}{' '}
                                                        included
                                                    </div>
                                                )}

                                                {item.per_person_sharing ? (
                                                    <div className="mt-2">{`Per Person Sharing (Double) - $${
                                                        item?.per_person_sharing?.double_room
                                                            ?.amount
                                                    }${
                                                        item.per_person_sharing.double_room
                                                            .single_supplement
                                                            ? `. Single supplement - $${item.per_person_sharing.double_room.single_supplement}`
                                                            : ''
                                                    }`}</div>
                                                ) : null}
                                                {item.per_person_sharing ? (
                                                    <div>{`Per Person Sharing (Twin) - $${
                                                        item?.per_person_sharing?.twin_room?.amount
                                                    }${
                                                        item.per_person_sharing.double_room
                                                            .single_supplement
                                                            ? `. Single supplement - $${item.per_person_sharing.double_room.single_supplement}`
                                                            : ''
                                                    }`}</div>
                                                ) : null}
                                                {item.per_person_single ? (
                                                    <div className="mt-2">{`Per Person Single (Double) - $${item?.per_person_single?.double_room?.amount}`}</div>
                                                ) : null}
                                                {item.per_person_single ? (
                                                    <div>{`Per Person Single (Twin) - $${item?.per_person_single?.twin_room?.amount}`}</div>
                                                ) : null}
                                                {item.accomodation_group_prices ? (
                                                    <div className="mt-2">
                                                        Including Group Booking Price
                                                    </div>
                                                ) : null}
                                                {item.accomodation_peak_season_supplements ? (
                                                    <div className="mt-2">
                                                        Including Price for Peak/Festive Season
                                                        Supplement
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                    </div>

                                    <div className="row">
                                        <div className="group-btn" style={{ paddingRight: '20px' }}>
                                            <img
                                                src={Edit}
                                                alt=""
                                                title="Edit"
                                                style={{ paddingRight: '10px' }}
                                                className="itinerary-img cursor-pointer"
                                                onClick={() => {
                                                    props.onEdit(item);
                                                    props.onClick();
                                                }}
                                            />
                                            {pricings?.length > 1 && (
                                                <img
                                                    src={Delete}
                                                    alt=""
                                                    title="Delete"
                                                    onClick={() => {
                                                        props.onDelete(item.id);
                                                    }}
                                                    className="itinerary-img cursor-pointer"
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
AddPricingButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.array,
    product: Proptypes.any,
    isAddDisabled: Proptypes.bool,
    toggleShowValidation: Proptypes.func
};

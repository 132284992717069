export const getVendorRankFromStars = (stars) => {
    if (stars <= 1) {
        return 'UPCOMING';
    }

    if (stars <= 2) {
        return 'FAIR';
    }

    if (stars <= 3) {
        return 'GOOD';
    }

    if (stars <= 4) {
        return 'SUPERIOR';
    }

    return 'EXCEPTIONAL';
};

export const getCalendarStars = (score) => {
    if (score < 20) {
        return 1;
    }

    if (score < 40) {
        return 2;
    }

    if (score < 60) {
        return 3;
    }

    if (score < 80) {
        return 4;
    }

    if (score < 100) {
        return 5;
    }

    return 5;
};

export const getQualityOfInformationOfSingleCalendar = (allProducts) => {
    try {
        let totalRating = {
            description: 0,
            photos_and_videos: 0,
            customer_feedback: 0,
            type_of_adventure: 0,
            top_sights: 0,
            review_connections: 0,
            customer_requests: 0,
            covid_19_updates: 0,
            average: 0
        };
        if (!allProducts || allProducts?.length < 1)
            return {
                description: 0,
                photos_and_videos: 0,
                customer_feedback: 0,
                type_of_adventure: 0,
                top_sights: 0,
                review_connections: 0,
                customer_requests: 0,
                covid_19_updates: 0,
                average: 0
            };
        for (let product of allProducts) {
            totalRating.description += product?.rating?.description || 0;
            totalRating.photos_and_videos += product?.rating?.photos_and_videos || 0;
            totalRating.customer_feedback += product?.rating?.customer_feedback || 0;
            totalRating.type_of_adventure += product?.rating?.type_of_adventure || 0;
            totalRating.top_sights += product?.rating?.top_sights || 0;
            totalRating.review_connections += product?.rating?.review_connections || 0;
            totalRating.customer_requests += product?.rating?.customer_requests || 0;
            totalRating.covid_19_updates += product?.rating?.covid_19_updates || 0;
        }

        totalRating.description = totalRating.description / allProducts.length;
        totalRating.photos_and_videos = totalRating.photos_and_videos / allProducts.length;
        totalRating.customer_feedback = totalRating.customer_feedback / allProducts.length;
        totalRating.type_of_adventure = totalRating.type_of_adventure / allProducts.length;
        totalRating.top_sights = totalRating.top_sights / allProducts.length;
        totalRating.review_connections = totalRating.review_connections / allProducts.length;
        totalRating.customer_requests = totalRating.customer_requests / allProducts.length;
        totalRating.covid_19_updates = totalRating.covid_19_updates / allProducts.length;
        totalRating.average = (
            Number(
                totalRating.description +
                    totalRating.photos_and_videos +
                    totalRating.covid_19_updates +
                    totalRating.customer_feedback +
                    totalRating.type_of_adventure +
                    totalRating.top_sights +
                    totalRating.review_connections +
                    totalRating.customer_requests
            ) / 8
        ).toFixed(0);
        return totalRating;
    } catch (error) {
        console.log(error);
        return {
            rating: {
                description: 0,
                photos_and_videos: 0,
                customer_feedback: 0,
                type_of_adventure: 0,
                top_sights: 0,
                review_connections: 0,
                customer_requests: 0,
                covid_19_updates: 0,
                average: 0
            }
        };
    }
};

export const getTotalHoursOfSingleCalendar = (calendar) => {
    let hours = {
        total: 0,
        used: 0,
        available: 0
    };

    try {
        if (calendar?.data?.days) {
            for (let day of calendar.data.days) {
                for (let hour of day.hours) {
                    if (hour.status !== 'blocked') {
                        hours.total += 1;
                        if (hour.status === 'available') {
                            hours.available += 1;
                        } else {
                            hours.used += 1;
                        }
                    }
                }
            }
        }
        return hours;
    } catch (error) {
        console.log(error);
        return hours;
    }
};

export const getAllDayEvents = (products, selectedDate, calendar) => {
    try {
        const currentDay = selectedDate.split('- ')[1];
        const currentDayNo = Number(selectedDate.split(' ')[1]);

        let currentCalendarDay =
            calendar?.days?.length > 0 ? calendar.days[currentDayNo - 1] : null;

        let events = products
            ? products.filter(
                  (item) =>
                      item.day_type === 'all_day' &&
                      item?.calendar_days?.days?.length > 0 &&
                      item?.calendar_days?.days?.indexOf(currentDay) !== -1
              )
            : [];

        events.forEach(function (item, i) {
            if (item?.id === currentCalendarDay?.selected_all_day_product) {
                events.splice(i, 1);
                events.unshift(item);
            }
        });

        return events;
    } catch (error) {
        return [];
    }
};

export const disableCalendarScroll = (timeout = 0) => {
    const fcScroller = document.getElementsByClassName('fc-scroller')[1];
    if (!fcScroller) return;
    localStorage.setItem('lastScrollPosition', fcScroller.scrollTop);
    const handler = (e) => {
        e.preventDefault();
        fcScroller.scrollTop = localStorage.getItem('lastScrollPosition');
    };
    fcScroller.addEventListener('scroll', handler);
    setTimeout(() => {
        fcScroller.removeEventListener('scroll', handler);
    }, timeout);
};

export const hasCalendarMainProductOrPackage = (calendar) => {
    try {
        const mainProduct =
            calendar?.mainProduct &&
            Array.isArray(calendar?.mainProduct) &&
            calendar?.mainProduct.length > 0
                ? calendar?.mainProduct[0]
                : null;
        const mainPackage =
            calendar?.mainPackage &&
            Array.isArray(calendar?.mainPackage) &&
            calendar?.mainPackage.length > 0
                ? calendar?.mainPackage[0]
                : null;
        return mainProduct || mainPackage;
    } catch (error) {
        console.log(error);
        return false;
    }
};

import React, { Component } from 'react';
import './index.scss';
import { Button as AntButton, Col, Drawer, Form, Input, Row } from 'antd';
import ImportMainProduct from '../../../organisms/views/importMainProduct';
import Spacer from '../../../atoms/spacer';
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import Gallery from '../../gallery';
import Plus from '../../../../assets/images/plus.svg';
import DeleteIcon from '../../../../assets/images/close.svg';
import WhereCanPeopleSleep from '../accomodation/where-sleep';
import AddFacilities from '../accomodation/add-facilities';
import BreakfastDetails from '../accomodation/breakfast-details';
import HouseRules from '../accomodation/house-rules';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import { getCalendars } from '../../../../store/actions/calendarActions';
import {
    clearUploadedFile,
    fileUpload,
    getFiles,
    createAccomodation
} from '../../../../store/actions/contentActions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import GoogleAutoComplete from '../../../atoms/google-autocomplete';
import Proptypes from 'prop-types';
import AddRoomsButton from '../../../atoms/add-rooms';
import AddFacilitiesButton from '../../../atoms/add-facilities';
import AddBreakfastButton from '../../../atoms/add-breakfast';
import AddHouseRulesButton from '../../../atoms/add-house-rules';

const accomodation = [
    {
        name: 'Villa/Mansion'
    },
    {
        name: 'Hotel'
    },
    {
        name: 'Apartment'
    },
    {
        name: 'Camping'
    },
    {
        name: 'Bread & Breakfast'
    },
    {
        name: 'Chalet'
    },
    {
        name: 'Hostel'
    },
    {
        name: 'Log Cabin'
    },
    {
        name: 'Country Home'
    },
    {
        name: 'Timeshare Rentals'
    },
    {
        name: 'Cottage'
    },
    {
        name: 'Other'
    }
];

class AddAccomodation extends Component {
    formRefAccomodation = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            title: '',
            titleGallery: '',
            media: null,
            isOpenGallery: false,
            isOpenCategory: false,
            category: '',
            description: '',
            name: '',
            err: false,
            calendars: null,
            isOpenWhereCanPeopleSleep: false,
            isOpenAddFacilities: false,
            isOpenBreakfastDetails: false,
            isOpenHouseRules: false,
            isOpenPhysicalRestriction: false,
            isOpenCovid: false,
            isOpenAddReviews: false,
            whereCanPeopleSleep: null,
            facilities: null,
            breakfastDetails: null,
            houseRules: null,
            otherCategory: '',
            destination: '',
            address: null
        };
    }
    componentDidMount() {
        this.props.getCalendars();
    }

    setImportAccomodation = (item) => {
        this.setState({
            title: item.title,
            description: item.description,
            name: item.name,
            category: item.type || '',
            whereCanPeopleSleep: item.rooms,
            breakfastDetails: item.breakfast_details,
            facilities: item.facilities,
            houseRules: item.house_rules,
            media: item.photos_and_videos
        });
    };

    onClose = () => this.props.onClose();

    onSelectCategory = (category) => {
        this.setState({ category: category });
    };
    onOpenCategory = () => this.setState({ isOpenCategory: !this.state.isOpenCategory });

    // uploading new media
    onChange = (e) => {
        let file = e;
        let form_data = new FormData();
        form_data.append('media', file);
        this.props.fileUpload(form_data);
    };

    onSaveMedia = (value) => this.setState({ media: value, isOpenGallery: false });

    // deleting media
    onDeleteMedia = (index) => {
        const { media } = this.state;
        let tmp = media;
        tmp.splice(index, 1);
        this.setState({ media: tmp });
    };

    onOpenGallery = () => this.setState({ isOpenGallery: !this.state.isOpenGallery });
    changeOpenWhereCanPeopleSleep = () =>
        this.setState({ isOpenWhereCanPeopleSleep: !this.state.isOpenWhereCanPeopleSleep });
    changeOpenAddFacilities = () =>
        this.setState({ isOpenAddFacilities: !this.state.isOpenAddFacilities });
    changeOpenBreakfastDetails = () =>
        this.setState({ isOpenBreakfastDetails: !this.state.isOpenBreakfastDetails });
    changeOpenHouseRules = () => this.setState({ isOpenHouseRules: !this.state.isOpenHouseRules });

    onFinish = () => {
        const {
            category,
            otherCategory,
            description,
            name,
            media,
            whereCanPeopleSleep,
            facilities,
            breakfastDetails,
            houseRules,
            address
        } = this.state;
        const { day, createAccomodation, onClose, calendar_data } = this.props;
        if (calendar_data && calendar_data.data) {
            const selectCalendar = window.location.pathname.substring(
                window.location.pathname.lastIndexOf('/') + 1
            );
            let tmp = calendar_data.data.find((res) => parseInt(selectCalendar) === res.id);
            if (tmp?.mainProduct) {
                const tmpMedia = [];
                media.map((res) => {
                    tmpMedia.push({ type: res.type, url: res.url });
                });
                const setAccomodation = {
                    main_product_id: tmp.mainProduct.id,
                    calendar_days: day,
                    type: category !== 'Other' ? category : otherCategory,
                    description: description,
                    name: name,
                    address: address.terms[0].value,
                    city: address.terms[address.terms.length - 1].value,
                    country: address.terms[address.terms.length - 2].value,
                    photos_and_videos: tmpMedia,
                    rooms: whereCanPeopleSleep,
                    facilities: facilities,
                    breakfast_details: breakfastDetails,
                    house_rules: houseRules
                };
                createAccomodation(setAccomodation);
                this.props.onSave(setAccomodation);
            }
        }
        onClose();
    };

    render() {
        const {
            requiredMark,
            category,
            isOpenCategory,
            isOpenGallery,
            media,
            titleGallery,
            name,
            isOpenWhereCanPeopleSleep,
            otherCategory,
            isOpenAddFacilities,
            isOpenBreakfastDetails,
            isOpenHouseRules,
            description,
            whereCanPeopleSleep,
            facilities,
            breakfastDetails,
            houseRules
        } = this.state;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application add-accomodation"
                    onClose={() => {
                        this.onClose();
                    }}
                    style={{
                        zIndex: this.props.showModal ? 1046 : 1000
                    }}
                    visible={this.props.showModal}
                    bodyStyle={{ paddingBottom: 35, paddingTop: 19 }}>
                    <Form
                        ref={this.formRefAccomodation}
                        name="control-ref"
                        layout="vertical"
                        form={this.formRefAccomodation.current}
                        initialValues={{
                            requiredMark
                        }}
                        onFinish={this.onFinish}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}>
                        <Row>
                            <Col>
                                <h4 className="create-product-title">
                                    Tell us about your ‘Main Product’ Accommodation
                                </h4>
                                <ImportMainProduct
                                    calendars={this.props.calendar_data}
                                    onMainProduct={(item) => this.setImportAccomodation(item)}
                                    import="I will import accomodation information from my other activity"
                                />
                                <Spacer top={32} />
                                <div className="subTitle">Accomodation type</div>
                                <div className="subTitle">
                                    What type of accommodation are you providing to your travelers?
                                </div>

                                <Spacer top={21} />

                                <Form.Item
                                    name="category"
                                    valuePropName="checked"
                                    className="select-category"
                                    rules={[
                                        {
                                            validator: async () => {
                                                if (
                                                    !this.state.category ||
                                                    (this.state.category === 'Other' &&
                                                        this.state.otherCategory.trim() === '')
                                                )
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <Dropdown isOpen={isOpenCategory} toggle={this.onOpenCategory}>
                                        <DropdownToggle className="dropdown-category" caret>
                                            {category !== ''
                                                ? category
                                                : 'Select accomodation type'}
                                        </DropdownToggle>
                                        <DropdownMenu style={{ width: '100%' }}>
                                            {accomodation && accomodation.length > 0
                                                ? accomodation.map((res, i) => (
                                                      <DropdownItem
                                                          key={i}
                                                          onClick={() =>
                                                              this.onSelectCategory(res.name)
                                                          }
                                                          className="category-item">
                                                          {res.name}
                                                      </DropdownItem>
                                                  ))
                                                : null}
                                        </DropdownMenu>
                                    </Dropdown>
                                    {category === 'Other' ? (
                                        <Input
                                            placeholder="Enter Other"
                                            className="input-type"
                                            value={otherCategory}
                                            onChange={(e) => {
                                                this.setState({ otherCategory: e.target.value });
                                                this.formRefAccomodation.current.setFieldsValue({
                                                    otherCategory: e.target.value
                                                });
                                            }}
                                        />
                                    ) : null}
                                </Form.Item>

                                <Spacer top={26} />

                                <Form.Item
                                    name="description"
                                    style={{ marginLeft: '5px' }}
                                    rules={[
                                        {
                                            validator: async (_, description) => {
                                                if (
                                                    !description ||
                                                    description.trim() === '' ||
                                                    description.length < 120
                                                )
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <p className="subTitle" style={{ marginLeft: '2px' }}>
                                        Description
                                    </p>
                                    <p className="subTitle" style={{ marginLeft: '2px' }}>
                                        Describe the basics of where travelers will stay overnight,
                                        e.g. the name, or details about the lodging.
                                    </p>
                                    <Input.TextArea
                                        className="input-area"
                                        placeholder="e.g. ‘ Overnight at Yosemite Lodge, a 3-star hotel’"
                                        value={description}
                                        onChange={(e) => {
                                            this.setState({ description: e.target.value });
                                            this.formRefAccomodation.current.setFieldsValue({
                                                description: e.target.value
                                            });
                                        }}
                                    />
                                    <div className="description-input">120 characters needed</div>
                                </Form.Item>

                                <Form.Item
                                    name="title"
                                    rules={[
                                        {
                                            validator: async () => {
                                                const name = this.state.name;
                                                if (name.trim() === '')
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <div className="input-title">Accomodation Name</div>
                                    <Input
                                        placeholder="Enter accomodation name"
                                        style={{
                                            border: '0.5px solid #A2792C',
                                            height: 50,
                                            borderRadius: 8
                                        }}
                                        value={name}
                                        onChange={(e) => {
                                            this.setState({ name: e.target.value });
                                        }}
                                    />
                                </Form.Item>

                                <Form.Item
                                    name="destination"
                                    rules={[
                                        {
                                            validator: async () => {
                                                const destination = this.state.destination;
                                                if (destination === '')
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <div className="input-title">Accomodation Location</div>
                                    <GoogleAutoComplete
                                        placeholder="Enter accomodation address"
                                        onSelectDistance={(value) =>
                                            this.setState({
                                                destination: value.description,
                                                address: value
                                            })
                                        }
                                    />
                                </Form.Item>

                                <Spacer top={15} />

                                <Form.Item
                                    name="media"
                                    rules={[
                                        {
                                            validator: async () => {
                                                const media = this.state.media;
                                                if (media === null || media.length < 1)
                                                    return Promise.reject(
                                                        new Error('Please fill this field in')
                                                    );
                                            }
                                        }
                                    ]}>
                                    <Gallery
                                        showModal={isOpenGallery}
                                        media={media}
                                        title={titleGallery}
                                        onClose={this.onOpenGallery}
                                        onSaveMedia={(value) => this.onSaveMedia(value)}
                                    />
                                    <div className="d-flex flex-wrap gap-3 mb-3">
                                        <AntButton
                                            className="dash-btn"
                                            style={{ marginLeft: 0 }}
                                            id="addPhotos"
                                            type="dashed"
                                            onClick={() => {
                                                this.onOpenGallery();
                                                this.setState({ titleGallery: 'My Images' });
                                            }}>
                                            <img
                                                className="cursor-pointer img-plus"
                                                src={Plus}
                                                alt=""
                                            />{' '}
                                            Add Photos
                                        </AntButton>
                                        <AntButton
                                            className="dash-btn"
                                            style={{ marginLeft: 10 }}
                                            id="addVideos"
                                            type="dashed"
                                            onClick={() => {
                                                this.onOpenGallery();
                                                this.setState({ titleGallery: 'My Videos' });
                                            }}>
                                            <img
                                                className="cursor-pointer img-plus"
                                                src={Plus}
                                                alt=""
                                            />{' '}
                                            Add Videos
                                        </AntButton>
                                    </div>
                                    <Row>
                                        {media && media.length > 0
                                            ? media.map((res, i) => (
                                                  <div key={i} className="select-media">
                                                      <img src={res.url} alt="" />
                                                      <div className="delete-block">
                                                          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
                                                          <img
                                                              src={DeleteIcon}
                                                              className="delete-icon cursor-pointer"
                                                              alt=""
                                                              onClick={() => this.onDeleteMedia(i)}
                                                          />
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                    </Row>
                                </Form.Item>

                                <Spacer top={30} />

                                <div className="subTitle">
                                    What can customers use at this accomodation?
                                </div>

                                <Spacer top={15} />

                                <Form.Item>
                                    <Form.Item
                                        name="whereCanPeopleSleep"
                                        style={{ margin: '0 !important' }}
                                        rules={[
                                            {
                                                validator: async () => {
                                                    const whereCanPeopleSleep =
                                                        this.state.whereCanPeopleSleep;
                                                    if (whereCanPeopleSleep === null)
                                                        return Promise.reject(
                                                            new Error('Please fill this field in')
                                                        );
                                                }
                                            }
                                        ]}>
                                        <AddRoomsButton
                                            name="Where can people sleep?"
                                            add="Add Rooms"
                                            data={whereCanPeopleSleep}
                                            onClick={this.changeOpenWhereCanPeopleSleep}
                                            onDelete={() =>
                                                this.setState({ whereCanPeopleSleep: null })
                                            }
                                        />
                                    </Form.Item>
                                    <AddFacilitiesButton
                                        name="What can your customers use?"
                                        data={facilities}
                                        add="Add Facilities"
                                        onClick={this.changeOpenAddFacilities}
                                        onDelete={() => this.setState({ facilities: null })}
                                    />
                                    <AddBreakfastButton
                                        name="Breakfast details"
                                        data={breakfastDetails}
                                        onClick={this.changeOpenBreakfastDetails}
                                        onDelete={() => this.setState({ breakfastDetails: null })}
                                    />
                                    <AddHouseRulesButton
                                        name="House rules"
                                        data={houseRules}
                                        onDelete={() => this.setState({ houseRules: null })}
                                        onClick={this.changeOpenHouseRules}
                                    />
                                </Form.Item>
                                <WhereCanPeopleSleep
                                    data={whereCanPeopleSleep}
                                    isOpen={isOpenWhereCanPeopleSleep}
                                    onClose={this.changeOpenWhereCanPeopleSleep}
                                    onSave={(rooms) =>
                                        this.setState({
                                            whereCanPeopleSleep: rooms,
                                            isOpenWhereCanPeopleSleep: false
                                        })
                                    }
                                />
                                <AddFacilities
                                    isOpen={isOpenAddFacilities}
                                    onClose={this.changeOpenAddFacilities}
                                    data={facilities}
                                    onSave={(value) =>
                                        this.setState({
                                            facilities: value,
                                            isOpenAddFacilities: false
                                        })
                                    }
                                />
                                <BreakfastDetails
                                    isOpen={isOpenBreakfastDetails}
                                    onClose={this.changeOpenBreakfastDetails}
                                    data={breakfastDetails}
                                    onSave={(value) =>
                                        this.setState({
                                            breakfastDetails: value,
                                            isOpenBreakfastDetails: false
                                        })
                                    }
                                />
                                <HouseRules
                                    isOpen={isOpenHouseRules}
                                    onClose={this.changeOpenHouseRules}
                                    data={houseRules}
                                    onSave={(value) =>
                                        this.setState({
                                            houseRules: value,
                                            isOpenHouseRules: false
                                        })
                                    }
                                />
                                <Spacer top={26} />
                            </Col>
                        </Row>
                        <div className="footer">
                            <div className="footer-btn">
                                <Button color="primary" type="submit">
                                    Update Calender & Close
                                </Button>
                                <Button color="dark" className="help-btn">
                                    <img src={HelpIcon} alt="" /> Help
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Drawer>
            </div>
        );
    }
}
AddAccomodation.propTypes = {
    getCalendars: Proptypes.func,
    onClose: Proptypes.func,
    fileUpload: Proptypes.func,
    showModal: Proptypes.bool,
    calendar_data: Proptypes.object,
    create_calendar: Proptypes.object,
    day: Proptypes.array,
    createAccomodation: Proptypes.func,
    onSave: Proptypes.func
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    create_calendar: state.calendar.create_calendar,
    success_calendar: state.calendar.success_calendar,
    selected_calendar: state.calendar.selected_calendar,
    files: state.contentDetail.files
});

const mapDispatch = (dispatch) => ({
    getCalendars: () => dispatch(getCalendars()),
    fileUpload: (data) => dispatch(fileUpload(data)),
    getFiles: () => dispatch(getFiles()),
    clearUploadedFile: () => dispatch(clearUploadedFile()),
    createAccomodation: (data) => dispatch(createAccomodation(data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddAccomodation));

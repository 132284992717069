import React from 'react';
import Details from '../../organisms/views/product-content/details';

export default function MainProduct() {
    return (
        <>
            <Details />
        </>
    );
}

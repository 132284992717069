import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form } from 'antd';
import './index.scss';
import Spacer from '../../../atoms/spacer';
import DaysAccordion from '../../../molecules/days-accodion';
import { withRouter } from 'react-router-dom';

class GuideLanding extends React.Component {
    state = {
        show: false,
        days: [
            {
                day: 'day 1',
                open: false,
                actionLink: '/guide/details',
                titleText: 'Market Guide',
                subTitleText: 'Add a Market Guide'
            },
            {
                day: 'day 2',
                open: false,
                actionLink: '/guide/other-vendor-text',
                titleText: 'Night Life Guide',
                subTitleText: 'Add a Night Life Guide'
            }
        ]
    };

    render() {
        const { days } = this.state;
        return (
            <div className="guide-landing">
                <Form layout="vertical">
                    <Row>
                        <Col span={24}>
                            <h4>Tell us about your ‘Product Title’ Multiple Itineraries - Guide</h4>
                            <Spacer top={3} />
                            <p>
                                Be sure to enter all key attractions in your itinerary. This helps
                                travelers find and book your experience.
                            </p>
                            <Spacer top={13} />
                            <DaysAccordion
                                days={days}
                                newTitleText="Market Guide"
                                newSubtitleText="Add a Market Guide"
                            />
                            <Button
                                style={{
                                    width: 323,
                                    height: 42
                                }}
                                className="mt-50px"
                                onClick={() => {
                                    this.props.history.push('/guide/details');
                                }}
                                type="primary"
                                shape="round">
                                Update Calender & Continue
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

GuideLanding.propTypes = {
    history: PropTypes.object
};

export default withRouter(GuideLanding);

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { ExtraInfoIcon } from 'components/atoms';
import CollapseMoreLess from '../../collapse-more-less';
const EventTravellerInfo = (props) => {
    const { product, onEdit } = props;

    if (!product?.traveller_information)
        return (
            <DetailedPanel
                panelIcon={<ExtraInfoIcon />}
                panelTitle="Extra info"
                isEditable={true}
                onEdit={onEdit}></DetailedPanel>
        );

    return (
        <DetailedPanel
            panelIcon={<ExtraInfoIcon />}
            panelTitle="Extra info"
            isEditable={true}
            onEdit={onEdit}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Phone No</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {product.traveller_information.phone_number}
                    </span>
                </Col>
            </Row>

            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Health Restrictions</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {!product.traveller_information.health_restrictions.length > 0
                            ? ''
                            : product.traveller_information.health_restrictions.map(
                                  (item, i) =>
                                      `${item}${
                                          i <
                                          product.traveller_information.health_restrictions.length -
                                              1
                                              ? ','
                                              : ''
                                      }`
                              )}
                    </span>
                </Col>
            </Row>

            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Difficulty Level</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {product.traveller_information.difficulty_level?.type}
                        {product.traveller_information.difficulty_level?.content &&
                            ` (${product.traveller_information.difficulty_level?.content})`}
                    </span>
                </Col>
            </Row>

            <p className="fs-20 fw-500 lh-1-7 flex-grow-1 mr-2 mb-0 p-0 mt-2  ">Accessibility</p>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Wheelchair accessibility
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product.traveller_information?.accessibility?.wheelchair)}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Stroller accessible</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product.traveller_information?.accessibility?.stroller)}
                    </span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Service animals Allowed
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">
                        {getYesNo(product.traveller_information?.accessibility?.service_animals)}
                    </span>
                </Col>
            </Row>

            <CollapseMoreLess>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                Can travelers easily arrive/depart on public transportation?
                            </span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(product.traveller_information?.accessibility?.public_trans)}
                        </span>
                    </Col>
                </Row>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                Are infants required to sit on laps?
                            </span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(product.traveller_information?.accessibility?.infant_lapping)}
                        </span>
                    </Col>
                </Row>

                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                Do infants get seats?
                            </span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">
                            {getYesNo(product.traveller_information?.accessibility?.infant_seats)}
                        </span>
                    </Col>
                </Row>
            </CollapseMoreLess>
        </DetailedPanel>
    );
};

EventTravellerInfo.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

const getYesNo = (value) => (value ? 'Yes' : 'No');

export default EventTravellerInfo;

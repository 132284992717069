import React from 'react';
import PropTypes from 'prop-types';

const GuideIcon = ({ color }) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M16.4848 4.88326C16.4717 4.9802 16.442 5.06084 16.3703 5.13423C15.8824 5.62866 15.6139 6.28357 15.6139 6.97897C15.6139 9.97075 15.6333 9.83507 15.556 10.1182C15.4865 10.3727 15.114 10.3262 15.114 10.0623V8.28429C15.114 7.56534 14.4802 6.99892 13.7562 7.09495C11.3708 7.41146 8.93266 6.80574 6.94514 5.28221C6.30798 4.79322 5.37776 5.05824 5.0929 5.81013C4.84813 6.45949 4.35305 6.94167 3.68759 7.12995C3.17211 7.27688 2.81571 7.74721 2.81571 8.28429V9.74193C2.81571 10.0316 2.37993 10.0459 2.36733 9.75653C2.36508 9.70454 2.38987 9.86217 2.36578 7.63388C2.35478 6.61882 2.49426 4.07737 4.348 2.29062C6.59774 0.121626 10.2411 0.193816 13.0048 1.52373C13.2646 1.64861 13.3118 2.00141 13.0893 2.19014C14.4566 2.52309 15.6395 3.36057 16.3928 4.56631C16.4486 4.65544 16.5044 4.73853 16.4848 4.88326Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M13.8613 7.88723C11.2438 8.23449 8.59679 7.55583 6.45834 5.91651C6.24467 5.75283 5.93441 5.84352 5.84092 6.09248C5.51047 6.96461 4.83211 7.63592 3.90569 7.89873C3.73372 7.94773 3.61523 8.1047 3.61523 8.28368V11.0893C3.61523 14.0389 6.0149 16.4385 8.96449 16.4385C11.9141 16.4385 14.3137 14.0389 14.3137 11.0893V8.28368C14.3138 8.04186 14.1013 7.85564 13.8613 7.88723ZM10.6643 10.5334C10.6643 10.7543 10.4853 10.9333 10.2644 10.9333C10.0434 10.9333 9.86441 10.7543 9.86441 10.5334V9.83346C9.86441 9.61249 10.0434 9.43352 10.2644 9.43352C10.4853 9.43352 10.6643 9.61249 10.6643 9.83346V10.5334ZM8.16465 10.5334C8.16465 10.7543 7.98567 10.9333 7.76471 10.9333C7.54374 10.9333 7.36476 10.7543 7.36476 10.5334V9.83346C7.36476 9.61249 7.54374 9.43352 7.76471 9.43352C7.98567 9.43352 8.16465 9.61249 8.16465 9.83346V10.5334Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M7.16436 21.9817C6.5017 21.9817 5.96452 22.5189 5.96452 23.1815V24.1814C5.96452 24.4023 5.78545 24.5814 5.56458 24.5814H1.26518C1.04431 24.5814 0.865234 24.4023 0.865234 24.1814V21.5203C0.865234 20.197 1.71517 19.0474 2.95444 18.5852C2.97514 18.5775 3.93971 18.2452 4.61971 18.0106C4.70155 17.9823 4.79219 18.0121 4.84138 18.0834L7.32308 21.6793C7.41137 21.8072 7.31978 21.9817 7.16436 21.9817Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M11.3175 19.2403L9.48461 21.8967C9.44727 21.9508 9.38573 21.9831 9.32004 21.9831H8.60814C8.54239 21.9831 8.48085 21.9508 8.44356 21.8967L6.58771 19.2072C6.46493 19.0292 6.6724 18.8107 6.85683 18.9236C8.1505 19.7158 9.72753 19.7446 11.0499 18.9558C11.2347 18.8455 11.4397 19.0631 11.3175 19.2403Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M11.7267 17.0475C11.7802 17.1188 11.7825 17.2161 11.7294 17.2877C10.4785 18.9718 8.21581 19.2571 6.64968 17.7602C6.43536 17.5557 6.29408 17.3781 6.21304 17.266C6.16215 17.1956 6.16385 17.1008 6.21534 17.0309C6.25903 16.9716 6.29978 16.9103 6.33747 16.8471C6.38902 16.7607 6.49455 16.7227 6.58724 16.7617C8.10508 17.4 9.82779 17.3986 11.3425 16.7616C11.4332 16.7235 11.539 16.7575 11.5892 16.8421C11.6313 16.913 11.6772 16.9816 11.7267 17.0475Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M11.165 23.1831V24.183C11.165 24.4039 10.9859 24.5829 10.7651 24.5829H7.16557C6.9447 24.5829 6.76562 24.4039 6.76562 24.183V23.1831C6.76562 22.9623 6.9447 22.7832 7.16557 22.7832H10.7651C10.9859 22.7832 11.165 22.9622 11.165 23.1831Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M14.977 24.5812H12.3635C12.1427 24.5812 11.9636 24.4022 11.9636 24.1813V23.1814C11.9636 22.519 11.4262 21.9816 10.7638 21.9816C10.6099 21.9816 10.5158 21.8098 10.6048 21.6791L13.0869 18.0831C13.1359 18.0121 13.2264 17.9822 13.3084 18.0106C14.8985 18.5613 14.9783 18.5825 15.1221 18.6408C15.2679 18.6998 15.3631 18.8419 15.3631 18.9992V24.1855C15.3639 24.4025 15.194 24.5812 14.977 24.5812Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M16.5621 24.5811C16.3412 24.5811 16.1621 24.4021 16.1621 24.1812V13.0328C16.1621 12.8119 16.3412 12.6328 16.5621 12.6328C16.7829 12.6328 16.962 12.8119 16.962 13.0328V24.1812C16.962 24.4021 16.7829 24.5811 16.5621 24.5811Z"
                    fill={color || 'currentColor'}
                />
                <path
                    d="M23.7274 18.6188C22.2324 19.519 20.4817 19.0717 18.3203 19.9957C18.0559 20.1087 17.7617 19.9164 17.7617 19.6288V13.8847C17.7617 13.6288 17.9988 13.4388 18.2487 13.494C20.4226 13.9744 20.8919 15.6415 23.4684 16.4467C24.454 16.755 24.6129 18.0854 23.7274 18.6188Z"
                    fill={color || 'currentColor'}
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="24"
                        height="24"
                        fill={color || 'currentColor'}
                        transform="translate(0.587891 0.582031)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
GuideIcon.propTypes = {
    color: PropTypes.string
};
export default GuideIcon;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-undef */
import { Button, Form, Input } from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import 'components/pages/signin/index.scss';
import { connect } from 'react-redux';
import logo from 'assets/images/logos/brand.svg';
import { withRouter } from 'react-router-dom';
import { Container } from 'reactstrap';
import { createPassword, setInitialAuth, getCurretUser } from 'store/actions/AuthActions';
import queryString from 'query-string';
import { validatePassword } from 'utils/validation';

const CreatePasswordComponent = (props) => {
    const [form] = Form.useForm();
    const [showError, setError] = useState(false);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [didMount, setDidMount] = useState(false);
    const [passwordForm, setpasswordForm] = useState({
        confirm_password: '',
        password: ''
    });

    useEffect(() => {
        if (!didMount) {
            localStorage.clear();
            props.setInitialAuth();
            let { email, token } = queryString.parse(props.location.search);
            setEmail(email);
            setToken(token);
            // props.getCurretUser();
            setDidMount(true);
        } else {
            const { auth } = props;
            if (auth && auth.update_password) {
                props.history.push('/signin');
            }
        }
    }, [props, props.my_profile, didMount]);

    const updateForm = (field, value) => {
        if (typeof field == 'string') {
            let itemSet = passwordForm;
            itemSet[field] = value;
            setpasswordForm(itemSet);
        }

        if (passwordForm.password !== passwordForm.confirm_password) {
            setError(true);
            // history.push("/signin");
        } else {
            setError(false);
        }
    };

    const onFinish = () => {
        let data = {};
        data['password'] = passwordForm.password;
        data['password_confirmation'] = passwordForm.confirm_password;
        data['email'] = email;
        data['token'] = token;
        props.createPassword(data, props.history);
    };

    return (
        // Wrapper
        <div className="signin">
            {/* Custom Container for login, forgot password and create password */}
            <Container className="container-login">
                {/* White Box Starts */}
                <div className="form_box">
                    <div className="form_box--inner">
                        <img className="logo" src={logo} alt="" />
                        <div className="text-primary mb-15px">
                            password must contain at least 8 characters, one lowercase letter, one
                            uppercase letter and one special letter.{' '}
                        </div>

                        {props?.update_password?.success === false && (
                            <span className="invalid-feedback d-block">
                                {props?.update_password?.error}
                            </span>
                        )}

                        <Form
                            form={form}
                            onValuesChange={(field) => updateForm(field)}
                            onFinish={onFinish}
                            hideRequiredMark
                            className="form">
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        validator: (rule, value) =>
                                            value != undefined && value.length
                                                ? validatePassword(rule, value)
                                                : Promise.reject(`Please enter your password`)
                                    }
                                ]}>
                                <Input.Password
                                    size="large"
                                    defaultValue={passwordForm.password}
                                    placeholder="Create Password"
                                    onChange={(e) => updateForm('password', e.target.value)}
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirm_password"
                                rules={[
                                    {
                                        validator: (rule, value) =>
                                            value != undefined && value.length
                                                ? validatePassword(rule, value)
                                                : Promise.reject(`Please enter your password`)
                                    }
                                ]}>
                                <Input.Password
                                    size="large"
                                    placeholder="Confirm Password"
                                    defaultValue={passwordForm.confirm_password}
                                    onChange={(e) => updateForm('confirm_password', e.target.value)}
                                />
                            </Form.Item>
                            {showError ? (
                                <span className="invalid-feedback d-block">
                                    Password doesn't match
                                </span>
                            ) : (
                                ''
                            )}
                            <div className="form-btn-wrapper">
                                <Button
                                    type="primary"
                                    size="large"
                                    className="btn-block"
                                    htmlType="submit"
                                    disabled={
                                        passwordForm.password == passwordForm.confirm_password &&
                                        !showError
                                            ? false
                                            : true
                                    }>
                                    {'Next'}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                {/* White Box Ends */}
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth.auth,
    my_profile: state.auth.my_profile,
    update_password: state.auth.update_password
});

const mapDispatch = (dispatch) => ({
    createPassword: (data, history) => dispatch(createPassword(data, history)),
    getCurretUser: () => dispatch(getCurretUser()),
    setInitialAuth: () => dispatch(setInitialAuth())
});

CreatePasswordComponent.propTypes = {
    setInitialAuth: PropTypes.func,
    location: PropTypes.object,
    auth: PropTypes.object,
    history: PropTypes.object,
    my_profile: PropTypes.object,
    createPassword: PropTypes.func,
    update_password: PropTypes.object
};

export default withRouter(connect(mapStateToProps, mapDispatch)(CreatePasswordComponent));

import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { getAllCountries } from 'store/actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

function SelectCountry(props) {
    let {
        label,
        required = true,
        placeHolder = 'Search country',
        countries,
        getAllCountries,
        initialValue,
        selectValue = 'name',
        selectName = 'name',
        onSelect,
        fieldName = 'country',
        disabled = false
    } = props;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Array.isArray(countries) && countries.length < 1) fetchCountries();
        else if (!countries) fetchCountries();
        else if (!Array.isArray(countries)) fetchCountries();
    }, []);

    function fetchCountries() {
        setLoading(true);
        getAllCountries().finally(() => setLoading(false));
    }

    return (
        <Form.Item
            label={label || undefined}
            name={fieldName}
            initialValue={initialValue}
            rules={[{ required: required, message: 'Please select country' }]}>
            <Select
                autoComplete="none"
                className="d-block"
                placeholder={placeHolder}
                showSearch
                loading={loading}
                onSelect={onSelect}
                disabled={disabled}
                optionFilterProp={'label'}>
                {countries &&
                    countries.map((item, index) => (
                        <Select.Option
                            label={item[selectName]}
                            value={item[selectValue]}
                            key={index}>
                            {item[selectName]}
                        </Select.Option>
                    ))}
            </Select>
        </Form.Item>
    );
}

SelectCountry.propTypes = {
    getAllCountries: PropTypes.func,
    countries: PropTypes.array,
    label: PropTypes.string,
    required: PropTypes.bool,
    placeHolder: PropTypes.string,
    initialValue: PropTypes.string,
    selectValue: PropTypes.string,
    selectName: PropTypes.string,
    onSelect: PropTypes.func,
    fieldName: PropTypes.string,
    disabled: PropTypes.bool
};

const mapStateToProps = (state) => ({
    countries: state.profile.countries
});
const mapDispatch = (dispatch) => ({
    getAllCountries: () => dispatch(getAllCountries())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(SelectCountry));

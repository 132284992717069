import React from 'react';

const PlusIcon = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 6.66667C0 2.98467 2.98392 0 6.665 0C10.3461 0 13.33 2.98467 13.33 6.66667C13.33 10.3487 10.3461 13.3333 6.665 13.3333C2.98392 13.3333 0 10.3487 0 6.66667ZM6.665 1.33333C5.25087 1.33333 3.89465 1.89524 2.89471 2.89543C1.89476 3.89562 1.333 5.25218 1.333 6.66667C1.333 8.08115 1.89476 9.43771 2.89471 10.4379C3.89465 11.4381 5.25087 12 6.665 12C8.07913 12 9.43535 11.4381 10.4353 10.4379C11.4352 9.43771 11.997 8.08115 11.997 6.66667C11.997 5.25218 11.4352 3.89562 10.4353 2.89543C9.43535 1.89524 8.07913 1.33333 6.665 1.33333Z"
                fill="#A2792C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.33152 3.33366C7.33152 3.15685 7.26129 2.98728 7.1363 2.86225C7.01131 2.73723 6.84178 2.66699 6.66502 2.66699C6.48825 2.66699 6.31872 2.73723 6.19373 2.86225C6.06874 2.98728 5.99852 3.15685 5.99852 3.33366V6.00033H3.33252C3.15575 6.00033 2.98622 6.07056 2.86123 6.19559C2.73624 6.32061 2.66602 6.49018 2.66602 6.66699C2.66602 6.8438 2.73624 7.01337 2.86123 7.1384C2.98622 7.26342 3.15575 7.33366 3.33252 7.33366H5.99852V10.0003C5.99852 10.1771 6.06874 10.3467 6.19373 10.4717C6.31872 10.5968 6.48825 10.667 6.66502 10.667C6.84178 10.667 7.01131 10.5968 7.1363 10.4717C7.26129 10.3467 7.33152 10.1771 7.33152 10.0003V7.33366H9.99752C10.1743 7.33366 10.3438 7.26342 10.4688 7.1384C10.5938 7.01337 10.664 6.8438 10.664 6.66699C10.664 6.49018 10.5938 6.32061 10.4688 6.19559C10.3438 6.07056 10.1743 6.00033 9.99752 6.00033H7.33152V3.33366Z"
                fill="#A2792C"
            />
        </svg>
    );
};

export default PlusIcon;

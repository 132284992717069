import React from 'react';
import { Provider } from 'react-redux';
import store from '../src/store';
import Routes from '../src/routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// styles to help with paddings and margin
// import './helpers/_width_margins_paddings.sass';
import Toastable from './components/atoms/toastable';

// ant design styles
import 'antd/dist/antd.less';
import './App.css';
import './index.scss';
import RolesAndPermission from 'context/rolesandpermission';

const App = () => {
    return (
        <Provider store={store}>
            <RolesAndPermission>
                <Routes />
                <ToastContainer />
                <Toastable />
            </RolesAndPermission>
        </Provider>
    );
};

export default App;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */

import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import Carousel from 'react-multi-carousel';
import MoreProducts from './more-products';
import { getMoment } from 'utils';
import { ProductCategoryIcon } from '../index';
import { CalendarEventCarouselArrow } from 'components/atoms';
import { useRef } from 'react';

const responsive = {
    eventGallery: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};

// SM - 126.15
// LG - 383.78

const MainEvent = ({ eventInfo, setCurrentProduct }) => {
    const carouselRef = useRef();
    const product = eventInfo?.event?.extendedProps;
    const size = getSize(product);
    const images =
        product?.photos_and_videos && product?.photos_and_videos.length
            ? product?.photos_and_videos
            : [];

    return (
        <div className={`with-bullets h-100 ${product?.moreProducts ? 'has-more-products' : ''}`}>
            <MoreProducts eventInfo={eventInfo} setCurrentProduct={setCurrentProduct} />

            <div
                className="event-card"
                onClick={() => setCurrentProduct(product)}
                onKeyUp={() => setCurrentProduct(product)}>
                <div className="event-card-header">
                    <div className="event-card-title">
                        <span className="event-card-header-icon mr-2">
                            <ProductCategoryIcon
                                id={eventInfo.event.extendedProps?.product_category_id}
                            />
                        </span>
                        <span className="name">{eventInfo.event.extendedProps?.title}</span>

                        {/* Name Icon */}
                        {product?.productType === 'main_product' ? (
                            <span className="ml-2">
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="12" cy="12" r="12" fill="white" />
                                    <path
                                        d="M12.9643 7.62383V15.9998H11.2843V10.1558L9.13628 15.9998H7.67228L5.52428 10.1798V15.9998H3.83228V7.62383H5.88428L8.42828 13.9238L10.9243 7.62383H12.9643Z"
                                        fill="#A2792C"
                                    />
                                    <path
                                        d="M16.1883 12.8558V15.9998H14.4963V7.52783H17.5803C18.5803 7.52783 19.3323 7.76783 19.8363 8.24783C20.3483 8.72783 20.6043 9.37983 20.6043 10.2038C20.6043 11.0118 20.3403 11.6558 19.8123 12.1358C19.2923 12.6158 18.5483 12.8558 17.5803 12.8558H16.1883ZM17.4003 11.4998C18.3923 11.4998 18.8883 11.0678 18.8883 10.2038C18.8883 9.77983 18.7723 9.45583 18.5403 9.23183C18.3083 9.00783 17.9283 8.89583 17.4003 8.89583H16.1883V11.4998H17.4003Z"
                                        fill="#A2792C"
                                    />
                                </svg>
                            </span>
                        ) : null}
                    </div>
                    <div className="event-card-header-action">
                        {size === 'SM' && product?.booking_time_periods?.time_period ? (
                            <span className="mr-2">
                                <Checkbox
                                    className="__type-time-block"
                                    title="Suggested Time"
                                    checked={true}></Checkbox>
                            </span>
                        ) : null}
                        <span role="button" tabIndex="0" title="Edit Event">
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M18.9881 2.01202L21.9881 5.01202L19.701 7.30002L16.701 4.30002L18.9881 2.01202Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M8 16H11L18.287 8.71301L15.287 5.71301L8 13V16Z"
                                    fill="currentColor"
                                />
                                <path
                                    d="M19 19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.896 3 5V19C3 20.104 3.897 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10.332L19 12.332V19Z"
                                    fill="currentColor"
                                />
                            </svg>
                        </span>
                    </div>
                </div>

                {/* Event image gallery */}
                <div className="event-gallery-wrapper">
                    {images.length === 1 || size === 'SM' ? (
                        <div className="event-gallery-item">
                            <img
                                className="event-gallery-img"
                                src={images[0]?.type == 'image' ? images[0].url : null}
                                alt=""
                            />
                        </div>
                    ) : (
                        <></>
                    )}

                    {images.length > 1 && size !== 'SM' ? (
                        <Carousel
                            ref={carouselRef}
                            customLeftArrow={
                                <CalendarEventCarouselArrow
                                    onClick={() => {
                                        carouselRef.previous();
                                    }}
                                    type={'left'}
                                />
                            }
                            customRightArrow={
                                <CalendarEventCarouselArrow
                                    onClick={() => {
                                        carouselRef.next();
                                    }}
                                />
                            }
                            swipeable={true}
                            draggable={false}
                            showDots={false}
                            arrows={true}
                            responsive={responsive.eventGallery}
                            infinite={true}
                            autoPlay={false}
                            autoPlaySpeed={3000}
                            keyBoardControl={true}
                            containerClass="event-gallery-carousel">
                            {eventInfo.event.extendedProps.photos_and_videos.map((item, index) =>
                                item.type == 'image' ? (
                                    <div className="event-gallery-item" key={index}>
                                        <img className="event-gallery-img" src={item.url} alt="" />
                                    </div>
                                ) : null
                            )}
                        </Carousel>
                    ) : (
                        <></>
                    )}
                </div>

                {product?.booking_time_periods?.time_period && size != 'SM' ? (
                    <div className="event-card-info __type-time-block">
                        <div className="suggested-time-block">
                            <div className="suggested-name">Suggested Time</div>
                            <Checkbox checked={true} className="__type-time-block"></Checkbox>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

MainEvent.propTypes = {
    eventInfo: PropTypes.any,
    setCurrentProduct: PropTypes.func
};

export default MainEvent;

const getSize = (product) => {
    let duration = getMoment(product);
    let minutes = duration.end.diff(duration.start) / 60000;

    if (minutes >= 383) {
        return 'LG';
    }

    if (minutes >= 126) {
        return 'MD';
    }

    return 'SM';
};

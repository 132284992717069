import React from 'react';
import PropTypes from 'prop-types';
const MealsAndDrinksIcon = ({ color }) => {
    return (
        <svg
            width="21"
            height="16"
            viewBox="0 0 21 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.51641 0.34052C9.36974 0.40962 9.30363 0.478255 9.23707 0.630517C9.05887 1.03813 9.18275 1.48822 9.51641 1.64542C9.68569 1.72518 9.81271 1.73594 10.5832 1.73594C11.6297 1.73594 11.7817 1.69296 11.9293 1.35542C12.1075 0.947801 11.9836 0.497718 11.65 0.34052C11.4807 0.260756 11.3537 0.25 10.5832 0.25C9.81271 0.25 9.68569 0.260756 9.51641 0.34052ZM9.88389 2.71987C8.70422 2.82929 7.27593 3.34648 6.23248 4.04209C4.41671 5.2526 2.9624 7.1591 2.36035 9.1183C2.19991 9.64047 2.06118 10.3013 2.06118 10.5436V10.6782H1.58843C1.07958 10.6782 0.886889 10.7393 0.684963 10.9647C0.441188 11.2367 0.438901 11.4549 0.675005 11.9215C0.953002 12.4711 1.31281 12.9615 1.78861 13.4392C2.62134 14.2754 3.51633 14.7583 4.75234 15.0386C5.11215 15.1202 5.44155 15.125 10.6056 15.125H16.0776L16.545 15.0164C17.7023 14.7474 18.6147 14.2442 19.4445 13.4171C19.8386 13.0243 20.2863 12.4097 20.5057 11.9602C20.6284 11.7088 20.6388 11.6537 20.6144 11.3823C20.5669 10.8547 20.3071 10.6782 19.5779 10.6782H19.1052L19.1042 10.5501C19.1027 10.3531 18.9419 9.6346 18.7854 9.12562C18.6207 8.58986 18.1372 7.5547 17.8034 7.02294C17.3082 6.2343 16.4595 5.2627 15.756 4.67917C14.4173 3.56863 12.7629 2.86552 11.1438 2.71894C10.5495 2.66511 10.4736 2.6652 9.88389 2.71987ZM11.2465 4.2546C11.7997 4.31658 12.4866 4.48533 12.9304 4.66837C14.834 5.45338 16.499 7.16129 17.2687 9.1183C17.4506 9.58073 17.6687 10.3727 17.6695 10.5734L17.6699 10.6782H10.5832H3.49646V10.5668C3.49646 10.5057 3.54634 10.2563 3.60729 10.0127C4.16392 7.78859 5.86832 5.73425 7.94984 4.77849C8.62604 4.468 9.11202 4.34065 10.0225 4.23537C10.3235 4.20054 10.8356 4.2086 11.2465 4.2546ZM18.3851 12.3358C17.7996 12.9708 16.8131 13.4767 15.9043 13.6079C15.421 13.6776 5.74533 13.6776 5.26204 13.6079C4.35328 13.4767 3.36675 12.9708 2.78124 12.3358L2.62668 12.1682H10.5832H18.5397L18.3851 12.3358Z"
                fill={color || 'currentColor'}
            />
        </svg>
    );
};

MealsAndDrinksIcon.propTypes = {
    color: PropTypes.any
};

export default MealsAndDrinksIcon;

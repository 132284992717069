/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';

export default function AddReviewButton(props) {
    const [reviews, setReviews] = useState([]);
    const { add = 'Add', moreInfo = true } = props;

    useEffect(() => {
        if (props.data && props.data.length > 0) {
            const tmpData = [];
            props.data.map((res) =>
                res.type === 'google'
                    ? tmpData.push('GOOGLE')
                    : res.type === 'trip_advisor'
                    ? tmpData.push('TRIP ADVISOR')
                    : res.type === 'trust_pilot'
                    ? tmpData.push('TRUST PILOT')
                    : res.type === 'facebook'
                    ? tmpData.push('FACEBOOK')
                    : res.type === 'booking'
                    ? tmpData.push('BOOKING.COM')
                    : null
            );
            setReviews(tmpData);
        }
    }, [props.data]);

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                {props.data ? (
                    <div className="group-btn" style={{ paddingRight: '20px' }}>
                        <img
                            src={Edit}
                            alt=""
                            title="Edit"
                            style={{ paddingRight: '10px' }}
                            className="itinerary-img cursor-pointer"
                            onClick={props.onClick}
                        />
                        <img
                            src={Delete}
                            alt=""
                            title="Delete"
                            onClick={props.onDelete}
                            className="itinerary-img cursor-pointer"
                        />
                    </div>
                ) : (
                    <Button
                        color="link"
                        onClick={() => {
                            if (props.isAddDisabled) {
                                props.toggleShowValidation();
                            } else {
                                props.onClick();
                            }
                        }}>
                        <img className="img-plus" src={Plus} alt="" /> {add}
                    </Button>
                )}
            </div>
            {props.data ? (
                <Row className="actions">
                    <Col style={{ paddingLeft: 0 }} sm={10}>
                        {reviews && reviews.length > 0
                            ? reviews.map((res, i) => (i === reviews.length - 1 ? res : `${res}, `))
                            : null}
                    </Col>
                </Row>
            ) : null}
        </div>
    );
}
AddReviewButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any,
    isAddDisabled: Proptypes.bool,
    toggleShowValidation: Proptypes.func
};

import React from 'react';
import './index.scss';
import { Button, Modal } from 'reactstrap';
import TimeBlock from '../time-block';
import Proptypes from 'prop-types';

export default class SelectTime extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
            startTime: '00:00',
            endTime: '00:00'
        };
    }

    onClose = (value) => this.props.onClose(value);
    onSave = () => {
        const { startTime, endTime } = this.state;
        this.props.onSave(startTime, endTime);
    };

    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered size="lg">
                <div className="select-time">
                    <h3 className="description">
                        Adjust the duration of your Activity from start to finish?
                    </h3>

                    <TimeBlock
                        onTime={(value) => this.setState({ startTime: value })}
                        showAmPm={false}
                        padding={'26px'}
                    />
                    <TimeBlock
                        onTime={(value) => this.setState({ endTime: value })}
                        showAmPm={false}
                        padding={'26px'}
                    />

                    <div className="footer">
                        <Button color="primary" onClick={() => this.onSave()}>
                            ADJUST
                        </Button>
                        <Button
                            color="dark"
                            style={{
                                marginLeft: '13px'
                            }}
                            onClick={() => {
                                this.onClose(false);
                            }}>
                            CANCEL
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}
SelectTime.propTypes = {
    onClose: Proptypes.func,
    onSave: Proptypes.func,
    isOpen: Proptypes.bool
};

/* eslint-disable no-case-declarations */
import {
    GET_CALENDAR_BOOKINGS,
    RESET_BOOKING_REDUCER,
    GET_BOOKED_CALENDARS,
    GET_CALENDAR_BOOKED_PRODUCTS,
    GET_OTHER_BOOKED_PRODUCTS,
    GET_LEAD_TRAVELLERS,
    GET_ALL_PRODUCTS,
    GET_CALENDAR_BOOKED_DETAILS
} from 'store/types';

const initialState = {
    calendar_bookings: {},
    booked_calendars: [],
    calendar_booked_products: [],
    other_booked_products: [],
    lead_travellers: [],
    all_products: [],
    calendar_booked_details: null
};

const bookingReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CALENDAR_BOOKINGS:
            return {
                ...state,
                calendar_bookings: action.payload?.data
            };
        case RESET_BOOKING_REDUCER:
            if (action.payload && Object.keys(action.payload).length > 0) {
                Object.keys(action.payload).forEach((key) => {
                    state[key] = initialState[key];
                });
            }
            return { ...state };
        case GET_BOOKED_CALENDARS:
            return {
                ...state,
                booked_calendars: action.payload?.data
            };
        case GET_CALENDAR_BOOKED_PRODUCTS:
            let allData = [];
            let keys = Object.keys(action.payload?.data);
            if (action.payload?.data && keys.length > 0) {
                keys.forEach((item) => {
                    allData = [...allData, ...action.payload?.data[item]];
                });
            }
            return {
                ...state,
                calendar_booked_products: allData
            };
        case GET_OTHER_BOOKED_PRODUCTS:
            return {
                ...state,
                other_booked_products: action.payload?.data
            };
        case GET_LEAD_TRAVELLERS:
            return {
                ...state,
                lead_travellers: action.payload?.data
            };
        case GET_ALL_PRODUCTS:
            let allProducts = action.payload?.data;
            if (allProducts && allProducts.length > 0) {
                allProducts = allProducts.filter((item) => item.title && item.title !== null);
            } else {
                allProducts = [];
            }
            return {
                ...state,
                all_products: allProducts
            };
        case GET_CALENDAR_BOOKED_DETAILS:
            return {
                ...state,
                calendar_booked_details: action.payload?.data
            };
        default:
            return state;
    }
};

export default bookingReducer;

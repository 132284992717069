/* eslint-disable no-prototype-builtins */
import React, { Component } from 'react';
import { Button, Modal, Row, Col, ModalBody, ModalHeader } from 'reactstrap';
import { Form, Input, Select } from 'antd';
import PropTypes from 'prop-types';
import {
    connectStripeAccount,
    updateBankAccountInformation,
    createBankAccountInformation,
    verifyCountryCode,
    createPaystackAccount,
    getBanksPaystack,
    verifyPaystack
} from 'store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { SelectCountry, PhoneCountryCode } from 'components/atoms';
import { GoogleAutoComplete } from 'components/atoms';

class AddPayoutSettingsModal extends Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            countryOptions: [],
            supportStatus: {}
        };
    }

    componentDidMount() {
        const { banks_paystack, getBanksPaystack } = this.props;
        //this will help to update the phone country code field
        setTimeout(() => this.forceUpdate(), 1000);
        if (Array.isArray(banks_paystack) && banks_paystack.length < 1) getBanksPaystack();
    }

    componentDidUpdate(prevProps) {
        if (prevProps?.showModal && this.props.showModal === false)
            if (this.formRef.current) this.formRef.current.resetFields();

        if (prevProps?.showModal === false && this.props.showModal) {
            setTimeout(() => this.forceUpdate(), 1000);
        }
    }

    onChangeCountry = (value) => {
        let countryDetail = this.props.countries.find((element) => element?.name === value);
        if (countryDetail)
            this.props.verifyCountryCode(countryDetail?.country_code).then((res) => {
                this.setState({ supportStatus: res });
            });
    };

    onFinish = () => {
        const { business_bank_account, banks_paystack } = this.props;
        const { supportStatus, paystackRes } = this.state;

        if (supportStatus?.gateway === 'paystack') {
            const { account_number, bank } = this.formRef?.current.getFieldsValue();
            let bankDetail = banks_paystack.find((val) => val.name === bank);

            if (paystackRes?.data && paystackRes?.data.hasOwnProperty('account_name'))
                this.props
                    .createPaystackAccount({ account_number, bank_code: bankDetail?.code })
                    .then((res) => {
                        if (res) this.props.closeAndUpdateUser();
                    });
            else
                this.props
                    .verifyPaystack({ account_number, bank_code: bankDetail?.code })
                    .then((res) => {
                        this.setState({ paystackRes: res });
                    });
        } else if (business_bank_account && Object.keys(business_bank_account).length > 0)
            this.props
                .updateBankAccountInformation(this.formRef?.current.getFieldsValue())
                .then((res) => {
                    if (res?.payload) this.onboradToStripe();
                });
        else
            this.props
                .createBankAccountInformation(this.formRef?.current.getFieldValue())
                .then((res) => {
                    if (res?.payload) this.onboradToStripe();
                });
    };

    onboradToStripe = () => {
        const country = this.formRef?.current.getFieldValue().account_country;
        let countryDetail = this.props.countries.find((element) => element?.name === country);

        this.props
            .connectStripeAccount({ country_code: countryDetail.country_code })
            .then((res) => {
                if (res) {
                    this.props.toggleModal();
                    window.location.assign(res?.payload?.data?.linkedAccountdata.url);
                }
            });
    };

    render() {
        const { supportStatus, paystackRes } = this.state;
        const { showModal, toggleModal, business_bank_account, title, banks_paystack } = this.props;

        let selectCode = '';
        if (this.formRef?.current) {
            const country = this.formRef?.current.getFieldValue().account_country;
            let countryDetail = this.props.countries.find((element) => element?.name === country);
            selectCode = countryDetail?.phone_code || '';
        }
        return (
            <div>
                <Modal isOpen={showModal} toggle={toggleModal} className="modal-dialog-centered">
                    <ModalHeader>
                        <Row className="ml-0 mr-0 pl-0 pr-0">
                            <Col>
                                <h3>{title}</h3>
                            </Col>
                        </Row>
                    </ModalHeader>
                    <ModalBody className="">
                        {/* {loading && (
                            <Loader />
                        )} */}

                        <Form
                            ref={this.formRef}
                            onFinish={this.onFinish}
                            layout="vertical"
                            className="form-label-fw-500 mx-auto d-flex flex-column"
                            style={{ maxWidth: 600, minHeight: 400 }}>
                            <Row className="row-lg">
                                <Col md={6}>
                                    <SelectCountry
                                        fieldName="account_country"
                                        label="Select country"
                                        placeHolder="Country"
                                        initialValue={
                                            business_bank_account
                                                ? business_bank_account.account_country
                                                : undefined
                                        }
                                        onSelect={this.onChangeCountry}
                                    />
                                </Col>

                                {supportStatus?.message && !supportStatus?.status && (
                                    <Col xs={12}>
                                        <Row className="row-lg">
                                            <Col>
                                                <p>{supportStatus.message}</p>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}

                                {supportStatus?.gateway === 'transferwise' ? (
                                    <Col xs={12}>
                                        <Row className="row-lg">
                                            <Col>
                                                {`At the moment, we do not support direct payments to ${supportStatus?.name}. Please create a USD account on Wise and use that to continue with your payout set up.`}

                                                <div className="my-3 d-flex gap-2 align-items-center">
                                                    <Button
                                                        style={{ width: 100 }}
                                                        onClick={() =>
                                                            window.open(
                                                                'https://wise.prf.hn/click/camref:1100ljSuU',
                                                                '_blank'
                                                            )
                                                        }
                                                        color="primary">
                                                        USD
                                                    </Button>
                                                    <div>
                                                        <span>United states of America</span>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                ) : supportStatus?.gateway === 'paystack' ? (
                                    <>
                                        <Col md={6}>
                                            <Form.Item
                                                className="mb-3"
                                                label="Bank account number"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please enter bank account number'
                                                    }
                                                ]}
                                                name="account_number">
                                                <Input
                                                    onChange={() =>
                                                        this.setState({ paystackRes: null })
                                                    }
                                                    placeholder="Bank account number"
                                                />
                                            </Form.Item>
                                            {paystackRes &&
                                                !paystackRes?.data?.hasOwnProperty(
                                                    'account_name'
                                                ) && (
                                                    <span className="text-danger">
                                                        {paystackRes?.message || ''}
                                                    </span>
                                                )}
                                        </Col>
                                        <Col md={6}>
                                            <Form.Item
                                                className="mb-3"
                                                label="Bank"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select bank'
                                                    }
                                                ]}
                                                name="bank">
                                                <Select
                                                    autoComplete="none"
                                                    className="d-block"
                                                    placeholder="Bank"
                                                    showSearch
                                                    loading={this.props.loading}
                                                    onChange={() =>
                                                        this.setState({ paystackRes: null })
                                                    }>
                                                    {Array.isArray(banks_paystack) &&
                                                        banks_paystack.map((item, index) => (
                                                            <Select.Option
                                                                value={item.name}
                                                                key={index}>
                                                                {item.name}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        {paystackRes?.data?.account_name && (
                                            <Col md={6}>
                                                <div className="ant-col ant-form-item-label">
                                                    Account holder name
                                                </div>
                                                <Input
                                                    style={{ color: 'rgba(0, 0, 0, 0.60)' }}
                                                    disabled
                                                    placeholder="Bank account name"
                                                    value={paystackRes?.data?.account_name}
                                                />
                                            </Col>
                                        )}
                                    </>
                                ) : (
                                    (supportStatus?.status ||
                                        supportStatus.gateway === 'stripe' ||
                                        (business_bank_account?.account_country &&
                                            Object.keys(supportStatus).length < 1)) && (
                                        <>
                                            {/* <Col md={6}>
                                                    <Form.Item
                                                        className="mb-3"
                                                        label="Account holder name"
                                                        initialValue={
                                                            business_bank_account
                                                                ? business_bank_account.account_holder_name
                                                                : ''
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter name'
                                                            }
                                                        ]}
                                                        name="account_holder_name">
                                                        <Input placeholder="name" />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={6}>
                                                    <Form.Item
                                                        className="mb-3"
                                                        label="Account number"
                                                        initialValue={
                                                            business_bank_account
                                                                ? business_bank_account.account_number
                                                                : ''
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please enter account number'
                                                            }
                                                        ]}
                                                        name="account_number">
                                                        <Input placeholder="Account number" />
                                                    </Form.Item>
                                                </Col>

                                                <Col md={6}>
                                                    <Form.Item
                                                        className="mb-3"
                                                        label="Account Routing number"
                                                        initialValue={
                                                            business_bank_account
                                                                ? business_bank_account.account_routing_number
                                                                : ''
                                                        }
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please enter routing number'
                                                            }
                                                        ]}
                                                        name="account_routing_number">
                                                        <Input placeholder="Routing number" />
                                                    </Form.Item>
                                                </Col> */}
                                            <Col md={6}>
                                                <Form.Item
                                                    className="mb-3"
                                                    label="Business type"
                                                    initialValue={
                                                        business_bank_account
                                                            ? business_bank_account.business_type
                                                            : null
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter business type'
                                                        }
                                                    ]}
                                                    name="business_type">
                                                    <Select className="d-block" placeholder="Type">
                                                        <Select.Option value={'individual'} key={1}>
                                                            Individual
                                                        </Select.Option>
                                                        <Select.Option value={'company'} key={2}>
                                                            Company
                                                        </Select.Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Item
                                                    className="mb-3"
                                                    label="Address"
                                                    initialValue={
                                                        business_bank_account
                                                            ? business_bank_account.account_address
                                                            : ''
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter address'
                                                        }
                                                    ]}
                                                    name="account_address">
                                                    <Input placeholder="address" />
                                                </Form.Item>
                                            </Col>

                                            {/* <Col md={6}>
                                                <Form.Item
                                                    className="mb-3"
                                                    label="State"
                                                    initialValue={
                                                        business_bank_account
                                                            ? business_bank_account.account_state
                                                            : ''
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter state'
                                                        }
                                                    ]}
                                                    name="account_state">
                                                    <Input placeholder="State" />
                                                </Form.Item>
                                            </Col> */}

                                            <Col md={6}>
                                                <Form.Item
                                                    className="mb-3"
                                                    label="Zip/Post code"
                                                    initialValue={
                                                        business_bank_account
                                                            ? business_bank_account.account_zip_code
                                                            : ''
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter zip/post code'
                                                        }
                                                    ]}
                                                    name="account_zip_code">
                                                    <Input placeholder="Zip/post code" />
                                                </Form.Item>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Item
                                                    name="city_id"
                                                    initialValue={
                                                        business_bank_account
                                                            ? business_bank_account.account_city
                                                            : ''
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter city'
                                                        }
                                                    ]}>
                                                    <div className="input-title">
                                                        Search and select location
                                                    </div>
                                                    <GoogleAutoComplete
                                                        placeholder="Search location"
                                                        containerClassName="profile-field"
                                                        fieldName="city_id"
                                                        defValue={
                                                            business_bank_account
                                                                ? business_bank_account.account_city
                                                                : ''
                                                        }
                                                    />
                                                </Form.Item>
                                                {/* <Form.Item
                                                    className="mb-3"
                                                    label="City"
                                                    initialValue={
                                                        business_bank_account
                                                            ? business_bank_account.account_city
                                                            : ''
                                                    }
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please enter city'
                                                        }
                                                    ]}
                                                    name="account_city">
                                                    <Input placeholder="City" />
                                                </Form.Item> */}
                                            </Col>

                                            <Col md={6}>
                                                <PhoneCountryCode
                                                    label="Bank account holder phone number"
                                                    phoneNumberFieldName="account_contact"
                                                    selectedCountryCode={selectCode}
                                                    disabled={true}
                                                    // onChangeCountryCode={(code) => {
                                                    //     setSelectCode(code);
                                                    //     //setSelectCode(code); //new
                                                    //     form.validateFields(['selectCode']);
                                                    // }}
                                                    // expandMenu={isSelectCode}
                                                    // toggleMenu={togglePhone}
                                                    phoneNumber={
                                                        business_bank_account
                                                            ? business_bank_account?.account_contact
                                                            : ''
                                                    }
                                                />
                                            </Col>
                                        </>
                                    )
                                )}
                            </Row>

                            <div className="d-flex justify-content-center gap-3 mt-auto">
                                {supportStatus?.gateway === 'transferwise' ? (
                                    <Button
                                        onClick={() =>
                                            window.open(
                                                'https://wise.prf.hn/click/camref:1100ljSuU',
                                                '_blank'
                                            )
                                        }
                                        color="primary">
                                        Create Wise Account
                                    </Button>
                                ) : (
                                    (supportStatus?.status ||
                                        (business_bank_account?.account_country &&
                                            Object.keys(supportStatus).length < 1)) && (
                                        <Button color="primary" htmlType="submit">
                                            {`${
                                                supportStatus?.gateway === 'paystack' &&
                                                !paystackRes?.data?.hasOwnProperty('account_name')
                                                    ? 'Verify'
                                                    : 'Confirm and Continue'
                                            } `}
                                        </Button>
                                    )
                                )}

                                <Button onClick={toggleModal} color="dark">
                                    CLOSE
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

AddPayoutSettingsModal.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    showModal: PropTypes.bool,
    toggleModal: PropTypes.func,
    loading: PropTypes.bool,
    connectStripeAccount: PropTypes.func,
    showStripeModal: PropTypes.func,
    updateBankAccountInformation: PropTypes.func,
    createBankAccountInformation: PropTypes.func,
    business_bank_account: PropTypes.object,
    countries: PropTypes.array,
    verifyCountryCode: PropTypes.func,
    createPaystackAccount: PropTypes.func,
    closeAndUpdateUser: PropTypes.func,
    banks_paystack: PropTypes.array,
    getBanksPaystack: PropTypes.func,
    verifyPaystack: PropTypes.func
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    business_bank_account: state.profile.business_bank_account || {},
    countries: state.profile.countries,
    banks_paystack: state.profile.banks_paystack
});
const mapDispatch = (dispatch) => ({
    connectStripeAccount: (data) => dispatch(connectStripeAccount(data)),
    createBankAccountInformation: (data) => dispatch(createBankAccountInformation(data)),
    updateBankAccountInformation: (data) => dispatch(updateBankAccountInformation(data)),
    verifyCountryCode: (code) => dispatch(verifyCountryCode(code)),
    createPaystackAccount: (data) => dispatch(createPaystackAccount(data)),
    getBanksPaystack: () => dispatch(getBanksPaystack()),
    verifyPaystack: (data) => dispatch(verifyPaystack(data))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(AddPayoutSettingsModal));

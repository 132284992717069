/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import './index.scss';
import Plus from '../../../assets/images/plus.svg';
import Spacer from '../spacer';
import EditIcon from '../../../assets/images/edit-black.svg';
import DeleteIcon from '../../../assets/images/delete-black.svg';
import { Input } from 'antd';
import Proptypes from 'prop-types';

export default function AddButton(props) {
    const [selectCategory, setSelectCategory] = useState(null);
    const [selectTitle, setSelectTitle] = useState({});
    const [otherCategory, setOtherCategory] = useState('');
    const [otherTitle, setOtherTitle] = useState('');
    const [isDropdownCategory, setIsDropdownCategory] = useState(false);
    const [isDropdownTitle, setIsDropdownTitle] = useState(false);
    const [isEdit, setIsEdit] = useState(null);

    const toggleCategory = () => setIsDropdownCategory(!isDropdownCategory);
    const toggleTitle = () => (selectCategory ? setIsDropdownTitle(!isDropdownTitle) : null);

    //adding to inclusions or exclusions
    const onAdd = () => {
        const isAdded = props.value?.findIndex((res) => res.title === selectTitle.title);
        if (
            isAdded === -1 &&
            selectCategory?.category !== 'Other' &&
            selectTitle?.title &&
            selectTitle?.title !== 'Other'
        ) {
            let categories = { category: selectCategory.category, title: selectTitle.title };
            if (isEdit === null) {
                props.onAdd(categories);
                doRest();
            } else {
                props.onEdit(categories, isEdit);
                doRest();
            }
        } else if (
            isAdded === -1 &&
            selectTitle?.title === 'Other' &&
            selectCategory?.category !== 'Other' &&
            otherTitle !== ''
        ) {
            let categories = { category: selectCategory.category, title: otherTitle };
            if (isEdit === null) {
                props.onAdd(categories);
                doRest();
            } else {
                props.onEdit(categories, isEdit);
                doRest();
            }
        } else if (otherCategory !== '' && otherTitle !== '') {
            let categories = { category: otherCategory, title: otherTitle };
            if (isEdit === null) {
                props.onAdd(categories);
                doRest();
            } else {
                props.onEdit(categories, isEdit);
                doRest();
            }
        }
    };

    function doRest() {
        setSelectTitle('');
        setSelectCategory(null);
        setOtherTitle('');
        setOtherCategory('');
        if (isEdit !== null) setIsEdit(null);
    }

    const onCancel = () => {
        doRest();
        props.onCancel();
    };

    //editing to inclusions or exclusions
    const onEdit = (category, title, index) => {
        const categories = props.categories.findIndex(
            (res) => res?.category?.toLocaleLowerCase() === category?.toLocaleLowerCase()
        );
        if (categories !== -1) {
            const selectedTitle = props.categories[categories].inclusionSub.findIndex(
                (res) => res?.title?.toLocaleLowerCase() === title?.toLocaleLowerCase()
            );
            setSelectCategory(props.categories[categories]);
            setSelectTitle(props.categories[categories].inclusionSub[selectedTitle]);
            setIsEdit(index);
        } else if (category && title) {
            setSelectCategory(props?.categories[3]);
            setSelectTitle(props?.categories[3]?.inclusionSub[0]);
            setOtherCategory(category);
            setOtherTitle(title);
            setIsEdit(index);
        }
        props.onClickAdd();
    };

    function validateAlreadySelectedCategory(subCategory) {
        let selectedSubCategory = -1;
        if (subCategory?.title !== 'Other') {
            selectedSubCategory = props.value?.findIndex(
                (res) => res?.title?.toLocaleLowerCase() === subCategory?.title?.toLocaleLowerCase()
            );
        } else {
            selectedSubCategory = props.value?.findIndex(
                (res) => res?.title?.toLocaleLowerCase() === otherTitle?.toLocaleLowerCase()
            );
        }

        if (selectedSubCategory > -1) return true;
        else return false;
    }

    function onClickAdd() {
        setSelectCategory(null);
        setSelectTitle({});
        props.onClickAdd();
    }

    return (
        <div className="add-with-category">
            <div className="card-head">
                <div style={{ display: 'flex' }}>
                    <div className="title">{props.name}</div>
                </div>
                <Button color="link" onClick={onClickAdd}>
                    <img className="img-plus" src={Plus} alt="" /> Add
                </Button>
            </div>
            {props.isAdd
                ? [
                      <p className="description" key="p1">
                          Make your list precise so travelers understand the value of this
                          experience. Don’t add more than 10 items and avoid duplicates.
                      </p>,

                      <div className="category-block" key="div1">
                          <div className="category">Category</div>
                          <Dropdown isOpen={isDropdownCategory} toggle={toggleCategory}>
                              <DropdownToggle className="dropdown-category" caret>
                                  {selectCategory ? selectCategory.category : 'Select a category'}
                              </DropdownToggle>
                              <DropdownMenu style={{ width: '100%' }}>
                                  {props.categories && props.categories.length > 0
                                      ? props.categories.map((res, i) => [
                                            <DropdownItem
                                                onClick={() => setSelectCategory(res)}
                                                key={i + 'cat-up'}
                                                className="category-item">
                                                {res.category}
                                            </DropdownItem>,
                                            <DropdownItem key={i + 'cat-down'} divider />
                                        ])
                                      : []}
                              </DropdownMenu>
                          </Dropdown>
                          {!selectCategory?.category && (
                              <div className="error">Please fill these fields in.</div>
                          )}

                          {selectCategory && selectCategory.category === 'Other' ? (
                              <Input
                                  required
                                  placeholder="Enter Category"
                                  className="input-type"
                                  value={otherCategory}
                                  onChange={(e) => {
                                      setOtherCategory(e.target.value);
                                  }}
                              />
                          ) : null}

                          {selectCategory &&
                              selectCategory.category === 'Other' &&
                              !otherCategory && (
                                  <div className="error">Please fill these fields in.</div>
                              )}
                      </div>,

                      <Spacer top={15} key="spa1" />,

                      <div className="category-block" key="div2">
                          <div className="category">Type</div>
                          <Dropdown isOpen={isDropdownTitle} toggle={toggleTitle}>
                              <DropdownToggle className="dropdown-category" caret>
                                  {Object.keys(selectTitle).length > 0
                                      ? selectTitle.title
                                      : 'Select a type'}
                              </DropdownToggle>
                              <DropdownMenu style={{ width: '100%' }}>
                                  {selectCategory && selectCategory.inclusionSub.length > 0
                                      ? selectCategory.inclusionSub.map((res, i) => [
                                            <DropdownItem
                                                onClick={() => setSelectTitle(res)}
                                                key={i + 'ty-up'}
                                                className="category-item">
                                                {res.title}
                                            </DropdownItem>,
                                            <DropdownItem key={i + 'ty-down'} divider />
                                        ])
                                      : []}
                              </DropdownMenu>
                          </Dropdown>

                          {!selectTitle?.title && (
                              <div className="error">Please fill these fields in.</div>
                          )}
                          {selectTitle &&
                              selectTitle.title !== 'Other' &&
                              validateAlreadySelectedCategory(selectTitle) && (
                                  <div className="error">This title has been already selected.</div>
                              )}

                          {selectTitle.title === 'Other' ? (
                              <Input
                                  required
                                  placeholder="Enter Type"
                                  className="input-type"
                                  value={otherTitle}
                                  onChange={(e) => {
                                      setOtherTitle(e.target.value);
                                  }}
                              />
                          ) : null}

                          {selectTitle?.title === 'Other' && !otherTitle && (
                              <div className="error">Please fill these fields in.</div>
                          )}
                          {selectTitle &&
                              otherTitle &&
                              selectTitle.title === 'Other' &&
                              validateAlreadySelectedCategory(selectTitle) && (
                                  <div className="error">This title has been already selected.</div>
                              )}
                      </div>,

                      <div className="d-flex flex-wrap gap-2 py-3" key="div3">
                          <Button
                              color="primary"
                              className="btn-mw btn-block btn-sm-inline-block m-0"
                              onClick={() => {
                                  if (!validateAlreadySelectedCategory(selectTitle)) {
                                      onAdd();
                                  }
                              }}>
                              Add
                          </Button>
                          <Button
                              color="dark"
                              className="btn-mw btn-block btn-sm-inline-block m-0"
                              onClick={onCancel}>
                              Cancel
                          </Button>
                      </div>
                  ]
                : null}

            {props.value && props.value.length > 0
                ? props.value.map((res, i) => (
                      <div className="selected-title" key={i + 'li'}>
                          {res.title}
                          <div>
                              <img
                                  src={EditIcon}
                                  className="cursor-pointer"
                                  alt=""
                                  title="Edit"
                                  onClick={() => {
                                      onEdit(res.category, res.title, i);
                                  }}
                              />
                              <img
                                  src={DeleteIcon}
                                  className="cursor-pointer"
                                  alt=""
                                  title="Delete"
                                  onClick={() => props.onDelete(i)}
                              />
                          </div>
                      </div>
                  ))
                : null}

            <Spacer top={props.value && props.value.length > 0 ? 14 : 0} />
        </div>
    );
}

AddButton.propTypes = {
    name: Proptypes.string,
    onClick: Proptypes.func,
    onAdd: Proptypes.func,
    onEdit: Proptypes.func,
    onCancel: Proptypes.func,
    onDelete: Proptypes.func,
    onClickAdd: Proptypes.func,
    isRecommended: Proptypes.bool,
    isAdd: Proptypes.bool,
    value: Proptypes.array,
    categories: Proptypes.array
};

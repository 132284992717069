import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailedPanel from './panel';
import { ExtraInfoIcon } from './panel/icons';
import CollapseMoreLess from '../../collapse-more-less';

const ExtraInfoPanel = () => {
    return (
        <DetailedPanel panelIcon={<ExtraInfoIcon />} panelTitle="Extra info" isEditable={true}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Wheelchair accessibility
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">No</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Stroller accessible</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">Yes</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Service animals Allowed
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">No</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                            Can travelers easily arrive/depart on public transportation?
                        </span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs="auto" style={{ minWidth: 60 }}>
                    <span className="fs-18 lh-1-7">Yes</span>
                </Col>
            </Row>
            <CollapseMoreLess>
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">
                                Are infants required to sit on laps?
                            </span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs="auto" style={{ minWidth: 60 }}>
                        <span className="fs-18 lh-1-7">No</span>
                    </Col>
                </Row>
            </CollapseMoreLess>
        </DetailedPanel>
    );
};

export default ExtraInfoPanel;

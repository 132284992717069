import React from 'react';
import PropTypes from 'prop-types';

export default function HeaderDotted({ title, line, top }) {
    return (
        <div style={{ marginTop: top }} className="row d-flex align-items-center">
            <div className="col-md-4">
                <img className="move-left" src={line} alt="" />
            </div>
            <div className="col-md-4">
                <h5 style={{ textAlign: 'center' }} className="bold-font">
                    {title}
                </h5>
            </div>
            <div className="col-md-4">
                <img className="move-right" src={line} alt="" />
            </div>
        </div>
    );
}

HeaderDotted.propTypes = {
    title: PropTypes.string,
    line: PropTypes.string,
    top: PropTypes.string
};

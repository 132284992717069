/* eslint-disable no-undef */
import axios from 'axios';
//import { toggleToastAble } from '../store/actions/toastActions';
export default {
    setupInterceptors: () => {
        // Add a response interceptor
        axios.interceptors.response.use(
            function (response) {
                // if (response.data.code >= 400 && response.data.code <= 500) {
                //     // in case response status is 200 and response code is >= 400
                //     toggleToastAble({
                //         message: response.data.message,
                //         success: false,
                //         toast: true
                //     });
                // }

                // let dataReturn = {};

                // if (typeof response.data == 'string') {
                //     dataReturn['data'] = response.data;
                // } else {
                //     dataReturn = response.data;
                // }

                // if (response?.status) {
                //     dataReturn['code'] = response?.status;
                // }
                // return dataReturn;
                return response;
            },
            function (error) {
                // in case request error code is >= 400

                //catches if the session ended!

                if (
                    (error &&
                        error.response &&
                        error.response.statusText == 'Unauthorized' &&
                        error.response.status == 401) ||
                    (error && error.message == 'Request failed with status code 401')
                ) {
                    // localStorage.clear();
                    // window.location.replace('/');
                }

                if (error && error.response && error?.response?.status) {
                    localStorage.setItem('loader', false);
                    //     // if (error?.response?.data?.message) {
                    //     //     toggleToastAble({
                    //     //         message: error?.response?.data?.message,
                    //     //         success: false,
                    //     //         toast: true
                    //     //     });
                    //     // }
                    //     // let dataReturn = {
                    //     //     data: error?.response?.data,
                    //     //     code: error?.response?.status
                    //     // };
                    return error.response;
                }
            }
        );
    }
};

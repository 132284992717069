const INITIAL_STATE = {
    isSubmitting: false,
    application: {
        first_name: '',
        last_name: '',
        email: '',
        contact_number: '',
        // gender: "",
        company_name: '',
        company_url: '',
        trip_advisor_listing_url: '',
        booking_com_listing_url: '',
        other_listing_urls: ''
    }
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case 'SET_APPLICATION_FIELD': {
            let application = { ...state.application };
            application = {
                ...application,
                ...payload
            };
            return {
                ...state,
                application
            };
        }
        case 'SET_SUBMIT_STATE': {
            return {
                ...state,
                isSubmitting: payload
            };
        }

        case 'SAVE_APPLICATION_DATA': {
            return {
                ...state,
                save_application: payload?.data
            };
        }

        default:
            return state;
    }
};

import React from 'react';

const NightLifeIcon = () => {
    return (
        <div className="timeline-icon-border mr-2">
            <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0)">
                    <path
                        d="M11.4271 16.972V11.283L20.5 0.394043H0.5L9.57285 11.283V16.972C6.94153 17.1859 4.9329 18.2784 4.9329 19.6056H16.0694C16.0694 18.2784 14.0608 17.1844 11.4271 16.972ZM10.8664 6.74848C10.3445 6.74848 9.92201 6.32597 9.92201 5.80261C9.92201 5.28078 10.3453 4.85674 10.8664 4.85674C11.3889 4.85674 11.813 5.28078 11.813 5.80261C11.8145 6.3252 11.3889 6.74848 10.8664 6.74848ZM16.9816 2.04282L16.1687 3.01772L6.21418 4.67643L4.01836 2.04282H16.9816Z"
                        fill="#333333"
                    />
                </g>
                <defs>
                    <clipPath id="clip0">
                        <rect width="20" height="20" fill="white" transform="translate(0.5)" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default NightLifeIcon;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import './index.scss';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Counter from '../../../../atoms/counter';
import Down from '../../../../../assets/images/down-caret.svg';
import Caret from '../../../../../assets/images/caret.svg';
import Double from '../../../../../assets/images/double.svg';
import Sofa from '../../../../../assets/images/sofa.svg';
import Single from '../../../../../assets/images/single.svg';
import Bunk from '../../../../../assets/images/bunk.svg';
import { Checkbox, Form } from 'antd';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import Proptypes from 'prop-types';
import ConfirmationModal from '../../../modals/confirmation-modal';
import { compareRooms } from 'utils';

function WhereSleep(props) {
    const [accomodationData, setAccomodationData] = useState([
        {
            title: 'Double bed',
            subtitle: '131 - 150 cm wide',
            image: Double,
            count: 0
        },
        {
            title: 'Large bed(King size)',
            subtitle: '151 - 180 cm wide',
            image: Double,
            count: 0
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: '181 - 210 cm wide',
            image: Double,
            count: 0
        }
    ]);
    const [accomodationTwin, setAccomodationTwin] = useState([
        {
            title: 'Single Bed x 2',
            subtitle: '90 - 130 cm wide',
            image: Single,
            count: 0
        },
        {
            title: 'Bunk bed',
            subtitle: 'Variable size',
            image: Bunk,
            count: 0
        }
    ]);
    const [singleRoom, setSingleRoom] = useState([
        {
            title: 'Single Bed',
            subtitle: '90 - 130 cm wide',
            image: Single,
            count: 0
        }
    ]);
    const [doubleRoom, setDoubleRoom] = useState([
        {
            title: 'Double Bed',
            subtitle: '90 - 130 cm wide',
            image: Single,
            count: 0
        },
        {
            title: 'Large bed (King size)',
            subtitle: '150 - 180 cm wide',
            image: Single,
            count: 0
        },
        {
            title: 'Extra-large double bed (Super-king-size)',
            subtitle: '181 - 210 cm wide',
            image: Single,
            count: 0
        }
    ]);
    const [doubleMoreOption, setDoubleMoreOption] = useState([
        {
            title: 'Sofa bed',
            subtitle: 'Variable size',
            image: Sofa,
            countSharing: 0,
            countSingle: 0
        },
        {
            title: 'Futon Mat',
            subtitle: 'Variable size',
            image: Double,
            countSharing: 0,
            countSingle: 0
        }
    ]);

    const [perPerson, setPerPerson] = useState(false);
    const changePerPerson = () => setPerPerson(!perPerson);

    const [double, setDouble] = useState(false);
    const changeDouble = () => setDouble(!double);

    const [options, setOptions] = useState(3);
    const [optionDown, setoptionDown] = useState(false);

    const [countBathroom, setCountBathroom] = useState(1);
    const [confirmationModal, setConfirmationModal] = useState(false);

    const showOption = (key) => {
        setOptions(6);
        setoptionDown(key);
    };
    const closeOption = (key) => {
        setOptions(3);
        setoptionDown(!key);
    };
    useEffect(() => {
        if (perPerson || double) {
            form.setFields([
                {
                    name: 'perPersonCheck',
                    errors: []
                }
            ]);
        }
    }, [perPerson, double]);
    //loading data
    useEffect(() => {
        if (props.data?.pp_sharing) {
            const pp_sharing = props.data.pp_sharing;
            let tmpAccomodationData = accomodationData;
            let tmpDoubleMoreOption = doubleMoreOption;
            let tmpAccomodationTwin = accomodationTwin;

            tmpAccomodationData[0].count = pp_sharing.double.double_bed;
            tmpAccomodationData[1].count = pp_sharing.double.large_bed;
            tmpAccomodationData[2].count = pp_sharing.double.xl_double_bed;
            tmpDoubleMoreOption[0].countSharing = pp_sharing.double.sofa_bed;
            tmpDoubleMoreOption[1].countSharing = pp_sharing.double.futon_mat;
            tmpAccomodationTwin[0].count = pp_sharing.twin.single_bed;
            tmpAccomodationTwin[1].count = pp_sharing.twin.bunk_bed;

            setAccomodationData(tmpAccomodationData);
            setDoubleMoreOption(tmpDoubleMoreOption);
            setAccomodationTwin(tmpAccomodationTwin);
            setCountBathroom(props.data.no_of_bathrooms);
            setPerPerson(true);
            // setDouble(false);
            if (pp_sharing.double.sofa_bed || pp_sharing.double.futon_mat) showOption('optionDown');
        }
        // else
        if (props.data?.pp_single) {
            const pp_single = props.data.pp_single;
            let tmpSingleRoom = singleRoom;
            let tmpDoubleRoom = doubleRoom;
            let tmpDoubleMoreOption = doubleMoreOption;

            tmpSingleRoom[0].count = pp_single.single.bed;
            tmpDoubleRoom[0].count = pp_single.double.double_bed;
            tmpDoubleRoom[1].count = pp_single.double.large_bed;
            tmpDoubleRoom[2].count = pp_single.double.xl_double_bed;
            tmpDoubleMoreOption[0].countSingle = pp_single.double.sofa_bed;
            tmpDoubleMoreOption[1].countSingle = pp_single.double.futon_mat;

            setSingleRoom(tmpSingleRoom);
            setDoubleRoom(tmpDoubleRoom);
            setDoubleMoreOption(tmpDoubleMoreOption);
            setCountBathroom(props.data.no_of_bathrooms);
            setDouble(true);
            // setPerPerson(false);
            if (pp_single.double.sofa_bed || pp_single.double.futon_mat) showOption('optionDown');
        }
    }, [props.data, props.isOpen]);

    const [form] = Form.useForm();

    const onFinish = () => {
        const { selected_existing_product, data } = props;

        form.validateFields()
            .then(() => {
                if (selected_existing_product && data)
                    if (
                        compareRooms(data, {
                            accomodationData,
                            doubleMoreOption,
                            accomodationTwin,
                            singleRoom,
                            doubleRoom,
                            countBathroom,
                            double,
                            perPerson
                        })
                    )
                        return setConfirmationModal(true);
                    else props.onClose();
                else onSave();
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onSave = () => {
        form.validateFields()
            .then(() => {
                const pp_sharing = {
                    double: {
                        double_bed: accomodationData[0].count,
                        large_bed: accomodationData[1].count,
                        xl_double_bed: accomodationData[2].count,
                        sofa_bed: doubleMoreOption[0].countSharing,
                        futon_mat: doubleMoreOption[1].countSharing
                    },
                    twin: {
                        single_bed: accomodationTwin[0].count,
                        bunk_bed: accomodationTwin[1].count
                    }
                };
                const pp_single = {
                    single: {
                        bed: singleRoom[0].count
                    },
                    double: {
                        double_bed: doubleRoom[0].count,
                        large_bed: doubleRoom[1].count,
                        xl_double_bed: doubleRoom[2].count,
                        sofa_bed: doubleMoreOption[0].countSingle,
                        futon_mat: doubleMoreOption[1].countSingle
                    }
                };
                if (perPerson && !double) {
                    const rooms = {
                        pp_sharing,
                        no_of_bathrooms: countBathroom
                    };
                    props.onSave(rooms);
                    // onClear();
                } else if (perPerson && double) {
                    const rooms = {
                        pp_sharing,
                        pp_single,
                        no_of_bathrooms: countBathroom
                    };
                    props.onSave(rooms);
                    // onClear();
                } else if (!perPerson && double) {
                    const rooms = {
                        pp_single,
                        no_of_bathrooms: countBathroom
                    };
                    props.onSave(rooms);
                    // onClear();
                }
            })
            .catch((err) => {
                console.log('err', err);
            });
    };

    const onClear = () => {
        form.resetFields();
        setAccomodationData([
            {
                title: 'Double bed',
                subtitle: '131 - 150 cm wide',
                image: Double,
                count: 0
            },
            {
                title: 'Large bed(King size)',
                subtitle: '151 - 180 cm wide',
                image: Double,
                count: 0
            },
            {
                title: 'Extra-large double bed (Super-king-size)',
                subtitle: '181 - 210 cm wide',
                image: Double,
                count: 0
            }
        ]);
        setAccomodationTwin([
            {
                title: 'Single Bed x 2',
                subtitle: '90 - 130 cm wide',
                image: Single,
                count: 0
            },
            {
                title: 'Bunk bed',
                subtitle: 'Variable size',
                image: Bunk,
                count: 0
            }
        ]);
        setSingleRoom([
            {
                title: 'Single Bed',
                subtitle: '90 - 130 cm wide',
                image: Single,
                count: 0
            }
        ]);
        setDoubleRoom([
            {
                title: 'Double Bed',
                subtitle: '90 - 130 cm wide',
                image: Single,
                count: 0
            },
            {
                title: 'Large bed (King size)',
                subtitle: '150 - 180 cm wide',
                image: Single,
                count: 0
            },
            {
                title: 'Extra-large double bed (Super-king-size)',
                subtitle: '181 - 210 cm wide',
                image: Single,
                count: 0
            }
        ]);
        setDoubleMoreOption([
            {
                title: 'Sofa bed',
                subtitle: 'Variable size',
                image: Sofa,
                countSharing: 0,
                countSingle: 0
            },
            {
                title: 'Futon Mat',
                subtitle: 'Variable size',
                image: Double,
                countSharing: 0,
                countSingle: 0
            }
        ]);
        setPerPerson(false);
        setDouble(false);
        setOptions(3);
        setoptionDown(false);
        setCountBathroom(1);
    };

    const onClose = () => {
        props.onClose();
        onClear();
    };
    return (
        <Modal isOpen={props.isOpen} centered size="lg">
            <Form form={form} name="where-sleep-form" className="where-sleep" onFinish={onFinish}>
                <ModalHeader className="title">Where can people sleep?</ModalHeader>
                <ModalBody className="body-modal">
                    <Form.Item
                        name="perPersonCheck"
                        rules={[
                            {
                                required: !perPerson && !double ? true : false,
                                message: 'Please select one'
                            }
                        ]}>
                        <div>
                            <Checkbox checked={perPerson} onChange={changePerPerson}>
                                Per person sharing
                                <span className="check-box">
                                    This is for a person sharing a room with another person.
                                </span>
                            </Checkbox>
                            {perPerson && (
                                <div style={{ marginTop: '15px' }}>
                                    <div>
                                        <div>Double</div>
                                        <p className="subcategory">
                                            This refers to a bed enough for two adults
                                        </p>
                                        {accomodationData.slice(0, options).map((item, i) => (
                                            <div key={i} className="row item-sleep">
                                                <div className="col-md-1">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="item-sleep__title">
                                                        {item.title}
                                                    </p>
                                                    <p className="item-sleep__subtitle">
                                                        {item.subtitle}
                                                    </p>
                                                </div>
                                                <div className="col-md-5">
                                                    <Counter
                                                        initNumber={item.count}
                                                        onChange={(value) => (item.count = value)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        {!optionDown ? (
                                            <div
                                                onClick={() => showOption('optionDown')}
                                                className="row cursor-pointer prompter-rooms">
                                                <img
                                                    className="image-icon-down"
                                                    src={Down}
                                                    alt=""
                                                />
                                                <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                    More bed options
                                                </p>
                                            </div>
                                        ) : (
                                            <div
                                                style={{ marginBottom: 26 }}
                                                onClick={() => closeOption('optionDown')}
                                                className="row cursor-pointer prompter-rooms">
                                                <img className="image-icon" src={Caret} alt="" />
                                                <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                    Close bed options
                                                </p>
                                            </div>
                                        )}
                                        {optionDown
                                            ? doubleMoreOption.map((res, i) => (
                                                  <div key={i} className="row item-sleep">
                                                      <div className="col-md-1">
                                                          <img alt="" src={res.image} />
                                                      </div>
                                                      <div className="col-md-6">
                                                          <p className="item-sleep__title">
                                                              {res.title}
                                                          </p>
                                                          <p className="item-sleep__subtitle">
                                                              {res.subtitle}
                                                          </p>
                                                      </div>
                                                      <div className="col-md-5">
                                                          <Counter
                                                              initNumber={res.countSharing}
                                                              onChange={(value) =>
                                                                  (res.countSharing = value)
                                                              }
                                                          />
                                                      </div>
                                                  </div>
                                              ))
                                            : null}
                                        <hr />
                                        <p className="subcategory">
                                            This refers to a bed enough for two adults
                                        </p>
                                        {accomodationTwin.map((item, index) => (
                                            <div key={index} className="row item-sleep">
                                                <div className="col-md-1">
                                                    <img src={item.image} alt="" />
                                                </div>
                                                <div className="col-md-6">
                                                    <p className="item-sleep__title">
                                                        {item.title}
                                                    </p>
                                                    <p className="item-sleep__subtitle">
                                                        {item.subtitle}
                                                    </p>
                                                </div>
                                                <div className="col-md-5">
                                                    <Counter
                                                        initNumber={item.count}
                                                        onChange={(value) => (item.count = value)}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                            <Checkbox
                                checked={double}
                                onChange={changeDouble}
                                className="unselectable">
                                Per Person Single Room Occupancy
                                <span className="check-box">This is for one person in a room.</span>
                            </Checkbox>
                            {double && (
                                <div style={{ marginTop: '15px' }}>
                                    <div>Single</div>
                                    <p className="subcategory">
                                        This refers to a bed or room big enough for one average
                                        sized person.
                                    </p>
                                    {singleRoom.map((item, i) => (
                                        <div key={i} className="row item-sleep">
                                            <div className="col-md-1">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-6">
                                                <p className="item-sleep__title">{item.title}</p>
                                                <p className="item-sleep__subtitle">
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-5">
                                                <Counter
                                                    initNumber={item.count}
                                                    onChange={(value) => (item.count = value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    <hr />
                                    <div>Double</div>
                                    <p className="subcategory">
                                        This refers to a bed big enough for two adults.
                                    </p>
                                    {doubleRoom.map((item, index) => (
                                        <div key={index} className="row item-sleep">
                                            <div className="col-md-1">
                                                <img alt="" src={item.image} />
                                            </div>
                                            <div className="col-md-6">
                                                <p className="item-sleep__title">{item.title}</p>
                                                <p className="item-sleep__subtitle">
                                                    {item.subtitle}
                                                </p>
                                            </div>
                                            <div className="col-md-5">
                                                <Counter
                                                    initNumber={item.count}
                                                    onChange={(value) => (item.count = value)}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                    {!optionDown ? (
                                        <div
                                            onClick={() => showOption('optionDown')}
                                            className="row cursor-pointer prompter-rooms">
                                            <img className="image-icon-down" src={Down} alt="" />
                                            <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                More bed options
                                            </p>
                                        </div>
                                    ) : (
                                        <div
                                            style={{ marginBottom: 26 }}
                                            onClick={() => closeOption('optionDown')}
                                            className="row cursor-pointer prompter-rooms">
                                            <img className="image-icon" src={Caret} alt="" />
                                            <p style={{ marginBottom: 0, marginLeft: 10 }}>
                                                Close bed options
                                            </p>
                                        </div>
                                    )}
                                    {optionDown
                                        ? doubleMoreOption.map((res, i) => (
                                              <div key={i} className="row item-sleep">
                                                  <div className="col-md-1">
                                                      <img alt="" src={res.image} />
                                                  </div>
                                                  <div className="col-md-6">
                                                      <p className="item-sleep__title">
                                                          {res.title}
                                                      </p>
                                                      <p className="item-sleep__subtitle">
                                                          {res.subtitle}
                                                      </p>
                                                  </div>
                                                  <div className="col-md-5">
                                                      <Counter
                                                          initNumber={res.countSingle}
                                                          onChange={(value) =>
                                                              (res.countSingle = value)
                                                          }
                                                      />
                                                  </div>
                                              </div>
                                          ))
                                        : null}
                                </div>
                            )}
                        </div>
                    </Form.Item>
                    <hr width="100%" style={{ marginTop: '20px' }} />
                    <p style={{ fontWeight: '600' }}>How many bathrooms are there?</p>
                    <Counter
                        initNumber={countBathroom}
                        minNumber={1}
                        onChange={(value) => setCountBathroom(value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <div className="footer-btn">
                        <div>
                            <Button color="primary" type="submit">
                                {props.submitButtonName ?? 'Update Calender & Continue'}
                            </Button>
                            <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                                Cancel
                            </Button>
                        </div>
                        <Button color="dark" style={{ width: '137px' }}>
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </ModalFooter>
            </Form>

            <ConfirmationModal
                isModalOpen={confirmationModal}
                setModalOpenState={(value) => setConfirmationModal(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    onSave();
                    setConfirmationModal(false);
                }}
            />
        </Modal>
    );
}
WhereSleep.propTypes = {
    data: Proptypes.any,
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    submitButtonName: Proptypes.string,
    selected_existing_product: Proptypes.bool
};

const mapStateToProps = (state) => ({
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = () => ({});

export default withRouter(connect(mapStateToProps, mapDispatch)(WhereSleep));

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import './index.scss';
import DeleteWhite from '../../../../assets/images/delete--white.svg';
import Display from '../../../atoms/display';

function Media(props) {
    const { src, badge, caption, removeMedia } = props;
    return (
        <div className="media">
            <div className="media__extras">
                <Display show={!!badge}>
                    <div className="media__badge">{badge}</div>
                </Display>
                <div onClick={removeMedia} className="media__action">
                    <img src={DeleteWhite} alt="delete"></img>
                </div>
            </div>
            <div className="media--img">
                <img src={src} alt={src} style={{ width: 151, height: 84 }} />
            </div>
            <p className="media--caption">{caption}</p>
        </div>
    );
}

Media.propTypes = {
    removeMedia: PropTypes.func,
    src: PropTypes.string,
    caption: PropTypes.string,
    badge: PropTypes.string
};

export default Media;

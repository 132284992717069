import React from 'react';

const ShareIcon = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.8516 4.89844L9.85159 0.898475C9.75262 0.799501 9.63548 0.75 9.50005 0.75C9.36463 0.75 9.2474 0.799501 9.14851 0.898475C9.04954 0.997504 8.99998 1.1147 8.99998 1.2501V3.25008H7.25C3.53631 3.25008 1.2578 4.29959 0.41393 6.39855C0.137967 7.09638 0 7.96367 0 9.00012C0 9.86465 0.330744 11.0391 0.992178 12.5235C1.00778 12.5601 1.03503 12.6224 1.07416 12.7109C1.11326 12.7994 1.14837 12.8775 1.17962 12.9453C1.21098 13.0129 1.24485 13.0702 1.28128 13.117C1.34372 13.2056 1.41667 13.25 1.50002 13.25C1.57814 13.25 1.63936 13.224 1.68366 13.1719C1.72785 13.1199 1.74999 13.0547 1.74999 12.9768C1.74999 12.9297 1.74347 12.8608 1.73042 12.7696C1.7174 12.6784 1.71086 12.6174 1.71086 12.5861C1.68478 12.232 1.67175 11.9115 1.67175 11.6252C1.67175 11.0992 1.7174 10.6279 1.80846 10.2112C1.89967 9.79449 2.02595 9.43389 2.18745 9.12917C2.34892 8.82434 2.55719 8.56151 2.81246 8.34014C3.06763 8.11877 3.34233 7.93784 3.6366 7.79719C3.93092 7.65651 4.27727 7.54583 4.67571 7.46513C5.07412 7.38441 5.47514 7.32839 5.87883 7.29712C6.28253 7.26584 6.73955 7.2503 7.25 7.2503H8.99998V9.25034C8.99998 9.38573 9.04943 9.50296 9.14832 9.60188C9.24738 9.70074 9.36452 9.7503 9.49986 9.7503C9.63526 9.7503 9.75248 9.70074 9.85159 9.60188L13.8516 5.60177C13.9505 5.5028 14 5.38565 14 5.25023C13.9999 5.11484 13.9505 4.99761 13.8516 4.89844Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default ShareIcon;

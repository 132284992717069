/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import { Button, Col, Container, Input, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import {
    Button as AntButton,
    Form as AntForm,
    Input as AntInput,
    Checkbox as AntCheckbox,
    Select as AntSelect,
    Checkbox,
    Popover,
    Dropdown,
    Menu,
    TimePicker,
    Radio,
    InputNumber
} from 'antd';
import EditIcon from '../atoms/icons/edit-icon';
import Carousel from 'react-multi-carousel';
import { ComponentPermissionContext } from 'context/rolesandpermission';
const layout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 16
    }
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16
    }
};
const responsive = {
    eventGallery: {
        mobile: {
            breakpoint: { max: 99999999, min: 0 },
            items: 1
        }
    }
};

export default function Components() {
    const onFinish = () => {};
    const { assignComponentPermission } = useContext(ComponentPermissionContext);
    const onFinishFailed = () => {};
    return (
        <section className="static-section">
            <Container className="container-section">
                <h1 className="static-heading">All Components</h1>
                <h2>Buttons</h2>
                <h3>ReactStrap Buttons</h3>
                <div>
                    <Button size="sm">Small Button</Button> <Button color="primary">primary</Button>{' '}
                    <Button color="secondary">secondary</Button>{' '}
                    <Button color="success">success</Button> <Button color="info">info</Button>{' '}
                    <Button color="warning">warning</Button> <Button color="danger">danger</Button>{' '}
                    <Button color="dark">Dark</Button> <Button color="link">link</Button>{' '}
                    <Button size="lg">Apply Now</Button>{' '}
                    <Button size="lg" className="btn-mw">
                        Apply Now
                    </Button>{' '}
                </div>
                <h3>Ant Design Buttons</h3>
                <div>
                    <AntButton type="primary">Primary Button</AntButton>{' '}
                    <AntButton type="secondary" shape="round">
                        Secondary
                    </AntButton>{' '}
                    <AntButton>Default Button</AntButton>{' '}
                    <AntButton type="dashed">Dashed Button</AntButton> <br />
                    <AntButton type="text">Text Button</AntButton>{' '}
                    <AntButton type="link">Link Button</AntButton> <br />
                    <AntButton type="primary" size="small">
                        Primary Button
                    </AntButton>{' '}
                    <AntButton type="primary" size="middle">
                        Primary Button
                    </AntButton>{' '}
                    <AntButton type="primary" size="large">
                        Apply Now
                    </AntButton>{' '}
                    <br />
                    <AntButton type="primary" disabled>
                        Primary(disabled)
                    </AntButton>
                    <AntButton disabled>Default(disabled)</AntButton>
                </div>

                <hr />

                <h2>Inputs</h2>
                <h3>Reactstrap Inputs</h3>
                <div>
                    <Input placeholder="lg" bsSize="lg" />
                    <Input placeholder="default" />
                    <Input placeholder="sm" bsSize="sm" />
                    <Input type="select" bsSize="lg">
                        <option>Large Select 1</option>
                        <option>Large Select 2</option>
                        <option>Large Select 3</option>
                    </Input>
                    <Input type="select">
                        <option>Default Select</option>
                    </Input>
                    <Input type="select" bsSize="sm">
                        <option>Small Select</option>
                    </Input>
                </div>
                <div>
                    <h4>Input Group</h4>
                    <div className="mb-3">
                        <InputGroup size="sm">
                            <InputGroupAddon addonType="prepend">
                                <Button>I'm a button</Button>
                            </InputGroupAddon>
                            <Input />
                        </InputGroup>
                    </div>
                    <div className="mb-3">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <Button>I'm a button</Button>
                            </InputGroupAddon>
                            <Input />
                        </InputGroup>
                    </div>
                    <div className="mb-3">
                        <InputGroup size="lg">
                            <InputGroupAddon addonType="prepend">
                                <Button>I'm a button</Button>
                            </InputGroupAddon>
                            <Input />
                        </InputGroup>
                    </div>
                </div>

                <h3>Ant Form Inputs</h3>
                <div>
                    <AntForm
                        {...layout}
                        name="basic"
                        initialValues={{
                            remember: true
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}>
                        <AntForm.Item
                            label="Small Username"
                            name="username3"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!'
                                }
                            ]}>
                            <AntInput size="small" />
                        </AntForm.Item>
                        <AntForm.Item
                            label="Username"
                            name="username1"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your username!'
                                }
                            ]}>
                            <AntInput />
                        </AntForm.Item>

                        <AntForm.Item
                            label="Large Username"
                            name="username2"
                            rules={[
                                {
                                    // type: 'email',
                                    required: true,
                                    message: 'Please input your username!'
                                }
                            ]}>
                            <div>
                                <div>please select username</div>
                                <AntInput size="large" />
                            </div>
                        </AntForm.Item>
                        <AntForm.Item
                            label="Checkbox"
                            name="checkBoxes"
                            valuePropName="checked"
                            rules={[
                                {
                                    required: true,
                                    validator: (_, value) =>
                                        value && value.length
                                            ? Promise.resolve()
                                            : Promise.reject(new Error('Please select atleast one'))
                                }
                            ]}>
                            <Checkbox.Group style={{ width: '100%' }}>
                                <Row>
                                    <Col xl={4}>
                                        <Checkbox value="A">A</Checkbox>
                                    </Col>
                                    <Col xl={4}>
                                        <Checkbox value="B">B</Checkbox>
                                    </Col>
                                    <Col xl={4}>
                                        <Checkbox value="C">C</Checkbox>
                                    </Col>
                                    <Col xl={4}>
                                        <Checkbox value="D">D</Checkbox>
                                    </Col>
                                    <Col xl={4}>
                                        <Checkbox value="E">E</Checkbox>
                                    </Col>
                                </Row>
                            </Checkbox.Group>
                        </AntForm.Item>
                        <AntForm.Item
                            name="radio-button"
                            label="Radio.Button"
                            rules={[{ required: true, message: 'Please pick an item!' }]}>
                            <div>
                                <Radio.Group className="type-tile">
                                    <Radio value="a">item 1</Radio>
                                    <Radio value="b">item 2</Radio>
                                    <Radio value="c">item 3</Radio>
                                </Radio.Group>
                            </div>
                        </AntForm.Item>

                        <AntForm.Item
                            label="Password"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your password!'
                                }
                            ]}>
                            <AntInput.Password size="small" />
                        </AntForm.Item>
                        <AntForm.Item
                            label="Select Service Type"
                            name="service_provided"
                            rules={[
                                { required: true, message: 'Please enter main service provided' }
                            ]}>
                            <AntSelect allowClear size="large" placeholder="Main Service Provided">
                                <AntSelect value="tours">Tours/ Activities</AntSelect>
                                <AntSelect value="accomodation">Accomodation</AntSelect>
                                <AntSelect value="transportation">Transportation</AntSelect>
                                <AntSelect value="restaurant">
                                    Restaurant service or Meals & Drinks
                                </AntSelect>
                                <AntSelect value="personalcare">Personal Care</AntSelect>
                                <AntSelect value="guide">Guide</AntSelect>
                                <AntSelect value="other">Other</AntSelect>
                            </AntSelect>
                        </AntForm.Item>

                        <AntForm.Item
                            name={`currency`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please fill'
                                }
                            ]}>
                            <InputNumber
                                //value={amount}
                                min={1}
                                //onChange={(value) => setAmount(value)}
                                //disabled={!isCheck}
                            />
                        </AntForm.Item>

                        <AntForm.Item {...tailLayout} name="remember" valuePropName="checked">
                            <AntCheckbox>Remember me</AntCheckbox>
                        </AntForm.Item>

                        <AntForm.Item {...tailLayout}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </AntForm.Item>
                    </AntForm>
                </div>

                <h4>Calendar Components</h4>
                <div>
                    <div style={{ width: 350 }}>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval start"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-freetime">
                                    <div className="timeline-icon">
                                        <svg
                                            width="44"
                                            height="43"
                                            viewBox="0 0 44 43"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <circle
                                                cx="21.7916"
                                                cy="21.2916"
                                                r="20.2916"
                                                stroke="#FF5260"
                                                strokeWidth="2"
                                            />
                                            <circle
                                                cx="21.7918"
                                                cy="21.2916"
                                                r="17.743"
                                                fill="#E8ECEF"
                                            />
                                            <path
                                                d="M21.7916 9.46289C15.2692 9.46289 9.96289 14.7692 9.96289 21.2916C9.96289 27.8139 15.2692 33.1202 21.7916 33.1202C28.3139 33.1202 33.6202 27.8139 33.6202 21.2916C33.6202 14.7692 28.3139 9.46289 21.7916 9.46289ZM25.6867 26.8593L20.6087 21.7813V14.1944H22.9744V20.8019L27.3593 25.1867L25.6867 26.8593Z"
                                                fill="#FF5260"
                                            />
                                        </svg>
                                    </div>
                                    <div className="timeline-info">
                                        <div className="timeline-duration">
                                            <span>+15 hrs</span>
                                        </div>
                                        <div className="timeline-title">
                                            <span>Free Time</span>
                                        </div>
                                        <div
                                            className={`timeline-action ${assignComponentPermission(
                                                'product'
                                            )}`}>
                                            <button
                                                className={`timeline-action-btn ${assignComponentPermission(
                                                    'product-update'
                                                )}`}>
                                                Add Product
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                                {/* Event card wrapper */}
                                <div className="event-cards-wrapper">
                                    <div className="text-right">
                                        <Popover
                                            placement="right"
                                            getPopupContainer={(trigger) => trigger.parentElement}
                                            content={
                                                <div style={{ width: 450, padding: 15 }}>
                                                    <div className="d-flex flex-nowrap mx-n2 event-schedule">
                                                        <div className="px-2 event-time-window-wrapper">
                                                            <div className="event-time-window">
                                                                <div className="event-start-time">
                                                                    17:00
                                                                </div>
                                                                <div className="event-end-time">
                                                                    18:00
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 flex-grow-1">
                                                            <div className="event-info-wrapper">
                                                                <div className="event-title">
                                                                    <span className="event-icon mr-2">
                                                                        <svg
                                                                            width="21"
                                                                            height="21"
                                                                            viewBox="0 0 21 21"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M18.3193 4.38278C18.1087 3.68236 17.6714 3.0681 17.0734 2.63266C16.4755 2.19723 15.7493 1.96423 15.0045 1.96882C14.1413 1.96957 13.3095 2.28687 12.6728 2.85833C12.0361 3.4298 11.6405 4.21409 11.5636 5.05704H9.4374C9.35955 4.21321 8.96258 3.42844 8.32451 2.85698C7.68644 2.28552 6.85343 1.96871 5.9892 1.96882C5.24536 1.96524 4.52047 2.19873 3.92373 2.63411C3.327 3.0695 2.89073 3.6832 2.68065 4.38278L0.0441001 12.9969C0.0152175 13.0928 0.000369431 13.1923 0 13.2923V14.3217C0.00166777 15.6865 0.555471 16.9948 1.5399 17.9598C2.52433 18.9247 3.85899 19.4674 5.25105 19.4688C6.27131 19.467 7.26899 19.1741 8.12224 18.6257C8.97549 18.0773 9.64738 17.2971 10.0558 16.3805H10.9441C11.3526 17.2971 12.0245 18.0773 12.8778 18.6257C13.731 19.1741 14.7287 19.467 15.749 19.4688C17.1412 19.4674 18.476 18.9246 19.4605 17.9594C20.4449 16.9943 20.9986 15.6856 21 14.3207V13.2923C21 13.1914 20.9842 13.0926 20.9559 12.9969L18.3193 4.38278ZM5.25105 17.4099C4.41586 17.4088 3.61518 17.0832 3.02452 16.5043C2.43385 15.9254 2.10139 15.1405 2.1 14.3217C2.1 14.2435 2.11785 14.1704 2.1231 14.0932C2.18055 13.3178 2.53464 12.5924 3.11447 12.0621C3.6943 11.5318 4.45706 11.2359 5.25 11.2335C6.08543 11.2335 6.88665 11.5589 7.47739 12.138C8.06813 12.7172 8.4 13.5027 8.4 14.3217V14.331C8.39639 15.1479 8.06321 15.9302 7.4732 16.5071C6.8832 17.084 6.08433 17.4086 5.25105 17.4099ZM15.749 17.4099C14.9157 17.4086 14.1168 17.084 13.5268 16.5071C12.9368 15.9302 12.6036 15.1479 12.6 14.331V14.3217C12.5999 13.9162 12.6813 13.5146 12.8396 13.1399C12.9978 12.7652 13.2298 12.4247 13.5222 12.1379C14.1129 11.5586 14.914 11.2331 15.7495 11.233C16.5849 11.2328 17.3862 11.5581 17.977 12.1371C18.5679 12.7162 18.8999 13.5016 18.9 14.3207C18.8989 15.1397 18.5665 15.9248 17.9758 16.5039C17.3852 17.083 16.5843 17.4088 15.749 17.4099Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Food Tours
                                                                </div>
                                                                <div className="event-info">
                                                                    Burj Khalifa is the tallest
                                                                    tower in the world and it's one
                                                                    of...{' '}
                                                                    <i role="button" tabIndex="0">
                                                                        see more
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2">
                                                            <span
                                                                className="text-white"
                                                                role="button"
                                                                tabIndex="0">
                                                                <EditIcon />
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-nowrap mx-n2 event-schedule">
                                                        <div className="px-2 event-time-window-wrapper">
                                                            <div className="event-time-window padded__travel-mode">
                                                                <div className="event-time-icon-wrapper">
                                                                    <Dropdown
                                                                        overlay={
                                                                            <Menu>
                                                                                <Menu.Item>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        href="https://www.antgroup.com">
                                                                                        1st menu
                                                                                        item
                                                                                    </a>
                                                                                </Menu.Item>
                                                                                <Menu.Item>
                                                                                    <a
                                                                                        target="_blank"
                                                                                        rel="noopener noreferrer"
                                                                                        href="https://www.luohanacademy.com">
                                                                                        2nd menu
                                                                                        item
                                                                                    </a>
                                                                                </Menu.Item>
                                                                            </Menu>
                                                                        }
                                                                        getPopupContainer={(
                                                                            trigger
                                                                        ) => trigger.parentElement}>
                                                                        <span className="event-time-icon __with-icon __icon-position-absolute">
                                                                            <span>
                                                                                <svg
                                                                                    width="24"
                                                                                    height="23"
                                                                                    viewBox="0 0 24 23"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path
                                                                                        d="M21.2262 2.16219C20.9595 1.35094 20.2128 0.773438 19.3328 0.773438H4.66618C3.78618 0.773438 3.05285 1.35094 2.77285 2.16219L-0.000488281 10.3984V21.3984C-0.000488281 22.1547 0.599512 22.7734 1.33285 22.7734H2.66618C3.39951 22.7734 3.99951 22.1547 3.99951 21.3984V20.0234H19.9995V21.3984C19.9995 22.1547 20.5995 22.7734 21.3328 22.7734H22.6662C23.3995 22.7734 23.9995 22.1547 23.9995 21.3984V10.3984L21.2262 2.16219ZM4.66618 15.8984C3.55951 15.8984 2.66618 14.9772 2.66618 13.8359C2.66618 12.6947 3.55951 11.7734 4.66618 11.7734C5.77285 11.7734 6.66618 12.6947 6.66618 13.8359C6.66618 14.9772 5.77285 15.8984 4.66618 15.8984ZM19.3328 15.8984C18.2262 15.8984 17.3328 14.9772 17.3328 13.8359C17.3328 12.6947 18.2262 11.7734 19.3328 11.7734C20.4395 11.7734 21.3328 12.6947 21.3328 13.8359C21.3328 14.9772 20.4395 15.8984 19.3328 15.8984ZM2.66618 9.02344L4.66618 2.83594H19.3328L21.3328 9.02344H2.66618Z"
                                                                                        fill="white"
                                                                                    />
                                                                                </svg>
                                                                            </span>
                                                                        </span>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 flex-grow-1 align-self-center">
                                                            <div className="event-info-wrapper padded__travel-mode">
                                                                <div className="timeline-info">
                                                                    <div className="timeline-eta">
                                                                        <span>10 min</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex flex-nowrap mx-n2 event-schedule __special">
                                                        <div className="px-2 event-time-window-wrapper">
                                                            <div className="event-time-window">
                                                                <div className="event-start-time">
                                                                    17:10
                                                                </div>
                                                                <div className="event-end-time">
                                                                    20:00
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2 flex-grow-1">
                                                            <div className="event-info-wrapper">
                                                                <div className="event-title">
                                                                    <span className="event-icon mr-2">
                                                                        <svg
                                                                            width="21"
                                                                            height="21"
                                                                            viewBox="0 0 21 21"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg">
                                                                            <path
                                                                                d="M18.3193 4.38278C18.1087 3.68236 17.6714 3.0681 17.0734 2.63266C16.4755 2.19723 15.7493 1.96423 15.0045 1.96882C14.1413 1.96957 13.3095 2.28687 12.6728 2.85833C12.0361 3.4298 11.6405 4.21409 11.5636 5.05704H9.4374C9.35955 4.21321 8.96258 3.42844 8.32451 2.85698C7.68644 2.28552 6.85343 1.96871 5.9892 1.96882C5.24536 1.96524 4.52047 2.19873 3.92373 2.63411C3.327 3.0695 2.89073 3.6832 2.68065 4.38278L0.0441001 12.9969C0.0152175 13.0928 0.000369431 13.1923 0 13.2923V14.3217C0.00166777 15.6865 0.555471 16.9948 1.5399 17.9598C2.52433 18.9247 3.85899 19.4674 5.25105 19.4688C6.27131 19.467 7.26899 19.1741 8.12224 18.6257C8.97549 18.0773 9.64738 17.2971 10.0558 16.3805H10.9441C11.3526 17.2971 12.0245 18.0773 12.8778 18.6257C13.731 19.1741 14.7287 19.467 15.749 19.4688C17.1412 19.4674 18.476 18.9246 19.4605 17.9594C20.4449 16.9943 20.9986 15.6856 21 14.3207V13.2923C21 13.1914 20.9842 13.0926 20.9559 12.9969L18.3193 4.38278ZM5.25105 17.4099C4.41586 17.4088 3.61518 17.0832 3.02452 16.5043C2.43385 15.9254 2.10139 15.1405 2.1 14.3217C2.1 14.2435 2.11785 14.1704 2.1231 14.0932C2.18055 13.3178 2.53464 12.5924 3.11447 12.0621C3.6943 11.5318 4.45706 11.2359 5.25 11.2335C6.08543 11.2335 6.88665 11.5589 7.47739 12.138C8.06813 12.7172 8.4 13.5027 8.4 14.3217V14.331C8.39639 15.1479 8.06321 15.9302 7.4732 16.5071C6.8832 17.084 6.08433 17.4086 5.25105 17.4099ZM15.749 17.4099C14.9157 17.4086 14.1168 17.084 13.5268 16.5071C12.9368 15.9302 12.6036 15.1479 12.6 14.331V14.3217C12.5999 13.9162 12.6813 13.5146 12.8396 13.1399C12.9978 12.7652 13.2298 12.4247 13.5222 12.1379C14.1129 11.5586 14.914 11.2331 15.7495 11.233C16.5849 11.2328 17.3862 11.5581 17.977 12.1371C18.5679 12.7162 18.8999 13.5016 18.9 14.3207C18.8989 15.1397 18.5665 15.9248 17.9758 16.5039C17.3852 17.083 16.5843 17.4088 15.749 17.4099Z"
                                                                                fill="currentColor"
                                                                            />
                                                                        </svg>
                                                                    </span>
                                                                    Heritage Market
                                                                </div>
                                                                <div className="event-info">
                                                                    Burj Khalifa is the tallest
                                                                    tower in the world and it's one
                                                                    of...{' '}
                                                                    <i role="button" tabIndex="0">
                                                                        see more
                                                                    </i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2">
                                                            <span
                                                                className="text-white"
                                                                role="button"
                                                                tabIndex="0">
                                                                <EditIcon />
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            }>
                                            <span
                                                role="button"
                                                tabIndex="0"
                                                className="see-more-products-btn">
                                                <span className="mr-2">See more Products</span>
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <rect
                                                        x="5"
                                                        width="2"
                                                        height="12"
                                                        fill="currentColor"
                                                    />
                                                    <rect
                                                        x="12"
                                                        y="5"
                                                        width="2"
                                                        height="12"
                                                        transform="rotate(90 12 5)"
                                                        fill="currentColor"
                                                    />
                                                </svg>
                                            </span>
                                        </Popover>
                                    </div>
                                    <div className="event-card" data-rows={3}>
                                        <div className="event-card-header">
                                            <div className="event-card-title">
                                                <span className="event-card-header-icon mr-2">
                                                    <svg
                                                        width="21"
                                                        height="21"
                                                        viewBox="0 0 21 21"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18.3193 4.38278C18.1087 3.68236 17.6714 3.0681 17.0734 2.63266C16.4755 2.19723 15.7493 1.96423 15.0045 1.96882C14.1413 1.96957 13.3095 2.28687 12.6728 2.85833C12.0361 3.4298 11.6405 4.21409 11.5636 5.05704H9.4374C9.35955 4.21321 8.96258 3.42844 8.32451 2.85698C7.68644 2.28552 6.85343 1.96871 5.9892 1.96882C5.24536 1.96524 4.52047 2.19873 3.92373 2.63411C3.327 3.0695 2.89073 3.6832 2.68065 4.38278L0.0441001 12.9969C0.0152175 13.0928 0.000369431 13.1923 0 13.2923V14.3217C0.00166777 15.6865 0.555471 16.9948 1.5399 17.9598C2.52433 18.9247 3.85899 19.4674 5.25105 19.4688C6.27131 19.467 7.26899 19.1741 8.12224 18.6257C8.97549 18.0773 9.64738 17.2971 10.0558 16.3805H10.9441C11.3526 17.2971 12.0245 18.0773 12.8778 18.6257C13.731 19.1741 14.7287 19.467 15.749 19.4688C17.1412 19.4674 18.476 18.9246 19.4605 17.9594C20.4449 16.9943 20.9986 15.6856 21 14.3207V13.2923C21 13.1914 20.9842 13.0926 20.9559 12.9969L18.3193 4.38278ZM5.25105 17.4099C4.41586 17.4088 3.61518 17.0832 3.02452 16.5043C2.43385 15.9254 2.10139 15.1405 2.1 14.3217C2.1 14.2435 2.11785 14.1704 2.1231 14.0932C2.18055 13.3178 2.53464 12.5924 3.11447 12.0621C3.6943 11.5318 4.45706 11.2359 5.25 11.2335C6.08543 11.2335 6.88665 11.5589 7.47739 12.138C8.06813 12.7172 8.4 13.5027 8.4 14.3217V14.331C8.39639 15.1479 8.06321 15.9302 7.4732 16.5071C6.8832 17.084 6.08433 17.4086 5.25105 17.4099ZM15.749 17.4099C14.9157 17.4086 14.1168 17.084 13.5268 16.5071C12.9368 15.9302 12.6036 15.1479 12.6 14.331V14.3217C12.5999 13.9162 12.6813 13.5146 12.8396 13.1399C12.9978 12.7652 13.2298 12.4247 13.5222 12.1379C14.1129 11.5586 14.914 11.2331 15.7495 11.233C16.5849 11.2328 17.3862 11.5581 17.977 12.1371C18.5679 12.7162 18.8999 13.5016 18.9 14.3207C18.8989 15.1397 18.5665 15.9248 17.9758 16.5039C17.3852 17.083 16.5843 17.4088 15.749 17.4099Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="name">Burj al khalifa</span>

                                                {/* Name Icon */}
                                                <span className="ml-2">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="12"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M12.9643 7.62383V15.9998H11.2843V10.1558L9.13628 15.9998H7.67228L5.52428 10.1798V15.9998H3.83228V7.62383H5.88428L8.42828 13.9238L10.9243 7.62383H12.9643Z"
                                                            fill="#A2792C"
                                                        />
                                                        <path
                                                            d="M16.1883 12.8558V15.9998H14.4963V7.52783H17.5803C18.5803 7.52783 19.3323 7.76783 19.8363 8.24783C20.3483 8.72783 20.6043 9.37983 20.6043 10.2038C20.6043 11.0118 20.3403 11.6558 19.8123 12.1358C19.2923 12.6158 18.5483 12.8558 17.5803 12.8558H16.1883ZM17.4003 11.4998C18.3923 11.4998 18.8883 11.0678 18.8883 10.2038C18.8883 9.77983 18.7723 9.45583 18.5403 9.23183C18.3083 9.00783 17.9283 8.89583 17.4003 8.89583H16.1883V11.4998H17.4003Z"
                                                            fill="#A2792C"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="event-card-header-action">
                                                <span role="button" tabIndex="0" title="Edit Event">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18.9881 2.01202L21.9881 5.01202L19.701 7.30002L16.701 4.30002L18.9881 2.01202Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M8 16H11L18.287 8.71301L15.287 5.71301L8 13V16Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M19 19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.896 3 5V19C3 20.104 3.897 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10.332L19 12.332V19Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        {/* Event image gallery */}
                                        <div className="event-gallery-wrapper">
                                            <Carousel
                                                swipeable={true}
                                                draggable={false}
                                                showDots={false}
                                                arrows={true}
                                                responsive={responsive.eventGallery}
                                                // ssr={true} // means to render carousel on server-side.
                                                infinite={true}
                                                autoPlay={false}
                                                autoPlaySpeed={3000}
                                                keyBoardControl={true}
                                                // customTransition="all .5"
                                                // transitionDuration={500}
                                                containerClass="event-gallery-carousel"
                                                // removeArrowOnDeviceType={["tablet", "mobile"]}
                                                // deviceType={this.props.deviceType}
                                                // dotListClass="custom-dot-list-style"
                                                // itemClass="carousel-item-padding-40-px"
                                            >
                                                <div className="event-gallery-item">
                                                    <img
                                                        className="event-gallery-img"
                                                        src={require('../../assets/images/burj-al-khalifa.jpg')}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="event-gallery-item">
                                                    <img
                                                        className="event-gallery-img"
                                                        src={require('../../assets/images/burj-al-khalifa.jpg')}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="event-gallery-item">
                                                    <img
                                                        className="event-gallery-img"
                                                        src={require('../../assets/images/burj-al-khalifa.jpg')}
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="event-gallery-item">
                                                    <img
                                                        className="event-gallery-img"
                                                        src={require('../../assets/images/burj-al-khalifa.jpg')}
                                                        alt=""
                                                    />
                                                </div>
                                            </Carousel>
                                        </div>

                                        <div className="event-card-info __type-time-block">
                                            <div className="suggested-time-block">
                                                <div className="suggested-name">Suggested Time</div>
                                                <Checkbox className="__type-time-block"></Checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>

                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                                <div className="timeline-freetime __icon-only">
                                    <Popover
                                        placement="right"
                                        getPopupContainer={(trigger) => trigger.parentElement}
                                        content={
                                            <div
                                                style={{
                                                    width: 295,
                                                    paddingTop: 20,
                                                    paddingBottom: 20
                                                }}>
                                                <div className="d-flex flex-nowrap mx-n3 event-schedule">
                                                    <div className="px-3 event-time-window-wrapper">
                                                        <div className="event-time-window py-2">
                                                            <div className="event-time-icon-wrapper">
                                                                <svg
                                                                    width="43"
                                                                    height="43"
                                                                    viewBox="0 0 43 43"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                    <circle
                                                                        cx="21.2911"
                                                                        cy="21.5118"
                                                                        r="20.2916"
                                                                        stroke="#FF5260"
                                                                        strokeWidth="2"
                                                                    />
                                                                    <circle
                                                                        cx="21.2913"
                                                                        cy="21.5116"
                                                                        r="17.743"
                                                                        fill="black"
                                                                        fillOpacity="0.5"
                                                                    />
                                                                    <path
                                                                        d="M21.2911 9.68311C14.7687 9.68311 9.4624 14.9894 9.4624 21.5118C9.4624 28.0341 14.7687 33.3404 21.2911 33.3404C27.8134 33.3404 33.1197 28.0341 33.1197 21.5118C33.1197 14.9894 27.8134 9.68311 21.2911 9.68311ZM25.1862 27.0795L20.1082 22.0015V14.4146H22.4739V21.0221L26.8588 25.407L25.1862 27.0795Z"
                                                                        fill="#FF5260"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 flex-grow-1">
                                                        <div className="event-info-wrapper">
                                                            <div className="timeline-info flex-wrap">
                                                                <div className="timeline-duration">
                                                                    <span>+30 min</span>
                                                                </div>
                                                                <div className="timeline-title text-reset">
                                                                    <span>Free Time</span>
                                                                </div>
                                                                <div className="timeline-action w-100">
                                                                    <button className="timeline-action-btn tinted">
                                                                        <i>Add Product</i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-nowrap mx-n3 event-schedule">
                                                    <div className="px-3 event-time-window-wrapper">
                                                        <div className="event-time-window py-2">
                                                            <div className="event-time-icon-wrapper">
                                                                <Dropdown
                                                                    overlay={
                                                                        <Menu>
                                                                            <Menu.Item>
                                                                                <a
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    href="https://www.antgroup.com">
                                                                                    1st menu item
                                                                                </a>
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                <a
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                    href="https://www.luohanacademy.com">
                                                                                    2nd menu item
                                                                                </a>
                                                                            </Menu.Item>
                                                                        </Menu>
                                                                    }
                                                                    getPopupContainer={(trigger) =>
                                                                        trigger.parentElement
                                                                    }>
                                                                    <span className="event-time-icon __with-icon __icon-position-absolute">
                                                                        <span>
                                                                            <svg
                                                                                width="24"
                                                                                height="23"
                                                                                viewBox="0 0 24 23"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
                                                                                <path
                                                                                    d="M21.2262 2.16219C20.9595 1.35094 20.2128 0.773438 19.3328 0.773438H4.66618C3.78618 0.773438 3.05285 1.35094 2.77285 2.16219L-0.000488281 10.3984V21.3984C-0.000488281 22.1547 0.599512 22.7734 1.33285 22.7734H2.66618C3.39951 22.7734 3.99951 22.1547 3.99951 21.3984V20.0234H19.9995V21.3984C19.9995 22.1547 20.5995 22.7734 21.3328 22.7734H22.6662C23.3995 22.7734 23.9995 22.1547 23.9995 21.3984V10.3984L21.2262 2.16219ZM4.66618 15.8984C3.55951 15.8984 2.66618 14.9772 2.66618 13.8359C2.66618 12.6947 3.55951 11.7734 4.66618 11.7734C5.77285 11.7734 6.66618 12.6947 6.66618 13.8359C6.66618 14.9772 5.77285 15.8984 4.66618 15.8984ZM19.3328 15.8984C18.2262 15.8984 17.3328 14.9772 17.3328 13.8359C17.3328 12.6947 18.2262 11.7734 19.3328 11.7734C20.4395 11.7734 21.3328 12.6947 21.3328 13.8359C21.3328 14.9772 20.4395 15.8984 19.3328 15.8984ZM2.66618 9.02344L4.66618 2.83594H19.3328L21.3328 9.02344H2.66618Z"
                                                                                    fill="white"
                                                                                />
                                                                            </svg>
                                                                        </span>
                                                                    </span>
                                                                </Dropdown>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="px-3 flex-grow-1 align-self-center">
                                                        <div className="event-info-wrapper">
                                                            <div className="timeline-info">
                                                                <div className="timeline-eta">
                                                                    <span>10 min</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }>
                                        <span role="button" tabIndex="0">
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="12" cy="12" r="12" fill="#FF5260" />
                                                <rect
                                                    x="11"
                                                    y="6"
                                                    width="2"
                                                    height="12"
                                                    fill="white"
                                                />
                                                <rect
                                                    x="18"
                                                    y="11"
                                                    width="2"
                                                    height="12"
                                                    transform="rotate(90 18 11)"
                                                    fill="white"
                                                />
                                            </svg>
                                        </span>
                                    </Popover>
                                </div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                                {/* Event card wrapper */}
                                <div className="event-cards-wrapper">
                                    <div className="event-card" data-rows={2}>
                                        <div className="event-card-header">
                                            <div className="event-card-title">
                                                <span className="event-card-header-icon mr-2">
                                                    <svg
                                                        width="21"
                                                        height="21"
                                                        viewBox="0 0 21 21"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18.3193 4.38278C18.1087 3.68236 17.6714 3.0681 17.0734 2.63266C16.4755 2.19723 15.7493 1.96423 15.0045 1.96882C14.1413 1.96957 13.3095 2.28687 12.6728 2.85833C12.0361 3.4298 11.6405 4.21409 11.5636 5.05704H9.4374C9.35955 4.21321 8.96258 3.42844 8.32451 2.85698C7.68644 2.28552 6.85343 1.96871 5.9892 1.96882C5.24536 1.96524 4.52047 2.19873 3.92373 2.63411C3.327 3.0695 2.89073 3.6832 2.68065 4.38278L0.0441001 12.9969C0.0152175 13.0928 0.000369431 13.1923 0 13.2923V14.3217C0.00166777 15.6865 0.555471 16.9948 1.5399 17.9598C2.52433 18.9247 3.85899 19.4674 5.25105 19.4688C6.27131 19.467 7.26899 19.1741 8.12224 18.6257C8.97549 18.0773 9.64738 17.2971 10.0558 16.3805H10.9441C11.3526 17.2971 12.0245 18.0773 12.8778 18.6257C13.731 19.1741 14.7287 19.467 15.749 19.4688C17.1412 19.4674 18.476 18.9246 19.4605 17.9594C20.4449 16.9943 20.9986 15.6856 21 14.3207V13.2923C21 13.1914 20.9842 13.0926 20.9559 12.9969L18.3193 4.38278ZM5.25105 17.4099C4.41586 17.4088 3.61518 17.0832 3.02452 16.5043C2.43385 15.9254 2.10139 15.1405 2.1 14.3217C2.1 14.2435 2.11785 14.1704 2.1231 14.0932C2.18055 13.3178 2.53464 12.5924 3.11447 12.0621C3.6943 11.5318 4.45706 11.2359 5.25 11.2335C6.08543 11.2335 6.88665 11.5589 7.47739 12.138C8.06813 12.7172 8.4 13.5027 8.4 14.3217V14.331C8.39639 15.1479 8.06321 15.9302 7.4732 16.5071C6.8832 17.084 6.08433 17.4086 5.25105 17.4099ZM15.749 17.4099C14.9157 17.4086 14.1168 17.084 13.5268 16.5071C12.9368 15.9302 12.6036 15.1479 12.6 14.331V14.3217C12.5999 13.9162 12.6813 13.5146 12.8396 13.1399C12.9978 12.7652 13.2298 12.4247 13.5222 12.1379C14.1129 11.5586 14.914 11.2331 15.7495 11.233C16.5849 11.2328 17.3862 11.5581 17.977 12.1371C18.5679 12.7162 18.8999 13.5016 18.9 14.3207C18.8989 15.1397 18.5665 15.9248 17.9758 16.5039C17.3852 17.083 16.5843 17.4088 15.749 17.4099Z"
                                                            fill="white"
                                                        />
                                                    </svg>
                                                </span>
                                                <span className="name">Burj al khalifa</span>

                                                {/* Name Icon */}
                                                <span className="ml-2">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="12"
                                                            fill="white"
                                                        />
                                                        <path
                                                            d="M12.9643 7.62383V15.9998H11.2843V10.1558L9.13628 15.9998H7.67228L5.52428 10.1798V15.9998H3.83228V7.62383H5.88428L8.42828 13.9238L10.9243 7.62383H12.9643Z"
                                                            fill="#A2792C"
                                                        />
                                                        <path
                                                            d="M16.1883 12.8558V15.9998H14.4963V7.52783H17.5803C18.5803 7.52783 19.3323 7.76783 19.8363 8.24783C20.3483 8.72783 20.6043 9.37983 20.6043 10.2038C20.6043 11.0118 20.3403 11.6558 19.8123 12.1358C19.2923 12.6158 18.5483 12.8558 17.5803 12.8558H16.1883ZM17.4003 11.4998C18.3923 11.4998 18.8883 11.0678 18.8883 10.2038C18.8883 9.77983 18.7723 9.45583 18.5403 9.23183C18.3083 9.00783 17.9283 8.89583 17.4003 8.89583H16.1883V11.4998H17.4003Z"
                                                            fill="#A2792C"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                            <div className="event-card-header-action">
                                                <span role="button" tabIndex="0" title="Edit Event">
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M18.9881 2.01202L21.9881 5.01202L19.701 7.30002L16.701 4.30002L18.9881 2.01202Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M8 16H11L18.287 8.71301L15.287 5.71301L8 13V16Z"
                                                            fill="currentColor"
                                                        />
                                                        <path
                                                            d="M19 19H8.158C8.132 19 8.105 19.01 8.079 19.01C8.046 19.01 8.013 19.001 7.979 19H5V5H11.847L13.847 3H5C3.897 3 3 3.896 3 5V19C3 20.104 3.897 21 5 21H19C19.5304 21 20.0391 20.7893 20.4142 20.4142C20.7893 20.0391 21 19.5304 21 19V10.332L19 12.332V19Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        </div>

                                        {/* Event image gallery */}
                                        <div className="event-gallery-wrapper">
                                            <div className="event-gallery-item">
                                                <img
                                                    className="event-gallery-img"
                                                    src={require('../../assets/images/burj-al-khalifa.jpg')}
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <div className="event-card-info __type-time-block">
                                            <div className="suggested-time-block">
                                                <div className="suggested-name">Suggested Time</div>
                                                <Checkbox className="__type-time-block"></Checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                                <div className="time-slot-internal-action">
                                    <div className="d-flex flex-nowrap align-items-center">
                                        <div className="timeline-icon-border mr-3">
                                            <svg
                                                width="21"
                                                height="20"
                                                viewBox="0 0 21 20"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0)">
                                                    <path
                                                        d="M11.4271 16.972V11.283L20.5 0.394043H0.5L9.57285 11.283V16.972C6.94153 17.1859 4.9329 18.2784 4.9329 19.6056H16.0694C16.0694 18.2784 14.0608 17.1844 11.4271 16.972ZM10.8664 6.74848C10.3445 6.74848 9.92201 6.32597 9.92201 5.80261C9.92201 5.28078 10.3453 4.85674 10.8664 4.85674C11.3889 4.85674 11.813 5.28078 11.813 5.80261C11.8145 6.3252 11.3889 6.74848 10.8664 6.74848ZM16.9816 2.04282L16.1687 3.01772L6.21418 4.67643L4.01836 2.04282H16.9816Z"
                                                        fill="#333333"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0">
                                                        <rect
                                                            width="20"
                                                            height="20"
                                                            fill="white"
                                                            transform="translate(0.5)"
                                                        />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                        <div>
                                            <span
                                                className="timeline-action-btn"
                                                role="button"
                                                tabIndex="0">
                                                <i>Add nightlife</i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-interval continue"></div>
                            </div>
                            <div className="time-slot">
                                <div className="timeline-interval end icon"></div>
                            </div>
                        </div>
                        <div className="time-slot-row">
                            <div className="time-slot">
                                <div className="timeline-suggested-block">
                                    <div className="mr-3">
                                        <div className="timeline-icon-border">
                                            <svg
                                                width="28"
                                                height="28"
                                                viewBox="0 0 28 28"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M22.7211 11.5905H12.6228V20.4265H2.52457V9.06592H0V28.0002H2.52457V24.2133H25.2457V28.0002H27.7702V16.6396C27.7702 15.3005 27.2383 14.0162 26.2914 13.0693C25.3445 12.1224 24.0602 11.5905 22.7211 11.5905ZM7.5737 19.1642C8.57804 19.1642 9.54124 18.7652 10.2514 18.055C10.9616 17.3449 11.3606 16.3817 11.3606 15.3773C11.3606 14.373 10.9616 13.4098 10.2514 12.6996C9.54124 11.9895 8.57804 11.5905 7.5737 11.5905C6.56937 11.5905 5.60617 11.9895 4.896 12.6996C4.18582 13.4098 3.78685 14.373 3.78685 15.3773C3.78685 16.3817 4.18582 17.3449 4.896 18.055C5.60617 18.7652 6.56937 19.1642 7.5737 19.1642Z"
                                                    fill="#333333"
                                                />
                                                <path
                                                    d="M19.3932 4.81963H15.6376V3.61472L17.7595 1.20491H15.6376V0H19.3932V1.20491L17.2775 3.61472H19.3932V4.81963ZM14.3858 7.22944H10.6302V6.02454L12.7521 3.61472H10.6302V2.40981H14.3858V3.61472L12.2701 6.02454H14.3858V7.22944ZM9.37836 9.63926H5.6228V8.43435L7.74469 6.02454H5.6228V4.81963H9.37836V6.02454L7.26273 8.43435H9.37836V9.63926Z"
                                                    fill="#333333"
                                                />
                                            </svg>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="fw-500 mb-1">
                                            <span>Resting time</span>
                                        </div>
                                        <div>
                                            <span className="fs-13">
                                                <Popover
                                                    placement="topRight"
                                                    trigger="click"
                                                    content={
                                                        <div style={{ padding: 10 }}>
                                                            <div className="d-flex align-items-center mx-n2 mb-2">
                                                                <div className="px-2 flex-grow-1">
                                                                    <div className="fs-15 text-khaki fw-500 ff-special">
                                                                        Start Time
                                                                    </div>
                                                                </div>
                                                                <div className="px-2">
                                                                    <TimePicker
                                                                        className="tooltip-timepicker"
                                                                        use12Hours
                                                                        format="h:mm a"
                                                                        getPopupContainer={(
                                                                            trigger
                                                                        ) => trigger.parentElement}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mx-n2">
                                                                <div className="px-2 flex-grow-1">
                                                                    <div className="fs-15 text-khaki fw-500 fst-italic ff-special">
                                                                        End Time
                                                                    </div>
                                                                </div>
                                                                <div className="px-2">
                                                                    <TimePicker
                                                                        className="tooltip-timepicker"
                                                                        use12Hours
                                                                        format="h:mm a"
                                                                        getPopupContainer={(
                                                                            trigger
                                                                        ) => trigger.parentElement}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }>
                                                    <span
                                                        className="fs-14 btn btn-link p-0"
                                                        role="button"
                                                        tabIndex="0">
                                                        <i>Adjust</i>
                                                    </span>{' '}
                                                </Popover>
                                                for better travel Experience.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}

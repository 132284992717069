import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalBody } from 'reactstrap';
import warningIcon from '../../../../assets/images/warning-icon.svg';

const ConfirmationModal = (props) => {
    const {
        isModalOpen,
        setModalOpenState,
        description,
        handleConfirmation,
        title,
        size = 'lg',
        yesAllias = 'Yes',
        noAllias = 'No',
        showYesbutton = true
    } = props;
    return (
        <Modal size={size} centered isOpen={isModalOpen} zIndex={2000}>
            <ModalBody className="text-center">
                <img src={warningIcon} alt="" />
                <h5 className="modal-body-title-lg mt-2"> {title || 'Are you sure?'}</h5>
                <p className="modal-body-text">{description}</p>
                <div className="mt-4">
                    {showYesbutton && (
                        <Button
                            color="primary"
                            className="btn-mw mx-2"
                            onClick={handleConfirmation}>
                            {yesAllias}
                        </Button>
                    )}

                    <Button
                        color="dark"
                        className="btn-mw mx-2"
                        onClick={() => setModalOpenState(false)}>
                        {noAllias}
                    </Button>
                </div>
            </ModalBody>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    handleConfirmation: PropTypes.func,
    size: PropTypes.string,
    yesAllias: PropTypes.string,
    noAllias: PropTypes.string,
    showYesbutton: PropTypes.bool
};

export default ConfirmationModal;

/* eslint-disable no-case-declarations */

const initialState = {
    categories: null
};

const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_CATEGORY':
            state.categories = action.payload?.data;
            return { ...state };

        default:
            return state;
    }
};

export default categoryReducer;

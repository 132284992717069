import React from 'react';

const ExpandIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16 1.30555C16 1.05448 15.8946 0.813694 15.7071 0.636159C15.5196 0.458625 15.2652 0.358887 15 0.358887H10C9.73478 0.358887 9.48043 0.458625 9.29289 0.636159C9.10536 0.813694 9 1.05448 9 1.30555C9 1.55662 9.10536 1.79741 9.29289 1.97495C9.48043 2.15248 9.73478 2.25222 10 2.25222H12.57L9.29 5.36675C9.19627 5.45476 9.12188 5.55946 9.07111 5.67482C9.02034 5.79018 8.9942 5.91392 8.9942 6.03889C8.9942 6.16386 9.02034 6.28759 9.07111 6.40295C9.12188 6.51831 9.19627 6.62301 9.29 6.71102C9.38296 6.79975 9.49356 6.87018 9.61542 6.91824C9.73728 6.9663 9.86799 6.99104 10 6.99104C10.132 6.99104 10.2627 6.9663 10.3846 6.91824C10.5064 6.87018 10.617 6.79975 10.71 6.71102L14 3.59649V6.03889C14 6.28996 14.1054 6.53075 14.2929 6.70828C14.4804 6.88582 14.7348 6.98555 15 6.98555C15.2652 6.98555 15.5196 6.88582 15.7071 6.70828C15.8946 6.53075 16 6.28996 16 6.03889V1.30555ZM6.71 9.15342C6.61704 9.06469 6.50644 8.99426 6.38458 8.9462C6.26272 8.89814 6.13201 8.8734 6 8.8734C5.86799 8.8734 5.73728 8.89814 5.61542 8.9462C5.49356 8.99426 5.38296 9.06469 5.29 9.15342L2 12.2585V9.82555C2 9.57448 1.89464 9.33369 1.70711 9.15616C1.51957 8.97862 1.26522 8.87889 1 8.87889C0.734784 8.87889 0.48043 8.97862 0.292893 9.15616C0.105357 9.33369 0 9.57448 0 9.82555V14.5589C0 14.81 0.105357 15.0507 0.292893 15.2283C0.48043 15.4058 0.734784 15.5056 1 15.5056H6C6.26522 15.5056 6.51957 15.4058 6.70711 15.2283C6.89464 15.0507 7 14.81 7 14.5589C7 14.3078 6.89464 14.067 6.70711 13.8895C6.51957 13.712 6.26522 13.6122 6 13.6122H3.42L6.71 10.4977C6.80373 10.4097 6.87812 10.305 6.92889 10.1896C6.97966 10.0743 7.0058 9.95052 7.0058 9.82555C7.0058 9.70058 6.97966 9.57685 6.92889 9.46149C6.87812 9.34613 6.80373 9.24142 6.71 9.15342Z"
                fill="white"
            />
        </svg>
    );
};

export default ExpandIcon;

import React, { useState, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateCalendarAccess } from 'store/actions';
import { disableDate } from 'utils/index';
import moment from 'moment';

import { Button, Checkbox, DatePicker, TimePicker } from 'antd';
import WarningIcon from 'assets/images/warning-icon.svg';

const CalendarAccessUpdateModal = (props) => {
    const {
        isOpen,
        toggleModal,
        productId,
        calendarId,
        access,
        date,
        loading,
        updateCalendarAccess,
        update_availability_state,
        refreshList
    } = props;
    const [calendarAccess, setCalendarAccess] = useState(access);
    const [specificDates, setSpecificDates] = useState(false);
    const [newDate, setNewDate] = useState(date);
    const [newStartTime, setNewStartTime] = useState(null);
    const [newEndTime, setNewEndTime] = useState(null);

    const format = 'YYYY-MM-DD';
    const disableCalendar = !calendarAccess;

    const handleCalendarAccess = (e) => {
        return !isNaN(e.target.value) ? setCalendarAccess(e.target.value) : null;
    };

    const onChange = (e) => {
        setSpecificDates(e.target.checked);
    };

    const handleDateChange = (_, valueString) => {
        return setNewDate(valueString);
    };

    const handleTimeChange = (_, valueString) => {
        const [startTime, endTime] = valueString;
        setNewStartTime(startTime);
        setNewEndTime(endTime);
    };

    const toggleUpdateModal = () => {
        toggleModal();
        setSpecificDates(false);
        setNewDate(date);
        setCalendarAccess(access);
        setNewStartTime(null);
        setNewEndTime(null);
    };

    const submitUpdateCalendarAccess = () => {
        const payload = {
            productId,
            calendarAccess,
            ...(newDate && { dateQuery: newDate }),
            ...(newStartTime &&
                newEndTime && {
                    startTimeQuery: newStartTime,
                    endTimeQuery: newEndTime
                })
        };
        updateCalendarAccess(calendarId, payload).then(() => {
            refreshList();
            toggleUpdateModal();
        });
    };

    useEffect(() => {
        if (update_availability_state) {
            return toggleUpdateModal();
        }
    }, [update_availability_state]);

    useEffect(() => {
        if (access) {
            setCalendarAccess(access);
        }
    }, [access]);

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleUpdateModal}>
            <ModalBody className="text-center">
                <img src={WarningIcon} alt="" />
                <div className="modal-body-title-lg mt-2">
                    {/* modal title */}
                    Update?
                </div>
                <div className="modal-body-text">
                    {/* modal description */}
                    <p className="m-0 my-3">This will only reflect for today - {newDate}</p>

                    <div className="d-flex align-items-center justify-content-center">
                        <input
                            placeholder="Number of calendar access"
                            id="calendar_access"
                            name="calendar_access"
                            className="num-input num-input--large"
                            value={calendarAccess}
                            onChange={handleCalendarAccess}
                        />
                    </div>

                    <div className="mt-4 d-flex flex-column align-items-center">
                        <Checkbox className="d-flex align-items-center" onChange={onChange}>
                            <span style={{ fontSize: '14px' }}>
                                Change Date and Time duration of Calendar Access
                            </span>
                        </Checkbox>
                        {specificDates && (
                            <div
                                className="d-flex align-items-center flex-wrap date-filter__modal"
                                style={{ gap: '10px' }}>
                                <DatePicker
                                    disabledDate={disableDate}
                                    className="my-3"
                                    format={format}
                                    defaultValue={moment(newDate, format)}
                                    onChange={handleDateChange}
                                />
                                <div className="d-flex align-items-center justify-content-center">
                                    <TimePicker.RangePicker
                                        format={'HH:mm'}
                                        placeholder={['Start', 'End']}
                                        onChange={handleTimeChange}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4">
                    {/* modal buttons */}
                    <>
                        <Button
                            id="pauseSaleTooltip"
                            type="primary"
                            loading={loading}
                            disabled={disableCalendar}
                            className="btn-mw mx-2"
                            onClick={() => submitUpdateCalendarAccess()}>
                            UPDATE
                        </Button>
                        <Button
                            type="dark"
                            className="btn-mw mx-2"
                            onClick={() => toggleUpdateModal()}>
                            CANCEL
                        </Button>
                    </>
                </div>
            </ModalBody>
        </Modal>
    );
};
CalendarAccessUpdateModal.propTypes = {
    loading: PropTypes.bool,
    productId: PropTypes.string,
    calendarId: PropTypes.string,
    access: PropTypes.number,
    date: PropTypes.string,
    updateCalendarAccess: PropTypes.func,
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    refreshList: PropTypes.func,
    update_availability_state: PropTypes.bool
};

const mapStateToProps = (state) => ({
    loading: state.loading,
    update_calendar_access: state.availability.update_calendar_access,
    update_availability_state: state.availability.update_availability_state
});

const mapDispatch = (dispatch) => ({
    updateCalendarAccess: (id, data) => dispatch(updateCalendarAccess(id, data))
});
export default withRouter(connect(mapStateToProps, mapDispatch)(CalendarAccessUpdateModal));

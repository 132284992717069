/* eslint-disable react/jsx-key */
import React from 'react';
import { Form, InputNumber, TimePicker } from 'antd';
import { Button, Col, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import PlusIcon from 'components/atoms/icons/plusIcon';
import { Spacer } from 'components/atoms';
import PropTypes from 'prop-types';
import moment from 'moment';

const TimePeriod = (props) => {
    const {
        onChangeActivityDurationHour,
        onChangeActivityDurationMinute,
        showActivityDuration,
        onChangeTimePeriod,
        setTimePeriodsList,
        timePeriodsList,
        onClickRemovePeriod
    } = props;
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <div className="fw-600 mb-6px">
                        Allow customers to choose within a time period in the day
                    </div>
                    <div className="ant-form-item-label">
                        <span className="ant-form-item-required">
                            What time period in the day can this product be booked?
                        </span>
                    </div>
                </Col>
            </Row>

            {Array.isArray(timePeriodsList) &&
                timePeriodsList.map((value, index) => (
                    <div key={index + 'ti'}>
                        <Row>
                            <Col xs={6}>
                                <h6>{`Period ${index + 1}`}</h6>
                            </Col>
                            <Col xs={6} className="d-flex justify-content-end">
                                {index !== 0 ? (
                                    <Button
                                        color="link"
                                        type="button"
                                        className="p-0 text-small text-nowrap"
                                        onClick={() => onClickRemovePeriod(index)}>
                                        Remove this time period
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                        <Row key={index} style={{ margin: 0 }}>
                            <Col md={4}>
                                <div>From</div>
                                <Spacer top={5} />
                                <Form.Item
                                    name={`from_${index}`}
                                    className="check-style"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select'
                                        }
                                    ]}>
                                    <TimePicker
                                        size="large"
                                        //defaultValue={0}
                                        use12Hours
                                        format="hh:mm A"
                                        placeholder="12:00 AM"
                                        style={{
                                            height: '50px',
                                            width: '216px'
                                        }}
                                        onChange={(e) =>
                                            onChangeTimePeriod(
                                                e ? e.format('hh:mm A') : null,
                                                'from',
                                                index
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col md={4}>
                                <div>Until</div>
                                <Spacer top={5} />
                                <Form.Item
                                    name={`to_${index}`}
                                    className="check-style"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select'
                                        }
                                    ]}>
                                    <TimePicker
                                        size="large"
                                        //defaultValue={0}
                                        use12Hours
                                        format="hh:mm A"
                                        placeholder="12:00 AM"
                                        style={{
                                            height: '50px',
                                            width: '216px'
                                        }}
                                        onChange={(e) =>
                                            onChangeTimePeriod(
                                                e ? e.format('hh:mm A') : null,
                                                'to',
                                                index
                                            )
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            {moment(value.from, 'hh:mm A').isSameOrAfter(
                                moment(value.to, 'hh:mm A')
                            ) && (
                                <div style={{ marginBottom: '0.75rem', marginLeft: 10 }}>
                                    <div className="time-suggested-tip">
                                        Note: Selected time period has moved into the next day!
                                    </div>
                                </div>
                            )}
                        </Row>
                    </div>
                ))}

            <Button
                color="link"
                type="button"
                className={`px-0 text-small text-dark ${!showActivityDuration ? 'mb-3' : ''}`}
                onClick={setTimePeriodsList}>
                <PlusIcon /> Add another time period in the day.
            </Button>

            {showActivityDuration && (
                <div className="mt-3">
                    <div className="ant-form-item-label">
                        <span className="ant-form-item-required">
                            {"What's the duration of your Activity ?"}
                        </span>
                    </div>
                    <Row className="flex-nowrap m-0">
                        <Col md={4}>
                            <InputGroup className="_type-time _type-time-stable mb-10px">
                                <Form.Item
                                    className="mb-0"
                                    name="activity_duration_hour"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select'
                                        }
                                    ]}>
                                    <InputNumber
                                        size="large"
                                        min={0}
                                        max={23}
                                        placeholder={'0'}
                                        onChange={onChangeActivityDurationHour}
                                    />
                                </Form.Item>
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <span className="d-none d-lg-inline">{'hours'}</span>
                                        <span className={'d-lg-none'}>{'hr'}</span>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>

                        <Col md={4}>
                            <InputGroup className="_type-time _type-time-stable mb-10px">
                                <Form.Item
                                    className="mb-0"
                                    name="activity_duration_minute"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select'
                                        }
                                    ]}>
                                    <InputNumber
                                        size="large"
                                        min={0}
                                        max={59}
                                        placeholder={'0'}
                                        onChange={onChangeActivityDurationMinute}
                                        formatter={(value) =>
                                            `${value && value < 10 ? '0' + value : value}`
                                        }
                                    />
                                </Form.Item>
                                <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                        <span className="d-none d-lg-inline">{'minutes'}</span>
                                        <span className={'d-lg-none'}>{'min'}</span>
                                    </InputGroupText>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col>
                    </Row>
                </div>
            )}
        </div>
    );
};

TimePeriod.propTypes = {
    onChangeActivityDurationHour: PropTypes.func,
    onChangeActivityDurationMinute: PropTypes.func,
    showActivityDuration: PropTypes.bool,
    onChangeTimePeriod: PropTypes.func,
    setTimePeriodsList: PropTypes.func,
    timePeriodsList: PropTypes.array,
    onClickRemovePeriod: PropTypes.func
};
export default TimePeriod;

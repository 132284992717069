import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';

const styles = {
    cell: {
        border: 'none'
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px',
        width: '68px'
    },
    input: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        height: '48px',
        width: '89px',
        textAlign: 'center',
        marginRight: '10px'
    }
};

export default function GroupRow(props) {
    const [amount, setAmount] = useState(1.0);

    const handleAmount = (e) => {
        setAmount(e.target.value);
    };

    return (
        <>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={styles.cell} align="left">
                    {props.groupName}
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={styles.currency}>USD</div>
                        <input
                            style={{ ...styles.input, borderRadius: '0 5px 5px 0' }}
                            type="number"
                            value={amount}
                            min={1}
                            onChange={handleAmount}
                        />
                        <div>PP</div>
                    </div>
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={styles.currency}>USD</div>
                        <input
                            style={{ ...styles.input, borderRadius: '0 5px 5px 0' }}
                            type="number"
                            value={amount}
                            min={1}
                            onChange={handleAmount}
                        />
                        <div>PP</div>
                    </div>
                </TableCell>
            </TableRow>
        </>
    );
}

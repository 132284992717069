import React from 'react';
import PropTypes from 'prop-types';
import { toDecimal, transformFirstLetterToUpperCase } from 'utils';
import VendorCalendarProductPart from './vendor-calendar-product-part';
import moment from 'moment';

export default function RefundPart(props) {
    const { paymentDetail } = props;

    return (
        <>
            <div className="mb-4">
                <div className="pb-2 border-bottom">
                    <div className="d-flex align-items-center">
                        <div className="pr-2">
                            <span className="fs-20 fw-700">Transfer overview</span>
                        </div>
                    </div>
                </div>
                <div className="py-2">
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Refunded amount</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`${
                                paymentDetail?.amount ? Number(paymentDetail?.amount) / 100 : 0
                            } ${paymentDetail?.currency || ''}`}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Received amount</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">{`${
                                paymentDetail?.vendor_amount
                                    ? Number(paymentDetail?.vendor_amount) / 100
                                    : 0
                            } ${paymentDetail?.vendor_currency || ''}`}</span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Refund id</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.refund_id || 'N/A'}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Date</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {paymentDetail?.created &&
                                    moment.unix(paymentDetail.created).format('MMM D, H:mm A')}
                            </span>
                        </div>
                    </div>
                    <div className="payment-stats-data-row">
                        <div className="payment-stats-data-header">
                            <span className="text-muted fs-14">Status</span>
                        </div>
                        <div className="payment-stats-data-info">
                            <span className="text-gray fs-14">
                                {transformFirstLetterToUpperCase(paymentDetail?.status)}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <VendorCalendarProductPart data={paymentDetail?.transaction ?? {}} />
        </>
    );
}

RefundPart.propTypes = {
    paymentDetail: PropTypes.object,
    data: PropTypes.object
};

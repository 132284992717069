import { callApi } from 'utils/apiCaller';
import { Notification } from 'components/atoms/notification';
import { queryBuilder } from 'utils';
import { loading_ } from 'store/actions';
import {
    GET_ALL_AVAILABILITY_DATA,
    GET_ALL_PRODUCT_CALENDARS_DATA,
    UPDATE_PRODUCT_AVAILABILITY,
    UPDATE_CALENDAR_ACCESS,
    UPDATE_AVAILABILITY_STATE
} from 'store/types';

export function elseNotifyError(response, status) {
    if (response?.data && Array.isArray(response?.data?.errors) && response?.data?.errors.length) {
        return Notification('error', response.data.errors[0].message, status);
    } else {
        return Notification('error', response?.data?.message, status);
    }
}

export const updateAvailabilityState_ = (payload) => ({
    type: UPDATE_AVAILABILITY_STATE,
    payload
});

export const getAvailabilities_ = (payload) => ({
    type: GET_ALL_AVAILABILITY_DATA,
    payload
});
export const getAvailabilities = (query) => {
    return (dispatch) => {
        let url = `/availability`;
        if (query) {
            query = queryBuilder(query);
            url = `/availability${query}`;
        }

        dispatch(loading_(true));
        callApi(url, 'GET')
            .then((response) => {
                const status = response?.status;

                if (status === 200) {
                    dispatch(getAvailabilities_(response.data));
                    return true; //Notification('success', response.data.message, status);
                } else elseNotifyError(response, status);
            })
            .finally(() => dispatch(loading_(false)));
    };
};

export const getCalendarsByProduct_ = (payload) => ({
    type: GET_ALL_PRODUCT_CALENDARS_DATA,
    payload
});
export const getCalendarsByProduct = (id, query) => async (dispatch) => {
    let url = `/availability/${id}`;
    if (query) {
        query = queryBuilder(query);
        url = `/availability/${id}${query}`;
    }

    dispatch(loading_(true));

    callApi(url, 'GET')
        .then((response) => {
            const { status } = response;

            if (status === 200) {
                dispatch(getCalendarsByProduct_(response.data));
                return true; // Notification('success', response.data.message, status);
            } else elseNotifyError(response, status);
        })
        .finally(() => dispatch(loading_(false)));
};

export const updateProductAvailability_ = (payload) => ({
    type: UPDATE_PRODUCT_AVAILABILITY,
    payload
});
export const updateProductAvailability = (id, data) => async (dispatch) => {
    let url = `/update-all-spaces-available/${id}`;

    dispatch(loading_(true));
    let response = await callApi(url, 'PUT', data);
    const { status } = response;

    if (status === 200) {
        dispatch(updateAvailabilityState_(true));
        dispatch(updateProductAvailability_(response.data));
        Notification('success', response.data.message, status);
        dispatch(updateAvailabilityState_(false));
    } else {
        dispatch(updateAvailabilityState_(true));
        elseNotifyError(response, status);
        dispatch(updateAvailabilityState_(false));
        return dispatch(loading_(false));
    }
    return dispatch(loading_(false));
};

export const updateCalendarAccess_ = (payload) => ({
    type: UPDATE_CALENDAR_ACCESS,
    payload
});

export const updateCalendarAccess = (id, data) => async (dispatch) => {
    let url = `/update-calendar-access/${id}`;

    dispatch(loading_(true));
    let response = await callApi(url, 'PUT', data);
    const { status } = response;

    if (status === 200) {
        dispatch(updateAvailabilityState_(true));
        dispatch(updateCalendarAccess_(response.data));
        Notification('success', response.data.message, status);
        dispatch(updateAvailabilityState_(false));
    } else {
        dispatch(updateAvailabilityState_(true));
        elseNotifyError(response, status);
        dispatch(updateAvailabilityState_(false));
        return dispatch(loading_(false));
    }
    return dispatch(loading_(false));
};

export const pauseProduct = ({ id, params }) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/pause-product/${id}?${params}`, 'PUT').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(loading_(false));
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                dispatch(loading_(false));
                return elseNotifyError(response, status);
            }
        });
    };
};

export const archiveProduct = ({ id }) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/archive-product/${id}`, 'PUT').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(loading_(false));
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                dispatch(loading_(false));
                return elseNotifyError(response, status);
            }
        });
    };
};
export const unArchiveProduct = ({ id }) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/unarchive-product/${id}`, 'PUT').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(loading_(false));
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                dispatch(loading_(false));
                return elseNotifyError(response, status);
            }
        });
    };
};
export const reinstateSale = ({ id }) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/reinstatesale-product/${id}`, 'PUT').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(loading_(false));
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                dispatch(loading_(false));
                return elseNotifyError(response, status);
            }
        });
    };
};
export const cancelProduct = ({ params }) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/cancel-product?${params}`, 'PUT').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(loading_(false));
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                dispatch(loading_(false));
                return elseNotifyError(response, status);
            }
        });
    };
};

export const stopProduct = ({ id }) => {
    return (dispatch) => {
        dispatch(loading_(true));
        return callApi(`/stop-product/${id}`, 'PUT').then((response) => {
            const status = response?.status;
            if (status === 200) {
                dispatch(loading_(false));
                Notification('success', response?.data?.message || response?.message, status);
                return true;
            } else {
                dispatch(loading_(false));
                return elseNotifyError(response, status);
            }
        });
    };
};

import { Progress } from 'antd';
import React from 'react';
import Proptypes from 'prop-types';

const BasicProgress = (props) => {
    return (
        <Progress
            trailColor="#f0f0f0"
            strokeColor={
                props.percent < 67 ? (props.percent < 33 ? '#D14848' : '#FFC700') : '#06B706'
            }
            className={
                (props.percent < 67
                    ? props.percent < 33
                        ? 'text-red'
                        : 'text-yellow'
                    : 'text-green') +
                ' ' +
                props.className
            }
            percent={props.percent}
            format={(percent) => percent + '%'}
            showInfo={props.showInfo}
        />
    );
};

BasicProgress.propTypes = {
    percent: Proptypes.any,
    className: Proptypes.any,
    showInfo: Proptypes.any
};

export default BasicProgress;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { createFeedback } from '../../../store/actions/feedbackActions';

import { Button, Drawer, Form, Input, message, Radio, Upload } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

import CancelIcon from '../../../assets/images/cancelhelp.svg';
import { API_URL } from '../../../utils/constants';
import ConfirmEditModal from '../../molecules/confirmation-modal';

import './index.css';

const { TextArea } = Input;

const options = [
    { label: 'Issues', value: 'issues' },
    { label: 'Suggestions', value: 'suggestions' }
];

/**
 * Feedback Component: Used to get feedback
 * from vendors and send via an api to the backend.
 * Has its own state in the component.
 */

const Feedback = (props) => {
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [feedbackProcessing, setFeedbackProcessing] = useState(false);
    const [description, setDescription] = useState('');
    const [thankYouNote, setThankYouNote] = useState('');
    const [isModalClose, setIsModalClose] = useState(false);

    const url = useLocation();
    const [form] = Form.useForm();

    const submitFeedback = () => {
        const formValue = form.getFieldsValue();
        const { feedbackType, feedbackTopic, feedbackMessage, screenshotUrl } = formValue;

        setFeedbackProcessing(true);

        const payload = {
            feedbackType,
            feedbackTopic,
            routePath: url.pathname,
            ...(feedbackMessage && { message: feedbackMessage }),
            ...(screenshotUrl && { screenshotUrl: screenshotUrl.file.response.data.url })
        };

        props.createFeedback(payload);
    };

    const authToken = localStorage.getItem('token');
    const props1 = {
        name: 'media',
        action: `${API_URL}/media-library`,
        listType: 'picture',
        maxCount: 1,
        headers: {
            authorization: `Bearer ${authToken}`
        },
        onChange(info) {
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    };

    const displayFeedbackText = (e) => {
        const value = e.target.value;

        if (value === 'issues') {
            setDescription('This is for a system issue that needs to be worked');
        }

        if (value === 'suggestions') {
            setDescription(
                'This is for feedback and recommendation on ways this page can be improved'
            );
        }
    };

    const brieflyShowText = () => {
        const removeText = setTimeout(() => setThankYouNote(''), 3000);
        setThankYouNote('Thank you for your feedback');

        if (thankYouNote) {
            removeText();
        }
    };

    const reset = () => {
        form.resetFields();
        setFeedbackProcessing(false);
        setDescription('');
        brieflyShowText();
    };

    const openModal = () => {
        setmodalIsOpen(true);
    };

    function handleModal() {
        const formValue = form.getFieldsValue();
        const { feedbackType, feedbackTopic, feedbackMessage, screenshotUrl } = formValue;

        if (feedbackType || feedbackTopic || feedbackMessage || screenshotUrl) {
            return setIsModalClose(!isModalClose);
        }
        return setmodalIsOpen(false);
    }

    const closeAllModals = () => {
        if (modalIsOpen && isModalClose) {
            form.resetFields();
            setmodalIsOpen(false);
            setDescription('');
            setIsModalClose(!isModalClose);
        }
    };

    useEffect(() => {
        if (props.feedback_data.success) {
            reset();
            setmodalIsOpen(false);
        }

        if (!props.feedback_data.success) {
            setFeedbackProcessing(false);
        }
    }, [props.feedback_data]);

    return (
        <>
            <Drawer
                placement={props.drawerPlacement || 'left'}
                onClose={handleModal}
                visible={modalIsOpen}
                closable={false}
                key="left"
                width={400}
                zIndex={1061}
                className="help-center-drawer">
                <div className="bg-white" style={{ minHeight: '100vh' }}>
                    <div className="brown-modal d-flex flex-nowrap justify-content-between">
                        <h5
                            className="m-0 flex-grow-1"
                            style={{ textAlign: 'left', color: '#fff' }}>
                            Feedback
                        </h5>
                        <span
                            role="button"
                            tabIndex="0"
                            onClick={handleModal}
                            onKeyUp={handleModal}>
                            <img alt="Close" src={CancelIcon} />
                        </span>
                    </div>
                    <div className="p-3">
                        <Form
                            form={form}
                            layout="vertical"
                            className="form-label-fw-500"
                            onFinish={submitFeedback}>
                            <p className="mb-3">Please give us your feedback on this page</p>
                            <Form.Item
                                className="mb-3"
                                label="Type of Feedback"
                                name="feedbackType"
                                rules={[{ required: true, message: 'feedback type is required' }]}>
                                <Radio.Group
                                    options={options}
                                    optionType="button"
                                    buttonStyle="solid"
                                    onChange={displayFeedbackText}
                                />
                            </Form.Item>

                            <div className="mb-3">
                                <p
                                    className="font-italic font-weight-bold"
                                    style={{ fontSize: '12px' }}>
                                    {description}
                                </p>
                            </div>

                            <Form.Item
                                className="mb-3"
                                label="Feedback Topic"
                                name="feedbackTopic"
                                rules={[{ required: true, message: 'feedback topic is required' }]}>
                                <Input size="small" placeholder="e.g - Create Button not working" />
                            </Form.Item>

                            <Form.Item
                                className="mb-3"
                                label="Feedback Description"
                                name="feedbackMessage">
                                <TextArea
                                    size="small"
                                    placeholder="Please give a short description of your feedback e.g - On clicking the create button, nothing happens."
                                    autoSize={{ minRows: 5, maxRows: 8 }}
                                />
                            </Form.Item>

                            <Form.Item
                                className="mb-3"
                                name="screenshotUrl"
                                valuePropName="file"
                                label="Please attach screenshot image(s) below">
                                <Upload {...props1}>
                                    <div>
                                        <span className="btn btn-link p-0 mb-0">
                                            Upload Screenshot
                                        </span>
                                    </div>
                                </Upload>
                            </Form.Item>

                            <Button
                                type="primary"
                                className="fs-13 btn-px"
                                htmlType="submit"
                                loading={feedbackProcessing}>
                                Submit
                            </Button>

                            <div className="mt-3">
                                <p
                                    className="font-italic font-weight-bold fade-in-text"
                                    style={{ fontSize: '15px' }}>
                                    {thankYouNote}
                                </p>
                            </div>
                        </Form>
                    </div>
                </div>
            </Drawer>
            {/* Confirm Edit Modal */}
            <ConfirmEditModal
                modal={isModalClose}
                handleModal={handleModal}
                closeModal={closeAllModals}
            />
            <Button
                onClick={openModal}
                type="dark"
                className={props.buttonClassName}
                size={props.buttonSize}
                icon={<QuestionCircleOutlined />}
                block={props.buttonBlock}>
                <span className={props.hideName ? `d-none d-${props.hideName}-inline` : 'd-inline'}>
                    {' Give Feedback '}
                </span>
            </Button>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        feedback_data: state.feedback
    };
};

const mapDispatch = (dispatch) => ({
    createFeedback: (data) => dispatch(createFeedback(data))
});

Feedback.propTypes = {
    createFeedback: PropTypes.func,
    feedback_data: PropTypes.object,
    buttonSize: PropTypes.string,
    form: PropTypes.any,
    buttonBlock: PropTypes.any,
    hideName: PropTypes.string,
    buttonClassName: PropTypes.string,
    drawerPlacement: PropTypes.string
};

export default connect(mapStateToProps, mapDispatch)(Feedback);

/* eslint eqeqeq: 0 */
/* eslint-disable jsx-a11y/anchor-is-valid */
/*eslint-disable eqeqeq*/
/*eslint eqeqeq: "off"*/
/* eslint-disable-line */
/* eslint-disable-next-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class PublicRoute extends Component {
    componentDidMount() {
        document.body.classList.add('target-main-layout');
    }

    isLoggedIn() {
        // const { my_profile } = this.props;
        return this.props.isLoggedIn && localStorage.getItem('token') ? true : false;
        // return (this.props.isLoggedIn && my_profile?.photo_url) ? true : false
    }

    render() {
        const { component: Component, restricted, ...rest } = this.props;
        let isLoggedIn = this.props.isLoggedIn && localStorage.getItem('token') ? true : false; //this.isLoggedIn();
        // this.props.history.push()
        return (
            <Route
                {...rest}
                render={(props) =>
                    isLoggedIn && restricted ? <Redirect to="/home" /> : <Component {...props} />
                }
            />
        );
    }
}
PublicRoute.propTypes = {
    component: PropTypes.any,
    isLoggedIn: PropTypes.any,
    restricted: PropTypes.any
};
const mapState = (state) => {
    return {
        authStates: state.auth,
        isLoggedIn: state?.auth?.auth?.token || false,
        my_profile: state.auth.my_profile
    };
};

export default connect(mapState)(PublicRoute);

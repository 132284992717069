import { PlusCircleOutlined } from '@ant-design/icons';
import React from 'react';
import './imageuploader.scss';
import PropTypes from 'prop-types';

export default function ImageUploaderComponent({ onChange, children }) {
    return (
        <div className="img-uploader">
            {children ? (
                children
            ) : (
                <div className="d-flex align-items-center justify-content-center cursor-pointer">
                    <PlusCircleOutlined color="#A2792C" />
                    <p className="mb-0 ml-3 fw-600 fs-14">Add Photo</p>
                </div>
            )}
            <input accept="image/*" type="file" name="" id="" onChange={onChange} />
        </div>
    );
}

ImageUploaderComponent.propTypes = {
    children: PropTypes.any,
    onChange: PropTypes.func
};

import React, { useState } from 'react';
import ReactCrop from 'react-image-crop';
import { Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import 'react-image-crop/lib/ReactCrop.scss';
import './index.scss';

let imageRef;
let fileUrl;

const MediaCropper = (props) => {
    const { isVisible, handleCancel, img } = props;
    const [crop, setCrop] = useState({
        aspect: 16 / 9,
        unit: '%',
        width: 100
    });
    const onCrop = async () => {
        await makeClientCrop(crop);
    };
    const onImageLoaded = (image) => {
        imageRef = image;
    };
    const onCropComplete = async () => {
        // await makeClientCrop(crop);
    };
    const onCropChange = (crop) => {
        setCrop(crop);
    };
    const makeClientCrop = async (crop) => {
        if (imageRef) {
            const [croppedImageUrl, fileBlob] = await getCroppedImg(imageRef, crop, 'newFile.jpeg');
            props.onCrop &&
                props.onCrop({
                    croppedImageUrl,
                    fileBlob
                });
        }
    };

    const getCroppedImg = (image, crop, fileName) => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve([fileUrl, blob]);
            }, 'image/jpeg');
        });
    };
    return (
        <Modal
            width={600}
            wrapClassName="media-cropper-modal-wrapper "
            visible={isVisible}
            onCancel={handleCancel}
            footer={null}>
            <div className="media-cropper">
                <div className="media-cropper__container">
                    <h6>Position your photo</h6>
                    <ReactCrop
                        locked={true}
                        src={img}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    />
                </div>
            </div>
            <Button onClick={onCrop} type="primary">
                Save
            </Button>
        </Modal>
    );
};

MediaCropper.propTypes = {
    isVisible: PropTypes.bool,
    handleCancel: PropTypes.func,
    img: PropTypes.any,
    onCrop: PropTypes.func
};

export default MediaCropper;

import React, { useState, useEffect } from 'react';
import './index.scss';
import { Button, Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import Proptypes from 'prop-types';
import Caret from '../../../assets/images/caret.svg';
import RightCaret from '../../../assets/images/down-caret.svg';
import { adventureCategoryIcons } from 'utils/helper';

const DropdownSelectButton = (props) => {
    const [isAddAdventure, setIsAddAdventure] = useState(false);
    const [selectedAdventure, setSelectedAdventure] = useState([]);

    const onSelect = (item) => {
        const index = isSelected(item);
        if (index === -1) {
            setSelectedAdventure([...selectedAdventure, item]);
            props.onChangeData([...selectedAdventure, item]);
        } else {
            let tmp = selectedAdventure;
            tmp.splice(index, 1);
            setSelectedAdventure([...tmp]);
            props.onChangeData([...tmp]);
        }
    };
    const isSelected = (item) =>
        selectedAdventure.findIndex((res) => res.toLocaleLowerCase() === item.toLocaleLowerCase());
    const toggleAdventure = () => setIsAddAdventure(!isAddAdventure);
    useEffect(() => {
        if (props.preData) setSelectedAdventure(props.preData);
    }, [props.preData, props.data]);

    return (
        <div className="dropdown-select-button">
            <Dropdown isOpen={isAddAdventure} toggle={toggleAdventure}>
                <DropdownToggle
                    className="dropdown-adventure"
                    style={{
                        backgroundColor:
                            isAddAdventure || selectedAdventure.length > 0 ? '#E7D99F' : 'white'
                    }}>
                    {props.name}
                    <img
                        src={isAddAdventure ? Caret : RightCaret}
                        alt=""
                        style={{
                            width: isAddAdventure ? 12 : 7.33,
                            height: isAddAdventure ? 7.33 : 12
                        }}
                    />
                </DropdownToggle>
                <DropdownMenu
                    style={{ width: '517px' }}
                    className={`category-menu ${props.isLeft ? 'active' : ''}`}>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="dropdown"
                        aria-label="Close"
                        title="Close"
                        onClick={toggleAdventure}>
                        <span role="img" aria-label="close" className="anticon anticon-close">
                            <svg
                                width="8"
                                viewBox="0 0 24 27"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M14.1988 13.4683L23.5439 3.5314C24.1521 2.88505 24.1521 1.83998 23.5439 1.19363C22.936 0.547274 21.9532 0.547274 21.3453 1.19363L11.9999 11.1305L2.65474 1.19363C2.04658 0.547274 1.064 0.547274 0.456124 1.19363C-0.152041 1.83998 -0.152041 2.88505 0.456124 3.5314L9.80125 13.4683L0.456124 23.4052C-0.152041 24.0515 -0.152041 25.0966 0.456124 25.7429C0.759068 26.0654 1.15739 26.2273 1.55543 26.2273C1.95347 26.2273 2.35152 26.0654 2.65474 25.7429L11.9999 15.8061L21.3453 25.7429C21.6485 26.0654 22.0466 26.2273 22.4446 26.2273C22.8426 26.2273 23.2407 26.0654 23.5439 25.7429C24.1521 25.0966 24.1521 24.0515 23.5439 23.4052L14.1988 13.4683Z"
                                    fill="black"
                                />
                            </svg>
                        </span>
                    </button>
                    <div className="d-flex flex-wrap">
                        {props.data && props.data.length > 0
                            ? props.data.map((res, i) => (
                                  <Button
                                      key={i}
                                      style={{
                                          backgroundColor:
                                              isSelected(res.name) !== -1 ? '#E7D99F' : 'white'
                                      }}
                                      onClick={() => onSelect(res.name)}>
                                      <img src={adventureCategoryIcons[res.name]} alt="" />
                                      {res.name}
                                  </Button>
                              ))
                            : 'Nothing'}
                    </div>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

DropdownSelectButton.propTypes = {
    data: Proptypes.any,
    preData: Proptypes.any,
    onChangeData: Proptypes.func,
    name: Proptypes.string,
    isLeft: Proptypes.bool
};

export default DropdownSelectButton;

/* eslint-disable react/jsx-key */
import historicattractions from '../../../../../assets/images/historic-attractions.svg';
import touristattractions from '../../../../../assets/images/tourist-attractions.svg';
import artsandculture from '../../../../../assets/images/artsandculture.svg';
import localfavorites from '../../../../../assets/images/local-favorites.svg';
import amusementpark from '../../../../../assets/images/amusement-park.svg';
import architecture from '../../../../../assets/images/architecture.svg';
import supermarket from '../../../../../assets/images/supermarket.svg';
import artgallery from '../../../../../assets/images/art-gallery.svg';
import wildlife from '../../../../../assets/images/wildlife.svg';
import aquaria from '../../../../../assets/images/aquaria.svg';
import beaches from '../../../../../assets/images/beaches.svg';
import history from '../../../../../assets/images/history.svg';
import casino from '../../../../../assets/images/casino.svg';
import museum from '../../../../../assets/images/museum.svg';
// import lion from "../../../../../assets/images/lion.svg";
// import elephant from "../../../../../assets/images/elephant.svg";
import { Modal, Button, Form, Row } from 'antd';
import Prompter from '../../../../atoms/prompter';
import React, { useState } from 'react';
import Spacer from '../../../../atoms/spacer';
import PropTypes from 'prop-types';
// import Display from "../../../../atoms/display";
import './index.scss';
import SelectorOption from '../../../../molecules/selector/selector-option';
import Frequency from '../frequency';
// import Display from "../../../../atoms/display";
import ShowTravellers from '../show-travellers';

const TopSights = (props) => {
    const { handleOk, isVisible, handleCancel } = props;
    const [selected, setSelected] = useState([]);
    const [showFrequecyModal, setShowFrequecyModal] = useState(false);
    const [showTravellersOptions, setShowTravellersOptions] = useState(false);
    const topSights = [
        {
            icon: artsandculture,
            name: 'Art and culture'
        },
        {
            icon: aquaria,
            name: 'Aquaria'
        },
        {
            icon: artgallery,
            name: 'Art galleries'
        },
        {
            icon: architecture,
            name: 'Architecture'
        },
        {
            icon: museum,
            name: 'Museums'
        },
        {
            icon: amusementpark,
            name: 'Amusement parks'
        },
        {
            icon: beaches,
            name: 'Beaches'
        },
        {
            icon: casino,
            name: 'Casinos'
        },
        {
            icon: supermarket,
            name: 'Markets'
        },
        {
            icon: historicattractions,
            name: 'Historic Attractions'
        },
        {
            icon: history,
            name: 'History'
        },
        {
            icon: touristattractions,
            name: 'Cultural tourist attractions'
        },
        {
            icon: localfavorites,
            name: 'Local favourites'
        },
        {
            icon: wildlife,
            name: 'Wildlife',
            options: [
                {
                    icon: '',
                    name: 'Elephant'
                },
                {
                    icon: '',
                    name: 'Buffalo'
                },
                {
                    icon: '',
                    name: 'Hyena'
                },
                {
                    icon: '',
                    name: 'Lion'
                },
                {
                    icon: '',
                    name: 'Leopard'
                },
                {
                    icon: '',
                    name: 'Giraffe'
                },
                {
                    icon: '',
                    name: 'Black Rhino'
                },
                {
                    icon: '',
                    name: 'White Rhino'
                },
                {
                    icon: '',
                    name: 'Cheetah'
                },
                {
                    icon: '',
                    name: 'Hippo'
                },
                {
                    icon: '',
                    name: 'Wild Dog'
                },
                {
                    icon: '',
                    name: 'Wild beast'
                },
                {
                    icon: '',
                    name: 'Zebra'
                }
            ]
        }
    ];

    const handleChange = (e) => {
        const newSelected = [...selected];
        newSelected[newSelected.length - 1]['frequency'] = e.target.value;
        setSelected(newSelected);
    };

    const closeFrequencyModal = () => {
        setShowFrequecyModal(false);
        setShowTravellersOptions(true);
    };

    const removeLastEntry = () => {
        const newSelected = [...selected];
        newSelected.splice(newSelected.length - 1, 1);
        setSelected(newSelected);
    };

    const cancelFrequencyModal = () => {
        setShowFrequecyModal(false);
        removeLastEntry();
    };

    const cancelShowTravelers = () => {
        setShowTravellersOptions(false);
        removeLastEntry();
    };

    const acceptTravellersOptions = (item) => {
        const newSelected = [...selected];
        newSelected[newSelected.length - 1]['photoUrl'] = item.img;
        setSelected(newSelected);
        setShowTravellersOptions(false);
    };

    const select = (name) => {
        const newSelected = [...selected];
        const exists = newSelected.findIndex((i) => i.name == name);
        if (exists >= 0) {
            newSelected.splice(exists, 1);
        } else {
            newSelected.push({
                name,
                frequency: '',
                photoUrl: '',
                icon: ''
            });
        }
        setSelected(newSelected);
        setShowFrequecyModal(true);
    };

    return (
        <Modal
            width={703}
            onOk={handleOk}
            centered={true}
            visible={isVisible}
            onCancel={handleCancel}
            wrapClassName="topsights"
            footer={[
                <Button
                    style={{ width: 135, height: 42 }}
                    type="outline"
                    className="rounded-btn mr20"
                    onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    onClick={handleOk}
                    style={{ width: 135, height: 42 }}
                    type="primary"
                    className="rounded-btn">
                    Save
                </Button>
            ]}>
            <h4>Add top sights to see on this Itinerary</h4>
            <Prompter
                text="I will import top sights information from my other activity"
                textColor="#A2792C"
            />
            <Spacer top={16} />
            <p>Select sights your customers will see on this itinerary.</p>
            <p>
                {' '}
                Please note that this is what your customers will search for when booking your
                product..
            </p>
            <Spacer top={36} />
            <div className="topsights__form-wrapper">
                <Form layout="vertical">
                    <h6>Top Sights</h6>
                    <Row>
                        {topSights.map(({ name, icon }, index) => (
                            <SelectorOption
                                key={index}
                                icon={icon}
                                name={name}
                                selected={selected}
                                height={39}
                                handleSelect={() => select(name)}
                            />
                        ))}
                    </Row>
                    <Row className="topsights-list">
                        {selected.map((item) => (
                            <div className="topsights-list__item">
                                <img
                                    alt=""
                                    className="topsights-list__item-img"
                                    src={item.photoUrl}></img>
                                <div className="topsights-list__item-info">
                                    <img alt="" src={item.icon}></img>
                                    <span>{item.name}</span>
                                    <span
                                        className={`topsights-list__item-${item.frequency}`}></span>
                                </div>
                            </div>
                        ))}
                    </Row>
                </Form>
            </div>
            {showFrequecyModal && (
                <Frequency
                    value={selected[selected.length - 1]['frequency']}
                    handleChange={handleChange}
                    isVisible={showFrequecyModal}
                    handleOk={closeFrequencyModal}
                    handleCancel={cancelFrequencyModal}
                />
            )}
            {showTravellersOptions && (
                <ShowTravellers
                    handleCancel={cancelShowTravelers}
                    handleOk={acceptTravellersOptions}
                    isVisible={showTravellersOptions}
                />
            )}
        </Modal>
    );
};

TopSights.propTypes = {
    handleOk: PropTypes.func.isRequired,
    isVisible: PropTypes.bool.isRequired,
    handleCancel: PropTypes.func.isRequired
};

export default TopSights;

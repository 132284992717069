import React from 'react';
import { Tooltip } from 'antd';

export const calendarTitleAndLengthWrap = (data) => {
    return (
        <>
            <Tooltip title={data?.title ? data?.title : ''}>
                <div className="fs-14 fw-500 text-primary text-truncate">
                    {data?.title ? data?.title : ''}
                </div>
            </Tooltip>
            <span className="d-inline-flex text-nowrap fs-12 text-primary pb-1">
                <span>
                    {data?.weeks} Week
                    {data?.weeks > 1 ? 's' : ''}{' '}
                    {data?.days ? `${data?.days} Day${data?.days > 1 ? 's ' : ''}` : ''}
                </span>
            </span>
        </>
    );
};

export const calendarLogoWrap = (
    <div className="calendar-logo-container">
        <img
            src="https://res.cloudinary.com/bimbiphilips/image/upload/v1617274056/5zyo606ull6/tcfiwpczctjkubzmtiat.png"
            alt="Logo"
        />
    </div>
);

export const customerCalendarWrapSingle = (
    <div className="d-flex mx-n1">
        <div className="px-1">
            <img
                className="calendar-user-img _main"
                src={`https://randomuser.me/api/portraits/women/${30}.jpg`}
                alt="User"
            />
        </div>
        <div className="px-1">
            <div className="lh-1-2 text-nowrap">
                <span className="fs-16 fw-600 text-primary">Buki</span>{' '}
                <span className="fs-12">(Lead Traveler)</span>
            </div>
            <div className="lh-1-2 text-nowrap">
                <span className="fs-14">United States</span>
            </div>
            <div className="lh-1-2 text-nowrap">
                <span className="fs-14">Language : English</span>
            </div>
        </div>
    </div>
);

export const userInfoPopover = (data) => (
    <>
        <div className="single-user-info">
            <div className="d-flex mx-n2">
                <div className="px-2">
                    <img
                        className="calendar-user-img _sub"
                        src={
                            data?.photo_url
                                ? data?.photo_url
                                : require('assets/images/user-primary-img.svg')
                        }
                        alt="User"
                    />
                </div>
                <div className="px-2">
                    <div className="lh-1-2 text-nowrap">
                        <span className="fs-16 fw-600 text-special">
                            {data.first_name} {data.last_name}
                        </span>{' '}
                        <span className="fs-12">(Lead Traveler)</span>
                    </div>
                    <div className="lh-1-2 text-nowrap">
                        <span className="fs-14">
                            {data?.country ? data?.country : 'Not Provided'}
                        </span>
                    </div>
                    <div className="lh-1-2 text-nowrap">
                        <span className="fs-14">Language : English</span>
                    </div>
                </div>
            </div>
        </div>
    </>
);

export const multiUserInfoPopover = (data) => (
    <div className="scrollbar-fixed-dark" style={{ maxHeight: 200, overflowY: 'auto' }}>
        {data.map((item, index) => {
            return (
                <div className="single-user-info" key={index}>
                    <div className="d-flex mx-n2">
                        <div className="px-2">
                            <img
                                className="calendar-user-img _sub"
                                src={
                                    item?.photo_url
                                        ? item?.photo_url
                                        : require('assets/images/user-primary-img.svg')
                                }
                                alt="User"
                            />
                        </div>
                        <div className="px-2">
                            <div className="lh-1-2 text-nowrap">
                                <span className="fs-16 fw-600 text-special">
                                    {item.first_name} {item.last_name}
                                </span>{' '}
                                <span className="fs-12">(Lead Traveler)</span>
                            </div>
                            <div className="lh-1-2 text-nowrap">
                                <span className="fs-14">
                                    {item?.country ? item?.country : 'Not Provided'}
                                </span>
                            </div>
                            <div className="lh-1-2 text-nowrap">
                                <span className="fs-14">Language : English</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        })}
    </div>
);

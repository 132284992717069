import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';

const DropdownActionMenu = (props) => {
    const {
        onClickViewCalendar,
        onClickViewSummary
        // setCalendarDropdownFlag
    } = props;
    const onClick = ({ key }) => {
        // setCalendarDropdownFlag(false);
        if (key == 1) {
            onClickViewSummary();
        } else if (key == 2) {
            onClickViewCalendar();
        }
    };
    return (
        <Menu className="dropdown-action-menu" onClick={onClick}>
            <Menu.Item key="1">View Summary</Menu.Item>
            <Menu.Item key="2">View Calendar</Menu.Item>
        </Menu>
    );
};

DropdownActionMenu.propTypes = {
    onClickViewCalendar: PropTypes.func,
    onClickViewSummary: PropTypes.func
};

export default DropdownActionMenu;

import React from 'react';

import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';

const EventDetailRoomItem = ({ name, value }) => {
    return !value ? (
        <></>
    ) : (
        <Row className="border-bottom border-primary-shaded py-2" noGutters>
            <Col xs={6} md={4}>
                <div className="d-flex flex-nowrap">
                    <span className="fs-18 lh-1-7 flex-grow-1 mr-2">{name}</span>
                    <span className="fs-18 lh-1-7 mr-4">:</span>
                </div>
            </Col>
            <Col xs={6} md={8}>
                <span className="fs-18 lh-1-7">{value}</span>
            </Col>
        </Row>
    );
};

EventDetailRoomItem.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any
};

export default EventDetailRoomItem;

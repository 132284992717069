import { callApi } from 'utils/apiCaller';
import { Notification } from 'components/atoms/notification';

export const GET_CATEGORY = 'GET_CATEGORY';

export const getAllCategories_ = (payload) => ({ type: GET_CATEGORY, payload });
export const getAllCategories = () => {
    return (dispatch) => {
        callApi('/all-product-categories', 'GET').then((response) => {
            const status = response?.status;
            if (status === 200) {
                return dispatch(getAllCategories_(response));
            } else {
                if (
                    response?.data &&
                    Array.isArray(response?.data?.errors) &&
                    response?.data?.errors.length
                ) {
                    return Notification('error', response.data.errors[0].message, status);
                } else {
                    return Notification('error', response?.data?.message, status);
                }
            }
        });
    };
};

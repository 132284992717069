import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Input, Row } from 'antd';
import './index.scss';

function SyncRow(props) {
    const { title } = props;
    return (
        <div className="sync-row">
            <Row align="middle" gutter={4}>
                <Col span={6}>
                    <h6 className="sync-row__title">{title}</h6>
                </Col>
                <Col span={5}>
                    <Input size="large" placeholder="Website name" />
                </Col>
                <Col span={8}>
                    <Input size="large" placeholder="Paste your iCal link" />
                </Col>
                <Col span={3}>
                    <Button size="large" type="primary">
                        Import
                    </Button>
                </Col>
                <Col span={2}>
                    <Button size="large" type="text">
                        Export
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

SyncRow.propTypes = {
    title: PropTypes.string
};

export default SyncRow;

import React from 'react';
import Proptypes from 'prop-types';
import './index.scss';

const Display = (props) => {
    const { show, forceRender } = props;

    return (
        <div className="atc-display" style={{ opacity: show ? 1 : 0 }}>
            {
                // props.children
                show && !forceRender ? props.children : null
            }
            {forceRender && props.children}
        </div>
    );
};

Display.propTypes = {
    show: Proptypes.bool,
    forceRender: Proptypes.bool,
    children: Proptypes.string
};

export default Display;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';
import { Button, Row } from 'reactstrap';
import './index.scss';
import MoreInfo from '../../../assets/images/more_info.svg';
import Plus from '../../../assets/images/plus.svg';
import Edit from '../../../assets/images/edit.svg';
import Delete from '../../../assets/images/delete.svg';
import Proptypes from 'prop-types';
import { ConfirmationModal } from 'components/molecules';
export default function AddButton(props) {
    const { add = 'Add', moreInfo = true, onChange, data } = props;
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [currentDeleteIndex, setCurrentDeleteIndex] = useState('');

    useEffect(() => {
        //run for validation
        if (onChange && Array.isArray(data) && data.length > 0) onChange(data);
    }, [data]);

    return (
        <div className="button-add">
            <div className="card-head">
                <div className="d-flex flex-wrap align-items-center gap-1">
                    <div className="title">
                        {props.name}
                        {moreInfo ? <img src={MoreInfo} alt="" /> : null}
                    </div>
                    {props.isRecommended ? <div className="recommended">RECOMMENDED</div> : null}
                </div>
                <Button color="link" onClick={props.onClick}>
                    <img className="img-plus" src={Plus} alt="" /> {add}
                </Button>
            </div>
            {props.data && props.data.length > 0 ? (
                <Row className="actions">
                    <div>
                        {props.data.map((res, i) => (
                            <Row key={i}>
                                {res.type + ' Added'}

                                <div className="group-btn">
                                    <img
                                        src={Edit}
                                        alt=""
                                        title="Edit"
                                        className="itinerary-img cursor-pointer"
                                        onClick={() => props.onEdit(i)}
                                    />
                                    <img
                                        src={Delete}
                                        alt=""
                                        title="Delete"
                                        onClick={() => {
                                            setCurrentDeleteIndex(i);
                                            setIsConfirmationModalOpen(true);
                                            //props.onDelete(i);
                                        }}
                                        className="itinerary-img cursor-pointer"
                                    />
                                </div>
                            </Row>
                        ))}
                    </div>
                </Row>
            ) : null}

            <ConfirmationModal
                isModalOpen={isConfirmationModalOpen}
                setModalOpenState={(value) => setIsConfirmationModalOpen(value)}
                description="This update will be reflected on all calendars that this product is associated with."
                handleConfirmation={() => {
                    setIsConfirmationModalOpen(false);
                    props.onDelete(currentDeleteIndex);
                }}
            />
        </div>
    );
}
AddButton.propTypes = {
    add: Proptypes.string,
    name: Proptypes.string,
    onClick: Proptypes.func,
    onEdit: Proptypes.func,
    onDelete: Proptypes.func,
    isRecommended: Proptypes.bool,
    moreInfo: Proptypes.bool,
    data: Proptypes.any,
    onChange: Proptypes.func
};

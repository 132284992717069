/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Proptypes from 'prop-types';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { Form } from 'antd';
import { AddBookingButton, AddPricingButton } from 'components/atoms';
import {
    updateProductPricing,
    deleteProductPricing,
    updateProductBookingsTickets,
    updatePackageBookingsTickets,
    updatePackagePricing,
    deleteProductBookingAndTicket,
    getPackageById,
    deletePackagePricing,
    deletePackageBookingAndTickets
} from 'store/actions';
import { Pricing, BookingTickets } from 'components/molecules';
import { getValidPricingDays } from 'utils';

function BookingPricingForm({
    state,
    setState,
    formRef,
    previousSelectedSubCategory,
    updateProductPricing,
    updateProductBookingsTickets,
    calendar_data,
    deleteProductPricing,
    updatePackageBookingsTickets,
    package_detail,
    isAddDisabled,
    updatePackagePricing,
    toggleShowValidation,
    getPackageById,
    isForPackage,
    deleteProductBookingAndTicket,
    currentProduct,
    selected_existing_product,
    deletePackagePricing,
    deletePackageBookingAndTickets
}) {
    const params = useParams();

    const validDays = getValidPricingDays(currentProduct, null, { filterUnAvailableDays: true });

    const addPricingInformation = (value, showNotificaiton = true) => {
        formRef.current.setFieldsValue({
            pricing: value
        });
        if (package_detail && package_detail.id) {
            updatePackagePricing({
                newPricing: value,
                product: package_detail || {},
                packageId: package_detail.id,
                showNotificaiton: showNotificaiton
            }).then((res) => {
                if (res) setState({ pricing: value, isOpenPricing: false });
                getPackageById(package_detail.id, params.id);
            });
        } else {
            updateProductPricing({
                newPricing: value,
                product: currentProduct,
                showNotificaiton: showNotificaiton
            }).then((res) => {
                if (res?.payload) setState({ pricing: value, isOpenPricing: false });
            });
        }
    };

    const addBookingTickets = (value) => {
        let data = {
            booking_ticket_details: value
        };
        if (package_detail && package_detail.id) {
            updatePackageBookingsTickets({
                data,
                id: package_detail.id
            }).then((res) => {
                if (res) setState({ booking: value, isOpenBookingTickets: false });
                getPackageById(package_detail.id, params.id);
            });
        } else {
            updateProductBookingsTickets({
                data,
                id: currentProduct.id
            }).then((res) => {
                if (res?.payload) setState({ booking: value, isOpenBookingTickets: false });
            });
        }
    };

    function deleteBookingAndTickets(id) {
        deleteProductBookingAndTicket(id).then((res) => {
            if (res === true) setState({ booking: null });
        });
    }

    function deletePackageBAndT(id) {
        deletePackageBookingAndTickets(id).then((res) => {
            if (res === true) setState({ booking: null });
        });
    }

    function handleDeletePackagePricing(id, package_detail) {
        deletePackagePricing({
            id,
            product: package_detail
        }).then((res) => {
            if (res) getPackageById(package_detail.id, params.id);
        });
    }

    return (
        <>
            <Form.Item
                className="mb-1"
                name="productPricing"
                rules={[
                    {
                        required: isForPackage
                            ? state.pricing
                                ? false
                                : true
                            : validDays.length > 0,
                        message: isForPackage
                            ? 'Please fill pricing'
                            : `Please fill pricing information for ${validDays.map(
                                  (item) => `${item}`
                              )}`
                    }
                ]}>
                <AddPricingButton
                    product={isForPackage ? package_detail : currentProduct}
                    // days={currentProduct?.calendar_days?.days}
                    name="Pricing"
                    onClick={() => setState({ isOpenPricing: true })}
                    onEdit={(pricing) => setState({ pricing })}
                    onDelete={(id) => {
                        isForPackage
                            ? handleDeletePackagePricing(id, package_detail)
                            : deleteProductPricing({
                                  id,
                                  product: currentProduct
                              });
                    }}
                    isAddDisabled={isAddDisabled}
                    toggleShowValidation={toggleShowValidation}
                />
            </Form.Item>

            <Pricing
                isOpen={state.isOpenPricing}
                data={state.pricing}
                calendarData={calendar_data}
                onClose={() => setState({ isOpenPricing: false })}
                onEdit={state.pricingEdit}
                onSave={(value, showNotificaiton) => addPricingInformation(value, showNotificaiton)}
                submitButtonName="Save"
                hideImport
                title={previousSelectedSubCategory?.name ?? 'Product'}
                isForPackage={isForPackage}
                currentProduct={isForPackage ? package_detail : currentProduct}
                selected_existing_product={selected_existing_product}
            />

            <Form.Item
                className="mb-1"
                name="booking"
                rules={[
                    {
                        required: state.booking ? false : true,
                        message: 'Please fill booking & tickets information'
                    }
                ]}>
                <AddBookingButton
                    data={state.booking ? state.booking : null}
                    onDelete={() =>
                        isForPackage
                            ? deletePackageBAndT(package_detail?.id)
                            : deleteBookingAndTickets(currentProduct?.id)
                    }
                    name="Booking & Tickets"
                    onClick={() => setState({ isOpenBookingTickets: true })}
                    isAddDisabled={isAddDisabled}
                    toggleShowValidation={toggleShowValidation}
                />
            </Form.Item>

            <BookingTickets
                data={state.booking}
                isOpen={state.isOpenBookingTickets}
                onSave={(value) => addBookingTickets(value)}
                onClose={() => setState({ isOpenBookingTickets: false })}
                submitButtonName="Save"
                hideImport
                isAddOnProduct
                title={previousSelectedSubCategory?.name ?? 'Product'}
            />
        </>
    );
}

BookingPricingForm.propTypes = {
    state: Proptypes.object,
    setState: Proptypes.func,
    formRef: Proptypes.any,
    previousSelectedSubCategory: Proptypes.object,
    calendar_data: Proptypes.object,
    updateProductPricing: Proptypes.func,
    updateProductBookingsTickets: Proptypes.func,
    deleteProductPricing: Proptypes.func,
    updatePackageBookingsTickets: Proptypes.func,
    package_detail: Proptypes.object,
    isAddDisabled: Proptypes.bool,
    updatePackagePricing: Proptypes.func,
    toggleShowValidation: Proptypes.func,
    isForPackage: Proptypes.bool,
    getPackageById: Proptypes.func,
    deleteProductBookingAndTicket: Proptypes.func,
    currentProduct: Proptypes.object,
    selected_existing_product: Proptypes.bool,
    deletePackagePricing: Proptypes.func,
    deletePackageBookingAndTickets: Proptypes.func
};

const mapStateToProps = (state) => ({
    calendar_data: state.calendar.calendar_data,
    package_detail: state.product.package_detail,
    currentProduct: state.product.currentProduct,
    selected_existing_product: state.product.selected_existing_product
});

const mapDispatch = (dispatch) => ({
    updateProductPricing: (data) => dispatch(updateProductPricing(data)),
    deleteProductPricing: (data) => dispatch(deleteProductPricing(data)),
    updateProductBookingsTickets: (data) => dispatch(updateProductBookingsTickets(data)),
    updatePackageBookingsTickets: (data) => dispatch(updatePackageBookingsTickets(data)),
    updatePackagePricing: (data) => dispatch(updatePackagePricing(data)),
    getPackageById: (id, cId) => dispatch(getPackageById(id, cId)),
    deleteProductBookingAndTicket: (id) => dispatch(deleteProductBookingAndTicket(id)),
    deletePackagePricing: (data) => dispatch(deletePackagePricing(data)),
    deletePackageBookingAndTickets: (id) => dispatch(deletePackageBookingAndTickets(id))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(BookingPricingForm));

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import './index.scss';
import Proptypes from 'prop-types';

export default function Counter(props) {
    const { initNumber = 0, minNumber = 0, onChange } = props;
    const [number, setNumber] = useState(initNumber);

    const addNumber = () => {
        setNumber(number + 1);
        onChange(number + 1);
    };
    const subNumber = () => {
        if (number !== minNumber) {
            setNumber(number - 1);
            onChange(number - 1);
        }
    };

    useEffect(() => {
        if (initNumber !== number) {
            setNumber(number);
        }
    }, [initNumber, number]);

    return (
        <div className="d-flex counter unselectable">
            <div className="card cursor-pointer" onClick={subNumber}>
                <p className="card-details minus">-</p>
            </div>
            <div className="card-number">
                <div className="card-number-details">{number}</div>
            </div>
            <div className="card cursor-pointer" onClick={addNumber}>
                <p className="card-details">+</p>
            </div>
        </div>
    );
}

Counter.propTypes = {
    initNumber: Proptypes.number,
    minNumber: Proptypes.number,
    onChange: Proptypes.func
};

import React, { useEffect } from 'react';
import Bookings from '../bookings';
import CalendarComponent from '../../../molecules/calendar/Calendar';
import Back from '../../../../assets/images/back.svg';
import { Link } from 'react-router-dom';

export default function BookingCalendar() {
    //Function for scrolling up when the page loads
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Bookings>
            <div className="d-flex justify-content-end">
                <Link to="/bookings">
                    <p style={{ fontWeight: 900, paddingRight: 30 }}>
                        Back
                        <span style={{ paddingLeft: 7, fontWeight: 100 }}>
                            <img src={Back} alt="" />
                        </span>
                    </p>
                </Link>
            </div>
            <CalendarComponent />
        </Bookings>
    );
}

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Cancel from '../../../assets/images/cancelhelp.svg';
import './index.css';
import { Button, Drawer, Form, Input, Tooltip } from 'antd';
import { SendOutlined } from '@ant-design/icons';

const UserFeedback = () => {
    const { TextArea } = Input;
    const [modalIsOpen, setmodalIsOpen] = useState(false);

    const openModal = () => {
        setmodalIsOpen(true);
    };

    const closeModal = () => {
        setmodalIsOpen(false);
    };

    return (
        <>
            <Drawer
                placement="left"
                onClose={closeModal}
                visible={modalIsOpen}
                closable={false}
                key="left"
                width={285}
                zIndex={1051}
                className="help-center-drawer">
                <div className="bg-white" style={{ minHeight: '100vh' }}>
                    <div className="brown-modal d-flex justify-content-between">
                        <h5 className="m-0" style={{ textAlign: 'left', color: '#fff' }}>
                            Feedback
                        </h5>
                        <img
                            alt=""
                            onClick={closeModal}
                            className="cursor-pointer"
                            style={{ textAlign: 'right' }}
                            src={Cancel}
                        />
                    </div>

                    <div className="p-3">
                        <Form layout="vertical" className="form-label-fw-500">
                            <Form.Item
                                className="mb-2"
                                label="Please give us your feedback on this page">
                                <TextArea
                                    size="small"
                                    // value={value}
                                    // onChange={this.onChange}
                                    placeholder="Your feedback"
                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                />
                            </Form.Item>

                            <Button type="primary" className="fs-13 btn-px" htmlType="submit">
                                {'Enter'}
                            </Button>
                        </Form>

                        <div className="mt-4">
                            <div className="fs-16 fw-600 text-primary mb-2">Your Feedback</div>
                            <ul className="default-list">
                                <li>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting
                                    industry. Lorem Ipsum has been the industry's standard dummy
                                    text ever since the 1500s, when an unknown printer took a galley
                                    of type and scrambled it to make a type specimen book.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Drawer>

            {!modalIsOpen ? (
                <div className="fixed-action-bar" hidden>
                    <Tooltip title="Send Feedback" placement="left">
                        <Button
                            onClick={openModal}
                            type="dark"
                            size="large"
                            shape="circle"
                            icon={<SendOutlined />}></Button>
                    </Tooltip>
                </div>
            ) : null}
        </>
    );
};
export default UserFeedback;

import React from 'react';
import PropTypes from 'prop-types';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { LanguageOfferedIcon, EventDetailRoomItem } from 'components/atoms';

const EventRooms = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<LanguageOfferedIcon />}
            panelTitle="Rooms"
            isEditable={true}
            onEdit={onEdit}>
            {!product?.rooms?.pp_sharing ? (
                <></>
            ) : (
                <>
                    <p className="fs-18 fw-500 text-uppercase mb-1 p-0">
                        Per Person Sharing ( Double )
                    </p>

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.double?.double_bed}
                        name="Double Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.double?.large_bed}
                        name="Large Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.double?.xl_double_bed}
                        name="XL Double Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.double?.sofa_bed}
                        name="Sofa Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.double?.futon_mat}
                        name="Futon Mat"
                    />

                    <p className="fs-18 fw-500 text-uppercase mt-3 mb-1 p-0 ">
                        Per Person Sharing ( Twin )
                    </p>

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.twin?.single_bed}
                        name="Single Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_sharing?.twin?.bunk_bed}
                        name="Bunk Bed"
                    />
                </>
            )}

            {!product?.rooms?.pp_single ? (
                <></>
            ) : (
                <>
                    <p className="fs-18 fw-500 text-uppercase  mt-3 mb-1 p-0 ">
                        Per Person Single ( Double )
                    </p>

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_single?.double?.double_bed}
                        name="Double Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_single?.double?.large_bed}
                        name="Large Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_single?.double?.xl_double_bed}
                        name="XL Double Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_single?.double?.sofa_bed}
                        name="Sofa Bed"
                    />

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_single?.double?.futon_mat}
                        name="Futon Mat"
                    />

                    <p className="fs-18 fw-500 text-uppercase mt-3 mb-1 p-0 ">
                        Per Person Single ( Single )
                    </p>

                    <EventDetailRoomItem
                        value={product?.rooms?.pp_single?.single?.bed}
                        name="Single Bed"
                    />
                </>
            )}
        </DetailedPanel>
    );
};

EventRooms.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventRooms;

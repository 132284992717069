import React, { useEffect, useState } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select, Radio } from 'antd';
import { Button, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { Feedback } from 'components/molecules';
import {
    getAllDayProductCategories,
    getWithInDayProductCategories,
    createMainProduct,
    // updatePackageProduct,
    unlinkProductOrPackage,
    getCalendarsById,
    packageDetail_
} from 'store/actions';

function AddNewMainProductModal(props) {
    const {
        createMainProductPackageModal,
        setCreateMainProductPackageModal,
        //setCreateMainProductModal,
        setCreateYourPackageDrawer,
        product_categories,
        product_in_day_categories,
        getAllDayProductCategories,
        getWithInDayProductCategories,
        createMainProduct,
        setAddOnProductItineraryTimeModal,
        setSelectCalendarDaysBookingModal,
        // updatePackageProduct,
        setCreateMainProductInitModal,
        unlinkProductOrPackage,
        changeWithNewProduct,
        get_calendar_data,
        getCalendarsById,
        packageDetail_
    } = props;

    const params = useParams();
    const [form] = Form.useForm();

    const [productPackageValue, setProductPackageValue] = useState();
    const [selectCategoryForLeadingProduct, setSelectCategoryForLeadingProduct] = useState();
    const [selectSubCategoryForLeadingProduct, setSelectSubCategoryForLeadingProduct] = useState();

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getAllDayProductCategories();
        getWithInDayProductCategories('INDAYCAT');
    }, []);

    useEffect(() => {
        if (selectCategoryForLeadingProduct === 1)
            if (Array.isArray(product_categories)) {
                let index = product_categories.findIndex((value) => value.id === 1);
                if (index > -1 && Array.isArray(product_categories[index]?.productSubCategories))
                    setCategories(product_categories[index]?.productSubCategories);
            }

        if (selectCategoryForLeadingProduct === 5)
            if (Array.isArray(product_in_day_categories)) {
                let index = product_in_day_categories.findIndex((value) => value.id === 5);
                if (
                    index > -1 &&
                    Array.isArray(product_in_day_categories[index]?.productSubCategories)
                )
                    setCategories(product_in_day_categories[index]?.productSubCategories);
            }
    }, [selectCategoryForLeadingProduct]);

    function handleOnProductPackageChange(e) {
        setProductPackageValue(e.target.value);
    }

    function onCloseModal() {
        setProductPackageValue();
        setSelectCategoryForLeadingProduct();
        setSelectSubCategoryForLeadingProduct();

        setCreateMainProductPackageModal(false);
        props.setChangeWithNewProduct(false);
        form.resetFields();
    }

    function callCreateMainProduct(product_category_id, product_sub_category_id) {
        let data = {
            calendar_id: Number(params.id),
            day_type: selectCategoryForLeadingProduct === 1 ? 'all_day' : 'within_day',
            product_type: 'main_product',
            product_category_id,
            product_sub_category_id
        };

        createMainProduct(data).then((res) => {
            if (res?.payload) {
                onCloseModal();
                getCalendarsById(params.id);
                if (selectCategoryForLeadingProduct === 1) {
                    setSelectCalendarDaysBookingModal(true);
                } else {
                    setAddOnProductItineraryTimeModal(true);
                }
            }
        });
    }

    function onFinish() {
        let product_category_id;
        let product_sub_category_id = selectSubCategoryForLeadingProduct;
        if (selectCategoryForLeadingProduct === 1) {
            let index = product_categories.findIndex((value) => value.id === 1);
            if (index > -1) {
                product_category_id = product_categories[index]?.id;

                if (typeof selectSubCategoryForLeadingProduct === 'string') {
                    let subIndex = product_categories[index]?.productSubCategories.findIndex(
                        (value) => value.name === selectSubCategoryForLeadingProduct
                    );
                    if (subIndex > -1) {
                        product_sub_category_id =
                            product_categories[index]?.productSubCategories[subIndex].id;
                    }
                }
            }
        } else if (selectCategoryForLeadingProduct === 5) {
            let index = product_in_day_categories.findIndex((value) => value.id === 5);
            if (index > -1) {
                product_category_id = product_in_day_categories[index]?.id;

                if (typeof selectSubCategoryForLeadingProduct === 'string') {
                    let subIndex = product_in_day_categories[index]?.productSubCategories.findIndex(
                        (value) => value.name === selectSubCategoryForLeadingProduct
                    );
                    if (subIndex > -1) {
                        product_sub_category_id =
                            product_in_day_categories[index]?.productSubCategories[subIndex].id;
                    }
                }
            }
        }

        let payload = { calendar_id: params.id };
        if (mainPackage) {
            payload.package_id = mainPackage?.id;
            payload.url = '/unlink-package';
        } else if (mainProduct) payload.product_id = mainProduct?.id;

        if (productPackageValue === false) {
            if (mainPackage || mainProduct) {
                unlinkProductOrPackage(payload).then((res) => {
                    if (res) {
                        callCreateMainProduct(product_category_id, product_sub_category_id);
                    }
                });
            } else callCreateMainProduct(product_category_id, product_sub_category_id);
        } else {
            if (mainPackage || mainProduct) {
                unlinkProductOrPackage(payload).then((res) => {
                    if (res) {
                        packageDetail_({});
                        getCalendarsById(params.id);
                        onCloseModal();
                        setCreateYourPackageDrawer(true);
                    }
                });
            } else {
                onCloseModal();
                setCreateYourPackageDrawer(true);
            }
        }
    }

    const { Option } = Select;

    const mainProduct =
        get_calendar_data?.data?.mainProduct &&
        Array.isArray(get_calendar_data?.data?.mainProduct) &&
        get_calendar_data?.data?.mainProduct.length > 0
            ? get_calendar_data?.data?.mainProduct[0]
            : null;
    const mainPackage =
        get_calendar_data?.data?.mainPackage &&
        Array.isArray(get_calendar_data?.data?.mainPackage) &&
        get_calendar_data?.data?.mainPackage.length > 0
            ? get_calendar_data?.data?.mainPackage[0]
            : null;

    return (
        <Modal
            size="lg"
            centered
            scrollable
            isOpen={createMainProductPackageModal}
            toggle={() => setCreateMainProductPackageModal(!createMainProductPackageModal)}>
            <Form className="d-flex flex-column h-100" onFinish={onFinish} form={form}>
                <div className="modal-header flex-wrap flex-lg-nowrap">
                    <h5 className="modal-title order-2 order-lg-1">Create Main Product</h5>
                    <div className="flex-shrink-0 order-1 order-lg-2 ml-lg-2">
                        <Button
                            color="link"
                            className="px-0 text-decoration-underline"
                            type="button"
                            onClick={() => {
                                onCloseModal();
                                //setCreateMainProductModal(true);
                                if (changeWithNewProduct) {
                                    props.setChangeWithNewProduct(false);
                                    props.setEditMainProductInitModal(true);
                                } else {
                                    setCreateMainProductInitModal(true);
                                }
                            }}>
                            Go Back
                        </Button>
                    </div>
                </div>
                <ModalBody className="d-flex flex-column">
                    <div style={{ minHeight: 210 }}>
                        <legend className="text-body border-bottom-0 fs-16 fw-500 mb-2">
                            Is this a Package?
                        </legend>
                        <Form.Item
                            className="mb-12px"
                            name="if_product_is_package"
                            rules={[{ required: true, message: 'Please select one' }]}>
                            <Radio.Group
                                onChange={handleOnProductPackageChange}
                                value={productPackageValue}>
                                <Radio value={true} style={{ marginRight: 50 }}>
                                    Yes
                                </Radio>
                                <Radio value={false} style={{ marginRight: 50 }}>
                                    No
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {productPackageValue === true && (
                            <div className="text-gray fs-14 mb-12px">
                                You will be able to add more than one product to this pakage
                            </div>
                        )}
                        {productPackageValue === false && (
                            <>
                                <Row>
                                    <Col md={6} xl={5}>
                                        <Form.Item
                                            value={selectCategoryForLeadingProduct}
                                            name="select_category_for_leading_product"
                                            rules={[
                                                { required: true, message: 'Please select one' }
                                            ]}>
                                            <Select
                                                className="d-block"
                                                placeholder={`Select Category ${
                                                    productPackageValue ? 'for Leading Product' : ''
                                                }`}
                                                size="small"
                                                dropdownClassName="select-dropdown select-dropdown-sm"
                                                onChange={(val) => {
                                                    setSelectCategoryForLeadingProduct(val);
                                                    form.setFields([
                                                        {
                                                            name: 'select_sub_category_for_leading_product',
                                                            value: undefined
                                                        }
                                                    ]);
                                                }}
                                                value={selectCategoryForLeadingProduct}>
                                                <Option value={1}>Accommodation</Option>
                                                <Option value={5}>Activity</Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                {selectCategoryForLeadingProduct && (
                                    <Row>
                                        <Col md={6} xl={5}>
                                            <Form.Item
                                                value={selectSubCategoryForLeadingProduct}
                                                name="select_sub_category_for_leading_product"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Please select one'
                                                    }
                                                ]}>
                                                <Select
                                                    className="d-block"
                                                    placeholder={`Select type of ${
                                                        selectCategoryForLeadingProduct == 1
                                                            ? 'Accomodation'
                                                            : 'Activity'
                                                    }`}
                                                    size="small"
                                                    dropdownClassName="select-dropdown select-dropdown-sm"
                                                    onChange={(val) =>
                                                        setSelectSubCategoryForLeadingProduct(val)
                                                    }
                                                    value={selectSubCategoryForLeadingProduct}>
                                                    {categories.map((value, ind) => (
                                                        <Option key={ind} value={value.id}>
                                                            {value.name}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </div>
                </ModalBody>
                <ModalFooter className="justify-content-start">
                    <Button
                        color="primary"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        type="submit">
                        {productPackageValue ? 'Next' : 'Create'}
                    </Button>
                    <Button
                        color="dark"
                        className="btn-mw flex-grow-1 flex-lg-grow-0"
                        onClick={onCloseModal}>
                        Cancel
                    </Button>
                    <Feedback buttonClassName="ml-lg-auto btn-block btn-lg-inline-block" />
                </ModalFooter>
            </Form>
        </Modal>
    );
}

AddNewMainProductModal.propTypes = {
    loading: PropTypes.bool,
    createMainProductPackageModal: PropTypes.bool,
    setCreateMainProductPackageModal: PropTypes.func,
    setCreateMainProductModal: PropTypes.func,
    setCreateYourPackageDrawer: PropTypes.func,
    getAllDayProductCategories: PropTypes.func,
    getWithInDayProductCategories: PropTypes.func,
    product_categories: PropTypes.array,
    product_in_day_categories: PropTypes.array,
    createMainProduct: PropTypes.func,
    setAddOnProductItineraryTimeModal: PropTypes.func,
    setSelectCalendarDaysBookingModal: PropTypes.func,
    // updatePackageProduct: PropTypes.func,
    setCreateMainProductInitModal: PropTypes.func,
    setChangeWithNewProduct: PropTypes.func,
    changeWithNewProduct: PropTypes.bool,
    setEditMainProductInitModal: PropTypes.any,
    unlinkProductOrPackage: PropTypes.func,
    get_calendar_data: PropTypes.object,
    getCalendarsById: PropTypes.func,
    packageDetail_: PropTypes.func
};

const mapStateToProps = (state) => ({
    loading: state.calendar.loading,
    product_categories: state.calendar.product_categories,
    product_in_day_categories: state.calendar.product_in_day_categories,
    get_calendar_data: state.calendar.get_calendar_data
});

const mapDispatch = (dispatch) => ({
    getAllDayProductCategories: () => dispatch(getAllDayProductCategories()),
    getWithInDayProductCategories: (type) => dispatch(getWithInDayProductCategories(type)),
    createMainProduct: (data) => dispatch(createMainProduct(data)),
    unlinkProductOrPackage: (data) => dispatch(unlinkProductOrPackage(data)),
    getCalendarsById: (id) => dispatch(getCalendarsById(id)),
    packageDetail_: (data) => dispatch(packageDetail_(data))
    // updatePackageProduct: (data) => dispatch(updatePackageProduct(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatch)(AddNewMainProductModal));

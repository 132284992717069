import {
    GET_ALL_AVAILABILITY_DATA,
    GET_ALL_PRODUCT_CALENDARS_DATA,
    UPDATE_PRODUCT_AVAILABILITY,
    UPDATE_CALENDAR_ACCESS,
    UPDATE_AVAILABILITY_STATE
} from 'store/types';
const initialState = {
    availability_data: null,
    product_calendars_data: null,
    update_product_availability: null,
    update_calendar_access: null
};

const availabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_AVAILABILITY_DATA:
            return { ...state, availability_data: action.payload.data };
        case GET_ALL_PRODUCT_CALENDARS_DATA:
            return { ...state, product_calendars_data: action.payload };
        case UPDATE_PRODUCT_AVAILABILITY:
            return { ...state, update_product_availability: action.payload };
        case UPDATE_CALENDAR_ACCESS:
            return { ...state, update_calendar_access: action.payload };
        case UPDATE_AVAILABILITY_STATE:
            return { ...state, update_availability_state: action.payload };
        default:
            return state;
    }
};
export default availabilityReducer;

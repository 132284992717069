import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { Drawer } from 'antd';
import Feedback from '../../user-feedback';
import DownloadIcon from '../../../atoms/icons/download-icon';
import { EyeFilled } from '@ant-design/icons';
import ProductSummaryDrawer from '../product-details';
import GalleryMediaModal from '../gallery';
import ProductSummaryChild from './product-summary-child';
import TravellerDetails from './traveller-details';

const CalendarSummaryDrawer = (props) => {
    const { visible, onClose, viewSummaryData, other_booked_products, setRedirectToCalendarView } =
        props;
    const [visibleProductSummaryDrawer, setVisibleProductSummaryDrawer] = useState(false);
    const [viewGalleryItemModal, setViewGalleryItemModal] = useState(false);

    const history = useHistory();

    const getProductType = (type) => {
        let newType = type;
        if (type == 'main_product') {
            newType = 'Main Product';
        } else if (type == 'addon_product') {
            newType = 'Add-On Product';
        } else if (type == 'package') {
            newType = 'Package';
        }
        return newType;
    };
    const getAgeGroupString = () => {
        let params = [];
        let newAgeGroup = viewSummaryData?.age_group.map((el) => {
            if (el.count) {
                if (el.type == 'adult') {
                    params.push(`${el.count} Adult${el.count == 1 ? '' : 's'}`);
                } else if (el.type == 'children') {
                    params.push(`${el.count} ${el.count == 1 ? 'Child' : 'Children'}`);
                } else if (el.type == 'senior') {
                    params.push(`${el.count} Senior${el.count == 1 ? '' : 's'}`);
                }
            }
            return el;
        });
        return params.length > 0 && newAgeGroup ? params.join(', ') : 0;
    };

    return (
        <Drawer
            width={609}
            placement="right"
            className="basic-drawer"
            closeIcon={
                <span role="img" aria-label="close" className="anticon anticon-close">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14.1988 11.9999L23.5439 2.65453C24.1521 2.04665 24.1521 1.06379 23.5439 0.45591C22.936 -0.15197 21.9532 -0.15197 21.3453 0.45591L11.9999 9.80132L2.65474 0.45591C2.04658 -0.15197 1.064 -0.15197 0.456124 0.45591C-0.152041 1.06379 -0.152041 2.04665 0.456124 2.65453L9.80125 11.9999L0.456124 21.3453C-0.152041 21.9532 -0.152041 22.9361 0.456124 23.544C0.759068 23.8472 1.15739 23.9995 1.55543 23.9995C1.95347 23.9995 2.35152 23.8472 2.65474 23.544L11.9999 14.1986L21.3453 23.544C21.6485 23.8472 22.0466 23.9995 22.4446 23.9995C22.8426 23.9995 23.2407 23.8472 23.5439 23.544C24.1521 22.9361 24.1521 21.9532 23.5439 21.3453L14.1988 11.9999Z"
                            fill="black"
                        />
                    </svg>
                </span>
            }
            onClose={onClose}
            visible={visible}>
            <div className="d-flex flex-column h-100">
                <div>
                    <h4 className="mb-3">Summary</h4>
                </div>

                <div className="mb-3">
                    <div className="fs-16 bg-secondary-light rounded px-3 py-2">
                        <div className="d-flex flex-wrap my-2">
                            <span className="mr-2 fw-600">
                                {viewSummaryData?.calendar_weeks} Week
                                {viewSummaryData?.calendar_weeks > 1 ? 's' : ''}{' '}
                                {viewSummaryData?.calendar_days
                                    ? `${viewSummaryData?.calendar_days} Day${
                                          viewSummaryData?.calendar_days > 1 ? 's ' : ''
                                      }`
                                    : ''}
                                Calendar:
                            </span>
                            <span>
                                {`${
                                    viewSummaryData?.calendar_title
                                        ? viewSummaryData?.calendar_title
                                        : ''
                                }`}{' '}
                                {viewSummaryData?.calendar_ref_id
                                    ? `(${viewSummaryData?.calendar_ref_id})`
                                    : ''}
                            </span>
                        </div>
                    </div>
                </div>

                <div className="flex-grow-1 overflow-y-auto overflow-x-hidden scrollbar-fixed pr-2">
                    <div className="d-flex align-items-start flex-wrap flex-md-nowrap">
                        <h5 className="fs-18 fw-400 mr-2 col-12 p-0 col-md order-last order-md-first">
                            No. of Travellers: {getAgeGroupString()}
                        </h5>
                        <div className="order-first order-md-last col-12 p-0 col-md-auto">
                            <span
                                className="d-inline-block text-nowrap text-primary fs-14 fw-400"
                                role="button"
                                tabIndex="0">
                                <span className="mr-2">
                                    <DownloadIcon />
                                </span>
                                <span>Download Details</span>
                            </span>
                        </div>
                    </div>
                    {/* Traveller's Details Accordion */}
                    <div>
                        <TravellerDetails viewSummaryData={viewSummaryData} />
                        <hr className="mt-2 mb-3" />
                        <div className="d-flex align-items-start flex-wrap flex-md-nowrap">
                            <h5 className="fs-18 fw-400 mr-2 col-12 p-0 col-md order-last order-md-first">
                                No. of Products/Activities Booked: {other_booked_products?.length}
                            </h5>
                            <div className="order-first order-md-last col-12 p-0 col-md-auto">
                                <span
                                    className="d-inline-block text-nowrap text-primary fs-14 fw-400"
                                    role="button"
                                    tabIndex="0">
                                    <span className="mr-2">
                                        <EyeFilled
                                            style={{ fontSize: 16, verticalAlign: 'middle' }}
                                        />
                                    </span>
                                    <span
                                        tabIndex="0"
                                        role="button"
                                        onKeyDown={() => history.push('/calendar/424')}
                                        onClick={() => {
                                            onClose();
                                            setRedirectToCalendarView(true);
                                        }}>
                                        View Calendar
                                    </span>
                                </span>
                            </div>
                        </div>
                        {/* Show As Accordion */}
                        <div className="ant-collapse ant-collapse-borderless primary-collapse-accordion">
                            {/* Repeat this */}
                            {other_booked_products.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="ant-collapse-item ant-collapse-no-arrow primary-collapse-panel">
                                        <div
                                            className="ant-collapse-header d-flex flex-nowrap align-items-start"
                                            role="button"
                                            tabIndex="0"
                                            onKeyUp={() => setVisibleProductSummaryDrawer(true)}
                                            onClick={() => setVisibleProductSummaryDrawer(true)}>
                                            <div className="fs-15 flex-grow-1 mr-4">
                                                <span className="fw-500 mr-2">
                                                    {getProductType(item.product_type)}:
                                                </span>
                                                <span>{item.title}</span>
                                            </div>
                                            <span
                                                className="d-inline-flex align-items-center text-primary text-nowrap"
                                                style={{ paddingTop: 8 }}>
                                                <span className="fs-14 fw-500 lh-1-5 mr-2">
                                                    View details
                                                </span>
                                                <span>
                                                    <svg
                                                        width="8"
                                                        height="12"
                                                        viewBox="0 0 8 12"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0L0 1.41Z"
                                                            fill="currentColor"
                                                        />
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>

                <div className="pt-3">
                    <div className="d-flex justify-content-between">
                        <Button color="primary" onClick={onClose}>
                            Go Back
                        </Button>
                        <Feedback drawerPlacement="right" />
                    </div>
                </div>
            </div>

            {/* Product Summary Drawer */}
            <ProductSummaryDrawer
                forAvailability={false}
                productTitle={`Burj Khalifa`}
                productDesc={
                    <>
                        <p>
                            {`Burj Khalifa is the tallest tower in the world and it's one of the top
                            attractions to visit in Dubai. Visit our website and book your Burj
                            Khalifa ticket.`}
                        </p>
                        <p>
                            {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc non eros
                            ac sapien suscipit sollicitudin a finibus mauris. Donec nulla ligula,
                            auctor a varius id, viverra sed ante. Donec sodales cursus sem, in
                            vulputate dolor dictum ac. Fusceunt eros quis cursus commodo. Nullam
                            ligula tortor, consectetur sit amet gravida quis, hendrerit sit amet
                            libero. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                            imperdiet nisl quis rutrum malesuada. Ut posuere tempus ante, quis
                            ultricies nisl posuere at Lorem ipsum dolor, Nam ab aliquam esse. Quaerat cum consequuntur, debitis
                            sed animi nobis recusandae dolor officia, eligendi at asperiores
                            laboriosam! Facilis omnis fuga quisquam?`}

                            {/* Read more if applicable */}
                            <Button
                                color="link"
                                className="ml-2 d-inline text-nowrap p-0 ff-special text-primary"
                                size="small">
                                <i>Read More</i>
                            </Button>
                        </p>
                    </>
                }
                visible={visibleProductSummaryDrawer}
                onClose={() => setVisibleProductSummaryDrawer(false)}>
                <ProductSummaryChild setViewGalleryItemModal={setViewGalleryItemModal} />
            </ProductSummaryDrawer>

            <GalleryMediaModal
                isModalOpen={viewGalleryItemModal}
                setModalOpenState={() => setViewGalleryItemModal(!viewGalleryItemModal)}>
                <div className="gallery-carousel-item">
                    <img src={require('../../../../assets/images/gallery-image-01.png')} alt="" />
                </div>
                <div className="gallery-carousel-item">
                    <img src={require('../../../../assets/images/gallery-image-02.png')} alt="" />
                </div>
                <div className="gallery-carousel-item">
                    <img src={require('../../../../assets/images/gallery-image-03.png')} alt="" />
                </div>
            </GalleryMediaModal>
        </Drawer>
    );
};

CalendarSummaryDrawer.propTypes = {
    visible: PropTypes.bool,
    onClose: PropTypes.func,
    viewSummaryData: PropTypes.any,
    other_booked_products: PropTypes.array,
    setRedirectToCalendarView: PropTypes.func
};

const mapStateToProps = (state) => ({
    other_booked_products: state.booking.other_booked_products
});

export default withRouter(connect(mapStateToProps, null)(CalendarSummaryDrawer));

import React from 'react';
import { Col, Row } from 'reactstrap';
import PropTypes from 'prop-types';

const MasterEventHeader = (props) => {
    const {
        calendarMenuVisibility,
        currentWindowWidth,
        calendarTitleAndLengthWrap,
        onClickCalendarMenu,
        calendarLogoWrap,
        calendarNameWrap,
        calendarStatusWrap,
        customerStatusWrap,
        customerCalendarWrapMulti,
        calendarBookingsData
    } = props;
    const getAgeGroupString = () => {
        let params = [];
        let ageGroup = calendarBookingsData?.ageGroups;
        if (ageGroup) {
            if (ageGroup?.adult) {
                params.push(`${ageGroup?.adult} Adult${ageGroup?.adult == 1 ? '' : 's'}`);
            } else if (ageGroup?.child) {
                params.push(`${ageGroup?.child} ${ageGroup?.child == 1 ? 'Child' : 'Children'}`);
            }
        }
        return params.length > 0 ? params.join(', ') : 0;
    };
    return (
        <div className="master-event-header">
            <Row className="align-items-center flex-md-nowrap">
                <Col xs={12} className="col-md overflow-md-hidden">
                    <div className="d-flex align-items-center flex-nowrap flex-md-wrap flex-lg-nowrap mx-n2">
                        <div
                            className="px-2 overflow-hidden mr-3 mr-xl-5 flex-grow-1"
                            style={{ minHeight: 50 }}>
                            {(!calendarMenuVisibility || currentWindowWidth >= 768) &&
                                calendarTitleAndLengthWrap}
                        </div>
                        {currentWindowWidth < 768 ? (
                            <>
                                <div className="px-2 order-first">
                                    <button
                                        aria-label="Toggle Calendar Menu"
                                        className="common-toggler"
                                        type="button"
                                        onClick={onClickCalendarMenu}>
                                        <span className="common-toggler-icon"></span>
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="px-2 order-first">
                                <div
                                    className="d-flex align-items-center flex-nowrap mx-n2"
                                    style={{ minHeight: 65 }}>
                                    <div className="px-2">{calendarLogoWrap}</div>
                                    <div className="px-2">{calendarNameWrap}</div>
                                </div>
                            </div>
                        )}
                    </div>
                    {currentWindowWidth >= 992 && currentWindowWidth < 1200 ? (
                        <div className="d-flex flex-nowrap align-items-center mx-n2 mt-2">
                            <div className="px-2">{calendarStatusWrap}</div>
                            <div className="px-2">{customerStatusWrap}</div>
                        </div>
                    ) : null}
                </Col>
                {currentWindowWidth >= 768 ? (
                    <Col className="col-lg-auto">
                        <Row className="align-items-center justify-content-end flex-xl-nowrap">
                            <Col xs="auto" className="order-first order-xl-last">
                                <div
                                    className="d-flex align-items-center"
                                    style={{ minHeight: 60 }}>
                                    {/* If customers are one or more */}
                                    <>
                                        {/* When more than 1 customer */}
                                        {customerCalendarWrapMulti}
                                    </>
                                </div>
                            </Col>
                            <Col
                                xs={12}
                                xl="auto"
                                className="order-last order-xl-first mt-2 mt-xl-0">
                                <div className="d-flex justify-content-end align-items-center flex-nowrap mx-n2">
                                    {currentWindowWidth >= 992 &&
                                    currentWindowWidth < 1200 ? null : (
                                        <div className="px-2 align-self-stretch">
                                            <div className="d-flex flex-nowrap align-items-center h-100 mx-n2">
                                                <div className="px-2">{calendarStatusWrap}</div>
                                                <div className="px-2">{customerStatusWrap}</div>
                                                <div className="px-2 align-self-stretch">
                                                    <div className="border-left-separator h-100"></div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="px-2">
                                        <div className="d-flex mx-n2">
                                            <div className="px-2">
                                                <div className="text-right text-nowrap">
                                                    <div className="fs-14">
                                                        <span className="fw-600">
                                                            No. of Products Booked:{' '}
                                                        </span>
                                                        {
                                                            calendarBookingsData?.calendar
                                                                ?.totalProductsBooked
                                                        }
                                                    </div>
                                                    <div className="fs-14">
                                                        <span className="fw-600">
                                                            No. of Travellers:{' '}
                                                        </span>
                                                        {getAgeGroupString()}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-2 align-self-stretch d-none d-xl-block">
                                                <div className="border-left-separator h-100">
                                                    &nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                ) : null}
            </Row>
        </div>
    );
};
MasterEventHeader.propTypes = {
    calendarMenuVisibility: PropTypes.bool,
    currentWindowWidth: PropTypes.number,
    calendarTitleAndLengthWrap: PropTypes.node,
    onClickCalendarMenu: PropTypes.func,
    calendarLogoWrap: PropTypes.node,
    calendarNameWrap: PropTypes.node,
    calendarStatusWrap: PropTypes.node,
    customerStatusWrap: PropTypes.node,
    customerCalendarWrapMulti: PropTypes.node,
    calendarBookingsData: PropTypes.object
};
export default MasterEventHeader;

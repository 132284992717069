/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component } from 'react';
import { Form, Drawer, Pagination, Modal, Progress } from 'antd';
import { Button } from 'reactstrap';

import './index.scss';
import {
    clearUploadedFile,
    fileUpload,
    getFiles,
    getFilesType,
    onDeleteMedia
} from '../../../store/actions/contentActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import HelpIcon from '../../../assets/images/helpIcon.svg';
import SelectIcon from '../../../assets/images/select.svg';
import DeleteIcon from '../../../assets/images/delete_block.svg';
import MediaField from '../media-library/add-media';
import WarningIcon from '../../../assets/images/warning-icon.svg';
import Proptypes from 'prop-types';
import { Loader } from 'components/atoms';
const { confirm } = Modal;

class Gallery extends Component {
    constructor(props) {
        super(props);

        this.state = {
            requiredMark: 'optional',
            form: {},
            category: '',
            media: [],
            selectMedia: [],
            height: window.innerHeight,
            totalPages: 1
        };
    }

    componentDidMount() {
        const { media } = this.props;
        if (media && media.length > 0) this.setState({ media: media });
        else {
            this.getMedia();
        }
    }

    componentDidUpdate(prevProps) {
        const { files, uploaded_file, title, delete_file, selectMediaList } = this.props;

        if (files !== prevProps.files)
            this.setState({ media: [...files.data], totalPages: files.meta.total });

        if (prevProps.uploaded_file !== uploaded_file && uploaded_file) {
            if (this.state.media.length < 10) {
                this.setState({ media: [uploaded_file, ...this.state.media] });
            } else this.getMedia();
        }

        if ((prevProps.title !== title && title) || prevProps.delete_file !== delete_file)
            this.getMedia();

        if (prevProps.selectMediaList !== selectMediaList && selectMediaList)
            this.setState({ selectMedia: selectMediaList });
    }
    getMedia = () => {
        const { getFilesType, title } = this.props;
        if (title === 'My Videos') {
            getFilesType('video');
        } else {
            getFilesType('image');
        }
    };
    changePage = (page) => {
        this.props.getFilesType('image', page);
    };

    addToMedia = (item) => {
        const { selectMedia } = this.state;
        if (this.isSelect(item.id)) {
            let tmp = selectMedia;
            tmp.splice(
                selectMedia.findIndex((res) => res === item),
                1
            );
            this.setState({ selectMedia: [...tmp] });
        } else {
            this.setState({ selectMedia: [...selectMedia, item] });
        }
    };

    addSingleToMedia = (item) => {
        this.setState({ selectMedia: [item] });
    };

    onChange = (e) => {
        let form_data = new FormData();
        form_data.append('media', e);
        this.props.fileUpload(form_data);
    };

    showPropsConfirm = (id) => {
        confirm({
            title: 'Are you sure?',
            icon: <img src={WarningIcon} alt="" />,
            content: 'Are you sure you want to remove it?',
            okText: 'YES',
            okType: 'primary',
            cancelText: 'NO',
            maskStyle: { display: 'none' },
            centered: true,
            width: '910px',
            onOk: () => {
                this.onDeleteMedia(id);
                return new Promise((resolve) => {
                    resolve(true);
                }).catch();
            },
            onCancel() {
                return false;
            }
        });
    };

    onDeleteMedia = async (id) => {
        const { selectMedia } = this.state;
        if (this.isSelect(id)) {
            let tmp = selectMedia;
            tmp.splice(
                selectMedia.findIndex((res) => res.id === id),
                1
            );
            this.setState({ selectMedia: [...tmp] });
        }
        this.props.onDeleteMedia(id);
    };
    isSelect = (res) => this.state.selectMedia.findIndex((item) => item.id === res) !== -1;
    onClose = () => this.props.onClose();
    onSave = () => this.props.onSaveMedia(this.state.selectMedia);

    render() {
        const { requiredMark, media, totalPages } = this.state;
        const {
            title = 'My Images/Videos',
            subTitle = '',
            showModal,
            classNameTitle = 'header-title',
            selectMultiple = true,
            file_upload_progress
        } = this.props;

        return (
            <div className="itenary-form">
                <Drawer
                    className="application gallery"
                    onClose={() => {
                        this.onClose();
                    }}
                    visible={showModal}
                    zIndex={1052}
                    width={610}>
                    <Form
                        layout="vertical"
                        initialValues={{
                            requiredMark
                        }}
                        onValuesChange={this.onRequiredTypeChange}
                        requiredMark={requiredMark}
                        className="flex-grow-1 d-flex flex-column overflow-y-auto">
                        <div className={classNameTitle}>{title}</div>

                        {subTitle ? <div className="subTitle">{subTitle}</div> : null}
                        <div style={{ marginBottom: '23px' }}>
                            <MediaField
                                className="media-field btn-secondary cursor-pointer"
                                height={42}
                                width={185}
                                onSelectMedia={(file) => this.onChange(file)}
                                type={
                                    title === 'My Images'
                                        ? 'picture'
                                        : title === 'My Videos'
                                        ? 'video'
                                        : 'picture'
                                }
                            />
                        </div>

                        {this.props.loading && <Loader typeContainer={true} />}
                        {file_upload_progress && Object.keys(file_upload_progress).length > 0 && (
                            <div
                                className="text-center p-4"
                                style={{
                                    position: 'absolute',
                                    zIndex: 1100,
                                    bottom: 0,
                                    left: 0,
                                    width: '100%',
                                    backgroundColor: 'rgba(255, 255, 255,0.95)'
                                }}>
                                <div className="d-flex align-items-center flex-nowrap gap-3 justify-content-between mb-3">
                                    <div className="fs-18 flex-fill text-left">Uploading ...</div>
                                    <div
                                        style={{
                                            flex: '0 0 200px',
                                            maxWidth: 200,
                                            overflow: 'hidden'
                                        }}>
                                        <small className="text-truncate d-block mr-3">
                                            {file_upload_progress.name}
                                        </small>
                                        <Progress
                                            percent={
                                                file_upload_progress?.total &&
                                                file_upload_progress.loaded
                                                    ? Math.floor(
                                                          (file_upload_progress.loaded /
                                                              file_upload_progress.total) *
                                                              100
                                                      )
                                                    : 0
                                            }
                                        />
                                    </div>
                                </div>
                                <Button onClick={this.onClose} type="button" color="link">
                                    Click to close and upload in background.
                                </Button>
                            </div>
                        )}
                        <div className="flex-grow-1 overflow-y-auto scrollbar-fixed">
                            <div className="media-block">
                                {media && media.length > 0
                                    ? media.map((res) => (
                                          <div
                                              key={res.id}
                                              style={{
                                                  position: 'relative'
                                              }}>
                                              {res?.type === 'image' ? (
                                                  <img
                                                      width={160}
                                                      height={110}
                                                      className={
                                                          this.isSelect(res.id)
                                                              ? 'select-media'
                                                              : null
                                                      }
                                                      onClick={() => {
                                                          selectMultiple
                                                              ? this.addToMedia(res)
                                                              : this.addSingleToMedia(res);
                                                      }}
                                                      src={
                                                          res.thumbnail
                                                              ? res.thumbnail.url
                                                              : res.url
                                                      }
                                                      alt=""
                                                      style={{
                                                          objectFit: 'cover',
                                                          objectPosition: 'center center'
                                                      }}
                                                  />
                                              ) : (
                                                  res?.type === 'video' && (
                                                      <video
                                                          onClick={() => {
                                                              selectMultiple
                                                                  ? this.addToMedia(res)
                                                                  : this.addSingleToMedia(res);
                                                          }}
                                                          width={160}
                                                          height={110}
                                                          className={
                                                              this.isSelect(res.id)
                                                                  ? 'select-media'
                                                                  : null
                                                          }
                                                          src={
                                                              res.thumbnail
                                                                  ? res.thumbnail.url
                                                                  : res.url
                                                          }
                                                          style={{
                                                              objectFit: 'cover',
                                                              objectPosition: 'center center'
                                                          }}></video>
                                                  )
                                              )}

                                              {this.isSelect(res.id) ? (
                                                  <img
                                                      src={SelectIcon}
                                                      className="selected-icon"
                                                      alt=""
                                                  />
                                              ) : null}
                                              <img
                                                  src={DeleteIcon}
                                                  className="delete-icon cursor-pointer"
                                                  alt=""
                                                  onClick={() => this.showPropsConfirm(res.id)}
                                              />
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </div>
                    </Form>
                    <div className="pagination-gallery">
                        <Pagination
                            defaultCurrent={1}
                            total={totalPages}
                            defaultPageSize={10}
                            onChange={(page) => {
                                this.changePage(page);
                            }}
                        />
                    </div>
                    <div className="footer-button">
                        <Button color="primary" onClick={() => this.onSave()}>
                            Save & Close
                        </Button>
                        <Button color="dark">
                            <img src={HelpIcon} alt="" /> Help
                        </Button>
                    </div>
                </Drawer>
            </div>
        );
    }
}
Gallery.propTypes = {
    media: Proptypes.array,
    selectMediaList: Proptypes.array,
    files: Proptypes.object,
    uploaded_file: Proptypes.object,
    title: Proptypes.string,
    delete_file: Proptypes.object,
    getFilesType: Proptypes.func,
    fileUpload: Proptypes.func,
    onDeleteMedia: Proptypes.func,
    onClose: Proptypes.func,
    onSaveMedia: Proptypes.func,
    subTitle: Proptypes.string,
    showModal: Proptypes.bool,
    classNameTitle: Proptypes.string,
    loading: Proptypes.bool,
    selectMultiple: Proptypes.bool,
    file_upload_progress: Proptypes.object
};

const mapStateToProps = (state) => ({
    files: state.contentDetail.files,
    uploaded_file: state.contentDetail.uploaded_file,
    delete_file: state.contentDetail.delete_file,
    loading: state.loading,
    file_upload_progress: state.contentDetail.file_upload_progress
});

const mapDispatch = (dispatch) => ({
    fileUpload: (data) => dispatch(fileUpload(data)),
    getFiles: () => dispatch(getFiles()),
    getFilesType: (data, page) => dispatch(getFilesType(data, page)),
    onDeleteMedia: (id) => dispatch(onDeleteMedia(id)),
    clearUploadedFile: () => dispatch(clearUploadedFile())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(Gallery));

/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Popover } from 'antd';
import { PlusIcon, BasicProgress, StarRating } from 'components/atoms';
import { getCalendarsById, getAllCalendarProducts } from 'store/actions/calendarActions';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCalendarStars,
    getTotalHoursOfSingleCalendar,
    getQualityOfInformationOfSingleCalendar
} from 'utils';
const CalendarRatingModal = (props) => {
    const {
        isModalOpen,
        setModalOpenState,
        increaseRating: { setAvailableHoursModal, setProductQualityModal }
    } = props;

    const route = useRouteMatch();
    const dispatch = useDispatch();

    const calendar = useSelector((state) => state?.calendar?.get_calendar_data);
    const allProducts = useSelector((state) => state?.calendar?.get_all_calendar_products?.data);
    const QualityOfInformation = getQualityOfInformationOfSingleCalendar(allProducts);
    const totalHours = getTotalHoursOfSingleCalendar(calendar);
    const total =
        Number(
            Number(QualityOfInformation?.average) +
                Number(((totalHours.used * 100) / totalHours.total).toFixed(0)) +
                100
        ) / 3;
    const stars = getCalendarStars(total || 0);

    useEffect(() => {
        if (route.params.id) {
            dispatch(getCalendarsById(route.params.id));
            dispatch(getAllCalendarProducts(route.params.id));
        }
    }, []);

    return (
        <Modal
            size="lg"
            centered
            scrollable
            isOpen={isModalOpen}
            toggle={() => {
                setModalOpenState(!isModalOpen);
            }}>
            <ModalHeader
                className="close-alt"
                toggle={() => setModalOpenState(!isModalOpen)}></ModalHeader>
            <ModalBody className="d-flex flex-column">
                <div className="d-md-flex mb-3">
                    <div className="d-flex flex-nowrap flex-grow-1 mr-3">
                        <span className="modal-title w-auto mr-2">Calendar Rating </span>
                        <Popover
                            overlayClassName="popover-container popover-large"
                            placement="top"
                            content={
                                <div className="fs-14">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                    Vestibulum non blandit dui. Mauris elementum ex nulla. Duis enim
                                    nulla, eleifend blandit faucibus quis,
                                </div>
                            }>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM12 20.7188C7.18594 20.7188 3.28125 16.8141 3.28125 12C3.28125 7.18594 7.18594 3.28125 12 3.28125C16.8141 3.28125 20.7188 7.18594 20.7188 12C20.7188 16.8141 16.8141 20.7188 12 20.7188Z"
                                    fill="#333333"
                                />
                                <path
                                    d="M10.875 7.875C10.875 8.17337 10.9935 8.45952 11.2045 8.6705C11.4155 8.88147 11.7016 9 12 9C12.2984 9 12.5845 8.88147 12.7955 8.6705C13.0065 8.45952 13.125 8.17337 13.125 7.875C13.125 7.57663 13.0065 7.29048 12.7955 7.0795C12.5845 6.86853 12.2984 6.75 12 6.75C11.7016 6.75 11.4155 6.86853 11.2045 7.0795C10.9935 7.29048 10.875 7.57663 10.875 7.875ZM12.5625 10.5H11.4375C11.3344 10.5 11.25 10.5844 11.25 10.6875V17.0625C11.25 17.1656 11.3344 17.25 11.4375 17.25H12.5625C12.6656 17.25 12.75 17.1656 12.75 17.0625V10.6875C12.75 10.5844 12.6656 10.5 12.5625 10.5Z"
                                    fill="#333333"
                                />
                            </svg>
                        </Popover>
                    </div>
                    <div>
                        <div className="fs-22 text-primary fw-600">{stars} Stars</div>
                        <StarRating value={stars} />
                    </div>
                </div>

                <div>
                    <Row
                        className="align-items-lg-center py-3 border-bottom border-primary-shaded"
                        noGutters>
                        <Col xs={12} className="flex-lg-150-rest">
                            <div className="fs-16 fw-500">Available Hours</div>
                        </Col>
                        <Col xs={12} className="flex-lg-150">
                            <BasicProgress
                                className="progress-title-stand-out"
                                percent={((totalHours.used * 100) / totalHours.total).toFixed(0)}
                            />
                            <div
                                className="float-right position-relative"
                                style={{ marginTop: -36, paddingBottom: 12 }}>
                                <span
                                    role="button"
                                    tabIndex="0"
                                    className="fs-12 text-primary fw-400 ff-special"
                                    onClick={() => {
                                        setModalOpenState(false);
                                        setAvailableHoursModal(true);
                                    }}
                                    onKeyUp={() => {
                                        setModalOpenState(false);
                                        setAvailableHoursModal(true);
                                    }}>
                                    Increase <PlusIcon />
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className="align-items-lg-center py-3 border-bottom border-primary-shaded"
                        noGutters>
                        <Col xs={12} className="flex-lg-150-rest">
                            <div className="fs-16 fw-500">Product QoI (Quality of Information)</div>
                        </Col>
                        <Col xs={12} className="flex-lg-150">
                            <BasicProgress
                                className="progress-title-stand-out"
                                percent={QualityOfInformation?.average}
                            />
                            <div
                                className="float-right position-relative"
                                style={{ marginTop: -36, paddingBottom: 12 }}>
                                <span
                                    role="button"
                                    tabIndex="0"
                                    className="fs-12 text-primary fw-400 ff-special"
                                    onClick={() => {
                                        setModalOpenState(false);
                                        setProductQualityModal(true);
                                    }}
                                    onKeyUp={() => {
                                        setModalOpenState(false);
                                        setProductQualityModal(true);
                                    }}>
                                    Increase <PlusIcon />
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row
                        className="align-items-lg-center py-3 border-bottom border-primary-shaded"
                        noGutters>
                        <Col xs={12} className="flex-lg-150-rest">
                            <div className="fs-16 fw-500">Customer Experience</div>
                        </Col>
                        <Col xs={12} className="flex-lg-150">
                            <BasicProgress className="progress-title-stand-out" percent={100} />
                            <div
                                className="float-right position-relative"
                                style={{ marginTop: -36, paddingBottom: 12 }}>
                                <span
                                    role="button"
                                    className="fs-12 text-primary fw-400 ff-special">
                                    Increase <PlusIcon />
                                </span>
                            </div>
                        </Col>
                    </Row>
                </div>
            </ModalBody>
        </Modal>
    );
};

CalendarRatingModal.propTypes = {
    isModalOpen: PropTypes.bool,
    setModalOpenState: PropTypes.func,
    increaseRating: PropTypes.object
};

export default CalendarRatingModal;

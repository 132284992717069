import React from 'react';
import PropTypes from 'prop-types';
const PersonalCareIcon = ({ color }) => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3873 0.897339C12.9643 0.499276 12.423 0.308132 11.8775 0.308132C11.2634 0.308132 10.6433 0.550249 10.1809 1.01324L9.98248 1.21166L9.78405 1.01263C9.32165 0.550249 8.7015 0.308132 8.08743 0.308132C7.54191 0.308132 7.00062 0.499276 6.57769 0.897339C5.64745 1.77296 5.63109 3.23778 6.52794 4.13464L9.98248 7.58917L13.437 4.13464C14.3339 3.23778 14.3175 1.77296 13.3873 0.897339Z"
                fill="currentColor"
            />
            <path
                d="M16.7782 9.3483C16.2472 8.98848 15.6216 9.09043 15.0676 9.41022C14.5135 9.73 11.3327 12.4497 11.3327 12.4497L8.20157 12.4503C8.05593 12.4503 7.89817 12.2865 7.89817 12.1414C7.89817 11.9709 8.04684 11.838 8.20157 11.838H10.1173C10.7878 11.838 11.5384 11.4011 11.5384 10.6244C11.5384 9.79917 10.7878 9.4108 10.1173 9.4108C8.95401 9.4108 8.72767 9.41566 8.72767 9.41566C8.26165 9.41566 7.78895 9.38774 7.42486 9.19296C6.93943 8.94418 6.37994 8.8034 5.78284 8.8034C4.7804 8.8034 3.88293 9.20329 3.26764 9.83376L0 13.051L3.64082 16.6918L4.85443 15.4782H10.9996C11.6112 15.4782 12.2016 15.2537 12.6585 14.8478L16.8194 10.1802C17.0737 9.95451 17.0591 9.53944 16.7782 9.3483Z"
                fill={color || '#FFF'}
            />
        </svg>
    );
};

PersonalCareIcon.propTypes = {
    color: PropTypes.any
};

export default PersonalCareIcon;

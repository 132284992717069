export const GET_VENDOR_RATING = 'GET_VENDOR_RATING';
export const STRIPE_CONNECT = 'STRIPE_CONNECT';
export const FUND_DETAIL = 'FUND_DETAIL';
export const STRIPE_PAYMENTS = 'STRIPE_PAYMENTS';
export const STRIPE_TRANSFERS = 'STRIPE_TRANSFERS';
export const STRIPE_PAYOUTS = 'STRIPE_PAYOUTS';
export const STRIPE_REFUNDS = 'STRIPE_REFUNDS';
export const STRIPE_PAYMENT_DETAIL = 'STRIPE_PAYMENT_DETAIL';
export const STRIPE_ACCOUNT_DETAIL = 'STRIPE_ACCOUNT_DETAIL';

export const BUSINESS_INFORMATION = 'BUSINESS_INFORMATION';
export const UPDATE_BUSINESS_INFORMATION = 'UPDATE_BUSINESS_INFORMATION';
export const UPDATE_BUSINESS_STATE = 'UPDATE_BUSINESS_STATE';
export const UPDATE_OFFICE_INFORMATION = 'UPDATE_OFFICE_INFORMATION';
export const UPDATE_OFFICE_STATE = 'UPDATE_OFFICE_STATE';
export const LICENSE_INFORMATION = 'LICENSE_INFORMATION';
export const UPDATE_LICENSE_INFORMATION = 'UPDATE_LICENSE_INFORMATION';
export const UPDATE_LICENSE_STATE = 'UPDATE_LICENSE_STATE';
// export const INSURRANCE_INFORMATION = 'INSURRANCE_INFORMATION';
export const UPDATE_INSURRANCE_INFORMATION = 'UPDATE_INSURRANCE_INFORMATION';
export const UPDATE_INSURRANCE_STATE = 'UPDATE_INSURRANCE_STATE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES';

export const STRIPE_SINGLE_TRANS_DETAIL = 'STRIPE_SINGLE_TRANS_DETAIL';
export const BUSINESS_BANK_ACCOUNT_INFORMATION = 'BUSINESS_BANK_ACCOUNT_INFORMATION';
export const ANALYSIS_DETAIL = 'ANALYSIS_DETAIL';

export const BANKS_PAYSTACK = 'BANKS_PAYSTACK';
export const PAYSTACK_ACCOUNT_DET = 'PAYSTACK_ACCOUNT_DET';

export const PAYSTACK_PAYMENTS = 'PAYSTACK_PAYMENTS';
export const PAYSTACK_TRANSFERS = 'PAYSTACK_TRANSFERS';
export const PAYSTACK_PAYOUTS = 'PAYSTACK_PAYOUTS';
export const PAYSTACK_REFUNDS = 'PAYSTACK_REFUNDS';

export const FETCH_LOCATION = 'FETCH_LOCATION';

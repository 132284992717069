// import React, { Component } from 'react'

// export default class index extends Component {
//     setGender(event) {
//       }

//     render() {
//         return (
//     <div onChange={this.setGender.bind(this)}>
//         <input type="radio" value="YES" name="boolean"/> Yes
//         <input type="radio" value="NO" name="boolean"/> No
//       </div>
//         )
//     }
// }

import React from 'react';
import PropTypes from 'prop-types';

export default function RadioButton(props) {
    return (
        <div style={{ paddingTop: '20px' }} onChange={props.gender}>
            <h6>{props.details}</h6>
            <p>{props.text}</p>
            <div style={{ paddingBottom: '10px' }}>
                <input type="radio" value="YES" name="boolean" /> {props.yes}
            </div>
            <input type="radio" value="NO" name="boolean" /> {props.no}
        </div>
    );
}

RadioButton.propTypes = {
    gender: PropTypes.string,
    details: PropTypes.string,
    text: PropTypes.string,
    yes: PropTypes.string,
    no: PropTypes.string
};

import React from 'react'; // , { useState }
import PropTypes from 'prop-types';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import CollapseMoreLess from '../../collapse-more-less';
import { PictureIcon } from 'components/atoms';

const EventPhotosAndVideos = (props) => {
    const { product, onEdit } = props;
    // const [visibleProductSummaryDrawer, setVisibleProductSummaryDrawer] = useState(false);
    // const [viewGalleryItemModal, setViewGalleryItemModal] = useState(false);

    return (
        <DetailedPanel
            panelIcon={<PictureIcon />}
            panelTitle="Photos & videos"
            isEditable={true}
            onEdit={onEdit}>
            <div className="gallery-grid">
                {product?.photos_and_videos && product?.photos_and_videos.length > 0 ? (
                    product?.photos_and_videos.slice(0, 6).map((item, index) => (
                        <div
                            key={index}
                            className={`gallery-item item-type-${
                                item.type === 'video' ? 'video' : 'img'
                            }`}
                            role="button"
                            tabIndex="0"
                            // onKeyUp={() => setViewGalleryItemModal(true)}
                            // onClick={() => setViewGalleryItemModal(true)}
                        >
                            <img src={item.url} alt="" />
                        </div>
                    ))
                ) : (
                    <></>
                )}
            </div>
            {product?.photos_and_videos && product?.photos_and_videos.length > 6 ? (
                <>
                    {' '}
                    <div className="gallery-gap-y"></div>
                    <CollapseMoreLess>
                        <div className="gallery-grid">
                            {product?.photos_and_videos
                                .slice(6, product?.photos_and_videos.length)
                                .map((item, index) => (
                                    <div
                                        key={index}
                                        className={`gallery-item item-type-${
                                            item.type === 'video' ? 'video' : 'img'
                                        }`}
                                        role="button"
                                        tabIndex="0"
                                        // onKeyUp={() => setViewGalleryItemModal(true)}
                                        // onClick={() => setViewGalleryItemModal(true)}
                                    >
                                        <img src={item.url} alt="" />
                                    </div>
                                ))}
                        </div>
                    </CollapseMoreLess>
                </>
            ) : (
                <></>
            )}
        </DetailedPanel>
    );
};

EventPhotosAndVideos.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventPhotosAndVideos;

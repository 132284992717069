import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import DropDown from '../../../../assets/images/dropDown.svg';
import MoreText from '../pricing/MoreText.jsx';
import MoreInfoIcon from '../../../../assets/images/more_info.svg';
import { Grid } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import UpDown from '../../../../assets/images/UpDown.svg';
import Edit from '../../../../assets/images/edit.svg';
import Copy from '../../../../assets/images/Copy.svg';
import Delete from '../../../../assets/images/delete.svg';

const Accordion = withStyles({
    root: {
        'boxShadow': 'none',
        '&:not(:last-child)': {
            borderBottom: 0
        },
        '&:before': {
            display: 'none'
        },
        '&$expanded': {
            margin: 'auto'
        }
    },
    expanded: {}
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        'backgroundColor': '#E7D99F',
        'marginBottom': -1,
        'minHeight': 56,
        '&$expanded': {
            minHeight: 56
        },
        'fontWeight': '600',
        'fontSize': '18px',
        'lineHeight': '22px',
        /* identical to box height */
        'fontStyle': 'normal',
        'color': '#000000'
    },
    content: {
        '&$expanded': {
            margin: '12px 0'
        }
    },
    expanded: {}
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2)
    }
}))(MuiAccordionDetails);

export default function ActivityAccordion() {
    const [expanded, setExpanded] = useState('panel1');
    const [expandAll, setExpandAll] = useState(false);
    const [days, setDays] = useState([
        [
            {
                startTime: 10,
                endTime: 1,
                itineraryName: 'Panorama Route',
                additions: [
                    'Meals & Drinks added',
                    'Language offered added',
                    'Inclusions & Exclusions added',
                    'Information travellers need from you added'
                ]
            },
            {
                startTime: 8,
                endTime: 10,
                itineraryName: 'Atlantic Route',
                additions: ['Meals & Drinks added', 'Language offered added']
            }
        ],
        []
    ]);

    const handleChange = (panel) => (e, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const addDay = () => {
        let tempDays = [...days];
        tempDays.push([]);
        setDays(tempDays);
    };

    const removeDay = (index) => {
        let tempDays = [...days];
        tempDays.splice(index, 1);
        setDays(tempDays);
    };

    const handleExpandAll = () => {
        setExpandAll(!expandAll);
    };

    const timeConverter = (time) => {
        if (time > 12) {
            return `${time}pm`;
        } else {
            return `${time}am`;
        }
    };

    const timeCompare = (a, b) => {
        if (a.startTime > b.startTime) {
            return 1;
        }
        if (a.startTime < b.startTime) {
            return -1;
        }
        if (a.startTime === b.startTime) {
            return 0;
        }
    };

    const dragActivities = (e, index, timeIndex) => {
        let activityContent = days[index][timeIndex];
        let activityContentAdded = { ...activityContent, index, timeIndex };
        let movingObject = JSON.stringify(activityContentAdded);
        e.dataTransfer.setData('activity', movingObject);
    };

    const dropActivities = (e) => {
        e.preventDefault();
        e.stopPropagation();
        var data = JSON.parse(e.dataTransfer.getData('activity'));
        let tempDays = [...days];
        tempDays[e.target.id].push(data);
        tempDays[data.index].splice(data.timeIndex, 1);
        setDays(tempDays);
    };

    const deleteActivity = (index, timeIndex) => {
        let tempDays = [...days];
        tempDays[index].splice(timeIndex, 1);
        setDays(tempDays);
    };

    const allowDrop = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            {days.map((each, index) => (
                <Accordion
                    key={index}
                    style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal'
                    }}
                    square={false}
                    expanded={expanded === `panel${index + 1}` || expandAll === true}
                    onChange={handleChange(`panel${index + 1}`)}>
                    <AccordionSummary aria-controls="panel1d-content">
                        <Grid container justify="space-between">
                            {index === 0 ? (
                                <Grid
                                    item
                                    container
                                    justify="flex-end"
                                    style={{
                                        backgroundColor: '#E7D99F',
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        /* identical to box height */
                                        color: '#333333'
                                    }}
                                    onClick={handleExpandAll}>
                                    <Grid item>
                                        <Typography>Expand All Days</Typography>
                                    </Grid>
                                </Grid>
                            ) : null}
                            <Grid item>
                                <Typography>
                                    <img src={DropDown} style={{ marginRight: '8px' }} alt="" />
                                    {`Day ${index + 1}`}
                                </Typography>
                            </Grid>
                            {index !== 0 && days.length > 1 ? (
                                <Grid
                                    item
                                    style={{
                                        fontWeight: '500',
                                        fontSize: '16px',
                                        lineHeight: '20px',
                                        /* identical to box height */
                                        color: '#333333'
                                    }}
                                    onClick={() => removeDay(index)}>
                                    Remove Day
                                </Grid>
                            ) : null}
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                        style={{
                            backgroundColor: '#E7D99F'
                        }}>
                        <Grid
                            container
                            direction="column"
                            style={{
                                backgroundColor: '#ffffff',
                                borderRadius: '8px',
                                padding: '14px 28px'
                            }}>
                            <Grid
                                container
                                item
                                style={{
                                    fontWeight: '600',
                                    fontSize: '18px',
                                    lineHeight: '22px'
                                    /* identical to box height */
                                }}>
                                <Grid item>
                                    Itineraries
                                    <span>
                                        <img src={MoreInfoIcon} alt="" />
                                    </span>
                                </Grid>
                            </Grid>
                            <Grid item style={{ margin: '19px 0' }}>
                                <hr />
                            </Grid>
                            <Grid
                                item
                                id={index}
                                onDrop={(e) => dropActivities(e)}
                                onDragOver={(e) => allowDrop(e)}>
                                {days[index].sort(timeCompare).map((each, timeIndex) => (
                                    <div
                                        key={timeIndex}
                                        draggable="true"
                                        onDragStart={(e) => dragActivities(e, index, timeIndex)}>
                                        <Grid container spacing={1}>
                                            <Grid
                                                item
                                                md={3}
                                                style={{
                                                    fontWeight: 'normal',
                                                    fontSize: '14px',
                                                    lineHeight: '17px',
                                                    /* identical to box height */
                                                    color: '#333333'
                                                }}>
                                                {timeConverter(each.startTime)} -{' '}
                                                {timeConverter(each.endTime)}
                                            </Grid>
                                            <Grid item container direction="column" md={6}>
                                                <Grid
                                                    item
                                                    style={{
                                                        fontWeight: '600',
                                                        fontSize: '18px',
                                                        lineHeight: '22px',
                                                        /* identical to box height */
                                                        color: '#333333',
                                                        marginBottom: '13px'
                                                    }}>
                                                    {each.itineraryName}
                                                </Grid>
                                                {each.additions.map((added, addIndex) => (
                                                    <Grid
                                                        key={addIndex}
                                                        item
                                                        style={{
                                                            fontWeight: 'normal',
                                                            fontSize: '18px',
                                                            lineHeight: '22px',
                                                            /* identical to box height */
                                                            color: '#333333',
                                                            marginBottom: '13px'
                                                        }}>
                                                        {added}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                            <Grid item md={3}>
                                                <img
                                                    src={UpDown}
                                                    alt=""
                                                    style={{
                                                        marginRight: '18px'
                                                    }}
                                                />
                                                <NavLink
                                                    to={`/edit-activity-option/${index + 1}/${
                                                        timeIndex + 1
                                                    }`}>
                                                    <img
                                                        src={Edit}
                                                        alt=""
                                                        style={{
                                                            marginRight: '18px'
                                                        }}
                                                    />
                                                </NavLink>
                                                <img
                                                    src={Copy}
                                                    alt=""
                                                    style={{
                                                        marginRight: '18px'
                                                    }}
                                                />
                                                <img
                                                    src={Delete}
                                                    alt=""
                                                    onClick={() => deleteActivity(index, timeIndex)}
                                                    style={{
                                                        marginRight: '18px'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                marginBottom: '12px'
                                            }}>
                                            <hr />
                                        </Grid>
                                    </div>
                                ))}
                            </Grid>
                            <Grid item style={{ cursor: 'pointer' }}>
                                <NavLink to="/add-activity-option">
                                    <MoreText text="Add an Itinerary" />
                                </NavLink>
                            </Grid>
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            ))}
            <div style={{ marginTop: '36px', marginBottom: '26px' }}>
                <hr />
            </div>
            <div style={{ cursor: 'pointer' }} onClick={addDay}>
                <MoreText text="Add another day" />
            </div>
        </div>
    );
}

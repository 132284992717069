import React from 'react';

const SleepIcon = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.3182 6.59091H9.95455V12.1591H3.59091V5H2V16.9318H3.59091V14.5455H17.9091V16.9318H19.5V9.77273C19.5 8.92886 19.1648 8.11955 18.5681 7.52284C17.9714 6.92614 17.1621 6.59091 16.3182 6.59091ZM6.77273 11.3636C7.40563 11.3636 8.01261 11.1122 8.46014 10.6647C8.90767 10.2172 9.15909 9.61018 9.15909 8.97727C9.15909 8.34437 8.90767 7.73739 8.46014 7.28986C8.01261 6.84233 7.40563 6.59091 6.77273 6.59091C6.13982 6.59091 5.53284 6.84233 5.08531 7.28986C4.63778 7.73739 4.38636 8.34437 4.38636 8.97727C4.38636 9.61018 4.63778 10.2172 5.08531 10.6647C5.53284 11.1122 6.13982 11.3636 6.77273 11.3636Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default SleepIcon;

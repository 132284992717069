import React from 'react';
import MainEvent from './main-event';
import FreeTime from './free-time';
import FreeTimeMini from './free-time-mini';
import NightTime from './night-time';
import ClashingEvent from './clashing-event';
import { RestingTime } from 'components/atoms';

export default function renderEventContent(eventInfo, otherProps) {
    return (
        <>
            {eventInfo?.event?.extendedProps?.event_type == 'main_event' && (
                <MainEvent eventInfo={eventInfo} {...otherProps} />
            )}

            {eventInfo?.event?.extendedProps?.event_type == 'free_time' && (
                <FreeTime eventInfo={eventInfo} {...otherProps} />
            )}

            {eventInfo?.event?.extendedProps?.event_type == 'free_time_mini' && (
                <FreeTimeMini eventInfo={eventInfo} {...otherProps} />
            )}

            {eventInfo?.event?.extendedProps?.event_type == 'night_time' && (
                <NightTime eventInfo={eventInfo} {...otherProps} />
            )}

            {eventInfo?.event?.extendedProps?.event_type == 'resting_time' && (
                <RestingTime eventInfo={eventInfo} {...otherProps} />
            )}

            {eventInfo?.event?.extendedProps?.event_type == 'clashing_event' && (
                <ClashingEvent eventInfo={eventInfo} {...otherProps} />
            )}
        </>
    );
}

export const GET_VENDOR_SUBSCRIPTION = 'GET_VENDOR_SUBSCRIPTION';
export const TOP_UP_PAY_NOW = 'TOP_UP_PAY_NOW';
export const GET_ALL_PLANS = 'GET_ALL_PLANS';
export const GET_UPCOMING_SUBSCRIPTION = 'GET_UPCOMING_SUBSCRIPTION';
export const TOOGLE_UPGRADE_PLAN_DRAWER = 'TOOGLE_UPGRADE_PLAN_DRAWER';
export const UPDATE_VENDOR_SUBSCRIPTION = 'UPDATE_VENDOR_SUBSCRIPTION';
export const SHOW_DRAFT_CALENDAR_POPUP = 'SHOW_DRAFT_CALENDAR_POPUP';
export const ALL_LIVE_CALENDARS = 'ALL_LIVE_CALENDARS';
export const PREFERED_DISBALE_CALENDARS = 'PREFERED_DISBALE_CALENDARS';
export const INITIALIZE_SUBSCRIPTION = 'INITIALIZE_SUBSCRIPTION';
export const INITIALIZE_TOKEN_TOP_UP = 'INITIALIZE_TOKEN_TOP_UP';
export const GET_USER_CARDS = 'GET_USER_CARDS';
export const ACC_LOADING = 'ACC_LOADING';
export const DOWNGRADE_TO_FREE = 'DOWNGRADE_TO_FREE';
export const All_TRAVELLERS = 'All_TRAVELLERS';

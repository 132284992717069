/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import './index.css';
import VendorDashboard from '../vendor-dashboard';
import TextFormLayout from '../../../atoms/textform-layout';
import TextAreaLayout from '../../../atoms/textarea-layout';
import Button from '../button';
import TextDropDown from '../../../atoms/text-dropdown';
import Info from '../../../../assets/images/info.svg';
import Accomodation from '../../../../assets/images/accomodation.svg';
import Activity from '../../../../assets/images/activity.svg';
import Guide from '../../../../assets/images/guide.svg';
import Meals from '../../../../assets/images/meals.svg';
import Personal from '../../../../assets/images/personal.svg';
import Transport from '../../../../assets/images/transport.svg';
import Caret from '../../../../assets/images/caret.svg';
import Down from '../../../../assets/images/down-caret.svg';
import InputBox from '../../../atoms/inputbox';
import ImportProduct from '../importproduct';
import { useDispatch } from 'react-redux';
import { progress } from '../../../../store/actions/progress';
import { NavLink } from 'react-router-dom';
import Feedback from '../../../molecules/user-feedback';

export default function ProjectCategorization() {
    const dispatch = useDispatch();
    //MOck data for initial data
    const initData = [
        {
            name: 'Activity',
            picture: Activity,
            child: ['itenary']
        },
        {
            name: 'Accomodation',
            picture: Accomodation,
            child: [
                'Villa / Mansion ',
                'Hotel',
                'Apartment',
                'Lodge',
                'Camping',
                'Bread & Breakfast',
                'Chalet',
                'Hostel',
                'Log Cabin',
                'Country Home',
                'Timeshare Rentals ',
                'Cottage'
            ]
        },
        {
            name: 'Transportation',
            picture: Transport,
            child: ['Airport Transfer/ Pickup', 'Car Rental', 'Chauffer Service']
        },
        {
            name: 'Meals & Drinks',
            picture: Meals,
            child: [
                'Restaurant Booking',
                'Personal Chef Service',
                'Groceries Shopper',
                'Traditional Food Delivery'
            ]
        },
        {
            name: 'Guide',
            picture: Guide,
            child: ['Market Guide', 'Nightlife Guide', 'Meet The Locals']
        },
        {
            name: 'Personal Care',
            picture: Personal,
            child: ['Laundry Service', 'Spa / Wellness', 'Child Minder Service']
        }
    ];

    //Usestatefor setting category data
    const [addCategory, setAddCategory] = useState('');
    const [data, setData] = useState(initData);
    const handleChange = () => {
        setData([
            ...data,
            {
                name: addCategory,
                picture: Guide
            }
        ]);
    };

    // const [isOpen, setIsOpen] = useState(false);
    // const toggle = () => {
    //     setIsOpen(!isOpen);
    // };

    return (
        <VendorDashboard>
            <div className="background-sz">
                <ImportProduct
                    create="Create your main product"
                    import=" I will import a main product information from my other calendar"
                />
                <p style={{ fontSize: '16px', paddingTop: '20px', width: '90%' }}>
                    {' '}
                    Describe the main product you will like to use to attract travelers to make a
                    purchase. This is what gets you access to customers calendar. You'll be able to
                    add more details next.
                </p>

                <TextFormLayout layout={'Enter your product title'} />
                <div style={{ paddingTop: '30.0px' }}>
                    <p style={{ fontWeight: '500' }}>
                        Select Category
                        <span>
                            <img style={{ paddingBottom: '20px' }} src={Info} alt="" />
                        </span>
                    </p>
                    <TextDropDown
                        name="Select One"
                        className="input-bar-dropdown"
                        caret={Caret}
                        down={Down}
                        class={'image-icon-down'}
                        icon={'image-icon'}>
                        {data.map((item) => {
                            return (
                                <TextDropDown
                                    width="32px"
                                    caret={Caret}
                                    down={Down}
                                    isCheckBox={false}
                                    image={item.picture}
                                    isSidebar={true}
                                    name={item.name}
                                    key={item.name}
                                    className="input-no-curve"
                                    class={'image-icon-down'}
                                    icon={'image-icon'}>
                                    {item.child &&
                                        item.child.map((child) => {
                                            return (
                                                //  <input placeholder={child} style={{ height: "37px"}} className="input-bar"/>

                                                <TextDropDown
                                                    check={true}
                                                    name={child}
                                                    key={child}
                                                    isSidebar={false}
                                                    className="input-no-curve"
                                                    isCheckBox={true}
                                                    formCheck="form-check move-down "></TextDropDown>
                                            );
                                        })}
                                </TextDropDown>
                            );
                        })}
                        <div className="input-curve">
                            <InputBox other="Other" padleft="35px" />
                            <div className="padding-box">
                                <input
                                    placeholder="Enter Category Here"
                                    style={{ height: '37px', float: 'left', paddingLeft: '15px' }}
                                    className="input-bar"
                                    onChange={(e) => setAddCategory(e.target.value)}
                                    value={addCategory}
                                />
                                <Button onClick={handleChange} text="Done" float="right" />
                            </div>
                        </div>
                    </TextDropDown>
                </div>
                <TextAreaLayout
                    isSelected={true}
                    layout={'Why should travelers book this tour?'}
                    height={135}
                />
                <div style={{ paddingTop: '40px', paddingBottom: '40px' }}>
                    <NavLink to="product-content">
                        <Button
                            onClick={() => dispatch(progress())}
                            float={'left'}
                            text={'Update Calendar and Continue'}
                        />
                    </NavLink>
                    <div style={{ float: 'right' }}>
                        <Feedback />
                    </div>
                </div>
            </div>
        </VendorDashboard>
    );
}

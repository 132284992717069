import React from 'react';
import PropTypes from 'prop-types';

const StarRatingsSmall = ({ stars }) => {
    return (
        <svg
            width="95"
            height="16"
            viewBox="0 0 95 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.9075 5.42468L10.2892 4.72184L8.22477 0.339049C8.16838 0.219051 8.07562 0.12191 7.96103 0.0628632C7.67364 -0.0857059 7.3244 0.0381017 7.18071 0.339049L5.11623 4.72184L0.497992 5.42468C0.370668 5.44373 0.254257 5.50659 0.16513 5.60182C0.0573798 5.71779 -0.00199519 5.87382 5.11952e-05 6.03561C0.00209758 6.1974 0.0653979 6.35172 0.176043 6.46467L3.5174 9.87604L2.72799 14.6931C2.70948 14.8052 2.72132 14.9204 2.76217 15.0258C2.80302 15.1311 2.87125 15.2224 2.95912 15.2892C3.04698 15.3561 3.15097 15.3958 3.2593 15.4039C3.36762 15.412 3.47594 15.3881 3.57197 15.335L7.70274 13.0607L11.8335 15.335C11.9463 15.3979 12.0772 15.4188 12.2028 15.3959C12.5192 15.3388 12.7321 15.0245 12.6775 14.6931L11.8881 9.87604L15.2294 6.46467C15.3204 6.37133 15.3804 6.24943 15.3986 6.1161C15.4477 5.78277 15.2258 5.47421 14.9075 5.42468Z"
                fill="#E2C022"
                fillOpacity={stars >= 1 ? '1' : '0.3'}
            />
            <path
                d="M34.8062 5.42468L30.1879 4.72184L28.1235 0.339049C28.0671 0.219051 27.9743 0.12191 27.8597 0.0628632C27.5723 -0.0857059 27.2231 0.0381017 27.0794 0.339049L25.0149 4.72184L20.3967 5.42468C20.2693 5.44373 20.1529 5.50659 20.0638 5.60182C19.9561 5.71779 19.8967 5.87382 19.8987 6.03561C19.9008 6.1974 19.9641 6.35172 20.0747 6.46467L23.4161 9.87604L22.6267 14.6931C22.6082 14.8052 22.62 14.9204 22.6609 15.0258C22.7017 15.1311 22.7699 15.2224 22.8578 15.2892C22.9457 15.3561 23.0497 15.3958 23.158 15.4039C23.2663 15.412 23.3746 15.3881 23.4707 15.335L27.6014 13.0607L31.7322 15.335C31.845 15.3979 31.9759 15.4188 32.1014 15.3959C32.4179 15.3388 32.6307 15.0245 32.5762 14.6931L31.7868 9.87604L35.1281 6.46467C35.2191 6.37133 35.2791 6.24943 35.2973 6.1161C35.3464 5.78277 35.1245 5.47421 34.8062 5.42468Z"
                fill="#E2C022"
                fillOpacity={stars >= 2 ? '1' : '0.3'}
            />
            <path
                d="M54.7047 5.42468L50.0865 4.72184L48.022 0.339049C47.9656 0.219051 47.8729 0.12191 47.7583 0.0628632C47.4709 -0.0857059 47.1216 0.0381017 46.9779 0.339049L44.9135 4.72184L40.2952 5.42468C40.1679 5.44373 40.0515 5.50659 39.9624 5.60182C39.8546 5.71779 39.7952 5.87382 39.7973 6.03561C39.7993 6.1974 39.8626 6.35172 39.9733 6.46467L43.3146 9.87604L42.5252 14.6931C42.5067 14.8052 42.5186 14.9204 42.5594 15.0258C42.6003 15.1311 42.6685 15.2224 42.7564 15.2892C42.8442 15.3561 42.9482 15.3958 43.0565 15.4039C43.1649 15.412 43.2732 15.3881 43.3692 15.335L47.5 13.0607L51.6308 15.335C51.7435 15.3979 51.8745 15.4188 52 15.3959C52.3165 15.3388 52.5293 15.0245 52.4747 14.6931L51.6853 9.87604L55.0267 6.46467C55.1176 6.37133 55.1776 6.24943 55.1958 6.1161C55.2449 5.78277 55.023 5.47421 54.7047 5.42468Z"
                fill="#E2C022"
                fillOpacity={stars >= 3 ? '1' : '0.3'}
            />
            <path
                d="M74.6034 5.42468L69.9852 4.72184L67.9207 0.339049C67.8643 0.219051 67.7715 0.12191 67.6569 0.0628632C67.3696 -0.0857059 67.0203 0.0381017 66.8766 0.339049L64.8122 4.72184L60.1939 5.42468C60.0666 5.44373 59.9502 5.50659 59.8611 5.60182C59.7533 5.71779 59.6939 5.87382 59.696 6.03561C59.698 6.1974 59.7613 6.35172 59.872 6.46467L63.2133 9.87604L62.4239 14.6931C62.4054 14.8052 62.4172 14.9204 62.4581 15.0258C62.4989 15.1311 62.5672 15.2224 62.655 15.2892C62.7429 15.3561 62.8469 15.3958 62.9552 15.4039C63.0635 15.412 63.1719 15.3881 63.2679 15.335L67.3987 13.0607L71.5294 15.335C71.6422 15.3979 71.7732 15.4188 71.8987 15.3959C72.2152 15.3388 72.428 15.0245 72.3734 14.6931L71.584 9.87604L74.9254 6.46467C75.0163 6.37133 75.0763 6.24943 75.0945 6.1161C75.1436 5.78277 74.9217 5.47421 74.6034 5.42468Z"
                fill="#E2C022"
                fillOpacity={stars >= 4 ? '1' : '0.3'}
            />

            <path
                d="M94.5021 5.42468L89.8839 4.72184L87.8194 0.339049C87.763 0.219051 87.6702 0.12191 87.5556 0.0628632C87.2682 -0.0857059 86.919 0.0381017 86.7753 0.339049L84.7108 4.72184L80.0926 5.42468C79.9653 5.44373 79.8489 5.50659 79.7597 5.60182C79.652 5.71779 79.5926 5.87382 79.5947 6.03561C79.5967 6.1974 79.66 6.35172 79.7706 6.46467L83.112 9.87604L82.3226 14.6931C82.3041 14.8052 82.3159 14.9204 82.3568 15.0258C82.3976 15.1311 82.4659 15.2224 82.5537 15.2892C82.6416 15.3561 82.7456 15.3958 82.8539 15.4039C82.9622 15.412 83.0705 15.3881 83.1666 15.335L87.2973 13.0607L91.4281 15.335C91.5409 15.3979 91.6719 15.4188 91.7974 15.3959C92.1138 15.3388 92.3267 15.0245 92.2721 14.6931L91.4827 9.87604L94.824 6.46467C94.915 6.37133 94.975 6.24943 94.9932 6.1161C95.0423 5.78277 94.8204 5.47421 94.5021 5.42468Z"
                fill="#E2C022"
                fillOpacity={stars >= 5 ? '1' : '0.3'}
            />
        </svg>
    );
};

StarRatingsSmall.propTypes = {
    stars: PropTypes.number
};

export default StarRatingsSmall;

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, { useEffect } from 'react';
import SupplierAgreement from 'components/molecules/supplier-agreement';
import Application from 'components/organisms/homepage/application';
import { Row, Col, Container, Button } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { fetchLegalContent, fetchhomepageContent } from 'store/actions/contentActions';
import { connect } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import LoaderComponent from 'components/atoms/loader';
import { styles, useStyles, FAQresponsive } from './styles';
import ThankYouModal from 'components/molecules/home/thank-you-modal';
import PropTypes from 'prop-types';

function Onboarding(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [isShowDialogModal, setShowDialogModal] = React.useState(false);
    const [isShowingApplicationModal, setShowApplicationModal] = React.useState(false);
    const [legalContent, setLegalContent] = React.useState({}); //homepage_content
    const [homePageContent, setHomePageContent] = React.useState({}); //
    const [didMount, setDidMount] = React.useState(false);
    const openModal = () => {
        setOpen(true);
    };

    const closeParent = () => {
        setOpen(false);
    };

    useEffect(() => {
        // Update the document title using the browser API
        if (!didMount) {
            props.fetchhomepageContent();
            props.fetchLegalContent();
            setDidMount(true);
            window.scrollTo(0, 0);
        } else {
            setLegalContent(props.legal_content);
            setHomePageContent(props.homepage_content);
        }
    }, [props.legal_content, props.homepage_content]);

    return (
        <div style={styles.root}>
            {props.homepage_content ? '' : <LoaderComponent />}
            {homePageContent?.hero_page && (
                // Master Header
                <header
                    className="hero-header"
                    style={{
                        backgroundImage: `url(${homePageContent?.hero_page?.image?.url})`,
                        display: 'flex',
                        justifyContent: 'flex-start',
                        // alignItems: "center",
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}>
                    <Container className="banner-container">
                        <div style={styles.customers} className={classes.customers}>
                            {homePageContent?.hero_page?.title &&
                                ReactHtmlParser(homePageContent?.hero_page?.title)}
                        </div>
                        <div style={styles.manage} className={classes.manage}>
                            {homePageContent?.hero_page?.body &&
                                ReactHtmlParser(homePageContent?.hero_page?.body)}
                        </div>
                        <div className={classes.applyParent}>
                            <Button
                                color="primary"
                                size="lg"
                                className="btn-mw"
                                onClick={() => setShowApplicationModal(true)}>
                                {'Apply Now'}
                            </Button>
                        </div>
                    </Container>
                </header>
            )}
            {/* Product Benefits Section */}
            <section>
                <Container>
                    <div style={styles.benefits} className={classes.benefits}>
                        <Row noGutters className="benefit-row">
                            {homePageContent?.features &&
                            Array.isArray(homePageContent?.features) &&
                            homePageContent?.features?.length
                                ? homePageContent?.features.map((item, index) => (
                                      <Col
                                          lg={4}
                                          className="benefitsItem"
                                          key={index}
                                          style={styles.benefitsItem}>
                                          <div className="fixed-height-icon-wrap d-flex flex-column justify-content-center align-items-center">
                                              <img
                                                  src={item.image.url}
                                                  alt={item.image.alternativeText}
                                              />
                                          </div>
                                          <div
                                              style={styles.itemsHeader}
                                              className={classes.itemsHeader}>
                                              {item?.title && ReactHtmlParser(item?.title)}
                                          </div>
                                          <div className="item-divider"></div>
                                          <div
                                              style={styles.itemsContent}
                                              className={classes.itemsContent}>
                                              {item?.body && ReactHtmlParser(item?.body)}
                                          </div>
                                      </Col>
                                  ))
                                : null}
                        </Row>
                    </div>
                </Container>
            </section>
            {/* Product Intro Video Section */}
            <section className="main-section organize">
                <div className="bg-wrapper">
                    <div className="dotted-bg _1"></div>
                    <div className="dotted-bg _2"></div>
                    <div className="circle-bg _1"></div>
                    <div className="circle-bg _2"></div>
                </div>
                <Container className="px-sm-only-0">
                    <Row className="text-center">
                        <Col xs={12}>
                            <p className={classes.howItWorksText} style={styles.howItWorksText}>
                                Organizing your customers calendar around your business is simple.
                            </p>
                        </Col>
                        <Col xs={12}>
                            <div className="videoWrapper">
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/D-VNL_-CHA4?controls=0"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* How it works Section */}
            <section className="main-section">
                <Container className="px-sm-only-0 ">
                    <div className="howworks-outer">
                        <Row>
                            <Col xs={12}>
                                <h3 className="section-title text-center text-uppercase">
                                    How It Works
                                </h3>
                            </Col>
                            <Col xs={12}>
                                <div className="steps-wrapper">
                                    {homePageContent?.how_it_works &&
                                    Array.isArray(homePageContent?.how_it_works) &&
                                    homePageContent?.how_it_works?.length
                                        ? homePageContent?.how_it_works.map((item, index) => (
                                              <div className="step" key={index}>
                                                  <div className="step-inner">
                                                      <div className="step-count">{index + 1}</div>
                                                      <div className="step-divider"></div>
                                                      <div className="step-card">
                                                          <div className="step-img-wrapper">
                                                              <img
                                                                  src={item?.image?.url}
                                                                  alt={
                                                                      item?.image
                                                                          ?.alternativeText || ''
                                                                  }
                                                              />
                                                          </div>
                                                          <div className="step-title ">
                                                              {item?.title &&
                                                                  ReactHtmlParser(item?.title)}
                                                          </div>
                                                          <div className="step-info">
                                                              {item?.body &&
                                                                  ReactHtmlParser(item?.body)}
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))
                                        : null}
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            {/* Faq Section */}
            <section className="main-section pb-0 faq-section">
                <h3 className="section-title text-center">FAQs</h3>
                <div className="main-section faq-bg">
                    {homePageContent?.faqs &&
                    Array.isArray(homePageContent?.faqs) &&
                    homePageContent?.faqs?.length ? (
                        <Container>
                            <Carousel
                                className="faq-carousel"
                                autoPlay={false}
                                autoPlaySpeed={3000}
                                showDots={true}
                                infinite={true}
                                responsive={FAQresponsive}
                                onMouseEnter={{ onMouseOverFaq: true }}
                                onMouseLeave={{ onMouseOverFaq: false }}>
                                {homePageContent?.faqs.map((item, index) => (
                                    <div className="item" key={index}>
                                        <div className="card faq-card">
                                            <div className="card-body">
                                                <h4 className="faq-title">
                                                    {item?.title && ReactHtmlParser(item?.title)}
                                                </h4>
                                                <div className="faq-info">
                                                    {item?.body && ReactHtmlParser(item?.body)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Carousel>
                        </Container>
                    ) : null}
                </div>
            </section>
            {/* Ready to get started section */}
            {homePageContent?.home_ready && (
                <section className="main-section bg-primary text-white text-center ready-section padding-lg">
                    <Container>
                        <div className="mx-auto" style={{ maxWidth: '790px' }}>
                            <h3 className="section-title text-white">
                                {homePageContent?.home_ready?.title &&
                                    ReactHtmlParser(homePageContent?.home_ready?.title)}
                            </h3>
                            <div className="section-intro text-shadow text-white">
                                {homePageContent?.home_ready?.body &&
                                    ReactHtmlParser(homePageContent?.home_ready?.body)}
                            </div>
                            <div className="ready-btn-wrapper">
                                <p
                                    className="text-white d-inline-block  review-agreement-btn"
                                    role="button"
                                    onClick={openModal}>
                                    Review Supplier Agreement
                                </p>
                            </div>
                            <button
                                className="btn btn-white btn-lg btn-mw apply-now-btn btn-shadow"
                                onClick={() => setShowApplicationModal(true)}>
                                Apply Now
                            </button>
                        </div>
                    </Container>
                </section>
            )}
            {/* Apply Now Drawer Component */}
            <Application
                onClose={() => setShowApplicationModal(false)}
                show={isShowingApplicationModal}
                onDialog={() => setShowDialogModal(true)}
            />
            {/* Thank You Dialog */}
            <ThankYouModal
                isShowDialogModal={isShowDialogModal}
                setShowDialogModal={setShowDialogModal}
            />
            {/* Supplier Agreement Dialog Component */}
            <SupplierAgreement
                data={legalContent?.supplierAgreement}
                parentState={open}
                closeParent={closeParent}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        legal_content: state.contentDetail.legal_data,
        homepage_content: state.contentDetail.homepage_content
    };
};

const mapProps = (dispatch) => ({
    fetchLegalContent: () => dispatch(fetchLegalContent()),
    fetchhomepageContent: () => dispatch(fetchhomepageContent())
});
Onboarding.propTypes = {
    legal_content: PropTypes.object,
    homepage_content: PropTypes.object,
    fetchLegalContent: PropTypes.func,
    fetchhomepageContent: PropTypes.func
};

export default connect(mapStateToProps, mapProps)(Onboarding);

import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const data = [
    {
        name: '',
        cs: 4000,
        eo: 2400,
        ed: 1500,
        amt: 2400
    },
    {
        name: '',
        cs: 3000,
        eo: 1398,
        ed: 1500,
        amt: 2210
    },
    {
        name: '',
        cs: 2000,
        eo: 9800,
        ed: 1500,
        amt: 2290
    },
    {
        name: '',
        cs: 2780,
        eo: 3908,
        ed: 1500,
        amt: 2000
    },
    {
        name: '',
        cs: 1890,
        eo: 4800,
        ed: 2000,
        amt: 2181
    },
    {
        name: '',
        cs: 2390,
        eo: 3800,
        ed: 1780,
        amt: 2500
    },
    {
        name: '',
        cs: 3490,
        eo: 4300,
        ed: 2300,
        amt: 2100
    }
];

export default class Example extends PureComponent {
    static jsfiddleUrl = 'https://jsfiddle.net/alidingling/xqjtetw0/';

    render() {
        return (
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="eo" stroke="#A2792C" activeDot={{ r: 8 }} />
                <Line type="monotone" dataKey="cs" stroke="#FF3D00" />
                <Line type="monotone" dataKey="ed" stroke="#4CDED5" />
            </LineChart>
        );
    }
}

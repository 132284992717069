/* eslint-disable no-case-declarations */
import { SET_AUTH_TOKEN, SET_PAYMENT_PROCESSING } from 'store/types';
import { INIT } from '../../utils/constants';

const initialState = {
    phase: INIT,
    auth: { token: null, authLoading: localStorage.getItem('token') ? true : false },
    path_enable: false,
    error: null,
    my_profile: null,
    email_exist: null,
    forgot_password: null,
    update_password: null,
    account_delete: null,
    toggle_toast: false,
    upcomingSubscription: {},
    paymentProcessing: false
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AUTH_TOKEN:
            state.auth.token = action.payload;
            state.auth.authLoading = false;
            return { ...state };

        case 'TOGGLE_TOAST':
            state.toggle_toast = action.payload;
            return { ...state };

        case 'SET_INITIAL_AUTH_NEW':
            localStorage.clear();
            state.phase = INIT;
            state.auth = null;
            state.error = null;
            state.email_exist = null;
            state.forgot_password = null;
            state.account_delete = null;
            state.my_profile = null;
            return { ...state };

        case 'USER_LOGIN':
            state.auth = action.payload;
            return { ...state };

        case 'CURRENT_USER':
            state.my_profile = {
                ...action.payload
                //  photo_url: null
            };
            return { ...state };

        case 'FORGOT_PASSWORD':
            state.forgot_password = action.payload;
            return { ...state };

        case 'UPDATE_PASSWORD':
            state.update_password = action.payload;
            return { ...state };

        case 'ACCOUNT_DELETE':
            state.account_delete = action.payload;
            return { ...state };

        case 'UPDATE_PROFILE':
            let storeProfileObject = {
                ...state,
                my_profile: {
                    ...state.my_profile,
                    photo_url: action.payload.photo_url,
                    first_name: action.payload.first_name,
                    last_name: action.payload.last_name,
                    email: action.payload.email,
                    nick_name: action.payload.nick_name,
                    govt_photo_url: action.payload.govt_photo_url,
                    is_account_verified: true
                    // photo_url: null
                }
            };
            return storeProfileObject;

        case 'UPCOMING_SUBSCRIPTION':
            return { ...state, upcomingSubscription: action.payload };
        case SET_PAYMENT_PROCESSING:
            return { ...state, paymentProcessing: action.payload };

        default:
            return state;
    }
};

export default authReducer;

import { callApi } from '../../utils/apiCaller';
export const LEGAL_CONTENT = 'LEGAL_CONTENT';

export const setSubmitState = (state) => ({
    type: 'SET_SUBMIT_STATE',
    payload: state
});

export const setApplicationField = (state) => ({
    type: 'SET_APPLICATION_FIELD',
    payload: state
});

export const saveApplicationData_ = (state) => ({
    type: 'SAVE_APPLICATION_DATA',
    payload: state
});

export const saveApplication = () => {
    return (dispatch, getState) => {
        let data = getState().application.application;
        if (data.main_service_provided !== 'other') {
            // if (data.hasOwnProperty('other_main_service')) {
            if (Object.prototype.hasOwnProperty.call(data, 'other_main_service')) {
                delete data.other_main_service;
            }
        }

        return callApi(`/vendor-apply`, 'POST', data).then((response) => {
            dispatch(saveApplicationData_(response));
            return response;
        });
    };
};

export const fetchLegalContent_ = (data) => {
    return {
        type: LEGAL_CONTENT,
        payload: data
    };
};

export const fetchLegalContent = () => {
    return (dispatch) => {
        callApi('/legal-content', 'GET').then((response) => {
            if (response.status == 200) {
                return dispatch(fetchLegalContent_(response));
            } else {
                //toast.error(response.data[0].message);
            }
        });
    };
};

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import Availability from '../availability';
import { Link } from 'react-router-dom';
import Back from '../../../../assets/images/back.svg';
import Check from '../../../../assets/images/checkbox.svg';
import './index.css';
import X from '../../../../assets/images/x.svg';
import Modal from 'react-modal';

//Custom mock data
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

export default function AvailabilitySync() {
    //Saving data in local storage
    var data = localStorage.getItem('sync');
    data = JSON.parse(data);

    //Usestate and Function to Open and Close Modal
    const [open, setOpen] = useState(false);
    const [sync, setSync] = useState(false);

    const openModal = () => {
        setOpen(true);
    };

    const closeModal = () => {
        setOpen(false);
    };

    const syncModal = () => {
        setSync(true);
    };

    const syncCloseModal = () => {
        setSync(false);
    };

    return (
        <>
            <Modal
                isOpen={open}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div>
                    <div className="d-flex justify-content-between ">
                        <h5 style={{ textAlign: 'left' }}>Where do i find my iCal link</h5>
                        <img
                            width="16px"
                            className="cursor-pointer"
                            onClick={closeModal}
                            src={X}
                            alt=""
                        />
                    </div>
                    <hr />
                    <p>You can find instructions on how to export your calendar in an </p>
                    <p>iCal file format in the help section of the other site where your </p>
                    <p>property is listed.</p>
                </div>
            </Modal>
            <Modal
                isOpen={sync}
                onRequestClose={syncCloseModal}
                style={customStyles}
                contentLabel="Example Modal">
                <div>
                    <div>
                        <p>
                            Having accurate availability on your calendar is crucial to avoid
                            overbookings{' '}
                        </p>
                        <p>
                            and manage your property. Copy the below iCal link and paste it to the
                            other{' '}
                        </p>
                        <p>
                            site's 'Import calendar' area. After finishing exporting, you will have
                            the{' '}
                        </p>
                        <p>
                            calendars connected to sync your availability across the two channels.
                        </p>
                    </div>
                    <div style={{ paddingTop: 40 }}>
                        <h6 style={{ fontWeight: 700, paddingBottom: 10 }}>
                            Your Booking.com calendar link
                        </h6>
                        <input
                            placeholder="Websitelink"
                            className="input-bar"
                            style={{ width: '100%' }}
                        />
                        <button
                            className="brown-archived-available align-self-center"
                            style={{ width: '100%', marginTop: 20 }}>
                            Copy link
                        </button>
                        <div className="d-flex align-items-start" style={{ paddingTop: 20 }}>
                            <img style={{ paddingRight: 10 }} src={Check} alt="" />
                            <div>
                                <p>
                                    Link copied to clipboard. Please go to the other channel and
                                    paste the{' '}
                                </p>
                                <p>link to complete your calendar connection.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            <Availability title={`Availability`}>
                <div style={{ marginTop: 30 }} className="white-bg-colors">
                    <div style={{ paddingTop: 20 }} className="d-flex justify-content-between">
                        <h6 style={{ fontWeight: 900 }}>
                            Sub-Category Details -{' '}
                            <span style={{ fontWeight: 200 }}>{data.subtitle}</span>
                        </h6>
                        <Link to="/availability-data">
                            <p style={{ fontWeight: 900, paddingRight: 30, color: '#333333' }}>
                                Back
                                <span style={{ paddingLeft: 7, fontWeight: 100 }}>
                                    <img src={Back} alt="" />
                                </span>
                            </p>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <input
                                placeholder="Website name"
                                className="input-bar"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="col-md-4">
                            <input
                                placeholder="Paste your iCal link"
                                className="input-bar"
                                style={{ width: '100%' }}
                            />
                            <p
                                onClick={openModal}
                                style={{ textAlign: 'right', color: '#A2792C', paddingTop: 10 }}
                                className="cursor-pointer">
                                Where do i find my iCal link?
                            </p>
                        </div>
                        <div className="col-md-3">
                            <div className="d-flex align-items-center" style={{ width: '100%' }}>
                                <button className="brown-archived-available align-self-center">
                                    Import
                                </button>
                                <p
                                    onClick={syncModal}
                                    style={{
                                        color: '877EF3',
                                        width: '100%',
                                        marginBottom: 0,
                                        paddingLeft: 20,
                                        cursor: 'pointer'
                                    }}>
                                    Export
                                </p>
                            </div>
                        </div>
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </Availability>
        </>
    );
}

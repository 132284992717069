const initialState = {
    calendar_data: null,
    archived_calendar_data: null,
    success_calendar: false,
    update_calendar_product: null,
    update_products_personal_info: null,
    existing_products: null,
    get_all_calendar_products: null,
    vendor_calendars: null,
    inclusionCategories: [],
    adventureCategories: [],
    occassionCategories: [],
    sightCategories: [],
    product_categories: [],
    product_in_day_categories: [],
    calendars_for_filter: null
};

const calendarReducer = (state = initialState, action) => {
    var storeCalendarObject;
    var storeCalendarLgObject;
    switch (action.type) {
        case 'GET_ALL_VENDOR_CALENDARS':
            state.vendor_calendars = state.vendor_calendars
                ? {
                      meta: action?.payload?.data?.meta,
                      data: [...state.vendor_calendars.data, ...action?.payload?.data?.data]
                  }
                : action?.payload?.data;
            return { ...state };
        case 'CALENDAR_DATA':
            state.calendar_data = action.payload?.data;
            return { ...state };
        case 'GET_CALENDAR_BY_UUID':
            state.calendar_data_uuid = action.payload?.data;
            return { ...state };
        case 'PRODUCT_CATEGORIES':
            state.product_categories = action.payload?.product_categories;
            return { ...state };
        case 'BUSINESS_DATA':
            state.business_data = action.payload;
            return { ...state };
        case 'UPDATE_BUSINESS_DATA':
            state.update_business_data = action.payload;
            return { ...state };
        case 'UPDATE_BUSINESS_DATA_LOADING':
            state.update_business_data_loading = action.payload;
            return { ...state };
        case 'CREATE_CALENDAR':
            state.create_calendar = action.payload?.data?.data;
            state.success_calendar = action.payload?.success;
            return { ...state };
        case 'CREATE_CALENDAR_ERROR':
            state.create_calendar = action.payload?.data;
            state.error_calendar = action.payload?.error;
            return { ...state };

        case 'UPDATE_PRODUCTS_PERSONAL_INFO':
            return {
                ...state,
                update_products_personal_info: action.payload?.data,
                create_addon_product: action.payload?.data
            };

        case 'UPDATE_SUCCESS_CALENDAR':
            state.success_calendar = action.payload;
            return { ...state };
        case 'UPDATE_ERROR_CALENDAR':
            state.success_calendar = action.payload;
            return { ...state };
        case 'RESET_CALENDAR_REDUCER':
            if (action.payload && Object.keys(action.payload).length > 0) {
                Object.keys(action.payload).forEach((key) => {
                    state[key] = null;
                });
            }
            return { ...state };
        case 'UPDATE_CALENDAR_TITLE':
            state.get_calendar_data = action.payload?.data;
            return { ...state };
        case 'GET_CALENDAR_BY_ID':
            if (
                action?.payload?.data?.id &&
                state?.get_calendar_data?.data?.id === action?.payload?.data?.id
            ) {
                return {
                    ...state,
                    get_calendar_data: {
                        ...action.payload,
                        data: { ...state.get_calendar_data.data, ...action.payload.data }
                    }
                };
            }
            return { ...state, get_calendar_data: action.payload };

        case 'GET_ALL_CALENDAR_PRODUCTS':
            state.get_all_calendar_products = action.payload;
            return { ...state };
        case 'UPDATE_CALENDAR_LENGTH':
            state.update_calendar_length = action.payload;
            storeCalendarObject = {
                ...state,
                update_calendar_length: {
                    ...state.update_calendar_length,
                    success: true
                },
                get_calendar_data: action.payload?.data
            };
            return storeCalendarObject;
        case 'UPDATE_CALENDAR_LG_SUCCESS':
            storeCalendarLgObject = {
                ...state,
                update_calendar_length: {
                    ...state.update_calendar_length,
                    success: false
                }
            };
            return storeCalendarLgObject;
        case 'GET_EXISTING_PRODUCTS':
            state.existing_products = action.payload;
            return { ...state };

        case 'GET_INCLUSION_CATEGORIES':
            state.inclusionCategories = action.payload;
            return { ...state };
        case 'GET_ADVENTURE_CATEGORIES':
            state.adventureCategories = action.payload;
            return { ...state };

        case 'GET_OCCASSION_CATEGORIES':
            state.occassionCategories = action.payload;
            return { ...state };
        case 'GET_SIGHT_CATEGORIES':
            state.sightCategories = action.payload;
            return { ...state };
        case 'ARCHIVED_CALENDAR_DATA':
            state.archived_calendar_data = action.payload;
            return { ...state };
        case 'PRODUCT_IN_DAY_CATEGORIES':
            state.product_in_day_categories = action.payload?.product_categories;
            return { ...state };
        case 'GET_CALENDAR_FOR_FILTER':
            state.calendars_for_filter = action.payload.data;
            return { ...state };

        default:
            return state;
    }
};

export default calendarReducer;

import React, { useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { Button } from 'antd';
import InfoIcon from '../../../../assets/images/info-white.svg';
import WarningIcon from '../../../../assets/images/warning-icon.svg';
import PropTypes from 'prop-types';
import { stopProduct } from 'store/actions/availabilityActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const StopModal = ({ isOpen, toggleModal, stopProduct, refreshList, openedRecord }) => {
    const [confirmationWindow, setConfirmationWindow] = useState(false);

    const toggleStopModal = () => {
        toggleModal();
        setConfirmationWindow(false);
    };

    const onClickStop = () => {
        let data = {
            id: openedRecord?.id
        };
        stopProduct(data)
            .then(() => {
                setConfirmationWindow(true);
                refreshList();
            })
            .catch((e) => console.error(e));
    };

    return (
        <Modal size="md" centered isOpen={isOpen} toggle={toggleStopModal}>
            <ModalBody className="text-center">
                <div className="d-flex flex-row note mb-4">
                    <div className="icon-container">
                        <img src={InfoIcon} alt="" className="p-3" />
                    </div>
                    <div style={{ fontSize: 11 }} className="p-2">
                        Stop selling the product to new customers and fulfill the orders of existing
                        custumers
                    </div>
                </div>
                <img src={WarningIcon} alt="" />
                <div className="modal-body-title-lg mt-2">
                    {confirmationWindow ? 'Stopped' : 'Stop?'}
                </div>
                <p className="modal-body-text">
                    {confirmationWindow ? (
                        <span>
                            This product will now stop selling immediately and is no longer on sale
                            for <b className="fs-18">new customers.</b>
                            <br />
                            However it will be{' '}
                            <b className="fs-18">available for existing customers.</b>
                            <br />
                            If you want to stop selling for existing customers as well please choose{' '}
                            <b className="fs-18">cancel</b> option.
                        </span>
                    ) : (
                        <span>
                            Are you sure you want to stop sales{' '}
                            <b className="fs-18">for new customers for this product?</b>
                        </span>
                    )}
                </p>
                <div className="mt-4">
                    {!confirmationWindow && (
                        <Button
                            id="pauseSaleTooltip"
                            type="primary"
                            className="btn-mw mx-2"
                            onClick={onClickStop}>
                            STOP
                        </Button>
                    )}
                    {confirmationWindow ? (
                        <Button
                            type="dark"
                            className="btn-mw mx-2"
                            onClick={() => toggleStopModal()}>
                            OK
                        </Button>
                    ) : (
                        <Button
                            type="dark"
                            className="btn-mw mx-2"
                            onClick={() => toggleStopModal()}>
                            NO
                        </Button>
                    )}
                </div>
            </ModalBody>
        </Modal>
    );
};
StopModal.propTypes = {
    isOpen: PropTypes.bool,
    toggleModal: PropTypes.func,
    refreshList: PropTypes.func,
    openedRecord: PropTypes.any,
    stopProduct: PropTypes.func
};
const mapDispatch = (dispatch) => ({
    stopProduct: (params) => dispatch(stopProduct(params))
});

export default withRouter(connect(null, mapDispatch)(StopModal));

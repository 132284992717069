import React from 'react';
import { Col, Row } from 'react-bootstrap';
import DetailedPanel from './panel';
import { TagIcon } from './panel/icons';

const AmountPanel = () => {
    return (
        <DetailedPanel
            panelIcon={<TagIcon />}
            panelTitle="Amount you will get (PP)"
            isEditable={true}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Adult</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">USD 50</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Infant</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">No Fee</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Child</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">USD 20</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Youth</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">USD 50</span>
                </Col>
            </Row>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Senior</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">USD 20</span>
                </Col>
            </Row>
        </DetailedPanel>
    );
};

export default AmountPanel;

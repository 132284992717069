import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import { CalendarSummaryDetailedPanel as DetailedPanel } from 'components/molecules';
import { Calendar } from 'react-feather';

const EventDays = (props) => {
    const { product, onEdit } = props;

    return (
        <DetailedPanel
            panelIcon={<Calendar color="#A3792D" />}
            panelTitle="Calendar Days"
            isEditable={true}
            onEdit={onEdit}>
            <Row className="border-bottom border-primary-shaded py-2" noGutters>
                <Col xs={6} md={4}>
                    <div className="d-flex flex-nowrap">
                        <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Available on</span>
                        <span className="fs-18 lh-1-7 mr-4">:</span>
                    </div>
                </Col>
                <Col xs={6} md={8}>
                    <span className="fs-18 lh-1-7">
                        {product?.calendar_days?.days
                            ? product?.calendar_days?.days.map(
                                  (item, index) =>
                                      `${item}${
                                          index < product?.calendar_days.days.length - 1 ? ', ' : ''
                                      }`
                              )
                            : ''}
                    </span>
                </Col>
            </Row>
            {product?.day_type !== 'within_day' ? (
                <></>
            ) : (
                <Row className="border-bottom border-primary-shaded py-2" noGutters>
                    <Col xs={6} md={4}>
                        <div className="d-flex flex-nowrap">
                            <span className="fs-18 lh-1-7 flex-grow-1 mr-2">Suggested Day</span>
                            <span className="fs-18 lh-1-7 mr-4">:</span>
                        </div>
                    </Col>
                    <Col xs={6} md={8}>
                        <span className="fs-18 lh-1-7">{product?.suggestedDay}</span>
                    </Col>
                </Row>
            )}
        </DetailedPanel>
    );
};

EventDays.propTypes = {
    product: PropTypes.any,
    onEdit: PropTypes.func
};

export default EventDays;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import './index.scss';

import { Checkbox, Form, Input, Radio, Row, Col } from 'antd';
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';
import HelpIcon from '../../../../../assets/images/helpIcon.svg';
import Spacer from '../../../../atoms/spacer';
import Prompter from '../../../../atoms/prompter';
import Close from '../../../../../assets/images/close.svg';
import PhonesCode from '../../../../../phonesCode.json';
import Proptypes from 'prop-types';
import MoreInfo from '../../../../../assets/images/more_info.svg';

const radioStyle = {
    display: 'flex'
};

export default function PhysicalRestriction(props) {
    let [state, setState] = useState({
        accessibility: [
            {
                name: 'Is it Wheelchair accessible?',
                value: false
            },
            {
                name: 'Is it stroller accessible?',
                value: false
            },
            {
                name: 'Are service animals allowed?',
                value: false,
                info: true
            },
            {
                name: 'Can travelers easily arrive/depart on public transportation?',
                value: false
            },
            {
                name: 'Are infants required to sit on laps?',
                value: false
            },
            {
                name: 'Are infant seats available?',
                value: false
            }
        ],
        accessibleAnother: [
            {
                name: 'Is all transportation wheelchair accessible?',
                value: false
            },
            {
                name: 'Are all surfaces wheelchair accessible (no uneven terrain, stairs, etc.)?',
                value: false
            }
        ],
        healthRestricitons: [
            {
                name: 'Not recommended for travelers with back problems',
                value: false
            },
            {
                name: 'Not recommended for pregnant travelers',
                value: false
            },
            {
                name: 'Not recommended for travelers with heart problems or other serious medical conditions',
                value: false
            }
        ]
    });
    const [isAnotherAccessibility, setIsAnotherAccessibility] = useState(false);
    const [isSelectCode, setIsSelectCode] = useState(false);
    const [selectCode, setSelectCode] = useState('');
    const [healthRestricitonsInput, setHealthRestricitonsInput] = useState('');
    const [accessibilityInput, setAccessibilityInput] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [difficulty, setDifficulty] = useState(1);

    useEffect(() => {
        if (props.data) onSetImportData(props.data);
    }, [props.data]);

    //set import data
    const onSetImportData = (item) => {
        const newState = { ...state };
        if (item) {
            newState.accessibility[0].value = item?.accessibility.wheelchair;
            newState.accessibility[1].value = item?.accessibility.stroller;
            newState.accessibility[2].value = item?.accessibility.service_animals;
            newState.accessibility[3].value = item?.accessibility.public_trans;
            newState.accessibility[4].value = item?.accessibility.infant_lapping;
            newState.accessibility[5].value = item?.accessibility.infant_seats;
            if (item?.health_restrictions && item.health_restrictions.length > 0) {
                newState.healthRestricitons.map((res) => {
                    res.value = item.health_restrictions.includes(res.name);
                });
            }
            setState(newState);
            setDifficulty(item?.difficulty_level);
            if (item.phone_number) {
                setSelectCode(item?.phone_number.substring(0, item?.phone_number.indexOf('-')));
                setPhoneNumber(item?.phone_number.substring(item?.phone_number.indexOf('-') + 1));
            } else {
                setSelectCode('');
                setPhoneNumber('');
            }
            props.onSave(item);
        }
    };

    const addNewHealthRestricition = () => {
        const newState = { ...state };

        newState.healthRestricitons.push({
            name: '',
            value: '',
            added: true
        });
        setState(newState);
    };
    const addNewAccessibility = () => {
        const newState = { ...state };

        newState.accessibility.push({
            name: '',
            value: '',
            added: true
        });
        setState(newState);
    };

    const removeHealthRestricition = (index) => {
        const newState = { ...state };

        newState.healthRestricitons.splice(index, 1);
        setState(newState);
        setHealthRestricitonsInput('');
    };
    const removeAccessibility = (index) => {
        const newState = { ...state };

        newState.accessibility.splice(index, 1);
        setState(newState);
        setAccessibilityInput('');
    };

    const setAccessibility = (index, e) => {
        const newState = { ...state };
        if (e.code === 'Enter' && accessibilityInput.trim() !== '') {
            newState.accessibility[index].name = accessibilityInput.trim();
            newState.accessibility[index].added = false;
            setState(newState);
            setAccessibilityInput('');
        }
    };
    const setHealthRestriction = (index, e) => {
        if (e.code === 'Enter' && healthRestricitonsInput.trim() !== '') {
            const tmp = state;
            tmp.healthRestricitons[index].name = healthRestricitonsInput.trim();
            tmp.healthRestricitons[index].added = false;
            setState({
                ...tmp
            });
            setHealthRestricitonsInput('');
        }
    };

    const changeAccessibility = (value, index) => {
        let newState = { ...state };
        state.accessibility[index].value = value;
        setState(newState);
    };
    const changeAccessibilityAnother = (value, index) => {
        let newState = { ...state };
        state.accessibleAnother[index].value = value;
        setState(newState);
    };
    const changeHealthRestricitons = (value, index) => {
        let newState = { ...state };
        state.healthRestricitons[index].value = value;
        setState(newState);
    };

    const togglePhone = () => setIsSelectCode(!isSelectCode);

    const onFinish = () => {
        let healthRestricitons = [];
        state.healthRestricitons.map((res) => {
            if (res.value) healthRestricitons.push(res.name);
        });
        const tmpInformation = {
            accessibility: {
                wheelchair: state.accessibility[0].value,
                stroller: state.accessibility[1].value,
                service_animals: state.accessibility[2].value,
                public_trans: state.accessibility[3].value,
                infant_lapping: state.accessibility[4].value,
                infant_seats: state.accessibility[5].value
            },
            health_restrictions: healthRestricitons.length > 0 ? healthRestricitons : [''],
            difficulty_level: difficulty
        };
        if (selectCode && phoneNumber) tmpInformation.phone_number = `${selectCode}-${phoneNumber}`;
        props.onSave(tmpInformation);
    };
    const onClose = () => props.onClose();

    return (
        <Modal isOpen={props.isOpen} centered size="lg" className="physical-restriction">
            <ModalHeader className="title">
                Add any physical restrictions for this Accomodation
                <Prompter
                    classNames="promter-information"
                    textColor="#A2792C"
                    text="I will import physical restriction information from my other activity"
                />
            </ModalHeader>
            <ModalBody className="body-modal">
                <p>This information will help travelers know if this is a good product for them.</p>
                <Form layout="vertical" className="">
                    <div className="physical-restriction">
                        <div className="">
                            <Row className="physical-restriction__row">
                                <Col span="21">
                                    <span>Accessibility</span>
                                </Col>
                                <Col className="physical-restricition__selection" span="3">
                                    <span>Yes</span>
                                    <span>No</span>
                                </Col>
                            </Row>
                            {state.accessibility.map((item, index) => (
                                <Row
                                    className="physical-restriction__row"
                                    style={{ fontSize: 14 }}
                                    key={index}>
                                    <Col span="21">
                                        {item.added ? (
                                            <Input
                                                suffix={
                                                    <img
                                                        height="12"
                                                        width="12"
                                                        src={Close}
                                                        onClick={() => removeAccessibility(index)}
                                                        alt=""
                                                    />
                                                }
                                                className="physical-restriction-input"
                                                value={accessibilityInput}
                                                onChange={(e) =>
                                                    setAccessibilityInput(e.target.value)
                                                }
                                                onKeyPress={(e) => setAccessibility(index, e)}
                                            />
                                        ) : isAnotherAccessibility && index === 0 ? (
                                            <div>
                                                <span
                                                    style={{ marginBottom: '15px' }}
                                                    className="accessible-name">
                                                    {item.name}
                                                </span>
                                                <span
                                                    style={{ marginBottom: '10px' }}
                                                    className="accessible-name">
                                                    {state.accessibleAnother[0].name}
                                                </span>
                                                <span className="accessible-name">
                                                    {state.accessibleAnother[1].name}
                                                </span>
                                            </div>
                                        ) : (
                                            <span className="accessible">
                                                {item.name}
                                                {item?.info ? <img src={MoreInfo} alt="" /> : null}
                                            </span>
                                        )}
                                    </Col>
                                    {isAnotherAccessibility && index === 0 && !item.added ? (
                                        <Col span="3">
                                            <Radio.Group
                                                value={state.accessibility[0].value}
                                                onChange={(e) => {
                                                    if (item === state.accessibility[0]) {
                                                        setIsAnotherAccessibility(e.target.value);
                                                    }
                                                    changeAccessibility(e.target.value, 0);
                                                }}>
                                                <Radio value={true} />
                                                <Radio value={false} />
                                            </Radio.Group>
                                            <Spacer top={10} />
                                            <Radio.Group
                                                value={state.accessibleAnother[0].value}
                                                onChange={(e) => {
                                                    changeAccessibilityAnother(e.target.value, 0);
                                                }}>
                                                <Radio value={true} />
                                                <Radio value={false} />
                                            </Radio.Group>
                                            <Spacer top={10} />
                                            <Radio.Group
                                                value={state.accessibleAnother[1].value}
                                                onChange={(e) => {
                                                    changeAccessibilityAnother(e.target.value, 1);
                                                }}>
                                                <Radio value={true} />
                                                <Radio value={false} />
                                            </Radio.Group>
                                        </Col>
                                    ) : !item.added ? (
                                        <Col span="3">
                                            <Radio.Group
                                                value={item.value}
                                                onChange={(e) => {
                                                    if (item === state.accessibility[0]) {
                                                        setIsAnotherAccessibility(e.target.value);
                                                    }
                                                    changeAccessibility(e.target.value, index);
                                                }}>
                                                <Radio value={true} />
                                                <Radio value={false} />
                                            </Radio.Group>
                                        </Col>
                                    ) : null}
                                </Row>
                            ))}
                            <Spacer top={15} />
                            <Prompter
                                onClick={addNewAccessibility}
                                textColor="#A2792C"
                                text="Add another"
                            />
                        </div>
                        <Spacer top={50} />
                        <div className="health-restricitons">
                            <h6>Health restrictions</h6>
                            <p>Check all that apply</p>
                            {state.healthRestricitons.map((item, index) => (
                                <p key={item.name + index} style={{ marginBottom: '10px' }}>
                                    {item.added ? (
                                        <Input
                                            suffix={
                                                <img
                                                    height="12"
                                                    width="12"
                                                    alt=""
                                                    src={Close}
                                                    onClick={() => removeHealthRestricition(index)}
                                                />
                                            }
                                            style={{
                                                width: 420,
                                                border: '0.5px solid #A2792C',
                                                boxSizing: 'border-box',
                                                borderRadius: '8px'
                                            }}
                                            onChange={(e) =>
                                                setHealthRestricitonsInput(e.target.value)
                                            }
                                            value={healthRestricitonsInput}
                                            onKeyPress={(e) => setHealthRestriction(index, e)}
                                        />
                                    ) : (
                                        <Checkbox
                                            checked={item.value}
                                            onChange={(e) =>
                                                changeHealthRestricitons(e.target.checked, index)
                                            }
                                            style={{ display: 'flex', alignItems: 'center' }}>
                                            {item.name}
                                        </Checkbox>
                                    )}
                                </p>
                            ))}
                            <Prompter
                                onClick={addNewHealthRestricition}
                                textColor="#A2792C"
                                text="Add another"
                            />
                        </div>
                        <Spacer top={36} />
                        <div className="difficulty">
                            <h6 style={{ fontWeight: 600 }}>
                                Select the physical difficulty level
                            </h6>
                            <Radio.Group
                                value={difficulty}
                                onChange={(value) => setDifficulty(value.target.value)}>
                                <Radio style={radioStyle} value={1}>
                                    <h6 style={{ marginTop: 3 }}>Easy</h6>
                                    <p>Most travelers can participate</p>
                                </Radio>
                                <Radio style={radioStyle} value={2}>
                                    <h6 style={{ marginTop: 3 }}>Moderate</h6>
                                    <p>Travelers should have a moderate physical fitness level</p>
                                </Radio>
                                <Radio style={radioStyle} value={3}>
                                    <h6 style={{ marginTop: 3 }}>Challenging</h6>
                                    <p>Travelers should have a moderate physical fitness level</p>
                                </Radio>
                            </Radio.Group>
                        </div>
                        <Spacer top={36} />
                        <div>
                            <h6>Your phone number</h6>
                            <p>
                                This is the number travelers will call if they need to reach you on
                                the day of the travel
                            </p>
                            <div style={{ display: 'flex' }}>
                                <Dropdown isOpen={isSelectCode} toggle={togglePhone}>
                                    <DropdownToggle className="dropdown-category" caret>
                                        {selectCode === '' ? '' : selectCode}
                                    </DropdownToggle>
                                    <DropdownMenu
                                        style={{ width: '300px', maxHeight: '200px' }}
                                        className="category-menu">
                                        {PhonesCode.phones && PhonesCode.phones.length > 0
                                            ? PhonesCode.phones.map((res, i) => (
                                                  <DropdownItem
                                                      onClick={() => setSelectCode(res.dial_code)}
                                                      style={{
                                                          backgroundColor:
                                                              res.dial_code === selectCode
                                                                  ? '#E7D99F'
                                                                  : '#fff'
                                                      }}
                                                      key={i}
                                                      className="category-item">
                                                      {res.dial_code + ' ' + res.name}
                                                  </DropdownItem>
                                              ))
                                            : null}
                                    </DropdownMenu>
                                </Dropdown>
                                <Input
                                    type="phone"
                                    className="input-phone"
                                    value={phoneNumber}
                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </ModalBody>
            <ModalFooter>
                <div className="footer-btn">
                    <div>
                        <Button color="primary" onClick={onFinish}>
                            Update Calender & Continue
                        </Button>
                        <Button color="dark" style={{ marginLeft: '8px' }} onClick={onClose}>
                            Cancel
                        </Button>
                    </div>
                    <Button color="dark" style={{ width: '137px' }}>
                        <img src={HelpIcon} alt="" /> Help
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}
PhysicalRestriction.propTypes = {
    onSave: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    data: Proptypes.object
};

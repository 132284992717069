/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

export default function VideoModal(props) {
    const { showModal, setShowModal, src, uploadSelectedVideo } = props;
    const [videoAtt, setVideoAtt] = useState({});
    const [gridAtt, setGridAtt] = useState({ width: 0, height: 0 });
    const [gridVisibility, setGridVisibility] = useState(true);

    function getGridAttribute() {
        let width = document.getElementById('video-preview-cont').offsetWidth;
        let height = document.getElementById('video-preview-cont').offsetHeight;
        setGridAtt({ width, height });
    }

    function changeGridVisibilty() {
        setGridVisibility(false);
        setTimeout(() => setGridVisibility(true), 2000);
    }

    return (
        <Modal
            zIndex={9999}
            isOpen={showModal}
            toggle={() => setShowModal((bool) => !bool)}
            className="modal-black video-preview-modal modal-dialog-centered">
            <ModalBody>
                <div id="video-preview-cont" className="video-preview-holder">
                    <video
                        onLoadedMetadata={(e) => {
                            setVideoAtt({
                                videoHeight: e.target.videoHeight,
                                videoWidth: e.target.videoWidth,
                                duration: e.target.duration
                            });
                            getGridAttribute();
                        }}
                        // width="100%"
                        controls
                        autoPlay
                        src={src}
                        // style={{ maxHeight: 'calc(100vh - 300px)' }}
                    >
                        {' '}
                        <track default kind="captions" srcLang="en" src={src} />
                    </video>

                    {gridVisibility && (
                        <div
                            onClick={changeGridVisibilty}
                            className="ReactCrop__crop-selection"
                            style={{
                                cursor: 'default',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width:
                                    gridAtt?.width > gridAtt?.height
                                        ? gridAtt?.height * (637 / 602)
                                        : gridAtt?.width,
                                height:
                                    gridAtt?.width > gridAtt?.height
                                        ? gridAtt?.height
                                        : gridAtt?.width * (602 / 637)
                            }}>
                            <div className="ReactCrop__rule-of-thirds-hz"></div>
                            <div className="ReactCrop__rule-of-thirds-vt"></div>
                        </div>
                    )}
                </div>
            </ModalBody>
            <ModalFooter className="justify-content-center">
                {/* {(videoAtt?.videoHeight < 720 || videoAtt.videoWidth < 1280) && (
                    <div className="text-danger text-center w-100 mb-3">{`For better user experience please choose a video with high resolution ( > 720H-1280W )`}</div>
                )} */}
                {videoAtt?.duration > 180 && (
                    <div className="text-danger text-center w-100 mb-3">{`Please select a video less than 3 minutes.`}</div>
                )}
                <div className="d-flex justify-content-center">
                    <Button
                        disabled={videoAtt && videoAtt?.duration > 180}
                        color="primary"
                        onClick={() => {
                            uploadSelectedVideo();
                            setShowModal(false);
                        }}
                        className="px-5 mr-2">
                        SAVE
                    </Button>
                    <Button
                        color="dark"
                        onClick={() => setShowModal((bool) => !bool)}
                        className="ml-1 px-5">
                        CANCEL
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
    );
}

VideoModal.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    src: PropTypes.string,
    uploadSelectedVideo: PropTypes.func
};

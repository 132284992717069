import React from 'react';
import PropTypes from 'prop-types';
import './index.css';

export default function ReviewCard(props) {
    return (
        <div className="review-shadow " style={{ width: props.width }}>
            <div className="d-flex justify-content-between" style={{ paddingBottom: 28 }}>
                <p style={{ marginBottom: 0, fontSize: 17, fontWeight: '800', marginRight: 30 }}>
                    {props.name}
                </p>
                <img width="25px" style={{ marginLeft: 30 }} src={props.google} alt="" />
            </div>
            <div className="d-flex align-items-center">
                <button className="button-review">Connect</button>
            </div>
        </div>
    );
}
ReviewCard.propTypes = {
    width: PropTypes.string,
    name: PropTypes.string,
    google: PropTypes.string
};

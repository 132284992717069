/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import './index.scss';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import HelpIcon from '../../../../assets/images/helpIcon.svg';
import ImportItineraries from '../../../atoms/importItineraries';
import GoogleAutoComplete from '../../../atoms/google-autocomplete';
import { Button as AntButton, Checkbox, Col, Form, Input, Radio } from 'antd';
import Gallery from '../../gallery';
import InclusionAndExclusion from '../inclusion-exclusion';
import CalendarSeason from '../calendar-season';
import AddAdventure from '../add-adventure';
import AddTopSights from '../add-top-sights';
import AddInformation from '../add-information';
import TimeBlock from '../../../atoms/time-block';
import AddButton from '../../../atoms/add-button';
import AddInformationButton from '../../../atoms/add-information';
import AddAdventureButton from '../../../atoms/add-adventures';
import AddTopSightsButton from '../../../atoms/add-top-sights';
import AddLanguages from '../../../atoms/add-languages';
import AddInclusionExclusion from '../../../atoms/add-inclusion-exclusion';
import AddSeason from '../../../atoms/add-seasons';
import GoogleMap from '../../../atoms/google-map-itinerary';
import moment from 'moment';
import { PlusCircleOutlined } from '@ant-design/icons';
import {
    clearUploadedFile,
    fileUpload,
    getFiles,
    createItineraries,
    updateItineraries,
    createItineraries_,
    editItineraries_,
    getItineraries
} from '../../../../store/actions/contentActions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Plus from '../../../../assets/images/plus.svg';
import DeleteIcon from '../../../../assets/images/close.svg';
import MealsDrinks from '../meals-drinks';
import LanguageOffered from '../language-offered';
import Spacer from '../../../atoms/spacer';
import Proptypes from 'prop-types';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '19px'
};

class ModalComponent extends React.Component {
    formRef = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            describe: '',
            media: null,
            isOpenGallery: false,
            isOpenInclusionExclusion: false,
            openMealsAndDrinks: false,
            isOpenCalendarSeason: false,
            isOpenAddAdventure: false,
            isOpenAddTopSights: false,
            isOpenAddInformation: false,
            languageOffered: false,
            languageGuide: null,
            mealsAndDrinks: [],
            mealsAndDrinksEdit: null,
            adventure: null,
            sights: null,
            isCheckBox: 1,
            titleGallery: '',
            languages: [],
            inclusion: [],
            exclusion: [],
            start: null,
            finish: null,
            admission_with_price: 3,
            calendar_season: null,
            calendar_season_type: null,
            adventureCategory: null,
            destination: '',
            information: null,
            address: null,
            departure_time: '',
            departure_pickup_duration: 0,
            anotherStart: [''],
            anotherStartForMap: [''],
            atAStartPointLocations: [],
            page: 1
        };
    }
    componentDidUpdate(prevProps) {
        if (
            this.props?.edit_itineraries &&
            prevProps.edit_itineraries !== this.props.edit_itineraries
        ) {
            const item = this.props.edit_itineraries;
            setTimeout(() => {
                this.onSetMainProduct(item);
            }, 1);
            this.setState({ page: 1 });
        }
    }

    //set import data
    onSetMainProduct = (item) => {
        this.setState({
            title: item?.title,
            describe: item?.description,
            media: item?.photos_and_videos,
            mealsAndDrinks: item?.meals_and_drinks || [],
            languages: item?.language_offered?.languages || [],
            inclusion: item?.inclusions || [],
            exclusion: item?.exclusions || [],
            adventureCategory: item?.adventures,
            adventure: item?.adventures,
            sights: item?.sights,
            start: item?.start_time,
            finish: item?.finish_time,
            admission_with_price: item?.admission_with_price,
            information: item?.traveller_information,
            calendar_season: item?.calendar_season || null,
            departure_time: item?.departure_time,
            departure_pickup_duration: item?.departure_pickup_duration,
            destination: `${item?.address}, ${item?.country}, ${item?.city}`,
            address: {
                address: item?.address,
                city: item?.city,
                country: item?.country
            },
            anotherStart: item?.meetup_points || [''],
            anotherStartForMap: item?.meetup_points || ['']
        });
        if (this.formRef.current) {
            this.formRef.current.setFieldsValue({
                title: item?.title,
                describe: item?.description,
                startTime: item?.start_time,
                endTime: item?.finish_time,
                media: item?.photos_and_videos,
                destination: `${item?.address}, ${item?.country}, ${item?.city}`
            });
        }
        this.setState({ page: 1 });
    };

    //uploading media file
    onChange = (e) => {
        let file = e;
        let form_data = new FormData();
        form_data.append('media', file);
        this.props.fileUpload(form_data);
    };
    //save media
    onSaveMedia = (value) => {
        this.formRef.current.setFieldsValue({
            media: value
        });
        this.setState({ media: value, isOpenGallery: false });
    };
    //remove item in media
    onDeleteMedia = (index) => {
        const { media } = this.state;
        let tmp = media;
        tmp.splice(index, 1);
        this.formRef.current.setFieldsValue({
            media: tmp
        });
        this.setState({ media: tmp });
    };

    onOpenGallery = () => this.setState({ isOpenGallery: !this.state.isOpenGallery });
    onOpenMealsAndDrinks = () =>
        this.setState({ openMealsAndDrinks: !this.state.openMealsAndDrinks });
    onOpenLanguageOffered = () => this.setState({ languageOffered: !this.state.languageOffered });
    onOpenCalendarSeason = () =>
        this.setState({ isOpenCalendarSeason: !this.state.isOpenCalendarSeason });
    onOpenAddAdventure = () =>
        this.setState({ isOpenAddAdventure: !this.state.isOpenAddAdventure });
    onOpenAddTopSights = () =>
        this.setState({ isOpenAddTopSights: !this.state.isOpenAddTopSights });
    onOpenAddInformation = () =>
        this.setState({ isOpenAddInformation: !this.state.isOpenAddInformation });
    onClose = () => {
        this.onClear();
        this.props.onClose();
    };
    //set inclusions and exclusions
    addInclusionExclusion(inclusionExclusion) {
        this.setState({
            inclusion: inclusionExclusion.inclusions,
            exclusion: inclusionExclusion.exclusions,
            isOpenInclusionExclusion: false
        });
    }
    //set meals and drinks
    addMealsAndDrinks = (mealsAndDrinks) =>
        this.setState({ mealsAndDrinks: mealsAndDrinks, mealsAndDrinksEdit: null });
    //remove meals and drinks
    deleteMealsAndDrinks = (index) => {
        const { mealsAndDrinks } = this.state;
        const tmp = mealsAndDrinks;
        tmp.splice(index, 1);
        this.setState({ mealsAndDrinks: [...tmp] });
    };
    //remove languages
    deleteLanguages = (index) => {
        const { languages } = this.state;
        const tmp = languages;
        tmp.splice(index, 1);
        this.setState({ languages: [...tmp] });
    };
    onCloseInclusionExclusion = () =>
        this.setState({ isOpenInclusionExclusion: !this.state.isOpenInclusionExclusion });
    onChangePage = (perPage) => {
        if (perPage === 2) {
            this.setState({ page: 2 });
        } else if (perPage === 1) {
            this.setState({ page: 1 });
        }
    };
    onAddAnotherStart = () => {
        const tmp = this.state.anotherStart;
        const tmpForMap = this.state.anotherStartForMap;
        tmp.push('');
        tmpForMap.push('');
        this.setState({ anotherStart: [...tmp], anotherStartForMap: tmpForMap });
    };
    setAddress = (value, address) => {
        this.setState({
            destination: value.description,
            address: address
        });
    };
    onDeleteAnotherStart = (index) => {
        const tmpData = [...this.state.anotherStart];
        const tmpDataForMap = [...this.state.anotherStartForMap];
        tmpData.splice(index, 1);
        tmpDataForMap.splice(index, 1);
        this.setState({ anotherStart: tmpData, anotherStartForMap: tmpDataForMap });
    };

    onClear = () => {
        this.setState({
            mealsAndDrinks: [],
            mealsAndDrinksEdit: null,
            adventure: null,
            sights: null,
            isCheckBox: 0,
            titleGallery: '',
            languages: [],
            inclusion: [],
            exclusion: [],
            start: null,
            finish: null,
            admission_with_price: 3,
            calendar_season: null,
            calendar_season_type: null,
            adventureCategory: null,
            destination: '',
            information: null,
            media: null,
            address: null,
            departure_time: '',
            departure_pickup_duration: null,
            title: '',
            describe: '',
            anotherStart: [],
            page: 1
        });
        this.props.createItineraries_(null);
        this.props.editItineraries_(null);
    };

    onFinish = async () => {
        const {
            page,
            title,
            describe,
            address,
            start,
            finish,
            media,
            mealsAndDrinks,
            adventureCategory,
            sights,
            languages,
            languageGuide,
            inclusion,
            exclusion,
            admission_with_price,
            calendar_season,
            departure_time,
            departure_pickup_duration,
            information,
            anotherStart
        } = this.state;
        const {
            calendarDay,
            createItineraries,
            updateItineraries,
            create_itineraries,
            edit_itineraries,
            calendar_data,
            getItineraries
        } = this.props;
        let mainProductId = null;
        if (calendar_data && calendar_data.data) {
            const selectCalendar = window.location.pathname.substring(
                window.location.pathname.lastIndexOf('/') + 1
            );
            let tmp = calendar_data.data.find((res) => parseInt(selectCalendar) === res.id);
            if (tmp?.mainProduct) mainProductId = tmp.mainProduct.id;
        }
        if (page === 1) {
            const tmpMedia = [];
            media.map((res) => {
                tmpMedia.push({ type: res.type, url: res.url });
            });
            const createItinerary = {
                main_product_id: mainProductId,
                calendar_day: calendarDay,
                title: title,
                description: describe,
                address: address.address,
                city: address.city,
                country: address.country,
                start_time: start,
                finish_time: finish,
                photos_and_videos: tmpMedia
            };
            if (create_itineraries) updateItineraries(create_itineraries.id, createItinerary);
            else if (edit_itineraries) updateItineraries(edit_itineraries.id, createItinerary);
            else createItineraries(createItinerary);
            this.setState({ page: 2 });
        } else {
            const updateItinerary = {
                calendar_day: calendarDay,
                admission_with_price: admission_with_price,
                meals_and_drinks: mealsAndDrinks,
                language_offered: {
                    languages: languages,
                    guide_is_certified: languageGuide?.guide_is_certified || null,
                    guide_as_driver: languageGuide?.guide_as_driver || null
                },
                inclusions: inclusion,
                exclusions: exclusion,
                calendar_season: calendar_season,
                adventures: adventureCategory,
                sights: sights,
                traveller_information: information,
                departure_time: departure_time,
                departure_pickup_duration: departure_pickup_duration,
                meetup_points: anotherStart
            };
            if (create_itineraries) await updateItineraries(create_itineraries.id, updateItinerary);
            else if (edit_itineraries)
                await updateItineraries(edit_itineraries.id, updateItinerary);
            await getItineraries();
            this.onClear();
            this.onClose();
        }
    };

    render() {
        const {
            title,
            describe,
            isOpenGallery,
            media,
            isOpenInclusionExclusion,
            inclusion,
            exclusion,
            openMealsAndDrinks,
            languageOffered,
            languages,
            mealsAndDrinks,
            mealsAndDrinksEdit,
            isOpenCalendarSeason,
            isOpenAddAdventure,
            isOpenAddTopSights,
            isOpenAddInformation,
            page,
            titleGallery,
            isCheckBox,
            start,
            finish,
            admission_with_price,
            calendar_season,
            calendar_season_type,
            adventure,
            sights,
            adventureCategory,
            destination,
            information,
            departure_time,
            departure_pickup_duration,
            anotherStart,
            anotherStartForMap
        } = this.state;
        return (
            <Modal isOpen={this.props.isOpen} centered size="lg">
                <Form ref={this.formRef} name="itinerary-ref" onFinish={this.onFinish}>
                    <ModalHeader>
                        <Row className="header">
                            Add an Itinerary
                            <div className="pages unselectable">
                                {page === 2 ? (
                                    <Button onClick={() => this.onChangePage(1)}>Back</Button>
                                ) : null}
                                {`Page ${page} of 2`}
                            </div>
                        </Row>
                        <div className="header-import">
                            <ImportItineraries
                                calendars={this.props.itineraries}
                                import="I will import Itinerary information from my other activity "
                                onMainProduct={(item) => this.onSetMainProduct(item)}
                            />
                        </div>
                    </ModalHeader>
                    {page === 1 ? (
                        <ModalBody
                            className="pt-0"
                            style={{
                                maxHeight: '55vh',
                                overflowY: 'auto'
                            }}>
                            <Form.Item
                                name="title"
                                rules={[{ required: true, message: 'Please fill this field in' }]}>
                                <div className="input-title">Add Itinerary title</div>
                                <Input
                                    style={{
                                        border: '0.5px solid #A2792C',
                                        height: 50,
                                        borderRadius: 8
                                    }}
                                    value={title}
                                    placeholder="Kruger National Park Safari, South Africa"
                                    onChange={(e) => {
                                        this.formRef.current.setFieldsValue({
                                            title: e.target.value
                                        });
                                        this.setState({ title: e.target.value });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="describe"
                                rules={[{ required: true, message: 'Please fill this field in' }]}>
                                <Spacer top={10} />
                                <div
                                    className="input-title"
                                    style={{ marginLeft: '2px', marginBottom: '8px' }}>
                                    Describe what travelers will see and do here if they book your
                                    experience
                                </div>
                                <Input.TextArea
                                    style={{
                                        height: '139px',
                                        padding: '17px 51px 10px 25px'
                                    }}
                                    className="input-area"
                                    value={describe}
                                    placeholder='Enter a description about this Itinerary only.  For example, "Our accredited
                                guide will meet you at the ticket booth and will accompany you up the tower, explaining what
                                 you"ll see at each level." Description is required.'
                                    onChange={(e) => {
                                        this.formRef.current.setFieldsValue({
                                            describe: e.target.value
                                        });
                                        this.setState({ describe: e.target.value });
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="destination"
                                rules={[{ required: true, message: 'Please fill this field in' }]}>
                                <div className="input-title">Select main destination</div>
                                <GoogleAutoComplete
                                    placeholder="Kruger National Park Safari, South Africa"
                                    defValue={destination}
                                    onSelectDistance={(value, address) => {
                                        this.formRef.current.setFieldsValue({
                                            destination: value
                                        });
                                        this.setAddress(value, address);
                                    }}
                                />
                            </Form.Item>

                            <Form.Item
                                name="media"
                                rules={[
                                    {
                                        validator: async (_, media) => {
                                            if (!media)
                                                return Promise.reject(
                                                    new Error('Please select photo and/or video')
                                                );
                                            else if (media.length < 6)
                                                return Promise.reject(
                                                    new Error('You need to add at least 6 items')
                                                );
                                        }
                                    }
                                ]}>
                                <h6 className="input-title">Add Photos & Videos</h6>
                                <p className="input-description">
                                    Use photos that highlight what’s unique about your experience
                                    and show travelers having fun. You need to add at least 6 items,
                                    it will help you to increase your bookings.
                                </p>
                                <Gallery
                                    showModal={isOpenGallery}
                                    media={media}
                                    onClose={this.onOpenGallery}
                                    title={titleGallery}
                                    onSaveMedia={(value) => this.onSaveMedia(value)}
                                />
                                <div className="d-flex flex-wrap gap-3 mb-3">
                                    <AntButton
                                        className="dash-btn"
                                        id="addPhotos"
                                        type="dashed"
                                        onClick={() => {
                                            this.onOpenGallery();
                                            this.setState({ titleGallery: 'My Images' });
                                        }}>
                                        <img
                                            className="cursor-pointer img-plus"
                                            src={Plus}
                                            alt=""
                                        />{' '}
                                        Add Photos
                                    </AntButton>
                                    <AntButton
                                        className="dash-btn"
                                        id="addVideos"
                                        type="dashed"
                                        onClick={() => {
                                            this.onOpenGallery();
                                            this.setState({ titleGallery: 'My Videos' });
                                        }}>
                                        <img
                                            className="cursor-pointer img-plus"
                                            src={Plus}
                                            alt=""
                                        />{' '}
                                        Add Videos
                                    </AntButton>
                                </div>
                                <Row>
                                    {media && media.length > 0
                                        ? media.map((res, i) => (
                                              <div key={i} className="select-media">
                                                  <img src={res.url} alt="" />
                                                  <div className="delete-block">
                                                      <img
                                                          src={DeleteIcon}
                                                          className="delete-icon cursor-pointer"
                                                          alt=""
                                                          onClick={() => this.onDeleteMedia(i)}
                                                      />
                                                  </div>
                                              </div>
                                          ))
                                        : null}
                                </Row>
                            </Form.Item>

                            <h6 className="input-title">
                                What's the duration of your Activity from start to finish?
                            </h6>
                            <Form.Item
                                style={{ marginBottom: 0 }}
                                name="startTime"
                                rules={[{ required: true, message: 'Please select time' }]}>
                                <TimeBlock
                                    data={start}
                                    onTime={(e) => {
                                        this.formRef.current.setFieldsValue({ startTime: e });
                                        this.setState({ start: e });
                                    }}
                                    showAmPm={false}
                                />
                            </Form.Item>
                            <Form.Item
                                name="endTime"
                                rules={[{ required: true, message: 'Please select time' }]}>
                                <TimeBlock
                                    data={finish}
                                    onTime={(e) => {
                                        this.formRef.current.setFieldsValue({ endTime: e });
                                        this.setState({ finish: e });
                                    }}
                                    showAmPm={false}
                                />
                            </Form.Item>
                        </ModalBody>
                    ) : (
                        <ModalBody
                            className="pt-0"
                            style={{
                                maxHeight: '55vh',
                                overflowY: 'auto'
                            }}>
                            <Form.Item name="admission">
                                <div className="input-title">
                                    Is admission to this place included in the price of your
                                    activity?
                                </div>
                                <Radio.Group
                                    value={admission_with_price}
                                    onChange={(value) => {
                                        this.setState({ admission_with_price: value.target.value });
                                    }}>
                                    <Radio style={radioStyle} value={1}>
                                        Yes
                                    </Radio>
                                    <Radio style={radioStyle} value={2}>
                                        No
                                    </Radio>
                                    <Radio style={radioStyle} value={3}>
                                        N/A
                                    </Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item>
                                <AddButton
                                    name="Meals & Drinks"
                                    data={mealsAndDrinks}
                                    onEdit={(index) => {
                                        this.setState({
                                            mealsAndDrinksEdit: index,
                                            openMealsAndDrinks: true
                                        });
                                    }}
                                    onDelete={(index) => this.deleteMealsAndDrinks(index)}
                                    onClick={this.onOpenMealsAndDrinks}
                                />
                                <AddLanguages
                                    name="Language Offered"
                                    data={languages}
                                    onEdit={this.onOpenLanguageOffered}
                                    onDelete={(index) => this.deleteLanguages(index)}
                                    onClick={this.onOpenLanguageOffered}
                                />
                                <AddInclusionExclusion
                                    inclusion={inclusion}
                                    exclusion={exclusion}
                                    onEdit={this.onCloseInclusionExclusion}
                                    name="Inclusion & Exclusion"
                                    onClick={this.onCloseInclusionExclusion}
                                />
                            </Form.Item>

                            <InclusionAndExclusion
                                data={[inclusion, exclusion]}
                                itineraries={this.props.itineraries}
                                onClose={this.onCloseInclusionExclusion}
                                showModal={isOpenInclusionExclusion}
                                onSave={(value) => this.addInclusionExclusion(value)}
                            />

                            <MealsDrinks
                                isOpen={openMealsAndDrinks}
                                allMealsAndDrinks={mealsAndDrinks}
                                onEdit={mealsAndDrinksEdit}
                                itineraries={this.props.itineraries}
                                onClose={() => {
                                    this.onOpenMealsAndDrinks();
                                    this.setState({ mealsAndDrinksEdit: null });
                                }}
                                onSave={(value) => this.addMealsAndDrinks(value)}
                            />
                            <LanguageOffered
                                isOpen={languageOffered}
                                selectedLanguages={languages}
                                onSave={(value, guide) =>
                                    this.setState({ languages: value, languageGuide: guide })
                                }
                                onClose={this.onOpenLanguageOffered}
                            />
                            <CalendarSeason
                                itineraries={this.props.itineraries}
                                data={calendar_season}
                                onSave={(value) =>
                                    this.setState({
                                        calendar_season: value,
                                        isOpenCalendarSeason: false
                                    })
                                }
                                onSaveType={(value) =>
                                    this.setState({ calendar_season_type: value })
                                }
                                isOpen={isOpenCalendarSeason}
                                onClose={this.onOpenCalendarSeason}
                            />
                            <AddAdventure
                                itineraries={this.props.itineraries}
                                data={adventure}
                                isOpen={isOpenAddAdventure}
                                onClose={this.onOpenAddAdventure}
                                onSave={(value) => this.setState({ adventureCategory: value })}
                            />
                            <AddTopSights
                                itineraries={this.props.itineraries}
                                data={sights}
                                onSave={(value) =>
                                    this.setState({
                                        sights: value,
                                        isOpenAddTopSights: false
                                    })
                                }
                                isOpen={isOpenAddTopSights}
                                onClose={this.onOpenAddTopSights}
                            />
                            <AddInformation
                                isOpen={isOpenAddInformation}
                                onClose={this.onOpenAddInformation}
                                data={information}
                                itineraries={this.props.itineraries}
                                onSave={(value) => this.setState({ information: value })}
                            />

                            <hr style={{ marginBottom: '25px' }} />

                            <Form.Item className="checkbox-block">
                                <p className="label input-title">
                                    Now tell us where you meet travelers for this market guide?
                                </p>
                                <div className="mb-12px">
                                    <Checkbox
                                        checked={isCheckBox === 1}
                                        onChange={() => this.setState({ isCheckBox: 1 })}>
                                        At the accomodation provided by you
                                    </Checkbox>
                                </div>
                                <div className="mb-12px">
                                    <Checkbox
                                        checked={isCheckBox === 2}
                                        onChange={() => this.setState({ isCheckBox: 2 })}>
                                        Allow travellers to enter their their own pick up location
                                        <div className="fs-14">
                                            Do not select this, if you only pick up from the your
                                            accomodation or the starting point you identified.
                                        </div>
                                    </Checkbox>
                                </div>
                                <div className="mb-12px">
                                    <Checkbox
                                        checked={isCheckBox === 3}
                                        onChange={() => this.setState({ isCheckBox: 3 })}>
                                        At a Starting Point
                                    </Checkbox>
                                </div>
                                {isCheckBox === 3 ? (
                                    <div>
                                        <div style={{ fontWeight: 500 }}>
                                            Search for point of interest or address
                                        </div>
                                        <Row style={{ margin: 0 }}>
                                            <Col sm={23}>
                                                <GoogleAutoComplete
                                                    defValue={anotherStart[0]}
                                                    onSelectDistance={(value) => {
                                                        const tmp = anotherStart;
                                                        tmp.splice(0, 1, value.description);
                                                        this.setState({
                                                            anotherStart: [...tmp],
                                                            anotherStartForMap: [...tmp]
                                                        });
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                className="delete-block-autocomplete"
                                                style={{ display: 'flex' }}
                                                sm={1}>
                                                <img
                                                    src={DeleteIcon}
                                                    className="delete-icon cursor-pointer"
                                                    onClick={() => {
                                                        const tmp = anotherStart;
                                                        const tmpForMap = anotherStartForMap;
                                                        tmp.splice(0, 1, ' ');
                                                        tmpForMap.splice(0, 1, '');
                                                        this.setState({
                                                            anotherStart: [...tmp],
                                                            anotherStartForMap: [...tmpForMap]
                                                        });
                                                    }}
                                                    alt=""
                                                />
                                            </Col>
                                        </Row>
                                        <Spacer bottom={15} />
                                    </div>
                                ) : null}
                                {anotherStart.length > 0 ? (
                                    <div>
                                        <div style={{ fontWeight: 500 }}>
                                            Add another starting point
                                        </div>
                                        {anotherStart.map((res, index) =>
                                            index !== 0 ? (
                                                <div key={index}>
                                                    <div className="d-flex flex-nowrap gap-2">
                                                        <div className="flex-grow-1">
                                                            <GoogleAutoComplete
                                                                defValue={res}
                                                                onSelectDistance={(value) => {
                                                                    const tmp = anotherStart;
                                                                    tmp.splice(
                                                                        index,
                                                                        1,
                                                                        value.description
                                                                    );
                                                                    this.setState({
                                                                        anotherStart: [...tmp],
                                                                        anotherStartForMap: [...tmp]
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="delete-block-autocomplete"
                                                            style={{ display: 'flex' }}>
                                                            <img
                                                                src={DeleteIcon}
                                                                className="delete-icon cursor-pointer"
                                                                onClick={() => {
                                                                    this.onDeleteAnotherStart(
                                                                        index
                                                                    );
                                                                }}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null
                                        )}
                                    </div>
                                ) : null}
                                <p
                                    className="page-sub-title brown-text add-another cursor-pointer"
                                    onClick={this.onAddAnotherStart}>
                                    <PlusCircleOutlined color="#A2792C" />
                                    Add another starting point
                                </p>
                                {isCheckBox === 3 ? (
                                    <div>
                                        <div style={{ fontWeight: 500 }}>
                                            If your starting point doesn't look right, you can drag
                                            the pin to adjust its location.
                                        </div>
                                        <GoogleMap
                                            data={anotherStartForMap}
                                            onSave={(value, index) => {
                                                const tmp = anotherStart;
                                                tmp.splice(index, 1, value);
                                                this.setState({
                                                    anotherStart: [...tmp]
                                                });
                                            }}
                                        />
                                    </div>
                                ) : null}

                                <p className="label mt25 input-title">What is the Depature time?</p>

                                <TimeBlock
                                    data={departure_time}
                                    onTime={(e) => this.setState({ departure_time: e })}
                                    showAmPm={false}
                                />

                                <p className="label mt25 input-title">
                                    How long before departure does your pickup start?
                                </p>

                                <TimeBlock
                                    data={moment
                                        .unix(departure_pickup_duration)
                                        .utc()
                                        .format('HH:mm')}
                                    isReturnSeconds={true}
                                    onSeconds={(e) =>
                                        this.setState({ departure_pickup_duration: e })
                                    }
                                    showAmPm={false}
                                />
                            </Form.Item>

                            <hr style={{ marginBottom: '25px' }} />

                            <Form.Item>
                                <AddSeason
                                    name="Itinerary Calendar Season"
                                    data={calendar_season_type}
                                    onClick={this.onOpenCalendarSeason}
                                />
                                <AddAdventureButton
                                    name="Type of Adventure on this Itinerary"
                                    data={adventureCategory}
                                    onClick={this.onOpenAddAdventure}
                                />
                                <AddTopSightsButton
                                    data={sights}
                                    name="Top Sights to see on this Itinerary"
                                    onClick={this.onOpenAddTopSights}
                                />
                                <AddInformationButton
                                    name="Information travellers need from you"
                                    data={information}
                                    onClick={this.onOpenAddInformation}
                                />
                            </Form.Item>
                        </ModalBody>
                    )}

                    <ModalFooter>
                        <div className="footer-btn">
                            <div>
                                <Button color="primary" htmlType="submit">
                                    Update Calender & Continue
                                </Button>
                                <Button
                                    color="dark"
                                    style={{ marginLeft: '8px' }}
                                    onClick={this.onClose}>
                                    Cancel
                                </Button>
                            </div>
                            <Button className="help-btn" color="dark" style={{ width: '137px' }}>
                                <img src={HelpIcon} alt="" /> Help
                            </Button>
                        </div>
                    </ModalFooter>
                </Form>
            </Modal>
        );
    }
}
ModalComponent.propTypes = {
    fileUpload: Proptypes.func,
    onClose: Proptypes.func,
    isOpen: Proptypes.bool,
    itineraries: Proptypes.any,
    calendarDay: Proptypes.number,
    create_itineraries: Proptypes.object,
    calendar_data: Proptypes.object,
    create_calendar: Proptypes.object,
    edit_itineraries: Proptypes.object,
    updateItineraries: Proptypes.func,
    createItineraries: Proptypes.func,
    createItineraries_: Proptypes.func,
    editItineraries_: Proptypes.func,
    getItineraries: Proptypes.func
};

const mapStateToProps = (state) => ({
    files: state.contentDetail.files,
    mealsAndDrinks: state.contentDetail.mealsAndDrinks,
    itineraries: state.contentDetail.itineraries,
    edit_itineraries: state.contentDetail.edit_itineraries,
    calendar_data: state.calendar.calendar_data,
    create_calendar: state.calendar.create_calendar,
    create_itineraries: state.contentDetail.create_itineraries
});

const mapDispatch = (dispatch) => ({
    fileUpload: (data) => dispatch(fileUpload(data)),
    getFiles: () => dispatch(getFiles()),
    getItineraries: (data) => dispatch(getItineraries(data)),
    clearUploadedFile: () => dispatch(clearUploadedFile()),
    createItineraries: (data) => dispatch(createItineraries(data)),
    createItineraries_: (data) => dispatch(createItineraries_(data)),
    editItineraries_: (data) => dispatch(editItineraries_(data)),
    updateItineraries: (id, data) => dispatch(updateItineraries(id, data))
});

export default withRouter(connect(mapStateToProps, mapDispatch)(ModalComponent));

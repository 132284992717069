import React, { useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import MoreText from './MoreText.jsx';
import NewGroupRow from './NewGroupRow.jsx';

const styles = {
    cell: {
        border: 'none'
    },
    currency: {
        background: '#F4F4F4',
        border: '0.5px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '10px 0px 0px 10px',
        padding: '12px',
        height: '48px'
    },
    input: {
        background: '#FFFFFF',
        border: '0.5px solid #A2792C',
        boxSizing: 'border-box',
        borderRadius: '5px',
        width: '89px',
        height: '48px',
        textAlign: 'center'
    }
};

export default function GroupRow(props) {
    const [amount, setAmount] = useState(1.0);
    const [newGroupRows, setNewGroupRows] = useState([]);

    const AddNewGroup = () => {
        let tempNewGroup = [...newGroupRows];
        tempNewGroup.push({});
        setNewGroupRows(tempNewGroup);
    };

    const handleAmount = (e) => {
        setAmount(e.target.value);
    };

    return (
        <>
            <TableRow style={{ border: 'none' }}>
                <TableCell style={styles.cell} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            style={{
                                border: '0.5px solid #A2792C',
                                boxSizing: 'border-box',
                                borderRadius: '5px',
                                marginRight: '10px'
                            }}
                        />
                        {props.groupName}
                    </div>
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <input style={styles.input} type="number" variant="outlined" min={1} />
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    -
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <input style={styles.input} type="number" variant="outlined" min={1} />
                </TableCell>
                <TableCell style={styles.cell} align="left">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={styles.currency}>USD</div>
                        <input
                            style={{ ...styles.input, borderRadius: '0 5px 5px 0' }}
                            type="number"
                            value={amount}
                            min={1}
                            onChange={handleAmount}
                        />
                        <div>PP</div>
                    </div>
                </TableCell>
                {newGroupRows.length > 0 ? null : (
                    <TableCell
                        // style={styles.cell}
                        align="left"
                        style={{ cursor: 'pointer', border: 'none' }}
                        onClick={AddNewGroup}>
                        <MoreText
                            customStyle={{
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#A2792C'
                            }}
                            text={`Add ${props.groupName} Group price`}
                        />
                    </TableCell>
                )}
            </TableRow>
            {newGroupRows.map(() => (
                <NewGroupRow groupName={props.groupName} />
            ))}
            {newGroupRows.length > 0 ? (
                <TableRow>
                    <TableCell style={styles.cell}></TableCell>
                    <TableCell style={styles.cell}></TableCell>
                    <TableCell style={styles.cell}></TableCell>
                    <TableCell style={styles.cell}></TableCell>
                    <TableCell style={{ cursor: 'pointer', border: 'none' }} onClick={AddNewGroup}>
                        <MoreText
                            customStyle={{
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '20px',
                                /* identical to box height */

                                color: '#A2792C'
                            }}
                            text={`Add Another ${props.groupName} Group price`}
                        />
                    </TableCell>
                </TableRow>
            ) : null}
        </>
    );
}

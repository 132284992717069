/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Row, Col, Input, Checkbox, Drawer } from 'antd';
import './index.scss';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ConfirmEditModal from 'components/molecules/confirmation-modal';
import {
    updateOfficeState,
    updateOfficeInformation,
    getBusinessInformation
} from 'store/actions/profileActions';
import { fetchLocations } from 'store/actions';
import SaveCancel from 'components/molecules/user-account/save-cancel';
import { GoogleAutoComplete } from 'components/atoms';

function OfficeInformation(props) {
    const {
        handleCancel,
        visible,
        updateOfficeState,
        updateOfficeInformation,
        getBusinessInformation,
        locations,
        business_info,
        fetchLocations
    } = props;
    const [form] = Form.useForm();
    const [savingForm, setSavingForm] = useState(false);
    const [isModalClose, setIsModalClose] = useState(false);

    useEffect(() => {
        if (business_info?.city?.name) {
            fetchLocations(business_info?.city?.name);
        }
    }, []);

    useEffect(() => {
        if (props.update_office) {
            updateOfficeState(false);
            setSavingForm(false);
            handleCancel();
        }
    }, [props.update_office, handleCancel, updateOfficeState]);

    const onFinish = () => {
        setSavingForm(true);
        const values = form.getFieldsValue();
        if (values?.cityId && typeof values.cityId === 'string') {
            let found = locations.find((val) => val?.city?.name === values?.cityId);
            if (found) values.cityId = found.city_id;
        }
        updateOfficeInformation(values).then(() => {
            getBusinessInformation();
        });
    };
    const handleModal = () => {
        const { country, city, postal_code, street_address, alternative_address, open_days } =
            props.business_info;
        const {
            country: form_country,
            city: form_city,
            postal_code: form_postal_code,
            street_address: form_street_address,
            alternative_address: form_alternative_address,
            open_days: form_open_days
        } = form.getFieldsValue();
        if (
            form_country !== country ||
            form_city !== city ||
            form_postal_code !== postal_code ||
            form_street_address !== street_address ||
            form_alternative_address !== alternative_address ||
            form_open_days !== open_days
        ) {
            return setIsModalClose(!isModalClose);
        }
        return handleCancel();
    };
    const closeAllModals = () => {
        if (visible && isModalClose) {
            handleCancel();
            form.resetFields();
            setIsModalClose(!isModalClose);
        }
    };

    return (
        <div>
            {/* Edit Office Information Drawer */}
            <Drawer
                width={609}
                visible={visible}
                onClose={handleModal}
                layout="vertical"
                key="right"
                className="office-information-modal">
                <h6 className="office-information-modal__title">
                    Edit Company Contact Information
                </h6>
                <Form
                    form={form}
                    onValuesChange={() => {}}
                    onFinish={onFinish}
                    layout="vertical"
                    className="mt-3 form-label-fw-500">
                    <Form.Item
                        name="cityId"
                        rules={[{ required: true, message: 'Please select location' }]}>
                        <div className="input-title">Search and select location</div>
                        <GoogleAutoComplete
                            placeholder="Search location"
                            containerClassName="profile-field"
                            fieldName="cityId"
                            defValue={props?.business_info?.city?.name ?? ''}
                            // onSelectDistance={(value, address) => {
                            //     this.formRef.current.setFieldsValue({
                            //         destination: value
                            //     });
                            //     this.setAddress(value);
                            // }}
                        />
                    </Form.Item>

                    {/* <SelectCountry
                        label="Search country"
                        initialValue={props.business_info ? props.business_info.country : undefined}
                    /> */}

                    {/* <Form.Item
                        label="State"
                        name="state"
                        initialValue={props.business_info ? props.business_info.state : ''}
                        rules={[{ required: true, message: 'Please enter state' }]}>
                        <Input size="large" />
                    </Form.Item> */}

                    {/* <Form.Item
                        label="City"
                        name="city"
                        initialValue={props.business_info ? props.business_info.city : ''}
                        rules={[{ required: true, message: 'Please enter city' }]}>
                        <Input size="large" />
                    </Form.Item> */}
                    <Form.Item
                        label="Zip code/postal code"
                        name="postal_code"
                        initialValue={props.business_info ? props.business_info.postal_code : ''}>
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label="Main office address"
                        name="street_address"
                        initialValue={props.business_info ? props.business_info.street_address : ''}
                        rules={[{ required: true, message: 'Please enter street address' }]}>
                        <Input size="large" />
                    </Form.Item>
                    <Form.Item
                        label={
                            <label>
                                Additional office address
                                <small className="ml-1">(Optional)</small>
                            </label>
                        }
                        name="alternative_address"
                        initialValue={
                            props.business_info ? props.business_info.alternative_address : ''
                        }>
                        <Input.TextArea
                            size="large"
                            placeholder="Please give addition office address"
                            autoSize={{ minRows: 5, maxRows: 8 }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="open_days"
                        label={
                            <label>
                                When is your office open?
                                <small className="ml-1">(Optional)</small>
                            </label>
                        }
                        initialValue={props.business_info ? props.business_info.open_days : ''}>
                        <Checkbox.Group className="d-flex flex-wrap">
                            <div className="row flex-grow-1">
                                <div className="col-lg-6">
                                    <Row>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Monday">Mon</Checkbox>
                                        </Col>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Tuesday">Tue</Checkbox>
                                        </Col>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Wednesday">Wed</Checkbox>
                                        </Col>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Thursday">Thu</Checkbox>
                                        </Col>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Friday">Fri</Checkbox>
                                        </Col>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Saturday">Sat</Checkbox>
                                        </Col>
                                        <Col span={12} className="mb-2">
                                            <Checkbox value="Sunday">Sun</Checkbox>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Checkbox.Group>
                    </Form.Item>
                    <SaveCancel saveLoading={savingForm} cancelClick={handleModal} />
                </Form>
            </Drawer>
            {/* Confirm Edit Modal */}
            <ConfirmEditModal
                modal={isModalClose}
                handleModal={handleModal}
                closeModal={closeAllModals}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    business_info: state.profile.business_info,
    update_office: state.profile.update_office,
    countries: state.profile.countries,
    locations: state.profile.locations
});
const mapDispatch = (dispatch) => ({
    updateOfficeInformation: (data) => dispatch(updateOfficeInformation(data)),
    updateOfficeState: (data) => dispatch(updateOfficeState(data)),
    getBusinessInformation: () => dispatch(getBusinessInformation()),
    fetchLocations: (query) => dispatch(fetchLocations(query))
});
OfficeInformation.propTypes = {
    handleCancel: PropTypes.func,
    visible: PropTypes.bool,
    updateOfficeState: PropTypes.func,
    update_office: PropTypes.any,
    updateOfficeInformation: PropTypes.func,
    business_info: PropTypes.object,
    countries: PropTypes.array,
    locations: PropTypes.array,
    getBusinessInformation: PropTypes.func,
    fetchLocations: PropTypes.func
};

export default withRouter(connect(mapStateToProps, mapDispatch)(OfficeInformation));

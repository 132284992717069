/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { DatePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button } from 'reactstrap';

export default function DatePickerComponent(props) {
    const {
        value,
        disabledDate,
        onChange,
        onPanelChange,
        showFooterHeader,
        dateAvailability,
        onOpenChange
    } = props;

    function dPExtraFooter(date, clearDates) {
        return (
            <div className="lh-1-5">
                <div className="calendar-package-data-header d-flex justify-content-between align-items-center gap-3">
                    <div>
                        <div className="calendar-package-data-header-title">Selected Date</div>
                        <div className="calendar-package-data-header-duration">
                            {date && moment(date, 'YYYY-MM-DD').format('MMM DD,YYYY')}
                        </div>
                    </div>
                </div>
                <div className="py-3">
                    <div className="mb-2">
                        <div className="d-flex flex-wrap gap-3 align-items-center">
                            <div className="d-inline-flex align-items-center gap-1">
                                <span className="package-date-marker _unavailable"></span>
                                <div className="package-date-label">Unavailable</div>
                            </div>
                            <div className="d-inline-flex align-items-center gap-1">
                                <span className="package-date-marker _available"></span>
                                <div className="package-date-label">Available</div>
                            </div>
                            <div className="d-inline-flex align-items-center gap-1">
                                <span className="package-date-marker _sold-out"></span>
                                <div className="package-date-label _sold-out">Sold out</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex gap-2 justify-content-end align-items-start">
                            <Button
                                onClick={clearDates}
                                size="sm"
                                color="link"
                                className="text-dark">
                                Clear date{' '}
                            </Button>
                            {/* <Button
                                size="sm"
                                color="dark">
                                Close </Button> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <DatePicker
            className="inputclass mb-1"
            size="small"
            placeholder="Date"
            value={value ? moment(value) : undefined}
            disabledDate={disabledDate ? disabledDate : null}
            onChange={onChange ? onChange : null}
            onPanelChange={onPanelChange ? onPanelChange : null}
            onOpenChange={onOpenChange ? onOpenChange : null}
            dateRender={(current) => {
                return (
                    <div
                        className={`ant-picker-cell-inner calendar-package-date-marker 
                        ${dateAvailability && `_${dateAvailability(current)}`} `}>
                        {current.date()}
                    </div>
                );
            }}
            dropdownClassName={
                showFooterHeader ? 'has-calendar-package-data-header zi-1060' : 'zi-1060'
            }
            renderExtraFooter={() =>
                showFooterHeader ? dPExtraFooter(value, () => onChange(null, null)) : null
            }
        />
    );
}

DatePickerComponent.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    disabledDate: PropTypes.func,
    onPanelChange: PropTypes.func,
    showFooterHeader: PropTypes.bool,
    dateAvailability: PropTypes.func,
    onOpenChange: PropTypes.func
};

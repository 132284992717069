import React, { useEffect, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { getAllCountries } from 'store/actions';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { LoadingOutlined } from '@ant-design/icons';

function PhoneCountryCode(props) {
    const {
        label,
        required = true,
        selectedCountryCode,
        onChangeCountryCode,
        expandMenu = false,
        toggleMenu,
        phoneNumber,
        onChangePhoneNumber,
        countries,
        placeHolder,
        phoneNumberFieldName = 'phone_number',
        countryCodeFieldName = 'selectCode',
        getAllCountries,
        disabled = false
    } = props;

    const [countryCodes, setCountryCodes] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (Array.isArray(countries) && countries.length < 1) fetchCountries();
        else if (!countries) fetchCountries();
        else if (!Array.isArray(countries)) fetchCountries();
    }, []);

    useEffect(() => {
        setCountryCodes(countries);
    }, [countries[0]?.name]);

    function fetchCountries() {
        setLoading(true);
        getAllCountries().finally(() => setLoading(false));
    }

    function onChangeSearch(e) {
        const { value } = e.target;
        filterCountryCode(value);
    }

    function filterCountryCode(query) {
        let raw = [];
        if (Array.isArray(countries)) {
            raw = countries.filter((res) => {
                if (res?.name) {
                    let name = res.name.toLowerCase();
                    if (name.startsWith(query ? query.toLowerCase() : '')) return true;
                    else return false;
                } else return false;
            });
            setCountryCodes(raw);
        } else setCountryCodes(raw);
    }

    function checkNumber(_, value) {
        if (isNaN(value)) {
            return Promise.reject('Please enter only digits');
        }
        if (value && value.length < 10) {
            return Promise.reject('Min. length is 10 digits');
        }
        if (value && value.length > 11) {
            return Promise.reject('Max. length is 11 digits');
        }
        if (!isNaN(value) && value && value.length > 0) {
            return Promise.resolve();
        }
        return Promise.reject('Please enter valid phone number');
    }

    return (
        <div>
            {label && (
                <div className="ant-form-item-label">
                    <label className={`${required ? 'ant-form-item-required' : ''}`}>{label}</label>
                </div>
            )}

            <div style={{ display: 'flex' }}>
                <Form.Item
                    name={countryCodeFieldName}
                    rules={[
                        {
                            required: selectedCountryCode ? false : true,
                            message: 'Required'
                        }
                    ]}>
                    <Dropdown size="lg" isOpen={expandMenu} toggle={toggleMenu}>
                        <DropdownToggle
                            disabled={disabled}
                            color="primary"
                            className="dropdown-category border-right-radius-0"
                            style={{
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0
                            }}
                            caret={!disabled}>
                            {selectedCountryCode === '' ? 'code' : selectedCountryCode}
                        </DropdownToggle>
                        <DropdownMenu className="category-menu">
                            <div className="mb-2">
                                <Input
                                    onChange={onChangeSearch}
                                    type="search"
                                    size="small"
                                    placeholder="Search.."
                                />
                            </div>
                            <Spin
                                spinning={loading}
                                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}>
                                <div
                                    style={{
                                        width: 300,
                                        height: 200,
                                        maxHeight: 200,
                                        overflowY: 'auto'
                                    }}>
                                    {Array.isArray(countryCodes)
                                        ? countryCodes.map((res, i) => (
                                              <DropdownItem
                                                  onClick={() => {
                                                      onChangeCountryCode(res.phone_code);
                                                      filterCountryCode('');
                                                  }}
                                                  style={{
                                                      backgroundColor:
                                                          res.phone_code === selectedCountryCode
                                                              ? '#E7D99F'
                                                              : '#fff'
                                                  }}
                                                  key={i}
                                                  className="category-item text-truncate">
                                                  {res.phone_code + ' ' + res.name}
                                              </DropdownItem>
                                          ))
                                        : null}
                                </div>
                            </Spin>
                        </DropdownMenu>
                    </Dropdown>
                </Form.Item>
                <Form.Item
                    initialValue={phoneNumber ? phoneNumber : ''}
                    name={phoneNumberFieldName}
                    rules={[{ required: true, validator: checkNumber }]}
                    className="flex-fill">
                    <Input
                        type="phone"
                        className="input-phone border-left-radius-0"
                        size="large"
                        // value={phoneNumber}
                        onChange={(e) => {
                            if (onChangePhoneNumber) onChangePhoneNumber(e.target.value);
                        }}
                        placeholder={placeHolder ? placeHolder : ''}
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    />
                </Form.Item>
            </div>
        </div>
    );
}

PhoneCountryCode.propTypes = {
    getAllCountries: PropTypes.func,
    countries: PropTypes.array,
    label: PropTypes.string,
    required: PropTypes.bool,
    selectedCountryCode: PropTypes.string,
    onChangeCountryCode: PropTypes.func,
    expandMenu: PropTypes.bool,
    toggleMenu: PropTypes.func,
    phoneNumber: PropTypes.any,
    onChangePhoneNumber: PropTypes.func,
    placeHolder: PropTypes.string,
    phoneNumberFieldName: PropTypes.string,
    countryCodeFieldName: PropTypes.string,
    disabled: PropTypes.bool
};

const mapStateToProps = (state) => ({
    countries: state.profile.countries
});
const mapDispatch = (dispatch) => ({
    getAllCountries: () => dispatch(getAllCountries())
});

export default withRouter(connect(mapStateToProps, mapDispatch)(PhoneCountryCode));

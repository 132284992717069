import { LoadingOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Spin, Tabs } from 'antd';
import React, { Component } from 'react';
import Chart from 'react-google-charts';
import { Col, Row, Table } from 'reactstrap';

class CreateCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { TabPane } = Tabs;
        return (
            <>
                <div className="e2e-fix">
                    <Row noGutters>
                        <Col xs={12} className="flex-lg-360-rest">
                            <div className="main-panel-padded min-h-100vh _with-header _with-footer h-100">
                                <h1 className="text-center">Calendar Plugin</h1>
                            </div>
                        </Col>
                        <Col xs={12} className="flex-lg-360">
                            <div className="side-panel-padded side-panel-scrollable">
                                {/* button and charts */}
                                <div className="side-panel-header">
                                    <Button type="secondary" size="large" className="btn-block">
                                        Create Your Main Product
                                    </Button>
                                    <div className="mt-10px">
                                        <Tabs
                                            className="sidebar-tabs"
                                            defaultActiveKey="revenue"
                                            type="card">
                                            <TabPane tab="Revenue Projection" key="revenue">
                                                <Chart
                                                    width={'100%'}
                                                    height={'250px'}
                                                    chartType="Bar"
                                                    loader={
                                                        <Spin
                                                            className="d-block py-4 text-center"
                                                            indicator={
                                                                <LoadingOutlined
                                                                    style={{ fontSize: 24 }}
                                                                    spin
                                                                />
                                                            }
                                                        />
                                                    }
                                                    data={[
                                                        [
                                                            'Sales',
                                                            'Entry Barrier',
                                                            'Actual Sales',
                                                            'Projected',
                                                            'Av. Customer Budget'
                                                        ],
                                                        ['2014', 600, 0, 0, 0],
                                                        ['2015', 0, 1300, 0, 0],
                                                        ['2016', 0, 0, 1800, 0],
                                                        ['2017', 0, 0, 0, 2500]
                                                    ]}
                                                    options={{
                                                        // Material design options
                                                        colors: [
                                                            '#FF3D00',
                                                            '#345BC8',
                                                            '#4CDED5',
                                                            '#A2792C'
                                                        ],
                                                        chart: {
                                                            title: '',
                                                            subtitle: ''
                                                        },
                                                        chartArea: { width: '50px' },
                                                        legend: { position: 'none' }
                                                    }}
                                                />
                                            </TabPane>
                                            <TabPane tab="Demand Projection" key="demand">
                                                <Chart
                                                    width={'100%'}
                                                    height={'250px'}
                                                    chartType="PieChart"
                                                    loader={
                                                        <Spin
                                                            className="d-block py-4 text-center"
                                                            indicator={
                                                                <LoadingOutlined
                                                                    style={{ fontSize: 24 }}
                                                                    spin
                                                                />
                                                            }
                                                        />
                                                    }
                                                    data={[
                                                        ['Task', 'Hours per Day'],
                                                        ['Work', 11],
                                                        ['Eat', 2],
                                                        ['Commute', 2],
                                                        ['Watch TV', 2],
                                                        ['Sleep', 7]
                                                    ]}
                                                    options={{
                                                        title: 'My Daily Activities'
                                                    }}
                                                    rootProps={{ 'data-testid': '1' }}
                                                />
                                            </TabPane>
                                        </Tabs>
                                    </div>
                                </div>

                                {/* Add/Remove Products Table */}
                                <div className="side-panel-body d-flex flex-column">
                                    <div className="scrollbar-fixed" style={{ overflowY: 'auto' }}>
                                        <Table className="fs-12 mb-1" size="sm" borderless>
                                            {/* <thead>
                                            <tr>
                                                <th>Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead> */}
                                            <tbody>
                                                <tr>
                                                    <td>Main Product</td>
                                                    <td className="text-right">
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Add-on Product 1</td>
                                                    <td>
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Add-on Product 2</td>
                                                    <td>
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Add-on Product 3</td>
                                                    <td>
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Add-on Product 4</td>
                                                    <td>
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Add-on Product 5</td>
                                                    <td>
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Add-on Product 6</td>
                                                    <td>
                                                        <div className="d-flex justify-content-end align-items-center text-nowrap">
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<MinusOutlined />}></Button>
                                                            <div className="px-2">$600</div>
                                                            <Button
                                                                type="secondary-light"
                                                                size="small"
                                                                icon={<PlusOutlined />}></Button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                    {/* Add more */}
                                    <div className="text-center">
                                        <Button type="link" size="small">
                                            Add More Add-on Products
                                        </Button>
                                    </div>
                                </div>

                                <div className="side-panel-footer">
                                    <Button
                                        type="secondary"
                                        size="large"
                                        className="btn-block"
                                        onClick={() => this.setState({ addOnProductsModal: true })}>
                                        Add-on Products
                                    </Button>
                                    <Button type="dark" size="large" className="btn-block">
                                        Access Customer Calendar
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default CreateCalendar;

import React from 'react';
import PropTypes from 'prop-types';
import { TimePicker, Drawer } from 'antd';
import { Row, Col, Button } from 'reactstrap';
import moment from 'moment';
import { disabledDate, dateAvailability, disabledDateAvailability, getDynamicError } from 'utils';
import { DatePickerComponent, RangePickerComponent } from 'components/atoms';

export default function PackageProductsDrawer(props) {
    const {
        state,
        setState,
        onChangPP,
        onChangPPRange,
        onPanelChange,
        removeErrors,
        validatePackageFields,
        inhertProps
    } = props;

    return (
        <Drawer
            width={609}
            placement="right"
            zIndex={1051}
            onClose={() => setState({ productDetailsDrawerToggle: false })}
            visible={state.productDetailsDrawerToggle}>
            <div className="d-flex flex-column h-100">
                <h4 className="mb-3">Enter Package Details</h4>
                <div className="d-flex flex-column overflow-y-auto flex-grow-1">
                    <div
                        className="d-flex flex-column h-100 overflow-y-auto p-2"
                        style={{
                            background: '#FEFCF4',
                            border: '1px solid #E7D99F',
                            borderRadius: '6px'
                        }}>
                        <div className="px-3">
                            <Row form>
                                <Col xs={12}>
                                    <div className="fs-14 fw-500 mb-2">Package dates</div>
                                </Col>
                                <Col xs={12}>
                                    <RangePickerComponent
                                        value={[state.packageStartDate, state.packageEndDate]}
                                        onChange={(date, dateString) => {
                                            setState({
                                                packageStartDate: dateString[0],
                                                packageEndDate: dateString[1]
                                            });
                                            removeErrors('packageStartDate', 'packageEndDate');
                                        }}
                                        disabledDate={(current) => disabledDate(current)}
                                        showFooterHeader={false}
                                    />

                                    {getDynamicError(state.errors, [
                                        {
                                            f: 'packageStartDate',
                                            m: 'Package dates is required'
                                        },
                                        {
                                            f: 'packageEndDate',
                                            m: 'Package dates is required'
                                        },
                                        {
                                            f: 'pasedNotInTRange',
                                            m: 'Package dates should be between the trip dates'
                                        }
                                    ])}
                                </Col>
                            </Row>
                        </div>
                        <div
                            className="bg-white py-2 px-3 rounded overflow-y-auto flex-grow-1 scrollbar-fixed"
                            style={{ border: '1px solid #E7D99F' }}>
                            {Array.isArray(state?.packageProducts) &&
                                state.packageProducts.map((val, i) =>
                                    val?.day_type === 'all_day' ? (
                                        <div key={i}>
                                            <div className="fs-16 fw-500 mb-1">
                                                {`${val?.title}`}
                                            </div>
                                            <Row form style={{ maxWidth: 350 }}>
                                                <Col xs={12}>
                                                    <div className="fs-14 fw-500 mb-2">
                                                        Product Dates
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    <RangePickerComponent
                                                        value={[
                                                            state.packageProducts[i]
                                                                .productStartDate,
                                                            state.packageProducts[i].productEndDate
                                                        ]}
                                                        onChange={(date, dateString) => {
                                                            onChangPPRange(
                                                                dateString[0],
                                                                dateString[1],
                                                                i,
                                                                'productStartDate',
                                                                'productEndDate',
                                                                state.viewPaidOne
                                                            );
                                                        }}
                                                        disabledDate={(current) =>
                                                            state.viewPaidOne
                                                                ? disabledDateAvailability(
                                                                      current,
                                                                      state.availabilityData
                                                                  )
                                                                : disabledDate(current)
                                                        }
                                                        onPanelChange={(date) =>
                                                            state.viewPaidOne &&
                                                            onPanelChange(
                                                                date[0] ? date[0] : date[1],
                                                                val?.id,
                                                                inhertProps?.calendarId,
                                                                true
                                                            )
                                                        }
                                                        onOpenChange={(bool) =>
                                                            bool &&
                                                            state.viewPaidOne &&
                                                            onPanelChange(
                                                                moment(),
                                                                val?.id,
                                                                inhertProps?.calendarId,
                                                                true
                                                            )
                                                        }
                                                        dateAvailability={(current) =>
                                                            state.viewPaidOne &&
                                                            dateAvailability(
                                                                current,
                                                                state.availabilityData
                                                            )
                                                        }
                                                        showFooterHeader={
                                                            state.viewPaidOne ? true : false
                                                        }
                                                    />

                                                    {getDynamicError(state?.errors, [
                                                        {
                                                            f: `productStartDate${i}`,
                                                            m: 'Date(s) of booking is required'
                                                        },
                                                        {
                                                            f: `productEndDate${i}`,
                                                            m: 'Date(s) of booking is required'
                                                        },
                                                        {
                                                            f: `ppRangeInvalid${i}`,
                                                            m: 'Please select a valid range of dates'
                                                        },
                                                        {
                                                            f: `psedNotInTRange${i}`,
                                                            m: 'Product dates should be between the package dates'
                                                        }
                                                    ])}
                                                </Col>

                                                <Col xs={12}>
                                                    <hr className="mt-2" />
                                                </Col>
                                            </Row>
                                        </div>
                                    ) : val?.day_type === 'within_day' ? (
                                        <div key={i}>
                                            <div className="fs-16 fw-500 mb-1">{`${val?.title}`}</div>
                                            <Row form style={{ maxWidth: 350 }}>
                                                <Col xs={12}>
                                                    <div className="fs-14 fw-500 mb-1 text-truncate">
                                                        Product Date
                                                    </div>
                                                    <div className="mb-1">
                                                        <DatePickerComponent
                                                            value={
                                                                state.packageProducts[i].productDate
                                                            }
                                                            disabledDate={(current) =>
                                                                state.viewPaidOne
                                                                    ? disabledDateAvailability(
                                                                          current,
                                                                          state.availabilityData
                                                                      )
                                                                    : disabledDate(current)
                                                            }
                                                            onChange={(date, dateString) =>
                                                                onChangPP(
                                                                    dateString,
                                                                    i,
                                                                    'productDate'
                                                                )
                                                            }
                                                            onPanelChange={(date) =>
                                                                state.viewPaidOne &&
                                                                onPanelChange(
                                                                    date,
                                                                    val?.id,
                                                                    inhertProps?.calendarId
                                                                )
                                                            }
                                                            onOpenChange={(bool) =>
                                                                bool &&
                                                                state.viewPaidOne &&
                                                                onPanelChange(
                                                                    moment(),
                                                                    val?.id,
                                                                    inhertProps?.calendarId
                                                                )
                                                            }
                                                            dateAvailability={(current) =>
                                                                state.viewPaidOne &&
                                                                dateAvailability(
                                                                    current,
                                                                    state.availabilityData
                                                                )
                                                            }
                                                            showFooterHeader={
                                                                state.viewPaidOne ? true : false
                                                            }
                                                        />
                                                    </div>
                                                    {getDynamicError(state?.errors, [
                                                        {
                                                            f: `productDate${i}`,
                                                            m: 'Date of booking is required'
                                                        },
                                                        {
                                                            f: `pdNotInTRange${i}`,
                                                            m: 'Product date should be between the package dates'
                                                        }
                                                    ])}
                                                </Col>
                                                <Col md={6}>
                                                    <div className="fs-14 fw-500 mb-1 text-truncate">
                                                        Product Start Time
                                                    </div>
                                                    <div className="mb-1">
                                                        <TimePicker
                                                            className="inputclass mb-1"
                                                            size="small"
                                                            placeholder="Select start time"
                                                            popupClassName="zi-1060"
                                                            use12Hours
                                                            format="hh:mm A"
                                                            onChange={(e) =>
                                                                onChangPP(
                                                                    e ? e.format('hh:mm A') : null,
                                                                    i,
                                                                    'productStartTime'
                                                                )
                                                            }
                                                            value={
                                                                state.packageProducts[i]
                                                                    .productStartTime
                                                                    ? moment(
                                                                          state.packageProducts[i]
                                                                              .productStartTime,
                                                                          'hh:mm A'
                                                                      )
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                                <Col md={6}>
                                                    <div className="fs-14 fw-500 mb-1 text-truncate">
                                                        Product End Time
                                                    </div>
                                                    <div className="mb-1">
                                                        <TimePicker
                                                            className="inputclass mb-1"
                                                            size="small"
                                                            placeholder="Select end time"
                                                            popupClassName="zi-1060"
                                                            use12Hours
                                                            format="hh:mm A"
                                                            onChange={(e) =>
                                                                onChangPP(
                                                                    e ? e.format('hh:mm A') : null,
                                                                    i,
                                                                    'productEndTime'
                                                                )
                                                            }
                                                            value={
                                                                state.packageProducts[i]
                                                                    .productEndTime
                                                                    ? moment(
                                                                          state.packageProducts[i]
                                                                              .productEndTime,
                                                                          'hh:mm A'
                                                                      )
                                                                    : undefined
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                                <Col xs={12}>
                                                    {getDynamicError(state?.errors, [
                                                        {
                                                            f: `productStartTime${i}`,
                                                            m: 'Time of booking is required'
                                                        },
                                                        {
                                                            f: `productEndTime${i}`,
                                                            m: 'Time of booking is required'
                                                        }
                                                    ])}
                                                </Col>
                                                <Col xs={12}>
                                                    <hr className="mt-2" />
                                                </Col>
                                            </Row>
                                        </div>
                                    ) : null
                                )}
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-3">
                    <Button onClick={validatePackageFields} color="primary" size="lg">
                        Save
                    </Button>
                </div>
            </div>
        </Drawer>
    );
}

PackageProductsDrawer.propTypes = {
    state: PropTypes.object,
    setState: PropTypes.func,
    onChangPP: PropTypes.func,
    onChangPPRange: PropTypes.func,
    onPanelChange: PropTypes.func,
    removeErrors: PropTypes.func,
    validatePackageFields: PropTypes.func,
    inhertProps: PropTypes.object
};
